
import { validate } from './validations';
import { build } from '../../services/crudFactory';
import { Action } from '../../services/actions.factory';
import api from '../../services/api.service';
const BASE_PATH = '/lognex/zipCodeRanges';
const prefix = 'crudZipCodeRange';
const crud = build(validate, BASE_PATH);

const newTypes = {
    SET_LOOKUP_PARENT_VISIBLE: `${prefix}setLookupParentVisible`,
    SET_RANGE: `${prefix}setRange`, 
}

const actions = new Action(prefix, crud);

const types = Object.assign({}, actions.types, newTypes);

actions.setLookupParentVisible = (visible) => {
    return {
        type: types.SET_LOOKUP_PARENT_VISIBLE,
        visible
    }
}

actions.setRange = (field, value) => {
    return {
        type: types.SET_RANGE,
        field,
        value
    }
}
 
export { types, actions }