
export function validate(record) {
    const errors = [];

    if (!record.documentNumber) {
        errors.push({ field: 'documentNumber', message: 'O número do documento é obrigatório!' });
    }
    if (!record.name) {
        errors.push({ field: 'name', message: 'O nome é obrigatório!' });
    }
    record.documentNumber = record.documentNumber || "";
    record.documentNumber = record.documentNumber.replaceAll(".","")
    record.documentNumber = record.documentNumber.replaceAll("-","")
    
    const result = {
        ok: errors.length === 0,
        errors,
        record
    }

    return new Promise((resolve, reject) => {
        const action = result.ok ? resolve : reject;
        return action(result);
    })
}