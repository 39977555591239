const languages = {
    "pt-BR":{
        "WEIGHT":"Peso",
        "EXCESS_WEIGHT":"Peso excedente",
        "PRODUCT_VALUE":"Valor da mercadoria",
        "VOLUME_QUANTITY": "Quantidade de volumes",
        "QUANTITY_ITEMS": "Quantidade de itens",
        "COST_FREIGHT":"Valor do frete",
        "TOTAL_QUOTED" : "Total orçado",
        "TOTAL_REALIZED" : "Total realizado",
        "TOTAL_DIVERGENT" : "Total Divergente",
        "TOTAL_RECORDS" : "Total de registros",
        "KILOMETER":"Kilometragem",
        "EXCESS_KILOMETER":"Kilometragem excedente",
        "CUBAGE":"Volume (M3)",
        "EXCESS_CUBAGE":"Volume (M3) excedente",
    },
    "en-US":{
        "WEIGHT":"Peso",
        "EXCESS_WEIGHT":"Peso excedente",
        "PRODUCT_VALUE":"Valor da mercadoria",
        "VOLUME_QUANTITY": "Quantidade de volumes",
        "QUANTITY_ITEMS": "Quantidade de itens",
        "COST_FREIGHT":"Valor do frete",
        "TOTAL_QUOTED" : "Total orçado",
        "TOTAL_REALIZED" : "Total realizado",
        "TOTAL_DIVERGENT" : "Total Divergente",
        "TOTAL_RECORDS" : "Total de registros",
        "KILOMETER":"Kilometragem",
        "EXCESS_KILOMETER":"Kilometragem excedente",
        "CUBAGE":"Volume (M3)",
        "EXCESS_CUBAGE":"Volume (M3) excedente",
    }
}

export const getValue = (key)=>{
    const language = navigator.language;
    const currentLanguageWords = languages[language] || {}
    const value = currentLanguageWords[key] || key
    return value;
}