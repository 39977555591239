import { types } from "./polices.actions";
import { Reducer } from "../../services/reducer.factory";

const initialState = {
  columns: [{
    field: "oid",
    header: "Código",
    columnKey: "oid",
    sortable: true,
    filter: false,
  },
  {
    field: "name",
    header: "Nome",
    columnKey: "name",
    sortable: false,
    filter: true,
  },
  {
    field: "description",
    header: "Descrição",
    columnKey: "description",
    sortable: false,
    filter: true,
  },
  {
    field: "category",
    header: "Categoria",
    columnKey: "category",
    sortable: false,
    filter: true,
  }],
};

const reducer = new Reducer(initialState, types);

export function CRUDPolicesReducer(state, action) {
  return reducer.execute(state, action);
}
