import { types } from './menu.actions.js';

const initialState = {
  items: [],
  currentRecord: null,
}

function findMenuByPath(path, menuItems) {
  let menuFinded = null
  menuItems.forEach(menuItem => {
    if (menuItem.path === path) {
      menuFinded = menuItem
    }
    if (menuItem.items) {
      const resultChildren = findMenuByPath(path, menuItem.items)
      if (resultChildren) {
        menuFinded = resultChildren
      }
    }
  });
  return menuFinded
}

export function menuReducer(state = initialState, action) {
  if (action.type === types.MENU_SET_ITEMS) {
    return Object.assign({}, state, { items: action.items || [] })
  } else if (action.type === types.MENU_CLEAR_ITEMS) {
    return Object.assign({}, state, { items: [] })
  } else if (action.type === types.SET_CURRENT_RECORD) {
    const currentMenu = findMenuByPath(action.menuPath, state.items)
    return Object.assign({}, state, { currentRecord: currentMenu })
  }
  return state;
}