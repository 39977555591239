import React from 'react';
import { InputText } from 'primereact/inputtext';
import { LookupField } from '../../../components/lookup/LookupField';
import { Card } from 'primereact/card';
import { SelectButton } from 'primereact/selectbutton';
import { InputCurrency } from '../../../components/input-currency/InputCurrency'
import { InputNumber } from '../../../components/input-number/InputNumber';
import SimpleTable from '../../../components/simple-table/simple-table'
import { InputCep } from '../../../components/input-cep/InputCep';
import './FreightCalculationForm.scss';

export const FreightCalculationForm = (props) => {
    const freightCalculationParams = props.freightCalculationParams;
    const lookupOriginComponent = (<div className="p-col-6 form-field-container">
        <label className="lxRequiredField">Origem</label>
        <LookupField
            value={freightCalculationParams && freightCalculationParams.locationOrigin ? freightCalculationParams.locationOrigin.description : ""}
            placeholder="Origem"
            name="originName"
            field="description"
            removeEdit={true}
            onComplete={props.lookupOriginOnComplete}
            onInputFilter={props.lookupOriginOnInputFilter}
            editDisabled={props.lookupOrigin.editDisabled}
            onEditField={props.lookupOriginOnEdit}
            onClick={props.lookupOriginOnClick}

            visible={props.lookupOrigin.visible}
            modal={props.lookupOrigin.modal}
            header={props.lookupOrigin.header}
            onHide={props.lookupOriginOnHide}
            onConfirm={props.lookupOriginOnConfirm}
            onCancel={props.lookupOriginOnCancel}
            records={props.lookupOrigin.records}
            columns={props.lookupOrigin.columns}
            totalRecords={props.lookupOrigin.totalRecords}
            sortOrder={props.lookupOrigin.order}
            sortField={props.lookupOrigin.sortField}
            first={props.lookupOrigin.first}
            rows={props.lookupOrigin.rows}
            onPage={props.lookupOriginOnPage}
            onFilter={props.lookupOriginOnFilter}
            onSort={props.lookupOriginOnOnSort}

        />
    </div>);

    const cepOriginComponent =
        <div className="p-col-6 form-field-container">
            <label className="lxRequiredField">CEP Origem</label>
            <InputCep value={freightCalculationParams.zipCodeOrigin} onChange={props.onChangeZipCodeOrigin} name="zipCodeOrigin" disabled={false} />
        </div>

    const originComponent = props.currentLocationTypeFilter === 'LOCATION' ? lookupOriginComponent : cepOriginComponent

    const lookupClientComponent =
        <div className="p-col-12 form-field-container">
            <label>Cliente</label>
            <LookupField
                value={freightCalculationParams && freightCalculationParams.client ? freightCalculationParams.client.razaoSocial : ""}
                placeholder="Cliente"
                name="razaoSocial"
                field="razaoSocial"
                removeEdit={true}
                onComplete={props.lookupClientOnComplete}
                onInputFilter={props.lookupClientOnInputFilter}
                editDisabled={props.lookupClient.editDisabled}
                onEditField={props.lookupClientOnEdit}
                onClick={props.lookupClientOnClick}

                visible={props.lookupClient.visible}
                modal={props.lookupClient.modal}
                header={props.lookupClient.header}
                onHide={props.lookupClientOnHide}
                onConfirm={props.lookupClientOnConfirm}
                onCancel={props.lookupClientOnCancel}
                records={props.lookupClient.records}
                columns={props.lookupClient.columns}
                totalRecords={props.lookupClient.totalRecords}
                sortOrder={props.lookupClient.order}
                sortField={props.lookupClient.sortField}
                first={props.lookupClient.first}
                rows={props.lookupClient.rows}
                onPage={props.lookupClientOnPage}
                onFilter={props.lookupClientOnFilter}
                onSort={props.lookupClientOnSort}
            />
        </div>;

    const lookupVehicleTypeComponent =
        <div className="p-col-12 form-field-container">
            <label>Tipo do veículo</label>
            <LookupField
                value={freightCalculationParams && freightCalculationParams.vehicleType ? freightCalculationParams.vehicleType.name : ""}
                placeholder="Tipo do veículo"
                name="name"
                field="name"
                removeEdit={true}
                onComplete={props.lookupVehicleTypeOnComplete}
                onInputFilter={props.lookupVehicleTypeOnInputFilter}
                editDisabled={props.lookupVehicleType.editDisabled}
                onEditField={props.lookupVehicleTypeOnEdit}
                onClick={props.lookupVehicleTypeOnClick}

                visible={props.lookupVehicleType.visible}
                modal={props.lookupVehicleType.modal}
                header={props.lookupVehicleType.header}
                onHide={props.lookupVehicleTypeOnHide}
                onConfirm={props.lookupVehicleTypeOnConfirm}
                onCancel={props.lookupVehicleTypeOnCancel}
                records={props.lookupVehicleType.records}
                columns={props.lookupVehicleType.columns}
                totalRecords={props.lookupVehicleType.totalRecords}
                sortOrder={props.lookupVehicleType.order}
                sortField={props.lookupVehicleType.sortField}
                first={props.lookupVehicleType.first}
                rows={props.lookupVehicleType.rows}
                onPage={props.lookupVehicleTypeOnPage}
                onFilter={props.lookupVehicleTypeOnFilter}
                onSort={props.lookupVehicleTypeOnSort}
            />
        </div>;

    const lookupDestinationComponent =
        <div className="p-col-6 form-field-container">
            <label className="lxRequiredField">Destino</label>
            <LookupField
                value={freightCalculationParams && freightCalculationParams.locationDestination ? freightCalculationParams.locationDestination.description : ""}
                placeholder="Destino"
                name="destinationName"
                field="description"
                removeEdit={true}
                onComplete={props.lookupDestinationOnComplete}
                onInputFilter={props.lookupDestinationOnInputFilter}
                editDisabled={props.lookupDestination.editDisabled}
                onEditField={props.lookupDestinationOnEdit}
                onClick={props.lookupDestinationOnClick}

                visible={props.lookupDestination.visible}
                modal={props.lookupDestination.modal}
                header={props.lookupDestination.header}
                onHide={props.lookupDestinationOnHide}
                onConfirm={props.lookupDestinationOnConfirm}
                onCancel={props.lookupDestinationOnCancel}
                records={props.lookupDestination.records}
                columns={props.lookupDestination.columns}
                totalRecords={props.lookupDestination.totalRecords}
                sortOrder={props.lookupDestination.order}
                sortField={props.lookupDestination.sortField}
                first={props.lookupDestination.first}
                rows={props.lookupDestination.rows}
                onPage={props.lookupDestinationOnPage}
                onFilter={props.lookupDestinationOnFilter}
                onSort={props.lookupDestinationOnOnSort}

            />
        </div>;
    const cepDestinationComponent =
        <div className="p-col-6 form-field-container">
            <label className="lxRequiredField">CEP Destino</label>
            <InputCep value={freightCalculationParams.zipCodeDestination} onChange={props.onChangeZipCodeDestination} name="freightCalculationParams.zipCodeDestination" disabled={false} />
        </div>

    const destinationComponent = props.currentLocationTypeFilter === 'LOCATION' ? lookupDestinationComponent : cepDestinationComponent
    return (
        <div className="freightCalculation-form">
            <div className="p-grid">
                <div className="p-col-12">

                    <Card>
                        <div className="p-grid">
                            <div className="p-col-6">
                                <div className="p-grid">
                                    <div className="p-col-2 form-field-container">
                                        <SelectButton value={props.currentLocationTypeFilter} options={props.locationTypeFilterOptions} onChange={props.locationTypeFilterChangeHandler}></SelectButton>
                                    </div>
                                    <div className="p-col-10 form-field-container">
                                    </div>
                                    {originComponent}
                                    {destinationComponent}
                                    <div className="p-col-6 form-field-container">
                                        <label className="lxRequiredField">Valor da nota</label>
                                        <InputCurrency currencyCode="R$" value={freightCalculationParams.value} onChange={props.onChangeValue} name="value" disabled={false}> </InputCurrency>

                                    </div>
                                    <div className="p-col-6 form-field-container">
                                        <label className="lxRequiredField">Peso</label>
                                        <InputText type="number" value={freightCalculationParams.weight} onChange={props.onChangeWeight} name="weight" disabled={false} />
                                    </div>
                                    <div className="p-col-6 box-cubage" >
                                        <div className="cubage-field form-field-container">
                                            <label>Total Cubagem (M³)</label>
                                            <InputNumber decimalPlaces={3} value={freightCalculationParams.cubage} onChange={props.onChangeCubage} name="cubage" disabled={false} />                                      
                                        </div>
                                        <div className="freight-calculationForm-btn-modal">
                                            <button
                                                className="lognex-btn-highlighted"
                                                onClick={props.onSetVisibleModalCalculateCubage}
                                            >...</button>
                                        </div>
                                        
                                    </div>
                                    {/* <div className="p-col-1 freight-calculationForm-btn-modal" >
                                       
                                    </div> */}
                                    <div className="p-col-6 form-field-container">
                                        <label>Distância (KM)</label>
                                        <InputText type="number" value={freightCalculationParams.distance} onChange={props.onChangeDistance} name="distance" disabled={false} />
                                    </div>
                                    {lookupVehicleTypeComponent}
                                    {lookupClientComponent}
                                    <div className="p-col-12 form-field-container" >
                                        <div className="p-grid">
                                            <div className="p-col-6 form-field-container lxDesactived" title="Em desenvolvimento">
                                                <label>Total Volumes</label>
                                                <InputText type="number" value={freightCalculationParams.volumes} onChange={props.onChangeVolumes} name="volumes" disabled={true} />
                                            </div>
                                            <div className="p-col-6 form-field-container lxDesactived" title="Em desenvolvimento">
                                                <label>Qtd. Total de itens</label>
                                                <InputText type="number" value={freightCalculationParams.quantityOfItems} onChange={props.onChangeQuantityOfItems} name="quantityOfItems" disabled={true} />
                                            </div>

                                        </div>
                                    </div>
                                    <div className="p-col-10"></div>
                                    <div className="p-col-2 form-field-container">
                                        <button
                                            title="Calcular"
                                            className="lognex-btn-highlighted"
                                            style={{ width: '100%' }}
                                            onClick={props.onCalculateFreight}>

                                            Calcular
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card>

                    <Card style={{ marginTop: "10px" }}>
                        <div className="p-grid">
                            <div className="p-col-12">

                                <h3>Simulações de frete</h3>
                                <SimpleTable
                                    records={props.tableQuotes.records}
                                    notFoundMessage="Nenhum cálculo encontrado"
                                    columns={props.tableQuotes.columns}>
                                </SimpleTable>
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
        </div>
    )
};