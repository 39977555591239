function isEmptyObject(obj) {
    if (obj) {
        for (var prop in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, prop)) {
                return false;
            }
        }
    }
    return true;
}

export function validate(record) {
    const errors = [];


    if (!record.name || record.name === '') {
        errors.push({ field: 'name', message: 'O nome do componente de frete é obrigatório!' });
    }

    if (!record.code || record.code === '') {
        errors.push({ field: 'code', message: 'A sigla do componente de frete é obrigatória!' });
    }


    if (!record.useBooleanValue &&
        !record.useMinValue &&
        !record.useMaxValue &&
        !record.useFixedValue &&
        !record.usePercentValue &&
        !record.useKilometerValue &&
        !record.useKiloValue &&
        !record.useCubageValue &&
        !record.useMultEntries &&
        !record.useList) {
        errors.push({ field: 'uses', message: 'O componente de frete deve utilizar ao menos um tipo de valor!' });
    }

    if (!record.rangeTypes || record.rangeTypes.length <= 0){
        errors.push({ field: 'rangeTypes', message: 'O componente de frete deve utilizar ao menos um tipo de faixa!' });
    }

    if (record.useList && isEmptyObject(record.presetList)) {
        errors.push({ field: 'presetList', message: 'Quando o componente de frete usa lista pré-definida a lista deve conter ao menos 1 item!' });
    }

    const result = {
        ok: errors.length === 0,
        errors,
        record
    }

    return new Promise((resolve, reject) => {
        const action = result.ok ? resolve : reject;
        return action(result);
    })
}