import React from 'react'
import { InputText } from 'primereact/inputtext';
import './InputAutoCompleteText.scss';

export class InputAutoCompleteText extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value || "",
        };
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.value != this.state.value) {
            const value = nextProps.value;
            this.setState({ value });
        }
    }

    onChangeHandler = (e) => {
        const target = e.target;
        let value = target.value;

        let tempValue = this.state.value.replace(/0*/, '')
        if (tempValue.length < 10 || value.length < 10) {
            value = ('0000000000' + value).slice(-10);
            this.setState({ value });
            e.target.value = value;
            this.props.onChange(e);
        }
    }

    render() {
        return (
            <div className="lxInputAutoCompleteText">
                <div className="p-inputgroup">
                    <InputText
                        defaultValue={this.props.value}
                        name={this.props.name}
                        disabled={this.props.disabled}
                        value={this.state.value}
                        onChange={this.onChangeHandler}
                        placeholder={this.props.placeholder}
                    />
                </div>
            </div>
        )
    }
}