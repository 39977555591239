import {types} from './report.actions';

const initialState = {    
    divergence: {
        title: "VALOR DIVERGENTE DE FRETE",
        percentageValue: "0%", 
        value: "0",
        filters: {
            carrier: {
                documentNumber: null,
                name: "TODOS",
            },
            period: {
                description:"Últimos 7 dias",
                period: "LAST_SEVEN_DAYS"
            },
        },
        lookup:{
            showCarrier: false,
            showPeriod: false,
            columnsCarrier:[
                {
                    field: "razaoSocial",
                    header: "Razão social",
                    columnKey: "razaoSocial",
                    sortable: false,
                    filter: true
                   
                  }
            ],
            columnsPeriod:[
                {
                    field: "description",
                    header: "Período",
                    columnKey: "description",
                    sortable: false,
                    filter: true
                   
                  }
            ]
        }
    }
}

export function reducerDivergence(state = initialState, action){
    let result = state;
    switch(action.type){
        case types.SET_PERCENTAGE_VALUE:
            return setPercentageValue(state, action)
        case types.SET_VALUE:
            return setValue(state, action);
        case types.SET_NAME:
            return setName(state, action);
        case types.SET_CNPJ:
            return setCNPJ(state, action);
        case types.SET_PERIOD:
            return setPeriod(state, action);
        case types.SET_SHOW_CARRIER:
            return setShowCarrier(state, action);
        case types.SET_SHOW_PERIOD:
            return setShowPeriod(state, action);   
        case types.LOADER_SHOW:
            return loaderShow(state, action);
        case types.LOADER_HIDDEN:
            return loaderHide(state, action);                   
        default:
        return result; 
    }
}

function setPercentageValue(state, action){
    const divergence = {...state.divergence, value:action.percentageValue}
    return Object.assign({}, state, {divergence});
}

function setValue(state, action){
    const divergence = {...state.divergence, value:action.value}
    return Object.assign({}, state, {divergence});
}

function setName(state, action){
    return Object.assign({}, state, { value: action.name }); 
}

function setCNPJ(state, action){
    const filters = {...state.divergence.filters,carrier:action.carrier}
    const divergence = {...state.divergence, filters}
    return Object.assign({}, state, {divergence}); 
}

function setPeriod(state, action){
    const filters = {...state.divergence.filters, period:action.period}
    const divergence = {...state.divergence, filters}
    return Object.assign({}, state, {divergence});  
}

function setShowCarrier(state, action){
    const lookup = {...state.divergence.lookup, showCarrier:action.showCarrier}
    const divergence = {...state.divergence, lookup}
    return Object.assign({}, state, {divergence}); 
}

function setShowPeriod(state, action){
    const lookup = {...state.divergence.lookup, showPeriod:action.showPeriod}
    const divergence = {...state.divergence, lookup}
    return Object.assign({}, state, {divergence}); 
}

function loaderShow(state, action){
    return Object.assign({}, state, { value: action.show }); 
}

function loaderHide(state, action){
    return Object.assign({}, state, { value: action.show }); 
}