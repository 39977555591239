import * as loaderActions from './loader.actions';

export const loaderMiddleware = store => next => action =>{
    const hasAsync =  action && action.hasOwnProperty('async');
    if(hasAsync){
        const async = action.async;
        if(async){
            store.dispatch(loaderActions.loaderShow())
        }else{
            store.dispatch(loaderActions.loaderHide());
        }
    };
    next(action);

}

