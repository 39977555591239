import React from 'react';
import HolidayListPageContainer from './HolidayListPageContainer';

export class HolidayListPage extends React.Component{
    
    render(){
      return (
        <HolidayListPageContainer {...this.props}/>
      )
    }
}