
import authentication from "../../services/authentication.service";
import history from '../../history';

export const types = {
  LOGIN_SET_CREDENTIAL: "login.setCrecential",
  LOGIN_CLEAR_CREDENTIAL: "login.setCredential",
  LOGIN_SET_ERROR_MESSAGE: "login.setErrorMessage",
  LOGIN_CLEAR_ERROR_MESSAGE: "login.clearErrorMessage",
  LOGIN_SET_TOKEN: "login.setToken",
  LOGIN_CLEAR_TOKEN: "login.clearToken",
  LOGIN_SET_LOGIN: "login.setLogin",
  LOGIN_CLEAR_LOGIN: "login.clearLogin",
  LOGIN_CLEAR_ALL: "login.clearAll",
  LOGIN_FETCH_AUTHENTICATION: "login.fetchAuthentication",
  LOGIN_FETCH_AUTHENTICATION_SUCCESS: "login.fetchAuthenticationSuccess",
  LOGIN_FETCH_AUTHENTICATION_ERROR: "login.fetchAuthenticationError",
  LOGIN_FETCH_AUTHENTICATION_2FA: "login.fetchAuthentication2FA",
  LOGIN_AUTHENTICATION_2FA_ON_CHANGE: "login.onChangeCode2FA",
  LOGIN_VALIDATE_CODE_2FA: "login.validateCode2FA",
  LOGIN_GENERATE_NEW_CODE: "login.generateNewCode"
};


export function validateCode2FA() {
  return (dispatch, getState) => {
    try {

      const credential = getState().loginState.credential;
      const code2FA = parseInt(getState().loginState.code2FA);
      
      if (!code2FA || isNaN(code2FA)) {
        const errorMessage = "Verifique se informou o código corretamente, somente numeros são permitidos";
        return dispatch(fetchAuthenticationError(errorMessage));
      } 
      const payload = { ...credential, code2FA };

      authentication.validateCode2FA(payload).then(response => {
        const { token, login, errorMessage } = response.data;
        if (errorMessage && errorMessage != "") { 
          dispatch(fetchAuthenticationError(errorMessage));
        } else {
          dispatch(fetchAuthenticationSucces({ token, login }));
        }
      }).catch(err => {
        if (err && err.response) {
          const errorMessage = "Código inválido";
          dispatch(fetchAuthenticationError(errorMessage));
        }
      });
    } catch (error) {
      const errorMessage = "Verifique se informou o código corretamente, somente numeros são permitidos";
      dispatch(fetchAuthenticationError(errorMessage));
    }
  };
}

export function generateNewCode() {
  return (dispatch, getState) => {

    const credential = getState().loginState.credential;

    authentication.generateNewCode(credential)
      .then(response => {
        const { twoFA } = response.data;
        if (twoFA) {
            dispatch(fetchAuthentication2FA(twoFA));       
        }else{
          dispatch(fetchAuthentication2FA(null));
        }
        dispatch(fetchSuccess());
      })
      .catch(err => {
        dispatch(fetchSuccess());
        if (err && err.response) {
          const errorMessage = "Login ou senha inválidos";
          dispatch(fetchAuthenticationError(errorMessage));
        }
      });
  };
}

export function onChangeCode2FA(code2FA) {
  return {
    type: types.LOGIN_AUTHENTICATION_2FA_ON_CHANGE,
    code2FA
  };
}

export function fetchAuthentication2FA(twoFA) {
  return {
    type: types.LOGIN_FETCH_AUTHENTICATION_2FA,
    twoFA
  };
}

export function loginSetCredential(credential) {
  return {
    type: types.LOGIN_SET_CREDENTIAL,
    credential
  };
}

export function loginClearCredential() {
  return {
    type: types.LOGIN_CLEAR_CREDENTIAL
  };
}

export function loginSetErrorMessage(errorMessage = "") {
  return {
    type: types.LOGIN_SET_ERROR_MESSAGE,
    errorMessage
  };
}

export function loginClearErrorMessage() {
  return {
    type: types.LOGIN_CLEAR_ERROR_MESSAGE
  };
}

export function loginSetToken(token = "") {
  return {
    type: types.LOGIN_SET_TOKEN,
    token
  };
}

export function loginClearToken() {
  return {
    type: types.LOGIN_CLEAR_TOKEN
  };
}

export function loginSetLogin(login = "") {
  return {
    type: types.LOGIN_SET_LOGIN,
    login
  };
}

export function loginClearLogin() {
  return {
    type: types.LOGIN_CLEAR_LOGIN
  };
}

export function loginClearAll() {
  return {
    type: types.LOGIN_CLEAR_All
  };
}
export function fetchAuthentication() {
  return {
    type: types.LOGIN_FETCH_AUTHENTICATION,
    async: true
  };
}

function fetchSuccess() {

  return {
    type: types.LOGIN_FETCH_AUTHENTICATION_SUCCESS,
    async: false
  };

}
export function fetchAuthenticationSucces(data) {
  const { token, login } = data;
  return dispatch => {
    dispatch(loginSetToken(token));
    dispatch(loginSetLogin(login));
    dispatch(fetchSuccess());
    history.push('/');

  };
}
export function fetchAuthenticationError(errorMessage) {
  return dispatch => {
    dispatch(loginSetErrorMessage(errorMessage));
    dispatch({
      type: types.LOGIN_FETCH_AUTHENTICATION_ERROR,
      async: false
    });
  };
}

export function authenticate() {
  return (dispatch, getState) => {
    dispatch(fetchAuthentication());
    authentication.login(getState().loginState.credential)
      .then(response => { 

        const { token, login, errorMessage, twoFA } = response.data;
        if (errorMessage && errorMessage != "" || twoFA) {
          const errorMessage = "Login ou senha inválidos";
          if (twoFA) {
            dispatch(fetchAuthentication2FA(twoFA));
            dispatch(fetchSuccess());
          } else {
            dispatch(fetchAuthenticationError(errorMessage));
          }
        } else {
          dispatch(fetchAuthenticationSucces({ token, login }));
        }
      })
      .catch(err => {
        if (err && err.response) {
          const errorMessage = "Login ou senha inválidos";
          dispatch(fetchAuthenticationError(errorMessage));
        }
      });
  };
}
