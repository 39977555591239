import React from 'react';
import { InputText } from 'primereact/inputtext';

export const TextConfigurationsComponent = (props) => {
    return (
      <div className="p-grid">
        {props.data.showHeader && (
          <div className="p-col-12">
            <p>{props.data.showHeader}</p>
            <hr></hr>
          </div>
        )}
        <div className="p-col-4">{props.data.title}</div>
        <div className="p-col-2 p-justify-center">
          <div className="field col-12 md:col-4">
            <InputText
              value={props.data.value}
              onChange={props.data.onChange}
              id={props.data.id}
              name={props.data.name}
            ></InputText>
          </div>
        </div>
      </div>
    );
} 