import React from "react";
import { InputText } from "primereact/inputtext";

export const CfopForm = (props) => {
  return (
    <div className="vehicletype-form">
      <div className="p-grid">
        <div className="p-col-6">
          <div className="p-grid">
            <div className="p-col-3 form-field-container">
              <label>Código</label>
              <InputText value={props.record.oid} name="oid" disabled={true} />
            </div>
            <div className="p-col-12 form-field-container">
              <label className="lxRequiredField">Código CFOP</label>
              <InputText value={props.record.codeCFOP} onChange={props.changeHandler} name="codeCFOP" />
            </div>
            <div className="p-col-12 form-field-container">
              <label>Descrição</label>
              <InputText value={props.record.description} onChange={props.changeHandler} name="description" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
