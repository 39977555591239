import React, { useEffect, useState } from 'react';
import Datetime from 'react-datetime';
import moment from 'moment-timezone'; 
import { Button } from 'primereact/button';
import 'react-datetime/css/react-datetime.css';
import './dateTime.scss';

const CustomDateField = ({ label, initialDate, timeZone, onDateChange }) => {
  const [selectedDate, setSelectedDate] = useState(initialDate ? moment(initialDate) : null);

  const handleDateChange = (date) => {
    // const zonedDate = moment.tz(date, timeZone).toDate();
    setSelectedDate(date);
    if (onDateChange) {
      onDateChange(date);
    }
  };

  useEffect(() => {
    if (initialDate) {
      setSelectedDate(moment(initialDate));
    }
  }, [initialDate]);

  const renderInput = (props, openCalendar) => {
    return (
      <div className="custom-date-input">
        <input
          {...props}
          readOnly
          onClick={openCalendar}
          className="datetime-picker"
        />
        <button className="btn-dt" onClick={openCalendar}>
            <i className="pi pi-calendar icon-dt" ></i>
        </button>
      </div>
    );
  };

  return (
    <div className="custom-date-field">
      {label && <label>{label}</label>}
      <Datetime
        value={selectedDate} 
        onChange={handleDateChange}
        renderInput={renderInput}
        dateFormat="DD/MM/YYYY"
        displayTimeZone={timeZone}
        inputProps={{ className: 'datetime-picker' }}
      />
    </div>
  );
};

export default CustomDateField;