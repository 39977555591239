import React from 'react'; 
import ScriptwriterListPageContainer from './ScriptwriterListPageContainer';


export class ScriptwriterListPage extends React.Component{
    
    render(){
      return (
        <ScriptwriterListPageContainer {...this.props}/>
      )
    }
}

