import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import { actions } from "../auditRecords.actions";
import * as menuActions from "../../../store/menu/menu.actions";
import { actions as carrierActions } from "../../carrier/carrier.actions";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Page } from "../../../components/page/Page";
import { AuditRecordsFilter } from "../components/filters/AuditRecordsFilter";
import {
  AppService,
  COMPANY_CHANGE_EVENT_NAME,
  BUSINESS_UNIT_CHANGE_EVENT_NAME,
} from "../../../services/app.service";
import { AuditRecordTable } from "../components/tables/AuditRecordTable";
import CustomToolbar from "../components/toolbar/CustomToolbar";
import "./AuditRecordsPageContainer.scss";
import Totalizador from "../components/indicators/Totalizador";
import UploadFileModal from "../components/modal/UploadFileModal";
import ImageModal from "../components/modal/ImageModal";
import * as utils from "../../../utils";
import ApprovedComponenteModal from "../components/modal/ApprovedComponentModal";
import { AuditRecordPendencesTable } from "../components/tables/AuditRecordPendencesTable";
import HistoryModal from "../components/modal/HistoryModal";
import LogsModal from "../components/modal/LogsModal";
import ApprovedCTEs from "../components/aproved-ctes/ApprovedCTEs";
import InfoDocument from "../components/modal/InfoDocument";

const mapStateToProps = (state) => {
  const lookupCarrierBase = {
    records: state.crudCarrierState.records,
    columns: state.crudCarrierState.columns
      .filter((col) => col.field === "oid" || col.field === "name" || col.field === "tradeName")
      .map((col) => <Column key={col.field} {...col} />),
    first: state.crudCarrierState.index,
    rows: state.crudCarrierState.max,
    totalRecords: state.crudCarrierState.count,
    sortField: state.crudCarrierState.sortField,
    order: state.crudCarrierState.sortOrder,
    rowsPerPageOptions:[20,50,100],
    editDisabled: true,
  };
  const lookupCarrier = {
    ...state.auditRecordsState.lookupCarrier,
    ...lookupCarrierBase,
  };

  const lookupCarrierUpload = {
    ...state.auditRecordsState.lookupCarrierUpload,
    ...lookupCarrierBase,
  };

  const selectedRow = state.auditRecordsState.selectedRow || [];
  // const selectedApproveComponent =
  //   state.auditRecordsState.selectedApproveComponent || {};
  const columsTemplateTreview = state.auditRecordsState.columsTemplateTreview;
  const expandedRows = state.auditRecordsState.expandedRows;
  const pendencesColumns =
    state.auditRecordsState.auditRecordPendencesColumns || [];


  // modal histories
  const historyRecords = state.auditRecordsState.historyRecords || [];
  const historyTbColumns = state.auditRecordsState.historyTbColumns;
  const dialogHistory = state.auditRecordsState.dialogHistory;


  // modal logs
  const dialogLogs = state.auditRecordsState.dialogLogs;
  const logRecords = state.auditRecordsState.logRecords;
  const logTbColumns = state.auditRecordsState.logTbColumns;



  return {
    initialDateFilter: state.auditRecordsState.initialDateFilter,
    finalDateFilter: state.auditRecordsState.finalDateFilter,
    carrierFilter: state.auditRecordsState.carrierFilter,
    lookupCarrier,
    totalizer: state.auditRecordsState.totalizer,
    // dados da tabela de records
    columns: state.auditRecordsState.columns,
    records: state.auditRecordsState.records,
    totalRecords: state.auditRecordsState.count,
    first: state.auditRecordsState.index,
    rows: state.auditRecordsState.max,
    sortField: state.auditRecordsState.sortField,
    sortOrder: state.auditRecordsState.sortOrder,
    observation: state.auditRecordsState.observation,
    oidCTE: state.auditRecordsState.oidCTE,
    componentLabel: state.auditRecordsState.componentLabel,
    approvalType: state.auditRecordsState.approvalType,
    enabledExportCSV: state.auditRecordsState.records && state.auditRecordsState.records.length > 0,
    historyRecords,
    historyTbColumns,
    dialogHistory,
    dialogLogs,
    logRecords,
    logTbColumns,
    listAprovedCTes: state.auditRecordsState.listAprovedCTes || [],
    documentView: state.auditRecordsState.documentView,
    enabledExportCSV:
      state.auditRecordsState.records &&
      state.auditRecordsState.records.length > 0,
    selectedRow,
    columsTemplateTreview,
    expandedRows,
    pendencesColumns,
    lookupCarrierUpload,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    paginate: (paginationParams) =>
      dispatch(actions.paginate(paginationParams)),
    sortOrder: (sortParams) => dispatch(actions.sortOrder(sortParams)),
    observationChangeHandler: (event) => {
      const target = event && event.target ? event.target : {};
      if (target) {
        let value = target.value;
        dispatch(actions.setObservation(value));
      } else {
        dispatch(actions.setObservation(""));
      }
    },
    setObservation: (observation) =>
      dispatch(actions.setObservation(observation)),
    setOidCTE: (oid) => dispatch(actions.setOidCTE(oid)),
    setComponentLabel: (label) => dispatch(actions.setComponentLabel(label)),
    setCurrentMenu: (menuPath) =>
      dispatch(menuActions.setCurrentRecord(menuPath)),
    initialDateChangeHandler: (e) => {
      dispatch(actions.setInitialDateFilter(e.value));
    },
    finalDateChangeHandler: (e) => {
      dispatch(actions.setFinalDateFilter(e.value));
    },
    //lookup carrier
    lookupCarrierSetVisible: (visible) =>
      dispatch(actions.setLookupCarrierVisible(visible)),
    lookupCarrierPaginate: (paginationParams) =>
      dispatch(carrierActions.paginate(paginationParams)),
    lookupCarrierSortOrder: (sortParams) =>
      dispatch(carrierActions.sortOrder(sortParams)),

    lookupCarrierLoad: () => dispatch(carrierActions.load()),

    setCarrierFilter: (carrier) => dispatch(actions.setCarrierFilter(carrier)),

    lookupCarrierApplyFilter: (filterParams) => {
      dispatch(carrierActions.setType(null));
      dispatch(carrierActions.applyFilter(filterParams));
    },

    applyFilter: (filterParams) => {
      // dispatch(actions.loadAuditRecordsWithPendencies(filterParams));
      dispatch(actions.setType(null));
      dispatch(actions.applyFilter(filterParams));
      dispatch(actions.setLoadFilters(filterParams));
    },

    loadAll: () => dispatch(actions.load()),
    // filterRecordsPendences: (filterParams) => dispatch(actions.filterRecordsPendences(filterParams)),
    counterAll: (filterParams) =>
      dispatch(actions.loadCounterAll(filterParams)),

    setSelectedRow: (row) => dispatch(actions.setSelectedRow(row)),
    setSelectedComponent: (data) =>
      dispatch(actions.setSelectedComponent(data)),
    setExpandedRows: (expandedRows) => {
      dispatch(actions.setExpandedRows(expandedRows));
    },
    approveComponent: (data) => dispatch(actions.approveComponent(data)),
    approveAllComponents: (data) =>
      dispatch(actions.approveAllComponents(data)),
    setApprovalType: (type) => dispatch(actions.setApprovalType(type)),
    setVisibleModalUpload: (visible) =>
      dispatch(actions.setVisibleModalUpload(visible)),
    downloadHandler: () => dispatch(actions.download()),
    showHistoryHandler: idCTe => {
      dispatch(actions.getHistories(idCTe));
      dispatch(actions.setDialogHistoryVisible(true));
    },
    hideHistoryHandler: () => {
      dispatch(actions.setDialogHistoryVisible(false));
    },
    showLogsHandler: idCTe => {
      dispatch(actions.getLogs(idCTe));
      dispatch(actions.setDialogLogsVisible(true));
    },
    hideLogsHandler: () => {
      dispatch(actions.setDialogLogsVisible(false));
    },
    setListAprovedCTes: (value) => dispatch(actions.setListAprovedCTes(value)),
    approvedCTEs: () => dispatch(actions.approvedCTEs()),
    reprocessCTEs: () => dispatch(actions.reprocessCTEs()),
    setDocumentView: (document) => dispatch(actions.setDocumentView(document)),
  };
};

class AuditRecordsPageContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      countRotation: 0,
      spin: false,
      currentImagePath: "",
      visibleModalApproveComponent: false,
      visibleModalImage: false,
      visibleModalInfoDoc: false,
      viewPendenceTable: true,
      viewFilter: "todos",
    };
  }

  componentWillReceiveProps(newProps) {
    if (
      (!this.props.menuItems || this.props.menuItems.length === 0) &&
      newProps.menuItems &&
      newProps.menuItems.length > 0
    ) {
      const menuPath = this.props.history.location.pathname;
      this.props.setCurrentMenu(menuPath);
    }

    if (
      (!this.props.currentMenu && newProps.currentMenu) ||
      this.props.currentMenu !== newProps.currentMenu
    ) {
      const oidMenu = newProps.currentMenu.oid;
      this.props.userLastAccess(oidMenu);
    }
  }

  componentDidMount() {
    AppService.on(COMPANY_CHANGE_EVENT_NAME, this);
    AppService.on(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);

    const menuPath = this.props.history.location.pathname;
    this.props.setCurrentMenu(menuPath);
    // this.props.loadAll();
    this.onApplyFilter();
  }
  componentWillUnmount() {
    AppService.off(COMPANY_CHANGE_EVENT_NAME, this);
    AppService.off(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
  }

  onReceiveAppNotifications = (eventName, params) => {
    if (
      eventName === COMPANY_CHANGE_EVENT_NAME ||
      eventName === BUSINESS_UNIT_CHANGE_EVENT_NAME
    ) {
      this.onApplyFilter();
    }
  };

  lookupCarrierOnComplete = (e) => {
    //this.props.lookupCarrierApplyFilter([])
  };

  lookupCarrierOnInputFilter = (e) => {
    const value = e.value;
    if (typeof value === "string") {
      const filters = [
        {
          campo: "tradeName",
          valor: e.value,
          tipoFiltro: "CONTEM",
        },
      ];
      this.props.setCarrierFilter({ tradeName: value  });
      this.props.lookupCarrierApplyFilter(filters);
    } else {
      this.props.setCarrierFilter(value);
    }
  };

  lookupCarrierOnClick = (e) => {
    this.props.lookupCarrierApplyFilter([]);
    this.props.lookupCarrierLoad();
    this.props.lookupCarrierSetVisible(true);
  };
  lookupCarrierOnHide = (e) => {
    this.props.lookupCarrierApplyFilter([]);
    this.props.lookupCarrierSetVisible(false);
  };
  lookupCarrierOnConfirm = (e) => {
    this.props.lookupCarrierApplyFilter([]);
    this.props.setCarrierFilter(e.selection);
    this.props.lookupCarrierSetVisible(false);
  };
  lookupCarrierOnCancel = (e) => {
    this.props.lookupCarrierApplyFilter([]);
    this.props.setCarrierFilter(null);
    this.props.lookupCarrierSetVisible(false);
  };
  lookupCarrierOnPage = (e) => {
    this.props.lookupCarrierPaginate({
      max: e.rows,
      index: e.first,
    });
  };
  lookupCarrierOnSort = (e) => {
    this.props.lookupCarrierSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField,
    });
  };
  lookupCarrierOnFilter = (e) => {
    const filters = [];
    const keys = Object.keys(e.filters) || [];
    keys.forEach((key) => {
      filters.push({
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: "CONTEM",
      });
    });
    this.props.lookupCarrierApplyFilter(filters);
  };

  getCarrierFilter = () => {
    const documentNumber =this.props.carrierFilter
        ? this.props.carrierFilter.documentNumber
        : "";
    return documentNumber;
  };

  onApplyFilter = (e, filtersParams = []) => {
    const stateViewFilter = this.state.viewFilter;
    const initialDateFilter = this.props.initialDateFilter;
    const finalDateFilter = this.props.finalDateFilter;
    const carrierDocumentNumber = this.getCarrierFilter() || "";

    const filtersParamsAll = [...filtersParams];
    if (initialDateFilter && finalDateFilter) {
      let createAt = {
        campo: "createdAt",
        campoComplementar: "createdAt",
        valor: initialDateFilter,
        valorComplementar: finalDateFilter,
        tipoFiltro: "ENTRE",
      };
      filtersParams.push(createAt);
      filtersParamsAll.push(createAt);
    }
    if (carrierDocumentNumber != "") {
      let carrierNumber = {
        campo: "carrierNumber",
        valor: carrierDocumentNumber,
        tipoFiltro: "IGUAL_A",
      };
      filtersParams.push(carrierNumber);
      filtersParamsAll.push(carrierNumber);
    }

    if (stateViewFilter === "pendencies") {
      filtersParams.push({
        campo: "havePendencies",
        valor: true,
        tipoFiltro: "IGUAL_A",
      });
    } else if (stateViewFilter === "divergencies") {
      filtersParams.push({
        campo: "haveDivergence",
        valor: true,
        tipoFiltro: "IGUAL_A",
      });
    } else if (stateViewFilter === "withoutReceipt") {
      filtersParams.push({
        campo: "withoutReceipt",
        valor: false,
        tipoFiltro: "IGUAL_A",
      });
    } else if (stateViewFilter === "finalized") {
      filtersParams.push({
        campo: "finalized",
        valor: true,
        tipoFiltro: "IGUAL_A",
      });
    }

    this.props.counterAll(filtersParamsAll);

    this.props.applyFilter(filtersParams);
  };

  onPage = (e) => {
    this.props.paginate({
      max: e.rows,
      index: e.first,
    });
  };

  onSort = (e) => {
    this.props.sortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField,
    });
  };

  onFilter = (e) => {
    const filtersParams = [];
    const keys = Object.keys(e.filters) || [];
    keys.forEach((key) => {
      if (key === "cargoShipmentId") {
        filtersParams.push({
          campo: key,
          valor: parseInt(e.filters[key].value),
          tipoFiltro: "IGUAL_A",
        });
      } else {
        filtersParams.push({
          campo: key,
          valor: e.filters[key].value,
          tipoFiltro: "CONTEM",
        });
      }
    });
    console.log("filtersParams => ",filtersParams )
    this.onApplyFilter(e, filtersParams);
  };

  setVisibleModalApproveComponent = (visible) => {
    this.setState({
      visibleModalApproveComponent: visible,
    });
    if (!visible) {
      this.props.setSelectedRow([]);
    }
  };

  setCurrentImage = (image) => {
    const path = `${image}`;//data:image/jpeg;base64,
    const spin = !!this.state.spin;
    image = (
      <img
        className={utils.classNames({ "rotate-image": true, spin: spin })}
        src={path}
      />
    );
    this.setState({
      spin,
      currentImagePath: path,
      image,
    });
  };

  setVisibleModalImage = (visible) => {
    this.setState({
      visibleModalImage: visible,
    });
  };
  setVisibleModalInfoDoc = (visible) => {
    this.setState({
      visibleModalInfoDoc: visible,
    });
  };

  setViewPendenceTable = (visible) => {
    this.setState({
      viewPendenceTable: visible,
    });
  };
  setViewFilter = (view) => {
    this.setState({
      viewFilter: view,
    });
  };

  onSelectedRow = (row) => {
    this.props.setSelectedRow(row);
  };

  setExpandedRowsByRowClick = (e) => {

    if (e.data && e.data.length > 1) {
      this.props.setExpandedRows([e.data[1]]);
    } else if (e.data && e.data.length == 1) {
      this.props.setExpandedRows([e.data[0]]);
    } else {
      this.props.setExpandedRows([e.data]);
    }
  };
  setExpandedRowsClick = (e) => {

    this.props.setSelectedRow(e.data);
    if (e.data && e.data.length > 1) {
      this.props.setExpandedRows([e.data[1]]);
    } else {
      this.props.setExpandedRows([e.data]);
    }
  };

  onObservationChangeHandler = (e) => {
    this.props.observationChangeHandler(e);
  };

  onSetApprovalType = (type) => {
    this.props.setApprovalType(type);
  };

  onShowApproveComponent = (oidCTE, label = "", tipo) => {
    this.onSetApprovalType(tipo);
    this.props.setOidCTE(oidCTE);
    this.props.setComponentLabel(label);
    this.setVisibleModalApproveComponent(true);
  };

  onApprove = () => {
    console.log("this.props.approvalType => ", this.props.approvalType);
    this.setVisibleModalApproveComponent(false);
    if (this.props.approvalType === "") {
      // this.props.approveAllComponents();
      // APROVAÇÃO POR CTE
      this.props.approvedCTEs();
    } else if (this.props.approvalType === "SINGLE") {
      // APROVAR COMPONENTES INDIVIDUALMENTE
      this.props.approveComponent();
    }
  };

  onApprovedCTEs = () => {
    this.setVisibleModalApproveComponent(true);
  }
  onReprocessCTEs = () => {
    this.props.reprocessCTEs();
  }

  onDownload = (e) => {
    this.props.downloadHandler();
  };

  changeTransiction = () => {
    let count = this.state.countRotation;
    let cssClassName = "rotate-image";
    if (count == 0) {
      cssClassName = cssClassName + " spin";
      count++;
    } else if (count == 1) {
      cssClassName = cssClassName + " spin180";
      count++;
    } else if (count == 2) {
      cssClassName = cssClassName + " spin360";
      count = 0;
    }

    this.setState({ countRotation: count });

    this.setState((prevState) => {
      const spin = !prevState.spin;
      return {
        spin,
        image: (
          <img className={cssClassName} src={prevState.currentImagePath} />
        ),
      };
    });
  };


  onSelectionChangeHandler = (e) => {
    e.originalEvent.stopPropagation();
    e.originalEvent.preventDefault();
    if (Array.isArray(e.value)){
      this.props.setListAprovedCTes(e.value);
    } else {
      this.props.setListAprovedCTes([e.value]);
    }
  }
  onClickShowModalInfoDoc = (e) => {
    e.stopPropagation();
    e.preventDefault();
    this.setVisibleModalInfoDoc(true);
  }


  onSetDocumentView = (document, type="") => {
    const doc = {...document, type}    
    this.props.setDocumentView(doc);
  }

  render() {
    const hasPendencies = (pendencies, document) => {
      if (pendencies && pendencies.length > 0) {
        for (let i = 0; i < pendencies.length; i++) {
          if (
            pendencies[i].document === document &&
            pendencies[i].eventRecord.active
          ) {
            return true;
          }
        }
      }
      return false;
    };

    const invoiceTemplate = (rowData, column) => {
      let invoiceNumber = rowData.invoice.number;
      if (invoiceNumber === "") {
        if (hasPendencies(rowData.pendencies, "INVOICE")) {
          const pendency = rowData.pendencies.find(
            (pendency) =>
              pendency.document === "INVOICE" && pendency.eventRecord.active
          );
          invoiceNumber = pendency != null ? pendency.number : "";
        }
      }
      const pendencyInvoiceIcon = !hasPendencies(
        rowData.pendencies,
        "INVOICE"
      ) ? (
        ""
      ) : (
        <i
          title={`Fatura pendente`}
          className="lxExclamationMark pi pi-exclamation-triangle"
        ></i>
      );

      let result = "";
      if (invoiceNumber) {
        if(!pendencyInvoiceIcon){
          result = (
            <div>
              <a href="" onClick={(e)=>{
                this.onSetDocumentView(rowData.invoice, "invoice");
                this.onClickShowModalInfoDoc(e);
              }}>
                {invoiceNumber}
              </a> {pendencyInvoiceIcon}
            </div>
          );
        } else {
          result = (
            <div>
             {invoiceNumber}{pendencyInvoiceIcon}
            </div>
          );
        }        
      } else if (rowData.useAuditByInvoice) {
        result = (
          <i
            title={`Fatura pendente`}
            className="lxExclamationMark pi pi-exclamation-triangle"
          ></i>
        );
      }
      const template = (
        <div style={{ textAlign: "center" }}>
          <div className="lxNumberCte">{result}</div>
        </div>
      );

      return template;
    };
    const cargoShipmentTemplate = (rowData, column) => {
      let cargoShipmentId = rowData.cargoShipmentId;
      let id = "";
      if (cargoShipmentId > 0) {
        id = <span>{cargoShipmentId}</span>;
      }
      const template = (
        <div style={{ textAlign: "center" }}>
          <div className="lxNumberCargoShpment">
            <a href="" onClick={(e)=>{
              this.onSetDocumentView(rowData.cargoShipment, "cargoShipment");
              this.onClickShowModalInfoDoc(e);
            }}>
              {id}
            </a>
          </div>
        </div>
      );

      return template;
    };

    const cteTemplate = (rowData, column) => {
      let numberCTE = rowData.cte.number || "";
      if (numberCTE === "") {
        if (hasPendencies(rowData.pendencies, "CTE")) {
          const pendency = rowData.pendencies.find(
            (pendency) =>
              pendency.document === "CTE" && pendency.eventRecord.active
          );
          numberCTE = pendency != null ? pendency.number : "";
        }
      }
      const pendencyCTeIcon = !hasPendencies(rowData.pendencies, "CTE") ? (
        ""
      ) : (
        <i
          title={`CTE pendente`}
          className="lxExclamationMark pi pi-exclamation-triangle"
        ></i>
      );

      let result = "";
      if (numberCTE) {
        if (!pendencyCTeIcon){
          result = (
            <div>
              <a href="" onClick={(e)=>{
                this.onSetDocumentView(rowData.cte, "cte");
                this.onClickShowModalInfoDoc(e);
              }}>
                {numberCTE}
              </a> {pendencyCTeIcon}
            </div>
            );
        } else {
          result = (
            <div>              
              {numberCTE}{pendencyCTeIcon}
            </div>
            );
        }
      } else {
        result = (
          <i
            title={`CTE pendente`}
            className="lxExclamationMark pi pi-exclamation-triangle"
          ></i>
        );
      }
      const template = (
        <div style={{ textAlign: "center" }}>
          <div className="lxNumberCte">{result}</div>
        </div>
      );

      return template;
    };

    const nfeTemplate = (rowData, column) => {
      let numberNFE = rowData.nfe.number;
      if (hasPendencies(rowData.pendencies, "NFE")) {
        const pendency = rowData.pendencies.find(
          (pendency) =>
            pendency.document === "NFE" && pendency.eventRecord.active
        );
        numberNFE = pendency != null ? pendency.number : "";
      }
      const pendencyNFEIcon = !hasPendencies(rowData.pendencies, "NFE") ? (
        ""
      ) : (
        <i
          title={`NFE pendente`}
          className="lxExclamationMark pi pi-exclamation-triangle"
        ></i>
      );

      let result = "";
      if (numberNFE) {
        if (!pendencyNFEIcon){
          result = (
            <div>
              <a href="" onClick={(e)=>{
                this.onSetDocumentView(rowData.nfe, "nfe")
                this.onClickShowModalInfoDoc(e)
              }}>
                {numberNFE}
              </a> {pendencyNFEIcon}
            </div>
          );
        } else {
          result = (
            <div>
              {numberNFE}{pendencyNFEIcon}             
            </div>
          );
        }
      } else {
        result = (
          <i
            title={`NFE pendente`}
            className="lxExclamationMark pi pi-exclamation-triangle"
          ></i>
        );
      }

      const template = (
        <div style={{ textAlign: "center" }}>
          <div className="lxNumberNFE">{result}</div>
        </div>
      );
      return template;
    };

    const auditTemplate = (rowData, column) => {
      
      /*
      verificar se o valor da auditoria(audit.totalValue) está diferente do valor do CTE (cte.totalValue) 
      */
      const currencyTotalRealized = utils.numberToCurrency(
        rowData.audit.totalValue,
        "REAL"
      );
      const currencyCTeTotalValue = utils.numberToCurrency(
        rowData.cte.totalValue,
        "REAL"
      );
      const title =
        `A soma dos componentes(${currencyCTeTotalValue})` +
        `difere do valor do CTe(${currencyTotalRealized})`;
      
        const pendinIcon = currencyCTeTotalValue === currencyTotalRealized ? (
            ""
          ) : (
              <i
                style={{ verticalAlign: "-10%" }}
                title={title}
                className="pi pi-exclamation-triangle"
              ></i>
          );

      return (
        <div style={{ textAlign: "center" }}>
          <div className="lxNumberAudit">
            {utils.numberToCurrency(rowData.audit.totalValue, "REAL")} {pendinIcon}{" "} 
          </div>
        </div>
      );
    };
    const quoteTemplate = (rowData, column) => {
      const template = (
        <div style={{ textAlign: "center" }}>
          <div className="lxNumberAudit">
            {utils.numberToCurrency(rowData.audit.totalQuoted, "REAL")}
          </div>
        </div>
      );
      return template;
    };

    const actionTemplate = (rowData, column) => {
      let approveAllDisabled = !rowData.isDivergent;
      const oidCTE = rowData.cte && rowData.cte.oid ? rowData.cte.oid : 0;

      const disableUpload = !(
        !rowData.hasReceipt &&
        rowData.cargoShipmentId > 0 &&
        rowData.orderCode !== ""
      );

      const body = (
        <div className="p-grid">
          <div className="p-col-12 lxActionButtons">
           

            {this.state.viewFilter &&
              this.state.viewFilter === "withoutReceipt" && (
                <button
                  className="lognex-btn-highlighted"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    this.onSelectedRow(rowData);
                    this.props.setVisibleModalUpload(true);
                  }}
                  disabled={disableUpload}
                  title={"Enviar arquivo"}
                >
                  <i className="pi pi-upload"></i>
                </button>
              )}
          </div>
        </div>
      );
      return body;
    };

    const imageTemplate = (rowData, column) => {
      const image = rowData.image;
      return (
        <div
          className="p-col-12 lxActionButtons"
          style={{ textAlign: "center" }}
        >
          <button
            className="lognex-btn-highlighted"
            onClick={(e) => {
              this.setCurrentImage(image);
              this.setVisibleModalImage(true);
            }}
            title={"Ver comprovante de entrega"}
            disabled={!image}
          >
            <i className="pi pi-image"></i>
          </button>
        </div>
      );
    };


    const auditRecordColumns = this.props.columns
      .filter((col) => col.field != "oid")
      .filter((col) => {
        // esconder as colunas (actions e image) de acordo com o botão selecinado
        if ( this.state.viewFilter === "divergencies" ) {
          return col.field !== "actions" && col.field !== "image";
        } else if (this.state.viewFilter === "finalized") {
          return col.field !== "actions";
        } else if (this.state.viewFilter === "withoutReceipt"){
          return col.field !== "image";
        } else {
          return col;
        }
      })
      .map((col) => {
        if (col.columnKey === "cte.number") {
          return <Column {...col} key={col.columnKey} body={cteTemplate} />;
        } else if (col.columnKey === "nfe.number") {
          return <Column {...col} key={col.columnKey} body={nfeTemplate} />;
        } else if (col.columnKey === "invoice.number") {
          return <Column {...col} key={col.columnKey} body={invoiceTemplate} />;
        } else if (col.columnKey === "audit.totalValue") {
          return <Column {...col} key={col.columnKey} body={auditTemplate} />;
        } else if (col.columnKey === "audit.totalQuoted") {
          return <Column {...col} key={col.columnKey} body={quoteTemplate} />;
        } else if (col.columnKey === "cargoShipmentId") {
          return (
            <Column {...col} key={col.columnKey} body={cargoShipmentTemplate} />
          );
        } else if (col.columnKey === "image") {
          return <Column {...col} key={col.columnKey} body={imageTemplate} />;
        } else if (col.columnKey === "actions") {
          return <Column {...col} key={col.columnKey} body={actionTemplate} />;
        }

        return (
          <Column
            style={{ textAlign: "center" }}
            {...col}
            key={col.field}
            field={col.field}
            header={col.header}
          />
        );
      });

    const auditRecordPendencesColumns = this.props.pendencesColumns
      .filter((col) => col.field != "oid")
      .map((col) => {
        if (col.columnKey === "nfe.number") {
          return <Column {...col} key={col.columnKey} body={nfeTemplate} />;
        } else if (col.columnKey === "cte.number") {
          return <Column {...col} key={col.columnKey} body={cteTemplate} />;
        } else if (col.columnKey === "invoice.number") {
          return <Column {...col} key={col.columnKey} body={invoiceTemplate} />;
        }else if (col.columnKey === "cargoShipmentId") {
          return <Column {...col} key={col.columnKey} body={cargoShipmentTemplate} />;
        }else if (col.columnKey === "actions") {
          return <Column {...col} key={col.columnKey} body={actionTemplate} />;
        }

        return (
          <Column
            style={{ textAlign: "center" }}
            {...col}
            key={col.field}
            field={col.field}
            header={col.header}
          />
        );
      });

    const header = (
      <div>
        <h1>Auditoria</h1>

        <hr className="lxSplitter"></hr>
        <div className="p-grid">
          <div className="p-col-10"></div>
        </div>
        <div className={`p-col-11 `}>
          <CustomToolbar
            viewFilter={this.state.viewFilter}
            totalizer={this.props.totalizer}
            onClick={(e) => onSelectPanel(e)}
          />

          <AuditRecordsFilter
            carrierFilter={this.props.carrierFilter}
            initialDateFilter={this.props.initialDateFilter}
            finalDateFilter={this.props.finalDateFilter}
            onInitialDateChangeHandler={this.props.initialDateChangeHandler}
            onFinalDateChangeHandler={this.props.finalDateChangeHandler}
            lookupCarrier={this.props.lookupCarrier}
            lookupCarrierOnComplete={this.lookupCarrierOnComplete}
            lookupCarrierOnInputFilter={this.lookupCarrierOnInputFilter}
            lookupCarrierOnEdit={this.lookupCarrierOnEdit}
            lookupCarrierOnClick={this.lookupCarrierOnClick}
            lookupCarrierOnConfirm={this.lookupCarrierOnConfirm}
            lookupCarrierOnCancel={this.lookupCarrierOnCancel}
            lookupCarrierOnPage={this.lookupCarrierOnPage}
            lookupCarrierOnFilter={this.lookupCarrierOnFilter}
            lookupCarrierOnSort={this.lookupCarrierOnSort}
            lookupCarrierOnHide={this.lookupCarrierOnHide}
            applyFilter={this.onApplyFilter}
            onDownloadCSV={this.onDownload}
            enabledExportCSV={this.props.enabledExportCSV}
          />
        </div>
        <div className="p-col-1"></div>
      </div>
    );

    const onFilterAllRecords = (e) => {
      this.setViewFilter("todos");
      setTimeout(() => {
        this.onApplyFilter(e);
      }, 50);
    };
    const onFilterRecordsWithPendencies = (e) => {
      this.setViewFilter("pendencies");
      setTimeout(() => {
        this.onApplyFilter(e);
      }, 50);
    };
    const onFilterRecordsWithDivergences = (e) => {
      this.setViewFilter("divergencies");
      setTimeout(() => {
        this.onApplyFilter(e);
      }, 50);
    };
    const onFilterRecordsWithoutProofDelivery = (e) => {
      this.setViewFilter("withoutReceipt");
      setTimeout(() => {
        this.onApplyFilter(e);
      }, 50);
    };
    const onFilterRecordsFinished = (e) => {
      this.setViewFilter("finalized");
      setTimeout(() => {
        this.onApplyFilter(e);
      }, 50);
    };

    const onSelectPanel = (event) => {
      const result = event || "";

      if (result === "todos") {
        this.setViewPendenceTable(true);
        onFilterAllRecords(event);
      } else if (result === "pendencies") {
        this.setViewPendenceTable(true);
        onFilterRecordsWithPendencies(event);
      } else if (result === "divergencies") {
        this.setViewPendenceTable(false);
        onFilterRecordsWithDivergences(event);
      } else if (result === "withoutReceipt") {
        this.setViewPendenceTable(false);
        onFilterRecordsWithoutProofDelivery(event);
      } else if (result === "finalized") {
        this.setViewPendenceTable(false);
        onFilterRecordsFinished(event);
      }
    };

    const inconsistenceClass = (rowData) => {
      const quotedValue = utils.numberToCurrency(rowData.quotedValue, "REAL");
      const realizedValue = utils.numberToCurrency(
        rowData.realizedValue,
        "REAL"
      );
      const status = rowData.inconsistency.status;
      if (quotedValue !== realizedValue && status === "OPEN") {
        return "newauditinconsistency_open";
      }
      return "";
    };

    const currenceTemplate = (rowData, column) => {
      return (
        <div className={`lxNumberAudit ${inconsistenceClass(rowData)}`}>
          <span>{utils.numberToCurrency(rowData[column.field], "REAL")}</span>
        </div>
      );
    };
    const quotedTemplate = (rowData, column) => {
      return (
        <div className="quoted_value">
          {utils.numberToCurrency(rowData[column.field], "REAL")}
        </div>
      );
    };

    const centeredTemplate = (rowData, column) => {
      const value = utils.getFieldValueFromStringRecursively(
        rowData,
        column.field
      );
      return (
        <div style={{ textAlign: "center", color: "#333333" }}>{value}</div>
      );
    };

    const dateHourTemplate = (rowData, column) => {
      const date = rowData[column.field];
      const formattedDate = moment(date).format("DD/MM/YYYY HH:mm:ss");
      return (
        <div style={{ textAlign: "center", color: "#333333" }}>
          {formattedDate}
        </div>
      );
    };

    const historyTbColumns = this.props.historyTbColumns.map(col => {
      if (col.columnKey === "date") {
        return (
          <Column
            {...col}
            key={col.columnKey}
            style={{ width: "20%" }}
            body={dateHourTemplate}
          />
        );
      } else if (col.columnKey === "user") {
        return (
          <Column
            {...col}
            key={col.columnKey}
            style={{ width: "20%" }}
            body={centeredTemplate}
          />
        );
      }
      return <Column {...col} key={col.field} />;
    });

    const logTbColumns = this.props.logTbColumns.map(col => {
      const { columnKey } = col;
      return (
        <Column
          {...col}
          style={{ width: columnKey === "errorCode" ? "25%" : "75%" }}
          key={col.columnKey}
          body={centeredTemplate}
        />
      );
    });
    

    const templateExpandedRow = (rowData, e) => {
      const oidCTE = rowData.cte && rowData.cte.oid;
      const records = [];
      try {
        rowData.audit &&
          rowData.audit.components.length > 0 &&
          rowData.audit.components.forEach((component) => {
            records.push({
              ...component,
            });
          });
      } catch (error) {
        console.log("Erro templateExpandedRow=", error);
      }

      const componentActionsTemplate = (rowData, column) => {
        const quotedValue = utils.numberToCurrency(rowData.quotedValue, "REAL");
        const realizedValue = utils.numberToCurrency(
          rowData.realizedValue,
          "REAL"
        );

        const btnApproveDisabled = !(
          quotedValue !== realizedValue &&
          rowData.inconsistency.status === "OPEN"
        );

        const label = rowData ? rowData.name : "";
        const body = (
          <div className="p-grid">
            <div className="p-col-12 lxActionButtons">
              <button
                className="lognex-btn-highlighted"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.onShowApproveComponent(oidCTE, label, "SINGLE");
                }}
                disabled={btnApproveDisabled}
                title={"Aprovar componente"}
              >
                <i className="pi pi-thumbs-up"></i>
              </button>
            </div>
          </div>
        );
        return body;
      };

      let columnsTreeview = this.props.columsTemplateTreview
        .filter((col) => {
          //mostrar a coluna de Ação apenas quando estiver clicado o botão de divergente
          if (this.state.viewFilter !== "divergencies") {
            return col.field !== "actions";
          } else {
            return col;
          }
        })
        .map((col) => {
          if (col.columnKey === "quotedValue") {
            return (
              <Column {...col} key={col.columnKey} body={quotedTemplate} />
            );
          } else if (col.columnKey === "realizedValue") {
            return (
              <Column {...col} key={col.columnKey} body={currenceTemplate} />
            );
          }

          if (col.columnKey === "actions") {
            return (
              <Column
                {...col}
                key={col.columnKey}
                body={componentActionsTemplate}
              />
            );
          }

          return <Column {...col} key={col.field} style={{ padding: "3px" }} />;
        });

      
     

      return (
        <div className="lxTableNewAudit">
          <div>
            <DataTable
              header="Auditoria"
              selectionMode="single"
              value={records}
              className="lxTableAuditDetail"
              emptyMessage="Não foram encontrados registros"
            >
              {columnsTreeview}
            </DataTable>
          </div>
          <div className="p-grid">
            <div className="p-col-1">
              <button
                  className="lognex-btn-highlighted"
                  onClick={() => this.props.showHistoryHandler(rowData.cte.oid)}
              >
                Histórico
              </button>
            </div>
            <div className="p-col-1">
              <button
                  className="lognex-btn-highlighted"
                  onClick={() => this.props.showLogsHandler(rowData.cte.oid)}
              >
                Logs
              </button>
            </div>  
          </div>
        </div>
      );
    };

    let totalOrcado =
      this.props.records &&
      this.props.records
        .filter((record) => record.isDivergent && record.audit)
        .map((record) => record.audit.totalQuoted)
        .reduce((prev, curr) => prev + curr, 0);

    let totalAuditado =
      this.props.records &&
      this.props.records
        .filter((record) => record.isDivergent && record.audit)
        .map((record) => record.audit.totalValue)
        .reduce((prev, curr) => prev + curr, 0);

    let totalDivergency = totalOrcado - totalAuditado;

    const content = (
      <div className="auditrecord_table">
        <div style={{ position: "relative" }}>
         
        </div>
        {this.state.viewPendenceTable && (
          <AuditRecordPendencesTable
            records={this.props.records}
            auditRecordColumns={auditRecordPendencesColumns}
            emptyMessage="Nenhum registro encontrado"
            onFilter={this.onFilter}
            onSort={this.onSort}
            onPage={this.onPage}
            totalRecords={this.props.totalRecords}
            sortField={this.props.sortField}
            first={this.props.first}
            rows={this.props.rows}
          />
        )}

        {!this.state.viewPendenceTable && (
          <div>
            
              <div className="p-grid">
                <div className="p-col-3">
                  {this.state.viewFilter === "divergencies" && (
                    <ApprovedCTEs
                      onApprove={this.onApprovedCTEs}
                      onReprocess={this.onReprocessCTEs}
                      visible={this.props.listAprovedCTes.length > 0 }
                    ></ApprovedCTEs>
                  )}
                </div>
                <div className="p-col-9 totalizator-right">
                  <Totalizador
                    totalOrcado={Number(totalOrcado)}
                    totalAuditado={Number(totalAuditado)}
                    totalDivergency={Number(totalDivergency)}
                  />
                </div>
              </div>
            
            <div>  
              <AuditRecordTable
                records={this.props.records}
                auditRecordColumns={auditRecordColumns}
                emptyMessage="Nenhum registro encontrado"
                onFilter={this.onFilter}
                onSort={this.onSort}
                onPage={this.onPage}
                totalRecords={this.props.totalRecords}
                sortField={this.props.sortField}
                first={this.props.first}
                rows={this.props.rows}
                selection={this.props.listAprovedCTes}
                onSelectionChange={this.onSelectionChangeHandler}
                rowExpandButtonOnClick={this.setExpandedRowsByRowClick}
                expandedRows={this.props.expandedRows}
                templateExpandedRow={templateExpandedRow}
                viewFilter={this.state.viewFilter}
                rowToggleHandler={this.setExpandedRowsClick}
              />
            </div>
          </div>
        )}

        {/* modal de upoad de arquivo */}

        <UploadFileModal />

        {/* modal de aprovação de componentes */}
        {this.state.visibleModalApproveComponent && (
          <ApprovedComponenteModal
            visible={this.state.visibleModalApproveComponent}
            onHide={(e) => {
              this.onSetApprovalType("");
              // this.onObservationChangeHandler(e);
              this.props.setObservation("");
              this.setVisibleModalApproveComponent(false);
            }}
            data={this.props.expandedRows}
            observation={this.props.observation}
            onChange={this.onObservationChangeHandler}
            onClick={this.onApprove}
          ></ApprovedComponenteModal>
        )}

        {/* modal da imagem */}
        {this.state.visibleModalImage && (
          <ImageModal
            title="Comprovante de entrega"
            visible={this.state.visibleModalImage}
            image={this.state.image}
            onHide={(e) => this.setVisibleModalImage(false)}
            changeTransiction={this.changeTransiction}
          ></ImageModal>
        )}

        { /* modal de history */}
        {this.props.dialogHistory.visible && (
          <HistoryModal
            header="Histórico"
            visible={this.props.dialogHistory.visible}
            modal={this.props.dialogHistory.modal}
            hideHistoryHandler={this.props.hideHistoryHandler}
            historyRecords={this.props.historyRecords}
            historyTbColumns={historyTbColumns}
          ></HistoryModal>
        )}

        { /* modal de Logs */}
        {this.props.dialogLogs.visible && (
          <LogsModal
            header="Logs"
            visible={this.props.dialogLogs.visible}
            modal={this.props.dialogLogs.modal}
            hideLogsHandler={this.props.hideLogsHandler}
            logRecords={this.props.logRecords}
            logTbColumns={logTbColumns}
          ></LogsModal>
        )}

        {/* modal de informações de documentos */}
        {this.state.visibleModalInfoDoc && (
          <InfoDocument
            title="Dados do Documento"
            document={this.props.documentView}
            visible={this.state.visibleModalInfoDoc}
            onHide={(e) => {
              this.onSetDocumentView({})
              this.setVisibleModalInfoDoc(false)
            }}
          ></InfoDocument>
        )}
      </div>
     
    );
    return <Page header={header} content={content} />;
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuditRecordsPageContainer);
