import React from 'react';
import Chart from 'chart.js';
import moment from 'moment';
import * as utils from '../../../utils';


Chart.defaults.global.defaultFontFamily = "Arial, sans-serif";

class LineChart extends React.Component {
    constructor(props) {
        super(props);
        this.chartRef = React.createRef();
    }

    componentDidUpdate() {
        const labels = this.props.data.descriptionPoints;
        const datasets = this.props.data.lineValues.map(lv => {
            return {
                data: lv.values,
                fill: 'none',
                label: lv.title,
                backgroundColor: lv.color,
                pointRadius: 2,
                borderColor: lv.color,
                borderWidth: 1,
                lineTension: 0
            }
        })
        if (this.props.data.currencyX){
            this.myChart.options.tooltips = {
                callbacks: {
                    title:function(tooltipItem, data) {
                        const date = moment(tooltipItem[0].label).format('DD/MM/YYYY')
                        return date;
                    },
                    label: function(tooltipItem, data) {
                        
                        return utils.numberToCurrency(parseFloat(tooltipItem.value),"REAL");
                    }
                }
            };
        }
        this.myChart.data.labels = labels;
        this.myChart.data.datasets = datasets;
        this.myChart.update();
       
    }
    componentDidMount() {
        const labels = this.props.data.descriptionPoints;
        const options =  {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                xAxes: [
                    {
                        type: 'time',
                        time: {
                            unit: 'day'
                        }
                    }
                ],
                yAxes: [
                    {
                        ticks: {
                            min: 0
                        }
                    }
                ]
            }
        }        
        if (this.props.data.currencyX){
            options.tooltips = {
                callbacks: {
                    label: function(tooltipItem, data) {
                        return utils.numberToCurrency(data,"REAL");
                    }
                }
            };
        }
        const datasets = this.props.data.lineValues.map(lv => {
            return {
                data: lv.values,
                fill: 'none',
                label: lv.title,
                backgroundColor: lv.color,
                pointRadius: 2,
                borderColor: lv.color,
                borderWidth: 1,
                lineTension: 0
            }
        })

        this.myChart = new Chart(this.chartRef.current, {
            type: 'line',
            options,
            data: {
                labels,
                datasets
            }
        });

    }

    render() {
        return <canvas ref={this.chartRef} style={{width:"100%!important"}}/>
    }
}

export default LineChart