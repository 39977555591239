import api from '../../../services/api.service';
import * as utils from '../../../utils';
export const types = {
    SET_VALUE: 'RelationWeightByFreightReportSetValue',
    SET_CNPJ: 'RelationWeightByFreightReportSetCNPJ',
    SET_NAME: 'RelationWeightByFreightReportSetName',
    SET_PERIOD: 'RelationWeightByFreightReportSetPeriod',
    SET_SHOW_CARRIER: 'RelationWeightByFreightReportetSethowCarrier',
    SET_SHOW_PERIOD: 'RelationWeightByFreightReportetSethowPeriod',
    LOADER_SHOW: 'loaderShow',
    LOADER_HIDDEN: 'loaderHide'
}

export function setValue(value){
    return {
        type: types.SET_VALUE,
        value,
    }
}

export function setCNPJ(carrier){
    return {
        type: types.SET_CNPJ,
        carrier,
    }
}

export function setName(name){
    return {
        type: types.SET_NAME,
        name,
    }
}

export function setPeriod(period){
    return {
        type: types.SET_PERIOD,
        period,
    }
}

export function setShowCarrier(showCarrier){
    return {
        type: types.SET_SHOW_CARRIER,
        showCarrier,
    }
}

export function setShowPeriod(showPeriod){
    return {
        type: types.SET_SHOW_PERIOD,
        showPeriod,
    }
}

export function loaderShow(){
    return {
        type: types.LOADER_SHOW,
        async: true,
    }
}

export function loaderHide(){
    return {
        type: types.LOADER_HIDDEN,
        async: false,
    }
}

export function load(){
    return (dispatch, getState)=>{
        const state = getState().relationWeightByFreightState.weightbyfreight;
        const filter = {
            carrierDocument:state.filters.carrier.documentNumber || "",
            period: state.filters.period.period || "CURRENT_MONTH"
        }
        const URL = `/api/v1/lognex/reports/indicators/relation_weight_by_freight`;
        dispatch(loaderShow())
        api.post(URL,filter)
        .then(({data})=>{

           let value = data.data.cteTotalValue / data.data.totalWeight
           value = value || 0;
        
            dispatch(setValue(`R$ ${utils.formatNumber(value)}`))
            dispatch(loaderHide())
        })
        .catch((error)=>{
            console.log(error)
            dispatch(loaderHide())
        })
    }
}

