import React from "react";
import { connect } from "react-redux";
import { Column } from "primereact/column";
import { actions } from "../../../../accountConfiguration.actions";
import { ButtonDialog } from "../../../../../../components/button-dialog/ButtonDialog";
import "./index.scss";
import { actions as actionVehicleType } from "../../../../../vehicleType/vehicleType.actions";
import { actions as actionFreightTable } from "../../../../../freightTable/freightTable.actions";
import { AppService, BUSINESS_UNIT_CHANGE_EVENT_NAME, COMPANY_CHANGE_EVENT_NAME } from "../../../../../../services/app.service";
import moment from "moment";
import { routes } from "../../../../../../constants";
import { LookupField } from "../../../../../../components/lookup/LookupField";
import { DefaultDataTableList } from "./DefaultDataTableList";
import { Link } from "react-router-dom";
import history from "../../../../../../history";
import { Dropdown } from "primereact/dropdown";
const initialState = {
  columnsFreihgtTable: [
    {
      field: "oid",
      header: "Código",
      columnKey: "oid",
      sortable: false,
      filter: false,
    },
    {
      field: "name",
      header: "Nome",
      columnKey: "name",
      sortable: false,
      filter: false,
    },
    {
      field: "carrier.name",
      header: "Transportadora",
      columnKey: "carrier.name",
      sortable: false,
      filter: false,
    },
    {
      field: "initialValidity",
      header: "Início da vigência",
      columnKey: "initialValidity",
      sortable: false,
      filter: false,
    },
    {
      field: "finalValidity",
      header: "Final da vigência",
      columnKey: "finalValidity",
      sortable: false,
      filter: false,
    },
    {
      field: "active",
      header: "Ativo",
      columnKey: "active",
      sortable: false,
      filter: false,
    },
    {
      field: "btnRemove",
      header: "",
      columnKey: "btnRemover",
      sortable: false,
      filter: false,
    },
  ],
  columnsVehicleLock: [
    {
      field: "oid",
      header: "Código",
      columnKey: "oid",
      sortable: false,
      filter: false,
    },
    {
      field: "name",
      header: "Nome",
      columnKey: "name",
      sortable: false,
      filter: false,
    },
    {
      field: "btnRemove",
      header: "",
      columnKey: "btnRemover",
      sortable: false,
      filter: false,
    },
  ],
};

const booleanFieldsTemplate = (rowData, column) => {
  const result = rowData[column.field];
  if (result) {
    return (
      <div style={{ color: "green", display: "flex", justifyContent: "center" }}>
        <span className="pi pi-check-circle" style={{ fontSize: "1.8rem" }} />{" "}
      </div>
    );
  } else {
    return (
      <div style={{ color: "red", display: "flex", justifyContent: "center" }}>
        <span className="pi pi-times-circle" style={{ fontSize: "1.8rem" }} />
      </div>
    );
  }
};
const negotiationTypeTemplate = (rowData, column) => {
  const data = {
    BOTH: "Ambos",
    EARLYBILLOFLADING: "Pré-conhecimento",
    SHIPMENT: "Embarque",
  };
  const value = data[rowData.negotiationType] || "";
  return <div style={{ textAlign: "center" }}>{value}</div>;
};
const dateTemplate = (rowData, column) => {
  const date = rowData[column.field];
  const formattedDate = moment(date).format("DD/MM/YYYY");
  return <div style={{ textAlign: "center", color: "black" }}>{formattedDate}</div>;
};
const makeCol = (col) => {
  if ("active" === col.field) {
    return <Column {...col} key={col.field} body={booleanFieldsTemplate} />;
  } else if (col.field === "negotiationType") {
    return <Column {...col} key={col.field} body={negotiationTypeTemplate} />;
  } else if (col.field === "initialValidity") {
    return <Column {...col} key={col.field} body={dateTemplate} />;
  } else if (col.field === "finalValidity") {
    return <Column {...col} key={col.field} body={dateTemplate} />;
  }
  return <Column {...col} key={col.field} />;
};

const mapStateToProps = (state) => {
  const { selectedBusinessUnitRecord = null } = state.crudAccountConfigurationState;

  const lookupFreightTableBase = {
    records: state.crudFreightTableState.records,
    columns: state.crudFreightTableState.columns.map((col) => makeCol(col)),
    first: state.crudFreightTableState.index,
    rows: state.crudFreightTableState.max,
    totalRecords: state.crudFreightTableState.count,
    sortField: state.crudFreightTableState.sortField,
    order: state.crudFreightTableState.sortOrder,
  };

  const lookupFreightTable = Object.assign({}, state.crudAccountConfigurationState.lookupFreightTable, lookupFreightTableBase);
 
  const recordsFreightTable = state.crudAccountConfigurationState.recordsFreightTable;
 

  let vehicleTypes = [];
  if (state.crudVehicleTypeState.records) {
    vehicleTypes = state.crudVehicleTypeState.records.map((v) => {
      return { oid: v.oid, name: v.name };
    });
  }
  let lockVehiclesFreightTable = [];
  if (
    selectedBusinessUnitRecord &&
    selectedBusinessUnitRecord.freightTableDefaultRoute &&
    selectedBusinessUnitRecord.freightTableDefaultRoute.vehiclesLock
  ) {
    lockVehiclesFreightTable = selectedBusinessUnitRecord.freightTableDefaultRoute.vehiclesLock;
  }

  const fields = ["oid", "name", "carrier.person.name", "initialValidity", "finalValidity", "active"];
  return {
    fields,
    columns: initialState.columnsFreihgtTable,
    columnsVehicleLock: initialState.columnsVehicleLock,
    selectedBusinessUnitRecord,
    lookupFreightTable,
    showLookupFreightTable: state.crudAccountConfigurationState.showLookupFreightTable,
    lookupFreightTableSelected: state.crudAccountConfigurationState.lookupFreightTableSelected,
    recordsFreightTable, 
    vehicleTypes,
    vehicleType: state.crudAccountConfigurationState.vehicleType,
    lockVehiclesFreightTable,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadVehicleType: () => {
      dispatch(actionVehicleType.setType(null));
      dispatch(actionVehicleType.applyFilter([]));
    },

    //lookup freightTable
    lookupFreightTableSetVisible: (visible) => dispatch(actions.setLookupFreightTableVisible(visible)),
    lookupFreightTablePaginate: (paginationParams) => dispatch(actionFreightTable.paginate(paginationParams)),
    lookupFreightTableSortOrder: (sortParams) => dispatch(actionFreightTable.sortOrder(sortParams)),
    lookupFreightTableApplyFilter: (filterParams, fields) => {
      dispatch(actionFreightTable.setType(null));
      dispatch(actionFreightTable.applyFilter(filterParams, { fields }));
    },
    //lookupFreightTableLoad: () => dispatch(actionFreightTable.load()),
    loadFreightTableById: () => dispatch(actions.loadFreightTableById()),

    setLookupFreightTableSelected: (freightTable) => {
      dispatch(actions.setLookupFreightTableSelected(freightTable));
    },
    //lookup freightTable

    onConfirmFreightTable: () => {
      dispatch(actions.onConfirmFreightTable());
    },
    onRemoveDefaultFreightTable: (oid) => dispatch(actions.onRemoveDefaultFreightTable(oid)),
    setSelectedVehicleType: (vehicleType) => dispatch(actions.setSelectedVehicleType(vehicleType)),
    addBtnAddVehicleTypeSelected: () => dispatch(actions.addBtnAddVehicleTypeSelected()),
    onRemoveVehicleDefaultFreightTable: (oid) => dispatch(actions.onRemoveVehicleDefaultFreightTable(oid)),
  };
};

class RouteFreightTableDefaultListPageContainer extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    AppService.on(COMPANY_CHANGE_EVENT_NAME, this);
    AppService.on(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
    this.props.loadVehicleType();
  }

  componentWillUnmount() {
    AppService.off(COMPANY_CHANGE_EVENT_NAME, this);
    AppService.off(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
  }

  onReceiveAppNotifications = (eventName, params) => {
    if (eventName === COMPANY_CHANGE_EVENT_NAME || eventName === BUSINESS_UNIT_CHANGE_EVENT_NAME) {
      this.props.loadVehicleType();
    }
  };

  //LookupFreightTable
  lookupFreightTableOnPage = (e) => {
    this.props.lookupFreightTablePaginate({
      max: e.rows,
      index: e.first,
    });
  };
  lookupFreightTableOnSort = (e) => {
    this.props.lookupFreightTableSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField,
    });
  };
  lookupFreightTableOnFilter = (e) => {
    const filters = [];
    const keys = Object.keys(e.filters) || [];
    keys.forEach((key) => {
      filters.push({
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: "CONTEM",
      });
    });

    this.props.lookupFreightTableApplyFilter(filters, this.props.fields);
  };
  lookupFreightTableOnClick = (e) => {
    const filters = [
      {
        campo: "active",
        valor: true,
        tipoFiltro: "IGUAL_A",
      },
      {
        campo: "routes.ranges.rangeType",
        valor: ["EXCESS_KILOMETER", "KILOMETER"],
        tipoFiltro: "ESTA_CONTIDO_EM",
      },
    ];
    this.props.lookupFreightTableApplyFilter(filters, this.props.fields);
    this.props.lookupFreightTableSetVisible(true);
  };
  lookupFreightTableOnHide = (e) => {
    this.props.lookupFreightTableSetVisible(false);
  };
  lookupFreightTableOnConfirm = (e) => {
    this.props.setLookupFreightTableSelected(e.selection);
    this.props.lookupFreightTableSetVisible(false);
  };
  lookupFreightTableOnCancel = (e) => {
    this.props.setLookupFreightTableSelected(null);
    this.props.lookupFreightTableSetVisible(false);
  };

  lookupFreightTableOnEdit = () => {
    if (this.props.lookupFreightTableSelected && this.props.lookupFreightTableSelected.oid) {
      const id = this.props.lookupFreightTableSelected.oid;
      history.push(`${routes.PATH_FREIGHT_TABLE}/${id}`);
    }
  };

  lookupFreightTableOnComplete = (e) => {};

  lookupFreightTableOnInputFilter = (e) => {
    const value = e.value;
    if (typeof value === "string") {
      const filters = [
        {
          campo: "name",
          valor: e.value,
          tipoFiltro: "CONTEM",
        },
      ];
      this.props.setLookupFreightTableSelected({ name: e.value });
      this.props.lookupFreightTableApplyFilter(filters, this.props.fields);
    } else {
      this.props.setEquipment(value);
    }
  };

  //LookupFreightTable

  //LookupCarrier
  addBtnAddConfigFreightTableSelected = () => {
    this.props.onConfirmFreightTable();
    this.props.setLookupFreightTableSelected(null);
  };

  render() {
    const btnRemoveTemplate = (rowData, column) => {
      
      return (
        <div>
          <ButtonDialog
            onClick={(e) => {
              this.props.onRemoveDefaultFreightTable(rowData.oid);
            }}
            button={{
              title: "Remover",
              className: "lognex-btn-danger",
            }}
            dialog={{
              header: "Confirmação",
              modal: true,
              message: "Deseja realmente desvincular a tabela de frete padrão?",
            }}
          ></ButtonDialog>
        </div>
      );
    };

    const linkClickTemplate = (rowData, column) => {
      try {
        return (
          <div className="id-link">
            <Link to={`${routes.PATH_FREIGHT_TABLE}/${rowData.oid}`}>{rowData.oid}</Link>
          </div>
        );
      } catch (error) {
        return <div></div>;
      }
    };

    const columns = this.props.columns.map((col) => {
      if ("active" === col.field) {
        return <Column {...col} key={col.field} body={booleanFieldsTemplate} />;
      } else if (col.field === "negotiationType") {
        return <Column {...col} key={col.field} body={negotiationTypeTemplate} />;
      } else if (col.field === "initialValidity") {
        return <Column {...col} key={col.field} body={dateTemplate} />;
      } else if (col.field === "finalValidity") {
        return <Column {...col} key={col.field} body={dateTemplate} />;
      } else if (col.field === "btnRemove") {
        return <Column {...col} key={col.field} body={btnRemoveTemplate} style={{ width: "60px" }} />;
      } else if (col.field === "oid") {
        return <Column {...col} key={col.field} body={linkClickTemplate} style={{ width: "90px" }} />;
      }
      return <Column {...col} key={col.field} />;
    });
    const btnRemoveVehicleTemplate = (rowData, column) => {
      return (
        <div>
          <ButtonDialog
            onClick={(e) => {
              this.props.onRemoveVehicleDefaultFreightTable(rowData.oid);
            }}
            button={{
              title: "Remover",
              className: "lognex-btn-danger",
            }}
            dialog={{
              header: "Confirmação",
              modal: true,
              message: "Deseja realmente desbloquear esse veículo?",
            }}
          ></ButtonDialog>
        </div>
      );
    };
    const columnsVehiclesLocks = this.props.columnsVehicleLock.map((col) => {
      if (col.field === "btnRemove") {
        return <Column {...col} key={col.field} body={btnRemoveVehicleTemplate} style={{ width: "60px" }} />;
      } else if (col.field === "oid") {
        return <Column {...col} key={col.field} style={{ width: "90px" }} />;
      }
      return <Column {...col} key={col.field} />;
    });

    return (
      <div className="client-form">
        <div className={this.props.className || "panel-margins-border"}>
          <div className="p-grid">
            <div className="p-col-12">
              <h3>Tabela de frete padrão - Roteirizador Lognex</h3>
            </div>

            <div className="p-col-6 form-field-container">
              <label className="lxRequiredField">Tabelas de frete</label>
              <LookupField
                value={this.props.lookupFreightTableSelected}
                placeholder="Tabela de Frete"
                name="name"
                field="name"
                onComplete={this.lookupFreightTableOnComplete}
                onInputFilter={this.lookupFreightTableOnInputFilter}
                editDisabled={this.props.lookupFreightTable.editDisabled}
                onEditField={this.lookupFreightTableOnEdit}
                onClick={this.lookupFreightTableOnClick}
                visible={this.props.lookupFreightTable.visible}
                modal={this.props.lookupFreightTable.modal}
                header={this.props.lookupFreightTable.header}
                onHide={this.lookupFreightTableOnHide}
                onConfirm={this.lookupFreightTableOnConfirm}
                onCancel={this.lookupFreightTableOnCancel}
                records={this.props.lookupFreightTable.records}
                columns={this.props.lookupFreightTable.columns}
                totalRecords={this.props.lookupFreightTable.totalRecords}
                sortOrder={this.props.lookupFreightTable.order}
                sortField={this.props.lookupFreightTable.sortField}
                first={this.props.lookupFreightTable.first}
                rows={this.props.lookupFreightTable.rows}
                rowsPerPageOptions={[10]}
                onPage={this.lookupFreightTableOnPage}
                onFilter={this.lookupFreightTableOnFilter}
                onSort={this.lookupFreightTableOnSort}
              />
            </div>

            <div className="p-col  align-bottom-right  " style={{ width: "auto" }}>
              <button
                disabled={!this.props.lookupFreightTableSelected || !this.props.selectedBusinessUnitRecord}
                className={"lognex-btn-highlighted"}
                onClick={this.addBtnAddConfigFreightTableSelected}
                title="Novo"
                style={{ marginRight: ".25em" }}
              >
                <i className="pi pi-plus"></i>
              </button>
            </div>
          </div>

          <DefaultDataTableList records={this.props.recordsFreightTable} columns={columns} />

          <br />
          <div className="p-grid">
            <div className="p-col-3 form-field-container">
              <label>Tipo de Veículo à ser Bloqueado</label>
              <Dropdown
                placeholder="Selecione"
                dataKey={"oid"}
                optionLabel="name"
                value={this.props.vehicleType}
                options={this.props.vehicleTypes}
                onChange={(e) => {
                  this.props.setSelectedVehicleType(e.value);
                }}
              ></Dropdown>
            </div>
            <div className="p-col align-bottom-right" style={{ width: "auto" }}>
              <button
                disabled={!this.props.vehicleType ||   !this.props.selectedBusinessUnitRecord}
                className={"lognex-btn-highlighted"}
                onClick={this.props.addBtnAddVehicleTypeSelected}
                title="Novo"
                style={{ marginRight: ".25em" }}
              >
                <i className="pi pi-plus"></i>
              </button>
            </div>
          </div>
          <DefaultDataTableList records={this.props.lockVehiclesFreightTable} columns={columnsVehiclesLocks} />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RouteFreightTableDefaultListPageContainer);
