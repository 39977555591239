import React from "react";
import { connect } from "react-redux";
import { Column } from "primereact/column";
import { OrderAttemdanceWindowList } from "./OrderAttemdanceWindowList";

const mapStateToProps = (state) => {
  const record = state.crudOrderDeliveryState.currentRecord;
  let attemdanceWindow = [];
  if (record && record.service && record.service.attemdanceWindow) {
    attemdanceWindow = record.service.attemdanceWindow;
  }

  return {
    columns: state.crudOrderDeliveryState.columnsOrderAttemdanceWindow,
    records: attemdanceWindow,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

class OrderAttemdanceWindowListPageContainer extends React.Component {
  render() {
    const columns = this.props.columns.map((col) => {
      return <Column {...col} key={col.field} />;
    });

    return <OrderAttemdanceWindowList {...this.props} columns={columns} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderAttemdanceWindowListPageContainer);
