import React from "react";
import { Dialog } from "primereact/dialog";
import { ButtonDialog } from "../../../components/button-dialog/ButtonDialog";
import { InputText } from "primereact/inputtext";
import "./modal-custom-fields.scss";
export const ModalCustomFields = (props) => {
  let listFields = [];

  if (props.record.length > 0) {
    listFields = props.record.map((item, index) => {
      return (
        <div className="p-grid " key={index}>
          <div className="p-col-3 form-field-container">
            <label>{"Nome do campo"}</label>
            <InputText value={item.campo} onChange={(e) => props.onChange(e, "campo", item.uuid)} type="text" />
          </div>
          <div className="p-col-7 form-field-container">
            <label>{"Valor do campo"}</label>
            <InputText value={item.value} onChange={(e) => props.onChange(e,"value", item.uuid)} type="text" />
          </div>

          <div className="p-col-2 form-field-container content-btn-edit">
            <button className="lognex-btn-highlighted btn-edit-fields" onClick={props.onAddNewCustomFieldsHandler} title={"duplicar campos"}>
              <i className="pi pi-plus"></i>
            </button>

            <button className="lognex-btn-highlighted btn-edit-fields" onClick={() => props.removeItemCustomFieldsHandler(item)} title={"remover campos"}>
              <i className="pi pi-trash"></i>
            </button>
          </div>
        </div>
      );
    });
  }
  const footer = (
    <div>
      <ButtonDialog
        onClick={props.onConfirm}
        button={{
          title: "Confirmar",
          className: "lognex-btn-highlighted",
          iconClassName: "pi pi-check",
        }}
        dialog={{
          header: "Confirmação",
          modal: false,
          message: "Confirmar a atualização?",
        }}
      ></ButtonDialog>
      <button title="Cancelar" className="lognex-btn-danger" onClick={props.onHide}>
        <i className="pi pi-times"></i>
      </button>
    </div>
  );

  return (
    <Dialog header={props.header} onHide={props.onHide} visible={props.visible} footer={footer} key={"dialogCustomFieldsNfe"}>
      <div className="custom-fields-dialog"> {listFields} </div>
    </Dialog>
  );
};
