import React from 'react'; 
import CargoShipmentInStatusColetaPageContainer from './CargoShipmentInStatusColetaPageContainer';
 
export class CargoShipmentInStatusColetaListPage extends React.Component{
    
    render(){
      return (
        <CargoShipmentInStatusColetaPageContainer {...this.props}/>
      )
    }
}

