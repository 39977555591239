import React from 'react';
import PendenceDocListPageContainer from './PendenceDocListPageContainer'; 

export class PendenceDocListPage extends React.Component{
    
    render(){
      return (
        <PendenceDocListPageContainer {...this.props}/>
      )
    }
}

