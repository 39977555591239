import api from '../../../services/api.service';
import * as utils from '../../../utils';

export const types = {
    SET_PERCENTAGE_VALUE: 'DivergenceReportPercentageValue',
    SET_VALUE: 'DivergenceReportSetValue',
    SET_CNPJ: 'DivergenceReportSetCNPJ',
    SET_NAME: 'DivergenceReportSetName',
    SET_PERIOD: 'DivergenceReportSetPeriod',
    SET_SHOW_CARRIER: 'DivergenceReportetSethowCarrier',
    SET_SHOW_PERIOD: 'DivergenceReportetSethowPeriod',
    LOADER_SHOW: 'loaderShow',
    LOADER_HIDDEN: 'loaderHide',
}

export function setPercentageValue(percentageValue){
    return {
        type: types.SET_PERCENTAGE_VALUE,
        percentageValue,
    }
}

export function setValue(value){
    return {
        type: types.SET_VALUE,
        value,
    }
}

export function setCNPJ(carrier){
    return {
        type: types.SET_CNPJ,
        carrier,
    }
}

export function setName(name){
    return {
        type: types.SET_NAME,
        name,
    }
}

export function setPeriod(period){
    return {
        type: types.SET_PERIOD,
        period,
    }
}

export function setShowCarrier(showCarrier){
    return {
        type: types.SET_SHOW_CARRIER,
        showCarrier,
    }
}

export function setShowPeriod(showPeriod){
    return {
        type: types.SET_SHOW_PERIOD,
        showPeriod,
    }
}

export function loaderShow(){
    return {
        type: types.LOADER_SHOW,
        async: true,
    }
}

export function loaderHide(){
    return {
        type: types.LOADER_HIDDEN,
        async: false,
    }
}

export function load(){
    return (dispatch, getState)=>{
        const state = getState().divergenceReportState.divergence;
        const filter = {
            carrierDocument:state.filters.carrier.documentNumber || "",
            period: state.filters.period.period || "CURRENT_MONTH"
        }
        const URL = `/api/v1/lognex/reports/indicators/divergence`;
        dispatch(loaderShow())
        api.post(URL,filter)
        .then(({data})=>{
            const {quantity = 0 , divergentQuantity = 0, totalValue = 0, divergentTotalValue = 0} = data.data
            dispatch(setValue(`R$ ${utils.formatNumber(divergentTotalValue)}`))
            dispatch(loaderHide())
        })
        .catch((error)=>{
            console.log(error)
            dispatch(loaderHide())
        })
    }
}

