import React from 'react';

import TransferRouteFormPageContainer from './TransferRouteFormPageContainer';

export class TransferRouteFormPage extends React.Component{

    
    render(){
        return (
           <TransferRouteFormPageContainer {...this.props} />
        )
    }
}