import React from 'react'
import { connect } from 'react-redux'
import { Page } from '../../../components/page/Page'
import Toolbar from '../../../components/toolbar/Toolbar'
import { Column } from "primereact/column";
import { routes } from '../../../constants';
import { actions } from '../packing-converter.actions'
import { actions as actionsPackage } from '../../packing/packing.actions'
import { PackingConverterForm } from './PackingConverterForm'


const mapStateToProps = state => {
    const record = state.crudPackingConverterState.currentRecord
    const lookupPacking1Base = {
        records: state.crudPackingState.records,
        columns: state.crudPackingConverterState.lookupPackingFilters.lookupPacking1.visible
            ? state.crudPackingState.columns
                .filter(col => col.field === 'oid' || col.field === 'name')
                .map(col => <Column key={col.field} {...col} />)
            : [<Column key={"columnKeyCarrier"} />],
        first: state.crudPackingState.index,
        rows: state.crudPackingState.max,
        totalRecords: state.crudPackingState.count,
        sortField: state.crudPackingState.sortField,
        order: state.crudPackingState.sortOrder,
        editDisabled: !record.oid,
    }

    const lookupPacking1 = { ...state.crudPackingConverterState.lookupPacking1, ...lookupPacking1Base }


    const lookupPacking2Base = {
        records: state.crudPackingState.records,
        columns: state.crudPackingConverterState.lookupPackingFilters.lookupPacking2.visible
            ? state.crudPackingState.columns
                .filter(col => col.field === 'oid' || col.field === 'name')
                .map(col => <Column key={col.field} {...col} />)
            : [<Column key={"columnKeyCarrier"} />],
        first: state.crudPackingState.index,
        rows: state.crudPackingState.max,
        totalRecords: state.crudPackingState.count,
        sortField: state.crudPackingState.sortField,
        order: state.crudPackingState.sortOrder,
        editDisabled: !record.oid,
    }

    const lookupPacking2 = { ...state.crudPackingConverterState.lookupPacking2, ...lookupPacking2Base }

    return {
        record,
        lookupPacking1,
        lookupPacking2,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        saveHandler: event => {
            dispatch(actions.save());
        },
        removeHandler: event => {
            dispatch(actions.remove());
        },
        newHandler: event => {
            dispatch(actions.makeNew());
        },
        changeHandler: event => {
            const target = event.target;
            const field = target.name;
            let value = target.value;
            dispatch(actions.removeErrors([{ field }]));
            
            dispatch(actions.setField(field, value));
        },
        lookupPacking1SetVisible: visible => {
            dispatch(actions.packing1SetVisible(visible))
        },
        lookupPacking1Paginate: paginationParams => dispatch(actionsPackage.paginate(paginationParams)),
        lookupPacking1SortOrder: sortParams => dispatch(actionsPackage.sortOrder(sortParams)),
        lookupPacking1ApplyFilter: filterParams => {
            dispatch(actionsPackage.setType(null))
            dispatch(actionsPackage.applyFilter(filterParams))
        },
        lookupPacking1Load: () => dispatch(actionsPackage.load()),
        setPacking1: package1 => {
            dispatch(actions.setPacking1(package1))
        },

        lookupPacking2SetVisible: visible => {
            dispatch(actions.packing2SetVisible(visible))
        },
        lookupPacking2Paginate: paginationParams => dispatch(actionsPackage.paginate(paginationParams)),
        lookupPacking2SortOrder: sortParams => dispatch(actionsPackage.sortOrder(sortParams)),
        lookupPacking2ApplyFilter: filterParams => {
            dispatch(actionsPackage.setType(null))
            dispatch(actionsPackage.applyFilter(filterParams))
        },
        lookupPacking2Load: () => dispatch(actionsPackage.load()),
        setPacking2: package2 => {
            dispatch(actions.setPacking2(package2))
        },
        loadById: oid => dispatch(actions.loadById(oid)),
        setField: (field, value) => dispatch(actions.setField(field, value))
    }
}

class PackingConverterFormPageContainer extends React.Component {
    constructor(props) {
        super(props);
        actions.afterSave = ({ oid }) => {
            this.props.history.replace(`${routes.PATH_PACKING_CONVERTER}/${oid}`);
        };
        actions.afterNew = () => {
            this.props.history.replace(`${routes.PATH_PACKING_CONVERTER}/novo`);
        };
        actions.afterRemove = () => {
            this.props.history.replace(`${routes.PATH_PACKING_CONVERTER}`);
        };
    }

    componentDidMount() {
        const oid = this.props.match.params.id;
        if (oid && parseInt(oid)) {
            this.props.loadById(oid);
        } else {
            this.props.newHandler();
        }
    }
    searchHandler = () => {
        this.props.history.push(`${routes.PATH_PACKING_CONVERTER}`)
    }

    lookupPacking1OnPage = e => {
        this.props.lookupPacking1Paginate({
            max: e.rows,
            index: e.first
        })
    }
    lookupPacking1OnSort = e => {
        this.props.lookupPacking1SortOrder({
            sortOrder: e.sortOrder,
            sortField: e.sortField
        })
    }

    lookupPacking1OnClick = e => {
        this.props.lookupPacking1ApplyFilter([])
        this.props.lookupPacking1Load()
        this.props.lookupPacking1SetVisible(true)
    }

    lookupPacking1OnHide = e => {
        this.props.lookupPacking1ApplyFilter([])
        this.props.lookupPacking1SetVisible(false)
    }

    lookupPacking1OnConfirm = e => {
        this.props.lookupPacking1ApplyFilter([])
        const {oid,name} = e.selection;
        this.props.setPacking1({oid,name})
        this.props.lookupPacking1SetVisible(false)
    }

    lookupPacking1OnCancel = e => {
        this.props.lookupPacking1ApplyFilter([])
        this.props.setPacking1({oid:null,name:""})
        this.props.lookupPacking1SetVisible(false)
    }

    lookupPacking1OnFilter = e => {
        const filters = []
        const keys = Object.keys(e.filters) || []
        keys.forEach(key => {
            filters.push({
                campo: key,
                valor: e.filters[key].value,
                tipoFiltro: "CONTEM"
            })
        })
        this.props.lookupPacking1ApplyFilter(filters)
    }

    lookupPacking1OnInputFilter = e => {
        const value = e.value
        const filters = [];
        if (typeof value === "string") {
             filters.push(
                {
                    campo: "name",
                    valor: e.value,
                    tipoFiltro: "CONTEM"
                }
             )
            this.props.setPacking1({ name: value })
        } else {
            const {oid,name} = value; 
            this.props.setPacking1({oid,name})
        }
        this.props.lookupPacking1ApplyFilter(filters)
    }

    lookupPacking1OnComplete = e => { }


    lookupPacking2OnPage = e => {
        this.props.lookupPacking2Paginate({
            max: e.rows,
            index: e.first
        })
    }
    lookupPacking2OnSort = e => {
        this.props.lookupPacking2SortOrder({
            sortOrder: e.sortOrder,
            sortField: e.sortField
        })
    }

    lookupPacking2OnClick = e => {
        this.props.lookupPacking2ApplyFilter([])
        this.props.lookupPacking2Load()
        this.props.lookupPacking2SetVisible(true)
    }

    lookupPacking2OnHide = e => {
        this.props.lookupPacking2ApplyFilter([])
        this.props.lookupPacking2SetVisible(false)
    }

    lookupPacking2OnConfirm = e => {
        this.props.lookupPacking2ApplyFilter([])
        const {oid,name} = e.selection;
        this.props.setPacking2({oid,name})
        this.props.lookupPacking2SetVisible(false)
    }

    lookupPacking2OnCancel = e => {
        this.props.lookupPacking2ApplyFilter([])
        this.props.setPacking2({oid:null,name:""})
        this.props.lookupPacking2SetVisible(false)
    }

    lookupPacking2OnFilter = e => {
        const filters = []
        const keys = Object.keys(e.filters) || []
        keys.forEach(key => {
            filters.push({
                campo: key,
                valor: e.filters[key].value,
                tipoFiltro: "CONTEM"
            })
        })
        this.props.lookupPacking2ApplyFilter(filters)
    }

    lookupPacking2OnInputFilter = e => {
        const value = e.value
        const filters = [];
        if (typeof value === "string") {
            filters.push(
                {
                    campo: "name",
                    valor: e.value,
                    tipoFiltro: "CONTEM"
                }
            )
            this.props.setPacking2({name: value } )
        } else {
           const {oid,name} = value; 
            this.props.setPacking2({oid,name})
        }
        this.props.lookupPacking2ApplyFilter(filters)
    }

    lookupPacking2OnComplete = e => { }

    render() {

        const title = `Conversor de embalagens ${this.props.record.oid || 'nova'}`
        const header = (
            <div>
                <h1>{title}</h1>
                <div>
                    <Toolbar
                        onSave={this.props.saveHandler} saveButtonStyle='highlighted'
                        onRemove={this.props.removeHandler} removeButtonStyle='danger'
                        onNew={this.props.newHandler}
                        onSearch={this.searchHandler}>
                    </Toolbar>
                </div>
            </div>
        )

        const content = (
            <PackingConverterForm
                record={this.props.record}  
                changeHandler={this.props.changeHandler}

                lookupPacking1={this.props.lookupPacking1}
                lookupPacking1OnComplete={this.lookupPacking1OnComplete}
                lookupPacking1OnInputFilter={this.lookupPacking1OnInputFilter}
                lookupPacking1OnEdit={this.lookupPacking1OnEdit}
                lookupPacking1OnClick={this.lookupPacking1OnClick}
                lookupPacking1OnHide={this.lookupPacking1OnHide}
                lookupPacking1OnConfirm={this.lookupPacking1OnConfirm}
                lookupPacking1OnCancel={this.lookupPacking1OnCancel}
                lookupPacking1OnPage={this.lookupPacking1OnPage}
                lookupPacking1OnFilter={this.lookupPacking1OnFilter}
                lookupPacking1OnOnSort={this.lookupPacking1OnSort}

                lookupPacking2={this.props.lookupPacking2}
                lookupPacking2OnComplete={this.lookupPacking2OnComplete}
                lookupPacking2OnInputFilter={this.lookupPacking2OnInputFilter}
                lookupPacking2OnEdit={this.lookupPacking2OnEdit}
                lookupPacking2OnClick={this.lookupPacking2OnClick}
                lookupPacking2OnHide={this.lookupPacking2OnHide}
                lookupPacking2OnConfirm={this.lookupPacking2OnConfirm}
                lookupPacking2OnCancel={this.lookupPacking2OnCancel}
                lookupPacking2OnPage={this.lookupPacking2OnPage}
                lookupPacking2OnFilter={this.lookupPacking2OnFilter}
                lookupPacking2OnOnSort={this.lookupPacking2OnOnSort}

            />
        )

        return (<Page header={header} content={content} />)
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(PackingConverterFormPageContainer)