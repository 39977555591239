import React from 'react';
import AccountConfigurationFormPageContainer from './accountConfigurationFormPageContainer';

export class AccountConfigurationFormPage extends React.Component{
    render(){
        return (
            <AccountConfigurationFormPageContainer {...this.props}/>
        )
    }
}
