import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import { LxButtonActions } from "../../../../components/lx/button-actions/LxButtonActions";
import { MultiEntryComponentModalInsert } from "./MultiEntriesComponentModalInsert";
import { MultiEntryComponentModalUpdate } from "./MultiEntriesComponentModalUpdate";

function styleClass(styleType) {
  if (!styleType || typeof styleType !== "string") {
    return "";
  }
  switch (styleType.toUpperCase()) {
    case "DANGER":
      return "lognex-btn-danger";
    case "HIGHLIGHTED":
      return "lognex-btn-highlighted";
    default:
      return "";
  }
}

export function MultiEntriesList(props) {

  const [selectedRecords, setSelectedRecords] = useState([]);
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [currentRecordPosition, setCurrentRecordPosition] = useState(-1);
  const [showInsert, setShowInsert] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);

  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [first, setFirst] = useState(0);

  useEffect(() => {
    props.onLoadMultiEntry({
      max: rowsPerPage,
      index: first,
      componentOid: props.componentOid,
      indexComponent: props.indexComponent,
    })
  }, []);

  const onSelectionChangeRecords = (event) => {
    const { records = [] } = props;
    const { value } = event;

    const position = records.indexOf(value);
    setCurrentRecord(value);
    setSelectedRecords(value ? [value] : []);
    setCurrentRecordPosition(position);
  };
  const onFilterRecords = ({ filters }) => {
    console.log(`==> Filters => ${filters}`);
    let filtered = [];
    if (filters && filters.key) {
      const { value } = filters.key;
      if (value.trim().length > 0 && props.records) {
        filtered =
          props.records.filter((record) =>
            record.key.toLowerCase().startsWith(value.toLowerCase())
          ) || [];
      }
    }
    setFilteredRecords(filtered);
  };
  const onSortRecords = (sortField, sortOrder, multiSortMeta) => {
    console.log(`==> sort => ${sortField} | ${sortOrder} | ${multiSortMeta}`);
  };

  const onConfirm = (values = []) => {
    props.onAddEntries && props.onAddEntries([...values]);
    setShowInsert(false);
  };
  const onRemove = () => {
    setSelectedRecords([]);
    setCurrentRecord(null);
    setCurrentRecordPosition(-1);
    props.onRemoveEntries && props.onRemoveEntries([...selectedRecords]);
  };
  const onImportXLSX = (event) => {
    event.preventDefault();
    props.sendInportTaxaComponetXLSX(event.target.files);
    event.target.value = null;
  };

  const onChangeHandler = (event) => {
    const { target = {} } = event;
    const { name = "", value = "" } = target;
    if (name && name.trim().length > 0 && value && value.trim().length > 0) {
      const record = { ...currentRecord, [name]: value };
      if (name === "value") {
        record.value = parseFloat(value);
      }
      setCurrentRecord(record);
    }
  };

  const onConfirmUpdate = () => {
    props.onUpdateEntry(currentRecord, currentRecordPosition);
    setShowUpdate(false);
  };

  const onPage = (e)=>{
    setFirst(e.first);
    setRowsPerPage(e.rows);
    props.onLoadMultiEntry({
      max: e.rows,
      index: e.page,
      componentOid: props.componentOid,
      indexComponent: props.indexComponent,
    });
  }

  const recordsColumns = [
    {
      field: "key",
      header: "Código",
      columnKey: "key",
      sortable: false,
      filter: true,
    },
    {
      field: "name",
      header: "Nome",
      columnKey: "name",
      sortable: false,
      filter: false,
    },
    {
      field: "value",
      header: "Valor",
      columnKey: "value",
      sortable: false,
      filter: false,
    },
  ].map((record) => <Column key={record.field} {...record} />);
  recordsColumns.unshift(
    <Column
      key="selectorColumn"
      selectionMode="single"
      style={{ width: "3em" }}
    />
  );

  const filePath = '/resources/templates/template_tabela_componente_multi_valores.xlsx'

  return (
    <div className="p-col-12 form-field-container">
      <label>Lista de valores</label>

      <LxButtonActions
        hint={"Lista de Valores"}
        onAdd={() => {
          setShowInsert(true);
        }}
        onEdit={() => {
          setShowUpdate(true);
        }}
        onRemove={onRemove}
        importxls={true}
        XLSXTemplatePath={filePath}
        onImportXLSX={onImportXLSX}
        removeMessage={`Deseja realmente remover os registros selecionados?`}
        header={
          props.toolbar && props.toolbar.header ? props.toolbar.header : ""
        }
      />

      <DataTable
        selection={selectedRecords}
        onSelectionChange={onSelectionChangeRecords}
        onFilter={onFilterRecords}
        onSort={onSortRecords}
        value={
          filteredRecords && filteredRecords.length > 0
          ? filteredRecords
          : props.records
        }
        lazy={true}
        responsive={true}
        scrollable={true}
        scrollHeight="200px"
        paginator={true}
        first={first}
        rows={rowsPerPage}
        totalRecords={props.auxiliarMultiEntry ? props.auxiliarMultiEntry.countMultiEntries : 0}
        onPage={onPage}
        onPageHandler={true}
        rowsPerPageOptions={[20,50,100,200]}
        emptyMessage = "Não foram encontrados registros"
        pageLinkSize={4}
      >
        {recordsColumns}
      </DataTable>

      <MultiEntryComponentModalInsert
        visible={showInsert}
        onHide={() => {
          setShowInsert(false);
        }}
        onConfirm={onConfirm}
      />
      <MultiEntryComponentModalUpdate
        visible={showUpdate}
        onHide={() => {
          setShowUpdate(false);
        }}
        onChangeHandler={onChangeHandler}
        onConfirm={onConfirmUpdate}
        record={currentRecord}
      />
    </div>
  );
}
