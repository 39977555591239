import React from "react";
import { Card } from "primereact/card";
import { RouteFreightTableDefaultListPage } from "./list/RouteFreightTableDefaultListPage";

export const RouteFreightTableDefault = (props) => {
  return (
    <Card>
      <RouteFreightTableDefaultListPage />
    </Card>
  );
};
