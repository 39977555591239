
import React from 'react';
import { connect } from 'react-redux';
import Toolbar from "../../../components/toolbar/Toolbar";
import { actions } from '../modal.actions';
import { routes } from '../../../constants';
import { Page } from "../../../components/page/Page";
import { ModalForm } from './ModalForm';



const mapStateToProps = state => {
  const record = state.crudModalState.currentRecord;


  return {
    record
    , transportTypes: [
      { label: 'Selecione uma opção', value: null }
      , { value: "AIR", label: "Aéreo" }, { value: "ROAD", label: "Rodoviário" }, { value: "SEA", label: "Marítimo" }
    ], freightTableTypes: [
      { label: 'Selecione uma opção', value: null }
      , { value: "R", label: "Rodoviária" }
    ], pickupTypes: [
      { label: 'Selecione uma opção', value: null }
      , { value: "N", label: "Normal" }, { value: "U", label: "Urgente" }, { value: "WD", label: "Sem entrega" }
    ], deliveryTypes: [
      { label: 'Selecione uma opção', value: null }
      , { value: "N", label: "Normal" }, { value: "U", label: "Urgente" }, { value: "WD", label: "Sem entrega" }
    ]
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setField: (field, value) => dispatch(actions.setField(field, value)),
    changeHandler: event => {
      const target = event.target;
      const field = target.name;
      let value = target.value;
      dispatch(actions.removeErrors([{ field }]));
      dispatch(actions.setField(field, value));
    },
    saveHandler: event => {
      dispatch(actions.save());
    },
    removeHandler: event => {
      dispatch(actions.remove());
    },
    newHandler: event => {
      dispatch(actions.makeNew());
    },
    loadById: oid => dispatch(actions.loadById(oid))
  }
}

class ModalFormPageContainer extends React.Component {

  constructor(props) {
    super(props);
    actions.afterSave = ({ oid }) => {
      this.props.history.replace(`${routes.PATH_MODAL}/${this.props.record.oid}`);
    };
    actions.afterNew = () => {
      this.props.history.replace(`${routes.PATH_MODAL}/novo`);
    };
    actions.afterRemove = () => {
      this.props.history.replace(`${routes.PATH_MODAL}`);
    };
  }

  componentDidMount() {
    const oid = this.props.match.params.id;
    if (oid && parseInt(oid)) {
      this.props.loadById(oid);
    } else {
      this.props.newHandler();
    }
  }
  searchHandler = () => {
    this.props.history.push(`${routes.PATH_MODAL}`)
  }


  render() {
    const content = (
      <ModalForm
        changeHandler={this.props.changeHandler}
        record={this.props.record}

        transportTypes={this.props.transportTypes}
        freightTableTypes={this.props.freightTableTypes}
        pickupTypes={this.props.pickupTypes}
        deliveryTypes={this.props.deliveryTypes}


      />
    );
    const title = `Modal ${this.props.record.oid || 'novo'}`
    const header = (
      <div>
        <h1>{title}</h1>
        <div>
          <Toolbar
            onSave={this.props.saveHandler} saveButtonStyle='highlighted'
            onRemove={this.props.removeHandler} removeButtonStyle='danger'
            onNew={this.props.newHandler}
            onSearch={this.searchHandler}>
          </Toolbar>
        </div>
      </div>
    );
    return (<Page header={header} content={content} />);
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalFormPageContainer);