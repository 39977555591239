import { types } from "./zipCodeRange.actions";
import { Reducer } from "../../services/reducer.factory";
import { actions } from "../region/region.actions";

const initialState = {
  isFavorite : false,
  permissions:{
    byCompany:true,
    buUser:false
  },
  columns: [{
    field: "oid",
    header: "Código",
    columnKey: "oid",
    sortable: true,
    filter: true
  }, {
    header: "CEP Inicial",
    field: "initialZipCode",
    columnKey: "initialZipCode",
    sortable: true,
    filter: true
  }, {
    header: "CEP Final",
    field: "finalZipCode",
    columnKey: "finalZipCode",
    sortable: true,
    filter: true
  }, {
    field: "description",
    header: "Descrição",
    columnKey: "name",
    sortable: true,
    filter: true
  }],
  emptyRecord: {
    oid: "",
    name: "",
    type: 'ZIP_CODE_RANGE',
    parent: null,
    zipCodeRanges :[{start: "", end: ""}]
  },
  currentRecord: {
    oid: "",
    name: "",
    type: 'ZIP_CODE_RANGE',
    parent: null,
    zipCodeRanges :[{start: "", end: ""}]
  },
  types : ['CITY', 'ZIP_CODE_RANGE'],
  lookupParent: {
    visible: false,
    modal: true,
    header: "Pertence a"
  },
};

const reducer = new Reducer(initialState, types);

export function CRUDZipCodeRangeReducer(state, action) {
  state = reducer.execute(state, action);
  return execute(state, action);
}

function execute(state, action) {
  let result = state;
  switch (action.type) {
    case types.SET_LOOKUP_PARENT_VISIBLE:
      return setLookupParentVisible(state, action);
      case types.SET_RANGE:
        return setRange(state, action);
    default:
      return result;
  }
}


function setLookupParentVisible(state, action){
  let lookupParent = state.lookupParent;
  lookupParent = Object.assign({}, lookupParent, { visible: action.visible });
  return Object.assign({}, state, { lookupParent })
}

function setRange(state, action) {
  let zipCodeRanges = state.currentRecord.zipCodeRanges;
  let zip = zipCodeRanges[0] || {start:"",end:""}
  zip[action.field] = action.value 
  zipCodeRanges = zipCodeRanges.filter((item,index)=> index > 0) || []
  zipCodeRanges.unshift(zip)
  const currentRecord = Object.assign({}, state.currentRecord,{zipCodeRanges})
  return Object.assign({}, state, { currentRecord })
}


