
    import React from 'react';
    import FreightCalculationFormPageContainer from './FreightCalculationFormPageContainer';
    
    export class FreightCalculationFormPage extends React.Component{
        render(){
            return (
                <FreightCalculationFormPageContainer {...this.props}/>
            )
        }
    }
    