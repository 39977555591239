import React from 'react';
import './ImportFiles.scss';
import { Dropdown } from 'primereact/dropdown';
import { FileUpload } from 'primereact/fileupload';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar';

export const ImportFilesForm = (props) => (
    <div className="importfile-form">
        <div className="p-grid">
            <div className="p-col-12">
                <div className="p-grid">
                    <div className="p-col-2 form-field-container">
                        <label>De</label>
                        <Calendar showIcon={true} value={props.logFilter.initialDateFilter} onBlur={props.onBlurInitialDateFilter} showTime={false} onChange={props.initialDateFilterChangeHandler} disabled={false} name="initialDateFilter" dateFormat="dd/mm/yy" />
                    </div>
                    <div className="p-col-2 form-field-container">
                        <label>Até</label>
                        <Calendar showIcon={true} value={props.logFilter.finalDateFilter} onBlur={props.onBlurFinalDateFilter} showTime={false} onChange={props.finalDateFilterChangeHandler} disabled={false} name="finallDateFilter" dateFormat="dd/mm/yy" />
                    </div>
                    <div className="p-col-12 form-field-container lxSelectFile">
                        <div className="form-field-container">
                            <label className="lxRequiredField">Tipo do arquivo</label>
                            <Dropdown value={props.currentFileType} onChange={props.onChangeFileType} name="currentFileType" options={props.fileTypes}></Dropdown>
                        </div>


                        <div className="form-field-container">
                            <br></br>
                            <button
                                title={`${props.importFilesVisible ? "Minimizar" : "Importar Arquivos"}`}
                                onClick={props.toggleImportFiles}
                                style={{ marginRight: ".25em", marginLeft: ".50em" }}
                                className={props.currentFileType ? "lognex-btn-highlighted" : ""}
                                disabled={!props.currentFileType}>
                                <i className={`pi ${props.importFilesVisible ? "pi-minus" : "pi-cloud-upload"}`}
                                    style={{ fontSize: '30px' }}>
                                </i>
                            </button>
                        </div>
                    </div>

                    <div className="p-col-12 form-field-container lxUploadFile" style={{ display: props.importFilesVisible ? "" : "none" }}>
                        <label>Arquivos</label>
                        <FileUpload
                            value={props.files}
                            maxFileSize={50000}
                            accept={props.currentFileType && props.currentFileType.startsWith("EDI") ? ".txt" : ".xml"}
                            name="files"
                            chooseLabel="Selecionar Arquivos"
                            uploadLabel=""
                            cancelLabel=""
                            url="/api/v1/lognex/resource/upload"
                            onBeforeSend={props.onBeforeSend}
                            onUpload={props.onUploadSucess}
                            onError={props.onUploadError}
                            multiple={true}
                        />
                    </div>
                    <div className="p-col-12 form-field-container">
                    </div>
                    <div className="p-col-12 form-field-container">
                        <DataTable
                            value={props.records}
                            onRowClick={props.onClickLogRow}
                            emptyMessage="Não foram encontrados registros"
                            selectionMode="single"
                            onFilter={props.onFilter}
                            onSort={props.onSort}
                            onPage={props.onPage}
                            lazy={true}
                            totalRecords={props.totalRecords}
                            sortOrder={props.order}
                            sortField={props.sortField}
                            paginator={true}
                            first={props.first}
                            rows={props.rows}
                            rowsPerPageOptions={[5, 10, 20, 50]}
                            resizableColumns={true}
                        >
                            {props.columns}

                        </DataTable>
                    </div>
                    <Dialog
                        header={props.dialogLogRecord.header +
                            (props.currentLogRecord && props.currentLogRecord.tags ? props.currentLogRecord.tags.fileName : "")}
                        visible={props.dialogLogRecord.visible}
                        style={{ width: '40vw' }}
                        modal={props.dialogLogRecord.modal}
                        onHide={props.onHideDialogLogRecord}
                        baseZIndex={1}
                    >
                        <div className="p-grid">
                            <div className="p-col-12">
                                <div className="p-grid">
                                    <div className="p-col-12 form-field-container">
                                        <label>Código</label>
                                        <label>{props.currentLogRecord && props.currentLogRecord.oid}</label>
                                    </div>
                                    <div className="p-col-12 form-field-container">
                                        <label>Data</label>
                                        <label>{props.currentLogRecord && props.currentLogRecord.createdAt}</label>
                                    </div>
                                    {/* <div className="p-col-8 form-field-container">
                                        <label>Hora</label>
                                        <label>{props.currentLogRecord && props.currentLogRecord.hour}</label>
                                    </div> */}

                                    <div className="p-col-4 form-field-container">
                                        <label>Extensão</label>
                                        <label>{props.currentLogRecord && props.currentLogRecord.tags && props.currentLogRecord.tags.extension}</label>
                                    </div>
                                    <div className="p-col-4 form-field-container">
                                        <label>Tipo</label>
                                        <label>{props.currentLogRecord && props.currentLogRecord.type}</label>
                                    </div>
                                    <div className="p-col-4 form-field-container">
                                        <label>Situação</label>
                                        <label>{props.currentLogRecord && props.currentLogRecord.status}</label>
                                    </div>
                                    <div className="p-col-12 form-field-container">
                                        <label>Transportadora</label>
                                        <label>{props.currentLogRecord && props.currentLogRecord.tags && props.currentLogRecord.tags.carrier}</label>
                                    </div>

                                    <div className="p-col-12 form-field-container">
                                        <label>Chave</label>
                                        <label>{props.currentLogRecord && props.currentLogRecord.tags && props.currentLogRecord.tags.key}</label>
                                    </div>
                                    <div className="p-col-12 form-field-container">
                                        <label>Código do Recurso</label>
                                        <label>{props.currentLogRecord && props.currentLogRecord.tags && props.currentLogRecord.tags.resource}</label>
                                    </div>
                                    <div className="p-col-12 form-field-container">
                                        <label>Descrição</label>
                                        <label>{props.currentLogRecord && props.currentLogRecord.description}</label>
                                    </div>
                                    <div className="p-col-12 form-field-container">
                                        <DataTable
                                            value={props.currentLogRecord && props.currentLogRecord.logs ? props.currentLogRecord.logs.reverse() : []}
                                            emptyMessage="Não foram encontrados registros"
                                            resizableColumns={true}
                                            scrollable={true} 
                                            scrollHeight="250px"
                                        >
                                            {props.detailColumns}

                                        </DataTable>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>

    </div >
)
