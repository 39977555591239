
import React from "react";
import { connect } from "react-redux";
import Toolbar from "../../../components/toolbar/Toolbar";
import { Column } from 'primereact/column';
import { routes } from '../../../constants';
import { Page } from "../../../components/page/Page";
import { ZipCodeRangeList } from "./ZipCodeRangeList";
import { actions } from '../zipCodeRange.actions'
import { AppService, COMPANY_CHANGE_EVENT_NAME, BUSINESS_UNIT_CHANGE_EVENT_NAME } from "../../../services/app.service";
import { actions as appActions } from '../../../store/app';
import * as menuActions from '../../../store/menu/menu.actions';
import * as utils from '../../../utils'

const mapStateToProps = state => {
  const currentMenu = state.menuState.currentRecord;
  const userConfigurations = state.appState.userConfigurations;
  let isFavorite = utils.isFavorite(currentMenu, userConfigurations);
  return {
    columns: state.crudZipCodeRangeState.columns,
    records: state.crudZipCodeRangeState.records,
    first: state.crudZipCodeRangeState.index,
    rows: state.crudZipCodeRangeState.max,
    totalRecords: state.crudZipCodeRangeState.count,
    sortField: state.crudZipCodeRangeState.sortField,
    order: state.crudZipCodeRangeState.sortOrder,
    menuItems: state.menuState.items,
    currentMenu,
    isFavorite,
  }
}
const mapDispatchToProps = dispatch => {
  return {
    paginate: paginationParams => dispatch(actions.paginate(paginationParams)),
    sortOrder: sortParams => dispatch(actions.sortOrder(sortParams)),
    applyFilter: filterParams => {
      dispatch(actions.setType(null));
      dispatch(actions.applyFilter(filterParams))
    },
    load: () => dispatch(actions.load()),
    userLastAccess: (oidMenu) => dispatch(appActions.userLastAccess(oidMenu)),
    setCurrentMenu: (menuPath) => dispatch(menuActions.setCurrentRecord(menuPath)),
    toggleFavorite: (isFavorite, oidMenu) => {
      dispatch(appActions.toggleFavorite(isFavorite, oidMenu))
    }
  }
}

class ZipCodeRangeListPageContainer extends React.Component {

  newHandler = () => {
    this.props.history.push(`${routes.PATH_ZIPCODERANGE}/novo`)
  }
  componentWillReceiveProps(newProps){
    if ((!this.props.menuItems || this.props.menuItems.length === 0 ) && newProps.menuItems && newProps.menuItems.length > 0){
      const menuPath = this.props.history.location.pathname;
      this.props.setCurrentMenu(menuPath)
    }

    if ((!this.props.currentMenu && newProps.currentMenu) ||
      (this.props.currentMenu !== newProps.currentMenu)) {
      const oidMenu = newProps.currentMenu.oid;
      this.props.userLastAccess(oidMenu)
    }
  }


  componentDidMount() {
    AppService.on(COMPANY_CHANGE_EVENT_NAME, this);
    AppService.on(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
    const menuPath = this.props.history.location.pathname;
    this.props.setCurrentMenu(menuPath)
    this.props.load();
  }
  componentWillUnmount() {
    AppService.off(COMPANY_CHANGE_EVENT_NAME, this);
    AppService.off(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
  }

  onReceiveAppNotifications = (eventName, params) => {
    if (eventName === COMPANY_CHANGE_EVENT_NAME || eventName === BUSINESS_UNIT_CHANGE_EVENT_NAME) {
      this.props.load();
    }
  }

  onPage = (e) => {
    this.props.paginate({
      max: e.rows,
      index: e.first
    })
  }
  onSort = (e) => {
    console.log('sorting', e);
    this.props.sortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField
    })


  }
  onFilter = (e) => {
    const filters = [];
    const keys = Object.keys(e.filters) || [];
    keys.forEach(key => {
      if (key !== "description") {
        filters.push({
          campo: key,
          valor: e.filters[key].value,
          tipoFiltro: "IGUAL_A"
        });
      } else {
        filters.push({
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: 'CONTEM'
      });
    }
  });
    this.props.applyFilter(filters)
  }

  onRowClick = (e) => {
    const oid = e && e.data && e.data.oid;
    if (oid) {
      this.props.history.push(`${routes.PATH_ZIPCODERANGE}/${oid}`)
    }
  }
  onFavorite = (e) => {
    if (this.props.currentMenu) {
      const oidMenu = this.props.currentMenu.oid
      const isFavorite = this.props.isFavorite;
      this.props.toggleFavorite(isFavorite, oidMenu)
    }
  }

  render() {
    const isFavorite = this.props.isFavorite;
    const header = (
      <div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h1>Faixas de CEPs </h1>

          <i className={`pi pi-star lxFavoriteStar ${isFavorite ? 'lxFavorite' : ''}`}
            title={isFavorite ? 'Remover dos favoritos' : 'Adicionar aos favoritos'} onClick={this.onFavorite}></i>
        </div>
        <hr className="lxSplitter" ></hr>
        <div>
          <Toolbar onNew={this.newHandler} newButtonStyle='highlighted'>
          </Toolbar >
        </div>
      </div>
    );


    const zipTemplate = (rowData, column) => {
      let zipStart = rowData.zipCodeRanges[0].start ? rowData.zipCodeRanges[0].start : "";
      let zipEnd = rowData.zipCodeRanges[0].start ? rowData.zipCodeRanges[0].end : "";
      let zip = "";
      if (column.columnKey === 'initialZipCode') {
        zip = utils.formatCep(zipStart.toString());
      } 
      if (column.columnKey === 'finalZipCode') {
        zip = utils.formatCep(zipEnd.toString());
      }

      return (
        <span style={{ display: "inline-block", width: "100%", textAlign: "center" }}>{ zip }</span>
      );
     
    }


    
    const columns = this.props.columns.map(col => {
      if (col.columnKey === 'initialZipCode') {
        return (<Column {...col} key={col.columnKey} body={zipTemplate} />);
      } 
      if (col.columnKey === 'finalZipCode') {
        return (<Column {...col} key={col.columnKey} body={zipTemplate} />);
      }
      return <Column {...col} key={col.columnKey} />
    })

    const content = <ZipCodeRangeList  
                      {...this.props} 
                      onRowClick={this.onRowClick} 
                      onFilter={this.onFilter} 
                      onPage={this.onPage} 
                      onSort={this.onSort} 
                      columns={columns} 
                    />;
    return <Page header={header} content={content} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ZipCodeRangeListPageContainer);
