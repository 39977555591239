import React from "react";
import { connect } from "react-redux";
import Toolbar from "../../../components/toolbar/Toolbar";
import { routes } from '../../../constants';
import { Page } from "../../../components/page/Page";
import { PolicesResourcesForm } from './PolicesResourcesForm';
import { actions } from '../policesResources.actions';
import { Column } from "primereact/column";
import { actions as actionsPolices } from '../../polices/polices.actions';
import { actions as actionsMenu } from '../../menu/menu.actions';

const mapStateToProps = state => {

  const lookupPolicesBase = {
    records: state.crudPolicesState.records,
    columns: state.crudPolicesState.columns.filter((col) => col.field === "oid" || col.field === "name" || col.field === "description").map((col) => <Column key={col.field} {...col} />),
    first: state.crudPolicesState.index,
    rows: state.crudPolicesState.max,
    totalRecords: state.crudPolicesState.count,
    sortField: state.crudPolicesState.sortField,
    order: state.crudPolicesState.sortOrder,
    rowsPerPageOptions:[20,50,100]
  };
  let lookupPolices = Object.assign({}, state.crudPolicesResourcesState.lookupPolices, lookupPolicesBase);

  const lookupMenusBase = {
    records: state.crudMenuState.records,
    columns: state.crudMenuState.columns.filter((col) => col.field === "oid" || col.field === "name" || col.field === "path" || col.field === "key").map((col) => <Column key={col.field} {...col} />),
    first: state.crudMenuState.index,
    rows: state.crudMenuState.max,
    totalRecords: state.crudMenuState.count,
    sortField: state.crudMenuState.sortField,
    order: state.crudMenuState.sortOrder,
    rowsPerPageOptions:[20,50,100]
  };
  let lookupMenus = Object.assign({}, state.crudPolicesResourcesState.lookupMenus, lookupMenusBase);

  return {
    record: state.crudPolicesResourcesState.currentRecord,
    lookupPolices,
    lookupMenus
  };
};

const mapDispatchToProps = dispatch => {
  return {
    changeHandler: event => {
      const target = event.target;
      const field = target.name;
      let value = target.value;

      dispatch(actions.removeErrors([{ field }]));
      dispatch(actions.setField(field, value));
    },
    saveHandler: event => {
      dispatch(actions.save());
    },
    removeHandler: event => {
      dispatch(actions.remove());
    },
    newHandler: event => {
      dispatch(actions.makeNew());
    },
    loadById: oid => dispatch(actions.loadById(oid)),

    lookupPolicesSetVisible: (visible) => dispatch(actions.setLookupPolicesVisible(visible)),
    lookupPolicesPaginate: (paginationParams) => dispatch(actionsPolices.paginate(paginationParams)),
    lookupPolicesSortOrder: (sortParams) => dispatch(actionsPolices.sortOrder(sortParams)),
    lookupPolicesApplyFilter: (filterParams) => {
      dispatch(actionsPolices.setType(null));
       dispatch(actionsPolices.applyFilter(filterParams)); 
    },

    setLookupPolices: (polices) => { 
      const { oid, name } = polices || {};
      dispatch(actions.setField("polices", polices ? { oid, name } : null));
      dispatch(actions.setField("policeId", oid));
    },

    //menu
    lookupMenusSetVisible: (visible) => dispatch(actions.setLookupMenusVisible(visible)),
    lookupMenusPaginate: (paginationParams) => dispatch(actionsMenu.paginate(paginationParams)),
    lookupMenusSortOrder: (sortParams) => dispatch(actionsMenu.sortOrder(sortParams)),
    lookupMenusApplyFilter: (filterParams) => {
      dispatch(actionsMenu.setType(null));
      dispatch(actionsMenu.applyFilter(filterParams)); 
    },

    setLookupMenus: (menu) => { 
      const { oid, name, path, key } = menu || {};
      dispatch(actions.setField("menu", menu ? { oid, name, path, key } : null));
      dispatch(actions.setField("resourceKey", key));
    },
  };
};

class PolicesResourcesFormPageContainer extends React.Component {

  constructor(props) {
    super(props);
    actions.afterSave = ({ oid }) => {
      this.props.history.replace(`${routes.PATH_POLICES_RESOURCES}/${oid}`);
    };
    actions.afterNew = () => {
      this.props.history.replace(`${routes.PATH_POLICES_RESOURCES}/novo`);
    };
    actions.afterRemove = () => {
      this.props.history.replace(`${routes.PATH_POLICES_RESOURCES}`);
    };
  }

  componentDidMount() {
    const oid = this.props.match.params.id;

    if (oid && parseInt(oid)) {
      this.props.loadById(oid);
    } else {
      this.props.newHandler();
    }
  }
  searchHandler = () => {
    this.props.history.push(`${routes.PATH_POLICES_RESOURCES}`)
  }


  //Polices
  lookupPolicesOnPage = (e) => {
    this.props.lookupPolicesPaginate({
      max: e.rows,
      index: e.first,
    });
  };
  lookupPolicesOnSort = (e) => {
    this.props.lookupPolicesSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField,
    });
  };
  lookupPolicesOnFilter = (e) => {
    const filters = [];
    const keys = Object.keys(e.filters) || [];
    keys.forEach((key) => {
      filters.push({
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: "CONTEM",
      });
    });

    this.props.lookupPolicesApplyFilter(filters);
  };
  lookupPolicesOnClick = (e) => {
    const filters = [];
    this.props.lookupPolicesApplyFilter(filters);
    this.props.lookupPolicesSetVisible(true);
  };
  lookupPolicesOnHide = (e) => {
    this.props.lookupPolicesSetVisible(false);
  };
  lookupPolicesOnConfirm = (e) => {
    this.props.setLookupPolices(e.selection);
    this.props.lookupPolicesSetVisible(false);
  };
  lookupPolicesOnCancel = (e) => {
    this.props.setLookupPolices(null);
    this.props.lookupPolicesSetVisible(false);
  };

  lookupPolicesOnEdit = () => {
    if (this.props.record.polices && this.props.record.polices.oid) {
      this.props.history.push(`${routes.PATH_POLICES}/${this.props.record.polices.oid}`);
    }
  };

  lookupPolicesOnComplete = (e) => { };

  lookupPolicesOnInputFilter = (e) => {
    const value = e.value;
    if (typeof value === "string") {
      const filters = [
        {
          campo: "name",
          valor: e.value,
          tipoFiltro: "CONTEM",
        },
      ];

      this.props.setField("polices.name", e.value);
      this.props.lookupPolicesApplyFilter(filters);
    } else {
      this.props.setLookupPolices(value);
    }
  };



  //Menu
  lookupMenusOnPage = (e) => {
    this.props.lookupMenusPaginate({
      max: e.rows,
      index: e.first,
    });
  };
  lookupMenusOnSort = (e) => {
    this.props.lookupMenusSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField,
    });
  };
  lookupMenusOnFilter = (e) => {
    const filters = [];
    const keys = Object.keys(e.filters) || [];
    keys.forEach((key) => {
      filters.push({
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: "CONTEM",
      });
    });

    this.props.lookupMenusApplyFilter(filters);
  };
  lookupMenusOnClick = (e) => {
    const filters = [];
    this.props.lookupMenusApplyFilter(filters);
    this.props.lookupMenusSetVisible(true);
  };
  lookupMenusOnHide = (e) => {
    this.props.lookupMenusSetVisible(false);
  };
  lookupMenusOnConfirm = (e) => {
    this.props.setLookupMenus(e.selection);
    this.props.lookupMenusSetVisible(false);
  };
  lookupMenusOnCancel = (e) => {
    this.props.setLookupMenus(null);
    this.props.lookupMenusSetVisible(false);
  };

  lookupMenusOnEdit = () => {
    if (this.props.record.menu && this.props.record.menu.oid) {
      this.props.history.push(`${routes.PATH_MENU}/${this.props.record.menu.oid}`);
    }
  };

  lookupMenusOnComplete = (e) => { };

  lookupMenusOnInputFilter = (e) => {
    const value = e.value;
    if (typeof value === "string") {
      const filters = [
        {
          campo: "name",
          valor: e.value,
          tipoFiltro: "CONTEM",
        },
      ];

      this.props.setField("menu.name", e.value);
      this.props.lookupMenusApplyFilter(filters);
    } else {
      this.props.setLookupMenus(value);
    }
  };


  render() {
    let recordId = this.props.record ? this.props.record.oid : null
    const title = `Politicas-Menu ${recordId || 'nova'}`
    const header = (
      <div>
        <h1>{title}</h1>
        <hr className="lxSplitter" ></hr>
        <div>
          <Toolbar
            onSave={this.props.saveHandler} saveButtonStyle='highlighted'
            onRemove={this.props.removeHandler} removeButtonStyle='danger'
            onNew={this.props.newHandler}
            onSearch={this.searchHandler}>
          </Toolbar>
        </div>
      </div>
    );

    const content = (<PolicesResourcesForm
      record={this.props.record}
      changeHandler={this.props.changeHandler}

      //Politicas
      lookupPolices={this.props.lookupPolices}
      lookupPolicesOnHide={this.lookupPolicesOnHide}
      lookupPolicesOnConfirm={this.lookupPolicesOnConfirm}
      lookupPolicesOnCancel={this.lookupPolicesOnCancel}
      lookupPolicesOnPage={this.lookupPolicesOnPage}
      lookupPolicesOnSort={this.lookupPolicesOnSort}
      lookupPolicesOnFilter={this.lookupPolicesOnFilter}
      lookupPolicesOnClick={this.lookupPolicesOnClick}
      lookupPolicesOnEdit={this.lookupPolicesOnEdit}
      lookupPolicesOnComplete={this.lookupPolicesOnComplete}
      lookupPolicesOnInputFilter={this.lookupPolicesOnInputFilter}
      //Menu
      lookupMenus={this.props.lookupMenus}
      lookupMenusOnHide={this.lookupMenusOnHide}
      lookupMenusOnConfirm={this.lookupMenusOnConfirm}
      lookupMenusOnCancel={this.lookupMenusOnCancel}
      lookupMenusOnPage={this.lookupMenusOnPage}
      lookupMenusOnSort={this.lookupMenusOnSort}
      lookupMenusOnFilter={this.lookupMenusOnFilter}
      lookupMenusOnClick={this.lookupMenusOnClick}
      lookupMenusOnEdit={this.lookupMenusOnEdit}
      lookupMenusOnComplete={this.lookupMenusOnComplete}
      lookupMenusOnInputFilter={this.lookupMenusOnInputFilter}


    />);
    return (<Page header={header} content={content} />);
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(PolicesResourcesFormPageContainer);
