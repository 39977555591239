import React from 'react';
import UsuarioListPageContainer from './UsuarioListPageContainer';

export class UsuarioListPage extends React.Component{
    
    render(){
      return (
        <UsuarioListPageContainer {...this.props}/>
      )
    }
}
