import React from 'react';
import axios from 'axios';

export class VersionNotes extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      versionInfo : {
        number: "0.0.0",
        date:"00/00/2019 00:00",
        notes:[
          {
            title:"Email Service",
            content:[
              "Liberação do serviço de email para processamento de documentos"
            ]
          }
        ]
      }
    }
  }
  componentDidMount(){
    axios.get('notes.json')
    .then((response =>{
      this.setState((prev) =>{
        return {
          versionInfo:response.data
        }
      })
    }))
    .catch(()=>{
    })
  }
  render() {
    const version = this.state.versionInfo;
    const data =  version.notes || [];
    const notes = data.map((note,i) =>{
      const content = note.content.map((it,index) =>{
        return (<p key={index}>{it}</p>)
      })
      return(
        <li key={i}>
        <div>
          <h3>{note.title}</h3>
          {content}
        </div>
      </li>
      )
    })
    return (
      <div>
        <h2>Lognex</h2>
        <div className="p-grid">
          <p className="p-col-12">Versão: {version.number}-prod</p>
        </div>
        <div className="p-grid">
          <p className="p-col-12">Atualizado em : {version.date}</p>
        </div>
        <h2>Notas da versão</h2>
        <ul>
          {notes}
        </ul>
      </div>
    )
  }
}
