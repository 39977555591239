import { build } from "../../services/crudFactory";
import { Action } from "../../services/actions.factory";
import api from "../../services/api.service";
const BASE_PATH = "/lognex/tracking";
const prefix = "tracking";

const crud = build(false, BASE_PATH);

const newTypes = {
  SET_FIELD_TRACKING_FILTER: `${prefix}setFieldTrackingFilter`,
  SET_FIELD_OCCURRENCE_TYPE_FILTER: `${prefix}setFieldOccurrenceTypeFilter`,
  SET_ADDITIONAL_DESCRIPTION: `${prefix}setAdditionalDesctiption`,
  SET_LOOKUP_CARRIER_VISIBLE: `${prefix}setLookupCarrierVisible`,
  SET_LOOKUP_OCCURENCE_TYPE_VISIBLE: `${prefix}setlookupOccurrenceTypeVisible`,
  SET_CURRENT_FILTER_DELIVERY_STATUS: `${prefix}setCurrentFilterDeliveryStatus`,
  SHOW_MODAL_DELIVERY_DATE_VISIBLE: `${prefix}showModalDeliveryDateVisible`,
  SET_RECORD_DELIVERY_DATE: `${prefix}setRecordDeliveryDate`,
  UPDATE_DELIVERY_DATE: `${prefix}updateDeliveryDate`,
  BACKUP_DELIVERY_DATE: `${prefix}backupDeliveryDate`,
  SELECT_RECORD: `${prefix}selectedRecord`,
  SET_FIELD_SELECTED_RECORD: `${prefix}setFieldSelectedRecord`,
  SET_DATE_COLUMN_FILTER: `${prefix}setDateColumnFilter`,
  DOWNLOAD: `${prefix}download`,
  SHOW_MODAL_CUBAGE_AND_VEHICLE_TYPE_VISIBLE: `${prefix}showModalCubageAndVehicleTypeVisible`,
  SET_VEHICLE_TYPE: `${prefix}setVehicleType`,
  SET_CUBAGE: `${prefix}setCubage`,
  SET_NFE_ID: `${prefix}setNFeID`,
  SET_LIST_VEHICLES_TYPE: `${prefix}setListVehiclesType`,
  SHOW_MODAL_CUSTOM_FIELDS: `${prefix}showModalCustomFields`,
  SET_RECORD_CUSTOM_FIELDS: `${prefix}setRecordWithCustomFields`,
  SET_CUSTOM_FIELDS: `${prefix}setCustomFields`,
  ON_REMOVE_CUSTOM_FIELDS: `${prefix}removeItemCustomFieldsHandler`,
  ON_ADD_CUSTOM_FIELDS: `${prefix}onAddNewCustomFieldsHandler`,
  SET_BAIXA_MANUAL: `${prefix}setBaixaManual`,
};

const actions = new Action(prefix, crud);

const types = Object.assign({}, actions.types, newTypes);

actions.setBaixaManual = (baixaManual) => {
  return {
    type: types.SET_BAIXA_MANUAL,
    baixaManual
  };
};
actions.removeItemCustomFieldsHandler = (item) => {
  return {
    type: types.ON_REMOVE_CUSTOM_FIELDS,
    item
  };
};
actions.onAddNewCustomFieldsHandler = () => {
  return {
    type: types.ON_ADD_CUSTOM_FIELDS
  };
};

actions.setCustomFields = (uuid, field, value) => {
  return {
    type: types.SET_CUSTOM_FIELDS,
    uuid, field, value
  };
};
actions.setRecordWithCustomFields = (data) => {
  return {
    type: types.SET_RECORD_CUSTOM_FIELDS,
    data,
  };
};
actions.showModalCustomFields = (visible) => {
  return {
    type: types.SHOW_MODAL_CUSTOM_FIELDS,
    visible,
  };
};

actions.download = (filters) => {
  return (dispatch) => {
    api
      .post("/api/v1/lognex/tracking/export/nfes", { filters })
      .then((response) => {
        const data = response.data;
        dispatch(actions.load());
        console.log("download data=", data)
        const anchor = document.createElement("a");
        anchor.href = data.presigned_url;
        anchor.target = "_blank";
        document.body.append(anchor);
        anchor.click();
        anchor.remove();
      })
      .catch((err) => {
        console.log(err);
        dispatch(actions.hideLoader());
      });
  };
};

actions.setCubage = (value) => {
  return {
    type: types.SET_CUBAGE,
    value,
  };
};
actions.setNFeID = (value) => {
  return {
    type: types.SET_NFE_ID,
    value,
  };
};
actions.setVehicleType = (value) => {
  return {
    type: types.SET_VEHICLE_TYPE,
    value,
  };
};
actions.setListVehiclesType = (data) => {
  return {
    type: types.SET_LIST_VEHICLES_TYPE,
    data,
  };
};

actions.setDateColumnFilter = (field, value) => {
  return {
    type: types.SET_DATE_COLUMN_FILTER,
    field,
    value,
  };
};

actions.setFieldSelectedRecord = (field, value) => {
  return {
    type: types.SET_FIELD_SELECTED_RECORD,
    field,
    value,
  };
};

actions.selectedRecord = (record) => {
  return {
    type: types.SELECT_RECORD,
    record,
  };
};

actions.backupDeliveryDate = (deliveryDate) => {
  return {
    type: types.BACKUP_DELIVERY_DATE,
    deliveryDate,
  };
};

actions.setRecord = (record) => {
  return {
    type: types.SET_RECORD,
    record,
  };
};
actions.showModalDeliveryDateVisible = (visible) => {
  return {
    type: types.SHOW_MODAL_DELIVERY_DATE_VISIBLE,
    visible,
  };
};
actions.showModalCubageAndVehicleTypeVisible = (visible) => {
  return {
    type: types.SHOW_MODAL_CUBAGE_AND_VEHICLE_TYPE_VISIBLE,
    visible,
  };
};

actions.setLookupCarrierVisible = (visible) => {
  return {
    type: types.SET_LOOKUP_CARRIER_VISIBLE,
    visible,
  };
};
actions.setlookupOccurrenceTypeVisible = (visible) => {
  return {
    type: types.SET_LOOKUP_OCCURENCE_TYPE_VISIBLE,
    visible,
  };
};

actions.setFieldTrackingFilter = (fieldName, fieldValue) => {
  return {
    type: types.SET_FIELD_TRACKING_FILTER,
    fieldName,
    fieldValue,
  };
};

actions.setFieldOccurrenceTypeFilter = (fieldName, fieldValue) => {
  return {
    type: types.SET_FIELD_OCCURRENCE_TYPE_FILTER,
    fieldName,
    fieldValue,
  };
};
actions.setAdditionalDesctiption = (additionalDescription) => {
  return {
    type: types.SET_ADDITIONAL_DESCRIPTION,
    additionalDescription,
  };
};

actions.setCurrentFilterDeliveryStatus = (currentFilterDeliveryStatus) => {
  return {
    type: types.SET_CURRENT_FILTER_DELIVERY_STATUS,
    currentFilterDeliveryStatus,
  };
};

actions.updateDeliveryDateOnServer = () => {
  return (dispatch, getState) => {
    const occurrenceType = getState().trackingState.occurrenceTypeFilter;
    const baixaManual = getState().trackingState.baixaManual;
    const userName = getState().appState.currentUser;
    const oldDeliveryDate = getState().trackingState.backupDeliveryDate;
    const { oid, deliveryDate } = getState().trackingState.selectedRow;
    const additionalDescription = getState().trackingState.additionalDescription;
    var codeOccurrence = parseInt(occurrenceType.code)

    if(codeOccurrence == 1 && !deliveryDate){
      dispatch(
        actions.messageActions.messageShowMessages(
          actions.makeMessages(
            ["Para entrega realizada deve-se informar data"],
            "error"
          )
        )
      );
      return
    } 

    if (occurrenceType) {
        const params = {
          userName: userName,
          oid: oid,
          deliveryDate: deliveryDate,
          occurrenceType: occurrenceType,
          additionalDescription: additionalDescription,
          baixaManual,
        };
        dispatch(actions.showLoader());
        api
          .post("/api/v1/lognex/tracking/updatedeliverydate", params)
          .then(({ data }) => {
            dispatch(actions.hideLoader());
            dispatch(actions.backupDeliveryDate(null));
            dispatch(actions.load());
            dispatch(
              actions.messageActions.messageShowMessages(
                actions.makeMessages(
                  ["Data de entrega atualizada com sucesso"],
                  "success"
                )
              )
            );
          })
          .catch((err) => {
            console.log(err);
            dispatch(
              actions.messageActions.messageShowMessages(
                actions.makeMessages(
                  ["Não foi possivel alterar a data de entrega"],
                  "error"
                )
              )
            );
            dispatch(
              actions.setFieldSelectedRecord("deliveryDate", oldDeliveryDate)
            );
            dispatch(actions.backupDeliveryDate(null));
            dispatch(actions.hideLoader());
          });
      }else{
        dispatch(
          actions.messageActions.messageShowMessages(
            actions.makeMessages(
              ["Selecione um tipo de ocorrência"],
              "error"
            )
          )
        );
      }
  };
};

actions.loadVehiclesType = () => {
  return (dispatch, getState) => {
    const filters = [
      {
        campo: "status",
        valor: true,
        tipoFiltro: "IGUAL_A",
      },
    ];

    const params = {
      filters,
      max: 100,
      index: 0,
    };
    dispatch(actions.showLoader());
    api
      .post("/api/v1/lognex/vehicletypes/all", params)
      .then(({ data }) => {
        dispatch(actions.hideLoader());
        dispatch(actions.setListVehiclesType(data.records));
      })
      .catch((error) => {
        console.log(error);
        dispatch(
          actions.messageActions.messageShowMessages(
            actions.makeMessages(
              ["Não foi possivel carregar os tipos de veículos"],
              "error"
            )
          )
        );
        dispatch(actions.hideLoader());
      });
  };
};

actions.updateCubageAndVehicleType = () => {
  return (dispatch, getState) => {
    const { nfeID, vehicleType, cubage } =
      getState().trackingState.selectCubageVehicleType;
    if (nfeID) {
      let vehicleName = vehicleType ? vehicleType.name : "";
      const params = {
        oid: nfeID,
        cubage: cubage.value,
        vehicleType: vehicleName,
      };
      dispatch(actions.showLoader());
      api
        .post("/api/v1/lognex/tracking/updatecubageandvehicletype", params)
        .then(({ data }) => {
          if (data) {
            dispatch(actions.hideLoader());
            dispatch(actions.load());
          }
          dispatch(actions.setVehicleType(null));
          dispatch(actions.setCubage({ value: 0 }));
          dispatch(actions.setNFeID(0));
          dispatch(actions.selectedRecord(null));
          dispatch(
            actions.messageActions.messageShowMessages(
              actions.makeMessages(
                ["Cadastro realizado com sucesso."],
                "success"
              )
            )
          );
        })
        .catch((error) => {
          console.log(error);
          dispatch(actions.setVehicleType(null));
          dispatch(actions.setCubage({ value: 0 }));
          dispatch(actions.setNFeID(0));
          dispatch(actions.selectedRecord(null));
          dispatch(
            actions.messageActions.messageShowMessages(
              actions.makeMessages(
                ["Não foi possivel cadastrar a cubagem e tipo de veículo"],
                "error"
              )
            )
          );
          dispatch(actions.hideLoader());
        });
    }
  };
};

actions.importFileXLSX = (fileXLSX) => {
  return (dispatch) => {
    dispatch(actions.showLoader());
    api
      .uploadFiles(`/api/v1/lognex/tracking/import/xlsx`, fileXLSX, null)
      .then((data) => {
        console.log("data => ", data)
        //verificar se houve algum erro no envio da planilha
        if (data && data.data && data.data.length > 0) {
          const messaError = data.data.join('\n');
          dispatch(
            actions.messageActions.messageShowMessages(
              actions.makeMessages(
                [messaError],
                "error"
              )
            )
          );
        } else {
          dispatch(
            actions.messageActions.messageShowMessages(
              actions.makeMessages(
                ["Planilha enviada com sucesso."],
                "success"
              )
            )
          );
        }
        dispatch(actions.hideLoader());
        dispatch(actions.load());
      })
      .catch((error) => {
        console.log(error);
        dispatch(
          actions.messageActions.messageShowMessages(
            actions.makeMessages(
              ["Não foi possivel enviar o arquivo"],
              "error"
            )
          )
        );
        dispatch(actions.hideLoader());
      });
  }
}
actions.updateCustomFields = () => {
  return (dispatch, getState) => {
    const { oid, listCustomFields } = getState().trackingState.recordWithCustomFields;
    let customFields;
    for (var i = 0; i < listCustomFields.length; ++i) {
      if (String(listCustomFields[i].campo).trim().length > 0 && String(listCustomFields[i].value).trim().length > 0) {
        if (!customFields) {
          customFields = {};
        }
        customFields[listCustomFields[i].campo] = listCustomFields[i].value;
      }
    }

    dispatch(actions.showLoader());
    api
      .post(`/api/v1/lognex/tracking/updatecustomfields`, { oid, customFields })
      .then((data) => {
        if (data) {
          dispatch(
            actions.messageActions.messageShowMessages(
              actions.makeMessages(
                ["Atualização foi realizada com sucesso."],
                "success"
              )
            )
          );
          dispatch(actions.setRecordWithCustomFields(null));
          dispatch(actions.hideLoader());
          dispatch(actions.load());
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch(
          actions.messageActions.messageShowMessages(
            actions.makeMessages(
              ["Não foi possivel atualizar os dados"],
              "error"
            )
          )
        );
        dispatch(actions.setRecordWithCustomFields(null));
        dispatch(actions.hideLoader());
      });
  }
}

export { types, actions };
