import { types } from "./packing.actions";
import { Reducer } from "../../services/reducer.factory";

const initialState = {
  columns: [{
    field: "oid",
    header: "Código",
    columnKey: "oid",
    sortable: "false",
    filter: "false"
  }, {
    field: "code",
    header: "Código do Usuário",
    columnKey: "code",
    sortable: "false",
    filter: "false"
  }, {
    field: "name",
    header: "Nome",
    columnKey: "name",
    sortable: "false",
    filter: "false"
  }, {
    field: "unit",
    header: "Unidade",
    columnKey: "unit",
    sortable: "false",
    filter: "false"
  }, {
    field: "compartmentName",
    header: "Compartimento",
    columnKey: "compartmentName",
    sortable: "false",
    filter: "false"
  }, {
    field: "value",
    header: "Valor",
    columnKey: "value",
    sortable: "false",
    filter: "false"
  }, {
    field: "cubage",
    header: "Cubagem",
    columnKey: "cubage",
    sortable: "false",
    filter: "false"
  }
  ],
  currentRecord: {
    oid: "",
    code: "",
    name: "",
    unit: "",
    compartmentName: "",
    value: "",
    cubage: "",
  },

};

const reducer = new Reducer(initialState, types);

export function CRUDPackingReducer(state, action) {
  state = reducer.execute(state, action);
  return execute(state, action);
}

function execute(state, action) {
  let result = state;
  switch (action.type) {


  }
  return result;
}



