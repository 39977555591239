
    import React from 'react';
    import VehicleGroupListPageContainer from './VehicleGroupListPageContainer';
    
    export class VehicleGroupListPage extends React.Component{
        
        render(){
          return (
            <VehicleGroupListPageContainer {...this.props}/>
          )
        }
    }