import React from 'react'
import { connect } from "react-redux";
import LoginForm from './login-form.component';
import * as loginActions from './login.actions';
import './LoginPageContainer.scss'
import background from '../../assets/images/login/login_background.jpeg';
import CheckCode2FAForm from './check-code-2FA-form.component';

const mapStateToProps = state => {
    const twoFA = state.loginState.twoFA;
    return {
        credential: state.loginState.credential,
        errorMessage: state.loginState.errorMessage,
        twoFA: state.loginState.twoFA,
        code2FA: state.loginState.code2FA
    }
}
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        authenticate: () => {
            dispatch(loginActions.authenticate());
        },
        inputHandler: (event) => {
            const value = event.target.value;
            const key = event.target.name;
            const credential = { [key]: value };
            dispatch(loginActions.loginSetCredential(credential));
        },
        onChangeCode2FA: (event) => {
            const value = event.target.value; 
            dispatch(loginActions.onChangeCode2FA(value));
        },
        validateCode2FA: () => {
            dispatch(loginActions.validateCode2FA());
        },
        generateNewCode: () => {
            dispatch(loginActions.generateNewCode());
        }
    }
}



class LoginContainer extends React.Component {

    render() {
        const checkCodeF2FA = () => {
            if (this.props.twoFA && this.props.twoFA.enabled) {
                return <CheckCode2FAForm 
                        code2FA={this.props.code2FA}
                        twoFA={this.props.twoFA}
                        changeValue={this.props.onChangeCode2FA}
                        validateCode2FA={this.props.validateCode2FA}
                        generateNewCode={this.props.generateNewCode}
                        errorMessage={this.props.errorMessage}
                        />
            }
            return <></>;
        }
        const loginForm = () => {
            if (!this.props.twoFA) {
                return <LoginForm
                    errorMessage={this.props.errorMessage}
                    login={this.props.credential.login}
                    password={this.props.credential.password}
                    changeValue={this.props.inputHandler}
                    authenticate={this.props.authenticate} />
            } else {
                return <></>;
            }
        }
        return (
            <div className="login-page">
                <div className="login-page__content">
                    <div className="login-page__content-form">
                        {checkCodeF2FA()}
                        {loginForm()}
                    </div>

                    <img className="login-page__img-backgroud" src={background} alt="background" />

                </div>

            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);