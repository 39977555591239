import moment from 'moment'
import { build } from '../../services/crudFactory';
import { Action } from '../../services/actions.factory';
import api from "../../services/api.service";
import * as messageActions from '../../store/message/message.actions';
const prefix = 'orderManagement';
const BASE_PATH = '/api/v1/lognex/shipment-order';
const BASE_SHIPMENT_CARGO = '/api/v1/lognex/cargo-shipment'
const BASE_LABELS = '/api/v1/lognex/labels'

const crud = build(false, BASE_PATH);

const newTypes = {
    HIDE_LOADER: `${prefix}hideLoader`,
    SHOW_LOADER: `${prefix}showLoader`,
    SET_INDEX: `${prefix}SetIndex`,
    SET_NUMBER_FILTER: `${prefix}setNumberFilter`,
    SET_PAGE: `${prefix}SetPage`,
    SET_MAX: `${prefix}SetMax`,
    SET_COUNT: `${prefix}SetCount`,
    FETCH_ORDERS: `${prefix}FetchOrders`,
    SET_ORDERS: `${prefix}SetOrders`,
    ADD_ORDERS: `${prefix}AddOrders`,
    SET_ORDER: `${prefix}SetOrder`,
    LOAD_COUNT_ORDERS: `${prefix}loadCountOrders`,
    SET_COUNT_ORDERS: `${prefix}setCountOrders`,
    SET_INITIAL_DATE_FILTER: `${prefix}setInitialDateFilter`,
    SET_FINAL_DATE_FILTER: `${prefix}setFinalDateFilter`,
    SET_CARRIER_FILTER: `${prefix}setCarrierFilter`,
    SET_LOOKUP_CARRIER_VISIBLE: `${prefix}setLookupCarrierVisible`,
    LOAD_COUNT_SHIPMENT_ORDERS: `${prefix}loadCountShipmentOrders`,
    SET_COUNT_CARGO_SHIPMENT_ORDERS: `${prefix}setCountCargoShipmentOrders`,
    SET_SELECTED_ODERS: `${prefix}setSelectedOrders`,
    SET_SHIPMENTS: `${prefix}setShipments`,
    SET_SHIPMENT: `${prefix}setShipment`,
    ADD_SHIPMENTS: `${prefix}addShipments`,
    FETCH_SHIPMENT: `${prefix}fetchShipment`,
    SET_SELECTED_SHIPMENTS: `${prefix}setSelectedShipments`,
    SET_ORIGIN_FILTER: `${prefix}setOriginFilter`,
    SET_LOOKUP_ORIGIN_VISIBLE: `${prefix}setLookupOriginVisible`,
    SET_DESTINATION_FILTER: `${prefix}setDestinationFilter`,
    SET_LOOKUP_DESTINATION_VISIBLE: `${prefix}setLookupDestinationVisible`,
    APPLY_ORDER_FILTERS: `${prefix}applyOrderFilters`,
    APPLY_SHIPMENT_FILTERS: `${prefix}applyShipmentFilters`,
    SET_CURRENT_VIEW: `${prefix}setCurrentView`,
    CHECK_PENDENCE: `${prefix}hasCargoShipmentPendences`,
    SET_PENDENCE_EMAIL: `${prefix}setPendenceEmail`,
    SET_PENDENCE_QUOTE: `${prefix}setPendenceQuote`,
    SET_PENDENCE_CONFIRM_SHIPMENT_WEBHOOK: `${prefix}setPendenceConfirmShipmentWebhook`,
    SET_GROUP_FILTER: `${prefix}setGroupFilter`,
    SET_VISIBLE_MODAL_WIZARD_FILTER: `${prefix}setVisibleModalGrouperFilter`,
    SET_VISIBLE_MODAL_SEARCH_FILTER: `${prefix}setVisibleModalSearchBusinessUnitFilter`,
    SET_SELECTED_UNITS_LIST_GROUPER: `${prefix}setSelectedUnitsListGrouper`,
    SET_BUSINESS_UNIT_GROUP: `${prefix}setBusinessUnitGroup`,
    SET_SELECTED_BUSINESS_UNIT_RECORD: `${prefix}setSelectedBusinessUnit`,
    SET_SELECTED_BUSINESS_UNITS: `${prefix}setSelectedBusinessUnits`,
    SET_SELECTED_MAIN_BUSINESS_UNIT: `${prefix}setSelectedMainBusinessUnit`,
    SET_SELECTED_BUSINESS_UNIT_GROUP_LABEL: `${prefix}setSelectedBusinessUnitGroupLabel`,
    SET_BUSINESS_UNIT_GROUP_FILTER: `${prefix}setBusinessUnitGroupFilter`,
    SET_BUSINESS_UNIT_FILTER: `${prefix}setBusinessUnitFilter`,
    CANCEL_CARGOSHIPMENT: `${prefix}cancelCargoShipment`,
    BACK_CARGOSHIPMENT: `${prefix}backCargoShipment`,
    SET_LABEL: `${prefix}setLabel`,
    SET_LABEL_LIST: `${prefix}setLabelList`,
    CHANGE_STATUS_CARGOSHIPMENT_IN_TRANSIT: `${prefix}setStatusInTransitShipments`,
    ON_SELECTED_URGENT: `${prefix}onSelectUrgent`,
    ON_SELECTED_AG: `${prefix}onSelectAG`,
    CREATE_CARGO_SHIPMENT_WITHOUT_QUOTE: `${prefix}createCargoShipmentWithoutQuote`,
    ON_SELECTED_ZORC: `${prefix}onSelectZorc`,
    ON_CHANGE_DEVOLUCAO: `${prefix}onlyDevolucaoChangeHandler`,
};

const actions = new Action(prefix, crud);

const types = Object.assign({}, actions.types, newTypes);


actions.onlyDevolucaoChangeHandler = (onlyDevolucao) => {
    return {
        type: types.ON_CHANGE_DEVOLUCAO,
        onlyDevolucao
    }
}

actions.onSelectZorc = (value) => {
    return {
        type: types.ON_SELECTED_ZORC,
        value
    }
}
actions.onSelectAG = (value) => {
    return {
        type: types.ON_SELECTED_AG,
        value
    }
}

actions.onSelectUrgent = (value) => {
    return {
        type: types.ON_SELECTED_URGENT,
        value
    }
}

actions.setLabelList = (labelList) => {
    return {
        type: types.SET_LABEL_LIST,
        labelList
    }
}


actions.setLabel = (value) => {
    return {
        type: types.SET_LABEL,
        value
    }
}


actions.setBusinessUnitFilter = (currentBusinessUnit) => {
    return {
        type: types.SET_BUSINESS_UNIT_FILTER,
        currentBusinessUnit
    };
}

actions.setBusinessUnitGroupFilter = (businessUnitGroup) => {
    return {
        type: types.SET_BUSINESS_UNIT_GROUP_FILTER,
        businessUnitGroup
    };
}


actions.setSelectedBusinessUnitGroupLabel = (value) => {
    return {
        type: types.SET_SELECTED_BUSINESS_UNIT_GROUP_LABEL,
        value
    }
}

actions.setSelectMainBusinessUnit = (record) => {
    return {
        type: types.SET_SELECTED_MAIN_BUSINESS_UNIT,
        record
    }
}
actions.setSelectedBusinessUnits = (records) => {
    return {
        type: types.SET_SELECTED_BUSINESS_UNITS,
        records
    }
}

actions.setSelectedBusinessUnit = (record) => {
    return {
        type: types.SET_SELECTED_BUSINESS_UNIT_RECORD,
        record
    }
}

actions.setSelectedRecord = (data) => {
    return {
        type: types.SET_SELECTED_GROUPER_RECORDS,
        data
    }
}

actions.setSelectedUnitsListGrouper = (value) => {
    return {
        type: types.SET_SELECTED_UNITS_LIST_GROUPER,
        value
    }
}

actions.setBusinessUnitGroup = (value) => {
    return {
        type: types.SET_BUSINESS_UNIT_GROUP,
        value
    }
}

actions.setVisibleModalGrouperFilter = (visible) => {
    return {
        type: types.SET_VISIBLE_MODAL_WIZARD_FILTER,
        visible
    }
}


actions.setVisibleModalSearchBusinessUnitFilter = (visible) => {
    return {
        type: types.SET_VISIBLE_MODAL_SEARCH_FILTER,
        visible
    }
}
actions.setCountOrders = (countOrders) => {
    return {
        type: types.SET_COUNT_ORDERS,
        countOrders
    }
}

actions.setCountCargoShipmentOrders = (countCargoShipmentOrders) => {
    return {
        type: types.SET_COUNT_CARGO_SHIPMENT_ORDERS,
        countCargoShipmentOrders
    }
}

actions.setIndex = (index) => {
    return {
        type: types.SET_INDEX,
        index
    };
}
actions.setPage = (page) => {
    return {
        type: types.SET_PAGE,
        page
    }
}
actions.setMax = (max) => {
    return {
        type: types.SET_MAX,
        max
    };
}
actions.setCount = (count) => {
    return {
        type: types.SET_COUNT,
        count
    };
}
actions.fetchOrders = () => {
    return {
        type: types.FETCH_ORDERS,
        async: true
    }
}
actions.setOrders = (orders) => {
    return {
        type: types.SET_ORDERS,
        orders
    }
}

actions.addOrders = (orders) => {
    return {
        type: types.ADD_ORDERS,
        orders
    }
}

actions.setOrder = (order) => {
    return {
        type: types.SET_ORDER,
        order
    }
}
actions.fetchOrder = () => {
    return {
        type: types.FETCH_ORDER,
        async: true
    }
}


actions.setNumberFilter = (field, value) => {
    return {
        type: types.SET_NUMBER_FILTER,
        field,
        value
    };
}

actions.setGroupFilter = (field, value) => {
    return {
        type: types.SET_GROUP_FILTER,
        field,
        value
    };
}

actions.setInitialDateFilter = (initialDateFilter) => {
    return {
        type: types.SET_INITIAL_DATE_FILTER,
        initialDateFilter
    }
}

actions.setFinalDateFilter = (finalDateFilter) => {
    return {
        type: types.SET_FINAL_DATE_FILTER,
        finalDateFilter
    }
}

actions.setCarrierFilter = (carrier) => {
    return {
        type: types.SET_CARRIER_FILTER,
        carrier
    }
}

actions.setLookupCarrierVisible = (visible) => {
    return {
        type: types.SET_LOOKUP_CARRIER_VISIBLE,
        visible
    }
}

actions.setOriginFilter = (origin) => {
    return {
        type: types.SET_ORIGIN_FILTER,
        origin
    }
}

actions.setLookupOriginVisible = (visible) => {
    return {
        type: types.SET_LOOKUP_ORIGIN_VISIBLE,
        visible
    }
}

actions.setDestinationFilter = (destination) => {
    return {
        type: types.SET_DESTINATION_FILTER,
        destination
    }
}

actions.setLookupDestinationVisible = (visible) => {
    return {
        type: types.SET_LOOKUP_DESTINATION_VISIBLE,
        visible
    }
}

actions.hideLoader = () => {
    return {
        type: types.HIDE_LOADER,
        async: false
    };
}

actions.showLoader = () => {
    return {
        type: types.SHOW_LOADER,
        async: true
    };
}
actions.setSelectedOrders = (orders) => {
    return {
        type: types.SET_SELECTED_ODERS,
        orders
    };
}

actions.setShipments = (shipments) => {
    return {
        type: types.SET_SHIPMENTS,
        shipments
    }
}

actions.setShipment = (shipment) => {
    return {
        type: types.SET_SHIPMENT,
        shipment
    }
}

actions.setSelectedShipments = (shipments) => {
    return {
        type: types.SET_SELECTED_SHIPMENTS,
        shipments
    }
}

actions.addShipments = (shipments) => {
    return {
        type: types.ADD_SHIPMENTS,
        shipments
    }
}

actions.fetchShipment = () => {
    return {
        type: types.FETCH_SHIPMENT,
        async: true
    }
}

actions.applyOrderFilters = (filters) => {
    return {
        type: types.APPLY_ORDER_FILTERS,
        filters
    }
}
actions.applyShipmentFilters = (filters) => {
    return {
        type: types.APPLY_SHIPMENT_FILTERS,
        filters
    }
}

actions.setCurrentView = (view) => {
    return {
        type: types.SET_CURRENT_VIEW,
        view
    }
}

actions.hasCargoShipmentPendences = (pendenceType) => {
    return {
        type: types.CHECK_PENDENCE,
        pendenceType
    }
}

actions.setPendenceEmail = (value) => {
    return {
        type: types.SET_PENDENCE_EMAIL,
        value
    }
}


actions.setPendenceQuote = (value) => {
    return {
        type: types.SET_PENDENCE_QUOTE,
        value
    }
}

actions.setPendenceConfirmShipmentWebhook = (value) => {
    return {
        type: types.SET_PENDENCE_CONFIRM_SHIPMENT_WEBHOOK,
        value
    }
}



actions.fetchOrdersSuccess = (result, replace) => {
    return dispatch => {
        const records = mapRecords(result.records)
        if (replace) {
            dispatch(actions.setOrders(records));
        } else {
            dispatch(actions.addOrders(records));
        }
        dispatch(actions.setIndex(result.index));
        dispatch(actions.setMax(result.size));
        dispatch(actions.setCount(result.count));
        dispatch(actions.hideLoader());
    };
}


actions.fetchShipmentsSuccess = (result, replace) => {
    return dispatch => {
        const records = mapRecords(result.records)
        if (replace) {
            dispatch(actions.setShipments(records));
        } else {
            dispatch(actions.addShipments(records));
        }
        dispatch(actions.setIndex(result.index));
        dispatch(actions.setMax(result.size));
        dispatch(actions.setCount(result.count));
        dispatch(actions.hideLoader());
        dispatch(actions.hasCargoShipmentPendences("EMAIL"));
        dispatch(actions.hasCargoShipmentPendences("QUOTE"));
        dispatch(actions.hasCargoShipmentPendences("CONFIRM_SHIPMENT_WEBHOOK"));
    };
}

const mapRecords = (records) => {
    records = records || [];
    return records.map(record => {
        record.createdAt = moment(record.createdAt);
        record.deliveryForecast = record.deliveryForecast && moment(record.deliveryForecast);
        return record;
    })
}


actions.fetchOrderSuccess = (result) => {
    return dispatch => {
        dispatch(actions.setOrder(result));
        dispatch(actions.hideLoader());
    };
}

actions.fetchRecordsError = (err) => {
    return dispatch => {
        dispatch(actions.hideLoader());
    };
}



actions.loadCountOrders = () => {
    return (dispatch, getState) => {
        const state = getState().orderManagementState
        const filters = state.orderFilters
        const params = {
            filters
        }
        api.post(`${BASE_PATH}/count`, params)
            .then(({ data }) => {
                dispatch(actions.setCountOrders(data))
            })
            .catch((err) => {
                console.log(err)
                dispatch(actions.setOrders([]));
                dispatch({
                    type: types.SET_COUNT_ORDERS,
                    countOrders: {
                        withStock: 0,
                        outOfStock: 0,
                        inScripting: 0
                    },
                })
            })
    }
}


actions.loadCountCargoShipmentOrders = () => {
    return (dispatch, getState) => {
        const state = getState().orderManagementState
        const filters = state.shipmentFilters
        const params = {
            filters
        }
        api.post(`${BASE_PATH}/count-shipment-order`, params)
            .then(({ data }) => {
                dispatch(actions.setCountCargoShipmentOrders(data))
            })
            .catch((err) => {
                console.log(err)
                dispatch({
                    type: types.SET_COUNT_CARGO_SHIPMENT_ORDERS,
                    countCargoShipmentOrders: {
                        new: 0,
                        confirmed: 0,
                        approved: 0,
                        rejected: 0,
                        traveling: 0,
                        delivered: 0,
                    },
                })
            })
    }
}

actions.loadOrders = (scriptStatus = 'NOT_SCRIPTED', readyStock = false, replace = true) => {
    return (dispatch, getState) => {
        dispatch(actions.fetchOrders())
        const state = getState().orderManagementState;
        let index = 0;
        if (!replace) {
            index = state.orders.length
        }

        let localFilters = [
            {
                campo: 'readyStock',
                valor: readyStock,
                tipoFiltro: 'IGUAL_A'
            },
            {
                campo: 'scriptStatus',
                valor: scriptStatus,
                tipoFiltro: 'IGUAL_A'
            }
        ]
        const filters = [...state.orderFilters, ...localFilters];

        const params = {
            filters,
            max: 100,
            index,
        }

        api.post(`${BASE_PATH}/all`, params)
            .then(response => {
                dispatch(actions.fetchOrdersSuccess(response.data, replace))
                dispatch(actions.setSelectedOrders([]));
            })
            .catch((err) => {
                console.log(err)
                dispatch(actions.setOrders([]));
                dispatch(actions.fetchRecordsError());
            })

    }
}


actions.scriptingOrders = (orders, mainBusinessUnit) => {
    return (dispatch, getState) => {
        mainBusinessUnit = mainBusinessUnit || {}
        const { documentNumber = null, label = null } = mainBusinessUnit
        const mainBusinessUnitGroup = {
            documentNumber,
            label
        }
        orders = orders || [];
        if (orders.length > 0) {
            orders = orders.map(order => order.oid);
            dispatch(actions.showLoader());
            api.post(`${BASE_PATH}/scripting`, { orders, mainBusinessUnitGroup })
                .then(response => {
                    dispatch(actions.hideLoader());
                    dispatch(
                        messageActions.messageShowMessages(
                            makeMessages(["Pedidos enviados para roteirização com sucesso"], "success")
                        )
                    );
                    dispatch(actions.setSelectedOrders([]));
                    dispatch(actions.loadCountOrders());
                    dispatch(actions.loadCountCargoShipmentOrders());
                    dispatch(actions.loadOrders('NOT_SCRIPTED', true, true));
                })
                .catch(err => {
                    dispatch(actions.hideLoader());
                    dispatch(
                        messageActions.messageShowMessages(
                            makeMessages(["Não foi possível enviar os pedidos para a roteirização"], "error")
                        )
                    );
                })
        }

    }

}

actions.loadShipmentCargo = (status = 'NEW', replace = true) => {
    return (dispatch, getState) => {
        dispatch(actions.fetchShipment())
        const state = getState().orderManagementState;
        let index = 0;
        if (!replace) {
            index = state.shipments.length
        }

        const localFilters = [
            {
                campo: 'status',
                valor: status,
                tipoFiltro: 'IGUAL_A'
            }
        ];

        const filters = [...state.shipmentFilters, ...localFilters];

        const params = {
            filters,
            max: 100,
            index,
        }

        dispatch(actions.fetchRecord());
        api.post(`${BASE_SHIPMENT_CARGO}/all`, params)
            .then(response => {
                dispatch(actions.fetchShipmentsSuccess(response.data, replace));
                dispatch(actions.setSelectedOrders([]));
                dispatch(actions.setSelectedShipments([]));
                dispatch(actions.hideLoader());
            })
            .catch((err) => {
                console.log(err)
                dispatch(actions.hideLoader());
                dispatch(actions.setShipments([]))
                dispatch(actions.fetchRecordsError())
            })
    }
}



actions.confirmCollectionOrder = (shipments) => {
    return (dispatch, getState) => {
        dispatch(actions.fetchRecord());
        api.post(`/api/v1/lognex/cargo-shipment/collection-ordes`, { shipments })
            .then(response => {
                dispatch(actions.setSelectedShipments([]));
                dispatch(actions.hideLoader());
                dispatch(
                    messageActions.messageShowMessages(
                        makeMessages(["Pedidos enviados para Ordem de coleta com sucesso"], "success")
                    )
                );
                dispatch(actions.loadShipmentCargo('NEW'));
                dispatch(actions.setSelectedShipments([]));
            })
            .catch(err => {
                dispatch(actions.hideLoader());
                dispatch(
                    messageActions.messageShowMessages(
                        makeMessages(["Não foi possível enviar os pedidos para a Ordem de Coleta"], "error")
                    )
                );

            })
    }

}

actions.resendEmailCarrier = (oids) => {
    return (dispatch, getState) => {
        dispatch(actions.fetchRecord());
        api.post('/api/v1/lognex/cargo-shipment/resend-email-carrier', { oids })
            .then(response => {
                dispatch(actions.hideLoader());
                dispatch(actions.loadShipmentCargo('NEW'));
                dispatch(
                    messageActions.messageShowMessages(
                        makeMessages(["Requisição enviada com com sucesso"], "success")
                    )
                );
            })
            .catch((error) => {
                console.log(error);
                dispatch(actions.hideLoader());
                dispatch(
                    messageActions.messageShowMessages(
                        makeMessages(["Ops! Falha na requisição para reprocessar email"], "error")
                    )
                );
            })
    }
}

actions.regenerateQuote = (oids) => {
    return (dispatch, getState) => {
        dispatch(actions.fetchRecord());
        api.post('/api/v1/lognex/cargo-shipment/regenerate-quote', { oids })
            .then(response => {
                dispatch(actions.hideLoader());
                dispatch(actions.loadShipmentCargo('NEW'));
                dispatch(
                    messageActions.messageShowMessages(
                        makeMessages(["Requisição enviada com sucesso"], "success")
                    )
                );
            })
            .catch((error) => {
                console.log(error);
                dispatch(actions.hideLoader());
                dispatch(
                    messageActions.messageShowMessages(
                        makeMessages(["Ops! Falha na requisição para regerar cotação"], "error")
                    )
                );
            })
    }
}

actions.resendConfirmShipmentWebhook = (oids) => {
    return (dispatch, getState) => {
        dispatch(actions.fetchRecord());
        api.post('/api/v1/lognex/cargo-shipment/resend-erp', { oids })
            .then(response => {
                dispatch(actions.hideLoader());
                dispatch(actions.loadShipmentCargo('NEW'));
                dispatch(
                    messageActions.messageShowMessages(
                        makeMessages(["Requisição enviada com sucesso"], "success")
                    )
                );
            })
            .catch((error) => {
                console.log(error);
                dispatch(actions.hideLoader());
                dispatch(
                    messageActions.messageShowMessages(
                        makeMessages(["Ops! Falha na requisição para envio ao ERP"], "error")
                    )
                );
            })
    }
}


actions.cancelOrders = (oids) => {
    return (dispatch, getState) => {
        dispatch(actions.fetchRecord());
        api.post('/api/v1/lognex/shipment-order/cancel-shipmentorder', { oids })
            .then(response => {
                dispatch(actions.hideLoader());
                dispatch(actions.loadCountOrders());
                dispatch(actions.loadOrders('NOT_SCRIPTED', true, true));
                dispatch(
                    messageActions.messageShowMessages(
                        makeMessages(["Requisição enviada com sucesso"], "success")
                    )
                );
            })
            .catch((error) => {
                console.log(error);
                dispatch(actions.hideLoader());
                dispatch(
                    messageActions.messageShowMessages(
                        makeMessages(["Ops! Falha na requisição para cancelar o(s) pedido(s)"], "error")
                    )
                );
            })
    }
}
actions.cancelScriptingOrders = (oids) => {
    return (dispatch, getState) => {
        dispatch(actions.fetchRecord());
        api.post('/api/v1/lognex/shipment-order/cancel-scripting', { oids })
            .then(response => {
                dispatch(actions.hideLoader());
                dispatch(actions.loadCountOrders());
                dispatch(actions.loadOrders('SCRIPTING', true, true));
                dispatch(
                    messageActions.messageShowMessages(
                        makeMessages(["Requisição enviada com sucesso"], "success")
                    )
                );
            })
            .catch((error) => {
                console.log(error);
                dispatch(actions.hideLoader());
                dispatch(
                    messageActions.messageShowMessages(
                        makeMessages(["Ops! Falha na requisição para cancelar o(s) pedido(s) em roteirização"], "error")
                    )
                );
            })
    }
}



actions.cancelCargoShipment = (oids) => {
    return (dispatch, getState) => {
        dispatch(actions.fetchRecord());
        api.post('/api/v1/lognex/cargo-shipment/cancel-cargoshipment', { oids })
            .then(response => {
                dispatch(actions.hideLoader());
                dispatch(actions.loadCountOrders());
                dispatch(actions.loadShipmentCargo('CONFIRMED'));
                dispatch(
                    messageActions.messageShowMessages(
                        makeMessages(["Requisição enviada com sucesso"], "success")
                    )
                );
            })
            .catch((error) => {
                console.log(error);
                dispatch(actions.hideLoader());
                dispatch(
                    messageActions.messageShowMessages(
                        makeMessages(["Ops! Falha na requisição para cancelar o(s) embarque(s)"], "error")
                    )
                );
            })
    }
}

actions.backCargoShipment = (oids) => {
    return (dispatch, getState) => {
        dispatch(actions.fetchRecord());
        api.post('/api/v1/lognex/cargo-shipment/back-cargoshipment', { oids })
            .then(response => {
                dispatch(actions.hideLoader());
                dispatch(actions.loadCountOrders());
                dispatch(actions.loadShipmentCargo('CONFIRMED'));
                dispatch(
                    messageActions.messageShowMessages(
                        makeMessages(["Requisição enviada com sucesso"], "success")
                    )
                );
            })
            .catch((error) => {
                console.log(error);
                dispatch(actions.hideLoader());
                dispatch(
                    messageActions.messageShowMessages(
                        makeMessages(["Ops! Falha na requisição para cancelar o(s) embarque(s)"], "error")
                    )
                );
            })
    }
}

actions.setStatusInTransitShipments = (oids) => {
    return (dispatch, getState) => {
        dispatch(actions.fetchRecord());
        api.post('/api/v1/lognex/cargo-shipment/set-status-in-transit-shipments', { oids })
            .then(response => {
                dispatch(actions.hideLoader());
                dispatch(actions.setSelectedOrders([]));
                dispatch(actions.loadCountOrders());
                dispatch(actions.loadShipmentCargo('CONFIRMED'));
                dispatch(actions.loadCountCargoShipmentOrders());
                dispatch(
                    messageActions.messageShowMessages(
                        makeMessages(["Requisição enviada com sucesso"], "success")
                    )
                );
            })
            .catch((error) => {
                console.log(error);
                dispatch(actions.hideLoader());
                dispatch(
                    messageActions.messageShowMessages(
                        makeMessages(["Ops! Falha na requisição para cancelar o(s) embarque(s)"], "error")
                    )
                );
            })
    }
}

actions.gerarEmbarqueSemCotacao = (selectOrders) => {
    return (dispatch, getState) => {
        const listCarrier = []
        for (let index = 0; index < selectOrders.length; index++) {
            if (selectOrders[index].carrier) {
                if (listCarrier.filter(c => c.documentNumber == selectOrders[index].carrier.documentNumber).length == 0) {
                    listCarrier.push(selectOrders[index].carrier)
                }
            }
        }

    }
}

actions.loadLabels = (filters) => {
    return (dispatch, getState) => {
        dispatch(actions.showLoader());
        api.post(`${BASE_LABELS}/all`, { filters })
            .then(response => {
                dispatch(actions.setLabelList(response.data.records))
                dispatch(actions.hideLoader());
            })
            .catch((err) => {
                console.log(err);
                dispatch(actions.hideLoader());
            })

    }
}



actions.createCargoShipmentWithoutQuote = (oids) => {
    return (dispatch, getState) => {

        // dispatch(actions.showLoader());
        // dispatch(actions.fetchRecord());
        api.post(`/api/v1/lognex/cargo-shipment/create/cargo-shipment-manual`, { oids })
            .then(response => {
                dispatch(actions.setSelectedShipments([]));
                dispatch(actions.hideLoader());
                dispatch(
                    messageActions.messageShowMessages(
                        makeMessages(["Pedidos enviados para Ordem de coleta com sucesso"], "success")
                    )
                );
                dispatch(actions.loadShipmentCargo('NEW'));
                dispatch(actions.setSelectedShipments([]));
            })
            .catch(err => {
                dispatch(actions.hideLoader());
                dispatch(
                    messageActions.messageShowMessages(
                        makeMessages(["Não foi possível enviar os pedidos para a Ordem de Coleta"], "error")
                    )
                );

            })
    }
}


actions.onImportDeliveryDevSafraFromXlsx = (xlsx) => {
    return (dispatch) => {
        const URL = `${BASE_PATH}/import-delivery-xlsx`;
        dispatch(actions.showLoader());
        api.uploadFiles(URL, xlsx).then((response) => {
            const { data } = response;
            dispatch(actions.loadCountOrders());
            dispatch(actions.loadOrders('NOT_SCRIPTED', true, true));


            if (data && data.totalSuccessRecords > 0) {
                const messages = ["Planilha importada com sucesso"];
                dispatch(actions.hideLoader());
                dispatch(
                    actions.messageActions.messageShowMessages(
                        actions.makeMessages(messages, "success")
                    )
                );
            } else {
                let messages = ["Verifique os dados da planilha, tem erros nos dados"];
                if (data.errors && data.errors.length > 0) {
                    messages = data.errors
                }

                dispatch(actions.hideLoader());
                dispatch(
                    actions.messageActions.messageShowMessages(
                        actions.makeMessages(messages, "error")
                    )
                );
            }

        }).catch((err) => {
            console.log(err);
            const messages = ["Não foi possível processar a planilha."];
            dispatch(
                actions.messageActions.messageShowMessages(
                    actions.makeMessages(messages, "error")
                )
            );
            dispatch(actions.hideLoader());
        });
    };
};

actions.onImportShipmentCodeFromXlsx = (xlsx) => {
    return (dispatch) => {
        const URL = `${BASE_PATH}/import-shipmentcode-xlsx`;
        dispatch(actions.showLoader());
        api.uploadFiles(URL, xlsx).then((response) => {
            const { data } = response;
            dispatch(actions.loadCountOrders());
            dispatch(actions.loadOrders('NOT_SCRIPTED', true, true));

            if (data && data.totalSuccessRecords > 0) {
                const messages = ["Planilha importada com sucesso"];
                dispatch(actions.hideLoader());
                dispatch(
                    actions.messageActions.messageShowMessages(
                        actions.makeMessages(messages, "success")
                    )
                );
            } else {
                const messages = ["Verifique os dados da planilha, tem erros nos dados"];
                dispatch(actions.hideLoader());
                dispatch(
                    actions.messageActions.messageShowMessages(
                        actions.makeMessages(messages, "error")
                    )
                );
            }

        }).catch((err) => {
            console.log(err);
            const messages = ["Não foi possível processar a planilha."];
            dispatch(
                actions.messageActions.messageShowMessages(
                    actions.makeMessages(messages, "error")
                )
            );
            dispatch(actions.hideLoader());
        });
    };
};

function makeMessages(messages, type) {
    return messages.map(message => {
        return { sticky: true, severity: type, detail: message };
    });
};


export { types, actions }