import React from "react";
import "./EditTypeDockConfig.scss";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
const columnsFields = [
  {
    field: "vehicleType.name",
    header: "Veículo",
    columnKey: "vehicleType.name",
    sortable: false,
    filter: false,
  },
  {
    field: "operationType",
    header: "Tipo de operação",
    columnKey: "operationType",
    sortable: false,
    filter: false,
  },
  {
    field: "packagingType",
    header: "Embalagem",
    columnKey: "packagingType",
    sortable: false,
    filter: false,
  },

  {
    field: "operationTime",
    header: "Tempo operação",
    columnKey: "operationTime",
    sortable: false,
    filter: false,
  } 
];

export const EditTypeDockConfig = (props) => {


  const packagingTypeTemplate = (rowData, col) => {
    try {
      const packagingTypes = rowData.packagingType;
      let listNames = [];
      if (packagingTypes) {
        listNames = packagingTypes.map((tp) => {
          return tp.name;
        });
      }
      return <span>{listNames.join(",")}</span>;
    } catch (error) {
      return <></>;
    }
  };
  const operationTypeTemplate = (rowData, col) => {
    try {
      const operationType = rowData.operationType;
      let listlabel = [];
      if (operationType) {
        listlabel = operationType.map((tp) => {
          return tp.label;
        });
      }
      return <span>{listlabel.join(",")}</span>;
    } catch (error) {
      return <></>;
    }
  };
  const minutosTemplate = (rowData, col) => {
    try {
      return <span>{rowData[col.field]} minutos</span>;
    } catch (error) {
      return <></>;
    }
  };

  let carrierColumns = [
    ...columnsFields.map((col) => {
      if (col.field == "packagingType") {
        return <Column {...col} key={col.field} className="col-head-table" body={packagingTypeTemplate} />;
      }
      if (col.field == "operationType") {
        return <Column {...col} key={col.field} className="col-head-table" body={operationTypeTemplate} />;
      }
      if (col.field == "operationTime") {
        return <Column {...col} key={col.field} className="col-head-table" body={minutosTemplate} />;
      }
      if (col.field == "timeNextOperation") {
        return <Column {...col} key={col.field} className="col-head-table" body={minutosTemplate} />;
      }
      return <Column {...col} key={col.field} />;
    }),
  ];
 
  return (
    <div className="let-component">
      {/* Uniades */}
      <div className="form-field-container-right-center" onClick={props.showDockConfigDialog}>
        <span>Adicionar/Alterar configurações dos veículos </span>
        <button   className="highlighted" title="Editar" style={{ marginRight: ".25em" }}>
          <i className="pi pi-pencil"></i>
        </button>
      </div>
 
      <div className="form-field-container">
        {/* <label>Transportadoras</label> */}
        <DataTable value={props.docaVehicleOperation || []} selectionMode="single" metaKeySelection={false}>
          {carrierColumns}
        </DataTable>
      </div>
    </div>
  );
};
