import React from "react"; 
import { connect } from "react-redux";
import { Page } from "../../../components/page/Page";
import { actions } from "../scriptwriter.actions";
import { messageActions, makeMessages } from "../../../store/message"; 

import { AppService, COMPANY_CHANGE_EVENT_NAME, BUSINESS_UNIT_CHANGE_EVENT_NAME } from "../../../services/app.service";

import "./scriptwriter.scss";
import "../index.scss"; 
import Map from "../form/map";
import moment from "moment"; 
import { TableRoutesListPage } from "../components/TableRoutesListPage";
import { Calendar } from "primereact/calendar";
import { OrdensNotScriptedListPage } from "../components/OrdensNotScripted/OrdensNotScriptedListPage";
import { MultiSelect } from "primereact/multiselect";
import RouteEditTablePage from "../components/editeRouteModal/RouteEditTablePage";
import RouteManualTablePage from "../components/manualRouteModal/RouteManualTablePage";
import { InputSwitch } from "primereact/inputswitch";

const mapStateToProps = (state) => {
  const currentUser = state.appState.currentUser;

  const listOrderNotScript = [];
  state.crudScriptwriterState.ordersNotScripted.forEach((o) => {
    if (
      state.crudScriptwriterState.usersSelected.filter((u) => String(u.user).toUpperCase() == String(o.owner.user).toUpperCase()).length > 0
    ) {
      listOrderNotScript.push(o);
    }
  });

  const records = [];
  state.crudScriptwriterState.records.forEach((o) => {
    if (
      state.crudScriptwriterState.usersSelected &&
      state.crudScriptwriterState.usersSelected.filter((u) => String(u.user).toUpperCase() == String(o.owner.user).toUpperCase()).length > 0
    ) {
      records.push(o);
    }
  });

  return {
    records: records,
    orders: state.crudScriptwriterState.orders,
    initialDate: state.crudScriptwriterState.initialDate,
    finalDate: state.crudScriptwriterState.finalDate,
    ordersNotScripted: listOrderNotScript,
    mostrarTodas: state.crudScriptwriterState.mostrarTodas,
    users: state.crudScriptwriterState.users,
    usersSelected: state.crudScriptwriterState.usersSelected,
    currentUser: currentUser,
    ordersNotScriptedSeletected: state.crudScriptwriterState.ordersNotScriptedSeletected,
    showModalInclusaoOrders: state.crudScriptwriterState.showModalInclusaoOrders,
    routesColumns: state.crudScriptwriterState.routesColumns,
    selectedRouterIncludDelivery: state.crudScriptwriterState.selectedRouterIncludDelivery,
    startLocation: state.crudScriptwriterState.startLocation,
    isCreateManualRoute: state.crudScriptwriterState.isCreateManualRoute,
    ordersNotScriptedColumns: state.crudScriptwriterState.ordersNotScriptedColumns,
    vehicles: state.crudScriptwriterState.vehicles,
    selectedRoutes:state.crudScriptwriterState.selectedRoutes
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeHandler: (event) => {
      const target = event.target;
      const field = target.name;
      let value = target.value;
      dispatch(actions.removeErrors([{ field }]));
      dispatch(actions.setField(field, value));
    },

    applyFilter: (filterParams) => {
      dispatch(actions.setType(null));
      dispatch(actions.applyFilter(filterParams));
    },
    setFilters: (filterParams) => {
      dispatch(actions.setFilters(filterParams));
    },
    onLoad: (filters) => {
      dispatch(actions.setType(null));
      dispatch(actions.onLoad(filters));
    },

    onLoadOrders: (filters) => {
      dispatch(actions.onLoadOrders(filters));
    },

    loadAllUnidade: () => {
      dispatch(actions.findAllBusinessunits());
    },

    showMessageError: (msg) => {
      dispatch(messageActions.messageShowMessages(makeMessages([msg], "error")));
    },

    setFilterMap: (filterMap) => {
      dispatch(actions.setFilterMap(filterMap));
    },
    initialDateChangeHandler: (e) => dispatch(actions.setInitialDateFilter(e.value)),
    finalDateChangeHandler: (e) => dispatch(actions.setFinalDateFilter(e.value)),
    onClickRoteirizarOrdens: () => dispatch(actions.onClickRoteirizarOrdens()),
    changeMostrarTodas: (mostrarTodas) => dispatch(actions.changeMostrarTodas(mostrarTodas)),
    setUsersSelected: (user) => dispatch(actions.setUsersSelected(user)),
    setCurrentUser: (currentUser) => dispatch(actions.setCurrentUser(currentUser)),
    showRoutesSelectAddOrders: (value) => dispatch(actions.showRoutesSelectAddOrders(value)),
    setSelectedRouterIncludDelivery: (value) => dispatch(actions.setSelectedRouterIncludDelivery(value)),
    onConfirmIncludeDeliveryRotaSelected: () => dispatch(actions.onConfirmIncludeDeliveryRotaSelected()),
    setSelectecVirtualCD: (value) => dispatch(actions.setSelectecVirtualCD(value)),
    setVisibleCreateManualRoute: (value) => dispatch(actions.setVisibleCreateManualRoute(value)),
    onLoadVehicles: () => dispatch(actions.onLoadVehicles()),
    onCreateManualRoute: (createManualRouteObj) => dispatch(actions.onCreateManualRoute(createManualRouteObj)),
  
    addPontoApoio: () => dispatch(actions.addPontoApoio()),
    getAllTolls:() => dispatch(actions.getAllTolls()),//pedagios
  };
};

class ScriptwriterListPageContainer extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    AppService.on(COMPANY_CHANGE_EVENT_NAME, this);
    AppService.on(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
    this.setSelectedCurrentUser();
    this.props.loadAllUnidade();
    this.onClickAplicarFiltro();
    this.props.onLoadVehicles();
    this.props.getAllTolls();
  }

  componentWillUnmount() {
    AppService.off(COMPANY_CHANGE_EVENT_NAME, this);
    AppService.off(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
  }

  onReceiveAppNotifications = (eventName, params) => {
    if(eventName ===COMPANY_CHANGE_EVENT_NAME){
      //só consultar se ouve alteração da empresa
      this.props.getAllTolls();
    }
    if (eventName === COMPANY_CHANGE_EVENT_NAME || eventName === BUSINESS_UNIT_CHANGE_EVENT_NAME) {
      this.setSelectedCurrentUser();
      this.props.loadAllUnidade();
      this.onClickAplicarFiltro();
      this.props.onLoadVehicles();
    }
  };
  normalizeDate = (date) => {
    return date && typeof date === "object" ? date : null;
  };
  getFilters = () => {
    let filters = [];

    const initialDate = this.normalizeDate(this.props.initialDate);
    const finalDate = this.normalizeDate(this.props.finalDate);

    if (initialDate && finalDate) {
      filters.push({
        field: "eventRecord.createdAt",
        startValue: moment(initialDate).startOf("day").toDate(),
        endValue: moment(finalDate).endOf("day").toDate(),
        type: "BETWEEN",
      });
    }
    filters.push({
      field: "createEmbarque",
      value: false,
      type: "EQUALS",
    });
    return filters;
  };
  onClickAplicarFiltro = () => {
    const filters = this.getFilters();
    this.props.onLoad(filters);
    this.props.onLoadOrders(filters);
    this.props.setFilters(filters);
  };
  onClickRoteirizarOrdens = () => {
    this.props.onClickRoteirizarOrdens();
  };

  onShowIncluirOrdensRotasExistente = () => {
    this.props.showRoutesSelectAddOrders(true);
  };
  onHideIncluirOrdensRotasExistente = () => {
    this.props.showRoutesSelectAddOrders(false);
    this.props.setSelectedRouterIncludDelivery(null);
  };
  onChangeSelectedUsers = (event) => {
    this.props.setUsersSelected(event.value);
  };
  setSelectedCurrentUser = () => {
    const currentUser = this.props.currentUser;
    this.props.setCurrentUser(currentUser);
  };

  onChangeSelectedRouterIncludDelivery = (e) => {
    this.props.setSelectedRouterIncludDelivery(e);
  };

  onClickGerarEmbarque = () => { };
  onOpenCreateManualRota = () => {
    this.props.onLoadVehicles();
    this.props.setVisibleCreateManualRoute(true);
  };
  onHideCreateManualRoute = () => {
    this.props.setVisibleCreateManualRoute(false);
  };
  
  render() {
    const header = this.props.match.params.id && (
      <div>
        <div className="header-mapa-travel">
          <h1>Roteirizador </h1>
          <button onClick={() => { }}>
            <i className="pi pi-sign-out"> </i>
          </button>
        </div>
        <hr className="lxSplitter"></hr>
      </div>
    );

    const content = (
      <div className="route-view-map lognex-route">
        <Map />

        {this.props.isCreateManualRoute && (
          <RouteManualTablePage
            visible={this.props.isCreateManualRoute}
            columns={this.props.ordersNotScriptedColumns}
            records={this.props.ordersNotScriptedSeletected}
            onHide={this.onHideCreateManualRoute}
            vehicles={this.props.vehicles}
            onConfirm={this.props.onCreateManualRoute}
          />
        )}

        <RouteEditTablePage
          visible={this.props.showModalInclusaoOrders}
          onHide={this.onHideIncluirOrdensRotasExistente}
          routesColumns={this.props.routesColumns}
          records={this.props.records}
          usersSelected={this.props.usersSelected}
          onSelectedEventsChange={this.onChangeSelectedRouterIncludDelivery}
          selectedRouterIncludDelivery={this.props.selectedRouterIncludDelivery}
          ordersNotScriptedSeletected={this.props.ordersNotScriptedSeletected}
          onConfirm={this.props.onConfirmIncludeDeliveryRotaSelected}
          startLocation={this.props.startLocation}
          setSelectecVirtualCD={this.props.setSelectecVirtualCD}
        />

        <div className="content-lateral-dir">
          <div className="content-routes-filter">
            <div className="p-grid">
              <div className="p-md-3  form-field-container">
                <label className="lxRequiredField">De</label>
                <Calendar
                  showIcon={true}
                  value={this.props.initialDate}
                  showTime={false}
                  onChange={this.props.initialDateChangeHandler}
                  disabled={false}
                  name="initialDate"
                  dateFormat="dd/mm/yy"
                />
              </div>
              <div className="p-md-3  form-field-container">
                <label className="lxRequiredField">Até</label>
                <Calendar
                  showIcon={true}
                  value={this.props.finalDate}
                  showTime={false}
                  onChange={this.props.finalDateChangeHandler}
                  disabled={false}
                  name="finallDate"
                  dateFormat="dd/mm/yy"
                />
              </div>

              <div className="p-col-4 form-field-container">
                <label>Usuários</label>
                <MultiSelect
                  optionLabel="user"
                  value={this.props.usersSelected}
                  options={this.props.users}
                  onChange={this.onChangeSelectedUsers}
                />
              </div>

              <div className="p-md-2  form-field-container" style={{ display: "flex", alignContent: "flex-end", flexWrap: "wrap" }}>
                <br />
                <button
                  className={"lognex-btn-highlighted"}
                  title="Aplicar filtro"
                  style={{ width: "81px", cursor: "pointer" }}
                  onClick={this.onClickAplicarFiltro}
                >
                  Filtrar
                </button>
              </div>
            </div>
          </div>
          <div className="content-routes">

            <TableRoutesListPage />
            {/* </div>

          <div className="content-routes"> */}
            <br />
            <div className="content-orden-header">
              <h3> Ordens não Roteririzadas (Total: {this.props.ordersNotScripted.length})</h3>
              <button
                className={"lognex-btn-highlighted-inverse"}
                style={{ width: "84px",
                  cursor: "pointer",
                  height: "27px"}}
                  disabled={ !this.props.selectedRoutes || this.props.selectedRoutes.length == 0}
                  onClick={this.props.addPontoApoio}
                >

                <div className="">
                  <i className="pi pi-plus">APOIO</i>
                </div>

              </button>
            </div>
            <OrdensNotScriptedListPage />
          </div>
          <div className="p-grid" style={{ marginTop: "4px" }}>

            <div className="p-md-3  form-field-container" style={{ display: "flex", flexDirection: "row-reverse", alignContent: "flex-end", flexWrap: "wrap" }}>
              <h3 style={{ marginLeft: "5px" }}>{" Todas"}</h3>
              <InputSwitch
                checked={this.props.mostrarTodas}
                onChange={(e) => this.props.changeMostrarTodas(e.value)}
              />
            </div>

            <div className="p-md-3  form-field-container" style={{ display: "flex", alignContent: "flex-end", flexWrap: "wrap" }}>
              <button
                className={"lognex-btn-highlighted"}
                style={{ width: "95px", cursor: "pointer" }}
                disabled={!this.props.ordersNotScriptedSeletected || this.props.ordersNotScriptedSeletected.length == 0}
                onClick={this.onShowIncluirOrdensRotasExistente}>
                {"Incluir em uma rota"}
              </button>
            </div>

            <div className="p-md-3  form-field-container" style={{ display: "flex", alignContent: "flex-end", flexWrap: "wrap" }}>
              <button
                className={"lognex-btn-highlighted"}
                style={{ width: "95px", cursor: "pointer" }}
                disabled={!this.props.ordersNotScriptedSeletected || this.props.ordersNotScriptedSeletected.length == 0}
                onClick={this.onOpenCreateManualRota}
              >
                {"Gerar rota manual"}
              </button>
            </div>

            <div className="p-md-3  form-field-container" style={{ display: "flex", alignContent: "flex-end", flexWrap: "wrap" }}>
              <button
                className={"lognex-btn-highlighted"}
                style={{ width: "95px", cursor: "pointer" }}
                disabled={!this.props.ordersNotScriptedSeletected || this.props.ordersNotScriptedSeletected.length == 0}
                onClick={this.onClickRoteirizarOrdens}
              >
                {"Gerar nova rota"}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
    return <Page header={header} content={content} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ScriptwriterListPageContainer);
