export function validate(record) {
    const errors = [];

    if (!record.name || record.name.trim() === "") {
        errors.push({ field: 'name', message: 'O nome da região é obrigatório!' });
    }

    if (!record.carrier || !record.carrier.oid || record.carrier.oid.toString().trim() === "") {
        errors.push({ field: 'carrier', message: 'A transportadora da região é obrigatória!' });
    }

    if (!record.locations || record.locations.length === 0) {
        errors.push({ field: 'locations', message: 'É necessário informar ao menos uma localização!' });
    } 

    if (!record.longitude || record.longitude === "") {
        record.longitude = "0";
    }

    if (!record.latitude || record.latitude === "") {
        record.latitude = "0";
    }
    const result = {
        ok: errors.length === 0,
        errors,
        record
    }

    return new Promise((resolve, reject) => {
        const action = result.ok ? resolve : reject;
        return action(result);
    })


}