import React from "react";
import { connect } from "react-redux";
import { Column } from 'primereact/column';
import Toolbar from "../../../components/toolbar/Toolbar";
import { routes } from '../../../constants';
import { Page } from "../../../components/page/Page";
import { ClientForm } from './ClientForm';
import { actions } from '../client.actions';
import { actions as locationActions } from '../../location/location.actions';

const mapStateToProps = state => {
  const record = state.crudClientState.currentRecord;
  const lookupCitiesBase = {
    records: state.crudLocationState.records,
    columns: state.crudLocationState.columns
      .filter(
        col =>
          col.field === "description"
      )
      .map(col => <Column key={col.field} {...col} />),
    first: state.crudLocationState.index,
    rows: state.crudLocationState.max,
    totalRecords: state.crudLocationState.count,
    sortField: state.crudLocationState.sortField,
    order: state.crudLocationState.sortOrder,
    //editDisabled: !record.oidClient
  };
  let lookupCities = Object.assign(
    {},
    state.crudClientState.lookupCities,
    lookupCitiesBase
  );

  const lookupStatesBase = {
    records: state.crudLocationState.records,
    columns: state.crudLocationState.columns
      .filter(
        col =>
          col.field === "name"
      )
      .map(col => <Column key={col.field} {...col} />),
    first: state.crudLocationState.index,
    rows: state.crudLocationState.max,
    totalRecords: state.crudLocationState.count,
    sortField: state.crudLocationState.sortField,
    order: state.crudLocationState.sortOrder,
    //editDisabled: !record.oidClient
  };
  let lookupStates = Object.assign(
    {},
    state.crudClientState.lookupStates,
    lookupStatesBase
  );

  return {
    record: state.crudClientState.currentRecord,
    lookupCities,
    lookupStates
  };
};

const mapDispatchToProps = dispatch => {
  const _setField = (field,value) =>{
    const fields = field.split(".");
    if(fields.length > 1 && fields[0] === 'address' ){
      dispatch(actions.setAddress(fields[1], value))
    }else{
      dispatch(actions.setField(field, value))
    }
  }
  return {
    changeHandler: event => {
      const target = event.target;
      const field = target.name;
      let value = target.value;
      dispatch(actions.removeErrors([{ field }]));
      _setField(field,value);
    },
    saveHandler: event => {
      dispatch(actions.save());
    },
    removeHandler: event => {
      dispatch(actions.remove());
    },
    newHandler: event => {
      dispatch(actions.makeNew());      
    },
    loadById: oid => dispatch(actions.loadById(oid)),

    lookupCitiesSetVisible: visible =>
      dispatch(actions.setlookupCitiesVisible(visible)),
    lookupCitiesPaginate: paginationParams =>
      dispatch(locationActions.paginate(paginationParams)),
    lookupCitiesSortOrder: sortParams =>
      dispatch(locationActions.sortOrder(sortParams)),
    lookupCitiesApplyFilter: (filterParams,callback) => {
      dispatch(locationActions.setType(null));
      dispatch(locationActions.applyFilter(filterParams,{callback}));
    },
    lookupCitiesLoad: () => dispatch(locationActions.load()),
    setCity: (city) => {
      const { oid, name, description, parent } = city || {};
      _setField("address.city", city ? { oid, name, description } : null);
      _setField("address.state", parent ? { oid: parent.oid, name: parent.name } : null);
    },
    setField: (field, value) =>{
     _setField(field,value)
    } ,


    lookupStatesSetVisible: visible =>
      dispatch(actions.setlookupStatesVisible(visible)),
    lookupStatesPaginate: paginationParams =>
      dispatch(locationActions.paginate(paginationParams)),
    lookupStatesSortOrder: sortParams =>
      dispatch(locationActions.sortOrder(sortParams)),
    lookupStatesApplyFilter: filterParams => {
      dispatch(locationActions.setType(null));
      dispatch(locationActions.applyFilter(filterParams));
    },
    lookupStatesLoad: () => dispatch(locationActions.load()),
    setState: (state) => {

      const { oid, name, description } = state || {}
      _setField("address.state", state ? { oid, name, description } : null)
    },
    

  };
};

class ClientFormPageContainer extends React.Component {

  constructor(props) {
    super(props);
    actions.afterSave = ({ oid }) => {
      this.props.history.replace(`${routes.PATH_CLIENT}/${oid}`);
    };
    actions.afterNew = () => {
      this.props.history.replace(`${routes.PATH_CLIENT}/novo`);
    };
    actions.afterRemove = () => {
      this.props.history.replace(`${routes.PATH_CLIENT}`);
    };
  }

  componentDidMount() {
    const oid = this.props.match.params.id;

    if (oid && parseInt(oid)) {
      this.props.loadById(oid);
    } else {
      this.props.newHandler();
    }
  }
  searchHandler = () => {
    this.props.history.push(`${routes.PATH_CLIENT}`)
  }

  lookupCitiesOnPage = e => {
    this.props.lookupCitiesPaginate({
      max: e.rows,
      index: e.first
    });
  };
  lookupCitiesOnSort = e => {
    this.props.lookupCitiesSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField
    });
  };
  lookupCitiesOnFilter = e => {
    const filters = [];
    const keys = Object.keys(e.filters) || [];
    keys.forEach(key => {
      filters.push({
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: "CONTEM"
      });
    });
    filters.push({
      campo: 'type',
      valor: 'CITY',
      tipoFiltro: 'IGUAL_A'
    })
    this.props.lookupCitiesApplyFilter(filters);
  };
  lookupCitiesOnClick = e => {
    const filters = [];
    filters.push({
      campo: 'type',
      valor: 'CITY',
      tipoFiltro: 'IGUAL_A'
    })
    this.props.lookupCitiesApplyFilter(filters);
    this.props.lookupCitiesSetVisible(true);
  };
  lookupCitiesOnHide = e => {
    this.props.lookupCitiesSetVisible(false);
  };
  lookupCitiesOnConfirm = e => {
    this.props.setCity(e.selection);
    this.props.lookupCitiesSetVisible(false);
  };
  lookupCitiesOnCancel = e => {
    this.props.setCity(null);
    this.props.lookupCitiesSetVisible(false);
  };

  lookupCitiesOnEdit = () => {
    if (this.props.record.address && this.props.record.address.parent.name) {
      this.props.history.push(
        `${routes.PATH_CLIENT}/${this.props.record.address.parent.name}`
      );
    }
  };

  lookupCitiesOnComplete = e => {
  };

  lookupCitiesOnInputFilter = e => {
    const value = e.value;
    if (typeof value === "string") {
      const filters = [
        {
          campo: "description",
          valor: e.value,
          tipoFiltro: "CONTEM"
        }
      ];
      filters.push({
        campo: 'type',
        valor: 'CITY',
        tipoFiltro: 'IGUAL_A'
      });
      this.props.setField("address.city.description", e.value);
      this.props.lookupCitiesApplyFilter(filters);
    } else {
      this.props.setCity(value);
    }
  };


  //State
  lookupStatesOnPage = e => {
    this.props.lookupStatesPaginate({
      max: e.rows,
      index: e.first
    });
  };
  lookupStatesOnSort = e => {
    this.props.lookupStatesSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField
    });
  };
  lookupStatesOnFilter = e => {
    const filters = [];
    const keys = Object.keys(e.filters) || [];
    keys.forEach(key => {
      filters.push({
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: "CONTEM"
      });
    });
    filters.push({
      campo: 'type',
      valor: 'STATE',
      tipoFiltro: 'IGUAL_A'
    });

    this.props.lookupStatesApplyFilter(filters);
  };
  lookupStatesOnClick = e => {
    const filters = [];
    filters.push({
      campo: 'type',
      valor: 'STATE',
      tipoFiltro: 'IGUAL_A'
    })
    this.props.lookupStatesApplyFilter(filters);
    //this.props.lookupStatesLoad();
    this.props.lookupStatesSetVisible(true);
  };
  lookupStatesOnHide = e => {
    this.props.lookupStatesSetVisible(false);
  };
  lookupStatesOnConfirm = e => {
    this.props.setState(e.selection);
    this.props.lookupStatesSetVisible(false);
  };
  lookupStatesOnCancel = e => {
    this.props.setState(null);
    this.props.lookupStatesSetVisible(false);
  };

  lookupStatesOnEdit = () => {
    if (this.props.record.address && this.props.record.address.city) {
      this.props.history.push(
        `${routes.PATH_CLIENT}/${this.props.record.address.city}`
      );
    }
  };

  lookupStatesOnComplete = e => {
  };

  lookupStatesOnInputFilter = e => {
    const value = e.value;
    if (typeof value === "string") {
      const filters = [
        {
          campo: "name",
          valor: e.value,
          tipoFiltro: "CONTEM"
        }
      ];
      filters.push({
        campo: 'type',
        valor: 'STATE',
        tipoFiltro: 'IGUAL_A'
      });

      this.props.setField("address.state", e.value);
      this.props.lookupStatesApplyFilter(filters);
    } else {
      this.props.setState(value);
    }
  };

  onCompleteCep = e => {
    const value = e.originalEvent.target.value.replace('-', '');
    const filters = [];
    filters.push({
      campo: 'zipCodeRanges',
      valor: value,
      tipoFiltro: 'CUSTOM'
    })
    this.props.setField('address.cep',value)
    this.props.lookupCitiesApplyFilter(filters,(err,data)=>{
      if(data && data.records && data.records.length > 0){
        this.props.setCity(data.records[0]);
      }
    });
  }

  render() {

    const title = `Cliente ${this.props.record.oid || 'novo'}`
    const header = (
      <div>
        <h1>{title}</h1>
        <div>
          <Toolbar
            onSave={this.props.saveHandler} saveButtonStyle='highlighted'
            onRemove={this.props.removeHandler} removeButtonStyle='danger'
            onNew={this.props.newHandler}
            onSearch={this.searchHandler}>
          </Toolbar>
        </div>
      </div>
    );

    const content = (
      <ClientForm
        record={this.props.record}
        changeHandler={this.props.changeHandler}

        onCompleteCep={this.onCompleteCep}

        lookupCities={this.props.lookupCities}
        lookupCitiesOnHide={this.lookupCitiesOnHide}
        lookupCitiesOnConfirm={this.lookupCitiesOnConfirm}
        lookupCitiesOnCancel={this.lookupCitiesOnCancel}
        lookupCitiesOnPage={this.lookupCitiesOnPage}
        lookupCitiesOnSort={this.lookupCitiesOnSort}
        lookupCitiesOnFilter={this.lookupCitiesOnFilter}
        lookupCitiesOnClick={this.lookupCitiesOnClick}
        lookupCitiesOnEdit={this.lookupCitiesOnEdit}
        lookupCitiesOnComplete={this.lookupCitiesOnComplete}
        lookupCitiesOnInputFilter={this.lookupCitiesOnInputFilter}

        lookupStates={this.props.lookupStates}
        lookupStatesOnHide={this.lookupStatesOnHide}
        lookupStatesOnConfirm={this.lookupStatesOnConfirm}
        lookupStatesOnCancel={this.lookupStatesOnCancel}
        lookupStatesOnPage={this.lookupStatesOnPage}
        lookupStatesOnSort={this.lookupStatesOnSort}
        lookupStatesOnFilter={this.lookupStatesOnFilter}
        lookupStatesOnClick={this.lookupStatesOnClick}
        lookupStatesOnEdit={this.lookupStatesOnEdit}
        lookupStatesOnComplete={this.lookupStatesOnComplete}
        lookupStatesOnInputFilter={this.lookupStatesOnInputFilter}
      />);
    return (<Page header={header} content={content} />);
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientFormPageContainer);
