import React from 'react';
import { InputText } from 'primereact/inputtext';
import './InputPhone.scss';

const processValue = (value) =>{
    let cleanerValue = value.replace(/\D/g,'');
        let formatedValue = "";
        for(let index = 0; index < cleanerValue.length; index++){
            if(index == 0){
                formatedValue += '(';   
            }else if(index == 2){
                formatedValue += ') ';                
            }else if(index == 7){
                formatedValue += '-';
            }
            formatedValue += cleanerValue[index]
        }
        return {
            formatedValue,
            cleanerValue
        }
}

export class InputPhone extends React.Component {
    constructor(props){
        super(props);
        const { formatedValue, cleanerValue } = processValue(props.value || "")
        this.state = {
            viewValue: formatedValue,
            value:cleanerValue
        };
    }

    componentWillReceiveProps(nextProps) {
        if(this.state.value != nextProps.value){
            const { formatedValue, cleanerValue } = processValue(nextProps.value);
            this.setState((prev) => {return {viewValue: formatedValue, value: cleanerValue}});
        }
    }

    
    onChangeHandler = (e) => {
        const target = e.target.cloneNode(true);
        let value = target.value;
        const { formatedValue, cleanerValue } = processValue(value);
        this.setState((prev) => {return { viewValue: formatedValue, value: cleanerValue }});
        if(this.props.onChange){
            target.value = cleanerValue;
            target.viewValue = formatedValue;
            const event = {
                ...e,
                target
            }
            this.props.onChange(event);
        }
    }

    render(){
        return(
            <div className="lxInputPhone">
                <div className="p-inputgroup">
                    <InputText 
                        name={this.props.name} 
                        disabled={this.props.disabled} 
                        value={this.state.viewValue}
                        onChange={this.onChangeHandler}
                        placeholder={"(99) 99999-9999"}
                        maxLength={15}
                    />
                </div>
            </div>   
        );
    }
}