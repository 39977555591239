
    import React from 'react';
    import SegmentListPageContainer from './SegmentListPageContainer';
    
    export class SegmentListPage extends React.Component{
        
        render(){
          return (
            <SegmentListPageContainer {...this.props}/>
          )
        }
    }