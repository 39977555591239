
import React from 'react';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';

export const ModalForm = (props) => {

    return (
        <div className="modal-form">
            <div className="p-grid">
                <div className="p-col-6">
                    <div className="p-grid">

                        <div className="p-col-6 form-field-container">
                            <label>ID</label>
                            <InputText value={props.record.oid} onChange={props.changeHandler} name="oid" disabled={true} />
                        </div>
                        <div className="p-col-6 form-field-container"></div>
                        <div className="p-col-6 form-field-container">
                            <label>Código*</label>
                            <InputText value={props.record.code} onChange={props.changeHandler} name="code" disabled={false} />
                        </div>
                        <div className="p-col-12 form-field-container">
                            <label>Nome*</label>
                            <InputText value={props.record.name} onChange={props.changeHandler} name="name" disabled={false} />
                        </div>
                        <div className="p-col-8 form-field-container">
                            <label>Tipo transporte*</label>
                            <Dropdown value={props.record.transportType} onChange={props.changeHandler} name="transportType" options={props.transportTypes} ></Dropdown>
                        </div>
                        <div className="p-col-8 form-field-container">
                            <label>Tipo tabela de frete*</label>
                            <Dropdown value={props.record.freightTableType} onChange={props.changeHandler} name="freightTableType" options={props.freightTableTypes} ></Dropdown>
                        </div>
                        <div className="p-col-8 form-field-container">
                            <label>Tipo coleta</label>
                            <Dropdown value={props.record.pickupType} onChange={props.changeHandler} name="pickupType" options={props.pickupTypes} ></Dropdown>
                        </div>
                        <div className="p-col-8 form-field-container">
                            <label>Tipo entrega</label>
                            <Dropdown value={props.record.deliveryType} onChange={props.changeHandler} name="deliveryType" options={props.deliveryTypes} ></Dropdown>
                        </div>
                        <div className="p-col-6 form-field-container">
                            <label>Identificador serviço</label>
                            <InputText value={props.record.serviceId} onChange={props.changeHandler} name="serviceId" disabled={false} />
                        </div>
                        <div className="p-col-6 form-field-container">
                            <label>Código serviço</label>
                            <InputText value={props.record.serviceCode} onChange={props.changeHandler} name="serviceCode" disabled={false} />
                        </div>
                        <div className="p-col-12 form-field-container">
                            <label>Descrição serviço</label>
                            <InputText value={props.record.serviceDescription} onChange={props.changeHandler} name="serviceDescription" disabled={false} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};