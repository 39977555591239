import React from "react";
import { InputText } from "primereact/inputtext";

import "./TowerEventControlActionForm.scss";

export const TowerEventControlForm = ({ record, changeHandler }) => {
  return (
    <div className="tower-event-form">
      <div className="p-grid">
        <div className="p-col-6">
          <div className="p-grid">
            <div className="p-col-3">
              <div className="form-field-container">
                <label>Código</label>
                <InputText
                  value={record.oid}
                  onChange={changeHandler}
                  disabled={true}
                />
              </div>
            </div>

            <div className="p-col-12 form-field-container">
              <label className="lxRequiredField">Nome do Evento</label>
              <InputText
                value={record.eventName}
                onChange={changeHandler}
                name="eventName"
              />
            </div>

            <div className="p-col-4 form-field-container">
              <label>Ação para o Evento</label>
              <InputText
                value={record.actionType}
                onChange={changeHandler}
                name="actionType"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
