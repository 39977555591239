    import { types } from "./location.actions";
    import { Reducer } from "../../services/reducer.factory";
    
    const initialState = {
      columns: [{
        field: "oid",
        header: "Código",
        columnKey: "oid",
        sortable: true,
        filter: true,
        style:{width:'20%'}
      },{
        field: "name",
        header: "Nome",
        columnKey: "name",
        sortable: true,
        filter: true
      },
      {
        field: "description",
        header: "Descrição",
        columnKey: "description",
        sortable: true,
        filter: true
      },
      {
        field: "ibgeCode",
        header: "Código IBGE",
        columnKey: "ibgeCode",
        sortable: true,
        filter: true
      },{
        field: "initialZipCode",
        header: "Latitude",
        columnKey: "initialZipCode",
        sortable: true,
        filter: true
      },{
        field: "finalZipCode",
        header: "CEP Final",
        columnKey: "finalZipCode",
        sortable: true,
        filter: true
      },{
        field: "type",
        header: "Tipo",
        columnKey: "type",
        sortable: true,
        filter: true
      }],
      currentRecord: {oid:"",name:"", description: "",ibgeCode:"",initialZipCode:"",finalZipCode:"",type:""},
      locationFilterType: null

    };
    
    const reducer = new Reducer(initialState, types);
    
    export function CRUDLocationReducer(state, action) {
      state = reducer.execute(state, action);
      return execute(state,action);
    }
    
    function execute(state, action){
        let result = state;
        switch(action.type){
          case types.SET_LOCATION_FILTER_TYPE:
            return setLocationFilterType(state,action);
          default:
            return result;
        }
    }

    function setLocationFilterType(state,action){
      state = Object.assign({},state,{locationFilterType:action.locationFilterType});
      return state;
    }
   
    
    
    