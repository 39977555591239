export function validate(record) {
    const errors = [];

    if (!record.description || record.description.trim() === "") {
        errors.push({ field: 'description', message: 'A descrição da ocorrência obrigatória!' });
    }
    
    if (!record.code || record.code.toString().trim() === "" || parseInt(record.code) <= 0) {
        errors.push({ field: 'code', message: 'O Código da ocorrência é obrigatório!' });
    }

    if (record.groupType === "SYSTEM") {
        errors.push({ field: 'code', message: 'Esta ocorrência não pode ser alterada!' });
    }        
    
    const result = {
        ok: errors.length === 0,
        errors,
        record
    }

    return new Promise((resolve, reject) => {
        const action = result.ok ? resolve : reject;
        return action(result);
    })
}