import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

export const CadastroVeiculosList = (props) => (
  <DataTable
    selectionMode="single"
    value={props.records}
    emptyMessage="Não foram encontrados registros"  

    onFilter={props.onFilter}
    onSort={props.onSort}
    onPage={props.onPage} 
    lazy={true}
    totalRecords={props.totalRecords}
    sortOrder={props.order}
    sortField={props.sortField}
    paginator={true}
    first={props.first}
    rows={props.rows} 
    rowsPerPageOptions={[5, 10, 20, 50]} 
    scrollable={true}
    scrollHeight="600px"
  >
    {props.columns}
  </DataTable>
);
