import React from "react";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import "./index.scss";
import { ButtonDialog } from "../../../../components/button-dialog/ButtonDialog";
import { formatNumber, formatNumberDefault, numberToCurrency } from "../../../../utils";
import { TableModalGroupByClients } from "./TableModalGroupByClients";
import { InputSwitch } from "primereact/inputswitch";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "../../../../components/input-number/InputNumber";
import { InputText } from "primereact/inputtext"; 

export default class ModalGroupByClients extends React.Component {
  constructor(props) {
    super(props);

  }
  onChangeSelectedCD = (e, index) => {
    this.props.setTypeSelectedCDGroupClient({ index, cd: e.value });
  };


  render() {
    const footer = (
      <div>
        <ButtonDialog
          onClick={() => this.props.onConfirm()}
          button={{
            title: "Confirmar",
            className: "lognex-btn-highlighted",
            iconClassName: "pi pi-check",
          }}
          dialog={{
            header: "Confirmação",
            modal: false,
            message: "Deseja realmente aplicar a otmização das rotas por cliente?",
          }}
        ></ButtonDialog>

        <button title="Cancelar" className="lognex-btn-danger" onClick={this.props.onHide}>
          <i className="pi pi-times"></i>
        </button>
      </div>
    );

    const templateCustoKg = (rowData, e) => {
      return <span>{formatNumberDefault(parseFloat(rowData.weight))}{"Kg"}</span>;
    };

    const columns = this.props.columns.map((col) => {
      if (col.field == "weight") {
        return <Column {...col} key={col.field} body={templateCustoKg} style={{ width: "180px" }} />;
      }
      return <Column {...col} key={col.field} />;
    });


    //mostrar somente veiculos que são dos CDs das Ordens
    let vehicles = this.props.vehicles;




    vehicles.sort((a, b) => {
      return b.pallets - a.pallets;
    });
    const isVeicleSelected = (indexG, dataVehicle) => {
      try {
        if (this.props.records[indexG].vehicles) {
          for (let index = 0; index < this.props.records[indexG].vehicles.length; index++) {
            if (this.props.records[indexG].vehicles[index].code == dataVehicle.code) {
              return true
            }
          }
        }
      } catch (error) {
        console.log("isVeicleSelected erro= ", error)
      }
      return false
    }

    const getVeiculosByCd = (rec, indexG) => {

      return rec.vehicles.map((vehicle, index) => {
        return <div key={indexG + index + vehicle.cd + vehicle.code} className="p-md-3  form-field-container align-veicle">
          <div className="vehicle-qtd">
            <InputText type="number" step={1} min={0} style={{ width: "53px", paddingRight: "5px" }} value={vehicle.qtd} onChange={(e) => {
              this.props.setTypeSelectedVeicleGroupClient({ indexG, qtd: e.target.value, index })
            }} />
            <h3 style={{ marginLeft: "5px" }}>{vehicle.name}</h3>
            <span title="Custo de frete em relação ao outros veículos">{"("}{formatNumber(vehicle.fatorValorPallets, 0)}{"%)"}</span>
          </div>
        </div>
      })
    }
    const getShortName = (name) => {
      if (String(name).trim().length > 30) {
        return String(name).trim().substring(0, 30) + "..."
      }
      return name;
    }
    const showResumoVeiculos = (rec, indexG) => {
      if (rec.deliveryByVehicleType && Array(rec.deliveryByVehicleType).length > 0) {
        return rec.deliveryByVehicleType.map((deli, indexRem) => {
          return <div className="p-col-3 resumo-vehicles" key={"resumo-vehicle-" + indexRem}>
            <div className="content-name-vehicle">
              <InputSwitch checked={deli.enabled}
                onChange={(e) => this.props.onEnableVehicle({ enable: e.value, indexG, index: indexRem })}
              />
              <h3>{deli.vehicle.name}</h3>
            </div>
            <p>{`.........PALLETS: `}<span className="resum-vehicle-destaq">{formatNumber(deli.ocupation, 0)}%</span></p>
            <p>{`QTD-PALLETS: `}<span className="resum-vehicle-destaq">{deli.pallets}</span></p>
            <p>{`...QTD-SACOS: `}<span className="resum-vehicle-destaq">{deli.sacos}</span></p>
            <p>{`.......DELIVERY: `}<span className="resum-vehicle-destaq">{deli.deliveries.length}</span></p>
            <p>{`..................KM: `}<span className="resum-vehicle-destaq">{formatNumber(deli.km, 0)}{" KM"}</span></p>
            <p>{`CUSTO/SACO: `}<span className="resum-vehicle-destaq">{deli.custoSaco}</span></p>
          </div>
        })
      }
      return <></>
    }
    const showResumoDeliveryNotIncluded = (rec) => {
      if (rec.deliveryByVehicleType && rec.deliveryByVehicleType.length > 0 && (rec.deliveryMaiorQueMaiorVehicle > 0 || rec.totalSobraMenorVeiculo > 0)) {
        return <div className="p-col-9 form-field-container" title="Total delivery sobra, não ocupa mais que 60% do menor veículo disponível">
          <label>Total delivery não inclusa</label>
          {rec.deliveryMaiorQueMaiorVehicle > 0 &&
            <span className="resum-delivery-notincluded">{rec.deliveryMaiorQueMaiorVehicle} Delivery com QTDE pallets maior que o maior veículo</span>
          }
          {rec.totalSobraMenorVeiculo > 0 &&
            <span className="resum-delivery-notincluded">{rec.totalSobraMenorVeiculo} Delivery não foi possível incluir</span>
          }
        </div>
      }
      return <></>
    }

    return (
      <Dialog
        className="content"
        header={"Otimizar cargas por cliente"}
        footer={footer}
        visible={this.props.visible}
        modal={true}

        onHide={this.props.onHide}
      >
        <div className="optimize-client">
          {this.props.records && (

            this.props.records.map((rec, index) => {

              return <div className="p-grid content-client" key={index}>
                <div className="p-col-2 form-field-container">
                  <span>{"Peso: "}<h3>{formatNumberDefault(parseFloat(rec.weight))}{"Kg"}</h3></span>
                </div>
                <div className="p-col-2 form-field-container">
                  <span>{"Pallets: "}<h3>{formatNumberDefault(parseFloat(rec.pallets))}</h3></span>
                </div>
                <div className="p-col-3 form-field-container">
                  <span>{"CNPJ: "}<h3>{rec.clientDoc}</h3></span>
                </div>
                <div className="p-col-5 form-field-container">
                  <span>{"Cliente: "}<h3>{getShortName(rec.name)}</h3></span>
                </div>
                <div className="p-col-12 form-field-container">
                  <TableModalGroupByClients
                    records={rec.deliveries}
                    columns={columns} />
                </div>
                <div className="p-col-12 form-field-container">
                  <h3>Quantidade Veículos disponíveis por tipo</h3>
                  <div className="p-grid">
                    {getVeiculosByCd(rec, index)}
                  </div>
                </div>
                {/* <div className="p-col-3 form-field-container">
                  <label>Selecione o CD de origem</label>
                  <Dropdown
                    placeholder="Selecione"
                    value={rec.cdStart}
                    dataKey={"cd"}
                    key={Math.random()}
                    optionLabel="cd"
                    options={rec.cds.map(t => ({ cd: t }))}
                    onChange={(e) => this.onChangeSelectedCD(e, index)}
                  />
                </div> */}
                {rec.deliveryByVehicleType && rec.deliveryByVehicleType.length > 0 && (
                  showResumoDeliveryNotIncluded(rec)
                )}

                {rec.deliveryByVehicleType && rec.deliveryByVehicleType.length > 0 && (
                  <div className="p-col-12 form-field-container">
                    <div className="resumo-veiculo-header">
                      <h3>RESUMO DA MONTAGEM DOS VEÍCULOS</h3>
                      <span className="custo-medio">CUSTO MÉDIO POR SACO: {numberToCurrency(rec.custoMedioSaco, "R$")}</span>
                      <span className="custo-medio">VALOR TOTAL FRETE: {numberToCurrency(rec.somaCustoFreteTotal, "R$")}</span>
                    </div>
                  </div>
                )}
                {showResumoVeiculos(rec, index)}

              </div>
            })
          )}
        </div>
      </Dialog>
    );
  }
}
