

export function validate(record) {
    const errors = [];
    if (!record.dataInicio) {
        errors.push({ field: 'dataInicio', message: 'O código Data Inicio é obrigatório!' });
    }
    if (!record.dataFim) {
        errors.push({ field: 'dataFim', message: 'O código Data Fim é obrigatório!' });
    }
    if (!record.maketplaceName || String(record.maketplaceName).trim().length == 0) {
        errors.push({ field: 'dataFim', message: 'O nome do marketplace é obrigatório!' });
    }
    if (!record.descricao || String(record.descricao).trim().length == 0) {
        errors.push({ field: 'dataFim', message: 'A descrição é obrigatório!' });
    }
    if ((record.valMin && record.valMin > 0) || (record.valMax && record.valMax > 0)) {
        const valorInformado = (record.valMin && record.valMin > 0) && (record.valMax && record.valMax > 0)
        console.log("valorInformado=", valorInformado)
        if (!valorInformado) {
            errors.push({ field: 'dataFim', message: 'Deve preencher os dois campos valor mínimo e máximo!' });
        }
    }
    const result = {
        ok: errors.length === 0,
        errors,
        record
    }

    return new Promise((resolve, reject) => {
        const action = result.ok ? resolve : reject;
        return action(result);
    })
}