import { validate } from "./validations";
import { build } from "../../services/crudFactory";
import { Action } from "../../services/actions.factory";
import api from "../../services/api.service";

const BASE_PATH = "/lognex/policesResources";
const prefix = "crudPolicesResources";
const crud = build(validate, BASE_PATH);

const newTypes = {
  SET_LOOKUP_POLICES_VISIBLE: `${prefix}setLookupPolicesVisible`,
  SET_LOOKUP_MENUS_VISIBLE: `${prefix}setLookupMenusVisible`,
}


const actions = new Action(prefix, crud);

const types = Object.assign({}, actions.types, newTypes);

actions.setLookupMenusVisible = (visible) => {
  return {
    type: types.SET_LOOKUP_MENUS_VISIBLE,
    visible,
  };
};

actions.setLookupPolicesVisible = (visible) => {
  return {
    type: types.SET_LOOKUP_POLICES_VISIBLE,
    visible,
  };
};

export { types, actions };
