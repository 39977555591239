import { types } from "./empresa.actions";
import { Reducer } from "../../services/reducer.factory";

const initialState = {
  columns: [
    {
      field: "oid",
      header: "Código",
      columnKey: "oid",
      sortable: true,
      filter: true
    },
    {
      field: "code",
      header: "Código",
      columnKey: "code",
      sortable: true,
      filter: true
    },
    {
      field: "tradeName",
      header: "Nome",
      columnKey: "tradeName",
      sortable: true,
      filter: true
    },
    {
      header: "Razão Social",
      field: "name",
      columnKey: "name",
      sortable: true,
      filter: true
    }
  ],
  currentRecord: {
    oid: "",
    accountid:"",
    code: "",
    name: "",
    oidPessoa:"",
    tradeName:"",
    documentNumber:"",
    address:{
      street: "",
      number: "",
      complement: "",
      district: "",
      city: "",
      zipCode: null
    },
    businessUnits:[],
    contact:"",
    site:"",
    phone:"",
    email:"",
    avatar:"",
  }
  
};

const reducer = new Reducer(initialState, types);

export function CRUDEmpresaReducer(state, action) {
  state = reducer.execute(state, action);
  return execute(state, action);
}

function execute(state, action) {
  let result = state;
  switch (action.type) {
    case types.SET_LOOKUP_PESSOA_VISIBLE:
      return setLoockupPessoaVisible(state, action);
    default:
      return result;
  }
}

function setLoockupPessoaVisible(state, action) {
  let lookupPessoa = state.lookupPessoa;
  lookupPessoa = Object.assign({}, lookupPessoa, { visible: action.visible });
  return Object.assign({}, state, { lookupPessoa });
}
