import React from "react";
import { tooltip } from "primereact/tooltip";
import { connect } from "react-redux";
import { Page } from "../../../components/page/Page";
import { actions as actionsTravel } from "../../towerTravel/tower_travel.actions";
import { actions as actionsDashboard } from "../../towerDashboard/tower_dashboard.actions";
import { actions } from "../tower_travel_visualize_map.actions";
import { messageActions, makeMessages } from "../../../store/message";
import { routes } from "../../../constants";
import { AppService, COMPANY_CHANGE_EVENT_NAME, BUSINESS_UNIT_CHANGE_EVENT_NAME } from "../../../services/app.service";

import { IndicatorTravel } from "../../towerDashboard/components/indicator/totalizadores";
import storage from '../../../services/stoage.service'

import vehicleIconSuccess from "../../../assets/images/icons/truck_success.png";
import vehicleIconWarning from "../../../assets/images/icons/truck_warning.png";
import vehicleIconNormal from "../../../assets/images/icons/truck_normal.png";

import vehicleIconCanceled from "../../../assets/images/icons/truck_failure.png";
import vehicleIconLagTrend from "../../../assets/images/icons/truck_success_with_pendings.png";
import pin_green from "../../../assets/images/icons/pin_green.svg";
import pin_red from "../../../assets/images/icons/pin_red.svg";
import pin_blue from "../../../assets/images/icons/pin_blue.svg";

import "./tower-travel-map-portal.scss";
import { CANCELED, CREATED, END_TRAVEL, ORIGEM_PORTAL_TRANSPORTADOR, TRAVELING, TRAVEL_LAG_TREND, TRAVEL_LATE, TRAVEL_ON_TIME } from "../../towerDashboard/constants";
import Map from "../form/map";
import moment from "moment";
import { InputDeliveryDateDialog } from "../components/dialogChangeDateDelivery";
const mapStateToProps = (state) => {


  const totalizers = state.crudTowerTravelVisualizeMapState.totalizer;
  const travels = state.crudTowerTravelVisualizeMapState.travels || [];
  const userConfigurations = state.appState.userConfigurations;
  let towerShowTotalizers = userConfigurations.towerShowTotalizers || {
    total: true,
    andamento: true,
    atraso: true,
    tendencia_atraso: true,
    on_time: true,
    notmirrored: true,
    finalizados: false,
    canceladas: false,
  };
  const carrier = state.crudPortalCarrierState.carrier

  return {
    travels,
    totalizers,
    towerShowTotalizers,
    selectedTypeFilter: state.crudTowerTravelVisualizeMapState.selectedTypeFilter,
    carrier,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    applyFilter: (filterParams) => {
      dispatch(actions.getAllTravelsPortal(filterParams));
    },


    loadAllUnidade: () => {
      dispatch(actions.findAllBusinessunits());
    },

    loadTotalizersPortal: (filters) => {
      dispatch(actions.loadTotalizersPortal(filters));
    },

    setSelectedTypeFilter: (selectedTypeFilter) => {
      dispatch(actions.setSelectedTypeFilter(selectedTypeFilter));
    },
  };
};

class TowerTravelVisualizeMapPortalListPageContainer extends React.Component {
  constructor(props) {
    super(props);
    this.intervalFecht = null;
    this.fechtTravelByInterval = this.fechtTravelByInterval.bind(this);
  }

  componentDidMount() {
    AppService.on(COMPANY_CHANGE_EVENT_NAME, this);
    AppService.on(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
    this.props.loadAllUnidade();
    this.props.setSelectedTypeFilter("andamento");
    this.onAplyFilter();
    this.intervalFecht = setInterval(this.fechtTravelByInterval, 60 * 60000);
  }
  componentDidUpdate() {
  }

  componentWillUnmount() {
    AppService.off(COMPANY_CHANGE_EVENT_NAME, this);
    AppService.off(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
    clearInterval(this.intervalFecht);
  }

  onReceiveAppNotifications = (eventName, params) => {
    if (eventName === COMPANY_CHANGE_EVENT_NAME || eventName === BUSINESS_UNIT_CHANGE_EVENT_NAME) {
      this.props.loadAllUnidade();
      this.props.setSelectedTypeFilter("andamento");
      this.onAplyFilter();
    }
  };

  fechtTravelByInterval = function () {
    this.onAplyFilter();
  };

  getFilters = () => {
    let filters = [];


    let initialDate = moment().startOf("month").toDate();
    let finalDate = moment().toDate();
    filters.push({
      field: "eventRecord.createdAt",
      startValue: moment(initialDate).startOf("day").toDate(),
      endValue: moment(finalDate).endOf("day").toDate(),
      type: "BETWEEN",
    });
    const user = storage.getUser();
    if (user.carrier) {
      filters.push({
        field: "carrier.documentNumber",
        value: user.carrier.cnpj,
        type: 'EQUALS'
      })
    } else {
      const carrier = this.props.carrier;
      if (carrier && carrier.documentNumber && carrier.documentNumber != "") {
        filters.push({
          field: "carrier.documentNumber",
          value: carrier.documentNumber,
          type: 'EQUALS'
        })
      }
    } 
    return filters;
  };

  onAplyFilter = () => {
    // const filters = [...this.getFilters()];
    const filtersMapParamsPortal = this.getFilters();
    this.props.loadTotalizersPortal(filtersMapParamsPortal);
    //  
    if (this.props.selectedTypeFilter == "andamento") {
      this.onClickAndamento();
    } else if (this.props.selectedTypeFilter == "atraso") {
      this.onClickAtraso();
    } else if (this.props.selectedTypeFilter == "tendencia_atraso") {
      this.onClickTendenciaAtraso();
    } else if (this.props.selectedTypeFilter == "on_time") {
      this.onClickOnTime();
    }else{
      this.onClickAndamento();
    }
  };


  onClickOnTime = () => {

    const filters = [...this.getFilters()];

    filters.push({
      campo: "status_time",
      valor: TRAVEL_ON_TIME,
      tipoFiltro: "IGUAL_A",
    });
    filters.push({
      field: "travel_status",
      value: [TRAVELING],
      type: "IN",
    });

    filters.push({
      campo: "pathTraveledCount",
      valor: 2,
      tipoFiltro: "MAIOR",
    });
    this.props.applyFilter(filters);

    this.props.setSelectedTypeFilter("on_time");
  };

  onClickTendenciaAtraso = () => {
    if (this.props.origem && this.props.origem == ORIGEM_PORTAL_TRANSPORTADOR) {
      return
    }
    const filters = [...this.getFilters()];

    filters.push({
      campo: "status_time",
      valor: TRAVEL_LAG_TREND,
      tipoFiltro: "IGUAL_A",
    });
    filters.push({
      field: "travel_status",
      value: [TRAVELING],
      type: "IN",
    });

    filters.push({
      campo: "pathTraveledCount",
      valor: 2,
      tipoFiltro: "MAIOR",
    });

    this.props.applyFilter(filters);

    this.props.setSelectedTypeFilter("tendencia_atraso");
  };

  onClickAtraso = () => {
    const filters = [...this.getFilters()];

    filters.push({
      campo: "status_time",
      valor: TRAVEL_LATE,
      tipoFiltro: "IGUAL_A",
    });
    filters.push({
      field: "travel_status",
      value: [TRAVELING],
      type: "IN",
    });

    filters.push({
      campo: "pathTraveledCount",
      valor: 2,
      tipoFiltro: "MAIOR",
    });
    this.props.applyFilter(filters);
    this.props.setSelectedTypeFilter("atraso");
  };

  onClickAndamento = () => {
    const filters = [...this.getFilters()];
    filters.push({
      field: "travel_status",
      value: [TRAVELING],
      type: "IN",
    });
    filters.push({
      campo: "pathTraveledCount",
      valor: 2,
      tipoFiltro: "MAIOR",
    });
    this.props.applyFilter(filters);
    this.props.setSelectedTypeFilter("andamento");
  };


  getTravelIcon = (travel) => {
    var iconeVeiculo = vehicleIconNormal;
    if (travel.travel_status === CREATED) {
      iconeVeiculo = vehicleIconNormal;
    } else if (travel.travel_status === CANCELED) {
      iconeVeiculo = vehicleIconCanceled;
    } else if (travel.status_time === TRAVEL_ON_TIME) {
      iconeVeiculo = vehicleIconSuccess;
    } else if (travel.status_time === TRAVEL_LAG_TREND) {
      iconeVeiculo = vehicleIconLagTrend;
    } else if (travel.status_time === TRAVEL_LATE) {
      iconeVeiculo = vehicleIconWarning;
    }

    return iconeVeiculo;
  };

  render() {

    const indicadores = <IndicatorTravel
      {...this.props.totalizers}
      onClickOnTime={this.onClickOnTime}
      onClickAndamento={this.onClickAndamento}
      onClickAtraso={this.onClickAtraso}
      onClickTendenciaAtraso={this.onClickTendenciaAtraso}
      selectedTypeFilter={this.props.selectedTypeFilter}
      towerShowTotalizers={this.props.towerShowTotalizers}
      isFromMap={true}
      origem={ORIGEM_PORTAL_TRANSPORTADOR}
    />

    let permitEditarMap = false
    const content = (
      <div className="travel-view-map">
        {indicadores}
        <Map permitEditarMap={permitEditarMap} />
      </div>
    )
    return <Page content={content} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TowerTravelVisualizeMapPortalListPageContainer);
