import { types } from "./dashboard.actions";
import { Reducer } from "../../services/reducer.factory";
import moment from 'moment';

const initialState = {
    currentRecord: {
        userConfigurationRecord: null,
    },    
    expectedAndRealized: {
        initialDateFilter: moment().startOf('month').toDate(),
        finalDateFilter: moment().toDate(),
    },
};

const reducer = new Reducer(initialState, types);

export function DashboardReducer(state, action) {
    state = reducer.execute(state, action);
    return execute(state, action);
}

function execute(state, action) {
    let result = state;
    switch (action.type) {
        case types.SET_INITIAL_DATE:
            return setInitialDate(state, action);
        case types.SET_FINAL_DATE:
            return setFinalDate(state, action);
        default:
            return result;
    }
}

function setInitialDate(state, action) {
    let expectedAndRealized = state.expectedAndRealized
    let initialDateFilter = action.date

    expectedAndRealized = Object.assign({}, expectedAndRealized, { initialDateFilter: initialDateFilter });
    return Object.assign({}, state, { expectedAndRealized })
}

function setFinalDate(state, action) {
    let expectedAndRealized = state.expectedAndRealized
    let finalDateFilter = action.date

    expectedAndRealized = Object.assign({}, expectedAndRealized, {finalDateFilter: finalDateFilter});
    return Object.assign({}, state, {expectedAndRealized})
}

