import React from 'react';
import { connect } from 'react-redux';
import { Column } from 'primereact/column';
import Toolbar from "../../../components/toolbar/Toolbar";
import { actions } from '../menu.actions';
import { actions as moduloActions } from '../../modulo/modulo.actions';
import { actions as menuActions } from '../../menu/menu.actions';
import { types as typeActions } from '../../menu/menu.actions';
import { routes } from '../../../constants';
import { Page } from "../../../components/page/Page";
import { MenuForm } from './MenuForm';


const mapStateToProps = state => {
  const record = state.crudMenuState.currentRecord;
  const lookupMenuSuperiorBase = {
    records: state.crudMenuState.records,
    columns: state.crudMenuState.columns
      .filter(col => col.field === 'oid' || col.field === 'name' || col.field === 'path' || col.field === 'key')
      .map(col => <Column key={col.field} {...col} />),
    first: state.crudMenuState.index,
    rows: state.crudMenuState.max,
    totalRecords: state.crudMenuState.count,
    sortField: state.crudMenuState.sortField,
    order: state.crudMenuState.sortOrder,
    editDisabled: !record.parent,
    rowsPerPageOptions: [5, 10, 20, 50]
  }
  /*const lookupModuloBase = {
    records: state.crudModuloState.records,
    columns: state.crudModuloState.columns
      .filter(col => col.field === 'oid' || col.field === 'nome' || col.field === 'descricao')
      .map(col => <Column key={col.field} {...col} />),
    first: state.crudModuloState.index,
    rows: state.crudModuloState.max,
    totalRecords: state.crudModuloState.count,
    sortField: state.crudModuloState.sortField,
    order: state.crudModuloState.sortOrder,
    editDisabled:!record.oidModulo
  }*/
  let lookupMenuSuperior = Object.assign({}, state.crudMenuState.lookupMenuSuperior, lookupMenuSuperiorBase);
  // let lookupModulo = Object.assign({}, state.crudMenuState.lookupModulo, lookupModuloBase);

  return {
    record,
    lookupMenuSuperior
  }
}

const mapDispatchToProps = dispatch => {
  return {
    lookupModuloSetVisible: visible => dispatch(actions.setLookupMenuVisible(visible, typeActions.SET_LOOKUP_MODULO_VISIBLE)),
    lookupModuloPaginate: paginationParams => dispatch(moduloActions.paginate(paginationParams)),
    lookupModuloSortOrder: sortParams => dispatch(moduloActions.sortOrder(sortParams)),
    lookupModuloApplyFilter: filterParams => {
      dispatch(moduloActions.setType(null));
      dispatch(moduloActions.applyFilter(filterParams))
    },
    lookupModuloLoad: () => dispatch(moduloActions.load()),
    setModulo: (modulo) => {
      dispatch(actions.setField('oidModulo', modulo ? modulo.oid : null))
      dispatch(actions.setField('nomeModulo', modulo ? modulo.nome : null));
    },

    lookupMenuSuperiorSetVisible: visible => dispatch(actions.setLookupMenuVisible(visible, typeActions.SET_LOOKUP_MENU_SUPERIOR_VISIBLE)),
    lookupMenuSuperiorPaginate: paginationParams => dispatch(menuActions.paginate(paginationParams)),
    lookupMenuSuperiorSortOrder: sortParams => dispatch(menuActions.sortOrder(sortParams)),
    lookupMenuSuperiorApplyFilter: filterParams => {
      dispatch(menuActions.setType(null));
      dispatch(menuActions.applyFilter(filterParams))
    },
    lookupMenuSuperiorLoad: () => dispatch(menuActions.load()),
    setMenuSuperior: (menuParent) => {
      dispatch(actions.setField('parent', menuParent ? menuParent.oid : null));
      dispatch(actions.setField('menuParent', menuParent ? menuParent.name : null))
    },


    changeHandler: event => {
      const target = event.target;
      const field = target.name;
      let value = target.value;

      dispatch(actions.removeErrors([{ field }]));
      dispatch(actions.setField(field, value));
    },
    saveHandler: event => {
      dispatch(actions.save());
    },
    removeHandler: event => {
      dispatch(actions.remove());
    },
    newHandler: event => {
      dispatch(actions.makeNew());
    },
    loadById: oid => dispatch(actions.loadById(oid)),
    setField: (field, value) => dispatch(actions.setField(field, value))
  }
}

class MenuFormPageContainer extends React.Component {

  constructor(props) {
    super(props);
    actions.afterSave = ({ oid }) => {
      this.props.history.replace(`${routes.PATH_MENU}/${oid}`);
    };
    actions.afterNew = () => {
      this.props.history.replace(`${routes.PATH_MENU}/novo`);
    };
    actions.afterRemove = () => {
      this.props.history.replace(`${routes.PATH_MENU}`);
    };
  }

  componentDidMount() {
    const oid = this.props.match.params.id;
    if (oid && parseInt(oid)) this.props.loadById(oid);
  }
  searchHandler = () => {
    this.props.history.push(`${routes.PATH_MENU}`)
  }


  lookupMenuSuperiorOnPage = (e) => {
    this.props.lookupMenuSuperiorPaginate({
      max: e.rows,
      index: e.first
    })
  }
  lookupMenuSuperiorOnSort = (e) => {
    this.props.lookupMenuSuperiorSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField
    })
  }

  lookupMenuSuperiorOnFilter = (e) => {
    const filters = [];
    const keys = Object.keys(e.filters) || [];
    keys.forEach(key => {
      filters.push({
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: 'CONTEM'

      })
    })
    this.props.lookupMenuSuperiorApplyFilter(filters)

  }
  lookupMenuSuperiorOnClick = (e) => {
    this.props.lookupMenuSuperiorLoad();
    this.props.lookupMenuSuperiorSetVisible(true);
  }
  lookupMenuSuperiorOnHide = (e) => {
    this.props.lookupMenuSuperiorSetVisible(false);
  }
  lookupMenuSuperiorOnConfirm = (e) => {
    this.props.setMenuSuperior(e.selection);
    this.props.lookupMenuSuperiorSetVisible(false);

  }
  lookupMenuSuperiorOnCancel = (e) => {
    this.props.setMenuSuperior(null);
    this.props.lookupSuperiorSetVisible(false);
  }

  lookupModuloOnPage = (e) => {
    this.props.lookupModuloPaginate({
      max: e.rows,
      index: e.first
    })
  }
  lookupModuloOnSort = (e) => {
    this.props.lookupModuloSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField
    })
  }

  lookupModuloOnFilter = (e) => {
    const filters = [];
    const keys = Object.keys(e.filters) || [];
    keys.forEach(key => {
      filters.push({
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: 'CONTEM'

      })
    })
    this.props.lookupModuloApplyFilter(filters)

  }
  lookupModuloOnClick = (e) => {
    this.props.lookupModuloLoad();
    this.props.lookupModuloSetVisible(true);
  }
  lookupModuloOnHide = (e) => {
    this.props.lookupModuloSetVisible(false);
  }
  lookupModuloOnConfirm = (e) => {
    this.props.setModulo(e.selection);
    this.props.lookupModuloSetVisible(false);

  }
  lookupModuloOnCancel = (e) => {
    this.props.setModulo(null);
    this.props.lookupModuloSetVisible(false);
  }

  lookupMenuSuperiorOnEdit = () => {
    if (this.props.record.oidMenuSuperior) {
      this.props.history.push(`${routes.PATH_MENU}/${this.props.record.oidMenuSuperior}`);
    }
  }
  lookupMenuSuperiorOnComplete = (e) => {
  }

  lookupMenuSuperiorOnInputFilter = (e) => {
    const value = e.value;
    if (typeof value === 'string') {
      const filters = [{
        campo: "name",
        valor: e.value,
        tipoFiltro: 'CONTEM'

      }];
      this.props.setField("menuParent", e.value);
      this.props.setField("parent", null);
      this.props.lookupMenuSuperiorApplyFilter(filters)
    } else {
      this.props.setMenuSuperior(value);
    }
  }
  /*
  lookupModuloOnEdit = () =>{
    if(this.props.record.oidModulo){
        this.props.history.push(`${routes.PATH_MODULO}/${this.props.record.oidModulo}`);
    }
  }
  lookupModuloOnComplete = (e) =>{
  }
  
  lookupModuloOnInputFilter =(e) =>{
    const value = e.value;
    if(typeof value === 'string'){
        const filters = [{
            campo: "nome",
            valor: e.value,
            tipoFiltro: 'CONTEM'
  
        }];
        this.props.setField("nomeModulo",e.value);
        this.props.setField("oidModulo",null);
        this.props.lookupModuloApplyFilter(filters)
    }else {
        this.props.setModulo(value);
    }
  }*/


  render() {
    const content = (
      <MenuForm
        changeHandler={this.props.changeHandler}
        record={this.props.record}
        lookupMenuSuperior={this.props.lookupMenuSuperior}
        lookupMenuSuperiorOnHide={this.lookupMenuSuperiorOnHide}
        lookupMenuSuperiorOnConfirm={this.lookupMenuSuperiorOnConfirm}
        lookupMenuSuperiorOnCancel={this.lookupMenuSuperiorOnCancel}
        lookupMenuSuperiorOnPage={this.lookupMenuSuperiorOnPage}
        lookupMenuSuperiorOnSort={this.lookupMenuSuperiorOnSort}
        lookupMenuSuperiorOnFilter={this.lookupMenuSuperiorOnFilter}
        lookupMenuSuperiorOnClick={this.lookupMenuSuperiorOnClick}

        lookupMenuSuperiorOnEdit={this.lookupMenuSuperiorOnEdit}
        lookupMenuSuperiorOnComplete={this.lookupMenuSuperiorOnComplete}
        lookupMenuSuperiorOnInputFilter={this.lookupMenuSuperiorOnInputFilter}

      // lookupModulo={this.props.lookupModulo}
      // lookupModuloOnHide={this.lookupModuloOnHide}
      // lookupModuloOnConfirm={this.lookupModuloOnConfirm}
      // lookupModuloOnCancel={this.lookupModuloOnCancel}
      // lookupModuloOnPage={this.lookupModuloOnPage}
      // lookupModuloOnSort={this.lookupModuloOnSort}
      // lookupModuloOnFilter={this.lookupModuloOnFilter}
      // lookupModuloOnClick={this.lookupModuloOnClick}

      // lookupModuloOnEdit = {this.lookupModuloOnEdit}
      // lookupModuloOnComplete={this.lookupModuloOnComplete}
      // lookupModuloOnInputFilter={this.lookupModuloOnInputFilter}
      />
    );
    const title = `Menu ${this.props.record.oid || 'novo'}`
    const header = (
      <div>
        <h1>{title}</h1>
        <div>
          <Toolbar
            onSave={this.props.saveHandler} saveButtonStyle='highlighted'
            onRemove={this.props.removeHandler} removeButtonStyle='danger'
            onNew={this.props.newHandler}
            onSearch={this.searchHandler}>
          </Toolbar>
        </div>
      </div>
    );
    return (<Page header={header} content={content} />);
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuFormPageContainer);