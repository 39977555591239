import React from 'react';

import PolicesResourcesFormPageContainer from './PolicesResourcesFormPageContainer';

export class PolicesResourcesFormPage extends React.Component {

    render() {
        return (
            <PolicesResourcesFormPageContainer {...this.props} />
        )
    }
}
