import React from "react";
import { DataTable } from "primereact/datatable";

export const TableVehicles = (props) => (
  <DataTable
    value={props.records}
    emptyMessage="Não foram encontrados registros"
      onRowClick={props.rowClickHandler}
    scrollable={true}
    scrollHeight={"300px"}
    onSelectionChange={props.onSelectedEventsChange}
    selection={props.selectedRow}
  >
    {props.columns}
  </DataTable>
);
