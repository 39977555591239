import React from "react";
import { Link } from 'react-router-dom';
import { connect } from "react-redux";

import Toolbar from "../../../components/toolbar/Toolbar";
import apiService from "../../../services/api.service";
import { Card } from 'primereact/card';
import { Page } from "../../../components/page/Page";
import { Dialog } from 'primereact/dialog';
import { Column } from 'primereact/column';
import { routes } from '../../../constants';
import { LookupField } from '../../../components/lookup/LookupField';
import { DeliveryDeadlineList } from "./DeliveryList";
import { actions as appActions } from '../../../store/app';
import { AppService, COMPANY_CHANGE_EVENT_NAME, BUSINESS_UNIT_CHANGE_EVENT_NAME } from "../../../services/app.service";
import * as menuActions from '../../../store/menu/menu.actions';
import * as utils from '../../../utils'
import './DeliveryListPageContainer.scss';
import { messageActions } from "../../../store/message/index";
import { actions } from '../delivery.actions'
import { actions as carrierActions } from '../../carrier/carrier.actions';

const mapStateToProps = state => {

    let carriers = state.crudCarrierState.records || [];
    
    carriers = carriers.map((carrier)=>{
       const {name, documentNumber,tradeName} = carrier || {}
        return {...carrier, name, documentNumber,tradeName}
    })

    const lookupCarrierBase = {
        records:carriers,
        record: state.crudDeliveryDeadlineState.currentRecord,
        columns: state.crudCarrierState.columns
            .filter(col => col.field === 'oid' || col.field === 'name' || col.field === 'tradeName')
            .map(col => <Column key={col.field} {...col} />),
        first: state.crudCarrierState.index,
        rows: state.crudCarrierState.max,
        totalRecords: state.crudCarrierState.count,
        sortField: state.crudCarrierState.sortField,
        order: state.crudCarrierState.sortOrder,
        rowsPerPageOptions:[20,50,100],
        paginator:true,
    }
    let lookupCarrier = Object.assign({}, state.crudDeliveryDeadlineState.lookupCarrierExportXLSX, lookupCarrierBase);


    const currentMenu = state.menuState.currentRecord;
    const userConfigurations = state.appState.userConfigurations;
    let isFavorite = utils.isFavorite(currentMenu, userConfigurations);
    return {
        columns: state.crudDeliveryDeadlineState.columns,
        records: state.crudDeliveryDeadlineState.records,
        first: state.crudDeliveryDeadlineState.index,
        rows: state.crudDeliveryDeadlineState.max,    
        totalRecords: state.crudDeliveryDeadlineState.count,
        sortField: state.crudDeliveryDeadlineState.sortField,
        order: state.crudDeliveryDeadlineState.sortOrder,
        importFilesVisible: state.crudDeliveryDeadlineState.importFilesVisible,
        menuItems: state.menuState.items,
        totalImportedRecords: state.crudDeliveryDeadlineState.totalRecords,
        totalSuccessRecords: state.crudDeliveryDeadlineState.totalSuccessRecords,
        totalFailedRecords: state.crudDeliveryDeadlineState.totalFailedRecords,
        tableErrors: state.crudDeliveryDeadlineState.tableErrors,
        log: state.crudDeliveryDeadlineState.log,
        errorsDialogVisible: state.crudDeliveryDeadlineState.errorsDialogVisible,
        modalExportPrazo: state.crudDeliveryDeadlineState.modalExportPrazo,
        carrierExport : state.crudDeliveryDeadlineState.carrierExport,
        lookupCarrier,
        currentMenu,
        isFavorite,
    }
}

const mapDispatchToProps = dispatch => {

    const makeMessages = (messages, type) => {
        return messages.map(message => {
          return { sticky: true, severity: type, detail: message };
        });
      };      

    return {
        paginate: paginationParams => {            
            dispatch(actions.paginate(paginationParams))
        },

        
        sortOrder: sortParams => dispatch(actions.sortOrder(sortParams)),
        
        applyFilter: filterParams => {
            dispatch(actions.setType(null));
            dispatch(actions.applyFilter(filterParams))
        },
        
        changeBooleanFilter: (e) => {
            const target = e.target;
            dispatch(actions.setBooleanFilter(target.name, target.value));
            let filters = [];
            if (target.value || target.value === false) {
                filters = [{
                    campo: `${target.name}`,
                    valor: target.value,
                    tipoFiltro: 'IGUAL_A'
                }];
            }
            dispatch(actions.applyFilter(filters));
        },
        
        load: () => dispatch(actions.load()),
        
        showFilesVisible: (value) => dispatch(actions.setFilesVisible(value)),
        
        userLastAccess: (oidMenu) => dispatch(appActions.userLastAccess(oidMenu)),
        
        setCurrentMenu: (menuPath) => dispatch(menuActions.setCurrentRecord(menuPath)),
        
        toggleFavorite: (isFavorite, oidMenu) => {
            dispatch(appActions.toggleFavorite(isFavorite, oidMenu))
        },
        
        errorsDialogHide: (visible) => dispatch(actions.setErrorsDialogVisible(visible)),
        
        setImportLogData: (data) => dispatch(actions.setImportLogData(data)),
        
        fetchRecord: () => dispatch(actions.fetchRecord()),
        
        hideLoader: () => dispatch(actions.hideLoader()),
        
        copyErrorsMessage: (message) => dispatch(messageActions.messageShowMessages(makeMessages(message, "success"))),

        onShowModalExportPrazo: (visible) => dispatch(actions.setModalExportPrazoVisible(visible)),

        // LookupCarrier
        lookupCarrierSetVisible: visible => {
            dispatch(actions.setLookupCarrierExportVisible(visible))
        },        
        setCarrier: (carrier) => {
            if (carrier) {
                const { oid=null, name=null, documentNumber=null,tradeName=null } = carrier || {}
                dispatch(actions.setCarrierExport({ oid, name, documentNumber,tradeName,person:{oid, name, documentNumber,tradeName}}));
            } else {
                dispatch(actions.setCarrierExport(null));
            }
        },       
        lookupCarrierPaginate: paginationParams => dispatch(carrierActions.paginate(paginationParams)),
        lookupCarrierSortOrder: sortParams => dispatch(carrierActions.sortOrder(sortParams)),
        lookupCarrierApplyFilter: filterParams => {
            dispatch(carrierActions.setType(null));
            dispatch(carrierActions.applyFilter(filterParams))
        },
        lookupCarrierLoad: () => dispatch(carrierActions.load()),

        onExportPrazoHandler: () => {
            dispatch(actions.setModalExportPrazoVisible(false));
            dispatch(actions.exportPrazo());
        }

    }
}

class DeliveryListPageContainer extends React.Component {

    newHandler = () => {
        this.props.history.push(`${routes.PATH_DELIVERY_DEADLINE}/novo`)
    }

    componentWillReceiveProps(newProps) {
        if ((!this.props.menuItems || this.props.menuItems.length === 0) && newProps.menuItems && newProps.menuItems.length > 0) {
            const menuPath = this.props.history.location.pathname;
            this.props.setCurrentMenu(menuPath)
        }
    }

    componentDidMount() {
        this.props.load();
        AppService.on(COMPANY_CHANGE_EVENT_NAME, this);
        AppService.on(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
        const menuPath = this.props.history.location.pathname;
        this.props.setCurrentMenu(menuPath)
    }
    
    componentWillUnmount() {
        AppService.off(COMPANY_CHANGE_EVENT_NAME, this);
        AppService.off(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
    }

    onReceiveAppNotifications = (eventName, params) => {
        if (eventName === COMPANY_CHANGE_EVENT_NAME || eventName === BUSINESS_UNIT_CHANGE_EVENT_NAME) {
            this.props.load();
        }
    }

    onPage = (e) => {
        this.props.paginate({
            max: e.rows,
            index: e.first
        })
    }
    onSort = (e) => {        
        this.props.sortOrder({
            sortOrder: e.sortOrder,
            sortField: e.sortField
        })
    }
    
    onFilter = (e) => {                
        const filters = [];
        const keys = Object.keys(e.filters) || [];
        keys.forEach(key => {
            if (key == "oid" || key == "deadline.value") {
                filters.push({
                    campo: key,
                    valor: parseInt(e.filters[key].value),
                    tipoFiltro: 'IGUAL_A'
                })
            } else {
                let field = key; 
                filters.push({
                    campo: field,
                    valor: e.filters[key].value,
                    tipoFiltro: 'CONTEM'
                })
            }
        })
        this.props.applyFilter(filters)
    }

    onFavorite = (e) => {
        if (this.props.currentMenu) {
            const oidMenu = this.props.currentMenu.oid
            const isFavorite = this.props.isFavorite;
            this.props.toggleFavorite(isFavorite, oidMenu)
        }
    }

    toggleImportFiles = () => {
        const props = this.props;
        const visible = props.importFilesVisible ? false : true
        this.props.showFilesVisible(visible)
    }

    //TODO that method should be implemented at the actions
    importXLSX = (e) => {
        this.props.fetchRecord()
        const files = e.target.files
        if (files && files.length >= 0) {
            const url = "/api/v1/lognex/deliverydeadline/import/xls"
            apiService.uploadFiles(url, files)
                .then((response) => {
                    if (response.data) {
                        this.props.setImportLogData(response.data)
                        this.props.errorsDialogHide(true)
                        this.props.load()
                    }
                    this.props.hideLoader()
                })
                .catch((err) => {
                    this.props.hideLoader()
                })
        }
    }

    errorsDialogOnHide = () => {
        this.props.errorsDialogHide(false)
    }

    copyErrorsToClipBoard = () => {
        const content = this.props.log.errors.join("\n")
        navigator.clipboard.writeText(content)
            .then(() => {
                this.props.copyErrorsMessage(["Erros copiados"])                                            
            })
    }

    lookupCarrierOnHide = (e) => {
        this.props.lookupCarrierSetVisible(false);
    }

    lookupCarrierOnConfirm = (e) => {
        this.props.setCarrier(e.selection);
        this.props.lookupCarrierSetVisible(false);
    }

    lookupCarrierOnCancel = (e) => {
        this.props.setCarrier(null);
        this.props.lookupCarrierSetVisible(false);
    }
    
    lookupCarrierOnPage = (e) => {
        this.props.lookupCarrierPaginate({
            max: e.rows,
            index: e.first
        })
    }
    
    lookupCarrierOnSort = (e) => {
        this.props.lookupCarrierSortOrder({
            sortOrder: e.sortOrder,
            sortField: e.sortField
        })
    }
    
    lookupCarrierOnFilter = (e) => {
        const filters = [];
        const keys = Object.keys(e.filters) || [];
        keys.forEach(key => {
            filters.push({
                campo: key,
                valor: e.filters[key].value,
                tipoFiltro: 'CONTEM'
            })
        })
        this.props.lookupCarrierApplyFilter(filters)
    }
    
    lookupCarrierOnClick = (e) => {
        this.props.lookupCarrierSetVisible(true);
        this.props.lookupCarrierLoad();
    }
    
    lookupCarrierOnEdit = () => {
        if (this.props.carrierExport) {
            this.props.history.push(`${routes.PATH_CARRIER}/${this.props.carrierExport.oid}`);
        }
    }
    
    lookupCarrierOnComplete = e => { };
    
    lookupCarrierOnInputFilter = (e) => {
        const name = e.target.name;
        const value = e.value;
        if (typeof value === 'string') {
            const filters = [{
                campo: "name",
                valor: value,
                tipoFiltro: 'CONTEM'
            }];
            this.props.setCarrier({ name: value } );
            this.props.lookupCarrierApplyFilter(filters)
        } else {
            this.props.setCarrier(value);
        }
    }

    onHideModalExportPrazo = () =>{
        this.props.onShowModalExportPrazo(false);
    }

    onVisibleModalExportPrazo = () =>{
        this.props.onShowModalExportPrazo(true);
    }

    onRowClick =(e) => {
        const oid = e && e.data && e.data.oid
        if(oid){
          this.props.history.push(`${routes.PATH_DELIVERY_DEADLINE}/${oid}`)
        }
      }

    render() {
        const isFavorite = this.props.isFavorite;
        const filePath = '/resources/templates/template_prazo_entrega.xlsx'
        const header = (
            <div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <h1>Prazos de entrega</h1>
                    <i className={`pi pi-star lxFavoriteStar ${isFavorite ? 'lxFavorite' : ''}`}
                        title={isFavorite ? 'Remover dos favoritos' : 'Adicionar aos favoritos'} onClick={this.onFavorite}></i>
                </div>
                <hr className="lxSplitter" ></hr>
                <div>
                    <Toolbar onNew={this.newHandler} newButtonStyle='highlighted'
                        onImportXLSX={this.importXLSX}
                        onExportXLSX={this.onVisibleModalExportPrazo}
                        XLSXTemplatePath={filePath}
                    >
                    </Toolbar >
                </div>
            </div>
        );

        const oidTemplate = (rowData, column) => {
            return (
                <div style={{ textAlign: "center" }} className="id-link"><Link to={`${routes.PATH_DELIVERY_DEADLINE}/${rowData.oid}`}>{rowData.oid}</Link></div>
            )
        }

        const columns = this.props.columns.map(col => {
            if (col.field === 'oid') {
                return (<Column {...col} key={col.field} body={oidTemplate} style={{ width: '10%' }} />);
            }
            return (<Column {...col} key={col.field} />)
        })

        const errorItems = this.props.log.errors.map((error, index) => {
            return <li key={index}>{error}</li>

        })

        const errors = this.props.log.errors && this.props.log.errors.length > 0 ? (
            <div className="p-grid import_result__errors">
                <div className="p-col-6">
                    <h3>Erros</h3>
                </div>
                <div className="p-col-6 button-clipboard-div">
                    <div style={{ padding: "0.5em" }}>
                        <button title="Copiar erros" className="button-clipboard" onClick={this.copyErrorsToClipBoard}>
                            <i className="pi pi-copy"></i>
                        </button>
                    </div>
                </div>

                <div className="p-col-12">
                    {<ul className="error-list">{errorItems}</ul>}

                </div>
            </div >
        ) : null;

        const footer = (
            <div>
                <button className="lognex-btn-highlighted" onClick={this.errorsDialogOnHide}>
                    <i className="pi pi-check"></i>
                </button>
            </div>
        );

        const footerExportXLSX = (
            <div>
                <button
                    className={'lognex-btn-highlighted'}
                    onClick={this.props.onExportPrazoHandler} 
                    title="Exportar Prazos de Entrega"
                    disabled={!this.props.carrierExport}
                    style={{ width:"80px", marginLeft:"5px", marginRight: ".25em" }}
                >
                    Exportar
                </button>
            </div>
        );
       
        const content = (
            <div>
                <div className="p-col-12 form-field-container">
                    <Dialog footer={footer}
                        header="Log de importação"
                        visible={this.props.log.errorsDialogVisible}
                        onHide={this.errorsDialogOnHide} style={{ width: '50vw' }}>
                        <div className="import-result">
                            <h3 className="import-result__title">Resultado</h3>
                            <br />
                            <div className="p-grid import-result__dashboard">
                                <div className="p-col-4">
                                    <Card className="cardText-total" title={this.props.log.totalRecords ? this.props.log.totalRecords.toString() : "0"}>
                                        Total Linhas
                                    </Card>
                                </div>
                                <div className="p-col-4">
                                    <Card className="cardText-success" title={this.props.log.totalSuccessRecords ? this.props.log.totalSuccessRecords.toString() : "0"}>
                                        Sucesso
                                    </Card>
                                </div>
                                <div className="p-col-4">
                                    <Card className="cardText-failed" title={this.props.log.totalFailedRecords ? this.props.log.totalFailedRecords.toString() : "0"}>
                                        Erros
                                    </Card>
                                </div>
                            </div>
                            <br />
                            <hr />
                            <br />
                            {errors}
                        </div>
                    </Dialog>

                </div>
                <div>
                    <Dialog
                        header="Exportar Prazos de Entrega"
                        visible={this.props.modalExportPrazo}
                        style={{ width: "500px" }}
                        modal={true}
                        onHide={this.onHideModalExportPrazo}
                        footer={footerExportXLSX}
                        >
                         <div className="p-col-12 form-field-container">
                            <label className="lxRequiredField">Transportadora</label>
                            <LookupField
                                field="name"
                                value={this.props.carrierExport}
                                placeholder="Transportadora"
                                onComplete={this.lookupCarrierOnComplete}
                                onInputFilter={this.lookupCarrierOnInputFilter}
                                editDisabled={this.props.lookupCarrier.editDisabled}
                                onEditField={this.lookupCarrierOnEdit}
                                onClick={this.lookupCarrierOnClick}

                                visible={this.props.lookupCarrier.visible}
                                modal={this.props.lookupCarrier.modal}
                                header={this.props.lookupCarrier.header}
                                onHide={this.lookupCarrierOnHide}
                                onConfirm={this.lookupCarrierOnConfirm}
                                onCancel={this.lookupCarrierOnCancel}
                                records={this.props.lookupCarrier.records}
                                columns={this.props.lookupCarrier.columns}
                                totalRecords={this.props.lookupCarrier.totalRecords}
                                sortOrder={this.props.lookupCarrier.order}
                                sortField={this.props.lookupCarrier.sortField}
                                first={this.props.lookupCarrier.first}
                                rows={this.props.lookupCarrier.rows}
                                onPage={this.lookupCarrierOnPage}
                                onFilter={this.lookupCarrierOnFilter}
                                onSort={this.props.lookupCarrierOnOnSort}
                                paginator={this.props.lookupCarrier.paginator}
                                emptyMessage="Não foram encontrados registros"
                                rowsPerPageOptions={this.props.lookupCarrier.rowsPerPageOptions}
                            />
                        </div>
                    </Dialog>    
                </div>
                <DeliveryDeadlineList  {...this.props} onFilter={this.onFilter} onPage={this.onPage} onSort={this.onSort} columns={columns} onRowClick={this.onRowClick}/>
            </div>
        );
        return <Page header={header} content={content} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryListPageContainer);
