import { types } from "./perfil.actions";
import { Reducer } from "../../services/reducer.factory";

const initialState = {
  booleanFilter: {
    indicadorPersonalizado: '',
    administrador: '',
    criaUsuariosWebService: '',
    alteraUnidade: ''

  },
  columns: [
    {
      field: "oid",
      header: "Código",
      columnKey: "oid",
      sortable: true,
      filter: true
    },
    {
      field: "name",
      header: "Nome",
      columnKey: "name",
      sortable: true,
      filter: true
    }
  ],
  columnsGroups: [
    {
      field: "oid",
      header: "Código",
      columnKey: "oid",
      sortable: false,
      filter: false
    },
    {
      field: "name",
      header: "Nome",
      columnKey: "name",
      sortable: false,
      filter: false
    },{
      field: "remove",
      header: "",
      columnKey: "remove",
      sortable: false,
      filter: false
    }
  ], 

  lookupPolices: {
    visible: false,
    modal: true,
    header: "Politicas",
  },
  polices: { name: "" },

  groups: [],//lista de perfils para ser incluido como filhos

};

const reducer = new Reducer(initialState, types);

export function crudPerfilReducer(state, action) {
  let newState = reducer.execute(state, action);
  return execute(newState, action);
}

function execute(state, action) {
  switch (action.type) {
    case types.SET_BOOLEAN_FILTER:
      return setBooleanFilter(state, action);
    case types.SET_LOOKUP_POLICES_VISIBLE:
      return setLookupPolicesVisible(state, action);
    case types.SET_POLICE:
      return setPolice(state, action);
    case types.SET_GROUPS:
      return setGroups(state, action);
    case types.ADD_GROUP_TO_CHILDREN:
      return addGroupFilho(state, action);
    case types.REMOVER_GROUP_LIST:
      return removeGroupBy(state, action);
    default:
      return state
  }

}

 
function removeGroupBy(state, action) {
  let currentRecord = state.currentRecord;
  if (currentRecord.oid != action.oid) {
    if (currentRecord.children) {
      const list = currentRecord.children.filter(ch => ch.oid != action.oid)
      currentRecord.children=list;
    }      
  }
  return Object.assign({}, state, { currentRecord });
}
function addGroupFilho(state, action) {
  let currentRecord = state.currentRecord;
  if (currentRecord.oid != action.group.oid && currentRecord.name != action.group.name) {
    if (!currentRecord.children) {
      currentRecord.children = []
    }
    if (currentRecord.children.filter(ch => ch.oid == action.group.oid).length == 0) {
      currentRecord.children.push({ oid: action.group.oid, name: action.group.name });
    }
  }
  return Object.assign({}, state, { currentRecord });
}


function setGroups(state, action) {
  let groups = state.groups;
  groups = action.groups || [];
  return Object.assign({}, state, { groups });
}

function setPolice(state, action) {
  let polices = state.polices;
  polices = action.police;
  return Object.assign({}, state, { polices });
}

function setLookupPolicesVisible(state, action) {
  let lookupPolices = state.lookupPolices;

  lookupPolices = Object.assign({}, lookupPolices, { visible: action.visible });
  return Object.assign({}, state, { lookupPolices });
}

function setBooleanFilter(state, action) {
  let booleanFilter = Object.assign({}, state.booleanFilter);
  booleanFilter[action.field] = action.value;
  return Object.assign({}, state, { booleanFilter });
}

