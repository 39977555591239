import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import React, { Fragment } from "react";
import { Dialog } from "primereact/dialog";
import { Lookup } from "../../../../components/lookup/Lookup";
import { LookupField } from "../../../../components/lookup/LookupField";
import { LxButtonActions } from "../../../../components/lx/button-actions/LxButtonActions";
import { formatNumber, numberToCurrency } from "../../../../utils";
import { QuoteTable } from "../components/QuoteTable";
import "./CargoShipmentForm.scss";
import { InputTextarea } from "primereact/inputtextarea";
import { InputNumber } from "../../../../components/input-number/InputNumber";
import { CheckBox } from "@material-ui/icons";
import { InputSwitch } from "primereact/inputswitch";

export function CargoShipmentForm(props) {
  const statusOptions = {
    NEW: "Novo",
    APROVED: "Aprovado",
    CONFIRMED: "Confirmado",
    REJECTED: "Rejeitado",
    TRAVELING: "Em Viagem",
    DELIVERED: "Entregue",
  };

  const typeOptions = {
    CLOSED_CARGO: "Carga fechada",
    FRACTIONAL_CARGO: "Carga fracionada",
  };

  const totalWeightVehicle = isFinite(props.totalWeightVehicle)
    ? formatNumber(props.totalWeightVehicle)
    : 0;

  const buttonRotation = (
    <div>
      <button
        className="lognex-btn-highlighted"
        onClick={props.changeTransiction}
        title={"Girar imagem"}
      >
        <i className="pi pi-undo"></i>
      </button>
    </div>
  );
  const freightCombinedTemplate =(props)=>{
    if(props.freightCombinedConfiguration && props.record.freightCombinedCheck){
    return(
      <>
      <label>Frete combinado</label>
      <InputText disabled={!props.freightCombinedDisable} type="number" placeholder="0.0" name="freightCombinedShipment" value={props.record.freightCombinedShipment} onChange={props.changeHandlerFreightCombinedShipment}/>
      </>
    )
  }
}
  const freightCombinedCheck =(props)=>{
    if(props.freightCombinedConfiguration){
    return(
      <>
      <label>Frete Combinado</label>
      <InputSwitch disabled={!props.freightCombinedDisable} checked={props.record.freightCombinedCheck} onChange={props.changeHandlerFreightCombinedCheck} name="freightCombinedCheck" />
      </>
    )
  }
}

  return (
    <div className="freightComponent-form">
      <div className="p-grid">
        <div className="p-col-12">
          <div className="p-grid">
            <div className="p-col-3">
              <div className="lxCargoShipmentCard">
                <h3>KG {formatNumber(props.weight, 3)}</h3>
                <p>Peso total</p>
              </div>
            </div>
            <div className="p-col-4">
              <div className="lxCargoShipmentCard">
                <h3>R$ {formatNumber(props.value)}</h3>
                <p>Valor total mercadorias</p>
              </div>
            </div>
            <div className="p-col-3">
              <div className="lxCargoShipmentCard">
                <h3>R$ {formatNumber(props.freightValue)}</h3>
                <p>Total frete</p>
              </div>
            </div>
            <div className="p-col-2">
              <div className="lxCargoShipmentCard">
                <h3>{totalWeightVehicle}%</h3>
                <p>Capac veículo usada</p>
              </div>
            </div>
          </div>
          <div className="p-grid">
            <div className="p-col-2 form-field-container">
              <label>Código</label>
              <InputText value={props.record.oid} name="oid" disabled={true} />
            </div>
            <div className="p-col-2 form-field-container">
              <label>Código Cliente</label>
              <InputText
                value={props.record.code}
                name="code"
                disabled={true}
                title={props.record.code}
              />
            </div>

            <div className="p-col-2 form-field-container">
              <label>Status</label>
              <InputText
                value={
                  props.record.status ? statusOptions[props.record.status] : ""
                }
                name="status"
                disabled={true}
              />
            </div>
            <div className="p-col-2 form-field-container">
              <label>Tipo</label>
              <InputText
                value={props.record.type ? typeOptions[props.record.type] : ""}
                disabled={true}
                name="vehicleType"
              />
            </div>

            <div className="p-col-4 form-field-container">
              <label>Tipo Veículo</label>
              <LookupField
                value={props.record.vehicle.vehicleType || null}
                placeholder="Tipo Veículo"
                name="vehicleType"
                field="name"
                removeEdit={true}
                onComplete={props.lookupVehicleType.actions.onComplete}
                onInputFilter={props.lookupVehicleType.actions.onInputFilter}
                editDisabled={props.lookupVehicleType.fields.editDisabled}
                onEditField={props.lookupVehicleType.actions.onEdit}
                onClick={props.lookupVehicleType.actions.onClick}
                disabled={props.lookupVehicleType.actions.disabled}
                visible={props.lookupVehicleType.fields.visible}
                modal={props.lookupVehicleType.fields.modal}
                header={props.lookupVehicleType.fields.header}
                onHide={props.lookupVehicleType.actions.onHide}
                onConfirm={props.lookupVehicleType.actions.onConfirm}
                onCancel={props.lookupVehicleType.actions.onCancel}
                records={props.lookupVehicleType.fields.records}
                columns={props.lookupVehicleType.fields.columns}
                totalRecords={props.lookupVehicleType.fields.totalRecords}
                sortOrder={props.lookupVehicleType.fields.order}
                sortField={props.lookupVehicleType.fields.sortField}
                first={props.lookupVehicleType.fields.first}
                rows={props.lookupVehicleType.fields.rows}
                onPage={props.lookupVehicleType.actions.onPage}
                onFilter={props.lookupVehicleType.actions.onFilter}
                onSort={props.lookupVehicleType.actions.onSort}
              />
            </div>

            <div className="p-col-4 form-field-container">
              <label>Transportadora</label>
              <LookupField
                value={props.record.carrier || null}
                placeholder="Transportadora"
                name="carrier"
                field="name"
                removeEdit={true}
                onComplete={props.lookupCarrier.actions.onComplete}
                onInputFilter={props.lookupCarrier.actions.onInputFilter}
                editDisabled={props.lookupCarrier.fields.editDisabled}
                onEditField={props.lookupCarrier.actions.onEdit}
                onClick={props.lookupCarrier.actions.onClick}
                disabled={props.lookupCarrier.actions.disabled}
                visible={props.lookupCarrier.fields.visible}
                modal={props.lookupCarrier.fields.modal}
                header={props.lookupCarrier.fields.header}
                onHide={props.lookupCarrier.actions.onHide}
                onConfirm={props.lookupCarrier.actions.onConfirm}
                onCancel={props.lookupCarrier.actions.onCancel}
                records={props.lookupCarrier.fields.records}
                columns={props.lookupCarrier.fields.columns}
                totalRecords={props.lookupCarrier.fields.totalRecords}
                sortOrder={props.lookupCarrier.fields.order}
                sortField={props.lookupCarrier.fields.sortField}
                first={props.lookupCarrier.fields.first}
                rows={props.lookupCarrier.fields.rows}
                onPage={props.lookupCarrier.actions.onPage}
                onFilter={props.lookupCarrier.actions.onFilter}
                onSort={props.lookupCarrier.actions.onSort}
                paginator={true}
                emptyMessage="Não foram encontrados registros"
                rowsPerPageOptions={props.lookupCarrier.rowsPerPageOptions}
              />
            </div>
            <div className="p-col-4 form-field-container">
              <label>Veículo</label>
              <InputText
                value={props.record.vehicle.plate}
                onChange={props.changeHandler}
                name="vehicle.plate"
              />
            </div>
            <div className="p-col-4 form-field-container">
              <label>Motorista</label>
              <LookupField
                value={props.record.driver || null}
                placeholder="Motorista"
                name="driver"
                field="name"
                removeEdit={true}
                onComplete={props.lookupDriver.actions.onComplete}
                onInputFilter={props.lookupDriver.actions.onInputFilter}
                editDisabled={props.lookupDriver.fields.editDisabled}
                onEditField={props.lookupDriver.actions.onEdit}
                onClick={props.lookupDriver.actions.onClick}
                disabled={props.lookupDriver.actions.disabled}
                visible={props.lookupDriver.fields.visible}
                modal={props.lookupDriver.fields.modal}
                header={props.lookupDriver.fields.header}
                onHide={props.lookupDriver.actions.onHide}
                onConfirm={props.lookupDriver.actions.onConfirm}
                onCancel={props.lookupDriver.actions.onCancel}
                records={props.lookupDriver.fields.records}
                columns={props.lookupDriver.fields.columns}
                totalRecords={props.lookupDriver.fields.totalRecords}
                sortOrder={props.lookupDriver.fields.order}
                sortField={props.lookupDriver.fields.sortField}
                first={props.lookupDriver.fields.first}
                rows={props.lookupDriver.fields.rows}
                onPage={props.lookupDriver.actions.onPage}
                onFilter={props.lookupDriver.actions.onFilter}
                onSort={props.lookupDriver.actions.onSort}
                paginator={true}
                emptyMessage="Não foram encontrados registros"
                rowsPerPageOptions={props.lookupDriver.rowsPerPageOptions}
              />
            </div>

            <div className="p-col-2 form-field-container">
              <label>Distância(km)</label>
              <InputText
                value={
                  props.record.distance
                    ? formatNumber(props.record.distance)
                    : 0
                }
                name="distance"
                disabled={true}
              />
            </div>
            <div className="p-col-2 form-field-container">
              <label>Tipo da Embalagem</label>
              <InputText
                value={
                  props.record.costByPacking &&
                    props.record.costByPacking.packingName
                    ? props.record.costByPacking.packingName
                    : "Não informado"
                }
                name="distance"
                disabled={true}
              />
            </div>
            <div className="p-col-2 form-field-container">
              <label>Custo(R$) / Embalagem</label>
              <InputText
                value={
                  props.record.costByPacking && props.record.costByPacking.value
                    ? formatNumber(props.record.costByPacking.value)
                    : 0
                }
                name="distance"
                disabled={true}
              />
            </div>

            <div className="p-col-2 form-field-container">
              <label>Quant Embalagem</label>
              <InputText
                value={
                  props.record.costByPacking &&
                    props.record.costByPacking.quantity
                    ? props.record.costByPacking.quantity
                    : 0
                }
                name="distance"
                disabled={true}
              />
            </div>
            { props.record.valorPedagio &&  props.record.valorPedagio > 0 &&
              <div className="p-col-2 form-field-container">
                <label>Valor Pedágio</label>
                <InputText
                  value={
                    props.record.valorPedagio
                      ? numberToCurrency(props.record.valorPedagio, "R$" )
                      : 0
                  }
                  name="distance"
                  disabled={true}
                />
              </div>
            }
            <div className="p-col-2 form-field-container">
              <label>Data Coleta</label>
              <Calendar
                showIcon={true}
                value={props.record.pickupDate}
                showTime={false}
                onChange={props.changeHandler}
                disabled={false}
                name="pickupDate"
                dateFormat="dd/mm/yy"
              />
            </div>
            <div className="p-col-2 form-field-container">
            {freightCombinedCheck(props)}
            </div>
            <div className="p-col-2 form-field-container">
            {freightCombinedTemplate(props)}
            </div>

            <div className="p-col-12 form-field-container">
              <label>Observação</label>
              <InputTextarea
                value={ props.record.obs || "" }
                onChange={props.changeHandler}
                name="obs"
              />
            </div>

            <div className="p-col-12">
              <LxButtonActions
                hint={
                  props.orders.toolbar && props.orders.toolbar.hint
                    ? props.orders.toolbar.hint
                    : ""
                }
                onAdd={
                  props.orders.toolbar && props.orders.toolbar.onAdd
                    ? props.orders.toolbar.onAdd
                    : () => {
                      console.log("orders.toolbar.onAdd must be implemented");
                    }
                }
                disabled={props.orders.toolbar.disabledButtonOrders}
                onRemove={
                  props.orders.toolbar && props.orders.toolbar.onRemove
                    ? props.orders.toolbar.onRemove
                    : () => {
                      console.log(
                        "orders.toolbar.onRemove must be implemented"
                      );
                    }
                }
                removeMessage={`Deseja realmente remover o pedido ${props.orders.table.selection &&
                    props.orders.table.selection.length > 0
                    ? props.orders.table.selection[0].oid
                    : ""
                  }?`}
                header={
                  props.orders.toolbar && props.orders.toolbar.header
                    ? props.orders.toolbar.header
                    : ""
                }
              />
              <DataTable {...props.orders.table.dataTable}>
                {props.orders.table.columns}
              </DataTable>
              <Lookup
                isMultiple={props.lookupOrder.fields.isMultiple}
                visible={props.lookupOrder.fields.visible}
                modal={props.lookupOrder.fields.modal}
                header={props.lookupOrder.fields.header}
                onHide={props.lookupOrder.actions.onHide}
                onConfirm={props.lookupOrder.actions.onConfirm}
                onCancel={props.lookupOrder.actions.onCancel}
                records={props.lookupOrder.fields.records}
                columns={props.lookupOrder.fields.columns}
                totalRecords={props.lookupOrder.fields.totalRecords}
                sortOrder={props.lookupOrder.fields.order}
                sortField={props.lookupOrder.fields.sortField}
                first={props.lookupOrder.fields.first}
                rows={props.lookupOrder.fields.rows}
                onPage={props.lookupOrder.actions.onPage}
                onFilter={props.lookupOrder.actions.onFilter}
                onSort={props.lookupOrder.actions.onSort}
              />
            </div>

            <div className="p-col-12">
              <label>
                <h3>Ocorrências</h3>
              </label>
              <DataTable
                columns={props.occurrences.columns}
                selectionMode="single"
                value={props.occurrences.records}
                lazy={true}
                paginator={false}
                emptyMessage="Não foram encontrados registros"
              >
                {props.occurrences.columns}
              </DataTable>
            </div>

            <div className="p-col-12">
              <label>
                <h3>Transfêrencias</h3>
              </label>
              <DataTable
                columns={props.deliverysSto.columns}
                selectionMode="single"
                value={props.deliverysSto.records}
                lazy={true}
                paginator={false}
                emptyMessage="Não foram encontrados registros"
              >
                {props.deliverysSto.columns}
              </DataTable>
            </div>


            <div>
              <Dialog
                className="lxLookupDialog"
                header={props.modal.title}
                visible={props.modal.visible}
                style={{ width: "auto" }}
                modal={true}
                onHide={props.modal.onHide}
              >
                {buttonRotation}

                {props.modal.image}
              </Dialog>
            </div>

            <div>
              <Dialog
                className="lxLookupDialogFreightQuote"
                header={props.modalFreightQuote.title}
                visible={props.modalFreightQuote.visible}
                modal={true}
                onHide={props.modalFreightQuote.onHide}
              >
                <QuoteTable modalFreightQuote={props.modalFreightQuote} />
              </Dialog>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}
