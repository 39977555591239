
    import React from 'react';
    import PalletListPageContainer from './PalletListPageContainer';
    
    export class PalletListPage extends React.Component{
        
        render(){
          return (
            <PalletListPageContainer {...this.props}/>
          )
        }
    }