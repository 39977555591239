import React from 'react'
import { ConfigurationPanel } from './ConfigurationPanel';


export const FreightCalculationSection=(props)=>{
    return (
        <div>
        <ConfigurationPanel
            id={"calculateICMS"}
            title={"Cálculo de frete"}
            configurations={props.freightCalcConfigurations}
        />
        </div>
    )
}