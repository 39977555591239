import React from "react";
import { Card } from "primereact/card";
import GrowlMessenger from "../growl/GrowlMessenger";
import './Page.scss';

export const Page = props => {
  const header = !props.header ? '' :
    <div className="page__header">
      <Card><div>{props.header}</div></Card>
    </div>;
  return (
    <div className="page">
      {header}

      <GrowlMessenger />
      <div className="page__content">
        <Card>{props.content}</Card>
      </div>
    </div>
  )
};
