
import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { LookupTable } from '../../../components/lookup/LookupTable';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { LxPanelRoutes } from '../components/LxPanelRoutes'
import { LxPanelRestrictionsByComponent } from '../components/LxPanelRestrictionsByComponent'
import { LxPanelClientsByComponentRestriction } from '../components/LxPanelClientsByComponentRestriction'
import { LxPanelLocationsByComponentRestriction } from '../components/LxPanelLocationsByComponentRestriction'
import { LxPanelUnitByComponentRestriction } from '../components/LxPanelUnitByComponentRestriction'
import { LxDialogLookup } from '../components/LxDialogLookup'
import './FreightTableForm.scss'
import moment from 'moment';
import { Card } from 'primereact/card';
import * as i18n from "../../../i18n";
import { LxButtonActions } from '../../../components/lx/button-actions/LxButtonActions';
import { LxFreightTableMainForm } from './components/LxFreightTableMainForm';
import { LxRouteRangeDialog } from './components/LxRouteRangeDialog';
import { LxEditSerieDialog } from './components/LxEditSerieDialog';
import { LxFreightComponentsList } from '../components/LxFreightComponentsList';
import { LxDialogDuplicateRoutes } from './components/LxDialogDuplicateRoutes';
import FileUploaderButton from '../../../components/file-uploader-button/FileUploaderButton';
import { IgnoreComponentList } from './components/IgnoreComponentList';

window.moment = moment;

export class FreightTableForm extends React.Component {
    state = {
        activeIndex: 0,
        visible: true
    }

    componentWillUnmount() {
       this.props.mainForm.clearCurrentRecord();  
    }



    onDateChange = (event) => {
        let text = '';
        if (event.target.value.length >= 10) {
            let date = moment(event.target.value);
            if (date.isValid()) {
                text = date.format('dd/MM/yyyy');
            } else {
                text = event.target.value;
            }
        }
        else {
            text = event.target.value;
        }
        let e = {
            target: {
                id: event.target.id,
                name: event.target.name,
                value: text
            }
        }
        this.props.changeHandler(e);
    }

    formatDate(value) {
        if (typeof value === 'string') {
            return value;
        } else {
            return moment(value).format('DD/MM/YYYY');
        }
    }
    render() {
        const { props } = this;

        const selectedSerie = props.panelComponentsByRote.table.selection;

        const addRestrictionsDisabled = false;

        const ranges = props.panelComponentsByRote.table.ranges ? props.panelComponentsByRote.table.ranges.map(range => {
            const header = i18n.getValue(range.rangeType)
            const panelSeries = props.panelComponentsByRote.panelSeries 
            return (
                <AccordionTab header={header} key={range.rangeType}>
                    <LxButtonActions
                        hint={panelSeries.toolbar && panelSeries.toolbar.hint ? panelSeries.toolbar.hint : ""}
                        onEdit={panelSeries.toolbar && panelSeries.toolbar.onEdit ? panelSeries.toolbar.onEdit : () => { console.log("toolbar.onEdit must be implemented") }}
                        onRemove={panelSeries.toolbar && panelSeries.toolbar.onRemove ? panelSeries.toolbar.onRemove : () => { console.log("toolbar.onRemove must be implemented") }}
                        header={panelSeries.toolbar && panelSeries.toolbar.header ? panelSeries.toolbar.header : ""}
                        disabled={panelSeries.toolbar && panelSeries.toolbar.disabled}
                    />
                    <div className="p-grid">
                        <div className="p-col-6">
                            <DataTable
                                value={range.series}
                                selection={selectedSerie}
                                onSelectionChange={props.panelComponentsByRote.table.actions.onChangeSelection}
                            >
                                {this.props.panelComponentsByRote.table.columns}
                            </DataTable>
                        </div>

                    </div>
                    <div className="p-grid">
                        <div className="p-col-6">
                            <LxButtonActions
                                hint={props.panelComponentsByRote.panelComponents.toolbar && props.panelComponentsByRote.panelComponents.toolbar.hint ? props.panelComponentsByRote.panelComponents.toolbar.hint : ""}
                                onAdd={props.panelComponentsByRote.panelComponents.toolbar && props.panelComponentsByRote.panelComponents.toolbar.onAdd ? props.panelComponentsByRote.panelComponents.toolbar.onAdd : () => { console.log("toolbar.onAdd must be implemented") }}
                                header={props.panelComponentsByRote.panelComponents.toolbar && props.panelComponentsByRote.panelComponents.toolbar.header ? props.panelComponentsByRote.panelComponents.toolbar.header : ""}
                            />

                            <LxFreightComponentsList componentsParams={props.panelComponentsByRote.panelComponents.componentsParams} />
                        </div>

                    </div>

                </AccordionTab>
            )
        })
            : null

        let rangesByRoute = null;
        if (this.props.panelRoutes.dataTable.selectedRoutes && this.props.panelRoutes.dataTable.selectedRoutes.length === 1) {
            rangesByRoute = (
                <Card>
                    <div className="p-grid">
                        <div className="p-col-12">
                            <h3>Faixas por Rota</h3>
                            <LxDialogLookup  {...props.panelComponentsByRote.panelComponents.dialogAddComponentSelectedSerie} />
                            <LxButtonActions
                                hint={props.panelComponentsByRote.toolbar && props.panelComponentsByRote.toolbar.hint ? props.panelComponentsByRote.toolbar.hint : ""}
                                onAdd={props.panelComponentsByRote.toolbar && props.panelComponentsByRote.toolbar.onAdd ? props.panelComponentsByRote.toolbar.onAdd : () => { console.log("toolbar.onAdd must be implemented") }}
                                onRemove={props.panelComponentsByRote.toolbar && props.panelComponentsByRote.toolbar.onRemove ? props.panelComponentsByRote.toolbar.onRemove : () => { console.log("toolbar.onRemove must be implemented") }}
                                removeMessage={`Deseja realmente remover a faixa ${props.panelComponentsByRote && props.panelComponentsByRote.selectedRange && props.panelComponentsByRote.selectedRange.rangeType ? 'por ' + props.panelComponentsByRote.selectedRange.rangeType : 'selecionada'}?`}
                                header={props.panelComponentsByRote.toolbar && props.panelComponentsByRote.toolbar.header ? props.panelComponentsByRote.toolbar.header : ""}
                            />
                            <Accordion activeIndex={props.panelComponentsByRote.indexSelectedRange} onTabChange={props.panelComponentsByRote.onSelectionRangeChange}>
                                {ranges}
                            </Accordion>
                            <LxRouteRangeDialog  {...props.panelComponentsByRote.dialog} />
                            <LxEditSerieDialog {...props.panelComponentsByRote.panelSeries.dialogEditSerie} />
                        </div>
                    </div>
                </Card>
            )
        }

        const ClientsByComponentRestriction = !props.panelClientsByComponentRestriction.showPanelClientsByComponentRestriction ? "" : (
            <Card>
                <div className="p-grid">
                    <div className="p-col-12">
                        <h3>Clientes por componente com restrição</h3>
                        <LxPanelClientsByComponentRestriction {...props.panelClientsByComponentRestriction} />
                    </div>
                </div>
            </Card>
        )

        const LocationsByComponentRestriction = !props.panelLocationsByComponentRestriction.showPanelLocationsByComponentRestriction ? "" : (
            <Card>
                <div className="p-grid">
                    <div className="p-col-12">
                        <h3>Localizações por componente com restrição</h3>
                        <LxPanelLocationsByComponentRestriction {...props.panelLocationsByComponentRestriction} />
                    </div>
                </div>
            </Card>
        )

        const UnitByComponentRestriction = !props.panelBusinessUnitByComponentRestriction.showPanelUnitByComponentRestriction ? "" : (
            <Card>
                <div className="p-grid">
                    <div className="p-col-12">
                        <h3>Unidades por componente com restrição</h3>
                        <LxPanelUnitByComponentRestriction {...props.panelBusinessUnitByComponentRestriction} />
                    </div>
                </div>
            </Card>
        )


        const generalComponents = (
            <div style={{ "padding": "7px" }}>
                <Card>
                    <div className="p-grid">
                        <div className="p-col-6">
                            <h3>Componentes Gerais</h3>
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-6">
                            <LxDialogLookup  {...props.generalComponents.dialogAddComponentGeneralComponents} />
                            <LxButtonActions
                                hint={props.generalComponents.toolbar && props.generalComponents.toolbar.hint ? props.generalComponents.toolbar.hint : ""}
                                onAdd={props.generalComponents.toolbar && props.generalComponents.toolbar.onAdd ? props.generalComponents.toolbar.onAdd : () => { console.log("toolbar.onAdd must be implemented") }}
                                header={props.generalComponents.toolbar && props.generalComponents.toolbar.header ? props.generalComponents.toolbar.header : ""}
                            />
                            <LxFreightComponentsList componentsParams={props.generalComponents.componentsParams} />
                        </div>
                    </div>

                </Card>
            </div>
        )

        const lookupClientComponent = !props.dialogAddRestrictions.showlookupClient ? "" : (
            <div className="p-col-12 form-field-container">
                <label>Cliente*</label>
                <LookupTable
                    value={props.lookupClient.searchText}
                    placeholder="Cliente"
                    name="cliente"
                    field="razaoSocial"
                    onComplete={props.lookupClientOnComplete}
                    onInputFilter={props.lookupClient.lookupClientOnInputFilter}
                    onClick={props.lookupClient.lookupClientOnClick}

                    visible={props.lookupClient.visible}
                    modal={props.lookupClient.modal}
                    header={props.lookupClient.header}
                    onHide={props.lookupClient.lookupClientOnHide}
                    onConfirm={props.lookupClient.lookupClientOnConfirm}
                    onCancel={props.lookupClient.lookupClientOnCancel}
                    records={props.lookupClient.records}
                    columns={props.lookupClient.columns}
                    totalRecords={props.lookupClient.totalRecords}
                    sortOrder={props.lookupClient.order}
                    sortField={props.lookupClient.sortField}
                    first={props.lookupClient.first}
                    rows={props.lookupClient.rows}
                    onPage={props.lookupClientOnPage}
                    onFilter={props.lookupClient.lookupClientOnFilter}
                    onSort={props.lookupClientOnOnSort}
                    disabled={props.lookupClient.disabled}

                    onTableSelectionChange={props.lookupClient.lookupClientOnSelectClient}
                    onRemoveSelection={props.lookupClient.lookupClientOnRemoveSelected}
                    tableRecords={props.lookupClient.tableRecords}
                    tableSelection={props.lookupClient.selectedRecords}
                    tableColumns={props.lookupClient.tableColumns}
                    scrollable={true}
                    scrollHeight="400px"
                />
            </div>
        )

        const lookupLocationComponent = !props.dialogAddRestrictions.showlookupLocation ? "" : (
            <div className="p-col-12 form-field-container">
                <label>Localização</label>
                <LookupTable
                    value={props.lookupLocation.searchText}
                    placeholder="Localização*"
                    name="location.name"
                    field="name"
                    onComplete={props.lookupLocationOnComplete}
                    onInputFilter={props.lookupLocationOnInputFilter}
                    onClick={props.lookupLocation.lookupLocationOnClick}
                    visible={props.lookupLocation.visible}
                    modal={props.lookupLocation.modal}
                    header={props.lookupLocation.header}
                    onHide={props.lookupLocation.lookupLocationOnHide}
                    onConfirm={props.lookupLocation.lookupLocationOnConfirm}
                    onCancel={props.lookupLocation.lookupLocationOnCancel}
                    records={props.lookupLocation.records}
                    columns={props.lookupLocation.columns}
                    totalRecords={props.lookupLocation.totalRecords}
                    sortOrder={props.lookupLocation.order}
                    sortField={props.lookupLocation.sortField}
                    first={props.lookupLocation.first}
                    rows={props.lookupLocation.rows}
                    onPage={props.lookupLocationOnPage}
                    onFilter={props.lookupLocation.lookupLocationOnFilter}
                    onSort={props.lookupLocationOnOnSort}
                    onTableSelectionChange={props.lookupLocation.lookupLocationOnSelectLocation}
                    onRemoveSelection={props.lookupLocation.lookupLocationOnRemoveSelected}
                    tableRecords={props.lookupLocation.tableRecords}
                    tableSelection={props.lookupLocation.selectedRecords}
                    tableColumns={props.lookupLocation.tableColumns}
                    scrollable={true}
                    scrollHeight="400px"
                />
            </div>
        )

        const lookupUnitComponent = !props.dialogAddRestrictions.showLookupUnit ? "" : (
            <div className="p-col-12 form-field-container">
                <label>Unidades</label>
                <LookupTable
                    value={props.lookupUnitRestricted.searchText}
                    placeholder="Unidades"
                    name="label"
                    field="label"
                    onComplete={props.lookupUnitRestricted}
                    onInputFilter={props.lookupUnitRestricted}
                    onClick={props.lookupUnitRestricted.lookupUnitRestrictedOnClick}
                    visible={props.lookupUnitRestricted.visible}
                    modal={props.lookupUnitRestricted.modal}
                    header={props.lookupUnitRestricted.header}
                    onHide={props.lookupUnitRestricted.lookupUnitRestrictedOnHide}
                    onConfirm={props.lookupUnitRestricted.lookupUnitRestrictedOnConfirm}
                    onCancel={props.lookupUnitRestricted.lookupUnitRestrictedOnCancel}
                    records={props.lookupUnitRestricted.records}
                    columns={props.lookupUnitRestricted.columns}
                    totalRecords={props.lookupUnitRestricted.totalRecords}
                    sortOrder={props.lookupUnitRestricted.order}
                    sortField={props.lookupUnitRestricted.sortField}
                    first={props.lookupUnitRestricted.first}
                    rows={props.lookupUnitRestricted.rows}
                    onPage={props.lookupUnitRestricted}
                    onFilter={props.lookupUnitRestricted.lookupUnitRestrictedOnFilter}
                    onSort={props.lookupUnitOnOnSort}
                    onTableSelectionChange={props.lookupUnitRestricted.lookupUnitRestrictedOnSelectUnit}
                    onRemoveSelection={props.lookupUnitRestricted.lookupUnitRestrictedOnRemoveSelected}
                    tableRecords={props.lookupUnitRestricted.tableRecords}
                    tableSelection={props.lookupUnitRestricted.selectedRecords}
                    tableColumns={props.lookupUnitRestricted.tableColumns}
                    scrollable={true}
                    scrollHeight="400px"
                />
            </div>

        )


        const FILE_TYPE =  props.dialogAddRestrictions.showlookupLocation ? 'LOCATION' : 'CLIENT';
         
        
        return (
            <div className="freightTable-form">
                <div className="p-grid">
                    <div className="p-col-12">
                        <LxFreightTableMainForm {...this.props.mainForm} />
                    </div>
                    <div className="p-col-12">
                        {generalComponents}
                    </div>

                    <div className="p-col-12">
                        <IgnoreComponentList
                            ignoreComponentsColumns={props.ignoreComponents.ignoreComponentsColumns}
                            onlyIgnoreListComponent={props.ignoreComponents.onlyIgnoreListComponent}
                            addIgnoreComponentList={props.ignoreComponents.addIgnoreComponentList}
                            removeIgnoreComponentList={props.ignoreComponents.removeIgnoreComponentList}
                        ></IgnoreComponentList>
                    </div>

                    <div className="p-col-12">
                        <div style={{ "padding": "7px" }}>
                            <Card>
                                <div className="p-grid">
                                    <div className="p-col-12">
                                        <h3 className="lxRequiredField">Rotas</h3>
                                        <LxDialogLookup  {...this.props.dialogRoutes} />
                                        <LxDialogDuplicateRoutes {...this.props.dialogDuplicateRoute} />
                                        <LxPanelRoutes {...props.panelRoutes} />
                                    </div>
                                </div>
                            </Card>
                        </div>
                    </div>
                    <div className="p-col-12">
                        <div style={{ "padding": "7px" }}>
                            {rangesByRoute}
                        </div>
                    </div>

                    <div className="p-col-12">
                        <div style={{ "padding": "7px" }}>
                            <Card>
                                <div className="p-grid">
                                    <div className="p-col-12">
                                        <h3>Restrições por Componente</h3>
                                        <LxPanelRestrictionsByComponent {...props.panelRestrictionByComponent} />
                                    </div>
                                </div>
                            </Card>
                        </div>
                    </div>
                    <div className="p-col-12">
                        <div style={{ "padding": "7px" }}>
                            {ClientsByComponentRestriction}
                            {LocationsByComponentRestriction}
                            {UnitByComponentRestriction}
                        </div>
                    </div>

                </div>

                <Dialog
                    header={props.dialogAddRestrictions.header}
                    visible={props.dialogAddRestrictions.visible}
                    style={{ width: '70vw' }}
                    modal={props.dialogAddRestrictions.modal}
                    onHide={props.dialogAddRestrictions.actions.dialogAddRestrictionsClose}
                    footer={
                        <div>
                            <button
                                title="Adicionar restrição"
                                onClick={props.dialogAddRestrictions.actions.dialogAddRestrictionsOnConfirm}
                                style={{ marginRight: ".25em" }}
                                className="lognex-btn-highlighted"
                                disabled={addRestrictionsDisabled}>
                                <i className="pi pi-check"></i>
                            </button>
                            <button
                                title="Cancelar"
                                onClick={props.dialogAddRestrictions.actions.dialogAddRestrictionsClose}
                                style={{ marginRight: ".25em" }}
                                className="lognex-btn-danger">
                                <i className="pi pi-times"></i>
                            </button>

                        </div>}>
                    { props.dialogAddRestrictions.showLookupUnit
                        ?  <div>
                                {lookupUnitComponent}
                            </div>  
                        :
                            <div> 
                                <div className="p-col-6 form-field-container">
                                    <label>Componente</label>
                                    <InputText readOnly="readonly" value={props.dialogAddRestrictions.dialogComponentName} />
                                </div>
                                <div className="p-col-6 form-field-container content-section implementation">
                                    <label>Duplicar De</label>
                                    <Dropdown style={{ width: "auto" }} value={props.dialogAddRestrictions.currentComponentRestriction} onChange={props.dialogAddRestrictions.actions.componentsRestrictionOnChange} name="component" options={props.dialogAddRestrictions.componentsRestriction} ></Dropdown>
                                </div>
                                
                                <div className="p-col-12 form-field-container content-section implementation">
                                    <div>
                                        <FileUploaderButton  accept=".xlsx,.xls" onUpload={(e)=>props.dialogAddRestrictions.actions.showImportFileXLSX(e,FILE_TYPE)}/>
                                        <span>Importar  {FILE_TYPE === 'CLIENT' ? 'clientes' : 'localidades'} da Planilha Excel</span>
                                    </div>
                                </div>

                                {lookupClientComponent}
                                {lookupLocationComponent}
                            </div> 
                    }  
                </Dialog>
            </div >
        )
    }
};

