
    import React from 'react';
    import FreightTableFormPageContainer from './FreightTableFormPageContainer';
    
    export class FreightTableFormPage extends React.Component{
        render(){
            return (
                <FreightTableFormPageContainer {...this.props}/>
            )
        }
    }
    