import React from "react";
import { connect } from "react-redux";
import Toolbar from "../../../components/toolbar/Toolbar";
import { Page } from "../../../components/page/Page";
import { routes } from '../../../constants';
import { UserDocumentTypeForm } from './UserDocumentTypeForm';
import { actions } from '../userdocumenttype.actions';


const mapStateToProps = state => {
    return {
      record: state.crudUserDocumentTypeState.currentRecord,
      lognexDocumentTypes:state.crudUserDocumentTypeState.lognexDocumentTypes
    }
}

const mapDispatchToProps = dispatch => {
    return {
      setField: (field, value) => dispatch(actions.setField(field, value)),
      changeHandler: event => {
          const target = event.target;
          const field = target.name;
          let value = target.value;
          // if (field === "lognexDocumentTypes"){
          //   dispatch(actions.setField("lognexType", value));  
          // }
          dispatch(actions.removeErrors([{ field }]));
          dispatch(actions.setField(field, value));
      },
      saveHandler: event => {
        dispatch(actions.save());
      },
      removeHandler: event => {
        dispatch(actions.remove());
      },
      newHandler: event => {
        dispatch(actions.makeNew());
      }, 
      loadById: oid => dispatch(actions.loadById(oid)),
      setLognexAllDocumentTypes: () => {
        dispatch(actions.setLognexAllDocumentTypes())
      },
      changeLognexDocumentTypeHandler: (event) => dispatch(actions.setLognexDocumentType(event.target.value)) 
    }
}

class UserDocumentTypeFormPageContainer extends React.Component {
    constructor(props) {
        super(props)
        actions.afterSave = ({ oid }) => {
          this.props.history.replace(`${routes.PATH_USER_DOCUMENT_TYPE}/${oid}`);
        };
        actions.afterNew = () => {
          this.props.history.replace(`${routes.PATH_USER_DOCUMENT_TYPE}/novo`);
        };
        actions.afterRemove = () => {
          this.props.history.replace(`${routes.PATH_USER_DOCUMENT_TYPE}`);
        };

    }

    componentDidMount() {
        const oid = this.props.match.params.id;    
        if (oid && parseInt(oid)) {
          this.props.loadById(oid);
        } else {
          this.props.newHandler();
        }

        this.props.setLognexAllDocumentTypes()

    }
    
    searchHandler = () => {
        this.props.history.push(`${routes.PATH_USER_DOCUMENT_TYPE}`)
    }


    render() {
        const title = `Tipo Documento ${this.props.record.oid || 'novo'}`
        const header = (
            <div>
                <h1>{title}</h1>
                <hr className="lxSplitter" ></hr>
                <div>
                    <Toolbar
                        onSave={this.props.saveHandler} saveButtonStyle='highlighted'
                        onRemove={this.props.removeHandler} removeButtonStyle='danger'
                        onNew={this.props.newHandler}
                        onSearch={this.searchHandler}>
                    </Toolbar>
                </div>
            </div> 
        )

        const content = (<UserDocumentTypeForm 
                              record={this.props.record} 
                              lognexDocumentTypes={this.props.lognexDocumentTypes} 
                              changeHandler={this.props.changeHandler}
                              changeLognexDocumentTypeHandler={this.props.changeLognexDocumentTypeHandler}
                        />)
        return (<Page header={header} content={content} />)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserDocumentTypeFormPageContainer)