import {build} from '../../services/crudFactory';
import {Action} from '../../services/actions.factory';
const BASE_PATH = '/lognex/testes';
const crud = build(null,BASE_PATH);
const prefix = 'CRUDTeste'
const actions = new Action(prefix,crud);

const newTypes = {
    SET_CARRIER: `${prefix}SetCarrier`,
    SET_LOOKUP_CARRIER_VISIBLE:`${prefix}SetLookupCarrierVisible`,
    SET_LABEL:`${prefix}setLabel`,
    SET_SELECTED_LABEL:`${prefix}setSelectedLabels`,
    SET_LABEL_LIST:`${prefix}setLabelList`,
}

const types = {...actions.types, ...newTypes};


actions.setSelectedLabels = (value) => {
    return {
        type: types.SET_SELECTED_LABEL,
        value
    }
}

actions.setLabel = (value) => {
    return {
        type: types.SET_LABEL,
        value
    }
}


actions.setCarrier = (carrier)=>{
    return {
        type:types.SET_CARRIER,
        carrier
    }
}

actions.setLabelList = (labelList)=>{
    return {
        type:types.SET_LABEL_LIST,
        labelList
    }
}


actions.setLookupCarrierVisible = (visible)=>{
    return {
        type:types.SET_LOOKUP_CARRIER_VISIBLE,
        visible
    }
}

export {types, actions};
