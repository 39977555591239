
import React from 'react';
import { connect } from 'react-redux';
import Toolbar from "../../../components/toolbar/Toolbar";
import { Column } from 'primereact/column';
import { actions } from '../zipCodeRange.actions';
import { routes } from '../../../constants';
import { Page } from "../../../components/page/Page";
import { ZipCodeRangeForm } from './ZipCodeRangeForm';
import { actions as locationActions } from '../../location/location.actions';


const mapStateToProps = state => {
  const record = state.crudZipCodeRangeState.currentRecord;

  const lookupParentBase = {
    records: state.crudLocationState.records,
    columns: state.crudLocationState.columns
      .filter(col =>
        col.field === 'oid' ||
        col.field === 'name' ||
        col.field === 'ibgeCode' ||
        col.field === 'initialZipCode' ||
        col.field === 'finalZipCode' ||
        col.field === 'locationType')
      .map(col => <Column key={col.field} {...col} />),
    first: state.crudLocationState.index,
    rows: state.crudLocationState.max,
    totalRecords: state.crudLocationState.count,
    sortField: state.crudLocationState.sortField,
    order: state.crudLocationState.sortOrder,
    editDisabled: !record.oidParent
  }

  let lookupParent = Object.assign({}, state.crudZipCodeRangeState.lookupParent, lookupParentBase);
  let types = state.crudZipCodeRangeState.types;
  types = [{ label: 'Selecione uma opção', value: null }, ...Object.values(types).map(type => {
    switch (type) {
      case "CITY":
        return { value: "CITY", label: "Cidade" };
      case "ZIP_CODE_RANGE":
        return { value: "ZIP_CODE_RANGE", label: "Faixa de Cep" };
      default:
        return type;
    }
  })];
  
  return {
    record,
    lookupParent,
    types
  }
}


const mapDispatchToProps = dispatch => {

  return {
    lookupParentSetVisible: visible => dispatch(actions.setLookupParentVisible(visible)),
    lookupParentPaginate: paginationParams => dispatch(locationActions.paginate(paginationParams)),
    lookupParentSortOrder: sortParams => dispatch(locationActions.sortOrder(sortParams)),
    lookupParentApplyFilter: filterParams => {
      dispatch(locationActions.setType(null));
      dispatch(locationActions.applyFilter(filterParams))
    },
    lookupParentLoad: () => dispatch(locationActions.load()),
    setParent: (parent) => {
      if (parent) {
        const { oid, name, ibgeCode, initialZipCode, finalZipCode, locationType } = parent;
        const currentParent = {
          oid,
          name,
          ibgeCode,
          initialZipCode,
          finalZipCode,
          locationType
        }
        dispatch(actions.setField('parent', currentParent));
      } else {
        dispatch(actions.setField('parent', null));
      }
    },
    setField: (field, value) => dispatch(actions.setField(field, value)),
    changeHandler: event => {
      const target = event.target;
      const field = target.name;
      let value = target.value;
      dispatch(actions.removeErrors([{ field }]));
      if(field === "start" || field === "end" ){        
        dispatch(actions.setRange(field,value))
      }else{
        dispatch(actions.setField(field, value));
      }
    },
    saveHandler: event => {
      dispatch(actions.save());
    },
    removeHandler: event => {
      dispatch(actions.remove());
    },
    newHandler: event => {
      dispatch(actions.makeNew());
      dispatch(actions.setRange("start",""));
      dispatch(actions.setRange("end",""));
    },
    loadById: oid => dispatch(actions.loadById(oid))
  }
}

class ZipCodeRangeFormPageContainer extends React.Component {

  constructor(props) {
    super(props);
    actions.afterSave = ({ oid }) => {
      this.props.history.replace(`${routes.PATH_ZIPCODERANGE}/${oid}`);
    };
    actions.afterNew = () => {
      this.props.history.replace(`${routes.PATH_ZIPCODERANGE}/novo`);
    };
    actions.afterRemove = () => {
      this.props.history.replace(`${routes.PATH_ZIPCODERANGE}`);
    };
  }

  componentDidMount() {
    const oid = this.props.match.params.id;

    if (oid && parseInt(oid)) {
      this.props.loadById(oid);
    } else {
      this.props.newHandler();
    }
  }
  searchHandler = () => {
    this.props.history.push(`${routes.PATH_ZIPCODERANGE}`)
  }

  render() {
    const content = (
      <ZipCodeRangeForm
        changeHandler={this.props.changeHandler}
        record={this.props.record}
        types={this.props.types}
        lookupParent={this.props.lookupParent}
        lookupParentOnHide={this.lookupParentOnHide}
        lookupParentOnConfirm={this.lookupParentOnConfirm}
        lookupParentOnCancel={this.lookupParentOnCancel}
        lookupParentOnPage={this.lookupParentOnPage}
        lookupParentOnSort={this.lookupParentOnSort}
        lookupParentOnFilter={this.lookupParentOnFilter}
        lookupParentOnClick={this.lookupParentOnClick}
        lookupParentOnEdit={this.lookupParentOnEdit}
        lookupParentOnComplete={this.lookupParentOnComplete}
        lookupParentOnInputFilter={this.lookupParentOnInputFilter}
      />
    );
    const title = `Faixa de CEP ${this.props.record.oid || 'nova'}`
    const header = (
      <div>
        <h1>{title}</h1>
        <hr className="lxSplitter" ></hr>
        <div>
          <Toolbar
            onSave={this.props.saveHandler} saveButtonStyle='highlighted'
            onRemove={this.props.removeHandler} removeButtonStyle='danger'
            onNew={this.props.newHandler}
            onSearch={this.searchHandler}>
          </Toolbar>
        </div>
      </div>
    );
    return (<Page header={header} content={content} />);
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ZipCodeRangeFormPageContainer);