import React from "react";
import SimpleTable from "../../../../components/simple-table/simple-table";
import './QuoteTable.scss';

export const QuoteTable = (props) => {
  return (
    <div className="tableFreightQuote">

      <SimpleTable
        records={props.modalFreightQuote.value}
        notFoundMessage="Nenhum cálculo encontrado"
        columns={props.modalFreightQuote.columns}
      ></SimpleTable>
    </div>
  );
};
