import React from "react";
import { connect } from "react-redux";
import { Column } from "primereact/column";
import { actions } from "../../../../accountConfiguration.actions";
import { ButtonDialog } from "../../../../../../components/button-dialog/ButtonDialog";
import { DockConfigList } from "./DockConfigList";
import "./index.scss";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";

import { actions as actionPacking } from "../../../../../packing/packing.actions";
import { actions as actionVehicleType } from "../../../../../vehicleType/vehicleType.actions";
import { AppService, BUSINESS_UNIT_CHANGE_EVENT_NAME, COMPANY_CHANGE_EVENT_NAME } from "../../../../../../services/app.service";
import { Dropdown } from "primereact/dropdown";

const initialState = {
  columns: [
    {
      field: "vehicleType.name",
      header: "Veículo",
      columnKey: "vehicleType.name",
      sortable: false,
      filter: false,
    },
    {
      field: "operationType",
      header: "Tipo de operação",
      columnKey: "operationType",
      sortable: false,
      filter: false,
    },
    {
      field: "packagingType",
      header: "Embalagem",
      columnKey: "packagingType",
      sortable: false,
      filter: false,
    },

    {
      field: "operationTime",
      header: "Tempo operação",
      columnKey: "operationTime",
      sortable: false,
      filter: false,
    } 
  ],
};
const mapStateToProps = (state) => {
  const { docaVehicleOperation = [] } = state.crudAccountConfigurationState.currentRecord;
  //normalizar dados packing
  let packagingTypeDock = [];
  if (state.crudPackingState.records) {
    packagingTypeDock = state.crudPackingState.records.map((p) => {
      return { oid: p.oid, name: p.name };
    });
  }

  let vehicleTypesDock = [];
  if (state.crudVehicleTypeState.records) {
    vehicleTypesDock = state.crudVehicleTypeState.records.map((v) => {
      return { oid: v.oid, name: v.name };
    });
  }

  return {
    columns: initialState.columns,
    records: docaVehicleOperation,  
    operationTimeDock: state.crudAccountConfigurationState.operationTimeDock,
    timeNextOperationDock: state.crudAccountConfigurationState.timeNextOperationDock,
    operationTypesDock: state.crudAccountConfigurationState.operationTypesDock,
    operationTypeDock: state.crudAccountConfigurationState.operationTypeDock,
    selectedPackagingTypeDock: state.crudAccountConfigurationState.selectedPackagingTypeDock,
    packagingTypeDock,
    vehicleTypesDock,
    vehicleTypeDock: state.crudAccountConfigurationState.selectedVehicleTypeDock,
    selectedConfigDockRecord:state.crudAccountConfigurationState.selectedConfigDockRecord,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    operationTimeDockChangeHandler: (e) => {
      dispatch(actions.setOperationTimeDock(parseInt(e.target.value)));
    },

    timeNextOperationDockChangeHandler: (e) => { 
      dispatch(actions.setTimeNextOperationDock(parseInt(e.target.value)));
    },
    loadPacking: () => {
      dispatch(actionPacking.setType(null));
      dispatch(actionPacking.applyFilter([]));
    },
    loadVehicleType: () => {
      dispatch(actionVehicleType.setType(null));
      dispatch(actionVehicleType.applyFilter([]));
    },

    setSelectedOperationType: (selectedOperationTypeDock) => dispatch(actions.setSelectedOperationType(selectedOperationTypeDock)),
    setSelectedPackagingTypeDock: (selectedPackagingTypeDock) => dispatch(actions.setSelectedPackagingTypeDock(selectedPackagingTypeDock)),
    setSelectedVehicleTypeDock: (selectedVehicleTypeDock) => dispatch(actions.setSelectedVehicleTypeDock(selectedVehicleTypeDock)),

    addBtnAddConfigDock: () => {
      dispatch(actions.addBtnAddConfigDock());
    },
    onChangeConfigDockRecordSelect:(value)=>{
      dispatch(actions.setConfigDockRecordSelect(value));
    },
    onRemoveSelectedConfigDockRecord:()=>{
      dispatch(actions.onRemoveSelectedConfigDockRecord());
    }
  };
};

class DockConfigListPageContainer extends React.Component {
  constructor(props) {
    super(props); 
  }

  componentDidMount() {
    AppService.on(COMPANY_CHANGE_EVENT_NAME, this);
    AppService.on(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
    this.props.onChangeConfigDockRecordSelect(null);
    this.props.loadPacking();
    this.props.loadVehicleType(); 
  }

  componentWillUnmount() {
    AppService.off(COMPANY_CHANGE_EVENT_NAME, this);
    AppService.off(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
  }

  onReceiveAppNotifications = (eventName, params) => {
    if (eventName === COMPANY_CHANGE_EVENT_NAME || eventName === BUSINESS_UNIT_CHANGE_EVENT_NAME) {
      this.props.loadPacking();
      this.props.loadVehicleType();
    }
  };

  enableBtnAddConfigDock = () => {
    return this.props.selectedPackagingTypeDock.length > 0 && this.props.vehicleTypeDock && this.props.operationTypeDock.length > 0;
  };
  //LookupCarrier
  addBtnAddConfigDock = () => {
    this.props.addBtnAddConfigDock();
  };
  onChangeConfigDockRecordSelect = (e) => {
    this.props.onChangeConfigDockRecordSelect(e.value);
  };
  onChnageHandlerOperationTypeDock = (e) => {
    this.props.setSelectedOperationType(e.value);
  };
  onChnageHandlerPackagingTypeDock = (e) => {
    this.props.setSelectedPackagingTypeDock(e.value);
  };
  onChnageHandlerVehicleTypeDock = (e) => {
    this.props.setSelectedVehicleTypeDock(e.value);
  };
  //adiciona na lista o tipo de equipamento selecionado
  addNewEquipType = () => {
    this.props.addNewEquipType();
  }; 
  
  render() {

    const packagingTypeTemplate = (rowData, col) => {
      try {
        const packagingTypes = rowData.packagingType;
        let listNames = [];
        if (packagingTypes) {
          listNames = packagingTypes.map((tp) => {
            return tp.name;
          });
        }
        return <span>{listNames.join(",")}</span>;
      } catch (error) {
        return <></>;
      }
    };
    const operationTypeTemplate = (rowData, col) => {
      try {
        const operationType = rowData.operationType;
        let listlabel = [];
        if (operationType) {
          listlabel = operationType.map((tp) => {
            return tp.label;
          });
        }
        return <span>{listlabel.join(",")}</span>;
      } catch (error) {
        return <></>;
      }
    };
    const minutosTemplate = (rowData, col) => {
      try { 
        return <span>{rowData[col.field]} minutos</span>;
      } catch (error) {
        return <></>;
      }
    };
    

    let carrierColumns = [<Column key="selectorColumn" selectionMode="single" style={{ width: "3em" }} />];
    carrierColumns = [
      ...carrierColumns,
      ...this.props.columns.map((col) => {
        if (col.field == "packagingType") {
          return <Column {...col} key={col.field} className="col-head-table" body={packagingTypeTemplate} />;
        }
        if (col.field == "operationType") {
          return <Column {...col} key={col.field} className="col-head-table" body={operationTypeTemplate} />;
        }
        if (col.field == "operationTime") {
          return <Column {...col} key={col.field} className="col-head-table" body={minutosTemplate} />;
        }  
        return <Column {...col} key={col.field} />;
      }),
    ];

    return (
      <div className="client-form">
        <div className={this.props.className || "panel-margins-border" }>
          <div className="p-grid">
            <div className="p-col-12">
              <h3>Configurar Docas x Tipo de Veículo</h3>
            </div>

            <div className="p-col-2 form-field-container max-align">
              <label className="lxRequiredField">Tempo Operação(Minutos)</label>
              <InputText type="number" min={15} step={15} value={this.props.operationTimeDock} onChange={this.props.operationTimeDockChangeHandler} name="operationTimeDock" />
            </div>

            {/* <div className="p-col-2 form-field-container max-align"  >
              <label className="lxRequiredField">Próxima Operação(Minutos)</label>
              <InputText type="number" min={15} step={15} value={this.props.timeNextOperationDock} onChange={this.props.timeNextOperationDockChangeHandler} name="timeNextOperationDock" />
            </div> */}

            <div className="p-col-2 form-field-container max-align">
              <label>Tipo de operação</label>
              <MultiSelect optionLabel="label" value={this.props.operationTypeDock} options={this.props.operationTypesDock} onChange={this.onChnageHandlerOperationTypeDock} />
            </div>

            <div className="p-col-2 form-field-container max-align">
              <label>Embalagens</label>
              <MultiSelect optionLabel="name" value={this.props.selectedPackagingTypeDock} options={this.props.packagingTypeDock} onChange={this.onChnageHandlerPackagingTypeDock} />
            </div>

            <div className="p-col-2 form-field-container max-align">
              <label>Tipo de Veículo</label>
              <Dropdown  
               placeholder="Selecione" 
               dataKey={"oid"}
               optionLabel="name"  
               value={this.props.vehicleTypeDock} options={this.props.vehicleTypesDock} 
              onChange={this.onChnageHandlerVehicleTypeDock}></Dropdown>
            </div>

            <div className="p-col  align-bottom-right  " style={{width:"auto"}}>
              <button disabled={!this.enableBtnAddConfigDock()} className={"lognex-btn-highlighted"} onClick={this.addBtnAddConfigDock} title="Novo" style={{ marginRight: ".25em" }}>
                <i className="pi pi-plus"></i>
              </button>

              <ButtonDialog
                onClick={this.props.onRemoveSelectedConfigDockRecord}
                button={{
                  title: "Remover",
                  className: "lognex-btn-danger",
                  disabled: !this.props.selectedConfigDockRecord,
                }}
                dialog={{
                  header: "Confirmação",
                  modal: false,
                  message: "Deseja realmente remover o registro?",
                }}
              ></ButtonDialog>
            </div>

            <DockConfigList
              records={this.props.records}
              onSelectedRecordsChange={this.onChangeConfigDockRecordSelect}
              selectedConfigDockRecord={this.props.selectedConfigDockRecord}
              columns={carrierColumns}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DockConfigListPageContainer);
