import moment from "moment";
import { Reducer } from "../../services/reducer.factory";
import { types } from "./accountConfiguration.actions";

const initialState = {
  currentRecord: {
    oid: null,
    auditConfiguration: {
      automatedApprovement: false,
      divergentValuesTolerance: false,
      usePercentValueTolerance: false,
      percentToleranceValue: null,
      useFixedValueTolerance: false,
      useAprovationForMenorValue: false,
      toleranceValue: null,
      usePercentageFreightAboutNFe: false,
      percentFreightAboutNFeValue: null,
      useAproveForValueCTE: false,
      useChangeCarrierNfe: false,
      useApprovePostDelivery: false,
      useAuditInvoice: false,
      useDeliveryReceipt: false,
      useSendEmailForClient: false,
      useGroupDocumentsByInvoice: false,
      extractWbsFromNFeInfoParams: false,
    },
    freightCalculationConfiguration: {
      calculateICMS: false,
      useTransferTable: false,
      extractCubageFromNFeInfoParams: false,
      cubagePattern: null,
      vehicleTypePattern: null,
      usarTagFiltrarTabelaFrete: false,
      tagFiltrarTabelaEmbarque: "",
      useAddressRebidorByCarrier: []
    },
    businessUnitConfigurations: [],
    deliveryDeadLineConfigurations: {
      before: 0,
      after: 0,
    },
    orderManagementConfigurations: {
      deliveryTime: "",
      workingDay: {
        workingMorning: {
          start: moment().startOf("D").toDate(),
          end: moment().endOf("D").toDate(),
        },
        workingAfternoon: {
          start: moment().startOf("D").toDate(),
          end: moment().endOf("D").toDate(),
        },
      },
      workDays: {
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
      },
    },
    docaVehicleOperation: [],
    customColumns: []
  },
  favorites: [],
  lastAccesses: [],
  changed: false,
  selectedBusinessUnitRecord: null,
  lookupBusinessUnit: {
    visible: false,
    modal: true,
    header: "Unidades",
  },
  lookupCarrier: {
    visible: false,
    modal: true,
    header: "Transportadora",
  },
  lookupUnitSelected: {},
  selectedWarehouseEmail: null,
  inputEmailWareHouse: "",
  selectedCarrierRecord: null,
  carrierSelected: null,
  inputEmailCarrier: "",
  selectedCarrierEmail: null,
  carrierEquipTypeSelected: null,
  lookupCarrierEquipType: {
    visible: false,
    modal: true,
    header: "Transportadora",
  },
  selectedCarrierEquipTypeRecord: [],
  lookupEquipment: {
    visible: false,
    modal: true,
    header: "Equipamento",
  },
  equipmentType: null,//tipo equipamento selecionano do lookup
  dialogCustomFieldsEquipTypeVisible: false,
  equipTypeSelected: null,
  selectedEquipTypeRecord: null,

  operationTimeDock: 60,//60 minutos
  timeNextOperationDock: 15,//minutos

  operationTypesDock: [
    { label: "Inbound", type: 1 },
    { label: "Outbound", type: 2 },
  ],
  operationTypeDock: [],
  selectedPackagingTypeDock: [],
  selectedVehicleTypeDock: null,
  selectedConfigDockRecord: null,
  selectedRangeTimeRecord: null,
  selectedTagRecord: null,
  selectedHolidayRecord: null,
  labelCarrier: "",
  cnpjCarrier:"",
  lookupFreightTable: {
    visible: false,
    modal: true,
    header: "Tabela de Frete",
  },
  showLookupFreightTable: false,
  recordsFreightTable: [],
  lookupFreightTableSelected: null,
  vehicleType: null,
};

const reducer = new Reducer(initialState, types);

export function AccountConfigurationReducer(state, action) {
  state = reducer.execute(state, action);
  return execute(state, action);
}

function execute(state, action) {
  let result = state;
  switch (action.type) {
    case types.SET_FAVORITE:
      return setFavorite(state, action);
    case types.SET_LAST_ACCESSED:
      return setLastAccessed(state, action);
    case types.LOAD_ACCOUNT_CONFIGURATIONS_BY_COMPANY:
      return loadAccountConfigurationsByCompany(state, action);
    case types.SET_CHANGED:
      return setChanged(state, action);
    case types.SET_SELECTED_BUSINESS_UNIT_RECORD:
      return setSelectedBusinessUnitRecord(state, action);
    case types.SET_LOOKUP_BUSINESS_UNIT_VISIBLE:
      return setLookupBusinessUnitSetVisible(state, action);
    case types.SET_LOOKUP_UNIT_SELECTED:
      return setLookupUnitSelected(state, action);
    case types.ADD_NEW_BUSINESS_UNIT:
      return addNewBusinessUnit(state, action);
    case types.REMOVE_BUSINESS_UNIT_SELECTED:
      return onRemoveBusinessUnitSelected(state, action);
    case types.SET_CHANGE_ROW_WAREHOUSE_EMAIL:
      return setSelectedWarehouseEmail(state, action);
    case types.INPUT_CHANGE_WAREHOUSE_EMAIL:
      return inputChangeWarehouseEmail(state, action);
    case types.ADD_NEW_WAREHOUSE_EMAIL:
      return addNewEmailWareHouse(state, action);
    case types.REMOVE_SELECTED_WAREHOUSE_EMAIL:
      return onRemoveEmailWareHouseSelected(state, action);
    case types.CHANGE_CARRIER_RECORD_SELECTED:
      return onChangeCarrierRecordSelect(state, action);
    case types.REMOVE_CARRIER_RECORD_SELECTED:
      return onRemoveCarrierSelected(state, action);
    case types.SET_LOOKUP_CARRIER_VISIBLE:
      return setLookupCarrierSetVisible(state, action);
    case types.SET_CARRIER_SELECTED_LOOKUP:
      return setLookupCarrierSelected(state, action);
    case types.INPUT_CHANGE_LOOKUP_CARRIR:
      return setLookupCarrierInputChange(state, action);
    case types.ADD_NEW_CARRIER_SELECTED:
      return addNewCarrierSelected(state, action);
    case types.INPUT_CHANGE_CARRIER_EMAIL:
      return inputEmailCarrierChangeHandler(state, action);
    case types.ADD_NEW_CARRIER_EMAIL:
      return addNewEmailCarrier(state, action);
    case types.SET_CHANGE_ROW_CARRIER_EMAIL:
      return onSelectedEmailCarrierRecordChange(state, action);
    case types.REMOVE_SELECTED_CARRIER_EMAIL:
      return onRemoveEmailcarrierSelected(state, action);
    case types.ADD_NEW_CARRIER_EQUIP_TYPE_SELECTED:
      return addNewCarrierEquipTypeSelected(state, action);
    case types.SET_CARRIER_EQUIP_TYPE_SELECTED_LOOKUP:
      return setLookupCarrierEquipTypeSelected(state, action);
    case types.SET_LOOKUP_CARRIER_EQUIP_TYPE_VISIBLE:
      return setLookupCarrierEquipTypeSetVisible(state, action);
    case types.CHANGE_CARRIER_EQUIP_TYPE_RECORD_SELECTED:
      return onChangeCarrierEquipTypeRecordSelect(state, action);
    case types.SET_LOOKUP_EQUIPMENT_VISIBLE:
      return setLookupEquipmentVisible(state, action);
    case types.SET_EQUIPMENT_TYPE:
      return setEquipmentType(state, action);
    case types.ADD_EQUIPMENT_TYPE:
      return addNewEquipType(state, action);
    case types.REMOVE_CARRIER_EQUIP_TYPE_RECORD_SELECTED:
      return onRemoveCarrierEquipTypeRecord(state, action);
    case types.SET_DIALOG_CUSTOM_FIELDS_EQUIP_TYPE_VISIBLE:
      return setVisibliDialogCustomFieldsEquipType(state, action);
    case types.SET_EQUIP_TYPE_SELECTED:
      return setEquipTypeSelected(state, action);
    case types.SET_EQUIP_TYPE_CUSTOM_FIELDS:
      return onChangeEquipTypeCustomFields(state, action);
    case types.SET_SELECTED_EQUIP_TYPE_RECORD:
      return setSelectedEquipTypeRecord(state, action);
    case types.REMOVE_SELECTED_EQUIP_TYPE_RECORD:
      return onRemoveSelectedEquipTypeRecord(state, action);
    case types.SET_OPERATION_TIME_DOCK:
      return setOperationTimeDock(state, action);
    case types.SET_TIME_BETWEEN_OPERATION_DOCK:
      return setTimeNextOperationDock(state, action);
    case types.SET_SELECTED_OPERATION_TYPE_DOCK:
      return setSelectedOperationType(state, action);
    case types.SET_SELECTED_PACKAGING_TYPE_DOCK:
      return setSelectedPackagingTypeDock(state, action);
    case types.SET_SELECTED_VEHICLE_TYPE_DOCK:
      return setSelectedVehicleTypeDock(state, action);
    case types.ADD_SELECTED_CONFIG_DOCK:
      return addBtnAddConfigDock(state, action);
    case types.SET_SELECTED_CONFIG_DOCK:
      return setConfigDockRecordSelect(state, action);
    case types.REMOVE_SELECTED_CONFIG_DOCK_RECORD:
      return onRemoveSelectedConfigDockRecord(state, action);
    case types.SET_DOCA_VEHICLE_OPERATION:
      return setDocaVehicleOperation(state, action);
    case types.SET_WORK_DAY_RANGE_TIME:
      return setWorkDayTimeRangeTime(state, action);
    case types.SET_SELECTED_RANGE_TIME_RECORDS:
      return setSelectedRangeTimeRecord(state, action);
    case types.REMOVE_SELECTED_RANGE_TIME_RECORDS:
      return removeSelectedRangeTimeRecord(state, action);
    case types.SET_ENABLE_WORK_DAY_UNIT:
      return onChangeWorkDay(state, action);
    case types.SET_DAY_TO_SCHEDULE:
      return changeDayStartSchedule(state, action);
    case types.SET_TIME_NEXT_OPERATION_SCHEDULE:
      return changeTimeNextOperation(state, action);
    case types.SET_SELECTED_HOLIDAY_RECORDS:
      return setSelectedHolidayRecord(state, action);
    case types.REMOVE_SELECTED_HOLIDAY_RECORDS:
      return removeSelectedHolidayRecord(state, action);
    case types.SET_HOLIDAY:
      return setDateHoliday(state, action);
    case types.SET_HOLIDAY_RECORS_XLS:
      return setHolidayRecordsXls(state, action);
    case types.SET_ENABLE_USE_DOCK:
      return setDocaWorkDayTimeUseDocks(state, action);
    case types.SET_NEW_FREIGHT_TAG:
      return addNewFreightTag(state, action);
    case types.SET_SELECTED_TAG_RECORD:
      return setSelectedTagRedord(state, action);
    case types.ON_REMOVE_SELECTED_TAG_RECORD:
      return onRemoveSelectedTagRecord(state, action);
    case types.SET_RANGE_DAY_TO_SCHEDULE:
      return changeRangeDaysSchedule(state, action);
    case types.SET_LABEL_CARRIER:
      return setLabelCarrier(state, action);
    case types.SET_CNPJ_CARRIER:
      return setCnpjCarrier(state,action);
    case types.SET_LOOKUP_FREIGHTTABLE_VISIBLE:
      return setLookupFreightTableVisible(state, action);
    case types.SET_LOOKUP_FREIGHTTABLE_SELECTED:
      return setLookupFreightTableSelected(state, action);
    case types.CONFIRM_FREIGHTTABLE_DEFAULT_SELECTED:
      return onConfirmFreightTable(state, action);
    case types.REMOVER_FREIGHTTABLE_DEFAULT_SELECTED:
      return onRemoveDefaultFreightTable(state, action);
    case types.SET_DEFAULT_FREIGHTTABLE_DEFAULT:
      return setDefaultFreightTableRecord(state, action);
    case types.ADD_SELECTED_VEHICLE_TYPE_FREIGHT_TABLE:
      return addBtnAddVehicleTypeSelected(state, action);
    case types.SET_SELECTED_VEHICLE_TYPE:
      return setSelectedVehicleType(state, action);
    case types.REMOVE_VEHICLE_TYPE_FREIGHT_TABLE:
      return onRemoveVehicleDefaultFreightTable(state, action);

    default:
      return result;
  }
}

function onRemoveVehicleDefaultFreightTable(state, action) {

  let selectedBusinessUnitRecord = state.selectedBusinessUnitRecord;
  if (selectedBusinessUnitRecord && selectedBusinessUnitRecord.freightTableDefaultRoute && selectedBusinessUnitRecord.freightTableDefaultRoute.oid
    && selectedBusinessUnitRecord.freightTableDefaultRoute.vehiclesLock) {

    const newList = selectedBusinessUnitRecord.freightTableDefaultRoute.vehiclesLock.filter(v => v.oid !== action.oid);
    selectedBusinessUnitRecord.freightTableDefaultRoute.vehiclesLock = newList;
    return { ...state, selectedBusinessUnitRecord };
  }

  return state;
}

function addBtnAddVehicleTypeSelected(state, action) {
  const vehicleType = state.vehicleType;

  let selectedBusinessUnitRecord = state.selectedBusinessUnitRecord;
  if (selectedBusinessUnitRecord) {
    if (!selectedBusinessUnitRecord.freightTableDefaultRoute) {
      selectedBusinessUnitRecord.freightTableDefaultRoute = {
        oid: null,
        vehiclesLock: [],
      }
    }
    if (selectedBusinessUnitRecord.freightTableDefaultRoute.vehiclesLock.filter(v => v.oid === vehicleType.oid).length == 0) {
      selectedBusinessUnitRecord.freightTableDefaultRoute.vehiclesLock.push({ ...vehicleType });
    }

    return { ...state, selectedBusinessUnitRecord, vehicleType: null };
  }

  return state;
}

function setSelectedVehicleType(state, action) {
  const vehicleType = action.vehicleType;
  return { ...state, vehicleType };
}
function onRemoveDefaultFreightTable(state, action) {
  let selectedBusinessUnitRecord = state.selectedBusinessUnitRecord;
  if (selectedBusinessUnitRecord) {
    if (!selectedBusinessUnitRecord.freightTableDefaultRoute) {
      selectedBusinessUnitRecord.freightTableDefaultRoute = {
        oid: null,
        vehiclesLock: [],
      }
    }

    let recordsFreightTable = state.recordsFreightTable;
    if (recordsFreightTable) {
      recordsFreightTable = recordsFreightTable.filter(e => e.oid != action.oid)
    }

    selectedBusinessUnitRecord.freightTableDefaultRoute.oid = recordsFreightTable.map(e => e.oid);

    return { ...state, recordsFreightTable, selectedBusinessUnitRecord };
  }
  return state;
}

function onConfirmFreightTable(state, action) {
  let lookupFreightTableSelected = state.lookupFreightTableSelected;
  const freightTableDefault = { ...lookupFreightTableSelected };

  let recordsFreightTable = state.recordsFreightTable;
  if (!recordsFreightTable) {
    recordsFreightTable = []
  }

  let selectedBusinessUnitRecord = state.selectedBusinessUnitRecord;
  if (selectedBusinessUnitRecord) {
    if (!selectedBusinessUnitRecord.freightTableDefaultRoute) {
      selectedBusinessUnitRecord.freightTableDefaultRoute = {
        oid: null,
        vehiclesLock: [],
      }
    }
    if (recordsFreightTable.filter(e => e.oid == freightTableDefault.oid).length == 0) {
      recordsFreightTable.push(freightTableDefault)
    }

    selectedBusinessUnitRecord.freightTableDefaultRoute.oid = recordsFreightTable.map(e => e.oid);
  }

  return { ...state, selectedBusinessUnitRecord, recordsFreightTable };

}

function setDefaultFreightTableRecord(state, action) {
  const freightTableDefault = action.freightTable;

  const recordsFreightTable = freightTableDefault;
  return { ...state, recordsFreightTable };
}

function setLookupFreightTableSelected(state, action) {
  const lookupFreightTableSelected = action.freightTable;
  return Object.assign({}, state, { lookupFreightTableSelected });
}

function setLookupFreightTableVisible(state, action) {
  let lookupFreightTable = state.lookupFreightTable;
  lookupFreightTable.visible = action.visible;
  return Object.assign({}, state, { lookupFreightTable });
}

function setLabelCarrier(state, action) {
  let labelCarrier = action.label;
  return { ...state, labelCarrier };
}
function setCnpjCarrier(state, action) {
  let cnpjCarrier = action.cnpj;
  return { ...state, cnpjCarrier };
}

function setSelectedTagRedord(state, action) {
  let selectedTagRecord = action.selectedTagRecord;
  return { ...state, selectedTagRecord };
}

function onRemoveSelectedTagRecord(state, action) {
  let currentRecord = state.currentRecord
  let { integrationConfigurations = {} } = currentRecord;

  if (!integrationConfigurations) {
    integrationConfigurations = {};
  }

  if (!integrationConfigurations.integrationAnymarket) {
    integrationConfigurations.integrationAnymarket = {};
  }

  if (!integrationConfigurations.integrationAnymarket.tags) {
    integrationConfigurations.integrationAnymarket.tags = [];
  }

  const newTags = integrationConfigurations.integrationAnymarket.tags.filter((tag) => String(tag).toUpperCase() != String(state.selectedTagRecord.name).toUpperCase())
  integrationConfigurations.integrationAnymarket.tags = newTags;
  currentRecord = Object.assign({}, currentRecord, { integrationConfigurations });


  return { ...state, currentRecord };
}

function addNewFreightTag(state, action) {
  let currentRecord = state.currentRecord
  let { integrationConfigurations = {} } = currentRecord;

  if (!integrationConfigurations) {
    integrationConfigurations = {};
  }

  if (!integrationConfigurations.integrationAnymarket) {
    integrationConfigurations.integrationAnymarket = {};
  }

  if (!integrationConfigurations.integrationAnymarket.tags) {
    integrationConfigurations.integrationAnymarket.tags = [];
  }

  if (integrationConfigurations.integrationAnymarket.tags.filter((tag) => String(tag).toUpperCase() == String(action.tag).toUpperCase()).length == 0) {
    integrationConfigurations.integrationAnymarket.tags.push(action.tag);
    currentRecord = Object.assign({}, currentRecord, { integrationConfigurations });
  }
  return { ...state, currentRecord };
}

function setDocaWorkDayTimeUseDocks(state, action) {
  let selectedBusinessUnitRecord = state.selectedBusinessUnitRecord;
  if (!selectedBusinessUnitRecord.docaWorkDayTime) {
    selectedBusinessUnitRecord.docaWorkDayTime = {
      timeNextOperation: 15,//sempre de 15 em 15 minutos
      dayStartSchedule: 1,//dias
      rangeDaysSchedule: 5,//quantidade de dias ,mostrar horario de agendamento 
      docaRangeTime: [
      ],
      holidays: [],
      docaWorkDays: {
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
      }
    }
  }
  selectedBusinessUnitRecord.docaWorkDayTime.useDocks = action.useDocks;
  return { ...state, selectedBusinessUnitRecord };
}

function setHolidayRecordsXls(state, action) {
  let selectedBusinessUnitRecord = state.selectedBusinessUnitRecord;

  if (!selectedBusinessUnitRecord.docaWorkDayTime) {
    selectedBusinessUnitRecord.docaWorkDayTime = {
      timeNextOperation: 15,//sempre de 15 em 15 minutos
      dayStartSchedule: 1,//dias
      rangeDaysSchedule: 5,//quantidade de dias ,mostrar horario de agendamento 
      docaRangeTime: [
      ],
      holidays: [],
      docaWorkDays: {
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
      }
    }
  }

  if (!selectedBusinessUnitRecord.docaWorkDayTime.holidays)
    selectedBusinessUnitRecord.docaWorkDayTime.holidays = []
  let list = [...selectedBusinessUnitRecord.docaWorkDayTime.holidays, ...action.records]

  list = list.sort((a, b) => moment(a.holiday).diff(moment(b.holiday)))

  selectedBusinessUnitRecord.docaWorkDayTime.holidays = list;

  return { ...state, selectedHolidayRecord: null, selectedBusinessUnitRecord };
}

function setDateHoliday(state, action) {
  let selectedBusinessUnitRecord = state.selectedBusinessUnitRecord;

  if (!selectedBusinessUnitRecord.docaWorkDayTime) {
    selectedBusinessUnitRecord.docaWorkDayTime = {
      timeNextOperation: 15,//sempre de 15 em 15 minutos
      dayStartSchedule: 1,//dias
      rangeDaysSchedule: 5,//quantidade de dias ,mostrar horario de agendamento 
      docaRangeTime: [
      ],
      holidays: [],
      docaWorkDays: {
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
      }
    }
  }

  if (!selectedBusinessUnitRecord.docaWorkDayTime.holidays)
    selectedBusinessUnitRecord.docaWorkDayTime.holidays = []

  const newList = selectedBusinessUnitRecord.docaWorkDayTime.holidays.filter((item) => item.holiday == action.holiday.holiday);
  if (newList.length == 0) {
    selectedBusinessUnitRecord.docaWorkDayTime.holidays.push(action.holiday);
  }
  return { ...state, selectedHolidayRecord: null, selectedBusinessUnitRecord };
}

function removeSelectedHolidayRecord(state, action) {
  let selectedHolidayRecord = state.selectedHolidayRecord;
  let selectedBusinessUnitRecord = state.selectedBusinessUnitRecord;

  if (!selectedBusinessUnitRecord.docaWorkDayTime) {
    selectedBusinessUnitRecord.docaWorkDayTime = {
      timeNextOperation: 15,//sempre de 15 em 15 minutos
      dayStartSchedule: 1,//dias
      rangeDaysSchedule: 5,//quantidade de dias ,mostrar horario de agendamento 
      docaRangeTime: [
      ],
      holidays: [],
      docaWorkDays: {
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
      }
    }
  }

  const newList = selectedBusinessUnitRecord.docaWorkDayTime.holidays.filter((item) => item.holiday != selectedHolidayRecord.holiday);
  selectedBusinessUnitRecord.docaWorkDayTime.holidays = newList;

  return { ...state, selectedHolidayRecord: null, selectedBusinessUnitRecord };
}

function setSelectedHolidayRecord(state, action) {
  let selectedHolidayRecord = action.value;
  return { ...state, selectedHolidayRecord };
}

function changeTimeNextOperation(state, action) {
  let selectedBusinessUnitRecord = state.selectedBusinessUnitRecord;

  if (!selectedBusinessUnitRecord.docaWorkDayTime) {
    selectedBusinessUnitRecord.docaWorkDayTime = {
      timeNextOperation: 15,//sempre de 15 em 15 minutos
      dayStartSchedule: 1,//dias
      rangeDaysSchedule: 5,//quantidade de dias ,mostrar horario de agendamento 
      docaRangeTime: [
      ],
      holidays: [],
      docaWorkDays: {
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
      }
    }
  }

  if (selectedBusinessUnitRecord.docaWorkDayTime) {
    selectedBusinessUnitRecord.docaWorkDayTime.timeNextOperation = action.value;;
  }
  return { ...state, selectedBusinessUnitRecord };
}

function changeDayStartSchedule(state, action) {
  let selectedBusinessUnitRecord = state.selectedBusinessUnitRecord;
  if (!selectedBusinessUnitRecord.docaWorkDayTime) {
    selectedBusinessUnitRecord.docaWorkDayTime = {
      timeNextOperation: 15,//sempre de 15 em 15 minutos
      dayStartSchedule: 1,//dias
      rangeDaysSchedule: 5,//quantidade de dias ,mostrar horario de agendamento 
      docaRangeTime: [
      ],
      holidays: [],
      docaWorkDays: {
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
      }
    }
  }
  if (selectedBusinessUnitRecord.docaWorkDayTime) {
    selectedBusinessUnitRecord.docaWorkDayTime.dayStartSchedule = action.value;;
  }
  return { ...state, selectedBusinessUnitRecord };
}

function changeRangeDaysSchedule(state, action) {
  let selectedBusinessUnitRecord = state.selectedBusinessUnitRecord;
  if (!selectedBusinessUnitRecord.docaWorkDayTime) {
    selectedBusinessUnitRecord.docaWorkDayTime = {
      timeNextOperation: 15,//sempre de 15 em 15 minutos
      dayStartSchedule: 1,//dias
      rangeDaysSchedule: 5,//quantidade de dias ,mostrar horario de agendamento 
      docaRangeTime: [
      ],
      holidays: [],
      docaWorkDays: {
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
      }
    }
  }
  selectedBusinessUnitRecord.docaWorkDayTime.rangeDaysSchedule = action.value;;

  return { ...state, selectedBusinessUnitRecord };
}


function onChangeWorkDay(state, action) {
  let selectedBusinessUnitRecord = state.selectedBusinessUnitRecord;
  let fieldValue = action.fieldValue;
  if (!selectedBusinessUnitRecord.docaWorkDayTime) {
    selectedBusinessUnitRecord.docaWorkDayTime = {
      timeNextOperation: 15,//sempre de 15 em 15 minutos
      dayStartSchedule: 1,//dias
      rangeDaysSchedule: 5,//quantidade de dias ,mostrar horario de agendamento 
      docaRangeTime: [
      ],
      holidays: [],
      docaWorkDays: {
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
      }
    }
  }
  if (selectedBusinessUnitRecord.docaWorkDayTime) {
    selectedBusinessUnitRecord.docaWorkDayTime.docaWorkDays[fieldValue.field] = fieldValue.value;
  }
  return { ...state, selectedBusinessUnitRecord };
}
function removeSelectedRangeTimeRecord(state, action) {
  let selectedBusinessUnitRecord = state.selectedBusinessUnitRecord;

  if (!selectedBusinessUnitRecord.docaWorkDayTime) {
    selectedBusinessUnitRecord.docaWorkDayTime = {
      timeNextOperation: 15,//sempre de 15 em 15 minutos
      dayStartSchedule: 1,//dias
      rangeDaysSchedule: 5,//quantidade de dias ,mostrar horario de agendamento 
      docaRangeTime: [
      ],
      holidays: [],
      docaWorkDays: {
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
      }
    }
  }

  const newList = selectedBusinessUnitRecord.docaWorkDayTime.docaRangeTime.filter((item) => item.start != state.selectedRangeTimeRecord.start && item.end != state.selectedRangeTimeRecord.end);
  selectedBusinessUnitRecord.docaWorkDayTime.docaRangeTime = newList;

  return { ...state, selectedBusinessUnitRecord, selectedRangeTimeRecord: null };
}

function setSelectedRangeTimeRecord(state, action) {
  let selectedRangeTimeRecord = action.selectedRangeTimeRecord;
  return { ...state, selectedRangeTimeRecord };
}

function setWorkDayTimeRangeTime(state, action) {

  let selectedBusinessUnitRecord = state.selectedBusinessUnitRecord;
  if (!selectedBusinessUnitRecord.docaWorkDayTime) {
    selectedBusinessUnitRecord.docaWorkDayTime = {
      timeNextOperation: 15,//sempre de 15 em 15 minutos
      dayStartSchedule: 1,//dias
      rangeDaysSchedule: 5,//quantidade de dias ,mostrar horario de agendamento 
      docaRangeTime: [
      ],
      holidays: [],
      docaWorkDays: {
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
      }
    }
  }

  if (!selectedBusinessUnitRecord.docaWorkDayTime.docaRangeTime) {
    selectedBusinessUnitRecord.docaWorkDayTime.docaRangeTime = [];
  }

  //não deixar adicionar duplicados e nem deixar conflito entre horarios  
  if (selectedBusinessUnitRecord.docaWorkDayTime.docaRangeTime.filter((item) => item.start == action.timeRange.start && item.end == action.timeRange.end).length == 0) {
    selectedBusinessUnitRecord.docaWorkDayTime.docaRangeTime.push(action.timeRange)
  }

  return { ...state, selectedBusinessUnitRecord };
}


function setDocaVehicleOperation(state, action) {
  let currentRecord = state.currentRecord;
  currentRecord.docaVehicleOperation = action.docaVehicleOperation;
  return { ...state, currentRecord };
}

function onRemoveSelectedConfigDockRecord(state, action) {
  let currentRecord = state.currentRecord;
  let { docaVehicleOperation = [] } = state.currentRecord;

  let selectedConfigDockRecord = state.selectedConfigDockRecord;
  const index = docaVehicleOperation.indexOf(selectedConfigDockRecord);//.filter((d)=>d.vehicleType.oid !== selectedConfigDockRecord.vehicleType.oid)
  if (index > -1)
    docaVehicleOperation.splice(index, 1);
  currentRecord.docaVehicleOperation = docaVehicleOperation;
  return { ...state, currentRecord };
}

function setConfigDockRecordSelect(state, action) {
  let selectedConfigDockRecord = state.selectedConfigDockRecord;
  selectedConfigDockRecord = action.selectedConfigDockRecord;
  return { ...state, selectedConfigDockRecord };
}

function addBtnAddConfigDock(state, action) {

  let currentRecord = state.currentRecord;
  let { docaVehicleOperation = [] } = state.currentRecord;
  let selectedVehicleTypeDock = state.selectedVehicleTypeDock;
  let selectedPackagingTypeDock = state.selectedPackagingTypeDock;
  let operationTypeDock = state.operationTypeDock;
  let operationTimeDock = state.operationTimeDock;
  let timeNextOperationDock = state.timeNextOperationDock;

  //verificar se tem embalagem selecionados
  if (operationTypeDock && operationTypeDock.length > 0) {
    if (selectedPackagingTypeDock && selectedPackagingTypeDock.length > 0) {
      //nao add duplicados
      //check ja add vehicle
      let isAdd = false;
      //não pode existir valores conflitante
      //não pode existir dois registro com mesmo veiculo e mesmo embalagem e mesmo tipo operacao
      for (const op of docaVehicleOperation) {

        let existVehicleType = op.vehicleType.oid == selectedVehicleTypeDock.oid;
        let existEmbalag = false;
        let existOperationType = false;
        //check embalagem ja add
        op.packagingType.forEach(p => {
          selectedPackagingTypeDock.forEach(g => {
            if (p.oid == g.oid) {
              existEmbalag = true;
            }
          });
        });
        //check operationType ja add
        op.operationType.forEach(o => {
          operationTypeDock.forEach(t => {
            if (o.type == t.type) {
              existOperationType = true;
            }
          });
        });

        isAdd = existVehicleType && existEmbalag && existOperationType;
        if (isAdd) {
          break;
        }
      }

      if (selectedVehicleTypeDock && !isAdd) {
        docaVehicleOperation.push({
          vehicleType: selectedVehicleTypeDock,
          operationTime: operationTimeDock,
          operationType: operationTypeDock,
          packagingType: selectedPackagingTypeDock,
          timeNextOperation: timeNextOperationDock,
        })
      }
      currentRecord = { ...currentRecord, docaVehicleOperation };
    }
  }
  return Object.assign({}, state, { currentRecord, operationTimeDock: 60, selectedVehicleTypeDock: null, timeNextOperationDock: 15, operationTypeDock: [], selectedPackagingTypeDock: [] });

}

function setSelectedVehicleTypeDock(state, action) {
  let selectedVehicleTypeDock = state.selectedVehicleTypeDock;
  selectedVehicleTypeDock = action.selectedVehicleTypeDock;
  return { ...state, selectedVehicleTypeDock };
}
function setSelectedPackagingTypeDock(state, action) {
  let selectedPackagingTypeDock = state.selectedPackagingTypeDock;
  selectedPackagingTypeDock = action.selectedPackagingTypeDock;
  return { ...state, selectedPackagingTypeDock };
}

function setSelectedOperationType(state, action) {
  let operationTypeDock = state.operationTypeDock;
  operationTypeDock = action.operationTypeDock;
  return { ...state, operationTypeDock };
}
function setOperationTimeDock(state, action) {
  let operationTimeDock = state.operationTimeDock;
  operationTimeDock = Number(action.operationTimeDock);
  return { ...state, operationTimeDock };
}

function setTimeNextOperationDock(state, action) {
  let timeNextOperationDock = state.timeNextOperationDock;
  timeNextOperationDock = Number(action.timeNextOperationDock);
  return { ...state, timeNextOperationDock };
}



function setChanged(state, action) {
  let changed = state.changed || false;
  changed = action.value;
  return { ...state, changed };
}

function setFavorite(state, action) {
  let favorites = state.favorites;
  if (favorites.includes(action.value)) {
    favorites.splice(favorites.indexOf("action.value"), 1);
  } else {
    favorites.push(action.value);
  }
  return { ...state, favorites };
}

function setLastAccessed(state, action) {
  let lastAccesses = state.lastAccesses;
  if (lastAccesses.includes(action.value)) {
    lastAccesses.splice(lastAccesses.indexOf("action.value"), 1);
  } else {
    lastAccesses.push(action.value);
  }
  return Object.assign({}, state, { lastAccesses });
}

function loadAccountConfigurationsByCompany(state, action) {
  let currentRecord = state.currentRecord;
  if (action.configurations) {
    currentRecord = { ...action.configurations };
    if (
      currentRecord.orderManagementConfigurations &&
      currentRecord.orderManagementConfigurations.workingDay
    ) {
      currentRecord.orderManagementConfigurations.workingDay.workingMorning.start =
        moment(
          currentRecord.orderManagementConfigurations.workingDay.workingMorning
            .start
        ).toDate();
      currentRecord.orderManagementConfigurations.workingDay.workingMorning.end =
        moment(
          currentRecord.orderManagementConfigurations.workingDay.workingMorning
            .end
        ).toDate();
      currentRecord.orderManagementConfigurations.workingDay.workingAfternoon.start =
        moment(
          currentRecord.orderManagementConfigurations.workingDay
            .workingAfternoon.start
        ).toDate();
      currentRecord.orderManagementConfigurations.workingDay.workingAfternoon.end =
        moment(
          currentRecord.orderManagementConfigurations.workingDay
            .workingAfternoon.end
        ).toDate();
    }
    return Object.assign({}, state, { currentRecord });
  }
  return state;
}

function setSelectedBusinessUnitRecord(state, action) {
  const selectedBusinessUnitRecord = action.selectedBusinessUnitRecord;
  return Object.assign({}, state, { selectedBusinessUnitRecord });
}

function setLookupBusinessUnitSetVisible(state, action) {
  let lookupBusinessUnit = state.lookupBusinessUnit;
  lookupBusinessUnit = Object.assign({}, lookupBusinessUnit, {
    visible: action.visible,
  });
  return Object.assign({}, state, { lookupBusinessUnit });
}

function setLookupUnitSelected(state, action) {
  const lookupUnitSelected = action.lookupUnitSelected;
  return Object.assign({}, state, { lookupUnitSelected });
}

function addNewBusinessUnit(state, action) {
  let currentRecord = state.currentRecord;
  let { businessUnitConfigurations = [] } = state.currentRecord;

  if (!businessUnitConfigurations) businessUnitConfigurations = [];
  //nao add duplicados
  if (businessUnitConfigurations.length == 0
    || businessUnitConfigurations.filter((item) => item.businessUnit == state.lookupUnitSelected.documentNumber
      && item.sigla == state.lookupUnitSelected.code).length == 0) {
    businessUnitConfigurations.push({
      businessUnit: state.lookupUnitSelected.documentNumber,
      sigla: state.lookupUnitSelected.code,
      label: state.lookupUnitSelected.label,
      businessUnitParams: {
        warehouseEmail: [], //list  email string
      },
      carrierConfigurations: [],
    });

    currentRecord = Object.assign({}, currentRecord, {
      businessUnitConfigurations,
    });
  }
  return Object.assign({}, state, { currentRecord, lookupUnitSelected: null });
}

function onRemoveBusinessUnitSelected(state, action) {
  let currentRecord = state.currentRecord;
  const { businessUnitConfigurations = [] } = state.currentRecord;
  const newList = [];
  for (const item of businessUnitConfigurations) {
    if (item.businessUnit != state.selectedBusinessUnitRecord.businessUnit || item.sigla != state.selectedBusinessUnitRecord.sigla) {
      newList.push(item);
    }
  }
  currentRecord = Object.assign({}, currentRecord, {
    businessUnitConfigurations: newList,
  });

  return Object.assign({}, state, {
    currentRecord,
    selectedBusinessUnitRecord: null,
    selectedWarehouseEmail: null,
    selectedCarrierRecord: null,
  });
}

function setSelectedWarehouseEmail(state, action) {
  return Object.assign({}, state, { selectedWarehouseEmail: action.email });
}
function inputChangeWarehouseEmail(state, action) {
  return Object.assign({}, state, { inputEmailWareHouse: action.email });
}
function addNewEmailWareHouse(state, action) {
  const inputEmailWareHouse = state.inputEmailWareHouse
    ? state.inputEmailWareHouse.trim()
    : "";

  let currentRecord = state.currentRecord;
  let { businessUnitConfigurations = [] } = state.currentRecord;

  //nao add duplicados
  let selectedBusinessUnitRecordUpdate = null;
  businessUnitConfigurations.forEach((item) => {
    if (item.businessUnit == state.selectedBusinessUnitRecord.businessUnit &&
      item.sigla == state.selectedBusinessUnitRecord.sigla) {
      if (!item.businessUnitParams) {
        item.businessUnitParams = {};
      }
      if (
        !item.businessUnitParams.warehouseEmail ||
        item.businessUnitParams.warehouseEmail.length === 0
      )
        item.businessUnitParams.warehouseEmail = [];
      if (
        item.businessUnitParams.warehouseEmail.indexOf(inputEmailWareHouse) ===
        -1
      ) {
        item.businessUnitParams.warehouseEmail.push(inputEmailWareHouse);
        selectedBusinessUnitRecordUpdate = Object.assign({}, item);
        return;
      }
    }
  });

  currentRecord = Object.assign({}, currentRecord, {
    businessUnitConfigurations,
  });

  return Object.assign({}, state, {
    currentRecord,
    selectedBusinessUnitRecord: selectedBusinessUnitRecordUpdate,
    inputEmailWareHouse: "",
  });
}

function onRemoveEmailWareHouseSelected(state, action) {
  const selectedWarehouseEmail = state.selectedWarehouseEmail;

  let currentRecord = state.currentRecord;
  let { businessUnitConfigurations = [] } = state.currentRecord;

  let selectedBusinessUnitRecordUpdate = null;
  businessUnitConfigurations.forEach((item) => {
    if (item.businessUnit == state.selectedBusinessUnitRecord.businessUnit && item.sigla == state.selectedBusinessUnitRecord.sigla) {
      let indexOf = item.businessUnitParams.warehouseEmail.indexOf(
        selectedWarehouseEmail.email
      );
      if (indexOf > -1) {
        item.businessUnitParams.warehouseEmail.splice(indexOf, 1);
        selectedBusinessUnitRecordUpdate = Object.assign({}, item);
        return;
      }
    }
  });

  currentRecord = Object.assign({}, currentRecord, {
    businessUnitConfigurations,
  });

  return Object.assign({}, state, {
    currentRecord,
    selectedBusinessUnitRecord: selectedBusinessUnitRecordUpdate,
    selectedWarehouseEmail: null,
  });
}

function onChangeCarrierRecordSelect(state, action) {
  const selectedCarrierRecord = action.selectedCarrierRecord;
  return Object.assign({}, state, { selectedCarrierRecord });
}

function onRemoveCarrierSelected(state, action) {

  const selectedCarrierRecord = state.selectedCarrierRecord;
  const selectedBusinessUnitRecord = state.selectedBusinessUnitRecord;

  let currentRecord = state.currentRecord;
  let { businessUnitConfigurations = [] } = currentRecord;

  let selectedCarrierRecordUpdate = null;
  businessUnitConfigurations.forEach((item) => {
    if (item.carrierConfigurations) {
      let indexOf = -1;
      for (let index = 0; index < item.carrierConfigurations.length; index++) {
        if (item.carrierConfigurations[index].carrier == selectedCarrierRecord.carrier) {
          if (item.businessUnit == selectedBusinessUnitRecord.businessUnit && item.sigla == selectedBusinessUnitRecord.sigla) {
            indexOf = index;
            break;
          }
        }
      }
      if (indexOf > -1) {
        item.carrierConfigurations.splice(indexOf, 1);
        selectedCarrierRecordUpdate = Object.assign({}, item);
        return;
      }
    }
  });

  currentRecord = Object.assign({}, currentRecord, {
    businessUnitConfigurations,
  });

  return Object.assign({}, state, {
    currentRecord,
    selectedCarrierRecord: selectedCarrierRecordUpdate,
  });
}

function setLookupCarrierSetVisible(state, action) {
  let lookupCarrier = state.lookupCarrier;
  lookupCarrier = Object.assign({}, lookupCarrier, { visible: action.visible });
  return Object.assign({}, state, { lookupCarrier });
}

function setLookupCarrierSelected(state, action) {
  let carrierSelected = action.carrierSelected;
  return Object.assign({}, state, { carrierSelected });
}

function setLookupCarrierInputChange(state, action) {
  let carrierSelected = state.carrierSelected;
  carrierSelected = Object.assign({}, carrierSelected, {
    name: action.personalName
  });
  return Object.assign({}, state, { carrierSelected });
}

function addNewCarrierSelected(state, action) {
  let carrierSelected = state.carrierSelected;
  let selectedBusinessUnitRecord = state.selectedBusinessUnitRecord;

  let currentRecord = state.currentRecord;
  let { businessUnitConfigurations = [] } = state.currentRecord;

  businessUnitConfigurations.forEach((item) => {
    if (item.businessUnit == selectedBusinessUnitRecord.businessUnit &&
      item.sigla == state.selectedBusinessUnitRecord.sigla) {
      if (!item.carrierConfigurations) item.carrierConfigurations = [];

      let carrierExist = item.carrierConfigurations.filter(
        (c) => c.carrier == carrierSelected.documentNumber
      );
      //para nao permitir registros duplicados
      if (carrierExist.length === 0) {
        item.carrierConfigurations.push({
          carrier: carrierSelected.documentNumber,
          name: carrierSelected.name,
          carrierParams: {},
          label: state.labelCarrier,
        });
        selectedBusinessUnitRecord = Object.assign({}, item);
      }
    }
  });

  currentRecord = Object.assign({}, currentRecord, {
    businessUnitConfigurations,
  });

  return Object.assign({}, state, {
    currentRecord,
    selectedBusinessUnitRecord: selectedBusinessUnitRecord,
    carrierSelected: null,
    labelCarrier: ""
  });
}

function inputEmailCarrierChangeHandler(state, action) {
  return Object.assign({}, state, { inputEmailCarrier: action.email });
}

function addNewEmailCarrier(state, action) {
  const inputEmailCarrier = state.inputEmailCarrier
    ? state.inputEmailCarrier.trim()
    : "";
  const selectedCarrierRecord = state.selectedCarrierRecord;

  let currentRecord = state.currentRecord;
  let { businessUnitConfigurations = [] } = state.currentRecord;

  //nao add duplicados
  let selectedBusinessUnitRecordUpdate = Object.assign(
    {},
    state.selectedBusinessUnitRecord
  );
  businessUnitConfigurations.forEach((item) => {
    if (item.businessUnit == state.selectedBusinessUnitRecord.businessUnit &&
      item.sigla == state.selectedBusinessUnitRecord.sigla) {
      if (!item.carrierConfigurations) {
        item.carrierConfigurations = [];
      }

      //pecorrer os carriers
      let isAdd = false;
      item.carrierConfigurations.forEach((c) => {
        if (c.carrier == selectedCarrierRecord.carrier) {
          if (!c.carrierParams) {
            c.carrierParams = { email: [] };
          }
          if (!c.carrierParams.email) {
            c.carrierParams.email = [];
          }

          if (c.carrierParams.email.indexOf(inputEmailCarrier) === -1) {
            c.carrierParams.email.push(inputEmailCarrier);
            selectedBusinessUnitRecordUpdate = Object.assign({}, item);
            isAdd = true;
            return;
          }
        }
      });

      //uma vez add pode sai do loop
      if (isAdd) return;
    }
  });

  currentRecord = Object.assign({}, currentRecord, {
    businessUnitConfigurations,
  });

  return Object.assign({}, state, {
    currentRecord,
    selectedBusinessUnitRecord: selectedBusinessUnitRecordUpdate,
    inputEmailCarrier: "",
  });
}

function onSelectedEmailCarrierRecordChange(state, action) {
  const selectedCarrierEmail = action.selectedCarrierEmail;
  return Object.assign({}, state, { selectedCarrierEmail });
}

function onRemoveEmailcarrierSelected(state, action) {
  const selectedCarrierEmail = state.selectedCarrierEmail;
  const selectedCarrierRecord = state.selectedCarrierRecord;

  let lista = selectedCarrierRecord.carrierParams.email.filter(
    (e) => e !== selectedCarrierEmail.email
  );
  selectedCarrierRecord.carrierParams.email = lista;

  return Object.assign({}, state, {
    selectedCarrierRecord,
    selectedCarrierEmail: null,
  });
}


function addNewCarrierEquipTypeSelected(state, action) {
  let carrierEquipTypeSelected = state.carrierEquipTypeSelected;

  let currentRecord = state.currentRecord;
  let { integrationConfigurations = {} } = state.currentRecord;
  let { carrierEquipmentType = [] } = integrationConfigurations;

  let index = 0;
  let existsCarrier = false
  for (; index < carrierEquipmentType.length; index++) {
    if (carrierEquipmentType[index].documentNumber == carrierEquipTypeSelected.documentNumber) {
      existsCarrier = true
      break
    }
  }

  //para nao permitir registros duplicados
  if (!existsCarrier) {
    carrierEquipmentType.push({
      documentNumber: carrierEquipTypeSelected.documentNumber,
      name: carrierEquipTypeSelected.name,
      equipmentTypes: []
    });
  }

  integrationConfigurations = Object.assign({}, integrationConfigurations, { carrierEquipmentType })
  currentRecord = Object.assign({}, currentRecord, { integrationConfigurations });

  return Object.assign({}, state, {
    currentRecord,
    carrierEquipTypeSelected: null,
  });
}

function setLookupCarrierEquipTypeSelected(state, action) {
  let carrierEquipTypeSelected = action.carrierEquipTypeSelected;
  return Object.assign({}, state, { carrierEquipTypeSelected });
}

function setLookupCarrierEquipTypeSetVisible(state, action) {
  let lookupCarrierEquipType = state.lookupCarrierEquipType;
  lookupCarrierEquipType = Object.assign({}, lookupCarrierEquipType, { visible: action.visible });
  return Object.assign({}, state, { lookupCarrierEquipType });
}

function onChangeCarrierEquipTypeRecordSelect(state, action) {
  const selectedCarrierEquipTypeRecord = action.selectedCarrierEquipTypeRecord;
  return Object.assign({}, state, { selectedCarrierEquipTypeRecord });
}

function setLookupEquipmentVisible(state, action) {
  let lookupEquipment = state.lookupEquipment;
  lookupEquipment = Object.assign({}, lookupEquipment, {
    visible: action.visible,
  });
  return Object.assign({}, state, { lookupEquipment });
}

function setEquipmentType(state, action) {
  let equipmentType = state.equipmentType;
  equipmentType = action.equipmentType;
  return Object.assign({}, state, { equipmentType });
}


function addNewEquipType(state, action) {
  let selectedCarrierEquipTypeRecord = state.selectedCarrierEquipTypeRecord;
  let equipmentType = state.equipmentType
  let equipmentTypes = selectedCarrierEquipTypeRecord.equipmentTypes || []
  const exists = equipmentTypes.filter((e) => e.oid == equipmentType.oid)

  if (exists.length == 0) {
    equipmentTypes.push({ oid: equipmentType.oid, name: equipmentType.name, customFields: {} })
    selectedCarrierEquipTypeRecord.equipmentTypes = equipmentTypes
  }

  return Object.assign({}, state, { selectedCarrierEquipTypeRecord, equipmentType: null });
}


function onRemoveCarrierEquipTypeRecord(state, action) {
  const selectedCarrierEquipTypeRecord = state.selectedCarrierEquipTypeRecord;
  let currentRecord = state.currentRecord;

  let { integrationConfigurations = {} } = state.currentRecord;
  let { carrierEquipmentType = [] } = integrationConfigurations;

  const list = carrierEquipmentType.filter((q) => q.documentNumber != selectedCarrierEquipTypeRecord.documentNumber)

  carrierEquipmentType = list;

  integrationConfigurations = Object.assign({}, integrationConfigurations, { carrierEquipmentType })
  currentRecord = Object.assign({}, currentRecord, { integrationConfigurations });

  return Object.assign({}, state, {
    currentRecord,
    selectedCarrierEquipTypeRecord: null,
  });
}

function setVisibliDialogCustomFieldsEquipType(state, action) {
  let dialogCustomFieldsEquipTypeVisible = state.dialogCustomFieldsEquipTypeVisible;
  dialogCustomFieldsEquipTypeVisible = action.visible
  return Object.assign({}, state, { dialogCustomFieldsEquipTypeVisible });
}

function setEquipTypeSelected(state, action) {
  const equipTypeSelected = action.equipTypeSelected;
  return Object.assign({}, state, { equipTypeSelected });
}

function onChangeEquipTypeCustomFields(state, action) {
  const equipTypeSelected = action.equipTypeSelected;
  return Object.assign({}, state, { equipTypeSelected });
}

function setSelectedEquipTypeRecord(state, action) {
  const selectedEquipTypeRecord = action.selectedEquipTypeRecord;
  return Object.assign({}, state, { selectedEquipTypeRecord });
}
function onRemoveSelectedEquipTypeRecord(state, action) {

  const selectedCarrierEquipTypeRecord = state.selectedCarrierEquipTypeRecord;
  const selectedEquipTypeRecord = state.selectedEquipTypeRecord
  const list = selectedCarrierEquipTypeRecord.equipmentTypes.filter((e) => e.oid != selectedEquipTypeRecord.oid)
  selectedCarrierEquipTypeRecord.equipmentTypes = list

  return Object.assign({}, state, {
    selectedCarrierEquipTypeRecord,
    selectedEquipTypeRecord: null,
  });


}

