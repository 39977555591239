
import React from 'react';
import { InputText } from 'primereact/inputtext';
import { LookupField } from '../../../components/lookup/LookupField';
import { LookupTable } from '../../../components/lookup/LookupTable';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import FileUploaderButton from '../../../components/file-uploader-button/FileUploaderButton';

export const RegionForm = (props) => {
    const record = props.record;
    const addLocationsDisabled = false;
    const addRestrictionsDisabled = false;
    return (
        <div className="region-form">
            <div className="p-grid">
                <div className="p-col-6">
                    <div className="p-grid">

                        <div className="p-col-6 form-field-container">
                            <label>Código</label>
                            <InputText value={props.record.oid} onChange={props.changeHandler} name="oid" disabled={true} />
                        </div>
                        <div className="p-col-12 form-field-container">
                            <label className="lxRequiredField">Nome</label>
                            <InputText value={props.record.name} onChange={props.changeHandler} name="name" disabled={false} />
                        </div>
                        <div className="p-col-12 form-field-container">
                            <label className="lxRequiredField">Transportadora</label>
                            <LookupField                                
                                value={record.carrier ? record.carrier.name:""}
                                placeholder="Transportadora"
                                name="carrier.name"
                                field="name"
                                onComplete={props.lookupCarrierOnComplete}
                                onInputFilter={props.lookupCarrierOnInputFilter}
                                editDisabled={props.lookupCarrier.editDisabled}
                                onEditField={props.lookupCarrierOnEdit}
                                onClick={props.lookupCarrierOnClick}

                                visible={props.lookupCarrier.visible}
                                modal={props.lookupCarrier.modal}
                                header={props.lookupCarrier.header}
                                onHide={props.lookupCarrierOnHide}
                                onConfirm={props.lookupCarrierOnConfirm}
                                onCancel={props.lookupCarrierOnCancel}
                                records={props.lookupCarrier.records}
                                columns={props.lookupCarrier.columns}
                                totalRecords={props.lookupCarrier.totalRecords}
                                sortOrder={props.lookupCarrier.order}
                                sortField={props.lookupCarrier.sortField}
                                first={props.lookupCarrier.first}
                                rows={props.lookupCarrier.rows}
                                onPage={props.lookupCarrierOnPage}
                                onFilter={props.lookupCarrierOnFilter}
                                onSort={props.lookupCarrierOnOnSort}

                            />
                        </div>
                        <div className="p-col-6">
                            <button
                                title="Adicionar localizações"
                                onClick={props.dialogAddLocationsOnOpen}
                                style={{ marginRight: ".25em" }}
                                className="lognex-btn-highlighted">
                                <i className="pi pi-plus"></i>
                            </button>
                            <button
                                title="Remover localizações selecionadas"
                                onClick={props.onRemoveSelectedLocations}
                                style={{ marginRight: ".25em", marginBottom: ".25em" }}
                                className="lognex-btn-danger">
                                <i className="pi pi-times"></i>
                            </button>
                            <FileUploaderButton 
                                buttonClass="lognex-btn-highlighted" 
                                accept=".xlsx,.xls" 
                                title="Importar localizações"
                                onUpload={props.onUploadLocalizations}
                            ></FileUploaderButton>

                            <label className="lxRequiredField">Localizações</label>
                                <Dialog
                                    header={props.dialogAddLocations.header}
                                    visible={props.dialogAddLocations.visible}
                                    style={{ width: '50vw' }}
                                    modal={props.dialogAddLocations.modal}
                                    baseZIndex={1}
                                    onHide={props.dialogAddLocationsOnClose}
                                    footer={
                                        <div>
                                            <button
                                                title="Adicionar localização"
                                                onClick={props.dialogAddLocationsOnConfirm}
                                                style={{ marginRight: ".25em" }}
                                                className="lognex-btn-highlighted"
                                                disabled={addLocationsDisabled}>
                                                <i className="pi pi-check"></i>
                                            </button>
                                            <button
                                                title="Incluir localização"
                                                onClick={props.dialogAddLocationsOnAdd}
                                                style={{ marginRight: ".25em" }}
                                                className="lognex-btn-highlighted"
                                                disabled={addLocationsDisabled}>
                                                <i className="pi pi-plus"></i>
                                            </button>
                                            <button
                                                title="Cancelar"
                                                onClick={props.dialogAddLocationsOnClose}
                                                style={{ marginRight: ".25em" }}
                                                className="lognex-btn-danger">
                                                <i className="pi pi-times"></i>
                                            </button>
                                        </div>}
                                >
                                <div className="p-col-6 form-field-container">
                                    <label>Tipo</label>
                                    <Dropdown value={props.locationFilterType} onChange={props.changeLocationFilterTypeHandler} name="type" options={props.types} style={{width:"auto"}} ></Dropdown>
                                </div>
                                <div className="p-col-12 form-field-container">
                                    <label>Pertence a</label>
                                    <LookupField
                                        value={props.record.parent ? props.record.parent : null}
                                        placeholder="Pertence a"
                                        name="parent.name"
                                        field="name"
                                        onComplete={props.lookupParentOnComplete}
                                        onInputFilter={props.lookupParentOnInputFilter}
                                        editDisabled={props.lookupParent.editDisabled}
                                        onEditField={props.lookupParentOnEdit}
                                        onClick={props.lookupParentOnClick}

                                        visible={props.lookupParent.visible}
                                        modal={props.lookupParent.modal}
                                        header={props.lookupParent.header}
                                        onHide={props.lookupParentOnHide}
                                        onConfirm={props.lookupParentOnConfirm}
                                        onCancel={props.lookupParentOnCancel}
                                        records={props.lookupParent.records}
                                        columns={props.lookupParent.columns}
                                        totalRecords={props.lookupParent.totalRecords}
                                        sortOrder={props.lookupParent.order}
                                        sortField={props.lookupParent.sortField}
                                        first={props.lookupParent.first}
                                        rows={props.lookupParent.rows}
                                        onPage={props.lookupParentOnPage}
                                        onFilter={props.lookupParentOnFilter}
                                        onSort={props.lookupParentOnOnSort}
                                        disabled={props.lookupParent.disabled}
                                    />
                                </div>
                                <div className="p-col-12 form-field-container">
                                    <label>Localizações</label>
                                    <LookupTable
                                        value={props.lookupLocation.searchText}
                                        placeholder="Localização"
                                        name="props.lookupLocation.searchText"
                                        field="name"
                                        onComplete={props.lookupLocationOnComplete}
                                        onInputFilter={props.lookupLocationOnInputFilter}
                                        editDisabled={props.lookupLocation.editDisabled}
                                        //onEditField={props.lookupLocationOnEdit}
                                        onClick={props.lookupLocationOnClick}
                                        visible={props.lookupLocation.visible}
                                        modal={props.lookupLocation.modal}
                                        header={props.lookupLocation.header}
                                        onHide={props.lookupLocationOnHide}
                                        onConfirm={props.lookupLocationOnConfirm}
                                        onCancel={props.lookupLocationOnCancel}
                                        records={props.lookupLocation.locationRecords}
                                        columns={props.lookupLocation.columns}
                                        totalRecords={props.lookupLocation.totalRecords}
                                        sortOrder={props.lookupLocation.order}
                                        sortField={props.lookupLocation.sortField}
                                        first={props.lookupLocation.first}
                                        rows={props.lookupLocation.rows}
                                        onPage={props.lookupLocationOnPage}
                                        onFilter={props.lookupLocationOnFilter}
                                        onSort={props.lookupLocationOnOnSort}
                                        onTableSelectionChange={props.lookupLocationOnSelectLocation}
                                        onRemoveSelection={props.lookupLocationOnRemoveSelected}
                                        tableRecords={props.lookupLocation.records}
                                        tableSelection={props.lookupLocation.selectedRecords}
                                        tableColumns={props.lookupLocation.tableColumns}
                                        scrollable={true}
                                        scrollHeight="400px"
                                        paginator={true}
                                        rowsPerPageOptions={[5,10,50,100,200,500,1000]}


                                    />
                                </div>
                            </Dialog>

                            <DataTable
                                selection={props.selectedLocations}
                                onSelectionChange={props.locationsOnSelectionChange}
                                onFilter={props.onFilterLocations}
                                onSort={props.onSortLocations}
                                onPage={props.onPageLocations}
                                value={props.locationsRecords}
                                lazy={true}
                                totalRecords={props.locationsTotalRecords}
                                sortOrder={props.locationsOrder}
                                sortField={props.locationsSortField}
                                first={props.locationsFirst}
                                rows={props.locationsRows}
                                emptyMessage="Não foram encontrados registros"
                                scrollable={true}
                                scrollHeight="400px"
                            >

                                {props.locationsColumns}
                            </DataTable>
                        </div>
                        <div className="p-col-6">
                            <button
                                title="Adicionar restrições"
                                onClick={props.dialogAddRestrictionsOnOpen}
                                style={{ marginRight: ".25em" }}
                                className="lognex-btn-highlighted">
                                <i className="pi pi-plus"></i>
                            </button>
                            <button
                                title="Remover restrições selecionadas"
                                onClick={props.onRemoveSelectedRestrictions}
                                style={{ marginRight: ".25em", marginBottom: ".25em" }}
                                className="lognex-btn-danger">
                                <i className="pi pi-times"></i>
                            </button>
                            <FileUploaderButton 
                                buttonClass="lognex-btn-highlighted" 
                                accept=".xlsx,.xls" 
                                title="Importar restrições"
                                onUpload={props.onUploadRestrictions}
                            ></FileUploaderButton>

                            Restrições
                    <Dialog
                                header={props.dialogAddRestrictions.header}
                                visible={props.dialogAddRestrictions.visible}
                                style={{ width: '50vw' }}
                                modal={props.dialogAddRestrictions.modal}
                                baseZIndex={1}
                                onHide={props.dialogAddRestrictionsOnClose}
                                footer={
                                    <div>
                                        <button
                                            title="Adicionar restrição"
                                            onClick={props.dialogAddRestrictionsOnConfirm}
                                            style={{ marginRight: ".25em" }}
                                            className="lognex-btn-highlighted"
                                            disabled={addRestrictionsDisabled}>
                                            <i className="pi pi-check"></i>
                                        </button>
                                        <button
                                            title="Incluir restrição"
                                            onClick={props.dialogAddRestrictionsOnAdd}
                                            style={{ marginRight: ".25em" }}
                                            className="lognex-btn-highlighted"
                                            disabled={addRestrictionsDisabled}>
                                            <i className="pi pi-plus"></i>
                                        </button>
                                        <button
                                            title="Cancelar"
                                            onClick={props.dialogAddRestrictionsOnClose}
                                            style={{ marginRight: ".25em" }}
                                            className="lognex-btn-danger">
                                            <i className="pi pi-times"></i>
                                        </button>

                                    </div>}>

                                <div className="p-col-6 form-field-container">
                                    <label>Tipo</label>
                                    <Dropdown value={props.locationFilterType} onChange={props.changeLocationFilterTypeHandler} name="type" options={props.types} style={{width:"auto"}}></Dropdown>
                                </div>
                                <div className="p-col-12 form-field-container">
                                    <label>Pertence a</label>
                                    <LookupField
                                        value={props.record.parent ? props.record.parent : null}
                                        placeholder="Pertence a"
                                        name="parent.name"
                                        field="name"
                                        onComplete={props.lookupParentOnComplete}
                                        onInputFilter={props.lookupParentOnInputFilter}
                                        editDisabled={props.lookupParent.editDisabled}
                                        onEditField={props.lookupParentOnEdit}
                                        onClick={props.lookupParentOnClick}

                                        visible={props.lookupParent.visible}
                                        modal={props.lookupParent.modal}
                                        header={props.lookupParent.header}
                                        onHide={props.lookupParentOnHide}
                                        onConfirm={props.lookupParentOnConfirm}
                                        onCancel={props.lookupParentOnCancel}
                                        records={props.lookupParent.records}
                                        columns={props.lookupParent.columns}
                                        totalRecords={props.lookupParent.totalRecords}
                                        sortOrder={props.lookupParent.order}
                                        sortField={props.lookupParent.sortField}
                                        first={props.lookupParent.first}
                                        rows={props.lookupParent.rows}
                                        onPage={props.lookupParentOnPage}
                                        onFilter={props.lookupParentOnFilter}
                                        onSort={props.lookupParentOnOnSort}
                                        disabled={props.lookupParent.disabled}
                                    />
                                </div>

                                <div className="p-col-12 form-field-container">
                                    <label>Restrições</label>
                                    <LookupTable
                                        value={props.lookupRestriction.searchText}
                                        placeholder="Restrição"
                                        name="record.currentRestriction.name"
                                        field="name"
                                        onComplete={props.lookupRestrictionOnComplete}
                                        onInputFilter={props.lookupRestrictionOnInputFilter}
                                        editDisabled={props.lookupRestriction.editDisabled}
                                        //onEditField={props.lookupRestrictionOnEdit}
                                        onClick={props.lookupRestrictionOnClick}
                                        visible={props.lookupRestriction.visible}
                                        modal={props.lookupRestriction.modal}
                                        header={props.lookupRestriction.header}
                                        onHide={props.lookupRestrictionOnHide}
                                        onConfirm={props.lookupRestrictionOnConfirm}
                                        onCancel={props.lookupRestrictionOnCancel}
                                        records={props.lookupRestriction.locationRecords}
                                        columns={props.lookupRestriction.columns}
                                        totalRecords={props.lookupRestriction.totalRecords}
                                        sortOrder={props.lookupRestriction.order}
                                        sortField={props.lookupRestriction.sortField}
                                        first={props.lookupRestriction.first}
                                        rows={props.lookupRestriction.rows}
                                        onPage={props.lookupRestrictionOnPage}
                                        onFilter={props.lookupRestrictionOnFilter}
                                        onSort={props.lookupRestrictionOnOnSort}
                                        onTableSelectionChange={props.lookupRestrictionOnSelectLocation}
                                        onRemoveSelection={props.lookupRestrictionOnRemoveSelected}
                                        tableRecords={props.lookupRestriction.records}
                                        tableSelection={props.lookupRestriction.selectedRecords}
                                        tableColumns={props.lookupRestriction.tableColumns}
                                        scrollable={true}
                                        scrollHeight="400px"
                                        paginator={true}
                                        rowsPerPageOptions={[5,10,50,100,200,500,1000]}
                                    />
                                </div>
                            </Dialog>

                            <DataTable

                                selection={props.selectedRestrictions}
                                onSelectionChange={props.restrictionsOnSelectionChange}
                                onFilter={props.onFilterRestrictions}
                                onSort={props.onSortRestrictions}
                                onPage={props.onPageRestrictions}
                                value={props.restrictionsRecords}
                                lazy={true}
                                totalRecords={props.restrictionsTotalRecords}
                                sortOrder={props.restrictionsOrder}
                                sortField={props.restrictionsSortField}
                                
                                first={props.restrictionsFirst}
                                rows={props.restrictionsRows}
                                emptyMessage="Não foram encontrados registros"
                                scrollable={true}
                                scrollHeight="400px"
                            >

                                {props.locationsColumns}
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};