import React from "react";
import { Dialog } from 'primereact/dialog';
import { LookupField } from '../../../components/lookup/LookupField';

export const LxDialogLookup = (props) => {
    return (
        <div className="p-grid">
            <div className="p-col-12">
                <Dialog
                    
                    style={{ width: '50vw' }}
                    baseZIndex={1}
                    header={props.dialog && props.dialog.header ? props.dialog.header : ""}
                    visible={props.dialog ? props.dialog.visible : false}
                    modal={props.dialog  ? props.dialog.modal : false}
                    onHide={props.dialog && props.dialog.actions && props.dialog.actions.onHide ? props.dialog.actions.onHide : () => {console.log("dialog.onHide must be setted")}}
                    footer={
                        <div>
                            <button
                                title="Confirmar"
                                className={props.dialog && props.dialog.confirmButtonDisabled ? "" : "lognex-btn-highlighted"}
                                onClick={props.dialog && props.dialog.actions && props.dialog.actions.onConfirm ? props.dialog.actions.onConfirm : () => {console.log("dialog.onConfirm must be setted")}}
                                disabled={props.dialog ? props.dialog.confirmButtonDisabled : true} >
                                <i className="pi pi-check"></i>
                            </button>
                            <button
                                title="Incluir"
                                className={props.dialog && props.dialog.addButtonDisabled ? "" : "lognex-btn-highlighted"}
                                onClick={props.dialog && props.dialog.actions && props.dialog.actions.onAdd ? props.dialog.actions.onAdd : () => {console.log("dialog.onAdd must be setted")}}
                                disabled={props.dialog ? props.dialog.addButtonDisabled : true} >
                                <i className="pi pi-plus"></i>
                            </button>
                            <button
                                title="Cancelar"
                                className="lognex-btn-danger"
                                onClick={props.dialog && props.dialog.actions && props.dialog.actions.onHide ? props.dialog.actions.onHide : () => {console.log("dialog.onHide must be setted")}} >
                                <i className="pi pi-times"></i>
                            </button>
                        </div>}>

                    <div className="p-col-12 form-field-container">
                        <label className="lxRequiredField">{props.lookup ? props.lookup.title : ""}</label>
                        <LookupField
                            
                            // * Lookup props
                            editDisabled={props.lookup ? props.lookup.editDisabled : true}
                            visible={props.lookup ? props.lookup.visible : false}
                            modal={props.lookup ? props.lookup.modal : false}
                            header={props.lookup && props.lookup.header ? props.lookup.header : ""}
                            records={props.lookup && props.lookup.records ? props.lookup.records : []}
                            columns={props.lookup && props.lookup.columns ? props.lookup.columns : null}
                            sortOrder={props.lookup && props.lookup.order ? props.lookup.order : ""}
                            sortField={props.lookup && props.lookup.sortField ? props.lookup.sortField : ""}
                            first={props.lookup && props.lookup.first ? props.lookup.first : 0}
                            rows={props.lookup && props.lookup.rows ? props.lookup.rows : 0}
                            totalRecords={props.lookup && props.lookup.totalRecords ? props.lookup.totalRecords : 0}
                            value={props.lookup ? props.lookup.value : null}
                            placeholder={props.lookup && props.lookup.placeholder ? props.lookup.placeholder : ""}
                            name={props.lookup && props.lookup.name ? props.lookup.name : ""}
                            field={props.lookup && props.lookup.field ? props.lookup.field : ""}
                            // * Lookup actions
                            onComplete={props.lookup && props.lookup.actions && props.lookup.actions.onComplete ? props.lookup.actions.onComplete : () => {console.log("lookup.OnComplete must be setted")}}
                            onInputFilter={props.lookup && props.lookup.actions && props.lookup.actions.onInputFilter ? props.lookup.actions.onInputFilter : () => {console.log("lookup.onInputFilter must be setted")}}                       
                            onEditField={props.lookup && props.lookup.actions && props.lookup.actions.onEditField ? props.lookup.actions.onEditField : () => {console.log("lookup.onEditField must be setted")}}
                            onClick={props.lookup && props.lookup.actions && props.lookup.actions.onClick ? props.lookup.actions.onClick : () => {console.log("lookup.onClick must be setted")}}
                            onHide={props.lookup && props.lookup.actions && props.lookup.actions.onHide ? props.lookup.actions.onHide : () => {console.log("lookup.onHide must be setted")}}
                            onConfirm={props.lookup && props.lookup.actions && props.lookup.actions.onConfirm ? props.lookup.actions.onConfirm : () => {console.log("lookup.onConfirm must be setted")}}
                            onCancel={props.lookup && props.lookup.actions && props.lookup.actions.onCancel ? props.lookup.actions.onCancel : () => {console.log("lookup.onCancel must be setted")}}
                            onPage={props.lookup && props.lookup.actions && props.lookup.actions.onPage ? props.lookup.actions.onPage : () => {console.log("lookup.onPage must be setted")}}
                            onFilter={props.lookup && props.lookup.actions && props.lookup.actions.onFilter ? props.lookup.actions.onFilter : () => {console.log("lookup.onFilter must be setted")}}
                            onSort={props.lookup && props.lookup.actions && props.lookup.actions.onSort ? props.lookup.actions.onSort : () => {console.log("lookup.onSort must be setted")}}
                        />
                    </div>
                </Dialog>
            </div>
        </div>
    );
}
