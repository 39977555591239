import React from 'react';
import { Password } from 'primereact/password';

export const PasswordConfigurationsComponent = (props) => {
    return (
        <div className="p-grid">
            <div className="p-col-4">{props.data.title}</div>
            <div className="p-col-2 p-justify-center">
                <div className="field col-12 md:col-4">
                    <Password
                        value={props.data.value}
                        onChange={props.data.onChange}
                        id={props.data.id}
                        name={props.data.name}
                    ></Password>
                </div>
            </div>
        </div>
    )
} 