import React from 'react';
import DriverListPageContainer from './DriverListPageContainer';

export class DriverListPage extends React.Component{
    
    render(){
      return (
        <DriverListPageContainer {...this.props}/>
      )
    }
}