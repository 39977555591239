import React from "react";
import { Chart } from "react-google-charts";
import { Dialog } from "primereact/dialog";
import moment from "moment";
import "./grafico-temp-hora.scss";

export class GraficoTempHora extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() { }

  processData = (pathTraveled) => {
    // Agrupa dados por dia e hora
    const grouped = {};
    pathTraveled.forEach(item => {
      if (item.temperatura1) {
        const datePosit = moment(item.datePosition);
        const key = `${datePosit.format('YYYY-MM-DD')} ${datePosit.hour()}h`;

        if (!grouped[key]) {
          grouped[key] = { temperatura: [], velocidade: [] };
        }

        grouped[key].temperatura.push(item.temperatura1);
        grouped[key].velocidade.push(Number(item.velocidade));
      }
    });

    // Calcula médias
    const averagedData = [["Hora", "Temperatura Média", "Velocidade Média"]];
    Object.keys(grouped).forEach((key) => {
      const temps = grouped[key].temperatura;
      const speeds = grouped[key].velocidade;
      const tempAvg = temps.reduce((a, b) => a + b, 0) / temps.length;
      const speedAvg = speeds.reduce((a, b) => a + b, 0) / speeds.length; 
      averagedData.push([key, tempAvg, speedAvg]);
    });

    return averagedData;
  }

  render() {
    const { travel, visible, onHide } = this.props;

    const data = travel && travel.pathTraveled && travel.pathTraveled.length > 0 ? this.processData(travel.pathTraveled) : [["Hora", "Temperatura Média", "Velocidade Média"]];

    const options = {
      title: "Temperatura e Velocidade por Hora",
      curveType: "function",
      legend: { position: "bottom" },
    };

    return (
      <Dialog visible={visible} modal={false} onHide={onHide}>
        <Chart
          chartType="LineChart"
          width="1100px"
          height="600px"
          data={data}
          options={options}
        />
      </Dialog>
    );
  }
}
