import moment from "moment";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import React from "react";
import { connect } from "react-redux";
import { ButtonDialog } from "../../../components/button-dialog/ButtonDialog";
import { Page } from "../../../components/page/Page";
import { Totalizer } from "../../../components/totalizer/Totalizer";
import { getValue } from "../../../i18n";
import {
  AppService,
  BUSINESS_UNIT_CHANGE_EVENT_NAME,
  COMPANY_CHANGE_EVENT_NAME
} from "../../../services/app.service";
import { actions as appActions } from "../../../store/app";
import * as menuActions from "../../../store/menu/menu.actions";
import * as utils from "../../../utils";
import { actions as accountConfigurationActions } from "../../accountConfiguration/accountConfiguration.actions";
import { actions as carrierActions } from "../../carrier/carrier.actions";
import { actions } from "../audit.actions";
import { AuditDetail } from "../components/AuditDetail";
import { AuditFilter } from "../components/AuditFilter";
import { CTeTable } from "../components/CTeTable";
import { InvoiceTable } from "../components/InvoiceTable/InvoiceTable";
import "./AuditListPageContainer.scss";

let cteTableFilterTimer = null;
let cteTimer = null;
let invoiceTableFilterTimer = null;
let timer = null;

const mapStateToProps = store => {
  const lookupCarrierBase = {
    records: store.crudCarrierState.records,
    columFields: store.crudCarrierState.columns,
    columns: store.auditState.auditFilter.lookupCarrier.visible
      ? store.crudCarrierState.columns
        .filter(
          col => col.field === "oid" || col.field === "tradeName" || col.field === "name"
        )
        .map((col, index) => <Column key={index} {...col} />)
      : [<Column key={"columnKeyCarrier"} />],
    first: store.crudCarrierState.index,
    rows: store.crudCarrierState.max,
    totalRecords: store.crudCarrierState.count,
    sortField: store.crudCarrierState.sortField,
    order: store.crudCarrierState.sortOrder,
    visible: store.auditState.auditFilter.lookupCarrier.visible,
    rowsPerPageOptions:[20,50,100],
    editDisabled: true,
    modal: false
  };
  const lookupCarrier = Object.assign(
    {},
    store.auditState.auditFilter.lookupCarrier,
    lookupCarrierBase
  );

  const currentMenu = store.menuState.currentRecord;
  const userConfigurations = store.appState.userConfigurations;
  let isFavorite = utils.isFavorite(currentMenu, userConfigurations);

  const { auditConfiguration = { useManualInvoiceRelease: false } } = store.crudAccountConfigurationState.currentRecord;


  return {
    accordionActiveIndex:
      store.auditState.auditFilter.currentDocTypeFilter === "INVOICE"
        ? [0, 1]
        : [1], //será visivel se o currentDocTypeFilter for igual a 'INVOICE'
    docTypeOptions: store.auditState.auditFilter.docTypeFilterOptions,
    currentDocTypeFilter: store.auditState.auditFilter.currentDocTypeFilter,
    docStatusOptions: store.auditState.auditFilter.docStatusFilterOptions,
    docPendingsTypeOptions: store.auditState.auditFilter.docPendingsTypeFilterOptions,
    currentDocStatusFilter: store.auditState.auditFilter.currentDocStatusFilter,
    currentPendingsTypeFilter: store.auditState.auditFilter.currentPendingsTypeFilter || 'ALL',
    carrierFilter: store.auditState.auditFilter.carrierFilter,
    initialDateFilter: store.auditState.auditFilter.initialDateFilter,
    finalDateFilter: store.auditState.auditFilter.finalDateFilter,

    lookupCarrier,
    /* Invoice Table*/
    invoiceTbColumns: store.auditState.invoiceTableColumns,
    invoiceTbRecords: store.auditState.invoiceRecords,
    invoiceTbEmptyMessage: "Não foram encontrados registros para a pesquisa",
    invoiceTbTotalRecords: store.auditState.filterInvoiceTable.count,
    invoiceTbOrder: store.auditState.filterInvoiceTable.sortOrder,
    invoiceTbSortField: store.auditState.filterInvoiceTable.sortField,
    invoiceTbFirst: store.auditState.filterInvoiceTable.index,
    invoiceTbRows: store.auditState.filterInvoiceTable.max,


    /* audit detail */
    auditTbColumns: store.auditState.auditTbColumns,

    /* CTe Table */
    cteTbColumns: store.auditState.cteTableColumns,
    cteTbRecords: store.auditState.cteRecords,
    cteTbTotalRecords: store.auditState.filterCTeTable.count,
    cteTbOrder: store.auditState.filterCTeTable.sortOrder,
    cteTbSortField: store.auditState.filterCTeTable.sortField,
    cteTbFirst: store.auditState.filterCTeTable.index,
    cteTbRows: store.auditState.filterCTeTable.max,
    cteTbEmptyMessage: "Não foram encontrados registros para a pesquisa",
    expandedCTeRows: store.auditState.expandedCTeRows,
    /*NFe Modal*/
    nfeRecords: store.auditState.nfeRecords,
    dialogNFes: store.auditState.dialogNFes,
    nfesTbColumns: store.auditState.nfesTbColumns,
    /*Quote Modal */
    dialogQuote: store.auditState.dialogQuote,
    /*History Modal*/
    historyRecords: store.auditState.historyRecords,
    dialogHistory: store.auditState.dialogHistory,
    historyTbColumns: store.auditState.historyTbColumns,
    /*Log Modal*/
    logRecords: store.auditState.logRecords,
    dialogLogs: store.auditState.dialogLogs,
    logTbColumns: store.auditState.logTbColumns,
    dialogApproveComponent: store.auditState.dialogApproveComponent,
    currentApproveComponent: store.auditState.currentApproveComponent,
    dialogApproveInvoice: store.auditState.dialogApproveInvoice,
    currentCTe: store.auditState.currentCTe,
    currentInvoice: store.auditState.currentInvoice,
    menuItems: store.menuState.items,
    currentMenu,
    isFavorite,
    cteTotalizer: store.auditState.cteTotalizer,
    dateFilter: store.auditState.dateFilter,
    filters: store.auditState.filterCTeTable.filters,
    // filters: store.auditState.auditFilter,
    enableExportCSV: store.auditState.cteRecords && store.auditState.cteRecords.length > 0,
    auditConfiguration,
    listCtesAllAprove: store.auditState.listCtesAllAprove,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    docTypeChangeHandler: e => {
      dispatch(actions.setCurrentDocTypeFilter(e.value));
    },
    docStatusChangeHandler: e => {
      dispatch(actions.setCurrentDocStatusFilter(e.value));
    },
    onPendingsTypeChangeHandler: e => {
      dispatch(actions.setCurrentPendingsTypeFilter(e.value));
    },
    initialDateChangeHandler: e => {
      dispatch(actions.setInitialDateFilter(e.value));
    },
    finalDateChangeHandler: e => {
      dispatch(actions.setFinalDateFilter(e.value));
    },
    lookupCarrierSetVisible: visible =>
      dispatch(actions.setLookupCarrierVisible(visible)),
    lookupCarrierPaginate: paginationParams =>
      dispatch(carrierActions.paginate(paginationParams)),
    lookupCarrierSortOrder: sortParams =>
      dispatch(carrierActions.sortOrder(sortParams)),
    lookupCarrierApplyFilter: filterParams => {
      dispatch(carrierActions.setType(null));
      dispatch(carrierActions.applyFilter(filterParams));
    },
    lookupCarrierLoad: () => dispatch(carrierActions.load()),
    setCarrierFilter: carrier => {
      dispatch(actions.setCarrierFilter(carrier));
    },
    invoiceTbFilterHandler: (e) => {
      if (invoiceTableFilterTimer) {
        window.clearTimeout(invoiceTableFilterTimer);
      }
      invoiceTableFilterTimer = setTimeout(() => {
        const filters = [];
        const keys = Object.keys(e.filters) || [];
        keys.forEach(key => {
          filters.push({
            campo: key,
            valor: e.filters[key].value,
            tipoFiltro: "CONTEM"
          });
        });
        dispatch(actions.setFilterInvoiceTable({ filters }));
        dispatch(actions.loadInvoiceRecords()); //aqui tem que lembrar de adicionar o filtro por invoice também
      }, 400)
    },
    changeDateFilter: (e) => {
      if (e.value === "" || (e.value != null && typeof e.value === 'object')) {
        if (timer) {
          window.clearTimeout(timer);
        }
        timer = setTimeout(() => {

          const target = e.target;
          let filters = [];
          if (target.value || target.value === false) {
            filters = [{
              campo: `${target.name}`,
              valor: target.value,
              tipoFiltro: 'EQUALS'
            }];
          }
          dispatch(actions.setDateFilter(target.name, target.value))
          dispatch(actions.applyInvoiceFilter(filters))

        }, 400)
        console.log('novo timer criado ', timer);
      }
    },

    /*Invoice */
    setCurrentInvoiceRecord: invoice =>
      dispatch(actions.setCurrentInvoiceRecord(invoice)),
    loadCTesByInvoice: data => {
      const filters = [];
      filters.push({
        campo: "documents.content.number",
        // valor: data.number.replace(/^0+/, ''), //* Caso precise remover os zeros a esquerda
        valor: data.number,
        tipoFiltro: "CONTEM"
      });
      dispatch(actions.setFilterCTeTable({ filters }));
      dispatch(actions.loadCTeRecords());
    },
    invoiceTbSortHandler: e => {
      dispatch(
        actions.setFilterInvoiceTable({
          sortOrder: e.sortOrder,
          sortField: e.sortField
        })
      );
    },
    invoiceTbPageHandler: e => {
      dispatch(
        actions.setFilterInvoiceTable({
          max: e.rows,
          index: e.first
        })
      );
      dispatch(actions.loadInvoiceRecords());
    },
    loadInvoiceRecords: () => dispatch(actions.loadInvoiceRecords()),
    /*CTe Table */
    cteTbFilterHandler: e => {
      if (cteTableFilterTimer) {
        window.clearTimeout(cteTableFilterTimer);
      }
      cteTableFilterTimer = setTimeout(() => {
        const filters = [];
        const keys = Object.keys(e.filters) || [];
        keys.forEach(key => {
          filters.push({
            campo: key,
            valor: e.filters[key].value,
            tipoFiltro: "CONTEM"
          });
        });
        dispatch(actions.setFilterCTeTable({ filters }));
        dispatch(actions.loadCTeRecords()); //aqui tem que lembrar de adicionar o filtro por invoice também
      }, 400)

    },

    cteInvoiceHandler: e => {
      if (cteTimer) {
        window.clearTimeout(cteTimer);
      }
      cteTimer = setTimeout(() => {
        const filters = [];
        const keys = Object.keys(e.data.ctes) || [];
        keys.forEach(key => {
          let numero = e.data.ctes[key].numero;
          filters.push({
            campo: "numero",
            valor: numero.replace(/^0+/, ""),
            tipoFiltro: "CONTEM"
          });
        });
        dispatch(actions.setFilterCTeTable({ filters }));
        dispatch(actions.loadCTeRecords());
      }, 400);
    },

    cteTbSortHandler: e => {
      dispatch(
        actions.setFilterCTeTable({
          sortOrder: e.sortOrder,
          sortField: e.sortField
        })
      );
    },
    cteTbPageHandler: e => {
      dispatch(
        actions.setFilterCTeTable({
          max: e.rows,
          index: e.first
        })
      );
      dispatch(actions.loadCTeRecords()); //aqui tem que lembrar de adicionar o filtro por invoice também
    },
    loadCTeRecords: () => dispatch(actions.loadCTeRecords()),
    /* AuditDetail */
    showNFesHandler: idCTe => {
      dispatch(actions.loadNFeRecords(idCTe));
      dispatch(actions.setDialogNFesVisible(true));
    },
    hideNFesHandler: () => {
      dispatch(actions.setDialogNFesVisible(false));
    },
    showQuoteHandler: () => {
      dispatch(actions.setDialogQuoteVisible(true));
      dispatch(actions.loadQuoteRecord());
    },
    hideQuoteHandler: () => {
      dispatch(actions.setDialogQuoteVisible(false));
    },
    hideHistoryHandler: () => {
      dispatch(actions.setDialogHistoryVisible(false));
    },
    showHistoryHandler: idCTe => {
      dispatch(actions.loadHistoryRecords(idCTe));
      dispatch(actions.setDialogHistoryVisible(true));
    },

    setField: (field, value) => dispatch(actions.setField(field, value)),

    showApproveComponentHandler: e => {
      dispatch(actions.setDialogApproveComponentVisible(true));
    },
    hideApproveComponentHandler: e => {
      dispatch(actions.setDialogApproveComponentVisible(false));
    },

    setDialogApproveComponentType: approvalType => {
      dispatch(actions.setDialogApproveComponentType(approvalType));
    },
    setDialogApproveComponentAlertMessage: alertMessage => {
      dispatch(actions.setDialogApproveComponentAlertMessage(alertMessage));
    },

    currentApproveComponentDescriptionChangeHandler: e => {
      dispatch(actions.setCurrentApproveComponentDescription(e.target.value));
    },
    setCurrentApproveComponentIDCTe: idCTe => {
      dispatch(actions.setCurrentApproveComponentIDCTe(idCTe));
    },
    setCurrentApproveComponentLabelCTe: labelCTe => {
      dispatch(actions.setCurrentApproveComponentLabelCTe(labelCTe));
    },
    setCurrentApproveComponentDescription: description => {
      dispatch(actions.setCurrentApproveComponentDescription(description));
    },
    approveComponent: () => {
      dispatch(actions.approveComponent());
    },
    approveAllComponents: () => {
      dispatch(actions.approveAllComponents());
    },
    setExpandedCTeRows: expandedCTeRows => {
      dispatch(actions.setExpandedCTeRows(expandedCTeRows));
    },
    userLastAccess: oidMenu => dispatch(appActions.userLastAccess(oidMenu)),
    setCurrentMenu: menuPath =>
      dispatch(menuActions.setCurrentRecord(menuPath)),
    toggleFavorite: (isFavorite, oidMenu) => {
      dispatch(appActions.toggleFavorite(isFavorite, oidMenu));
    },
    clearCTeRecords: () => dispatch(actions.clearCTeRecords()),
    clearInvoiceRecords: () => dispatch(actions.clearInvoiceRecords()),
    reprocessCTe: () => {
      dispatch(actions.reprocessCTe());
    },
    removeCTe: () => {
      dispatch(actions.removeCTe());
    },
    reprocessAllCTEs: () => {
      dispatch(actions.reprocessAllCTEs());
    },
    setCurrentCTe: cte => {
      dispatch(actions.setCurrentCTe(cte));
    },
    showLogsHandler: idCTe => {
      dispatch(actions.loadLogRecords(idCTe));
      dispatch(actions.setDialogLogsVisible(true));
    },
    hideLogsHandler: () => {
      dispatch(actions.setDialogLogsVisible(false));
    },

    approveInvoice: () => {
      dispatch(actions.approveInvoice());
    },
    setCurrentInvoice: (invoice) => {
      dispatch(actions.setCurrentInvoice(invoice));
    },
    setDialogApproveInvoiceAlertMessage: alertMessage => {
      dispatch(actions.setDialogApproveInvoiceAlertMessage(alertMessage));
    },
    approveInvoiceDescriptionChangeHandler: e => {
      dispatch(actions.setDialogApproveInvoiceDescription(e.target.value));
    },
    showApproveInvoiceHandler: () => {
      dispatch(actions.setDialogApproveInvoiceVisible(true));
    },
    hideApproveInvoiceHandler: () => {
      dispatch(actions.setDialogApproveInvoiceVisible(false));
    },
    setDialogApproveInvoiceDescription: (value) => {
      dispatch(actions.setDialogApproveInvoiceDescription(value));
    },
    downloadHandler: (filters) => {
      dispatch(actions.download(filters));
    },
    exportReportInvoice: (id) => {
      dispatch(actions.exportReportInvoice(id));
    },
    accountConfigurationLoad: () => dispatch(accountConfigurationActions.loadAccountConfigurationsByCompany()),
    setReleaseManualInvoice: (param) => dispatch(actions.setReleaseManualInvoice(param)),
    setSelectecCteAproveAll: (listCtes) => dispatch(actions.setSelectecCteAproveAll(listCtes)),
    approveAllSelectedCTEs: () => dispatch(actions.approveAllSelectedCTEs()),
  };
};

class AuditListPageContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expandedRows: null,
      filterVisible: true
    };
  }

  componentWillReceiveProps(newProps) {
    if (
      (!this.props.menuItems || this.props.menuItems.length === 0) &&
      newProps.menuItems &&
      newProps.menuItems.length > 0
    ) {
      const menuPath = this.props.history.location.pathname;
      this.props.setCurrentMenu(menuPath);
    }

    if (
      (!this.props.currentMenu && newProps.currentMenu) ||
      this.props.currentMenu !== newProps.currentMenu
    ) {
      const oidMenu = newProps.currentMenu.oid;
      this.props.userLastAccess(oidMenu);
    }
  }

  componentDidMount() {
    AppService.on(COMPANY_CHANGE_EVENT_NAME, this);
    AppService.on(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);

    const menuPath = this.props.history.location.pathname;
    this.props.setCurrentMenu(menuPath);
    this.props.accountConfigurationLoad();
  }
  componentWillUnmount() {
    AppService.off(COMPANY_CHANGE_EVENT_NAME, this);
    AppService.off(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
  }

  onReceiveAppNotifications = (eventName, params) => {
    if (
      eventName === COMPANY_CHANGE_EVENT_NAME ||
      eventName === BUSINESS_UNIT_CHANGE_EVENT_NAME
    ) {
      this.props.accountConfigurationLoad();
      this.applyAuditFilter();
    }
  };

  lookupCarrierOnPage = e => {
    this.props.lookupCarrierPaginate({
      max: e.rows,
      index: e.first
    });
  };
  lookupCarrierOnSort = e => {
    this.props.lookupCarrierSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField
    });
  };
  lookupCarrierOnFilter = e => {
    const filters = [];
    const keys = Object.keys(e.filters) || [];
    keys.forEach(key => {
      filters.push({
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: "CONTEM"
      });
    });
    this.props.lookupCarrierApplyFilter(filters);
  };
  lookupCarrierOnClick = e => {
    this.props.lookupCarrierApplyFilter([]);
    this.props.lookupCarrierLoad();
    this.props.lookupCarrierSetVisible(true);
  };
  lookupCarrierOnHide = e => {
    this.props.lookupCarrierApplyFilter([]);
    this.props.lookupCarrierSetVisible(false);
  };
  lookupCarrierOnConfirm = e => {
    this.props.lookupCarrierApplyFilter([]);
    this.props.setCarrierFilter(e.selection);
    this.props.lookupCarrierSetVisible(false);
  };
  lookupCarrierOnCancel = e => {
    this.props.lookupCarrierApplyFilter([]);
    this.props.setCarrierFilter(null);
    this.props.lookupCarrierSetVisible(false);
  };

  lookupCarrierOnComplete = e => {
    //this.props.lookupCarrierApplyFilter([])
  };

  lookupCarrierOnInputFilter = e => {
    const value = e.value;
    if (typeof value === "string") {
      const filters = [
        {
          campo: "tradeName",
          valor: e.value,
          tipoFiltro: "CONTEM"
        }
      ];
      this.props.setCarrierFilter({ name: value });
      this.props.lookupCarrierApplyFilter(filters);
    } else {
      this.props.setCarrierFilter(value);
    }
  };

  onTbInvoiceRowClick = e => {
    this.props.setCurrentInvoiceRecord(e.data);
    this.props.loadCTesByInvoice(e.data);
  };

  applyAuditFilter = e => {
    const props = this.props;
    if (props.currentDocTypeFilter === "INVOICE") {
      this.props.loadInvoiceRecords();
      this.props.clearCTeRecords();
    } else {
      this.props.loadCTeRecords();
      this.props.clearInvoiceRecords();
    }
  };

  onFavorite = e => {
    if (this.props.currentMenu) {
      const oidMenu = this.props.currentMenu.oid;
      const isFavorite = this.props.isFavorite;
      this.props.toggleFavorite(isFavorite, oidMenu);
    }
  };

  toggleFilter = () => {
    this.setState(prev => {
      return {
        filterVisible: !prev.filterVisible
      };
    });
  };

  onDownload = (e) => {
    const filters = this.props.filters;
    if (this.props.docPendingsTypeFilterOptions) {
      filters.push(this.props.docPendingsTypeFilterOptions);
    }
    this.props.downloadHandler(filters);
  }

  render() {

    const dateFilter = (field) => {
      return (<Calendar name={field}
        style={{ width: "100%" }}
        dateFormat="dd/mm/yy"
        value={this.props.dateFilter[field]}
        onChange={(e) => this.props.changeDateFilter(e)} />)
    }

    const docStatusFilterItemSelectedItemTemplate = option => {
      if (option) {
        const colorClass =
          option === "WITHPENDINGS" ? "lxWithPendings" : "lxSolved";
        const label = option === "WITHPENDINGS" ? "Divergentes" : "Resolvidas";
        return (
          <div className="lxMultSelectDocStatusHeader">
            <div>
              <div
                className={"lxMultSelectDocStatusHeader-color " + colorClass}
              ></div>
              <div className="">{label}</div>
            </div>
          </div>
        );
      }
      return <div></div>;
    };

    const isFavorite = this.props.isFavorite;
    const header = (
      <div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h1>Auditoria</h1>

          <i
            className={`pi pi-star lxFavoriteStar ${isFavorite ? "lxFavorite" : ""
              }`}
            title={`${isFavorite ? "Remover dos favoritos" : "Adicionar aos favoritos"
              }`}
            onClick={this.onFavorite}
          ></i>
        </div>
        <div className="lxLineToggleHeader">
          <hr className="lxSplitter"></hr>
          <button
            className="lognex-btn-highlighted"
            title={
              this.state.filterVisible ? "Minimizar filtro" : "Maximizar filtro"
            }
            onClick={this.toggleFilter}
          >
            <i
              className={`pi pi-${this.state.filterVisible ? "minus" : "plus"}`}
            ></i>
          </button>
        </div>
        <div
          className={`p-grid lxFilter ${this.state.filterVisible ? "" : "lxFilterInvisible"
            }`}
        >
          <div className={`p-col-11 `}>
            <AuditFilter
              docTypeOptions={this.props.docTypeOptions}
              currentDocTypeFilter={this.props.currentDocTypeFilter}
              docStatusOptions={this.props.docStatusOptions}
              docPendingsTypeOptions={this.props.docPendingsTypeOptions}
              currentPendingsTypeFilter={this.props.currentPendingsTypeFilter}
              currentDocStatusFilter={this.props.currentDocStatusFilter}
              initialDateFilter={this.props.initialDateFilter}
              finalDateFilter={this.props.finalDateFilter}
              carrierFilter={this.props.carrierFilter}
              lookupCarrier={this.props.lookupCarrier}
              docTypeChangeHandler={this.props.docTypeChangeHandler}
              docStatusChangeHandler={this.props.docStatusChangeHandler}
              onPendingsTypeChangeHandler={this.props.onPendingsTypeChangeHandler}
              initialDateChangeHandler={this.props.initialDateChangeHandler}
              finalDateChangeHandler={this.props.finalDateChangeHandler}
              lookupCarrierOnComplete={this.lookupCarrierOnComplete}
              lookupCarrierOnInputFilter={this.lookupCarrierOnInputFilter}
              lookupCarrierOnEdit={this.lookupCarrierOnEdit}
              lookupCarrierOnClick={this.lookupCarrierOnClick}
              lookupCarrierOnConfirm={this.lookupCarrierOnConfirm}
              lookupCarrierOnCancel={this.lookupCarrierOnCancel}
              lookupCarrierOnPage={this.lookupCarrierOnPage}
              lookupCarrierOnFilter={this.lookupCarrierOnFilter}
              lookupCarrierOnSort={this.lookupCarrierOnSort}
              lookupCarrierOnHide={this.lookupCarrierOnHide}
              applyAuditFilter={this.applyAuditFilter}
              onDownloadCSV={this.onDownload}
              enableExportCSV={this.props.enableExportCSV}
              docStatusFilterItemSelectedItemTemplate={
                docStatusFilterItemSelectedItemTemplate
              }

            />
          </div>
          <div className="p-col-1"></div>
        </div>
        {/* <Toolbar
          onDownload={this.onDownload}
        /> */}
      </div>
    );

    const approveComponentOnClick = (idCTe, labelCTe, alertMessage) => {
      console.log("approveComponentOnClick", idCTe, labelCTe, alertMessage);
      this.props.showApproveComponentHandler();

      this.props.setDialogApproveComponentType("COMPONENT");
      this.props.setDialogApproveComponentAlertMessage(alertMessage);
      this.props.setCurrentCTe({ oid: idCTe });
      this.props.setCurrentApproveComponentLabelCTe(labelCTe);
    };

    const approveInvoiceOnClick = (idInvoice, alertMessage) => {
      this.props.showApproveInvoiceHandler();
      this.props.setDialogApproveInvoiceAlertMessage(alertMessage);
      this.props.setCurrentInvoice({ oid: idInvoice });
    };

    const approveAllComponentsOnClick = (idCTe, alertMessage) => {
      this.props.showApproveComponentHandler();
      this.props.setDialogApproveComponentAlertMessage(alertMessage);
      this.props.setDialogApproveComponentType("ALL");
      this.props.setCurrentCTe({ oid: idCTe });
    };

    const reprocessCTeOnClick = idCTe => {
      this.props.setCurrentCTe({ oid: idCTe });
      this.props.reprocessCTe();
    };
    const reprocessAllCTEs = () => {
      this.props.reprocessAllCTEs();
    };

    const releaseSyncInvoiceOnClick = (idInvoice, isEnable) => {
      this.props.setReleaseManualInvoice({ oid: idInvoice, isEnable });
    };

    const removeCTeOnClick = idCTe => {
      this.props.setCurrentCTe({ oid: idCTe });
      this.props.removeCTe();
    }


    /* Custom Templates */

    const invoiceActionTemplate = (rowData, column) => {
      const idInvoice = rowData.oid;
      const manualRelease = rowData.manualRelease;
      const hasPendencies = rowData.hasPendencies || false;


      const alertMessage = (hasPendencies)
        ? "Lembrete: Esta Fatura possui CTes pendentes."
        : " ";

      const body = (
        <div className="p-grid">
          <div className="p-col-12 lxActionButtons">
            <button
              className="lognex-btn-highlighted"
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                approveInvoiceOnClick(idInvoice, alertMessage);
              }}
              disabled={!hasPendencies}
              title={"Aprovar Fatura"}
            >
              <i className="pi pi-thumbs-up"></i>
            </button>

            <button
              className="lognex-btn-highlighted"
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
              }}
              disabled={true}
              title=" "
            >
              <i className="pi pi-thumbs-down"></i>
            </button>

            {this.props.auditConfiguration.useManualInvoiceRelease &&
              <button
                className={manualRelease ? "lognex-btn-highlighted-inverse" : "lognex-btn-highlighted"}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  releaseSyncInvoiceOnClick(idInvoice, !manualRelease);
                }}
                title={"Liberar fatura manual"}
              >
                {manualRelease &&
                  <i className="pi pi pi-check" style={{ color: "green" }}></i>
                }
                {!manualRelease &&
                  <i className="pi pi pi-check"></i>
                }
              </button>
            }

          </div>
        </div>
      );
      return body;
    }

    const cteActionsTemplate = (rowData, column) => {
      let btnApproveDisabled = !rowData.needApproval;
      let btnReprocessDisabled = !rowData.needApproval;
      let btnRemoveDisabled = !rowData.needApproval;
      if (rowData.audit.length == 0) {
        btnRemoveDisabled = false;
      }
      let textTitle="Aprovar todos os componentes divergentes do CTe"
      var titleReprocess="Reprocessar CTe"
      if (rowData.isCTeCompra && rowData.isAproveBloqueado) {
        textTitle = "CTE de compra ou devolução bloqueado para aprovação, CFOP:" + rowData.cfop;
        btnApproveDisabled=true;
      }
     if (rowData.situacaoCte != "") {
        textTitle = "CT-e cancelado";
       btnApproveDisabled=true;
       titleReprocess = "CT-e cancelado";
        btnReprocessDisabled=true;
        btnRemoveDisabled=true;
      }
     
      const idCTe = rowData && rowData.oid;
      const pendings = rowData && rowData.pendings;
      const alertMessage = hasOpenPending(pendings)
        ? "Lembrete: Este CTe possui documentos pendentes."
        : "";

        const body = (
        <div className="p-grid">
          <div className="p-col-12 lxActionButtons">
            <button
              className="lognex-btn-highlighted"
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                approveAllComponentsOnClick(idCTe, alertMessage);
              }}
              disabled={btnApproveDisabled}
              title={textTitle}
            >
              <i className="pi pi-thumbs-up"></i>
            </button>

            <button
              className="lognex-btn-highlighted"
              disabled={true}
              title="Reprovar componente(Em desenvolvimento)"
            >
              <i className="pi pi-thumbs-down"></i>
            </button>

            <ButtonDialog
              preventDefault={true}
              onClick={e => {
                reprocessCTeOnClick(idCTe);
              }}
              button={{
                title: titleReprocess,
                className: "lognex-btn-highlighted",
                iconClassName: "pi pi-refresh",
                disabled: btnReprocessDisabled
                
              }}
              dialog={{
                header: "Confirmação",
                modal: false,
                message: "Deseja realmente reprocessar o CTe?"
              }}
            ></ButtonDialog>
            <ButtonDialog
              preventDefault={true}
              onClick={e => {
                removeCTeOnClick(idCTe);
              }}  
              button={{
                title: "Remover CTe",
                className: "lognex-btn-danger",
                iconClassName: "pi pi-trash",
                disabled: btnRemoveDisabled
              }}
              dialog={{
                header: "Confirmação",
                modal: false,
                message: "Deseja realmente Remover o CTe?"
              }}
            ></ButtonDialog>
          </div>
        </div>
      );
      return body;
    };

    const componentActionsTemplate = (rowData, column ) => {
      const btnApproveDisabled = rowData.situacaoCte !== "" || !(rowData.needApproval && rowData.parent.needApproval);
      const title = (btnApproveDisabled)
      ?"CT-e cancelado"
      :"Aprovar componente"
      const idCTe = rowData && rowData.parent ? rowData.parent.oid : null;
      const labelCTe = rowData ? rowData.labelCTe : "";
      const pendings = rowData && rowData.parent && rowData.parent.pendings;
      const alertMessage = hasOpenPending(pendings)
        ? "Lembrete: Este CTe possui documentos pendentes."
        : "";
        
      const body = (
        <div className="p-grid">
          <div className="p-col-12 lxActionButtons">
            <button
              className="lognex-btn-highlighted"
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                approveComponentOnClick(idCTe, labelCTe, alertMessage);
              }}
              disabled={btnApproveDisabled}
              title={title}
            >
              <i className="pi pi-thumbs-up"></i>
            </button>

            <button
              className="lognex-btn-highlighted"
              disabled={true}
              title="Reprovar componente(Em desenvolvimento)"
            >
              <i className="pi pi-thumbs-down"></i>
            </button>
          </div>
        </div>
      );
      return body;
    };

    const componentStatusClass = rowData => {
      if (
        rowData.status === "SOLVED_AUTO" ||
        rowData.status === "SOLVED_MANUALLY"
      ) {
        return "inconsistency_solved";
      } else if (rowData.parent.needApproval && rowData.needApproval) {
        return "inconsistency_open";
      }
      return "";
    };

    const getClassCTEbloqueadoOuCompra = (rowData) => {
      let classCteCompra = "";
      if (rowData.isCTeCompra) {
        classCteCompra = "cteCompra";
      }
      if (rowData.isCTeCompra && rowData.isAproveBloqueado) {
        classCteCompra = "cteCompraBloqueado";
      }
      return classCteCompra;
    }
    const getTitleCTEbloqueadoOuCompra = (rowData) => {
      let textTitle = "";
      if (rowData.isCTeCompra) {
        textTitle = "CTE de compra ou devolução CFOP:" + rowData.cfop;
      }
      if (rowData.isCTeCompra && rowData.isAproveBloqueado) {
        textTitle = "CTE de compra ou devolução bloqueado para aprovação, CFOP:" + rowData.cfop;
      }
      return textTitle;
    }

    const valueTemplate = (rowData, column) => { 
      return (
        <span>
          {utils.numberToCurrency(rowData[column.field], "REAL")}
          {/* {cteComplementIcon} */}
        </span>
      );
    };

    const valueTemplateCTecanceled = (rowData, column) => { 
      let classColor=""
      let titlePerson=""
      if (rowData.situacaoCte != "") {
        classColor = "cteCancelado"
        titlePerson = "CT-e cancelado"
      }
      return (
        <span className={classColor} title={titlePerson}>
          {utils.numberToCurrency(rowData[column.field], "REAL")}
          {/* {cteComplementIcon} */}
        </span>
      );
    };

    const realizedTemplate = (rowData, column) => {
    
      return (
        <div className={`p-grid ${componentStatusClass(rowData)}`}>
          <div className="p-col-12 align-center">
            <span className={`${componentStatusClass(rowData)}`}>
              {utils.numberToCurrency(rowData.realizedValue, "REAL")}
            </span>
          </div>
        </div>
      );
    };

    const nameTemplate = (rowData, column) => {

      return (
        <div className={`p-grid`}>
          <div className="p-col-12">
            {" "}
            {rowData.labelCTe && rowData.labelCTe !== ""
              ? rowData.labelCTe
              : rowData.name}
          </div>
        </div>
      );
    };

    const centeredTemplate = (rowData, column) => {
      const value = utils.getFieldValueFromStringRecursively(
        rowData,
        column.field
      );
      return (
        <span style={{ textAlign: "center" }}>{value}</span>
      );
    };

    const hasOpenPending = pendings => {
      for (let index = 0; index < pendings.length; index++) {
        if (pendings[index].status === "OPEN") {
          return true;
        }
      }
      return false;
    };


    const hasPendingNFes = pendings => {
      if (pendings && pendings.length > 0) {
        for (let index = 0; index < pendings.length; index++) {
          if (
            pendings[index].type === "NEEDNFES" &&
            pendings[index].status === "OPEN"
          ) {
            return true;
          }
        }
      }
      return false;
    };

    const hasPendingQuote = pendings => {
      if (pendings && pendings.length > 0) {
        for (let index = 0; index < pendings.length; index++) {
          if (
            pendings[index].type === "NEEDQUOTE" &&
            pendings[index].status === "OPEN"
          ) {
            return true;
          }
        }
      }
      return false;
    };

    const numeroCTeTemplate = (rowData, column) => {
      const classCteCompra = getClassCTEbloqueadoOuCompra(rowData)
      const textTitle = getTitleCTEbloqueadoOuCompra(rowData);


      let pendingDocumentTypes = [];

      if (hasPendingNFes(rowData.pendings)) {
        pendingDocumentTypes.push("NFes");
      }
      if (hasPendingQuote(rowData.pendings)) {
        pendingDocumentTypes.push("Cotação");
      }
      let idCTe = rowData.oid;

      pendingDocumentTypes = pendingDocumentTypes.join("/");
      const pendinNFeIcon = !hasPendingNFes(rowData.pendings) ? (
        ""
      ) : (
        <i
          title={`NFes pendentes`}
          className="lxExclamationMark_NFe pi pi-exclamation-triangle"
        ></i>
      );
      const pendinQuoteIcon = !hasPendingQuote(rowData.pendings) ? (
        ""
      ) : (
        <i
          title={`Orçamento Zerado, verifique o log`}
          onClick={e => {
            this.props.showLogsHandler(idCTe);
          }}
          className="lxExclamationMark_Quote pi pi-exclamation-triangle"
        ></i>
      );
      const cteComplementIcon = !rowData.cteComplemento ? (
        ""
      ) : (
        <i
          title={`É complemento do CTE:${rowData.cteComplemento.numero}`}
          className="lxExclamationMark_Complement pi pi-exclamation-triangle"
        ></i>
      );
      const template = (
        <>
          <span className={`lxNumberCte ${classCteCompra}`} title={textTitle}>{rowData[column.field]}</span>
          {pendinNFeIcon}
          {pendinQuoteIcon}
          {cteComplementIcon}
        </>
      );
      return template;
    };

    const totalRealizedValueTemplate = (rowData, column) => {
      const currencyTotalRealized = utils.numberToCurrency(
        rowData.totalRealizedValue,
        "REAL"
      );
      const currencyCTeFreightValue = utils.numberToCurrency(
        rowData.cteFreightValue,
        "REAL"
      );
      const title = `A soma dos componentes(${currencyTotalRealized}) difere do valor do CTe(${currencyCTeFreightValue})`;

      const pendinIcon = currencyCTeFreightValue === currencyTotalRealized ? (
        ""
      ) : (
        <i
          style={{ verticalAlign: "-10%" }}
          title={title}
          className="pi pi-exclamation-triangle"
        ></i>
      );
      return (
        <span style={{ textAlign: "center" }}>
          {utils.numberToCurrency(rowData[column.field], "REAL")} {pendinIcon}{" "}
        </span>
      );
    };

    const dateTemplate = (rowData, column) => {
      const date = rowData[column.field];
      const formattedDate = moment(date).utc().format("DD/MM/YYYY");
      return <span style={{ textAlign: "center" }}>{formattedDate}</span>;
    };

    const porcentageTemplate = (rowData, column) => {
      const porcent = rowData[column.field] ? parseFloat(rowData[column.field]).toFixed(2) : 0.0

      return <span style={{ textAlign: 'center' }}> {porcent}</span>;
    }

    const qtdeCTesTemplate = (rowData, column) => {
      return <span style={{ textAlign: 'center' }}> {`${rowData.qtdeCTesImported}/${rowData.qtdeCTes}`}</span>;
    }

    /* Custom Columns  */
    const invoiceTbColumns = this.props.invoiceTbColumns.map((col, index) => {
      const key = `${index}-${col.field}`;
      if (col.field === "total") {
        return <Column {...col} key={key} body={valueTemplate} />;
      } else if (col.field === "date") {
        return <Column {...col} key={key} body={dateTemplate} filterElement={dateFilter(col.field)} />;
      } else if (col.field === "dueDate") {
        return <Column {...col} key={key} body={dateTemplate} filterElement={dateFilter(col.field)} />;
      } else if (col.field === "number" || col.field === "serie") {
        return <Column {...col} body={centeredTemplate} key={key} />;
      } else if (col.field === "qtdeCTes") {
        return <Column {...col} key={key} body={qtdeCTesTemplate} />;
      } else if (col.field === "actions") {
        return (
          <Column {...col} key={key} body={invoiceActionTemplate} />
        );
      }
      return <Column {...col} key={key} />;
    });

    const cteTbColumns = this.props.cteTbColumns.map((col, index) => {
      const key = `${col.index}-${col.field}`;
      if (col.field === "cteFreightValue") {
        return <Column {...col} key={key} body={totalRealizedValueTemplate} style={{ width: "125px" }} />;
      } else if (col.field === "totalQuotedValue") {
        return <Column {...col} key={key} body={valueTemplate} style={{ width: "125px" }} />;
      } else if (col.field === "totalValueNFe") {
        return <Column {...col} key={key} body={valueTemplateCTecanceled} style={{ width: "125px" }} />;
      } else if (col.field === "porcentageFreightNFe") {
        return <Column {...col} key={key} body={porcentageTemplate} style={{ width: "115px" }} />;
      } else if (col.field === "number") {
        return <Column {...col} key={key} body={numeroCTeTemplate} style={{ width: "135px" }} />;
      } else if (col.field === "actions") {
        return (
          <Column {...col} key={key} body={cteActionsTemplate} style={{ width: "150px" }} />
        );
      } else if (col.field === "dtEmissao") {
        return <Column {...col} key={key} body={dateTemplate} style={{ width: "100px" }} />;
      }

      return <Column {...col} style={{ textAlign: "center" }} key={key} />;
    });

    const auditTbColumns = this.props.auditTbColumns.map((col, index) => {
      const key = `${col.index}-${col.field}`;
      if (col.field === "quotedValue") {
        return (
          <Column
            {...col}
            key={key}
            body={valueTemplate}
            bodyClassName="quotedValue"
          />
        );
      } else if (col.field === "realizedValue") {
        return (
          <Column
            {...col}
            key={key}
            body={realizedTemplate}
            bodyClassName="realizedValue"
          />
        );
      } else if (col.columnKey === "actions") {
        return (
          <Column
            {...col}
            key={key}
            body={componentActionsTemplate}
          />
        );
      } else if (col.columnKey === "name") {
        return <Column {...col} key={key} body={nameTemplate} />;
      }
      return <Column {...col} key={key} />;
    });

    const dateHourTemplate = (rowData, column) => {
      const date = rowData[column.field];
      const formattedDate = moment(date).format("DD/MM/YYYY HH:mm:ss");
      return (
        <span style={{ textAlign: "center" }}>
          {formattedDate}
        </span>
      );
    };
    const historyTbColumns = this.props.historyTbColumns.map(col => {
      if (col.columnKey === "date") {
        return (
          <Column
            {...col}
            key={col.columnKey}
            style={{ width: "20%" }}
            body={dateHourTemplate}
          />
        );
      } else if (col.columnKey === "user") {
        return (
          <Column
            {...col}
            key={col.columnKey}
            style={{ width: "20%" }}
            body={centeredTemplate}
          />
        );
      }
      return <Column {...col} key={col.field} />;
    });

    const nfesTbColumns = this.props.nfesTbColumns.map(col => {
      if (col.columnKey === "number") {
        return (
          <Column
            {...col}
            key={col.columnKey}
            style={{ width: "20%" }}
            body={centeredTemplate}
          />
        );
      } else if (col.columnKey === "serie") {
        return (
          <Column
            {...col}
            key={col.columnKey}
            style={{ width: "15%" }}
            body={centeredTemplate}
          />
        );
      } else if (col.columnKey === "key") {
        return (
          <Column
            {...col}
            key={col.columnKey}
            style={{ width: "70%" }}
            body={centeredTemplate}
          />
        );
      }
      return <Column {...col} key={col.field} />;
    });

    const logTbColumns = this.props.logTbColumns.map(col => {
      const { columnKey } = col;
      return (
        <Column
          {...col}
          style={{ width: columnKey === "errorCode" ? "25%" : "75%" }}
          key={col.columnKey}
          body={centeredTemplate}
        />
      );
    });

    const onApproveAllComponents = () => {
      this.props.approveAllComponents();
      this.props.setCurrentApproveComponentDescription("");
      this.props.setCurrentApproveComponentIDCTe(null);
      this.props.setCurrentApproveComponentLabelCTe("");
      this.props.hideApproveComponentHandler();
    };


    const onApproveComponent = () => {
      this.props.approveComponent();
      this.props.setCurrentApproveComponentDescription("");
      this.props.setCurrentApproveComponentIDCTe(null);
      this.props.setCurrentApproveComponentLabelCTe("");
      this.props.hideApproveComponentHandler();
    };

    const onCancelApproveComponent = () => {
      this.props.hideApproveComponentHandler();
      this.props.setCurrentApproveComponentDescription("");
      this.props.setCurrentApproveComponentIDCTe(null);
      this.props.setCurrentApproveComponentLabelCTe("");
    };


    const onApproveInvoice = () => {
      this.props.approveInvoice();
      this.props.setDialogApproveInvoiceDescription("");
      this.props.hideApproveInvoiceHandler();
    };

    const onCancelApproveInvoice = () => {
      this.props.hideApproveInvoiceHandler();
      this.props.setDialogApproveInvoiceDescription("");
      this.props.setCurrentInvoice(null);

    };




    const setExpandedCTeRowsByRowClick = e => {
      const expandedCTeRows = this.props.expandedCTeRows;
      const newExpandedRow = e.data;
      //* Verify wether it must expand or collapse
      if (
        expandedCTeRows &&
        expandedCTeRows.length > 0 &&
        newExpandedRow.oid === expandedCTeRows[0].oid
      ) {
        this.props.setExpandedCTeRows([]);
      } else {
        this.props.setExpandedCTeRows([newExpandedRow]);
      }
    };

    const setExpandedCTeRows = e => {
      if (e.data && e.data.length > 1) {
        this.props.setExpandedCTeRows([e.data[1]]);
      } else {
        this.props.setExpandedCTeRows(e.data);
      }
    };

    const dialogApproveComponent = {
      description: this.props.currentApproveComponent.description,
      actions: {
        onCancel: onCancelApproveComponent,
        onChangeDescription: this.props
          .currentApproveComponentDescriptionChangeHandler,
        onApprove:
          this.props.dialogApproveComponent.approvalType === "ALL"
            ? onApproveAllComponents
            : onApproveComponent
      },
      ...this.props.dialogApproveComponent
    };

    const dialogApproveInvoice = {
      description: this.props.dialogApproveInvoice.description,
      actions: {
        onCancel: onCancelApproveInvoice,
        onChangeDescription: this.props.approveInvoiceDescriptionChangeHandler,
        onApprove: onApproveInvoice,
      },
      ...this.props.dialogApproveInvoice
    };


    const templateCTETbExpandedRow = (rowData, e) => {
      const audit = rowData.audit.map(current => {
        return {
          parent: rowData,
          ...current
        };
      });
      let idCTe = rowData.oid;
      return (
        <AuditDetail
          auditTbRecords={audit}
          auditTbColumns={auditTbColumns}
          showNFesHandler={() => {
            this.props.showNFesHandler(idCTe);
          }}
          hideNFesHandler={this.props.hideNFesHandler}
          showQuoteHandler={this.props.showQuoteHandler}
          hideQuoteHandler={this.props.hideQuoteHandler}
          dialogNFes={this.props.dialogNFes}
          nfesTbColumns={nfesTbColumns}
          dialogQuote={this.props.dialogQuote}
          nfeRecords={this.props.nfeRecords}
          // dialogQuote={this.props.dialogQuote}
          showHistoryHandler={() => {
            this.props.showHistoryHandler(idCTe);
          }}
          hideHistoryHandler={this.props.hideHistoryHandler}
          historyRecords={this.props.historyRecords}
          dialogHistory={this.props.dialogHistory}
          historyTbColumns={historyTbColumns}
          showLogsHandler={() => {
            this.props.showLogsHandler(idCTe);
          }}
          hideLogsHandler={this.props.hideLogsHandler}
          logRecords={this.props.logRecords}
          dialogLogs={this.props.dialogLogs}
          logTbColumns={logTbColumns}
        />
      );
    };

    const totalRealized = this.props.cteTotalizer
      ? this.props.cteTotalizer.totalRealized
      : 0.0;
    const totalQuoted = this.props.cteTotalizer
      ? this.props.cteTotalizer.totalQuoted
      : 0.0;
    const totalDivergent = totalRealized - totalQuoted;
    const totalRecords = this.props.cteTotalizer
      ? this.props.cteTotalizer.totalRecords
      : 0;
    const totalizers = [
      {
        label: `${getValue("TOTAL_DIVERGENT")}: `,
        value: totalDivergent,
        colours: true
      },
      { label: `${getValue("TOTAL_REALIZED")}: `, value: totalRealized },
      { label: `${getValue("TOTAL_QUOTED")}: `, value: totalQuoted },
      { label: `${getValue("TOTAL_RECORDS")}: `, value: totalRecords }
    ];
    /*End Custom Templates */
    const onSelectedCTeEventsChange = (e) => {
      e.originalEvent.preventDefault();
      e.originalEvent.stopPropagation();
      this.props.setSelectecCteAproveAll(e.value);
    }
    const approveAllSelectedCTEs = () => {
      // this.props.showApproveInvoiceHandler();
      // this.props.setDialogApproveInvoiceAlertMessage("alertMessage");
      this.props.approveAllSelectedCTEs();
    }


    const content = (
      <div>
        <Accordion
          className={
            this.props.currentDocTypeFilter === "CTE"
              ? "lxInvoiceInvisible"
              : ""
          }
          activeIndex={[0, 1]}
          multiple={true}
        >
          <AccordionTab disabled={false} header="Faturas">
            <InvoiceTable
              invoiceTbFilterHandler={this.props.invoiceTbFilterHandler}
              invoiceTbSortHandler={this.props.invoiceTbSortHandler}
              invoiceTbPageHandler={this.props.invoiceTbPageHandler}
              invoiceTbTotalRecords={this.props.invoiceTbTotalRecords}
              dialogApproveInvoice={dialogApproveInvoice}
              invoiceTbOrder={this.props.invoiceTbOrder}
              invoiceTbSortField={this.props.invoiceTbSortField}
              invoiceTbFirst={this.props.invoiceTbFirst}
              invoiceTbRows={this.props.invoiceTbRows}
              invoiceTbEmptyMessage={this.props.invoiceTbEmptyMessage}
              invoiceTbRowClickHandler={this.onTbInvoiceRowClick}
              invoiceTbColumns={invoiceTbColumns}
              invoiceTbRecords={this.props.invoiceTbRecords}
              exportReportInvoice={this.props.exportReportInvoice}
            ></InvoiceTable>
          </AccordionTab>
          <AccordionTab disabled={false} header="CTes">
            <div className="p-grid">
              <div className="p-col-2">

                <ButtonDialog
                  onClick={approveAllSelectedCTEs}
                  button={{
                    title: "Aprovar todos os CTe",
                    className: "lognex-btn-highlighted",
                    disabled: this.props.listCtesAllAprove.length == 0,
                    iconClassName: "pi pi-thumbs-up"
                  }}
                  dialog={{
                    header: "Confirmação",
                    modal: false,
                    message: "Deseja realmente aprovar todos os CTEs selecionados?",
                  }}
                ></ButtonDialog>

                <ButtonDialog
                  onClick={reprocessAllCTEs}
                  button={{
                    title: "Reprocessar todos os CTe",
                    className: "lognex-btn-highlighted",
                    disabled: this.props.listCtesAllAprove.length == 0,
                    iconClassName: "pi pi-refresh"
                  }}
                  dialog={{
                    header: "Confirmação",
                    modal: false,
                    message: "Deseja realmente reprocessar todos os CTEs selecionados?",
                  }}
                ></ButtonDialog>


              </div>
              <div className="p-col-10">
                <Totalizer
                  classNameT="lxCTeTotalizer"
                  totalizers={totalizers}
                ></Totalizer>
              </div>

            </div>
            <CTeTable
              cteTbRowExpandButtonOnClick={setExpandedCTeRows}
              cteTbRowToggleHandler={setExpandedCTeRowsByRowClick}
              cteTbRowExpasionTemplateHandler={templateCTETbExpandedRow}
              cteTBExpandedRows={this.props.expandedCTeRows}
              cteTbColumns={cteTbColumns}
              cteTbRecords={this.props.cteTbRecords}
              dialogApproveComponent={dialogApproveComponent}
              cteTbFilterHandler={this.props.cteTbFilterHandler}
              cteTbSortHandler={this.props.cteTbSortHandler}
              cteTbPageHandler={this.props.cteTbPageHandler}
              cteTbTotalRecords={this.props.cteTbTotalRecords}
              cteTbOrder={this.props.cteTbOrder}
              cteTbSortField={this.props.cteTbSortField}
              cteTbFirst={this.props.cteTbFirst}
              cteTbRows={this.props.cteTbRows}
              cteTbEmptyMessage={this.props.cteTbEmptyMessage}
              onSelectedEventsChange={onSelectedCTeEventsChange}
              selectedRows={this.props.listCtesAllAprove}
            ></CTeTable>
          </AccordionTab>
        </Accordion>
      </div>
    );
    return <Page header={header} content={content} />;
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuditListPageContainer);
