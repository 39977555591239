
    import React from 'react';
    import LogNFeListPageContainer from './LogNFeListPageContainer';
    
    export class LogNFeListPage extends React.Component{
        
        render(){
          return (
            <LogNFeListPageContainer {...this.props}/>
          )
        }
    }