import { types } from "./auditRecords.actions";
import { Reducer } from "../../services/reducer.factory";
import moment from "moment";

const initialState = {
  initialDateFilter: moment().startOf("month").toDate(),
  finalDateFilter: moment().toDate(),
  lookupCarrier: {
    visible: false,
    modal: false,
    header: "Transportadora",
  },
  carrierFilter: {
    oid: null,
    documentNumber: "",
    name: "",
    tradeName: "",
  },
 
  columns: [
    {
      field: "nfe.number",
      header: "NFE",
      columnKey: "nfe.number",
      sortable: true,
      filter: true,
    },
    {
      field: "cte.number",
      header: "CTE",
      columnKey: "cte.number",
      sortable: true,
      filter: true,
    },
    {
      field: "invoice.number",
      header: "Fatura",
      columnKey: "invoice.number",
      sortable: true,
      filter: true,
    },
    {
      field: "carrier.name",
      header: "Transportadora",
      columnKey: "carrier.name",
      sortable: true,
      filter: true,
    },
    {
      field: "orderCode",
      header: "Pedido",
      columnKey: "orderCode",
      sortable: true,
      filter: true,
    },
    {
      field: "cargoShipmentId",
      header: "Embarque",
      columnKey: "cargoShipmentId",
      sortable: true,
      filter: true,
    },
    {
      field: "audit.totalQuoted",
      header: "Cotação",
      columnKey: "audit.totalQuoted",
      sortable: false,
      filter: false,
    },
    {
      field: "audit.totalValue",
      header: "Auditoria",
      columnKey: "audit.totalValue",
      sortable: false,
      filter: false,
    },
    {
      field: "actions",
      header: "Ação",
      columnKey: "actions",
      sortable: false,
      filter: false,
    },
    {
      field: "image",
      header: "Comprovante Entrega",
      columnKey: "image",
      sortable: false,
      filter: false
    }
  ],
  columsTemplateTreview: [
    {
      field: "name",
      header: "Componente",
      columnKey: "name",
      sortable: false,
      filter: false,
    },
    {
      field: "quotedValue",
      header: "Valor Cotado",
      columnKey: "quotedValue",
      sortable: false,
      filter: false,
    },
    {
      field: "realizedValue",
      header: "Valor Auditado",
      columnKey: "realizedValue",
      sortable: false,
      filter: false,
    },
    {
      field: "actions",
      header: "Ação",
      columnKey: "actions",
      sortable: false,
      filter: false,
    },
  ],
  auditRecordPendencesColumns: [
    {
      field: "nfe.number",
      header: "NFE",
      columnKey: "nfe.number",
      sortable: true,
      filter: true,
    },
    {
      field: "cte.number",
      header: "CTE",
      columnKey: "cte.number",
      sortable: true,
      filter: true,
    },
    {
      field: "invoice.number",
      header: "Fatura",
      columnKey: "invoice.number",
      sortable: true,
      filter: true,
    },
    {
      field: "carrier.name",
      header: "Transportadora",
      columnKey: "carrier.name",
      sortable: true,
      filter: true,
    },
    {
      field: "orderCode",
      header: "Pedido",
      columnKey: "orderCode",
      sortable: true,
      filter: true,
    },
    {
      field: "cargoShipmentId",
      header: "Embarque",
      columnKey: "cargoShipmentId",
      sortable: true,
      filter: true,
    },
  ],
  historyRecords: [],
  historyTbColumns: [
    {
      field: "date",
      header: "Data",
      columnKey: "date"
    },
    {
      field: "user",
      header: "Usuário",
      columnKey: "user"
    },
    {
      field: "note",
      header: "Descrição",
      columnKey: "note"
    }
  ],
  dialogHistory: {
    visible: false,
    modal: true,
    header: "Histórico"
  },
  dialogLogs: {
    visible: false,
    modal: true,
    header: "Logs"
  },
  logRecords: [],
  logTbColumns: [
    {
      field: "errorCode",
      header: "Código",
      columnKey: "errorCode"
    },
    {
      field: "description",
      header: "Descrição",
      columnKey: "description"
    }
  ],
  // recordPendences:[],
  expandedRows: [],
  totalizer: {
    total: 0,
    pendencies: 0,
    divergence: 0,
    withoutRecept: 0,
    finished: 0,
  },
  selectedRow: [],
  observation: "",
  oidCte: 0,
  componentLabel: "",
  approvalType: "",
  loadFilters: {},
  description: "",
  visibleModalUploudFile: false,
  listAprovedCTes: [],
  documentView: {},
};

const reducer = new Reducer(initialState, types);

export function AuditRecordsReducer(state, action) {
  state = reducer.execute(state, action);
  return execute(state, action);
}

function execute(state, action) {
  switch (action.type) {
    case types.SET_LOOKUP_CARRIER_VISIBLE:
      return setLookupCarrierVisible(state, action);
    case types.SET_INITIAL_DATE_FILTER:
      return setInitialDateFilter(state, action);
    case types.SET_FINAL_DATE_FILTER:
      return setFinalDateFilter(state, action);
    case types.SET_CARRIER_FILTER:
      return setCarrierFilter(state, action);
    case types.SET_COUNTER:
      return setCounter(state, action);
    case types.SET_SELECTED_ROW:
      return setSelectedRow(state, action);
    case types.SET_EXPANDED_ROWS:
      return setExpandedRows(state, action);
    case types.SET_OBSERVATION:
      return setObservation(state, action);
    case types.SET_OID_CTE:
      return setOidCTE(state, action);
    case types.SET_COMPONENT_LABEL:
      return setComponentLabel(state, action);
    case types.SET_APPROVAL_TYPE:
      return setApprovalType(state, action);
    case types.SET_LOAD_FILTERS:
      return setLoadFilters(state, action);
    case types.SET_DESCRIPTION:
      return setDescription(state, action);
    case types.SET_VISIBLE_MODAL_UPLOAD:
      return setVisibleModalUpload(state, action);
    case types.LOAD_HISTORY_RECORDS:
      return loadHistoryRecords(state, action);
    case types.SET_DIALOG_HISTORY_VISIBLE:
      return setDialogHistoryVisible(state, action);
    case types.SET_DIALOG_LOGS_VISIBLE:
      return setDialogLogsVisible(state, action);
    case types.LOAD_LOG_RECORDS:
      return loadLogRecords(state, action)
    case types.SET_LIST_APROVED_CTES:
      return setListAprovedCTes(state, action);
    case types.SET_DOCUMENT_VIEW:
      return setDocumentView(state, action);
    default:
      return state;
  }
}

function loadLogRecords(state, action) {
  let logRecords = state.logRecords;
  logRecords = action.records;
  return { ...state, logRecords }
}

function setDialogLogsVisible(state, action) {
  let dialogLogs = state.dialogLogs;
  dialogLogs.visible = action.visible;
  return { ...state, dialogLogs }
}
function setDialogHistoryVisible(state, action) {
  let dialogHistory = state.dialogHistory;
  dialogHistory.visible = action.visible;
  return { ...state, dialogHistory }
}

function loadHistoryRecords(state, action) {
  let historyRecords = state.historyRecords;
  historyRecords = action.records;
  return { ...state, historyRecords }
}

function setListAprovedCTes(state, action) {
  let listAprovedCTes = state.listAprovedCTes
  listAprovedCTes = action.listAprovedCTes
  return { ...state, listAprovedCTes }
}

function setDocumentView(state, action) {
  let documentView = state.documentView;
  documentView = action.documentView;
  return { ...state, documentView };
}
function setDescription(state, action) {
  let description = state.description;
  description = action.description;
  return { ...state, description };
}

function setVisibleModalUpload(state, action) {
  let visibleModalUploudFile = action.visibleModalUploudFile;
  return { ...state, visibleModalUploudFile };
}

function setLoadFilters(state, action) {
  let loadFilters = state.loadFilters;
  loadFilters = action.filters;
  return { ...state, loadFilters };
}

function setComponentLabel(state, action) {
  let componentLabel = state.componentLabel;
  componentLabel = action.label;
  return { ...state, componentLabel };
}
function setOidCTE(state, action) {
  let oidCte = state.oidCte;
  oidCte = action.oid;
  return { ...state, oidCte };
}
function setApprovalType(state, action) {
  let approvalType = state.approvalType;
  approvalType = action.value;
  return { ...state, approvalType };
}
function setObservation(state, action) {
  let observation = state.observation;
  observation = action.value;
  return { ...state, observation };
}
function setExpandedRows(state, action) {
  let expandedRows = state.expandedRows;
  expandedRows = action.expandedRows;
  return { ...state, expandedRows };
}
function setSelectedRow(state, action) {
  let selectedRow = state.selectedRow;
  selectedRow = action.row;
  return { ...state, selectedRow };
}

function setCounter(state, action) {
  let totalizer = state.totalizer;
  totalizer = action.totalizer;
  return { ...state, totalizer };
}

function setCarrierFilter(state, action) {
  let carrierFilter = state.carrierFilter;
  carrierFilter = action.carrier;
  return { ...state, carrierFilter };
}

function setLookupCarrierVisible(state, action) {
  let lookupCarrier = state.lookupCarrier;
  lookupCarrier.visible = action.visible;
  return { ...state, lookupCarrier };
}

function setInitialDateFilter(state, action) {
  let initialDateFilter = action.initialDateFilter;
  return { ...state, initialDateFilter };
}

function setFinalDateFilter(state, action) {
  let finalDateFilter = action.finalDateFilter;
  return { ...state, finalDateFilter };
}
