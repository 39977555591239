import React from 'react';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import moment from 'moment';

export const HolidayForm = (props) => {
    const datetime_prev_begin =
    props.record && props.record.date ? moment(props.record.date).toDate() : null;
    return (
        <div className="Holiday-form">
            <div className="p-grid">
                <div className="p-col-6">
                    <div className="p-grid">
                        <div className="p-col-4 form-field-container">
                            <label>Código</label>
                            <InputText value={props.record.oid} onChange={props.changeHandler} name="oid" disabled={true} />
                        </div>
                        <div className="p-col-4 form-field-container">
                            <label>Data do feriado</label>
                            <Calendar dateFormat="dd/mm/yy" value={datetime_prev_begin} onChange={props.changeHandler} showIcon={true} name="date" />
                        </div>
                        <div className="p-col-4 form-field-container">
                            <label>Descrição</label>
                            <InputText value={props.record.description} onChange={props.changeHandler} name="description" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}