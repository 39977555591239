import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "./index.scss";
import MainLayout from "./layouts/main.layout";
import * as serviceWorker from "./serviceWorker";
import store from "./store";
import {loaderActions} from './store/loader';
import {actions} from './store/app/index';

import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.scss';

window.store = store;
window.loaderActions = loaderActions;
window.actions = actions;
const Main = () => (
  <Provider store={store}>
    <MainLayout />
  </Provider>
);

ReactDOM.render(<Main/>, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
