import React from 'react';
import UnidadeListPageContainer from './UnidadeListPageContainer';

export class UnidadeListPage extends React.Component{

    render(){
        return (
            <UnidadeListPageContainer {...this.props}/>
        )
    }
}
