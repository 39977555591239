
    import React from 'react';
    import ModalFormPageContainer from './ModalFormPageContainer';
    
    export class ModalFormPage extends React.Component{
        render(){
            return (
                <ModalFormPageContainer {...this.props}/>
            )
        }
    }
    