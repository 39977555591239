import React from 'react';
import UserDocumentTypeListPageContainer from './UserDocumentTypeListPageContainer';

export class UserDocumentTypeListPage extends React.Component{
    
    render(){
      return (
        <UserDocumentTypeListPageContainer {...this.props}/>
      )
    }
}