import React from 'react';
import AcaoFormPageContainer from './AcaoFormPageContainer';

export class AcaoFormPage extends React.Component{
    render(){
        return (
            <AcaoFormPageContainer {...this.props}/>
        )
    }
}

