export function validate(record) {
    const errors = [];

    if (!record.initialValidity || typeof record.initialValidity === 'string') {
        errors.push({ field: 'initialValidity', message: 'A data inicial não possui um formato válido!' });
    }

    if (!record.finalValidity || typeof record.finalValidity === 'string') {
        errors.push({ field: 'finalValidity', message: 'A data final não possui um formato válido!' });
    }

    if (!record.name || record.name === '') {
        errors.push({ field: 'name', message: 'O nome da tabela de frete é obrigatório!' });
    }

    if (!record.carrier) {
        errors.push({ field: 'carrier', message: 'A transportadora da tabela de frete é obrigatória!' });
    }

    for(let restriction of record.restrictions){
        if(restriction.useMultEntries){
            continue;
        }
        if (restriction.restrictedByClient ) {
            if (!restriction.clients) {
                errors.push({ field: 'addRestrictionsByComponent', message: 'É necessario incluir ao menos um cliente para o componente com restrição' });
            }
        }
        if (restriction.restrictedByLocalization) {
            if (!restriction.locations) {
                errors.push({ field: 'addRestrictionsByComponent', message: 'É necessario incluir ao menos uma localização para o componente com restrição' });
            }
        }
    }

    // record.restrictions.forEach(restriction => {
    //     if(rescriction.useMultEntries){

    //     }
    //     if (restriction.restrictedByClient ) {
    //         if (!restriction.clients) {
    //             errors.push({ field: 'addRestrictionsByComponent', message: 'É necessario incluir ao menos um cliente para o componente com restrição' });
    //         }
    //     }
    //     if (restriction.restrictedByLocalization) {
    //         if (!restriction.locations) {
    //             errors.push({ field: 'addRestrictionsByComponent', message: 'É necessario incluir ao menos uma localização para o componente com restrição' });
    //         }
    //     }
    // });


    // if(!record.modal){
    //     errors.push({ field: 'modal', message: 'O modal da tabela de frete é obrigatório!' });
    // }


    if (!record.routes || record.routes.length === 0) {
        errors.push({ field: 'routes', message: 'É necessário informar ao menos uma rota!' });
    }
    for (let component of record.generalFreightComponents) {
        consistComponent(component, errors);
    }
    const routes = record.routes || [];
    for (let route of routes) {
        const ranges = route.ranges || [];
        for (let range of ranges) {
            const series = range.series || [];
            for (let serie of series) {
                const components = serie.components || [];
                for (let component of components) {
                    consistComponent(component, errors)
                }
            }
        }
    }

    const result = {
        ok: errors.length === 0,
        errors,
        record
    }

    return new Promise((resolve, reject) => {
        const action = result.ok ? resolve : reject;
        return action(result);
    })
}

function consistComponent(component, errors) {
    if (component.useFixedValue && (!component.fixedValue && component.fixedValue !== 0)) {
        errors.push({ field: 'fixedValue', message: 'O campo Valor Fixo é obrigatório!' });
    }
    if (component.useMinValue && (!component.minValue && component.minValue !== 0)) {
        errors.push({ field: 'minValue', message: 'O campo Valor Minimo é obrigatório!' });
    }
    if (component.useMaxValue && (!component.maxValue && component.maxValue !== 0)) {
        errors.push({ field: 'maxValue', message: 'O campo Valor Máximo é obrigatório!' });
    }
    if (component.usePercentValue && (!component.percentValue && component.percentValue !== 0)) {
        errors.push({ field: 'percentValue', message: 'O campo Valor Percentual é obrigatório!' });
    }
    if (component.useKilometerValue && (!component.kmValue && component.kmValue !== 0)) {
        errors.push({ field: 'kmValue', message: 'O campo Valor Kilômetro é obrigatório!' });
    }
    if (component.useKiloValue && (!component.kgValue && component.kgValue !== 0)) {
        errors.push({ field: 'kgValue', message: 'O campo Valor Peso é obrigatório!' });
    }
    if (component.useUnitValue && (!component.unitValue && component.unitValue !== 0)) {
        errors.push({ field: 'useUnitValue', message: 'O campo Valor Unitário é obrigatório!' });
    }
    if (component.useDiscountValue && (!component.discountValue && component.discountValue !== 0)) {
        errors.push({ field: 'useDiscountValue', message: 'O campo Valor Desconto é obrigatório!' });
    }
}