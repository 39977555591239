import React from "react";
import { connect } from "react-redux";
import Toolbar from "../../../components/toolbar/Toolbar";
import { routes } from "../../../constants";
import { Page } from "../../../components/page/Page";
import { actions } from "../patiomanagerdoca.actions";
import { actions as actionPacking } from "../../packing/packing.actions";
import { actions as actionVehicleType } from "../../vehicleType/vehicleType.actions";
import { PatioManagerDocaForm } from "./PatioManagerDocaForm";
import { makeMessages, messageActions } from "../../../store/message";
import { actions as accountConfigurationActions } from "../../accountConfiguration/accountConfiguration.actions";
import { AppService, COMPANY_CHANGE_EVENT_NAME, BUSINESS_UNIT_CHANGE_EVENT_NAME } from "../../../services/app.service";
import { Dialog } from "primereact/dialog";
import DockConfigListPageContainer from "../../accountConfiguration/form/components/dockConfigVehicleType/list/DockConfigListPageContainer";
import { EditTypeDockConfig } from "../components/EditTypeDockConfig";
import { actions as appActions } from '../../../store/app';

const mapStateToProps = (state) => {
  const record = state.crudPatioManagerDocaState.currentRecord;
  const currentUnit = state.appState.currentUnit;
  const businessUnits = state.appState.units;

  let { docaVehicleOperation = [] } = state.crudAccountConfigurationState.currentRecord;

  if (record && record.oid > 0) {
    docaVehicleOperation = record.docaVehicleOperation;
  }
  let docaVehicleOperationBackup = state.crudPatioManagerDocaState.docaVehicleOperationBackup;
  if (!state.crudPatioManagerDocaState.docaVehicleOperationBackup) {
    docaVehicleOperationBackup = [...docaVehicleOperation];
  }
  return {
    record,
    currentUnit,
    businessUnits,
    docaVehicleOperation,
    docaVehicleOperationBackup: docaVehicleOperationBackup,
    isVisibleModalDockConfig: state.crudPatioManagerDocaState.isVisibleModalDockConfig,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeHandler: (event) => {
      const target = event.target;

      const field = target.name;
      let value = target.value;
      if (target.type == "number") {
        value = Number(value);
      }

      dispatch(actions.removeErrors([{ field }]));
      dispatch(actions.setField(field, value));
    },
    setField: (field, value) => dispatch(actions.setField(field, value)),
    saveHandler: (event) => {
      dispatch(actions.save());
    },
    removeHandler: (event) => {
      dispatch(actions.remove());
    },
    newHandler: (event) => {
      dispatch(actions.makeNew());
    },
    loadById: (oid) => dispatch(actions.loadById(oid)),

    showMessageSuccess: (msgs) => {
      dispatch(messageActions.messageShowMessages(makeMessages(msgs, "success")));
    },
    showMessageError: (msgs) => {
      dispatch(messageActions.messageShowMessages(makeMessages(msgs, "error")));
    },
    accountConfigurationLoad: () => dispatch(accountConfigurationActions.loadAccountConfigurationsByCompany()),
    setDocaVehicleOperation: (docaVehicleOperation) => dispatch(accountConfigurationActions.setDocaVehicleOperation(docaVehicleOperation)),
    setDocaVehicleOperationBackup: (docaVehicleOperation) => dispatch(actions.setDocaVehicleOperationBackup(docaVehicleOperation)),

    setVisibleModalDockConfig: (visible) => dispatch(actions.setVisibleModalDockConfig(visible)),
    setRestauraDocaVehicleOperation: (docaVehicleOperation) => dispatch(actions.setRestauraDocaVehicleOperation(docaVehicleOperation)),

    // setCurrentUnit: (unit) => {
    //   dispatch(appActions.setCurrentUnit(unit)); 
    // } 

  };
};

class PatioManagerDocaFormPageContainer extends React.Component {
  constructor(props) {
    super(props);
    actions.afterSave = ({ oid }) => {
      this.props.history.replace(`${routes.PATH_PATIO_MANAGER_DOCK}/${oid}`);
    };
    actions.afterNew = () => {
      this.props.history.replace(`${routes.PATH_PATIO_MANAGER_DOCK}/novo`);
    };
    actions.afterRemove = () => {
      this.props.history.replace(`${routes.PATH_PATIO_MANAGER_DOCK}`);
    };
  }

  componentDidMount() {
    AppService.on(COMPANY_CHANGE_EVENT_NAME, this);
    AppService.on(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);

    const oid = this.props.match.params.id;

    if (oid && parseInt(oid)) {
      this.props.loadById(oid);
    } else {
      this.props.newHandler();
    }
    this.props.accountConfigurationLoad();
  }
  componentWillUnmount() {
    AppService.off(COMPANY_CHANGE_EVENT_NAME, this);
    AppService.off(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
  }

  onReceiveAppNotifications = (eventName, params) => {
    if (eventName === COMPANY_CHANGE_EVENT_NAME || eventName === BUSINESS_UNIT_CHANGE_EVENT_NAME) {
      this.props.accountConfigurationLoad();
    }
  };

  // setLectedBusinessUnit=()=>{
  //   const businessUnits = this.props.businessUnits ||[] ;
  //   if (businessUnits && this.props.record.owner) {
  //     businessUnits.forEach(busn => {
  //       if(busn.cnpj == this.props.record.owner.businessUnit){
  //         this.props.setCurrentUnit(busn);
  //       }
  //     }); 
  //   }
  // }
  searchHandler = () => {
    this.props.history.push(`${routes.PATH_PATIO_MANAGER_DOCK}`);
  };

  saveHandler = () => {
    const currentUnit = this.props.currentUnit;
    if (currentUnit) {
      //check se o owner é igual

      if (this.isEnableChange()) {
        this.props.setField("docaVehicleOperation", this.props.docaVehicleOperation);
        this.props.setField("sigla", currentUnit.code);
        setTimeout(() => {
          this.props.saveHandler();
        }, 1000);
      } else {
        this.props.showMessageError(["Essa DOCA não pertence à Unidade selecionada!"]);
      }
    } else {
      this.props.showMessageError(["Deve selecionar uma Unidade!"]);
    }
  };
  isEnableChange = () => {
    try { 
      const oid = this.props.match.params.id;
      if (oid && parseInt(oid)) {
        const currentUnit = this.props.currentUnit;  
        if (this.props.record.owner.businessUnit == currentUnit.documentNumber && this.props.record.sigla ==currentUnit.code ) {
          return true;
        }
      } else {
        return true;
      }
    } catch (error) { }
    return false;
  };
  showDockConfigDialog = () => {
    this.props.setDocaVehicleOperation(this.props.docaVehicleOperation);
    this.props.setDocaVehicleOperationBackup(this.props.docaVehicleOperation);
    this.props.setVisibleModalDockConfig(true);
  };

  hideModalDockConfigDialog = () => {
    this.props.setVisibleModalDockConfig(false);
    this.props.setRestauraDocaVehicleOperation([...this.props.docaVehicleOperationBackup]);
  };
  confimaDockConfigDialog = () => {
    this.props.setVisibleModalDockConfig(false);
  };
  cancelaDockConfigDialog = () => {
    this.props.setVisibleModalDockConfig(false);
    this.props.setRestauraDocaVehicleOperation([...this.props.docaVehicleOperationBackup]);
  };

  getBusinessUnit = () => {
    let businessUnit;
    const businessUnits = this.props.businessUnits || []; 
    if (businessUnits && this.props.record.owner) {
      for (let index = 0; index < businessUnits.length; index++) {
        const busn = businessUnits[index];
        console.log("busn=",busn)
        if(busn.cnpj == this.props.record.owner.businessUnit && busn.sigla == this.props.record.sigla){
          // this.props.setCurrentUnit(busn);
          businessUnit = { ...busn };
        }
      }
    }
    return businessUnit;
  }
  render() {
    const title = `Doca ${this.props.record.oid || "novo"}`;
    const header = (
      <div>
        <h1>{title}</h1>
        <hr className="lxSplitter"></hr>
        <div>
          <Toolbar
            onSave={this.saveHandler}
            saveButtonStyle="highlighted"
            onRemove={this.props.removeHandler}
            removeButtonStyle="danger"
            onNew={this.props.newHandler}
            onSearch={this.searchHandler}
            btnSaveIsDisabled={!this.isEnableChange()}
            btnRemoveIsDisabled={!this.isEnableChange()}
          ></Toolbar>
        </div>
      </div>
    );
    const footer = (
      <div>
        <button title="Confirmar" className="lognex-btn-highlighted" onClick={this.confimaDockConfigDialog}>
          <i className="pi pi-check"></i>
        </button>
        <button title="Cancelar" className="lognex-btn-danger" onClick={this.cancelaDockConfigDialog}>
          <i className="pi pi-times"></i>
        </button>
      </div>
    );
    const content = (
      <React.Fragment>
        <PatioManagerDocaForm record={this.props.record} changeHandler={this.props.changeHandler} businessUnit={this.getBusinessUnit()} />

        <EditTypeDockConfig
          showDockConfigDialog={this.showDockConfigDialog}
          docaVehicleOperation={this.props.isVisibleModalDockConfig ? this.props.docaVehicleOperationBackup : this.props.docaVehicleOperation}
        />
        {this.props.isVisibleModalDockConfig && (
          <Dialog
            header={"Configuração Doca x Veículos"}
            visible={this.props.isVisibleModalDockConfig}
            style={this.props.style || { width: "70vw" }}
            modal={false}
            footer={footer}
            onHide={this.hideModalDockConfigDialog}
          >
            <DockConfigListPageContainer className={"panel-margins"} />
          </Dialog>
        )}
      </React.Fragment>
    );
    return <Page header={header} content={content} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PatioManagerDocaFormPageContainer);
