import React from 'react';
import {InputText} from 'primereact/inputtext';
import { InputCnpjCpf } from '../../../components/input-cnpj-cpf/InputCnpjCpf';
import { InputCep } from '../../../components/input-cep/InputCep';
import { InputTextarea } from 'primereact/inputtextarea';

export const EmpresaForm = (props)=>{
    const record = props.record;
    return(
        <div className="pessoa-form">
            <div className="p-grid">
            <div className="p-col-6">
                <div className="p-grid">
                    <div className="p-col-3">
                        <div className="form-field-container">
                            <label>Código</label>
                            <InputText value={record.oid} onChange={props.changeHandler} disabled={true} />
                        </div>

                    </div>
                    <div className="p-col-12 form-field-container">
                        <label className="lxRequiredField">Nome/Razão Social</label>
                        <InputText value={record.name} onChange={props.changeHandler} name="name" />
                    </div>
                    <div className="p-col-12 form-field-container">
                        <label>Nome Fantasia</label>
                        <InputText value={record.tradeName} onChange={props.changeHandler} name="tradeName" />
                    </div>
                    <div className="p-col-6 form-field-container">
                            <label className="lxRequiredField">Sigla</label>
                            <InputText value={record.code}  onChange={props.changeHandler} name="code" />
                        </div>

                    <div className="p-col-6 form-field-container">
                        <label className="lxRequiredField">CNPJ</label>
                        <InputCnpjCpf value={record.documentNumber} onChange={props.changeHandler} name="documentNumber" />
                    </div>

                    <div className="p-col-12 form-field-container">
                        <label>Endereço</label>
                        <InputText value={record.address.street} onChange={props.changeHandler} name="address.street" />
                    </div>
                    <div className="p-col-12 form-field-container">
                        <label>Complemento</label>
                        <InputText value={record.address.complement} onChange={props.changeHandler} name="address.complement" />
                    </div>
                    <div className="p-col-4 form-field-container">
                        <label>Número</label>
                        <InputText value={record.address.number} onChange={props.changeHandler} name="address.number" />
                    </div>
                    <div className="p-col-4 form-field-container">
                        <label>Bairro</label>
                        <InputText value={record.address.district} onChange={props.changeHandler} name="address.district" />
                    </div>
                    <div className="p-col-4 form-field-container">
                        <label>CEP</label>
                        <InputCep value={record.address.zipCode} onChange={props.changeHandler} name="address.zipCode" disabled={false} />
                    </div>
                    <div className="p-col-12 form-field-container">
                        <label>Cidade</label>
                        <InputText value={record.address.city} onChange={props.changeHandler} name="address.city" />
                    </div>

                    <div className="p-col-12 form-field-container">
                        <label>Email</label>
                        <InputText value={record.email} onChange={props.changeHandler} name="email" />
                    </div>
                    <div className="p-col-12 form-field-container">
                        <label>Site</label>
                        <InputText value={record.site} onChange={props.changeHandler} name="site" />
                    </div>
                    <div className="p-col-12 form-field-container">
                        <label>Contato</label>
                        <InputTextarea value={record.contact}  onChange={props.changeHandler} name="contact" />
                    </div>

                </div>
            </div>
        </div>
        </div>
    
    )
};