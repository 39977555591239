import React from "react";
import { tooltip } from "primereact/tooltip";
import { connect } from "react-redux";
import { Page } from "../../../components/page/Page";
import { actions as actionsTravel } from "../../towerTravel/tower_travel.actions";
import { actions as actionsDashboard } from "../../towerDashboard/tower_dashboard.actions";
import { actions } from "../tower_travel_visualize_map.actions";
import { messageActions, makeMessages } from "../../../store/message";
import { routes } from "../../../constants";
import { AppService, COMPANY_CHANGE_EVENT_NAME, BUSINESS_UNIT_CHANGE_EVENT_NAME } from "../../../services/app.service";

import { IndicatorTravel } from "../../towerDashboard/components/indicator/totalizadores";
import storage from '../../../services/stoage.service'

import vehicleIconFail from "../../../assets/images/icons/truck_failure.png";
import vehicleIconSuccess from "../../../assets/images/icons/truck_success.png";
import vehicleIconWarning from "../../../assets/images/icons/truck_warning.png";
import vehicleIconNormal from "../../../assets/images/icons/truck_normal.png";

import vehicleIconCanceled from "../../../assets/images/icons/truck_failure.png";
import vehicleIconLagTrend from "../../../assets/images/icons/truck_success_with_pendings.png";
import pin_end_travel from "../../../assets/images/icons/travel-finish.png";

import pin_green from "../../../assets/images/icons/pin_green.svg";
import pin_red from "../../../assets/images/icons/pin_red.svg";
import pin_blue from "../../../assets/images/icons/pin_blue.svg";

import "./tower-travel-map.scss";
import { CANCELED, CREATED, END_TRAVEL, ORIGEM_PORTAL_TRANSPORTADOR, TRAVELING, TRAVEL_LAG_TREND, TRAVEL_LATE, TRAVEL_ON_TIME } from "../../towerDashboard/constants";
import Map from "../form/map";
import moment from "moment";
import { InputDeliveryDateDialog } from "../components/dialogChangeDateDelivery";
import { GraficoTempHora } from "../components/dialogGraficoTempHora";
const mapStateToProps = (state) => {
  const crudTowerTravelBase = {
    travels: state.crudTowerTravelState.records,
  };

  let travelsRecords = Object.assign({}, state.crudTowerTravelVisualizeMapState, crudTowerTravelBase);
  const totalizers = state.crudTowerDashboardState.totalizers;
  const filtersMapParams = state.crudTowerDashboardState.filtersMapParams;
  const filterAux = state.crudTowerDashboardState.filterAux;
  const travels = state.crudTowerTravelVisualizeMapState.travels || [];
  const userConfigurations = state.appState.userConfigurations;
  let towerShowTotalizers = userConfigurations.towerShowTotalizers || {
    total: true,
    andamento: true,
    atraso: true,
    tendencia_atraso: true,
    on_time: true,
    notmirrored: true,
    finalizados: false,
    canceladas: false,
  };
  const carrier = state.crudPortalCarrierState.carrier

  return {
    travelsRecords,
    travels,
    totalizers,
    dropdownStatusTravelOptions: state.crudTowerDashboardState.dropdownStatusTravelOptions,
    dropdownStatusTimeOptions: state.crudTowerDashboardState.dropdownStatusTimeOptions,
    filterMap: state.crudTowerTravelVisualizeMapState.filterMap,
    deliveryChange: state.crudTowerTravelVisualizeMapState.deliveryChange,
    dialogInputDeliveryDateVisible: state.crudTowerTravelVisualizeMapState.dialogInputDeliveryDateVisible,
    filtersMapParams,
    filterAux,
    towerShowTotalizers,
    selectedTypeFilter: state.crudTowerDashboardState.selectedTypeFilter,
    totalizersFiltersParams: state.crudTowerDashboardState.totalizersFiltersParams,
    carrier,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    changeHandler: (event) => {
      const target = event.target;
      const field = target.name;
      let value = target.value;
      dispatch(actions.removeErrors([{ field }]));
      dispatch(actions.setField(field, value));
    },

    applyFilter: (filterParams) => {
      dispatch(actions.getAllTravels(filterParams));
    },

    loadById: (oid) => dispatch(actions.loadById(oid)),

    loadAllUnidade: () => {
      dispatch(actions.findAllBusinessunits());
    },

    loadTotalizers: (filter) => {
      dispatch(actionsDashboard.loadTotalizers(filter));
    },

    showMessageError: (msg) => {
      dispatch(messageActions.messageShowMessages(makeMessages([msg], "error")));
    },
    setFilterMap: (filterMap) => {
      dispatch(actions.setFilterMap(filterMap));
    },

    deliveryDateChangeHandler: (e) => {
      dispatch(actions.deliveryDateChangeHandler(e.value));
    },
    changeDialogDeliveryDateVisible: (val) => {
      dispatch(actions.changeDialogDeliveryDateVisible(val));
    },
    onConfirmInputDeliveryDate: () => {
      dispatch(actions.onConfirmInputDeliveryDate());
    },
    setTavelDeliveryDate: (val) => {
      dispatch(actions.setTavelDeliveryDate(val));
    },
    setSelectedTypeFilter: (selectedTypeFilter) => {
      dispatch(actionsDashboard.setSelectedTypeFilter(selectedTypeFilter));
    },
    showGraficoTempHora: (visible) => dispatch(actions.showGraficoTempHora(visible)),
  };
};

class TowerTravelVisualizeMapListPageContainer extends React.Component {
  constructor(props) {
    super(props);
    this.intervalFecht = null;
    this.fechtTravelByInterval = this.fechtTravelByInterval.bind(this);
  }

  componentDidMount() {
    AppService.on(COMPANY_CHANGE_EVENT_NAME, this);
    AppService.on(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
    this.props.loadAllUnidade();

    const oid = this.getId();
    if (oid && parseInt(oid)) {
      this.loadById(oid);
    } else {
      this.onAplyFilter();
    }
    this.intervalFecht = setInterval(this.fechtTravelByInterval, 10 * 60000);
  }
  getId = () => {
    let id = null;
    if (this.props.match && this.props.match.params && this.props.match.params.id) {
      id = this.props.match.params.id
    }
    return id
  }
  componentWillUnmount() {
    AppService.off(COMPANY_CHANGE_EVENT_NAME, this);
    AppService.off(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
    clearInterval(this.intervalFecht);
  }

  onReceiveAppNotifications = (eventName, params) => {
    if (eventName === COMPANY_CHANGE_EVENT_NAME || eventName === BUSINESS_UNIT_CHANGE_EVENT_NAME) {
      const oid = this.getId();
      this.props.loadAllUnidade();
      if (oid && parseInt(oid)) {
        this.loadById(oid);
      } else {
        this.onAplyFilter();
      }
    }
  };

  fechtTravelByInterval = function () {
    //a cada 5 minutos atualiza as viagens na tela
    // const oid = this.props.match.params.id;
    const oid = this.getId()
    if (oid && parseInt(oid)) {
      this.loadById(oid);
    } else {
      this.onAplyFilter();
    }
  };

  loadById = (oid) => {
    const filters = [];
    filters.push({
      campo: "_id",
      valor: parseInt(oid),
      tipoFiltro: "IGUAL_A",
    });
    this.props.applyFilter(filters);
  };

  getFilters = () => {
    let filters = [];


    if (!this.getId()) {
      filters = [... this.props.filtersMapParams];

      console.log(" ------------- getFilters this.props.filtersMapParams=", this.props.filtersMapParams)
    }

    if (filters.length == 0) {
      let initialDate = moment().startOf("month").toDate();
      let finalDate = moment().toDate();
      filters.push({
        field: "eventRecord.createdAt",
        startValue: moment(initialDate).startOf("day").toDate(),
        endValue: moment(finalDate).endOf("day").toDate(),
        type: "BETWEEN",
      });
    }
    if (this.props.origem && this.props.origem == ORIGEM_PORTAL_TRANSPORTADOR) {

      const user = storage.getUser();
      if (user.carrier) {
        filters.push({
          field: "carrier.documentNumber",
          value: user.carrier.cnpj,
          type: 'EQUALS'
        })
      } else {
        const carrier = this.props.carrier;
        if (carrier && carrier.documentNumber && carrier.documentNumber != "") {
          filters.push({
            field: "carrier.documentNumber",
            value: carrier.documentNumber,
            type: 'EQUALS'
          })
        }
      }
    }
    console.log("towerTravelVisualizeMap getFilters filters=", filters, ", this.props.origem:", this.props.origem)
    return filters;
  };
  getTotalizersFilters = () => {
    let filters = [];

    filters = this.props.totalizersFiltersParams ? [...this.props.totalizersFiltersParams] : [];

    if (filters.length == 0) {
      let initialDate = moment().startOf("month").toDate();
      let finalDate = moment().toDate();
      filters.push({
        field: "eventRecord.createdAt",
        startValue: moment(initialDate).startOf("day").toDate(),
        endValue: moment(finalDate).endOf("day").toDate(),
        type: "BETWEEN",
      });
    }
    if (this.props.origem && this.props.origem == ORIGEM_PORTAL_TRANSPORTADOR) {

      const user = storage.getUser();

      if (user.carrier) {
        filters.push({
          field: "carrier.documentNumber",
          value: user.carrier.cnpj,
          type: 'EQUALS'
        })
      } else {
        const carrier = this.props.carrier;
        if (carrier && carrier.documentNumber && carrier.documentNumber != "") {
          filters.push({
            field: "carrier.documentNumber",
            value: carrier.documentNumber,
            type: 'EQUALS'
          })
        }
      }
    }
    console.log("towerTravelVisualizeMap getTotalizersFilters filters=", filters, ", this.props.origem:", this.props.origem)
    return filters;
  };
  onAplyFilterFirstLoad = () => {
    const filters = [...this.getFilters()];
    const totalizersFiltersParams = this.getTotalizersFilters();
    this.props.loadTotalizers(totalizersFiltersParams);
    this.props.applyFilter(filters);
  };

  onAplyFilter = () => {
    // const filters = [...this.getFilters()];
    const totalizersFiltersParams = this.getTotalizersFilters();
    this.props.loadTotalizers(totalizersFiltersParams);
    // this.props.applyFilter(filters);

    if (this.props.selectedTypeFilter == "total") {
      this.onClickTotal();
    } else if (this.props.selectedTypeFilter == "andamento") {
      this.onClickAndamento();
    } else if (this.props.selectedTypeFilter == "atraso") {
      this.onClickAtraso();
    } else if (this.props.selectedTypeFilter == "tendencia_atraso") {
      this.onClickTendenciaAtraso();
    } else if (this.props.selectedTypeFilter == "on_time") {
      this.onClickOnTime();
    } else if (this.props.selectedTypeFilter == "finalizados") {
      this.onClickFinalizadas();
    }
  };

  onClickFinalizadas = () => {
    if (this.props.origem && this.props.origem == ORIGEM_PORTAL_TRANSPORTADOR) {
      return
    }
    const filters = [...this.getFilters()];
    filters.push({
      field: "travel_status",
      value: [END_TRAVEL],
      type: "IN",
    });
    this.props.applyFilter(filters);
    this.props.setSelectedTypeFilter("finalizados");
  };

  onClickOnTime = () => {

    const filters = [...this.getFilters()];

    filters.push({
      campo: "status_time",
      valor: TRAVEL_ON_TIME,
      tipoFiltro: "IGUAL_A",
    });
    filters.push({
      field: "travel_status",
      value: [TRAVELING],
      type: "IN",
    });

    filters.push({
      campo: "pathTraveledCount",
      valor: 2,
      tipoFiltro: "MAIOR",
    });
    this.props.applyFilter(filters);

    this.props.setSelectedTypeFilter("on_time");
  };

  onClickTendenciaAtraso = () => {
    if (this.props.origem && this.props.origem == ORIGEM_PORTAL_TRANSPORTADOR) {
      return
    }
    const filters = [...this.getFilters()];

    filters.push({
      campo: "status_time",
      valor: TRAVEL_LAG_TREND,
      tipoFiltro: "IGUAL_A",
    });
    filters.push({
      field: "travel_status",
      value: [TRAVELING],
      type: "IN",
    });

    filters.push({
      campo: "pathTraveledCount",
      valor: 2,
      tipoFiltro: "MAIOR",
    });

    this.props.applyFilter(filters);

    this.props.setSelectedTypeFilter("tendencia_atraso");
  };

  onClickAtraso = () => {
    const filters = [...this.getFilters()];

    filters.push({
      campo: "status_time",
      valor: TRAVEL_LATE,
      tipoFiltro: "IGUAL_A",
    });
    filters.push({
      field: "travel_status",
      value: [TRAVELING],
      type: "IN",
    });

    filters.push({
      campo: "pathTraveledCount",
      valor: 2,
      tipoFiltro: "MAIOR",
    });
    this.props.applyFilter(filters);
    this.props.setSelectedTypeFilter("atraso");
  };

  onClickAndamento = () => {
    const filters = [...this.getFilters()];
    filters.push({
      field: "travel_status",
      value: [TRAVELING],
      type: "IN",
    });
    filters.push({
      campo: "pathTraveledCount",
      valor: 2,
      tipoFiltro: "MAIOR",
    });
    this.props.applyFilter(filters);
    this.props.setSelectedTypeFilter("andamento");
  };

  onClickNotMirrored = () => {
    if (this.props.origem && this.props.origem == ORIGEM_PORTAL_TRANSPORTADOR) {
      return
    }
    const filters = [...this.getFilters()];
    filters.push({
      field: "travel_status",
      value: [TRAVELING, CREATED],
      type: "IN",
    });

    filters.push({
      campo: "pathTraveledCount",
      valor: 2,
      tipoFiltro: "MENOR_OU_IGUAL",
    });

    this.props.applyFilter(filters);
    this.props.setSelectedTypeFilter("notmirrored");
  };
  onClickCanceladas = () => {
    if (this.props.origem && this.props.origem == ORIGEM_PORTAL_TRANSPORTADOR) {
      return
    }
    const filters = [...this.getFilters()];
    filters.push({
      field: "travel_status",
      value: [CANCELED],
      type: "IN",
    });
    this.props.applyFilter(filters);
    this.props.setSelectedTypeFilter("canceladas");
  };
  onClickTotal = () => {
    if (this.props.origem && this.props.origem == ORIGEM_PORTAL_TRANSPORTADOR) {
      return
    }
    const filters = [...this.getFilters()];
    this.props.applyFilter(filters);
    this.props.setSelectedTypeFilter("total");
  };

  getTravelIcon = (travel) => {
    var iconeVeiculo = vehicleIconNormal;
    if (travel.travel_status === CREATED) {
      iconeVeiculo = vehicleIconNormal;
    } else if (travel.travel_status === CANCELED) {
      iconeVeiculo = vehicleIconCanceled;
    } else if (travel.status_time === TRAVEL_ON_TIME) {
      iconeVeiculo = vehicleIconSuccess;
    } else if (travel.status_time === TRAVEL_LAG_TREND) {
      iconeVeiculo = vehicleIconLagTrend;
    } else if (travel.status_time === TRAVEL_LATE) {
      iconeVeiculo = vehicleIconWarning;
    }

    return iconeVeiculo;
  };

  getDestinationDetails = (destination) => {
    let detailStr = destination.description;
    return detailStr;
  };

  formatDate = (date) => {
    return moment(date).format("DD/MM/YYYY HH:mm:ss");
  };

  getUserOwner = (obs) => {
    if (obs.owner && obs.owner.user.length > 0) {
      return " - " + obs.owner.user;
    }
    return "";
  };

  getIconPointDestino = (travel, destino) => {
    if (destino.deliveryDate) {
      return pin_green;
    } else {
      //não entregue
      if (travel.status_time === TRAVEL_LATE) {
        return pin_red;
      }
      return pin_blue;
    }
  };

  changeDialogDeliveryDateVisible = () => {
    this.props.changeDialogDeliveryDateVisible(false);
  };

  onConfirmInputDeliveryDate = () => {
    this.props.onConfirmInputDeliveryDate();
  };
  onCancelInputDeliveryDate = () => {
    this.props.changeDialogDeliveryDateVisible(false);
    this.props.setTavelDeliveryDate({ oid: null, index: null, delaveryDatetime: null, destinOid: null });
  };

  onBackDashboard = () => {
    this.props.history.push(`${routes.PATH_TOWER_DASHBOARD}`);
  };

  isShowEndTravel = () => {
    const id = this.getId()
    return id && id > 0;
  };

  getId = () => {
    let id = null;
    if (this.props.match && this.props.match.params && this.props.match.params.id) {
      id = this.props.match.params.id
    }
    return id
  }
  render() {
    let id = this.getId()

    const header = id && (
      <div>
        <div className="header-mapa-travel">
          <h1>Viagem {id}</h1>
          <button
            onClick={() => {
              this.onBackDashboard();
            }}
            title="Voltar para o dashboard"
          >
            <i className="pi pi-sign-out"> </i>
          </button>
        </div>
        <hr className="lxSplitter"></hr>
      </div>
    );

    const btnBackToDasboard = (
      <div className="btn-exit-mapa">
        <button
          onClick={() => {
            this.onBackDashboard();
          }}
          title="Voltar para o dashboard"
        >
          <i className="pi pi-sign-out"> </i>
        </button>
      </div>
    );

    const indicadores = !id && (
      <IndicatorTravel
        {...this.props.totalizers}
        onClickFinalizadas={this.onClickFinalizadas}
        onClickOnTime={this.onClickOnTime}
        onClickAndamento={this.onClickAndamento}
        onClickAtraso={this.onClickAtraso}
        onClickTotal={this.onClickTotal}
        onClickTendenciaAtraso={this.onClickTendenciaAtraso}
        onClickNotMirrored={this.onClickNotMirrored}
        onClickCanceladas={this.onClickCanceladas}
        selectedTypeFilter={this.props.selectedTypeFilter}
        towerShowTotalizers={this.props.towerShowTotalizers}
        isFromMap={true}
        origem={this.props.origem}
      />
    );

    const detalhesDaviagem = /*this.props.match.params.id &&*/ this.props.travels.length > 0 && (
      <div className="content-travel-detail">
        <h3>{"Detalhes da viagem"}</h3>
        {/* {this.props.travelsRecords.travels[0].oid} */}
        {/* Time line */}

        {this.props.travels.map((travel, index) => {
          return (
            <div key={index} className="travel-content-detail">
              <div className="travel-detail">
                {/*   Origin */}
                <div className="content-origin-end"></div>

                <div style={{ width: "100%" }}>
                  <div className="content-pin-deliverys">
                    {/* show pin */}
                    {travel.destinations.map((dest, destIndex) => {
                      return (
                        <img
                          key={"img-" + destIndex}
                          className="icon-pin-timeline"
                          style={{ left: `${dest.percentDistancia}%` }}
                          src={this.getIconPointDestino(travel, dest)}
                          title={this.getDestinationDetails(dest)}
                        />
                      );
                    })}
                  </div>
                  {/*   destinos */}
                  <div className="timeline">
                    <div className="line-foreground">
                      <div className="line" style={{ width: `${travel.currentPercent}%` }}>
                        <div className="percent-line">{travel.currentPercent}%</div>
                        <img className="image-veiculo-icon-timeline" src={this.getTravelIcon(travel)} />
                      </div>
                    </div>
                  </div>
                </div>

                {/*   Origin */}
                <div className="content-origin-end"></div>
              </div>

              <div>
                <h3>Observações</h3>
                {travel.observation &&
                  travel.observation.map((obs, obsIndex) => {
                    return (
                      <div key={"obs_" + obsIndex} className="content-obs">
                        <div className="obs-title">
                          {obs.observationType.name} - <samp className="obs-title-span">{this.formatDate(obs.createdAt)}</samp> {this.getUserOwner(obs)}
                        </div>
                        <div>{obs.obs}</div>
                      </div>
                    );
                  })}
              </div>
            </div>
          );
        })}
      </div>
    );
    let permitEditarMap = (!this.props.origem || this.props.origem != ORIGEM_PORTAL_TRANSPORTADOR)
    const content = (
      <div className="travel-view-map">
        {!id && btnBackToDasboard}
        {indicadores}
        <Map showEndTravel={this.isShowEndTravel()} permitEditarMap={permitEditarMap} />
        {permitEditarMap &&
          detalhesDaviagem
        }
        {/* mostrar grafico modal */}
        <InputDeliveryDateDialog
          onHide={this.changeDialogDeliveryDateVisible}
          onConfirm={this.onConfirmInputDeliveryDate}
          onCancel={this.onCancelInputDeliveryDate}
          visible={this.props.dialogInputDeliveryDateVisible}
          deliveryDateChangeHandler={this.props.deliveryDateChangeHandler}
          {...this.props}
        />
       
      </div>
    )
    return <Page header={header} content={content} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TowerTravelVisualizeMapListPageContainer);
