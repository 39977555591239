import { types } from "./tower_travel_visualize_map.actions";
import { Reducer } from "../../services/reducer.factory";
import moment from "moment";
import { CREATED, TRAVELING } from "../towerDashboard/constants";

const initialState = {
  currentRecord: {},
  filterMap: {
    travelStatus: [TRAVELING, CREATED],
    statusTime: null,
  },

  defaultPosition: [-13.625, -50.713],
  routeColors: [
    "#B10DC9", // purple
    "#0074D9", // blue
    "#FF851B", // orange
    "#2ECC40", // green
    "#FFDC00", // yellow
    "#F012BE", // fuchsia
    "#01FF70", // lime
    "#999999", // gray
    "#001f3f", // navy 
    "#85144b", // maroon
    "#3D9970", // olive
    "#39CCCC", // teal
    "#FF3333", //
    "#E74F1B", //
    "#E7A91B", //
    "#E3E71B", //
    "#ACE71B", //
    "#1BE7C8", //
    "#1BA9E7", //
    "#663D3D", //
  ],
  lineStyle: {
    weight: 10,
    opacity: 0.6,
  },
  deliveryChange: {
    delaveryDatetime: null,
    oid: null,
    indexDestino: null,
    destinOid: null,
  },
  dialogInputDeliveryDateVisible: false,
  unidadeRecords: [],
  travels: [],
  filterMapsPortal: [],
  totalizer: null,
  selectedTypeFilter:null,
  graficoTempHoraVisible:false,
};

const reducer = new Reducer(initialState, types);

export function CRUDTowerTravelVisualizeMapReducer(state, action) {
  state = reducer.execute(state, action);
  return execute(state, action);
}

function execute(state, action) {
  let result = state;
  switch (action.type) {
    case types.SET_FILTER_MAP:
      return setFilterMap(state, action);
    case types.SET_DELIVERY_DATETIME:
      return deliveryDateChangeHandler(state, action);
    case types.SET_DELIVERY_DATE_DIALOG_VISIBLE:
      return changeDialogDeliveryDateVisible(state, action);
    case types.SET_TRAVEL_CHANGE_DELIVERUDATE:
      return setTavelDeliveryDate(state, action);
    case types.SET_ALL_BUSINESUNITS:
      return setAllBusinessunits(state, action);
    case types.SET_ALL_TRAVELS:
      return setTravels(state, action); 
    case types.SET_TOTALIZADORES_PORTAL:
      return setTotalizadoresPortal(state, action);
    case types.SET_SELECTEC_TYPE_FILTER:
      return setSelectedTypeFilter(state, action);
    case types.SET_VISIBLE_GRAFICO_TEMP_HORA:
      return showGraficoTempHora(state, action);
    default:
      return result;
  }
} 
 
function showGraficoTempHora(state, action) {
  const graficoTempHoraVisible = action.visible;
  console.log("graficoTempHoraVisible:",graficoTempHoraVisible)
  return { ...state, graficoTempHoraVisible };
}
function setSelectedTypeFilter(state, action) {
  const selectedTypeFilter = action.selectedTypeFilter;
  return { ...state, selectedTypeFilter };
}


function setTotalizadoresPortal(state, action) {
  const totalizer = action.totalizers;
  return { ...state, totalizer };
}
 
function setTravels(state, action) {
  const travels = action.travels;
  return { ...state, travels };
}
function setAllBusinessunits(state, action) {
  let unidadeRecords = state.unidadeRecords;
  unidadeRecords = action.records;
  return { ...state, unidadeRecords };
}
function setFilterMap(state, action) {
  let filterMap = state.filterMap;
  filterMap = action.filterMap;
  return { ...state, filterMap };
}

function deliveryDateChangeHandler(state, action) {
  let deliveryChange = state.deliveryChange;
  deliveryChange.delaveryDatetime = action.dateTime;
  return { ...state, deliveryChange };
}

function changeDialogDeliveryDateVisible(state, action) {
  let dialogInputDeliveryDateVisible = state.dialogInputDeliveryDateVisible;
  dialogInputDeliveryDateVisible = action.visible;
  return { ...state, dialogInputDeliveryDateVisible };
}

function setTavelDeliveryDate(state, action) {
  let deliveryChange = state.deliveryChange;
  const { oid, index, destinOid } = action.travelData;
  deliveryChange.oid = oid;
  deliveryChange.indexDestino = index;
  deliveryChange.destinOid = destinOid;
  return { ...state, deliveryChange };
}
