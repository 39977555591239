
    import React from 'react';
    import FreightComponentFormPageContainer from './FreightComponentFormPageContainer';
    
    export class FreightComponentFormPage extends React.Component{
        render(){
            return (
                <FreightComponentFormPageContainer {...this.props}/>
            )
        }
    }
    