import { validate } from "./validations";
import { build } from "../../services/crudFactory";
import { Action } from "../../services/actions.factory";
import api from "../../services/api.service";

const BASE_PATH = "/lognex/policesGroup";
const prefix = "crudPolicesGroup";
const crud = build(validate, BASE_PATH);

const newTypes = {
  SET_LOOKUP_POLICES_VISIBLE: `${prefix}setLookupPolicesVisible`,
  SET_LOOKUP_GROUP_VISIBLE: `${prefix}setLookupGroupVisible`,
  LOAD_ALL_BY_POLICE_GROUP_ID: `${prefix}loadAllPolicesByGroupID`,
  SET_RECORS_LOAD_BY_ID: `${prefix}setRecordsLoadByGroupId`,
  REMOVE_POLICES_GROUP_BY_ID: `${prefix}removePolicesGroupBy`,
  LOAD_ALL_BY_GROUP_ID: `${prefix}loadAllByGroupId`,
}


const actions = new Action(prefix, crud);

const types = Object.assign({}, actions.types, newTypes);

actions.loadAllByGroupId = (groupId) => {
  return (dispatch, getState) => {
    api.post("/api/v1/lognex/policesGroup/loadAllByGroupId", { groupId })
      .then(({ data }) => {
        dispatch(actions.setRecordsLoadByGroupId(data));
      }).catch((err) => {
        console.log(err);
        dispatch(actions.setRecordsLoadByGroupId([]));
      });
  };
};
actions.loadAllPolicesByGroupID = (groupId) => {
  return (dispatch, getState) => {
    api.post("/api/v1/lognex/policesGroup/loadAllByPoliceGroupId", { groupId })
      .then(({ data }) => {
        dispatch(actions.setRecordsLoadByGroupId(data));
      }).catch((err) => {
        console.log(err);
        dispatch(actions.setRecordsLoadByGroupId([]));
      });
  };
};

actions.removePolicesGroupBy = (groupId) => {
  return (dispatch, getState) => {
    api.remove("/api/v1/lognex/policesGroup/" + groupId)
      .then((result) => {
        console.log("remove result=", result)
      }).catch((err) => {
        console.log(err);
        dispatch(actions.setRecordsLoadByGroupId([]));
      });
  };
};

actions.setRecordsLoadByGroupId = (records) => {
  return {
    type: types.SET_RECORS_LOAD_BY_ID,
    records,
  };
};

actions.setLookupGroupVisible = (visible) => {
  return {
    type: types.SET_LOOKUP_GROUP_VISIBLE,
    visible,
  };
};

actions.setLookupPolicesVisible = (visible) => {
  return {
    type: types.SET_LOOKUP_POLICES_VISIBLE,
    visible,
  };
};

export { types, actions };
