import React from 'react';
import PermissaoFormPageContainer from './PermissaoFormPageContainer';

export class PermissaoFormPage extends React.Component{

    render(){
        return(
            <PermissaoFormPageContainer {...this.props}/>
        )
    }
}
