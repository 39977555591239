import React from 'react';
import { Dialog } from "primereact/dialog";
import { MapContainer, Marker, Polyline, Popup, TileLayer, useMap } from 'react-leaflet';
import L, { Icon, map, marker, point } from "leaflet";
import "./MapViewRouteDialog.scss";
import { actions } from './map-route.actions';
import { connect } from 'react-redux';
import polyUtil from "@mapbox/polyline";
import pin_vehicle_origem from "../../assets/images/icons/truck_origem.png";
import pin_home_origin from "../../assets/images/icons/icon-cd.svg";



function ChangeView({ polyline }) {
  const map = useMap();

  React.useEffect(() => {
    if (polyline.length > 0) {
      const bounds = L.latLngBounds(polyline);
      map.fitBounds(bounds);
    }
  }, [polyline, map]);

  return null;
}

const positionCenter = [-16.4677031, -49.707108];

const mapStateToProps = state => {
  return {

  }
}
const mapDispatchToProps = dispatch => {
  return {
    findRouteEmbarque: (id, calback) => dispatch(actions.findRouteEmbarque(id, calback))
  }
}

class MapViewRouteDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      positions: [],
      initialized: false, // Controla se a vista já foi inicializada
      route: null,
      polyline: [],
      postionStart: [],
      iconSize: point(34, 34),
      // iconAnchor: [30, 28],
      iconAnchor: null,
      stepsPikupPosition: [], 
      dv:false
    };
  }

  componentDidMount() {
    this.showSpinner();
    this.props.findRouteEmbarque(this.props.idEmbarqueShowMap, (response) => {

      if (response.data) {
        const polyline = polyUtil.decode(response.data.geometry);
        delete response.data.geometry 

        const positions = response.data.routerDetails.map(detail => {
          return [detail.latitude, detail.longitude];
        });

        let stepsPikupPosition = []
        if (response.data.stepsPikup) {
          stepsPikupPosition = response.data.stepsPikup.map(detail => {
            return [detail.location[1], detail.location[0]];
          });
        }

        let stepsDeliveryPosition = []
        if (response.data.stepsDelivery) {
          stepsDeliveryPosition = response.data.stepsDelivery.map(detail => {
            return [detail.location[1], detail.location[0]];
          });
        }

        const postionStart = [response.data.depotRoute.latitude, response.data.depotRoute.longitude];

        this.setState({ route: response.data, polyline, positions, initialized: true, postionStart, stepsPikupPosition, stepsDeliveryPosition,dv:response.data.dv })
      }
      this.hideSpinner();
    });
  }
  hideDialog = () => {
    this.props.onHideDialog();
  };

  showSpinner = () => {
    document.querySelector('.loading-container').style.display = 'flex';
  };

  hideSpinner = () => {
    document.querySelector('.loading-container').style.display = 'none';
  };

  getIconTruck = () => {
    return new Icon({
      iconUrl: pin_vehicle_origem,
      iconRetinaUrl: pin_vehicle_origem,
      iconSize: this.state.iconSize,
      // iconAnchor: iconAnchor,
    })
  }
  getIconPickup = (index) => {
    return new Icon({
      iconUrl: pin_home_origin,
      iconRetinaUrl: pin_home_origin,
      iconSize: point(38, 38),
      iconAnchor: null,
    })
  }

  getInfoPonto = (index) => {
    const ponto = this.state.route.routerDetails[index];
    return `
        <div>
            <strong>${ponto.clientFormalName}</strong><br>
            Rua: ${ponto.street}<br>
            Cidade: ${ponto.city}, ${ponto.state}<br> 
            Delivery: ${ponto.delivery}<br>
            Dimensões: ${ponto.dimensions.map(dim => dim.name + ': ' + dim.value).join(', ')}<br>
            Depósito: ${ponto.depotCode}
        </div>
    `;
  }

  getInfoPickupPonto = (index) => {
    const ponto = this.state.route.stepsPikup[index]
    return `
        <div>
            <strong>${ponto.description}</strong><br>
            Delivery: ${ponto.code}<br> 
            Tipo: <strong> Coleta </strong>
        </div>
    `;
  }
  getInfoDeliveryPonto = (index) => {
    const ponto = this.state.route.stepsDelivery[index]
    // const  des = this.state.route.routerDetails[index];
    return `
        <div>
            <strong>${ponto.description}</strong><br>
            Delivery: ${ponto.code}<br> 
            Tipo: Entrega
        </div>
    `;
  }
  getInfoVeiculo = () => {

    const htmlString = `
    <div>
        <h4>Informações do Veículo</h4><br>
        <span><strong>Distância:</strong> ${this.state.route.distance} km</span><br>
        <span><strong>Cidade:</strong> ${this.state.route.city}</span> <br>
        <span><strong>Rua:</strong> ${this.state.route.street}</span><br>
        <span><strong>Bairro:</strong> ${this.state.route.district}</span><br>
        <span><strong>Depósito:</strong> ${this.state.route.depotName || "(não especificado)"}</span><br>
        <span><strong>Placa do Veículo:</strong> ${this.state.route.plate}</span>
    </div>
`;
    return htmlString
  }


  getMap = () => {
    return (
      <MapContainer center={positionCenter} zoom={4} style={{ height: '80vh', width: '80vw' }}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />

        {this.state.polyline.length > 0 && this.state.postionStart.length > 0 &&
          <Marker icon={this.getIconTruck()} zIndexOffset={2} key={"startP"} position={this.state.postionStart}>
            <Popup>
              <div dangerouslySetInnerHTML={{ __html: this.getInfoVeiculo() }} />
            </Popup>
          </Marker>
        }

        {!this.state.dv && this.state.polyline.length > 0 && this.state.stepsDeliveryPosition.map((position, idx) => (
          <Marker key={idx} position={position}>
            <Popup>
              <div dangerouslySetInnerHTML={{ __html: this.getInfoDeliveryPonto(idx) }} />
            </Popup>
          </Marker>
        ))}
        {this.state.dv && this.state.polyline.length > 0 && this.state.stepsDeliveryPosition.map((position, idx) => (
          <Marker key={idx}  icon={this.getIconPickup(idx)} position={position}>
            <Popup>
              <div dangerouslySetInnerHTML={{ __html: this.getInfoDeliveryPonto(idx) }} />
            </Popup>
          </Marker>
        ))}
        {this.state.dv && this.state.polyline.length > 0 && this.state.stepsPikupPosition.map((position, idx) => ( 
          <Marker zIndexOffset={1} key={"pickup_" + idx} position={position}>
            <Popup>
              <div dangerouslySetInnerHTML={{ __html: this.getInfoPickupPonto(idx) }} />
            </Popup>
          </Marker>
        ))}
        {!this.state.dv && this.state.polyline.length > 0 && this.state.stepsPikupPosition.map((position, idx) => ( 
          <Marker zIndexOffset={1} icon={this.getIconPickup(idx)} key={"pickup_" + idx} position={position}>
            <Popup>
              <div dangerouslySetInnerHTML={{ __html: this.getInfoPickupPonto(idx) }} />
            </Popup>
          </Marker>
        ))}
        {this.state.polyline.length > 0 &&
          <Polyline positions={this.state.polyline} weight={8} color={this.state.route.routeColor ?? "blue"} />
        }
        {this.state.polyline.length > 0 && this.state.initialized &&
          <ChangeView polyline={this.state.polyline} />
        }

      </MapContainer>
    );
  };

  render() {
    const { props } = this;
    return (
      <Dialog
        header={
          <div className="lxButtonDialogHeader">
            <div className="lxHeaderTitle">
              <span>{"Visualização da Rota"}</span>
            </div>
            <button className="p-dialog-titlebar-icon p-link lxHeaderClose" onClick={this.hideDialog}>
              <span className="pi pi-times"></span>
            </button>
          </div>
        }
        closable={false}
        visible={this.props.dialogMapViewRouteVisible}
        modal={props.modal}
        onHide={this.hideDialog}
        dismissableMask={true}
      >
        <div className="map-view-route">
          {this.getMap()}
          <div className="loading-container">
            <div className="spinner"></div>
          </div>
          {this.state.polyline.length == 0 && this.state.initialized &&
           <div className="loading-container">
           <div className="empty-route"><h2>Esse embarque não tem informações de rota!</h2></div>
         </div>
        }
        </div>
      </Dialog>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MapViewRouteDialog);