import React from 'react';
import MenuFormPageContainer from './MenuFormPageContainer';

export class MenuFormPage extends React.Component{
    render(){
        return (
            <MenuFormPageContainer {...this.props}/>
        )
    }
}

