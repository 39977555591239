import React from 'react';
import { InputText } from 'primereact/inputtext';

import './PolicesResourcesForm.scss';
import { LookupField } from '../../../components/lookup/LookupField';

export const PolicesResourcesForm = (props) => {
    const polices = props.record ? props.record.polices : null;
    const menu = props.record ? props.record.menu : null;
    
    return (
        <div className="PolicesResources-form">
            <div className="p-grid">
                <div className="p-col-6">
                    <div className="p-grid">
                        <div className="p-col-3">
                            <div className="form-field-container">
                                <label>Código</label>
                                <InputText value={props.record ? props.record.oid : ""} onChange={props.changeHandler} disabled={true} />
                            </div>

                        </div>

                        <div className="p-col-12 form-field-container">
                            <label>Politicas</label>
                            <LookupField
                                value={polices}
                                placeholder="Politicas"
                                name="name"
                                field="name"
                                onComplete={props.lookupPolicesOnComplete}
                                onInputFilter={props.lookupPolicesOnInputFilter}
                                editDisabled={props.lookupPolices.editDisabled}
                                onEditField={props.lookupPolicesOnEdit}
                                onClick={props.lookupPolicesOnClick}
                                visible={props.lookupPolices.visible}
                                modal={props.lookupPolices.modal}
                                header={props.lookupPolices.header}
                                onHide={props.lookupPolicesOnHide}
                                onConfirm={props.lookupPolicesOnConfirm}
                                onCancel={props.lookupPolicesOnCancel}
                                records={props.lookupPolices.records}
                                columns={props.lookupPolices.columns}
                                totalRecords={props.lookupPolices.totalRecords}
                                sortOrder={props.lookupPolices.order}
                                sortField={props.lookupPolices.sortField}
                                first={props.lookupPolices.first}
                                rows={props.lookupPolices.rows}
                                onPage={props.lookupPolicesOnPage}
                                onFilter={props.lookupPolicesOnFilter}
                                onSort={props.lookupPolicesOnOnSort}
                                paginator={true}
                                rowsPerPageOptions={props.lookupPolices.rowsPerPageOptions} 
                            />
                        </div>

                        <div className="p-col-12 form-field-container">
                            <label>Menus</label>
                            <LookupField
                                value={menu}
                                placeholder="Menus"
                                name="name"
                                field="name"
                                onComplete={props.lookupMenusOnComplete}
                                onInputFilter={props.lookupMenusOnInputFilter}
                                editDisabled={props.lookupMenus.editDisabled}
                                onEditField={props.lookupMenusOnEdit}
                                onClick={props.lookupMenusOnClick}
                                visible={props.lookupMenus.visible}
                                modal={props.lookupMenus.modal}
                                header={props.lookupMenus.header}
                                onHide={props.lookupMenusOnHide}
                                onConfirm={props.lookupMenusOnConfirm}
                                onCancel={props.lookupMenusOnCancel}
                                records={props.lookupMenus.records}
                                columns={props.lookupMenus.columns}
                                totalRecords={props.lookupMenus.totalRecords}
                                sortOrder={props.lookupMenus.order}
                                sortField={props.lookupMenus.sortField}
                                first={props.lookupMenus.first}
                                rows={props.lookupMenus.rows}
                                onPage={props.lookupMenusOnPage}
                                onFilter={props.lookupMenusOnFilter}
                                onSort={props.lookupMenusOnOnSort}
                                paginator={true}
                                rowsPerPageOptions={props.lookupMenus.rowsPerPageOptions} 
                            />
                        </div>
                        <div className="p-col-12 form-field-container">
                            <label>Resource Key</label>
                            <InputText value={props.record ? props.record.resourceKey : ""} onChange={props.changeHandler} name="resourceKey" />
                        </div>

                        <div className="p-col-4 form-field-container">
                            <label>Ação</label>
                            <InputText value={props.record ? props.record.action : ""} placeholder='VIEW' onChange={props.changeHandler} name="action" />
                        </div>


                    </div>
                </div>
            </div>

        </div>
    );
}
