import React from 'react';
import { InputText } from 'primereact/inputtext';

import './PolicesGroupForm.scss';
import { LookupField } from '../../../components/lookup/LookupField';
import { DataTable } from 'primereact/datatable';

export const PolicesGroupForm = (props) => {
    const polices = props.record ? props.record.polices : null;
    const group = props.record ? props.record.group : null;
    return (
        <div className="PolicesGroup-form">
            <div className="p-grid">
                <div className="p-col-6">
                    <div className="p-grid">
                        <div className="p-col-3">
                            <div className="form-field-container">
                                <label>Código</label>
                                <InputText value={props.record ? props.record.oid : ""} onChange={props.changeHandler} disabled={true} />
                            </div>
                        </div>

                        <div className="p-col-12 form-field-container">
                            <label>Perfil</label>
                            <LookupField
                                value={group}
                                placeholder="Perfil"
                                name="name"
                                field="name"
                                onComplete={props.lookupGroupOnComplete}
                                onInputFilter={props.lookupGroupOnInputFilter}
                                editDisabled={props.lookupGroup.editDisabled}
                                onEditField={props.lookupGroupOnEdit}
                                onClick={props.lookupGroupOnClick}
                                visible={props.lookupGroup.visible}
                                modal={props.lookupGroup.modal}
                                header={props.lookupGroup.header}
                                onHide={props.lookupGroupOnHide}
                                onConfirm={props.lookupGroupOnConfirm}
                                onCancel={props.lookupGroupOnCancel}
                                records={props.lookupGroup.records}
                                columns={props.lookupGroup.columns}
                                totalRecords={props.lookupGroup.totalRecords}
                                sortOrder={props.lookupGroup.order}
                                sortField={props.lookupGroup.sortField}
                                first={props.lookupGroup.first}
                                rows={props.lookupGroup.rows}
                                onPage={props.lookupGroupOnPage}
                                onFilter={props.lookupGroupOnFilter}
                                onSort={props.lookupGroupOnOnSort}
                                paginator={true}
                                rowsPerPageOptions={props.lookupGroup.rowsPerPageOptions}
                            />
                        </div>

                        <div className="p-col-12 form-field-container">
                            <label>Politicas</label>
                            <LookupField
                                value={polices}
                                placeholder="Politicas"
                                name="name"
                                field="name"
                                onComplete={props.lookupPolicesOnComplete}
                                onInputFilter={props.lookupPolicesOnInputFilter}
                                editDisabled={props.lookupPolices.editDisabled}
                                onEditField={props.lookupPolicesOnEdit}
                                onClick={props.lookupPolicesOnClick}
                                visible={props.lookupPolices.visible}
                                modal={props.lookupPolices.modal}
                                header={props.lookupPolices.header}
                                onHide={props.lookupPolicesOnHide}
                                onConfirm={props.lookupPolicesOnConfirm}
                                onCancel={props.lookupPolicesOnCancel}
                                records={props.lookupPolices.records}
                                columns={props.lookupPolices.columns}
                                totalRecords={props.lookupPolices.totalRecords}
                                sortOrder={props.lookupPolices.order}
                                sortField={props.lookupPolices.sortField}
                                first={props.lookupPolices.first}
                                rows={props.lookupPolices.rows}
                                onPage={props.lookupPolicesOnPage}
                                onFilter={props.lookupPolicesOnFilter}
                                onSort={props.lookupPolicesOnOnSort}
                                paginator={true}
                                isMultiple={false}
                                rowsPerPageOptions={props.lookupPolices.rowsPerPageOptions}
                            />
                        </div>

                        <div className="p-col-12  form-field-container">
                            <label>Todas as Politicas x Grupo - Para adicionar um novo click no botão ADD </label>
                        </div>

                        <div className="p-col-12  form-field-container">
                            <DataTable
                                selectionMode="single"
                                metaKeySelection={false}
                                value={props.policesGroup}
                                lazy={true}
                                totalRecords={props.totalRecords}
                                sortOrder={props.order}
                                sortField={props.sortField}
                                paginator={false}
                                first={props.first}
                                rows={props.rows}
                                scrollable={true}
                                scrollHeight="400px"
                                emptyMessage="Não foram encontrados registros"
                            >
                                {props.columnsPolices}
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}
