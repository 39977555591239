import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

export const AuditRecordPendencesTable = (props) => (
  <div style={{ minHeight: "300px" }}>
    <DataTable
      selectionMode="single"
      onFilter={props.onFilter}
      onSort={props.onSort}
      onPage={props.onPage}
      value={props.records}
      lazy={true}
      sortField={props.sortField}
      totalRecords={props.totalRecords}
      first={props.first}
      rows={props.rows}
      paginator={true}
      emptyMessage={props.emptyMessage}
      rowsPerPageOptions={[5, 10, 20, 50, 100, 200]}
      scrollable={true}
      scrollHeight={"calc(100vh - 510px)"}
    >
      {[
        <Column expander={false} key={"auditTable"} style={{ width: "4em" }} />,
        ...props.auditRecordColumns,
      ]}
      {/* {props.auditRecordColumns} */}
    </DataTable>
  </div>
);
