import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import React, { useState } from 'react';
import { LookupField } from '../../../components/lookup/LookupField';
import './PerfilForm.scss';


export const PerfilForm = (props) => {

    const [groupSelected, setGroupDrop] = useState([])
    const onChangeComboBoxGroup = (e) => { 
        setGroupDrop(e.value);
    }

    const btnAddPoliceEnable = !props.polices || !props.polices.oid || !props.record || !props.record.oid;
    
    return (

        <div className="perfil-form">
            <div className="p-grid">
                <div className="p-col-6">
                    <div className="p-grid">
                        <div className="p-col-3">
                            <div className="form-field-container">
                                <label>Código</label>
                                <InputText value={props.record.oid} onChange={props.changeHandler} disabled={true} />
                            </div>

                        </div>
                        <div className="p-col-12 form-field-container">
                            <label className="lxRequiredField">Nome</label>
                            <InputText value={props.record.name} onChange={props.changeHandler} name="name" />
                        </div>

                        <div className="p-col-8 form-field-container">
                            <label>Perfil filhos</label>
                            <Dropdown placeholder="Selecione um perfil" value={groupSelected} dataKey={"oid"} optionLabel="name" options={props.groups} onChange={onChangeComboBoxGroup} />
                        </div>
                        <div className="p-col-4 form-field-container row-buttons">
                            <button
                                className={"lognex-btn-highlighted btn-group"}
                                onClick={()=>{props.addGroupFilho(groupSelected)}}
                                title="Novo"
                                disabled={!groupSelected}
                            >
                                <i className="pi pi-plus"></i>
                            </button> 
                        </div>

                        <div className="p-col-12 form-field-container">
                            <label>Perfil filhos</label>

                            <DataTable
                                selectionMode="single"
                                metaKeySelection={false}
                                value={props.record.children}
                                lazy={true}
                                paginator={false}
                                scrollable={true}
                                scrollHeight="400px"
                                emptyMessage="Não foram encontrados registros"
                            >
                                {props.columnsGroups}
                            </DataTable>
                        </div>




                        <div className="p-col-12 form-field-container">
                            <div style={{ height: "25px" }} />
                        </div>
                        <div className="p-col-12 form-field-container">
                            <h3>Os dados abaixos não serão salvo com o botão de cima</h3>
                        </div>

                        <div className="p-col-12 form-field-container">
                            <label>Politicas</label>
                            <LookupField
                                value={props.polices || ""}
                                placeholder="Politicas"
                                name="name"
                                field="name"
                                onComplete={props.lookupPolicesOnComplete}
                                onInputFilter={props.lookupPolicesOnInputFilter}
                                editDisabled={props.lookupPolices.editDisabled}
                                onEditField={props.lookupPolicesOnEdit}
                                onClick={props.lookupPolicesOnClick}
                                visible={props.lookupPolices.visible}
                                modal={props.lookupPolices.modal}
                                header={props.lookupPolices.header}
                                onHide={props.lookupPolicesOnHide}
                                onConfirm={props.lookupPolicesOnConfirm}
                                onCancel={props.lookupPolicesOnCancel}
                                records={props.lookupPolices.records}
                                columns={props.lookupPolices.columns}
                                totalRecords={props.lookupPolices.totalRecords}
                                sortOrder={props.lookupPolices.order}
                                sortField={props.lookupPolices.sortField}
                                first={props.lookupPolices.first}
                                rows={props.lookupPolices.rows}
                                onPage={props.lookupPolicesOnPage}
                                onFilter={props.lookupPolicesOnFilter}
                                onSort={props.lookupPolicesOnOnSort}
                                paginator={true}
                                isMultiple={false}
                                rowsPerPageOptions={props.lookupPolices.rowsPerPageOptions}
                            />
                        </div>

                        <div className="p-col-12  form-field-container" >
                            <label>Para vincular uma politica à esse perfil, clique no botão</label>
                            <button
                                className={"lognex-btn-highlighted  "}
                                onClick={props.addPoliceGroup}
                                title="Novo"
                                disabled={btnAddPoliceEnable}>
                                <i className="pi pi-save"></i>
                            </button>
                        </div>

                        <div className="p-col-12 form-field-container">
                            <label>Todas as Politicas x Grupo - Para adicionar um novo click no botão ADD </label>
                        </div>


                        <div className="p-col-12 form-field-container">
                            <DataTable
                                selectionMode="single"
                                metaKeySelection={false}
                                value={props.policesGroup}
                                lazy={true}
                                totalRecords={props.totalRecords}
                                sortOrder={props.order}
                                sortField={props.sortField}
                                paginator={false}
                                first={props.first}
                                rows={props.rows}
                                scrollable={true}
                                scrollHeight="400px"
                                emptyMessage="Não foram encontrados registros"
                            >
                                {props.columnsPolices}
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
