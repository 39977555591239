import React from "react";
import { InputText } from "primereact/inputtext";

import "./TowerTravelActionForm.scss";
import { Divider } from "@material-ui/core";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import { LookupField } from "../../../components/lookup/LookupField";
import { DestinoListPage } from "../components/destino/DestinoListPage";
import { OriginListPage } from "../components/origem/OriginListPage";
import { Dialog } from "primereact/dialog";

export const TowerTravelForm = (props) => {
  const datetime_prev_begin =
    props.record && props.record.datetime_prev_begin
      ? moment(props.record.datetime_prev_begin).toDate()
      : null;
  const datetime_prev_end =
    props.record && props.record.datetime_prev_end
      ? moment(props.record.datetime_prev_end).toDate()
      : null;
  const datetime_begin =
    props.record && props.record.datetime_begin
      ? moment(props.record.datetime_begin).toDate()
      : null;
  const datetime_finished =
    props.record && props.record.datetime_finished
      ? moment(props.record.datetime_finished).toDate()
      : null;
  const equipment =
    props.record && props.record.equipment ? props.record.equipment : "";

  const disableBtnDelOrigin = props.selectedRecordsOrigin
    ? props.selectedRecordsOrigin.length == 0
    : true;
  const disableBtnDelDestination = props.selectedRecordsDest
    ? props.selectedRecordsDest.length == 0
    : true;

  const vehiclePlate = props.record.vehicle ? props.record.vehicle.plate : "";
  
  const carrierName = props.record.carrier ? props.record.carrier.name : null;
  const userName = props.record && props.record.equipment && props.record.equipment.userName? props.record.equipment.userName:""
  const password = props.record && props.record.equipment && props.record.equipment.password? props.record.equipment.password:""
  console.log("props.record=",props.record)
  //
  // -Data Inicio e fim
  // -Status
  // -Origem(lookup)//exite hoje no sistema
  // -Destino(lookup)//exite hoje no sistema
  // -Filiais(lookup)//exite hoje no sistema
  // -Veiculo(lookup)//exite hoje no sistema
  // -Transportadora(lookup)//exite hoje no sistema
  const footerOrigin = (
    <div>
      <button
        title="Confirmar"
        className="lognex-btn-highlighted"
        onClick={props.onConfirmeAddOrigin}
      >
        <i className="pi pi-check"></i>
      </button>
      <button
        title="Cancelar"
        className="lognex-btn-danger"
        onClick={props.onHideModalOrigin}
      >
        <i className="pi pi-times"></i>
      </button>
    </div>
  );
  const footerDestination = (
    <div>
      <button
        title="Confirmar"
        className="lognex-btn-highlighted"
        onClick={props.onConfirmeAddDestination}
      >
        <i className="pi pi-check"></i>
      </button>
      <button
        title="Cancelar"
        className="lognex-btn-danger"
        onClick={props.onHideModalDestination}
      >
        <i className="pi pi-times"></i>
      </button>
    </div>
  );

  return (
    <div className="travel-form">
      {/* Modal origin */}

      <Dialog
        className="lxImputTravelObsDialog"
        header={"Informe os dados de origem da viagem"}
        footer={footerOrigin}
        visible={props.showModalOrigin}
        // style={{ width: "55vw" }}
        modal={true}
        onHide={props.onHideModalOrigin}
      >
        <div className="p-grid">
          <div className="p-col-3 form-field-container">
            <label className="lxRequiredField">Latitude</label>

            <InputText
              className="input-route"
              value={props.inputLatitudeOrigin}
              onChange={props.changeHandlerInputLatitudeOrigin}
              placeholder={"Latitude"}
              name="inputLatitudeOrigin"
            />
          </div>

          <div className="p-col-3 form-field-container">
            <label className="lxRequiredField">Longitude</label>
            <InputText
              className="input-route"
              value={props.inputLongitudeOrigin}
              onChange={props.changeHandlerInputLongitudeOrigin}
              placeholder="Longitude"
              name="inputLongitudeOrigin"
            />
          </div>
          <div className="p-col-6 form-field-container">
            <label className="lxRequiredField">Origem</label>
            <LookupField
              value={props.originTemp}
              placeholder="Origem"
              name="descriptionOrigin"
              field="description"
              onComplete={props.lookupOriginOnComplete}
              onInputFilter={props.lookupOriginOnInputFilter}
              editDisabled={props.lookupOrigin.editDisabled}
              onEditField={props.lookupOriginOnEdit}
              onClick={props.lookupOriginOnClick}
              visible={props.lookupOrigin.visible}
              modal={false}
              header={props.lookupOrigin.header}
              onHide={props.lookupOriginOnHide}
              onConfirm={props.lookupOriginOnConfirm}
              onCancel={props.lookupOriginOnCancel}
              records={props.lookupOrigin.records}
              columns={props.lookupOrigin.columns}
              totalRecords={props.lookupOrigin.totalRecords}
              sortOrder={props.lookupOrigin.order}
              sortField={props.lookupOrigin.sortField}
              first={props.lookupOrigin.first}
              rows={props.lookupOrigin.rows}
              onPage={props.lookupOriginOnPage}
              onFilter={props.lookupOriginOnFilter}
              onSort={props.lookupOriginOnOnSort}
            />
          </div>
        </div>
      </Dialog>

      {/* Modal destination */}
      <Dialog
        className="lxImputTravelObsDialog"
        header={"Informe os dados de destino da viagem"}
        footer={footerDestination}
        visible={props.showModalDestination}
        // style={{ width: "55vw" }}
        modal={true}
        onHide={props.onHideModalDestination}
      >
        <div className="p-grid">
          <div className="p-col-3 form-field-container">
            <label className="lxRequiredField">Latitude</label>

            <InputText
              className="input-route"
              value={props.inputLatitudeDestination}
              onChange={props.changeHandlerInputLatitudeDestination}
              placeholder={"Latitude"}
              name="inputLatitudeOrigin"
            />
          </div>

          <div className="p-col-3 form-field-container">
            <label className="lxRequiredField">Longitude</label>
            <InputText
              className="input-route"
              value={props.inputLongitudeDestination}
              onChange={props.changeHandlerInputLongitudeDestination}
              placeholder="Longitude"
              name="inputLongitudeOrigin"
            />
          </div>
          <div className="p-col-6 form-field-container">
            <label className="lxRequiredField">Destino</label>
            <LookupField
              value={props.destinationTemp}
              placeholder="Destino"
              name="destinationName"
              field="name"
              onComplete={props.lookupDestinationOnComplete}
              onInputFilter={props.lookupDestinationOnInputFilter}
              editDisabled={props.lookupDestination.editDisabled}
              onEditField={props.lookupDestinationOnEdit}
              onClick={props.lookupDestinationOnClick}
              visible={props.lookupDestination.visible}
              modal={false}
              header={props.lookupDestination.header}
              onHide={props.lookupDestinationOnHide}
              onConfirm={props.lookupDestinationOnConfirm}
              onCancel={props.lookupDestinationOnCancel}
              records={props.lookupDestination.records}
              columns={props.lookupDestination.columns}
              totalRecords={props.lookupDestination.totalRecords}
              sortOrder={props.lookupDestination.order}
              sortField={props.lookupDestination.sortField}
              first={props.lookupDestination.first}
              rows={props.lookupDestination.rows}
              onPage={props.lookupDestinationOnPage}
              onFilter={props.lookupDestinationOnFilter}
              onSort={props.lookupDestinationOnOnSort}
            />
          </div>
        </div>
      </Dialog>

      <div className="p-grid">
        <div className="p-col-6">
          <div className="p-grid">
            <div className="p-col-3">
              <div className="form-field-container">
                <label>Código</label>
                <InputText
                  value={props.record.oid}
                  onChange={props.changeHandler}
                  disabled={true}
                />
              </div>
            </div>

            <div className="p-col-9 form-field-container">
              <label>Equipamento</label>
              <LookupField
                value={equipment}
                placeholder="equipamento"
                name="name"
                field="name"
                onComplete={props.lookupEquipmentOnComplete}
                onInputFilter={props.lookupEquipmentOnInputFilter}
                editDisabled={props.lookupEquipment.editDisabled}
                onEditField={props.lookupEquipmentOnEdit}
                onClick={props.lookupEquipmentOnClick}
                visible={props.lookupEquipment.visible}
                modal={props.lookupEquipment.modal}
                header={props.lookupEquipment.header}
                onHide={props.lookupEquipmentOnHide}
                onConfirm={props.lookupEquipmentOnConfirm}
                onCancel={props.lookupEquipmentOnCancel}
                records={props.lookupEquipment.records}
                columns={props.lookupEquipment.columns}
                totalRecords={props.lookupEquipment.totalRecords}
                sortOrder={props.lookupEquipment.order}
                sortField={props.lookupEquipment.sortField}
                first={props.lookupEquipment.first}
                rows={props.lookupEquipment.rows}
                onPage={props.lookupEquipmentOnPage}
                onFilter={props.lookupEquipmentOnFilter}
                onSort={props.lookupEquipmentOnOnSort}
              />
            </div>

            <div className="p-col-12 form-field-container">
              <label className="lxRequiredField">Transportadora</label>
              <LookupField
                value={carrierName}
                placeholder="Transportadora"
                name="carrierName"
                field="name"
                onComplete={props.lookupCarrierOnComplete}
                onInputFilter={props.lookupCarrierOnInputFilter}
                editDisabled={props.lookupCarrier.editDisabled}
                onEditField={props.lookupCarrierOnEdit}
                onClick={props.lookupCarrierOnClick}
                visible={props.lookupCarrier.visible}
                modal={props.lookupCarrier.modal}
                header={props.lookupCarrier.header}
                onHide={props.lookupCarrierOnHide}
                onConfirm={props.lookupCarrierOnConfirm}
                onCancel={props.lookupCarrierOnCancel}
                records={props.lookupCarrier.records}
                columns={props.lookupCarrier.columns}
                totalRecords={props.lookupCarrier.totalRecords}
                sortOrder={props.lookupCarrier.order}
                sortField={props.lookupCarrier.sortField}
                first={props.lookupCarrier.first}
                rows={props.lookupCarrier.rows}
                onPage={props.lookupCarrierOnPage}
                onFilter={props.lookupCarrierOnFilter}
                onSort={props.lookupCarrierOnOnSort}
              />
            </div> 

            <div className="p-col-6 form-field-container">
              <label className="lxRequiredField">Veículo</label>
              {/* <InputText
                value={vehiclePlate}
                placeholder="Placa do veículo"
                 onChange={props.vehicleOnInputPlate}
                name="plate"
              /> */}
                <LookupField
                value={vehiclePlate}
                placeholder="Veículo"
                name="plate"
                field="plate"
                onComplete={props.lookupVehicleOnComplete}
                onInputFilter={props.lookupVehicleOnInputFilter}
                editDisabled={props.lookupVehicle.editDisabled}
                onEditField={props.lookupVehicleOnEdit}
                onClick={props.lookupVehicleOnClick}
                visible={props.lookupVehicle.visible}
                modal={props.lookupVehicle.modal}
                header={props.lookupVehicle.header}
                onHide={props.lookupVehicleOnHide}
                onConfirm={props.lookupVehicleOnConfirm}
                onCancel={props.lookupVehicleOnCancel}
                records={props.lookupVehicle.records}
                columns={props.lookupVehicle.columns}
                totalRecords={props.lookupVehicle.totalRecords}
                sortOrder={props.lookupVehicle.order}
                sortField={props.lookupVehicle.sortField}
                first={props.lookupVehicle.first}
                rows={props.lookupVehicle.rows}
                onPage={props.lookupVehicleOnPage}
                onFilter={props.lookupVehicleOnFilter}
                onSort={props.lookupVehicleOnOnSort}
              /> 
            </div>
            <div className="p-col-6 form-field-container">
              <label>Motorista</label>
              <LookupField
                value={props.record.driver || null}
                placeholder="Motorista"
                name="driver"
                field="name" 
                onComplete={props.lookupDriverOnComplete}
                onInputFilter={props.lookupDriverOnInputFilter}
                editDisabled={props.lookupDriver.editDisabled}
                onEditField={props.lookupDriverOnEdit}
                onClick={props.lookupDriverOnClick}
                visible={props.lookupDriver.visible}
                modal={props.lookupDriver.modal}
                header={props.lookupDriver.header}
                onHide={props.lookupDriverOnHide}
                onConfirm={props.lookupDriverOnConfirm}
                onCancel={props.lookupDriverOnCancel}
                records={props.lookupDriver.records}
                columns={props.lookupDriver.columns}
                totalRecords={props.lookupDriver.totalRecords}
                sortOrder={props.lookupDriver.order}
                sortField={props.lookupDriver.sortField}
                first={props.lookupDriver.first}
                rows={props.lookupDriver.rows}
                onPage={props.lookupDriverOnPage}
                onFilter={props.lookupDriverOnFilter}
                onSort={props.lookupDriverOnOnSort}                 
              />
            </div>

            {/* datetime_prev_begin  data de previsao de saida*/}
            <div className="p-col-4 form-field-container">
              <label className="lxRequiredField">Previsão de saída</label>
              <Calendar
                showIcon={true}
                value={datetime_prev_begin}
                showTime={true}
                onChange={props.changeHandler}
                disabled={false}
                name="datetime_prev_begin"
                dateFormat="dd/mm/yy"
                hideOnDateTimeSelect={true}
              />
            </div>

            {/* datetime_prev_end  data de previsao de chegada*/}
            <div className="p-col-4 form-field-container">
              <label>Previsão de chegada</label>
              <Calendar
                showIcon={true}
                value={datetime_prev_end}
                showTime={true}
                onChange={props.changeHandler}
                disabled={false}
                name="datetime_prev_end"
                dateFormat="dd/mm/yy"
                hideOnDateTimeSelect={true}
              />
            </div>

            {/* datetime_begin  data de inicio viagem*/}
            <div className="p-col-4 form-field-container">
              <label>Data de partida</label>
              <Calendar
                showIcon={true}
                value={datetime_begin}
                showTime={true}
                onChange={props.changeHandler}
                disabled={false}
                name="datetime_begin"
                dateFormat="dd/mm/yy"
                hideOnDateTimeSelect={true}
              />
            </div>

            <div className="p-col-6 form-field-container">
              <label>Login espelhamento</label>
              <InputText
                value={userName}
                placeholder="Usuário do espelhamento"
                onChange={props.changeHandler}
                name="equipment.userName"
              />
            </div>

            <div className="p-col-6 form-field-container">
              <label>Senha espelhamento</label>
              <InputText
                value={password}
                placeholder="Senha do espelhamento"
                onChange={props.changeHandler}
                name="equipment.password"
              />
            </div>
 

            <div className="p-card-content">
              <div className="p-col-12">
                <h3>Origem*</h3>
              </div>
              <div className="p-col-12  form-field-container row-buttons">
                <button
                  className={"lognex-btn-highlighted bottons"}
                  onClick={props.showAddNewOrigin}
                  title="Novo"
                >
                  <i className="pi pi-plus"></i>
                </button>
                <button
                  className={"lognex-btn-danger bottons"}
                  onClick={props.onRemoveItemOrigin}
                  title="Remove"
                  disabled={disableBtnDelOrigin}
                >
                  <i className="pi pi-trash"></i>
                </button>
              </div>

              <div className="p-col-12  form-field-container">
                <OriginListPage {...props} />
              </div>
            </div>

            <div className="p-card-content">
              <div className="p-col-12">
                <h3>Destino*</h3>
              </div>
              <div className="p-col-12  form-field-container row-buttons">
                <button
                  className={"lognex-btn-highlighted bottons"}
                  onClick={props.showAddNewDestination}
                  title="Novo"
                >
                  <i className="pi pi-plus"></i>
                </button>
                <button
                  className={"lognex-btn-danger bottons"}
                  onClick={props.onRemoveItemDestination}
                  title="Remover"
                  disabled={disableBtnDelDestination}
                >
                  <i className="pi pi-trash"></i>
                </button>
              </div>

              <div className="p-col-12  form-field-container">
                <DestinoListPage {...props} />
              </div>
            </div>

            <div className="p-col-4 form-field-container">
              <label>Perfil da carga</label>
              <InputText
                value={props.record.perfil}
                placeholder="Tipo de perfil da carga"
                onChange={props.changeHandler}
                name="perfil"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
