import { validate } from './validations';
import {build} from '../../services/crudFactory';
import {Action} from '../../services/actions.factory';
const BASE_PATH = '/lognex/companies';
const prefix = 'crudEmpresa';
const crud = build(validate,BASE_PATH);

const newTypes = {
    SET_LOOKUP_PESSOA_VISIBLE: `${prefix}setLookupPessoaVisible`,
}

const actions = new Action(prefix,crud);

const types = Object.assign({},actions.types,newTypes);

actions.setLookupEmpresaVisible = (visible)=>{
 return{
     type:types.SET_LOOKUP_PESSOA_VISIBLE,
     visible
 }
}

    export {types, actions};