function createCNPJ(cnpj) {
    cnpj = cnpj.replace(/[^\d]+/g, '');

    if (cnpj === '') {
        return false;
    }

    if (cnpj.length === 11) {
        return true;
    }

    if (cnpj.length !== 14) {
        return false;
    }

    // Elimina CNPJs invalidos conhecidos
    if (cnpj === '00000000000000' ||
        cnpj === '11111111111111' ||
        cnpj === '22222222222222' ||
        cnpj === '33333333333333' ||
        cnpj === '44444444444444' ||
        cnpj === '55555555555555' ||
        cnpj === '66666666666666' ||
        cnpj === '77777777777777' ||
        cnpj === '88888888888888' ||
        cnpj === '99999999999999') {
        return false;
    }

    // Valida DVs
    let tamanho = cnpj.length - 2
    let numeros = cnpj.substring(0, tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) {
            pos = 9;
        }

    }
    const resultados = [0, 0];
    resultados[0] = soma % 11 < 2 ? 0 : 11 - soma % 11;

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) {
            pos = 9;
        }
    }
    resultados[1] = soma % 11 < 2 ? 0 : 11 - soma % 11;
    return resultados;
}

// function validateCNPJ(cnpj) {
//     cnpj = cnpj.replace(/[^\d]+/g, '');
//     let tamanho = cnpj.length - 2
//     const digitos = cnpj.substring(tamanho);
//     const resultados = createCNPJ(cnpj);
//     if (resultados[0] !== parseInt(digitos.charAt(0), 10)) {
//         return false;
//     }

//     if (resultados[1] !== parseInt(digitos.charAt(1), 10)) {
//         return false;
//     }
//     return true;
// }

export function validate(record) {
    const errors = [];
    record.cnpj = record.cnpj ? record.cnpj.replace(/[^\d]+/g, '') : "";
    const cnpj = record.cnpj;
    record.phone = record.phone ? record.phone.replace(/[^\d]+/g, '') : "";    
    if(record.address){
        record.address.cep = record.address.cep ? record.address.cep.replace(/[^\d]+/g, '') : "";
    }

    if (!record.razaoSocial || record.razaoSocial.trim() === "") {
        errors.push({ field: 'razaoSocial', message: 'A razão social da empresa é obrigatória!' });
    }

    if (!record.cnpj || record.cnpj.trim() === "") {
        errors.push({ field: 'cnpj', message: 'O CNPJ da empresa é obrigatório!' });
    }

    if (cnpj && cnpj.trim() !== "" &&
        (cnpj.length !== 11 && cnpj.length !== 14)) {
        errors.push({ field: 'cnpj', message: 'O CNPJ informado é inválido!' });
    }

    if (record.address.cep && record.address.cep.toString().trim() !== "" && record.address.cep.toString().length !== 8) {
        errors.push({ field: 'cep', message: 'O CEP informado não contem 8 digitos!' });
    }

    if (!record.address.cep || record.address.cep.toString().trim() === "") {
        record.cep = null;
    }

    const result = {
        ok: errors.length === 0,
        errors,
        record
    }

    return new Promise((resolve, reject) => {
        const action = result.ok ? resolve : reject;
        return action(result);
    })
}