import React from "react";
import { connect } from "react-redux";
import { Column } from "primereact/column";
import { routes } from "../../../constants";
import { Page } from "../../../components/page/Page";
import { actions } from "../cargoshipment-report.actions";
import { AppService, COMPANY_CHANGE_EVENT_NAME, BUSINESS_UNIT_CHANGE_EVENT_NAME } from "../../../services/app.service";
import { CargoShipmentReportList } from "./CargoShipmentReportList";
import "./cargoshipmentReport.scss";
import moment from "moment";
import { Filter } from "../components/Filter";

const mapStateToProps = (state) => {
  const currentUnit = state.appState.currentUnit; 
  return {
    columns: state.crudCargoShipmentReportState.columns,
    records: state.crudCargoShipmentReportState.records,
    first: state.crudCargoShipmentReportState.index,
    rows: state.crudCargoShipmentReportState.max,
    totalRecords: state.crudCargoShipmentReportState.count,
    sortField: state.crudCargoShipmentReportState.sortField,
    order: state.crudCargoShipmentReportState.sortOrder,
    currentUnit,
    filterFields: state.crudCargoShipmentReportState.filterFields
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    paginate: (paginationParams) => dispatch(actions.paginate(paginationParams)),
    sortOrder: (sortParams) => dispatch(actions.sortOrder(sortParams)),
    applyFilter: (filterParams) => {
      dispatch(actions.setType(null));
      dispatch(actions.applyFilter(filterParams));
    },
    // load: () => {
    //   dispatch(actions.load());
    // }, 
    initialDateChangeHandler: (e) => {
      dispatch(actions.initialDateChangeHandler(e));
    },
    finalDateChangeHandler: (e) => {
      dispatch(actions.finalDateChangeHandler(e));
    },
    onlyDevolucaoChangeHandler: (e) => {
      dispatch(actions.onlyDevolucaoChangeHandler(e));
    },
    downloadExportXlsx:(filters)=>{
      dispatch(actions.downloadExportXlsx(filters));
    }

  };
};

class CargoShipmentReportListPageContainer extends React.Component {


  componentDidMount() {
    AppService.on(COMPANY_CHANGE_EVENT_NAME, this);
    AppService.on(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
    // this.onApplyFilter();
  }

  componentWillUnmount() {
    AppService.off(COMPANY_CHANGE_EVENT_NAME, this);
    AppService.off(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
  }

  onReceiveAppNotifications = (eventName, params) => {
    if (eventName === COMPANY_CHANGE_EVENT_NAME || eventName === BUSINESS_UNIT_CHANGE_EVENT_NAME) {
      this.onApplyFilter();
    }
  };

  getDefaultFilter = () => {
    const filters = []
    if (this.props.filterFields.initialDate && this.props.filterFields.finalDate) {
      const initialDate = moment.utc(this.props.filterFields.initialDate.getTime()).startOf('day').toDate()
      const finalDate = moment.utc(this.props.filterFields.finalDate.getTime()).endOf('day').toDate()
      
      filters.push({
        campo: "createdAt",
        campoComplementar: "createdAt",
        valor: initialDate,
        valorComplementar: finalDate,
        tipoFiltro: "ENTRE"
    });

    }
    if (this.props.currentUnit && this.props.currentUnit.documentNumber != "0" && this.props.currentUnit.documentNumber != "-1" && this.props.currentUnit.code !="Todas") {
      filters.push({
        campo: "businessUnit.label",
        valor: this.props.currentUnit.code,
        tipoFiltro: 'EQUALS'
      })
      filters.push({
        campo: "businessUnit.documentNumber",
        valor: this.props.currentUnit.documentNumber,
        tipoFiltro: 'EQUALS'
      })
    } 

    filters.push({
      campo: "dv",//devolucao de safra
      valor: this.props.filterFields.onlyDevolucao,
      tipoFiltro: 'EQUALS'
    })

    return filters
  }
  onApplyFilter = () => {
    const filters = this.getDefaultFilter();
    
    this.props.applyFilter(filters);

  }
  exportXlsx = () => {
    const filters = this.getDefaultFilter();
    
    this.props.downloadExportXlsx(filters);

  }
  onPage = (e) => {
    this.props.paginate({
      max: e.rows,
      index: e.first,
    });
  };
  onSort = (e) => {
    this.props.sortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField,
    });
  };

  onFilter = (e) => {
    const filters = [];
    const keys = Object.keys(e.filters) || [];
    keys.forEach((key) => {
      filters.push({
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: "CONTEM",
      });
    });
    this.props.applyFilter(filters);
  };

  onRowClick = (e) => {
    const oid = e && e.data && e.data.oid;
    if (oid) {
      this.props.history.push(`${routes.PATH_PATIO_MANAGER_DOCK}/${oid}`);
    }
  };

  onInitialDateChangeHandler = (e) => {
    this.props.initialDateChangeHandler(e.value)
  }
  onFinalDateChangeHandler = (e) => {
    this.props.finalDateChangeHandler(e.value)
  }
  onlyDevolucaoChangeHandler = (e) => {
    this.props.onlyDevolucaoChangeHandler(e.value)
  }


  render() {
    const header = (
      <div>
        <h1>Embarques X Agendamentos</h1>
        <hr className="lxSplitter"></hr>

      </div>
    );


    const columns = this.props.columns.map((col) => {
      if (col.field == "centro") {
        return <Column {...col} key={col.field} style={{ width: '65px' }} />;
      }
      if (col.field == "estado") {
        return <Column {...col} key={col.field} style={{ width: '45px' }} />;
      }
      if (col.field == "placa") {
        return <Column {...col} key={col.field} style={{ width: '80px' }} />;
      }
      if (col.field == "tipoVeiculo") {
        return <Column {...col} key={col.field} style={{ width: '110px' }} />;
      }
      if (col.field == "codigoMaterial") {
        return <Column {...col} key={col.field} style={{ width: '90px' }} />;
      }
      if (col.field == "qutdeEmbalagem") {
        return <Column {...col} key={col.field} style={{ width: '75px' }} />;
      }
      if (col.field == "embalagem") {
        return <Column {...col} key={col.field} style={{ width: '90px' }} />;
      }
      if (col.field == "palletsEmbarque") {
        return <Column {...col} key={col.field} style={{ width: '70px' }} />;
      }
      if (col.field == "urgente") {
        return <Column {...col} key={col.field} style={{ width: '75px' }} />;
      }
      if (col.field == "shipmentVenda") {
        return <Column {...col} key={col.field} style={{ width: '100px' }} />;
      }
      if (col.field == "remessaVenda") {
        return <Column {...col} key={col.field} style={{ width: '100px' }} />;
      }
      if (col.field == "shipmentTransf") {
        return <Column {...col} key={col.field} style={{ width: '95px' }} />;
      }
      if (col.field == "remessaTransf") {
        return <Column {...col} key={col.field} style={{ width: '95px' }} />;
      }
      if (col.field == "dataCanhoto") {
        return <Column {...col} key={col.field} style={{ width: '90px' }} />;
      }
      if (col.field == "nroEmbarque") {
        return <Column {...col} key={col.field} style={{ width: '70px' }} />;
      }
      if (col.field == "dataCriacaoEmbarque") {
        return <Column {...col} key={col.field} style={{ width: '95px' }} />;
      }
      if (col.field == "dataAgendamento") {
        return <Column {...col} key={col.field} style={{ width: '140px' }} />;
      }
      if (col.field == "doca") {
        return <Column {...col} key={col.field} style={{ width: '140px' }} />;
      }
      if (col.field == "statusAgendamento") {
        return <Column {...col} key={col.field} style={{ width: '130px' }} />;
      }
      return <Column {...col}   key={col.field} />;
    });

    const actionsFilter = {
      initialDateChangeHandler: this.onInitialDateChangeHandler,
      finalDateChangeHandler: this.onFinalDateChangeHandler,
      onlyDevolucaoChangeHandler: this.onlyDevolucaoChangeHandler,
      applyFilter: this.onApplyFilter, 
    };


    const content = (
      <div>
        <Filter
          filterFields={this.props.filterFields}
          actions={actionsFilter}
          records={this.props.businessUnitGroupRecords}
          exportXlsx={this.exportXlsx}
        />
        <CargoShipmentReportList
          {...this.props}
          onRowClick={this.onRowClick}
          onFilter={this.onFilter}
          onPage={this.onPage}
          onSort={this.onSort}
          columns={columns}
        />
      </div>

    );
    return <Page header={header} content={content} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CargoShipmentReportListPageContainer);
