import React from 'react'
import {Switch, Route, Router} from  'react-router-dom';
import PrivateRoute from '../components/PrivateRoute';
import AdminLayout from './admin.layout.jsx';
import LoginPage from '../pages/login/login.page';
import history from '../history';
//import TestePage from '../pages/teste/TestePage';
import { CollectionOrderFormPage } from '../pages/collectionOrder'
import { RastrearDeliveryFormPage } from '../pages/rastrearDelivery'

window.myHistory = history;
class MainLayout extends React.Component{
    render(){
        return (
            <Router history={history}>
                <Switch>
                    <Route path="/login" component={LoginPage}></Route>
                    {/* <Route exact path="/teste" component={TestePage}></Route> */}
                    <Route exact path="/trackorders" component={RastrearDeliveryFormPage}></Route>
                    <Route exact path="/trackorders/:traceCode" component={RastrearDeliveryFormPage}></Route>
                    <Route exact path="/collectionorder/:company/:uid/:shipmentid/:collectionorderid" component={CollectionOrderFormPage}></Route>
                    <PrivateRoute  path="/"  component={AdminLayout}/>
                   
                </Switch>
            </Router>
        )
    }
}

export default  MainLayout; 