
import React from "react";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { CloudDone, CloudOff } from '@material-ui/icons';
import { Dropdown } from "primereact/dropdown";
import { Totalizer } from "../../../components/totalizer/Totalizer";
import Toolbar from "../../../components/toolbar/Toolbar";
import { Column } from 'primereact/column';
import { routes } from '../../../constants';
import { Page } from "../../../components/page/Page";
import { LogNFeList } from "./LogNFeList";
import { actions } from '../log-nfe.actions'
import { getValue } from "../../../i18n";
import { AppService, COMPANY_CHANGE_EVENT_NAME, BUSINESS_UNIT_CHANGE_EVENT_NAME } from "../../../services/app.service";
import { actions as appActions } from '../../../store/app';
import moment from "moment";
import { DataTable } from "primereact/datatable";
import { LogNFeFilter } from '../components/LogNFeFilter'
import './LogNFeListPageContainer.scss';
let timer = null;

const mapStateToProps = state => {
  const statusFilterOptions = state.logNFeState.statusFilterOptions;
  const currentStatusFilter = state.logNFeState.currentStatusFilter;
  const nfeCols = state.logNFeState.nfeTableColumns || []
  return {
    columns: state.logNFeState.columns,
    records: state.logNFeState.records,
    columnsNFesTable: nfeCols.map(col => { return (<Column {...col} key={col.field} />) }),
    first: state.logNFeState.index,
    rows: state.logNFeState.max,
    totalRecords: state.logNFeState.count,
    sortField: state.logNFeState.sortField,
    order: state.logNFeState.sortOrder,
    logNFeFilter: state.logNFeState.logNFeFilter.logNFeFilter,
    initialDateFilter: state.logNFeState.logNFeFilter.dataFilter.initialDateFilter,
    finalDateFilter: state.logNFeState.logNFeFilter.dataFilter.finalDateFilter,
    orderFilter: state.logNFeState.logNFeFilter.orderFilter,
    record: state.logNFeState.currentRecord,

    statusFilterOptions,
    currentStatusFilter,
  }
}
function mapDispatchToProps(dispatch) {
  return {
    paginate: paginationParams => dispatch(actions.paginate(paginationParams)),
    sortOrder: sortParams => dispatch(appActions.sortOrder(sortParams)),
    load: (filters) => {
      dispatch(actions.setType(null));
      dispatch(actions.applyFilter(filters));
      // dispatch(actions.load())
    },
    onInitialDateChangeHandler: e => {
      dispatch(actions.setDateFieldForm(e.target.name, e.value));
    },
    onFinalDateChangeHandler: e => {
      dispatch(actions.setDateFieldForm(e.target.name, e.value));
    },
    orderFilterChangeHandler: e => {
      dispatch(actions.setFieldOrderFilterForm(e.target.value));
    },
    applyFilter: filterParams => {
      dispatch(actions.setType(null));
      dispatch(actions.applyFilter(filterParams));
    },
    setExpandedLogNFeRows: expandedLogNFeRows => {
      const record = expandedLogNFeRows[1];
      dispatch(actions.setRecord(record));
    },
    setCurrentStatusFilter: (value) => {
      dispatch(actions.setCurrentStatusFilter(value));
    }
  }
}


class LogNFeListPageContainer extends React.Component {

  load=()=>{
    //busca os filtros selecionados e se não tiver algum dos filtros padrões aplica
    //filtro para a data
    //filtro para o número do pedido
    //filtro do status
    //filtro para o type deve ser sempre RECEIVE_VIRTUAL_NFE
    const filters = this.getLogNFeFilter()
    this.props.load(filters)
  }


  componentDidMount() {
    this.load();
    AppService.on(COMPANY_CHANGE_EVENT_NAME, this);
    AppService.on(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
  }
  componentWillUnmount() {
    AppService.off(COMPANY_CHANGE_EVENT_NAME, this);
    AppService.off(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
  }

  onReceiveAppNotifications = (eventName, params) => {
    if (eventName === COMPANY_CHANGE_EVENT_NAME || eventName === BUSINESS_UNIT_CHANGE_EVENT_NAME) {
      this.load();
    }
  }


  oonPage = (e) => {
    this.props.paginate({
      max: e.rows,
      index: e.first
    })
  }

  onSort = (e) => {
    this.props.sortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField
    })
  }

  getLogNFeFilter = () => {
    const normalizeDate = date => {
      return date && typeof date === "object" ? date : null;
    };
    const initialDateFilter = normalizeDate(this.props.initialDateFilter);
    const finalDateFilter = normalizeDate(this.props.finalDateFilter);
    const orderFilter = this.props.orderFilter && this.props.orderFilter.trim() !== ""
      ? this.props.orderFilter.trim()
      : "";
    const status = this.props.currentStatusFilter || null;

    let filters = [];
    if (initialDateFilter && finalDateFilter) {
      filters.push({
        campo: "dateFilter",
        valorInicial: initialDateFilter,
        valorFinal: finalDateFilter,
        tipoFiltro: "BETWEEN"
      });
    }

    if (orderFilter) {
      filters.push({
        campo: "orderFilter",
        valor: orderFilter,
        tipoFiltro: "EQUALS"
      });
    }
    if(status){
      filters.push({
        campo: "status",
        valor: status,
        tipoFiltro: "EQUALS"
      });
    }else{
      filters.push({
        campo: "status",
        valor: ['SUCCESS','ERROR'],
        tipoFiltro: "IN"
      });
    }

    filters.push({
      campo: "type",
      valor: 'RECEIVE_VIRTUAL_NFE',
      tipoFiltro: "EQUALS"
    });


    return filters;
  }

  applyLogNFeFilter = () => {
    const filterLogNFeParams = this.getLogNFeFilter();
    this.props.applyFilter(filterLogNFeParams);
    this.props.setCurrentStatusFilter(null);
  }



  changeStatusFilter = ({ value }) => {
    this.props.setCurrentStatusFilter(value);
    setTimeout(()=>{
      const logNFeFilter = this.getLogNFeFilter();
      this.props.applyFilter(logNFeFilter);
    },300)
   
  }

  render() {
    const header = (
      <div>
        <h1>LOG Integração NFe</h1>
        {/* <div>
          <Toolbar onNew={this.newHandler} newButtonStyle='highlighted'>
          </Toolbar >
        </div> */}
        <hr className="lxSplitter"></hr>
        <div className="p-grid">
          <div className="p-col-10">
            <LogNFeFilter
              orderNumber={ this.props.orderNumber }
              orderFilterChangeHandler = { this.props.orderFilterChangeHandler }
              initialDateFilter={ this.props.initialDateFilter }
              finalDateFilter={ this.props.finalDateFilter }
              onInitialDateChangeHandler={ this.props.onInitialDateChangeHandler }
              onFinalDateChangeHandler={ this.props.onFinalDateChangeHandler }
              applyLogNFeFilter={ this.applyLogNFeFilter }
            />
          </div>
        </div>
      </div>
    );
    // const oidTemplate = (rowData, column) => {
    //   return (
    //     <div className="id-link"><Link to={`${routes.PATH_LOCATION}/${rowData.oid}`}>{rowData.oid}</Link></div>
    //   )
    // }

    // const columns = this.props.columns.map(col => {
    //   return (<Column {...col} key={col.field} />)
    // })


    const dateTemplate = (rowData, column) => {
      const date = rowData[column.field];
      let formattedDate = moment(date).utc().format("DD/MM/YYYY HH:mm:ss");
      return <div style={{ textAlign: "center" }}>{formattedDate}</div>
    };

    const statusTemplate = (rowData, column) => {
      if (rowData.status === 'ERROR') {
        return <div style={{ textAlign: "center", color: "red" }}><CloudOff /></div>
      }
      return <div style={{ textAlign: "center", color: "#529664" }}><CloudDone /></div>
    };

    const orderCodeTemplate = (rowData, column) => {
      const bodyJSON = JSON.parse(rowData.tags.body);
      if (bodyJSON.orderCode) {
        return <div style={{ textAlign: "center" }}>{bodyJSON.orderCode ? bodyJSON.orderCode : ""}</div>
      } else if (bodyJSON.urlParams) {
        return <div style={{ textAlign: "center" }}>{bodyJSON.urlParams ? bodyJSON.urlParams : ""}</div>
      }
    }

    const getStatusFilterIcon = status => {
      let statusIcon = null;
      let title = "";
      switch (status) {
        case "ERROR":
          statusIcon = <div style={{ textAlign: "center", color: "red" }}><CloudOff /></div>;
          title = "Com erro";
          break;
        case "SUCCESS":
          statusIcon = <div style={{ textAlign: "center", color: "#529664" }}><CloudDone /></div>;
          title = "Sem erro";
          break;
      }
      return {
        icon: statusIcon,
        title: title
      };
    }

    const statusFilter = field => {
      const statusFilterOptions = this.props.statusFilterOptions.map(
        (option) => {
          const iconObj = getStatusFilterIcon(option.value);
          return {
            value: option.value,
            label: !option.value
              ? ("TODOS")
              : (<div key={option.label} title={iconObj.title}>{iconObj.icon}</div>)
          };
        }
      );
      return (
        <Dropdown
          style={{ width: "100%" }}
          name={field}
          value={this.props.currentStatusFilter}
          options={statusFilterOptions}
          onChange={this.changeStatusFilter}
        />
      );
    };

    const columns = this.props.columns.map((col, index) => {
      const key = `${index}-${col.field}`;
      if (col.field === "createdAt") {
        return (<Column {...col} key={key} body={dateTemplate} />);
      }
      if (col.field === "order") {
        return (<Column {...col} key={key} body={orderCodeTemplate} />);
      }
      if (col.field === "status") {
        return (
          <Column
            {...col}
            key={key}
            body={statusTemplate}
            filterElement={statusFilter(col.field)}
            style={{ width: "10%" }}
          />);
      }

      return (<Column {...col} key={col.field} />)
    })

    const setExpandedLogNFeRows = e => {
      this.props.setExpandedLogNFeRows(e.data);
    }

    const templateLogNFeTbRowExpendedRow = (rowData, e) => {
      if (rowData.status == 'ERROR') {
        // console.log(rowData)
        return (
          <div style={{ textAlign: "center" }}>
            <h3>{rowData.error}</h3>
          </div>
        )
      }

      const bodyJSON = JSON.parse(rowData.tags.body);
      const nfeJSON = bodyJSON.nfes || [];
      if (nfeJSON.length > 0) {
        return (
          <DataTable
            className="lxOrderLogNFeTable"
            selectionMode="single"
            value={nfeJSON}
            lazy={true}
            columnResizeMode="expand"
            responsive={true}
            onRowClick={() => { }}
            emptyMessage="Não foram encontrados registros"
          >
            {this.props.columnsNFesTable}
          </DataTable>
        );
      }

    }

    const totalRecords = this.props.totalRecords ? this.props.totalRecords : 0;
    const totalizers = [
      { label: `${getValue("TOTAL_RECORDS")}: `, value: totalRecords }
    ]

    const content = (
      <div>
        <Totalizer
          classNameT="lxCTeTotalizer"
          totalizers={totalizers}
        ></Totalizer>
        <LogNFeList
          {...this.props}
          onPage={this.onPage}
          onSort={this.onSort}
          columns={columns}
          logNFeRowToggleHandler={setExpandedLogNFeRows}
          logNFeTbRowExpasionTemplateHandler={templateLogNFeTbRowExpendedRow}
          logNFeTBExpandedRows={[this.props.record]}
        />
      </div>
    );




    //const content = <LogNFeList  {...this.props} onFilter={this.onFilter} onPage={this.onPage} onSort={this.onSort} columns={columns} />;
    return <div className="log-nfe-page"><Page header={header} content={content} /></div>;
    // return "oi"
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LogNFeListPageContainer);
