import React from "react";
import { connect } from "react-redux";
import Toolbar from "../../../components/toolbar/Toolbar";
import { routes } from '../../../constants';
import { Page } from "../../../components/page/Page";
import { TowerEventControlForm } from './TowerEventControlForm';
import { actions } from '../tower_event_control.actions';

const mapStateToProps = state => {
  const record = state.crudTowerEventControlState.currentRecord;
  return {
    record
  };
};

const mapDispatchToProps = dispatch => {
  return {
    changeHandler: event => {
      const target = event.target;
      const field = target.name;
      let value = target.value;
      
      dispatch(actions.removeErrors([{ field }]));
      dispatch(actions.setField(field, value));
    },
    setField: (field, value) => dispatch(actions.setField(field, value)),
    saveHandler: event => {
      dispatch(actions.save());
    },
    removeHandler: event => {
      dispatch(actions.remove());
    },
    newHandler: event => {
      dispatch(actions.makeNew());
    },
    loadById: oid => dispatch(actions.loadById(oid))
  };
};

class TowerEventControlFormPageContainer extends React.Component {

  constructor(props) {
    super(props);
    actions.afterSave = ({ oid }) => {
      this.props.history.replace(`${routes.PATH_TOWER_EVENT}/${oid}`);
    };
    actions.afterNew = () => {
      this.props.history.replace(`${routes.PATH_TOWER_EVENT}/novo`);
    };
    actions.afterRemove = () => {
      this.props.history.replace(`${routes.PATH_TOWER_EVENT}`);
    };
  }

  componentDidMount() {
    const oid = this.props.match.params.id;

    if (oid && parseInt(oid)) {
      this.props.loadById(oid);
    } else {
      this.props.newHandler();
    }
  }
  searchHandler = () => {
    this.props.history.push(`${routes.PATH_TOWER_EVENT}`)
  }

  render() {

    const title = `Evento ${this.props.record.oid || 'novo'}`
    const header = (
      <div>
        <h1>{title}</h1>
        <hr className="lxSplitter" ></hr>
        <div>
          <Toolbar
            onSave={this.props.saveHandler} saveButtonStyle='highlighted'
            onRemove={this.props.removeHandler} removeButtonStyle='danger'
            onNew={this.props.newHandler}
            onSearch={this.searchHandler}>
          </Toolbar>
        </div>
      </div>
    );

    const content = (<TowerEventControlForm record={this.props.record} changeHandler={this.props.changeHandler} />);
    return (<Page header={header} content={content} />);
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(TowerEventControlFormPageContainer);
