import React from "react";
import { connect } from "react-redux";
import Toolbar from "../../../components/toolbar/Toolbar";
import { routes } from "../../../constants";
import { Page } from "../../../components/page/Page";
import { TowerEquipmentForm } from "./TowerEquipmentForm";
import { actions } from "../tower_equipment.actions";
import { actions as actionEquipmentType } from "../../towerEquipmentType/tower_equipment_type.actions";

import { Column } from "primereact/column";

const mapStateToProps = (state) => {
  const record = state.crudTowerEquipmentState.currentRecord;
  
  
  const lookupEquipmentTypeBase = {
    records: state.crudTowerEquipmentTypeState.records,
    columns: state.crudTowerEquipmentTypeState.columns.filter((col) => col.field === "name" || col.field === "companyName").map((col) => <Column key={col.field} {...col} />),
    first: state.crudTowerEquipmentTypeState.index,
    rows: state.crudTowerEquipmentTypeState.max,
    totalRecords: state.crudTowerEquipmentTypeState.count,
    sortField: state.crudTowerEquipmentTypeState.sortField,
    order: state.crudTowerEquipmentTypeState.sortOrder,
  };
  let lookupEquipmentType = Object.assign({}, state.crudTowerEquipmentState.lookupEquipmentType, lookupEquipmentTypeBase);
   
  return {
    record,
    lookupEquipmentType
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeHandler: (event) => {
      const target = event.target;
      const field = target.name;
      let value = target.value;
      
      dispatch(actions.removeErrors([{ field }]));
      dispatch(actions.setField(field, value));
    },
    setField: (field, value) => dispatch(actions.setField(field, value)),
    saveHandler: (event) => {
      dispatch(actions.save());
    },
    removeHandler: (event) => {
      dispatch(actions.remove());
    },
    newHandler: (event) => {
      dispatch(actions.makeNew());
    },
    loadById: (oid) => dispatch(actions.loadById(oid)),
    
    
    lookupEquipmentTypeSetVisible: (visible) => dispatch(actions.setLookupEquipmentTypeVisible(visible)),
    lookupEquipmentTypePaginate: (paginationParams) => dispatch(actionEquipmentType.paginate(paginationParams)),
    lookupEquipmentTypeSortOrder: (sortParams) => dispatch(actionEquipmentType.sortOrder(sortParams)),
    lookupEquipmentTypeApplyFilter: (filterParams) => {
      dispatch(actionEquipmentType.setType(null));
      // dispatch(actionEquipmentType.applyFilter(filterParams));
      dispatch(actionEquipmentType.loadAll());
    },
    
    setEquipmentType: (equipType) => {
      const { oid, name, companyName } = equipType || {};
      dispatch(actions.setField("equipmentType", equipType ? { oid, name, companyName } : null));
    },
    
  };
};

class TowerEquipmentFormPageContainer extends React.Component {
  constructor(props) {
    super(props);
    actions.afterSave = ({ oid }) => {
      this.props.history.replace(`${routes.PATH_TOWER_EQUIPMENT}/${oid}`);
    };
    actions.afterNew = () => {
      this.props.history.replace(`${routes.PATH_TOWER_EQUIPMENT}/novo`);
    };
    actions.afterRemove = () => {
      this.props.history.replace(`${routes.PATH_TOWER_EQUIPMENT}`);
    };
  }
  
  componentDidMount() {
    const oid = this.props.match.params.id;
    
    if (oid && parseInt(oid)) {
      this.props.loadById(oid);
    } else {
      this.props.newHandler();
    }
  }
  searchHandler = () => {
    this.props.history.push(`${routes.PATH_TOWER_EQUIPMENT}`);
  };
  
  //EquipamentType
  lookupEquipmentTypeOnPage = (e) => {
    this.props.lookupEquipmentTypePaginate({
      max: e.rows,
      index: e.first,
    });
  };
  lookupEquipmentTypeOnSort = (e) => {
    this.props.lookupEquipmentTypeSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField,
    });
  };
  lookupEquipmentTypeOnFilter = (e) => {
    const filters = [];
    const keys = Object.keys(e.filters) || [];
    keys.forEach((key) => {
      filters.push({
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: "CONTEM",
      });
    });
    
    this.props.lookupEquipmentTypeApplyFilter(filters);
  };
  lookupEquipmentTypeOnClick = (e) => {
    const filters = [];
    this.props.lookupEquipmentTypeApplyFilter(filters);
    this.props.lookupEquipmentTypeSetVisible(true);
  };
  lookupEquipmentTypeOnHide = (e) => {
    this.props.lookupEquipmentTypeSetVisible(false);
  };
  lookupEquipmentTypeOnConfirm = (e) => {
    this.props.setEquipmentType(e.selection);
    this.props.lookupEquipmentTypeSetVisible(false);
  };
  lookupEquipmentTypeOnCancel = (e) => {
    this.props.setEquipmentType(null);
    this.props.lookupEquipmentTypeSetVisible(false);
  };
  
  lookupEquipmentTypeOnEdit = () => {
    if (this.props.record.equipmentType && this.props.record.equipmentType.oid) {
      this.props.history.push(`${routes.PATH_TOWER_EQUIPMENT_TYPE_TYPE}/${this.props.record.equipmentType.oid}`);
    }
  };
  
  lookupEquipmentTypeOnComplete = (e) => {};
  
  lookupEquipmentTypeOnInputFilter = (e) => {
    const value = e.value;
    if (typeof value === "string") {
      const filters = [
        {
          campo: "name",
          valor: e.value,
          tipoFiltro: "CONTEM",
        },
      ];
      
      this.props.setField("equipmentType.name", e.value);
      this.props.lookupEquipmentTypeApplyFilter(filters);
    } else {
      this.props.setEquipmentType(value);
    }
  };
  
  render() {
    const title = `Equipamento ${this.props.record.oid || "novo"}`;
    const header = (
      <div>
      <h1>{title}</h1>
      <hr className="lxSplitter"></hr>
      <div>
      <Toolbar
      onSave={this.props.saveHandler}
      saveButtonStyle="highlighted"
      onRemove={this.props.removeHandler}
      removeButtonStyle="danger"
      onNew={this.props.newHandler}
      onSearch={this.searchHandler}
      ></Toolbar>
      </div>
      </div>
      );
      
      const content = (
        <TowerEquipmentForm
        record={this.props.record}
        changeHandler={this.props.changeHandler}
        
        lookupEquipmentType={this.props.lookupEquipmentType}
        lookupEquipmentTypeOnHide={this.lookupEquipmentTypeOnHide}
        lookupEquipmentTypeOnConfirm={this.lookupEquipmentTypeOnConfirm}
        lookupEquipmentTypeOnCancel={this.lookupEquipmentTypeOnCancel}
        lookupEquipmentTypeOnPage={this.lookupEquipmentTypeOnPage}
        lookupEquipmentTypeOnSort={this.lookupEquipmentTypeOnSort}
        lookupEquipmentTypeOnFilter={this.lookupEquipmentTypeOnFilter}
        lookupEquipmentTypeOnClick={this.lookupEquipmentTypeOnClick}
        lookupEquipmentTypeOnEdit={this.lookupEquipmentTypeOnEdit}
        lookupEquipmentTypeOnComplete={this.lookupEquipmentTypeOnComplete}
        lookupEquipmentTypeOnInputFilter={this.lookupEquipmentTypeOnInputFilter}  
        
        />
        );
        return <Page header={header} content={content} />;
      }
    }
    
    export default connect(mapStateToProps, mapDispatchToProps)(TowerEquipmentFormPageContainer);
    