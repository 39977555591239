import React from "react";
import { connect } from "react-redux";
import { Column } from "primereact/column";
import { OrdensNotScriptedList } from "./OrdensNotScriptedList";
import { actions } from "../../scriptwriter.actions";
import ModalEditOrder from "../modalediteOrder/ModalEditOrder";
import { ButtonDialog } from "../../../../components/button-dialog/ButtonDialog";

import truck_success from "../../../../assets/images/icons/truck_success.png";
import truck_failure from "../../../../assets/images/icons/truck_failure.png";
import "./order-notscripted.scss";

const mapStateToProps = (state) => {
  let recordsOrder = state.crudScriptwriterState.ordersNotScripted || [];

  const records = []
  recordsOrder.forEach(o => {
    if (state.crudScriptwriterState.usersSelected && state.crudScriptwriterState.usersSelected.filter(u => String(u.user).toUpperCase() == String(o.owner.user).toUpperCase()).length > 0) {
      records.push(o);
    }
  })


  const currentUser = state.appState.currentUser;
  return {
    columns: state.crudScriptwriterState.ordersNotScriptedColumns,
    records,
    currentUser,
    ordersNotScriptedSeletected: state.crudScriptwriterState.ordersNotScriptedSeletected,
    orderEdit: state.crudScriptwriterState.orderEdit,
    unidadeRecords: state.crudScriptwriterState.unidadeRecords,
    vehicles: state.crudScriptwriterState.vehicles,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSelectedEventsChange: (rows) => {
      dispatch(actions.setOrdersNotScriptedSeletected(rows));
    },
    showBoxInfoDeliveryMap: (data) => {
      dispatch(actions.showBoxInfoDeliveryMap(data));
    },
    showEditDelivery: (order) => dispatch(actions.showEditDelivery(order)),
    onChangeInfoDelivery: (orderChanged) => dispatch(actions.onChangeInfoDelivery(orderChanged)),
    onRemoveOrder: (oid) => dispatch(actions.onRemoveOrder(oid)),
  };
};

class OrdensNotScriptedListPageContainer extends React.Component {
  onSelectedEventsChange = (e) => {
    e.originalEvent.preventDefault();
    e.originalEvent.stopPropagation();
    this.props.onSelectedEventsChange(e.value);
    this.props.showBoxInfoDeliveryMap(null);
  };
  rowClick = (e) => {
    e.originalEvent.preventDefault();
    e.originalEvent.stopPropagation();
    this.props.showBoxInfoDeliveryMap(null);
  };

  render() {

    const templateBtnRemoveOrder = (rowData, e) => {

      return (
        <div>
          <ButtonDialog
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              this.props.onRemoveOrder(rowData.oid);
            }}
            button={{
              title: "Remover",
              className: "lognex-btn-danger-inverse",
            }}
            dialog={{
              header: "Confirmação",
              modal: true,
              message: "Deseja realmente remover essa rota?",
            }}
          ></ButtonDialog>
        </div>
      );
    };


    const templateBtnViewMap = (rowData, e) => {
      return (
        <div>
          <button
            className="lognex-btn-highlighted-inverse"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              this.props.showBoxInfoDeliveryMap(rowData);
            }}
          >
            <i className={"pi pi-eye"}></i>
          </button>
        </div>
      );
    };
    const templateBtnEdit = (rowData, e) => {
      return (
        <div>
          <button
            className="lognex-btn-highlighted-inverse"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              this.props.showEditDelivery(rowData);
            }}
          >
            <i className={"pi pi-pencil"}></i>
          </button>
        </div>
      );
    }; 
 

    const templateVechileDisp = (rowData, e) => {
      const deliveryCd= rowData.pickup.virtualCD;
      if(this.props.vehicles){
        if(this.props.vehicles.filter(v=>String(v.cd).toUpperCase() === String(deliveryCd).toUpperCase()).length>0){
          // const iconVeic =  IconFactory.getIcon("truck_success", false);
          return <img src={truck_success} className={"router-icon-vehicle-disponivel"}  title={"Veículo disponível"} />
        }else{ 
          return <img src={truck_failure} className={"router-icon-vehicle-disponivel"} title={"Veículo indisponível"} />
        }
      }
    }
    let columns = [<Column key="selectorColumnOrder" selectionMode="multiple" style={{ width: "3em" }} />];
    columns = [
      ...columns,
      ...this.props.columns.map((col) => {
        if (col.field == "pickup.virtualCD") {
          return <Column {...col} key={col.field} style={{ fontSize: "11px", width: "50px" }} />;
        } else if (col.field == "vehicleDisponivel") {
          return <Column {...col} key={col.field} style={{ fontSize: "11px", width: "30px" }} body={templateVechileDisp}/>;
        } else if (col.field == "code") {
          return <Column {...col} key={col.field} style={{ fontSize: "11px", width: "80px" }} />;
        } else if (col.field == "btnEdit") {
          return <Column {...col} key={col.field} style={{ fontSize: "10px", width: "45px" }} body={templateBtnEdit} />;
        } else if (col.field == "btnViewMap") {
          return <Column {...col} key={col.field} style={{ fontSize: "10px", width: "45px" }} body={templateBtnViewMap} />;
        } else if (col.field == "btnRemoveOrder") {
          return <Column {...col} key={col.field} style={{ fontSize: "10px", width: "45px" }} body={templateBtnRemoveOrder} />;
        }
        return <Column {...col} key={col.field} style={{ fontSize: "11px" }} />;
      }),
    ];

    return (
      <div>
        {this.props.orderEdit && (
          <ModalEditOrder orderEdit={this.props.orderEdit}
            unidadeRecords={this.props.unidadeRecords} visible={this.props.orderEdit != null} onHide={() => this.props.showEditDelivery(null)} onConfirm={this.props.onChangeInfoDelivery} />
        )}

        <OrdensNotScriptedList
          rowClick={this.rowClick}
          records={this.props.records}
          onSelectedEventsChange={this.onSelectedEventsChange}
          selectedRows={this.props.ordersNotScriptedSeletected}
          columns={columns}
        />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrdensNotScriptedListPageContainer);
