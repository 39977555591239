import React from "react"; 
import "./filtro.scss";  
import { Calendar } from "primereact/calendar";

export const FiltroPendenceDoc = (props) => {
  const totalItens = props.records ? props.records.length : 0;
  return (
    <div className="filtro">
      <div className="p-grid" style={{ display: "flex" }}>
        <div className="p-md-2  form-field-container">
          <label className="lxRequiredField">De</label>
          <Calendar showIcon={true} value={props.initialDate} showTime={false} onChange={props.initialDateChangeHandler} disabled={false} name="initialDate" dateFormat="dd/mm/yy" />
        </div>
        <div className="p-md-2  form-field-container">
          <label className="lxRequiredField">Até</label>
          <Calendar showIcon={true} value={props.finalDate} showTime={false} onChange={props.finalDateChangeHandler} disabled={false} name="finallDate" dateFormat="dd/mm/yy" />
        </div>
          

        <div className="p-md-2" style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ width: "48%" }}>
            <br />
            <button className={"lognex-btn-highlighted"} title="Aplicar filtro" style={{ width: "100%", cursor: "pointer" }} onClick={props.onClickAplicarFiltro}>
              Filtrar
            </button>
          </div> 
        </div>
      </div>

      <div className="p-grid">
        <div className="p-col-12  form-field-container row-align-right-bottom">
          <label></label>
          <h3>Itens: {totalItens}</h3>
        </div>
      </div>
    </div>
  );
};
