import React from "react";
import { DataTable } from "primereact/datatable";

export const ProductCampAnyList = (props) => (
  <DataTable
    selectionMode="single"
    metaKeySelection={false} 
    value={props.records}
    lazy={true} 
    paginator={false}  
    emptyMessage="Não foram encontrados registros" 
    onRowClick={props.onRowClick}
  >
    {props.columns}
  </DataTable>
);
