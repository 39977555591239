
export function validate(record) {
    const errors = [];
    if (!record.name || record.name.trim() === "") {
        errors.push({ field: 'name', message: 'O nome do tipo é obrigatório!' });
    }

    if (!record.sigla || record.sigla.trim() === "") {
        errors.push({ field: 'sigla', message: 'A sigla do tipo é obrigatório!' });
    }

    if (!record.lognexDocumentType) {
        errors.push({ field: 'lognexDocumentTypes', message: 'O campo Lognex Tipo é obrigatório!' });
    }
   
    const result = {
        ok: errors.length === 0,
        errors,
        record
    }

    return new Promise((resolve, reject) => {
        const action = result.ok ? resolve : reject;
        return action(result);
    })
}