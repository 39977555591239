import React from "react";
import { connect } from "react-redux";
import { Column } from "primereact/column";
import { actions } from "../../../../accountConfiguration.actions";
import { UnitConfigurationList } from "./UnitConfigurationList";
import { InputText } from "primereact/inputtext";
import { LookupField } from "../../../../../../components/lookup/LookupField";
import { actions as actionUnidade } from "../../../../../unidade/unidade.actions";
import { actions as actionCarrier } from "../../../../../carrier/carrier.actions";
import "./business_unit.scss";
import { ButtonDialog } from "../../../../../../components/button-dialog/ButtonDialog";
import { UnitConfigurationEmailList } from "./UnitConfigurationEmailList";
import { UnitConfigurationCarrierList } from "./UnitConfigurationCarrierList";
import { Calendar } from 'primereact/calendar';
import {
  AppService,
  BUSINESS_UNIT_CHANGE_EVENT_NAME,
  COMPANY_CHANGE_EVENT_NAME,
} from "../../../../../../services/app.service";

import { EmailPanelConfigUnidade } from "../components/emailComponents";
import { InputSwitch } from "primereact/inputswitch";
import { WorkDayConfigUnidade } from "../components/workDayUnidade";
import moment from "moment";
const initialState = {
  columns: [
    {
      field: "businessUnit",
      header: "CNPJ",
      columnKey: "businessUnit",
      sortable: false,
      filter: true,
    },
    {
      field: "sigla",
      header: "Código",
      columnKey: "sigla",
      sortable: false,
      filter: true,
    },
    {
      field: "label",
      header: "Label",
      columnKey: "label",
      sortable: false,
      filter: true,
    },
  ],
  unitColumns: [
    {
      field: "documentNumber",
      header: "CNPJ",
      columnKey: "documentNumber",
      sortable: false,
      filter: true,
    },
    {
      field: "tradeName",
      header: "Empresa",
      columnKey: "tradeName",
      sortable: false,
      filter: true,
    },
    {
      field: "code",
      header: "Código",
      columnKey: "code",
      sortable: false,
      filter: true,
    },
    {
      field: "label",
      header: "Label",
      columnKey: "label",
      sortable: false,
      filter: true,
    },
  ],

  warehousecolumns: [
    {
      field: "email",
      header: "Email do Armazém",
      columnKey: "email",
      sortable: false,
      filter: true,
    },
  ],
  carrierEmailsColumns:[
    {
      field: "email",
      header: "Email Transportadora",
      columnKey: "email",
      sortable: false,
      filter: true,
    },
  ],
   
  carrierColumns: [
    {
      field: "name",
      header: "Transportadora",
      columnKey: "name",
      sortable: false,
      filter: true,
    },
    {
      field: "carrier",
      header: "CNPJ",
      columnKey: "carrier",
      sortable: false,
      filter: true,
    },
    {
      field: "label",
      header: "Label",
      columnKey: "label",
      sortable: false,
      filter: true,
    },
  ],
  //default values
  docaWorkDayTime: { 
    timeNextOperation:15,//sempre de 15 em 15 minutos
    "dayStartSchedule" : 1,//dias
    rangeDaysSchedule:5,
    docaRangeTime: [],
    docaWorkDays: {
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false,
    },
  }, 
};
const mapStateToProps = (state) => {
  const { businessUnitConfigurations = [] } = state.crudAccountConfigurationState.currentRecord;

  const lookupBusinessUnitBase = {
    records: state.crudUnidadeState.records,
    columns: initialState.unitColumns
      .filter((col) => col.field === "documentNumber" || col.field === "tradeName" || col.field === "code" || col.field ==="label" )
      .map((col) => <Column key={col.field} {...col} />),
    first: state.crudUnidadeState.index,
    rows: state.crudUnidadeState.max,
    totalRecords: state.crudUnidadeState.count,
    sortField: state.crudUnidadeState.sortField,
    order: state.crudUnidadeState.sortOrder,
  };
  let lookupBusinessUnit = Object.assign(
    {},
    state.crudAccountConfigurationState.lookupBusinessUnit,
    lookupBusinessUnitBase
  );
  const lookupUnitSelected = state.crudAccountConfigurationState.lookupUnitSelected;

  //
  const lookupCarrierBase = {
    records: state.crudCarrierState.records,
    columns: state.crudCarrierState.columns
      .filter((col) => col.field === "oid" || col.field === "documentNumber" || col.field === "name" || col.field === "tradeName")
      .map((col) => <Column key={col.field} {...col} />),
    first: state.crudCarrierState.index,
    rows: state.crudCarrierState.max,
    totalRecords: state.crudCarrierState.count,
    sortField: state.crudCarrierState.sortField,
    order: state.crudCarrierState.sortOrder,
    rowsPerPageOptions:[20,50,100]
  };
  let lookupCarrier = Object.assign({}, state.crudAccountConfigurationState.lookupCarrier, lookupCarrierBase);

  let docaWorkDayTime = initialState.docaWorkDayTime;
  if(state.crudAccountConfigurationState.selectedBusinessUnitRecord && state.crudAccountConfigurationState.selectedBusinessUnitRecord.docaWorkDayTime){
    docaWorkDayTime = state.crudAccountConfigurationState.selectedBusinessUnitRecord.docaWorkDayTime ;
  }

  return {
    columns: initialState.columns,
    records: businessUnitConfigurations,
    selectedBusinessUnitRecord: state.crudAccountConfigurationState.selectedBusinessUnitRecord,
    lookupBusinessUnitBase,
    lookupBusinessUnit,
    lookupUnitSelected,
    warehousecolumns: initialState.warehousecolumns,
    selectedWarehouseEmail: state.crudAccountConfigurationState.selectedWarehouseEmail,
    inputEmailWareHouse: state.crudAccountConfigurationState.inputEmailWareHouse,
    carrierColumns: initialState.carrierColumns,
    carrierEmailsColumns: initialState.carrierEmailsColumns,
    selectedCarrierRecord: state.crudAccountConfigurationState.selectedCarrierRecord,
    carrierSelected: state.crudAccountConfigurationState.carrierSelected,
    lookupCarrier,
    inputEmailCarrier: state.crudAccountConfigurationState.inputEmailCarrier,
    selectedCarrierEmail: state.crudAccountConfigurationState.selectedCarrierEmail,
    docaWorkDayTime,
    selectedRangeTimeRecord: state.crudAccountConfigurationState.selectedRangeTimeRecord,
    selectedHolidayRecord: state.crudAccountConfigurationState.selectedHolidayRecord,
    labelCarrier: state.crudAccountConfigurationState.labelCarrier,
  };
};
const mapDispatchToProps = (dispatch) => {
  const _setField = (field, value) => {
    dispatch(actions.setField(field, value));
  };
  return {
    // paginate: (paginationParams) => dispatch(actions.paginate(paginationParams)),
    setSelectedBusinessUnitRecord: (selectedRecords) => {
      dispatch(actions.setSelectedBusinessUnitRecord(selectedRecords));
      dispatch(actions.loadFreightTableById());      
    },
    lookupBusinessUnitLoad: () => {
      dispatch(actionUnidade.load());
    },
    setLookupBusinessUnitSetVisible: (visible) => {
      dispatch(actions.setLookupBusinessUnitSetVisible(visible));
    },
    setLookupUnitSelected: (unit) => {
      dispatch(actions.setLookupUnitSelected(unit));
    },
    lookupUnitApplyFilter: (filterParams) => {
      dispatch(actionUnidade.setType(null));
      dispatch(actionUnidade.applyFilter(filterParams));
    },
    setField: (field, value) => {
      _setField(field, value);
    },
    lookupBusinessUnitOnPage: (e) => {
      dispatch(actionUnidade.paginate(e));
    },
    lookupBusinessUnitOnSort: (e) => {
      dispatch(actionUnidade.sortOrder(e));
    },

    addNewBusinessUnit: () => {
      dispatch(actions.addNewBusinessUnit());
    },
    onRemoveBusinessUnitSelected: () => {
      dispatch(actions.onRemoveBusinessUnitSelected());
    },
    setSelectedWarehouseEmail: (email) => {
      dispatch(actions.setSelectedWarehouseEmail(email));
    },
    inputEmailWareHouseChangeHandler: (textEmail) => {
      dispatch(actions.inputChangeWarehouseEmail(textEmail));
    },
    addNewEmailWareHouse: () => {
      dispatch(actions.addNewEmailWareHouse());
    },
    onRemoveEmailWareHouseSelected: () => {
      dispatch(actions.onRemoveEmailWareHouseSelected());
    },
    onChangeCarrierRecordSelect: (value) => {
      dispatch(actions.onChangeCarrierRecordSelect(value));
    },
    onRemoveCarrierSelected: () => {
      dispatch(actions.onRemoveCarrierSelected());
    },

    lookupCarrierLoad: () => {
      dispatch(actionCarrier.load());
    },
    setLookupCarrierSetVisible: (visible) => {
      dispatch(actionCarrier.applyFilter([]));
      dispatch(actions.setLookupCarrierSetVisible(visible));
    },
    setLookupCarrierSelected: (carrier) => {
      dispatch(actions.setLookupCarrierSelected(carrier));
    },
    lookupCarrierApplyFilter: (filterParams) => {
      dispatch(actionCarrier.setType(null));
      dispatch(actionCarrier.applyFilter(filterParams));
    },
    setLookupCarrierInputChange: (val) => {
      dispatch(actions.setLookupCarrierInputChange(val));
    },
    lookupCarrierOnPage: (e) => {
      dispatch(actionCarrier.paginate(e));
    },
    lookupCarrierOnOnSortOrder: (e) => {
      dispatch(actionCarrier.sortOrder(e));
    },
    addNewCarrierSelected: (e) => {
      dispatch(actions.addNewCarrierSelected());
    },

    inputEmailCarrierChangeHandler: (value) => {
      dispatch(actions.inputEmailCarrierChangeHandler(value));
    },
    addNewEmailCarrier: () => {
      dispatch(actions.addNewEmailCarrier());
    },
    onRemoveEmailcarrierSelected:()=>{
      dispatch(actions.onRemoveEmailcarrierSelected())
    },
    onSelectedEmailCarrierRecordChange:(value)=>{
      dispatch(actions.onSelectedEmailCarrierRecordChange(value))
    },
    setWorkDayTimeRangeTime:(timeRange)=>{
      dispatch(actions.setWorkDayTimeRangeTime(timeRange));
    },
    showMessages: (messages, type) => dispatch(actions.messageActions.messageShowMessages(actions.makeMessages(messages, type))),
    setSelectedRangeTimeRecord:(selectedRangeTimeRecord)=>{
      dispatch(actions.setSelectedRangeTimeRecord(selectedRangeTimeRecord));
    },
    removeSelectedRangeTimeRecord:()=>{
      dispatch(actions.removeSelectedRangeTimeRecord());
    },
    onChangeWorkDay:(event)=>{
      const target = event.target;
      const field = target.name;
      let value = target.value; 
      dispatch(actions.onChangeWorkDay({field, value}));
    }, 

    changeTimeNextOperation:(v)=>{
      dispatch(actions.changeTimeNextOperation(v));
    },

    changeDayStartSchedule:(v)=>{
      dispatch(actions.changeDayStartSchedule(v));
    }, 

    changeRangeDaysSchedule:(v)=>{
      dispatch(actions.changeRangeDaysSchedule(v));
    },   

    setSelectedHolidayRecord:(v)=>{
      dispatch(actions.setSelectedHolidayRecord(v));
    }, 
    removeSelectedHolidayRecord:()=>{
      dispatch(actions.removeSelectedHolidayRecord());
    },
    setDateHoliday:(holiday)=>{
      dispatch(actions.setDateHoliday(holiday));
    },

    onClickImportHolidayXLSX:(file)=>{
      dispatch(actions.onClickImportHolidayXLSX(file)); 
    },
    setDocaWorkDayTimeUseDocks:(e)=>{
      dispatch(actions.setDocaWorkDayTimeUseDocks(e.value)); 
    },
    onChangeLabelCarrier:(e)=>{
      let code = e.currentTarget.value;
      dispatch(actions.setLabelCarrier(code));
    }
  };
};

class UnitConfigurationListPageContainer extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    AppService.on(COMPANY_CHANGE_EVENT_NAME, this);
    AppService.on(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
  }

  componentWillUnmount() {
    AppService.off(COMPANY_CHANGE_EVENT_NAME, this);
    AppService.off(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
  }

  onSelectedRecordsChangeHandler = (e) => {
    this.props.setSelectedWarehouseEmail(null);
    this.props.onChangeCarrierRecordSelect(null);
    this.props.setSelectedBusinessUnitRecord(e.value);
  };
  onReceiveAppNotifications = (eventName, params) => {
    if (eventName === COMPANY_CHANGE_EVENT_NAME || eventName === BUSINESS_UNIT_CHANGE_EVENT_NAME) {
      this.props.setLookupCarrierSelected(null);
      this.props.setLookupUnitSelected(null);
      this.props.setSelectedBusinessUnitRecord(null);
      this.props.setSelectedWarehouseEmail(null);
      this.props.onChangeCarrierRecordSelect(null);
      this.props.onSelectedEmailCarrierRecordChange(null);
      this.props.inputEmailCarrierChangeHandler(null);
    }
  };

  //Lookup BusinessUnit
  lookupBusinessUnitOnComplete = (e) => {};
  lookupBusinessUnitOnEdit = (e) => {};
  lookupBusinessUnitOnClick = (e) => {
    this.props.lookupBusinessUnitLoad();
    this.props.setLookupBusinessUnitSetVisible(true);
  };
  lookupBusinessUnitOnHide = (e) => {
    this.props.setLookupBusinessUnitSetVisible(false);
  };
  lookupBusinessUnitOnConfirm = (e) => {
    this.props.setLookupUnitSelected(e.selection);
    this.props.setLookupBusinessUnitSetVisible(false);
  };
  lookupBusinessUnitOnCancel = (e) => {
    this.props.setLookupUnitSelected(null);
    this.props.setLookupBusinessUnitSetVisible(false);
  };
  lookupBusinessUnitOnPage = (e) => {
    this.props.lookupBusinessUnitOnPage({
      max: e.rows,
      index: e.first,
    });
  };

  lookupBusinessUnitOnOnSort = (e) => {
    this.props.lookupBusinessUnitSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField,
    });
  };

  lookupBusinessUnitOnInputFilter = (e) => {
    const value = e.value;
    if (typeof value === "string") {
      const filters = [
        {
          campo: "sigla",
          valor: e.value,
          tipoFiltro: "CONTEM",
        },
      ];
      this.props.setLookupUnitSelected({ sigla: value });
      this.props.lookupUnitApplyFilter(filters);
    } else {
      this.props.setLookupUnitSelected(value);
    }
  };
  lookupBusinessUnitOnFilter = (e) => {
    const filters = [];
    const keys = Object.keys(e.filters) || [];
    keys.forEach((key) => {
      filters.push({
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: "CONTEM",
      });
    });
    this.props.lookupUnitApplyFilter(filters);
  };

  //Lookup BusinessUnit

  addNewBusinessUnit = (e) => {
    this.props.addNewBusinessUnit();
  };
  onRemoveBusinessUnitSelected = () => {
    this.props.onRemoveBusinessUnitSelected();
  };

  //Armazem
  onSelectedWarehouseRecordChange = (e) => {
   
    this.props.setSelectedWarehouseEmail(e.value);
  };

  addLabelColumn = (emails) => {
    return emails.map((email) => {
      return { email: email };
    });
  };
  inputEmailWareHouseChangeHandler = (event) => {
    this.props.inputEmailWareHouseChangeHandler(event.target.value);
  };
  addNewEmailWareHouse = () => {
    this.props.addNewEmailWareHouse();
  };
  isEmail = (email) => {
    if (email && email.trim().length > 0 && email.indexOf("@") > -1) return true;
    return false;
  };
  onRemoveEmailWareHouseSelected = () => {
    this.props.onRemoveEmailWareHouseSelected();
  };

  
  //Armazem

  //Lookup Carrier

  lookupCarrierOnComplete = (e) => {};
  lookupCarrierOnEdit = (e) => {};
  lookupCarrierOnClick = (e) => {
    this.props.lookupCarrierLoad();
    this.props.setLookupCarrierSetVisible(true);
  };
  lookupCarrierOnHide = (e) => {
    this.props.setLookupCarrierSetVisible(false);
  };
  lookupCarrierOnCancel = (e) => {
    this.props.setLookupCarrierSelected(null);
    this.props.setLookupCarrierSetVisible(false);
  };
  lookupCarrierOnConfirm = (e) => {
    this.props.setLookupCarrierSelected(e.selection);
    this.props.setLookupCarrierSetVisible(false);
  };
  lookupCarrierOnPage = (e) => {
    this.props.lookupCarrierOnPage({
      max: e.rows,
      index: e.first,
    });
  };
  lookupCarrierOnOnSort = (e) => {
    this.props.lookupCarrierOnOnSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField,
    });
  };

  lookupCarrierOnInputFilter = (e) => {
    const value = e.value; 
    if (typeof value === "string") {
      const filters = [
        {
          campo: "name",
          valor: e.value,
          tipoFiltro: "CONTEM",
        },
      ];
      this.props.setLookupCarrierInputChange(value);
      this.props.lookupCarrierApplyFilter(filters);
    } else {
      this.props.setLookupCarrierSelected(value);
    }
  };
  lookupCarrierOnFilter = (e) => {
    const filters = [];
    const keys = Object.keys(e.filters) || [];
    keys.forEach((key) => {
      filters.push({
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: "CONTEM",
      });
    }); 
    this.props.lookupCarrierApplyFilter(filters);
  };

  isValidCarrierLookupSelect = () => {
    return this.props.carrierSelected && this.props.carrierSelected.oid > 0;
  };

  enableBtnAddCarrier = () => {
    return this.isValidCarrierLookupSelect() && this.props.selectedBusinessUnitRecord;
  };
  //LookupCarrier
  addNewCarrierSelected = () => {
    this.props.addNewCarrierSelected();
  };
  onChangeCarrierRecordSelect = (e) => {
    this.props.onSelectedEmailCarrierRecordChange(null);
    this.props.onChangeCarrierRecordSelect(e.value);
  };

  //Email carrier
  inputEmailCarrierChangeHandler = (e) => {
    this.props.inputEmailCarrierChangeHandler(e.target.value);
  };
  addNewEmailCarrier = () => {
    this.props.addNewEmailCarrier();
  };

  getEmailsCarrier = () => {
    try {
      return this.addLabelColumn(this.props.selectedCarrierRecord.carrierParams.email);
    } catch (error) {
      return [];
    }
  };
  getCarrierRecord = () => {
    try {
      return this.props.selectedBusinessUnitRecord.carrierConfigurations;
    } catch (error) {
      return [];
    }
  };

  onSelectedEmailCarrierRecordChange=(e)=>{
    this.props.onSelectedEmailCarrierRecordChange(e.value);
  }
  onRemoveEmailcarrierSelected=()=>{
    this.props.onRemoveEmailcarrierSelected()
  }

  setWorkDayTimeRangeTime=(startTime,endTime)=>{
   
    const dateStart = moment(startTime);
    const dateEnd = moment(endTime);
    const hourStart = dateStart.hour(); 
    const hourEnd = dateEnd.hour();

    if(dateStart.isBefore(dateEnd)){
      let valido=true;
      //validar conflitos entre horarios
      if (this.props.selectedBusinessUnitRecord.docaWorkDayTime && this.props.selectedBusinessUnitRecord.docaWorkDayTime.docaRangeTime.length>0){
        this.props.selectedBusinessUnitRecord.docaWorkDayTime.docaRangeTime.forEach(item => {
          const hourInicio = parseInt(String(item.start).split(":")[0]);
          const hourFim = parseInt(String(item.end).split(":")[0]);
          if (hourStart > hourInicio && hourStart < hourFim){
            //entao tem um conflito de horario
            valido=false;
          }else
          // 
          if (hourEnd > hourInicio && hourEnd < hourFim){
            //entao tem um conflito de horario
            valido=false;
          }
        }); 
      }

      if(valido){
        const itemTime = { start: dateStart.format("HH:mm"), end:dateEnd.format("HH:mm") };
        this.props.setWorkDayTimeRangeTime(itemTime);
      }else{
        this.props.showMessages(["Existe conflito entre horários"], "error");
      }
    }else{
      this.props.showMessages(["Informe um intervalo de horário válido"], "error");
    }
  }

  setSelectedRangeTimeRecord=(e)=>{
    this.props.setSelectedRangeTimeRecord(e.value);
  }

  changeTimeNextOperation=(e)=>{
    let valor = parseInt(e.target.value)
    this.props.changeTimeNextOperation(valor)
  }
  changeDayStartSchedule=(e)=>{
    let valor = parseInt(e.target.value)
    this.props.changeDayStartSchedule(valor)
  } 
  changeRangeDaysSchedule=(e)=>{
    let valor = parseInt(e.target.value)
    this.props.changeRangeDaysSchedule(valor)
  }  

  setSelectedHolidayRecord=(e)=>{
    this.props.setSelectedHolidayRecord(e.value);
  }
  onClickImportHolidayXLSX=(event)=>{
    event.preventDefault();
    this.props.onClickImportHolidayXLSX(event.target.files)
    event.target.value = null  
  }

  onDownloadHolidayTemplate=()=>{ 
    const anchor = document.createElement("a");
    anchor.href ="/resources/templates/holiday_template.xlsx";
    anchor.target = "_blank";         
    document.body.append(anchor);
    anchor.click();
    anchor.remove();
  }
  

  render() {
    let columns = [<Column key="selectorColumn" selectionMode="single" style={{ width: "3em" }} />];
    columns = [
      ...columns,
      ...this.props.columns.map((col) => {
        return <Column {...col} key={col.field} />;
      }),
    ];

    let warehouseColumns = [<Column key="selectorColumn" selectionMode="single" style={{ width: "3em" }} />];
    warehouseColumns = [
      ...warehouseColumns,
      ...this.props.warehousecolumns.map((col) => {
        return <Column {...col} key={col.field} />;
      }),
    ];

    let carrierColumns = [<Column key="selectorColumn" selectionMode="single" style={{ width: "3em" }} />];
    carrierColumns = [
      ...carrierColumns,
      ...this.props.carrierColumns.map((col) => {
        return <Column {...col} key={col.field} />;
      }),
    ];
    let carrierEmailsColumns = [<Column key="selectorColumn" selectionMode="single" style={{ width: "3em" }} />];
    carrierEmailsColumns = [
      ...carrierEmailsColumns,
      ...this.props.carrierEmailsColumns.map((col) => {
        return <Column {...col} key={col.field} />;
      }),
    ];


    let warehouseRecord =
      this.props.selectedBusinessUnitRecord &&
      this.props.selectedBusinessUnitRecord.businessUnitParams &&
      this.props.selectedBusinessUnitRecord.businessUnitParams.warehouseEmail
        ? this.addLabelColumn(this.props.selectedBusinessUnitRecord.businessUnitParams.warehouseEmail)
        : [];

    const businessUnit = this.props.lookupUnitSelected && this.props.lookupUnitSelected.code ? this.props.lookupUnitSelected : "";
    const isLookupUnitSelect = this.props.lookupUnitSelected && this.props.lookupUnitSelected.oid;

    const inputEmailWareHouseIsValid = this.isEmail(String(this.props.inputEmailWareHouse));
    const isEnableBtnRemoveWhareHouse = this.props.selectedWarehouseEmail && this.props.selectedWarehouseEmail != "";
    const isEnableBtnRemoveCarrier = this.props.selectedCarrierEmail && this.props.selectedCarrierEmail != "";

    //carrierRecord
    let carrierRecord = this.getCarrierRecord();

    const inputEmailCarrierIsValid = this.isEmail(String(this.props.inputEmailCarrier));
    const carrierEmails = this.getEmailsCarrier();
 
    return (
      <div className="client-form">
        <div className={"panel-categoria"}>
          <div className="p-grid">
            <div className="p-col-6">
              <label>Unidade</label>
              <LookupField
                value={businessUnit}
                placeholder="Unidade"
                name="code"
                field="code"
                onComplete={this.lookupBusinessUnitOnComplete}
                onInputFilter={this.lookupBusinessUnitOnInputFilter}
                editDisabled={this.props.lookupBusinessUnit.editDisabled}
                onEditField={this.lookupBusinessUnitOnEdit}
                onClick={this.lookupBusinessUnitOnClick}
                visible={this.props.lookupBusinessUnit.visible}
                modal={this.props.lookupBusinessUnit.modal}
                header={this.props.lookupBusinessUnit.header}
                onHide={this.lookupBusinessUnitOnHide}
                onConfirm={this.lookupBusinessUnitOnConfirm}
                onCancel={this.lookupBusinessUnitOnCancel}
                records={this.props.lookupBusinessUnit.records}
                columns={this.props.lookupBusinessUnit.columns}
                totalRecords={this.props.lookupBusinessUnit.totalRecords}
                sortOrder={this.props.lookupBusinessUnit.order}
                sortField={this.props.lookupBusinessUnit.sortField}
                first={this.props.lookupBusinessUnit.first}
                rows={this.props.lookupBusinessUnit.rows}
                onPage={this.lookupBusinessUnitOnPage}
                onFilter={this.lookupBusinessUnitOnFilter}
                onSort={this.lookupBusinessUnitOnOnSort}
                
              />
            </div>
 
            <div className="p-col-6  align-bottom-right">
              <button
                className={"lognex-btn-highlighted"}
                onClick={this.addNewBusinessUnit}
                title="Novo"
                disabled={!isLookupUnitSelect}
                style={{ marginRight: ".25em" }}
              >
                <i className="pi pi-plus"></i>
              </button>

              <ButtonDialog
                onClick={this.onRemoveBusinessUnitSelected}
                button={{
                  title: "Remover",
                  className: "lognex-btn-danger",
                  disabled: !this.props.selectedBusinessUnitRecord,
                }}
                dialog={{
                  header: "Confirmação",
                  modal: false,
                  message: "Deseja realmente remover o registro?",
                }}
              ></ButtonDialog>
            </div>
            <div className="p-col-12">
              <UnitConfigurationList
                {...this.props}
                onSelectedRecordsChange={this.onSelectedRecordsChangeHandler}
                columns={columns}
              />
            </div>
          </div>
        </div>

        {/* Horario de funcionamento da unidade */}
        <WorkDayConfigUnidade  
          docaWorkDayTime={this.props.docaWorkDayTime} 
          selectedBusinessUnitRecord={this.props.selectedBusinessUnitRecord}
          setWorkDayTimeRangeTime={this.setWorkDayTimeRangeTime}
          selectedRangeTimeRecord={this.props.selectedRangeTimeRecord}
          setSelectedRangeTimeRecord={this.setSelectedRangeTimeRecord}
          removeSelectedRangeTimeRecord={this.props.removeSelectedRangeTimeRecord}
          onChangeWorkDay={this.props.onChangeWorkDay}
          changeTimeNextOperation={this.changeTimeNextOperation}
          changeDayStartSchedule={this.changeDayStartSchedule}
          selectedHolidayRecord={this.props.selectedHolidayRecord}
          setSelectedHolidayRecord={this.setSelectedHolidayRecord}
          removeSelectedHolidayRecord={this.props.removeSelectedHolidayRecord}
          setDateHoliday={this.props.setDateHoliday}
          onClickImportHolidayXLSX={this.onClickImportHolidayXLSX}
          onDownloadHolidayTemplate={this.onDownloadHolidayTemplate}
          setDocaWorkDayTimeUseDocks={this.props.setDocaWorkDayTimeUseDocks}
          changeRangeDaysSchedule={this.changeRangeDaysSchedule}
          />
        

        {/* WAREHOUSE */}

        <EmailPanelConfigUnidade
          title={"E-mail do Armazém"}
          disableBtnAdd={!inputEmailWareHouseIsValid}
          disableBtnRemove={!isEnableBtnRemoveWhareHouse}
          records={warehouseRecord}
          columns={warehouseColumns}
          inputEmailValue={this.props.inputEmailWareHouse}
          inputEmailChangeHandler={this.inputEmailWareHouseChangeHandler}
          onClickBtnAdd={this.addNewEmailWareHouse}
          onClickBtnRemove={this.onRemoveEmailWareHouseSelected}
          onSelectedRecordsChange={this.onSelectedWarehouseRecordChange}
          selectedEmail={this.props.selectedWarehouseEmail}
        />

        {/* CARRIER carrierConfigurations*/}
        <div className={"panel-categoria"}>
          <div className="p-grid">
            <div className="p-col-12">
              <h3>Transportadora</h3>
            </div>

            <div className="p-col-6 form-field-container">
              <br/>
              <LookupField
                value={this.props.carrierSelected}
                placeholder="Transportadora"
                name="name"
                field="name"
                onComplete={this.lookupCarrierOnComplete}
                onInputFilter={this.lookupCarrierOnInputFilter}
                editDisabled={this.props.lookupCarrier.editDisabled}
                onEditField={this.lookupCarrierOnEdit}
                onClick={this.lookupCarrierOnClick}
                visible={this.props.lookupCarrier.visible}
                modal={this.props.lookupCarrier.modal}
                header={this.props.lookupCarrier.header}
                onHide={this.lookupCarrierOnHide}
                onConfirm={this.lookupCarrierOnConfirm}
                onCancel={this.lookupCarrierOnCancel}
                records={this.props.lookupCarrier.records}
                columns={this.props.lookupCarrier.columns}
                totalRecords={this.props.lookupCarrier.totalRecords}
                sortOrder={this.props.lookupCarrier.order}
                sortField={this.props.lookupCarrier.sortField}
                first={this.props.lookupCarrier.first}
                rows={this.props.lookupCarrier.rows}
                onPage={this.lookupCarrierOnPage}
                onFilter={this.lookupCarrierOnFilter}
                onSort={this.lookupCarrierOnOnSort}
                paginator={true}
                rowsPerPageOptions={this.props.lookupCarrier.rowsPerPageOptions}
              />
            </div>

            <div className="p-col-3 form-field-container">
              <label>Código</label>
              <InputText value={this.props.labelCarrier} disabled={!this.props.carrierSelected}  placeholder="Código/label para transportadora" onChange={this.props.onChangeLabelCarrier}  />
            </div>


            <div className="p-col-3 align-bottom-right">
              <button
                disabled={!this.enableBtnAddCarrier()}
                className={"lognex-btn-highlighted"}
                onClick={this.addNewCarrierSelected}
                title="Novo"
                style={{ marginRight: ".25em" }}
              >
                <i className="pi pi-plus"></i>
              </button>

              <ButtonDialog
                onClick={this.props.onRemoveCarrierSelected}
                button={{
                  title: "Remover",
                  className: "lognex-btn-danger",
                  disabled: !this.props.selectedCarrierRecord,
                }}
                dialog={{
                  header: "Confirmação",
                  modal: false,
                  message: "Deseja realmente remover o registro?",
                }}
              ></ButtonDialog>
            </div>

            <UnitConfigurationCarrierList
              records={carrierRecord}
              onSelectedRecordsChange={this.onChangeCarrierRecordSelect}
              selectedCarrierRecord={this.props.selectedCarrierRecord}
              columns={carrierColumns}
            />
          </div>
        </div>

        {/* carrierParams EMAILS */}
        <EmailPanelConfigUnidade
          title={"Email Transportadora"}
          disableBtnAdd={!inputEmailCarrierIsValid || !this.props.selectedCarrierRecord}
          disableBtnRemove={!isEnableBtnRemoveCarrier  || !this.props.selectedCarrierRecord}
          records={carrierEmails}
          columns={carrierEmailsColumns}
          inputEmailValue={this.props.inputEmailCarrier}
          inputEmailChangeHandler={this.inputEmailCarrierChangeHandler}
          onClickBtnAdd={this.addNewEmailCarrier}
          onClickBtnRemove={this.onRemoveEmailcarrierSelected}
          onSelectedRecordsChange={this.onSelectedEmailCarrierRecordChange}
          selectedEmail={this.props.selectedCarrierEmail}
        />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UnitConfigurationListPageContainer);
