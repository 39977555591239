import React from 'react';
import { InputText } from 'primereact/inputtext';
import * as utils from '../../utils'
import './inputCurrency.scss';
export class InputCurrency extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            currencyValue: ""
        }
    }
    static getDerivedStateFromProps(props, state) {
        if(props.value === ""){
            return{
                currencyValue: ""
            }
        }
        if(props.value && props.value != state.currencyValue){
            const partial = `${props.value}`
            const dinheiro = parseFloat(partial)
            const mask = utils.applyThousandsPoints(`${dinheiro.toFixed(2).replace(".", ",")}`)

            return{
                currencyValue: mask
            }
        }
        return null

    }

    isCharacterValid = (character) => {
        const characterValids = '0123456789,'
        return characterValids.includes(character)
    }

    consistCurrencyValue = (value) => {
        value = utils.removeInvalidCaracteres(value, this.isCharacterValid)
        value = utils.applyThousandsPoints(value)
        return value;
    }

    consistValue = (currencyValue) => {
        currencyValue = utils.replaceAll(currencyValue, '.', '')
        currencyValue = utils.replaceAll(currencyValue, ',', '.')
        return parseFloat(currencyValue)
    }
    onChangeCurrencyValue = (e) => {
        const target = e.target;
        let value = e.target.value;
        if (value.length == 1) {
            value = `0,0${value}`
        } else if (value.length == 2) {
            value = `0,${value}`
        }
        target.selectionStart = value.length;
        target.selectionEnd = value.length;
        let partial = value.replace('.', '').replace(',', '')
        let length = partial.length;
        partial = partial.substring(0, length - 2) + "." + partial.substring(length - 2)

        const dinheiro = parseFloat(partial)
        const mask = utils.applyThousandsPoints(`${dinheiro.toFixed(2).replace(".", ",")}`)

        this.setState({
            currencyValue: mask
        })

        e.target.value = `${dinheiro}`
        this.props.onChange(e)
    }
    render() {
        const { props, state } = this;
        let currencyAddon = null;
        if(props.currencyCode){
            currencyAddon = (
                <span className="p-inputgroup-addon">
                        <i className="">{props.currencyCode}</i>
                    </span>
            )
        }
        return (
            <div className="lxInputCurrency">
                <div className="p-inputgroup">
                    {currencyAddon}
                    <InputText value={state.currencyValue} onBlur={this.onBlurCurrencyValue} onChange={this.onChangeCurrencyValue} name={props.name} disabled={props.disabled} />
                </div>
            </div>
        );
    }
}
