import React, { useState } from "react";
import { TowerGridBox } from "../gridBoxTower/TowerGridBox";
import "./tower_totalizadores.scss";
import { LinearScaleOutlined } from "@material-ui/icons";
import { Dialog } from "primereact/dialog";
import { ORIGEM_PORTAL_TRANSPORTADOR } from "../../constants";

export class IndicatorTravel extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isVisibleDialogSetting: false };
  }

  setVisibleDialogSetting = (val) => {
    this.setState({ isVisibleDialogSetting: val });
  };
  onConfirmar = () => {
    let towerShowTotalizers = this.props.towerShowTotalizers || {
      total: true,
      andamento: true,
      atraso: true,
      tendencia_atraso: true,
      on_time: true,
      notmirrored: true,
      finalizados: false,
      canceladas: false,
    };
    this.props.userSaveTowerTotalizers(towerShowTotalizers);
    this.setVisibleDialogSetting(false);
  };
  onCancelar = () => {
    this.props.loaderUser();
    this.setVisibleDialogSetting(false);
  };

  changeStateTotalizers = (fieldName, value) => {
    let towerShowTotalizers = this.props.towerShowTotalizers || {
      total: true,
      andamento: true,
      atraso: true,
      tendencia_atraso: true,
      on_time: true,
      notmirrored: true,
      finalizados: false,
      canceladas: false,
    };
    towerShowTotalizers[fieldName] = value; 
    this.props.setTowerShowTotalizers(towerShowTotalizers);
  };

  render() {
    let towerShowTotalizers = this.props.towerShowTotalizers || {
      total: true,
      andamento: true,
      atraso: true,
      tendencia_atraso: true,
      on_time: true,
      notmirrored: true,
      finalizados: false,
      canceladas: false,
    };
    //Se a origigem for portal transportadora, mostrar somente
    let classsStyleOrigem="";
    let classTotalizers="tower_totalizadores"
    // let classCads="card"
    if(this.props.origem && this.props.origem==ORIGEM_PORTAL_TRANSPORTADOR){
      classsStyleOrigem="small"
      classTotalizers="tower_totalizadores-small"
      // classCads="card-small"
      towerShowTotalizers.total=false;
      towerShowTotalizers.andamento=true;
      towerShowTotalizers.on_time=true;
      towerShowTotalizers.notmirrored=true;
      towerShowTotalizers.finalizados=false;
      towerShowTotalizers.canceladas=false;
      towerShowTotalizers.tendencia_atraso=false;
    }
    const footerIndicator = <div></div>;
    const footer = (
      <div>
        <button title="Confirmar" className="lognex-btn-highlighted" onClick={this.onConfirmar}>
          <i className="pi pi-check"></i>
        </button>
        <button title="Cancelar" className="lognex-btn-danger" onClick={this.onCancelar}>
          <i className="pi pi-times"></i>
        </button>
      </div>
    );

    return (
      <div className="totalizers_tower">
        <div className={classTotalizers}>
          {towerShowTotalizers.total && (
            <div className={"card"}>
              <TowerGridBox
                title={"Total"}
                isSelected={this.props.selectedTypeFilter == "total"}
                onClick={this.props.onClickTotal}
                content={this.props.indicatorTotalTravel}
                footer={footerIndicator}
                classsStyleOrigem={classsStyleOrigem}
              />
            </div>
          )}

          
          {towerShowTotalizers.atraso && (
            <div className="card">
              <TowerGridBox
                title={"Atraso"}
                isSelected={this.props.selectedTypeFilter == "atraso"}
                onClick={this.props.onClickAtraso}
                content={this.props.indicatorTotalTravelLate}
                footer={footerIndicator}
              />
            </div>
          )}
          {towerShowTotalizers.tendencia_atraso && (
            <div className="card">
              <TowerGridBox
                title={"Tendência Atraso"}
                isSelected={this.props.selectedTypeFilter == "tendencia_atraso"}
                onClick={this.props.onClickTendenciaAtraso}
                content={this.props.indicatorTotalTravelDelayTrend}
                footer={footerIndicator}
              />
            </div>
          )}
          {towerShowTotalizers.on_time && (
            <div className="card">
              <TowerGridBox
                title={"On Time"}
                isSelected={this.props.selectedTypeFilter == "on_time"}
                onClick={this.props.onClickOnTime}
                content={this.props.indicatorTotalTravelOnTime}
                footer={footerIndicator}
              />
            </div>
          )}
          
          {towerShowTotalizers.andamento && (
            <div className="card">
              <TowerGridBox
                title={"Andamento"}
                isSelected={this.props.selectedTypeFilter == "andamento"}
                onClick={this.props.onClickAndamento}
                content={this.props.indicatorTotalTravelInProgress}
                footer={footerIndicator}
              />
            </div>
          )}

          {towerShowTotalizers.notmirrored && (
            <div className="card">
              <TowerGridBox
                title={"Não Espelhada"}
                isSelected={this.props.selectedTypeFilter == "notmirrored"}
                onClick={this.props.onClickNotMirrored}
                content={this.props.indicatorTotalTravelNotMirrored}
                footer={footerIndicator}
              />
            </div>
          )}
          {towerShowTotalizers.finalizados && (
            <div className="card">
              <TowerGridBox
                title={"Finalizadas"}
                isSelected={this.props.selectedTypeFilter == "finalizados"}
                onClick={this.props.onClickFinalizadas}
                content={this.props.indicatorTotalTravelCompleted}
                footer={footerIndicator}
              />
            </div>
          )}
          {towerShowTotalizers.canceladas && (
            <div className="card">
              <TowerGridBox
                title={"Canceladas"}
                isSelected={this.props.selectedTypeFilter == "canceladas"}
                onClick={this.props.onClickCanceladas}
                content={this.props.indicatorTotalTravelCanceladas}
                footer={footerIndicator}
              />
            </div>
          )}
        </div>
        {!this.props.isFromMap && (
          <div className="setting_tower_cards_totalizers" onClick={() => this.setVisibleDialogSetting(!this.state.isVisibleDialogSetting)}>
            <LinearScaleOutlined className="iconTotalizer"></LinearScaleOutlined>
          </div>
        )}
        <Dialog
          className="setting_tower_cards_totalizers_dialog"
          header={"Configurar Totalizadores"}
          footer={footer}
          visible={this.state.isVisibleDialogSetting}
          modal={true}
          onHide={() => this.onCancelar()}
        >
          <div className="content">
            <div
              className="inputCheckTotalizer"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                this.changeStateTotalizers("total", !towerShowTotalizers.total);
              }}
            >
              <input type="checkbox" id="checkTotal" checked={towerShowTotalizers.total} name="checkTotal" onChange={()=>{}}/>
              <label htmlFor={`checkTotal`}>{"Total"}</label>
            </div>

            <div
              className="inputCheckTotalizer"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                this.changeStateTotalizers("andamento", !towerShowTotalizers.andamento);
              }}
            >
              <input type="checkbox" id="checkAndamento" name="checkAndamento" checked={towerShowTotalizers.andamento}  onChange={()=>{}}/>
              <label htmlFor={`checkAndamento`}>{"Andamento"}</label>
            </div>

            <div
              className="inputCheckTotalizer"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                this.changeStateTotalizers("atraso", !towerShowTotalizers.atraso);
              }}
            >
              <input type="checkbox" id="checkAtraso" name="checkAtraso" checked={towerShowTotalizers.atraso}  onChange={()=>{}}/>
              <label htmlFor={`checkAtraso`}>{"Atraso"}</label>
            </div>

            <div
              className="inputCheckTotalizer"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                this.changeStateTotalizers("tendencia_atraso", !towerShowTotalizers.tendencia_atraso);
              }}
            >
              <input type="checkbox" id="checkTendenciaAtraso" name="checkTendenciaAtraso" checked={towerShowTotalizers.tendencia_atraso}  onChange={()=>{}}/>
              <label htmlFor={`checkTendenciaAtraso`}>{"Tendência Atraso"}</label>
            </div>

            <div
              className="inputCheckTotalizer"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                this.changeStateTotalizers("on_time", !towerShowTotalizers.on_time);
              }}
            >
              <input type="checkbox" id="checkOnTime" name="checkOnTime" checked={towerShowTotalizers.on_time}  onChange={()=>{}}/>
              <label htmlFor={`checkOnTime`}>{"On Time"}</label>
            </div>

            <div
              className="inputCheckTotalizer"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                this.changeStateTotalizers("notmirrored", !towerShowTotalizers.notmirrored);
              }}
            >
              <input type="checkbox" id="checkNotMirrored" name="checkNotMirrored" checked={towerShowTotalizers.notmirrored}  onChange={()=>{}}/>
              <label htmlFor={`checkNotMirrored`}>{"Não Espelhada"}</label>
            </div>

            <div
              className="inputCheckTotalizer"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                this.changeStateTotalizers("finalizados", !towerShowTotalizers.finalizados);
              }}
            >
              <input type="checkbox" id="checkFinalizadas" name="checkFinalizadas" checked={towerShowTotalizers.finalizados}  onChange={()=>{}}/>
              <label htmlFor={`checkFinalizadas`}>{"Finalizadas"}</label>
            </div>

            <div
              className="inputCheckTotalizer"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                this.changeStateTotalizers("canceladas", !towerShowTotalizers.canceladas);
              }}
            >
              <input type="checkbox" id="checkCanceladas" name="checkCanceladas" checked={towerShowTotalizers.canceladas}  onChange={()=>{}}/>
              <label htmlFor={`checkCanceladas`}>{"Canceladas"}</label>
            </div>
          </div>
        </Dialog>
      </div>
    );
  }
}
