import { types } from "./tower_travel.actions";
import { Reducer } from "../../services/reducer.factory";
import { CREATED, TRAVELING } from "../towerDashboard/constants";

const initialState = {
  columns: [
    {
      field: "oid",
      header: "Código",
      columnKey: "oid",
      sortable: true,
      filter: true,
    },
    {
      field: "perfil",
      header: "Perfil",
      columnKey: "perfil",
      sortable: true,
      filter: true,
    },
    {
      field: "current_status.status",
      header: "Status", //
      columnKey: "current_status.status",
      sortable: true,
      filter: true,
    },
    {
      field: "datetime_begin",
      header: "Data Partida",
      columnKey: "datetime_begin",
      sortable: true,
      filter: true,
    },
    {
      field: "datetime_finished",
      header: "Data Chegada",
      columnKey: "datetime_finished",
      sortable: true,
      filter: true,
    },
    {
      field: "equipment.name",
      header: "Equipamento",
      columnKey: "equipment.name",
      sortable: true,
      filter: true,
    },
    {
      field: "equipment.equipmentType.name",
      header: "Tipo Equipamento",
      columnKey: "equipment.equipmentType.name",
      sortable: true,
      filter: true,
    },
    {
      field: "route.current_point.lat",
      header: "Latitude (Atual)",
      columnKey: "route.current_point.lat",
      sortable: true,
      filter: true,
    },
    {
      field: "route.current_point.lng",
      header: "Longitude (Atual)",
      columnKey: "route.current_point.lng",
      sortable: true,
      filter: true,
    },
    {
      field: "route.start_point.lat",
      header: "Latitude (Partida)",
      columnKey: "route.start_point.lat",
      sortable: true,
      filter: true,
    },
    {
      field: "route.start_point.lng",
      header: "Longitude (Partida)",
      columnKey: "route.start_point.lng",
      sortable: true,
      filter: true,
    },
    {
      field: "route.end_point.lat",
      header: "Latitude (Destino)",
      columnKey: "route.end_point.lat",
      sortable: true,
      filter: true,
    },
    {
      field: "route.end_point.lng",
      header: "Longitude (Destino)",
      columnKey: "route.end_point.lng",
      sortable: true,
      filter: true,
    },
    {
      field: "eventRecord.createdAt",
      header: "Data Cadastro",
      columnKey: "eventRecord.createdAt",
      sortable: true,
      filter: true,
    },
  ],
  destinationColumns: [
    {
      field: "description",
      header: "Destino",
      columnKey: "description",
      sortable: true,
      filter: true,
    },{
      field: "cidade_state",
      header: "Cidade",
      columnKey: "cidade_state",
      sortable: true,
      filter: true,
    },
    {
      field: "lat_lng",
      header: "Lat/Long",
      columnKey: "lat_lng",
      sortable: true,
      filter: true,
    } 
  ],
  originColumns: [
    {
      field: "description",
      header: "Origem",
      columnKey: "description",
      sortable: true,
      filter: true,
    },{
      field: "address.City",
      header: "Cidade-UF",
      columnKey: "address.City",
      sortable: true,
      filter: true,
    },
    {
      field: "lat_lng",
      header: "Lat/Long",
      columnKey: "lat_lng",
      sortable: true,
      filter: true,
    } 
  ],

  currentRecord: {
    oid: "",
    equipment: null,
    carrier: null,
    vehicle: null,
    driver: null, 
    origin: [],
    destinations: [],
    observation: [],
    pathTraveled: [], //caminho percorrido {lat:null,lng:null}
    datetime_prev_begin: null,
    datetime_prev_end: null,
    datetime_begin: null,
    datetime_finished: null,
    perfil: "",
    status_time: "",
    travel_status: TRAVELING,
    userLoginEspelhamento:"",
    passwordEspelhamento:""
  },

  lookupEquipment: {
    visible: false,
    modal: true,
    header: "Equipamento",
  },

  lookupCarrier: {
    visible: false,
    modal: true,
    header: "Transportadora",
  },
  lookupOrigin: {
    visible: false,
    modal: true,
    header: "Origem",
  },
  lookupDestination: {
    visible: false,
    modal: true,
    header: "Destino",
  },
  
  lookupVehicle: {
    visible: false,
    modal: false,
    header: "Veículo",
  },
  lookupDriver: {
    visible: false,
    modal: false,
    header: "Motorista",
  },

  showModalOrigin: false,
  showModalDestination: false,
  inputLatitudeOrigin: "",
  inputLongitudeOrigin: "",
  originTemp: null,

  selectedRecordsOrigin: [],
  selectedRecordsDest: [],

  showModalDestination: false,
  destinationTemp: null,
  inputLatitudeDestination: "",
  inputLongitudeDestination: "",
};

const reducer = new Reducer(initialState, types);

export function CRUDTowerTravelReducer(state, action) {
  state = reducer.execute(state, action);
  return execute(state, action);
}

function execute(state, action) {
  let result = state;
  switch (action.type) {
    case types.SET_LOOKUP_EQUIPMENT_VISIBLE:
      return setLookupEquipmentVisible(state, action);
    case types.SET_LOOKUP_CARRIER_VISIBLE:
      return setLookupCarrierVisible(state, action);
    case types.SET_LOOKUP_ORIGIN_VISIBLE:
      return setLookupOriginVisible(state, action);
    case types.SET_LOOKUP_DESTINATION_VISIBLE:
      return setLookupDestinationVisible(state, action);

    case types.SET_VISIBLE_MODAL_ORIGIN:
      return setModalOriginVisible(state, action);

    case types.SET_VALUE_LATITUDE_ORIGIN:
      return setInputLatitudeOrigin(state, action);
    case types.SET_VALUE_LONGITUDE_ORIGIN:
      return setInputLongitudeOrigin(state, action);
    case types.ADD_NEW_ORIGIN:
      return addNewOrigin(state, action);

    case types.SET_ORIGIN_LOOKUP:
      return setOriginTemp(state, action);

    case types.SET_SELECTED_ROWS_ORIGIN:
      return setSelectedRowsOrigin(state, action);

    case types.REMOVE_SELECTED_ROWS_ORIGIN:
      return onRemoveItemOrigin(state, action);

    case types.SET_SELECTED_ROWS_DESTINATION:
      return setSelectedRowsDestination(state, action);

    case types.REMOVE_SELECTED_ROWS_DESTINATION:
      return onRemoveItemDestination(state, action);

    case types.SET_VISIBLE_MODAL_DESTINATION:
      return setModalDestinationVisible(state, action);

    case types.SET_DESTINATION_FROM_LOOKUP:
      return setDestinationTemp(state, action);

    case types.SET_VALUE_LATITUDE_DESTINATION:
      return setInputLatitudeDestination(state, action);

    case types.SET_VALUE_LONGITUDE_DESTINATION:
      return setInputLongitudeDestination(state, action);

    case types.ADD_NEW_DESTINATION:
      return addNewDestination(state, action);

    
    case types.SET_LOOKUP_VEHICLE_VISIBLE:
      return setlookupVehicleVisible(state, action);

    case types.SET_LOOKUP_DRIVER_VISIBLE:
      return setlookupDriverVisible(state, action);

    default:
      return result;
  }
}

function setlookupDriverVisible(state, action) {
  let lookupDriver = state.lookupDriver;
  lookupDriver = Object.assign({}, lookupDriver, {
    visible: action.visible,
  });
  return Object.assign({}, state, { lookupDriver });
}

function setlookupVehicleVisible(state, action) {
  let lookupVehicle = state.lookupVehicle;
  lookupVehicle = Object.assign({}, lookupVehicle, {
    visible: action.visible,
  });
  return Object.assign({}, state, { lookupVehicle });
}
 

function addNewDestination(state, action) {
  let currentRecord = state.currentRecord;

  let newDestination = {
    oid: state.destinationTemp.oid,
    name: state.destinationTemp.name,
    description: state.destinationTemp.description,
    type: state.destinationTemp.type,
    lat: state.inputLatitudeDestination,
    lng: state.inputLongitudeDestination,
    distance: 0,
    deliveryDate: null,
    uid: state.destinationTemp.oid,
    deliveryForecastDate: null,
  };

  if (!currentRecord.destinations) {
    currentRecord.destinations = [];
  }

  currentRecord.destinations.push(newDestination);
  return Object.assign({}, state, { currentRecord });
}

function setInputLatitudeDestination(state, action) {
  return Object.assign({}, state, {
    inputLatitudeDestination: action.inputLatitudeDestination,
  });
}
function setInputLongitudeDestination(state, action) {
  return Object.assign({}, state, {
    inputLongitudeDestination: action.inputLongitudeDestination,
  });
}

function setDestinationTemp(state, action) {
  const destinationTemp = action.destination;
  return Object.assign({}, state, { destinationTemp });
}

function setModalDestinationVisible(state, action) {
  return Object.assign({}, state, { showModalDestination: action.visible });
}

function onRemoveItemDestination(state, action) {
  const selectedRecordsDest = state.selectedRecordsDest;
  if (!selectedRecordsDest || selectedRecordsDest.length == 0) return state;

  let currentRecord = state.currentRecord;
  const newLitDestination = [];
  for (const origin of currentRecord.destinations) {
    const isRemove =
      selectedRecordsDest.filter((item) => {
        return item.oid == origin.oid;
      }).length > 0;

    if (!isRemove) {
      newLitDestination.push(origin);
    }
  }

  currentRecord.destinations = newLitDestination;
  return Object.assign({}, state, { currentRecord });
}

function onRemoveItemOrigin(state, action) {
  const selectedRecordsOrigin = state.selectedRecordsOrigin;
  if (!selectedRecordsOrigin || selectedRecordsOrigin.length == 0) return state;

  let currentRecord = state.currentRecord;
  const newLitOrigin = [];
  for (const origin of currentRecord.origin) {
    const isRemove =
      selectedRecordsOrigin.filter((item) => {
        return item.oid == origin.oid;
      }).length > 0;

    if (!isRemove) {
      newLitOrigin.push(origin);
    }
  }

  currentRecord.origin = newLitOrigin;

  return Object.assign({}, state, { currentRecord });
}

function setInputLatitudeOrigin(state, action) {
  return Object.assign({}, state, {
    inputLatitudeOrigin: action.inputLatitudeOrigin,
  });
}
function setInputLongitudeOrigin(state, action) {
  return Object.assign({}, state, {
    inputLongitudeOrigin: action.inputLongitudeOrigin,
  });
}

function setLookupEquipmentVisible(state, action) {
  let lookupEquipment = state.lookupEquipment;
  lookupEquipment = Object.assign({}, lookupEquipment, {
    visible: action.visible,
  });
  return Object.assign({}, state, { lookupEquipment });
}

function setModalOriginVisible(state, action) {
  return Object.assign({}, state, { showModalOrigin: action.visible });
}

function setLookupCarrierVisible(state, action) {
  let lookupCarrier = state.lookupCarrier;
  lookupCarrier = Object.assign({}, lookupCarrier, { visible: action.visible });
  return Object.assign({}, state, { lookupCarrier });
}
function setLookupOriginVisible(state, action) {
  let lookupOrigin = state.lookupOrigin;
  lookupOrigin = Object.assign({}, lookupOrigin, { visible: action.visible });
  return Object.assign({}, state, { lookupOrigin });
}
function setLookupDestinationVisible(state, action) {
  let lookupDestination = state.lookupDestination;
  lookupDestination = Object.assign({}, lookupDestination, {
    visible: action.visible,
  });
  return Object.assign({}, state, { lookupDestination });
}

function addNewOrigin(state, action) {
  let currentRecord = state.currentRecord;

  let newOrigin = {
    oid: state.originTemp.oid,
    name: state.originTemp.name,
    description: state.originTemp.description,
    type: state.originTemp.type,
    lat: state.inputLatitudeOrigin,
    lng: state.inputLongitudeOrigin,
  };

  currentRecord.origin.push(newOrigin);
  //adicionar a posição default inicial {lat:null,lng:null}
  currentRecord.pathTraveled=[{lat:state.inputLatitudeOrigin,lng:state.inputLongitudeOrigin}]

  return Object.assign({}, state, { currentRecord });
}

function setOriginTemp(state, action) {
  const originTemp = action.origin;
  return Object.assign({}, state, { originTemp });
}

function setSelectedRowsOrigin(state, action) {
  const selectedRecordsOrigin = action.selectedRows;
  return Object.assign({}, state, { selectedRecordsOrigin });
}

function setSelectedRowsDestination(state, action) {
  const selectedRecordsDest = action.selectedRows;
  return Object.assign({}, state, { selectedRecordsDest });
}
