import React from 'react';
import PackingConverterListPageContainer from './PackingConverterListPageContainer';

export class PackingConverterListPage extends React.Component{
    
    render(){
      return (
        <PackingConverterListPageContainer {...this.props} />
      )
    }
}