
    import React from 'react';
    import VehicleTypeFormPageContainer from './VehicleTypeFormPageContainer';
    
    export class VehicleTypeFormPage extends React.Component{
        render(){
            return (
                <VehicleTypeFormPageContainer {...this.props}/>
            )
        }
    }
    