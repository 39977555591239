
import React from 'react';
import { connect } from 'react-redux';
import { actions } from '../../../portalCarrier.actions';
import { Page } from "../../../../../components/page/Page";
import Toolbar from '../../../../../components/toolbar/Toolbar';
import { routes } from '../../../../../constants';
import { Column } from 'primereact/column';
import { TableRowTreeview, TableRowTreeviewTrasito } from './TableRowTreeviewTransito';
import { CargoShipmentInStatusTrasitoList } from './CargoShipmentInStatusTrasitoList';
import { numberToCurrency } from '../../../../../utils';
import storage from '../../../../../services/stoage.service'
import { AppService, COMPANY_CHANGE_EVENT_NAME } from '../../../../../services/app.service';
import { CARGO_IN_TRANSITO } from '../../../constantes';
import moment from 'moment';
import UpdateDataEntregaPortaCarrierl from '../components/updateDataEntregaPortaCarrierl';
import "./cargoShipTransito.scss"
import { InputText } from 'primereact/inputtext';
import MapViewRouteDialog from '../../../../../components/map-view-route-dialog/MapViewRouteDialog';
import locationIcon from "../../../../../assets/images/icons/location.png"; 

const mapStateToProps = state => {
  const records = state.crudPortalCarrierState.cargoShipStatusInTransito;
  let columns = [];
  const columnsRowTreeviewInTransitoTemplate = state.crudPortalCarrierState.columnsRowTreeviewInTransitoTemplate;

  const user = storage.getUser();

  if (!user.carrier) {

    columns = [...state.crudPortalCarrierState.columnsShipStatusInTransito, {
      field: "carrier.name",
      header: "Transportadora",
      columnKey: "carrier.name",
      sortable: false,
      filter: false
    }]
  } else {
    columns = [...state.crudPortalCarrierState.columnsShipStatusInTransito]
  }
  const dialogMapViewRouteVisible = state.crudPortalCarrierState.dialogMapViewRouteVisible;
  const idEmbarqueShowMap = state.crudPortalCarrierState.idEmbarqueShowMap;
  
  return {
    records,
    columns,
    first: state.crudPortalCarrierState.index,
    rows: state.crudPortalCarrierState.max,
    totalRecords: state.crudPortalCarrierState.count,
    sortField: state.crudPortalCarrierState.sortField,
    order: state.crudPortalCarrierState.sortOrder,

    expandedRows: state.crudPortalCarrierState.expandedRows,
    columnsRowTreeviewInTransitoTemplate,
    link: state.crudPortalCarrierState.link,
    carrier: state.crudPortalCarrierState.carrier,
    showModalUploadImg: state.crudPortalCarrierState.showModalUploadImg,
    delivery: state.crudPortalCarrierState.delivery,
    nroNfe: state.crudPortalCarrierState.nroNfe,
    codeShipment: state.crudPortalCarrierState.codeShipment,
    nroEmbarque: state.crudPortalCarrierState.nroEmbarque,
    dialogMapViewRouteVisible,
    idEmbarqueShowMap
  }
}

const mapDispatchToProps = dispatch => {
  return {

    paginate: paginationParams => {
      if (paginationParams.index !== undefined) {
        dispatch(actions.setIndex(paginationParams.index));
      }
      if (paginationParams.page !== undefined) {
        dispatch(actions.setPage(paginationParams.page));
      }
      if (paginationParams.max !== undefined) {
        dispatch(actions.setMax(paginationParams.max));
      }
    },
    cleanPaginate: () => {
      dispatch(actions.setIndex(0));
      dispatch(actions.setPage(0));
      dispatch(actions.setMax(50));
    },
    setField: (field, value) => dispatch(actions.setField(field, value)),
    changeHandler: event => {
      const target = event.target;
      const field = target.name;
      let value = target.value;
      dispatch(actions.removeErrors([{ field }]));
      dispatch(actions.setField(field, value));
    },

    getAllCargoShipmentInTransito: () => dispatch(actions.getAllCargoShipmentInTransito()),
    setCurrentScreen: (currentScreen) => dispatch(actions.setCurrentScreen(currentScreen)),
    setExpandedRows: (expandedRows) => {
      dispatch(actions.setExpandedRows(expandedRows));
    },
    openDialogIframeCollectionOrder: (visible, link) => {
      dispatch(actions.openDialogIframeCollectionOrder({ visible, link }));
    },
    setRecordsCollectionOrders: (filters) => dispatch(actions.setRecordsCollectionOrders(filters)),
    setShowModalUploadImg: visible => dispatch(actions.setShowModalUploadImg(visible)),
    setCodeOrder: order => dispatch(actions.setCodeOrder(order)),
    setLinkAddCanhoto: linkCanhoto => dispatch(actions.setLinkAddCanhoto(linkCanhoto)),

    setDeliveryFilter: delivery => dispatch(actions.setDeliveryFilter(delivery)),
    setNroNfeFilter: nroNfe => dispatch(actions.setNroNfeFilter(nroNfe)),
    setCodeShipmentFilter: codeShipment => dispatch(actions.setCodeShipmentFilter(codeShipment)),
    setNroEmbarqueFilter: nroEmbarque => dispatch(actions.setNroEmbarqueFilter(nroEmbarque)),
    setVisibleDialogMapViewRoute: (visible) => dispatch(actions.setVisibleDialogMapViewRoute(visible)),
    setIdEmbarToViewRouteOnMap: (idEmbarqueShowMap) => dispatch(actions.setIdEmbarToViewRouteOnMap(idEmbarqueShowMap)),
  }
}

class CargoShipmentInStatusTrasitoPageContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      dialogWidth: '100%',
      dialogHeight: '98%',
      windowHeight: 800,
    };
  }

  componentDidMount() {
    AppService.on(COMPANY_CHANGE_EVENT_NAME, this);
    this.props.setCurrentScreen(CARGO_IN_TRANSITO);
    this.props.cleanPaginate();
    this.props.getAllCargoShipmentInTransito();
    this.clearFilter();
  }


  componentWillUnmount() {
    AppService.off(COMPANY_CHANGE_EVENT_NAME, this);
    this.props.cleanPaginate();
  }

  onReceiveAppNotifications = (eventName, params) => {
    if (eventName === COMPANY_CHANGE_EVENT_NAME) {
      this.props.cleanPaginate();
      this.props.setCurrentScreen(CARGO_IN_TRANSITO);
      this.props.getAllCargoShipmentInTransito();
      this.clearFilter();
    }
  };
  searchHandler = () => {
    this.props.history.push(`${routes.PATH_CARRIER_PORTAL}`)
  }

  setExpandedRowsByRowClick = (e) => {
    if (e.data && e.data.length > 1) {
      this.props.setExpandedRows([e.data[1]]);
    } else if (e.data && e.data.length == 1) {
      this.props.setExpandedRows([e.data[0]]);
    } else {
      this.props.setExpandedRows([e.data]);
    }
  };
  setExpandedRowsClick = (e) => {
    if (e.data && e.data.length > 1) {
      this.props.setExpandedRows([e.data[1]]);
    } else {
      this.props.setExpandedRows([e.data]);
    }
  };
  onRowsClick = (e) => {
    if (e.data && e.data.length > 1) {
      this.props.setExpandedRows([e.data[1]]);
    } else {
      this.props.setExpandedRows([e.data]);
    }
  };
  onSetCodeOrder = (order) => {
    this.props.setLinkAddCanhoto(this.props.expandedRows[0].link);
    this.props.setCodeOrder(order);
  }

  onPage = (e) => {
    this.props.paginate({
      max: e.rows,
      index: e.first
    })
    this.props.getAllCargoShipmentInTransito();
  }
  setDeliveryFilter = (e) => {
    let resul = String(e.target.value).trim();
    this.props.setDeliveryFilter(resul);
  }
  setNroNfeFilter = (e) => {
    let resul = String(e.target.value).trim();
    this.props.setNroNfeFilter(resul);
  }
  setCodeShipmentFilter = (e) => {
    let resul = String(e.target.value).trim();
    this.props.setCodeShipmentFilter(resul);
  }
  setNroEmbarqueFilter = (e) => {
    let resul = String(e.target.value).trim();
    if (resul.length > 0) {
      try {
        let nroEmb = parseInt(resul)
        this.props.setNroEmbarqueFilter(nroEmb);
      } catch (error) {
        this.props.setNroEmbarqueFilter("");
      }
    } else {
      this.props.setNroEmbarqueFilter("");
    }
  }

  clearFilter = () => {
    this.props.setNroEmbarqueFilter("");
    this.props.setCodeShipmentFilter("");
    this.props.setNroNfeFilter("");
    this.props.setDeliveryFilter("");
  }
  openViewRouteOnMap = (cargoShipViewMap) => {
    this.props.setIdEmbarToViewRouteOnMap(cargoShipViewMap.oid);
    this.props.setVisibleDialogMapViewRoute(true);
  }

  
  render() {

    const title = `Embarques em Trânsito`
    const header = (
      <div>
        <h1>{title}</h1>
        <hr className="lxSplitter" ></hr>
        <div>
          <Toolbar onSearch={this.searchHandler} />
        </div>
      </div>
    );

    const dateTemplate = (rowData, column) => {
      const date = rowData[column.field];
      const formattedDate = moment(date).format("DD/MM/YYYY");
      return <span style={{ textAlign: "center" }}  >{formattedDate}</span>;
    };
    const btnAceiteTemplate = (rowData, column) => {
      try {

        return (
          <div style={{ textAlign: "center" }}>
            <button
              className="lognex-btn-highlighted"
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                this.props.openDialogIframeCollectionOrder(true, rowData.link);
                // 
                this.props.history.push(`${routes.PATH_CARRIER_PORTAL_COLLECTION_ORDER}`)
              }}

              title={"Abrir pagina para dar o aceite"}
            >
              <i className="pi pi-pencil"></i>
            </button>
          </div>
        );
      } catch (error) {
        return <div></div>;
      }
    };
    const dateEntregaTemplate = (rowData, column) => {
      let className = "no-pendencia-ocorren";
      if (!possuiOcorrenEntrega(rowData)) {
        className = "pendencia-ocorren"
      }

      if (rowData.occurrence && rowData.occurrence.dateOccurrence) {
        const date = rowData.occurrence.dateOccurrence;
        const formattedDate = moment(date).format("DD/MM/YYYY");
        return <span style={{ textAlign: "center" }} className={className}>{formattedDate}</span>;
      } else {
        return <div></div>
      }
    };

    const pickupDateTemplate = (rowData, column) => {
      let className = "no-pendencia-ocorren";
      if (!possuiOcorrenEntrega(rowData)) {
        className = "pendencia-ocorren"
      }

      if (rowData.occurrence && rowData.occurrence.pickupDate) {
        const date = rowData.occurrence.pickupDate;
        const formattedDate = moment(date).format("DD/MM/YYYY");
        return <span style={{ textAlign: "center" }} className={className}>{formattedDate}</span>;
      } else {
        return <div></div>
      }
    };


    const possuiCanhotoTemplate = (rowData, column) => {
      try {

        if (rowData.occurrence && rowData.occurrence.image) {
          return (
            <span className="no-pendencia-ocorren">
              Sim
            </span>
          );
        }
        return <span className="pendencia-ocorren" >Não</span>;
      } catch (error) {
        return <span>Não</span>;
      }
    };
    const bodyDefault = (rowData, column) => {
      let className = "no-pendencia-ocorren";
      if (!possuiOcorrenEntrega(rowData)) {
        className = "pendencia-ocorren"
      }
      return (
        <span className={className}>{rowData[`${column.field}`]}</span>
      );
    };
    const ocorrenceDescriptTemplate = (rowData, column) => {
      let className = "no-pendencia-ocorren";
      if (!possuiOcorrenEntrega(rowData)) {
        className = "pendencia-ocorren"
      }
      if (rowData["occurrence"] && rowData["occurrence"].description) {
        return (
          <span className={className}>{rowData["occurrence"].description}</span>
        );
      } else {
        return <div></div>
      }
    };

    const possuiOcorrenEntrega = (rowData) => {
      return rowData.occurrence && rowData.occurrence.image;
    }


    const btnIncludeCanhotoTemplate = (rowData, column) => {
      try {

        return (
          <div style={{ textAlign: "center" }}>
            <button
              className="lognex-btn-highlighted"
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                this.onSetCodeOrder(rowData)
                this.props.setShowModalUploadImg(true);
              }}

              title={"Incluir data da entrega e canhoto"}
            >
              <i className="pi pi-upload"></i>
            </button>
          </div>
        );
      } catch (error) {
        return <div></div>;
      }
    };



    const templateExpandedRow = (rowData, e) => {
      const records = rowData.orders;
      let newColumns = []
      if (!rowData.dv) {
        newColumns = this.props.columnsRowTreeviewInTransitoTemplate.filter(c => c.field != "occurrence.pickupDate")
      } else {
        newColumns = this.props.columnsRowTreeviewInTransitoTemplate;
      }

      let columnsTreeview = newColumns.map((col) => {
        if (col.field === "nroNfe" && rowData.dv) {
          col.header = "NFe Ref Venda"
        }

        if (col.field === "code") {
          return <Column {...col} key={col.field} style={{ width: "100px" }} body={bodyDefault} />;
        }

        if (col.field === "occurrence.image") {
          return <Column {...col} key={col.field} style={{ width: "150px" }} body={possuiCanhotoTemplate} />;
        }
        if (col.field === "nroNfe") {
          return <Column {...col} key={col.field} style={{ width: "120px" }} body={bodyDefault} />;
        }
        if (col.field === "shipmentCode") {
          return <Column {...col} key={col.field} style={{ width: "130px" }} body={bodyDefault} />;
        }

        if (col.field === "btnCanhoto") {
          return <Column {...col} key={col.field} style={{ width: "80px" }} body={btnIncludeCanhotoTemplate} />;
        }

        if (col.field === "occurrence.dateOccurrence") {
          return <Column {...col} key={col.field} style={{ width: "130px" }} body={dateEntregaTemplate} />;
        }
        if (col.field === "occurrence.pickupDate") {
          return <Column {...col} key={col.field} style={{ width: "130px" }} body={pickupDateTemplate} />;
        }
        if (col.field === "occurrence.description") {
          return <Column {...col} key={col.field} style={{ width: "130px" }} body={ocorrenceDescriptTemplate} />;
        }
        return <Column {...col} key={col.field} body={bodyDefault} />;
      });


      return (
        <div>
          <h3>Para incluir o canhoto ou a data de entregas, clique no botão "Alterar" na linha do pedido!</h3>
          <TableRowTreeviewTrasito records={records} columns={columnsTreeview} />
        </div>
      );
    };


    const valorCustoTemplate = (rowData, column) => {
      try {
        const valor = Number(rowData[column.field]);
        let formattedDate = "";
        if (valor) {
          formattedDate = numberToCurrency(valor, "R$");
        }
        return (
          <div style={{ textAlign: "center", color: "black" }}>
            {formattedDate}
          </div>
        );
      } catch (error) {
        return <div></div>;
      }
    };


    const getMapViewRouteDialog = () => {
      if (this.props.dialogMapViewRouteVisible) {
        return <MapViewRouteDialog
          dialogMapViewRouteVisible={this.props.dialogMapViewRouteVisible}
          idEmbarqueShowMap={this.props.idEmbarqueShowMap}
          onHideDialog={(e) => {
            this.props.setVisibleDialogMapViewRoute(false);
          }} />
      }
      return <></>
    }
    const btnViewOnMapTemplate = (rowData, column) => {
      try {

        return (
          <div style={{ textAlign: "center" }}>

            <button
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation(); 
                this.openViewRouteOnMap(rowData);
              }}
              className={"btnAcoes"}
              title="Ver no Mapa">
              <i>
                <img src={locationIcon} style={{ height: "26px" }}></img>
              </i>
            </button>
          </div>


        );
      } catch (error) {
        return <div></div>;
      }
    };

    const columns = this.props.columns.map((col) => {

      if (col.field === "freightValue") {
        return <Column {...col} key={col.field} style={{ width: "120px" }} body={valorCustoTemplate} />;
      }

      if (col.field === "oid") {
        return <Column {...col} key={col.field} style={{ width: "80px" }} />;
      }
      if (col.field === "status") {
        return <Column {...col} key={col.field} style={{ width: "90px" }} />;
      }

      if (col.field === "pickupDate") {
        return <Column {...col} key={col.field} style={{ width: "110px" }} body={dateTemplate} />;
      }

      if (col.field === "deliveryForeCastDate") {
        return <Column {...col} key={col.field} style={{ width: "110px" }} body={dateTemplate} />;
      }
      if (col.field === "darAceite") {
        return <Column {...col} key={col.field} style={{ width: "80px" }} body={btnAceiteTemplate} />;
      }
      if (col.field === "btnViewOnMap") {
        return <Column {...col} key={col.field} style={{ width: "80px" }} body={btnViewOnMapTemplate} />;
      }
      return <Column {...col} key={col.field} />;
    });



    const content = (
      <div>
        {this.props.showModalUploadImg &&
          <UpdateDataEntregaPortaCarrierl />
        }
         {getMapViewRouteDialog()}
        <div className="p-grid-filter">

          <div className="form-field-container field-filter">
            <label>Número pedido</label>
            <InputText maxlength="15" style={{ width: "120px" }} value={this.props.delivery} onChange={this.setDeliveryFilter} />
          </div>

          <div className="form-field-container field-filter">
            <label>Número NFe</label>
            <InputText maxlength="15" style={{ width: "120px" }} value={this.props.nroNfe} onChange={this.setNroNfeFilter} />
          </div>

          <div className="form-field-container field-filter">
            <label>Código Shipment</label>
            <InputText maxlength="15" style={{ width: "120px" }} value={this.props.codeShipment} onChange={this.setCodeShipmentFilter} />
          </div>

          <div className="form-field-container field-filter">
            <label>Número Embarque</label>
            <InputText maxlength="15" style={{ width: "120px" }} value={this.props.nroEmbarque} onChange={this.setNroEmbarqueFilter} />
          </div>

          <div className="form-field-container field-filter">
            <button
              title="Filtrar"
              onClick={this.props.getAllCargoShipmentInTransito}
              style={{ marginRight: ".25em" }}
              className="lognex-btn-highlighted btl-filtrar">
              {"Filtrar"}
            </button>
          </div>

        </div>

        <CargoShipmentInStatusTrasitoList
          records={this.props.records}
          rowToggleHandler={this.setExpandedRowsClick}
          rowExpandButtonOnClick={this.setExpandedRowsByRowClick}
          expandedRows={this.props.expandedRows}
          templateExpandedRow={templateExpandedRow}
          onRowsClick={this.onRowsClick}
          onPage={this.onPage}
          first={this.props.first}
          rows={this.props.rows}
          totalRecords={this.props.totalRecords}
          sortField={this.props.sortField}
          order={this.props.order}
          columns={columns} />
      </div>
    );

    return (<Page header={header} content={content} />);
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CargoShipmentInStatusTrasitoPageContainer);