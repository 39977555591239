import {types} from './loader.actions.js';

const initialState = {
    show:false
}
export function loaderReducer(state = initialState, action){
    if(action.type === types.LOADER_SHOW || action.type === types.LOADER_HIDE ){
        return Object.assign({},state,{show:action.show})
    }
    return state;
}