import { validate } from './validations';
import {build} from '../../services/crudFactory';
import {Action} from '../../services/actions.factory'; 
const BASE_PATH = '/lognex/campanha-anymarket';
const crud = build(validate,BASE_PATH);
const prefix = 'crudCampanhaAnymarket';

const actions = new Action(prefix,crud);

const newTypes = {
    SET_INPUT_SKU: `${prefix}setInputSku`, 
    ADD_NEW_PRODUCT: `${prefix}addNewProduct`,
    ADD_REMOVE_PRODUCT: `${prefix}removeProduct`,
    SET_INPUT_ZIP_CODE_START: `${prefix}setInputZipCodeStart`,
    SET_INPUT_ZIP_CODE_END: `${prefix}setInputZipCodeEnd`,
    ADD_NEW_ZIP_CODE_RANGE: `${prefix}addNewZipCodeRange`,
    REMOVE_ZIP_CODE_RANGE: `${prefix}onRemoveZipCodeRange`,
    ADD_CLEAR_FIELDS: `${prefix}clearFields`,
}

const types = Object.assign({}, actions.types, newTypes);



actions.clearFields = () => {
    return {
        type: types.ADD_CLEAR_FIELDS
    }
}

actions.removeProduct = (sku) => {
    return {
        type: types.ADD_REMOVE_PRODUCT,
        sku
    }
}

actions.addNewProduct = () => {
    return {
        type: types.ADD_NEW_PRODUCT
    }
}

actions.setInputSku = (sku) => {
    return {
        type: types.SET_INPUT_SKU,
        sku
    }
}
 

actions.setInputZipCodeStart = (zipcode) => {
    return {
        type: types.SET_INPUT_ZIP_CODE_START,
        zipcode
    }
}


actions.setInputZipCodeEnd = (zipcode) => {
    return {
        type: types.SET_INPUT_ZIP_CODE_END,
        zipcode
    }
}

actions.addNewZipCodeRange = () => {
    return {
        type: types.ADD_NEW_ZIP_CODE_RANGE
    }
}


actions.onRemoveZipCodeRange = (zipcodeRange) => {
    return {
        type: types.REMOVE_ZIP_CODE_RANGE,
        zipcodeRange
    }
}


export {types, actions};
