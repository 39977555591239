import React from 'react';
import { connect } from 'react-redux';
import Toolbar from "../../../components/toolbar/Toolbar";
import { actions } from '../packing.actions';
import { routes } from '../../../constants';
import { Page } from "../../../components/page/Page";
import { PackingForm } from './PackingForm';

const mapStateToProps = state => {
  const record = state.crudPackingState.currentRecord;
  return {
    record,
    rotations: [
      { label: 'Selecione uma opção', value: null },
      { label: 'Somente Horizontal', value: 'HORIZONTALLY' },
      { label: 'Somente Vertical', value: 'VERTICALLY' },
      { label: 'Ambos', value: 'BOTH' }
    ]
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setField: (field, value) => dispatch(actions.setField(field, value)),
    changeHandler: event => {
      const target = event.target;
      const field = target.name;
      let value = target.value;
      dispatch(actions.removeErrors([{ field }]));
      dispatch(actions.setField(field, value));
    },
    saveHandler: event => {
      dispatch(actions.save());
    },
    removeHandler: event => {
      dispatch(actions.remove());
    },
    newHandler: event => {
      dispatch(actions.makeNew());
    },
    loadById: oid => dispatch(actions.loadById(oid))
  }
}

class PackingFormPageContainer extends React.Component {

  constructor(props) {
    super(props);
    actions.afterSave = ({ oid }) => {
      this.props.history.replace(`${routes.PATH_PACKING}/${oid}`);
    };
    actions.afterNew = () => {
      this.props.history.replace(`${routes.PATH_PACKING}/novo`);
    };
    actions.afterRemove = () => {
      this.props.history.replace(`${routes.PATH_PACKING}`);
    };
  }

  componentDidMount() {
    const oid = this.props.match.params.id;

    if (oid && parseInt(oid)) {
      this.props.loadById(oid);
    } else {
      this.props.newHandler();
    }
  }
  searchHandler = () => {
    this.props.history.push(`${routes.PATH_PACKING}`)
  }

  onChangeHandlerCalculate = e => {
    this.props.changeHandler(e);

    const height = parseFloat(e.target.name === 'height' ? e.target.value ? e.target.value : 0 : this.props.record.height ? this.props.record.height : 0);
    const width = parseFloat(e.target.name === 'width' ? e.target.value ? e.target.value : 0 : this.props.record.width ? this.props.record.width : 0);
    const depth = parseFloat(e.target.name === 'depth' ? e.target.value ? e.target.value : 0 : this.props.record.depth ? this.props.record.depth : 0);

    let m3cubage = (height * width * depth) / 1000000;
    this.props.setField("cubage", m3cubage.toFixed(4));
  }

  render() {
    const content = (
      <PackingForm
        changeHandler={this.props.changeHandler}
        changeHandlerCalculate={this.onChangeHandlerCalculate}
        record={this.props.record}
        rotations={this.props.rotations}


      />
    );
    const title = `Embalagem ${this.props.record.oid || 'nova'}`
    const header = (
      <div>
        <h1>{title}</h1>
        <div>
          <Toolbar
            onSave={this.props.saveHandler} saveButtonStyle='highlighted'
            onRemove={this.props.removeHandler} removeButtonStyle='danger'
            onNew={this.props.newHandler}
            onSearch={this.searchHandler}>
          </Toolbar>
        </div>
      </div>
    );
    return (<Page header={header} content={content} />);
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PackingFormPageContainer);