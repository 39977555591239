
export function validate(record) {
    const errors = [];

    if (!record.label) {
        errors.push({ field: 'label', message: 'O Label é obrigatório!' });
    }
    if (!record.mainBusinessUnit) {
        errors.push({ field: 'mainBusinessUnit', message: 'O Main principal é obrigatório!' });
    }
    
    if ( !record.businessUnits || record.businessUnits.length == 0 ) {
        errors.push({ field: 'businessUnit', message: 'A unidade é obrigatória!' });
    }

    const result = {
        ok: errors.length === 0,
        errors,
        record
    }

    return new Promise((resolve, reject) => {
        const action = result.ok ? resolve : reject;
        return action(result);
    })
}