
import React from 'react';
import { InputText } from 'primereact/inputtext';

export const LocationForm = (props) => {

    return (
        <div className="location-form">
            <div className="p-grid">
                <div className="p-col-6">
                    <div className="p-grid">

                        <div className="p-col-12 form-field-container">
                            <label>Código</label>
                            <InputText value={props.record.oid} onChange={props.changeHandler} name="oid" disabled={true} />
                        </div>
                        <div className="p-col-12 form-field-container">
                            <label>Nome</label>
                            <InputText value={props.record.name} onChange={props.changeHandler} name="name" disabled={true} />
                        </div>
                        <div className="p-col-12 form-field-container">
                            <label>Código IBGE</label>
                            <InputText value={props.record.ibgeCode} onChange={props.changeHandler} name="ibgeCode" disabled={true} />
                        </div>
                        <div className="p-col-12 form-field-container">
                            <label>Latitude</label>
                            undefined
                        </div>
                        <div className="p-col-12 form-field-container">
                            <label>CEP Final</label>
                            <InputText value={props.record.finalZipCode} onChange={props.changeHandler} name="finalZipCode" disabled={true} />
                        </div>
                        <div className="p-col-12 form-field-container">
                            <label>Tipo</label>
                            <InputText value={props.record.type} onChange={props.changeHandler} name="type" disabled={true} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};