import React from 'react'
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { ButtonDialog } from '../../../../components/button-dialog/ButtonDialog'

export class LxRouteRangeDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            start: 0,
            end: 0,
            records: props.select.series,
            currentType: props.select.currentType,
            types: props.select.types,
            selectedRanges: []
        }
    }

    tableSelectionChangeHandler = (event) => {
        this.setState(() => {
            return {
                selectedRanges: event.value
            }
        })
    }
    selectOnChangeHandler = (event) => {
        this.setState(() => {
            return {
                currentType: event.value
            }
        })
    }
    onChangeHandler = (event) => {
        const target = event.target;
        this.setState(() => {
            return {
                [target.name]: target.value
            }
        })
    }
    onAdd = () => {
        const start = parseFloat(this.state.start) || 0;
        const end = parseFloat(this.state.end) || 0;
        if (start < end) {
            const range = {
                start,
                end
            }
            let records = this.state.records.filter(record => record.start !== range.start && record.end != range.end) || [];
            records.push(range);
            records.sort((record1, record2) => {
                if (record1.start === record2.start) {
                    return 0
                }
                if (record1.start > record2.start) {
                    return 1
                }
                return -1
            })
            this.setState(() => {
                return { records, start: 0, end: 0 }
            })
        }
    }

    onRemove = () => {
        let records = [...this.state.records];
        const selectedRanges = this.state.selectedRanges;
        records = records.filter(record => {
            return selectedRanges.find(range => range.start === record.start && range.end === record.end) == null;
        })
        this.setState(() => {
            return { records }
        })
    }

    onConfirm = () => {
        this.props.actions.onConfirm && this.props.actions.onConfirm({
            rangeType: this.state.currentType,
            series: this.state.records
        })
        this.setState({
            start: 0,
            end: 0,
            records: [],
            currentType: null,
            types: this.props.select.types,
            selectedRanges: []
        })
    }
    onInsert = () => {
        this.props.actions.onAdd && this.props.actions.onAdd({
            rangeType: this.state.currentType,
            series: this.state.records
        })
        this.setState({
            start: 0,
            end: 0,
            records: [],
            currentType: null,
            types: this.props.select.types,
            selectedRanges: []
        })
    }
    onCancel = () => {
        this.setState({ records: [], currentType: null })
        this.props.actions.onCancel && this.props.actions.onCancel()
    }

    render() {
        const { props } = this;
        const columns = props.table.columns;
        return (
            <Dialog
                header={props.header}
                visible={props.visible}
                style={{ width: '50vw' }}
                modal={props.modal}
                baseZIndex={1}
                onHide={props.actions && props.actions.onHide ? props.actions.onHide : () => { console.log("dialog.onHide must be implemented") }}
                footer={
                    <div>
                        <button
                            title="Confirmar"
                            className={props.confirmDisabled !== true ? "lognex-btn-highlighted" : ""}
                            onClick={this.onConfirm}
                            disabled={this.state.records.length === 0 || !this.state.currentType} >
                            <i className="pi pi-check"></i>
                        </button>

                        <button
                            title="Cancelar"
                            className="lognex-btn-danger"
                            onClick={this.onCancel} >
                            <i className="pi pi-times"></i>
                        </button>
                    </div>}>

                <div className="p-col-12">
                    <div className="p-grid">
                        <div className="p-col-12 form-field-container">
                            <label className="lxRequiredField">Tipo</label>
                            <Dropdown
                                style={{ width: "auto" }}
                                placeholder="Selecione um tipo"
                                value={this.state.currentType}
                                options={this.state.types}
                                onChange={this.selectOnChangeHandler}
                            />
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-5 form-field-container">
                            <label>Valor Inicial</label>
                            <InputText name="start" value={this.state.start} onChange={this.onChangeHandler} />
                        </div>
                        <div className="p-col-5 form-field-container">
                            <label>Valor Final</label>
                            <InputText name="end" value={this.state.end} onChange={this.onChangeHandler} />
                        </div>
                        <div className="p-col-2" style={{ display: "flex", alignItems: "flex-end", justifyContent: "flex-end" }}>
                            <button
                                title="Incluir"
                                className="lognex-btn-highlighted"
                                onClick={this.onAdd}
                                disabled={false} >
                                <i className="pi pi-plus"></i>
                            </button>
                            <ButtonDialog
                                onClick={this.onRemove}
                                button={{
                                    title: "Remover selecionado(s)",
                                    className: "lognex-btn-danger",
                                    disabled: this.state.selectedRanges.length === 0,
                                    style : { marginLeft: ".50em"  }
                                }}
                                dialog={{
                                    header: 'Confirmação',
                                    modal: false,
                                    message: props.removeSelectionMessage ? props.removeSelectionMessage : "Deseja realmente remover o registro(s)?",
                                }}
                            ></ButtonDialog>
                            
                        </div>
                    </div>

                    <div className="p-grid">
                        <div className="p-col-12 form-field-container">
                            <label>Valores</label>
                            <DataTable
                                value={this.state.records}
                                selection={this.state.selectedRanges}
                                onSelectionChange={this.tableSelectionChangeHandler}
                                emptyMessage="informe os valores que deseja aplicar"
                            >
                                {columns}
                            </DataTable>
                        </div>
                    </div>
                </div>
            </Dialog>
        )
    }
}