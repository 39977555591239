import { types } from "./policesGroup.actions";
import { Reducer } from "../../services/reducer.factory";

const initialState = {
  columns: [
    
    {
      field: "group.oid",
      header: "ID Perfil",
      columnKey: "group.oid",
      sortable: true,
      filter: false,
    },
    {
      field: "group.name",
      header: "Perfil",
      columnKey: "group.name",
      sortable: true,
      filter: false,
    }
    /*,
    {
      field: "polices.oid",
      header: "ID Politica",
      columnKey: "polices.oid",
      sortable: true,
      filter: false,
    },
    {
      field: "polices.name",
      header: "Politica",
      columnKey: "polices.name",
      sortable: true,
      filter: false,
    }*/
  ],
  columnsPolices: [
    {
      field: "oid",
      header: "Código",
      columnKey: "oid",
      sortable: false,
      filter: false,
    },
    {
      field: "polices.oid",
      header: "ID Politica",
      columnKey: "polices.oid",
      sortable: false,
      filter: false,
    },
    {
      field: "polices.name",
      header: "Politica",
      columnKey: "polices.name",
      sortable: false,
      filter: false,
    },
    {
      field: "remove",
      header: "",
      columnKey: "remove",
      sortable: false,
      filter: false,
    }
  ],
  lookupPolices: {
    visible: false,
    modal: true,
    header: "Politicas",
  },
  lookupGroup: {
    visible: false,
    modal: true,
    header: "Perfil",
  },
  currentRecord: {
    polices: { name: "" },
    group: { name: "" },
  },
};

const reducer = new Reducer(initialState, types);

export function CRUDPolicesGroupReducer(state, action) {
  state = reducer.execute(state, action);
  return execute(state, action);
}

function execute(state, action) {
  let result = state;
  switch (action.type) {
    case types.SET_LOOKUP_POLICES_VISIBLE:
      return setLookupPolicesVisible(state, action);
    case types.SET_LOOKUP_GROUP_VISIBLE:
      return setLookupGroupVisible(state, action);
    case types.SET_RECORS_LOAD_BY_ID:
      return setRecordsLoadByGroupId(state, action);

    default:
      return result;
  }
}


function setRecordsLoadByGroupId(state, action) {
  let policesGroup = state.policesGroup;
  policesGroup = action.records
  return Object.assign({}, state, { policesGroup });
}

function setLookupGroupVisible(state, action) {
  let lookupGroup = state.lookupGroup;

  lookupGroup = Object.assign({}, lookupGroup, { visible: action.visible });
  return Object.assign({}, state, { lookupGroup });
}

function setLookupPolicesVisible(state, action) {
  let lookupPolices = state.lookupPolices;

  lookupPolices = Object.assign({}, lookupPolices, { visible: action.visible });
  return Object.assign({}, state, { lookupPolices });
}
