import api from '../../../services/api.service';
export const types = {
    SET_VALUE: 'PercentFreightOnBillReportSetValue',
    SET_CNPJ: 'PercentFreightOnBillReportSetCNPJ',
    SET_NAME: 'PercentFreightOnBillReportSetName',
    SET_PERIOD: 'PercentFreightOnBillReportSetPeriod',
    SET_SHOW_CARRIER: 'PercentFreightOnBillReportetSethowCarrier',
    SET_SHOW_PERIOD: 'PercentFreightOnBillReportetSethowPeriod',
    LOADER_SHOW: 'loaderShow',
    LOADER_HIDDEN: 'loaderHide'
}

export function setValue(value){
    return {
        type: types.SET_VALUE,
        value
    }
}


export function setCNPJ(carrier){
    return {
        type: types.SET_CNPJ,
        carrier,
    }
}

export function setName(name){
    return {
        type: types.SET_NAME,
        name,
    }
}

export function setPeriod(period){
    return {
        type: types.SET_PERIOD,
        period,
    }
}

export function setShowCarrier(showCarrier){
    return {
        type: types.SET_SHOW_CARRIER,
        showCarrier,
    }
}

export function setShowPeriod(showPeriod){
    return {
        type: types.SET_SHOW_PERIOD,
        showPeriod,
    }
}

export function loaderShow(){
    return {
        type: types.LOADER_SHOW,
        async: true,
    }
}

export function loaderHide(){
    return {
        type: types.LOADER_HIDDEN,
        async: false,
    }
}

export function load(){
    return (dispatch, getState)=>{
        const state = getState().percentFreightOnBillReportState.percentFreightOnBill;
        const filter = {
            carrierDocument:state.filters.carrier.documentNumber || "",
            period: state.filters.period.period || "CURRENT_MONTH"
        }
        const URL = `/api/v1/lognex/reports/indicators/percentFreightOnBill`;
        dispatch(loaderShow())
        api.post(URL,filter)
        .then(({data})=>{

            let percentFreightOnBill = (data.data.cteTotalValue * 100) / data.data.nfeTotalValue

            percentFreightOnBill = percentFreightOnBill || 0;
            
            //const {value = 0 } = data.data
            //dispatch(setValue(`${value.toFixed(2)} %`))
            dispatch(setValue(`${percentFreightOnBill.toFixed(2)} %`))
            dispatch(loaderHide())
        })
        .catch((error)=>{
            console.log(error)
            dispatch(loaderHide())
        })
    }
}

