import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import React from "react";
import moment from 'moment';

export const EnchentesRSForm = (props) => {
  const datetime_begin =
  props.record && props.record.dateBlock
    ? moment(props.record.dateBlock).toDate()
    : null;
  return (
    <div className="vehicletype-form">
      <div className="p-grid">
        <div className="p-col-6">
          <div className="p-grid">
            <div className="p-col-3 form-field-container">
              <label>Código</label>
              <InputText value={props.record.oid} name="oid" disabled={true} />
            </div>
            <div className="p-col-12 form-field-container">
              <label className="">Nome da rodovia</label>
              <InputText value={props.record.highwayName} placeholder="ERS 348, km 37" onChange={props.changeHandler} name="highwayName" />
            </div>
            <div className="p-col-12 form-field-container">
              <label className="lxRequiredField">Coordenadas</label>
              <InputText value={props.record.latlong} placeholder="-29.5833, -53.46452" onChange={props.changeHandler} name="latlong" />
            </div>
            <div className="p-col-12 form-field-container">
              <label className="">Observações importantes</label>
              <InputText value={props.record.description} placeholder="Queda de cabeceira de ponte" onChange={props.changeHandler} name="description" />
            </div>
            <div className="p-col-12 form-field-container">
              <label className="">Data do bloqueio</label>
              <Calendar showIcon={true} value={datetime_begin} onChange={props.changeHandler} name="dateBlock" dateFormat="dd/mm/yy" />
            </div>
            <div className="p-col-12 form-field-container">
              <label className="lxRequiredField">Tipo de bloqueio</label>
              <MultiSelect className="lxMultiSelectDocStatus" selectedItemTemplate={props.docStatusFilterItemSelectedItemTemplate} value={props.record.currentBlockType} onChange={props.changeBlockTypeHandle} name="currentDisasterType" options={props.record.disasterType}></MultiSelect>
            </div>
            <div className="p-col-12 form-field-container">
              <label className="lxRequiredField">Situação</label>
            </div>
            <div className="p-col form-field-container">
              <label>Bloqueio Parcial</label>
              <InputSwitch checked={props.record.blockPartial} onChange={props.changeBlockPartialHandle} name="blockPartial" />
            </div>
            <div className="p-col form-field-container">
              <label>Bloqueio Total</label>
              <InputSwitch checked={props.record.blockTotal} onChange={props.changeBlockTotalHandle} name="blockTotal" />
            </div>
            <div className="p-col-12 form-field-container">
            <label>Veículos liberados</label>
              <MultiSelect className="lxMultiSelectDocStatus" selectedItemTemplate={props.docStatusFilterItemSelectedItemTemplate} value={props.record.currentVehicleTypeReleased} onChange={props.vehicleTypeReleasedHandle} name="currentVehicleType" options={props.record.vehicleTypeReleased}></MultiSelect>
            </div>
            <div className="p-col-12 form-field-container">
              <label className="">Peso máximo do caminhão permitido (KG)</label>
              <InputText disabled={props.record.truckNotSelect} value={props.record.weightVehicle} placeholder="1000" onChange={props.changeHandler} name="weightVehicle" />
            </div>
            <div className="p-col-12 form-field-container">
              <label className="">Horário de liberação</label>
              <InputText type='time' value={props.record.timeRelease} placeholder="Queda de cabeceira de ponte" onChange={props.changeHandler} name="timeRelease" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
