import { validate } from './validations';
import { build } from '../../services/crudFactory';
import { Action } from '../../services/actions.factory';
import api from '../../services/api.service';

const BASE_PATH = '/lognex/towerobservationtype';
const prefix = 'crudTowerObservationType';
const crud = build(validate, BASE_PATH);

const newTypes = {  
  // 
}

const actions = new Action(prefix, crud);

const types = Object.assign({}, actions.types, newTypes); 
export { types, actions }

  