import React from 'react';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import './Lookup.scss';
export class Lookup extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selection: null
        }
    }

    onHide = (e) => {
        this.setState({ selection: null });//retira a seleção 
        if (this.props.onHide) {
            this.props.onHide(e);
        }
    }
    onConfirm = (e) => {
        if (this.props.onConfirm) {
            const event = {
                originalEvent: e,
                selection: this.state.selection
            }
            this.props.onConfirm(event);   
        }
        this.setState({ selection: null });
    }
    onCancel = (e) => {
        if (this.props.onCancel) {
            this.props.onCancel(e);
        }
    }
    onSelectionChange = (e) => {
        if (e.value === this.state.selection) {
            this.setState({ selection: null });
        } else {
            this.setState({ selection: e.value });
        }
        if (this.props.onSelectionChange) {
            const event = {
                originalEvent: e,
                selection: this.state.selection
            }
            this.props.onSelectionChange(event);
        }
    }

    rowClassName = (rowData) => {
        return { 'p-highlight': (this.state.selection && this.state.selection === rowData) };
    }

    onFilter = (e) => {
        if (this.props.onFilter) {
            this.props.onFilter(e);
        }
    }

    onSort = (e) => {
        if (this.props.onSort) {
            this.props.onSort(e);
        }
    }
    onPage = (e) => {
        if (this.props.onPage) {
            this.props.onPage(e);
        }
    }
    render() {
        let columns = this.props.columns;
        if (this.props.isMultiple) {
            columns = [<Column selectionMode="multiple" style={{ width: '3em' }} key={"selection"}></Column>, ...columns]
        }
        const footer = this.props.footer || (
            <div>
                <button
                    title="Confirmar"
                    className="lognex-btn-highlighted"
                    onClick={this.onConfirm}
                    disabled={!this.state.selection} >
                    <i className="pi pi-check"></i>
                </button>
                <button
                    title="Cancelar"
                    className="lognex-btn-danger"
                    onClick={this.onCancel} >
                    <i className="pi pi-times"></i>
                </button>
            </div>
        )
        
        return (
            <Dialog
                className="lxLookupDialog"
                header={this.props.header}
                footer={footer}
                visible={this.props.visible}
                style={this.props.style || { width: '70vw' }}
                modal={this.props.modal}
                onHide={this.onHide}
            >

                <DataTable
                    resizableColumns={true} 
                    columnResizeMode="fit"
                    rowClassName={this.rowClassName}
                    value={this.props.records}
                    selectionMode={this.props.isMultiple ? "multiple" : "single"}
                    metaKeySelection={false}
                    selection={this.state.selection}
                    onSelectionChange={this.onSelectionChange}
                    onFilter={this.onFilter}
                    onSort={this.onSort}
                    onPage={this.onPage}
                    lazy={true}
                    totalRecords={this.props.totalRecords}
                    sortOrder={this.props.order}
                    sortField={this.props.sortField}
                    paginator={this.props.paginator}
                    first={this.props.first}
                    rows={this.props.rows || 15}
                    emptyMessage="Não foram encontrados registros"
                    scrollable={true}
                    scrollHeight="370px"
                    rowsPerPageOptions={this.props.rowsPerPageOptions || [5, 10, 15]}
                >
                    {columns}
                </DataTable>

            </Dialog>
        )
    }
};
