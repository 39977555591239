import React from "react";
import { connect } from "react-redux";
import Toolbar from "../../../components/toolbar/Toolbar";
import { routes } from '../../../constants';
import { Page } from "../../../components/page/Page";
import { DriverForm } from './DriverForm';
import { actions } from '../driver.actions';

const mapStateToProps = state => {

  return {
    record: state.crudDriverState.currentRecord,
  };
};

const mapDispatchToProps = dispatch => {
  const _setField = (field,value) =>{
      dispatch(actions.setField(field, value))
  }
  return {
    changeHandler: event => {
      const target = event.target;
      const field = target.name;
      let value = target.value.toUpperCase();
      dispatch(actions.removeErrors([{ field }]));
      _setField(field,value);
    },
    saveHandler: event => {
      dispatch(actions.save());
    },
    removeHandler: event => {
      dispatch(actions.remove());
    },
    newHandler: event => {
      dispatch(actions.makeNew());      
    },
    loadById: oid => dispatch(actions.loadById(oid)),

    setField: (field, value) =>{
     _setField(field,value)
    } ,

  };
};

class DriverFormPageContainer extends React.Component {

  constructor(props) {
    super(props);
    actions.afterSave = ({ oid }) => {
      this.props.history.replace(`${routes.PATH_DRIVER}/${oid}`);
    };
    actions.afterNew = () => {
      this.props.history.replace(`${routes.PATH_DRIVER}/novo`);
    };
    actions.afterRemove = () => {
      this.props.history.replace(`${routes.PATH_DRIVER}`);
    };
  }

  componentDidMount() {
    const oid = this.props.match.params.id;

    if (oid && parseInt(oid)) {
      this.props.loadById(oid);
    } else {
      this.props.newHandler();
    }
  }
  searchHandler = () => {
    this.props.history.push(`${routes.PATH_DRIVER}`)
  }

  

 

  render() {

    const title = `Motorista ${this.props.record.oid || 'novo'}`
    const header = (
      <div>
        <h1>{title}</h1>
        <div>
          <Toolbar
            onSave={this.props.saveHandler} saveButtonStyle='highlighted'
            onRemove={this.props.removeHandler} removeButtonStyle='danger'
            onNew={this.props.newHandler}
            onSearch={this.searchHandler}>
          </Toolbar>
        </div>
      </div>
    );

    const content = (
      <DriverForm
        record={this.props.record}
        changeHandler={this.props.changeHandler}
      />);
    return (<Page header={header} content={content} />);
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DriverFormPageContainer);
