
import { validate } from './validations';
import { build } from '../../services/crudFactory';
import { Action } from '../../services/actions.factory';
import api from '../../services/api.service';

const BASE_PATH = '/lognex/carriers';
const prefix = 'crudCarrier';
const crud = build(validate, BASE_PATH);

const newTypes = {
    REMOVE_SELECTED_BRANCHES: `${prefix}removeSelectedBranches`,
    SET_DIALOG_ADD_BRANCHES_VISIBLE : `${prefix}setDialogAddBranchesVisible`,
    ADD_BRANCH : `${prefix}addBranch`,
    SET_SELECTED_RECORDS : `${prefix}setSelectedRecords`,
    SET_CURRENT_BRANCH_FIELD : `${prefix}setCurrentBranchField`,
    SET_CURRENT_BRANCH : `${prefix}setCurrentBranch`,
    SET_SELECTED_BRANCHES : `${prefix}setSelectedBranches`
}

const actions = new Action(prefix, crud);

const types = Object.assign({}, actions.types, newTypes);


actions.setCurrentBranchField = (field,value)=>{
    return {
        type: types.SET_CURRENT_BRANCH_FIELD,
        field,
        value
    }
}

actions.setEmptyCurrentBranch = ()=>{
    const emptyCurrentBranch = {
        label: "",
        name: "", 
        tradeName: "",
        documentNumber: "",
        address:{
          street:"",number:"",complement:"",district:"",city:"",zipCode:""
        }, 
        email: "",
        site: "", 
        phone: "", 
        contact: "", 

    }
    return {
        type: types.SET_CURRENT_BRANCH,
        currentBranch:emptyCurrentBranch
    }
}

actions.setCurrentBranch = (currentBranch)=>{
    return {
        type: types.SET_CURRENT_BRANCH,
        currentBranch
    }
}



actions.removeSelectedBranches = () => {
    return {
        type: types.REMOVE_SELECTED_BRANCHES
    }
}

actions.addBranch = () => {
    return {
        type: types.ADD_BRANCH
    }
}
actions.setSelectedBranches = (branches) => {
    return {
        type: types.SET_SELECTED_BRANCHES,
        branches
    }
}

actions.setDialogAddBranchesVisible = (visible) => {
    return {
        type: types.SET_DIALOG_ADD_BRANCHES_VISIBLE,
        visible
    }
}


actions.setSelectedRecords = (selectedRecords) => {
    return {
        type: types.SET_SELECTED_RECORDS,
        selectedRecords
    }
}

export { types, actions }