import { validate } from './validations';
import { build } from '../../services/crudFactory';
import { Action } from '../../services/actions.factory';
import api from '../../services/api.service';

const BASE_PATH = '/lognex/tower-event-control';
const prefix = 'crudTowerEventControl';
const crud = build(validate, BASE_PATH);

const newTypes = { 
  //  SET_SELECTED_RECORDS : `${prefix}setSelectedRecords`
}

const actions = new Action(prefix, crud);

const types = Object.assign({}, actions.types, newTypes); 
export { types, actions }