
import { validate } from './validations';
import { build } from '../../services/crudFactory';
import { Action } from '../../services/actions.factory';

const BASE_PATH = '/lognex/freightcomponents';
const prefix = 'crudFreightComponent';


const crud = build(validate, BASE_PATH);

const newTypes = {
    SET_GROUP_TYPE: `${prefix}setGroupType`,
    SET_RESTRICTED_BY_TYPE: `${prefix}setRestrictedByType`
}

const actions = new Action(prefix, crud);

const types = Object.assign({}, actions.types, newTypes);


actions.setRestrictedByType = (value) => {
    return {
        type: types.SET_RESTRICTED_BY_TYPE,
        value
    }
}

actions.setGroupType = (field, value) => {
    return {
        type: types.SET_GROUP_TYPE,
        field, 
        value
    }
}



export { types, actions }