
    import React from 'react';
    import RegionListPageContainer from './RegionListPageContainer';
    
    export class RegionListPage extends React.Component{
        
        render(){
          return (
            <RegionListPageContainer {...this.props}/>
          )
        }
    }