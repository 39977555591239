export function validate(record) {
    const errors = [];

    if (!record.vehicle.plate || record.vehicle.plate.trim() === "") {
        errors.push({ field: 'vehicle.plate', message: 'O veículo é obrigatório!' });
    }

    if (!record.driver.name || record.driver.name === "") {
        errors.push({ field: 'driver.name', message: 'O Motorista é obrigatório' });
    }


    if (!record.driver.documentNumber || record.driver.documentNumber === "") {
        errors.push({ field: 'driver.documentNumber', message: 'Número da CNH do motorista é obrigatório' });
    }

    const result = {
        ok: errors.length === 0,
        errors,
        record
    }

    return new Promise((resolve, reject) => {
        const action = result.ok ? resolve : reject;
        return action(result);
    })
}