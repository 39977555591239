
import React from 'react';
import { connect } from 'react-redux';
import { Page } from "../../../components/page/Page";
import Toolbar from "../../../components/toolbar/Toolbar";
import { routes } from '../../../constants';
import { actions } from '../../holiday/holiday.actions';
import { HolidayForm } from "./HolidayForm";

const mapStateToProps = state => {
    let currentRecord = state.crudHolidayState.currentRecord;
    return {
        currentRecord,
    }
    
}

const mapDispatchToProps = dispatch => {

    return {
        setField: (field, value) => dispatch(actions.setField(field, value)),

        changeHandler: event => {
            const target = event.target;
            const field = target.name;
            let value = target.value;
            dispatch(actions.removeErrors([{ field }]));
            dispatch(actions.setField(field, value));
        },

        saveHandler: event => {
            dispatch(actions.save());
        },

        removeHandler: event => {
            dispatch(actions.remove());
        },

        newHandler: event => {
            dispatch(actions.makeNew());
        },

        loadById: oid => dispatch(actions.loadById(oid)),

    }
}


class HolidayFormPageContainer extends React.Component {
    constructor(props) {
        super(props);
        actions.afterSave = ({ oid }) => {
            this.props.history.replace(`${routes.PATH_HOLIDAY}/${oid}`);
        };
        actions.afterNew = () => {
            this.props.history.replace(`${routes.PATH_HOLIDAY}/novo`);
        };
        actions.afterRemove = () => {
            this.props.history.replace(`${routes.PATH_HOLIDAY}`);
        };
    }

    componentDidMount() {
        const oid = this.props.match.params.id;
        if (oid && parseInt(oid)) {
            this.props.loadById(oid);
        } else {
            this.props.newHandler();
        }
    }

    searchHandler = () => {
        this.props.history.push(`${routes.PATH_HOLIDAY}`)
    }

    render() {
        const title = `Novo feriado`
        const header = (
            <div>
                <h1>{title}</h1>
                <hr className="lxSplitter" ></hr>
                <div>
                    <Toolbar
                        onSave={this.props.saveHandler} saveButtonStyle='highlighted'
                        onRemove={this.props.removeHandler} removeButtonStyle='danger'
                        onNew={this.props.newHandler}
                        onSearch={this.searchHandler}>
                    </Toolbar>
                </div>
            </div>
        );
        const content = (
            <HolidayForm
            changeHandler={this.props.changeHandler} 
            record={this.props.currentRecord}
            />
        )

        return (<Page header={header} content={content} />)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HolidayFormPageContainer);



