import { types } from "./usuario.actions";
import { Reducer } from "../../services/reducer.factory";

const initialState = {
  booleanFilter: {
    ativo: ""
  },
  columns: [
    {
      field: "oid",
      header: "Código",
      columnKey: "oid",
      sortable: true,
      filter: true
    },
    {
      field: "name",
      header: "Nome",
      columnKey: "name",
      sortable: true,
      filter: true
    },
    {
      field: "login",
      header: "Login",
      columnKey: "login",
      sortable: true,
      filter: true
    },
    {
      field: "email",
      header: "Email",
      columnKey: "email",
      sortable: true,
      filter: true
    },
    {
      field: "role",
      header: "Cargo",
      columnKey: "role",
      sortable: true,
      filter: true
    }
  ],
  currentRecord: {
    oid: null,
    name: "",
    documentNumber: "",
    login: "",
    password: "",
    email: "",
    phone: "",
    role: "",
    address: {},
    userType: "",
    accountkey: "",
    groups: [],
    carrier:null,
  },
  lookupCarrier: {
    visible: false,
    modal: true,
    header: "Transportadoras"
  },
};

const reducer = new Reducer(initialState, types);

export function CRUDUsuarioReducer(state, action) {
  state = reducer.execute(state, action);
  return execute(state, action);
}

function execute(state, action) {
  let result = state;
  switch (action.type) {
    case types.PICKLIST_PERFIS_MOVE_TO_SOURCE:
      return pickListPerfisMoveToSource(state, action);
    case types.PICKLIST_PERFIS_MOVE_ALL_TO_SOURCE:
      return pickListPerfisMoveAllToSource(state, action);
    case types.PICKLIST_PERFIS_MOVE_TO_TARGET:
      return pickListPerfisMoveToTarget(state, action);
    case types.PICKLIST_PERFIS_MOVE_ALL_TO_TARGET:
      return pickListPerfisMoveAllToTarget(state, action);
    case types.SET_LOOKUP_CARRIER_VISIBLE:
      return setLookupCarrierVisible(state, action);
    default:
      return result;
  }
}

function setLookupCarrierVisible(state, action) {
  let lookupCarrier = state.lookupCarrier;
  lookupCarrier.visible = action.visible;
  return Object.assign({}, state, { lookupCarrier })
}

function pickListPerfisMoveToSource(state, action) {
  let perfisDeselecionados = [];

  action.perfisRemoved.forEach(perfilRemoved => {
    perfisDeselecionados = state.currentRecord.groups.filter(group => group.oid !== perfilRemoved.oid);
  });

  let currentRecord = Object.assign({}, state.currentRecord, { groups: perfisDeselecionados });
  return Object.assign({}, state, { currentRecord });
}

function pickListPerfisMoveAllToSource(state, action) {
  let currentRecord = Object.assign({}, state.currentRecord, { groups: [] });
  return Object.assign({}, state, { currentRecord });
}

function filterOldPerfisUsuario(perfisAdd, perfisUsuarioSelecionados) {
  return perfisAdd.filter(perfil => {
    let existe = perfisUsuarioSelecionados.filter(perfilUsuario => perfilUsuario.oid === perfil.oid);
    return existe.length > 0 ? false : true;
  });
}

function pickListPerfisMoveToTarget(state, action) {

  let groups = []
  if (state.currentRecord.groups) {
    groups = [...state.currentRecord.groups];
  }

  let perfisAdd = filterOldPerfisUsuario(action.perfisAdd, groups) || [];
  perfisAdd = perfisAdd.map(perfil => {
    const { oid, name } = perfil;
    return { oid, name }
  })
  groups = [...groups, ...perfisAdd];

  let currentRecord = Object.assign({}, state.currentRecord, { groups });
  return Object.assign({}, state, { currentRecord });
}

function pickListPerfisMoveAllToTarget(state, action) {
  return pickListPerfisMoveToTarget(state)
}


