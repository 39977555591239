import React, { Fragment } from "react";
import { connect } from "react-redux";
import Toolbar from "../../../components/toolbar/Toolbar";
import { routes } from "../../../constants";
import { Page } from "../../../components/page/Page";
import { TowerEquipmentFormPage } from "./TowerEquipmentTypeFormPage";
import { actions } from "../tower_equipment_type.actions";
import { actions as actionEvents } from "../../towerEventControl/tower_event_control.actions";

import { Column } from "primereact/column";
import { makeMessages, messageActions } from "../../../store/message";
import EventListPageContainer from "../event_list/EventListPageContainer";
import { TowerEquipmentTypeForm } from "./TowerEquipmentTypeForm";
import { clearSpecialCharacteresSpace } from "../../../utils";

const mapStateToProps = (state) => {
  const record = state.crudTowerEquipmentTypeState.currentRecord;

  const lookupEventLognexBase = {
    records: state.crudTowerEventControlState.records,
    columns: state.crudTowerEventControlState.columns.filter((col) => col.field === "eventName").map((col) => <Column key={col.field} {...col} />),
    first: state.crudTowerEventControlState.index,
    rows: state.crudTowerEventControlState.max,
    totalRecords: state.crudTowerEventControlState.count,
    sortField: state.crudTowerEventControlState.sortField,
    order: state.crudTowerEventControlState.sortOrder,
  };
  let lookupEventLognex = Object.assign({}, state.crudTowerEquipmentTypeState.lookupEventLognex, lookupEventLognexBase);

  return {
    record,
    lookupEventLognex
  };
};

const mapDispatchToProps = (dispatch) => {
  const closeMessage = () => {
    dispatch(messageActions.messageClearMessages());
  };
  return {
    changeHandler: (event) => {
      const target = event.target;
      const field = target.name;
      let value = target.value;
      closeMessage();
      dispatch(actions.removeErrors([{ field }]));
      dispatch(actions.setField(field, value));

      if(field=="name"){
        dispatch(actions.setField("nameKey", clearSpecialCharacteresSpace(value)));
      }
    },
    setField: (field, value) => dispatch(actions.setField(field, value)),
    saveHandler: (event) => {
      dispatch(actions.save());
    },
    removeHandler: (event) => {
      dispatch(actions.remove());
    },
    newHandler: (event) => {
      dispatch(actions.makeNew());
    },
    loadById: (oid) => dispatch(actions.loadById(oid)), 

    onClickAddNewEvent: (event) => {
      closeMessage();
      dispatch(actions.vinculaEventEquipmentTypeEventLognex());
    },
    setNewEventName: (event) => {
      const target = event.target;
      let value = target.value;
      dispatch(actions.setNewEventName(value));
      closeMessage();
    },
    setEventControl: (eventControl) => {
      closeMessage();
      const { oid, eventName } = eventControl || {};
      dispatch(actions.setField("eventControl", eventControl ? { oid, eventName } : null));
    },

    showMessageError: (msg) => {
      dispatch(messageActions.messageShowMessages(makeMessages([msg], "error")));
    },

    setLookupEventLognexVisible: (visible) => dispatch(actions.setLookupEventLognexVisible(visible)),
    lookupEventLognexPaginate: (paginationParams) => dispatch(actionEvents.paginate(paginationParams)),
    lookupEventLognexSortOrder: (sortParams) => dispatch(actionEvents.sortOrder(sortParams)),
    lookupEventLognexApplyFilter: (filterParams) => {
      dispatch(actionEvents.setType(null));
      dispatch(actionEvents.applyFilter(filterParams));
    },


  };
};

class TowerEquipmentTypeFormPageContainer extends React.Component {
  constructor(props) {
    super(props);
    actions.afterSave = ({ oid }) => {
      this.props.history.replace(`${routes.PATH_TOWER_EQUIPMENT_TYPE}/${oid}`);
    };
    actions.afterNew = () => {
      this.props.history.replace(`${routes.PATH_TOWER_EQUIPMENT_TYPE}/novo`);
    };
    actions.afterRemove = () => {
      this.props.history.replace(`${routes.PATH_TOWER_EQUIPMENT_TYPE}`);
    };
  }

  componentDidMount() {
    const oid = this.props.match.params.id;
    if (oid && parseInt(oid)) {
      this.props.loadById(oid);
    } else {
      this.props.newHandler();
    }
    this.props.setEventControl(null);
  }
  searchHandler = () => {
    this.props.history.push(`${routes.PATH_TOWER_EQUIPMENT_TYPE}`);
  };

  onSave = (e) => {
    this.props.saveHandler(e);
  };

  //lookupEventLognex
  lookupEventLognexOnPage = (e) => {
    this.props.lookupEventLognexPaginate({
      max: e.rows,
      index: e.first,
    });
  };

  lookupEventLognexOnHide = (e) => {
    this.props.setLookupEventLognexVisible(false);
  };

  lookupEventLognexOnSort = (e) => {
    this.props.lookupEventLognexSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField,
    });
  };
  lookupEventLognexOnFilter = (e) => {
    const filters = [];

    const keys = Object.keys(e.filters) || [];
    keys.forEach((key) => {
      filters.push({
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: "CONTEM",
      });
    });

    this.props.lookupEventLognexApplyFilter(filters);
  };
  lookupEventLognexOnClick = (e) => {
    const filters = [];
    this.props.lookupEventLognexApplyFilter(filters);
    this.props.setLookupEventLognexVisible(true);
  };

  lookupEventLognexOnConfirm = (e) => {
    this.props.setEventControl(e.selection);
    this.props.setLookupEventLognexVisible(false);
  };
  lookupEventLognexOnCancel = (e) => {
    this.props.setEventControl(null);
    this.props.setLookupEventLognexVisible(false);
  };

  lookupEventLognexOnInputFilter = (e) => {
    const value = e.value;
    if (typeof value === "string") {
      const filters = [
        {
          campo: "eventName",
          valor: e.value,
          tipoFiltro: "CONTEM",
        },
      ];

      this.props.setField("eventControl", { eventName: e.value, oid: null });
      this.props.lookupEventLognexApplyFilter(filters);
    } else {
      this.props.setEventControl(value);
    }
  };

  lookupEventLognexOnEdit = () => {
    if (this.props.record.eventControl && this.props.record.eventControl.oid) {
      this.props.history.push(`${routes.PATH_TOWER_EVENT}/${this.props.record.eventControl.oid}`);
    }
  };

  lookupEventLognexOnComplete = (e) => {};

  onClickAddNewEvent = (e) => {
    //check dados
    if (!this.props.record.eventName || !this.props.record.eventName.trim().length === 0) {
      this.props.showMessageError("Informe o evento do Tipo Equipamento");
    } else if (!this.props.record.eventControl || !this.props.record.eventControl.oid) {
      this.props.showMessageError("Selecione um Evento Lognex");
    } else {
      //check duplicados
      let jaAdd = false;
      let msg = "";
      if (this.props.record.events && this.props.record.events.length > 0) {
        this.props.record.events.forEach((e) => {
          if (e.event === this.props.record.eventName) {
            jaAdd = true;
            msg = "Esse evento já foi vinculado!";
            return;
          } else if (e.eventLognex === this.props.record.eventControl.eventName) {
            jaAdd = true;
            msg = "Esse Evento Lognex já foi vinculado!";
            return;
          }
        });
      }
      if (!jaAdd) {
        this.props.onClickAddNewEvent();
      } else {
        this.props.showMessageError(msg);
      }
    }
  };


  


  render() {
    const title = `Tipo Equipamento ${this.props.record.oid || "novo"}`;
    const header = (
      <div>
        <h1>{title}</h1>
        <hr className="lxSplitter"></hr>
        <div>
          <Toolbar
            onSave={this.onSave}
            saveButtonStyle="highlighted"
            onRemove={this.props.removeHandler}
            removeButtonStyle="danger"
            onNew={this.props.newHandler}
            onSearch={this.searchHandler}
          ></Toolbar>
        </div>
      </div>
    );

    const content = (
      <Fragment>
        <TowerEquipmentTypeForm
          record={this.props.record}
          recordsListEvent={this.props} 
          onClickAddNewEvent={this.onClickAddNewEvent}
          changeHandler={this.props.changeHandler}
          setNewEventName={this.props.setNewEventName}
          lookupEventLognex={this.props.lookupEventLognex}
          lookupEventLognexOnHide={this.lookupEventLognexOnHide}
          lookupEventLognexOnConfirm={this.lookupEventLognexOnConfirm}
          lookupEventLognexOnCancel={this.lookupEventLognexOnCancel}
          lookupEventLognexOnPage={this.lookupEventLognexOnPage}
          lookupEventLognexOnSort={this.lookupEventLognexOnSort}
          lookupEventLognexOnFilter={this.lookupEventLognexOnFilter}
          lookupEventLognexOnClick={this.lookupEventLognexOnClick}
          lookupEventLognexOnEdit={this.lookupEventLognexOnEdit}
          lookupEventLognexOnComplete={this.lookupEventLognexOnComplete}
          lookupEventLognexOnInputFilter={this.lookupEventLognexOnInputFilter}

           />
 
      </Fragment>
    );
    return <Page header={header} content={content} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TowerEquipmentTypeFormPageContainer);
