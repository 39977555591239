import React from "react";
import { ConfigurationPanel } from "./ConfigurationPanel";

export const IntegrationsSection = (props) => {
    return (
        <div>
            <div>
                <ConfigurationPanel
                    id={"integrations"}
                    title={"Integração"}
                    configurations={props.integrations}
                /> 
                <ConfigurationPanel
                    id={"roteirizador"}
                    title={"Roteirizador"}
                    configurations={props.roteirizador}
                />
                <ConfigurationPanel
                    id={"integrationFtp"}
                    title={"Integração FTP"}
                    configurations={props.ftpConfigurations}
                />
                <ConfigurationPanel
                    id={"integrationAnymarket"}
                    title={"Integração Anymarket"}
                    configurations={props.anymarketConfigurations}
                />

            </div>
        </div>
    )
}