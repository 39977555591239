
    import React from 'react';
    import FreightTableListPageContainer from './FreightTableListPageContainer';
    
    export class FreightTableListPage extends React.Component{
        
        render(){
          return (
            <FreightTableListPageContainer {...this.props}/>
          )
        }
    }