import React from 'react';
import './FileUploaderButton.scss'

export class FileUploaderButton extends React.Component {
    removeFiles() {
        const importInput = this.refs.importInput;
        importInput.files = null
    }
    render() {
        const {props} = this;
        return (
            <button style={{ marginRight: ".25em" }}
                className={props.buttonClass} disabled={props.disabled}>
                <i style={{ marginTop: '6px' }} className="pi pi-upload"></i>  
                <input ref='importInput' disabled={props.disabled} title={props.title || "Importar"} type='file' accept={props.accept} className={"lxUploadFileButton"} onChange={(e) => { props.onUpload(e); this.removeFiles(); e.target.value = null; }} />
            </button>
        )
    }
};


export default FileUploaderButton;