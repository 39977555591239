
    import React from 'react';
    import PalletFormPageContainer from './PalletFormPageContainer';
    
    export class PalletFormPage extends React.Component{
        render(){
            return (
                <PalletFormPageContainer {...this.props}/>
            )
        }
    }
    