import { validate } from './validations';
import { build } from '../../services/crudFactory';
import { Action } from '../../services/actions.factory';
const BASE_PATH = '/lognex/users';
const prefix = 'crudUsuario';
const crud = build(validate, BASE_PATH);

const newTypes = {
    PICKLIST_PERFIS_MOVE_TO_TARGET: `${prefix}pickListPerfisMoveToTarget`,
    PICKLIST_PERFIS_MOVE_TO_SOURCE: `${prefix}pickListPerfisMoveToSource`,
    PICKLIST_PERFIS_MOVE_ALL_TO_SOURCE: `${prefix}pickListPerfisMoveAllToSource`,
    PICKLIST_PERFIS_MOVE_ALL_TO_TARGET: `${prefix}pickListPerfisMoveAllToTarget`,
    SET_LOOKUP_CARRIER_VISIBLE: `${prefix}setLookupCarrierVisible`,
}

const actions = new Action(prefix, crud);

const types = Object.assign({}, actions.types, newTypes);

actions.setLookupCarrierVisible = (visible) => {
    return {
        type: types.SET_LOOKUP_CARRIER_VISIBLE,
        visible,
    };
};

actions.setLookupPessoaVisible = (visible) => {
    return {
        type: types.SET_LOOKUP_PESSOA_VISIBLE,
        visible
    }
}


actions.setBooleanFilter = (field, value) => {
    return {
        type: types.SET_BOOLEAN_FILTER,
        field,
        value
    }
}

actions.pickListPerfisMoveToSource = (perfisRemoved) => {
    return {
        type: types.PICKLIST_PERFIS_MOVE_TO_SOURCE,
        perfisRemoved
    }
}

actions.pickListPerfisMoveAllToSource = () => {
    return {
        type: types.PICKLIST_PERFIS_MOVE_ALL_TO_SOURCE
    }
}

actions.pickListPerfisMoveToTarget = (perfisAdd, oid) => {
    return {
        type: types.PICKLIST_PERFIS_MOVE_TO_TARGET,
        perfisAdd,
        oid
    }
}

actions.pickListPerfisMoveAllToTarget = (allPerfis, oid) => {
    return actions.pickListPerfisMoveToTarget(allPerfis, oid)
}

export { types, actions };