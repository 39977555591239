    import { types } from "./palletStandard.actions";
    import { Reducer } from "../../services/reducer.factory";
    
    const initialState = {
      columns: [{
        field: "oid",
        header: "Código",
        columnKey: "oid",
        sortable: "false",
        filter: "false"
      },{
        field: "name",
        header: "Nome",
        columnKey: "name",
        sortable: "false",
        filter: "false"
      },{
        field: "height",
        header: "Altura",
        columnKey: "height",
        sortable: "false",
        filter: "false"
      },{
        field: "width",
        header: "Largura",
        columnKey: "width",
        sortable: "false",
        filter: "false"
      },{
        field: "depth",
        header: "Profundidade",
        columnKey: "depth",
        sortable: "false",
        filter: "false"
      },{
        field: "maxWeight",
        header: "Peso máximo",
        columnKey: "maxWeight",
        sortable: "false",
        filter: "false"
      },{
        field: "maxHeight",
        header: "Altura máxima",
        columnKey: "maxHeight",
        sortable: "false",
        filter: "false"
      }],
      currentRecord: {oid:"",name:"",height:"",width:"",depth:"",maxWeight:"",maxHeight:""},
      
    };
    
    const reducer = new Reducer(initialState, types);
    
    export function CRUDPalletStandardReducer(state, action) {
      state = reducer.execute(state, action);
      return execute(state,action);
    }
    
    function execute(state, action){
        let result = state;
        switch(action.type){
          
          
        }
        return result;
    }
   
    
    
    