export const COMPANY_CHANGE_EVENT_NAME = 'ON_COMPANY_CHANGE';
export const BUSINESS_UNIT_CHANGE_EVENT_NAME = 'ON_BUSINESS_UNIT_CHANGE';

const events = {};
let timers = {}


 const on = (eventName, listener) =>{
    const listeners = events[eventName] || [listener]
    if(!listeners.find((current)=> current === listener)){
        listeners.push(listener)
    }
    events[eventName] = listeners;
}

const dispatch = (eventName,...params) =>{
    if(timers[eventName]){
        window.clearTimeout(timers[eventName])
    }
   timers[eventName ]= window.setTimeout(()=>{
        const listeners = events[eventName] || [];
        listeners.forEach((listener)=> listener && listener.onReceiveAppNotifications(eventName,params))
    },500)
   
}

const off = (eventName, listener) =>{
    let listeners = events[eventName] || [];
    listeners = listeners.filter((current)=> current !== listener)
    events[eventName] = listeners;
}

export const AppService = {
    on,
    off,
    dispatch
}

