import React from "react";
import { DataTable } from "primereact/datatable";

export const DefaultDataTableList = (props) => (
  <DataTable
    selectionMode="single"
    onPage={props.onPage}
    value={props.records}
    lazy={true}
    paginator={false}
    emptyMessage="Não foram encontrados registros"
    metaKeySelection={false}
    onSelectionChange={props.onSelectedRecordsChange}>{props.columns}
  </DataTable>
);
