import React from 'react';
import TowerEventControlListPageContainer from './TowerEventControlListPageContainer';

export class TowerEventControlListPage extends React.Component{
    
    render(){
      return (
        <TowerEventControlListPageContainer {...this.props}/>
      )
    }
}

