import React from "react";
import { InputText } from "primereact/inputtext";
import { ProductCampAnyListPage } from "../components/product/ProductCampAnyListPage";
import { ZipcodeRangeCampAnyListPage } from "../components/zipcodeRange/ZipcodeRangeCampAnyListPage";
import { Calendar } from "primereact/calendar";
import { InputSwitch } from "primereact/inputswitch";
import moment from "moment";

export const CampanhaAnymarketForm = (props) => {
  let dataInicio = null
  if (props.record && props.record.dataInicio && moment(props.record.dataInicio).isValid()) {
    dataInicio = moment(props.record.dataInicio).toDate()
  }
  let dataFim = null
  if (props.record && props.record.dataFim && moment(props.record.dataFim).isValid()) {
    dataFim = moment(props.record.dataFim).toDate()
  }

  return (
    <div className="vehicletype-form">
      <div className="p-grid">
        <div className="p-col-6">
          <div className="p-grid">
            <div className="p-col-3 form-field-container">
              <label>Código</label>
              <InputText value={props.record.oid} name="oid" disabled={true} />
            </div>
            <div className="p-col-2 form-field-container">
              <label>Ativo</label>
              <InputSwitch checked={props.record.active} onChange={props.changeHandler} name="active" />
            </div>

            <div className="p-col-12 form-field-container">
              <label className="lxRequiredField">Descrição</label>
              <InputText type="text" value={props.record.descricao} name="descricao"  onChange={props.changeHandler} />
            </div>

            <div className="p-col-3 form-field-container">
              <label className="lxRequiredField">Data Inicio</label>
              <Calendar showIcon={true} value={dataInicio} showTime={false} onChange={props.changeHandler} disabled={false} name="dataInicio" dateFormat="dd/mm/yy" />
            </div>
            <div className="p-col-3 form-field-container">
              <label className="lxRequiredField">Data Fim</label>
              <Calendar showIcon={true} value={dataFim} showTime={false} onChange={props.changeHandler} disabled={false} name="dataFim" dateFormat="dd/mm/yy" />
            </div>


            <div className="p-col-3 form-field-container">
              <label>Valor mínimo</label>
              <InputText type="number" value={props.record.valMin} name="valMin"  onChange={props.changeHandler} />
            </div>
            <div className="p-col-3 form-field-container">
              <label>Valor máximo</label>
              <InputText type="number" value={props.record.valMax} name="valMax"  onChange={props.changeHandler} />
            </div>


            <div className="p-col-12 form-field-container">
              <ProductCampAnyListPage />
            </div>
            <div className="p-col-12 form-field-container">
              <ZipcodeRangeCampAnyListPage />
            </div>


          </div>
        </div>
      </div>
    </div>
  );
};
