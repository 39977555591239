

function setField(state, action) {
  let currentRecord = Object.assign({}, state.currentRecord);
  const { field, value } = action;
  const fields = field && field.split('.');
  const lastField = fields[fields.length - 1];
  let current = currentRecord;
  if (fields.length > 1) {
    for (let index = 0; index < fields.length - 1; index++) {
      const owner = current;
      current = current[fields[index]];
      if (!current) {
        current = {}
        owner[fields[index]] = current;
      }
    }
  }

  current[lastField] = value;
  return Object.assign({}, state, { currentRecord });
}

function clearErrors(state, actions) {
  let errors = {};
  return Object.assign({}, state, { errors });
}

function setSelectedRecods(state, action) {
  return Object.assign({}, state, { selectedRecords: action.selectedRecords || [] })
}

function addErrors(state, action) {
  let err = action.errors.reduce((result, current) => {
    result[current.field] = current.message
    return result;
  }, {})
  let errors = Object.assign({}, state.errors, err)
  return Object.assign({}, state, { errors });
}

function removeErrors(state, action) {
  let errors = Object.assign({}, state.errors);
  action.errors.forEach(err => delete errors[err.field]);
  return Object.assign({}, state, { errors });
}

export class Reducer {

  constructor(initialState, types) {
    const baseState = {

      columns: [],
      currentRecord: {},
      records: [],
      /*campos para paginação */
      filters: [],
      baseFilters: [],
      sortField: "",
      sortOrder: 0,
      index: 0,
      max: 50,
      page:0,
      count: 0,
      errors: {},
      selectedRecords: []
    };
    this.state = Object.assign({}, baseState, { ...initialState });
    this.emptyRecord = initialState.emptyRecord || Object.assign({}, { ...initialState.currentRecord });
    this.types = types;
  }

  execute(state = this.state, action) {
    let result = state;
    switch (action.type) {
      case this.types.MAKE_NEW:
        result = Object.assign({}, state, { currentRecord: Object.assign({}, this.emptyRecord) });
        break;
      case this.types.SET_RECORDS:
        result = Object.assign({}, state, { records: action.records });
        break;
      case this.types.SET_RECORD:
        let record = null;
        if(action.record){
          record = Object.assign({}, state.currentRecord, action.record);
        }else{
          record = Object.assign({},{ currentRecord: Object.assign({}, this.emptyRecord) });
        }
        result = Object.assign({}, state, { currentRecord: record });
        break;
      case this.types.SET_COUNT:
        result = Object.assign({}, state, { count: action.count });
        break;
      case this.types.SET_INDEX:
        result = Object.assign({}, state, { index: action.index });
        break;
        case this.types.SET_PAGE:
        result = Object.assign({}, state, { page: action.page });
        break;
      case this.types.SET_MAX:
        result = Object.assign({}, state, { max: action.max });
        break;
      case this.types.SET_SORT_FIELD:
        result = Object.assign({}, state, { sortField: action.sortField });
        break;
      case this.types.SET_SORT_ORDER:
        result = Object.assign({}, state, { sortOrder: action.sortOrder });
        break;
      case this.types.SET_BASE_FILTERS:
        result = Object.assign({}, state, { baseFilters: action.filters, filters: action.filters });
        break;
      case this.types.SET_FILTERS:
        let filters = []
        if (state.baseFilters) {
          filters = [...state.baseFilters];
        }
        if (action.filters) {
          filters = [...filters, ...action.filters];
        }
        result = Object.assign({}, state, { filters });
        break;
      case this.types.SET_TYPE:
        result = Object.assign({}, state, { type: action.filterType });
        break;
      case this.types.SET_FIELD:
        result = setField(state, action);
        break;
      case this.types.ADD_ERRORS:
        result = addErrors(state, action);
        break;
      case this.types.REMOVE_ERRORS:
        result = removeErrors(state, action);
        break;
      case this.types.CLEAR_ERRORS:
        result = clearErrors(state, action);
        break;
      case this.types.SET_SELECTED_RECORDS:
        result = setSelectedRecods(state, action);
        break;
      default:
        result = state;
    }
    return result;
  }
}




