import React from 'react'
import { DataTable } from 'primereact/datatable'

export const LxPanelLocationsByComponentRestriction = (props) => {
    const datatable = {
        // selection: props.dataTable && props.dataTable.selectedComponents ? props.dataTable.selectedComponents : [],
        value: props.dataTable && props.dataTable.records ? props.dataTable.records : [],
        sortOrder: props.dataTable && props.dataTable.Order ? props.dataTable.Order : 0,
        sortField: props.dataTable && props.restrictionsSortField ? props.restrictionsSortField : "",
        paginator: true,        
        rows: props.dataTable && props.dataTable.rows ? props.dataTable.rows : 0,
        onSelectionChange: props.dataTable && props.dataTable.actions && props.dataTable.actions.onSelectionChangeLocations ? props.dataTable.actions.onSelectionChangeLocations : props.dataTable.actions.onSelectionChangeLocations,
        onFilter: props.dataTable && props.dataTable.onFilterLocations && props.dataTable.actions.onFilterLocations ? props.dataTable.actions.onFilterLocations : () => { console.log("datatable.onFilterRestrictionsByComponent must be implemented") },
        onSort: props.dataTable && props.dataTable.onSortLocations && props.dataTable.actions.onSortLocations ? props.dataTable.actions.onSortLocations : () => { console.log("datatable.onSortRestrictionsByComponent must be implemented") },
        emptyMessage: "Não foram encontrados registros",
        rowsPerPageOptions: [5, 10, 20, 50],
        selectionMode: "single",        
    }

    return (
        // TODO ciar o proprio CSS para esta div
        <div className="p-grid lxPanelRestrictionsByComponent"> 
            <div className="p-col-12">
                <DataTable {...datatable}>
                    {props.dataTable.locationsByComponentRestrictioncolumns}
                </DataTable>
            </div>
        </div>
    )
}