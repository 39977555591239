import React from 'react'
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';

export class LxEditSerieDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            start: 0,
            end: 0,
        }
    }

    onChangeHandler = (event) => {
        const target = event.target;
        this.setState(() => {
            return {
                [target.name]: target.value
            }
        })
    }

    onConfirm = () => {
        this.props.actions.onConfirm &&
            this.props.actions.onConfirm(
                {
                    start: this.state.start,
                    end: this.state.end,
                }
            )
    }
    onCancel = () => {
        this.setState({ records: [], currentType: null })
        this.props.actions.onCancel && this.props.actions.onCancel()
    }

    componentWillReceiveProps = () => {
        this.setState({ start: this.props.start, end: this.props.end })
    }

    compo
    render() {
        const { props } = this;

        return (
            <Dialog
                header={props.header}
                visible={props.visible}
                style={{ width: '50vw' }}
                modal={props.modal}
                baseZIndex={1}
                onHide={props.actions && props.actions.onHide ? props.actions.onHide : () => { console.log("dialog.onHide must be implemented") }}
                footer={
                    <div>
                        <button
                            title="Confirmar"
                            className={props.confirmDisabled !== true ? "lognex-btn-highlighted" : ""}
                            onClick={this.onConfirm}
                            disabled={false} >
                            {
                                //TODO 
                            }
                            <i className="pi pi-check"></i>
                        </button>

                        <button
                            title="Cancelar"
                            className="lognex-btn-danger"
                            onClick={props.actions && props.actions.onHide ? props.actions.onHide : () => { console.log("dialog.onHide must be implemented") }}>
                            <i className="pi pi-times"></i>
                        </button>
                    </div>}>

                <div className="p-col-12">
                    <div className="p-grid">
                        <div className="p-col-5 form-field-container">
                            <label>Valor Inicial</label>
                            <InputText name="start" value={this.state.start} onChange={this.onChangeHandler} />
                        </div>
                        <div className="p-col-5 form-field-container">
                            <label>Valor Final</label>
                            <InputText name="end" value={this.state.end} onChange={this.onChangeHandler} />
                        </div>
                    </div>
                </div>
            </Dialog>
        )
    }
}