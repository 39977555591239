import moment from "moment";
import { Column } from "primereact/column";
import React from "react";
import { connect } from "react-redux";
import { Page } from "../../../components/page/Page";
import { AppService, BUSINESS_UNIT_CHANGE_EVENT_NAME, COMPANY_CHANGE_EVENT_NAME } from "../../../services/app.service";
import * as utils from "../../../utils";
import { actions as accountConfigurationActions } from "../../accountConfiguration/accountConfiguration.actions";
import { actions as carrierActions } from "../../carrier/carrier.actions";
import { actions } from "../auditoriaTracking.actions";
import { OrderTable } from "../components/OrdersTable";
import { ShipmentTable } from "../components/ShipmentTable";
import { AuditoriaTrackingFilter } from "../components/auditoriaTrackingFilter";
import "./auditoriaTrackingListPageContainer.scss";


const mapStateToProps = state => {
  return {
    columns: state.auditoriaTrackingState.columns,
    records: state.auditoriaTrackingState.records,
    first: state.auditoriaTrackingState.index,
    rows: state.auditoriaTrackingState.max,
    totalRecords: state.auditoriaTrackingState.count,
    sortField: state.auditoriaTrackingState.sortField,
    order: state.auditoriaTrackingState.sortOrder,
    initialDateFilter: state.auditoriaTrackingState.initialDateFilter,
    finalDateFilter: state.auditoriaTrackingState.finalDateFilter,
    columnsTreeview: state.auditoriaTrackingState.columnsTreeview,
    expandedOrders: state.auditoriaTrackingState.expandedOrders,
    dialogApproveComponent: state.auditoriaTrackingState.dialogApproveComponent,
    currentApproveComponent: state.auditoriaTrackingState.currentApproveComponent,
    visible: state.auditoriaTrackingState.auditoriaTrackingFilter.lookupCarrier.visible,
    lookupCarrier: state.auditoriaTrackingState.auditoriaTrackingFilter.lookupCarrier,
    enableExportCSV: state.auditoriaTrackingState.records && state.auditoriaTrackingState.records.length > 0,
    // carriers: state.carrierState.records,
    // customFields: state.accountConfigurationState.customFields,
    // customFieldsLoading: state.accountConfigurationState.loading
  };
};
const mapDispatchToProps = dispatch => {
  return {
    paginate: paginationParams => dispatch(actions.paginate(paginationParams)),
    sortOrder: sortParams => dispatch(actions.sortOrder(sortParams)),
    applyFilter: filterParams => {
      dispatch(actions.setType(null));
      dispatch(actions.applyFilter(filterParams));
    },

    loadCarriers: () => dispatch(carrierActions.load()),
    loadCustomFields: () => dispatch(accountConfigurationActions.loadCustomFields()),

    initialDateChangeHandler: e => {
      dispatch(actions.setInitialDateFilter(e.value));
    },
    finalDateChangeHandler: e => {
      dispatch(actions.setFinalDateFilter(e.value));
    },
    nfeNumberChangeHandler: e => {
      dispatch(actions.setNfeNumberFilter(e.target.value));
    },
    setExpandedOrdersRows: expandedOrders => {
      dispatch(actions.setExpandedOrdersRows(expandedOrders));
    },
    setOrdersToApprove: order => {
      dispatch(actions.setOrdersToApprove(order));
    },
    approveOrder: (order) => {
      dispatch(actions.approveOrder(order));
    },

    // Dialog Approve Component
    showApproveComponentHandler: e => {
      dispatch(actions.setDialogApproveComponentVisible(true));
    },
    setDialogApproveComponentType: approvalType => {
      dispatch(actions.setDialogApproveComponentType(approvalType));
    },
    currentApproveComponentDescriptionChangeHandler: e => {
      if (e.target) {
        dispatch(actions.setCurrentApproveComponentDescription(e.target.value));
      } else
        dispatch(actions.setCurrentApproveComponentDescription(e));
    },
    hideApproveComponentHandler: e => {
      dispatch(actions.setDialogApproveComponentVisible(false));
    },
    // End Dialog Approve Component
    downloadHandle: e => {
      dispatch(actions.downloadCSV(e))
    },
    // Lookup Carrier
    lookupCarrierSetVisible: visible =>
      dispatch(actions.setLookupCarrierVisible(visible)),
    lookupCarrierPaginate: paginationParams =>
      dispatch(carrierActions.paginate(paginationParams)),
    lookupCarrierSortOrder: sortParams =>
      dispatch(carrierActions.sortOrder(sortParams)),
    lookupCarrierApplyFilter: filterParams => {
      dispatch(carrierActions.setType(null));
      dispatch(carrierActions.applyFilter(filterParams));
    },
    lookupCarrierLoad: () => dispatch(carrierActions.load()),
    setCarrierFilter: carrier => {
      dispatch(actions.setCarrierFilter(carrier));
    },
  };
};

class AuditoriaTrackingListPageContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expandedRows: null,
      filterVisible: true
    };
  }

  onPage = (e) => {
    this.props.paginate({
      max: e.rows,
      index: e.first
    })
  }
  onSort = (e) => {
    this.props.sortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField
    })


  }
  onFilter = (e) => {
    const filters = [];
    const keys = Object.keys(e.filters) || [];
    keys.forEach(key => {
      if (key == "oid") {
        filters.push({
          campo: key,
          valor: parseInt(e.filters[key].value),
          tipoFiltro: 'IGUAL_A'
        })
      } else {
        filters.push({
          campo: key,
          valor: e.filters[key].value,
          tipoFiltro: 'CONTEM'

        })
      }
    })
    this.props.applyFilter(filters)
  }
  toggleFilter = () => {
    this.setState(prev => {
      return {
        filterVisible: !prev.filterVisible
      };
    });
  };

  applyFilter = () => {
    const filters = [];
    if (this.props.initialDateFilter && this.props.finalDateFilter) {
      filters.push({
        campo: "nfeDate",
        campoComplementar: "nfeDate",
        valor: this.props.initialDateFilter,
        valorComplementar: this.props.finalDateFilter,
        tipoFiltro: "ENTRE",
      });
    }
    this.props.applyFilter(filters);
  }

  componentDidMount() {
    AppService.on(COMPANY_CHANGE_EVENT_NAME, this);
    AppService.on(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);

    const menuPath = this.props.history.location.pathname;
    // this.props.setCurrentMenu(menuPath);
    this.applyFilter();
  }
  componentWillUnmount() {
    AppService.off(COMPANY_CHANGE_EVENT_NAME, this);
    AppService.off(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
  }

  onReceiveAppNotifications = (eventName, params) => {
    if (
      eventName === COMPANY_CHANGE_EVENT_NAME ||
      eventName === BUSINESS_UNIT_CHANGE_EVENT_NAME
    ) {
      this.applyFilter();
    }
  };

  lookupCarrierOnPage = e => {
    this.props.lookupCarrierPaginate({
      max: e.rows,
      index: e.first
    });
  };
  lookupCarrierOnSort = e => {
    this.props.lookupCarrierSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField
    });
  };
  lookupCarrierOnFilter = e => {
    const filters = [];
    const keys = Object.keys(e.filters) || [];
    keys.forEach(key => {
      filters.push({
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: "CONTEM"
      });
    });
    this.props.lookupCarrierApplyFilter(filters);
  };
  lookupCarrierOnClick = e => {
    this.props.lookupCarrierApplyFilter([]);
    this.props.lookupCarrierLoad();
    this.props.lookupCarrierSetVisible(true);
  };
  lookupCarrierOnHide = e => {
    this.props.lookupCarrierApplyFilter([]);
    this.props.lookupCarrierSetVisible(false);
  };
  lookupCarrierOnConfirm = e => {
    this.props.lookupCarrierApplyFilter([]);
    this.props.setCarrierFilter(e.selection);
    this.props.lookupCarrierSetVisible(false);
  };
  lookupCarrierOnCancel = e => {
    this.props.lookupCarrierApplyFilter([]);
    this.props.setCarrierFilter(null);
    this.props.lookupCarrierSetVisible(false);
  };

  lookupCarrierOnComplete = e => {
    //this.props.lookupCarrierApplyFilter([])
  };

  lookupCarrierOnInputFilter = e => {
    const value = e.value;
    if (typeof value === "string") {
      const filters = [
        {
          campo: "tradeName",
          valor: e.value,
          tipoFiltro: "CONTEM"
        }
      ];
      this.props.setCarrierFilter({ name: value });
      this.props.lookupCarrierApplyFilter(filters);
    } else {
      this.props.setCarrierFilter(value);
    }
  };
  onDownload = e => {
    this.props.downloadHandle(e);
  }


  render() {
    const dateTemplate = (rowData, column) => {
      const clickHandler = (e) => {
        // this.onClickSelectedDeliveryDate(rowData);
      };
      const date = rowData[column.field];
      let formattedDate = moment(date).utc().format("DD/MM/YYYY");
      if (formattedDate === "Invalid date" && column.field === "pickupDate" || column.field === "deliveryForecast") {
        formattedDate = "Data não cadastrada";
      } else if (formattedDate === "Invalid date" && column.field === "deliveryDeadlineDate") {
        formattedDate = "Prazo não cadastrado";
      } else if (formattedDate === "Invalid date" && column.field === "issueDate") {
        formattedDate = "Prazo não cadastrado";
      }
      if (formattedDate === "Pendente") {
        return (
          <div style={{ textAlign: "center" }}>
            <a onClick={clickHandler}>{formattedDate}</a>
          </div>
        );
      } else {
        return <div style={{ textAlign: "center" }}>{formattedDate}</div>;
      }
    };

    const payDateTemplate = (rowData, column) => {
      let date;
      if (rowData && rowData.auditOrder) {
        date = rowData.auditOrder.dataPagamento;
      }
      let formattedDate = moment(date).utc().format("DD/MM/YYYY");
      if (date == undefined) {
        return (
          <div style={{ textAlign: "center" }}>Pendente</div>
        );
      } else {
        return <div style={{ textAlign: "center", color: "green" }}>{formattedDate}</div>;
      }
    };
    const quoteTemplate = (rowData, column) => {
      return (
        <span style={{ textAlign: "center" }}>
          {utils.numberToCurrency(rowData[column.field], "REAL")}
        </span>
      );
    };

    const driverTemplate = (rowData, column) => {
      const driver = rowData[column.field];
      if (driver) {
        return <div style={{ textAlign: "center" }}>{driver}</div>;
      }
      else {
        return <div style={{ textAlign: "center" }}>Motorista não informado</div>;
      }
    }
    const canhoto = (rowData, column) => {
      const occurrence = rowData.occurrence;
      if (occurrence) {
        return <div style={{ textAlign: "center", color: "green" }}>SIM</div>;
      }
      else {
        return <div style={{ textAlign: "center", color: "red" }}>NÃO</div>;
      }
    }
    const statusTemplate = (rowData, column) => {
      let statusColor = "";
      if (!rowData.auditOrder) {
        statusColor = "black";
        return (
          <div style={{ textAlign: "center", color: statusColor }}>
            PENDDING
          </div>
        );
      }
      const status = rowData.auditOrder.status;
      if (status === "APPROVED" && rowData.auditOrder.code === rowData.code) {
        statusColor = "green";
        return (
          <div style={{ textAlign: "center", color: statusColor }}>
            {status}
          </div>
        );
      }
    }

    const distanceTemplate = (rowData, column) => {
      const distance = rowData[column.field];
      let formattedDistance = utils.formatNumber(distance, 2);
      if (distance) {
        return <div style={{ textAlign: "center" }}>{formattedDistance} km</div>;
      }
    }

    const onApproveAllComponents = () => {
      // this.props.approveAllComponents();
      this.props.hideApproveComponentHandler();
    };

    const onApproveComponent = () => {
      this.props.approveOrder();
      this.props.hideApproveComponentHandler();
    };

    const onCancelApproveComponent = () => {
      this.props.hideApproveComponentHandler();
      this.props.currentApproveComponentDescriptionChangeHandler("");
    };

    const dialogApproveComponent = {
      description: this.props.currentApproveComponent.description,
      actions: {
        onCancel: onCancelApproveComponent,
        onChangeDescription: this.props.currentApproveComponentDescriptionChangeHandler,
        onApprove:
          this.props.dialogApproveComponent.approvalType === "ALL"
            ? onApproveAllComponents
            : onApproveComponent
      },
      ...this.props.dialogApproveComponent
    };

    const componentActionsNfe = (rowData, column) => {
      let btnApproveDisabled = !rowData.needApproval;
      let textTitle = "Aprovar todos os componentes divergentes do CTe"
      if (rowData.isCTeCompra && rowData.isAproveBloqueado) {
        textTitle = "CTE de compra ou devolução bloqueado para aprovação, CFOP:" + rowData.cfop;
        btnApproveDisabled = true;
      }

      const btnReprocessDisabled = !rowData.needApproval;
      const idCTe = rowData && rowData.oid;
      const pendings = rowData && rowData.pendings;
      // const alertMessage = hasOpenPending(pendings)
      //   ? "Lembrete: Este CTe possui documentos pendentes."
      //   : "";

      const body = (
        <div className="p-grid">
          <div className="p-col-12 lxActionButtons">
            <button
              className="lognex-btn-highlighted"
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                // approveAllComponentsOnClick(idCTe, alertMessage);
              }}
              disabled={btnApproveDisabled}
              title={textTitle}
            >
              <i className="pi pi-thumbs-up"></i>
            </button>

            <button
              className="lognex-btn-highlighted"
              disabled={true}
              title="Reprovar componente(Em desenvolvimento)"
            >
              <i className="pi pi-thumbs-down"></i>
            </button>
          </div>
        </div>
      );
      return body;
    };

    const treViewActionsTemplate = (rowData, column) => {
      // const btnApproveDisabled = !(
      //   rowData.needApproval && rowData.parent.needApproval
      // );
      const order = rowData;
      // const alertMessage = hasOpenPending(pendings)
      //   ? "Lembrete: Este CTe possui documentos pendentes."
      //   : "";
      const body = (
        <div className="p-grid">
          <div className="p-col-12 lxActionButtons">
            <button
              className="lognex-btn-highlighted"
              onClick={e => {
                approveOrderOnClick(order);
              }}
              // disabled={btnApproveDisabled}
              title={"Aprovar componente"}
            >
              <i className="pi pi-thumbs-up"></i>
            </button>

            <button
              className="lognex-btn-highlighted"
              disabled={true}
              title="Reprovar componente(Em desenvolvimento)"
            >
              <i className="pi pi-thumbs-down"></i>
            </button>
          </div>
        </div>
      );
      return body;
    };
    const columnsTreeview = this.props.columnsTreeview.map((col, index) => {
      const key = `${col.index}-${col.field}`;
      if (col.field === "image") {
        return (<Column key={key} field={col.field} header={col.header} body={canhoto} />);
      }
      if (col.field === "auditOrder.status") {
        return (<Column key={key} field={col.field} header={col.header} body={statusTemplate} />);
      }
      if (col.field === "auditOrder.dataPagamento") {
        return (<Column key={key} field={col.field} header={col.header} body={payDateTemplate} />);
      }
      if (col.field === "actions") {
        return (
          <Column
            key={key}
            field={col.field}
            header={col.header}
            body={treViewActionsTemplate}
          />
        );
      }
      return <Column {...col} key={key} />;
    });

    const approveOrderOnClick = order => {
      this.props.showApproveComponentHandler();
      this.props.setDialogApproveComponentType("COMPONENT");
      this.props.setOrdersToApprove(order)
      // this.props.approveOrder(order);
    };

    const templateShipmentTbExpandedRow = (rowData, e) => {
      return (
        <OrderTable
          records={rowData.orders}
          columnsTreeview={columnsTreeview}
        // showNFesHandler={() => {
        //   this.props.showNFesHandler(idCTe);
        // }}
        // hideNFesHandler={this.props.hideNFesHandler}
        // showQuoteHandler={this.props.showQuoteHandler}
        // hideQuoteHandler={this.props.hideQuoteHandler}
        // dialogNFes={this.props.dialogNFes}
        // nfesTbColumns={nfesTbColumns}
        // dialogQuote={this.props.dialogQuote}
        // nfeRecords={this.props.nfeRecords}
        // // dialogQuote={this.props.dialogQuote}
        // showHistoryHandler={() => {
        //   this.props.showHistoryHandler(idCTe);
        // }}
        // hideHistoryHandler={this.props.hideHistoryHandler}
        // historyRecords={this.props.historyRecords}
        // dialogHistory={this.props.dialogHistory}
        // historyTbColumns={historyTbColumns}
        // showLogsHandler={() => {
        //   this.props.showLogsHandler(idCTe);
        // }}
        // hideLogsHandler={this.props.hideLogsHandler}
        // logRecords={this.props.logRecords}
        // dialogLogs={this.props.dialogLogs}
        // logTbColumns={logTbColumns}
        />
      );
    };

    const setExpandedOrdersRowsByRowClick = e => {
      const expandedOrders = this.props.expandedOrders;
      const newExpandedRow = e.data;
      //* Verify wether it must expand or collapse
      if (
        expandedOrders &&
        expandedOrders.length > 0 &&
        newExpandedRow.oid === expandedOrders[0].oid
      ) {
        this.props.setExpandedOrdersRows([]);
      } else {
        this.props.setExpandedOrdersRows([newExpandedRow]);
      }
    };

    const setExpandedOrdersRows = e => {
      if (e.data && e.data.length > 1) {
        this.props.setExpandedOrdersRows([e.data[1]]);
      } else {
        this.props.setExpandedOrdersRows(e.data);
      }
    };

    const header = (
      <div>
        <div>
          <h1>Auditoria de embarque</h1>
        </div>
        <div className="lxLineToggleHeader">
          <hr className="lxSplitter"></hr>
          <button
            className="lognex-btn-highlighted"
            title={
              this.state.filterVisible ? "Minimizar filtro" : "Maximizar filtro"
            }
            onClick={this.toggleFilter}
          >
            <i
              className={`pi pi-${this.state.filterVisible ? "minus" : "plus"}`}
            ></i>
          </button>
        </div>
        <div
          className={`p-grid lxFilter ${this.state.filterVisible ? "" : "lxFilterInvisible"
            }`}
        >
          <div className={`p-col-11 `}>
            <AuditoriaTrackingFilter
              initialDateFilter={this.props.initialDateFilter}
              initialDateChangeHandler={this.props.initialDateChangeHandler}
              finalDateFilter={this.props.finalDateFilter}
              finalDateChangeHandler={this.props.finalDateChangeHandler}
              lookupCarrierApplyFilter={this.lookupCarrierOnInputFilter}
              lookupCarrierOnComplete={this.lookupCarrierOnComplete}
              lookupCarrierOnClick={this.lookupCarrierOnClick}
              lookupCarrierOnHide={this.lookupCarrierOnHide}
              lookupCarrierOnConfirm={this.lookupCarrierOnConfirm}
              lookupCarrierOnCancel={this.lookupCarrierOnCancel}
              // lookupCarrierOnEdit={this.lookupCarrierOnEdit}
              lookupCarrierOnPage={this.lookupCarrierOnPage}
              lookupCarrierOnSort={this.lookupCarrierOnSort}
              lookupCarrierOnFilter={this.lookupCarrierOnFilter}
              lookupCarrier={this.props.lookupCarrier}
              carrierFilter={this.props.carrierFilter}
              applyTrackingFilter={this.applyFilter}
              enabledExportCSV={this.props.enableExportCSV}
              onDownloadCSV={this.onDownload}
            />
          </div>

        </div>
      </div>


    );

    const columns = this.props.columns.map(col => {
      if (col.field == 'actions') {
        return <Column key={col.field} field={col.field} header={col.header} body={componentActionsNfe} />
      }
      if (col.field == 'issueDate' || col.field == 'deliveryDate' || col.field == 'pickupDate' || col.field == 'deliveryForecast') {
        return <Column key={col.field} field={col.field} header={col.header} body={dateTemplate} />
      }
      if (col.field == 'driver.name') {
        return <Column key={col.field} field={col.field} header={col.header} body={driverTemplate} />
      }
      if (col.field == 'distance') {
        return <Column key={col.field} field={col.field} header={col.header} body={distanceTemplate} />
      }
      if (col.field == 'quote') {
        return <Column key={col.field} field={col.field} header={col.header} body={quoteTemplate} />
      }
      return (<Column {...col} style={{ textAlign: "center" }} key={col.field} />)
    })

    const content = (
      <ShipmentTable
        {...this.props}
        onFilter={this.onFilter}
        onPage={this.onPage}
        onSort={this.onSort}
        columns={columns}
        onRowToggle={setExpandedOrdersRows}
        rowExpansionTemplate={templateShipmentTbExpandedRow}
        expandendOrdersRowns={this.props.expandedOrders}
        shipmentRowToggleHandler={setExpandedOrdersRowsByRowClick}
        dialogApproveComponent={dialogApproveComponent}
      ></ShipmentTable>
    )

    // const content = <AuditoriaTrackingList  {...this.props} onFilter={this.onFilter} onPage={this.onPage} onSort={this.onSort} columns={columns} onRowClick={this.onRowClick} />;
    return <Page header={header} content={content} />;
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(AuditoriaTrackingListPageContainer);
