
    import React from 'react';
    import ModalListPageContainer from './ModalListPageContainer';
    
    export class ModalListPage extends React.Component{
        
        render(){
          return (
            <ModalListPageContainer {...this.props}/>
          )
        }
    }