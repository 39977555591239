export function validate(record) {
    const errors = [];

    if (!record.nome || record.nome.trim() === "") {
        errors.push({ field: 'nome', message: 'O nome do módulo é obrigatório!' });
    }

    const result = {
        ok: errors.length === 0,
        errors,
        record
    }

    return new Promise((resolve, reject) => {
        const action = result.ok ? resolve : reject;
        return action(result);
    })
}