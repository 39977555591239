import { types } from "./order-log.actions";
import { Reducer } from "../../services/reducer.factory";
import moment from "moment";

const initialState = {
  columns: [
    {
      field: "status",
      header: "Status",
      columnKey: "status",
      sortable: false,
      filter: true,
    },
    {
      field: "order",
      header: "Pedido",
      columnKey: "order",
      sortable: false,
      filter: false,
    },
    {
      field: "createdAt",
      header: "Data Hora",
      columnKey: "createdAt",
      sortable: false,
      filter: false,
    },
    {
      field: "carrier",
      header: "Transportadora",
      columnKey: "carrier",
      sortable: false,
      filter: false,
    },
    {
      field: "origin",
      header: "Origem",
      columnKey: "origin",
      sortable: false,
      filter: false,
    },
    {
      field: "destination",
      header: "Destino",
      columnKey: "destination",
      sortable: false,
      filter: false,
    },
    {
      field: "weight",
      header: "Peso",
      columnKey: "weight",
      sortable: false,
      filter: false,
    },
    {
      field: "cubage",
      header: "Cubagem",
      columnKey: "cubage",
      sortable: false,
      filter: false,
    },
    // {
    //     field: "error",
    //     header: "Erro",
    //     columnKey: "error",
    //     sortable: false,
    //     filter: false
    // }
  ],
  currentRecord: {
    status: "",
    order: "",
    createdAt: "",
    carrier: "",
    origin: "",
    destination: "",
    weight: 0,
    cubage: 0,
    error: "",
  },
  currentStatusFilter: null,
  statusFilterOptions : [
    { label: 'Todos', value: null },
    { label: "Sem Erro", value: "INFO" },
    { label: "Com Erro", value: "ERROR" },
  ],
  orderLogFilter: {
    dataFilter: {
      initialDateFilter: moment().startOf("month").toDate(),
      finalDateFilter: moment().toDate(),
    },
    orderFilter: "",
  },
  filter: {
    filters: [],
    sortField: "",
    sortOrder: 0,
    errors: {},
  },
  index: 0,
  max: 50,
  count: 2,
};

const reducer = new Reducer(initialState, types);

export function reducerOrderLog(state, action) {
  state = reducer.execute(state, action);
  return execute(state, action);
}

function execute(state, action) {
  let result = state;
  switch (action.type) {
    case types.SET_DATE_FIELD_FORM:
      return setDateFieldForm(state, action);
    case types.SET_FIELD_ORDER_FILTER_FORM:
      return setFieldOrderFilterForm(state, action);
    case types.SET_RECORD:
      return setRecord(state, action);
    case types.SET_CURRENT_STATUS_FILTER:
      return setCurrentStatusFilter(state, action);  
    default:
      return result;
  }
}

function setRecord(state, action) {
  let currentRecord = state.currentRecord;
  currentRecord = action.record;
  return { ...state, currentRecord };
}

function setCurrentStatusFilter(state, action) {
  let currentStatusFilter = action.currentStatusFilter;
  return { ...state, currentStatusFilter }
}

function setDateFieldForm(state, action) {
  let orderLogFilter = state.orderLogFilter;
  let dateFilter = orderLogFilter.dataFilter;
  dateFilter[action.name] = action.value;
  orderLogFilter[dateFilter] = dateFilter;
  return { ...state, orderLogFilter };
}

function setFieldOrderFilterForm(state, action) {
  let orderLogFilter = state.orderLogFilter;
  orderLogFilter["orderFilter"] = action.value;
  return { ...state, orderLogFilter };
}
