
import { validate } from './validations';
import { build } from '../../services/crudFactory';
import { Action } from '../../services/actions.factory';
import api from '../../services/api.service';
import { messageActions, makeMessages } from '../../store/message';
const BASE_PATH = '/lognex/freightCalculations';
const prefix = 'crudFreightCalculation';
const crud = build(validate, BASE_PATH);


const newTypes = {
    SET_LOOKUP_ORIGIN_VISIBLE: `${prefix}setLookupOriginVisible`,
    SET_LOOKUP_DESTINATION_VISIBLE: `${prefix}setLookupDestinationVisible`,
    SET_LOOKUP_CLIENT_VISIBLE: `${prefix}setLookupClientVisible`,
    LOAD_QUOTES: `${prefix}loadQuotes`,
    SET_LOCATION_ORIGIN: `${prefix}setLocationOrigin`,
    SET_CLIENT: `${prefix}setClient`,
    SET_LOCATION_DESTINATION: `${prefix}setLocationDestination`,
    SET_VALUE: `${prefix}setValue`,
    SET_WEIGHT: `${prefix}setWeight`,
    SET_VOLUMES: `${prefix}setVolume`,
    SET_QUANTITY_OF_ITEMS: `${prefix}setQuantityOfItems`,
    SET_ZIP_CODE_ORIGIN: `${prefix}setZipCodeOrigin`,
    SET_ZIP_CODE_DESTINATION: `${prefix}setZipCodeDestination`,
    SET_CUBAGE: `${prefix}setCubage`,
    SET_DISTANCE: `${prefix}setDistance`,
    SHOW_MESSAGE: `${prefix}showMessage`,
    SET_CURRENT_LOCATION_TYPE_FILTER: `${prefix}setCurrentLocationTypeFilter`,
    SET_LOOKUP_VEHICLE_TYPE_VISIBLE: `${prefix}setLookupVehicleTypeVisible`,
    SET_VEHICLE_TYPE: `${prefix}setLookupVehicleType`,
    CLEAR_QUOTES: `${prefix}clearQuotes`,
    SET_VISIBLE_MODAL_CALCULATE_CUBAGE: `${prefix}setVisibleModalCalculateCubage`,
    ADD_COMPONENT_LIST_CALCULATE_CUBAGE: `${prefix}addComponentListCalculateCubage`,
    REMOVE_COMPONENT_LIST_CALCULATE_CUBAGE: `${prefix}removeComponentListCalculateCubage`,
    SET_VALUES_FIELDS_CALCULATE_FREIGHT: `${prefix}setValuesFieldCalculateFreitgh`,
    CLEAR_FIELDS_CALCULATE_FREIGHT:`${prefix}clearFieldCalculateFreitgh`,
    SET_TYPE_CALCULATE:`${prefix}setTypeCalculate`,
}

const actions = new Action(prefix, crud);

const types = Object.assign({}, actions.types, newTypes);

actions.setTypeCalculate = (typeCalculate) => {
    return {
        type: types.SET_TYPE_CALCULATE,
        typeCalculate
    }
}

actions.clearFieldCalculateFreitgh = () => {
    return {
        type: types.CLEAR_FIELDS_CALCULATE_FREIGHT,
    }
}



actions.setValuesFieldCalculateFreitgh = (values) => {
    return {
        type: types.SET_VALUES_FIELDS_CALCULATE_FREIGHT,
        values
    }
}


actions.removeComponentListCalculateCubage = (component) => {
    return {
        type: types.REMOVE_COMPONENT_LIST_CALCULATE_CUBAGE,
        component
    }
}

actions.addComponentListCalculateCubage = () => {
    return {
        type: types.ADD_COMPONENT_LIST_CALCULATE_CUBAGE,
    }
}


actions.setListComponentsCalculateCubage = (list) => {
    return {
        type: types.SET_LIST_COMPONENTS_CALCULATE_CUBAGE,
        list
    }
}

actions.setVisibleModalCalculateCubage = (visible) => {
    return {
        type: types.SET_VISIBLE_MODAL_CALCULATE_CUBAGE,
        visible
    }
}

const validateCalculate = (params, locationFilterType) => {
    const errors = [];

    if (locationFilterType === 'LOCATION') {
        if (!params.zipCodeOriginFromLocation) {
            errors.push('A origem é obrigatória!');
        }
        if (!params.zipCodeDestinationFromLocation) {
            errors.push('O Destino é obrigatório!');
        }
    } else {
        if (params.zipCodeOrigin === "" ||
            params.zipCodeOrigin === "0" ||
            params.zipCodeOrigin === 0) {
            errors.push('A origem é obrigatória!');
        } else if (params.zipCodeOrigin.toString().length != 8) {
            errors.push('O CEP de origem deve conter 8 dígitos!');
        }

        if (params.zipCodeDestination === "" || params.zipCodeDestination === "0" || params.zipCodeDestination === 0) {
            errors.push('O destino é obrigatório!');
        } else if (params.zipCodeDestination.toString().length != 8) {
            errors.push('O CEP de destino deve conter 8 dígitos!');
        }
    }

    if (parseFloat(params.value) === 0 || params.value === "") {
        errors.push('O valor da nota é obrigatório!');
    }

    if (parseFloat(params.weight) === 0 || params.weight === "") {
        errors.push('O peso é obrigatório!');
    }

    return errors;
}

function calcPercentNFeValue(records, nfeValue){
    return records.map(record =>{
        return {
            nfePercent : record.totalFreightValue * 100 / nfeValue,
            ...record
        }
    })

}

const postCalculateFreight = (dispatch, freightCalculationParams, currentLocationTypeFilter) => {

    const errors = validateCalculate(freightCalculationParams, currentLocationTypeFilter)
    const body = Object.assign({}, {
        oidOrigin:parseInt(currentLocationTypeFilter === 'LOCATION' ? freightCalculationParams.locationOrigin.oid : null),
        oidDestination:parseInt(currentLocationTypeFilter === 'LOCATION' ? freightCalculationParams.locationDestination.oid : null),
        zipCodeDestination: parseInt(currentLocationTypeFilter === 'LOCATION' ? freightCalculationParams.zipCodeDestinationFromLocation : freightCalculationParams.zipCodeDestination),
        zipCodeOrigin: parseInt(currentLocationTypeFilter === 'LOCATION' ? freightCalculationParams.zipCodeOriginFromLocation : freightCalculationParams.zipCodeOrigin),
        weight: parseFloat(freightCalculationParams.weight),
        value: parseFloat(freightCalculationParams.value),
        cubage: parseFloat(freightCalculationParams.cubage),
        distance: parseFloat(freightCalculationParams.distance),
        volumes: parseFloat(freightCalculationParams.volumes),
        quantityOfItems: parseInt(freightCalculationParams.quantityOfItems),
        vehicleType: freightCalculationParams.vehicleType && freightCalculationParams.vehicleType.name ? freightCalculationParams.vehicleType.name.toLowerCase() : "",
        clientCNPJ: freightCalculationParams.client ? freightCalculationParams.client.cnpj : null,
        shipper: freightCalculationParams.shipper || "",
    })

    if (freightCalculationParams.deliveryNumbers) {
        body.deliveryNumbers = freightCalculationParams.deliveryNumbers
    }

    dispatch(messageActions.messageClearMessages());
    if (errors.length == 0) {
        dispatch(actions.fetchRecord());
        api.post('/api/v1/lognex/freightcalculation/all', body)
            .then(({ data }) => {
                data = calcPercentNFeValue(data, freightCalculationParams.value)
                dispatch({
                    type: types.LOAD_QUOTES,
                    quotes: data
                })
                dispatch(actions.hideLoader());
                if (!data || data.length <= 0) {
                    dispatch(
                        messageActions.messageShowMessages(
                            makeMessages(["Não foram encontradas tabelas de frete para as especificações solicitadas"], 'error')
                        )
                    );
                } else {
                    dispatch(
                        messageActions.messageShowMessages(
                            makeMessages(["Cálculo concluído com sucesso"], 'success')
                        )
                    );
                }
            })
            .catch(err =>{
                if (err){
                    const {response = {}} = err;
                    const {status = 599} = response;
                    dispatch(actions.hideLoader());
                    dispatch(
                        messageActions.messageShowMessages(
                            makeMessages([`Desculpe, Ocorreu um erro ao executar o processamento do cálculo [cod: ${status}].`], 'error')
                        )
                    );
                }   
                
            })
    } else {
        dispatch(
            messageActions.messageShowMessages(
                makeMessages(errors, 'error')
            )
        );

    }
}

function getZipCodeOrigin(location) {
    if (location) {
        let minorZipCode = 99999999;
        location.zipCodeRanges.forEach(zipCodeRange => {
            if (zipCodeRange.start < minorZipCode) {
                minorZipCode = zipCodeRange.start.toString()
                if (minorZipCode.length === 7) {
                    minorZipCode = '0' + minorZipCode
                }
            }
        });
        return minorZipCode
    } else {
        return 0
    }
}

function getZipCodeDestination(location) {
    if (location) {
        let maxZipCode = 0;

        location.zipCodeRanges.forEach(zipCodeRange => {
            if (zipCodeRange.end > maxZipCode) {
                maxZipCode = zipCodeRange.end.toString()
                if (maxZipCode.length === 7) {
                    maxZipCode = '0' + maxZipCode
                }
            }
        });
        return maxZipCode
    } else {
        return 0
    }
}
actions.calculateFreight = () => {
    return (dispatch, getState) => {
        
        const freightCalculationParams = getState().freightCalculationState.freightCalculationParams;
        const currentLocationTypeFilter = getState().freightCalculationState.currentLocationTypeFilter;
        freightCalculationParams.zipCodeOriginFromLocation = null
        freightCalculationParams.zipCodeDestinationFromLocation = null
        if (currentLocationTypeFilter === 'LOCATION') {
            freightCalculationParams.zipCodeOriginFromLocation = getZipCodeOrigin(freightCalculationParams.locationOrigin)
            freightCalculationParams.zipCodeDestinationFromLocation = getZipCodeDestination(freightCalculationParams.locationDestination)
        }
        postCalculateFreight(dispatch, freightCalculationParams, currentLocationTypeFilter);
       
    };
}

actions.apiCalculateFreight = (params) => {
    return (dispatch) => {
        postCalculateFreight(dispatch, params.freightCalculationParams, params.currentLocationTypeFilter);
    };
}

actions.setLookupOriginVisible = (visible) => {
    return {
        type: types.SET_LOOKUP_ORIGIN_VISIBLE,
        visible
    }
}
actions.setLookupDestinationVisible = (visible) => {
    return {
        type: types.SET_LOOKUP_DESTINATION_VISIBLE,
        visible
    }
}

actions.setLookupClientVisible = (visible) => {
    return {
        type: types.SET_LOOKUP_CLIENT_VISIBLE,
        visible
    }
}
actions.setLookupVehicleTypeVisible = (visible) => {
    return {
        type: types.SET_LOOKUP_VEHICLE_TYPE_VISIBLE,
        visible
    }
}

actions.setValue = (value) => {
    return {
        type: types.SET_VALUE,
        value
    }
}

actions.setWeight = (weight) => {
    return {
        type: types.SET_WEIGHT,
        weight
    }
}

actions.setVolumes = (volumes) => {
    return {
        type: types.SET_VOLUMES,
        volumes
    }
}

actions.setQuantityOfItems = (quantityOfItems) => {
    return {
        type: types.SET_QUANTITY_OF_ITEMS,
        quantityOfItems
    }
}

actions.setLocationOrigin = (locationOrigin) => {
    return {
        type: types.SET_LOCATION_ORIGIN,
        locationOrigin
    }
}

actions.setClient = (client) => {
    return {
        type: types.SET_CLIENT,
        client
    }
}
actions.setVehicleType = (vehicleType) => {
    return {
        type: types.SET_VEHICLE_TYPE,
        vehicleType
    }
}

actions.setLocationDestination = (locationDestination) => {
    return {
        type: types.SET_LOCATION_DESTINATION,
        locationDestination
    }
}

actions.setZipCodeOrigin = (zipCodeOrigin) => {
    return {
        type: types.SET_ZIP_CODE_ORIGIN,
        zipCodeOrigin
    }
}

actions.setZipCodeDestination = (zipCodeDestination) => {
    return {
        type: types.SET_ZIP_CODE_DESTINATION,
        zipCodeDestination
    }
}

actions.setCubage = (cubage) => {
    return {
        type: types.SET_CUBAGE,
        cubage
    }
}
actions.setDistance = (distance) => {
    return {
        type: types.SET_DISTANCE,
        distance
    }
}

actions.setCurrentLocationTypeFilter = (locationTypeFilter) => {
    return {
        type: types.SET_CURRENT_LOCATION_TYPE_FILTER,
        locationTypeFilter
    }
}

actions.clearQuotes = () => {
    return {
        type: types.CLEAR_QUOTES
    }
}


export { types, actions }