
    import React from 'react';
    import RegionFormPageContainer from './RegionFormPageContainer';
    
    export class RegionFormPage extends React.Component{
        render(){
            return (
                <RegionFormPageContainer {...this.props}/>
            )
        }
    }
    