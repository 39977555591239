
    import React from 'react';
    import EnchentesRSFormPageContainer from './EnchentesRSFormPageContainer';
    
    export class EnchentesRSFormPage extends React.Component{
        render(){
            return (
                <EnchentesRSFormPageContainer {...this.props}/>
            )
        }
    }
    