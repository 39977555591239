import { types } from "./cargoshipment-report-km.actions";
import { Reducer } from "../../services/reducer.factory";
import moment from "moment";

const initialState = {     

  columns: [
    {
      field: "centro",
      header: "CD",
      columnKey: "centro",
      sortable: false,
      filter: false,
    },  
    {
      field: "nroEmbarque",
      header: "Embarque",
      columnKey: "nroEmbarque",
      sortable: false,
      filter: false,
    },  
     
    {
      field: "dataAgendamento",
      header: "Agendam",
      columnKey: "dataAgendamento",
      sortable: false,
      filter: false,
    },  
    {
      field: "dataCriacaoEmbarque",
      header: "Criação Shipment",
      columnKey: "dataCriacaoEmbarque",
      sortable: false,
      filter: false,
    }, 
    {
      field: "tipoVeiculo",
      header: "Tipo Veículo",
      columnKey: "tipoVeiculo",
      sortable: false,
      filter: false,
    },
    {
      field: "custoFreteEmbarque",
      header: "Frete",
      columnKey: "custoFreteEmbarque",
      sortable: false,
      filter: false,
    },
    {
      field: "totalKm",
      header: "Km",
      columnKey: "totalKm",
      sortable: false,
      filter: false,
    },
    {
      field: "totalSacos",
      header: "Qtde Embal",
      columnKey: "totalSacos",
      sortable: false,
      filter: false,
    },
    {
      field: "embalageType",
      header: "Tipo Embal",
      columnKey: "embalageType",
      sortable: false,
      filter: false,
    },
    {
      field: "qtdEntregas",
      header: "Entregas",
      columnKey: "qtdEntregas",
      sortable: false,
      filter: false,
    },
    {
      field: "valorCarga",
      header: "Valor Carga",
      columnKey: "valorCarga",
      sortable: false,
      filter: false,
    },
    {
      field: "percentFreteSobProdutos",
      header: "%Frete",
      columnKey: "percentFreteSobProdutos",
      sortable: false,
      filter: false,
    },
    {
      field: "transportadora",
      header: "Transp",
      columnKey: "transportadora",
      sortable: false,
      filter: false,
    },
  ],                     


  filterFields:{
    initialDate:  moment().subtract(2,"days").toDate(),
    finalDate: moment().toDate(),
  }
};
 
const reducer = new Reducer(initialState, types);

export function CRUDCargoShipmentReportKmReducer(state, action) {
  state = reducer.execute(state, action);
  return execute(state, action);
}

function execute(state, action) {
  let result = state;
  switch (action.type) {
    
    case types.SET_INITIAL_DATE:
      return initialDateChangeHandler(state, action);
      case types.SET_FINAL_DATE:
        return finalDateChangeHandler(state, action);
  }
  return result;
}
  

function finalDateChangeHandler(state, action) {
  let filterFields = state.filterFields;
  filterFields.finalDate = action.finalDate;
  return Object.assign({}, state, { filterFields });
}
  

function initialDateChangeHandler(state, action) {
  let filterFields = state.filterFields;
  filterFields.initialDate = action.initialDate;
  return Object.assign({}, state, { filterFields });
}
  
