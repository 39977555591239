import React from 'react';
import './TimelineBallon.scss'
import { TimelineCard } from './TimelineCard'
export const TimelineBallon = (props) => {
    return (
        
        <div className={`timeline__ballon-${props.right ? "right" : "left"}`}>
            <TimelineCard 
                title={props.title} 
                subTitle={props.subTitle} 
                text={props.text} 
                resources={props.resources} 
                showVisibleModalImage={props.showVisibleModalImage}
            ></TimelineCard>
            <div className="timeline_ballon-spike-container">
                <div className="timeline__ballon-spike">
               
                </div>
            </div>
            <div className="timeline-horizontalLine"></div>
        </div>
    );
}
