import { validate } from './validations';
import {build} from '../../services/crudFactory';
import {Action} from '../../services/actions.factory';
// import api from '../../services/api.service';
const BASE_PATH = '/lognex/cfop';
const crud = build(validate,BASE_PATH);
const prefix = 'crudCfop';

const actions = new Action(prefix,crud);

const newTypes = {
    SET_ONCHANGE_CFOP: `${prefix}onChangeCFOP`
}

const types = Object.assign({}, actions.types, newTypes);

actions.onChangeCFOP = (cfop) => {
    return {
        type: types.SET_ONCHANGE_CFOP,
        cfop
    }
}

export {types, actions};
