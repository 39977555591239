import React from 'react';
import { connect } from 'react-redux';
import { Column } from 'primereact/column';
import { Page } from "../../../components/page/Page";
import Toolbar from "../../../components/toolbar/Toolbar"
import { routes } from '../../../constants';
import { PackingConverterList } from './PackingConverterList'
import { actions } from '../packing-converter.actions'
import { AppService, COMPANY_CHANGE_EVENT_NAME, BUSINESS_UNIT_CHANGE_EVENT_NAME } from "../../../services/app.service";


const mapStateToProps = store => {
  return {
    columns: store.crudPackingConverterState.columns,
    records: store.crudPackingConverterState.records,
    first: store.crudPackingConverterState.index,
    rows: store.crudPackingConverterState.max,
    totalRecords: store.crudPackingConverterState.count,
    sortField: store.crudPackingConverterState.sortField,
    order: store.crudPackingConverterState.sortOrder
  }
}

const mapDispatchToProps = dispatch => {
  return {
    paginate: paginationParams => dispatch(actions.paginate(paginationParams)),
    sortOrder: sortParams => dispatch(actions.sortOrder(sortParams)),
    load: () => dispatch(actions.load()),
    fetchRecord: () => dispatch(actions.fetchRecord()),
    hideLoader: () => dispatch(actions.hideLoader()),
    importXLSX: (data) => dispatch(actions.importXLSX(data)),
  }
}

class PackingConverterListPageContainer extends React.Component {

  newHandler = () => {
    this.props.history.push(`${routes.PATH_PACKING_CONVERTER}/novo`)
  }

  onRowClick = (e) => {
    const oid = e && e.data && e.data.oid;
    if (oid) {
      this.props.history.push(`${routes.PATH_PACKING_CONVERTER}/${oid}`)
    }
  }

  componentDidMount() {
    this.props.load();
    AppService.on(COMPANY_CHANGE_EVENT_NAME, this);
    AppService.on(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
  }
  componentWillUnmount() {
    AppService.off(COMPANY_CHANGE_EVENT_NAME, this);
    AppService.off(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
  }

  onReceiveAppNotifications = (eventName, params) => {
    if (eventName === COMPANY_CHANGE_EVENT_NAME || eventName === BUSINESS_UNIT_CHANGE_EVENT_NAME) {
      this.props.load();
    }
  }



  onPage = (e) => {
    this.props.paginate({
      max: e.rows,
      index: e.first
    })
  }

  onSort = (e) => {
    console.log('sorting', e);
    this.props.sortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField
    })
  }

  onImportXLSXPackageConverter = ({ target }) => {
    if (target.files && target.files.length > 0) {
      this.props.importXLSX(target.files);
    }
  }


  render() {
    const filePath = '/resources/templates/template_package_converter.xlsx'
    const header = (
      <div>
        <h1>Packing-converter</h1>
        <div>
          <Toolbar
            onNew={this.newHandler}
            newButtonStyle='highlighted'
            onImportJSON={this.onImportXLSXPackageConverter}
            XLSXTemplatePath={filePath}
          >
          </Toolbar>
        </div>
      </div>
    )

    const columns = this.props.columns.map(col => {
      return (<Column {...col} key={col.field} />)
    })

    const content = <PackingConverterList
      {...this.props}
      onFilter={this.onFilter}
      onPage={this.onPage}
      onSort={this.onSort}
      columns={columns}
      onRowClick={this.onRowClick}
    ></PackingConverterList>

    return <Page header={header} content={content} />

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PackingConverterListPageContainer)