import { types } from "./pendence_doc.actions";
import { Reducer } from "../../services/reducer.factory";
import moment from "moment";

const initialState = {
  columns: [
    {
      field: "oid",
      header: "Código",
      columnKey: "oid",
      sortable: true,
      filter: false,
    },
    {
      field: "eventRecord.createdAt",
      header: "Cadastro",
      columnKey: "eventRecord.createdAt",
      sortable: false,
      filter: false,
    },
    {
      field: "document.descricao",
      header: "Descrição Documento",
      columnKey: "document.descricao",
      sortable: false,
      filter: false,
    },
    {
      field: "status",
      header: "Status",
      columnKey: "status",
      sortable: true,
      filter: false,
    },
    {
      field: "type",
      header: "Tipo",
      columnKey: "type",
      sortable: true,
      filter: false,
    },
    {
      field: "btnProcessar",
      header: "Processar",
      columnKey: "btnProcessar"
    },
  ], 
  columnsCarrierTemplate:[
    {
      field: "cnpj",
      header: "CNPJ",
      columnKey: "cnpj"
    },
    {
      field: "name",
      header: "Nome",
      columnKey: "name"
    },
  ],
  initialDate: moment().startOf("month").toDate(),
  finalDate: moment().toDate(),
  expandedRows:[],
  filters:[],
};

const reducer = new Reducer(initialState, types);

export function CRUDPendenceDocReducer(state, action) {
  state = reducer.execute(state, action);
  return execute(state, action);
}

function execute(state, action) {
  let result = state;
  switch (action.type) {
    case types.SET_INITIAL_DATE_FILTER:
      return setInitialDateFilter(state, action);
    case types.SET_FINAL_DATE_FILTER:
      return setFinalDateFilter(state, action);
    case types.SET_EXPANDED_ROW:
      return setExpandedRows(state, action);
    case types.SET_FILTERS:
      return saveFilter(state, action);
    default:
      return result;
  }
}

function setInitialDateFilter(state, action) {
  let initialDate = state.initialDate;
  initialDate = action.initialDateFilter;
  return { ...state, initialDate };
}

function setFinalDateFilter(state, action) {
  let finalDate = state.finalDate;
  finalDate = action.finalDateFilter;
  return { ...state, finalDate };
}

function setExpandedRows(state, action) { 
  let expandedRows = action.expandedRows; 
  return { ...state, expandedRows };
}

function saveFilter(state, action) { 
  let filters = action.filters; 
  return { ...state, filters };
} 
