import React from 'react';
import TowerObservationTypeListPageContainer from './TowerObservationTypeListPageContainer';

export class TowerObservationTypeListPage extends React.Component{
    
    render(){
      return (
        <TowerObservationTypeListPageContainer {...this.props}/>
      )
    }
}

