import { types } from "./teste.actions";
import { Reducer } from '../../services/reducer.factory';

const initialState = {
  filters: {
    carrier: null
  },
  lookupCarrier: {
    visible: false
  },
  toolbarFilters: {
    label: [],//itens
    selectedLabels: [],
    labelList: [], //sugestoes
  },


};

const reducer = new Reducer(initialState, types);

export function CRUDTesteReducer(state, action) {
  state = reducer.execute(state, action);
  return execute(state, action);
}

function execute(state, action) {
  switch (action.type) {
    case types.SET_CARRIER:
      return setCarrier(state, action);
    case types.SET_LOOKUP_CARRIER_VISIBLE:
      return setLookupCarrierVisible(state, action);
    case types.SET_LABEL:
      return setLabel(state, action);
    case types.SET_SELECTED_LABEL:
      return setSelectedLabels(state, action);
    case types.SET_LABEL_LIST:
      return setLabelList(state, action);
    default:
      return state;
  }
}

function setSelectedLabels(state, action) {
  let toolbarFilters = state.toolbarFilters
  toolbarFilters.selectedLabels = { ...toolbarFilters.selectedLabels, ...action.value }
  return { ...state, toolbarFilters }
}

function setLabel(state, action) {
  let toolbarFilters = state.toolbarFilters;
  toolbarFilters.label = action.value;
  return { ...state, toolbarFilters }
}
function setLabelList(state, action) {
  let toolbarFilters = state.toolbarFilters;
  toolbarFilters.labelList = action.labelList;
  return { ...state, toolbarFilters }
}

function setCarrier(state, action) {
  const carrier = action.carrier;
  const filters = { ...state.filters, carrier };
  return { ...state, filters };
}

function setLookupCarrierVisible(state, action) {
  const visible = action.visible;
  const lookupCarrier = { ...state.lookupCarrier, visible };
  return { ...state, lookupCarrier };
}


