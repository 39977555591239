import React from 'react';
import ModuloListPageContainer from './ModuloListPageContainer';

export class ModuloListPage extends React.Component{
    
    render(){
      return (
        <ModuloListPageContainer {...this.props}/>
      )
    }
}

