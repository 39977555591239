import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Column } from "primereact/column"; 
import { actions } from "../../tower_travel.actions"; 
import "./destination.scss";
import { DestinoList } from "./DestinoList";

const mapStateToProps = (state) => {
  const destination= state.crudTowerTravelState.currentRecord.destinations || [];
  return {
    columns: state.crudTowerTravelState.destinationColumns,
    records:destination,
    first: state.crudTowerTravelState.index,
    rows: state.crudTowerTravelState.max,
    totalRecords:destination.length,
    sortField: state.crudTowerTravelState.sortField,
    order: state.crudTowerTravelState.sortOrder, 
    selectedRecords: state.crudTowerTravelState.selectedRecordsDest,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    paginate: (paginationParams) => dispatch(actions.paginate(paginationParams)),
    sortOrder: (sortParams) => dispatch(actions.sortOrder(sortParams)),
    applyFilter: (filterParams) => {
      dispatch(actions.setType(null));
      dispatch(actions.applyFilter(filterParams));
    },
    
    setSelectedRecords: (selectedRecords) => {
      dispatch(actions.setSelectedRowsDestination(selectedRecords));
    }, 
  };
};

class DestinoListPageContainer extends React.Component {
  componentDidMount() {
    this.props.setSelectedRecords([]);
  }

  onSelectedEventsChangeHandler = (e) => {
    this.props.setSelectedRecords(e.value);
  };

  onPage = (e) => {
    this.props.paginate({
      max: e.rows,
      index: e.first,
    });
  };
  onSort = (e) => {
    this.props.sortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField,
    });
  };

  onRemoveEventsSelected = (e) => {
    this.props.onRemoveEventsSelected();
  };

  render() { 

    const cidadeStateTemplate = (rowData, column) => {
      try {
        
        return (
          <div className={"center-itens-row"}>
            {rowData.address.City} - {rowData.address.State}
          </div>
        );
      } catch (error) {
        return <div></div>;
      }
    };
    const latLngTemplate = (rowData, column) => {
      try {
        
        return (
          <div className={"center-itens-row"}>
            {rowData.lat}/{rowData.lng}
          </div>
        );
      } catch (error) {
        return <div></div>;
      }
    };

    let columns = [<Column key="selectorColumn" selectionMode="multiple" style={{ width: "3em" }} />];
    columns = [
      ...columns,
      ...this.props.columns.map((col) => {

        if(col.field =="lat_lng"){
          return <Column {...col} key={col.field} body={latLngTemplate} style={{ width: "13em" }}/>;
        }
        if(col.field =="cidade_state"){
          return <Column {...col} key={col.field} body={cidadeStateTemplate} />;
        }
        return <Column {...col} key={col.field} />;
      }),
    ];

    return ( 
        <DestinoList
          {...this.props}
          onSelectedEventsChange={this.onSelectedEventsChangeHandler}
          onPage={this.onPage}
          onSort={this.onSort}
          columns={columns}
        /> 
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DestinoListPageContainer);
