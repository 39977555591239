import { validate } from './validations';
import { build } from '../../services/crudFactory';
import { Action } from '../../services/actions.factory';
const BASE_PATH = '/lognex/occurrencetype';
const prefix = 'crudOccurrenceType';
const crud = build(validate, BASE_PATH);

const newTypes = {    
    GET_GROUP_TYPE: `${prefix}getGroupType`,
    SET_BOOLEAN_FILTER: `${prefix}setBooleanFilter`,
}

const actions = new Action(prefix, crud);

const types = Object.assign({}, actions.types, newTypes);

actions.getGroupType = () => {
    return (dispatch, getState) => {
        dispatch(actions.fetchRecord());
        actions.getGroupType(dispatch);
        dispatch(actions.hideLoader());
    };
}

actions.setBooleanFilter = (field, value)=>{
    return {
        type:types.SET_BOOLEAN_FILTER,
        field,
        value
    }
}


export { types, actions }