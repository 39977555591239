import React from 'react';

import ImportFilesFormPageContainer from './ImportFilesFormPageContainer';

export class ImportFilesFormPage extends React.Component{

    
    render(){
        return (
           <ImportFilesFormPageContainer {...this.props} />
        )
    }
}
