
import React from 'react';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import 'primeflex/primeflex.scss';
import './AuditDetail.scss'


export const AuditDetail = (props) => {
    const getHeaderNf = () => {
        let descHeader = props.dialogNFes.header
        const auditCurrent = props.auditTbRecords[0]
        
        if(auditCurrent && auditCurrent.parent){
            descHeader +="("+ auditCurrent.parent.qtdNfeImportada +"/"+auditCurrent.parent.qtdNfeCte+")"
        }
        
        return descHeader
    }
    return (
        <div className="audit-detail">
            <div className="p-grid">
                <div className="p-col-12">
                    <h3>Auditoria</h3>
                </div>
                <div className="p-col-12">
                    <div className="p-grid">
                        <div className="p-col-12">
                            <DataTable
                                selectionMode="single"
                                value={props.auditTbRecords}
                                className="lxTableAuditDetail"
                                emptyMessage="Não foram encontrados registros"
                            >
                                {props.auditTbColumns}
                            </DataTable>
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-2">
                            <button
                                className="lognex-btn-highlighted"
                                onClick={props.showNFesHandler} >NFes</button>
                        </div>

                        <div className="p-col-2">
                            <button
                                className="lognex-btn-highlighted"
                                onClick={props.showHistoryHandler}>Histórico</button>
                        </div>
                        <div className="p-col-2">
                            <button
                                className="lognex-btn-highlighted"
                                onClick={props.showLogsHandler}>Logs</button>
                        </div>
                        <div className="p-col-2 lxDesactived" title="Em desenvolvimento">
                            <button
                                className="lognex-btn-highlighted"
                                onClick={props.showQuoteHandler}
                                disabled={true}>Orçamento</button>
                        </div>
                    </div>
                </div>
                <Dialog
                    header={getHeaderNf()}
                    visible={props.dialogNFes.visible}
                    style={{ width: '50vw' }}
                    modal={props.dialogNFes.modal}
                    baseZIndex={1}
                    onHide={props.hideNFesHandler}
                    footer={<div></div>}>
                    <DataTable
                        selectionMode="single"
                        value={props.nfeRecords}
                        resizableColumns={true}
                        scrollable={true}
                        scrollHeight="640px"
                        emptyMessage="Não foram encontrados registros"
                    >
                        {props.nfesTbColumns}
                    </DataTable>

                </Dialog>
                <Dialog
                    header={props.dialogQuote.header}
                    visible={props.dialogQuote.visible}
                    style={{ width: '50vw' }}
                    modal={props.dialogQuote.modal}
                    baseZIndex={1}
                    onHide={props.hideQuoteHandler}
                    footer={<div></div>}>
                </Dialog>
                {/* Historic dialog */}
                <Dialog
                    header={props.dialogHistory.header}
                    visible={props.dialogHistory.visible}
                    style={{ width: '50vw' }}
                    modal={props.dialogHistory.modal}
                    baseZIndex={1}
                    onHide={props.hideHistoryHandler}
                    footer={<div></div>}>
                    <DataTable
                        selectionMode="single"
                        value={props.historyRecords}
                        emptyMessage="Não foram encontrados registros"
                        resizableColumns={true}
                        scrollable={true}
                        scrollHeight="640px"
                    >
                        {props.historyTbColumns}
                    </DataTable>
                </Dialog>
                {/* Historic dialog */}
                <Dialog
                    header={props.dialogLogs.header}
                    visible={props.dialogLogs.visible}
                    style={{ width: '50vw' }}
                    modal={props.dialogLogs.modal}
                    baseZIndex={1}
                    onHide={props.hideLogsHandler}
                    footer={<div></div>}>
                    <DataTable
                        selectionMode="single"
                        value={props.logRecords}
                        emptyMessage="Não foram encontrados registros"
                        resizableColumns={true}
                        scrollable={true}
                        scrollHeight="640px"
                    >
                        {props.logTbColumns}
                    </DataTable>
                </Dialog>

            </div>
        </div>
    )
}