import React from 'react'
import { InputText } from 'primereact/inputtext';
import { LookupField } from '../../../components/lookup/LookupField';

export const PackingConverterForm = (props) => {
    const record = props.record;
    const changeHandler = props.changeHandler;
    return (
        <div className="packaging-form">
            <div className="p-grid">
                <div className="p-col-6">
                    <div className="p-grid">
                    <div className="p-col-3 form-field-container">
                            <label>Código</label>
                            <InputText value={record.oid} onChange={changeHandler} name="oid" disabled={true} />
                        </div>
                        <div className="p-col-9 form-field-container"></div>
                        <div className="p-col-3 form-field-container">
                            <label>Quantidade 1</label>
                            <InputText value={1}  name="packing1.length" disabled={true}/>
                        </div>
                        <div className="p-col-9 form-field-container">
                            <label className="lxRequiredField">Embalagem 1</label>
                            <LookupField
                                value={record.packing1 ? record.packing1 : null}
                                placeholder="Embalagem 1"
                                name="packing1.name"
                                field="name"
                                onComplete={props.lookupPacking1OnComplete}
                                onInputFilter={props.lookupPacking1OnInputFilter}
                                onEditField={props.lookupPacking1OnEdit}
                                editDisabled={props.lookupPacking1.editDisabled}
                                onClick={props.lookupPacking1OnClick}
                                visible={props.lookupPacking1.visible}
                                modal={props.lookupPacking1.modal}
                                header={props.lookupPacking1.header}
                                onHide={props.lookupPacking1OnHide}
                                onConfirm={props.lookupPacking1OnConfirm}
                                onCancel={props.lookupPacking1OnCancel}
                                records={props.lookupPacking1.records}
                                columns={props.lookupPacking1.columns}
                                totalRecords={props.lookupPacking1.totalRecords}
                                sortOrder={props.lookupPacking1.order}
                                sortField={props.lookupPacking1.sortField}
                                first={props.lookupPacking1.first}
                                rows={props.lookupPacking1.rows}
                                onPage={props.lookupPacking1OnPage}
                                onFilter={props.lookupPacking1OnFilter}
                                onSort={props.lookupPacking1OnOnSort} 
                            />
                        </div>
                        <div className="p-col-3 form-field-container">
                            <label className="lxRequiredField">Quantidade 2</label>
                            <InputText type='number' value={props.record.packing2.length} onChange={changeHandler} name="packing2.length" />
                        </div>
                        <div className="p-col-9 form-field-container">
                            <label className="lxRequiredField">Embalagem 2</label>
                            <LookupField
                                value={props.record.packing2 ? props.record.packing2 : null}
                                placeholder="Embalagem 2"
                                name="packing2.name"
                                field="name"
                                records={props.lookupPacking2.records}
                                columns={props.lookupPacking2.columns}
                                totalRecords={props.lookupPacking2.totalRecords}
                                sortOrder={props.lookupPacking2.order}
                                sortField={props.lookupPacking2.sortField}
                                first={props.lookupPacking2.first}
                                rows={props.lookupPacking2.rows}
                                visible={props.lookupPacking2.visible}
                                modal={props.lookupPacking2.modal}
                                header={props.lookupPacking2.header}
                                editDisabled={props.lookupPacking2.editDisabled}
                                onEditField={props.lookupPacking2OnEdit}
                                onClick={props.lookupPacking2OnClick}
                                onHide={props.lookupPacking2OnHide}
                                onConfirm={props.lookupPacking2OnConfirm}
                                onCancel={props.lookupPacking2OnCancel}
                                onComplete={props.lookupPacking2OnComplete}
                                onInputFilter={props.lookupPacking2OnInputFilter}
                                onPage={props.lookupPacking2OnPage}
                                onFilter={props.lookupPacking2OnFilter}
                                onSort={props.lookupPacking2OnOnSort} 
                            />
                        </div>
                        <div className="p-col-4 form-field-container">
                            <label className="lxRequiredField">Código do Produto</label>
                            <InputText value={props.record.productCode} onChange={changeHandler} name="productCode" />
                        </div>
                        <div className="p-col-9 form-field-container"></div>
                    </div>
                </div> 
            </div> 
        </div>          
    )
}