
import { types } from "./freightCalculation.actions";
import { Reducer } from "../../services/reducer.factory";



const initialState = {
  freightCalculationParams: {
    zipCodeOrigin: "",
    zipCodeDestination: "",
    locationOrigin: null,
    locationDestination: null,
    client: null,
    vehicleType: null,
    value: "",
    weight: "",
    volumes: 0,
    quantityOfItems: 0,
    distance: 0,
    cubage: 0
  }, //TODO
  lookupOrigin: {
    visible: false,
    modal: true,
    header: "Origem"
  },
  lookupDestination: {
    visible: false,
    modal: true,
    header: "Destino"
  },
  lookupClient: {
    visible: false,
    modal: true,
    header: "Cliente"
  },
  lookupVehicleType: {
    visible: false,
    modal: false,
    header: "Tipo do veículo"
  },
  tableQuotes: {
    //*records structure{carrier, deliveryTime, freightValue, efficiency}
    records: [],
    columns: [{
      field: "carrierName",
      header: "Transportadora",
      columnKey: "carrierName"
    },
    {
      field: "freightValue",
      header: "Frete",
      columnKey: "freightValue"
    },
    {
      field: "icmsValue",
      header: "ICMS",
      columnKey: "icmsValue"
    },
    {
      field: "totalFreightValue",
      header: "Frete Total",
      columnKey: "totalFreightValue"
    },
    {
      field: "nfePercent",
      header: "% NFe",
      columnKey: "nfePercent"
    },
    {
      field: "carrierDeliveryDeadline",
      header: "Prazo Transportadora (Dias)",
      columnKey: "carrierDeliveryDeadline"
    },
    {
      field: "clientDeliveryDeadline",
      header: "Prazo Cliente (Dias)",
      columnKey: "clientDeliveryDeadline"
    },
    {
      field: "onlyWorkingDays",
      header: "Dias de funcionamento",
      columnKey: "onlyWorkingDays"
    },
    {
      field: "tags",
      header: "Tags",
      columnKey: "tags"
    },
    ],
  },
  locationTypeFilterOptions: [
    { label: "Localização", value: "LOCATION" },
    { label: "CEP", value: "CEP" }
  ],
  currentLocationTypeFilter: 'LOCATION',
  visibleModalCalculateCubage: false,
  listComponentsCalculateCubage: [
    {
      "id": "", 
      "comprimento": 0,
      "altura": 0,
      "largura": 0,
      "quantidade": 0,
    }
  ],
  currentTypeCalculateFilter: "Metros",
  typeCalculateOptions: [
    { label: "Metros", value: "Metros" },
    { label: "Centímetros", value: "Centimetros" }
],
};

const reducer = new Reducer(initialState, types);

export function FreightCalculationReducer(state, action) {
  state = reducer.execute(state, action);
  return execute(state, action);
}

function execute(state, action) {
  let result = state;
  switch (action.type) {
    case types.SET_LOOKUP_ORIGIN_VISIBLE:
      return setLookupOriginVisible(state, action);
    case types.SET_LOOKUP_DESTINATION_VISIBLE:
      return setLookupDestinationVisible(state, action);
    case types.SET_LOOKUP_CLIENT_VISIBLE:
      return setLookupClientVisible(state, action);
    case types.SET_LOOKUP_VEHICLE_TYPE_VISIBLE:
      return setLookupVehicleTypeVisible(state, action);
    case types.LOAD_QUOTES:
      return loadQuotes(state, action);
    case types.SET_LOCATION_ORIGIN:
      return setLocationOrigin(state, action);
    case types.SET_CLIENT:
      return setClient(state, action);
    case types.SET_VEHICLE_TYPE:
      return setVehicleType(state, action);
    case types.SET_LOCATION_DESTINATION:
      return setLocationDestination(state, action);
    case types.SET_VALUE:
      return setValue(state, action);
    case types.SET_WEIGHT:
      return setWeight(state, action);
    case types.SET_VOLUMES:
      return setVolumes(state, action);
    case types.SET_QUANTITY_OF_ITEMS:
      return setQuantityOfItems(state, action);
    case types.SET_ZIP_CODE_ORIGIN:
      return setZipCodeOrigin(state, action);
    case types.SET_ZIP_CODE_DESTINATION:
      return setZipCodeDestination(state, action);
    case types.SET_CUBAGE:
      return setCubage(state, action);
    case types.SET_DISTANCE:
      return setDistance(state, action);
    case types.SET_CURRENT_LOCATION_TYPE_FILTER:
      return setCurrentLocationTypeFilter(state, action);
    case types.CLEAR_QUOTES:
      return clearQuotes(state, action);
    case types.SET_VISIBLE_MODAL_CALCULATE_CUBAGE:
      return setVisibleModalCalculateCubage(state, action);
    case types.ADD_COMPONENT_LIST_CALCULATE_CUBAGE:
      return addComponentListCalculateCubage(state, action);
    case types.REMOVE_COMPONENT_LIST_CALCULATE_CUBAGE:
      return removeComponentListCalculateCubage(state, action);
    case types.SET_VALUES_FIELDS_CALCULATE_FREIGHT:
      return setValuesFieldCalculateFreitgh(state, action);
    case types.CLEAR_FIELDS_CALCULATE_FREIGHT:
      return clearFieldCalculateFreitgh(state, action);
    case types.SET_TYPE_CALCULATE:
      return setTypeCalculate(state, action);
  }
  return result;
}

function setTypeCalculate(state, action){
  let currentTypeCalculateFilter = state.currentTypeCalculateFilter;
  currentTypeCalculateFilter = action.typeCalculate;
  return { ...state, currentTypeCalculateFilter}
}

function clearFieldCalculateFreitgh(state, action){
  const listComponentsCalculateCubage = [
    {
      "id": "", 
      "comprimento": 0,
      "altura": 0,
      "largura": 0,
      "quantidade": 0,
    }
  ];
  const currentTypeCalculateFilter = "Metros";
  return { ...state, currentTypeCalculateFilter, listComponentsCalculateCubage}
}

function setValuesFieldCalculateFreitgh(state, action){
  let listComponentsCalculateCubage = state.listComponentsCalculateCubage;
  const {id, value, fieldName} = action.values;

  for (let i=0; i< listComponentsCalculateCubage.length; i++) {
    if (listComponentsCalculateCubage[i].id == id) {
      
      listComponentsCalculateCubage[i][fieldName] = value;
    }
  }
  return { ...state, listComponentsCalculateCubage}
}



function addComponentListCalculateCubage(state, action) {
  let listComponentsCalculateCubage = state.listComponentsCalculateCubage;
  const id = String(new Date().getTime() + listComponentsCalculateCubage.length)
  const component = {
    "id": id, 
    "comprimento": 0,
    "altura": 0,
    "largura": 0,
    "quantidade": 0,
  }
  listComponentsCalculateCubage.push(component)
  return { ...state, listComponentsCalculateCubage }
}

function removeComponentListCalculateCubage(state, action){
  let listComponentsCalculateCubage = state.listComponentsCalculateCubage;
  listComponentsCalculateCubage = listComponentsCalculateCubage.filter( item => item.id !== action.component.id)
  return { ...state, listComponentsCalculateCubage }
}



function setVisibleModalCalculateCubage(state, action) {
  let visibleModalCalculateCubage = state.visibleModalCalculateCubage;
  visibleModalCalculateCubage = action.visible;
  return { ...state, visibleModalCalculateCubage }

}

function setLookupOriginVisible(state, action) {
  let lookupOrigin = state.lookupOrigin;
  lookupOrigin = Object.assign({}, lookupOrigin, { visible: action.visible });
  return Object.assign({}, state, { lookupOrigin })
}
function setLookupDestinationVisible(state, action) {
  let lookupDestination = state.lookupDestination;
  lookupDestination = Object.assign({}, lookupDestination, { visible: action.visible });
  return Object.assign({}, state, { lookupDestination })
}

function setLookupClientVisible(state, action) {
  let lookupClient = state.lookupClient;
  lookupClient = Object.assign({}, lookupClient, { visible: action.visible });
  return Object.assign({}, state, { lookupClient })
}
function setLookupVehicleTypeVisible(state, action) {
  let lookupVehicleType = { ...state.lookupVehicleType, visible: action.visible };
  return { ...state, lookupVehicleType }
}

function loadQuotes(state, action) {
  let tableQuotes = state.tableQuotes;
  const records = action.quotes || []
  records.sort((rec1, rec2) => {
    if (rec1.totalFreightValue === rec2.totalFreightValue) {
      return 0;
    } else if (rec1.totalFreightValue > rec2.totalFreightValue) {
      return 1;
    } else {
      return -1;
    }
  })
  tableQuotes = Object.assign({}, tableQuotes, { records });

  return Object.assign({}, state, { tableQuotes })
}

function clearQuotes(state, action) {
  let records = [];
  let tableQuotes = { ...state.tableQuotes, records }

  return { ...state, tableQuotes };
}

function setLocationOrigin(state, action) {
  let freightCalculationParams = state.freightCalculationParams;
  freightCalculationParams = Object.assign({}, freightCalculationParams, { locationOrigin: action.locationOrigin });
  return Object.assign({}, state, { freightCalculationParams })
}
function setLocationDestination(state, action) {
  let freightCalculationParams = state.freightCalculationParams;
  freightCalculationParams = Object.assign({}, freightCalculationParams, { locationDestination: action.locationDestination });
  return Object.assign({}, state, { freightCalculationParams })
}
function setClient(state, action) {
  let freightCalculationParams = state.freightCalculationParams;
  freightCalculationParams = Object.assign({}, freightCalculationParams, { client: action.client });
  return Object.assign({}, state, { freightCalculationParams })
}
function setVehicleType(state, action) {
  let freightCalculationParams = state.freightCalculationParams;
  freightCalculationParams = Object.assign({}, freightCalculationParams, { vehicleType: action.vehicleType });
  return Object.assign({}, state, { freightCalculationParams })
}
function setValue(state, action) {
  let freightCalculationParams = state.freightCalculationParams;
  freightCalculationParams = Object.assign({}, freightCalculationParams, { value: action.value });
  return Object.assign({}, state, { freightCalculationParams })
}
function setWeight(state, action) {
  let freightCalculationParams = state.freightCalculationParams;
  freightCalculationParams = Object.assign({}, freightCalculationParams, { weight: action.weight });
  return Object.assign({}, state, { freightCalculationParams })
}
function setVolumes(state, action) {
  let freightCalculationParams = state.freightCalculationParams;
  freightCalculationParams = Object.assign({}, freightCalculationParams, { volumes: action.volumes });
  return Object.assign({}, state, { freightCalculationParams })
}
function setQuantityOfItems(state, action) {
  let freightCalculationParams = state.freightCalculationParams;
  freightCalculationParams = Object.assign({}, freightCalculationParams, { quantityOfItems: action.quantityOfItems });
  return Object.assign({}, state, { freightCalculationParams })
}

function setZipCodeOrigin(state, action) {
  let freightCalculationParams = state.freightCalculationParams;
  freightCalculationParams = Object.assign({}, freightCalculationParams, { zipCodeOrigin: action.zipCodeOrigin });
  return Object.assign({}, state, { freightCalculationParams })
}
function setZipCodeDestination(state, action) {
  let freightCalculationParams = state.freightCalculationParams;
  freightCalculationParams = Object.assign({}, freightCalculationParams, { zipCodeDestination: action.zipCodeDestination });
  return Object.assign({}, state, { freightCalculationParams })
}
function setCubage(state, action) {
  let freightCalculationParams = state.freightCalculationParams;
  freightCalculationParams = Object.assign({}, freightCalculationParams, { cubage: action.cubage });
  return Object.assign({}, state, { freightCalculationParams })
}
function setDistance(state, action) {
  let freightCalculationParams = state.freightCalculationParams;
  freightCalculationParams = Object.assign({}, freightCalculationParams, { distance: action.distance });
  return Object.assign({}, state, { freightCalculationParams })
}

function setCurrentLocationTypeFilter(state, action) {
  return Object.assign({}, state, { currentLocationTypeFilter: action.locationTypeFilter })
}