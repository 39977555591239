import React from 'react';
import { Dropdown } from "primereact/dropdown";
import './InputWithSelect.scss';

const classes = {
    main:"input-select",
    inputContainer:"input-select__input-container",
    select:"input-select__select-container"
}
export const InputWithSelect = (props)=>{
    const select = {...props.select};
    if(!select.onChange){
        select.onChange = ()=>{}
    }
    return (
        <div className={classes.main}>
                <input {...props.input}/>
            <Dropdown 
            {...select}
            />
              
        </div>
    )
}