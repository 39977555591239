import React from "react";
import { connect } from "react-redux";
import { Column } from "primereact/column";
import Toolbar from "../../../components/toolbar/Toolbar";
import { actions } from "../order-delivery.actions";
import { routes } from "../../../constants";
import { Page } from "../../../components/page/Page";
import { OrderDeliveryForm } from "./OrderDeliveryForm";
import { HistoryLogListPage } from "../../history-log-component";
import "./orderDelivery.scss"

const mapStateToProps = (state) => {
  const record = state.crudOrderDeliveryState.currentRecord;

  return {
    record,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadById: (oid) => dispatch(actions.loadById(oid)), 
  };
};

class OrderDeliveryFormPageContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisibleHistorylog: false,
    };
  }

  componentDidMount() {
    const oid = this.props.match.params.id; 
    if (oid && parseInt(oid)) {
      this.props.loadById(oid);
    }
  }

  searchHandler = () => {
    this.props.history.push(`${routes.PATH_ORDER_DELIVERY}`);
  };

  onClickOpenHistoryLog = () => {
    this.setState({
      isVisibleHistorylog: true,
    });
  };
  onHideHistorylog = () => {
    this.setState({
      isVisibleHistorylog: false,
    });
  };


  render() {
    const content = (
      <div>
        <OrderDeliveryForm changeHandler={this.props.changeHandler} record={this.props.record} />
        {this.state.isVisibleHistorylog && this.props.record.oid && (
          <HistoryLogListPage docID={this.props.record.oid} resourceType={"shipment_order"} onHide={this.onHideHistorylog} />
        )}
      </div>
    );
    const title = `Ordem`;
    const header = (
      <div>
        <h1>{title}</h1>
        <div className={"delivery-toolbar"}>
          <Toolbar onSearch={this.searchHandler}   onShowHistoryLog={this.props.record.oid ? this.onClickOpenHistoryLog : null}></Toolbar>
        </div>
      </div>
    );
    return <Page header={header} content={content} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderDeliveryFormPageContainer);
