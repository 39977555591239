
import React from 'react';
import CampanhaAnymarketListPageContainer from './CampanhaAnymarketListPageContainer';

export class CampanhaAnymarketListPage extends React.Component {
  render() {
    return (
      <CampanhaAnymarketListPageContainer {...this.props} />
    )
  }
}