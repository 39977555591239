import { types } from "./transferRoute.actions";
import { Reducer } from "../../services/reducer.factory";

const initialState = {
    columns: [
        {
          field: "businessUnitOrigin.sigla",
          header: "Origem",
          columnKey: "businessUnitOrigin.sigla",
          sortable: true,
          filter: true
        },
        {
          field: "businessUnitDestination.sigla",
          header: "Destino",
          columnKey: "businessUnitDestination.sigla",
          sortable: true,
          filter: true
        },
        {
          field: "distance",
          header: "Distância",
          columnKey: "distance",
          sortable: true,
          filter: true
        }
    ],
    currentRecord: {
        oid: "",
        businessUnitOrigin: {
          sigla:""
        },
        businessUnitDestination: {
          sigla:""
        },
        distance:0,
    },
    lookupOrigin: {
        visible: false,
        modal: true,
        header: 'Origem'
    },
    lookupDestination: {
        visible: false,
        modal: true,
        header: 'Destino'
    },
};

const reducer = new Reducer(initialState, types);

export function CRUDTransferRouteReducer(state, action) {
  state = reducer.execute(state, action);
  return execute(state, action);
}

function execute(state, action) {
  let result = state;
  switch (action.type) {
    case types.SET_ORIGIN_SELECTED:
        return setOriginSelected(state, action); 
    case types.SET_ORIGIN_VISIBLE:
        return setOriginVisible(state, action); 
    case types.SET_DESTINATION_SELECTED:
        return setDestinationSelected(state, action); 
    case types.SET_DESTINATION_VISIBLE:
      return setDestinationVisible(state, action); 
    default:
        return result;
  }
}

function setDestinationVisible(state, action){
  let lookupDestination = state.lookupDestination;
  lookupDestination.visible = action.visible;
  return { ...state, lookupDestination}
}

function setOriginVisible(state, action){
  let lookupOrigin = state.lookupOrigin;
  lookupOrigin.visible = action.visible;
  return { ...state, lookupOrigin}
}

function setOriginSelected(state, action) {
  let currentRecord = state.currentRecord;
  let businessUnitOrigin = currentRecord.businessUnitOrigin;
  businessUnitOrigin = action.origin;
  currentRecord = { ...currentRecord, businessUnitOrigin}
  return { ...state, currentRecord}
}
function setDestinationSelected(state, action) {
  let currentRecord = state.currentRecord;
  let businessUnitDestination = currentRecord.businessUnitDestination;
  businessUnitDestination = action.origin;
  currentRecord = { ...currentRecord, businessUnitDestination}
  return { ...state, currentRecord}
}
