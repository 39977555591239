import React from "react";
import { Calendar } from "primereact/calendar";
import "./Filter.scss";

export const Filter = (props) => {
  //  const currentBusinessUnit = props.filterFields && props.filterFields.currentBusinessUnit ? props.filterFields.currentBusinessUnit : null;
  const canFilter = props.canFilter || true;

  return (
    <div className="p-grid">
      <div className="p-col-12">
        <label>Filtro por data da criação do Shipment</label>
      </div>

      <div className="p-col-2 form-field-container">
        <label className="lxRequiredField">De</label>
        <Calendar
          showIcon={true}
          value={props.filterFields.initialDate}
          showTime={false}
          onChange={props.actions.initialDateChangeHandler}
          disabled={false}
          name="initialDate"
          dateFormat="dd/mm/yy"
        />
      </div>
      <div className="p-col-2 form-field-container">
        <label className="lxRequiredField">Até</label>
        <Calendar
          showIcon={true}
          value={props.filterFields.finalDate}
          showTime={false}
          onChange={props.actions.finalDateChangeHandler}
          disabled={false}
          name="finallDate"
          dateFormat="dd/mm/yy"
        />
      </div>

      <div className="p-col-5 form-field-container" />

      <div className="p-col-1 form-field-container">
        <br />
        <button className="lognex-btn-highlighted" style={{ width: "100%" }} title="Filtrar" onClick={props.actions.applyFilter}>
          Filtrar
        </button>
      </div>

      <div className="p-col-2 form-field-container">
        <br />
        <button className="lognex-btn-highlighted-inverse" style={{ width: "100%" }} title="Download" onClick={props.exportXlsx} >
          Exportar
        </button>
      </div>
    </div>
  );
};
