
import { Column } from 'primereact/column';
import React from 'react';
import { connect } from 'react-redux';
import { Page } from "../../../components/page/Page";
import Toolbar from "../../../components/toolbar/Toolbar";
import { routes } from '../../../constants';
import { actions as carrierActions } from '../../carrier/carrier.actions';
import { actions } from '../../deliveryDeadline/delivery.actions';
import { actions as locationActions } from '../../location/location.actions';
import { DeliveryForm } from "./DeliveryForm";

const mapStateToProps = state => {
    let carriers = state.crudCarrierState.records || [];

    carriers = carriers.map((carrier) => {
        const { name, documentNumber,tradeName } = carrier || {}
        return { ...carrier, name, documentNumber ,tradeName}
    })

    const lookupCarrierBase = {
        records: carriers,
        record: state.crudDeliveryDeadlineState.currentRecord,
        columns: state.crudCarrierState.columns
            .filter(col => col.field === 'oid' || col.field === 'name' || col.field === 'tradeName')
            .map(col => <Column key={col.field} {...col} />),
        first: state.crudCarrierState.index,
        rows: state.crudCarrierState.max,
        totalRecords: state.crudCarrierState.count,
        sortField: state.crudCarrierState.sortField,
        order: state.crudCarrierState.sortOrder,
        rowsPerPageOptions:[20,50,100],
    }
    let lookupCarrier = Object.assign({}, state.crudDeliveryDeadlineState.lookupCarrier, lookupCarrierBase);

    const filterValidLocations = record => (record.type === "CITY")
    const lookupOriginBase = {
        records: state.crudLocationState.records.filter(filterValidLocations),
        record: state.crudDeliveryDeadlineState.currentRecord,
        columns: state.crudLocationState.columns
            .filter(col => col.field === 'oid' || col.field === 'description')
            .map(col => <Column key={col.field} {...col} />),
        first: state.crudLocationState.index,
        rows: state.crudLocationState.max,
        totalRecords: state.crudLocationState.count,
        sortField: state.crudLocationState.sortField,
        order: state.crudLocationState.sortOrder,
        rowsPerPageOptions:[20,50,100],
    }
    let lookupOrigin = Object.assign({}, state.crudDeliveryDeadlineState.lookupOrigin, lookupOriginBase);

    const lookupDestinationBase = {
        records: state.crudLocationState.records.filter(filterValidLocations),
        record: state.crudDeliveryDeadlineState.currentRecord,
        columns: state.crudLocationState.columns
            .filter(col => col.field === 'oid' || col.field === 'description')
            .map(col => <Column key={col.field} {...col} />),
        first: state.crudLocationState.index,
        rows: state.crudLocationState.max,
        totalRecords: state.crudLocationState.count,
        sortField: state.crudLocationState.sortField,
        order: state.crudLocationState.sortOrder,
        rowsPerPageOptions:[20,50,100],
    }
    let lookupDestination = Object.assign({}, state.crudDeliveryDeadlineState.lookupDestination, lookupDestinationBase);

    let currentRecord = state.crudDeliveryDeadlineState.currentRecord;
    const options = state.crudDeliveryDeadlineState.options;
    return (
        {
            lookupCarrier,
            lookupOrigin,
            lookupDestination,
            currentRecord,
            options
        }
    )
}


const mapDispatchToProps = dispatch => {

    return {
        newHandler: event => {
            dispatch(actions.makeNew());
        },

        setField: (field, value) => dispatch(actions.setField(field, value)),

        changeHandler: event => {
            const target = event.target;
            const field = target.name;
            let value = target.value;
            dispatch(actions.removeErrors([{ field }]));
            dispatch(actions.setField(field, value));
        },

        saveHandler: event => {
            dispatch(actions.save());
        },

        removeHandler: event => {
            dispatch(actions.remove());
        },

        newHandler: event => {
            dispatch(actions.makeNew());
        },

        loadById: oid => dispatch(actions.loadById(oid)),

        lookupCarrierSetVisible: visible => {
            dispatch(actions.setLookupCarrierVisible(visible))
        },

        setCarrier: (carrier) => { 
            if (carrier) {
                console.log("set carrier=",carrier)
                const { oid = null, name = null, documentNumber = null,tradeName="" } = carrier || {}
                dispatch(actions.setField('carrier', { oid, name, documentNumber,tradeName,person:{oid, name, documentNumber,tradeName} }));
            } else {
                dispatch(actions.setField('carrier', null));
            }
        },

        lookupCarrierPaginate: paginationParams => dispatch(carrierActions.paginate(paginationParams)),

        lookupCarrierSortOrder: sortParams => dispatch(carrierActions.sortOrder(sortParams)),

        lookupCarrierApplyFilter: filterParams => {
            dispatch(carrierActions.setType(null));
            dispatch(carrierActions.applyFilter(filterParams))
        },

        lookupCarrierLoad: () => dispatch(carrierActions.load()),

        lookupOriginSetVisible: visible => {
            dispatch(actions.setLookupOriginVisible(visible));
        },

        setOrigin: (origin) => {
            if (origin) {
                const { oid, name, description } = origin
                dispatch(actions.setField('origin', { oid, name, description }));
            } else {
                dispatch(actions.setField('origin', null));
            }
        },

        lookupOriginPaginate: paginationParams => dispatch(locationActions.paginate(paginationParams)),

        lookupOriginSortOrder: sortParams => dispatch(locationActions.sortOrder(sortParams)),

        lookupOriginApplyFilter: filterParams => {
            dispatch(locationActions.setType(null));
            dispatch(locationActions.applyFilter(filterParams))
        },

        lookupOriginLoad: () => dispatch(locationActions.load()),

        lookupDestinationSetVisible: visible => {
            dispatch(actions.setLookupDestinationVisible(visible));
        },

        setDestination: (destination) => {
            if (destination) {
                const { oid, name, description } = destination
                dispatch(actions.setField('destination', { oid, name, description }));
            } else {
                dispatch(actions.setField('destination', null))
            }
        },

        lookupDestinationPaginate: paginationParams => dispatch(locationActions.paginate(paginationParams)),

        lookupDestinationSortOrder: sortParams => dispatch(locationActions.sortOrder(sortParams)),

        lookupDestinationApplyFilter: filterParams => {
            dispatch(locationActions.setType(null));
            dispatch(locationActions.applyFilter(filterParams))
        },

        lookupDestinationLoad: () => dispatch(locationActions.load()),

        addTag: () => dispatch(actions.addTagToTagsList()),

        removeTag: (tag) => dispatch(actions.removeTagFromTagsList(tag)),

    }
}


class DeliveryFormPageContainer extends React.Component {
    constructor(props) {
        super(props);
        actions.afterSave = ({ oid }) => {
            this.props.history.replace(`${routes.PATH_DELIVERY_DEADLINE}/${oid}`);
        };
        actions.afterNew = () => {
            //TODO FIX ME
            this.props.setField('deadline', {
                value: '',
                type: 'DAY',
            })
            this.props.setField('delayAfterCollect', {
                value: '',
                type: 'DAY',
            })
            this.props.history.replace(`${routes.PATH_DELIVERY_DEADLINE}/novo`);
        };
        actions.afterRemove = () => {
            this.props.history.replace(`${routes.PATH_DELIVERY_DEADLINE}`);
        };
    }

    componentDidMount() {
        const oid = this.props.match.params.id;
        if (oid && parseInt(oid)) {
            this.props.loadById(oid);
        } else {
            this.props.newHandler();
        }
    }

    searchHandler = () => {
        this.props.history.push(`${routes.PATH_DELIVERY_DEADLINE}`)
    }

    lookupCarrierOnHide = (e) => {
        this.props.lookupCarrierSetVisible(false);
    }

    lookupCarrierOnConfirm = (e) => {
        this.props.setCarrier(e.selection);
        this.props.lookupCarrierSetVisible(false);
    }

    lookupCarrierOnCancel = (e) => {
        this.props.setCarrier(null);
        this.props.lookupCarrierSetVisible(false);
    }

    lookupCarrierOnPage = (e) => {
        this.props.lookupCarrierPaginate({
            max: e.rows,
            index: e.first
        })
    }

    lookupCarrierOnSort = (e) => {
        this.props.lookupCarrierSortOrder({
            sortOrder: e.sortOrder,
            sortField: e.sortField
        })
    }

    lookupCarrierOnFilter = (e) => {
        const filters = [];
        const keys = Object.keys(e.filters) || [];
        keys.forEach(key => {
            filters.push({
                campo: key,
                valor: e.filters[key].value,
                tipoFiltro: 'CONTEM'
            })
        })
        this.props.lookupCarrierApplyFilter(filters)
    }

    lookupCarrierOnClick = (e) => {
        this.props.lookupCarrierSetVisible(true);
        this.props.lookupCarrierLoad();
    }

    lookupCarrierOnEdit = () => {
        if (this.props.record.oidCarrier) {
            this.props.history.push(`${routes.PATH_CARRIER}/${this.props.record.oidCarrier}`);
        }
    }

    lookupCarrierOnComplete = e => { };

    lookupCarrierOnInputFilter = (e) => {
        const name = e.target.name;
        const value = e.value;
        if (typeof value === 'string') {
            const filters = [{
                campo: "name",
                valor: value,
                tipoFiltro: 'CONTEM'
            }];
            this.props.setCarrier({ name: value });
            this.props.lookupCarrierApplyFilter(filters)
        } else {
            this.props.setCarrier(value);
        }
    }

    lookupOriginOnHide = (e) => {
        this.props.lookupOriginSetVisible(false);
    }

    lookupOriginOnConfirm = (e) => {
        this.props.setOrigin(e.selection);
        this.props.lookupOriginSetVisible(false);

    }

    lookupOriginOnCancel = (e) => {
        this.props.setOrigin(null);
        this.props.lookupOriginSetVisible(false);
    }

    lookupOriginOnPage = (e) => {
        this.props.lookupOriginPaginate({
            max: e.rows,
            index: e.first
        })
    }

    lookupOriginOnSort = (e) => {
        this.props.lookupOriginSortOrder({
            sortOrder: e.sortOrder,
            sortField: e.sortField
        })
    }

    lookupOriginOnFilter = (e) => {
        const filters = [];
        const keys = Object.keys(e.filters) || [];
        keys.forEach(key => {
            filters.push({
                campo: key,
                valor: e.filters[key].value,
                tipoFiltro: 'CONTEM'
            })
        })
        this.props.lookupOriginApplyFilter(filters)
    }

    lookupOriginOnClick = (e) => {
        this.props.lookupOriginLoad();
        this.props.lookupOriginSetVisible(true);
    }

    lookupOriginOnEdit = () => {
        if (this.props.record.oidPessoa) {
            this.props.history.push(`${routes.PATH_ORIGIN}/${this.props.record.oidOrigin}`);
        }
    }

    lookupOriginOnComplete = e => { };

    lookupOriginOnInputFilter = (e) => {
        const value = e.value;
        if (typeof value === 'string') {
            const filters = [{
                campo: "name",
                valor: value,
                tipoFiltro: 'CONTEM'
            }];
            this.props.setField("origin.description", value);
            this.props.lookupOriginApplyFilter(filters)
        } else {
            this.props.setOrigin(value);
            this.props.lookupOriginApplyFilter([])
        }
    }

    lookupDestinationOnHide = (e) => {
        this.props.lookupDestinationSetVisible(false);
    }

    lookupDestinationOnConfirm = (e) => {
        this.props.setDestination(e.selection);
        this.props.lookupDestinationSetVisible(false);

    }

    lookupDestinationOnCancel = (e) => {
        this.props.setDestination(null);
        this.props.lookupDestinationSetVisible(false);
    }

    lookupDestinationOnPage = (e) => {
        this.props.lookupDestinationPaginate({
            max: e.rows,
            index: e.first
        })
    }

    lookupDestinationOnSort = (e) => {
        this.props.lookupDestinationSortOrder({
            sortOrder: e.sortOrder,
            sortField: e.sortField
        })
    }

    lookupDestinationOnFilter = (e) => {
        const filters = [];
        const keys = Object.keys(e.filters) || [];
        keys.forEach(key => {
            filters.push({
                campo: key,
                valor: e.filters[key].value,
                tipoFiltro: 'CONTEM'
            })
        })
        this.props.lookupDestinationApplyFilter(filters)
    }

    lookupDestinationOnClick = (e) => {
        this.props.lookupDestinationLoad();
        this.props.lookupDestinationSetVisible(true);
    }

    lookupDestinationOnEdit = () => {
        if (this.props.record.oidPessoa) {
            this.props.history.push(`${routes.PATH_DESTINATION}/${this.props.record.oidDestination}`);
        }
    }

    lookupDestinationOnComplete = e => { };

    lookupDestinationOnInputFilter = (e) => {
        const value = e.value;
        if (typeof value === 'string') {
            const filters = [{
                campo: "name",
                valor: value,
                tipoFiltro: 'CONTEM'
            }];
            this.props.setField("destination.description", value);
            this.props.lookupDestinationApplyFilter(filters)
        } else {
            this.props.setDestination(value);
            this.props.lookupDestinationApplyFilter([])
        }
    }

    buttonAddTagOnClick = () => {
        this.props.addTag()
    }

    removeTagOnClick = (e) => {
        this.props.removeTag(e.value)
    }

    onKeyPress = (e) => {
        if (e.charCode === 13) {
            this.props.addTag()
        }
    }

    render() {
        const record = this.props.currentRecord
        const carrierParams = {
            lookupCarrier: this.props.lookupCarrier,
            lookupCarrierOnHide: this.lookupCarrierOnHide,
            lookupCarrierOnConfirm: this.lookupCarrierOnConfirm,
            lookupCarrierOnCancel: this.lookupCarrierOnCancel,
            lookupCarrierOnPage: this.lookupCarrierOnPage,
            lookupCarrierOnSort: this.lookupCarrierOnSort,
            lookupCarrierOnFilter: this.lookupCarrierOnFilter,
            lookupCarrierOnClick: this.lookupCarrierOnClick,
            lookupCarrierOnEdit: this.lookupCarrierOnEdit,
            lookupCarrierOnComplete: this.lookupCarrierOnComplete,
            lookupCarrierOnInputFilter: this.lookupCarrierOnInputFilter
        }

        const originParams = {
            lookupOrigin: this.props.lookupOrigin,
            lookupOriginOnHide: this.lookupOriginOnHide,
            lookupOriginOnConfirm: this.lookupOriginOnConfirm,
            lookupOriginOnCancel: this.lookupOriginOnCancel,
            lookupOriginOnPage: this.lookupOriginOnPage,
            lookupOriginOnSort: this.lookupOriginOnSort,
            lookupOriginOnFilter: this.lookupOriginOnFilter,
            lookupOriginOnClick: this.lookupOriginOnClick,
            lookupOriginOnEdit: this.lookupOriginOnEdit,
            lookupOriginOnComplete: this.lookupOriginOnComplete,
            lookupOriginOnInputFilter: this.lookupOriginOnInputFilter
        }

        const destinationParams = {
            lookupDestination: this.props.lookupDestination,
            lookupDestinationOnHide: this.lookupDestinationOnHide,
            lookupDestinationOnConfirm: this.lookupDestinationOnConfirm,
            lookupDestinationOnCancel: this.lookupDestinationOnCancel,
            lookupDestinationOnPage: this.lookupDestinationOnPage,
            lookupDestinationOnSort: this.lookupDestinationOnSort,
            lookupDestinationOnFilter: this.lookupDestinationOnFilter,
            lookupDestinationOnClick: this.lookupDestinationOnClick,
            lookupDestinationOnEdit: this.lookupDestinationOnEdit,
            lookupDestinationOnComplete: this.lookupDestinationOnComplete,
            lookupDestinationOnInputFilter: this.lookupDestinationOnInputFilter
        }

        const tagsParams = {
            buttonAddTagOnClick: this.buttonAddTagOnClick,
            removeTagOnClick: this.removeTagOnClick,
            onKeyPress: this.onKeyPress,
        }

        const deliveryFormParams = {
            ...carrierParams,
            ...originParams,
            ...destinationParams,
            ...tagsParams,
            options: this.props.options,
            record
        }

        const title = `Cadastro de prazo`
        const header = (
            <div>
                <h1>{title}</h1>
                <hr className="lxSplitter" ></hr>
                <div>
                    <Toolbar
                        onSave={this.props.saveHandler} saveButtonStyle='highlighted'
                        onRemove={this.props.removeHandler} removeButtonStyle='danger'
                        onNew={this.props.newHandler}
                        onSearch={this.searchHandler}>
                    </Toolbar>
                </div>
            </div>
        );
        const content = (
            <DeliveryForm {...deliveryFormParams} changeHandler={this.props.changeHandler} />
        )

        return (<Page header={header} content={content} />)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryFormPageContainer);



