import React from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { ButtonDialog } from "../../../components/button-dialog/ButtonDialog";
import { Dropdown } from "primereact/dropdown";

export const ModalFields = (props) => {
  let title = props.header;

  const footer = (
    <div>
      <ButtonDialog
        onClick={props.onConfirm}
        button={{
          title: "Confirmar",
          className: "lognex-btn-highlighted",
          iconClassName: "pi pi-check",
        }}
        dialog={{
          header: "Confirmação",
          modal: false,
          message: "Confirmar a atualização?",
        }}
      ></ButtonDialog>
      <button
        title="Cancelar"
        className="lognex-btn-danger"
        onClick={props.onHide}
      >
        <i className="pi pi-times"></i>
      </button>
    </div>
  );
  const vehicleTypes = props.vehiclesTypeRecords.map((item) => {
    return { name: item.name };
  });
  const cubage = parseFloat(props.record.cubage.value);
  return (
    <div className="trackinFilter-form">
      <Dialog
        header={title}
        onHide={props.onHide}
        visible={props.visible}
        footer={footer}
      >
        <div className="p-grid">
          <div className="p-col-6 form-field-container">
            <label>Cubagem</label>
            <InputText
              value={cubage}
              onChange={props.onCubageChangeHandler}
              name="cubage"
              type="number"
            />
          </div>

          <div className="p-col-6 form-field-container">
            <label>Tipo do Veículo</label>
            <Dropdown
              placeholder="Selecione o Tipo do Veículo"
              name="vehicleType"
              optionLabel="name"
              options={vehicleTypes}
              value={props.record.vehicleType}
              onChange={props.onVehicleTypeChangeHandler}
            ></Dropdown>
          </div>
        </div>
      </Dialog>
    </div>
  );
};
