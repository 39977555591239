import { Action } from '../../services/actions.factory';
import {build} from '../../services/crudFactory';
const BASE_PATH = '/lognex/logs';
const prefix = 'logNFe';
const crud = build(null,BASE_PATH);
const actions = new Action(prefix,crud);

const newTypes = { 
    SET_DATE_FIELD_FORM: `${prefix}setDateFieldForm`,
    SET_FIELD_ORDER_FILTER_FORM: `${prefix}setFieldOrderFilterForm`,
    SET_CURRENT_STATUS_FILTER: `${prefix}setCurrentStatusFilter`,
}

const types = Object.assign({}, actions.types, newTypes);

actions.setCurrentStatusFilter = (currentStatusFilter) => {
    return {
        type: types.SET_CURRENT_STATUS_FILTER,
        currentStatusFilter
    }
}


actions.setRecord = (record) => {
    return {
        type: types.SET_RECORD,
        record
    }
}


actions.setDateFieldForm = (name, value) => {
    return {
        type: types.SET_DATE_FIELD_FORM,
        name,
        value
    }
}

actions.setFieldOrderFilterForm = (value) => {
    return {
        type: types.SET_FIELD_ORDER_FILTER_FORM,
        value
    }
}

export { types, actions }