import React from "react";
import { InputText } from "primereact/inputtext";

export default function FieldsCalculateCubage(props) {

    const onFieldChangeHandler = (e, fieldName) => {
        const id = props.id
        try {
            if(isNaN(e.target.value)){
                props.onChangeHaldler({id, value:0, fieldName});
            } else{
                const value = parseFloat(e.target.value);
                props.onChangeHaldler({id, value, fieldName});
            }
        } catch (error) {
            props.onChangeHaldler({id, value:0, fieldName});
            console.log("Erro Converter Number Float");
        }
    }

    return (
        <div className="fields-modal-calculate-cubage">
            <div className="p-col-2  form-field-container">
                <label>Largura</label>
                <InputText
                    name="largura"
                    value={props.largura}
                    type="number"
                    onChange={(e) => onFieldChangeHandler(e, "largura")}
                />
            </div>
            <div className="p-col-2  form-field-container">
                <label>Altura</label>
                <InputText
                    name="altura"
                    value={props.altura}
                    type="number"
                    onChange={(e) => onFieldChangeHandler(e, "altura")}
                />
            </div>
            <div className="p-col-2  form-field-container">
                <label>Comprimento</label>
                <InputText
                    name="comprimento"
                    value={props.comprimento}
                    type="number"
                    onChange={(e) => onFieldChangeHandler(e, "comprimento")}
                />
            </div>
            <div className="p-col-2 form-field-container ">
                <label>Quantidade</label>
                <InputText
                    name="quantidade"
                    value={props.quantidade}
                    type="number"
                    onChange={(e) => onFieldChangeHandler(e, "quantidade")}
                />
            </div>
            <div className="p-col-2 form-field-container">
                <div className="btn-container">
                    <div className="btn-fields">
                        <button
                            className="lognex-btn-highlighted btn-fields"
                            onClick={props.onAddComponentChageHandler}
                            title={"duplicar campos"}
                        >
                            <i className="pi pi-plus"></i>
                        </button>
                    </div>
                    {props.id !== "" && (
                        <div className="btn-fields">
                            <button
                                className="lognex-btn-highlighted btn-fields"
                                onClick={() => props.removeComponentChangeHandler(props)}
                                title={"remover campos"}
                            >
                                <i className="pi pi-trash"></i>
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )

}