import React from "react";
import { connect } from "react-redux";
import { Column } from "primereact/column";
import { OrderRouteList } from "./OrderRouteList";

const mapStateToProps = (state) => {
  const record = state.crudOrderDeliveryState.currentRecord;
  let segments = [];
  if (record && record.shipment && record.shipment.route) {
    segments = record.shipment.route.segments;
  }

  return {
    columns: state.crudOrderDeliveryState.columnsRouteOrder,
    records: segments,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

class OrderRouteListPageContainer extends React.Component {
  render() {
    const numberTemplate = (rowData, column) => {
      const distancia = rowData[column.field];
      return <div style={{ textAlign: "center", color: "black" }}>{distancia}</div>;
    };

    const columns = this.props.columns.map((col) => {
      if (col.field === "distance") {
        return <Column {...col} key={col.field} body={numberTemplate} />;
      }

      return <Column {...col} key={col.field} />;
    });

    return <OrderRouteList {...this.props} columns={columns} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderRouteListPageContainer);
