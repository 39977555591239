
import { types } from "./product.actions";
import { Reducer } from "../../services/reducer.factory";

const initialState = {
  columns: [{
    field: "oid",
    header: "ID",
    columnKey: "oid",
    sortable: true,
    filter: true
  }, {
    field: "code",
    header: "Código",
    columnKey: "code",
    sortable: true,
    filter: true
  }, {
    field: "name",
    header: "Nome",
    columnKey: "name",
    sortable: true,
    filter: true
  }, {
    field: "height",
    header: "Altura",
    columnKey: "height",
    sortable: true,
    filter: true
  }, {
    field: "width",
    header: "Largura",
    columnKey: "width",
    sortable: true,
    filter: true
  }, {
    field: "depth",
    header: "Profundidade",
    columnKey: "depth",
    sortable: true,
    filter: true
  }, {
    field: "weight",
    header: "Peso",
    columnKey: "weight",
    sortable: true,
    filter: true
  }, {
    field: "productParentName",
    header: "Produto pai",
    columnKey: "productParentName",
    sortable: true,
    filter: true
  }],
  currentRecord: { oid: "", code: "", name: "", height: "0", width: "0", depth: "0", weight: "0", oidProductParent: null, productParentName: null },
  lookupProductParent: {
    visible: false,
    modal: true,
    header: "ProductParents"
  }
};

const reducer = new Reducer(initialState, types);

export function CRUDProductReducer(state, action) {
  state = reducer.execute(state, action);
  return execute(state, action);
}

function execute(state, action) {
  let result = state;
  switch (action.type) {
    case types.SET_LOOKUP_PRODUCTPARENT_VISIBLE:
      return setLookupProductParentVisible(state, action);
    default:
      return result;  
  }
}


function setLookupProductParentVisible(state, action) {
  let lookupProductParent = state.lookupProductParent;
  lookupProductParent = Object.assign({}, lookupProductParent, { visible: action.visible });
  return Object.assign({}, state, { lookupProductParent })
}

