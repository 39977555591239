import { validate } from './validations';
import { build } from '../../services/crudFactory';
import { Action } from '../../services/actions.factory';
import api from '../../services/api.service';

const BASE_PATH = '/lognex/cargo-shipment/reportKm';
///api/v1/lognex/cargo-shipment
const prefix = 'crudCargoShipmentReportKm';
const crud = build(validate, BASE_PATH);

const newTypes = {
  SET_INITIAL_DATE: `${prefix}initialDateChangeHandler`,
  SET_FINAL_DATE: `${prefix}finalDateChangeHandler`,
  EXPORT_XLSX: `${prefix}downloadExportXlsx`,
}

const actions = new Action(prefix, crud);

const types = Object.assign({}, actions.types, newTypes);

// /

actions.downloadExportXlsx = (filters) => {
  return (dispatch) => {
    dispatch(actions.showLoader());
    api
      .post("/api/v1/lognex/cargo-shipment/reportKm/exportXlsx", { filters })
      .then((response) => {
        dispatch(actions.hideLoader());

        const data = response.data;
        if (data.erro) {
          const messages = [data.erro];
          dispatch(actions.messageActions.messageShowMessages(actions.makeMessages(messages, "error")));
        } else {
          dispatch(actions.load());
          const anchor = document.createElement("a");
          anchor.href = data.presigned_url;
          anchor.target = "_blank";
          document.body.append(anchor);
          anchor.click();
          anchor.remove();
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(actions.hideLoader());
        const messages = ["Erro ao exportar arquivo."];
        dispatch(actions.messageActions.messageShowMessages(actions.makeMessages(messages, "error")));
      });
  };
};

actions.finalDateChangeHandler = (finalDate) => {
  return {
    type: types.SET_FINAL_DATE,
    finalDate
  }
}
actions.initialDateChangeHandler = (initialDate) => {
  return {
    type: types.SET_INITIAL_DATE,
    initialDate
  }
}


export { types, actions }
