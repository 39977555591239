import React from "react";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import Toolbar from "../../../components/toolbar/Toolbar";
import { Card } from 'primereact/card';
import { Dialog } from 'primereact/dialog';
import { Column } from 'primereact/column';
import { routes } from '../../../constants';
import { Page } from "../../../components/page/Page";
import { ClientList } from "./ClientList";
import { actions } from '../client.actions';
import { AppService, COMPANY_CHANGE_EVENT_NAME, BUSINESS_UNIT_CHANGE_EVENT_NAME } from "../../../services/app.service";


const DEFAULT_LOG_RESULT = {
  created:{
    total:0
  },
  ignored:{
    total:0
  },
  error:{
    total:0,
    messages:[]
  }
}

const mapStateToProps = state => {
  return {
    columns: state.crudClientState.columns,
    records: state.crudClientState.records,
    first: state.crudClientState.index,
    rows: state.crudClientState.max,
    totalRecords: state.crudClientState.count,
    sortField: state.crudClientState.sortField,
    order: state.crudClientState.sortOrder,
    showImportMessages: state.crudClientState.logImportResult != null,
    log: {
      totalRecords: 0,
      result:state.crudClientState.logImportResult  || DEFAULT_LOG_RESULT
      ,
    }
  }
}
const mapDispatchToProps = dispatch => {
  return {
    paginate: paginationParams => dispatch(actions.paginate(paginationParams)),
    sortOrder: sortParams => dispatch(actions.sortOrder(sortParams)),
    applyFilter: filterParams => {
      dispatch(actions.setType(null));
      dispatch(actions.applyFilter(filterParams))
    },
    load: () => dispatch(actions.load()),
    sendXLSX: (files) => dispatch(actions.importXLSX(files)),
    hideDialog:()=> dispatch(actions.setLogImportXLSXResult(null))
  }
}


class ClientListPageContainer extends React.Component {

  newHandler = () => {
    this.props.history.push(`${routes.PATH_CLIENT}/novo`)
  }


  componentDidMount() {
    this.props.load();
    AppService.on(COMPANY_CHANGE_EVENT_NAME, this);
    AppService.on(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
  }
  componentWillUnmount() {
    AppService.off(COMPANY_CHANGE_EVENT_NAME, this);
    AppService.off(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
  }

  onReceiveAppNotifications = (eventName, params) => {
    if (eventName === COMPANY_CHANGE_EVENT_NAME || eventName === BUSINESS_UNIT_CHANGE_EVENT_NAME) {
      this.props.load();
    }
  }


  onPage = (e) => {
    this.props.paginate({
      max: e.rows,
      index: e.first
    })
  }
  onSort = (e) => {
    this.props.sortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField
    })


  }
  onFilter = (e) => {
    const filters = [];
    const keys = Object.keys(e.filters) || [];
    keys.forEach(key => {
      filters.push({
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: 'CONTEM'

      })
    })
    this.props.applyFilter(filters)

  }

  importXLSX = (event) => {
    this.props.sendXLSX(event.target.files)
  }
  errorsDialogOnHide = ()=>{
    this.props.hideDialog()
  }

  render() {

    const filePath = '/resources/templates/template_clientes.xlsx'
    
    const header = (
      <div>
        <h1>Clientes</h1>
        <div>
          <Toolbar onNew={this.newHandler} newButtonStyle='highlighted'
            onImportXLSX={this.importXLSX}
            XLSXTemplatePath={filePath}>
          </Toolbar >
        </div>
       
      </div>
    );

    const applyMask = (mask, value)=>{
      let index = 0;
      const data = mask.split('').map(it => {
        if (it === '#') {
          return value[index++]
        } else {
          return it
        }
      })
      let formated = data.join('');
      return formated
    }

    const formatCNPJ = (cnpj) => {
      cnpj = cnpj || ""
      const rawValue = cnpj;
      cnpj = cnpj.replace(/\D/g,'')
      
      if(cnpj.length === 14){
        return applyMask('##.###.###/####-##',cnpj);
      }else if(cnpj.length == 11){
        return applyMask('###.###.###-##',cnpj)
      }else{
        return rawValue
      }
      
    }

    const cnpjTemplate = (rowData, column) => {
      return (
        <span style={{ display: "inline-block", width: "100%", textAlign: "center" }}>{formatCNPJ(rowData.cnpj)}</span>
      )
    }

    const formatPhone = (phone) => {
      if (!phone || phone.lenth < 14) {
        return phone;
      }
      const mask = "(##)#####-####"
      let index = 0;
      const data = mask.split('').map(it => {
        if (it === '#') {
          return phone[index++]
        } else {
          return it
        }
      })
      let formated = data.join('');
      return formated
    }

    const phoneTemplate = (rowData, column) => {
      return (
        <span style={{ display: "inline-block", width: "100%", textAlign: "center" }}>{formatPhone(rowData.phone)}</span>
      )
    }

    const oidTemplate = (rowData, column) => {
      return (
        <div className="id-link"><Link to={`${routes.PATH_CLIENT}/${rowData.oid}`}>{rowData.oid}</Link></div>
      )
    }

    const columns = this.props.columns.map(col => {

      if (col.field === 'cnpj') {
        return (<Column {...col} key={col.field} body={cnpjTemplate} />);
      }

      if (col.field === 'phone') {
        return (<Column {...col} key={col.field} body={phoneTemplate} />);
      }

      if (col.field === 'oid') {
        return (<Column {...col} key={col.field} body={oidTemplate} />);
      }

      return (<Column {...col} key={col.field} />)
    })

    const footer = (
      <div>
          <button className="lognex-btn-highlighted" onClick={this.errorsDialogOnHide}>
              <i className="pi pi-check"></i>
          </button>
      </div>
  );
  
    const errorItems = this.props.log.result.error.messages.map((error, index) => {
      return <li key={index}>{error}</li>
    })
    const errors = this.props.log.result.error.messages && this.props.log.result.error.messages.length > 0 ? (
      <div className="p-grid import_result__errors">
        <div className="p-col-6">
          <h3>Erros</h3>
        </div>
        <div className="p-col-6 button-clipboard-div">
          <div style={{ padding: "0.5em" }}>
            <button title="Copiar erros" className="button-clipboard" onClick={this.copyErrorsToClipBoard}>
              <i className="pi pi-copy"></i>
            </button>
          </div>
        </div>

        <div className="p-col-12">
          {<ul className="error-list">{errorItems}</ul>}

        </div>
      </div >
    ) : null;
    const content = (
      <div>
        <div className="p-col-12 form-field-container">
          <Dialog footer={footer}
            header="Log de importação"
            visible={this.props.showImportMessages }
            onHide={this.errorsDialogOnHide} style={{ width: '50vw' }}>
            <div className="import-result">
              <h3 className="import-result__title">Resultado</h3>
              <br />
              <div className="p-grid import-result__dashboard">
                <div className="p-col-3">
                  <Card className="cardText-total" title={this.props.log.totalRecords ? this.props.log.totalRecords.toString() : "0"}>
                    Total Linhas
                      </Card>
                </div>
                <div className="p-col-3">
                  <Card className="cardText-total" title={this.props.log.result.created.total ? this.props.log.result.created.total.toString() : "0"}>
                    Sucesso
                      </Card>
                </div>
                <div className="p-col-3">
                  <Card className="cardText-success" title={this.props.log.result.ignored.total ? this.props.log.result.ignored.total.toString() : "0"}>
                    Já cadastrados 
                      </Card>
                </div>
                <div className="p-col-3">
                  <Card className="cardText-failed" title={this.props.log.result.error.total ? this.props.log.result.error.total.toString() : "0"}>
                    Erros
                      </Card>
                </div>
              </div>
              <br />
              <hr />
              <br />
              {errors}
            </div>
          </Dialog>

        </div>
        <ClientList  {...this.props} onFilter={this.onFilter} onPage={this.onPage} onSort={this.onSort} columns={columns} />
      </div>);
    return <Page header={header} content={content} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientListPageContainer);
