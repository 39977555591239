
    import React from 'react';
    import CfopListPageContainer from './CfopListPageContainer';
    
    export class CfopListPage extends React.Component{
        
        render(){
          return (
            <CfopListPageContainer {...this.props}/>
          )
        }
    }