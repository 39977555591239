import React from 'react';
import  './Tag.scss';

const classes = {
    tag:"tag",
    tagContainer:"tag__container",
    body:"tag__body",
    remove:"tag__remove"
}
export const Tag = (props)=>{
    return (
        <div className={classes.tag}>
            <div className={classes.tagContainer}>
            <span className={classes.body}>
                {props.name}
            </span>
            <span className={classes.remove} onClick={()=>props.onRemove({value:props.name})}>
                <i className="pi pi-times"> </i>
            </span>
            </div>

        </div>
    )
}