import React from 'react';
import { DataTable } from 'primereact/datatable';
import { LxButtonActions } from '../../../components/lx/button-actions/LxButtonActions'


export const LxPanelRoutes = (props) => {
        const datatable = {
            selection: props.dataTable && props.dataTable.selectedRoutes ? props.dataTable.selectedRoutes : [],
            value:props.dataTable && props.dataTable.routesRecords ? props.dataTable.routesRecords : [],
            sortOrder:props.dataTable && props.routesOrder ? props.routesOrder : 0,
            sortField:props.dataTable && props.routesSortField ? props.routesSortField : "",
            paginator:true,
            selectionMode:"multiple",
            rows:props.dataTable && props.dataTable.rows ? props.dataTable.rows  : 0,
            onSelectionChange:props.dataTable && props.dataTable.actions && props.dataTable.actions.onSelectionChangeRoutes ? props.dataTable.actions.onSelectionChangeRoutes : () => {console.log("datatable.routesOnSelectionChange must be implemented")},
            onFilter:props.dataTable && props.dataTable.onFilterRoutes && props.dataTable.actions.onFilterRoutes ? props.dataTable.actions.onFilterRoutes : () => {console.log("datatable.onFilterRoutes must be implemented")},
            onSort:props.dataTable && props.dataTable.onSortRoutes && props.dataTable.actions.onSortRoutes ? props.dataTable.actions.onSortRoutes: () => {console.log("datatable.onSortRoutes must be implemented")},
            emptyMessage:"Não foram encontrados registros",
            rowsPerPageOptions:[5, 10, 20, 50]

        }
    return (
        <div className="p-grid lxPanelRoutes">
            <div className="p-col-12">
                    <LxButtonActions
                        hint={props.toolbar && props.toolbar.hint ? props.toolbar.hint : ""}
                        disabled={props.toolbar && props.toolbar.disabled ? props.toolbar.disabled : ""}
                        onAdd={props.toolbar && props.toolbar.onAdd ? props.toolbar.onAdd : () => {console.log("toolbar.onAdd must be implemented")}}
                        onRemove={props.toolbar && props.toolbar.onRemove ? props.toolbar.onRemove : () => {console.log("toolbar.onRemove must be implemented")}}
                        onDuplicate={props.toolbar && props.toolbar.onDuplicate ? props.toolbar.onDuplicate : () => {console.log("toolbar.onDuplicate must be implemented")}}
                        removeMessage={`Deseja realmente remover a rota ${datatable.selection && datatable.selection.length > 0 ? datatable.selection[0].name : ''}?`}
                        header={props.toolbar && props.toolbar.header ? props.toolbar.header : ""}
                    />
                    <DataTable {...datatable}>
                        {props.dataTable.routeColumns }
                    </DataTable>
            </div>
        </div>

    )
}