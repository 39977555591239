import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

export const TableRowTreeviewWithPendences = (props) => (
  <DataTable
    selectionMode="single"
    value={props.records}
    emptyMessage="Não foram encontrados registros"  
  >
    {props.columns}
  </DataTable>
);
