import React from 'react';
import TowerTravelVisualizeMapListPageContainer from './TowerTravelVisualizeMapListPageContainer';


export class TowerTravelVisualizeMapListPage extends React.Component{
    
    render(){
      return (
        <TowerTravelVisualizeMapListPageContainer {...this.props}/>
      )
    }
}

