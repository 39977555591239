import React from "react";
import { connect } from "react-redux";
import Toolbar from "../../../components/toolbar/Toolbar";
import { Column } from 'primereact/column';
import { routes } from '../../../constants';
import { Page } from "../../../components/page/Page";
import { FreightComponentList } from "./FreightComponentList";
import { actions } from '../freightComponent.actions'
import { AppService, COMPANY_CHANGE_EVENT_NAME, BUSINESS_UNIT_CHANGE_EVENT_NAME } from "../../../services/app.service";
import { actions as appActions } from '../../../store/app';
import * as menuActions from '../../../store/menu/menu.actions';
import * as utils from '../../../utils'
import { Dropdown } from "primereact/dropdown";


const mapStateToProps = state => {
  const currentMenu = state.menuState.currentRecord;
  const userConfigurations = state.appState.userConfigurations;
  let isFavorite = utils.isFavorite(currentMenu, userConfigurations);
  return {
    columns: state.crudFreightComponentState.columns,
    records: state.crudFreightComponentState.records,
    first: state.crudFreightComponentState.index,
    rows: state.crudFreightComponentState.max,
    totalRecords: state.crudFreightComponentState.count,
    sortField: state.crudFreightComponentState.sortField,
    order: state.crudFreightComponentState.sortOrder,
    menuItems: state.menuState.items,
    currentMenu,
    isFavorite,
    groupTypeFilter: state.crudFreightComponentState.groupTypeFilter,
    groupTypeOptions:[
      { label: "TODOS", value: '' },
      { label: "SISTEMA", value: "SYSTEM" },
      { label: "EMPRESA", value: "COMPANY" },
    ],
  }
}
const mapDispatchToProps = dispatch => {
  return {
    paginate: paginationParams => dispatch(actions.paginate(paginationParams)),
    sortOrder: sortParams => dispatch(actions.sortOrder(sortParams)),
    applyFilter: filterParams => {
      dispatch(actions.setType(null));
      dispatch(actions.applyFilter(filterParams))
    },
    load: () => dispatch(actions.load()),
    userLastAccess: (oidMenu) => dispatch(appActions.userLastAccess(oidMenu)),
    setCurrentMenu: (menuPath) => dispatch(menuActions.setCurrentRecord(menuPath)),
    toggleFavorite: (isFavorite, oidMenu) => {
      dispatch(appActions.toggleFavorite(isFavorite, oidMenu))
    },
    setGroupTypeField: (field, value) => dispatch(actions.setGroupType(field, value)),
  }
}


class FreightComponentListPageContainer extends React.Component {

  newHandler = () => {
    this.props.history.push(`${routes.PATH_FREIGHT_COMPONENT}/novo`)
  }

  componentWillReceiveProps(newProps) {
    if ((!this.props.menuItems || this.props.menuItems.length === 0) && newProps.menuItems && newProps.menuItems.length > 0) {
      const menuPath = this.props.history.location.pathname;
      this.props.setCurrentMenu(menuPath)
    }

    if ((!this.props.currentMenu && newProps.currentMenu) ||
      (this.props.currentMenu !== newProps.currentMenu)) {
      const oidMenu = newProps.currentMenu.oid;
      this.props.userLastAccess(oidMenu)
    }
  }

  componentDidMount() {
    this.props.load();
    AppService.on(COMPANY_CHANGE_EVENT_NAME, this);
    AppService.on(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
    const menuPath = this.props.history.location.pathname;
    this.props.setCurrentMenu(menuPath)
  }
  componentWillUnmount() {
    AppService.off(COMPANY_CHANGE_EVENT_NAME, this);
    AppService.off(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
  }
  onReceiveAppNotifications = (eventName, params) => {
    if (eventName === COMPANY_CHANGE_EVENT_NAME || eventName === BUSINESS_UNIT_CHANGE_EVENT_NAME) {
      this.props.load();
    }
  }
  onPage = (e) => {
    this.props.paginate({
      max: e.rows,
      index: e.first
    })
  }
  onSort = (e) => {
    this.props.sortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField
    })
  }
  onFilter = (e) => {
    const filters = [];
    const keys = Object.keys(e.filters) || [];
    keys.forEach(key => {
      if (key === "oid") {      
        filters.push({
          campo: key,
          valor: e.filters[key].value,
          tipoFiltro: "IGUAL_A"
        });
      } else {
        filters.push({
          campo: key,
          valor: e.filters[key].value,
          tipoFiltro: 'CONTEM'
      });
    }
    });  
    this.props.applyFilter(filters)
  }

  onGroupTypeFilterChange = (e) => {
    const target = e.target;
    const field = target.name;
    const value = target.value;
    let filters = [];
    this.props.setGroupTypeField(field, value);
    if(value){
      filters.push({
        campo: field,
        valor: value,
        tipoFiltro: 'IGUAL_A'
      });
    }
    this.props.applyFilter(filters);
  }

  onRowClick = (e) => {
    const oid = e && e.data && e.data.oid;
    if (oid) {
      this.props.history.push(`${routes.PATH_FREIGHT_COMPONENT}/${oid}`)
    }
  }
  onFavorite = (e) => {
    if (this.props.currentMenu) {
      const oidMenu = this.props.currentMenu.oid
      const isFavorite = this.props.isFavorite;
      this.props.toggleFavorite(isFavorite, oidMenu)
    }
  }
  render() {
    const isFavorite = this.props.isFavorite;
    const header = (
      <div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h1>Componentes de frete</h1>
          <i className={`pi pi-star lxFavoriteStar ${isFavorite ? 'lxFavorite' : ''}`}
            title={isFavorite ? 'Remover dos favoritos' : 'Adicionar aos favoritos'} onClick={this.onFavorite}></i>
        </div>
        <hr className="lxSplitter" ></hr>
        <div>
          <Toolbar onNew={this.newHandler} newButtonStyle='highlighted'>
          </Toolbar >
        </div>
      </div>
    );

    const groupTypeFilter = (field) => {
    //  console.log(field);
      return (
        <Dropdown 
          style={{ width: "100%" }}
          name={field}
          value={this.props.groupTypeFilter.groupType}
          options={this.props.groupTypeOptions}
          onChange={(e) => this.onGroupTypeFilterChange(e)}
        />
      )
    }  

    const groupTypeTemplate = (rowData, column) => {
      if (rowData.groupType === "COMPANY") {
        return (
          <div style={{ textAlign: "center" }}>{"EMPRESA"}</div>
        )
      }
      return (
        <div style={{ textAlign: "center" }}>{"SISTEMA"}</div>
      )
    }

    const columns = this.props.columns.map(col => {
      if (col.field === 'groupType') {
        return (<Column 
                  {...col} 
                  key={col.field} 
                  body={groupTypeTemplate} 
                  filterElement={groupTypeFilter(col.field)}
                />);
      }
      return (<Column {...col} key={col.field} />)
    })

    const content = <FreightComponentList  {...this.props} onRowClick={this.onRowClick} onFilter={this.onFilter} onPage={this.onPage} onSort={this.onSort} columns={columns} />;
    return <Page header={header} content={content} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FreightComponentListPageContainer);
