import React from "react";
import { FavoriteSection } from "./components/FavoriteSection";
import { FreightCalculationSection } from "./components/FreightCalculationSection";
import { AuditSection } from "./components/AuditSection";
import { IndicatorsSection } from "./components/IndicatorsSection";
import { IntegrationsSection } from "./components/IntegrationsSection";
import { UnitConfigurationSection } from "./components/UnitConfigurationSection";
import { OrderManagementSection } from "./components/OrderManagement";
import { DeliveryDeadLineSection } from "./components/DeliveryDeadLineSection"
import { CarrierEquipmentTypeSection } from "./components/CarrierEquipmentTypeSection";
import { DockConfigSection } from "./components/DockConfigSection";
import { CadTagsPanel } from "./components/integrationCadTags/CadTagsPanel"; 
import { RouteFreightTableDefaultSection } from "./components/RouteFreightTableDefaultSection";

export const AccountConfigurationForm = (props) => {
  return (
    <div>
      <FavoriteSection favorites={props.configurations.favoriteConfigurations.favorites} />
      <FreightCalculationSection freightCalcConfigurations={props.configurations.freightCalcConfigurations} />
      <AuditSection auditConfigurations={props.configurations.auditConfigurations} />
      <IndicatorsSection indicatorConfigurations={props.configurations.indicatorConfigurations} />
      <IntegrationsSection 
          integrations={props.configurations.integrations} 
          ftpConfigurations={props.configurations.ftpConfigurations} 
          anymarketConfigurations={props.configurations.anymarketConfigurations} 
          roteirizador={props.configurations.roteirizador} 
      />
      <CadTagsPanel/>
      <CarrierEquipmentTypeSection/>
      <OrderManagementSection orderManagementConfigurations={props.configurations.orderManagementConfigurations} />
      <DeliveryDeadLineSection deliveryDeadLineConfigurations={props.configurations.deliveryDeadLineConfigurations} />
      
      <UnitConfigurationSection />
      <DockConfigSection/>
      <RouteFreightTableDefaultSection/>
    </div>
  );
};
