import React from 'react';

import PerfilFormPageContainer from './PerfilFormPageContainer';

export class PerfilFormPage extends React.Component{

    
    render(){
        return (
           <PerfilFormPageContainer {...this.props} />
        )
    }
}