import React from "react";
import { Card } from "primereact/card";
import { UnitConfigurationListPage } from "./list/UnitConfigurationListPage";

export const UnitConfigurationPanel = (props) => {
  return (
    <Card>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h2 id={props.id}> {props.title} </h2>
      </div>
      <hr className="lxSplitter"></hr>
      <UnitConfigurationListPage />
    </Card>
  );
};
