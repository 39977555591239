
import React from 'react';
import { DataTable } from 'primereact/datatable';

export const FreightTableList = (props) => {
        const onRowClick = (e) => {
                if (e.originalEvent && e.originalEvent.target && e.originalEvent.target.cellIndex) {
                        props.onRowClick(e)
                }
        }
        return (
                <DataTable
                        selectionMode="multiple"
                        onFilter={props.onFilter}
                        onSort={props.onSort}
                        onPage={props.onPage}
                        value={props.records}
                        lazy={true}
                        totalRecords={props.totalRecords}
                        sortOrder={props.order}
                        sortField={props.sortField}
                        paginator={true}
                        first={props.first}
                        rows={props.rows}
                        onRowClick={onRowClick}
                        emptyMessage="Não foram encontrados registros"
                        rowsPerPageOptions={[5, 10, 20, 50]}
                        onSelectionChange={props.onSelectionChange}
                        selection={props.selectedFreightTables}
                        metaKeySelection={false}
                >
                        {props.columns}

                </DataTable>
        )
}
