import { types } from "./auditoriaTracking.actions";
import { Reducer } from "../../services/reducer.factory";
import moment from "moment";

const initialState = {

  initialDateFilter: moment().startOf('month').toDate(),
  finalDateFilter: moment().toDate(),

  auditoriaTrackingFilter: {
    lookupCarrier: {
      visible: false,
      modal: false,
      header: "Transportadora"
    }
  },

  columns: [
    {
      field: "oid",
      header: "ID",
      columnKey: "oid",
      filter: true,
    },
    {
      field: "carrier.name",
      header: "Transportadora",
      columnKey: "carrier.name",
      filter: false,
    },
    {
      field: "status",
      header: "Status",
      columnKey: "status",
      filter: false,
    },
    {
      field: "driver.name",
      header: "Motorista",
      columnKey: "driver.name",
      filter: false,
    },
    {
      field: "vehicle.plate",
      header: "Placa do veículo",
      columnKey: "vehicle.plate",
      filter: false,
    },
    {
      field: "pickupDate",
      header: "Data de coleta",
      columnKey: "pickupDate",
      filter: false,
    },
    {
      field: "deliveryForecast",
      header: "Previsão de entrega",
      columnKey: "deliveryForecast",
      filter: false,
    },
    {
      field: "quote",
      header: "Frete total",
      columnKey: "quote",
      filter: false,
    },
    {
      field: "distance",
      header: "KM",
      columnKey: "distance",
      filter: false,
    },
    {
      field: "origin",
      header: "Origem",
      columnKey: "origin",
      filter: false,
    },
    {
      field: "destination",
      header: "Destino",
      columnKey: "destination",
      filter: false,
    },
    {
      field: "actions",
      header: "Ações",
      columnKey: "actions",
      sortable: false,
      filter: false
    }

  ],
  columnsTreeview: [
    {
      field: "code",
      header: "Pedido",
      columnKey: "code",
      filter: false,

    },
    // {
    //    field: "numberNFE",
    //    header: "Número NF-e",
    //    columnKey: "receiver",
    //    filter: false,

    // },
    {
      field: "sender",
      header: "Emitente",
      columnKey: "sender",
      filter: false,

    },
    {
      field: "receiver",
      header: "Destinatário",
      columnKey: "receiver",
      filter: false,

    },
    {
      field: "image",
      header: "Canhoto",
      columnKey: "image",
      filter: false,

    },
    {
      field: "auditOrder.status",
      header: "Status",
      columnKey: "auditOrder.status",
      filter: false,

    },
    {
      field: "valueFreight",
      header: "Valor do frete",
      columnKey: "valueFreight"
    },
    {
      field: "auditOrder.dataPagamento",
      header: "Pagamento",
      columnKey: "dataPagamento",
      filter: false,

    },
    {
      field: "actions",
      header: "Ações",
      columnKey: "actions"
    },
  ],
  approveOrder: null,
  dialogApproveComponent: {
    visible: false,
    modal: true,
    header: "Motivo da aprovação",
    approvalType: "ALL", /*ALL or COMPONENT*/
    alertMessage: "",
  },
  currentApproveComponent: {
    description: "",
    labelCTe: ""
  },
  idEmbarque: 0,
};

const reducer = new Reducer(initialState, types);

export function AuditoriaTrackingReducer(state, action) {
  state = reducer.execute(state, action);
  return execute(state, action);
}

function execute(state, action) {
  let result = state;
  switch (action.type) {
    case types.SET_LOOKUP_CARRIER_VISIBLE:
      return setLookupCarrierVisible(state,action)
    case types.SET_INITIAL_DATE_FILTER:
      return setInitialDateFilter(state, action);
    case types.SET_FINAL_DATE_FILTER:
      return setFinalDateFilter(state, action);
    case types.SET_EXPANDED_ORDERS_ROWS:
      return setExpandedOrdersRows(state, action);
    case types.SET_DIALOG_APPROVE_COMPONENT_VISIBLE:
      return setDialogApproveComponentVisible(state, action);
    case types.SET_CURRENT_APPROVE_COMPONENT_DESCRIPTION:
      return setCurrentApproveComponentDescription(state, action);
    case types.SET_DIALOG_APPROVE_COMPONENT_TYPE:
      return setDialogApproveComponentType(state, action);
    case types.SET_ORDERS_TO_APPROVE:
      return setOrdersToApprove(state, action);
    default:
      return result;
  }
}

function setLookupCarrierVisible(state, action) {
  let auditoriaTrackingFilter = state.auditoriaTrackingFilter;
  let lookupCarrier = auditoriaTrackingFilter.lookupCarrier;
  lookupCarrier = Object.assign({}, lookupCarrier, { visible: action.visible });
  auditoriaTrackingFilter = Object.assign({}, auditoriaTrackingFilter, { lookupCarrier });
  state = Object.assign({}, state, { auditoriaTrackingFilter })
  return state;
}

function setInitialDateFilter(state, action) {
  let initialDateFilter = action.initialDateFilter;
  state = { ...state, initialDateFilter };
  return state;
}

function setFinalDateFilter(state, action) {
  let finalDateFilter = action.finalDateFilter;
  state = { ...state, finalDateFilter };
  return state;
}

function setExpandedOrdersRows(state, action) {
  let expandedOrders = action.expandedOrders;
  state = { ...state, expandedOrders };
  return state;
}

function setOrdersToApprove(state, action) {
  let approveOrder = action.approveOrder;
  let idEmbarque = 0;
  let cnpjCarrier = 0;
  let valueFreight = 0;
  let numberNFE = ""
  const records = state.records;
  for (let i = 0; i < records.length; i++) {
    for (let j = 0; j < records[i].orders.length; j++) {
      if (records[i].orders[j].code === approveOrder.code) {
        idEmbarque = records[i].oid;
        cnpjCarrier = records[i].carrier.cnpj
        valueFreight = records[i].orders.valueFreight
        numberNFE = records[i].orders.numberNFE
        break;
      }
    }
    if (idEmbarque > 0) {
      break;
    }
  }
  state = { ...state, approveOrder, idEmbarque, cnpjCarrier, valueFreight, numberNFE };
  return state;
}


// Dialog Approve Component
function setDialogApproveComponentVisible(state, action) {
  let dialogApproveComponent = { ...state.dialogApproveComponent, visible: action.visible };
  state = { ...state, dialogApproveComponent };
  return state;
}
function setDialogApproveComponentType(state, action) {
  let dialogApproveComponent = { ...state.dialogApproveComponent, approvalType: action.approvalType };
  state = { ...state, dialogApproveComponent };
  return state;
}
function setCurrentApproveComponentDescription(state, action) {
  let currentApproveComponent = { ...state.currentApproveComponent, description: action.description };
  state = { ...state, currentApproveComponent };
  return state;
}