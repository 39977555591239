
import React from 'react';
import { InputText } from 'primereact/inputtext';

export const PackingForm = (props) => {
    console.log('PackingForm');

    return (
        <div className="packaging-form">
            <div className="p-grid">
                <div className="p-col-6">
                    <div className="p-grid">

                        <div className="p-col-6 form-field-container">
                            <label>Código</label>
                            <InputText value={props.record.oid} onChange={props.changeHandler} name="oid" disabled={true} />
                        </div>
                        <div className="p-col-6 form-field-container"></div>
                        <div className="p-col-6 form-field-container">
                            <label>Código do Usuário</label>
                            <InputText value={props.record.code} onChange={props.changeHandler} name="code" />
                        </div>

                        <div className="p-col-12 form-field-container">
                            <label className="lxRequiredField">Nome</label>
                            <InputText value={props.record.name} onChange={props.changeHandler} name="name"  />
                        </div>
                        <div className="p-col-6 form-field-container">
                            <label>Unidade</label>
                            <InputText value={props.record.unit} onChange={props.changeHandler} name="unit"/>
                        </div>
                        <div className="p-col-6 form-field-container">
                            <label>Nome do Compartimento</label>
                            <InputText value={props.record.compartmentName} onChange={props.changeHandler} name="compartmentName"  />
                        </div>
                        <div className="p-col-6 form-field-container">
                            <label>Valor</label>
                            <InputText value={props.record.value} onChange={props.changeHandler} name="value"  />
                        </div>
                        <div className="p-col-6 form-field-container">
                            <label>Cubagem(M³)</label>
                            <InputText value={props.record.cubage} onChange={props.changeHandler} name="cubage" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};