
import './FileUploaderButton.scss'
import React, { Component } from 'react'
import { v4 as uuidv4 } from 'uuid';

export class FileUploaderButtonLabel extends Component {
    removeFiles() {
        const importInput = this.refs.importInput;
        importInput.files = null

    }
    render() {
        const { props } = this;
        const btnId = uuidv4();
        return (

            <button style={{ marginRight: ".25em" }} key={btnId}
                className={"btn-import-file"} disabled={props.disabled}>
                <i style={{ marginTop: '6px' }} className="pi pi-upload"></i>
                <label htmlFor={btnId} style={{ paddingTop: "5px", marginLeft: "5px", fontWeight: "bold" }}>
                    {props.label}
                </label>
                <input ref='importInput' id={btnId} disabled={props.disabled} title={props.title || "Importar"} type='file' accept={props.accept} className={"lxUploadFileButton"} onChange={(e) => { props.onUpload(e); this.removeFiles(); e.target.value = null; }} />

            </button>

        )
    }
};


export default FileUploaderButtonLabel;