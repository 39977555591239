export function validate(record) {
    const errors = [];

    if (!record.code || record.code.trim() === "") {
        errors.push({ field: 'code', message: 'O código do produto é obrigatório!' });
    }

    if (!record.name || record.name.trim() === "") {
        errors.push({ field: 'name', message: 'O nome do produto é obrigatório!' });
    }


    if (!record.height || record.height === "" ) {
        record.height = "0";
    }

    if (!record.width || record.width === "" ) {
        record.width = "0";
    }

    if (!record.depth || record.depth === "" ) {
        record.depth = "0";
    }

    if (!record.maxWeight || record.maxWeight === "" ) {
        record.maxWeight = "0";
    }

    if (!record.maxHeight || record.maxHeight === "" ) {
        record.maxHeight = "0";
    }

    const result = {
        ok: errors.length === 0,
        errors,
        record
    }

    return new Promise((resolve, reject) => {
        const action = result.ok ? resolve : reject;
        return action(result);
    })
}