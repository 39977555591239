
    import React from 'react';
    import RouteListPageContainer from './RouteListPageContainer';
    
    export class RouteListPage extends React.Component{
        
        render(){
          return (
            <RouteListPageContainer {...this.props}/>
          )
        }
    }