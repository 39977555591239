
import { types } from "./carrier.actions";
import { Reducer } from "../../services/reducer.factory";

const initialState = {
  
   
  columns: [
    {
      field: "oid",
      header: "Código",
      columnKey: "oid",
      sortable: true,
      filter: true
    },
    {
      field: "label",
      header: "Sigla",
      columnKey: "label",
      sortable: true,
      filter: true
    },{
      field: "documentNumber",
      header: "CNPJ",
      columnKey: "documentNumber",
      sortable: true,
      filter: true
    },
    {
      field: "name",
      header: "Nome",
      columnKey: "name",
      sortable: true,
      filter: true
    },{
      field: "tradeName",
      header: "Nome Fantasia",
      columnKey: "tradeName",
      sortable: true,
      filter: true
    }
  ],
  branchesColumns: [
    {
      field: "name",
      header: "Nome",
      columnKey: "name",
      sortable: true,
      filter: true
    },
    {
      field: "tradeName",
      header: "Nome Fantasia",
      columnKey: "tradeName",
      sortable: true,
      filter: true
    },
    {
      field: "documentNumber",
      header: "Número",
      columnKey: "documentNumber",
      sortable: true,
      filter: true
    }
  ],
  
  currentRecord: {
    oid: "",
    label: "",
    name: "", 
    tradeName: "",
    documentNumber: "",
    address:{
      street:"",number:"",complement:"",district:"",city:"",zipCode:""
    }, 
    email: "",
    site: "", 
    phone: "", 
    contact: "", 
    standardCubage :null,
    branches :[]
  },
  selectedRecords : [],
  selectedBranches:[],
  currentBranch:{
    label: "",
    name: "", 
    tradeName: "",
    documentNumber: "",
    address:{
      street:"",number:"",complement:"",district:"",city:"",zipCode:""
    }, 
    email: "",
    site: "", 
    phone: "", 
    contact: ""
  },
 
  dialogAddBranches: {
    visible: false,
    modal: true,
    header: "Adicionar filiais"
  },
 
  
};

const reducer = new Reducer(initialState, types);

export function CRUDCarrierReducer(state, action) {
  state = reducer.execute(state, action);
  return execute(state, action);
}

function execute(state, action) {
  let result = state;
  switch (action.type) {
    case types.SET_DIALOG_ADD_BRANCHES_VISIBLE:
      return setDialogAddBranchesVisible(state, action);
    case types.ADD_BRANCH:
      return addBranch(state, action);
    case types.SET_SELECTED_RECORDS:
      return setSelectedRecords(state, action);
      case types.SET_CURRENT_BRANCH_FIELD:
        return setCurrentBranchField(state, action);
      case types.SET_CURRENT_BRANCH:
        return setCurrentBranch(state, action);
      case types.SET_SELECTED_BRANCHES:
        return setSelectedBranches(state, action);
      case types.REMOVE_SELECTED_BRANCHES:
        return removeSelectedBranches(state, action);
    default:
      return result;
  }
}



function setCurrentBranchField(state,action){
  let currentBranch ={...state.currentBranch};
  const { field, value } = action;
  const fields = field && field.split('.');
  const lastField = fields[fields.length - 1];
  let current = currentBranch;
  if (fields.length > 1) {
    for (let index = 0; index < fields.length - 1; index++) {
      const owner = current;
      current = current[fields[index]];
      if (!current) {
        current = {}
        owner[fields[index]] = current;
      }
    }
  }

  current[lastField] = value;
  return {...state, currentBranch}
}

function setCurrentBranch(state,action){
  const {currentBranch} = action;
  return {...state,currentBranch}
}


function setDialogAddBranchesVisible(state, action) {
  let dialogAddBranches = state.dialogAddBranches;
  dialogAddBranches = Object.assign({}, dialogAddBranches, { visible: action.visible });
  return Object.assign({}, state, { dialogAddBranches })
}




function addBranch(state, action) {
  let branches = [...state.currentRecord.branches];
  const alreadyAdded = branches.filter(branch => {
    return branch.documentNumber === state.currentBranch.documentNumber;
  }).length > 0;
  
  if (!alreadyAdded) {
    branches = [...branches, state.currentBranch];
  }else{
    branches = branches.map(branch =>{
      if(branch.documentNumber === state.currentBranch.documentNumber){
        return {...state.currentBranch}
      }
      return branch;
    })
  }
  let currentRecord = {...state.currentRecord,branches};
  return {...state,currentRecord};
}


function setSelectedRecords(state, action) {
  const selectedRecords = action.selectedRecords;
  return Object.assign({}, state, { selectedRecords });
}
function setSelectedBranches(state, action) {
  const selectedBranches = [...action.branches];
  return {...state,selectedBranches};
}

function removeSelectedBranches(state, action) {
  let {selectedBranches=[], currentRecord ={}} = state;
  let branches = [...currentRecord.branches];
  branches = branches.filter(branch =>{
    let contains = selectedBranches.find(selected => selected.documentNumber === branch.documentNumber) != null;
    return !contains;
  })
  currentRecord = {...currentRecord, branches};
  selectedBranches = []
  return {...state,currentRecord, selectedBranches};
}