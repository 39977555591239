
import React from 'react';
import PortalCarrierFormPageContainer from './PortalCarrierFormPageContainer';

export class PortalCarrierFormPage extends React.Component {
    render() {
        return (
            <PortalCarrierFormPageContainer {...this.props} />
        )
    }
}
