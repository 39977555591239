

export function validate(record) {
    const errors = [];
    if (!record.codeCFOP || record.codeCFOP.trim() === "") {
        errors.push({ field: 'codeCFOP', message: 'O código CFOP é obrigatório!' });
    }
    const result = {
        ok: errors.length === 0,
        errors,
        record
    }

    return new Promise((resolve, reject) => {
        const action = result.ok ? resolve : reject;
        return action(result);
    })
}