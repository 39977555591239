const CANCELED = "CANCELED";
const CREATED = "CREATED";
const TRAVELING = "TRAVELING";
const END_TRAVEL = "END_TRAVEL";
//prazo
const TRAVEL_ON_TIME="ON_TIME";
const TRAVEL_LATE="LATE";
const TRAVEL_LAG_TREND="LAG_TREND";
const ORIGEM_PORTAL_TRANSPORTADOR="ORIGEM_PORTAL_TRANSPORTADOR";
export {
    CREATED,CANCELED,TRAVELING,END_TRAVEL,TRAVEL_ON_TIME,TRAVEL_LATE,TRAVEL_LAG_TREND,ORIGEM_PORTAL_TRANSPORTADOR
}