import { validate } from './validations';
import { build } from '../../services/crudFactory';
import { Action } from '../../services/actions.factory';
const BASE_PATH = '/lognex/transferroute';
const prefix = 'crudTransferRoute';
const crud = build(validate, BASE_PATH);

const newTypes = {
    SET_ORIGIN_SELECTED:`${prefix}setOriginSelected`,
    SET_ORIGIN_VISIBLE:`${prefix}setOriginVisible`,
    SET_DESTINATION_SELECTED:`${prefix}setDestinationSelected`,
    SET_DESTINATION_VISIBLE:`${prefix}setDestinationVisible`,
}

const actions = new Action(prefix, crud);
const types = Object.assign({}, actions.types, newTypes);


actions.setOriginVisible = (visible) => {
    return {
        type: types.SET_ORIGIN_VISIBLE,
        visible
    }
}
actions.setDestinationVisible = (visible) => {
    return {
        type: types.SET_DESTINATION_VISIBLE,
        visible
    }
}
actions.setOriginSelected = (origin) => {
    return {
        type: types.SET_ORIGIN_SELECTED,
        origin
    }
}

actions.setDestinationSelected = (origin) => {
    return {
        type: types.SET_DESTINATION_SELECTED,
        origin
    }
}



export { types, actions };