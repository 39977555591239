
import React from 'react';
import { connect } from 'react-redux';
import { actions } from '../../../portalCarrier.actions';
import { Page } from "../../../../../components/page/Page";
import Toolbar from '../../../../../components/toolbar/Toolbar';
import { routes } from '../../../../../constants';
import { Column } from 'primereact/column';
import { TableRowTreeviewRejected } from './TableRowTreeviewRejected';
import { CollectionOrdersRejectedList } from './CollectionOrdersRejectedList';
import { numberToCurrency } from '../../../../../utils';
import storage from '../../../../../services/stoage.service'
import { AppService, COMPANY_CHANGE_EVENT_NAME } from '../../../../../services/app.service';
import { ORDER_REJECTED } from '../../../constantes';
import locationIcon from "../../../../../assets/images/icons/location.png";
import MapViewRouteDialog from '../../../../../components/map-view-route-dialog/MapViewRouteDialog';
import moment from 'moment';
import { PortalCarrierFiltro } from '../../filtro/filtro';


const mapStateToProps = state => {
  const records = state.crudPortalCarrierState.collectionOrderRejected;
  let columns = [];
  let columnsRowTreeviewTemplate = []; 
 

  const user = storage.getUser();

  if (!user.carrier) {
    columnsRowTreeviewTemplate = [...state.crudPortalCarrierState.columnsRowTreeviewRejectedTemplate, {
      field: "rejetedByTransp",
      header: "Transp Rescusou?",
      columnKey: "rejetedByTransp",
      sortable: false,
      filter: false
    }, {
      field: "carrier.name",
      header: "Transportadora",
      columnKey: "carrier.name",
      sortable: false,
      filter: false
    }]
    
  } else {
    columnsRowTreeviewTemplate = [...state.crudPortalCarrierState.columnsRowTreeviewRejectedTemplate]
  }
  columns = state.crudPortalCarrierState.columnsCollectionOrdersRejected
  const dialogMapViewRouteVisible = state.crudPortalCarrierState.dialogMapViewRouteVisible;
  const idEmbarqueShowMap = state.crudPortalCarrierState.idEmbarqueShowMap;
 
  return {
    records,
    columns,

    //devera ser limpo a cada troca de tela
    first: state.crudPortalCarrierState.index,
    rows: state.crudPortalCarrierState.max,
    totalRecords: state.crudPortalCarrierState.count,
    sortField: state.crudPortalCarrierState.sortField,
    order: state.crudPortalCarrierState.sortOrder,

    expandedRows: state.crudPortalCarrierState.expandedRows,
    columnsRowTreeviewTemplate,
    openDialogIframeVisible: state.crudPortalCarrierState.openDialogIframeVisible,
    link: state.crudPortalCarrierState.link,
    carrier: state.crudPortalCarrierState.carrier, 
    dialogMapViewRouteVisible,
    idEmbarqueShowMap,
    initialDate:state.crudPortalCarrierState.initialDate,
    finalDate:state.crudPortalCarrierState.finalDate
  }
}

const mapDispatchToProps = dispatch => {
  return {

    paginate: paginationParams => {
      if (paginationParams.index !== undefined) {
        dispatch(actions.setIndex(paginationParams.index));
      }
      if (paginationParams.page !== undefined) {
        dispatch(actions.setPage(paginationParams.page));
      }
      if (paginationParams.max !== undefined) {
        dispatch(actions.setMax(paginationParams.max));
      }
    },
    cleanPaginate: () => {
      dispatch(actions.setIndex(0));
      dispatch(actions.setPage(0));
      dispatch(actions.setMax(50));
    },


    setField: (field, value) => dispatch(actions.setField(field, value)),
    changeHandler: event => {
      const target = event.target;
      const field = target.name;
      let value = target.value;
      dispatch(actions.removeErrors([{ field }]));
      dispatch(actions.setField(field, value));
    },

    loadCollectionOrders: () => dispatch(actions.findAllCollectionOrderRejected()),
    exportReportCollectionOrderRejected: () => dispatch(actions.exportReportCollectionOrderRejected()),
    setExpandedRows: (expandedRows) => {
      dispatch(actions.setExpandedRows(expandedRows));
    },
    setCurrentScreen: (currentScreen) => {
      dispatch(actions.setCurrentScreen(currentScreen));
    },
    openDialogIframeCollectionOrder: (visible, link) => {
      dispatch(actions.openDialogIframeCollectionOrder({ visible, link }));
    },
    setVisibleDialogMapViewRoute: (visible) => dispatch(actions.setVisibleDialogMapViewRoute(visible)),
    setIdEmbarToViewRouteOnMap: (idEmbarqueShowMap) => dispatch(actions.setIdEmbarToViewRouteOnMap(idEmbarqueShowMap)),
    initialDateChangeHandler: (e) => dispatch(actions.setInitialDateFilter(e.value)),
    finalDateChangeHandler: (e) => dispatch(actions.setFinalDateFilter(e.value)),
  }
}

class CollectionOrdersRejectedPageContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      dialogWidth: '100%',
      dialogHeight: '98%',
      windowHeight: 800,
    };
  }

  componentDidMount() {
    AppService.on(COMPANY_CHANGE_EVENT_NAME, this);
    this.props.setCurrentScreen(ORDER_REJECTED);
    this.props.loadCollectionOrders();
  }

  componentWillUnmount() {
    AppService.off(COMPANY_CHANGE_EVENT_NAME, this);
    this.props.cleanPaginate();
  }

  onReceiveAppNotifications = (eventName, params) => {
    if (eventName === COMPANY_CHANGE_EVENT_NAME) {
      this.props.setCurrentScreen(ORDER_REJECTED);
      this.props.loadCollectionOrders();
    }
  };
  searchHandler = () => {
    this.props.history.push(`${routes.PATH_CARRIER_PORTAL}`)
  }

  setExpandedRowsByRowClick = (e) => {
    if (e.data && e.data.length > 1) {
      this.props.setExpandedRows([e.data[1]]);
    } else if (e.data && e.data.length == 1) {
      this.props.setExpandedRows([e.data[0]]);
    } else {
      this.props.setExpandedRows([e.data]);
    }
  };
  setExpandedRowsClick = (e) => {
    if (e.data && e.data.length > 1) {
      this.props.setExpandedRows([e.data[1]]);
    } else {
      this.props.setExpandedRows([e.data]);
    }
  };
  onRowsClick = (e) => {
    if (e.data && e.data.length > 1) {
      this.props.setExpandedRows([e.data[1]]);
    } else {
      this.props.setExpandedRows([e.data]);
    }
  };

  onPage = (e) => {
    this.props.paginate({
      max: e.rows,
      index: e.first
    })
    this.props.loadCollectionOrders();
  }
  openViewRouteOnMap = (cargoShipViewMap) => {
    this.props.setIdEmbarToViewRouteOnMap(cargoShipViewMap.oid);
    this.props.setVisibleDialogMapViewRoute(true);
  }

  render() {

    const header = (
      <div>

        <hr className="lxSplitter" ></hr>
        <div>
          <Toolbar onSearch={this.searchHandler} />
        </div>
      </div>
    );


    const numberTemplate = (rowData, column) => {
      try {
        const valor = rowData[column.field];
        let numero = "";
        if (valor) {
          numero = !valor ? 0.0 : parseFloat(valor).toFixed(3);
        }
        return (
          <div style={{ textAlign: "center", color: "black" }}>
            {numero}
          </div>
        );
      } catch (error) {
        return <div></div>;
      }
    };


    const pesoTemplate = (rowData, column) => {
      try {
        const valor = rowData.cargo.Weight;
        let numero = "";
        if (valor) {
          numero = !valor ? 0.0 : parseFloat(valor).toFixed(3);
        }
        return (
          <div style={{ textAlign: "center", color: "black" }}>
            {numero}
          </div>
        );
      } catch (error) {
        return <div></div>;
      }
    };


    const boolTemplate = (rowData, column) => {
      try {
        const valor = rowData[column.field];
        let valStr = "Não";
        if (valor) {
          valStr =  "Sim";
        }
        return (
          <div style={{ textAlign: "center", color: "black" }}>
            {valStr}
          </div>
        );
      } catch (error) {
        return <div></div>;
      }
    };


    const valorCustoTemplate = (rowData, column) => {
      try {
        const valor = Number(rowData[column.field]);
        let formattedDate = "";
        if (valor) {
          formattedDate = numberToCurrency(valor, "R$");
        }
        return (
          <div style={{ textAlign: "center", color: "black" }}>
            {formattedDate}
          </div>
        );
      } catch (error) {
        return <div></div>;
      }
    };
    
    const btnViewOnMapTemplate = (rowData, column) => {
      try {

        return (
          <div style={{ textAlign: "center" }}>

            <button
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                this.openViewRouteOnMap(rowData);
              }}
              className={"btnAcoes"}
              title="Ver no Mapa">
              <i>
                <img src={locationIcon} style={{ height: "26px" }}></img>
              </i>
            </button>
          </div>
        );
      } catch (error) {
        return <div></div>;
      }
    };
    const pickupDateTemplate = (rowData, column) => {
    
      if ( rowData.pickupDate) {
        const date = rowData.pickupDate;
        const formattedDate = moment(date).format("DD/MM/YYYY");
        return <span style={{ textAlign: "center" }}  >{formattedDate}</span>;
      } else {
        return <div></div>
      }
    };
    const rejectedAtDateTemplate = (rowData, column) => {
    
      if ( rowData.rejectedAt) {
        const date = rowData.rejectedAt;
        const formattedDate = moment(date).format("DD/MM/YYYY");
        return <span style={{ textAlign: "center" }}  >{formattedDate}</span>;
      } else {
        return <div></div>
      }
    };

    const createAtDateTemplate = (rowData, column) => {
    
      if ( rowData.createAt) {
        const date = rowData.createAt;
        const formattedDate = moment(date).format("DD/MM/YYYY");
        return <span style={{ textAlign: "center" }}  >{formattedDate}</span>;
      } else {
        return <div></div>
      }
    };

    const templateExpandedRow = (rowData, e) => {
      const records = [];
      try {
        let sequencia = 0;
        rowData.collectionOrders.forEach((dest) => {
          sequencia++;
          records.push({
            index: sequencia,
            ...dest
          });
        });
      } catch (error) {
        console.log("Erro templateExpandedRow=", error);
      }  

      let columnsTreeview = this.props.columnsRowTreeviewTemplate.map((col) => {
        if (col.field === "status") {
          return <Column {...col} key={col.field} style={{ width: "80px" }} />;
        }
        if (col.field === "origin.name") {
          return <Column {...col} key={col.field} style={{ width: "100px" }} />;
        }
        if (col.field === "destination.name") {
          return <Column {...col} key={col.field} style={{ width: "120px" }} />;
        }
        if (col.field === "vehicleType") {
          return <Column {...col} key={col.field} style={{ width: "120px" }} />;
        }
        if (col.field === "pickupDate") {
          return <Column {...col} key={col.field} style={{ width: "120px" }} body={pickupDateTemplate}/>;
        } 
        if (col.field === "rejectedAt") {
          return <Column {...col} key={col.field} style={{ width: "120px" }} body={rejectedAtDateTemplate}/>;
        } 
        if (col.field === "createAt") {
          return <Column {...col} key={col.field} style={{ width: "120px" }} body={createAtDateTemplate}/>;
        } 
        if (col.field === "cargo.Weight") {
          return <Column {...col} key={col.field} style={{ width: "110px" }} body={pesoTemplate} />;
        } 
        if (col.field === "rejetedByTransp") {
          return <Column {...col} key={col.field}  body={boolTemplate} />;
        } 
        
        return <Column {...col} key={col.field} />;
      });


      return (
        <div>
          <h3>Ordem coleta</h3>
          <TableRowTreeviewRejected records={records} columns={columnsTreeview} />           
        </div>
      );
    };

    const columns = this.props.columns.map((col) => {
      
      if (col.field === "oid") {
        return <Column {...col} key={col.field} style={{ width: "80px" }} />;
      }
      if (col.field === "status") {
        return <Column {...col} key={col.field} style={{ width: "120px" }} />;
      }
      if (col.field === "freightValue") {
        return <Column {...col} key={col.field} style={{ width: "130px" }} body={valorCustoTemplate} />;
      }
      if (col.field === "pickupDate") {
        return <Column {...col} key={col.field} style={{ width: "120px" }} body={pickupDateTemplate}/>;
      } 
      if (col.field === "createAt") {
        return <Column {...col} key={col.field} style={{ width: "120px" }} body={pickupDateTemplate}/>;
      } 
      if (col.field === "isDv") {
        return <Column {...col} key={col.field} style={{ width: "90px" }} body={boolTemplate} />;
      } 
      if (col.field === "isAg") {
        return <Column {...col} key={col.field} style={{ width: "90px" }} body={boolTemplate} />;
      } 
      if (col.field === "btnViewOnMap") {
        return <Column {...col} key={col.field} style={{ width: "80px" }} body={btnViewOnMapTemplate} />;
      }
      return <Column {...col} key={col.field} />;
    });
    const getMapViewRouteDialog = () => {
      if (this.props.dialogMapViewRouteVisible) {
        return <MapViewRouteDialog
          dialogMapViewRouteVisible={this.props.dialogMapViewRouteVisible}
          idEmbarqueShowMap={this.props.idEmbarqueShowMap}
          onHideDialog={(e) => {
            this.props.setVisibleDialogMapViewRoute(false);
          }} />
      }
      return <></>
    }

    const content = (
      <div>
        <h1>Embarque recusados</h1>
        <PortalCarrierFiltro 
          onClickAplicarFiltro={this.props.loadCollectionOrders}
          initialDate={this.props.initialDate}    
          finalDate={this.props.finalDate}    
          initialDateChangeHandler={this.props.initialDateChangeHandler}
          finalDateChangeHandler={this.props.finalDateChangeHandler}   
          onExportDownloadXLSX={this.props.exportReportCollectionOrderRejected}
        />

        <CollectionOrdersRejectedList
          records={this.props.records}
          rowToggleHandler={this.setExpandedRowsClick}
          rowExpandButtonOnClick={this.setExpandedRowsByRowClick}
          expandedRows={this.props.expandedRows}
          templateExpandedRow={templateExpandedRow}
          onRowsClick={this.onRowsClick}
          onPage={this.onPage}
          first={this.props.first}
          rows={this.props.rows}
          totalRecords={this.props.totalRecords}
          sortField={this.props.sortField}
          order={this.props.order}
          columns={columns} />
        {getMapViewRouteDialog()}
      </div>
    );

    return (<Page header={header} content={content} />);
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CollectionOrdersRejectedPageContainer);