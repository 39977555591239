import React from 'react';
import { InputSwitch } from 'primereact/inputswitch';

export const CheckboxConfigurationComponent =(props)=>{
    return(
        <div className="p-grid">
            <div className="p-col-4">{props.data.title}</div>
            <div className="p-col-2 p-justify-center">
                <InputSwitch
                    checked={props.data.value}
                    onChange={props.data.onChange}
                    name={props.data.name}
                    id={props.data.id}
                />
            </div>
        </div>
    )
} 