import React from "react";
import { AutoComplete } from 'primereact/autocomplete';
import { Lookup } from "./Lookup";
import './LookupField.scss';

export class LookupField extends React.Component {
  

  render() {
    const props = this.props;
    let btnEdit = (
      <button 
      icon="pi pi-pencil" 
      onClick={props.onEditField} 
      disabled={props.editDisabled}>
      <i className="pi pi-pencil"></i>
    </button>
    );
    if(props.removeEdit){
      btnEdit = null;
    }
    return (
      <React.Fragment>
        <div
          className="p-inputgroup lookup-field-complete"
          style={{ width: "100%", display: "flex" }}
        >
          <AutoComplete
            style={{ flexGrow: "1" }}
            completeMethod={props.onComplete}
            onChange={props.onInputFilter}
            suggestions={props.records}
            placeholder={props.placeholder}
            value={props.value}
            name={props.name}
            field={props.field}
            disabled={props.disabled}
          />
          <button
            className="lognex-btn-highlighted"
            disabled={props.disabled}
            onClick={props.onClick}>
            <i className="pi pi-search"></i>
          </button>
          {btnEdit}
        </div>

        <Lookup
          visible={props.visible}
          modal={props.modal}
          header={props.header}
          onHide={props.onHide}
          onConfirm={props.onConfirm}
          onCancel={props.onCancel}
          records={props.records}
          columns={props.columns}
          totalRecords={props.totalRecords}
          sortOrder={props.order}
          sortField={props.sortField}
          first={props.first}
          rows={props.rows}
          onPage={props.onPage}
          onFilter={props.onFilter}
          paginator={props.paginator}
          rowsPerPageOptions={this.props.rowsPerPageOptions || []}
          onSort={props.onSort}
          isMultiple={props.isMultiple}
        />
      </React.Fragment>
    );
  }
}
