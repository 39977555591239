import React from 'react';
import { Calendar } from 'primereact/calendar';

export const TimeConfigurationsComponent = (props) => {
    return (
        <div className="p-grid">
            <div className="p-col-4">{props.title}</div>
            <div className="p-col-2 p-justify-center">
                <div className="field col-12 md:col-4">
                    <Calendar
                        id={props.data.start.id}
                        name={props.data.start.name}
                        value={props.data.start.value}
                        onChange={props.data.start.onChange}
                        timeOnly={true} 
                        hourFormat="12" 
                        placeholder={props.data.start.placeholder} />
                </div>
            </div>
            <div className="p-col-2 p-justify-center">
                <div className="field col-12 md:col-4">
                    <Calendar
                        id={props.data.end.id}
                        name={props.data.end.name}
                        value={props.data.end.value}
                        onChange={props.data.end.onChange}
                        timeOnly={true} 
                        hourFormat="12" 
                        placeholder={props.data.end.placeholder} />
                </div>
            </div>
        </div>
    )
} 