import React from 'react';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown'

import './UserDocumentTypeForm.scss'

export const UserDocumentTypeForm = (props) => {
    return(
        <div className="userDocumentType-form">
            <div className="p-grid">
                <div className="p-col-6">
                    <div className="p-grid">
                        <div className="p-col-3">
                            <div className="form-field-container">
                                <label>Código</label>
                                <InputText value={props.record.oid} onChange={props.changeHandler} disabled={true} />
                            </div>
    
                        </div>
                        <div className="p-col-12 form-field-container">
                            <label className="lxRequiredField">Nome</label>
                            <InputText value={props.record.name} onChange={props.changeHandler} name="name" />
                        </div>
                        <div className="p-col-12 form-field-container">
                            <label  className="lxRequiredField">Sigla</label>
                            <InputText value={props.record.sigla} onChange={props.changeHandler} name="sigla" />
                        </div>
    
                        <div className="p-col-12 form-field-container">
                            <label>Descrição</label>
                            <InputTextarea value={props.record.description} onChange={props.changeHandler} name="description" />
                        </div>

                        <div className="p-col-6 form-field-container">
                            <label className="lxRequiredField">Lognex Tipo</label>
                            <Dropdown
                                name="lognexDocumentType" 
                                options={props.lognexDocumentTypes}
                                optionLabel="label"
                                value={props.record.lognexDocumentType}
                                onChange={props.changeLognexDocumentTypeHandler}  
                                placeholder="Selecione o Tipo Lognex" 
                            ></Dropdown>
                            
                        </div>                        
    
                    </div>
                </div>
            </div>
    
        </div>
    );
}