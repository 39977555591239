import React from 'react';
import EmpresaListPageContainer from './EmpresaListPageContainer';

export class EmpresaListPage extends React.Component{
    
    render(){
      return (
        <EmpresaListPageContainer {...this.props}/>
      )
    }
}


