export function isFavorite(currentMenu, userConfigurations) {
  let isFavorite = false;
  if (currentMenu && userConfigurations && userConfigurations.favorites) {
    isFavorite =
      userConfigurations.favorites.filter((favorite) => {
        return favorite === currentMenu.oid;
      }).length !== 0;
  }
  return isFavorite;
}

export function numberToCurrency(number, currency) {
  let formattedNumber = number;
  if (currency.toUpperCase() === "REAL" || currency.toUpperCase() === "R$") {
    formattedNumber =
      "R$ " +
      number
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,")
        .replace(",", ".")
        .replace(/\.(\d{2})$/, ",$&")
        .replace(/,./, ",");
  }
  return formattedNumber;
}

export function formatNumber(number, fixed = 2) {
  number = !number ? 0.0 : parseFloat(number);
  return applyThousandsPoints(number.toFixed(fixed).replace(".", ","));
}

export function formatNumberDefault(number) {
  number = !number ? 0.0 : parseFloat(number);
  return applyThousandsPoints(number.toString().replace(".", ","));
}

export function applyThousandsPoints(value) {
  const intergerPart = value.indexOf(",") >= 0 ? value.substring(0, value.indexOf(",")) : value;
  const decimalPart = value.indexOf(",") >= 0 ? value.substring(value.indexOf(","), value.length) : "";
  let intergerPartWithPoints = "";
  let intergerPartWithoutPoints = "";
  for (var i = intergerPart.length - 1; i >= 0; i--) {
    if (intergerPartWithoutPoints.length > 0 && intergerPartWithoutPoints.length % 3 === 0) {
      intergerPartWithPoints = "." + intergerPartWithPoints;
    }
    intergerPartWithPoints = intergerPart.charAt(i) + intergerPartWithPoints;
    intergerPartWithoutPoints = intergerPart.charAt(i) + intergerPartWithoutPoints;
  }

  return intergerPartWithPoints + decimalPart;
}

export function replaceAll(str, searchString, replaceString) {
  while (str.indexOf(searchString) >= 0) {
    str = str.replace(searchString, replaceString);
  }
  return str;
}

export function removeCharacterAt(str, char_pos) {
  const part1 = str.substring(0, char_pos);
  const part2 = str.substring(char_pos + 1, str.length);
  return part1 + part2;
}

export function removeInvalidCaracteres(value, cbValidateCharacter) {
  for (var i = 0; i < value.length; i++) {
    if (!cbValidateCharacter(value.charAt(i))) {
      value = removeCharacterAt(value, i);
    }
  }
  return value;
}

export function clearSpecialCharacteres(str) {
  let value = str.replace(/[^a-zA-Z0-9]/g, "");
  return value;
}

export function clearCharacteres(str) {
  let value = str.replace(/[^0-9]/g, "");
  return value;
}

export function getFieldValueFromStringRecursively(obj, str) {
  const fields = str.split(".");
  let currentValue = obj;
  fields.forEach((field) => {
    currentValue = currentValue[field];
  });
  return currentValue;
}

export function deepCopy(obj) {
  var rv;

  switch (typeof obj) {
    case "object":
      if (obj === null) {
        // null => null
        rv = null;
      } else {
        switch (toString.call(obj)) {
          case "[object Array]":
            // It's an array, create a new array with
            // deep copies of the entries
            rv = obj.map(deepCopy);
            break;
          case "[object Date]":
            // Clone the date
            rv = new Date(obj);
            break;
          case "[object RegExp]":
            // Clone the RegExp
            rv = new RegExp(obj);
            break;
          // ...probably a few others
          default:
            // Some other kind of object, deep-copy its
            // properties into a new object
            rv = Object.keys(obj).reduce(function (prev, key) {
              prev[key] = deepCopy(obj[key]);
              return prev;
            }, {});
            break;
        }
      }
      break;
    default:
      // It's a primitive, copy via assignment
      rv = obj;
      break;
  }
  return rv;
}

export function verifyIdExistsInObjectList(id, objList) {
  if (id && objList && objList.length > 0) {
    for (let index = 0; index < objList.length; index++) {
      const obj = objList[index];
      if (obj.id === id || obj.oid === id) {
        return true;
      }
    }
  }
  return false;
}

export function formatCNPJ(cnpj) {
  if (!cnpj || cnpj.lenth < 14) {
    return cnpj;
  }
  const mask = "##.###.###/####-##";
  let index = 0;
  const data = mask.split("").map((it) => {
    if (it === "#") {
      return cnpj[index++];
    } else {
      return it;
    }
  });
  let formated = data.join("");
  return formated;
}

export function formatPhone(phone) {
  if (!phone || phone.lenth < 11) {
    return phone;
  }
  const mask = "(##) #####-####";
  let index = 0;
  const data = mask.split("").map((it) => {
    if (it === "#") {
      return phone[index++];
    } else {
      return it;
    }
  });
  let formated = data.join("");
  return formated;
}

export function formatCep(cep) {
  if (!cep || cep.lenth < 8) {
    return cep;
  }
  const mask = "#####-###";
  let index = 0;
  const data = mask.split("").map((it) => {
    if (it === "#") {
      return cep[index++];
    } else {
      return it;
    }
  });
  let formated = data.join("");
  return formated;
}

export function clearSpecialCharacteresSpace(text) {
  const a = "àáäâãèéëêìíïîòóöôùúüûñçßÿœæŕśńṕẃǵǹḿǘẍźḧ·/_,:;";
  const b = "aaaaaeeeeiiiioooouuuuncsyoarsnpwgnmuxzh------";
  const p = new RegExp(a.split("").join("|"), "g");
  return text
    .toString()
    .toLowerCase()
    .trim()
    .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special chars
    .replace(/&/g, "-and-") // Replace & with 'and'
    .replace(/[\s\W-]+/g, "-"); // Replace spaces, non-word characters and dashes with a single dash (-)
}

export function classNames(cssClasses) {
  let result = "";
  if (cssClasses) {
    result = Object.keys(cssClasses).reduce((css, key) => {
      if (cssClasses[key] === true) {
        css = css + ` ${key}`;
      }
      return css;
    }, result);
  }
  return result;
}

export function segParaHora(seconds) { 

  var result = "";
  var minutes = Math.round(seconds / 60);
  if (minutes < 60) {
    result = minutes + " min";
  } else {
    var hours = Math.floor(minutes / 60);
    var minutes_mod = minutes % 60;
    if (minutes_mod > 0) {
      var padding = minutes_mod < 10 ? "0" : "";
      result = " " + padding + minutes_mod + result + " min";
    }
    if (hours > 0) {
      result = (hours % 24) + " hr" + result;
    } 

    var days = Math.floor(hours / 24);
    var weeks = Math.floor(days / 7);
    if (days > 0) {
      let d = days % 7;
      if (weeks > 0) {
        let diasSemana = weeks * 7;
        d = d + diasSemana;
      }
      result = d + " d " + result;
    }
  }
  return result;
 
}
