
    import React from 'react';
    import CfopFormPageContainer from './CfopFormPageContainer';
    
    export class CfopFormPage extends React.Component{
        render(){
            return (
                <CfopFormPageContainer {...this.props}/>
            )
        }
    }
    