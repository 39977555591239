import React from 'react';
import { DataTable } from 'primereact/datatable';

export const CargoShipmentReportKmList = (props) => (
  <DataTable
    selectionMode="single"
    onFilter={props.onFilter}
    onSort={props.onSort}
    onPage={props.onPage}
    value={props.records}
    lazy={true}
    totalRecords={props.totalRecords}
    sortOrder={props.order}
    sortField={props.sortField}
    paginator={false}
    first={props.first}
    rows={props.rows}
    emptyMessage="Não foram encontrados registros"
    // onRowToggle={props.rowExpandButtonOnClick}
    onRowClick={props.onRowClick}
    resizableColumns={true}
    scrollable={true}
    scrollHeight={"calc(100vh - 360px)"}
  >
    {props.columns}
  </DataTable>
)
