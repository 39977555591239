import React from 'react';

import ModuloFormPageContainer from './ModuloFormPageContainer';

export class ModuloFormPage extends React.Component{

    
    render(){
        return (
           <ModuloFormPageContainer {...this.props} />
        )
    }
}
