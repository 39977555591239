import React from 'react';
import './SideMenu.scss';
import {SocialMediaBar} from '../socialmedia-bar/SocialMediaBar'
import * as IconFactory from '../../services/icons.factory'

export class SideMenu extends React.Component {
    componentWillReceiveProps(old, neww) {
    }

    inactiveChildren = (element, childrenType) => {
        let children = element.querySelectorAll(`${childrenType}`);
        if (children) {
            for (let elem of children) {
                elem.classList.remove('active');
            }
        }
    }
    inactiveSiblings = (element, siblingType) => {
        const parent = element.parentElement;
        if (parent) {
            const liSiblings = parent.querySelectorAll(':scope > ' + siblingType);
            for (let sibling of liSiblings) {
                if (sibling !== element) {
                    this.inactiveChildren(sibling, 'ul');
                }
            }
        }
    }

    toggleMenu = (e) => {
        const el = e.currentTarget
        const li = el.parentElement;
        const ulChildren = li.querySelector('ul');

        this.inactiveSiblings(li, 'li');
        if (ulChildren) {
            ulChildren.classList.toggle('active');
            this.inactiveChildren(ulChildren, 'ul');
        } else {
            this.inactiveAll();
        }
    }

    inactiveAll = () => {
        const container = document.querySelector('.lognex-sidemenu');
        this.inactiveChildren(container, 'ul');
    }
    handleMountMenu = (menuItems, level = 0) => {
        return menuItems.map((menuItem, index) => {
            let labelMenuItem;

            if (level === 0) {
                labelMenuItem =
                    <p className="lognex-sidemenu__menuItemLabelParent" key={index} onClick={(e) => { menuItem.command && menuItem.command(); this.toggleMenu(e) }}>
                        <span className="lognex-sidemenu__menuItemLabelParent__icon">
                            <img src={IconFactory.getIcon(menuItem.iconName)} alt='Ícone menu'></img>
                        </span>
                        <span className="lognex-sidemenu__menuItemLabelParent__text">
                            {menuItem.label}
                        </span>
                    </p>
            } else {
                labelMenuItem =
                    <p className="lognex-sidemenu__menuItemLabelChild" key={index} onClick={(e) => { menuItem.command && menuItem.command(); this.toggleMenu(e) }}>
                        <span>
                            {menuItem.label}
                        </span>
                        <span className="lognex-sidemenu__menuItemLabelChild__arrowIcon">
                            {menuItem.items && <img src={IconFactory.getIcon('arrow')} alt='Seta'></img>}
                        </span>
                    </p>
            }

            return (
                <li key={index} className="lognex-sidemenu__item">
                    {labelMenuItem}
                    {menuItem.items && <ul className="lognex-subsidemenu"> {this.handleMountMenu(menuItem.items, level + 1)} </ul>}
                </li>
            )
        })
    }
    render() {
        const currentCompany = this.props.currentCompany;
        let logoPath = ''
        let companyName = ''
        if (currentCompany) {
            logoPath = currentCompany.caminhoLogo;
            companyName = currentCompany.nomeEmpresa;
        }
        const icon = IconFactory.getIcon(logoPath, false)
        const logo = icon ? <img src={icon} alt={companyName} title={companyName}></img> : ''
        return (
            <div className='lognex-sidemenu-wrapper'>
                <ul className="lognex-sidemenu">
                    {this.handleMountMenu(this.props.model)}
                </ul>
                <div className="lxLogoShipper" >
                    {logo}
                </div>
                <SocialMediaBar>

                </SocialMediaBar>
            </div>
        )
    }
}

