
import React from 'react';
import { InputText } from 'primereact/inputtext';
import { LookupField } from '../../../components/lookup/LookupField';

export const ProductForm = (props) => {
    console.log('ProductForm');
    const record = props.record;

    return (
        <div className="product-form">
            <div className="p-grid">
                <div className="p-col-6">
                    <div className="p-grid">

                        <div className="p-col-12 form-field-container">
                            <label>ID</label>
                            <InputText value={props.record.oid} onChange={props.changeHandler} name="oid" disabled={true} />
                        </div>
                        <div className="p-col-12 form-field-container">
                            <label>Código*</label>
                            <InputText value={props.record.code} onChange={props.changeHandler} name="code" disabled={false} />
                        </div>
                        <div className="p-col-12 form-field-container">
                            <label>Nome*</label>
                            <InputText value={props.record.name} onChange={props.changeHandler} name="name" disabled={false} />
                        </div>
                        <div className="p-col-12 form-field-container">
                            <label>Altura</label>
                            <InputText value={props.record.height} onChange={props.changeHandler} name="height" disabled={false} />
                        </div>
                        <div className="p-col-12 form-field-container">
                            <label>Largura</label>
                            <InputText value={props.record.width} onChange={props.changeHandler} name="width" disabled={false} />
                        </div>
                        <div className="p-col-12 form-field-container">
                            <label>Profundidade</label>
                            <InputText value={props.record.depth} onChange={props.changeHandler} name="depth" disabled={false} />
                        </div>
                        <div className="p-col-12 form-field-container">
                            <label>Peso</label>
                            <InputText value={props.record.weight} onChange={props.changeHandler} name="weight" disabled={false} />
                        </div>
                        <div className="p-col-12 form-field-container">
                            <label>Produto pai</label>
                            <LookupField
                                value={record.productParentName}
                                placeholder="Produto pai"
                                name="productParentName"
                                field="name"
                                onComplete={props.lookupProductParentOnComplete}
                                onInputFilter={props.lookupProductParentOnInputFilter}
                                editDisabled={props.lookupProductParent.editDisabled}
                                onEditField={props.lookupProductParentOnEdit}
                                onClick={props.lookupProductParentOnClick}

                                visible={props.lookupProductParent.visible}
                                modal={props.lookupProductParent.modal}
                                header={props.lookupProductParent.header}
                                onHide={props.lookupProductParentOnHide}
                                onConfirm={props.lookupProductParentOnConfirm}
                                onCancel={props.lookupProductParentOnCancel}
                                records={props.lookupProductParent.records}
                                columns={props.lookupProductParent.columns}
                                totalRecords={props.lookupProductParent.totalRecords}
                                sortOrder={props.lookupProductParent.order}
                                sortField={props.lookupProductParent.sortField}
                                first={props.lookupProductParent.first}
                                rows={props.lookupProductParent.rows}
                                onPage={props.lookupProductParentOnPage}
                                onFilter={props.lookupProductParentOnFilter}
                                onSort={props.lookupProductParentOnOnSort}

                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};