import React from "react";
import { Dialog } from "primereact/dialog";
import "./ButtonDialog.scss";

export class ButtonDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogVisible: false,
      preventDefault: props.preventDefault ? props.preventDefault : false,
      contador:10,
    };
  }


  setDialogVisible = dialogVisible => {
    this.setState(() => {
      return { dialogVisible };
    });
  };

  openDialog = e => {
    if (this.state.preventDefault) {
      e.preventDefault();
      e.stopPropagation();
    }

    if(this.props.counter) {
      this.setState({contador: 10});
      setTimeout(() => {
        this.decrementContador();     
      }, 1000)
    }

    this.setDialogVisible(true);
    this.props.onOpenDialog && this.props.onOpenDialog();
  };

  hideDialog = e => {
    if (e) {
      if (this.state.preventDefault) {
        e.preventDefault();
        e.stopPropagation();
      }
      this.setDialogVisible(false);
      if (this.props.alwaysExecute){
        this.props.onClick && this.props.onClick(e, false);
      }
      this.props.onHideDialog && this.props.onHideDialog();
    }
  };

  confirmDialog = e => {
    if (this.state.preventDefault) {
      e.preventDefault();
      e.stopPropagation();
    }
    this.setDialogVisible(false);
    this.props.onClick && this.props.onClick(e, true);
  };

  decrementContador = () => {
    let contador = this.state.contador - 1; 
    this.setState({ contador });
    if (contador > 0 ){
      setTimeout(()=>{
        this.decrementContador()
      },1000)
    }
  }
  
  render() {
    const { props } = this;
    return (
      <React.Fragment>
        <button
          className={props.button.className}
          onClick={props.dialog ? this.openDialog : props.onClick}
          title={props.button.title}
          style={
            props.button.style ? props.button.style : { marginRight: ".25em" }
          }
          disabled={props.button.disabled}
        >
          {!props.notShowIcon &&
            <i
              className={
                props.button && props.button.iconClassName
                  ? props.button.iconClassName
                  : "pi pi-trash"
              }
            ></i>
          }{props.button.text}
        </button>
        {props.dialog && <Dialog
          header={
            <div className="lxButtonDialogHeader">
              <div className="lxHeaderTitle">
                <i className="pi pi-exclamation-triangle lxAlertIcon"> </i>
                <h3>{props.dialog.header}</h3>
              </div>
              <button
                className="p-dialog-titlebar-icon p-link lxHeaderClose"
                onClick={this.hideDialog}
              >
                <span className="pi pi-times"></span>
              </button>
            </div>
          }
          closable={false}
          visible={this.state.dialogVisible}
          style={props.dialog.style}
          modal={props.dialog.modal}
          onHide={this.hideDialog}
          dismissableMask={true}
          footer={
            <div>
              <button
                title="Confirmar"
                className={"lognex-btn-highlighted"}
                onClick={this.confirmDialog}
                disabled={props.disabled || (this.props.counter && this.state.contador > 0) }
              >
              {props && props.counter  && this.state.contador > 0 ? <span><i className="pi pi-check"></i> {this.state.contador} </span>  : <i className="pi pi-check"></i> }  
              </button>
              <button
                title="Cancelar"
                className="lognex-btn-danger"
                onClick={this.hideDialog}
              >
                <i className="pi pi-times"></i>
              </button>
            </div>
          }
        >
          <h3> {props.dialog.message}</h3>
        </Dialog>}
      </React.Fragment>
    );
  }
}
