import { types } from "./vehicleType.actions";
import { Reducer } from "../../services/reducer.factory";

const initialState = {
  columns: [
    {
      field: "oid",
      header: "Código",
      columnKey: "oid",
      sortable: true,
      filter: true
    },
    {
      field: "code",
      header: "Label",
      columnKey: "code",
      sortable: true,
      filter: true
    },{
      field: "name",
      header: "Nome",
      columnKey: "name",
      sortable: true,
      filter: true
    },
    {
      field: "averageSpeed",
      header: "Velocidade média (KM)",
      columnKey: "averageSpeed",
      sortable: true,
      filter: true
    },
    {
      field: "weight",
      header: "Capacidade Peso",
      columnKey: "weight",
      sortable: true,
      filter: true
    },
    {
      field: "pallets",
      header: "Capacidade Pallets",
      columnKey: "pallets",
      sortable: true,
      filter: true
    },
    {
      field: "eixos",
      header: "Qtde Eixos",
      columnKey: "eixos",
      sortable: true,
      filter: true
    },
    
  ],
  currentRecord: {
    oid: "",
    name: "",
    code: "",
    averageSpeed: "",
    eixos: 1,
  },

  records: []
};

const reducer = new Reducer(initialState, types);

export function CRUDVehicleTypeReducer(state, action) {
  state = reducer.execute(state, action);
  return execute(state, action);
}

function execute(state, action) {
  return state;
}





