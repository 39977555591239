import React from 'react';
import TrackingListPageContainer from './TrackingListPageContainer';

export class TrackingListPage extends React.Component{
    
    render(){
      return (
        <TrackingListPageContainer {...this.props}/>
      )
    }
}