import React from 'react'
import { Card } from 'primereact/card'

export const FavoriteSection=(props)=>{
    return (
        <Card>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h2 id="favoritos"> Favoritos </h2>
            </div>
            <hr className="lxSplitter"></hr>
            <div className="p-grid">
                {props.favorites}
            </div>
        </Card>    
    )
}