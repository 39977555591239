import React from "react";
import { Column } from "primereact/column";
import { RouteEditTable } from "./RouteEditTable";
import { Dialog } from "primereact/dialog";
import { segParaHora } from "../../../../utils";
import "./editroute.scss";
import { ButtonDialog } from "../../../../components/button-dialog/ButtonDialog";
import { Dropdown } from "primereact/dropdown";
export default class RouteEditTablePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      virtualCD: {
        cd: "",
        location: [],
        label: "",
        documentNumber: ""
      },
    };
  }
  onChangeSelectedCD = (e) => {
    console.log("onChangeSelectedCD e=", e);
    this.props.setSelectecVirtualCD(e.value);
  };

  render() {
    const footer = (
      <div>
        <ButtonDialog
          onClick={this.props.onConfirm}
          button={{
            title: "Confirmar",
            disabled: !this.props.selectedRouterIncludDelivery,
            className: "lognex-btn-highlighted",
            iconClassName: "pi pi-check",
          }}
          dialog={{
            header: "Confirmação",
            modal: false,
            message: "Deseja realmente incluir as ordens da rota?",
          }}
        ></ButtonDialog>

        <button title="Cancelar" className="lognex-btn-danger" onClick={this.props.onHide}>
          <i className="pi pi-times"></i>
        </button>
      </div>
    );
    const templateDuration = (rowData, e) => {
      return <span>{segParaHora(rowData.duration)}</span>;
    };

    const templateDistanceKm = (rowData, e) => {
      return <span>{rowData.distanceKm} Km</span>;
    };
    const templateRouteColor = (rowData, e) => {
      return <div style={{ backgroundColor: rowData.routeColor, height: "20px" }}></div>;
    };

    let columns = [<Column key={"routeEditRoute"} selectionMode="single" style={{ width: "3em" }} />];
    columns = [
      ...columns,
      ...this.props.routesColumns
        .filter((col) => col.field != "btnRemoveRoute")
        .map((col) => {
          if (col.field == "oid") {
            return <Column {...col} key={col.field} style={{ fontSize: "11px", width: "60px" }} />;
          } else if (col.field == "distanceKm") {
            return <Column {...col} key={col.field} style={{ fontSize: "11px" }} body={templateDistanceKm} />;
          } else if (col.field == "duration") {
            return <Column {...col} key={col.field} style={{ fontSize: "11px", width: "110px" }} body={templateDuration} />;
          } else if (col.field == "routeColor") {
            return <Column {...col} key={col.field} style={{ fontSize: "11px", width: "40px" }} body={templateRouteColor} />;
          }
          return <Column {...col} key={col.field} style={{ fontSize: "11px" }} />;
        }),
    ];
    const onSelectedEventsChange = (e) => {
      e.originalEvent.preventDefault();
      e.originalEvent.stopPropagation();
      this.props.onSelectedEventsChange(e.value);
    };
    const rowClickHandler = (e) => {
      e.originalEvent.preventDefault();
      e.originalEvent.stopPropagation();
      if (this.props.selectedRouterIncludDelivery && this.props.selectedRouterIncludDelivery.oid === e.data.oid) {
        this.props.onSelectedEventsChange(null);
      } else {
        this.props.onSelectedEventsChange(e.data);
      }
    };
    const getRenderDeliveriesSelected = () => {
      if (this.props.ordersNotScriptedSeletected && this.props.ordersNotScriptedSeletected.length > 0) {
        return this.props.ordersNotScriptedSeletected.map((d) => {
          return (
            <h3 key={`deliv-${d.code}`} className="delivery">
              {d.code}
            </h3>
          );
        });
      }
      return <></>;
    };

    const virtualCDs = [];
    if (this.props.selectedRouterIncludDelivery) {
      //recuperar os CDs da rota selecionada
      this.props.selectedRouterIncludDelivery.shipments.forEach((ship) => {
        if (ship.type !== "pontoApoio") {
          if (virtualCDs.filter((vcd) => vcd.cd === ship.pickup.virtualCD && vcd.documentNumber === ship.pickup.documentNumber).length == 0) {
            virtualCDs.push({
              cd: ship.pickup.virtualCD,
              location: ship.pickup.location,
              documentNumber: ship.pickup.documentNumber,
              key:ship.pickup.virtualCD + "-" + ship.pickup.documentNumber,
              label: (ship.sender && ship.sender.label) || ship.pickup.virtualCD + "-" + ship.pickup.documentNumber,
            });
          }
        }
      });
      //adicionar os CDs das novas ordens, q serão inclusas
      this.props.ordersNotScriptedSeletected.forEach((ship) => {
        if (ship.type !== "pontoApoio") {
          if (virtualCDs.filter((vcd) => vcd.cd === ship.pickup.virtualCD && vcd.documentNumber === ship.pickup.documentNumber).length == 0) {
            virtualCDs.push({
              cd: ship.pickup.virtualCD,
              location: ship.pickup.location,
              documentNumber: ship.pickup.documentNumber,
              key:ship.pickup.virtualCD + "-" + ship.pickup.documentNumber,
              label: (ship.sender && ship.sender.label) || ship.pickup.virtualCD + "-" + ship.pickup.documentNumber,
            });
          }
        }
      });
    }

    let records = this.props.records || [];

    // const records = [];
    // recordsOrder.forEach((o) => {
    //   if (
    //     this.props.usersSelected &&
    //     this.props.usersSelected.filter((u) => String(u.user).toUpperCase() == String(o.owner.user).toUpperCase()).length > 0
    //   ) {
    //     records.push(o);
    //   }
    // });

    return (
      <Dialog
        className="content"
        header={"Incluir ordem em rotas existente"}
        footer={footer}
        visible={this.props.visible}
        style={this.props.style || { width: "50vw" }}
        modal={true}
        onHide={this.props.onHide}
      >
        <div style={{ minHeight: "350px" }}>
          <div className="p-col-12 form-field-container">
            <span className="deliveries-title">{"Ordens à ser inclusa em uma ROTA abaixo"}</span>
            <div className="deliveries">{getRenderDeliveriesSelected()}</div>
          </div>
          <div className="p-col-12 form-field-container">
            <label>Rotas em aberto</label>
            <RouteEditTable
              records={records}
              rowClickHandler={rowClickHandler}
              onSelectedEventsChange={onSelectedEventsChange}
              selectedRouterIncludDelivery={this.props.selectedRouterIncludDelivery}
              columns={columns}
            />
          </div>

          <div className="p-col-12 form-field-container">
            <h3>Se desejar, depois que selecionar a rota, escolha o Centro de distribuição(CD) de partida para o veículo</h3>
          </div>
          <div className="p-col-6 form-field-container">
            <label>Centro de Distribuição</label>
            <Dropdown
              placeholder="Selecione"
              value={this.props.startLocation}
              dataKey={"key"}
              key={Math.random()}
              optionLabel="label"
              options={virtualCDs}
              onChange={this.onChangeSelectedCD}
            />
          </div>
        </div>
      </Dialog>
    );
  }
}
