
import React from 'react';
import { connect } from 'react-redux';
import { Column } from 'primereact/column';
import Toolbar from "../../../components/toolbar/Toolbar";
import { actions } from '../enchentesRS.actions';
import { routes } from '../../../constants';
import { Page } from "../../../components/page/Page";
import { EnchentesRSForm } from './EnchentesRSForm';


const mapStateToProps = state => {
  const record = state.crudEnchentesRSState.currentRecord;
  return {
    record
  }
}

const mapDispatchToProps = dispatch => {
  return {

    setField: (field, value) => dispatch(actions.setField(field, value)),
    changeHandler: event => {
      const target = event.target;
      const field = target.name;
      let value = target.value;
      dispatch(actions.removeErrors([{ field }]));
      dispatch(actions.setField(field, value));
    },
    changeVehicleTypeHandle: e =>{
      dispatch(actions.addVehicleTypeReleased(e.value))
    },
    changeBlockPartialHandle: e =>{
      dispatch(actions.changeBlockPartialHandle(e.value))
    },
    changeBlockTotalHandle: e =>{
      dispatch(actions.changeBlockTotalHandle(e.value))
    },
    changeBlockTypeHandle: e =>{
      dispatch(actions.changeBlockType(e.value))
    },
    changeVehicleHandle: e =>{
      dispatch(actions.changeVehicle(e.value))
    },
    saveHandler: event => {
      dispatch(actions.save());
    },
    removeHandler: event => {
      dispatch(actions.remove());
    },
    newHandler: event => {
      dispatch(actions.makeNew());
    },
    loadById: oid => dispatch(actions.loadById(oid))
  }
}

class EnchentesRSFormPageContainer extends React.Component {
// IMPORTANT!!
  constructor(props) {
    super(props);
    actions.afterSave = ({ oid }) => {
      this.props.history.replace(`${routes.PATH_CADENCHENTESRS}/${oid}`);
    };
    actions.afterNew = () => {
      this.props.history.replace(`${routes.PATH_CADENCHENTESRS}/novo`);
    };
    actions.afterRemove = () => {
      this.props.history.replace(`${routes.PATH_CADENCHENTESRS}`);
    };
  }

  componentDidMount() {
    const oid = this.props.match.params.id;

    if (oid && parseInt(oid)) {
      this.props.loadById(oid);
    } else {
      this.props.newHandler();
    }
  }
  searchHandler = () => {
    this.props.history.push(`${routes.PATH_CADENCHENTESRS}`)
  }


  render() {
    const content = (
      <EnchentesRSForm
        changeHandler={this.props.changeHandler}
        changeBlockPartialHandle={this.props.changeBlockPartialHandle}
        record={this.props.record}
        vehicleTypeReleasedHandle={this.props.changeVehicleTypeHandle}
        changeBlockTypeHandle={this.props.changeBlockTypeHandle}
        changeBlockTotalHandle={this.props.changeBlockTotalHandle}
        changeVehicleHandle={this.props.changeVehicleHandle}
      />
    );
    const title = `Bloqueio RS ${this.props.record.oid || 'novo'}`
    const header = (
      <div>
        <h1>{title}</h1>
        <div>
          <Toolbar
            onSave={this.props.saveHandler} saveButtonStyle='highlighted'
            onRemove={this.props.removeHandler} removeButtonStyle='danger'
            onNew={this.props.newHandler}
            onSearch={this.searchHandler}>
          </Toolbar>
        </div>
      </div>
    );
    return (<Page header={header} content={content} />);
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EnchentesRSFormPageContainer);