import React from 'react';
import { connect } from 'react-redux';
import { Column } from 'primereact/column';
import Toolbar from "../../../components/toolbar/Toolbar";
import { actions } from '../unidade.actions';
import { actions as pessoaActions } from '../../pessoa/pessoa.actions';
import { actions as empresaActions } from '../../empresa/empresa.actions';
import { routes } from '../../../constants';
import { Page } from "../../../components/page/Page";
import { UnidadeForm } from './UnidadeForm';


const mapStateToProps = state => {
    const record = state.crudUnidadeState.currentRecord;
    
    const lookupEmpresaBase = {
        records: state.crudEmpresaState.records,
        columns: state.crudEmpresaState.columns
            .filter(col => col.field === 'oid' || col.field === 'tradeName')
            .map(col => <Column key={col.field} {...col} />),
        first: state.crudEmpresaState.index,
        rows: state.crudEmpresaState.max,
        totalRecords: state.crudEmpresaState.count,
        sortField: state.crudEmpresaState.sortField,
        order: state.crudEmpresaState.sortOrder,
        editDisabled: !record.oidEmpresa
    } 
    let lookupEmpresa = Object.assign({}, state.crudUnidadeState.lookupEmpresa, lookupEmpresaBase);

    return {
        record, 
        lookupEmpresa
    }
}

const mapDispatchToProps = dispatch => {
    return {
        lookupPessoaSetVisible: visible => dispatch(actions.setLookupPessoaVisible(visible)),
        lookupPessoaPaginate: paginationParams => dispatch(pessoaActions.paginate(paginationParams)),
        lookupPessoaSortOrder: sortParams => dispatch(pessoaActions.sortOrder(sortParams)),
        lookupPessoaApplyFilter: filterParams => {
            dispatch(pessoaActions.setType(null));
            dispatch(pessoaActions.applyFilter(filterParams))
        },
        lookupPessoaLoad: () => dispatch(pessoaActions.load()),
        
        lookupEmpresaSetVisible: visible => dispatch(actions.setLookupEmpresaVisible(visible)),
        lookupEmpresaPaginate: paginationParams => dispatch(empresaActions.paginate(paginationParams)),
        lookupEmpresaSortOrder: sortParams => dispatch(empresaActions.sortOrder(sortParams)),
        lookupEmpresaApplyFilter: filterParams => {
            dispatch(empresaActions.setType(null));
            dispatch(empresaActions.applyFilter(filterParams))
        },
        lookupEmpresaLoad: () => dispatch(empresaActions.load()),
        setEmpresa: (empresa) => {
            dispatch(actions.setField('oidCompany', empresa != null ? empresa.oid : null));
            dispatch(actions.setField('companyName', empresa != null ? empresa.tradeName : ''));
        },
        changeHandler: event => {
            const target = event.target;
            const field = target.name;
            let value = target.value;
            dispatch(actions.removeErrors([{ field }]));
            
            dispatch(actions.setField(field, value));
        },
        saveHandler: event => {
            dispatch(actions.save());
        },
        removeHandler: event => {
            dispatch(actions.remove());
        },
        newHandler: event => {
            dispatch(actions.makeNew());
        },
        clearForm: () => dispatch(actions.makeNew()),
        loadById: oid => dispatch(actions.loadById(oid)),
        setField: (field, value) => dispatch(actions.setField(field, value))
    }
}

class UnidadeFormPageContainer extends React.Component {

    constructor(props) {
        super(props);
        actions.afterSave = ({ oid }) => {
            this.props.history.replace(`${routes.PATH_UNIDADE}/${oid}`);
        };
        actions.afterNew = () => {
            this.props.history.replace(`${routes.PATH_UNIDADE}/novo`);
        };
        actions.afterRemove = () => {
            this.props.history.replace(`${routes.PATH_UNIDADE}`);
        };
    }

    componentDidMount() {
        const oid = this.props.match.params.id;

        if (oid && parseInt(oid)) {
            this.props.loadById(oid);
        } else {
            this.props.newHandler();
        }
    }
    searchHandler = () => {
        this.props.history.push(`${routes.PATH_UNIDADE}`)
    }


     

    lookupEmpresaOnPage = (e) => {
        this.props.lookupEmpresaPaginate({
            max: e.rows,
            index: e.first
        })
    }
    lookupEmpresaOnSort = (e) => {
        this.props.lookupEmpresaSortOrder({
            sortOrder: e.sortOrder,
            sortField: e.sortField
        })


    }
    lookupEmpresaOnFilter = (e) => {
        const filters = [];
        const keys = Object.keys(e.filters) || [];
        keys.forEach(key => {
            filters.push({
                campo: key,
                valor: e.filters[key].value,
                tipoFiltro: 'CONTEM'

            })
        })
        this.props.lookupEmpresaApplyFilter(filters)

    }
    lookupEmpresaOnClick = (e) => {
        this.props.lookupEmpresaLoad();
        this.props.lookupEmpresaSetVisible(true);
    }
    lookupEmpresaOnHide = (e) => {
        this.props.lookupEmpresaSetVisible(false);
    }
    lookupEmpresaOnConfirm = (e) => {
        this.props.setEmpresa(e.selection);
        this.props.lookupEmpresaSetVisible(false);

    }
    lookupEmpresaOnCancel = (e) => {
        this.props.setEmpresa(null);
        this.props.lookupEmpresaSetVisible(false);
    }
 
    lookupEmpresaOnEdit = () => {
        if (this.props.record.oidPessoa) {
            this.props.history.push(`${routes.PATH_EMPRESA}/${this.props.record.oidEmpresa}`);
        }
    }
    lookupEmpresaOnComplete = (e) => {
    }

    lookupEmpresaOnInputFilter = (e) => {
        const value = e.value;
        if (typeof value === 'string') {
            const filters = [{
                campo: "nomeEmpresa",
                valor: value,
                tipoFiltro: 'CONTEM'

            }];
            this.props.setField("nomeEmpresa", value);
            this.props.setField("oidEmpresa", null);
            this.props.lookupEmpresaApplyFilter(filters)
        } else {
            this.props.setEmpresa(value);
        }
    }



    render() {
        const content = (
            <UnidadeForm
                changeHandler={this.props.changeHandler}
                record={this.props.record} 

                lookupEmpresa={this.props.lookupEmpresa}
                lookupEmpresaOnHide={this.lookupEmpresaOnHide}
                lookupEmpresaOnConfirm={this.lookupEmpresaOnConfirm}
                lookupEmpresaOnCancel={this.lookupEmpresaOnCancel}
                lookupEmpresaOnPage={this.lookupEmpresaOnPage}
                lookupEmpresaOnSort={this.lookupEmpresaOnSort}
                lookupEmpresaOnFilter={this.lookupEmpresaOnFilter}
                lookupEmpresaOnClick={this.lookupEmpresaOnClick}
                lookupEmpresaOnEdit={this.lookupEmpresaOnEdit}
                lookupEmpresaOnComplete={this.lookupEmpresaOnComplete}
                lookupEmpresaOnInputFilter={this.lookupEmpresaOnInputFilter}

            />
        );
        const title = `Unidade ${this.props.record.oid || 'nova'}`
        const header = (
            <div>
                <h1>{title}</h1>
                <hr className="lxSplitter" ></hr>
                <div>
                    <Toolbar
                        onSave={this.props.saveHandler} saveButtonStyle='highlighted'
                        onRemove={this.props.removeHandler} removeButtonStyle='danger'
                        onNew={this.props.newHandler}
                        onSearch={this.searchHandler}>
                    </Toolbar>
                </div>
            </div>
        );
        return (<Page header={header} content={content} />);
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UnidadeFormPageContainer);