
import React from 'react';
import { connect } from 'react-redux';
import { actions } from '../../../portalCarrier.actions';
import { Page } from "../../../../../components/page/Page";
import Toolbar from '../../../../../components/toolbar/Toolbar';
import { routes } from '../../../../../constants';
import { Column } from 'primereact/column';
import { CadastroVeiculosList } from './cadastroVeiculosList';
import { numberToCurrency } from '../../../../../utils';
import storage from '../../../../../services/stoage.service'
import { AppService, COMPANY_CHANGE_EVENT_NAME } from '../../../../../services/app.service';
import { CADASTRO_VEICULO, CARGO_IN_COLETA } from '../../../constantes';
import moment from 'moment';
import { ButtonDialog } from '../../../../../components/button-dialog/ButtonDialog';
import { InputText } from 'primereact/inputtext';
import "./cadastra-veiculo.scss"
import { messageActions } from '../../../../../store/message';

const mapStateToProps = state => {
  const records = state.crudPortalCarrierState.cadastroVeiculos;

  let columns = [];

  const user = storage.getUser();

  if (!user.carrier) {
    columns = [...state.crudPortalCarrierState.columnsCadastroVeiculos, {
      field: "carrier.name",
      header: "Transportadora",
      columnKey: "carrier.name",
      sortable: false,
      filter: false
    }]
  } else {
    columns = [...state.crudPortalCarrierState.columnsCadastroVeiculos]
  }

  return {
    records,
    columns,
    //devera ser limpo a cada troca de tela
    first: state.crudPortalCarrierState.index,
    rows: state.crudPortalCarrierState.max,
    totalRecords: state.crudPortalCarrierState.count,
    sortField: state.crudPortalCarrierState.sortField,
    order: state.crudPortalCarrierState.sortOrder,

    cadVeiculoPlate: state.crudPortalCarrierState.cadVeiculoPlate,
    cadVeiculoDescription: state.crudPortalCarrierState.cadVeiculoDescription
  }
}

const mapDispatchToProps = dispatch => {
  return {

    paginate: paginationParams => {
      if (paginationParams.index !== undefined) {
        dispatch(actions.setIndex(paginationParams.index));
      }
      if (paginationParams.page !== undefined) {
        dispatch(actions.setPage(paginationParams.page));
      }
      if (paginationParams.max !== undefined) {
        dispatch(actions.setMax(paginationParams.max));
      }
    },
    cleanPaginate: () => {
      dispatch(actions.setIndex(0));
      dispatch(actions.setPage(0));
      dispatch(actions.setMax(50));
    },

    setCurrentScreen: (currentScreen) => {
      dispatch(actions.setCurrentScreen(currentScreen));
    },

    changeStatusCargoShipmentToTransito: (oid) => dispatch(actions.changeStatusCargoShipmentToTransito(oid)),

    openDialogIframeCollectionOrder: (visible, link) => {
      dispatch(actions.openDialogIframeCollectionOrder({ visible, link }));
    },
    showMessageErro: (msgs) => {
      actions.messageActions.messageShowMessages(
        actions.makeMessages(msgs, "error")
      )
    },

    setCadVeiculoPlate: (plate) => dispatch(actions.setCadVeiculoPlate(plate)),
    setCadVeiculoDescricao: (description) => dispatch(actions.setCadVeiculoDescricao(description)),
    salvarNovaPlaca: () => dispatch(actions.salvarNovaPlaca()),
    getAllVehicles: () => dispatch(actions.getAllVehicles()),
  }
}

class CadastroVeiculosPageContainer extends React.Component {

  constructor(props) {
    super(props);

  }

  componentDidMount() {
    AppService.on(COMPANY_CHANGE_EVENT_NAME, this);
    this.props.setCurrentScreen(CADASTRO_VEICULO);
    this.props.cleanPaginate();
    this.props.getAllVehicles();
  }

  componentWillUnmount() {
    AppService.off(COMPANY_CHANGE_EVENT_NAME, this);
    this.props.cleanPaginate();
  }

  onReceiveAppNotifications = (eventName, params) => {
    if (eventName === COMPANY_CHANGE_EVENT_NAME) {
      this.props.setCurrentScreen(CADASTRO_VEICULO);
      this.props.cleanPaginate();
      this.props.getAllVehicles();
    }
  };

  searchHandler = () => {
    this.props.history.push(`${routes.PATH_CARRIER_PORTAL}`)
  }

  onPage = (e) => {
    this.props.paginate({
      max: e.rows,
      index: e.first
    })
    this.props.getAllVehicles();
  }

  changePlateHandler = (e) => {
    try {
      const plate = String(e.target.value).trim().toUpperCase();
      this.props.setCadVeiculoPlate(plate);
    } catch (error) {
      console.log(error)
    }
  }

  changeDescriptionHandler = (e) => {
    try {
      const description =  e.target.value;
      this.props.setCadVeiculoDescricao(description);
    } catch (error) {
      console.log(error)
    }
  }
  cadastrarPlaca = () => {
    if (String(this.props.cadVeiculoPlate).trim().length == 7) {
      this.props.salvarNovaPlaca();
    } else {
      this.props.showMessageErro(["Informe uma placa válida!"])
    }
  }
  render() {

    const header = (
      <div>
        <h1>Veículos</h1>
        <hr className="lxSplitter" ></hr>
        <div>
          <Toolbar onSearch={this.searchHandler} />
        </div>
      </div>
    );
    const btnToTransitoTemplate = (rowData, column) => {
      try {
        const isChangeStatus = rowData.isChangeStatus
        let titulo = "Altere o status do embarque para em trânsito, obrigado!"
        if (!isChangeStatus) {
          titulo = `Ainda não chegou todas as Nfe do embarque`
        }
        return (
          <div style={{ textAlign: "center" }}>
            <ButtonDialog
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                this.props.changeStatusCargoShipmentToTransito(rowData.oid)
                //  
              }}
              button={{
                title: titulo,
                className: "lognex-btn-highlighted",
                iconClassName: "pi pi-check",
                disabled: !isChangeStatus
              }}
              dialog={{
                header: "Confirmação",
                modal: false,
                message: "Deseja realmente alterar o status do embarque para em Trânsito ?",
              }}
            ></ButtonDialog>

          </div>
        );
      } catch (error) {
        return <div></div>;
      }
    };

    const dateCreateTemplate = (rowData, column) => {
      const date = rowData.eventRecord.createdAt;
      const formattedDate = moment(date).format("DD/MM/YYYY");
      return <div style={{ textAlign: "center", color: "black" }}>{formattedDate}</div>;
    };

    const columns = this.props.columns.map((col) => {

      if (col.field === "oid") {
        return <Column {...col} key={col.field} style={{ width: "70px" }} />;
      }

      if (col.field === "eventRecord.createdAt") {
        return <Column {...col} key={col.field} style={{ width: "130px" }} body={dateCreateTemplate} />;
      }
      
      if (col.field === "darAceite") {
        return <Column {...col} key={col.field} style={{ width: "80px" }} body={btnToTransitoTemplate} />;
      }

      return <Column {...col} key={col.field} />;
    });

    const content = (
      <div className="p-grid portal-cadastra-veiculo-form">
        <div className="p-col-6">
          <h3 style={{ marginLeft: "5px" }}>Gerêncie seu veículos</h3>
          <div className="p-grid  ">

            <div className="p-col-3 form-field-container">
              <label>Placa</label>
              <InputText value={this.props.cadVeiculoPlate} maxLength={7} onChange={this.changePlateHandler} />
            </div>
            <div className="p-col-6 form-field-container">
              <label>Descrição</label>
              <InputText value={this.props.cadVeiculoDescription} maxLength={20} onChange={this.changeDescriptionHandler} />
            </div>
            <div className="p-col-3 form-field-container align-bottom">

              <button className={"lognex-btn-highlighted"} onClick={() => { this.cadastrarPlaca(); }} title="Cadastra nova placa">
                <i className="pi pi-plus"></i>
              </button>
            </div>

          </div>

          <br />

          <div className="p-grid  ">

            <div className="p-col-12">
              <h3 style={{ marginLeft: "5px" }}>Veículos cadastrados</h3>
            </div>

            <div className="p-col-12">
              <CadastroVeiculosList
                records={this.props.records}
                onPage={this.onPage}
                first={this.props.first}
                rows={this.props.rows}
                totalRecords={this.props.totalRecords}
                sortField={this.props.sortField}
                order={this.props.order}
                columns={columns} />
            </div>
          </div>
        </div>
      </div>
    );

    return (<Page header={header} content={content} />);
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CadastroVeiculosPageContainer);