import React from 'react'
import { DataTable } from 'primereact/datatable'

export const LxPanelRestrictionsByComponent = (props) => {
    const datatable = {
        selection: props.dataTable && props.dataTable.currentSelectedComponent ? props.dataTable.currentSelectedComponent : [],
        value: props.dataTable && props.dataTable.restrictionsRecords ? props.dataTable.restrictionsRecords : [],
        sortOrder: props.dataTable && props.restrictionsOrder ? props.restrictionsOrder : 0,
        sortField: props.dataTable && props.restrictionsSortField ? props.restrictionsSortField : "",
        paginator: true,        
        rows: props.dataTable && props.dataTable.rows ? props.dataTable.rows : 0,
        onSelectionChange: props.dataTable && props.dataTable.actions && props.dataTable.actions.onSelectionChangeRestrictionsComponents ? props.dataTable.actions.onSelectionChangeRestrictionsComponents : props.dataTable.actions.onSelectionChangeRestrictionsComponents,
        onFilter: props.dataTable && props.dataTable.onFilterRestrictions && props.dataTable.actions.onFilterRestrictions ? props.dataTable.actions.onFilterRestrictions : () => { console.log("datatable.onFilterRestrictionsByComponent must be implemented") },
        onSort: props.dataTable && props.dataTable.onSortRestrictions && props.dataTable.actions.onSortRestrictions ? props.dataTable.actions.onSortRestrictions : () => { console.log("datatable.onSortRestrictionsByComponent must be implemented") },
        emptyMessage: "Não foram encontrados registros",
        rowsPerPageOptions: [5, 10, 20, 50],
        selectionMode: "single",        
    }

    return (
        <div className="p-grid lxPanelRestrictionsByComponent">
            <div className="p-col-12">
                <button
                    name="addRestrictionsByComponent"
                    style={{ marginRight: ".25em", marginBottom: ".25em" }}
                    className="lognex-btn-highlighted"
                    disabled={props.disabled}
                    onClick={props.dialogAddRestrictionsOpen}>                    
                    <i className="pi pi-plus"></i>
                </button>
                <button
                    style={{ marginRight: ".25em", marginBottom: ".25em" }}
                    className="lognex-btn-highlighted"
                    disabled={props.editButtonDisabled}
                    onClick={props.dialogEditRestrictionsOpen}>                    
                    <i className="pi pi-pencil"></i>
                </button>
                <DataTable {...datatable}>
                    {props.dataTable.restrictionColumns}
                </DataTable>
            </div>
        </div>
    )
}