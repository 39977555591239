import React from "react";
import { Dialog } from "primereact/dialog";
import { DataTable } from 'primereact/datatable';

export default function LogsModal(props) {
  
  return (
    <Dialog
    header={props.header}
    visible={props.visible}
    style={{ width: '50vw' }}
    modal={props.modal}
    baseZIndex={1}
    onHide={props.hideLogsHandler}
    footer={<div></div>}>
    <DataTable
        selectionMode="single"
        value={props.logRecords}
        emptyMessage="Não foram encontrados registros"
        resizableColumns={true}
        scrollable={true}
        scrollHeight="640px"
    >
        {props.logTbColumns}
    </DataTable>
</Dialog>
  );
}
