import { validate } from './validations';
import { build } from '../../services/crudFactory';
import { Action } from '../../services/actions.factory';
const BASE_PATH = '/lognex/avoid_location';
const crud = build(validate, BASE_PATH);
const prefix = 'crudEnchentesRS';

const actions = new Action(prefix, crud);

const newTypes = {
    ADD_VEHICLE_TYPE_RELEASE: `${prefix}addVehicleTypeReleased`,
    CHANGE_BLOCK_PARTIAL_HANDLE: `${prefix}changeBlockPartialHandle`,
    CHANGE_BLOCK_TOTAL_HANDLE: `${prefix}changeBlockTotalHandle`,
    CHANGE_BLOCK_TYPE: `${prefix}changeBlockType`,
    CHANGE_VEHICLE: `${prefix}changeVehicle`,
    
}

const types = Object.assign({}, actions.types, newTypes);

actions.addVehicleTypeReleased = (vehicleType) => {
    return {
        type: types.ADD_VEHICLE_TYPE_RELEASE,
        vehicleType
    }
}
actions.changeBlockPartialHandle = (blockPartial) => {
    return {
        type: types.CHANGE_BLOCK_PARTIAL_HANDLE,
        blockPartial
    }
}
actions.changeBlockTotalHandle = (blockTotal) => {
    return {
        type: types.CHANGE_BLOCK_TOTAL_HANDLE,
        blockTotal
    }
}
actions.changeBlockType = (blockType) => {
    return {
        type: types.CHANGE_BLOCK_TYPE,
        blockType
    }
}

export { types, actions };
