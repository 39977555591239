import React from "react";
import moment from "moment";
import { Dialog } from "primereact/dialog";
import { formatNumber, numberToCurrency } from "../../../../utils";
import './InfoDocument.scss';

export default function InfoDocument(props) {

  let origin = ""
  if (props.document.origin && props.document.origin.city !== "" && props.document.origin.state !== "") {
    origin = props.document.origin.city +" - " +props.document.origin.state
  }

  let destiny = ""
  if (props.document.destiny && props.document.destiny.city !== "" && props.document.destiny.state !== "") {
    destiny = props.document.destiny.city +" - " +props.document.destiny.state
  }


  let bodyNFE = ""
  let bodyCTE = ""
  let bodyINVOICE = ""
  let bodyCargoShipment = ""
  if(props.document.type === "nfe" ){   // ********** dados da NFE
    bodyNFE = (
      <div>
        {props.document.number ? (
          <div>
            <div className="text"> <span className="title">Número: </span> {props.document.number} </div>
            <div className="text">  <span className="title">Série: </span> {props.document.sequence} </div>
          </div>
        ) : ""}
        
        {props.document.key ? (
          <div className="text"><span className="title">Chave: </span> {props.document.key}</div>
        ) : "" }
  
        {props.document.carrier ? (
          <div>
            <div className="text"><span className="title">Transportadora: </span> {props.document.carrier.name || ""}</div>
            <div className="text"><span className="title">CNPJ: </span> {props.document.carrier.cnpj || ""}</div>
          </div>        
        ): ""}
        
        {props.document.origin ? (
          <div>
            <div className="text"><span className="title">Origin: </span> {origin}</div>
            <div className="text"><span className="title">Cep: </span> {props.document.origin.zipCode}</div>
          </div>
        ) : ""}
        
        {props.document.destiny ? (
          <div>
            <div className="text"><span className="title">Destino: </span> {destiny}</div>
            <div className="text"><span className="title">Cep: </span> {props.document.destiny.zipCode}</div>
          </div>
        ) : ""}
        
        {props.document.totalValue ? (
          <div className="text"><span className="title">Valor: </span> {numberToCurrency(props.document.totalValue, "REAL") || 0}</div>
        ) : ""}
        
        {props.document.weight ? (
          <div className="text"><span className="title">Peso: </span> {formatNumber(props.document.weight,3) || 0}</div>
        ) : ""}
         
        {props.document.cubage ? (
          <div className="text"><span className="title">Cubagem: </span> {formatNumber(props.document.cubage,3) || 0}</div>
        ) : ""}
        
        {props.document.date ? (
          <div className="text"><span className="title">Data: </span> {  props.document.date || "" }</div>
        ) : ""}
        
        {props.document.dueDate ? (
          <div className="text"><span className="title">Data do Vencimento: </span> { props.document.dueDate || "" }</div>
        ) : ""}
      </div>
    )
  } else if (props.document.type === "cte") {  // ********** dados do CTE
    const nfeKeys = props.document && props.document.nfeKeys && props.document.nfeKeys.map(item => {
      return <div className="text"><span className="title">Chave: </span> {item}</div>
    }) 
    bodyCTE = (
      <div>
        {props.document.number ? (
          <div>
            <div className="text"><span className="title">Número: </span> {props.document.number} </div> 
            <div className="text"><span className="title">Série: </span>{props.document.serie}</div>
          </div>
        ) : ""}
        
        {props.document.key ? (
          <div className="text"><span className="title">Chave: </span> {props.document.key}</div>
        ) : "" }
  
        {props.document.carrier ? (
          <div>
            <div className="text"><span className="title">Transportadora: </span> {props.document.carrier.name || ""}</div>
            <div className="text"><span className="title">CNPJ: </span> {props.document.carrier.cnpj || ""}</div>
          </div>        
        ): ""}
        
        {props.document.origin ? (
          <div>
            <div className="text"><span className="title">Origin: </span> {origin}</div>
            <div className="text"><span className="title">Cep: </span> {props.document.origin.zipCode}</div>
          </div>
        ) : ""}
        
        {props.document.destiny ? (
          <div>
            <div className="text"><span className="title">Destino: </span> {destiny}</div>
            <div className="text"><span className="title">Cep: </span> {props.document.destiny.zipCode}</div>
          </div>
        ) : ""}
        
        {props.document.totalValue ? (
          <div className="text"><span className="title">Valor: </span> {numberToCurrency(props.document.totalValue, "REAL") || 0}</div>
        ) : ""}
        
        {props.document.weight ? (
          <div className="text"><span className="title">Peso: </span> {formatNumber(props.document.weight) || 0}</div>
        ) : ""}
        
        {props.document.nfeKeys ? (
           <div>
           <div className="text"><span className="title">NFes: </span></div>
           <div className="list_internal_doc">
             {nfeKeys} 
           </div>
         </div>
        ) : ""}
        
      </div>
    )

  } else if (props.document.type === "invoice") { // ********** dados da INVOICE
    const ctes = props.document && props.document.ctes && props.document.ctes.map(item => {
      return <div className="text"> <span className="title">Número:</span> {item.number} <span className="title">Série: </span>{item.serie} </div>
    }) 
    bodyINVOICE = (
      <div>
        {props.document.number && props.document.serie ? (
          <div>
          <div className="text"><span className="title">Número: </span> {props.document.number} </div> 
          <div className="text"><span className="title">Série: </span>{props.document.serie}</div>
        </div>
        ) : ""}     
  
        {props.document.carrier ? (
          <div>
            <div className="text"><span className="title">Transportadora: </span> {props.document.carrier.name || ""}</div>
            <div className="text"><span className="title">CNPJ: </span> {props.document.carrier.cnpj || ""}</div>
          </div>        
        ): ""}       
  
        {props.document.total ? (
          <div className="text"> <span className="title">Valor: </span> {numberToCurrency(props.document.total, "REAL") || 0} </div>
        ) : ""}
  
  
        {props.document.date ? (
          <div className="text"><span className="title">Data:</span> { moment(props.document.date).format("DD/MM/YYYY") || "" }</div>
        ) : ""}
        
        {props.document.dueDate ? (
          <div className="text"><span className="title">Data do Vencimento:</span> { moment(props.document.dueDate).format("DD/MM/YYYY") || "" }</div>
        ) : ""}

        { props.document.ctes ? (
          <div>
            <div className="text"><span className="title">CTEs: </span></div>
            <div className="list_internal_doc">
              {ctes}    
            </div>
          </div>
        ) : ""}
  
      </div>
    );

  }else if (props.document.type === "cargoShipment") {   // ********** dados do EMBARQUE
    
    bodyCargoShipment = (
      <div>

        {props.document.origin && props.document.origin.name ? (
          <div>
            <div className="text"><span className="title">Origen: </span> {props.document.origin.name || "" }</div>
            <div className="text"><span className="title">CEP: </span> {props.document.origin.zipCode || "" }</div>
          </div>

        ) : ""}

        {props.document.destination && props.document.destination.name ? (
          <div>
            <div className="text"><span className="title">Destino: </span> {props.document.destination.name || "" }</div>
            <div className="text"><span className="title">Cep: </span> {props.document.destination.zipCode || "" }</div>
          </div>
        ) : ""}

        {props.document.vehicle && props.document.vehicle.name && props.document.vehicle.plate && props.document.vehicle.capacity && props.document.vehicle.averageSpeed ? (
          <div>
            <div className="text"><span className="title">Veículo: </span> {props.document.vehicle.name || "" }</div>
            <div className="text"><span className="title">Placa: </span> {props.document.vehicle.plate || "" }</div>
            <div className="text"><span className="title">Capacidade: </span> {props.document.vehicle.capacity || "" }</div>
            <div className="text"><span className="title">Velocidade média: </span> {props.document.vehicle.averageSpeed+" Km/h" || "" }</div>
          </div>
        ) : ""}

        {props.document.distance ? (
          <div className="text"><span className="title">Distância: </span> {props.document.distance || "" }</div>
        ) : ""}
       

        {props.document.pickupDate ? (
          <div className="text"><span className="title">Data de Coleta:</span> { moment(props.document.pickupDate).format("DD/MM/YYYY") || "" }</div>
        ) : ""}

        {props.document.deliveryForecast ? (
          <div className="text"><span className="title">Previsão de Entrega:</span> { moment(props.document.deliveryForecast).format("DD/MM/YYYY") || "" }</div>
        ) : ""}
       

        {props.document.quote && props.document.quote.totalValue ? (
          <div className="text"> <span className="title">Valor: </span> {numberToCurrency(props.document.quote.totalValue, "REAL") || 0} </div>
        ) : ""}

        {props.document.status ? (
          <div className="text"><span className="title">Status do Embarque: </span> {props.document.status || "" }</div>
        ) : ""}

      </div>
    );
  }

  let title = props.document.type
  if (title === "invoice"){
    title = "fatura"
  } else if (title === "cargoShipment"){
    title = "Embarque"
  } 
  const body = (  
    <div className="audit-record-modal-info-doc">
      {props.document.type === "nfe" ? bodyNFE : ""}
      {props.document.type === "cte" ? bodyCTE : ""}
      {props.document.type === "invoice" ? bodyINVOICE : ""}
      {props.document.type === "cargoShipment" ? bodyCargoShipment : ""}

    </div>
  );

  return (
    <div>
      <div>
        <Dialog
          className="lxLookupDialog"
          header={<h2>Documento: {title.toUpperCase()} </h2>}
          visible={props.visible}
          style={{ width: "auto" }}
          modal={true}
          onHide={props.onHide}
        >
          {body}
        </Dialog>
      </div>
    </div>
  );
}
