import React from "react";
import { DataTable } from "primereact/datatable"; 

export const TableRowTreeview = (props) => (
  <DataTable
    selectionMode="single"
    value={props.records}
    emptyMessage="Não foram encontrados registros"  
  >
    {props.columns}
  </DataTable>
);
