import React from 'react';
import { InputText } from 'primereact/inputtext';
import './InputCnpjCpf.scss';

const formatCnpjOrCpf = (value) =>{
    value = value || "";
    let cleanerValue = value.replace(/\D/g,'');
    let formatedValue = "";
    if(cleanerValue.length == 11){ 
        for(let index = 0; index < cleanerValue.length; index++){
            if(index == 3 || index == 6){
                formatedValue += '.';
            }else if(index == 9 ){
                formatedValue += '-';
            }
            formatedValue += cleanerValue[index]
        }
    }else{
        for(let index = 0; index < cleanerValue.length; index++){
            if(index == 2 || index == 5){
                formatedValue += '.'    
            }else if(index == 8){
                formatedValue += '/'
            }else if(index == 12){
                formatedValue += '-'
            }
            formatedValue += cleanerValue[index]
        }
    }
    return {
        formatedValue,
        cleanerValue
    }
}

export class InputCnpjCpf extends React.Component {

    constructor(props){
        super(props);
        const {formatedValue,cleanerValue} = formatCnpjOrCpf(props.value || "")
        this.state = {
            viewValue: formatedValue,
            value:cleanerValue
        };
    }

    componentWillReceiveProps(nextProps){
        if(this.state.value != nextProps.value){
            const {formatedValue,cleanerValue} = formatCnpjOrCpf(nextProps.value);
            this.setState((prev)=> {return { viewValue: formatedValue, value:cleanerValue }});
        }
    }

    onChangeHandler = (e) => {
        const target = e.target.cloneNode(true);
        let value = target.value;
        const {formatedValue,cleanerValue} = formatCnpjOrCpf(value)
        this.setState((prev)=> {return { viewValue: formatedValue, value:cleanerValue }});
        if(this.props.onChange){
            target.value = cleanerValue;
            target.viewValue = formatedValue;
            const event = {
                ...e,
                target
            }
            this.props.onChange(event);
        }
    }

    render(){
        return(
            <div className="lxInputCnpjCpf">
                <div className="p-inputgroup">
                    <InputText 
                        name={this.props.name} 
                        disabled={this.props.disabled} 
                        value={this.state.viewValue}
                        onChange={this.onChangeHandler}
                        placeholder={"99.999.999/9999-99"}
                        maxLength={18}
                    />
                </div>
            </div>
        );
    }
}

