import React from "react";
import { connect } from "react-redux";
import {Link} from 'react-router-dom';
import  Toolbar from "../../../components/toolbar/Toolbar";
import {Column} from 'primereact/column';
import {routes} from '../../../constants';
import { Page } from "../../../components/page/Page";
import { AcaoList } from "./AcaoList";
import {actions} from '../acao.actions'
import { AppService, COMPANY_CHANGE_EVENT_NAME, BUSINESS_UNIT_CHANGE_EVENT_NAME } from "../../../services/app.service";


const mapStateToProps = state => {
  return {
      columns: state.crudAcaoState.columns,
      records: state.crudAcaoState.records,
      first: state.crudAcaoState.index,
      rows: state.crudAcaoState.max,
      totalRecords:state.crudAcaoState.count,
      sortField:state.crudAcaoState.sortField,
      order:state.crudAcaoState.sortOrder
  }
}
const mapDispatchToProps = dispatch =>{
  return {
      paginate: paginationParams => dispatch(actions.paginate(paginationParams)),
      sortOrder: sortParams => dispatch(actions.sortOrder(sortParams)),
      applyFilter: filterParams => {
          dispatch(actions.setType(null));
          dispatch(actions.applyFilter(filterParams))
      }, 
      load: ()=>dispatch(actions.load())
  }
}


class AcaoListPageContainer extends React.Component {

  newHandler = ()=>{
    this.props.history.push(`${routes.PATH_ACAO}/novo`)
  }
  componentDidMount(){
    this.props.load();
    AppService.on(COMPANY_CHANGE_EVENT_NAME,this);
  }
  componentWillUnmount(){
    AppService.off(COMPANY_CHANGE_EVENT_NAME,this);
  }
  
  onReceiveAppNotifications = (eventName, params)=>{
    if(eventName === COMPANY_CHANGE_EVENT_NAME || eventName === BUSINESS_UNIT_CHANGE_EVENT_NAME){
      this.props.load();
    }
  }


  onPage = (e)=>{
    this.props.paginate({
        max:e.rows,
        index: e.first
    })
}
onSort = (e)=>{
    this.props.sortOrder({
        sortOrder: e.sortOrder,
        sortField: e.sortField
    })
    
   
}
onFilter = (e)=>{
    const filters = [];
    const keys = Object.keys(e.filters) || [];
    keys.forEach(key =>{
        filters.push({
            campo:key,
            valor: e.filters[key].value,
            tipoFiltro: 'CONTEM'

        })
    })
   this.props.applyFilter(filters)
    
}

  render() {
    const header = (
      <div>
        <h1>Ações</h1>
        <div>
          <Toolbar onNew={this.newHandler} newButtonStyle='highlighted'>
          </Toolbar >
        </div>
      </div>
    );
    const oidTemplate = (rowData,column) =>{
      return (
        <div className="id-link"><Link to={`${routes.PATH_ACAO}/${rowData.oid}`}>{rowData.oid}</Link></div>
      )
  }

    const columns = this.props.columns.map(col => {
      if(col.field === 'oid'){
        return (<Column {...col} key={col.field} body={oidTemplate}/> );
      }
    return (<Column {...col} key={col.field}/>)})

    const content = <AcaoList  {...this.props}  onFilter={this.onFilter} onPage={this.onPage} onSort={this.onSort}  columns={columns} />;
    return <Page header={header} content={content} />;
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(AcaoListPageContainer);
