
    import React from 'react';
    import RouteFormPageContainer from './RouteFormPageContainer';
    
    export class RouteFormPage extends React.Component{
        render(){
            return (
                <RouteFormPageContainer {...this.props}/>
            )
        }
    }
    