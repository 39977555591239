
    import React from 'react';
    import VehicleTypeListPageContainer from './VehicleTypeListPageContainer';
    
    export class VehicleTypeListPage extends React.Component{
        
        render(){
          return (
            <VehicleTypeListPageContainer {...this.props}/>
          )
        }
    }