
    import React from 'react';
    import { InputText } from 'primereact/inputtext';
    
    export const EdiForm = (props) => {
        
        return (
            <div className="edi-form">
                <div className="p-grid">
                    <div className="p-col-6">
                        <div className="p-grid">
                        
            <div className="p-col-12 form-field-container">
            <label>Código</label>
            <InputText value={props.record.oid} onChange={props.changeHandler} name="oid" disabled={true} />
            </div>
            <div className="p-col-12 form-field-container">
            <label>Nome</label>
            <InputText value={props.record.name} onChange={props.changeHandler} name="name" disabled={false} />
            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    };