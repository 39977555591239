import React from "react";
import { connect } from "react-redux";
import { Dialog } from "primereact/dialog";
import FileUploaderButton from "../../../../components/file-uploader-button/FileUploaderButton";
import { InputTextarea } from "primereact/inputtextarea";
import { actions } from "../../auditRecords.actions";
import moment from 'moment'

const mapStateToProps = (state) => {
  return {
    description: state.auditRecordsState.description || "",
    visibleModalUploudFile:  state.auditRecordsState.visibleModalUploudFile,
    selectedRow: state.auditRecordsState.selectedRow || [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    descriptionChangeHandler: event => {
      const target = event.target;
      let value = target.value;
      dispatch(actions.setDescription(value));
    },
    sendReceipt: (data) => dispatch(actions.sendReceipt(data)),
    setVisibleModalUpload: (visible) => dispatch(actions.setVisibleModalUpload(visible)),
    setSelectedRow: (row) => dispatch(actions.setSelectedRow(row)),
  };
};



class  UploadFileModal extends React.Component {
  constructor(props) {
    super(props);
  }

  setVisibleModalUploudFile = (visible) => {
    this.props.setVisibleModalUpload(visible);
    if (!visible) {
      this.props.setSelectedRow([]);
    }
  };
  
  onUploadReceipt = (e) => {

    console.log("selectedRow => ",this.props.selectedRow)

    let file = e.target && e.target.files.length > 0 ? e.target.files[0] : []
    let reader = new FileReader();
    reader.readAsDataURL(file);
    let fileBase64 = "";
    reader.onloadend = () => {
      fileBase64 = reader.result
        .replace("data:", "")
        .replace(/^.+,/, "");

      const data = {
        // esse link deve conter o company do owner do registro
        "link": `0/0/0/0/${this.props.selectedRow.owner.company ? this.props.selectedRow.owner.company : ""}/`,  
        "code": this.props.selectedRow && this.props.selectedRow.orderCode ? this.props.selectedRow.orderCode : "",
        "deliveryDate": moment(),
        "observation": this.props.description || "Entrega Realizada Normalmente",
        "file": fileBase64
      }
      this.props.sendReceipt(data);
      this.setVisibleModalUploudFile(false);
      this.props.onDescriptionChangeHandler("");
    };
  };

  onDescriptionChangeHandler = (e) => {
    this.props.descriptionChangeHandler(e);
  }

  render(){    
    const fieldsUpload = (
      <React.Fragment>
         <div className="p-col-12 form-field-container">
        <label>Descrição</label>
        <InputTextarea
          rows={10}
          cols={30}
          name="description"
          value={this.props.description}
          onChange={this.onDescriptionChangeHandler}
        />
      </div>
        <div className="p-col-12 lxActionButtons">
          <FileUploaderButton
            // title="Enviar Comprovante de Entrega"
            onUpload={this.onUploadReceipt}
          />
          <label
            style={{ paddingTop: "5px", marginLeft: "5px", fontWeight: "bold" }}
          >
            Enviar
          </label>
        </div>
      </React.Fragment>
    );
  
  
    return (
      <Dialog
        header="Enviar Comprovante de Entrega"
        visible={this.props.visibleModalUploudFile}
        style={{ width: "30vw" }}
        modal={true}
        baseZIndex={1}
        onHide={(e) => this.setVisibleModalUploudFile(false)}
        footer={<div></div>}
      >
        {fieldsUpload}
      </Dialog>
    );
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadFileModal);