import { build } from "../../services/crudFactory";
import { Action } from "../../services/actions.factory";
import { messageActions, makeMessages } from "../../store/message";
import api from "../../services/api.service";
const BASE_PATH = "/lognex/audit-records";
const prefix = "auditRecords";

const crud = build(false, BASE_PATH);

const newTypes = {
  SET_LOOKUP_CARRIER_VISIBLE: `${prefix}setLookupCarrierVisible`,
  SET_INITIAL_DATE_FILTER: `${prefix}setInitialDateFilter`,
  SET_FINAL_DATE_FILTER: `${prefix}setFinalDateFilter`,
  SET_CARRIER_FILTER: `${prefix}setCarrierFilter`,
  SET_COUNTER: `${prefix}setCounter`,
  SET_SELECTED_ROW: `${prefix}setSelectedRow`,
  SET_EXPANDED_ROWS: `${prefix}setExpandedRows`,
  SET_OBSERVATION: `${prefix}setObservation`,
  SET_OID_CTE: `${prefix}setOidCTE`,
  SET_COMPONENT_LABEL: `${prefix}setComponentLabel`,
  SET_APPROVAL_TYPE: `${prefix}setApprovalType`,
  SET_LOAD_FILTERS: `${prefix}setLoadFilters`,
  SET_DESCRIPTION: `${prefix}setDescription`,
  SET_VISIBLE_MODAL_UPLOAD: `${prefix}setVisibleModalUpload`,
  DOWNLOAD_REPORT: `${prefix}downloadReport`,
  LOAD_HISTORY_RECORDS: `${prefix}loadHistoryRecords`,
  SET_DIALOG_HISTORY_VISIBLE: `${prefix}setDialogHistoryVisible`,
  SET_DIALOG_LOGS_VISIBLE: `${prefix}setDialogLogsVisible`,
  LOAD_LOG_RECORDS: `${prefix}loadLogsRecord`,
  SET_LIST_APROVED_CTES: `${prefix}setListAprovedCTes`,
  SET_DOCUMENT_VIEW: `${prefix}setDocumentView`,
};

const actions = new Action(prefix, crud);

const types = { ...actions.types, ...newTypes };

actions.setDialogLogsVisible = (visible) => {
  return {
    type: types.SET_DIALOG_LOGS_VISIBLE,
    visible
  }
}
actions.setDialogHistoryVisible = (visible) => {
  return {
    type: types.SET_DIALOG_HISTORY_VISIBLE,
    visible
  }
}

actions.setListAprovedCTes = (listAprovedCTes) => {
  return {
    type: types.SET_LIST_APROVED_CTES,
    listAprovedCTes,
  };
};

actions.setDocumentView = (documentView) => {
  return {
    type: types.SET_DOCUMENT_VIEW,
    documentView,
  };
};
actions.setDescription = (description) => {
  return {
    type: types.SET_DESCRIPTION,
    description,
  };
};

actions.setVisibleModalUpload = (visibleModalUploudFile) => {
  return {
    type: types.SET_VISIBLE_MODAL_UPLOAD,
    visibleModalUploudFile,
  };
};

actions.setLoadFilters = (filters) => {
  return {
    type: types.SET_LOAD_FILTERS,
    filters,
  };
};
actions.setComponentLabel = (label) => {
  return {
    type: types.SET_COMPONENT_LABEL,
    label,
  };
};

actions.setApprovalType = (value) => {
  return {
    type: types.SET_APPROVAL_TYPE,
    value,
  };
};
actions.setOidCTE = (oid) => {
  return {
    type: types.SET_OID_CTE,
    oid,
  };
};
actions.setObservation = (value) => {
  return {
    type: types.SET_OBSERVATION,
    value,
  };
};

actions.setExpandedRows = (expandedRows) => {
  return {
    type: types.SET_EXPANDED_ROWS,
    expandedRows,
  };
};

actions.setSelectedRow = (row) => {
  return {
    type: types.SET_SELECTED_ROW,
    row,
  };
};

actions.setCounter = (totalizer) => {
  return {
    type: types.SET_COUNTER,
    totalizer,
  };
};

actions.setCarrierFilter = (carrier) => {
  return {
    type: types.SET_CARRIER_FILTER,
    carrier,
  };
};

actions.setLookupCarrierVisible = (visible) => {
  return {
    type: types.SET_LOOKUP_CARRIER_VISIBLE,
    visible,
  };
};

actions.setInitialDateFilter = (initialDateFilter) => {
  return {
    type: types.SET_INITIAL_DATE_FILTER,
    initialDateFilter,
  };
};

actions.setFinalDateFilter = (finalDateFilter) => {
  return {
    type: types.SET_FINAL_DATE_FILTER,
    finalDateFilter,
  };
};
actions.loadHistoryRecords = (records) => {
  return {
    type: types.LOAD_HISTORY_RECORDS,
    records,
  };
};
actions.loadLogsRecord = (records) => {
  return {
    type: types.LOAD_LOG_RECORDS,
    records,
  };
};

actions.getHistories = (idCTe) => {
  return (dispatch) => {
    dispatch(actions.fetchRecord());
    api.post('/api/v1/lognex/cargorecord/history', { "cteId": idCTe })
      .then(({ data }) => {
        dispatch(actions.hideLoader());
        dispatch(actions.loadHistoryRecords(data));
      })
      .catch((error) => {
        dispatch(actions.hideLoader());
        dispatch(actions.loadHistoryRecords([]));
        dispatch(
          messageActions.messageShowMessages(
            makeMessages(["Não foi possível carregar os históricos."], "error")
          )
        );
      });

  }
}

actions.getLogs = (idCTe) => {
  return (dispatch) => {
    dispatch(actions.fetchRecords())
    dispatch(actions.showLoader());
    api.post('/api/v1/lognex/cargorecord/logsbycte', { "cteId": idCTe })
      .then(({ data }) => {
        dispatch(actions.hideLoader());
        dispatch(actions.loadLogsRecord(data));
      })
      .catch(err => {
        dispatch(actions.hideLoader());
        dispatch(actions.loadLogsRecord([]));
        dispatch(
          messageActions.messageShowMessages(
            makeMessages(["Não foi possível carregar os logs."], "error")
          )
        );
      });
  };
}

actions.loadCounterAll = (filterParams) => {
  return (dispatch) => {
    const params = {
      filters: filterParams,
    };
    dispatch(actions.showLoader());
    return api
      .post(`/api/v1${BASE_PATH}/count`, params)
      .then(({ data }) => {
        dispatch(actions.hideLoader());
        if (data) {
          dispatch(actions.setCounter(data));
        } {
          dispatch(actions.setCounter({
            total: 0,
            pendencies: 0,
            divergence: 0,
            withoutRecept: 0,
            finished: 0,
          }));
        }
      })
      .catch((err) => {
        dispatch(actions.hideLoader());
        dispatch(actions.setCounter({
          total: 0,
          pendencies: 0,
          divergence: 0,
          withoutRecept: 0,
          finished: 0,
        }));
        console.log("err => ", err);
      });
  };
};

actions.sendReceipt = (data) => {
  return (dispatch, getState) => {
    const auditRecordsState = getState().auditRecordsState;
    const filterParams = auditRecordsState.loadFilters || {};

    dispatch(actions.showLoader());
    return api
      .post(`/portal/collectionorder/add/receipt`, data)
      .then(({ data }) => {
        console.log("resposta do envio do comprovante de entrega => ", data)
        dispatch(actions.hideLoader());
        dispatch(actions.applyFilter(filterParams));
        dispatch(actions.setVisibleModalUpload(false));
        dispatch(
          messageActions.messageShowMessages(
            makeMessages(["Comprovante enviado com sucesso."], "success")
          )
        );

      })
      .catch((err) => {
        dispatch(actions.hideLoader());
        dispatch(actions.setVisibleModalUpload(false));
        dispatch(
          messageActions.messageShowMessages(
            makeMessages(["Falha no envio do comprovante de entrega."], "error")
          )
        );
        console.log("err => ", err);
      });
  };
};

actions.approveComponent = () => {
  return (dispatch, getState) => {
    const auditRecordsState = getState().auditRecordsState;
    const filterParams = auditRecordsState.loadFilters || {};
    if (auditRecordsState.oidCte > 0) {
      const body = {
        cteID: auditRecordsState.oidCte,
        description: auditRecordsState.observation
          ? auditRecordsState.observation
          : null,
        componentLabelCTe: auditRecordsState.componentLabel
          ? auditRecordsState.componentLabel
          : null,
      };

      // console.log("body SINGLE => ", body);

      api
        .post("/api/v1/lognex/cargorecord/approveauditcomponent", body)
        .then(() => {
          dispatch(actions.hideLoader());
          dispatch(actions.setOidCTE(0));
          dispatch(actions.setObservation(""));
          dispatch(actions.setApprovalType(""));
          dispatch(actions.applyFilter(filterParams));
          dispatch(
            messageActions.messageShowMessages(
              makeMessages(["Componente aprovado com sucesso"], "success")
            )
          );
        })
        .catch((err) => {
          dispatch(actions.hideLoader());
          dispatch(
            messageActions.messageShowMessages(
              makeMessages(["Ops! Falha na aprovação do componente"], "error")
            )
          );
        });
    }
  };
};
actions.approveAllComponents = () => {
  return (dispatch, getState) => {
    const auditRecordsState = getState().auditRecordsState;
    const filterParams = auditRecordsState.loadFilters || {};
    if (auditRecordsState.oidCte > 0) {
      const body = {
        cteID: auditRecordsState.oidCte,
        description: auditRecordsState.observation
          ? auditRecordsState.observation
          : null,
      };

      // console.log("body ALL => ", body);
      dispatch(actions.showLoader());
      api
        .post("/api/v1/lognex/cargorecord/approveallauditcomponents", body)
        .then(() => {
          dispatch(actions.hideLoader());
          dispatch(actions.setOidCTE(0));
          dispatch(actions.setObservation(""));
          dispatch(actions.setApprovalType(""));
          dispatch(actions.applyFilter(filterParams));
          dispatch(
            messageActions.messageShowMessages(
              makeMessages(["Componentes aprovados com sucesso"], "success")
            )
          );
        })
        .catch((err) => {
          dispatch(actions.hideLoader());
          dispatch(actions.setOidCTE(0));
          dispatch(actions.setObservation(""));
          dispatch(actions.setApprovalType(""));
          dispatch(
            messageActions.messageShowMessages(
              makeMessages(["Ops! Falha na aprovação dos componentes"], "error")
            )
          );
        });
    }
  };
};

actions.download = () => {
  return (dispatch, getState) => {
    const auditRecordsState = getState().auditRecordsState;
    const filters = auditRecordsState.loadFilters || {};
    dispatch(actions.showLoader());
    api
      .post("/api/v1/lognex/audit-records/report", { filters })
      .then((response) => {
        const data = response.data;
        dispatch(actions.hideLoader());

        const anchor = document.createElement("a");
        anchor.href = data.presigned_url;
        anchor.target = "_blank";
        document.body.append(anchor);
        anchor.click();
        anchor.remove();
      })
      .catch((err) => {
        console.log(err);
        dispatch(actions.hideLoader());
        dispatch(
          messageActions.messageShowMessages(
            makeMessages(["Ops! Falha ao exportar Relatório"], "error")
          )
        );
      });
  };
};

actions.approvedCTEs = () => {

  return (dispatch, getState) => {
    const auditRecordsState = getState().auditRecordsState;
    const filterParams = auditRecordsState.loadFilters || {};
    const observation = auditRecordsState.observation;
    const cteIDs = auditRecordsState.listAprovedCTes.map(item => item.cte.oid)
    const body = {
      observation,
      cteIDs
    }
    dispatch(actions.showLoader());
    api.post("/api/v1/lognex/audit-records/approved/ctes", body)
      .then(({ data }) => {
        dispatch(actions.hideLoader());
        dispatch(actions.setObservation(""));
        dispatch(actions.setApprovalType(""));
        dispatch(actions.applyFilter(filterParams));
        dispatch(
          messageActions.messageShowMessages(
            makeMessages(["CTEs Aprovados com sucesso."], "success")
          )
        );

      })
      .catch((err) => {
        console.log(err);
        dispatch(actions.hideLoader());
        dispatch(actions.setObservation(""));
        dispatch(actions.setApprovalType(""));
        dispatch(
          messageActions.messageShowMessages(
            makeMessages(["Ops! Falha ao aprovar todos os CTEs"], "error")
          )
        );
      })
  }
}
actions.reprocessCTEs = () => {

  return (dispatch, getState) => {
    const auditRecordsState = getState().auditRecordsState;
    const filterParams = auditRecordsState.loadFilters || {};
    const cteIDs = auditRecordsState.listAprovedCTes.map(item => item.cte.oid)

    dispatch(actions.showLoader());
    api.post("/api/v1/lognex/cargorecord/reprocessall", { cteIDs })
      .then(({ data }) => {
        dispatch(actions.hideLoader());
        dispatch(actions.setObservation(""));
        dispatch(actions.setApprovalType(""));
        dispatch(actions.applyFilter(filterParams));
        dispatch(
          messageActions.messageShowMessages(
            makeMessages(["CTEs Reprocessados com sucesso."], "success")
          )
        );
      })
      .catch((err) => {
        console.log(err);
        dispatch(actions.hideLoader());
        dispatch(actions.setObservation(""));
        dispatch(actions.setApprovalType(""));
        dispatch(
          messageActions.messageShowMessages(
            makeMessages(["Ops! Falha ao reprocessar todos os CTEs"], "error")
          )
        );
      })
  }
}


export { types, actions };
