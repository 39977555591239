import React from "react";
import { connect } from "react-redux";
import { Column } from "primereact/column";
import { actions } from "../../../../accountConfiguration.actions";
import { LookupField } from "../../../../../../components/lookup/LookupField";
import { actions as actionCarrier } from "../../../../../carrier/carrier.actions";
import { actions as actionEquipment } from "../../../../../towerEquipmentType/tower_equipment_type.actions";
import { ButtonDialog } from "../../../../../../components/button-dialog/ButtonDialog";
import { AppService, BUSINESS_UNIT_CHANGE_EVENT_NAME, COMPANY_CHANGE_EVENT_NAME } from "../../../../../../services/app.service";
import { CarrierEquipmentTypeList } from "./CarrierEquipmentTypeList";
import { EquipmentTypeList } from "../components/EquipmentTypeList";
import "./index.scss";
import { DialogCustomFieldsEquipType } from "../components/dialogCustomFields";
import { InputText } from "primereact/inputtext";

const initialState = {
  columns: [
    {
      field: "name",
      header: "Transportadora(SIGLA)",
      columnKey: "name",
      sortable: false,
      filter: true,
    },
    {
      field: "documentNumber",
      header: "CNPJ",
      columnKey: "documentNumber",
      sortable: false,
      filter: true,
    }
  ],
  equipmentTypeColumns: [
    {
      field: "oid",
      header: "ID",
      columnKey: "oid",
      sortable: false,
      filter: true,
    },
    {
      field: "name",
      header: "Nome",
      columnKey: "name",
      sortable: false,
      filter: true,
    },
  ],
};
const mapStateToProps = (state) => {
  const { integrationConfigurations } = state.crudAccountConfigurationState.currentRecord;
  const { carrierEquipmentType = [] } = integrationConfigurations ? integrationConfigurations : [];

  const lookupCarrierBase = {
    records: state.crudCarrierState.records,
    columns: state.crudCarrierState.columns.filter((col) => col.field === "oid" || col.field === "name" || col.field === "tradeName" || col.field === "documentNumber").map((col) => <Column key={col.field} {...col} />),
    first: state.crudCarrierState.index,
    rows: state.crudCarrierState.max,
    totalRecords: state.crudCarrierState.count,
    sortField: state.crudCarrierState.sortField,
    order: state.crudCarrierState.sortOrder,
    rowsPerPageOptions:[20,50,100]
  };
  let lookupCarrier = Object.assign({}, state.crudAccountConfigurationState.lookupCarrierEquipType, lookupCarrierBase);

  const lookupEquipmentBase = {
    records: state.crudTowerEquipmentTypeState.records,
    columns: state.crudTowerEquipmentTypeState.columns.filter((col) => col.field === "oid" || col.field === "name").map((col) => <Column key={col.field} {...col} />),
    first: state.crudTowerEquipmentTypeState.index,
    rows: state.crudTowerEquipmentTypeState.max,
    totalRecords: state.crudTowerEquipmentTypeState.count,
    sortField: state.crudTowerEquipmentTypeState.sortField,
    order: state.crudTowerEquipmentTypeState.sortOrder,
    rowsPerPageOptions:[20,50,100]
  };
 

  let lookupEquipment = Object.assign({}, state.crudAccountConfigurationState.lookupEquipment, lookupEquipmentBase);

  return {
    columns: initialState.columns,
    records: carrierEquipmentType,
    selectedCarrierEquipTypeRecord: state.crudAccountConfigurationState.selectedCarrierEquipTypeRecord,
    carrierEquipTypeSelected: state.crudAccountConfigurationState.carrierEquipTypeSelected,
    lookupCarrier,
    lookupEquipment,
    equipmentType: state.crudAccountConfigurationState.equipmentType,
    equipmentTypeColumns: initialState.equipmentTypeColumns,
    dialogCustomFieldsEquipTypeVisible: state.crudAccountConfigurationState.dialogCustomFieldsEquipTypeVisible,
    equipTypeSelected: state.crudAccountConfigurationState.equipTypeSelected,
    selectedEquipTypeRecord: state.crudAccountConfigurationState.selectedEquipTypeRecord,    
  };
};
const mapDispatchToProps = (dispatch) => {
  const _setField = (field, value) => {
    dispatch(actions.setField(field, value));
  };
  return {
    setField: (field, value) => {
      _setField(field, value);
    },

    onChangeCarrierRecordSelect: (value) => {
      dispatch(actions.onChangeCarrierEquipTypeRecordSelect(value));
    },
    onRemoveCarrierEquipTypeRecord: () => {
      dispatch(actions.onRemoveCarrierEquipTypeRecord());
    },

    lookupCarrierLoad: () => {
      dispatch(actionCarrier.load());
    },
    setLookupCarrierSetVisible: (visible) => {
      dispatch(actionCarrier.applyFilter([]));
      dispatch(actions.setLookupCarrierEquipTypeSetVisible(visible));
    },
    setLookupCarrierSelected: (carrier) => {
      dispatch(actions.setLookupCarrierEquipTypeSelected(carrier));
    },
    lookupCarrierApplyFilter: (filterParams) => {
      dispatch(actionCarrier.setType(null));
      dispatch(actionCarrier.applyFilter(filterParams));
    },

    lookupCarrierOnPage: (e) => {
      dispatch(actionCarrier.paginate(e));
    },
    lookupCarrierOnOnSortOrder: (e) => {
      dispatch(actionCarrier.sortOrder(e));
    },
    addNewCarrierEquipTypeSelected: (e) => {
      dispatch(actions.addNewCarrierEquipTypeSelected());
    },

    //
    //equipment
    lookupEquipmentSetVisible: (visible) => dispatch(actions.setLookupEquipmentVisible(visible)),
    lookupEquipmentPaginate: (paginationParams) => dispatch(actionEquipment.paginate(paginationParams)),
    lookupEquipmentSortOrder: (sortParams) => dispatch(actionEquipment.sortOrder(sortParams)),
    lookupEquipmentApplyFilter: (filterParams) => {
      dispatch(actionEquipment.setType(null));
      dispatch(actionEquipment.loadAll());
    },

    setEquipmentType: (equip) => {
      dispatch(actions.setEquipmentType(equip));
    },

    addNewEquipType: () => {
      dispatch(actions.addNewEquipType());
    },
    setVisibliDialogCustomFieldsEquipType: (val) => {
      dispatch(actions.setVisibliDialogCustomFieldsEquipType(val));
    },
    setEquipTypeSelected: (equipSel) => {
      dispatch(actions.setEquipTypeSelected(equipSel));
    },
    onChangeEquipTypeCustomFields: (equipSelCustom) => {
      dispatch(actions.onChangeEquipTypeCustomFields(equipSelCustom));
    },
    onChangeEquipTypeRecordSelect: (e) => {
      dispatch(actions.setSelectedEquipTypeRecord(e.value));
    },
    onRemoveSelectedEquipTypeRecord: () => {
      dispatch(actions.onRemoveSelectedEquipTypeRecord());
    },
  };
};

class CarrierEquipmentTypeListPageContainer extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    AppService.on(COMPANY_CHANGE_EVENT_NAME, this);
    AppService.on(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
  }

  componentWillUnmount() {
    AppService.off(COMPANY_CHANGE_EVENT_NAME, this);
    AppService.off(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
  }

  onSelectedRecordsChangeHandler = (e) => {
    this.props.onChangeCarrierRecordSelect(null);
  };
  onReceiveAppNotifications = (eventName, params) => {
    if (eventName === COMPANY_CHANGE_EVENT_NAME || eventName === BUSINESS_UNIT_CHANGE_EVENT_NAME) {
      this.props.setLookupCarrierSelected(null);
      this.props.onChangeCarrierRecordSelect(null);
    }
  };

  //Lookup Carrier

  lookupCarrierOnComplete = (e) => {};
  lookupCarrierOnEdit = (e) => {};
  lookupCarrierOnClick = (e) => {
    this.props.lookupCarrierLoad();
    this.props.setLookupCarrierSetVisible(true);
  };
  lookupCarrierOnHide = (e) => {
    this.props.setLookupCarrierSetVisible(false);
  };
  lookupCarrierOnCancel = (e) => {
    this.props.setLookupCarrierSelected(null);
    this.props.setLookupCarrierSetVisible(false);
  };
  lookupCarrierOnConfirm = (e) => {
    console.log("e.selection=", e.selection);
    this.props.setLookupCarrierSelected(e.selection);
    this.props.setLookupCarrierSetVisible(false);
  };
  lookupCarrierOnPage = (e) => {
    this.props.lookupCarrierOnPage({
      max: e.rows,
      index: e.first,
    });
  };
  lookupCarrierOnOnSort = (e) => {
    this.props.lookupCarrierOnOnSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField,
    });
  };

  lookupCarrierOnInputFilter = (e) => {
    const value = e.value;
    if (typeof value === "string") {
      const filters = [
        {
          campo: "name",
          valor: e.value,
          tipoFiltro: "CONTEM",
        },
      ];
      this.props.setLookupCarrierSelected({  name: e.value   });
      this.props.lookupCarrierApplyFilter(filters);
    } else {
      this.props.setLookupCarrierSelected(value);
    }
  };
  lookupCarrierOnFilter = (e) => {
    const filters = [];
    const keys = Object.keys(e.filters) || [];
    keys.forEach((key) => {
      filters.push({
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: "CONTEM",
      });
    });
    this.props.lookupCarrierApplyFilter(filters);
  };

  isValidCarrierLookupSelect = () => {
    return this.props.carrierSelected && this.props.carrierSelected.oid > 0;
  };

  enableBtnAddCarrier = () => {
    return this.props.carrierEquipTypeSelected;
  };
  //LookupCarrier
  addNewCarrierEquipTypeSelected = () => {
    this.props.addNewCarrierEquipTypeSelected();
  };
  onChangeCarrierRecordSelect = (e) => { 
    this.props.onChangeCarrierRecordSelect(e.value);
  };

  //EquipamentType
  lookupEquipmentOnPage = (e) => {
    this.props.lookupEquipmentPaginate({
      max: e.rows,
      index: e.first,
    });
  };
  lookupEquipmentOnSort = (e) => {
    this.props.lookupEquipmentSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField,
    });
  };
  lookupEquipmentOnFilter = (e) => {
    const filters = [];
    const keys = Object.keys(e.filters) || [];
    keys.forEach((key) => {
      filters.push({
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: "CONTEM",
      });
    });

    this.props.lookupEquipmentApplyFilter(filters);
  };
  lookupEquipmentOnOnSort = (e) => {};
  lookupEquipmentOnClick = (e) => {
    const filters = [];
    this.props.lookupEquipmentApplyFilter(filters);
    this.props.lookupEquipmentSetVisible(true);
  };
  lookupEquipmentOnHide = (e) => {
    this.props.lookupEquipmentSetVisible(false);
  };
  lookupEquipmentOnConfirm = (e) => {
    this.props.setEquipmentType(e.selection);
    this.props.lookupEquipmentSetVisible(false);
  };
  lookupEquipmentOnCancel = (e) => {
    this.props.setEquipmentType(null);
    this.props.lookupEquipmentSetVisible(false);
  };

  lookupEquipmentOnEdit = () => {};

  lookupEquipmentOnComplete = (e) => {};

  lookupEquipmentOnInputFilter = (e) => {
    const value = e.value;
    if (typeof value === "string") {
      const filters = [
        {
          campo: "name",
          valor: e.value,
          tipoFiltro: "CONTEM",
        },
      ];

      this.props.setEquipmentType({ name: e.value });
      this.props.lookupEquipmentApplyFilter(filters);
    } else {
      this.props.setEquipmentType(value);
    }
  };

  enableBtnAddEquipType = () => {
    return this.props.equipmentType &&  this.props.selectedCarrierEquipTypeRecord;
  };
  //adiciona na lista o tipo de equipamento selecionado
  addNewEquipType = () => {
    this.props.addNewEquipType();
  };

  showEditCustomFields = (rowData) => {
    this.props.setEquipTypeSelected(rowData);
    this.props.setVisibliDialogCustomFieldsEquipType(true);
  };
  onHideCustomFieldsEquipType=()=>{
    this.props.setEquipTypeSelected(null);
    this.props.setVisibliDialogCustomFieldsEquipType(false);
  }
  onConfirmCustomFieldsEquipType=()=>{
    this.props.setEquipTypeSelected(null);
    this.props.setVisibliDialogCustomFieldsEquipType(false);
  }
  onCancelCustomFieldsEquipType=()=>{
    this.props.setEquipTypeSelected(null);
    this.props.setVisibliDialogCustomFieldsEquipType(false);
  } 
  render() {
    const actionCustomFieldsEquipTypeTemplate = (rowData, column) => {
      const body = (
        <div className="p-grid">
          <div className="p-col-12 lxActionButtons">
            <button
              className="btnAddCustomFields"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                this.showEditCustomFields(rowData);
              }}
              title=" "
            >
              <i className="pi pi pi-cog"></i>
            </button>
          </div>
        </div>
      );
      return body;
    };

    let carrierColumns = [<Column key="selectorColumn" selectionMode="single" style={{ width: "3em" }} />];
    carrierColumns = [
      ...carrierColumns,
      ...this.props.columns.map((col) => {
        return <Column {...col} key={col.field} />;
      }),
    ];

    let equipmentTypeColumns = [<Column key="selectorColumn" selectionMode="single" style={{ width: "3em" }} />];
    equipmentTypeColumns = [
      ...equipmentTypeColumns,
      ...this.props.equipmentTypeColumns.map((col) => {
        return <Column {...col} key={col.field} />;
      }),
    ];
    equipmentTypeColumns.push(<Column key="selectorColumn" body={actionCustomFieldsEquipTypeTemplate} style={{ width: "5em" }} />);

    const equipmentTypes = this.props.selectedCarrierEquipTypeRecord ? this.props.selectedCarrierEquipTypeRecord.equipmentTypes : [];

    return (
      <div className="client-form">
        {/* CARRIER carrierConfigurations*/}
        <div className={"panel-categoria"}>
          <div className="p-grid">
            <div className="p-col-12">
              <h3>Transportadora</h3>
            </div>

            <div className="p-col-6 form-field-container">
              <label></label>
              <LookupField
                value={this.props.carrierEquipTypeSelected}
                placeholder="Transportadora"
                name="name"
                field="name"
                onComplete={this.lookupCarrierOnComplete}
                onInputFilter={this.lookupCarrierOnInputFilter}
                editDisabled={this.props.lookupCarrier.editDisabled}
                onEditField={this.lookupCarrierOnEdit}
                onClick={this.lookupCarrierOnClick}
                visible={this.props.lookupCarrier.visible}
                modal={this.props.lookupCarrier.modal}
                header={this.props.lookupCarrier.header}
                onHide={this.lookupCarrierOnHide}
                onConfirm={this.lookupCarrierOnConfirm}
                onCancel={this.lookupCarrierOnCancel}
                records={this.props.lookupCarrier.records}
                columns={this.props.lookupCarrier.columns}
                totalRecords={this.props.lookupCarrier.totalRecords}
                sortOrder={this.props.lookupCarrier.order}
                sortField={this.props.lookupCarrier.sortField}
                first={this.props.lookupCarrier.first}
                rows={this.props.lookupCarrier.rows}
                onPage={this.lookupCarrierOnPage}
                onFilter={this.lookupCarrierOnFilter}
                onSort={this.lookupCarrierOnOnSort}
                paginator={true}
                rowsPerPageOptions={this.props.lookupCarrier.rowsPerPageOptions} 
              />
            </div>
            <div className="p-col-3  align-bottom-right">
              <button disabled={!this.enableBtnAddCarrier()} className={"lognex-btn-highlighted"} onClick={this.addNewCarrierEquipTypeSelected} title="Novo" style={{ marginRight: ".25em" }}>
                <i className="pi pi-plus"></i>
              </button>

              <ButtonDialog
                onClick={this.props.onRemoveCarrierEquipTypeRecord}
                button={{
                  title: "Remover",
                  className: "lognex-btn-danger",
                  disabled: !this.props.selectedCarrierEquipTypeRecord,
                }}
                dialog={{
                  header: "Confirmação",
                  modal: false,
                  message: "Deseja realmente remover o registro?",
                }}
              ></ButtonDialog>
            </div>

            <CarrierEquipmentTypeList
              records={this.props.records}
              onSelectedRecordsChange={this.onChangeCarrierRecordSelect}
              selectedCarrierRecord={this.props.selectedCarrierEquipTypeRecord}
              columns={carrierColumns}
            />
          </div>

          <div className="p-grid">
            <div className="p-col-12" />

            {/* Tipo de equipamento */}
            <div className="p-col-12">
              <h3>Equipamento</h3>
            </div>

            <div className="p-col-6 form-field-container">
              <label> </label>
              <LookupField
                value={this.props.equipmentType}
                placeholder="equipamento"
                name="name"
                field="name"
                onComplete={this.lookupEquipmentOnComplete}
                onInputFilter={this.lookupEquipmentOnInputFilter}
                editDisabled={this.props.lookupEquipment.editDisabled}
                onEditField={this.lookupEquipmentOnEdit}
                onClick={this.lookupEquipmentOnClick}
                visible={this.props.lookupEquipment.visible}
                modal={this.props.lookupEquipment.modal}
                header={this.props.lookupEquipment.header}
                onHide={this.lookupEquipmentOnHide}
                onConfirm={this.lookupEquipmentOnConfirm}
                onCancel={this.lookupEquipmentOnCancel}
                records={this.props.lookupEquipment.records}
                columns={this.props.lookupEquipment.columns}
                totalRecords={this.props.lookupEquipment.totalRecords}
                sortOrder={this.props.lookupEquipment.order}
                sortField={this.props.lookupEquipment.sortField}
                first={this.props.lookupEquipment.first}
                rows={this.props.lookupEquipment.rows}
                onPage={this.lookupEquipmentOnPage}
                onFilter={this.lookupEquipmentOnFilter}
                onSort={this.lookupEquipmentOnOnSort}
              />
            </div>

            <div className="p-col-6  align-bottom-right">
              <button disabled={!this.enableBtnAddEquipType()} className={"lognex-btn-highlighted"} onClick={this.addNewEquipType} title="Novo" style={{ marginRight: ".25em" }}>
                <i className="pi pi-plus"></i>
              </button>

              <ButtonDialog
                onClick={this.props.onRemoveSelectedEquipTypeRecord}
                button={{
                  title: "Remover",
                  className: "lognex-btn-danger",
                  disabled: !this.props.selectedEquipTypeRecord || !this.props.selectedCarrierEquipTypeRecord,
                }}
                dialog={{
                  header: "Confirmação",
                  modal: false,
                  message: "Deseja realmente remover o registro?",
                }}
              ></ButtonDialog>
            </div>

            <EquipmentTypeList
              records={equipmentTypes}
               onSelectedRecordsChange={this.props.onChangeEquipTypeRecordSelect}
                selectedCarrierRecord={this.props.selectedEquipTypeRecord}
              columns={equipmentTypeColumns}
            />
          </div>

          {/* Usado esse condicional para sempre criar um novo Component e acionar o constructor, para usar o state local */}
          {this.props.dialogCustomFieldsEquipTypeVisible && (
            <DialogCustomFieldsEquipType
              onHide={this.onHideCustomFieldsEquipType}
              onConfirm={this.onConfirmCustomFieldsEquipType}
              onCancel={this.onCancelCustomFieldsEquipType}
              equipTypeSelected={this.props.equipTypeSelected}
              visible={this.props.dialogCustomFieldsEquipTypeVisible}  
              onChangeEquipTypeCustomFields={this.props.onChangeEquipTypeCustomFields}
            />
          )}
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CarrierEquipmentTypeListPageContainer);
