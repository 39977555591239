import { types } from './rastrearDelivery.actions';
import { Reducer } from '../../services/reducer.factory';

const initialState = {
    currentRecord: {
        image:"",
        pickupDate: "",
        lastCity: "",
        status: "",
        deliveryDate: null,
        deliveryForecast: "",
        code: "",
        clientName: "",
        volume: "",
        weight: 0,
        address: "",
        KmLeft: 0,
    },
    currentRecordDefault: {
        image:"",
        pickupDate: "",
        lastCity: "",
        status: "",
        deliveryDate: null,
        deliveryForecast: "",
        code: "",
        clientName: "",
        volume: "",
        weight: 0,
        address: "",
        KmLeft: 0,
    },
    cdRastreio: null,
}


const reducer = new Reducer(initialState, types);

export function RastrearDeliveryReducer(state, action) {
    state = reducer.execute(state, action);
    return execute(state, action);
}

function execute(state, action) {
    let result = state;
    switch (action.type) {
        case types.ON_CHANGE_CODIGO_RASTREIO:
            return onChangeCodigoRastreio(state, action);
        case types.SET_RECORD:
            return setRecord(state, action);
        default:
            return result
    }
}
function onChangeCodigoRastreio(state, action) {
    let cdRastreio = action.cdRastreio;
    return { ...state, cdRastreio };
}
function setRecord(state, action) {
    let currentRecord = action.record;
    if (!currentRecord) {
        currentRecord = state.currentRecordDefault
    }
    return { ...state, currentRecord };
} 