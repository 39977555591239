import {types} from './report.actions';

const initialState = {
    percentFreightOnBill: {
        title: "PORCENTAGEM -  FRETE / NFe",
        value: "0%", 
        filters: {
            carrier: {
                documentNumber: null,
                name: "TODOS",
            },
            period: {
                description:"Últimos 7 dias",
                period: "LAST_SEVEN_DAYS"
            },
        },
        lookup:{
            showCarrier: false,
            showPeriod: false,
            columnsCarrier:[
                {
                    field: "razaoSocial",
                    header: "Razão social",
                    columnKey: "razaoSocial",
                    sortable: false,
                    filter: true
                   
                  }
            ],
            columnsPeriod:[
                {
                    field: "description",
                    header: "Período",
                    columnKey: "description",
                    sortable: false,
                    filter: true
                   
                  }
            ]
        }
    }
}

export function reducerPercentFreightOnBill(state = initialState, action){
    let result = state;
    switch(action.type){
        case types.SET_VALUE:
            return setValue(state, action);
        case types.SET_NAME:
            return setName(state, action);
        case types.SET_CNPJ:
            return setCNPJ(state, action);
        case types.SET_PERIOD:
            return setPeriod(state, action);
        case types.SET_SHOW_CARRIER:
            return setShowCarrier(state, action);
        case types.SET_SHOW_PERIOD:
            return setShowPeriod(state, action);   
        case types.LOADER_SHOW:
            return loaderShow(state, action);
        case types.LOADER_HIDDEN:
            return loaderHide(state, action);                   
        default:
        return result; 
    }
}

function setValue(state, action){
    const percentFreightOnBill = {...state.percentFreightOnBill, value:action.value}
    return Object.assign({}, state, {percentFreightOnBill});
}

function setName(state, action){
    return Object.assign({}, state, { value: action.name }); 
}

function setCNPJ(state, action){
    const filters = {...state.percentFreightOnBill.filters,carrier:action.carrier}
    const percentFreightOnBill = {...state.percentFreightOnBill, filters}
    return Object.assign({}, state, {percentFreightOnBill}); 
}

function setPeriod(state, action){
    const filters = {...state.percentFreightOnBill.filters, period:action.period}
    const percentFreightOnBill = {...state.percentFreightOnBill, filters}
    return Object.assign({}, state, {percentFreightOnBill});  
}

function setShowCarrier(state, action){
    const lookup = {...state.percentFreightOnBill.lookup, showCarrier:action.showCarrier}
    const percentFreightOnBill = {...state.percentFreightOnBill, lookup}
    return Object.assign({}, state, {percentFreightOnBill}); 
}

function setShowPeriod(state, action){
    const lookup = {...state.percentFreightOnBill.lookup, showPeriod:action.showPeriod}
    const percentFreightOnBill = {...state.percentFreightOnBill, lookup}
    return Object.assign({}, state, {percentFreightOnBill}); 
}

function loaderShow(state, action){
    return Object.assign({}, state, { value: action.show }); 
}

function loaderHide(state, action){
    return Object.assign({}, state, { value: action.show }); 
}

