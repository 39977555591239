
import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import apiService from "../../../services/api.service";
import { Link } from 'react-router-dom';
import Toolbar from "../../../components/toolbar/Toolbar";
import { Column } from 'primereact/column';
import { routes } from '../../../constants';
import { Page } from "../../../components/page/Page";
import { EnchentesRSList } from "./EnchentesRSList";
import { actions } from '../enchentesRS.actions'
import { AppService, COMPANY_CHANGE_EVENT_NAME, BUSINESS_UNIT_CHANGE_EVENT_NAME } from "../../../services/app.service";


const mapStateToProps = state => {
  return {
    columns: state.crudEnchentesRSState.columns,
    records: state.crudEnchentesRSState.records,
    first: state.crudEnchentesRSState.index,
    rows: state.crudEnchentesRSState.max,
    totalRecords: state.crudEnchentesRSState.count,
    sortField: state.crudEnchentesRSState.sortField,
    order: state.crudEnchentesRSState.sortOrder
  }
}
const mapDispatchToProps = dispatch => {
  return {
    paginate: paginationParams => dispatch(actions.paginate(paginationParams)),
    sortOrder: sortParams => dispatch(actions.sortOrder(sortParams)),
    applyFilter: filterParams => {
      dispatch(actions.setType(null));
      dispatch(actions.applyFilter(filterParams))
    },
    load: () => dispatch(actions.load()),
    fetchRecord: () => dispatch(actions.fetchRecord()),
  }
}


class EnchentesRSListPageContainer extends React.Component {

  newHandler = () => {
    this.props.history.push(`${routes.PATH_CADENCHENTESRS}/novo`)
  }


  componentDidMount() {
    this.props.load();
    AppService.on(COMPANY_CHANGE_EVENT_NAME, this);
    AppService.on(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
  }
  componentWillUnmount() {
    AppService.off(COMPANY_CHANGE_EVENT_NAME, this);
    AppService.off(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
  }

  onReceiveAppNotifications = (eventName, params) => {
    if (eventName === COMPANY_CHANGE_EVENT_NAME || eventName === BUSINESS_UNIT_CHANGE_EVENT_NAME) {
      this.props.load();
    }
  }
  onRowClick = (e) => {
    const oid = e && e.data && e.data.oid
    if (oid) {
      this.props.history.push(`${routes.PATH_CADENCHENTESRS}/${oid}`)
    }
  }


  onPage = (e) => {
    this.props.paginate({
      max: e.rows,
      index: e.first
    })
  }
  onSort = (e) => {
    console.log('sorting', e);
    this.props.sortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField
    })


  }
  onFilter = (e) => {
    console.log(e);
    const filters = [];
    const keys = Object.keys(e.filters) || [];
    keys.forEach(key => {
      if (key == "oid") {
        filters.push({
          campo: key,
          valor: parseInt(e.filters[key].value),
          tipoFiltro: 'IGUAL_A'
        })
      } else {
        filters.push({
          campo: key,
          valor: e.filters[key].value,
          tipoFiltro: 'CONTEM'

        })
      }
    })
    console.log(filters);
    this.props.applyFilter(filters)
  }

  importXML = (e) => {
    this.props.fetchRecord()
    const files = e.target.files
    if (files && files.length >= 0) {
      const url = "/api/v1/lognex/avoid_location/import"
      apiService.uploadFiles(url, files)
        .then((response) => {
          if (response.data) {
            this.props.setImportLogData(response.data)
            this.props.errorsDialogHide(true)
            this.props.load()
          }
          this.props.hideLoader()
        })
        .catch((err) => {
          this.props.hideLoader()
        })
    }
  }

  render() {
    const header = (
      <div>
        <h1>Bloqueios RS</h1>
        <div>
          <Toolbar
            onNew={this.newHandler} newButtonStyle='highlighted'
            onImportXML={this.importXML}
          >
          </Toolbar >
        </div>
      </div>
    );

    const templateSituation = (rowData, column) => {
      return rowData.blockPartial
        ? <div style={{ color: "green", fontWeight: "bold" }}>Bloqueio Parcial</div>
        : <div style={{ color: "red", fontWeight: "bold" }}>Bloqueio Total</div>
    }
    const templateVehicles = (rowData, column) => {
      return rowData.currentVehicleTypeReleased == ""
        ? <div style={{ color: "red", fontWeight: "bold" }}>Nenhum</div>
        : <div style={{ color: "green", fontWeight: "bold" }}>{rowData.currentVehicleTypeReleased}</div>
    }
    const dateTemplate = (rowData, column) => {
      const date = rowData[column.field];
      const formattedDate = moment(date).utc().format("DD/MM/YYYY");
      return <span style={{ textAlign: "center" }}>{formattedDate}</span>;
    };

    const columns = this.props.columns.map(col => {
      if (col.field === 'oid') {
        return (<Column {...col} key={col.field} style={{ textAlign: "center" }} />);
      }
      if (col.field === 'situation') {
        return (<Column {...col} key={col.field} body={templateSituation} style={{ textAlign: "center" }} />);
      }
      if (col.field === 'currentVehicleTypeReleased') {
        return (<Column {...col} key={col.field} body={templateVehicles} style={{ textAlign: "center" }} />);
      }
      if (col.field === 'dateBlock') {
        return (<Column {...col} key={col.field} body={dateTemplate} style={{ textAlign: "center" }} />);
      }

      return (<Column {...col} key={col.field} style={{ textAlign: "center" }} />)
    })

    const content = <EnchentesRSList  {...this.props} onFilter={this.onFilter} onPage={this.onPage} onSort={this.onSort} columns={columns} onRowClick={this.onRowClick} />;
    return <Page header={header} content={content} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EnchentesRSListPageContainer);
