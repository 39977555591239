import React from 'react';
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import { InputCnpjCpf } from '../../../components/input-cnpj-cpf/InputCnpjCpf';
import { LookupField } from '../../../components/lookup/LookupField';
import './ClientForm.scss';

function clearMask(value){
    value = value || "";
    return value.replace(/[_.\-\/]/g,'');
}


export const ClientForm = (props) => {
    const record = props.record;
    const city = record && record.address && record.address.city ? record.address.city : '';
    const state = record && record.address && record.address.state ? record.address.state : '';
    const cep = record && record.address && record.address.cep ? record.address.cep : '';
    const streetNumber = record && record.address && record.address.number ? record.address.number : '';
    const district = record && record.address.district && record.address.district ? record.address.district : '';
    // const documentNumber = clearMask(record.cnpj);
    // let inputCnpj =  <InputMask mask="99.999.999/9999-99" value={documentNumber} onChange={props.changeHandler} name="cnpj" />
    // if(documentNumber.length <= 11){
    //     inputCnpj= <InputMask mask="999.999.999-99" value={documentNumber} onChange={props.changeHandler} name="cnpj" />
    // } 
    return (
        <div className="client-form">
            <div className="p-grid">
                <div className="p-col-6">
                    <div className="p-grid">
                        <div className="p-col-3">
                            <div className="form-field-container">
                                <label>Código</label>
                                <InputText value={record.oid} onChange={props.changeHandler} disabled={true} />
                            </div>
                        </div>
                        <div className="p-col-12 form-field-container">
                            <label className="lxRequiredField">Razão Social</label>
                            <InputText maxlength="300" value={record.razaoSocial} onChange={props.changeHandler} name="razaoSocial" />
                        </div>
                        <div className="p-col-12 form-field-container">
                            <label>Nome Fantasia</label>
                            <InputText maxlength="300" value={record.nomeFantasia} onChange={props.changeHandler} name="nomeFantasia" />
                        </div>

                        <div className="p-col-4 form-field-container">
                            <label className="lxRequiredField">CNPJ/CPF</label>
                            <InputCnpjCpf value={record.cnpj} onChange={props.changeHandler} name="cnpj" />
                        </div>

                        <div className="p-col-4 form-field-container">
                            <label>Telefone </label>
                            <InputMask mask="(99)99999-9999" value={record.phone} onChange={props.changeHandler} name="phone" />
                        </div>

                        <div className="p-col-12 form-field-container">
                            <label>Endereço</label>
                            <InputText maxlength="300" value={record.address.street} onChange={props.changeHandler} name="address.street" />
                        </div>

                        <div className="p-col-12 form-field-container">
                            <label>Complemento</label>
                            <InputText maxlength="300" value={record.address.complement} onChange={props.changeHandler} name="address.complement" />
                        </div>

                        <div className="p-col-4 form-field-container">
                            <label>Número</label>
                            <InputText maxlength="10" value={streetNumber} onChange={props.changeHandler} name="address.number" />
                        </div>

                        <div className="p-col-4 form-field-container">
                            <label>Bairro</label>
                            <InputText maxlength="300" value={district} onChange={props.changeHandler} name="address.district" />
                        </div>

                        <div className="p-col-4 form-field-container">
                            <label>CEP</label>
                            <InputMask mask="99999-999" value={cep} onChange={props.changeHandler} onComplete={props.onCompleteCep} name="address.cep" />
                        </div>

                        <div className="p-col-6 form-field-container">
                            <label>Cidade</label>
                            <LookupField
                                value={city}
                                placeholder="Cidade"
                                name="address.city"
                                field="description"
                                onComplete={props.lookupCitiesOnComplete}
                                onInputFilter={props.lookupCitiesOnInputFilter}
                                editDisabled={props.lookupCities.editDisabled}
                                onEditField={props.lookupCitiesOnEdit}
                                onClick={props.lookupCitiesOnClick}

                                visible={props.lookupCities.visible}
                                modal={props.lookupCities.modal}
                                header={props.lookupCities.header}
                                onHide={props.lookupCitiesOnHide}
                                onConfirm={props.lookupCitiesOnConfirm}
                                onCancel={props.lookupCitiesOnCancel}
                                records={props.lookupCities.records}
                                columns={props.lookupCities.columns}
                                totalRecords={props.lookupCities.totalRecords}
                                sortOrder={props.lookupCities.order}
                                sortField={props.lookupCities.sortField}
                                first={props.lookupCities.first}
                                rows={props.lookupCities.rows}
                                onPage={props.lookupCitiesOnPage}
                                onFilter={props.lookupCitiesOnFilter}
                                onSort={props.lookupCitiesOnOnSort}
                            />
                        </div>

                        <div className="p-col-6 form-field-container">
                            <label>Estado</label>
                            <LookupField
                                value={state}
                                placeholder="Estado"
                                name="address.state"
                                field="name"
                                onComplete={props.lookupStatesOnComplete}
                                onInputFilter={props.lookupStatesOnInputFilter}
                                editDisabled={props.lookupStates.editDisabled}
                                onEditField={props.lookupStatesOnEdit}
                                onClick={props.lookupStatesOnClick}

                                visible={props.lookupStates.visible}
                                modal={props.lookupStates.modal}
                                header={props.lookupStates.header}
                                onHide={props.lookupStatesOnHide}
                                onConfirm={props.lookupStatesOnConfirm}
                                onCancel={props.lookupStatesOnCancel}
                                records={props.lookupStates.records}
                                columns={props.lookupStates.columns}
                                totalRecords={props.lookupStates.totalRecords}
                                sortOrder={props.lookupStates.order}
                                sortField={props.lookupStates.sortField}
                                first={props.lookupStates.first}
                                rows={props.lookupStates.rows}
                                onPage={props.lookupStatesOnPage}
                                onFilter={props.lookupStatesOnFilter}
                                onSort={props.lookupStatesOnOnSort}
                            />
                        </div>
                        <div className="p-col-12 form-field-container">
                            <label>Email</label>
                            <InputText value={record.email} onChange={props.changeHandler} name="email" />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}
