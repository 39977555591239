import React from "react";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";

export default function ApprovedComponenteModal(props) {
  const fieldsModal = (
    <React.Fragment>
      <div className="p-col-12 form-field-container">
        <label>Observação</label>
        <InputTextarea
          rows={10}
          cols={30}
          name="observation"
          value={props.observation}
          onChange={props.onChange}
        />
      </div>
      <div className="p-col-12 form-field-container">
        <button
          className="lognex-btn-highlighted"
          style={{ width: "100%" }}
          onClick={props.onClick}
          title={"Aprovar componente divergentes"}
        >
          Aprovar componente
        </button>
      </div>
    </React.Fragment>
  );

  return (
    <Dialog
      header="Aprovar componente(s)"
      visible={props.visible}
      style={{ width: "30vw" }}
      modal={true}
      baseZIndex={1}
      onHide={props.onHide}
      footer={<div></div>}
    >
      {fieldsModal}
    </Dialog>
  );
}
