import React from "react";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import "./index.scss";
import { ButtonDialog } from "../../../../components/button-dialog/ButtonDialog";
import { Dropdown } from "primereact/dropdown";
export default class ModalRemoveDelivery extends React.Component {
  constructor(props) {
    super(props);
  }
  onChangeSelectedCD = (e) => {
    this.props.setSelectecVirtualCD(e.value);
  };

  render() {
    const footer = (
      <div>
        <ButtonDialog
          onClick={() => this.props.onConfirm(this.props.deliveryCDremove.codes, this.props.deliveryCDremove.routeId)}
          button={{
            title: "Confirmar",
            disabled: !this.props.startLocation,
            className: "lognex-btn-highlighted",
            iconClassName: "pi pi-check",
          }}
          dialog={{
            header: "Confirmação",
            modal: false,
            message: "Deseja realmente excluir essa ordem?",
          }}
        ></ButtonDialog>

        <button title="Cancelar" className="lognex-btn-danger" onClick={this.props.onHide}>
          <i className="pi pi-times"></i>
        </button>
      </div>
    );

    const getRenderDeliveriesSelected = () => {
      if (this.props.deliveryCDremove) {

        return this.props.deliveryCDremove.codes.map(c => {
          return <h3 key={`deliv-${c}`} className="delivery">
            {c}
          </h3>
        });
      }
      return <></>;
    };

    return (
      <Dialog className="content" header={"Exclusão de Ordem"} footer={footer} visible={this.props.visible} modal={true} onHide={this.props.onHide}>
        <div style={{ minHeight: "300px" }} >
          <div className="p-col-12 form-field-container">
            <h3>Selecione o Centro de distribuição(CD), ponto inical da rota</h3>
          </div>

          <div className="p-col-12 form-field-container">
            <span className="deliveries-title">{"Ordens à ser excluida"}</span>
            <div className="deliveries">{getRenderDeliveriesSelected()}</div>
          </div>


          <div className="p-col-12 form-field-container">
            <label>Centro de Distribuição</label>
            <Dropdown
              placeholder="Selecione"
              value={this.props.startLocation}
              dataKey={"key"}
              key={Math.random()}
              optionLabel="label"
              options={this.props.deliveryCDremove.virtualCDs}
              onChange={this.onChangeSelectedCD}
            />
          </div>
        </div>
      </Dialog>
    );
  }
}
