
export function validate(record) {
    const errors = [];
    
    if (!record.businessUnitOrigin.documentNumber) {
        errors.push({ field: 'origin', message: 'A unidade origin está com cnpj inválido!' });
    }
    if (!record.businessUnitDestination.documentNumber) {
        errors.push({ field: 'destination', message: 'A unidade de destino está com cnpj inválido!' });
    }   
    if (!record.distance || record.distance === 0) {
        errors.push({ field: 'destination', message: 'A distância não pode estar vazia ou zerada' });
    }   

    const result = {
        ok: errors.length === 0,
        errors,
        record
    }

    return new Promise((resolve, reject) => {
        const action = result.ok ? resolve : reject;
        return action(result);
    })
}