import React from 'react';
import { InputText } from 'primereact/inputtext';
import { LookupField } from '../../../components/lookup/LookupField';

export const MenuForm = (props) => {
    const record = props.record;
    const menuParent = props.record ? props.record.menuParent : ""

    return (
        <div className="pessoa-form">
            <div className="p-grid">
                <div className="p-col-6">
                    <div className="p-grid">
                        <div className="p-col-3">
                            <div className="form-field-container">
                                <label>Código</label>
                                <InputText value={props.record.oid} onChange={props.changeHandler} disabled={true} />
                            </div>

                        </div>

                        <div className="p-col-12 form-field-container">
                            <label>Nome*</label>
                            <InputText value={props.record.name} onChange={props.changeHandler} key={"name"} name="name" />
                        </div>
                        <div className="p-col-3 form-field-container">
                            <label>Icone</label>
                            <InputText value={record.icon} onChange={props.changeHandler} key={"icon"} name="icon" />
                        </div>
                        <div className="p-col-9 form-field-container">
                            <label>Caminho</label>
                            <InputText value={props.record.path} onChange={props.changeHandler} key={"path"} name="path" />
                        </div>

                        <div className="p-col-8 form-field-container">
                            <label>Chave*</label>
                            <InputText value={record.key} onChange={props.changeHandler}  key={"key"} name="key" />
                        </div>
                        <div className="p-col-4 form-field-container">
                            <label>Ordem*</label>
                            <InputText value={record.position} onChange={props.changeHandler} key={"position"} name="position" />
                        </div>
                        <div className="p-col-12 form-field-container">
                            <label>Menu Superior</label>
                            <LookupField
                                value={menuParent}
                                placeholder="Menu Superior"
                                name="name"
                                field="name"
                                onComplete={props.lookupMenuSuperiorOnComplete}
                                onInputFilter={props.lookupMenuSuperiorOnInputFilter}
                                editDisabled={props.lookupMenuSuperior.editDisabled}
                                onEditField={props.lookupMenuSuperiorOnEdit}
                                onClick={props.lookupMenuSuperiorOnClick}

                                visible={props.lookupMenuSuperior.visible}
                                modal={props.lookupMenuSuperior.modal}
                                header={props.lookupMenuSuperior.header}
                                onHide={props.lookupMenuSuperiorOnHide}
                                onConfirm={props.lookupMenuSuperiorOnConfirm}
                                onCancel={props.lookupMenuSuperiorOnCancel}
                                records={props.lookupMenuSuperior.records}
                                columns={props.lookupMenuSuperior.columns}
                                totalRecords={props.lookupMenuSuperior.totalRecords}
                                sortOrder={props.lookupMenuSuperior.order}
                                sortField={props.lookupMenuSuperior.sortField}
                                first={props.lookupMenuSuperior.first}
                                rows={props.lookupMenuSuperior.rows}
                                onPage={props.lookupMenuSuperiorOnPage}
                                onFilter={props.lookupMenuSuperiorOnFilter}
                                onSort={props.lookupMenuSuperiorOnOnSort}
                                paginator={true}
                                rowsPerPageOptions={props.lookupMenuSuperior.rowsPerPageOptions} 

                            />

                        </div>

                        {/* <div className="p-col-12 form-field-container">
                            <label>Módulo*</label>
                            <LookupField
                                value={props.record.nomeModulo}
                                placeholder="Módulo"
                                name="nomeModulo"
                                field="nome"
                                onComplete={props.lookupModuloOnComplete}
                                onInputFilter={props.lookupModuloOnInputFilter}
                                editDisabled={props.lookupModulo.editDisabled}
                                onEditField={props.lookupModuloOnEdit}
                                onClick={props.lookupModuloOnClick}

                                visible={props.lookupModulo.visible}
                                modal={props.lookupModulo.modal}
                                header={props.lookupModulo.header}
                                onHide={props.lookupModuloOnHide}
                                onConfirm={props.lookupModuloOnConfirm}
                                onCancel={props.lookupModuloOnCancel}
                                records={props.lookupModulo.records}
                                columns={props.lookupModulo.columns}
                                totalRecords={props.lookupModulo.totalRecords}
                                sortOrder={props.lookupModulo.order}
                                sortField={props.lookupModulo.sortField}
                                first={props.lookupModulo.first}
                                rows={props.lookupModulo.rows}
                                onPage={props.lookupModuloOnPage}
                                onFilter={props.lookupModuloOnFilter}
                                onSort={props.lookupModuloOnOnSort}

                            />
                        </div> */}

                    </div>
                </div>
            </div>
        </div>


    )
};