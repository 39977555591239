import { validate } from './validations';
import { build } from '../../services/crudFactory';
import { Action } from '../../services/actions.factory';
const BASE_PATH = '/lognex/menus';
const prefix = 'crudMenu';
const crud = build(validate, BASE_PATH);

const newTypes = {
    SET_LOOKUP_MENU_SUPERIOR_VISIBLE: `${prefix}setLoockupMenuSuperiorVisible`,
    SET_LOOKUP_MODULO_VISIBLE: `${prefix}setLoockupModuloVisible`,
}

const actions = new Action(prefix, crud);

const types = Object.assign({}, actions.types, newTypes);

actions.setLookupMenuVisible = (visible, type) => {
    return {
        type: type,
        visible
    }
}
export { types, actions };