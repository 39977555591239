import { types } from "./menu.actions";
import { Reducer } from "../../services/reducer.factory";

const initialState = {
  columns: [
    {
      field: "oid",
      header: "Código",
      columnKey: "oid",
      sortable: false,
      filter: false
    },
    {
      field: "name",
      header: "Nome",
      columnKey: "name",
      sortable: false,
      filter: true
    },
    {
      field: "parent",
      header: "ID Menu Pai",
      columnKey: "parent",
      sortable: false,
      filter: true
    }, {
      field: "menuParent",
      header: "Nome menu Pai",
      columnKey: "menuParent",
      sortable: false,
      filter: true
    },
    
    {
      field: "position",
      header: "Posição",
      columnKey: "position",
      sortable: false,
      filter: true
    },
    {
      field: "path",
      header: "Caminho",
      columnKey: "path",
      sortable: false,
      filter: true
    },
    {
      field: "icon",
      header: "Icone",
      columnKey: "icon",
      sortable: false,
      filter: false
    },
    {
      field: "key",
      header: "Chave",
      columnKey: "key",
      sortable: false,
      filter: true
    }
  ],
  currentRecord: {
    oid: "",
    name: "",
    path: "",
    icon: "",
    menuParent:""
  },
  lookupMenuSuperior: {
    visible: false,
    modal: true,
    header: 'Menus'
  },
  lookupModulo: {
    visible: false,
    modal: true,
    header: 'Módulos'
  }
};

const reducer = new Reducer(initialState, types);

export function CRUDMenuReducer(state, action) {
  state = reducer.execute(state, action);
  return execute(state, action);
}

function execute(state, action) {
  let result = state;
  switch (action.type) {
    case types.SET_LOOKUP_MENU_SUPERIOR_VISIBLE:
      return setLoockupMenuSuperiorVisible(state, action);
    case types.SET_LOOKUP_MODULO_VISIBLE:
      return setLoockupModuloVisible(state, action);
    default:
      return result;
  }
}

function setLoockupMenuSuperiorVisible(state, action) {
  let lookupMenuSuperior = state.lookupMenuSuperior;
  lookupMenuSuperior = Object.assign({}, lookupMenuSuperior, { visible: action.visible });
  return Object.assign({}, state, { lookupMenuSuperior })
}

function setLoockupModuloVisible(state, action) {
  let lookupModulo = state.lookupModulo;
  lookupModulo = Object.assign({}, lookupModulo, { visible: action.visible });
  return Object.assign({}, state, { lookupModulo })
}