const LOADER_SHOW = 'loader.show';
const LOADER_HIDE = 'loader.hide';


export const types = {
    LOADER_SHOW,
    LOADER_HIDE
}

export function loaderShow(){
    return {
        type : LOADER_SHOW,
        show:true
    }
}

export function loaderHide(){
    return {
        type : LOADER_HIDE,
        show: false
    }
}