import React from "react";
 
export class BoxGrid extends React.Component {
  render() {
    const onClick = (e) => {
      if (this.props.onClick) {
        this.props.onClick(e);
      }
    };
    return (
      <div className={`${this.props.isSelected ? "grid-box-selected" : "grid-box" } `} onClick={onClick}>
        <h1 className="title">{this.props.title}</h1>

        <p className="content">{this.props.content}</p>

        <div className="footer">{this.props.footer}</div>
      </div>
    );
  }
}
