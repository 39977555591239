import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import './tracking.scss'
export const TrackingList = (props) => (
        <DataTable        
                className="lxTrackingTable"
                selectionMode="single"
                onFilter={props.onFilter}
                onSort={props.onSort}
                onPage={props.onPage}
                value={props.records}
                lazy={true}
                totalRecords={props.totalRecords}
                sortOrder={props.order}
                sortField={props.sortField}
                paginator={true}
                first={props.first}
                rows={props.rows}
                columnResizeMode="expand"
                responsive={true}
                onRowClick={props.onRowClick}
                emptyMessage="Não foram encontrados registros"
                rowsPerPageOptions={[5, 10, 20, 50]}
                onRowToggle={props.trackingTbRowToggleHandler}
                rowExpansionTemplate={props.trackingTbRowExpasionTemplateHandler}
                expandedRows={props.trackingTBExpandedRows}
                
        >
                {[<Column key={"expander"} expander={true} style={{width: '4em'}} />,...props.columns]}

        </DataTable>
)
