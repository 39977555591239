import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Toolbar from "../../../components/toolbar/Toolbar";
import { Column } from "primereact/column";
import { routes } from "../../../constants";
import { Page } from "../../../components/page/Page";
import { TowerEventControlList } from "./TowerEventControlList";
import { actions } from "../tower_event_control.actions";
import {
  AppService,
  COMPANY_CHANGE_EVENT_NAME,
  BUSINESS_UNIT_CHANGE_EVENT_NAME,
} from "../../../services/app.service";

const mapStateToProps = (state) => {
 
  return {
    columns: state.crudTowerEventControlState.columns,
    records: state.crudTowerEventControlState.records,
    first: state.crudTowerEventControlState.index,
    rows: state.crudTowerEventControlState.max,
    totalRecords: state.crudTowerEventControlState.count,
    sortField: state.crudTowerEventControlState.sortField,
    order: state.crudTowerEventControlState.sortOrder,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    paginate: (paginationParams) =>
      dispatch(actions.paginate(paginationParams)),
    sortOrder: (sortParams) => dispatch(actions.sortOrder(sortParams)),
    applyFilter: (filterParams) => {
      dispatch(actions.setType(null));
      dispatch(actions.applyFilter(filterParams));
    },
    load: () => {
      dispatch(actions.load());
    },
    setSelectedRecords: (selectedRecords) => {
      dispatch(actions.setSelectedRecords(selectedRecords));
    },
  };
};

class TowerEventControlListPageContainer extends React.Component {
  newHandler = () => {
    this.props.history.push(`${routes.PATH_TOWER_EVENT}/novo`);
  };

  componentDidMount() {
    this.props.load();
    AppService.on(COMPANY_CHANGE_EVENT_NAME, this);
    AppService.on(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
  }
  componentWillUnmount() {
    AppService.off(COMPANY_CHANGE_EVENT_NAME, this);
    AppService.off(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
  }

  onReceiveAppNotifications = (eventName, params) => {
    if (
      eventName === COMPANY_CHANGE_EVENT_NAME ||
      eventName === BUSINESS_UNIT_CHANGE_EVENT_NAME
    ) {
      this.props.applyFilter([]);
    }
  };
  onPage = (e) => {
    this.props.paginate({
      max: e.rows,
      index: e.first,
    });
  };
  onSort = (e) => {
    this.props.sortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField,
    });
  };

  onFilter = (e) => {
    const filters = [];
    const keys = Object.keys(e.filters) || [];
    keys.forEach((key) => {
      filters.push({
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: "CONTEM",
      });
    });
    this.props.applyFilter(filters);
  };

  onRowClick = (e) => {
    const oid = e && e.data && e.data.oid;
    if (oid) {
      this.props.history.push(`${routes.PATH_TOWER_EVENT}/${oid}`);
    }
  };

  render() {
    const header = (
      <div>
        <h1>Controle de Eventos</h1>
        <hr className="lxSplitter"></hr>
        <div>
          <Toolbar
            onNew={this.newHandler}
            newButtonStyle="highlighted"
          ></Toolbar>
        </div>
      </div>
    );

    const columns = this.props.columns.map((col) => {
      return <Column {...col} key={col.field} />;
    });

    const content = (
      <TowerEventControlList
        {...this.props}
        onRowClick={this.onRowClick}
        onFilter={this.onFilter}
        onPage={this.onPage}
        onSort={this.onSort}
        columns={columns}
      />
    );
    return <Page header={header} content={content} />;
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TowerEventControlListPageContainer);
