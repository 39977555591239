  
    export function validate(record){
        const errors = [];

        if(!record.zipCodeRanges  ||record.zipCodeRanges.length == 0 || !record.zipCodeRanges[0].start || record.zipCodeRanges[0].start.toString().trim() === ""){
            errors.push({field:'initialZipCode',message:'O CEP inicial é obrigatório!'});
        }

        if(record.zipCodeRanges && record.zipCodeRanges.length >0 && record.zipCodeRanges[0].start && record.zipCodeRanges[0].start.toString().length !== 8){
            errors.push({field:'initialZipCode',message:'O CEP inicial informado não contem 8 digitos!'});
        }

        if(!record.zipCodeRanges ||record.zipCodeRanges.length == 0 || !record.zipCodeRanges[0].end || record.zipCodeRanges[0].end.toString().trim() === ""){
            errors.push({field:'finalZipCode',message:'O CEP final é obrigatório!'});
        }

        if(record.zipCodeRanges && record.zipCodeRanges.length > 0 && record.zipCodeRanges[0].end && record.zipCodeRanges[0].end.toString().length !== 8){
            errors.push({field:'finalZipCode',message:'O CEP final informado não contem 8 digitos!'});
        }

        if(!record.name || record.name.trim() === ""){
            errors.push({field:'name',message:'O nome da faixa de CEP é obrigatório!'});
        }
        if( record.zipCodeRanges && record.zipCodeRanges.length > 0){
            record.zipCodeRanges[0].start = parseInt(record.zipCodeRanges[0].start);
            record.zipCodeRanges[0].end = parseInt(record.zipCodeRanges[0].end);   
        }
             
        const result =  {
            ok: errors.length === 0,
            errors,
            record
        }
    
        return new Promise((resolve,reject)=>{
            const action = result.ok ? resolve : reject;
        return action(result);
        })
        
    
    }