import React from 'react';
import { ButtonDialog } from '../../button-dialog/ButtonDialog';
import FileUploaderButton from '../../file-uploader-button/FileUploaderButton';

export const LxButtonActions = (props) => {
    const buttons = []

    if (props.onAdd) {
        buttons.push(
            (<button
                key="add"
                title={props.hint.add}
                onClick={props.onAdd}
                style={{ marginRight: ".25em", marginBottom: ".25em" }}
                className="lognex-btn-highlighted"
                disabled={props.disabled ? props.disabled.add : false}>
                <i className="pi pi-plus"></i>
            </button>)
        )
    }
    if (props.onEdit) {
        buttons.push(
            (<button
                key="edit"
                title={props.hint.edit}
                onClick={props.onEdit}
                style={{ marginRight: ".25em", marginBottom: ".25em" }}
                className="lognex-btn-highlighted"
                disabled={props.disabled ? props.disabled.edit : false}>
                <i className="pi pi-pencil"></i>
            </button>)
        )
    }

    if (props.onDuplicate) {
        buttons.push(
            (<button
                key="duplicate"
                title={props.hint.duplicate}
                onClick={props.onDuplicate}
                style={{ marginRight: ".25em", marginBottom: ".25em" }}
                className="lognex-btn-highlighted"
                disabled={props.disabled ? props.disabled.duplicate : false}>
                <i className="pi pi-clone"></i>
            </button>)
        )
    }
    if (props.onRemove) {
        buttons.push(
            <ButtonDialog
                key="remove"
                onClick={props.onRemove}
                button={{
                    title: props.hint.remove,
                    className: "lognex-btn-danger",
                    disabled:props.disabled ? props.disabled.remove : false
                }}
                dialog={{
                    header: 'Confirmação',
                    modal: false,
                    message: props.removeMessage ? props.removeMessage : "Deseja realmente remover o registro?",
                }}
            ></ButtonDialog>
            
        )
    }
    
    if(props.XLSXTemplatePath){
        buttons.push(<a key="template" href={props.XLSXTemplatePath}
            title={props.titleBtnDownload ? props.titleBtnDownload : "Download Template"}>
            <button
                key="downloadTemplate"
                style={{ marginRight: ".25em", marginBottom: ".25em" }}
                className="lognex-btn-highlighted-inverter"
            >
                <i className="pi pi-download"></i>
            </button>
        </a>);
    }

    if(props.importxls){
        buttons.push( <FileUploaderButton key="importxlsx" onUpload={props.onImportXLSX}/>)
    }
    return (

        <div className="p-grid">
            <div className="p-col-12">
                {buttons}
                <h3>{props.header}</h3>
            </div>
        </div>
    )
}