import React from 'react';
import { SideButton } from './SidebarButton';
import './Sidebar.scss';
import logo from '../../assets/images/logo/logo-reverse.png';
import history from '../../history';
export class SideBar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            closed: false
        }
    }

    onToggleHandler = () => {
        this.setState((prevState) => {
            return {
                closed: !prevState.closed
            }
        })
    }
    onClickLogoHandler = () => {
        history.replace(`/`);
    }

    render() {
        const classes = ['lognex-sidebar'];
        if (this.state.closed) {
            classes.push('closed');
        }
        return (
            <div className={classes.join(' ')} >
                <div className="lognex-brand">
                    
                    <SideButton closed={this.state.closed} clickHandler={this.onToggleHandler} />
                    <div className="lognex-brand__logo">
                        <img src={logo} alt="logo Lognex" onClick={this.onClickLogoHandler} style={{ cursor: "pointer" }} />
                    </div>
                </div>
                <div className="content">
                    {this.props.content}
                </div>
            </div>
        )
    }
}
