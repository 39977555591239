import React from 'react';

import ClientFormPageContainer from './ClientFormPageContainer';

export class ClientFormPage extends React.Component{

    
    render(){
        return (
           <ClientFormPageContainer {...this.props} />
        )
    }
}