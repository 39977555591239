import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column"; 
import { ContextMenu } from 'primereact/contextmenu'; 
export class AuditRecordTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedRowData: null,
    };

    this.menuModel = [{ label: "View", icon: "pi pi-fw pi-search", command: () => this.viewProduct(this.state.selectedRowData) }];
  }
  viewProduct = (rowData) => {
    console.log("rowData=", rowData);
  };

  render() {
    return (
      <div>
        <ContextMenu model={this.menuModel} ref={(el) => (this.cm = el)} onHide={() => this.setState({ selectedRowData: null })} />

        <div style={{ minHeight: "300px" }}>
          <span>teste</span>
          <DataTable
            contextMenuSelection={this.state.selectedRowData}
            onContextMenuSelectionChange={(e) => this.setState({ selectedRowData: e.value })}
            onContextMenu={(e) => this.cm.show(e.originalEvent)}
            selectionMode="single"
            onFilter={this.props.onFilter}
            onSort={this.props.onSort}
            onPage={this.props.onPage}
            value={this.props.records}
            lazy={true}
            sortField={this.props.sortField}
            totalRecords={this.props.totalRecords}
            first={this.props.first}
            rows={this.props.rows}
            paginator={true}
            emptyMessage={this.props.emptyMessage}
            rowsPerPageOptions={[5, 10, 20, 50, 100, 200]}
            scrollable={true}
            scrollHeight={"calc(100vh - 510px)"}
            onRowToggle={this.props.rowExpandButtonOnClick}
            // onRowClick={this.props.rowToggleHandler}
            rowExpansionTemplate={this.props.templateExpandedRow}
            expandedRows={this.props.expandedRows}
            selection={this.props.selection}
            onSelectionChange={this.props.onSelectionChange}
          >
            {this.props.viewFilter === "divergencies"
              ? [
                  <Column key={"auditTable-check"} selectionMode="multiple" headerStyle={{ width: "3em" }}></Column>,
                  <Column expander={true} key={"auditTable"} style={{ width: "4em" }} />,
                  ...this.props.auditRecordColumns,
                ]
              : [<Column expander={true} key={"auditTable"} style={{ width: "4em" }} />, ...this.props.auditRecordColumns]}
          </DataTable>
        </div>
      </div>
    );
  }
}
