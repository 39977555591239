import { types } from "./modulo.actions";
import { Reducer } from "../../services/reducer.factory";

const initialState = {
  columns: [
    {
      field: "oid",
      header: "Código",
      columnKey: "oid",
      sortable: true,
      filter: true
    },
    {
      field: "nome",
      header: "Nome",
      columnKey: "nome",
      sortable: true,
      filter: true
    },
    {
      field: "descricao",
      header: "Descrição",
      columnKey: "descricao",
      sortable: true,
      filter: true
    }
    
  ],
  currentRecord: {
    oid: "",
    descricao: "",
    nome: ""
  }
};

const reducer = new Reducer(initialState, types);

export function CRUDModuloReducer(state, action) {
  return reducer.execute(state, action);
}

