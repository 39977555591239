import moment from 'moment';
import { validate } from './validations';
import { build } from '../../services/crudFactory';
import { Action } from '../../services/actions.factory';
import api from '../../services/api.service';
const BASE_PATH = '/lognex/freighttables';
const prefix = 'crudFreightTable';
const crud = build(validate, BASE_PATH);

const newTypes = {
    SET_LOOKUP_BUSINESSUNIT_VISIBLE: `${prefix}setLookupBusinessUnitVisible`,
    SET_LOOKUP_CARRIER_VISIBLE: `${prefix}setLookupCarrierVisible`,
    SET_LOOKUP_PARENTFREIGHTTABLE_VISIBLE: `${prefix}setLookupParentFreightTableVisible`,
    SET_LOOKUP_MODAL_VISIBLE: `${prefix}setLookupModalVisible`,
    SET_LOOKUP_VEHICLEGROUP_VISIBLE: `${prefix}setLookupVehicleGroupVisible`,
    SET_LOOKUP_ROUTE_VISIBLE: `${prefix}setLookupRouteVisible`,
    SET_LOOKUP_COMPONENTRANGETYPE_VISIBLE: `${prefix}setLookupComponentRangeTypeVisible`,
    SET_LOOKUP_GENERAL_FREIGHTCOMPONENT_VISIBLE: `${prefix}setLookupGeneralFreightComponentVisible`,
    SET_LOOKUP_ROUTE_RANGE_FREIGHTCOMPONENT_VISIBLE: `${prefix}setLookupRouteRangeFreightComponentVisible`,
    SET_DIALOG_ADD_ROUTES_VISIBLE: `${prefix}setDialogAddRoutesVisible`,
    ADD_ROUTE: `${prefix}addRoute`,
    REMOVE_SELECTED_CALCULATIONRANGES: `${prefix}removeSelectedCalculationRanges`,
    ADD_CALCULATIONRANGE: `${prefix}addCalculationRange`,
    REMOVE_SELECTED_BUSINESSUNITS: `${prefix}removeSelectedBusinessUnits`,
    SET_DIALOG_ADD_BUSINESSUNITS_VISIBLE: `${prefix}setDialogAddBusinessUnitsVisible`,
    ADD_BUSINESSUNIT: `${prefix}addBusinessUnit`,
    SET_DIALOG_ADD_GENERALFREIGHTCOMPONENTS_VISIBLE: `${prefix}setDialogAddGeneralFreightComponentsVisible`,
    ADD_GENERALFREIGHTCOMPONENT: `${prefix}addGeneralFreightComponent`,
    SET_DIALOG_ADD_ROUTE_RANGE_FREIGHTCOMPONENTS_VISIBLE: `${prefix}setDialogAddRouteRangeFreightComponentsVisible`,
    ADD_ROUTE_RANGE_FREIGHTCOMPONENT: `${prefix}addRouteRangeFreightComponent`,
    GET_NEGOTIATION_TYPES: `${prefix}getNegotiationTypes`,
    UPDATE_GENERAL_FREIGHT_COMPONENT: `${prefix}updateGeneralFreightComponent`,
    REMOVE_GENERAL_FREIGHT_COMPONENT: `${prefix}removeGeneralFreightComponent`,
    UPDATE_ROUTE_RANGE_FREIGHT_COMPONENT: `${prefix}updateRouteRangeFreightComponent`,
    REMOVE_ROUTE_RANGE_FREIGHT_COMPONENT: `${prefix}removeRouteRangeFreightComponent`,
    SET_CURRENT_BUSINESSUNIT: `${prefix}setCurrenBusinessUnit`,
    SET_SELECTED_BUSINESSUNITS: `${prefix}setSelectedBusinessUnits`,
    SET_CURRENT_ROUTE: `${prefix}setCurrentRoute`,
    SET_SELECTED_ROUTE: `${prefix}setSelectedRoute`,
    SET_SELECTED_ROUTES: `${prefix}setSelectedRoutes`,
    REMOVE_SELECTED_ROUTES: `${prefix}removeSelectedRoutes`,
    SET_DIALOG_RANGE_VISIBLE: `${prefix}setDialogRangeVisible`,
    SET_DIALOG_EDIT_SERIE_VISIBLE: `${prefix}setDialogEditSerieVisible`,
    ADD_RANGE: `${prefix}addRange`,
    SET_SELECTED_RANGE: `${prefix}setSelectedRange`,
    SET_SELECTED_SERIE: `${prefix}setSelectedSerie`,
    CHANGE_COMPONENT_FROM_SELECTED_SERIE: `${prefix}changeComponentFromSelectedSerie`,
    REMOVE_COMPONENT_FROM_SELECTED_SERIE: `${prefix}removeComponentFromSelectedSerie`,
    CHANGE_COMPONENT_FROM_GENERAL_COMPONENTS: `${prefix}changeComponentFromGeneralComponents`,
    REMOVE_COMPONENT_FROM_GENERAL_COMPONENTS: `${prefix}removeComponentFromGeneralComponents`,
    CHANGE_VISIBILITY_DIALOG_ADD_COMPONENT_INTO_SELECTED_SERIE: `${prefix}changeVisibilityDialogAddComponentSelectedSerie`,
    CHANGE_VISIBILITY_DIALOG_ADD_COMPONENT_INTO_GENERAL_COMPONENTS: `${prefix}changeVisibilityDialogAddComponentGeneralComponents`,
    SET_LOOKUP_COMPONENT_VISIBLE: `${prefix}setLookupComponentVisible`,
    SET_CURRENT_COMPONENT: `${prefix}setCurrentComponent`,
    ADD_COMPONENT_SELECTED_SERIE: `${prefix}addComponentSelectedSerie`,
    ADD_COMPONENT_GENERAL_COMPONENTS: `${prefix}addComponentGeneralComponents`,
    REMOVE_SELECTED_RANGE: `${prefix}removeSelectedRange`,
    ADD_RESTRICTION_ON_RESTRICTION_TABLE: `${prefix}addRestrictionOnRestrictionTable`,
    REMOVE_RESTRICTION_ON_RESTRICTION_TABLE: `${prefix}removeRestrictionOnRestrictionTable`,
    DELETE_ADDED_CLIENTS: `${prefix}deleteAddedClients`,
    DELETE_ADDED_LOCATIONS: `${prefix}deleteAddedLocations`,
    SET_ADD_RESTRICTION_BUTTON_STATE: `${prefix}setAddRestrictionButtonState`,
    SET_EDIT_RESTRICTION_BUTTON_STATE: `${prefix}setEditRestrictionButtonState`,
    SET_DIALOG_ADD_RESTRICTIONS_VISIBLE: `${prefix}setDialogAddRestrictionsVisible`,
    SET_CURRENT_SELECTED_COMPONENT: `${prefix}setCurrentSelectedComponent`,
    SET_DIALOG_COMPONENT_NAME: `${prefix}setdialogComponentName`,
    SET_DIALOG_COMPONENT_RESTRICTION_LOOKUP_CLIENT_VISIBLE: `${prefix}setDialogComponentRestrictionLookupClientVisible`,
    SET_DIALOG_COMPONENT_RESTRICTION_LOOKUP_LOCATION_VISIBLE: `${prefix}setDialogComponentRestrictionLookupLocationVisible`,
    LOOKUP_CLIENT_SET_VISIBLE: `${prefix}lookupClientSetVisible`,
    SET_LOOKUP_CLIENT_SELECTED_RECORDS: `${prefix}setlookupClientSelectedRecords`,
    LOOKUP_CLIENT_ADD_RECORDS: `${prefix}lookupClientAddRecords`,
    LOOKUP_LOCATION_SET_VISIBLE: `${prefix}lookupLocationSetVisible`,
    SET_LOOKUP_LOCATION_SELECTED_RECORDS: `${prefix}setLookupLocationSelectedRecords`,
    LOOKUP_LOCATION_ADD_RECORDS: `${prefix}lookupLocationAddRecords`,
    SET_CLIENTS_ON_CURRENT_RESTRICTION_COMPONENT: `${prefix}setClientsOnCurrentRestrictionComponent`,
    SET_LOCATIONS_ON_CURRENT_RESTRICTION_COMPONENT: `${prefix}setLocationsOnCurrentRestrictionComponent`,
    SET_BUSINESS_UNITS_ON_CURRENT_RESTRICTION_COMPONENT: `${prefix}setBusinessUnitsOnCurrentRestrictionComponent`,
    SET_RESTRICTIONS_TO_DUPLICATE: `${prefix}setRestrictionsToDuplicate`,
    DUPLICATE_CLIENTS_RESTRICTIONS: `${prefix}duplicateClientsRestrictions`,
    DUPLICATE_LOCATIONS_RESTRICTIONS: `${prefix}duplicateLocationsRestrictions`,
    SET_CURRENT_SELECTED_COMPONENT_ON_DROPDOWN: `${prefix}setCurrentSelectedComponentOnDropdown`,
    REMOVE_SELECTED_CLIENTS: `${prefix}removeSelectedClients`,
    REMOVE_SELECTED_CLIENTS_FROM_RESTRICTION_COMPONENT: `${prefix}removeSelectedClientsFromRestrictionComponent`,
    REMOVE_SELECTED_LOCATIONS_FROM_RESTRICTION_COMPONENT: `${prefix}removeSelectedLocationsFromRestrictionComponent`,
    REMOVE_SELECTED_BUSINESS_UNITS_FROM_RESTRICTION_COMPONENT: `${prefix}removeSelectedBusinessUnitsFromRestrictionComponent`,
    REMOVE_SELECTED_LOCATIONS: `${prefix}removeSelectedLocations`,
    SET_LOOKUPTABLE_CLIENTS: `${prefix}setLookupTableClients`,
    SET_LOOKUPTABLE_LOCATIONS: `${prefix}setLookupTableLocations`,
    SET_CURRENT_CLIENT_NAME: `${prefix}setCurrentClientName`,
    LOOKUPCLIENT_SET_SEARCH_TEXT: `${prefix}lookupClientSetSearchText`,
    SET_CURRENT_CLIENT: `${prefix}setCurrentClient`,
    SET_DIALOG_DUPLICATE_ROUTE_VISIBLE: `${prefix}setDialogDuplicateRouteVisible`,
    SET_DUPLICATE_ROUTE: `${prefix}setDuplicateRoute`,
    SET_DUPLICATE_ROUTE_FIELD: `${prefix}setDuplicateRouteField`,
    SET_LOOKUP_ORIGIN_VISIBLE: `${prefix}setLookupOriginVisible`,
    SET_LOOKUP_DESTINATION_VISIBLE: `${prefix}setLookupDestinationVisible`,
    SET_SERIE: `${prefix}setSerie`,
    REMOVE_SELECTED_SERIE: `${prefix}removeSelectedSerie`,
    IMPORT_FILE_BY_CLIENT_XLSX: `${prefix}importFileByClientXLSX`,
    SEND_XLSX: `${prefix}sendXLSX`,
    RESULT_IMPORT_CLIENT_XLSX: `${prefix}resultImportClientXLSX`,
    RESULT_IMPORT_LOCATION_XLSX: `${prefix}resultImportLocationXLSX`,
    SET_ACTIVE_FILTER: `${prefix}setActiveFilter`,
    SET_INITIAL_VALIDITY_FILTER: `${prefix}setInitialValidityFilter`,
    SET_FINAL_VALIDITY_FILTER: `${prefix}setFinalValidityFilter`,
    SET_FIELD: `${prefix}setField`,
    ADD_TAG: `${prefix}addTag`,
    REMOVE_TAG: `${prefix}removeTag`,
    SET_LOOKUP_RESTRICTED_UNIT_VISIBLE: `${prefix}setLookupRestrictedUnitVisible`,
    SET_LOOKUP_BUSINESS_UNIT2: `${prefix}setLookupBusinessUnit2`,
    SET_BUSINESS_UNIT_RESTRICTED: `${prefix}setBusinessUnitRestricted`,
    SET_SELECTED_RECORDS_UNIT_RESTRICTED: `${prefix}setSelectedRecordsUnitRestricted`,
    SET_DIALOG_COMPONENT_RESTRICTION_LOOKUP_UNIT_VISIBLE: `${prefix}setDialogComponentRestrictionLookupUnitVisible`,
    DELETE_ADDED_BUSINESS_UNIT: `${prefix}deleteAddedBusinessUnits`,
    IMPORT_FILE_RANGE_CEP_XLSX: `${prefix}sendInforTabelaFreteXLSX`,
    CREATE_ROTA_FROM_RANGE_CEP_XLSX: `${prefix}createRotaFromZipCodeRangeXLS`,
    SET_STATUS_DIALOG_IMPORT_XLS_ROUTE: `${prefix}setDialogStatusImportXlsRoutes`,
    SET_VISIBLE_DIALOG_IMPORT_XLS_ROUTE: `${prefix}setVisibleDialogStatusImportXlsRoutes`,
    SET_RESULT_IMPORT_TABLE_XLS: `${prefix}showMessagesImportTable`,
    ADD_COMPOSITION: `${prefix}addComposition`,
    REMOVE_COMPOSITION: `${prefix}removeComposition`,
    CHANGE_OPERATION: `${prefix}changeOperation`,
    ADD_ENTRIES: `${prefix}addEntries`,
    REMOVE_ENTRIES: `${prefix}removeEntries`,
    UPDATE_ENTRY: `${prefix}updateEntry`,
    SHOW_DIALOG_STATUS_MSG_ERROR_IMPORT_TAXA_MULT_VAL: `${prefix}showDialogStatusImportXlsTaxaMultValores`,
    LOAD_TABLE_BY_ID: `${prefix}loadTableById`,
    SAVE_ENTRIE: `${prefix}saveEntrie`,
    SET_ENTRIES_GENERAL_COMPONENT: `${prefix}setEntriesGeneralComponent`,
    CLEAR_CURRENT_RECORD: `${prefix}clearCurrentRecord`,
    SET_AUXILIAR_MULTIENTRIES: `${prefix}setAuxiliarMultiEntries`,
    ADD_IGNORE_COMPONENT: `${prefix}addIgnoreComponentList`,
    REMOVE_IGNORE_COMPONENT: `removeIgnoreComponentList`,
    EXPORT_XLSX_TEMPLATE_VTEX: `exportXlsxVtex`,
}

const beforeSave = (record) => {
    if (record && record.isOnlyMatriz) {
        record.businessUnits = []
    }
    return record;
}

const actions = new Action(prefix, crud, beforeSave);

const types = Object.assign({}, actions.types, newTypes);

actions.setAuxiliarMultiEntries = (count, idComponent, currentIndex) => {
    return {
        type: types.SET_AUXILIAR_MULTIENTRIES,
        count,
        idComponent,
        currentIndex,
    }
}

actions.clearCurrentRecord = () => {
    return {
        type: types.CLEAR_CURRENT_RECORD,
    }
}

actions.setEntriesGeneralComponent = (componentOid, entries) => {
    return {
        type: types.SET_ENTRIES_GENERAL_COMPONENT,
        componentOid,
        entries,
    }
}

actions.showDialogStatusImportXlsTaxaMultValores = (dialogStatusImportXlsTaxaMultValores) => {
    return {
        type: types.SHOW_DIALOG_STATUS_MSG_ERROR_IMPORT_TAXA_MULT_VAL,
        dialogStatusImportXlsTaxaMultValores
    }
}


actions.addIgnoreComponentList = (value) => {
    return {
        type: types.ADD_IGNORE_COMPONENT,
        value

    }
}

actions.removeIgnoreComponentList = (value) => {
    return {
        type: types.REMOVE_IGNORE_COMPONENT,
        value
    }
}

actions.removeComposition = (component, composition, index, componentType) => {
    return {
        type: types.REMOVE_COMPOSITION,
        component,
        composition,
        index,
        componentType
    }
}



actions.addComposition = (component, description, index, componentType) => {
    return {
        type: types.ADD_COMPOSITION,
        component,
        description,
        index,
        componentType
    }
}
actions.addEntries = (component, entries, index, componentType) => {
    return {
        type: types.ADD_ENTRIES,
        component,
        entries,
        index,
        componentType
    }
}
actions.removeEntries = (component, entries, index, componentType) => {
    return {
        type: types.REMOVE_ENTRIES,
        component,
        entries,
        index,
        componentType
    }
}
actions.updateEntry = (component, entry, index, indexEntry, componentType) => {
    return {
        type: types.UPDATE_ENTRY,
        component,
        entry,
        index,
        indexEntry,
        componentType
    }
}

actions.changeOperation = (component, name, value, index, componentType) => {
    return {
        type: types.CHANGE_OPERATION,
        component,
        name,
        value,
        index,
        componentType
    }
}

actions.setDialogStatusImportXlsRoutes = (statusCode) => {
    return {
        type: types.SET_STATUS_DIALOG_IMPORT_XLS_ROUTE,
        statusCode
    }
}

actions.setVisibleDialogStatusImportXlsRoutes = (visible) => {
    return {
        type: types.SET_VISIBLE_DIALOG_IMPORT_XLS_ROUTE,
        visible
    }
}

actions.addTag = (value) => {
    return {
        type: types.ADD_TAG,
        value
    }
}

actions.removeTag = (value) => {
    return {
        type: types.REMOVE_TAG,
        value
    }
}

actions.setField = (field, value) => {
    return {
        type: types.SET_FIELD,
        field,
        value
    }
}

actions.setFinalValidityFilter = (value) => {
    return {
        type: types.SET_FINAL_VALIDITY_FILTER,
        value
    }
}

actions.setInitialValidityFilter = (value) => {
    return {
        type: types.SET_INITIAL_VALIDITY_FILTER,
        value
    }
}

actions.setActiveFilter = (value) => {
    return {
        type: types.SET_ACTIVE_FILTER,
        value
    }
}

actions.removeSelectedRange = () => {
    return {
        type: types.REMOVE_SELECTED_RANGE
    }
}

actions.addComponentSelectedSerie = (component) => {
    return {
        type: types.ADD_COMPONENT_SELECTED_SERIE,
        component
    }
}

actions.addComponentGeneralComponents = (component) => {
    return {
        type: types.ADD_COMPONENT_GENERAL_COMPONENTS,
        component
    }
}

actions.addRestrictionOnRestrictionTable = (component) => {
    return {
        type: types.ADD_RESTRICTION_ON_RESTRICTION_TABLE,
        component
    }
}

actions.removeRestrictionOnRestrictionTable = (component) => {
    return {
        type: types.REMOVE_RESTRICTION_ON_RESTRICTION_TABLE,
        component
    }
}

actions.deleteAddedClients = (component) => {
    return {
        type: types.DELETE_ADDED_CLIENTS,
        component
    }
}

actions.deleteAddedLocations = (component) => {
    return {
        type: types.DELETE_ADDED_LOCATIONS,
        component
    }
}

actions.deleteAddedBusinessUnits = (component) => {
    return {
        type: types.DELETE_ADDED_BUSINESS_UNIT,
        component
    }
}

actions.setAddRestrictionButtonState = () => {
    return {
        type: types.SET_ADD_RESTRICTION_BUTTON_STATE
    }
}

actions.setEditRestrictionButtonState = () => {
    return {
        type: types.SET_EDIT_RESTRICTION_BUTTON_STATE
    }
}

actions.setDialogAddRestrictionsVisible = (visible) => {
    return {
        type: types.SET_DIALOG_ADD_RESTRICTIONS_VISIBLE,
        visible
    }
}

actions.setCurrentSelectedComponent = (component) => {
    return {
        type: types.SET_CURRENT_SELECTED_COMPONENT,
        component
    }
}

actions.setdialogComponentName = (component) => {
    return {
        type: types.SET_DIALOG_COMPONENT_NAME,
        component
    }
}

actions.setDialogComponentRestrictionLookupClientVisible = (component) => {
    return {
        type: types.SET_DIALOG_COMPONENT_RESTRICTION_LOOKUP_CLIENT_VISIBLE,
        component
    }
}

actions.setDialogComponentRestrictionLookupLocationVisible = (component) => {
    return {
        type: types.SET_DIALOG_COMPONENT_RESTRICTION_LOOKUP_LOCATION_VISIBLE,
        component
    }
}
actions.setDialogComponentRestrictionLookupUnitVisible = (component) => {
    return {
        type: types.SET_DIALOG_COMPONENT_RESTRICTION_LOOKUP_UNIT_VISIBLE,
        component
    }
}

actions.lookupClientSetVisible = (visible) => {
    return {
        type: types.LOOKUP_CLIENT_SET_VISIBLE,
        visible
    }
}

actions.setlookupClientSelectedRecords = (selectedRecords) => {
    return {
        type: types.SET_LOOKUP_CLIENT_SELECTED_RECORDS,
        selectedRecords
    }
}

actions.lookupClientAddRecords = (records) => {
    return {
        type: types.LOOKUP_CLIENT_ADD_RECORDS,
        records
    }
}

actions.lookupLocationSetVisible = (visible) => {
    return {
        type: types.LOOKUP_LOCATION_SET_VISIBLE,
        visible
    }
}

actions.setLookupLocationSelectedRecords = (selectedRecords) => {
    return {
        type: types.SET_LOOKUP_LOCATION_SELECTED_RECORDS,
        selectedRecords
    }
}

actions.lookupLocationAddRecords = (records) => {
    return {
        type: types.LOOKUP_LOCATION_ADD_RECORDS,
        records
    }
}

actions.setClientsOnCurrentRestrictionComponent = () => {
    return {
        type: types.SET_CLIENTS_ON_CURRENT_RESTRICTION_COMPONENT
    }
}

actions.setLocationsOnCurrentRestrictionComponent = () => {
    return {
        type: types.SET_LOCATIONS_ON_CURRENT_RESTRICTION_COMPONENT
    }
}
actions.setBusinessUnitsOnCurrentRestrictionComponent = () => {
    return {
        type: types.SET_BUSINESS_UNITS_ON_CURRENT_RESTRICTION_COMPONENT
    }
}

actions.setRestrictionsToDuplicate = () => {
    return {
        type: types.SET_RESTRICTIONS_TO_DUPLICATE
    }
}

actions.duplicateClientsRestrictions = (component) => {
    return {
        type: types.DUPLICATE_CLIENTS_RESTRICTIONS,
        component
    }
}

actions.duplicateLocationsRestrictions = (component) => {
    return {
        type: types.DUPLICATE_LOCATIONS_RESTRICTIONS,
        component
    }
}

actions.setCurrentSelectedComponentOnDropdown = (value) => {
    return {
        type: types.SET_CURRENT_SELECTED_COMPONENT_ON_DROPDOWN,
        value
    }
}

actions.removeSelectedClients = () => {
    return {
        type: types.REMOVE_SELECTED_CLIENTS
    }
}

actions.removeSelectedClientsFromRestrictionComponent = (selectedRecords) => {
    return {
        type: types.REMOVE_SELECTED_CLIENTS_FROM_RESTRICTION_COMPONENT,
        selectedRecords
    }
}

actions.removeSelectedLocationsFromRestrictionComponent = (selectedRecords) => {
    return {
        type: types.REMOVE_SELECTED_LOCATIONS_FROM_RESTRICTION_COMPONENT,
        selectedRecords
    }
}
actions.removeSelectedUnitsFromRestrictionComponent = (selectedRecords) => {
    return {
        type: types.REMOVE_SELECTED_BUSINESS_UNITS_FROM_RESTRICTION_COMPONENT,
        selectedRecords
    }
}

actions.removeSelectedLocations = () => {
    return {
        type: types.REMOVE_SELECTED_LOCATIONS
    }
}

actions.setLookupTableClients = () => {
    return {
        type: types.SET_LOOKUPTABLE_CLIENTS
    }
}

actions.setLookupTableLocations = () => {
    return {
        type: types.SET_LOOKUPTABLE_LOCATIONS
    }
}

actions.setCurrentClientName = (name) => {
    return {
        type: types.SET_CURRENT_CLIENT_NAME,
        name
    }
}

actions.lookupClientSetSearchText = (SearchText) => {
    return {
        type: types.LOOKUPCLIENT_SET_SEARCH_TEXT,
        SearchText
    }

}

actions.setCurrentClient = (client) => {
    return {
        type: types.SET_CURRENT_CLIENT,
        client
    }
}

actions.setCurrentComponent = (currentComponent) => {
    return {
        type: types.SET_CURRENT_COMPONENT,
        currentComponent
    }
}

actions.setLookupComponentVisible = (visible) => {
    return {
        type: types.SET_LOOKUP_COMPONENT_VISIBLE,
        visible
    }

}

actions.duplicateSelectedRecords = () => {
    return (dispatch, getState) => {
        const state = getState()[`${prefix}State`];
        const selectedRecords = state.selectedRecords || [];
        const duplicateds = []
        selectedRecords.forEach((record) => {
            const rec = Object.assign({}, record);
            delete rec.oid;
            rec.name = rec.name + '(cópia)'
            rec.initialValidity = moment(rec.initialValidity).toDate();
            rec.finalValidity = moment(rec.finalValidity).toDate();
            rec.restrictions = rec.restrictions || []
            duplicateds.push(actions.crud.insert(rec))
        })
        Promise.all(duplicateds)
            .then(() => {
                dispatch(actions.load());
                const messages = ["Registro duplicado com sucesso"];
                dispatch(
                    actions.messageActions.messageShowMessages(
                        actions.makeMessages(messages, "success")
                    )
                );
            })
            .catch(() => {
                const messages = ["Não foi possivel duplicar Registro selecionado"];
                dispatch(
                    actions.messageActions.messageShowMessages(
                        actions.makeMessages(messages, "error")
                    )
                );
            })
    }
}

actions.setVisibleDialogAddComponentIntoSelectedSerie = (visible) => {
    return {
        type: types.CHANGE_VISIBILITY_DIALOG_ADD_COMPONENT_INTO_SELECTED_SERIE,
        visible
    }
}
actions.setVisibleDialogAddComponentIntoGeneralComponents = (visible) => {
    return {
        type: types.CHANGE_VISIBILITY_DIALOG_ADD_COMPONENT_INTO_GENERAL_COMPONENTS,
        visible
    }
}

actions.removeComponentFromSelectedSerie = (component) => {
    return {
        type: types.REMOVE_COMPONENT_FROM_SELECTED_SERIE,
        component
    }
}

actions.changeComponentFromSelectedSerie = (component, field, value, index) => {
    return {
        type: types.CHANGE_COMPONENT_FROM_SELECTED_SERIE,
        component,
        field,
        value,
        index
    }
}
actions.removeComponentFromGeneralComponents = (component) => {
    return {
        type: types.REMOVE_COMPONENT_FROM_GENERAL_COMPONENTS,
        component
    }
}

actions.changeComponentFromGeneralComponents = (component, field, value) => {
    return {
        type: types.CHANGE_COMPONENT_FROM_GENERAL_COMPONENTS,
        component,
        field,
        value
    }
}

actions.setSelectedSerie = (serie) => {
    return {
        type: types.SET_SELECTED_SERIE,
        serie
    }
}

actions.setSelectedRange = (index) => {
    return {
        type: types.SET_SELECTED_RANGE,
        index
    }
}

actions.setDialogRouteRangeVisible = (visible) => {
    return {
        type: types.SET_DIALOG_RANGE_VISIBLE,
        visible
    }
}

actions.setDialogEditSerieVisible = (visible) => {
    return {
        type: types.SET_DIALOG_EDIT_SERIE_VISIBLE,
        visible
    }
}


actions.addRange = (rangeData) => {
    return {
        type: types.ADD_RANGE,
        rangeData
    }
}

actions.normalize = (record) => {
    if (record.initialValidity) {
        record.initialValidity = moment(record.initialValidity).toDate()
    }
    if (record.finalValidity) {
        record.finalValidity = moment(record.finalValidity).toDate()
    }
    return record;
}

actions.updateGeneralFreightComponent = (component, field, value) => {
    return {
        type: types.UPDATE_GENERAL_FREIGHT_COMPONENT,
        component,
        field,
        value
    }
}

actions.removeGeneralFreightComponent = (component) => {
    return {
        type: types.REMOVE_GENERAL_FREIGHT_COMPONENT,
        component
    }
}

actions.updateRouteRangeFreightComponent = (component, field, value) => {
    return {
        type: types.UPDATE_ROUTE_RANGE_FREIGHT_COMPONENT,
        component,
        field,
        value
    }
}

actions.removeRouteRangeFreightComponent = (component) => {
    return {
        type: types.REMOVE_ROUTE_RANGE_FREIGHT_COMPONENT,
        component
    }
}

actions.setCurrentRoute = (route) => {
    return {
        type: types.SET_CURRENT_ROUTE,
        route
    }
}

actions.getNegotiationTypes = (dispatch) => {
    api.get('/api/v1/lognex/negotiationTypes')
        .then(({ data }) => {
            dispatch({
                type: types.GET_NEGOTIATION_TYPES,
                negotiationTypes: data
            }
            )
        });
}

actions.loadNegotiationTypes = () => {
    return (dispatch, getState) => {
        actions.getNegotiationTypes(dispatch);
    };
}



actions.setLookupBusinessUnitVisible = (visible) => {
    return {
        type: types.SET_LOOKUP_BUSINESSUNIT_VISIBLE,
        visible
    }
}
actions.setLookupCarrierVisible = (visible) => {
    return {
        type: types.SET_LOOKUP_CARRIER_VISIBLE,
        visible
    }
}
actions.setLookupParentFreightTableVisible = (visible) => {
    return {
        type: types.SET_LOOKUP_PARENTFREIGHTTABLE_VISIBLE,
        visible
    }
}
actions.setLookupModalVisible = (visible) => {
    return {
        type: types.SET_LOOKUP_MODAL_VISIBLE,
        visible
    }
}
actions.setLookupVehicleGroupVisible = (visible) => {
    return {
        type: types.SET_LOOKUP_VEHICLEGROUP_VISIBLE,
        visible
    }
}
actions.setLookupRouteVisible = (visible) => {
    return {
        type: types.SET_LOOKUP_ROUTE_VISIBLE,
        visible
    }
}
actions.setLookupComponentRangeTypeVisible = (visible) => {
    return {
        type: types.SET_LOOKUP_COMPONENTRANGETYPE_VISIBLE,
        visible
    }
}
actions.setLookupGeneralFreightComponentVisible = (visible) => {
    return {
        type: types.SET_LOOKUP_GENERAL_FREIGHTCOMPONENT_VISIBLE,
        visible
    }
}

actions.setLookupRouteRangeFreightComponentVisible = (visible) => {
    return {
        type: types.SET_LOOKUP_ROUTE_RANGE_FREIGHTCOMPONENT_VISIBLE,
        visible
    }
}

actions.removeSelectedRoutes = () => {
    return {
        type: types.REMOVE_SELECTED_ROUTES
    }
}
actions.setSelectedRoute = (route) => {
    return {
        type: types.SET_SELECTED_ROUTE,
        route
    }
}
actions.setSelectedRoutes = (routes = []) => {
    return {
        type: types.SET_SELECTED_ROUTES,
        routes
    }
}

actions.addRoute = (route) => {
    return {
        type: types.ADD_ROUTE,
        route
    };
}

actions.setDialogRouteVisible = (visible) => {
    return {
        type: types.SET_DIALOG_ADD_ROUTES_VISIBLE,
        visible
    }
}

actions.removeSelectedCalculationRanges = () => {
    return {
        type: types.REMOVE_SELECTED_CALCULATIONRANGES
    }
}

actions.addCalculationRange = () => {
    return {
        type: types.ADD_CALCULATIONRANGE
    }
}



actions.removeSelectedBusinessUnits = () => {
    return {
        type: types.REMOVE_SELECTED_BUSINESSUNITS
    }
}

actions.addBusinessUnit = () => {
    return {
        type: types.ADD_BUSINESSUNIT
    }
}

actions.setDialogAddBusinessUnitsVisible = (visible) => {
    return {
        type: types.SET_DIALOG_ADD_BUSINESSUNITS_VISIBLE,
        visible
    }
}

actions.addGeneralFreightComponent = () => {
    return {
        type: types.ADD_GENERALFREIGHTCOMPONENT
    }
}

actions.setDialogAddGeneralFreightComponentsVisible = (visible) => {
    return {
        type: types.SET_DIALOG_ADD_GENERALFREIGHTCOMPONENTS_VISIBLE,
        visible
    }
}

actions.addRouteRangeFreightComponent = () => {
    return {
        type: types.ADD_ROUTE_RANGE_FREIGHTCOMPONENT
    }
}

actions.setDialogAddRouteRangeFreightComponentsVisible = (visible) => {
    return {
        type: types.SET_DIALOG_ADD_ROUTE_RANGE_FREIGHTCOMPONENTS_VISIBLE,
        visible
    }
}
actions.setCurrentBusinessUnit = (currentBusinessUnit) => {
    return {
        type: types.SET_CURRENT_BUSINESSUNIT,
        currentBusinessUnit
    }
}
actions.setSelectedBusinessUnits = (selectedBusinessUnits) => {
    return {
        type: types.SET_SELECTED_BUSINESSUNITS,
        selectedBusinessUnits
    }
}

actions.setDialogDuplicateRouteVisible = (visible) => {
    return {
        type: types.SET_DIALOG_DUPLICATE_ROUTE_VISIBLE,
        visible,
    }
}

actions.setDuplicateRoute = (route) => {
    return {
        type: types.SET_DUPLICATE_ROUTE,
        route
    }
}

actions.setDuplicateRouteField = (field, value) => {
    return {
        type: types.SET_DUPLICATE_ROUTE_FIELD,
        field,
        value
    }
}

actions.setLookupOriginVisible = (visible) => {
    return {
        type: types.SET_LOOKUP_ORIGIN_VISIBLE,
        visible
    }
}

actions.setLookupDestinationVisible = (visible) => {
    return {
        type: types.SET_LOOKUP_DESTINATION_VISIBLE,
        visible
    }
}

actions.setSerie = (serie) => {
    return {
        type: types.SET_SERIE,
        serie
    }
}

actions.removeSelectedSerie = () => {
    return {
        type: types.REMOVE_SELECTED_SERIE,
    }
}

actions.sendXLSX = () => {
    return {
        type: types.SEND_XLSX,
        async: true
    }
}

//criar uma action que atualiza a listagem de clientes
actions.resultImportClientXLSX = (data) => {
    return {
        type: types.RESULT_IMPORT_CLIENT_XLSX,
        data
    }
}
actions.resultImportLocationXLSX = (data) => {
    return {
        type: types.RESULT_IMPORT_LOCATION_XLSX,
        data
    }
}

actions.setLookupRestrictedUnitVisible = (visible) => {
    return {
        type: types.SET_LOOKUP_RESTRICTED_UNIT_VISIBLE,
        visible
    }
}

actions.setVisibleLookupRestrictedUnit = (visible) => {
    return {
        type: types.SET_LOOKUP_BUSINESS_UNIT2,
        visible
    }
}

actions.setTableRecordsBusinessUnitRestricted = (businessUnits) => {
    return {
        type: types.SET_BUSINESS_UNIT_RESTRICTED,
        businessUnits
    }
}
actions.setSelectedRecordsLookupUnitRestricted = (records) => {
    return {
        type: types.SET_SELECTED_RECORDS_UNIT_RESTRICTED,
        records
    }
}


//criar a action que faz o envio dos dados do arquivo para o servidor
actions.importFileByClientXLSX = (xlsx) => {
    return (dispatch) => {
        const URL = '/api/v1/lognex/freighttables/import/clients/xlsx';
        //enviar os dados do arquivo xlsx para o servidor
        dispatch(actions.sendXLSX());
        api.uploadFiles(URL, xlsx)
            .then(response => {
                // atualizar o log de sucesso ou erro no retorno do servidor
                const { records = [], totalMatchRecords = 0, totalRecords = 0 } = response.data
                dispatch(actions.resultImportClientXLSX(records))
                dispatch(actions.hideLoader());
                if (records.length === 0) {
                    const messages = [
                        "A planilha importada não possui registros válidos."
                    ];
                    dispatch(
                        actions.messageActions.messageShowMessages(actions.makeMessages(messages, "error"))
                    );
                } else {
                    const messages = [
                        `${totalMatchRecords}/${totalRecords} importados com sucesso`
                    ];
                    dispatch(
                        actions.messageActions.messageShowMessages(actions.makeMessages(messages, "info"))
                    );
                }
            })
            .catch((err) => {
                // atualizar o log de erro no retorno do servidor
                console.log(err);
                const messages = [
                    "Não foi possível processar a planilha."
                ];
                dispatch(
                    actions.messageActions.messageShowMessages(actions.makeMessages(messages, "error"))
                );
                dispatch(actions.hideLoader());
            })
    }
}

//criar a action que faz o envio dos dados do arquivo para o servidor
actions.importFileByLocationsXLSX = (xlsx) => {
    return (dispatch) => {
        const URL = '/api/v1/lognex/freighttables/import/locations/xlsx';
        //enviar os dados do arquivo xlsx para o servidor
        dispatch(actions.sendXLSX());
        api.uploadFiles(URL, xlsx)
            .then(response => {
                // atualizar o log de sucesso ou erro no retorno do servidor
                const { records = [], totalMatchRecords = 0, totalRecords = 0 } = response.data
                dispatch(actions.resultImportLocationXLSX(records))
                dispatch(actions.hideLoader());
                if (records.length === 0) {
                    const messages = [
                        "A planilha importada não possui registros válidos."
                    ];
                    dispatch(
                        actions.messageActions.messageShowMessages(actions.makeMessages(messages, "error"))
                    );
                } else {
                    const messages = [
                        `${totalMatchRecords}/${totalRecords} importados com sucesso`
                    ];
                    dispatch(
                        actions.messageActions.messageShowMessages(actions.makeMessages(messages, "info"))
                    );
                }
            })
            .catch((err) => {
                // atualizar o log de erro no retorno do servidor
                console.log(err);
                const messages = [
                    "Não foi possível processar a planilha."
                ];
                dispatch(
                    actions.messageActions.messageShowMessages(actions.makeMessages(messages, "error"))
                );
                dispatch(actions.hideLoader());
            })
    }
}

actions.sendInforTabelaFreteXLSX = (xlsx) => {
    return (dispatch, getState) => {
        const URL = '/api/v1/lognex/freighttables/import/rangecep/xlsx';
        const { oid } = getState().crudFreightTableState.currentRecord;
        dispatch(actions.setVisibleDialogStatusImportXlsRoutes(true));

        api.uploadFiles(URL, xlsx, { freightTableId: oid })
            .then(response => {
                const { records = [], erros = [] } = response.data

                if (records.length === 0 || erros.length > 0) {
                    // dispatch(actions.setVisibleDialogStatusImportXlsRoutes(false));
                    let messages = ["A planilha importada não possui registros válidos."];
                    if (erros.length > 0) {
                        messages = erros
                    }
                    dispatch(actions.showMessagesImportTable({ messages: [], isError: true, total: 0, erros: messages }));
                } else {
                    dispatch(actions.setDialogStatusImportXlsRoutes(1));
                    dispatch(actions.createRotaFromZipCodeRangeXLS(records))
                }
            })
            .catch((err) => {
                // atualizar o log de erro no retorno do servidor
                console.log(err);
                // dispatch(actions.setVisibleDialogStatusImportXlsRoutes(false));
                const messages = ["Não foi possível processar a planilha."];
                dispatch(actions.showMessagesImportTable({ messages: [], isError: true, total: 0, erros: messages }));
            })
    }
}



//zipcoderanges/rangecep

actions.createRotaFromZipCodeRangeXLS = (records) => {
    return (dispatch, getState) => {

        const { carrier } = getState().crudFreightTableState.currentRecord;
        const { oid, nomeEmpresa, CNPJ } = getState().appState.currentCompany

        if (!carrier) {
            dispatch(actions.setVisibleDialogStatusImportXlsRoutes(false));
            const messages = ["Selecione uma Transportadora!"];
            dispatch(actions.messageActions.messageShowMessages(actions.makeMessages(messages, "error")));
            return
        }

        const URL = `/api/v1/lognex/zipcoderanges/routeszipcoderange`;

        const body = {
            oidCarrier: carrier.oid,
            carrierName: carrier.name,
            carrierNumber: carrier.documentNumber,
            oidCompany: oid,
            companyName: nomeEmpresa,
            companyNumber: CNPJ
            , records
        }

        api.post(URL, body).then(response => {
            const { data } = response
            const { erros = [], routes = [] } = data

            if (routes.length === 0) {
                let messages = ["A planilha importada não possui registros válidos."];
                if (erros.length > 0)
                    messages = erros
                dispatch(actions.showMessagesImportTable({ messages: [], isError: true, total: 0, erros: messages }));
            } else {
                dispatch(actions.setDialogStatusImportXlsRoutes(2));
                routes.forEach((route) => { dispatch(actions.addRoute(route)); })
                let messages = ["Dados foram importados com sucesso"];
                if (erros.length > 0) {
                    messages = ["Alguns dados foram importados com sucesso"];
                }

                dispatch(actions.showMessagesImportTable({ messages, isError: false, total: routes.length, erros }));
            }
            //    setTimeout(()=>{
            //     dispatch(actions.setVisibleDialogStatusImportXlsRoutes(false));
            //    },2000)
        })
            .catch((err) => {
                // atualizar o log de erro no retorno do servidor
                console.log(err);
                const erros = ["Não foi possível processar a planilha."];
                dispatch(actions.showMessagesImportTable({ messages: [], isError: true, total: 0, erros }));
            })
    }
}

actions.loadMultiEntry = (filtersMultiEntries) => {
    return (dispatch, getState) => {
        const { oid } = getState().crudFreightTableState.currentRecord;
        const { max = 20, index = 0, componentOid } = filtersMultiEntries;

        if (!oid) {
            const messages = ["Selecione uma Tabela de frete!"];
            dispatch(actions.messageActions.messageShowMessages(actions.makeMessages(messages, "error")));
            return
        }

        const body = {
            oid,
            max,
            index,
            componentOid,
        }

        const URL = `/api/v1/lognex/freighttables/load/multientries`;
        api.post(URL, body).then(response => {
            // console.log("loadMultiEntry actions => ",response.data);
            dispatch(actions.setAuxiliarMultiEntries(response.data.count, componentOid, index))
            dispatch(actions.setEntriesGeneralComponent(componentOid, response.data.records))
        })
            .catch((err) => {
                console.log(err);
            })
    }
}

actions.sendInportTaxaComponetXLSX = (component, index, xlsx) => {

    return (dispatch, getState) => {
        const URL = '/api/v1/lognex/freighttables/import/xlsx/entries';
        const { oid } = getState().crudFreightTableState.currentRecord;
        dispatch(actions.showLoader());

        api.uploadFiles(URL, xlsx, { freightTableId: oid, indexComp: component.oid })
            .then(response => {
                dispatch(actions.loadMultiEntry({ componentOid: component.oid }))

                const {
                    records = [],
                    error = null,
                    totalRecords = 0,
                    totalMatchRecords = 0,
                    totalIgnoredRecords = 0,
                    totalAtualizado = 0,
                    totalInserido = 0,
                } = response.data

                //Aparece na tela -> 
                dispatch(actions.showDialogStatusImportXlsTaxaMultValores({ error, totalRecords, totalAtualizado, totalInserido }))
                dispatch(actions.hideLoader());
            }).catch((err) => {
                // atualizar o log de erro no retorno do servidor
                dispatch(actions.hideLoader());
                dispatch(actions.showDialogStatusImportXlsTaxaMultValores(null));
                const messages = ["Não foi possível processar a planilha."];
                dispatch(actions.showMessagesImportTable({ messages: [], isError: true, total: 0, erros: messages }));
            })

    }
}

actions.showMessagesImportTable = (resultImportTableXls) => {
    return {
        type: types.SET_RESULT_IMPORT_TABLE_XLS,
        resultImportTableXls
    }
}

actions.saveEntrie = (componentId, entrie) => {

    return (dispatch, getState) => {
        const URL = '/api/v1/lognex/freighttables/saveEntrie/';
        const { oid } = getState().crudFreightTableState.currentRecord;
        const body = { tableOid: oid, componentId, entrie }

        dispatch(actions.showLoader());

        api.post(URL, body).then(response => {
            dispatch(actions.hideLoader());
            dispatch(actions.loadTableById(oid));

        }).catch((err) => {
            // atualizar o log de erro no retorno do servidor
            dispatch(actions.hideLoader());
            console.log(err)
        })
    }
}


actions.loadTableById = (oid) => {
    return (dispatch, getState) => {
        const URL = '/api/v1/lognex/freighttables/tablebyid/';
        const fields = { "generalFreightComponents.entries": 0 }
        const body = { oid, fields }
        api.post(URL, body).then(response => {
            //   
            dispatch(actions.setCurrentRoute(null));
            dispatch(actions.setSelectedRoute(null));
            dispatch(actions.setSelectedRoute([]));
            dispatch(actions.setSelectedSerie(null));
            dispatch(actions.setCurrentComponent(null));


            dispatch(actions.setRecord(response.data));

        }).catch((err) => {
            // atualizar o log de erro no retorno do servidor
            dispatch(actions.hideLoader());
            console.log(err)
        })
    }
}

actions.saveMultiEntries = (component, entries) => {
    return (dispatch, getState) => {
        const URL = '/api/v1/lognex/freighttables/save/multientries';
        const { oid } = getState().crudFreightTableState.currentRecord;

        dispatch(actions.showLoader());

        const body = {
            oidTable: oid,
            oidComponent: component.oid,
            entries,
        }

        api.post(URL, body)
            .then(response => {
                dispatch(actions.hideLoader());
                const messages = ["Procedimento executado com sucesso!"];
                dispatch(actions.messageActions.messageShowMessages(actions.makeMessages(messages, "success")));
            }).catch((err) => {
                // atualizar o log de erro no retorno do servidor
                dispatch(actions.hideLoader());
                const messages = ["Erro no procedimento!."];
                dispatch(actions.showMessagesImportTable({ messages: [], isError: true, total: 0, erros: messages }));
            })
    }
}

actions.removeMultiEntry = (component, entries) => {
    return (dispatch, getState) => {
        const URL = '/api/v1/lognex/freighttables/remove/multientry';
        const { oid } = getState().crudFreightTableState.currentRecord;

        dispatch(actions.showLoader());

        const body = {
            oidTable: oid,
            oidComponent: component.oid,
            entries,
        }

        api.post(URL, body)
            .then(response => {
                dispatch(actions.hideLoader());
                const messages = ["Registro excluído com sucesso!"];
                dispatch(actions.messageActions.messageShowMessages(actions.makeMessages(messages, "success")));
            }).catch((err) => {
                // atualizar o log de erro no retorno do servidor
                dispatch(actions.hideLoader());
                const messages = ["Erro ao excluir o registro!."];
                dispatch(actions.showMessagesImportTable({ messages: [], isError: true, total: 0, erros: messages }));
            })
    }
}

actions.removeComponent = (component) => {
    return (dispatch, getState) => {
        const URL = '/api/v1/lognex/freighttables/remove/component/freighttable';
        const { oid } = getState().crudFreightTableState.currentRecord;

        dispatch(actions.showLoader());

        const body = {
            oidTable: oid,
            oidComponent: component.oid,
        }

        api.post(URL, body)
            .then(response => {
                dispatch(actions.hideLoader());
                const messages = ["Componente excluído com sucesso!"];
                dispatch(actions.messageActions.messageShowMessages(actions.makeMessages(messages, "success")));
            }).catch((err) => {
                // atualizar o log de erro no retorno do servidor
                dispatch(actions.hideLoader());
                const messages = ["Erro ao excluir o componente!."];
                dispatch(actions.showMessagesImportTable({ messages: [], isError: true, total: 0, erros: messages }));
            })
    }
}


function startDownload(file, fileName) {
    const url = window.URL.createObjectURL(new Blob([new Uint8Array(file)], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
}
function download(data) {
    const blob = new Blob([new Uint8Array(data)])
    // let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });

    if (navigator.msSaveOrOpenBlob) {
        navigator.msSaveBlob(blob, 'file.xlsx');
    } else {

        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute('download', 'file.xlsx');
        document.body.appendChild(link);
        link.download = '';
        link.click();
        document.body.removeChild(link);

    }
    /* var a = document.createElement("a");
     document.body.appendChild(a);
     a.style = "display: none";
     // data são os bytes do arquivo
     // createObjectURL cria um novo objeto URL para permitir o download
     var blob =  new Blob(["\ufeff", data], {
                 type: 'application/vnd.ms-excel'})
         , url = window.URL.createObjectURL(blob);
     a.href = url;
     a.download = "meu_arquivo.xlsx";
     a.click();
     window.URL.revokeObjectURL(url);
     */
}
actions.exportXlsxVtex = () => {
    return (dispatch, getState) => {
        const URL = '/api/v1/lognex/freighttables/export/xlsx/vtex';
        const { oid } = getState().crudFreightTableState.currentRecord;
 

        const body = {
            oid
        }
 
        dispatch(actions.messageActions.messageShowMessages(actions.makeMessages(["Aguarde, quando finalizar o processo, o arquivo será enviado enviado por o email"], "info")));

        api.post(URL, body).then(response => {
            const data = response.data;
            const anchor = document.createElement("a");
            anchor.href = data.presigned_url;
            anchor.target = "_blank";
            document.body.append(anchor);
            anchor.click();
            anchor.remove();
        }).catch((err) => {  
            const messages = ["Não foi possivel exportar essa tabela, talvez ja tenha um outro processo em andamento, aguarde."];
            dispatch(actions.messageActions.messageShowMessages(actions.makeMessages(messages, "error")));
        })
    }
}


export { types, actions }