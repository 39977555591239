import {types} from './report.actions';

const initialState = {    
    freightValue : {
        title: "VALOR DO FRETE / ESTADO",
        value: "0.0",
        filters: {
            location: {
                name: "TODOS",
                initials: null,
            },
            period: {
                description:"Últimos 7 dias",
                period: "LAST_SEVEN_DAYS"
            },
        },
        lookup:{
            showLocation: false,
            showPeriod: false,
            columnsLocation:[
                {
                    field: "initials",
                    header: "Estados",
                    columnKey: "initials",
                    sortable: false,
                    filter: true
                   
                  }
            ],
            columnsPeriod:[
                {
                    field: "description",
                    header: "Período",
                    columnKey: "description",
                    sortable: false,
                    filter: true
                   
                }
            ]
        }
    }
}

export function reducerValueFreightByLocation(state = initialState, action){
    let result = state;
    switch(action.type){
        case types.SET_PERIOD:
            return setPeriod(state, action);
        case types.SET_VALUE:
            return setValue(state, action);
        case types.SET_LOCATION:
            return setLocation(state, action);
        case types.SET_SHOW_LOCATION:
            return setShowLocation(state, action);
        case types.SET_SHOW_PERIOD:
            return setShowPeriod(state, action);   
        case types.LOADER_SHOW:
            return loaderShow(state, action);
        case types.LOADER_HIDDEN:
            return loaderHide(state, action);                   
        default:
        return result; 
    }
}

function setValue(state, action){
    const freightValue = {...state.freightValue, value:action.value}; 
    return { ...state, freightValue};
}

function setLocation(state, action){
    const filters = {...state.freightValue.filters, location:action.location}
    const freightValue = {...state.freightValue, filters}
    return Object.assign({}, state, {freightValue}); 
}

function setPeriod(state, action){
    const filters = {...state.freightValue.filters, period:action.period};
    const freightValue = {...state.freightValue, filters};
    return Object.assign({}, state, {freightValue});  
}

function setShowLocation(state, action){
    const lookup = {...state.freightValue.lookup, showLocation:action.showLocation};
    const freightValue = {...state.freightValue, lookup};
    return Object.assign({}, state, {freightValue}); 
}

function setShowPeriod(state, action){
    const lookup = {...state.freightValue.lookup, showPeriod:action.showPeriod};
    const freightValue = {...state.freightValue, lookup};
    return Object.assign({}, state, {freightValue}); 
}

function loaderShow(state, action){
    return Object.assign({}, state, { value: action.show }); 
}

function loaderHide(state, action){
    return Object.assign({}, state, { value: action.show }); 
}
