import React,{useEffect, useState} from 'react';
import { Card } from 'primereact/card';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { ButtonDialog } from "../../../../components/button-dialog/ButtonDialog";

export function IgnoreComponentList(props) {
    
    // Hooks
    const [name, setName] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [disableButton, setDisableButton] = useState(false);
    const [selectedComponents, setSelectedComponents] = useState(null);

    // quando a variavel selectedComponents sofrer alguma alteração.
    useEffect(() => {
        if(selectedComponents){
            setDisableButton(false);
        } else {
            setDisableButton(true);
        }
    }, [selectedComponents]);

    const onComponentChangeHandler = (e) => {
        const value = e && e.target && e.target.value || "";
        setName(value);
    }

    const removeIgnoreComponent = () => {
        if(selectedComponents){
            props.removeIgnoreComponentList(selectedComponents.name);
            setSelectedComponents(null);
        }
    }

    let columns = [<Column key="selectorColumn" selectionMode="single" style={{ width: "3em" }} />];
    columns = [...columns, ...props.ignoreComponentsColumns.map((col) => {
        return <Column {...col} key={col.field} />;
    })];

    const footer = (
        <div>
            <button
                className={'lognex-btn-highlighted'}
                onClick={() => {
                    props.addIgnoreComponentList(name)
                    setShowModal(false)
                }} 
                title="Adicionar componente"
                disabled={false}
                style={{ marginRight: ".25em" }}
            >
                 <i className="pi pi-check"></i>
            </button>

            <button
                title="Cancelar"
                className="lognex-btn-danger"
                onClick={() => setShowModal(false)} >
                <i className="pi pi-times"></i>
            </button>
        </div>
    );

    const ignoreComponentList = props.onlyIgnoreListComponent.map((item) => {
        return {name: item}
    })

    return(
        <div style={{ "padding": "7px" }}>
        <Card>
                <div className="p-grid">
                    <div className="p-col-12">
                        <h3>Ignorar Componentes na auditoria</h3>
                    </div>
                    <div className="p-col-12  align-bottom-left">
                        <button 
                            title="Novo" 
                            className={"lognex-btn-highlighted"} 
                            onClick={() => setShowModal(true)} 
                            style={{ marginRight: ".25em" }}
                        >
                            <i className="pi pi-plus"></i>
                        </button>

                        <ButtonDialog
                            onClick={()=>{
                                removeIgnoreComponent()
                            }}
                            button={{
                                title: "Remover",
                                className: "lognex-btn-danger",
                                disabled: disableButton
                            }}
                            dialog={{
                                header: "Confirmação",
                                modal: true,
                                message: "Deseja realmente remover o registro?",
                            }}
                        ></ButtonDialog>
                    </div>
                </div>
                <div className="p-grid">
                    <div className="p-col-6">
                        <DataTable
                            selectionMode="single"
                            value={ignoreComponentList}
                            lazy={true}
                            paginator={false}
                            emptyMessage="Não foram encontrados registros"
                            metaKeySelection={false}
                            selection={selectedComponents}
                            onSelectionChange={(e) => setSelectedComponents(e.value)}
                        >
                            {columns}
                        </DataTable>
                    </div>
                </div>
                <div className="p-grid">
                    <div className="p-col-6">
                    <Dialog
                            header="Ignorar Componente na auditoria"
                            visible={showModal}
                            style={{ width: "500px" }}
                            modal={true}
                            onHide={() => {
                                setName("");
                                setShowModal(false);
                            }}
                            footer={footer}
                            >
                            <div className="p-col-12 form-field-container">
                                <label>Componente</label>
                                <InputText 
                                    value={name} 
                                    onChange={(e)=> onComponentChangeHandler(e)}  
                                    name="name"  
                                />
                            
                            </div>
                        </Dialog>   
                    </div>
                </div>
            </Card>
        </div> 
    )
}