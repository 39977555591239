import { types } from "./businessUnitGroupFilter.actions";
import { Reducer } from "../../services/reducer.factory";

const initialState = {
    columns:[
        {
            field: "oid",
            header: "Código",
            columnKey: "oid",
            sortable: false,
            filter: false
        },
        ,
        {
            field: "label",
            header: "Label",
            columnKey: "label",
            sortable: false,
            filter: false
        },
        {
            field: "mainBusinessUnit",
            header: "Principal",
            columnKey: "mainBusinessUnit",
            sortable: false,
            filter: false
        },
        {
            field: "businessUnits",
            header: "Unidades",
            columnKey: "businessUnits",
            sortable: false,
            filter: false
        }
    ],

    records:[
        {
            oid: 1,
            label: "Sul RS",
            mainBusinessUnit:{
                id:1,
                documentNumber:"10466983001009",
                tradeName:"Longping WSRS",
                label:"WSRS"
            },
            businessUnits:[
                {
                    id:1,
                    documentNumber:"10466983001009",
                    tradeName:"Longping WSRS",
                    label:"WSRS"
                },
                {
                    id:2,
                    documentNumber:"123456788",
                    tradeName:"Longping WSSC",
                    label:"WSSC"
                },
                {
                    id:3,
                    documentNumber:"123456787",
                    tradeName:"Longping WSPR",
                    label:"WSPR"
                }]
        },
        {
            oid: 2,
            label: "Sudeste SP",
            mainBusinessUnit:{
                id:4,
                documentNumber:"08636452001903",
                tradeName:"Longping WSSP",
                label:"WSSP"
            },
            businessUnits:[
                {
                    id:4,
                    documentNumber:"08636452001903",
                    tradeName:"Longping WSSP",
                    label:"WSSP"
                },
                {
                    id:5,
                    documentNumber:"123456711",
                    tradeName:"Longping WSMG",
                    label:"WSMG"
                },
                {
                    id:6,
                    documentNumber:"123456722",
                    tradeName:"Longping WSRJ",
                    label:"WSRJ"
                }]
        },
        {
            oid: 3,
            label: "Centro Oeste MT",
            mainBusinessUnit:{
                id:7,
                documentNumber:"123456733",
                tradeName:"Longping WSMT",
                label:"WSMT"
            },
            businessUnits:[
                {
                    id:7,
                    documentNumber:"123456733",
                    tradeName:"Longping WSMT",
                    label:"WSMT"
                },
                {
                    id:8,
                    documentNumber:"123456744",
                    tradeName:"Longping WSMS",
                    label:"WSMS"
                },
                {
                    id:9,
                    documentNumber:"123456755",
                    tradeName:"Longping WSGO",
                    label:"WSGO"
                }
            ]
        },
    ]
};

const reducer = new Reducer(initialState, types);

export function CRUDBusinessUnitGroupFilterReducer(state, action){
    state = reducer.execute(state, action);
    return execute(state, action)
}

function execute(state, action){
    let result = state;
    return result;
}