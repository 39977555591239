import React from "react";
import { InputText } from "primereact/inputtext";

import "./TowerEquipmentTypeActionForm.scss"; 
import { LookupField } from "../../../components/lookup/LookupField"; 
import { EventListPage } from "../event_list/EventListPage";
import { EndpointListPage } from "../endpoints_list/EndpointListPage";

export const TowerEquipmentTypeForm = (props) => {
  const eventControl = props.record && props.record.eventControl && props.record.eventControl.eventName ? props.record.eventControl : "";
  
  return (
    <div className="tower-equip-type-form">
      <div className="p-grid">
        <div className="p-col-6">
          <div className="p-grid">
            <div className="p-col-3">
              <div className="form-field-container">
                <label>Código</label>
                <InputText value={props.record.oid} onChange={props.changeHandler} disabled={true} />
              </div>
            </div>

            
            <div className="p-col-12 form-field-container">
              <label className="lxRequiredField">Nome</label>
              <InputText value={props.record.name} onChange={props.changeHandler} name="name" />
            </div>

            <div className="p-col-12 form-field-container">
              <label className="lxRequiredField">Nome</label>
              <InputText value={props.record.nameKey}  readOnly={true} name="nameKey" />
            </div>
            

             
            {/* so para quebrar em nova line */}

            <div className="p-col-3 form-field-container">
              <label>Evento</label>
              <InputText value={props.record.eventName} onChange={props.changeHandler} name="eventName" />
            </div>

            <div className="p-col-6 form-field-container">
              <label>Evento Lognex</label>
              <LookupField
                value={eventControl}
                placeholder="Evento Lognex"
                name="eventName"
                field="eventName"
                records={props.lookupEventLognex.records}
                columns={props.lookupEventLognex.columns}
                totalRecords={props.lookupEventLognex.totalRecords}
                sortOrder={props.lookupEventLognex.order}
                sortField={props.lookupEventLognex.sortField}
                first={props.lookupEventLognex.first}
                rows={props.lookupEventLognex.rows}
                onComplete={props.lookupEventLognexOnComplete}
                onInputFilter={props.lookupEventLognexOnInputFilter}
                editDisabled={props.lookupEventLognex.editDisabled}
                onEditField={props.lookupEventLognexOnEdit}
                onClick={props.lookupEventLognexOnClick}
                visible={props.lookupEventLognex.visible}
                modal={props.lookupEventLognex.modal}
                header={props.lookupEventLognex.header}
                onHide={props.lookupEventLognexOnHide}
                onConfirm={props.lookupEventLognexOnConfirm}
                onCancel={props.lookupEventLognexOnCancel}
                onPage={props.lookupEventLognexOnPage}
                onFilter={props.lookupEventLognexOnFilter}
                onSort={props.lookupEventLognexOnOnSort}
              />
            </div>

            <div className={"p-col-2 form-field-container"}>
              <br />
              <button className={"lognex-btn-highlighted"} title="Adicionar" style={{ width: "100%", cursor: "pointer" }} onClick={props.onClickAddNewEvent}>
                Adicionar
              </button>
            </div>

            <div className={"p-col-12 form-field-container"}>
              <EventListPage {...props.recordsListEvent} />
            </div>
          </div>
        </div>

        <div className={"p-col-12 form-field-container"}>
          <h3>Rotas do equipamento</h3>
          <EndpointListPage {...props.recordsListEvent} />
        </div>
      </div>
    </div>
  );
};
