import { messageActions } from "../store/message/index";
import api from '../services/api.service';
const prefix = "reportAuditExpectedAndRealized";

class Action {
    constructor() {

        this.messageActions = messageActions;
        this.types = {
            DISPATCH_EXPECTED_AND_REALIZED: `${prefix}.getExpectedAndRealized`,
            SET_EXPECTED_AND_REALIZED: `${prefix}.setExpectedAndRealized`,
            REQUEST_ERROR: `${prefix}.requestError`,
            SET_INITIAL_DATE: `${prefix}setInitialDate`,
            SET_FINAL_DATE: `${prefix}setFinalDate`,
        }
    }

    getExpectedAndRealized() {
        return (dispatch, getState) => {
            const state = getState()[`${prefix}State`];
            const { initialDateFilter, finalDateFilter } = state.filter;
            const filter = {
                filters: [
                    {
                        "campo": "date",
                        "valor": initialDateFilter,
                        "valorComplementar": finalDateFilter,
                        "tipoFiltro": "ENTRE",
                        "isDate": true
                    }
                ]
            }
            dispatch(this.dispatchRequest())
            api.post('/api/v1/lognex/reports/audit/expectedandrealized', filter)
                .then(({ data }) => {
                    dispatch(this.setExptectedAndRealized(data))
                    dispatch(this.stopAsync())
                })
                .catch(err => {
                    //verificar se seria necessário apresentar alguma mensagem de erro ao usuário
                    dispatch(this.stopAsync())
                });

        }

    }

    setExptectedAndRealized(data) {
        return {
            type: this.types.SET_EXPECTED_AND_REALIZED,
            data,
        }
    }
    dispatchRequest() {
        return {
            type: this.types.DISPATCH_EXPECTED_AND_REALIZED,
            async: true
        }
    }

    stopAsync() {
        return {
            type: "STOP_ASYNC",
            async: false
        }
    }
    setInitialDate = (date) => {
        return {
            type: this.types.SET_INITIAL_DATE,
            date
        }
    }
    
    setFinalDate = (date) => {
        return {
            type: this.types.SET_FINAL_DATE,
            date
        }
    }

}

const actions = new Action();
const types = actions.types;
export { types, actions }

