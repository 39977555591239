import { types } from "./importfiles.actions";
import { Reducer } from "../../services/reducer.factory";
import moment from 'moment';

const initialState = {
  columns: [
    {
      field: "tags.fileName",
      header: "Arquivo",
      columnKey: "fileName",
      sortable: true,
      filter: true
    },
    {
      field: "createdAt",
      header: "Data",
      columnKey: "createdAt",
      sortable: true,
      filter: true
    },
    {
      field: "type",
      header: "Tipo",
      columnKey: "type",
      sortable: true,
      filter: true
    },
    {
      field: "description",
      header: "Descrição",
      columnKey: "description",
      sortable: true,
      filter: true
    }],
    detailColumns: [
      {
        field: "createdAt",
        header: "Data",
        columnKey: "createdAt",
        sortable: true,
        filter: true
      },
      {
        field: "description",
        header: "Descrição",
        columnKey: "description",
        sortable: true,
        filter: true
      }],
  currentRecord: {
    files: [],
    fileTypes: ["CTE", "EventoCTE", "NFE", "EDIDocCob", "EDIOcoRen"],

    currentFileType: null,
    oid: null,
    createdAt: null,
    tags: { fileName: "", carrier: "", key: "", resource: "" },
    hour: null,
    type: null,
    status: "",
    description: "",
    resource: null,
    key: "",
    carrier: "",
    fileName: "",
    fileExtension: "",
    importFilesVisible: false
  },
  records: [],
  logFilter: {
    initialDateFilter: moment().startOf('month').toDate(),
    finalDateFilter: moment().toDate(),
  },
  currentLogRecord: {},
  dialogLogRecord: {
    visible: false,
    modal: true,
    header: "Arquivo "
  }
};

const reducer = new Reducer(initialState, types);


export function CRUDImportFilesReducer(state, action) {
  state = reducer.execute(state, action);
  return execute(state, action);
}

function execute(state, action) {
  let result = state;
  switch (action.type) {
    case types.LOAD:
      return load(state, action);
    case types.SET_DIALOG_LOG_RECORD_VISIBLE:
      return setDialogLogRecordVisible(state, action);
    case types.SET_CURRENT_LOG_RECORD:
      return setCurrentLogRecord(state, action);
    case types.SET_FIELD_LOG_FILTER:
      return setFieldLogFilter(state, action);
    default:
      return result;
  }
}

function load(state, action) {
  const log = action.log;
  return Object.assign({}, state, { log });
}

function setDialogLogRecordVisible(state, action) {
  let dialogLogRecord = state.dialogLogRecord;
  dialogLogRecord = Object.assign({}, dialogLogRecord, { visible: action.visible });
  return Object.assign({}, state, { dialogLogRecord })
}

function setCurrentLogRecord(state, action) {
  let currentLogRecord = action.logRecord;
  return Object.assign({}, state, { currentLogRecord })
}

function setFieldLogFilter(state, action) {
  let logFilter = state.logFilter;
  logFilter = Object.assign({}, logFilter, { [action.fieldName]: action.fieldValue });
  state = Object.assign({}, state, { logFilter })
  return state;
}