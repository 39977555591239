import React from 'react';

import TowerEquipmentTypeFormPageContainer from './TowerEquipmentTypeFormPageContainer';

export class TowerEquipmentTypeFormPage extends React.Component{

    
    render(){
        return (
           <TowerEquipmentTypeFormPageContainer {...this.props} />
        )
    }
}
