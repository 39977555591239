import React from 'react';
import './Step.scss';

export const Step = (props) => {

    const { onClickPrevious, onClickNext, onClickConfirm, onClickCancel, disableConfirm } = props

    return (
        <div className="p-col-12">
            <div className="step__content">
                {props.content}
            </div>
            <div className="step__footer">
                { onClickPrevious && 
                    <button
                        className="lognex-btn-highlighted"
                        onClick={props.onClickPrevious}
                        title="Anterior"
                        // style={{ marginRight: ".25em" }}
                    >
                        <i className="pi pi-arrow-left"></i>
                    </button>
                }
                { onClickNext && 
                    <button
                        className="lognex-btn-highlighted"
                        onClick={props.onClickNext}
                        title="Próximo"
                        // style={{ marginLeft: ".25em" }}
                    >
                        <i className="pi pi-arrow-right"></i>
                    </button>
                 }
                { onClickConfirm &&
                    <button
                        className="lognex-btn-highlighted"
                        onClick={props.onClickConfirm}
                        disabled={disableConfirm}
                        title="Confirmar"
                        // style={{ marginRight: ".25em" }}
                    >
                        <i className="pi pi-check"></i>
                    </button>
                }
                { onClickCancel && 
                    <button
                        className="lognex-btn-danger"
                        onClick={props.onClickCancel}
                        title="Cancelar"
                        // style={{ marginRight: ".25em" }}
                    >
                        <i className="pi pi-times"></i>
                    </button>
                }            
            </div>
        </div>
    );
}