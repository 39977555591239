import React from 'react';

import TowerEquipmentFormPageContainer from './TowerEquipmentFormPageContainer';

export class TowerEquipmentFormPage extends React.Component{

    
    render(){
        return (
           <TowerEquipmentFormPageContainer {...this.props} />
        )
    }
}
