import { InputText } from "primereact/inputtext";
import { useState, useEffect } from 'react';
import React from "react";
import { formatNumber } from "../../../utils";
import moment from "moment";
import "./RastrearDeliveryForm.scss";

export const RastrearDeliveryForm = (props) => {
  const handleKeyUp = (event) => {
    if (event.key === 'Enter') {
      props.findDeliveryByTraceCode();
    }
  };
  const handleChange = (event) => {
    const target = event.target;
    let value = target.value;
    props.onChangeCodigoRastreio(value);
  };

  
  const downloadImage = () =>{
    var a = document.createElement("a"); //Create <a>
    document.body.append(a);
    a.href = props.record.image; //Image Base64 Goes here
    a.download = props.record.code + "_image.png"; //File name Here
    a.click(); //Downloaded file 
    a.remove();
  }

  const [imageOrientation, setImageOrientation] = useState(null);
  useEffect(() => {
    const img = new Image();
    img.src = props.record.image;

    img.onload = () => {
      if (img.height > img.width) {
        setImageOrientation('vertical-image');
      } else {
        setImageOrientation('horizontal-image');
      }
    };
  }, [props.record.image]);
  
  var imageSource = ""
  if (props.record.image && props.record.image != ""){
    imageSource =  props.record.image//"data:image/jpeg;base64," +
  }

  const dateFomat = (value, time = false) => {

    let formattedDate = "";
    if (value) {
      if (time) {
        formattedDate = moment(value).format("DD/MM/YYYY HH:mm:ss");
      } else {
        formattedDate = moment(value).format("DD/MM/YYYY");
      }
    }
    return formattedDate;
  };
  return (
    <div className="trace-delivery-form">
      <div className="p-grid">


        <div className="p-col-9 form-field-container">
          <label>Informe o código de rastreio</label>
          <InputText
            name="cdRastreio"
            value={props.cdRastreio ?? ""}
            onChange={handleChange}
            onKeyUp={handleKeyUp}
          />
        </div>
        <div className="p-col-2 form-field-container align-bottom" >
          <label>{" "}</label>
          <button
            className={"lognex-btn-highlighted bottons"}
            onClick={props.findDeliveryByTraceCode}
            title="Novo"
          >
            <i className="pi pi-search"></i>
          </button>
        </div>
        <div className="p-col-12 form-field-container align-center">
          <div>
            <h2>STATUS:&nbsp;</h2>
            <span className="result-trace-code">{props.record.status ?? ""}</span>
          </div>
          <div>
            <h2>DATA COLETA:&nbsp;</h2>
            <span className="result-trace-code">{dateFomat(props.record.pickupDate,false)}</span>
          </div>
          <div>
            <h2>PREVISÃO DE ENTREGA:&nbsp;</h2>
            <span className="result-trace-code">{dateFomat(props.record.deliveryForecast,false)}</span>
          </div>
          <div>
            <h2>DATA DA ENTREGA:&nbsp;</h2>
            <span className="result-trace-code">{dateFomat(props.record.deliveryDate)}</span>
          </div>
          <div>
            <h2>CLIENTE:&nbsp;</h2>
            <span className="result-trace-code">{props.record.clientName}</span>
          </div>
          <div>
            <h2>ENDEREÇO DE ENTREGA:&nbsp;</h2>
            <span className="result-trace-code">{props.record.address}</span>
          </div>
          <div>
            <h2>VOLUMES:&nbsp;</h2>
            <span className="result-trace-code">{props.record.volume}</span>
          </div>
          <div>
            <h2>PESO:&nbsp;</h2>
            <span className="result-trace-code">{formatNumber(props.record.weight, 0)}Kg</span>
          </div>

          {/* <div>
            <h2>ÚLTIMA LOCALIZAÇÃO:&nbsp;</h2>
            <span className="result-trace-code">{props.record.lastCity}</span>
          </div> */}

          {/* <div>
            <h2>DISTÂNCIA PARA CHEGAR:&nbsp;</h2>
            <span className="result-trace-code">{formatNumber(props.record.kmLeft/1000, 0)}Km</span>
          </div> */}

        </div>
        <div className="p-col-12 form-field-container align-center">
          {imageSource != "" &&
          // <img className="box-image" onClick={downloadImage} title="Clique aqui para baixar" alt='Clique aqui para baixar' src={imageSource} ></img>
          <img className={imageOrientation} onClick={downloadImage} title="Clique aqui para baixar" alt='Clique aqui para baixar' src={imageSource} ></img>
          }
        </div>

      </div>
    </div>

  );
};
