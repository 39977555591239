import React from 'react';
import { GridBox } from "../../../components/grid-box/GridBox";
import { TextSelector } from "../../../components/text-selector/TextSelector";
import  calendar from '../../../assets/images/icons/calendar_blue.png';
import  truck from '../../../assets/images/icons/truck_blue.png';
import { Lookup } from '../../../components/lookup/Lookup';

export const IndicatorWithCarrierAndPeriod = (props)=>{

    const footerIndicator = (
        <div>
            <TextSelector 
                icon={truck} 
                text={props.carrier} 
                onActivate={props.onCarrierClick}
            />
            <TextSelector 
                icon={calendar} 
                text={props.period} 
                onActivate={props.onPeriodClick}
            />
        </div>
    )
    
    const lookup = props.lookup;
    if(!lookup){
        return null
    }

    return (
        <div>

        <GridBox 
            title={props.title} 
            content={props.value} 
            footer={footerIndicator} 
        />
        <Lookup
            style={{width:'400px'}}
            visible={lookup.carrier.show}
            modal={false}
            header={"Selecionar Transportadora"}
            onHide={lookup.carrier.onHide}
            onConfirm={lookup.carrier.onConfirm}
            onCancel={lookup.carrier.onCancel}
            records={lookup.carrier.records}
            columns={lookup.carrier.columns}
            totalRecords={lookup.carrier.totalRecords}
            first={lookup.carrier.first}
            rows={lookup.carrier.rows}
            rowsPerPageOptions={lookup.carrier.rowsPerPageOptions}
            onPage={lookup.carrier.onPage}
            onFilter={lookup.carrier.onFilter}
        />
        <Lookup
            style={{width:'400px'}}
            visible={lookup.period.show}
            modal={false}
            header={"Selecionar período"}
            onHide={lookup.period.onHide}
            onConfirm={lookup.period.onConfirm}
            onCancel={lookup.period.onCancel}
            records={lookup.period.records}
            columns={lookup.period.columns}
            totalRecords={lookup.period.totalRecords}
            first={lookup.period.first}
            rows={lookup.period.rows}
            rowsPerPageOptions={lookup.period.rowsPerPageOptions}
            onPage={lookup.period.onPage}
            onFilter={lookup.period.onFilter}
        />

        </div>
    )
}