import React from 'react';
import TowerDashboardListPageContainer from './TowerDashboardListPageContainer';

export class TowerDashboardListPage extends React.Component{
    
    render(){
      return (
        <TowerDashboardListPageContainer {...this.props}/>
      )
    }
}

