import React from 'react'
import { Dialog } from 'primereact/dialog';
import { LookupField } from '../../../../components/lookup/LookupField';
import { InputText } from 'primereact/inputtext';

export class LxDialogDuplicateRoutes extends React.Component {
    render() {
        const { props } = this;
        const hasRoute = props.duplicateRoute && props.duplicateRoute.route && props.duplicateRoute.route.oid ? true : false;
        return (
            <Dialog
                header={props.dialog.header}
                visible={props.dialog.visible}
                style={{ width: '50vw' }}
                modal={props.dialog.modal}
                baseZIndex={1}
                onHide={props.dialog.actions && props.dialog.actions.onHide ? props.dialog.actions.onHide : () => { console.log("dialog.onHide must be implemented") }}
                footer={
                    <div>
                        <button
                            title="Confirmar"
                            className={props.dialog.confirmDisabled !== true ? "lognex-btn-highlighted" : ""}
                            onClick={props.dialog.actions.onConfirm}
                            disabled={props.dialog.confirmDisabled} >
                            <i className="pi pi-check"></i>
                        </button>

                        <button
                            title="Cancelar"
                            className="lognex-btn-danger"
                            onClick={props.dialog.actions.onHide} >
                            <i className="pi pi-times"></i>
                        </button>
                    </div>}>
                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="p-grid">
                            <div className="p-col-12 form-field-container">
                                <label className="lxRequiredField">Nome</label>
                                <InputText value={props.duplicateRoute.name} onChange={props.duplicateRoute.actions.onChangeDuplicateRouteName} name="duplicateRoute.name" disabled={hasRoute} />
                            </div>
                            <div className="p-col-6 form-field-container">
                                <label className="lxRequiredField">Origem</label>
                                <LookupField
                                    value={props.duplicateRoute.origin}
                                    placeholder="Localização"
                                    name="duplicateRoute.origin"
                                    field="description"
                                    visible={props.lookupOrigin.visible}
                                    modal={props.lookupOrigin.modal}
                                    header={props.lookupOrigin.header}
                                    records={props.lookupLocation.records}
                                    columns={props.lookupLocation.columns}
                                    totalRecords={props.lookupLocation.totalRecords}
                                    sortOrder={props.lookupLocation.order}
                                    sortField={props.lookupLocation.sortField}
                                    first={props.lookupLocation.first}
                                    rows={props.lookupLocation.rows}
                                    //*ACTIONS
                                    onClick={props.lookupOrigin.actions.onOpen}
                                    onComplete={props.lookupOrigin.actions.onComplete}
                                    onInputFilter={props.lookupOrigin.actions.onInputFilter}
                                    onEditField={props.lookupRestrictionOnEdit}
                                    onPage={props.lookupLocation.lookupLocationOnPage}
                                    onFilter={props.lookupLocation.lookupLocationOnFilter}
                                    onSort={props.lookupLocationOnOnSort}
                                    onHide={props.lookupOrigin.actions.onHide}
                                    onConfirm={props.lookupOrigin.actions.onConfirm}
                                    onCancel={props.lookupOrigin.actions.onHide}
                                    disabled={hasRoute}
                                />
                            </div>
                            <div className="p-col-6 form-field-container">
                                <label className="lxRequiredField">Destino</label>
                                <LookupField
                                    value={props.duplicateRoute.destination}
                                    placeholder="Localização"
                                    name="duplicateRoute.destination"
                                    field="description"
                                    visible={props.lookupDestination.visible}
                                    modal={props.lookupDestination.modal}
                                    header={props.lookupDestination.header}
                                    records={props.lookupLocation.records}
                                    columns={props.lookupLocation.columns}
                                    totalRecords={props.lookupLocation.totalRecords}
                                    sortOrder={props.lookupLocation.order}
                                    sortField={props.lookupLocation.sortField}
                                    first={props.lookupLocation.first}
                                    rows={5}
                                    scrollable={true}
                                    scrollHeight="400px"

                                    //* ACTIONS
                                    onClick={props.lookupDestination.actions.onOpen}
                                    onHide={props.lookupDestination.actions.onHide}
                                    onConfirm={props.lookupDestination.actions.onConfirm}
                                    onCancel={props.lookupDestination.actions.onHide}
                                    onComplete={props.lookupDestination.actions.onComplete}
                                    onInputFilter={props.lookupDestination.actions.onInputFilter}
                                    onEditField={props.lookupRestrictionOnEdit}
                                    onPage={props.lookupLocation.lookupLocationOnPage}
                                    onFilter={props.lookupLocation.lookupLocationOnFilter}
                                    onSort={props.lookupLocationOnOnSort}
                                    disabled={hasRoute}
                                />
                            </div>
                            <div className="p-col-12 form-field-container">
                                <label>Rota</label>
                                <LookupField
                                    editDisabled={props.lookupRoute ? props.lookupRoute.editDisabled : true}
                                    visible={props.lookupRoute ? props.lookupRoute.visible : false}
                                    modal={props.lookupRoute ? props.lookupRoute.modal : false}
                                    header={props.lookupRoute && props.lookupRoute.header ? props.lookupRoute.header : ""}
                                    records={props.lookupRoute && props.lookupRoute.records ? props.lookupRoute.records : []}
                                    columns={props.lookupRoute && props.lookupRoute.columns ? props.lookupRoute.columns : null}
                                    sortOrder={props.lookupRoute && props.lookupRoute.order ? props.lookupRoute.order : ""}
                                    sortField={props.lookupRoute && props.lookupRoute.sortField ? props.lookupRoute.sortField : ""}
                                    first={props.lookupRoute && props.lookupRoute.first ? props.lookupRoute.first : 0}
                                    rows={props.lookupRoute && props.lookupRoute.rows ? props.lookupRoute.rows : 0}
                                    totalRecords={props.lookupRoute && props.lookupRoute.totalRecords ? props.lookupRoute.totalRecords : 0}
                                    value={props.duplicateRoute.route}
                                    placeholder="Rota"
                                    name="duplicateRoute.route"
                                    field={"name"}
                                    // * Lookup actions
                                    onComplete={props.lookupRoute && props.lookupRoute.actions && props.lookupRoute.actions.onComplete ? props.lookupRoute.actions.onComplete : () => { console.log("lookup.OnComplete must be setted") }}
                                    onInputFilter={props.lookupRoute && props.lookupRoute.actions && props.lookupRoute.actions.onInputFilter ? props.lookupRoute.actions.onInputFilter : () => { console.log("lookup.onInputFilter must be setted") }}
                                    onEditField={props.lookupRoute && props.lookupRoute.actions && props.lookupRoute.actions.onEditField ? props.lookupRoute.actions.onEditField : () => { console.log("lookup.onEditField must be setted") }}
                                    onClick={props.lookupRoute && props.lookupRoute.actions && props.lookupRoute.actions.onClick ? props.lookupRoute.actions.onClick : () => { console.log("lookup.onClick must be setted") }}
                                    onHide={props.lookupRoute && props.lookupRoute.actions && props.lookupRoute.actions.onHide ? props.lookupRoute.actions.onHide : () => { console.log("lookup.onHide must be setted") }}
                                    onConfirm={props.lookupRoute && props.lookupRoute.actions && props.lookupRoute.actions.onConfirm ? props.lookupRoute.actions.onConfirm : () => { console.log("lookup.onConfirm must be setted") }}
                                    onCancel={props.lookupRoute && props.lookupRoute.actions && props.lookupRoute.actions.onCancel ? props.lookupRoute.actions.onCancel : () => { console.log("lookup.onCancel must be setted") }}
                                    onPage={props.lookupRoute && props.lookupRoute.actions && props.lookupRoute.actions.onPage ? props.lookupRoute.actions.onPage : () => { console.log("lookup.onPage must be setted") }}
                                    onFilter={props.lookupRoute && props.lookupRoute.actions && props.lookupRoute.actions.onFilter ? props.lookupRoute.actions.onFilter : () => { console.log("lookup.onFilter must be setted") }}
                                    onSort={props.lookupRoute && props.lookupRoute.actions && props.lookupRoute.actions.onSort ? props.lookupRoute.actions.onSort : () => { console.log("lookup.onSort must be setted") }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        )
    }
}