import React from 'react';
import { InputText } from 'primereact/inputtext';
import './ModuloForm.scss';



export const ModuloForm = ({ record, changeHandler }) => (
    <div className="pessoa-form">
        <div className="p-grid">
            <div className="p-col-6">
                <div className="p-grid">

                    <div className="p-col-3 form-field-container">
                        <label>Código</label>
                        <InputText value={record.oid} onChange={changeHandler} disabled={true} />
                    </div>

                    <div className="p-col-12 form-field-container">
                        <label>Nome*</label>
                        <InputText value={record.nome} onChange={changeHandler} name="nome" />
                    </div>
                    <div className="p-col-12 form-field-container">
                        <label>Descrição</label>
                        <InputText value={record.descricao} onChange={changeHandler} name="descricao" />
                    </div>

                </div>
            </div>
        </div>

    </div>
)
