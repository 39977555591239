import React from "react";
import { Dialog } from "primereact/dialog";
import "./index.scss";
import { ButtonDialog } from "../../../../components/button-dialog/ButtonDialog";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
export default class ModalEditOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = { order: { ...this.props.orderEdit }, selectedCd: null, msgInValid: "" };
  }

  onConfirm = () => {
    try {
      let order = this.state.order;
      let latPickup = parseFloat(order.pickup.location[1]);
      let lngPickup = parseFloat(order.pickup.location[0]);

      let latDelivery = parseFloat(order.delivery.location[1]);
      let lngDelivery = parseFloat(order.delivery.location[0]);

      if (isNaN(latPickup) || latPickup.lenght < 6) {
        this.setState({ msgInValid: "Latitude do ponto de coleta é inválido!" });
      } else if (isNaN(lngPickup) || lngPickup.lenght < 6) {
        this.setState({ msgInValid: "Longitude do ponto de coleta é inválido!" });
      } else if (isNaN(latDelivery) || latDelivery.lenght < 6) {
        this.setState({ msgInValid: "Latitude do ponto de entrega é inválido!" });
      } else if (isNaN(lngDelivery) || lngDelivery.lenght < 6) {
        this.setState({ msgInValid: "Longitude do ponto de entrega é inválido!" });
      } else {
        order.pickup.location[1] = latPickup;
        order.pickup.location[0] = lngPickup;

        order.delivery.location[1] = latDelivery;
        order.delivery.location[0] = lngDelivery;

        this.props.onConfirm(order);
      }
    } catch (error) {
      this.setState({ msgInValid: "Verifique os dados informado!" });
    }
  };
  onchangeLatPickup = (e) => {
    try {
      this.setState({ msgInValid: "" });
      const newVall = e.target.value;
      let order = this.state.order;
      order.pickup.location[1] = newVall;
      this.setState({ order });
    } catch (error) {
      console.log(error);
    }
  };
  onchangeLngPickup = (e) => {
    try {
      this.setState({ msgInValid: "" });
      const newVall = e.target.value;
      let order = this.state.order;
      order.pickup.location[0] = newVall;
      this.setState({ order });
    } catch (error) { }
  };

  onchangeLatDelivery = (e) => {
    try {
      this.setState({ msgInValid: "" });
      const newVall = e.target.value;
      let order = this.state.order;
      order.delivery.location[1] = newVall;
      this.setState({ order });
    } catch (error) {
      console.log(error);
    }
  };

  onchangeLngDelivery = (e) => {
    try {
      this.setState({ msgInValid: "" });
      let newVall = e.target.value;
      let order = this.state.order;
      order.delivery.location[0] = newVall;
      this.setState({ order });
    } catch (error) {
      console.log(error);
    }
  };
  onChangeSelectedCD = (e) => {
    console.log("onChangeSelectedCD e=", e);
    // this.props.setSelectecVirtualCD(e.value);
    let order = this.state.order;

    order.pickup = e.value;
    this.setState({ order });
  };
  render() {
    const footer = (
      <div className="footer-buttons">
        <div className="content-erro">{this.state.msgInValid}</div>
        <ButtonDialog
          onClick={this.onConfirm}
          button={{
            title: "Confirmar",
            className: "lognex-btn-highlighted",
            iconClassName: "pi pi-check",
          }}
          dialog={{
            header: "Confirmação",
            modal: false,
            message: "Deseja realmente excluir essa ordem?",
          }}
        ></ButtonDialog>

        <button title="Cancelar" className="lognex-btn-danger" onClick={this.props.onHide}>
          <i className="pi pi-times"></i>
        </button>
      </div>
    );

    // console.log("this.props.unidadeRecords=", this.props.unidadeRecords)
   /* let virtualCDs = this.props.unidadeRecords.map(unid => {
      let lat = 0;
      let lng = 0;
      try {

        lng = Number(unid.address.georeference.longitude);
        lat = Number(unid.address.georeference.latitude);
      } catch (error) {
        lng = 0;
        lat = 0;
      }
      if (lng != 0 && lat != 0) {
        return {
          "service": 7200,
          "description": unid.address.city,
          "virtualCD": String(unid.code).toUpperCase(),
          "location": [lng, lat]
        }
      } else {
        return {
          "service": 7200,
          "description": unid.address.city,
          "virtualCD": String(unid.code).toUpperCase(),
          "location": []
        }
      }
    }).filter(undCd => undCd.location.length > 0)
*/
    return (
      <Dialog className="content-dialog" header={"Alterar informações da Ordem"} style={{ width: "400px", height: "600px" }} footer={footer} visible={this.props.visible} modal={true} onHide={this.props.onHide}>
        <div className="p-grid">
          <div className="p-col-12 form-field-container">
            <h2>{this.state.order.code}</h2>
          </div>

        {/*  <div className="p-col-12 form-field-container">
            <h3>Ponto de Coleta</h3>
          </div>

          
           <div className="p-col-12 form-field-container">
            <label>Centro de Distribuição(CD)</label>
            <Dropdown
              placeholder="Selecione"
              value={this.state.order.pickup}
              dataKey={"virtualCD"}
              key={Math.random()}
              optionLabel="virtualCD"
              options={virtualCDs}
              onChange={this.onChangeSelectedCD} />
          </div> */}

          <div className="p-col-12 form-field-container">
            <h3>Ponto de Entrega</h3>
          </div>
          <div className="p-col-6 form-field-container">
            <label>Latitude</label>
            <InputText type="number" max={19} value={this.state.order.delivery.location[1]} placeholder="Latitude" onChange={this.onchangeLatDelivery} />
          </div>
          <div className="p-col-6 form-field-container">
            <label>Longitude</label>
            <InputText type="number" max={19} value={this.state.order.delivery.location[0]} placeholder="Longitude" onChange={this.onchangeLngDelivery} />
          </div>
        </div>
      </Dialog>
    );
  }
}
