import React from 'react';  
import CargoShipmentInStatusTrasitoPageContainer from './CargoShipmentInStatusTrasitoPageContainer';

export class CargoShipmentInStatusTrasitoListPage extends React.Component{
    
    render(){
      return (
        <CargoShipmentInStatusTrasitoPageContainer {...this.props}/>
      )
    }
}

