import React from "react";
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import { ButtonDialog } from '../../../components/button-dialog/ButtonDialog'
import CalculatedValue from "../form/components/CalculatedComponent";
import './LxFreightComponentPanel.scss'
import { LxButtonActions } from "../../../components/lx/button-actions/LxButtonActions";
import { MultiEntriesList } from "../form/components/MultiEntriesList";

export class LxFreightComponentPanel extends React.Component {
    render() {
        const { props } = this;

        const labelCTe =
            <div className="p-col-3 form-field-container">
                <label>Código CTe</label>
                <InputText value={props.component.labelCte || ""}  type="text" onChange={props.actions.onChange} name="labelCte" disabled={false} />
            </div>;
        const fixedValue =
            <div className="p-col-3 form-field-container">
                <label>Valor Fixo</label>
                <InputText value={props.component.fixedValue} type="number" onChange={props.actions.onChange} name="fixedValue" disabled={false} />
            </div>;
        const minValue =
            <div className="p-col-3 form-field-container">
                <label>Valor Mínimo</label>
                <InputText value={props.component.minValue} type="number" onChange={props.actions.onChange} name="minValue" disabled={false} />
            </div>;
        const maxValue =
            <div className="p-col-3 form-field-container">
                <label>Valor Máximo</label>
                <InputText value={props.component.maxValue} type="number" onChange={props.actions.onChange} name="maxValue" disabled={false} />
            </div>;
        const percentValue =
            <div className="p-col-3 form-field-container">
                <label>Valor Percentual</label>
                <InputText value={props.component.percentValue} type="number" onChange={props.actions.onChange} name="percentValue" disabled={false} />
            </div>;
        const booleanValue =
            <div className="p-col-3 form-field-container">
                <label>Valor Lógico</label>
                <InputSwitch checked={props.component.booleanValue} onChange={props.actions.onChange} name="booleanValue" disabled={false} />
            </div>;
        const textValue =
            <div className="p-col-3 form-field-container">
                <label>Valor Texto</label>
                <InputText value={props.component.textValue} onChange={props.actions.onChange} name="textValue" disabled={false} />
            </div>;
        const kgValue =
            <div className="p-col-3 form-field-container">
                <label>Valor por Kg</label>
                <InputText value={props.component.kgValue} type="number" onChange={props.actions.onChange} name="kgValue" disabled={false} />
            </div>;
        const kilometerValue =
            <div className="p-col-3 form-field-container">
                <label>Valor por KM</label>
                <InputText value={props.component.kmValue} type="number" onChange={props.actions.onChange} name="kmValue" disabled={false} />
            </div>;
        const unitValue =
            <div className="p-col-3 form-field-container">
                <label>Valor Unitário</label>
                <InputText value={props.component.unitValue} type="number" onChange={props.actions.onChange} name="unitValue" disabled={false} />
            </div>;
        const discountValue =
            <div className="p-col-3 form-field-container">
                <label>Valor Desconto</label>
                <InputText value={props.component.discountValue} type="number" onChange={props.actions.onChange} name="discountValue" disabled={false} />
            </div>;

        const cubageValue =
            <div className="p-col-3 form-field-container">
                <label>Valor por M3</label>
                <InputText value={props.component.cubageValue} type="number" onChange={props.actions.onChange} name="cubageValue" disabled={false} />
            </div>;
        const multiEntries =(<MultiEntriesList 
            componentOid={props.component.oid}
            indexComponent={props.indexComponent}
            records={props.component.entries} 
            onAddEntries={props.actions.onAddEntries} 
            onRemoveEntries={props.actions.onRemoveEntries}
            onUpdateEntry={props.actions.onUpdateEntry}
            sendInportTaxaComponetXLSX={props.actions.sendInportTaxaComponetXLSX}
            onLoadMultiEntry={props.actions.onLoadMultiEntry}
            auxiliarMultiEntry={props.auxiliarMultiEntry}
            />)
            

        return (
            <div className="p-grid" >
                <div className="p-col-12 div-component">
                    <div className="p-grid" >
                        <div className="p-col-11 form-field-container">
                            <h3> {props.component.name} </h3>
                        </div>
                        <div className="p-col-1 form-field-container">

                            <ButtonDialog
                                onClick={props.actions && props.actions.onRemove ? props.actions.onRemove : () => { console.log("Must implment method actions.onRemove") }}
                                button={{
                                    title: "Remover",
                                    className: "lognex-btn-danger"
                                }}
                                dialog={{
                                    header: 'Confirmação',
                                    modal: false,
                                    message: `Deseja realmente remover o componente ${props.component.name}?`,
                                }}
                            ></ButtonDialog>
                        </div>
                        {labelCTe}
                        {props.component.useFixedValue && fixedValue}
                        {props.component.useCubageValue && cubageValue}
                        {props.component.useMinValue && minValue}
                        {props.component.useMaxValue && maxValue}
                        {props.component.usePercentValue && percentValue}
                        {props.component.useBooleanValue && booleanValue}
                        {props.component.useTextValue && textValue}
                        {props.component.useKiloValue && kgValue}
                        {props.component.useKilometerValue && kilometerValue}
                        {props.component.useUnitValue && unitValue}
                        {props.component.useDiscountValue && discountValue}
                        {props.component.useMultEntries && multiEntries}
                        {props.component.useCalculatedValue && <CalculatedValue component={props.component} {...props} />}
                    </div>
                </div>
            </div>
        );
    }
}
