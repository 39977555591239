import { types } from "./occurrenceType.actions";
import { Reducer } from "../../services/reducer.factory";

const initialState = {
  booleanFilter: {
    groupType: ''
  },
  columns: [
    {
      field: "oid",
      header: "Código",
      columnKey: "oid",
      sortable: true,
      filter: true
    }, {
      field: "code",
      header: "Código ocorrência",
      columnKey: "code",
      sortable: true,
      filter: true
    }, {
      field: "description",
      header: "Descrição",
      columnKey: "description",
      sortable: true,
      filter: true
    }, {
      field: "groupType",
      header: "Tipo",
      columnKey: "groupType",
      sortable: false,
      filter: true
    }],
  currentRecord: {
    oid: "",
    code: "",
    description: "",
    groupType: "",
    total: 0
  },
  filter: {
    filters: [],
    sortField: "",
    sortOrder: 0,
    index: 0,
    max: 20,
    count: 2,
    errors: {}
  },
  lookupOccurrenceType: {
    visible: false,
    modal: true,
    header: "Ocorrencias"

  }
};

const reducer = new Reducer(initialState, types);

export function CRUDOccurrenceTypeReducer(state, action) {
  state = reducer.execute(state, action);
  return execute(state, action);
}

function execute(state, action) {
  let result = state;
  switch (action.type) {
    case types.GET_GROUP_TYPE:
      return setGroupType(state, action)
    case types.SET_BOOLEAN_FILTER:
      return setBooleanFilter(state, action)
    default:
      return result;
  }
}

function setGroupType(state, action) {
  const groupType = action.groupType;
  return Object.assign({}, state, { groupType });
}

function setBooleanFilter(state, action) {
  let booleanFilter = Object.assign({}, state.booleanFilter);
  booleanFilter[action.field] = action.value;
  return Object.assign({}, state, { booleanFilter });
}

