
    import { validate } from './validations';
    import { build } from '../../services/crudFactory';
    import { Action } from '../../services/actions.factory';
    const BASE_PATH = '/lognex/edis';
    const prefix = 'crudEdi';
    const crud = build(validate, BASE_PATH);

    const newTypes = {
        
    }

    const actions = new Action(prefix, crud);

    const types = Object.assign({}, actions.types, newTypes);
    
    

    export { types, actions }