import React from 'react'; 
import CollectionOrdersRejectedPageContainer from './CollectionOrdersRejectedPageContainer';

export class CollectionOrdersRejectedListPage extends React.Component{
    
    render(){
      return (
        <CollectionOrdersRejectedPageContainer {...this.props}/>
      )
    }
}

