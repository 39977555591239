 
export function validate(record) {
    const errors = [];
    
    const result = {
        ok: errors.length === 0,
        errors,
        record
    }

    return new Promise((resolve, reject) => {
        const action = result.ok ? resolve : reject;
        return action(result);
    })
}