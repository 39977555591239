import React from "react";
import { connect } from "react-redux";
import { Column } from "primereact/column";
import { formatNumber, numberToCurrency } from "../../../../utils";
import { OrderCargoProductsList } from "./OrderCargoProductsList";
import { DataTable } from "primereact/datatable";
import { actions } from "../../order-delivery.actions";
import "./cargo-product.scss";

const mapStateToProps = (state) => {
  const record = state.crudOrderDeliveryState.currentRecord;
  let products = [];
  if (record && record.cargo && record.cargo.products) {
    products = record.cargo.products;
  }

  return {
    columns: state.crudOrderDeliveryState.columnsCargoProducts,
    records: products,
    productRowSelected: state.crudOrderDeliveryState.productRowSelected,
    columnsExpandedProd: state.crudOrderDeliveryState.columnsExpandedProd,
    columnsExpandedProdPacking: state.crudOrderDeliveryState.columnsExpandedProdPacking,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOpenExpandedRows: (rowData) => {
      dispatch(actions.setExpandedProductRows(rowData));
    },
  };
};

class OrderCargoProductsListPageContainer extends React.Component {
  setOpenExpandedRows = (e) => {
    this.props.setOpenExpandedRows(e.data);
  };
  setCloseExpandedRows = (e) => {
    this.props.setOpenExpandedRows(null);
  };

  getProductContent = (rowData) => {
    return Array.from(rowData.content).map((item) => {
      return { key: item };
    });
  };

  render() {
    const numberTemplate = (rowData, column) => {
      let valor =0;
      try {
        valor=rowData[column.field];
      } catch (error) {
        
      }
      return <div style={{ textAlign: "center", color: "black" }}>{formatNumber(valor)}</div>;
    };

    const valorTemplate = (rowData, column) => {
      let valor =0;
      try {
        valor =rowData[column.field];
      } catch (error) {
      }
      return <div style={{ textAlign: "center", color: "black" }}>{numberToCurrency(valor, "REAL")}</div>;
    };

    const columnsDat = this.props.columns.map((col) => {
      if (col.field === "value") {
        return <Column {...col} key={col.field} body={valorTemplate} />;
      }
      if (col.field === "cubage") {
        return <Column {...col} key={col.field} body={numberTemplate} />;
      }

      if (col.field === "weight") {
        return <Column {...col} key={col.field} body={numberTemplate} />;
      }

      return <Column {...col} key={col.field} />;
    });

    const columns = [<Column key={"expander"} expander={true} style={{ width: "4em" }} />, ...columnsDat];

    const columnsExpandedProd = this.props.columnsExpandedProd.map((col) => {
      return <Column {...col} key={col.field} />;
    });
    const columnsExpandedProdPacking = this.props.columnsExpandedProdPacking.map((col) => {
      if (col.field === "value") {
        return <Column {...col} key={col.field} body={valorTemplate} />;
      }
      if (col.field === "cubage") {
        return <Column {...col} key={col.field} body={numberTemplate} />;
      }
      return <Column {...col} key={col.field} />;
    });

    // cubage,grossWeight,Valor

    const rowExpasionTemplateHandler = (rowData) => {
      return (
        <div className="product-detail">
          <h4>Detalhes</h4>
          <DataTable className="product-detail-subtable" value={this.getProductContent(rowData)} responsiveLayout="scroll">
            {columnsExpandedProd}
          </DataTable>
          <br />
          <h4>Embalagem</h4>
          <DataTable className="product-detail-subtable" value={[rowData.packing]} responsiveLayout="scroll">
            {columnsExpandedProdPacking}
          </DataTable>
        </div>
      );
    };

    return (
      <OrderCargoProductsList
        {...this.props}
        rowExpasionTemplateHandler={rowExpasionTemplateHandler}
        onRowCollapse={this.setCloseExpandedRows}
        onRowToggle={this.setOpenExpandedRows}
        columns={columns}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderCargoProductsListPageContainer);
