import { types } from "./dashboard-nfe.actions";
import { Reducer } from "../../services/reducer.factory";
import moment from "moment";

const initialState = { 
  nfeNumberFilter: null, 
  isLoading:false
};

const reducer = new Reducer(initialState, types);

export function CRUDDashboardNFeReducer(state, action) {
  state = reducer.execute(state, action);
  return execute(state, action);
} 
 

function execute(state, action) {
  let result = state;
  switch (action.type) {
    case types.ON_CHANGE_NUMBER_NFE_FILTER:
      return onChangeNfeNumberFilter(state, action); 
    case types.ON_LOADING_STATE:
      return onLoadingState(state,action)
    default:
      return result;
  }
} 
function  onChangeNfeNumberFilter(state, action) {
  const nfeNumberFilter = action.nfeNumberFilter;
  return { ...state, nfeNumberFilter };
}
function  onLoadingState(state, action) {
  const isLoading = action.visible;
  return { ...state, isLoading };
}
  
