import React from 'react';
import AuditoriaTrackingListPageContainer from './auditoriaTrackingListPageContainer';

export class AuditoriaTrackingListPage extends React.Component{
    
    render(){
      return (
        <AuditoriaTrackingListPageContainer {...this.props}/>
      )
    }
}