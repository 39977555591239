import { validate } from './validations';
import { build } from '../../services/crudFactory';
import { Action } from '../../services/actions.factory';
import api from '../../services/api.service';
import { makeMessages, messageActions } from '../../store/message';
const BASE_PATH = '/lognex/scriptwriter';
const crud = build(validate, BASE_PATH);
const prefix = 'crudRouteCargoShipment';

const actions = new Action(prefix, crud);

const newTypes = {
    FIND_ROUTE_CARGO_SHIPMENT: `${prefix}findRouteEmbarque`
}

const types = Object.assign({}, actions.types, newTypes);


actions.findRouteEmbarque = (oid, calback) => {
    return (dispatch, getState) => {
        console.log("findRouteEmbarque :", oid)

        dispatch(actions.showLoader());
        api.get(`/api/v1/lognex/cargo-shipment/get-route-embarque/${oid}`)
            .then(response => {
                dispatch(actions.hideLoader());
                // console.log("response :", JSON.stringify(response))
                if (calback) {
                    calback(response);
                }
            })
            .catch((error) => {
                console.log(error);
                if (calback) {
                    calback(null);
                }
                dispatch(actions.hideLoader());
                dispatch(messageActions.messageShowMessages(makeMessages(["Ops! Falha ao buscar a rota do embarque, a rota pode não estar disponível"], "error")
                )
                );
            })

    };
}

export { types, actions };
