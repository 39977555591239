
import React from 'react';
import { connect } from 'react-redux';
import { Column } from 'primereact/column';
import Toolbar from "../../../components/toolbar/Toolbar";
import { actions } from '../region.actions';
import { routes } from '../../../constants';
import { Page } from "../../../components/page/Page";
import { RegionForm } from './RegionForm';
import { importFromJSONFile } from '../../../file-handler'
import { actions as carrierActions } from '../../carrier/carrier.actions';
import { actions as locationActions } from '../../location/location.actions';


const mapStateToProps = state => {
  const record = state.crudRegionState.currentRecord;
  const locationFilterType = state.crudLocationState.locationFilterType
  const lookupParentBase = {
    records: state.crudLocationState.records,
    columns: state.crudLocationState.columns
      .filter(col =>
        col.field === 'oid' ||
        col.field === 'description')
      .map(col => <Column key={col.field} {...col} />),
    first: state.crudLocationState.index,
    rows: state.crudLocationState.max,
    totalRecords: state.crudLocationState.count,
    sortField: state.crudLocationState.sortField,
    order: state.crudLocationState.sortOrder,
    editDisabled: !record.oidParent,
    rowsPerPageOptions:[20,50,100],
  }
  let lookupParent = Object.assign({}, state.crudRegionState.lookupParent, lookupParentBase);

  const lookupCarrierBase = {
    records: state.crudCarrierState.records,
    columns: state.crudCarrierState.columns
      .filter(col => col.field === 'oid' || col.field === 'name' || col.field === 'tradeName')
      .map(col => <Column key={col.field} {...col} />),
    first: state.crudCarrierState.index,
    rows: state.crudCarrierState.max,
    totalRecords: state.crudCarrierState.count,
    sortField: state.crudCarrierState.sortField,
    order: state.crudCarrierState.sortOrder,
    editDisabled: !record.carrierOId,
    rowsPerPageOptions:[20,50,100],
  }
  let lookupCarrier = Object.assign({}, state.crudRegionState.lookupCarrier, lookupCarrierBase);

  const lookupLocationBase = {
    locationRecords: state.crudLocationState.records,
    columns: state.crudLocationState.columns
      .filter(col => col.field === 'oid' || col.field === 'description')
      .map(col => <Column key={col.field} {...col} />),
    first: state.crudLocationState.index,
    rows: state.crudLocationState.max,
    totalRecords: state.crudLocationState.count,
    sortField: state.crudLocationState.sortField,
    order: state.crudLocationState.sortOrder,
    editDisabled: !record.locationOId,
    rowsPerPageOptions:[20,50,100],
  }
  let lookupLocation = Object.assign({}, state.crudRegionState.lookupLocation, lookupLocationBase);

  const lookupRestrictionBase = {
    locationRecords: state.crudLocationState.records,
    columns: state.crudLocationState.columns
      .filter(col => col.field === 'oid' || col.field === 'description')
      .map(col => <Column key={col.field} {...col} />),
    first: state.crudLocationState.index,
    rows: state.crudLocationState.max,
    totalRecords: state.crudLocationState.count,
    sortField: state.crudLocationState.sortField,
    order: state.crudLocationState.sortOrder,
    editDisabled: !record.restrictionOId,
    rowsPerPageOptions:[20,50,100],
  }
  let lookupRestriction = Object.assign({}, state.crudRegionState.lookupRestriction, lookupRestrictionBase);

  let types = state.crudRegionState.types;
  types = [{ label: 'Selecione uma opção', value: null }, ...Object.values(types).map(type => {
    switch (type) {
      case "CITY":
        return { value: "CITY", label: "Cidade" };
      case "ZIP_CODE_RANGE":
        return { value: "ZIP_CODE_RANGE", label: "Faixa de Cep" };
      case "COUNTRY":
        return { value: "COUNTRY", label: "País" };
      case "REGION":
        return { value: "REGION", label: "Região" };
      case "STATE":
        return { value: "STATE", label: "Estado" };
      default:
        return type;
    }
  })];

  const locations = state.crudRegionState.currentRecord.locations;
  const locationsRecords = locations;
  const locationsRows = locations.length;
  const locationsTotalRecords = locations.length;

  const restrictions = state.crudRegionState.currentRecord.restrictions
  const restrictionsRecords = restrictions;
  const restrictionsRows = restrictions.length;
  const restrictionsTotalRecords = restrictions.length;

  return {
    record,
    locationFilterType,
    lookupCarrier,
    lookupLocation,
    lookupRestriction,
    locationsColumns: state.crudRegionState.locationsColumns,
    locationsRecords,
    selectedLocations: state.crudRegionState.currentRecord.selectedLocations,
    locationsFirst: 1,
    locationsRows,
    locationsTotalRecords,
    locationsSortField: '',
    locationsOrder: 1,
    dialogAddLocations: state.crudRegionState.dialogAddLocations,

    restrictionsColumns: state.crudRegionState.restrictionsColumns,
    restrictionsRecords,
    selectedRestrictions: state.crudRegionState.currentRecord.selectedRestrictions,
    restrictionsFirst: 1,
    restrictionsRows,
    restrictionsTotalRecords,
    restrictionsSortField: '',
    restrictionsOrder: 1,
    dialogAddRestrictions: state.crudRegionState.dialogAddRestrictions,
    lookupParent,
    types,

  }
}

const mapDispatchToProps = dispatch => {
  return {

    lookupCarrierSetVisible: visible => dispatch(actions.setLookupCarrierVisible(visible)),
    lookupCarrierPaginate: paginationParams => dispatch(carrierActions.paginate(paginationParams)),
    lookupCarrierSortOrder: sortParams => dispatch(carrierActions.sortOrder(sortParams)),
    lookupCarrierApplyFilter: filterParams => {
      dispatch(carrierActions.setType(null));
      dispatch(carrierActions.applyFilter(filterParams))
    },
    lookupCarrierLoad: () => dispatch(carrierActions.load()),
    setCarrier: (carrier) => {
      if (carrier) {
        const {oid, name, documentNumber } = carrier
        console.log("setCarrier carrier=",carrier)
        dispatch(actions.setField('carrier', { oid, name, cnpj: documentNumber }));
      } else {
        dispatch(actions.setField('carrier', null));
      }
    },
    lookupLocationSetVisible: visible => dispatch(actions.setLookupLocationVisible(visible)),
    lookupLocationPaginate: paginationParams => dispatch(locationActions.paginate(paginationParams)),
    lookupLocationSortOrder: sortParams => dispatch(locationActions.sortOrder(sortParams)),
    lookupLocationApplyFilter: filterParams => {
      dispatch(locationActions.setType(null));
      dispatch(locationActions.applyFilter(filterParams))
    },
    lookupLocationLoad: () => dispatch(locationActions.load()),
    lookupLocationSetRecords: records => dispatch(actions.setLookupLocationRecords(records)),
    lookupLocationAddRecords: records => dispatch(actions.addLookupLocationRecords(records)),
    lookupLocationFilterSelectedRecords: () => dispatch(actions.filterSelectedRecordsLookupLocationRecords()),
    lookupLocationSetSelectedRecords: selectedRecords => dispatch(actions.setLookupLocationSelectedRecords(selectedRecords)),
    lookupLocationSetSearchText: searchText => dispatch(actions.setLookupLocationSearchText(searchText)),
    locationApplyBaseFilter: filters => {
      dispatch(locationActions.applyBaseFilters(filters))
    },

    setLocation: (location) => {
      if (location) {
        const { oid, name } = location;
        const newLocationRegion = {
          oid,
          name,
          restricion: false,
          active: true
        }
        dispatch(actions.setField('currentLocation', newLocationRegion));
      } else {
        dispatch(actions.setField('currentLocation', null));
      }
    },
    lookupRestrictionSetVisible: visible => dispatch(actions.setLookupRestrictionVisible(visible)),
    lookupRestrictionPaginate: paginationParams => dispatch(locationActions.paginate(paginationParams)),
    lookupRestrictionSortOrder: sortParams => dispatch(locationActions.sortOrder(sortParams)),
    lookupRestrictionApplyFilter: filterParams => {
      dispatch(locationActions.setType(null));
      dispatch(locationActions.applyFilter(filterParams))
    },
    lookupRestrictionLoad: () => dispatch(locationActions.load()),
    lookupRestrictionSetRecords: records => dispatch(actions.setLookupRestrictionRecords(records)),
    lookupRestrictionAddRecords: records => dispatch(actions.addLookupRestrictionRecords(records)),
    lookupRestrictionFilterSelectedRecords: () => dispatch(actions.filterSelectedRecordsLookupRestrictionRecords()),
    lookupRestrictionSetSelectedRecords: selectedRecords => dispatch(actions.setLookupRestrictionSelectedRecords(selectedRecords)),
    lookupRestrictionSetSearchText: searchText => dispatch(actions.setLookupRestrictionSearchText(searchText)),

    setRestriction: (restriction) => {
      if (restriction) {
        const { oid, name } = restriction;
        const newRestrictionRegion = {
          oid,
          name,
          restricion: true,
          active: true
        }
        dispatch(actions.setField('currentRestriction', newRestrictionRegion));
      } else {
        dispatch(actions.setField('currentRestriction', null));
      }
    },

    lookupParentSetVisible: visible => dispatch(actions.setLookupParentVisible(visible)),
    lookupParentPaginate: paginationParams => dispatch(locationActions.paginate(paginationParams)),
    lookupParentSortOrder: sortParams => dispatch(locationActions.sortOrder(sortParams)),
    lookupParentApplyFilter: filterParams => {
      dispatch(locationActions.setType(null));
      dispatch(locationActions.applyFilter(filterParams))
    },
    lookupParentLoad: () => dispatch(locationActions.load()),
    setParent: (parent) => {
      if (parent) {
        const { oid, name, ibgeCode, initialZipCode, finalZipCode, locationType } = parent;
        const currentParent = {
          oid,
          name,
          ibgeCode,
          initialZipCode,
          finalZipCode,
          locationType
        }
        dispatch(actions.setField('parent', currentParent));
      } else {
        dispatch(actions.setField('parent', null));
      }
    },
    setField: (field, value) => dispatch(actions.setField(field, value)),
    setLocationFilterType: value => dispatch(locationActions.setLocationFilterType(value)),
    setParentDisabled: disabled => dispatch(actions.setLookupParentDisabled(disabled)),
    changeLocationFilterTypeHandler: event => {
      const target = event.target;
      let value = target.value;
      dispatch(locationActions.setLocationFilterType(value));
      if (value === "COUNTRY" || value === "REGION" || !value) {
        dispatch(actions.setLookupParentDisabled(true))
      } else {
        dispatch(actions.setLookupParentDisabled(false))
      }
    },
    changeHandler: event => {
      const target = event.target;
      const field = target.name;
      let value = target.value;
      dispatch(actions.removeErrors([{ field }]));
      dispatch(actions.setField(field, value));
    }
    ,
    saveHandler: event => {
      dispatch(actions.save());
    },
    removeHandler: event => {
      dispatch(actions.remove());
    },
    newHandler: event => {
      dispatch(actions.makeNew());
    },
    loadById: oid => dispatch(actions.loadById(oid)),
    setSelectedLocations: (locations) => {
      dispatch(actions.setField('selectedLocations', locations != null ? locations : []));
    },
    removeSelectedLocations: () => {
      dispatch(actions.removeSelectedLocations());
    },
    setDialogAddLocationsVisible: (visible) => {
      dispatch(actions.setDialogAddLocationsVisible(visible))
    },
    addLocation: () => {
      dispatch(actions.addLocation())
    },
    addLocationsCurrentRecord: () => { dispatch(actions.addLocationsCurrentRecord()) },
    setSelectedRestrictions: (restrictions) => {
      dispatch(actions.setField('selectedRestrictions', restrictions != null ? restrictions : []));
    },
    removeSelectedRestrictions: () => {
      dispatch(actions.removeSelectedRestrictions());
    },
    setDialogAddRestrictionsVisible: (visible) => {
      dispatch(actions.setDialogAddRestrictionsVisible(visible))
    },
    addRestriction: () => {
      dispatch(actions.addRestriction())
    },
    addRestrictionsCurrentRecord: () => { dispatch(actions.addRestrictionsCurrentRecord()) },
    importXLSXLocalizations: (file, type) => dispatch(actions.importXLSXLocalizations(file, type))
  }
}

class RegionFormPageContainer extends React.Component {

  constructor(props) {
    super(props);
    actions.afterSave = ({ oid }) => {
      this.props.history.replace(`${routes.PATH_REGION}/${oid}`);
    };
    actions.afterNew = () => {
      this.props.history.replace(`${routes.PATH_REGION}/novo`);
    };
    actions.afterRemove = () => {
      this.props.history.replace(`${routes.PATH_REGION}`);
    };
  }

  componentDidMount() {
    const oid = this.props.match.params.id;
    if (oid && parseInt(oid)) {
      this.props.loadById(oid);
    } else {
      this.props.newHandler();
    }
  }

  searchHandler = () => {
    this.props.history.push(`${routes.PATH_REGION}`)
  }

  lookupCarrierOnPage = (e) => {
    this.props.lookupCarrierPaginate({
      max: e.rows,
      index: e.first
    });
  }

  lookupCarrierOnSort = (e) => {
    this.props.lookupCarrierSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField
    });
  }

  lookupCarrierOnFilter = (e) => {
    const filters = [];
    const keys = Object.keys(e.filters) || [];
    keys.forEach(key => {
      filters.push({
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: 'CONTEM'
      });
    });
    this.props.lookupCarrierApplyFilter(filters);
  }

  lookupCarrierOnClick = (e) => {
    this.props.lookupCarrierLoad();
    this.props.lookupCarrierSetVisible(true);
  }

  lookupCarrierOnHide = (e) => {
    this.props.lookupCarrierSetVisible(false);
  }

  lookupCarrierOnConfirm = (e) => {
    this.props.setCarrier(e.selection);
    this.props.lookupCarrierSetVisible(false);
  }

  lookupCarrierOnCancel = (e) => {
    this.props.setCarrier(null);
    this.props.lookupCarrierSetVisible(false);
  }

  lookupCarrierOnEdit = () => {
    if (this.props.record.oidPessoa) {
      this.props.history.push(`${routes.PATH_CARRIER}/${this.props.record.carrierOId}`);
    }
  }

  lookupCarrierOnComplete = e => {
    console.log(e);
  }

  lookupCarrierOnInputFilter = (e) => {
    const value = e.value;
    const filters = [];
    if (typeof value === 'string') {
      filters.push({
        campo: "name",
        valor: value,
        tipoFiltro: 'CONTEM'
      });
      this.props.setField("name", value);
      this.props.setField("oid", null);
    } else {
      this.props.setCarrier(value);
    }
    this.props.lookupCarrierApplyFilter(filters);
  }

  lookupLocationOnPage = (e) => {
    this.props.lookupLocationPaginate({
      max: e.rows,
      index: e.first
    })
  }
  lookupLocationOnSort = (e) => {
    this.props.lookupLocationSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField
    })
  }

  lookupLocationOnFilter = (e) => {
    const filters = [];
    const keys = Object.keys(e.filters) || [];
    keys.forEach(key => {
      filters.push({
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: 'CONTEM'
      });
    });
    this.props.lookupLocationApplyFilter(filters);
  }

  lookupLocationOnClick = (e) => {
    const type = this.props.locationFilterType;
    const parent = this.props.record.parent;
    const filters = [];
    if (type) {
      filters.push({
        campo: "type",
        valor: type,
        tipoFiltro: 'IGUAL_A'
      });
    }
    if (parent && parent.oid) {
      filters.push({
        campo: "parent._id", //TODO To verify if we will use the _id like that in the frontend
        valor: parseInt(parent.oid),
        tipoFiltro: 'IGUAL_A'
      })
    }
    this.props.locationApplyBaseFilter(filters);
    this.props.lookupLocationLoad();
    this.props.lookupLocationSetVisible(true);
  }

  lookupLocationOnHide = (e) => {
    this.props.lookupLocationSetVisible(false);
  }

  lookupLocationOnConfirm = (e) => {
    this.props.lookupLocationAddRecords(e.selection);
    this.props.lookupLocationSetVisible(false);
  }

  lookupLocationOnCancel = (e) => {
    this.props.lookupLocationSetVisible(false);
  }

  lookupLocationOnSelectLocation = (e) => {
    this.props.lookupLocationSetSelectedRecords(e.value)
  }

  lookupLocationOnRemoveSelected = (e) => {
    this.props.lookupLocationFilterSelectedRecords()
  }

  lookupLocationOnComplete = e => { };

  lookupLocationOnInputFilter = (e) => {
    const value = e.value;
    const filters = [];
    if (typeof value === 'string') {
      filters.push({
        campo: "name",
        valor: value,
        tipoFiltro: 'CONTEM'
      });
      this.props.lookupLocationSetSearchText(value);
    } else {
      this.props.lookupLocationSetSearchText("");
      this.props.lookupLocationAddRecords([value]);
    }
    this.props.lookupLocationApplyFilter(filters);
  }

  lookupRestrictionOnPage = (e) => {
    this.props.lookupRestrictionPaginate({
      max: e.rows,
      index: e.first
    });
  }

  lookupRestrictionOnSort = (e) => {
    this.props.lookupRestrictionSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField
    });
  }

  lookupRestrictionOnFilter = (e) => {
    const filters = [];
    const keys = Object.keys(e.filters) || [];
    keys.forEach(key => {
      filters.push({
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: 'CONTEM'
      });
    });
    this.props.lookupRestrictionApplyFilter(filters);
  }

  lookupRestrictionOnClick = (e) => {
    const type = this.props.locationFilterType;
    const parent = this.props.record.parent;
    const filters = [];
    if (type) {
      filters.push({
        campo: "type",
        valor: type,
        tipoFiltro: 'IGUAL_A'
      });
    }
    if (parent && parent.oid) {
      filters.push({
        campo: "parent._id", //TODO To verify if we will use the _id like that in the frontend
        valor: parseInt(parent.oid),
        tipoFiltro: 'IGUAL_A'
      })
    }
    this.props.locationApplyBaseFilter(filters);
    this.props.lookupRestrictionLoad();
    this.props.lookupRestrictionSetVisible(true);
  }

  lookupRestrictionOnHide = (e) => {
    this.props.lookupRestrictionSetVisible(false);
  }

  lookupRestrictionOnConfirm = (e) => {
    this.props.lookupRestrictionAddRecords(e.selection);
    this.props.lookupRestrictionSetVisible(false);
  }

  lookupRestrictionOnCancel = (e) => {
    this.props.lookupRestrictionSetVisible(false);
  }

  lookupRestrictionOnSelectLocation = (e) => {
    this.props.lookupRestrictionSetSelectedRecords(e.value);
  }

  lookupRestrictionOnRemoveSelected = (e) => {
    this.props.lookupRestrictionFilterSelectedRecords();
  }

  lookupRestrictionOnComplete = e => { };

  lookupRestrictionOnInputFilter = (e) => {
    const value = e.value;
    const filters = [];
    if (typeof value === 'string') {
      filters.push({
        campo: "name",
        valor: value,
        tipoFiltro: 'CONTEM'
      });
      this.props.lookupRestrictionSetSearchText(value);
    } else {
      this.props.lookupRestrictionSetSearchText("");
      this.props.lookupRestrictionAddRecords([value]);
    }
    this.props.lookupRestrictionApplyFilter(filters);
  }

  lookupParentOnPage = (e) => {
    this.props.lookupParentPaginate({
      max: e.rows,
      index: e.first
    });
  }

  lookupParentOnSort = (e) => {
    this.props.lookupParentSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField
    });
  }

  lookupParentOnFilter = (e) => {
    const filters = [];
    const keys = Object.keys(e.filters) || [];
    keys.forEach(key => {
      filters.push({
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: 'CONTEM'
      });
    });
    this.props.lookupParentApplyFilter(filters);
  }

  lookupParentOnClick = (e) => {
    const locationType = this.props.locationFilterType;
    let parentType = null
    switch (locationType) {
      case "STATE":
        parentType = 'COUNTRY';
        break;
      case "CITY":
        parentType = 'STATE';
        break;
      case "ZIP_CODE_RANGE":
        parentType = 'CITY';
        break;
      default:
        parentType = ''
        break;
    }

    const filters = [];
    if (parentType) {
      filters.push({
        campo: "type",
        valor: parentType,
        tipoFiltro: 'IGUAL_A'
      });
    }
    if (filters.length > 0) {
      this.props.locationApplyBaseFilter(filters);
    }
    this.props.lookupParentLoad();
    this.props.lookupParentSetVisible(true);
  }

  lookupParentOnHide = (e) => {
    this.props.lookupParentSetVisible(false);
  }

  lookupParentOnConfirm = (e) => {
    console.log(e);
    this.props.setParent(e.selection);
    this.props.lookupParentSetVisible(false);
    let filters = [{
      campo: "parent._id", //TODO To verify if we will use the _id like that in the frontend
      valor: e.selection.oid,
      tipoFiltro: 'IGUAL_A'
    }]
    this.props.locationApplyBaseFilter(filters);
  }

  lookupParentOnCancel = (e) => {
    this.props.lookupParentApplyFilter([]);
    this.props.setParent(null);
    this.props.lookupParentSetVisible(false);
  }

  lookupParentOnEdit = () => {
    if (this.props.record.oidParent) {
      this.props.history.push(`${routes.PATH_Parent}/${this.props.record.oidParent}`);
    }
  }

  lookupParentOnComplete = e => {
    // this.props.lookupParentApplyFilter([]);
  };

  lookupParentOnInputFilter = (e) => {
    const value = e.value;
    const locationType = this.props.locationFilterType;
    let parentType = null
    switch (locationType) {
      case "STATE":
        parentType = 'COUNTRY';
        break;
      case "CITY":
        parentType = 'STATE';
        break;
      case "ZIP_CODE_RANGE":
        parentType = 'CITY';
        break;
      default:
        parentType = '';
        break;
    }
    if (typeof value === 'string') {
      const filters = [{
        campo: "name",
        valor: value,
        tipoFiltro: 'CONTEM'

      }];
      if (parentType) {
        filters.push({
          campo: "type",
          valor: parentType,
          tipoFiltro: 'IGUAL_A'
        });
      }
      this.props.setField("parent.name", value);
      this.props.setField("parent.oid", null);
      this.props.lookupParentApplyFilter(filters)
    } else {
      this.props.setParent(value);
      // const filter = {
      //   campo: "parent._id",
      //   valor: value.oid,
      //   tipoFiltro: 'CONTEM'
      // }
      // this.props.lookupParentApplyFilter([filter])
    }
  }

  locationsOnSelectionChange = (e) => {
    this.props.setSelectedLocations(e.value);
  }

  onRemoveSelectedLocations = (e) => {
    this.props.removeSelectedLocations();
    this.props.setSelectedLocations(null);
  }

  onUploadLocalizations = ({ target }) => {
    this.props.importXLSXLocalizations(target.files, "localizations");
    target.value = null;
  }

  onUploadRestrictions = ({ target }) => {
    this.props.importXLSXLocalizations(target.files, "restricions");
    target.value = null;
  }

  dialogAddLocationsOnOpen = (e) => {
    this.props.setDialogAddLocationsVisible(true);
  }

  dialogAddLocationsOnClose = (e) => {
    this.props.lookupLocationApplyFilter([])
    this.props.locationApplyBaseFilter([]);
    this.props.setParent(null);
    this.props.setLocationFilterType(null);
    this.props.setParentDisabled(true);
    this.props.setDialogAddLocationsVisible(false);
    this.props.lookupLocationSetRecords([]);
    this.props.lookupLocationSetSelectedRecords([]);
  }

  dialogAddLocationsOnConfirm = (e) => {
    this.props.lookupLocationApplyFilter([])
    this.props.locationApplyBaseFilter([]);
    this.props.setParent(null);
    this.props.setLocationFilterType(null);
    this.props.setParentDisabled(true);
    this.props.setDialogAddLocationsVisible(false);
    this.props.addLocationsCurrentRecord();
    this.props.lookupLocationSetRecords([]);
    this.props.lookupLocationSetSelectedRecords([]);
  }

  dialogAddLocationsOnAdd = (e) => {
    this.props.lookupLocationApplyFilter([])
    this.props.locationApplyBaseFilter([]);
    this.props.addLocationsCurrentRecord();
    this.props.lookupLocationSetRecords([]);
    this.props.lookupLocationSetSelectedRecords([]);
  }

  restrictionsOnSelectionChange = (e) => {
    this.props.setSelectedRestrictions(e.value);
  }

  onRemoveSelectedRestrictions = (e) => {
    this.props.removeSelectedRestrictions();
    this.props.setSelectedRestrictions(null)
  }

  dialogAddRestrictionsOnOpen = (e) => {
    this.props.setDialogAddRestrictionsVisible(true);
  }

  dialogAddRestrictionsOnClose = (e) => {
    this.props.locationApplyBaseFilter([]);
    this.props.setParent(null);
    this.props.setLocationFilterType(null);
    this.props.setParentDisabled(true);
    this.props.setDialogAddRestrictionsVisible(false);
    this.props.lookupRestrictionSetRecords([]);
    this.props.lookupRestrictionSetSelectedRecords([]);
  }

  dialogAddRestrictionsOnConfirm = (e) => {
    this.props.locationApplyBaseFilter([]);
    this.props.setParent(null);
    this.props.setLocationFilterType(null);
    this.props.setParentDisabled(true);
    this.props.setDialogAddRestrictionsVisible(false);
    this.props.addRestrictionsCurrentRecord();
    this.props.lookupRestrictionSetRecords([]);
    this.props.lookupRestrictionSetSelectedRecords([]);
  }

  dialogAddRestrictionsOnAdd = (e) => {
    this.props.locationApplyBaseFilter([]);
    this.props.addRestrictionsCurrentRecord();
    this.props.lookupRestrictionSetRecords([]);
    this.props.lookupRestrictionSetSelectedRecords([]);
  }

  render() {
    let locationsColumns = [<Column key="selectorColumn" selectionMode="multiple" style={{ width: '3em' }} />];
    locationsColumns = [...locationsColumns, ...this.props.locationsColumns.map(col => {
      return (<Column {...col} key={col.field} />)
    })];

    const content = (
      <RegionForm
        changeHandler={this.props.changeHandler}
        record={this.props.record}

        lookupCarrier={this.props.lookupCarrier}
        lookupCarrierOnHide={this.lookupCarrierOnHide}
        lookupCarrierOnConfirm={this.lookupCarrierOnConfirm}
        lookupCarrierOnCancel={this.lookupCarrierOnCancel}
        lookupCarrierOnPage={this.lookupCarrierOnPage}
        lookupCarrierOnSort={this.lookupCarrierOnSort}
        lookupCarrierOnFilter={this.lookupCarrierOnFilter}
        lookupCarrierOnClick={this.lookupCarrierOnClick}
        lookupCarrierOnEdit={this.lookupCarrierOnEdit}
        lookupCarrierOnComplete={this.lookupCarrierOnComplete}
        lookupCarrierOnInputFilter={this.lookupCarrierOnInputFilter}

        lookupLocation={this.props.lookupLocation}
        lookupLocationOnHide={this.lookupLocationOnHide}
        lookupLocationOnConfirm={this.lookupLocationOnConfirm}
        lookupLocationOnCancel={this.lookupLocationOnCancel}
        lookupLocationOnPage={this.lookupLocationOnPage}
        lookupLocationOnSort={this.lookupLocationOnSort}
        lookupLocationOnFilter={this.lookupLocationOnFilter}
        lookupLocationOnClick={this.lookupLocationOnClick}
        lookupLocationOnComplete={this.lookupLocationOnComplete}
        lookupLocationOnInputFilter={this.lookupLocationOnInputFilter}
        lookupLocationOnSelectLocation={this.lookupLocationOnSelectLocation}
        lookupLocationOnRemoveSelected={this.lookupLocationOnRemoveSelected}

        lookupRestriction={this.props.lookupRestriction}
        lookupRestrictionOnHide={this.lookupRestrictionOnHide}
        lookupRestrictionOnConfirm={this.lookupRestrictionOnConfirm}
        lookupRestrictionOnCancel={this.lookupRestrictionOnCancel}
        lookupRestrictionOnPage={this.lookupRestrictionOnPage}
        lookupRestrictionOnSort={this.lookupRestrictionOnSort}
        lookupRestrictionOnFilter={this.lookupRestrictionOnFilter}
        lookupRestrictionOnClick={this.lookupRestrictionOnClick}
        lookupRestrictionOnComplete={this.lookupRestrictionOnComplete}
        lookupRestrictionOnInputFilter={this.lookupRestrictionOnInputFilter}
        lookupRestrictionOnSelectLocation={this.lookupRestrictionOnSelectLocation}
        lookupRestrictionOnRemoveSelected={this.lookupRestrictionOnRemoveSelected}

        locationsColumns={locationsColumns}
        selectedLocations={this.props.selectedLocations}
        locationsRecords={this.props.locationsRecords}
        locationsFirst={this.props.locationsFirst}
        locationsRows={this.props.locationsRows}
        locationsTotalRecords={this.props.locationsTotalRecords}
        locationsSortField={this.props.locationsSortField}
        locationsOrder={this.props.locationsOrder}
        onFilterLocations={this.onFilterLocations}
        onPageLocations={this.onPageLocations}
        onSortLocations={this.onSortLocations}
        locationsOnSelectionChange={this.locationsOnSelectionChange}
        onRemoveSelectedLocations={this.onRemoveSelectedLocations}
        onUploadLocalizations={this.onUploadLocalizations}
        onUploadRestrictions={this.onUploadRestrictions}

        dialogAddLocations={this.props.dialogAddLocations}
        dialogAddLocationsOnOpen={this.dialogAddLocationsOnOpen}
        dialogAddLocationsOnClose={this.dialogAddLocationsOnClose}
        dialogAddLocationsOnConfirm={this.dialogAddLocationsOnConfirm}
        dialogAddLocationsOnAdd={this.dialogAddLocationsOnAdd}

        selectedRestrictions={this.props.selectedRestrictions}
        restrictionsRecords={this.props.restrictionsRecords}
        restrictionsFirst={this.props.restrictionsFirst}
        restrictionsRows={this.props.restrictionsRows}
        restrictionsTotalRecords={this.props.restrictionsTotalRecords}
        restrictionsSortField={this.props.restrictionsSortField}
        restrictionsOrder={this.props.restrictionsOrder}
        onFilterRestrictions={this.onFilterRestrictions}
        onPageRestrictions={this.onPageRestrictions}
        onSortRestrictions={this.onSortRestrictions}
        restrictionsOnSelectionChange={this.restrictionsOnSelectionChange}
        onRemoveSelectedRestrictions={this.onRemoveSelectedRestrictions}

        dialogAddRestrictions={this.props.dialogAddRestrictions}
        dialogAddRestrictionsOnOpen={this.dialogAddRestrictionsOnOpen}
        dialogAddRestrictionsOnClose={this.dialogAddRestrictionsOnClose}
        dialogAddRestrictionsOnConfirm={this.dialogAddRestrictionsOnConfirm}
        dialogAddRestrictionsOnAdd={this.dialogAddRestrictionsOnAdd}

        types={this.props.types}
        lookupParent={this.props.lookupParent}
        lookupParentOnHide={this.lookupParentOnHide}
        lookupParentOnConfirm={this.lookupParentOnConfirm}
        lookupParentOnCancel={this.lookupParentOnCancel}
        lookupParentOnPage={this.lookupParentOnPage}
        lookupParentOnSort={this.lookupParentOnSort}
        lookupParentOnFilter={this.lookupParentOnFilter}
        lookupParentOnClick={this.lookupParentOnClick}
        lookupParentOnEdit={this.lookupParentOnEdit}
        lookupParentOnComplete={this.lookupParentOnComplete}
        lookupParentOnInputFilter={this.lookupParentOnInputFilter}
        changeLocationFilterTypeHandler={this.props.changeLocationFilterTypeHandler}
        locationFilterType={this.props.locationFilterType}

      />
    );
    const title = `Região ${this.props.record.oid || 'nova'}`
    const header = (
      <div>
        <h1>{title}</h1>
        <hr className="lxSplitter" ></hr>
        <div>
          <Toolbar
            onSave={this.props.saveHandler} saveButtonStyle='highlighted'
            onRemove={this.props.removeHandler} removeButtonStyle='danger'
            onNew={this.props.newHandler}
            onSearch={this.searchHandler}>
          </Toolbar>
        </div>
      </div>
    );
    return (<Page header={header} content={content} />);
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RegionFormPageContainer);