import { types } from "./policesResources.actions";
import { Reducer } from "../../services/reducer.factory";

const initialState = {
  columns: [
    {
      field: "oid",
      header: "Código",
      columnKey: "oid",
      sortable: true,
      filter: false,
    },
   
    {
      field: "resourceKey",
      header: "ResourceKey",
      columnKey: "resourceKey",
      sortable: true,
      filter: true,
    }
    ,
    {
      field: "policeId",
      header: "ID Politica",
      columnKey: "policeId",
      sortable: true,
      filter: true,
    },
    {
      field: "polices.name",
      header: "Politica",
      columnKey: "polices.name",
      sortable: true,
      filter: true,
    },
    {
      field: "menu.name",
      header: "Menu",
      columnKey: "menu.name",
      sortable: true,
      filter: true,
    }
  ],
  lookupPolices: {
    visible: false,
    modal: true,
    header: "Politicas",
  },
  lookupMenus: {
    visible: false,
    modal: true,
    header: "Menus",
  },
  currentRecord: {
    polices: { name: "" },
    policeId: null,
    resourceKey: "",
    action: "VIEW"
  },
};

const reducer = new Reducer(initialState, types);

export function CRUDPolicesResourcesReducer(state, action) {
  state = reducer.execute(state, action);
  return execute(state, action);
}

function execute(state, action) {
  let result = state;
  switch (action.type) {
    case types.SET_LOOKUP_POLICES_VISIBLE:
      return setLookupPolicesVisible(state, action);
    case types.SET_LOOKUP_MENUS_VISIBLE:
      return setLookupMenusVisible(state, action);
    default:
      return result;
  }
}

function setLookupMenusVisible(state, action) {
  let lookupMenus = state.lookupMenus;

  lookupMenus = Object.assign({}, lookupMenus, { visible: action.visible });
  return Object.assign({}, state, { lookupMenus });
}
function setLookupPolicesVisible(state, action) {
  let lookupPolices = state.lookupPolices;

  lookupPolices = Object.assign({}, lookupPolices, { visible: action.visible });
  return Object.assign({}, state, { lookupPolices });
}
