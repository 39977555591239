import { Column } from "primereact/column";
import React from "react";
import { connect } from "react-redux";
import { Loader } from '../../../components/loader/Loader';
// import { createBrowserHistory } from "history"; 
import { Page } from "../../../components/page/Page";
import { actions } from "../collectionOrder.actions";
import { CollectionOrderForm } from "./CollectionOrderForm";
import "./CollectionOrderForm.scss"; 
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";

import { InputTextarea } from "primereact/inputtextarea";
import UpdateDataEntrega from "../components/updateDataEntrega";

const mapStateToProps = store => {
  const { collectionOrderState } = store;
  const link = window.location.href;
  const record = collectionOrderState.currentRecord;
  const orders = collectionOrderState.orders;
  const ordersColumns = collectionOrderState.ordersColumns;
  const status = collectionOrderState.status;
  const currentOrderEdit = collectionOrderState.currentOrderEdit;
  const equipmentTypes = collectionOrderState.equipmentTypes;
  const fileImg = collectionOrderState.fileImg;

  const disabledBtnConfirm = record.vehicle.plate
    && record.driver.name
    && record.driver.documentNumber
    && !record.disabledBtnConfirmm
    && record.status === "NEW" || record.status === "APROVED"
    ? false : true;

  const disabledBtnReject = record.disabledBtnReject || record.status != "NEW";
  const { dockScheduling } = record
  return {
    record,
    fileImg,
    dockScheduling,
    link,
    status,
    orders,
    ordersColumns,
    currentOrderEdit,
    disabledBtnConfirm,
    disabledBtnReject,
    equipmentTypes,
    docasHourSchedules: collectionOrderState.docasHourSchedules,
    showMessageErro: collectionOrderState.showMessageErro,
    errorMessage: collectionOrderState.errorMessage,
    showModalUploadImg: collectionOrderState.showModalUploadImg,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    loadCollectionOrder: (link) => dispatch(actions.loadCollectionOrder(link)),
    confirmCollectionOrderHandler: (link, statusCollectionOrder) => dispatch(actions.confirmCollectionOrder(link, statusCollectionOrder)),
    rejectedCollectionOrderHandler: (link) => dispatch(actions.rejectedCollectionOrder(link)),
    changeHandler: event => {
      const target = event.target;
      const field = target.name;
      let value = target.value;
      if (field == "vehicle.plate") {
        //remover tudo, menos letras e digitos
        value = value.replace(/[^a-zA-Z0-9]/g, '');
      }

      dispatch(actions.removeErrors([{ field }]));
      dispatch(actions.setField(field, value));
    },


    loadEquipmentType: (link) => dispatch(actions.loadEquipmentType(link)),
    loadHoursDocks: (link) => dispatch(actions.loadHoursDocks(link)),

    changeEquipmentTypeHandler: (equipmentType) => {
      const { name, oid } = equipmentType;
      const field = "towerIntegration.equipmentType";
      dispatch(actions.removeErrors([{ field }]));
      dispatch(actions.setField(field, { name, oid }));
    },
    sendReceipt: data => dispatch(actions.sendReceipt(data)),
    setCodeOrder: order => dispatch(actions.setCodeOrder(order)),
    loadOrdersInCargoShipment: data => dispatch(actions.loadOrdersInCargoShipment(data)),
    setShowModalUploadImg: visible => dispatch(actions.setShowModalUploadImg(visible)),
    changeScheduleDocaHourHandler: dockScheduling => {
      dispatch(actions.setField("dockScheduling", dockScheduling));
    },
    hideMessageErro: () => dispatch(actions.setShowMessageErro(false)),
    changeHandlerImage: (fileImg) => dispatch(actions.changeHandlerImage(fileImg)),
    onChangeDeliveryDate: (deliveryDate) => dispatch(actions.onChangeDeliveryDate(deliveryDate)),
    onChangeObs: (obs) => dispatch(actions.onChangeObs(obs))
  }
}

function Check(props) {
  if (!props.show) {
    return (<div style={{ width: "17px" }}></div>)
  }
  return (
    <React.Fragment>
      <div >
        <i className="pi pi-check"></i>
      </div>

    </React.Fragment>

  )
}

class CollectionOrderFormPageContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
    }
  }

  componentDidMount(props) {
    this.props.loadCollectionOrder(this.props.link);
  }


  onAcceptedCollectionOrderHandler = () => {
    const statusCollectionOrder = this.props.record.status;
    const index = this.props.link.split("/");
    const data = {
      link: this.props.link,
      index: parseInt(index[index.length - 1]),
      plate: this.props.record.vehicle.plate,
      driver: {
        name: this.props.record.driver.name,
        documentNumber: this.props.record.driver.documentNumber,
      },
      towerIntegration: this.props.record.towerIntegration,
      dockScheduling: this.props.record.dockScheduling
    };
    this.props.confirmCollectionOrderHandler(data, statusCollectionOrder);

  };

  onRejectCollectionOrderHandler = () => {
    const index = this.props.link.split("/");
    const data = {
      link: this.props.link,
      index: parseInt(index[index.length - 1]),
    };
    this.props.rejectedCollectionOrderHandler(data);
  };


  onDropdownEquipmentTypeChange = (e) => {
    this.props.changeEquipmentTypeHandler(e.target.value)
  };

  onDropdownScheduleDocaHourChange = (e) => {

    let dockScheduling = this.props.dockScheduling;

    let dockSelected = e.target.value;

    //verificar se ja foi add 
    for (let index = 0; index < dockScheduling.length; index++) {
      if (dockScheduling[index].sigla == dockSelected.sigla && dockScheduling[index].documentNumber == dockSelected.documentNumber) {
        dockScheduling[index].rangeTime = dockSelected;
        break;
      }
    }
    this.props.changeScheduleDocaHourHandler(dockScheduling)
  };


  onTabChange = (e) => {
    this.setState({ activeIndex: e.index })
    if (this.state.activeIndex == 0) {
      const data = {
        "link": this.props.link,
      }
      this.props.loadOrdersInCargoShipment(data);
    }
  }

  onSetCodeOrder = (order) => {
    this.props.setCodeOrder(order);
  } 
  changeHandler = (e) => {
    this.props.changeHandler(e);
  }
  // saveHandler =(e)=>{
  //   this.props.saveHandler(e)
  // }

  showModalUploadImg = (visible) => {
    this.props.setShowModalUploadImg(visible);
  }

  onChangeDeliveryDate = (event) => {
    this.props.onChangeDeliveryDate(event.target.value)
  }
  onChangeObs = (event) => {
    this.props.onChangeObs(event.target.value)
  }
  render() { 

    const viewButtonUpload = (order) => {
      return (
        <div className="lxActionButtons">
          <button
            className="lognex-btn-highlighted"
            onClick={(e) => {
              this.onSetCodeOrder(order);
              this.showModalUploadImg(true);
            }}
            title={"Enviar comprovante de entrega"}
          >
            <i className="pi pi-upload"></i>
          </button>
        </div>
      )
    }

    const imageTemplate = (rowData, column) => {
      let button = viewButtonUpload(rowData)
      return (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Check show={rowData.hasImage} />
          <div style={{ width: "40px" }}>
            {button}

          </div>
        </div>
      )
    }
    let ordersColumn = this.props.ordersColumns.map((col, index) => {
      const key = `${index}-${col.field}`;
      if (col.field === "image") {
        return (<Column {...col} style={{ textAlign: "center" }} key={key} body={imageTemplate} />)
      }
      return (<Column {...col} style={{ textAlign: "center" }} key={key} field={col.field} />)
    })

    const content = () => {
      if (this.props.status === 'SUCCESS_LOAD' || this.props.status === 'FAIL_REJECT' || this.props.status === 'FAIL_CONFIRM') {

        return (
          <div>
            <CollectionOrderForm
              record={this.props.record}
              orders={this.props.orders}
              columns={ordersColumn}
              dockScheduling={this.props.dockScheduling}
              changeHandler={this.props.changeHandler}
              onAcceptedHandler={this.onAcceptedCollectionOrderHandler}
              onRejectHandler={this.onRejectCollectionOrderHandler}
              disabledBtnConfirm={this.props.disabledBtnConfirm}
              disabledBtnReject={this.props.disabledBtnReject}
              activeIndex={this.state.activeIndex}
              onTabChange={this.onTabChange}
              equipmentTypes={this.props.equipmentTypes}
              onDropdownEquipmentTypeChange={this.onDropdownEquipmentTypeChange}
              docasHourSchedules={this.props.docasHourSchedules}
              onDropdownScheduleDocaHourChange={this.onDropdownScheduleDocaHourChange}
            />
          </div>
        )
      } else if (this.props.status === 'FAIL_LOAD') {
        return (
          <div className="collectionOrder-loadDanger">
            <h1>Ordem de coleta NÃO ENCONTRADA</h1>
          </div>
        )

      } else if (this.props.status === 'SUCCESS_CONFIRM') {
        return (
          <div className="collectionOrder-success">
            <h1>Confirmação da ordem de coleta recebida com sucesso.</h1>
          </div>
        )
      } else if (this.props.status === 'SUCCESS_REJECT') {
        return (
          <div className="collectionOrder-success">
            <h1>Cancelamento da ordem de coleta recebida com sucesso.</h1>
          </div>
        );
      }
    }
 

    return (

      <div> 
        {this.props.showMessageErro &&
          <Dialog
            header={"Atenção"}
            visible={this.props.showMessageErro}
            style={{ width: "320px" }}
            modal={true}
            onHide={this.props.hideMessageErro}
          >
            <h2 className="message-erro">{this.props.errorMessage}</h2>
          </Dialog>
        }

        {this.props.showModalUploadImg  &&
          <UpdateDataEntrega />
        }
        <Page content={content()} />

      </div>
    )

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CollectionOrderFormPageContainer);
