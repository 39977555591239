import React from 'react'
import { AutoComplete } from 'primereact/autocomplete';
import './InputWhitSearchPluss.scss'

export const InputWhitSearchPluss = (props) => {
    return (
            <div className="input-with-search-plus">
            <AutoComplete
                value={props.value}
                suggestions={props.records}
                completeMethod={props.onComplete}
                field={props.field}
                onChange={props.onInputFilter}
                placeholder={props.placeholder}
                name={props.name}
            ></AutoComplete>
            <div className="btnSearch">
                <button
                    className="lognex-btn-highlighted"
                    title="Pesqusiar Agrupadores"
                    onClick={props.onClickSearchHandler}
                >
                    <i className="pi pi-search"></i>
                </button>
            </div>
            <div className="btnPluss">
                <button
                    title="Criar Agrupador"
                    onClick={props.onClickAddHandler}
                >
                    <i className="pi pi-plus"></i>
                </button>
            </div>
            </div>
    )
}

