
import React from 'react';
import { connect } from 'react-redux';
import Toolbar from "../../../components/toolbar/Toolbar";
import { actions } from '../palletStandard.actions';
import { routes } from '../../../constants';
import { Page } from "../../../components/page/Page";
import { PalletStandardForm } from './PalletStandardForm';



const mapStateToProps = state => {
  const record = state.crudPalletStandardState.currentRecord;

  return {
    record
  }
}

const mapDispatchToProps = dispatch => {
  return {


    setField: (field, value) => dispatch(actions.setField(field, value)),
    changeHandler: event => {
      const target = event.target;
      const field = target.name;
      let value = target.value;
      dispatch(actions.removeErrors([{ field }]));
      dispatch(actions.setField(field, value));
    },
    saveHandler: event => {
      dispatch(actions.save());
    },
    removeHandler: event => {
      dispatch(actions.remove());
    },
    newHandler: event => {
      dispatch(actions.makeNew());
    },
    loadById: oid => dispatch(actions.loadById(oid))
  }
}

class PalletStandardFormPageContainer extends React.Component {

  constructor(props) {
    super(props);
    actions.afterSave = ({ oid }) => {
      this.props.history.replace(`${routes.PATH_PALLETSTANDARD}/${oid}`);
    };
    actions.afterNew = () => {
      this.props.history.replace(`${routes.PATH_PALLETSTANDARD}/novo`);
    };
    actions.afterRemove = () => {
      this.props.history.replace(`${routes.PATH_PALLETSTANDARD}`);
    };
  }

  componentDidMount() {
    const oid = this.props.match.params.id;

    if (oid && parseInt(oid)) {
      this.props.loadById(oid);
    } else {
      this.props.newHandler();
    }
  }
  searchHandler = () => {
    this.props.history.push(`${routes.PATH_PALLETSTANDARD}`)
  }


  render() {
    const content = (
      <PalletStandardForm
        changeHandler={this.props.changeHandler}
        record={this.props.record}


      />
    );
    const title = `Padrão de Pallet ${this.props.record.oid || 'novo'}`
    const header = (
      <div>
        <h1>{title}</h1>
        <div>
          <Toolbar
            onSave={this.props.saveHandler} saveButtonStyle='highlighted'
            onRemove={this.props.removeHandler} removeButtonStyle='danger'
            onNew={this.props.newHandler}
            onSearch={this.searchHandler}>
          </Toolbar>
        </div>
      </div>
    );
    return (<Page header={header} content={content} />);
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PalletStandardFormPageContainer);