import { types } from "./driver.actions";
import { Reducer } from "../../services/reducer.factory";

const initialState = {
    columns: [
        {
            field: "oid",
            header: "Código",
            columnKey: "oid",
            sortable: false,
            filter: true
        },
        {
            field: "name",
            header: "Nome",
            columnKey: "name",
            sortable: false,
            filter: true
        },
        {
            field: "documentNumber",
            header: "Número do documento",
            columnKey: "documentNumber",
            sortable: false,
            filter: true
        },
    ],

    currentRecord: {
        name: "",
        documentNumber: "",
    },
    records: [
        {
            oid: 1,
            name: "João da Silva",
            documentNumber: "12345678912"
        },
        {
            oid: 2,
            name: "Maria da Silva",
            documentNumber: "12345678913"
        }
    ]
}


const reducer = new Reducer(initialState, types);

export function CRUDDriverReducer(state, action) {
    state = reducer.execute(state, action);
    return execute(state, action);
}

function execute(state, action) {
    let result = state;
    switch (action.type) {
        default:
            result = state;
            break;
    }
    return result;
}