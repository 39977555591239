import React from "react";
import { InputText } from "primereact/inputtext";
import { ButtonDialog } from "../../../../../../components/button-dialog/ButtonDialog";
import { UnitConfigurationEmailList } from "../list/UnitConfigurationEmailList";

export  const EmailPanelConfigUnidade = (props) => {
  return (
    <div className={"panel-categoria"}>
      <div className="p-grid">
        <div className="p-col-12">
          <h3>{props.title}</h3>
        </div>

        <div className="p-col-6 form-field-container">
          <label>E-mail</label>
          <InputText
            maxLength={300}
            value={props.inputEmailValue || ""}
            onChange={props.inputEmailChangeHandler}
            name="email"
          />
        </div>

        <div className="p-col-6  align-bottom-right">
          <button
            disabled={props.disableBtnAdd}
            className={"lognex-btn-highlighted"}
            onClick={props.onClickBtnAdd}
            title="Novo"
            style={{ marginRight: ".25em" }}
          >
            <i className="pi pi-plus"></i>
          </button>

          <ButtonDialog
            onClick={props.onClickBtnRemove}
            button={{
              title: "Remover",
              className: "lognex-btn-danger",
              disabled: props.disableBtnRemove,
            }}
            dialog={{
              header: "Confirmação",
              modal: false,
              message: "Deseja realmente remover o registro?",
            }}
          ></ButtonDialog>
        </div>

        <UnitConfigurationEmailList
          records={props.records}
          onSelectedRecordsChange={props.onSelectedRecordsChange}
          selectedEmail={props.selectedEmail}
          columns={props.columns}
        />
      </div>
    </div>
  );
};
