import React from "react";
import { ConfigurationPanel } from "./ConfigurationPanel";

export const OrderManagementSection = (props) => {
    return (
        <ConfigurationPanel
            id={"gestaoembarque"}
            title={"Gestão de Embarque"}
            configurations={props.orderManagementConfigurations}
        />
    )
}