import React from 'react';

import DriverFormPageContainer from './DriverFormPageContainer';

export class DriverFormPage extends React.Component{

    
    render(){
        return (
           <DriverFormPageContainer {...this.props} />
        )
    }
}