import { Action } from '../../services/actions.factory';
import api from "../../services/api.service";
import { build } from '../../services/crudFactory';
import { validate } from './validations';

const BASE_PATH = '/lognex/profiles';
const prefix = 'crudPerfil';
const crud = build(validate, BASE_PATH);
const actions = new Action(prefix, crud);


const newTypes = {
    SET_LOOKUP_POLICES_VISIBLE: `${prefix}setLookupPolicesVisible`,
    SET_POLICE: `${prefix}setPolice`,
    ADD_POLICE_TO_GROUP: `${prefix}addPoliceGroup`,
    SET_GROUPS: `${prefix}setGroups`,
    ADD_GROUP_TO_CHILDREN: `${prefix}addGroupFilho`,
    REMOVER_GROUP_LIST: `${prefix}removeGroupBy`,
}


const types = Object.assign({}, actions.types, newTypes);
 

actions.removeGroupBy = (oid) => {
    return {
        type: types.REMOVER_GROUP_LIST,
        oid,
    };
};

actions.addGroupFilho = (group) => {
    return {
        type: types.ADD_GROUP_TO_CHILDREN,
        group,
    };
};

actions.setLookupPolicesVisible = (visible) => {
    return {
        type: types.SET_LOOKUP_POLICES_VISIBLE,
        visible,
    };
};

actions.setPolice = (police) => {
    return {
        type: types.SET_POLICE,
        police,
    };
};
actions.setGroups = (groups) => {
    return {
        type: types.SET_GROUPS,
        groups,
    };
};
actions.addPoliceGroup = () => {
    return (dispatch, getState) => {
        const currentRecord = getState().crudPerfilState.currentRecord;
        const police = getState().crudPerfilState.polices;

        const payload = {
            "group": {
                "oid": currentRecord.oid,
                "name": currentRecord.name
            },
            "polices": {
                "oid": police.oid,
                "name": police.name
            },
            "policeId": police.oid,
            "groupId": currentRecord.oid
        }
        api.post("/api/v1/lognex/policesGroup", payload)
            .then(({ data }) => {

            }).catch((err) => {
                console.log(err);
                dispatch(actions.setRecordsLoadByGroupId([]));
            });
    };
};

actions.loadAllGroups = () => {
    return (dispatch) => {
        api.post("/api/v1/lognex/profiles/loadAll")
            .then(({ data }) => {
                dispatch(actions.setGroups(data));
            }).catch((err) => {
                console.log("loadAllGroups erro: ", err);
                dispatch(actions.setGroups([]));
            });
    };
};

export { actions, types };
