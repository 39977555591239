import { types } from "./tower_dashboard.actions";
import { Reducer } from "../../services/reducer.factory";
import moment from "moment";
import { CANCELED, CREATED, END_TRAVEL, TRAVELING, TRAVEL_LAG_TREND, TRAVEL_LATE, TRAVEL_ON_TIME } from "./constants";

const initialState = {
  columns: [
    {
      field: "oid",
      header: "ID",
      columnKey: "oid",
      sortable: false,
      filter: false,
    },
    {
      field: "eventRecord.createdAt",
      header: "Cadastro",
      columnKey: "eventRecord.createdAt",
      sortable: false,
      filter: false,
    }, {
      field: "datetime_begin",
      header: "Início",
      columnKey: "datetime_begin",
      sortable: false,
      filter: false,
    },
    {
      field: "timeLastPostion",
      header: "Sinc",
      columnKey: "timeLastPostion",
      sortable: false,
      filter: false,
    },
    {
      field: "vehicle.plate",
      header: "Veículo",
      columnKey: "vehicle.plate",
      sortable: false,
      filter: false,
    },
    {
      field: "status_time",
      header: "Status", //ON_TIME, LATE
      columnKey: "status_time",
      sortable: false,
      filter: false,
    },
    {
      field: "travel_status",
      header: "Viagem", //CRIADA,EM_VIAGEM,FINALIZADA
      columnKey: "travel_status",
      sortable: false,
      filter: false,
    },
    {
      field: "firstOrigin.address.City",
      header: "Origem",
      columnKey: "firstOrigin.address.City",
      sortable: false,
      filter: false,
    },

    {
      field: "destination.description",
      header: "Destino Final",
      columnKey: "destination.description",
      sortable: false,
      filter: false,
    },
    {
      field: "tempoTotalKmtotal",
      header: "Tempo/km total",
      columnKey: "tempoTotalKmtotal",
      sortable: false,
      filter: false,
    },
    {
      field: "lastDelivery.description",
      header: "Último Atendimento",
      columnKey: "nextDelivery.description",
      sortable: false,
      filter: false,
    },
    {
      field: "lastDelivery.deliveryDate",
      header: "Horário Último Atendimento",
      columnKey: "lastDelivery.deliveryDate",
      sortable: false,
      filter: false,
    },
    {
      field: "nextDelivery.description",
      header: "Próximo Atendimento",
      columnKey: "nextDelivery.description",
      sortable: false,
      filter: false,
    },
    {
      field: "nextDelivery.deliveryForecastDate",
      header: "Horário Previsão próximo Atendimento",
      columnKey: "nextDelivery.deliveryForecastDate",
      sortable: false,
      filter: false,
    },
    //Incluir km e tempo pra próxima atendimento
    {
      field: "nextDelivery.currentDistanceLeft",
      header: "Próxima entrega em",
      columnKey: "nextDelivery.currentDistanceLeft",
      sortable: false,
      filter: false,
    },

    {
      field: "distanceTotalKm",
      header: "Km Total",
      columnKey: "distanceTotalKm",
      sortable: false,
      filter: false,
    },
    {
      field: "durationSeg",
      header: "Tempo Total ",
      columnKey: "durationSeg",
      sortable: false,
      filter: false,
    },

    {
      field: "currentPercent",
      header: "% Viagem",
      columnKey: "currentPercent",
      sortable: false,
      filter: false,
    },
    {
      field: "acoes",
      header: "Ações",
      columnKey: "acoes",
      sortable: false,
      filter: false,
    },
  ],

  totalizers: {
    indicatorTotalTravel: 0,
    indicatorTotalTravelInProgress: 0,
    indicatorTotalTravelLate: 0,
    indicatorTotalTravelDelayTrend: 0,
    indicatorTotalTravelOnTime: 0,
    indicatorTotalTravelCompleted: 0,
    indicatorTotalTravelCanceladas: 0,
    indicatorTotalTravelNotMirrored: 0
  },
  inputObs: "",
  selectedTravel: null,
  visibleImputTravelObsDialog: false,

  lookupCarrier: {
    visible: false,
    modal: true,
    header: "Transportadora",
  },
  lookupDestination: {
    visible: false,
    modal: true,
    header: "Destino",
  },
  lookupOrigin: {
    visible: false,
    modal: true,
    header: "Origem",
  },
  lookupObservationType: {
    visible: false,
    modal: false,
    header: "Tipo de observação"
  },
  carrier: {
    tradeName: "",
    documentNumber: "",
  },
  destination: {
    description: "",
  },
  origin: {
    description: "",
  },
  observationType: {
    name: "",
  },
  statusSelectedFilter: null,
  statusTimeSelectedFilter: null,

  initialDate: moment().startOf("month").toDate(),
  finalDate: moment().toDate(),
  deliveryCode: null,
  nroEmbarque: null,
  statusTravelOptions: [
    {
      travel_status: null,
      status: "Todos",
    },
    {
      travel_status: TRAVELING,
      status: "Andamento",
    },
    {
      travel_status: END_TRAVEL,
      status: "Finalizada",
    },
    {
      travel_status: CANCELED,
      status: "Cancelada",
    },
  ],
  statusTimeOptions: [
    {
      status_time: null,
      statusTime: "Todos",
    },
    {
      status_time: TRAVEL_ON_TIME,
      statusTime: "Dentro do prazo",
    },
    {
      status_time: TRAVEL_LAG_TREND,
      statusTime: "Tendência de atraso",
    },
    {
      status_time: TRAVEL_LATE,
      statusTime: "Atrasada",
    },
  ],
  expandedRows: [],
  columnsRowTreeviewTemplate: [
    {
      field: "index",
      header: "Sequência",
      columnKey: "index",
      sortable: false,
      filter: false,
    },
    {
      field: "code",
      header: "Ordem",
      columnKey: "code",
      sortable: false,
      filter: false,
    },
    {
      field: "embarque",
      header: "Embarque",
      columnKey: "embarque",
      sortable: false,
      filter: false,
    },
    {
      field: "originalExpectedDeliveryDate",
      header: "DATA ENTREGA PREVISTA ORIGINAL",
      columnKey: "originalExpectedDeliveryDate",
      sortable: false,
      filter: false,
    },
    {
      field: "deliveryForecastDate",
      header: "DATA ENTREGA PREVISÃO ATUAL",
      columnKey: "deliveryForecastDate",
      sortable: false,
      filter: false,
    },
    {
      field: "deliveryDate",
      header: "DATA ENTREGA REALIZADA",
      columnKey: "deliveryDate",
      sortable: false,
      filter: false,
    },
    {
      field: "isOcurrence",
      header: "Canhoto",
      columnKey: "isOcurrence",
      sortable: false,
      filter: false,
    },
  ],
  columnsRowTreeviewGridPlatesTemplate: [
    {
      field: "oid",
      header: "ID Viagem",
      columnKey: "oid",
      sortable: false,
      filter: false,
    },
    {
      field: "plate",
      header: "Placa",
      columnKey: "plate",
      sortable: false,
      filter: false,
    },
    {
      field: "createdAt",
      header: "Data Cadastro",
      columnKey: "createdAt",
      sortable: false,
      filter: false,
    }, {
      field: "shipment_code",
      header: "Embarque",
      columnKey: "shipment_code",
      sortable: false,
      filter: false,
    }, {
      field: "deliveryForecastDate",
      header: "Data Previsão Entrega",
      columnKey: "deliveryForecastDate",
      sortable: false,
      filter: false,
    },
    {
      field: "distanceTotalKm",
      header: "Total Km",
      columnKey: "distanceTotalKm",
      sortable: false,
      filter: false,
    },
  ],
  filtersMapParams: [],
  totalizersFiltersParams: [],
  selectedTypeFilter: "andamento",
  filterAux: [],
};

const reducer = new Reducer(initialState, types);

export function CRUDTowerDashboardReducer(state, action) {
  state = reducer.execute(state, action);

  return execute(state, action);
}

function execute(state, action) {
  let result = state;
  switch (action.type) {
    case types.SET_TOTALIZERS:
      return setTotalizers(state, action);
    case types.SET_IMPUT_OBS:
      return setImputObs(state, action);
    case types.SET_SELECTED_TRAVEL:
      return setSelectedTravel(state, action);
    case types.SET_VISIBLE_OBS:
      return setVisibleObs(state, action);
    case types.SET_LOOKUP_CARRIER_VISIBLE:
      return setLookupCarrierVisible(state, action);
    case types.SET_CARRIER_FILTER:
      return setCarrierFilter(state, action);
    case types.SET_DESTINATION_FILTER:
      return setDestinationFilter(state, action);
    case types.SET_LOOKUP_DESTINATION_VISIBLE:
      return setLookupDestinationVisible(state, action);
    case types.SET_ORIGIN_FILTER:
      return setOriginFilter(state, action);
    case types.SET_LOOKUP_ORIGIN_VISIBLE:
      return setLookupOriginVisible(state, action);
    case types.SET_TRAVEL_STATUS_SELECTED:
      return setStatusSelectedFilter(state, action);
    case types.SET_TRAVEL_STATUS_TIME_SELECTED:
      return setStatusTimeSelectedFilter(state, action);

    case types.SET_INITIAL_DATE_FILTER:
      return setInitialDateFilter(state, action);
    case types.SET_FINAL_DATE_FILTER:
      return setFinalDateFilter(state, action);
    case types.SET_DELIVERY_CODE:
      return setDeliveryCode(state, action);
    case types.SET_LOOKUP_OBSERVATION_TYPE_VISIBLE:
      return setLookupObservationTypeVisible(state, action);
    case types.SET_OBSERVATION_TYPE:
      return setObservationType(state, action);
    case types.SET_EMBARQUE_ID_FILTER:
      return setEmbarqueIdFilter(state, action);
    case types.SET_EXPANDED_ROW:
      return setExpandedRows(state, action);
    case types.SET_FILTERS_PARAMS:
      return setFiltersMapParams(state, action);
    case types.SET_SELECTED_FILTER_TYPE:
      return setSelectedTypeFilter(state, action);
    case types.SET_TOTALIZERS_FILTERS_PARAMS:
      return setTotalizersFiltersParams(state, action);
    case types.SET_FILTERS_AUX:
      return setFilterAux(state, action);

    default:
      return result;
  }
}

function setFilterAux(state, action) {
  const filterAux = action.filterAux;
  return { ...state, filterAux };
}

function setTotalizersFiltersParams(state, action) {
  const totalizersFiltersParams = action.totalizersFiltersParams;
  return { ...state, totalizersFiltersParams };
}
function setSelectedTypeFilter(state, action) {
  const selectedTypeFilter = action.selectedTypeFilter;
  return { ...state, selectedTypeFilter };
}
function setFiltersMapParams(state, action) {
  const filtersMapParams = action.filtersMapParams;
  return { ...state, filtersMapParams };
}
function setEmbarqueIdFilter(state, action) {
  const nroEmbarque = action.nroEmbarque;
  return { ...state, nroEmbarque };
}

function setLookupObservationTypeVisible(state, action) {
  let lookupObservationType = state.lookupObservationType;
  lookupObservationType.visible = action.visible;
  return { ...state, lookupObservationType };
}

function setObservationType(state, action) {
  let observationType = state.observationType;
  observationType = action.observationType;
  return { ...state, observationType };
}

function setDeliveryCode(state, action) {
  let deliveryCode = state.deliveryCode;
  deliveryCode = action.deliveryCode;
  return { ...state, deliveryCode };
}

function setInitialDateFilter(state, action) {
  let initialDate = state.initialDate;
  initialDate = action.initialDateFilter;
  return { ...state, initialDate };
}

function setFinalDateFilter(state, action) {
  let finalDate = state.finalDate;
  finalDate = action.finalDateFilter;
  return { ...state, finalDate };
}

function setStatusTimeSelectedFilter(state, action) {
  let statusTimeSelectedFilter = state.statusTimeSelectedFilter;
  statusTimeSelectedFilter = action.statusTimeSelectedFilter;
  return { ...state, statusTimeSelectedFilter };
}

function setStatusSelectedFilter(state, action) {
  let statusSelectedFilter = state.statusSelectedFilter;
  statusSelectedFilter = action.statusSelectedFilter;
  return { ...state, statusSelectedFilter };
}

function setOriginFilter(state, action) {
  let origin = state.origin;
  origin = action.origin;
  return { ...state, origin };
}

function setLookupOriginVisible(state, action) {
  let lookupOrigin = state.lookupOrigin;
  lookupOrigin.visible = action.visible;
  return { ...state, lookupOrigin };
}
function setDestinationFilter(state, action) {
  let destination = state.destination;
  destination = action.destination;
  return { ...state, destination };
}

function setLookupDestinationVisible(state, action) {
  let lookupDestination = state.lookupDestination;
  lookupDestination.visible = action.visible;
  return { ...state, lookupDestination };
}

function setCarrierFilter(state, action) {
  let carrier = state.carrier;
  carrier = action.carrier;
  return { ...state, carrier };
}
function setLookupCarrierVisible(state, action) {
  let lookupCarrier = state.lookupCarrier;
  lookupCarrier.visible = action.visible;
  return { ...state, lookupCarrier };
}

function setTotalizers(state, action) {
  let totalizers = {
    indicatorTotalTravel: 0,
    indicatorTotalTravelInProgress: 0,
    indicatorTotalTravelLate: 0,
    indicatorTotalTravelDelayTrend: 0,
    indicatorTotalTravelOnTime: 0,
    indicatorTotalTravelCompleted: 0,
    indicatorTotalTravelCanceladas: 0,
    indicatorTotalTravelNotMirrored: 0
  };

  if (action.totalizers) {
    totalizers = action.totalizers;
  }
  return Object.assign({}, state, { totalizers });
}

function setImputObs(state, action) {
  return Object.assign({}, state, { inputObs: action.inputObs });
}
function setVisibleObs(state, action) {
  return Object.assign({}, state, {
    visibleImputTravelObsDialog: action.visible,
  });
}
function setSelectedTravel(state, action) {
  const selectedTravel = action.selectedTravel;
  return Object.assign({}, state, { selectedTravel });
}

function setExpandedRows(state, action) {
  state = Object.assign({}, state, { expandedRows: action.expandedRows });

  return state;
}
