import { types } from "./permissao.actions";
import { Reducer } from "../../services/reducer.factory";

const initialState = {
  booleanFilter: {
    listar: '',
    inserir: '',
    alterar: '',
    excluir: ''

  },
  columns: [
    {
      field: "oid",
      header: "Código",
      columnKey: "oid",
      sortable: true,
      filter: true
    },
    {
      field: "nomeMenu",
      header: "Menu",
      columnKey: "nomeMenu",
      sortable: true,
      filter: true
    },
    {
      field: "nomePerfil",
      header: "Perfil",
      columnKey: "nomePerfil",
      sortable: true,
      filter: true
    },
    {
      field: "listar",
      header: "Listar",
      columnKey: "listar",
      sortable: true,
      filter: true
    },
    {
      field: "inserir",
      header: "Inserir",
      columnKey: "inserir",
      sortable: true,
      filter: true
    },
    {
      field: "alterar",
      header: "Alterar",
      columnKey: "alterar",
      sortable: true,
      filter: true
    },
    {
      field: "excluir",
      header: "Remover",
      columnKey: "excluir",
      sortable: true,
      filter: true
    },
    

  ],
  currentRecord: {
    oid: "",
    oidMenu: "",
    oidPerfil: "",
    nomeMenu: "",
    nomePerfil: "",
    listar: false,
    inserir: false,
    alterar: false,
    excluir: false
  },
  lookupMenu: {
    visible: false,
    modal: true,
    header: 'Menus'
  },
  lookupPerfil: {
    visible: false,
    modal: true,
    header: 'Perfís'
  }
};

const reducer = new Reducer(initialState, types);

export function CRUDPermissaoReducer(state, action) {
  state = reducer.execute(state, action);
  return execute(state, action);
}

function execute(state, action) {
  let result = state;
  switch (action.type) {
    case types.SET_LOOKUP_MENU_VISIBLE:
      return setLoockupMenuVisible(state, action);
    case types.SET_LOOKUP_PERFIL_VISIBLE:
      return setLoockupPerfilVisible(state, action);
    case types.SET_BOOLEAN_FILTER:
      return setBooleanFilter(state, action);
    default:
      return result;
  }

}

function setBooleanFilter(state, action) {
  let booleanFilter = Object.assign({}, state.booleanFilter);
  booleanFilter[action.field] = action.value;
  return Object.assign({}, state, { booleanFilter });
}
function setLoockupMenuVisible(state, action) {
  let lookupMenu = state.lookupMenu;
  lookupMenu = Object.assign({}, lookupMenu, { visible: action.visible });
  return Object.assign({}, state, { lookupMenu })
}
function setLoockupPerfilVisible(state, action) {
  let lookupPerfil = state.lookupPerfil;
  lookupPerfil = Object.assign({}, lookupPerfil, { visible: action.visible });
  return Object.assign({}, state, { lookupPerfil })
}