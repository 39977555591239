
import React from 'react';
import { connect } from 'react-redux';
import Toolbar from "../../../components/toolbar/Toolbar";
import { actions } from '../edi.actions';
import { routes } from '../../../constants';
import { Page } from "../../../components/page/Page";
import { EdiForm } from './EdiForm';
import { AppService, COMPANY_CHANGE_EVENT_NAME, BUSINESS_UNIT_CHANGE_EVENT_NAME } from '../../../services/app.service';



const mapStateToProps = state => {
  const record = state.crudEdiState.currentRecord;
  return {
    record
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setField: (field, value) => dispatch(actions.setField(field, value)),
    changeHandler: event => {
      const target = event.target;
      const field = target.name;
      let value = target.value;
      dispatch(actions.removeErrors([{ field }]));
      dispatch(actions.setField(field, value));
    },
    saveHandler: event => {
      dispatch(actions.save());
    },
    removeHandler: event => {
      dispatch(actions.remove());
    },
    newHandler: event => {
      dispatch(actions.makeNew());
    },
    loadById: oid => dispatch(actions.loadById(oid))
  }
}

class EdiFormPageContainer extends React.Component {

  constructor(props) {
    super(props);
    actions.afterSave = ({ oid }) => {
      this.props.history.replace(`${routes.PATH_EDI}/${oid}`);
    };
    actions.afterNew = () => {
      this.props.history.replace(`${routes.PATH_EDI}/novo`);
    };
    actions.afterRemove = () => {
      this.props.history.replace(`${routes.PATH_EDI}`);
    };
  }

  componentDidMount() {
    const oid = this.props.match.params.id;

    if (oid && parseInt(oid)) {
      this.props.loadById(oid);
    } else {
      this.props.newHandler();
    }
    AppService.on(COMPANY_CHANGE_EVENT_NAME,this);
    AppService.on(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
  }

  componentWillUnmount(){
    AppService.off(COMPANY_CHANGE_EVENT_NAME,this);
    AppService.off(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
  }
  
  onReceiveAppNotifications = (eventName, params)=>{
    if(eventName === COMPANY_CHANGE_EVENT_NAME || eventName === BUSINESS_UNIT_CHANGE_EVENT_NAME){
      // this.props.load();
    }
  }

  searchHandler = () => {
    this.props.history.push(`${routes.PATH_EDI}`)
  }


  render() {
    const content = (
      <EdiForm
        changeHandler={this.props.changeHandler}
        record={this.props.record}



      />
    );
    const title = `EDI mock ${this.props.record.oid || 'novo'}`
    const header = (
      <div>
        <h1>{title}</h1>
        <div>
          <Toolbar
            onSave={this.props.saveHandler} saveButtonStyle='highlighted'
            onRemove={this.props.removeHandler} removeButtonStyle='danger'
            onNew={this.props.newHandler}
            onSearch={this.searchHandler}>
          </Toolbar>
        </div>
      </div>
    );
    return (<Page header={header} content={content} />);
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EdiFormPageContainer);