import React from 'react';
import { connect } from 'react-redux';
import Toolbar from "../../../components/toolbar/Toolbar";
import { Page } from '../../../components/page/Page';
import { UsuarioForm } from './UsuarioForm';
import { routes } from '../../../constants';
import { actions } from '../usuario.actions';
import { actions as perfilActions } from '../../perfil/perfil.actions';
import { actions as carrierActions } from '../../carrier/carrier.actions';

import { Column } from 'primereact/column';

const mapStateToProps = state => {
    const record = state.crudUsuarioState.currentRecord;

    const permitCadastroUsuarioTransportadora = true;
    const lookupCarrierBase = {
        records: state.crudCarrierState.records,
        columns: state.crudCarrierState.columns
            .filter(col => col.field === 'oid' || col.field === 'name' || col.field === 'tradeName')
            .map(col => <Column key={col.field} {...col} />),
        first: state.crudCarrierState.index,
        rows: state.crudCarrierState.max,
        totalRecords: state.crudCarrierState.count,
        sortField: state.crudCarrierState.sortField,
        order: state.crudCarrierState.sortOrder,
        editDisabled: !record.carrierOId,
        rowsPerPageOptions:[20,50,100],
    }
    let lookupCarrier = Object.assign({}, state.crudUsuarioState.lookupCarrier, lookupCarrierBase);
 

    let perfis = state.crudPerfilState.records || [];
    perfis = perfis.filter(perfil => {
        let perfilUsuario = record.groups ? record.groups.find(g => g.oid === perfil.oid) : null;
        return perfilUsuario === null || perfilUsuario === undefined;
    })

    return {
        record,
        perfis,
        permitCadastroUsuarioTransportadora,
        lookupCarrier,
    }
}

const mapDispatchToProps = dispatch => {
    return {

        setField: (field, value) => dispatch(actions.setField(field, value)),

        changeHandler: event => {
            const target = event.target;
            const field = target.name;
            let value = target.value;
            dispatch(actions.removeErrors([{ field }]));

            dispatch(actions.setField(field, value));
        },
        saveHandler: event => {
            dispatch(actions.save());
        },
        removeHandler: event => {
            dispatch(actions.remove());
        },
        newHandler: event => {
            dispatch(actions.makeNew());
        },
        clearForm: () => dispatch(actions.makeNew()),
        loadById: oid => dispatch(actions.loadById(oid)),
        loadPerfis: () => dispatch(perfilActions.load()),
        pickListPerfisMoveToSource: (perfisUsuarioRemoved) => dispatch(actions.pickListPerfisMoveToSource(perfisUsuarioRemoved)),
        pickListPerfisMoveAllToSource: () => dispatch(actions.pickListPerfisMoveAllToSource()),
        pickListPerfisMoveToTarget: (perfisAdd, oidUsuario) => dispatch(actions.pickListPerfisMoveToTarget(perfisAdd, oidUsuario)),
        pickListPerfisMoveAllToTarget: (allPerfis, oidUsuario) => dispatch(actions.pickListPerfisMoveAllToTarget(allPerfis, oidUsuario)),


        lookupCarrierSetVisible: visible => dispatch(actions.setLookupCarrierVisible(visible)),
        lookupCarrierPaginate: paginationParams => dispatch(carrierActions.paginate(paginationParams)),
        lookupCarrierSortOrder: sortParams => dispatch(carrierActions.sortOrder(sortParams)),
        lookupCarrierApplyFilter: filterParams => {
            dispatch(carrierActions.setType(null));
            dispatch(carrierActions.applyFilter(filterParams))
        },
        lookupCarrierLoad: () => dispatch(carrierActions.load()),
        setCarrier: (carrier) => {
            if (carrier) {
                const { oid, name, documentNumber } = carrier
                dispatch(actions.setField('carrier', { oid, name, cnpj: documentNumber }));
            } else {
                dispatch(actions.setField('carrier', null));
            }
        },
    }
}

const header = (ctx) => {
    const { props, searchHandler } = ctx;
    const title = `Usuário ${props.record.oid || 'novo'}`
    const header = (
        <div>
            <h1>{title}</h1>
            <hr className="lxSplitter" ></hr>
            <div>
                <Toolbar
                    onSave={props.saveHandler} saveButtonStyle='highlighted'
                    onRemove={props.removeHandler} removeButtonStyle='danger'
                    onNew={props.newHandler}
                    onSearch={searchHandler}>
                </Toolbar>
            </div>
        </div>
    );
    return header;
}

const content = (ctx) => {
    const { props } = ctx;
    return (
        <UsuarioForm
            changeHandler={props.changeHandler}
            groups={props.perfis}
            record={props.record}
            perfisOnChange={ctx.perfisOnChange}
            pickListPerfisMoveToSource={ctx.pickListPerfisMoveToSource}
            pickListPerfisMoveAllToSource={ctx.pickListPerfisMoveAllToSource}
            pickListPerfisMoveToTarget={ctx.pickListPerfisMoveToTarget}
            pickListPerfisMoveAllToTarget={ctx.pickListPerfisMoveAllToTarget}
            permitCadastroUsuarioTransportadora={props.permitCadastroUsuarioTransportadora}

            lookupCarrier={props.lookupCarrier}
            lookupCarrierOnHide={ctx.lookupCarrierOnHide}
            lookupCarrierOnConfirm={ctx.lookupCarrierOnConfirm}
            lookupCarrierOnCancel={ctx.lookupCarrierOnCancel}
            lookupCarrierOnPage={ctx.lookupCarrierOnPage}
            lookupCarrierOnSort={ctx.lookupCarrierOnSort}
            lookupCarrierOnFilter={ctx.lookupCarrierOnFilter}
            lookupCarrierOnClick={ctx.lookupCarrierOnClick}
            lookupCarrierOnEdit={ctx.lookupCarrierOnEdit}
            lookupCarrierOnComplete={ctx.lookupCarrierOnComplete}
            lookupCarrierOnInputFilter={ctx.lookupCarrierOnInputFilter}
        />
    )
}

const page = (ctx) => {
    const data = {
        header: header(ctx),
        content: content(ctx)
    }
    return (<Page {...data} />)

}

class UsuarioFormPageContainer extends React.Component {

    constructor(props) {
        super(props);
        actions.afterSave = ({ oid }) => {
            this.props.history.replace(`${routes.PATH_USUARIO}/${oid}`);
        };
        actions.afterNew = () => {
            this.props.history.replace(`${routes.PATH_USUARIO}/novo`);
        };
        actions.afterRemove = () => {
            this.props.history.replace(`${routes.PATH_USUARIO}`);
        };
    }

    componentDidMount() {
        const oid = this.props.match.params.id;

        if (oid && parseInt(oid)) {
            this.props.loadById(oid);
        } else {
            this.props.newHandler();
        }
        this.props.loadPerfis();
    }

    perfisOnChange = (e) => { console.log(e) }
    pickListPerfisMoveToSource = (e) => {
        this.props.pickListPerfisMoveToSource(e.value);
    }

    pickListPerfisMoveAllToSource = () => {
        this.props.pickListPerfisMoveAllToSource();
    }

    pickListPerfisMoveToTarget = (e) => {
        this.props.pickListPerfisMoveToTarget(e.value, this.props.record.oid);
    }

    pickListPerfisMoveAllToTarget = (e) => {
        this.props.pickListPerfisMoveAllToTarget(this.props.perfis, this.props.record.oid);
    }
    searchHandler = () => {
        this.props.history.push(`${routes.PATH_USUARIO}`)
    }


    lookupCarrierOnPage = (e) => {
        this.props.lookupCarrierPaginate({
            max: e.rows,
            index: e.first
        });
    }

    lookupCarrierOnSort = (e) => {
        this.props.lookupCarrierSortOrder({
            sortOrder: e.sortOrder,
            sortField: e.sortField
        });
    }

    lookupCarrierOnFilter = (e) => {
        const filters = [];
        const keys = Object.keys(e.filters) || [];
        keys.forEach(key => {
            filters.push({
                campo: key,
                valor: e.filters[key].value,
                tipoFiltro: 'CONTEM'
            });
        });
        this.props.lookupCarrierApplyFilter(filters);
    }

    lookupCarrierOnClick = (e) => {
        console.log("click")
        this.props.lookupCarrierLoad();
        this.props.lookupCarrierSetVisible(true);
    }

    lookupCarrierOnHide = (e) => {
        this.props.lookupCarrierSetVisible(false);
    }

    lookupCarrierOnConfirm = (e) => {
        this.props.setCarrier(e.selection);
        this.props.lookupCarrierSetVisible(false);
    }

    lookupCarrierOnCancel = (e) => {
        this.props.setCarrier(null);
        this.props.lookupCarrierSetVisible(false);
    }

    lookupCarrierOnEdit = () => {
        if (this.props.record.oidPessoa) {
            this.props.history.push(`${routes.PATH_CARRIER}/${this.props.record.carrierOId}`);
        }
    }

    lookupCarrierOnComplete = e => {
        console.log(e);
    }

    lookupCarrierOnInputFilter = (e) => {
        const value = e.value;
        const filters = [];
        if (typeof value === 'string') {
            filters.push({
                campo: "name",
                valor: value,
                tipoFiltro: 'CONTEM'
            });
            this.props.setField("name", value);
            this.props.setField("oid", null);
        } else {
            this.props.setCarrier(value);
        }
        this.props.lookupCarrierApplyFilter(filters);
    }


    render() {
        return page(this);
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UsuarioFormPageContainer);
