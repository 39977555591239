import { validate } from "./validations";
import { build } from "../../services/crudFactory";
import { Action } from "../../services/actions.factory";
import api from "../../services/api.service";
import { makeMessages, messageActions } from "../../store/message";

const BASE_PATH = "/lognex/towertravel";
const prefix = "crudTowerDashboard";
const crud = build(validate, BASE_PATH);

const newTypes = {
  LOAD_TOTALIZERS: `${prefix}loadTotalizers`,
  SET_TOTALIZERS: `${prefix}setTotalizers`,
  SET_IMPUT_OBS: `${prefix}setImputObs`,
  SET_SELECTED_TRAVEL: `${prefix}setSelectedTravel`,
  SAVE_OBS_TRAVEL: `${prefix}saveObsTravel`,
  SET_VISIBLE_OBS: `${prefix}setVisibleObs`,
  SET_LOOKUP_CARRIER_VISIBLE: `${prefix}setLookupCarrierVisible`,
  SET_CARRIER_FILTER: `${prefix}setCarrierFilter`,
  SET_DESTINATION_FILTER: `${prefix}setDestinationFilter`,
  SET_LOOKUP_DESTINATION_VISIBLE: `${prefix}setLookupDestinationVisible`,
  SET_ORIGIN_FILTER: `${prefix}setOriginFilter`,
  SET_LOOKUP_ORIGIN_VISIBLE: `${prefix}setLookupOriginVisible`,
  SET_TRAVEL_STATUS_SELECTED: `${prefix}setStatusSelectedFilter`,
  SET_TRAVEL_STATUS_TIME_SELECTED: `${prefix}setStatusTimeSelectedFilter`,
  SET_INITIAL_DATE_FILTER: `${prefix}setInitialDateFilter`,
  SET_FINAL_DATE_FILTER: `${prefix}setFinalDateFilter`,
  SET_DELIVERY_CODE: `${prefix}setDeliveryCode`,
  SET_OBSERVATION_TYPE: `${prefix}setObservationType`,
  SET_LOOKUP_OBSERVATION_TYPE_VISIBLE: `${prefix}setLookupObservationTypeVisible`,
  SET_EMBARQUE_ID_FILTER: `${prefix}setEmbarqueIdFilter`,
  SET_EXPANDED_ROW: `${prefix}setExpandedRows`,
  SET_FILTERS_PARAMS: `${prefix}setFiltersMapParams`,
  SET_SELECTED_FILTER_TYPE: `${prefix}setSelectedTypeFilter`,
  SET_TOTALIZERS_FILTERS_PARAMS: `${prefix}setTotalizersFiltersParams`,
 
  ON_EXPORT_TRAVEL_XLSX: `${prefix}onExportTravelDownloadXLSX`,
  SET_FILTERS_AUX: `${prefix}setFilterAux`,
 
};
 
const actions = new Action(prefix, crud);
const types = Object.assign({}, actions.types, newTypes);
 

actions.setFilterAux = (filterAux) => {
  return {
    type: types.SET_FILTERS_AUX,
    filterAux,
  };
};

actions.setSelectedTypeFilter = (selectedTypeFilter) => {
  return {
    type: types.SET_SELECTED_FILTER_TYPE,
    selectedTypeFilter,
  };
};
actions.setLookupObservationTypeVisible = (visible) => {
  return {
    type: types.SET_LOOKUP_OBSERVATION_TYPE_VISIBLE,
    visible,
  };
};
actions.setObservationType = (observationType) => {
  return {
    type: types.SET_OBSERVATION_TYPE,
    observationType,
  };
};

actions.setEmbarqueIdFilter = (nroEmbarque) => {
  return {
    type: types.SET_EMBARQUE_ID_FILTER,
    nroEmbarque,
  };
};
actions.setDeliveryCode = (deliveryCode) => {
  return {
    type: types.SET_DELIVERY_CODE,
    deliveryCode,
  };
};

actions.setInitialDateFilter = (initialDateFilter) => {
  return {
    type: types.SET_INITIAL_DATE_FILTER,
    initialDateFilter,
  };
};

actions.setFinalDateFilter = (finalDateFilter) => {
  return {
    type: types.SET_FINAL_DATE_FILTER,
    finalDateFilter,
  };
};

actions.setStatusTimeSelectedFilter = (statusTimeSelectedFilter) => {
  return {
    type: types.SET_TRAVEL_STATUS_TIME_SELECTED,
    statusTimeSelectedFilter,
  };
};
actions.setStatusSelectedFilter = (statusSelectedFilter) => {
  return {
    type: types.SET_TRAVEL_STATUS_SELECTED,
    statusSelectedFilter,
  };
};

actions.setOriginFilter = (origin) => {
  return {
    type: types.SET_ORIGIN_FILTER,
    origin,
  };
};

actions.setLookupOriginVisible = (visible) => {
  return {
    type: types.SET_LOOKUP_ORIGIN_VISIBLE,
    visible,
  };
};

actions.setDestinationFilter = (destination) => {
  return {
    type: types.SET_DESTINATION_FILTER,
    destination,
  };
};

actions.setLookupDestinationVisible = (visible) => {
  return {
    type: types.SET_LOOKUP_DESTINATION_VISIBLE,
    visible,
  };
};
actions.setCarrierFilter = (carrier) => {
  return {
    type: types.SET_CARRIER_FILTER,
    carrier,
  };
};

actions.setLookupCarrierVisible = (visible) => {
  return {
    type: types.SET_LOOKUP_CARRIER_VISIBLE,
    visible,
  };
};

actions.setSelectedTravel = (selectedTravel) => {
  return {
    type: types.SET_SELECTED_TRAVEL,
    selectedTravel,
  };
};
actions.setVisibleObs = (visible) => {
  return {
    type: types.SET_VISIBLE_OBS,
    visible,
  };
};

actions.setImputObs = (inputObs) => {
  return {
    type: types.SET_IMPUT_OBS,
    inputObs,
  };
};

actions.setTotalizadores = (totalizers) => {
  return {
    type: types.SET_TOTALIZERS,
    totalizers,
  };
};

actions.loadTotalizers = (filters) => {
  return (dispatch, getState) => {
  console.log("towerDashboard loadTotalizers filters=",filters)
    api
      .post("/api/v1/lognex/towertravel/findTotalizers", { filters })
      .then((totalizers) => {
        dispatch(actions.setTotalizadores(totalizers.data));
      })
      .catch((err) => {
        console.log(err);
        dispatch(actions.setTotalizadores(null));
      });
  };
};

actions.saveObsTravel = () => {
  return (dispatch, getState) => {
    const state = getState().crudTowerDashboardState;
    const inputObs = state.inputObs.trim();
    const selectedTravel = state.selectedTravel;
    const observationType = state.observationType;
    if (observationType && observationType.oid && observationType.name) {
      if (inputObs.length > 0 && selectedTravel) {
        const params = { obs: inputObs, oid: selectedTravel.oid, observationType };
        api
          .post("/api/v1/lognex/towertravel/saveObs", params)
          .then((result) => {
            dispatch(actions.setSelectedTravel(null));
            dispatch(actions.setImputObs(""));
            dispatch(actions.setVisibleObs(false));
            dispatch(messageActions.messageShowMessages(makeMessages(["Registro realizado com sucesso"], "success")));
          })
          .catch((err) => {
            dispatch(messageActions.messageShowMessages(makeMessages(["Não foi possível atualizar o registro"], "error")));
            console.log(err);
          });
      } else {
        dispatch(messageActions.messageShowMessages(makeMessages(["Digite uma observação!"], "error")));
      }
    } else {
      dispatch(messageActions.messageShowMessages(makeMessages(["Informe um tipo de observação!"], "error")));
    }
  };
};

actions.setExpandedRows = (expandedRows) => {
  return {
    type: types.SET_EXPANDED_ROW,
    expandedRows,
  };
};
  
actions.setFiltersMapParams = (filtersMapParams) => {
  return {
    type: types.SET_FILTERS_PARAMS,
    filtersMapParams,
  };
};


actions.setTotalizersFiltersParams = (totalizersFiltersParams) => {
  return {
    type: types.SET_TOTALIZERS_FILTERS_PARAMS,
    totalizersFiltersParams,
  };
};
 

actions.onExportTravelDownloadXLSX = (filters) => {
  return (dispatch, getState) => {
    api
      .post("/api/v1/lognex/towertravel/exporttravelxlsx", { filters })
      .then((result) => {
        if (result.data && !result.data.erro) {
          const anchor = document.createElement("a");
          anchor.href = result.data.link;
          anchor.target = "_blank";
          document.body.append(anchor);
          anchor.click();
          anchor.remove();
        } else {
          dispatch(messageActions.messageShowMessages(makeMessages(["Problemas para gerar o relatório"], "error")));
        }
 
      })
      .catch((err) => {
        console.log(err);
        dispatch(messageActions.messageShowMessages(makeMessages(["Problemas para gerar o relatório"], "error")));
      });
  };
};

export { types, actions };
