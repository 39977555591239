import { types } from "./userdocumenttype.actions";
import { Reducer } from "../../services/reducer.factory";

const initialState = {

    columns: [
        {
            field: "oid",
            header: "Código",
            columnKey: "oid",
            sortable: true,
            filter: true
        },
        {
            field: "sigla",
            header: "Sigla",
            columnKey: "sigla",
            sortable: true,
            filter: true
        },
        {
            field: "name",
            header: "Nome",
            columnKey: "name",
            sortable: true,
            filter: true
        },
        {
            field: "description",
            header: "Descrição",
            columnKey: "description",
            sortable: true,
            filter: true
        },
        {
            field: "lognexDocumentType.name",
            header: "Lognex Tipo",
            columnKey: "lognexType",
            sortable: true,
            filter: true
        },
        {
            field: "lognexDocumentType.label",
            header: "Lognex Tipo",
            columnKey: "lognexType",
            sortable: true,
            filter: true
        },
    ],
    currentRecord: {
        oid: "",
        sigla: "",
        name: "",
        description: "",
        lognexDocumentType:null
    },  
    lognexDocumentTypes: null 

}

const reducer = new Reducer(initialState, types)

export function CRUDUserDocumentTypeReducer(state, action) {
    state = reducer.execute(state, action);
    return execute(state, action)
}

function execute(state, action){
    let result = state
    switch(action.type){
        case types.SET_LOGNEX_DOCUMENT_TYPES:
            return setLognexDocumentTypes(state, action); 
        case types.SET_LOGNEX_DOCUMENT_TYPE:
            return setLognexDocumentType(state, action); 
        default:
            return result
    }
}

function setLognexDocumentType(state, action){
    let currentRecord = state.currentRecord;
    currentRecord.lognexDocumentType = action.value;
    
    return {...state, currentRecord }
}

function setLognexDocumentTypes(state, action){
    const lognexDocumentTypes = action.lognexDocumentTypes
    return {...state, lognexDocumentTypes }
}