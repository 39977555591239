    import { types } from "./vehicleGroup.actions";
    import { Reducer } from "../../services/reducer.factory";
    
    const initialState = {
      columns: [{
        field: "oid",
        header: "Código",
        columnKey: "oid",
        sortable: "false",
        filter: "false"
      },{
        field: "description",
        header: "Descrição",
        columnKey: "description",
        sortable: "false",
        filter: "false"
      },{
        field: "height",
        header: "Altura(CM)",
        columnKey: "height",
        sortable: "false",
        filter: "false"
      },{
        field: "width",
        header: "Largura(CM)",
        columnKey: "width",
        sortable: "false",
        filter: "false"
      },{
        field: "length",
        header: "Comprimento(CM)",
        columnKey: "length",
        sortable: "false",
        filter: "false"
      },{
        field: "cubage",
        header: "Cubagem(m²)",
        columnKey: "cubage",
        sortable: "false",
        filter: "false"
      },{
        field: "netWeight",
        header: "Peso líquido",
        columnKey: "netWeight",
        sortable: "false",
        filter: "false"
      },{
        field: "vehicleType",
        header: "Tipo de veículo",
        columnKey: "vehicleType",
        sortable: "false",
        filter: "false"
      }],
      currentRecord: {
        oid:null,
        description:"",
        height:"0",
        width:"0",
        length:"0",
        cubage:"0",
        tareWeight:"0",
        maxWeight:"0",
        netWeight:"0",
        speedFactor:"0",
        vehicleType:null},
      
    };
    
    const reducer = new Reducer(initialState, types);
    
    export function CRUDVehicleGroupReducer(state, action) {
      state = reducer.execute(state, action);
      return execute(state,action);
    }
    
    function execute(state, action){
        let result = state;
        switch(action.type){
          
          
        }
        return result;
    }
   
    
    
    