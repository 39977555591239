import React from 'react'; 
import CollectionOrdersWithPendencesPageContainer from './CollectionOrdersWithPendencesPageContainer';

export class CollectionOrdersWithPendencesListPage extends React.Component{
    
    render(){
      return (
        <CollectionOrdersWithPendencesPageContainer {...this.props}/>
      )
    }
}

