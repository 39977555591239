import { build } from "../../services/crudFactory";
import { Action } from "../../services/actions.factory";
import apiService from "../../services/api.service";
const BASE_PATH = "/lognex/dashboard-nfe";
const prefix = "crudDashboardNfe";

const crud = build(false, BASE_PATH);

const newTypes = {
  ON_CHANGE_NUMBER_NFE_FILTER: `${prefix}onChangeNfeNumberFilter`,
  ON_LOADING_STATE: `${prefix}onLoadingState`,
  FIND_NFE: `${prefix}findNfe`,
};

const actions = new Action(prefix, crud);

const types = Object.assign({}, actions.types, newTypes);

actions.onChangeNfeNumberFilter = (nfeNumberFilter) => {
  return {
    type: types.ON_CHANGE_NUMBER_NFE_FILTER,
    nfeNumberFilter,
  };
};
actions.onLoadingState = (visible) => {
  return {
    type: types.ON_LOADING_STATE,
    visible,
  };
};
actions.findNfe = (filters) => {
  return (dispatch, getState) => {
    dispatch(actions.onLoadingState(true))
    apiService.post("/api/v1/lognex/dashboard-nfe/all", { filters }).then((response) => {
      dispatch(actions.setRecord(response.data));
      dispatch(actions.onLoadingState(false))
    })
    .catch((err) => {
      console.log(err);
      dispatch(actions.setRecord(null));
      dispatch(actions.onLoadingState(false))
      });
  };
};
export { types, actions };
