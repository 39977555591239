export function validate(record) {
    const errors = [];


    if (!record.name || record.name === '') {
        errors.push({ field: 'name', message: 'Nome é obrigatório!' });
    }

    if (!record.carrier || !record.carrier.oid || record.carrier.oid === "" || record.carrier.oid === 0) {
        errors.push({ field: 'carrier', message: 'Transportadora é obrigatória!' });
    }

    if (!record.origin || !record.origin.oid || record.origin.oid === "" || record.origin.oid === 0) {
        errors.push({ field: 'description', message: 'A Origem é obrigatória!' });
    }

    if (!record.destination || !record.destination.oid || record.destination.oid === ""  || record.destination.oid === 0) {
        errors.push({ field: 'description', message: 'O Destino é obrigatório!' });
    }

    if (!record.deadline || !record.deadline.value || record.deadline.value === '') {
        errors.push({ field: 'deadline', message: 'O Prazo de entrega é obrigatório!' });
    }

    if (!record.delayAfterCollect || !record.delayAfterCollect.value || record.delayAfterCollect.value === '') {
        errors.push({ field: 'deadline', message: 'O Prazo de despacho após coleta é obrigatório!' });
    }

    const result = {
        ok: errors.length === 0,
        errors,
        record
    }

    return new Promise((resolve, reject) => {
        const action = result.ok ? resolve : reject;
        return action(result);
    })
}