import { Checkbox } from "@material-ui/core";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import React from "react";
import "./dialog_obs.scss";
export class InputDeliveryDateDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const footer = (
      <div>
        <button title="Confirmar" className="lognex-btn-highlighted" onClick={this.props.onConfirm}>
          <i className="pi pi-check"></i>
        </button>
        <button title="Cancelar" className="lognex-btn-danger" onClick={this.props.onCancel}>
          <i className="pi pi-times"></i>
        </button>
      </div>
    );

    return (
      <Dialog header={"Adiocionar data da entrega"} footer={footer} visible={this.props.visible} style={this.props.style || { width: "auto" }} 
      modal={true} onHide={this.props.onHide}>
        <div className="p-col-12 form-field-container">
          <label>Data da entrega</label>
        </div>
        <div className="p-col-12 form-field-container">
          <Calendar
            showIcon={true}
            value={this.props.deliveryChange.delaveryDatetime}
            showTime={true}
            onChange={this.props.deliveryDateChangeHandler}
            disabled={false}
            name="finallDate"
            dateFormat="dd/mm/yy"
          />
        </div>
      </Dialog>
    );
  }
}
