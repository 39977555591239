import { Column } from "primereact/column";
import React from "react";
import { connect } from "react-redux";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";

import "./HistoryLog.scss";
import { actions } from "../history-log.actions";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import "../../../../src/index.scss";
import { Height } from "@material-ui/icons";

const mapStateToProps = (state) => {
  console.log("state.crudHistorylogState=",state.crudHistorylogState)
  return {
    columns: state.crudHistorylogState.columns,
    records: state.crudHistorylogState.records,
    first: state.crudHistorylogState.index,
    rows: state.crudHistorylogState.max || 20,
    totalRecords: state.crudHistorylogState.count,
    sortField: state.crudHistorylogState.sortField,
    order: state.crudHistorylogState.sortOrder,
    createdAtFilter: state.crudHistorylogState.createdAtFilter,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    applyFilter: (filter) => {
      dispatch(actions.setType(null));
      dispatch(actions.applyFilter(filter));
    },
    paginate: (paginationParams) => dispatch(actions.paginate(paginationParams)),
    sortOrder: (sortParams) => dispatch(actions.sortOrder(sortParams)),
    setCreateDateFilter: (value) => {
      dispatch(actions.setCreateDateFilter(value));
    },
  };
}

class HistoryLogListPageContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRow: null,
    };
  }

  onPage = (e) => {
    this.props.paginate({
      max: e.rows,
      index: e.first,
    });
  };
  onSort = (e) => {
    this.props.sortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField,
    });
  };

  onFilter = (e) => {
    const filters = [];
    const keys = Object.keys(e.filters) || [];
    keys.forEach((key) => {
      filters.push({
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: "CONTEM",
      });
    });

    if (this.props.resourceType)
      filters.push({
        campo: "resourceType",
        valor: this.props.resourceType,
        tipoFiltro: "IGUAL_A",
      });

    this.props.applyFilter(filters);
  };

  setCreateDateFilter = (e) => {
    this.props.setCreateDateFilter(e.value);
    const filters = [];

    filters.push({
      campo: "createdAt",
      valor: e.value,
      tipoFiltro: "IGUAL_A",
    });

    if (this.props.resourceType)
      filters.push({
        campo: "resourceType",
        valor: this.props.resourceType,
        tipoFiltro: "IGUAL_A",
      });

    this.props.applyFilter(filters);
  };

  onLoadDocID = (docId) => {
    const filters = [];
    filters.push({
      campo: "docID",
      valor: String(docId),
      tipoFiltro: "IGUAL_A",
    });

    if (this.props.resourceType)
      filters.push({
        campo: "resourceType",
        valor: this.props.resourceType,
        tipoFiltro: "IGUAL_A",
      });

    this.props.applyFilter(filters);
  };

  onShow = () => {
    this.props.setCreateDateFilter(null);
    this.setState({ selectedRow: null });

    if (this.props.docID) {
      const oid = this.props.docID;
      this.onLoadDocID(oid);
    }
  };

  dateFormat = (dat) => {
    return moment(dat).format("DD/MM/YYYY HH:mm:ss");
  };
  formatDateTime = (dataRow) => {
    return this.dateFormat(dataRow.createdAt);
  };

  onClickOpenDetail = (rowDetail) => {
    this.setState({ selectedRow: rowDetail });
  };

  btnSelectRow = (dataRow) => {
    return (
      <div style={{ display: "flex" }}>
        <button
          className={"lognex-btn-highlighted"}
          title="Detalhes"
          onClick={() => this.onClickOpenDetail(dataRow)}
          disabled={dataRow.operationType == "exclusao"}
          style={{ width: "auto", margin: "auto", padding: "5px 25px 5px 25px" }}
        >
          Detalhes
        </button>
      </div>
    );
  };
  onHideDetails = () => {
    this.setState({ selectedRow: null });
  };
  onShowDetails = () => {};

  isNotNull =(obj)=>{
    return (obj && String(obj).trim() != "null" && String(obj).trim() != "")
  }
  convertOldValueJson = (isNew, field, selectedRow) => {
    try {
      const {newValue,oldValue,totalCreate,totalUpdate,totalDelete} = selectedRow

      if (this.isNotNull(oldValue)) {
        let dados = JSON.parse(oldValue);

        if (dados.constructor === Object || dados.constructor === Array) {
          if(this.isNotNull(newValue))
            return `Excluido:${totalDelete}  itens no ${field}`;
          return `Foi ${isNew ? "adicionado" : "removido"} um(a) ${field}`;
        } else {
          return String(oldValue).replace(new RegExp('"', "g"), "");
        }
      }
      return "";
    } catch (error) {
      return selectedRow.oldValue;
    }
  };
  convertNewValueJson = (isNew, field, selectedRow) => {
    try {

      const {newValue,oldValue,totalCreate,totalUpdate,totalDelete} = selectedRow

      if (this.isNotNull(newValue)) {
        let dados = JSON.parse(newValue);

        if (dados.constructor === Object || dados.constructor === Array) {
          if(this.isNotNull(oldValue))
            return `Alterado:${totalUpdate}, Adicionado:${totalCreate} itens no ${field}`;
          return `Foi ${isNew ? "adicionado" : "removido"} um(a) ${field}`;
        } else {
          return String(newValue).replace(new RegExp('"', "g"), "");
        }
      }
      return "";
    } catch (error) {
      return selectedRow.newValue;
    }
  };
  render() {
    const dateFilter = (field) => {
      return (
        <div className="p-calendar">
          <Calendar name={field} showIcon={true} style={{ width: "100%" }} dateFormat="dd/mm/yy" value={this.props.createdAtFilter} onChange={(e) => this.setCreateDateFilter(e)} />
        </div>
      );
    };

    let columnsHistorylog = this.props.columns.map((col) => {
      if (col.field === "createdAt") {
        return <Column {...col} key={col.field} body={this.formatDateTime} filterElement={dateFilter(col.field)} />; //
      }
      if (col.field === "selectedRow") {
        return <Column {...col} key={col.field} body={this.btnSelectRow} />; //
      }

      return <Column {...col} key={col.field} />;
    });

    const getDetails = () => {
      if (this.state.selectedRow) {
        return (
          <div>
            <div className="p-grid row-content">
              <div className="p-col-2 form-field-container">
                <h3>Código</h3>
                <label>{this.state.selectedRow.docID}</label>
              </div>

              <div className="p-col-10 form-field-container">
                <h3>Campo alterado</h3>
                <label>{this.state.selectedRow.field}</label>
              </div>
            </div>

            <div className="p-grid row-content">
              <div className="p-col-6 form-field-container">
                <h3>Informação antes da operação</h3>
                <label>{this.convertOldValueJson(false, this.state.selectedRow.field, this.state.selectedRow)}</label>
              </div>

              <div className="p-col-6 form-field-container">
                <h3>Informação após operação</h3>
                <label>{this.convertNewValueJson(true, this.state.selectedRow.field, this.state.selectedRow)}</label>
              </div>
            </div>

            <div className="p-grid row-content">
              <div className="p-col-3 form-field-container">
                <h3>Usuário</h3>
                <label>{this.state.selectedRow.user}</label>
              </div>

              <div className="p-col-2 form-field-container">
                <h3>Tipo de operação</h3>
                <label>{this.state.selectedRow.operationType}</label>
              </div>

              <div className="p-col-6 form-field-container">
                <h3>Data da operação</h3>
                <label>{this.dateFormat(this.state.selectedRow.createdAt)}</label>
              </div>
            </div>
          </div>
        );
      } else return <div />;
    };

    return (
      <>
        <Dialog
          onShow={this.onShow}
          visible={true}
          style={{ width: "80vw" }}
          modal={true}
          baseZIndex={1}
          onHide={this.props.onHide}
          header={"Histórico de operações realizadas"}
          footer={<div></div>}
        >
          <DataTable
            selectionMode="single"
            value={this.props.records}
            resizableColumns={true}
            lazy={true}
            totalRecords={this.props.totalRecords}
            sortOrder={this.props.order}
            onSort={this.onSort}
            onPage={this.onPage}
            onFilter={this.onFilter}
            paginator={true}
            first={this.props.first} 
            rows={this.props.rows } 
            //scrollable={true}
            // scrollHeight="480px"
            emptyMessage="Não foram encontrados registros"
            rowsPerPageOptions={[5, 10]}
            metaKeySelection={false}
          >
            {columnsHistorylog}
          </DataTable>
        </Dialog>

        <Dialog
          onShow={this.onShowDetails}
          visible={this.state.selectedRow ? true : false}
          style={{ width: "80vw" }}
          modal={true}
          baseZIndex={2}
          onHide={this.onHideDetails}
          header={"Detalhes da operação"}
          footer={<div></div>}
        >
          {getDetails()}
        </Dialog>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HistoryLogListPageContainer);
