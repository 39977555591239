import { validate } from './validations';
import {build} from '../../services/crudFactory';
import {Action} from '../../services/actions.factory';
import api from '../../services/api.service';
const BASE_PATH = '/lognex/vehicletypes';
const crud = build(validate,BASE_PATH);
const prefix = 'crudVehicleType';

const actions = new Action(prefix,crud);

const types = {...actions.types};


export {types, actions};