import { types } from "./pallet.actions";
import { Reducer } from "../../services/reducer.factory";

const initialState = {
  columns: [{
    field: "oid",
    header: "Código",
    columnKey: "oid",
    sortable: "false",
    filter: "false"
  }, {
    field: "identifier",
    header: "Identificador",
    columnKey: "identifier",
    sortable: "false",
    filter: "false"
  }, {
    field: "palletStandardName",
    header: "Padrão de Pallet",
    columnKey: "palletStandardName",
    sortable: true,
    filter: true
  }, {
    field: "height",
    header: "Altura",
    columnKey: "height",
    sortable: "false",
    filter: "false"
  }, {
    field: "width",
    header: "Largura",
    columnKey: "width",
    sortable: "false",
    filter: "false"
  }, {
    field: "depth",
    header: "Profundidade",
    columnKey: "depth",
    sortable: "false",
    filter: "false"
  }, {
    field: "maxWeight",
    header: "Peso Máximo",
    columnKey: "maxWeight",
    sortable: "false",
    filter: "false"
  }],
  currentRecord: { oid: "", identifier: "", oidPalletStandard: "", palletStandardName: "", height: "", width: "", depth: "", maxWeight: "" },
  lookupPalletStandard: {
    visible: false,
    modal: true,
    header: "PalletStandards"
  }
};

const reducer = new Reducer(initialState, types);

export function CRUDPalletReducer(state, action) {
  state = reducer.execute(state, action);
  return execute(state, action);
}

function execute(state, action) {
  let result = state;
  switch (action.type) {

    case types.SET_LOOKUP_PALLETSTANDARD_VISIBLE:
      return setLookupPalletStandardVisible(state, action);
    default:
      return result;
  }
}


function setLookupPalletStandardVisible(state, action) {
  let lookupPalletStandard = state.lookupPalletStandard;
  lookupPalletStandard = Object.assign({}, lookupPalletStandard, { visible: action.visible });
  return Object.assign({}, state, { lookupPalletStandard })
}

