import React from "react";
import { connect } from "react-redux";
import { CloudDone, CloudOff } from '@material-ui/icons';
import Toolbar from "../../../components/toolbar/Toolbar";
import { Dropdown } from "primereact/dropdown";
import { Column } from 'primereact/column';
import { routes } from '../../../constants';
import { Page } from "../../../components/page/Page";
import { Totalizer } from "../../../components/totalizer/Totalizer";
import { OrderLogList } from "./OrderLogList";
import { actions } from '../order-log.actions'
import { AppService, COMPANY_CHANGE_EVENT_NAME, BUSINESS_UNIT_CHANGE_EVENT_NAME } from "../../../services/app.service";
import { actions as appActions } from '../../../store/app';
import moment from "moment";
import { OrderLogFilter } from '../components/OrderLogFilter';
import { getValue } from "../../../i18n";
import './OrderLogListPageContainer.scss';

let timer = null;

function mapStateToProps(store){

  const statusFilterOptions = store.orderLogState.statusFilterOptions;
  const currentStatusFilter = store.orderLogState.currentStatusFilter;

  return {
    columns: store.orderLogState.columns,
    records: store.orderLogState.records,
    first: store.orderLogState.index,
    rows: store.orderLogState.max,
    totalRecords: store.orderLogState.count,
    sortField: store.orderLogState.filter.sortField,
    order: store.orderLogState.filter.sortOrder,
    orderFilter: store.orderLogState.orderLogFilter.orderFilter,
    initialDateFilter: store.orderLogState.orderLogFilter.dataFilter.initialDateFilter,
    finalDateFilter: store.orderLogState.orderLogFilter.dataFilter.finalDateFilter,
    record: store.orderLogState.currentRecord,
    statusFilterOptions,
    currentStatusFilter,
  }
}

function mapDispatchToProps(dispatch){
  return {
    paginate: paginationParams => dispatch(actions.paginate(paginationParams)),
    sortOrder: sortParams => dispatch(actions.sortOrder(sortParams)),
     load: () => dispatch(appActions.load()),
    onInitialDateChangeHandler: e => {
      dispatch(actions.setDateFieldForm(e.target.name, e.value));
    },
    onFinalDateChangeHandler: e => {
      dispatch(actions.setDateFieldForm(e.target.name, e.value));
    },
    orderFilterChangeHandler: e => {
      dispatch(actions.setFieldOrderFilterForm(e.target.value));
    },
    applyFilter: filterParams => {
      dispatch(actions.setType(null));
      dispatch(actions.applyFilter(filterParams));
    },
    setExpandedOrderLogRows: expandedOrderLogsRows => {
      const record = expandedOrderLogsRows[1];
      dispatch(actions.setRecord(record));
    },
    setCurrentStatusFilter: (value) => {
      dispatch(actions.setCurrentStatusFilter(value));
    }
  }
}


class OrderLogListPageContainer extends React.Component {

 
  componentDidMount() {
    this.applyOrderLogFilter();
    AppService.on(COMPANY_CHANGE_EVENT_NAME, this);
    AppService.on(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
  }
  componentWillUnmount() {
    AppService.off(COMPANY_CHANGE_EVENT_NAME, this);
    AppService.off(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
  }

  onReceiveAppNotifications = (eventName, params) => {
    if (eventName === COMPANY_CHANGE_EVENT_NAME || eventName === BUSINESS_UNIT_CHANGE_EVENT_NAME) {
      this.applyOrderLogFilter();
    }
  }


  onPage = (e) => {
    this.props.paginate({
      max: e.rows,
      index: e.first
    })
  }

  onSort = (e) => {
    this.props.sortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField
    })
  }

  onFilter = (e) => {
    if(timer){
      window.clearTimeout(timer);
    }
    timer = setTimeout(() => {
      const filters = [];
      const keys = Object.keys(e.filters) || [];
      keys.forEach(key => {
        if(key != "status"){
          this.props.setCurrentStatusFilter(null);
        }
        filters.push({
          campo: key,
          valor: e.filters[key].value,
          tipoFiltro: 'CONTEM'
        });
      });
      const filtersOrderLogs = this.getOrderLogFilter();
      this.props.applyFilter(filtersOrderLogs.concat(filters));
    },400);
  }

  getOrderLogFilter = () => {
    const normalizeDate = date => {
      return date && typeof date === "object" ? date : null;
    };
    const initialDateFilter = normalizeDate(this.props.initialDateFilter);
    const finalDateFilter = normalizeDate(this.props.finalDateFilter);
    const orderFilter = this.props.orderFilter && this.props.orderFilter.trim() !== "" 
    ? this.props.orderFilter.trim()
    : "";

    let filters = [];
    if(initialDateFilter && finalDateFilter){
      filters.push({
        campo: "dateFilter",
        valorInicial:initialDateFilter,
        valorFinal: finalDateFilter,
        tipoFiltro: "BETWEEN"
      });
    }

    if (orderFilter) {
      filters.push({
        campo: "orderFilter",
        valor: orderFilter,
        tipoFiltro: "EQUALS"
      });
    }
    return filters;
  }  

  applyOrderLogFilter = () => {
    const filterOrderLogParams = this.getOrderLogFilter();
    this.props.applyFilter(filterOrderLogParams);
    this.props.setCurrentStatusFilter(null);
  }

  changeStatusFilter = ({ value }) => {
    this.props.setCurrentStatusFilter(value);
    let filters = [];
    if(value){
      filters.push({
          campo: "status",
          valor: value,
          tipoFiltro:"EQUALS"
      });
      const orderLogFilter = this.getOrderLogFilter();
      this.props.applyFilter(orderLogFilter.concat(filters));
    } else {
      const orderLogFilter = this.getOrderLogFilter();
      this.props.applyFilter(orderLogFilter);
    }
  }
  
  
  render() {
    const header = (
      <div>
        <h1>Log processamento de pedidos</h1>
        {/* <div>
          <Toolbar
            onNew={this.newHandler}
            newButtonStyle='highlighted' >
          </Toolbar >
        </div> */}
        <hr className="lxSplitter"></hr>
        <div className="p-grid">
          <div className="p-col-10">
            <OrderLogFilter 
              orderNumber={ this.props.orderNumber }
              orderFilterChangeHandler = { this.props.orderFilterChangeHandler }
              initialDateFilter={ this.props.initialDateFilter }
              finalDateFilter={ this.props.finalDateFilter }
              onInitialDateChangeHandler={ this.props.onInitialDateChangeHandler }
              onFinalDateChangeHandler={ this.props.onFinalDateChangeHandler }
              applyOrderLogFilter={ this.applyOrderLogFilter }
            />
          </div> 
        </div>
      </div>
    );

    const dateTemplate = (rowData, column) => {
      const date = rowData[column.field];
      let formattedDate = moment(date).utc().format("DD/MM/YYYY HH:mm:ss");
      return <div style={{ textAlign: "center" }}>{formattedDate}</div>     
    };

    const statusTemplate = (rowData, column) => {
      if(rowData.status === 'ERROR'){
        return <div style={{ textAlign: "center",color:"red" }}><CloudOff/></div> 
      }
      return <div style={{ textAlign: "center",color:"#529664" }}><CloudDone/></div>     
    };

    const getStatusFilterIcon = status => {
      let statusIcon = null;
      let title = "";
      switch (status) {
        case "ERROR":
          statusIcon = <div style={{ textAlign: "center",color:"red" }}><CloudOff/></div>;
          title = "Com erro";
          break;
        case "INFO":
          statusIcon = <div style={{ textAlign: "center",color:"#529664" }}><CloudDone/></div>; 
          title = "Sem erro";
          break;  
      }
      return {
        icon: statusIcon,
        title: title
      };
    }

    const statusFilter = field => {
      const statusFilterOptions = this.props.statusFilterOptions.map(
        (option) => {         
          const iconObj = getStatusFilterIcon(option.value);
          return {
            value: option.value,
            label: !option.value 
              ? ( "TODOS" )
              : (<div key={option.label} title={iconObj.title}>{iconObj.icon}</div>)
          };
        }
      );
      return (
        <Dropdown
          style={{ width: "100%" }}
          name={field}
          value={this.props.currentStatusFilter}
          options={statusFilterOptions}
          onChange={this.changeStatusFilter}
          />
      );
    };

    const columns = this.props.columns.map((col, index) => {
      const key = `${index}-${col.field}`;
      if( col.field === "createdAt"){
        return (<Column {...col} key={key} body={dateTemplate} />);
      }
      if( col.field === "status"){
        return (
          <Column 
            {...col} 
            key={key} 
            body={statusTemplate} 
            filterElement={statusFilter(col.field)}
            style={{ width: "10%" }}
          />);
      }

      return (<Column {...col} key={col.field} />)
    })

    const setExpandedOrderLogRows = e => {
      this.props.setExpandedOrderLogRows(e.data);
    }

    const templateOrderLogTbRowExpendedRow = (rowData ,e) => {
      
      if(!rowData.error || rowData.error.lenght <= 0){
        return (
          <div style={{ textAlign: "center" }}>
            <h3>Esse Log não possui mensagem de erro.</h3>
          </div>
        )
      }       
      return (
        <div style={{ textAlign: "center" }}>
           <h3 style={{fontSize:"20px", color:"#777"}}> {rowData.error} </h3>
        </div>
      );
    }

    const totalRecords = this.props.totalRecords ? this.props.totalRecords : 0; 
    const totalizers = [
      { label: `${getValue("TOTAL_RECORDS")}: `, value: totalRecords }
    ]

    const content = (
      <div>
        <Totalizer
              classNameT="lxCTeTotalizer"
              totalizers={totalizers}
        ></Totalizer>
        <OrderLogList
          {...this.props} 
          onFilter={this.onFilter} 
          onPage={this.onPage} 
          onSort={this.onSort} 
          columns={columns} 
          orderLogRowToggleHandler={setExpandedOrderLogRows}
          orderLogTbRowExpasionTemplateHandler={templateOrderLogTbRowExpendedRow}
          orderLogTBExpandedRows={[this.props.record]}
        />
      </div>
    );
    return <div className="log-order-page"><Page header={header} content={content} /></div>;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderLogListPageContainer);

