const moment = require('moment');

function getCDsFromRouter(route) {
  const listCds = [];
  try {
    route.shipments.forEach((ship) => {  
      const newCd = String(ship.pickup.virtualCD).trim();
      if (listCds.filter((vcd) => vcd === newCd).length == 0) {
        listCds.push(newCd);
      }
    });
  } catch (error) {
    console.log(error);
  }
  return listCds;
}
function createUniqueID() {
  const dataHoraAtual = moment();
  // const id = dataHoraAtual.format("YYMMDDHHmmssSSS");
  const id = dataHoraAtual.format("DDHHmmssSSS");
  return id;
}


module.exports = {
  getCDsFromRouter, createUniqueID
}