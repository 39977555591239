import { types } from "./tower_equipment.actions";
import { Reducer } from "../../services/reducer.factory";

const initialState = {
  columns: [
    {
      field: "oid",
      header: "Código",
      columnKey: "oid",
      sortable: true,
      filter: true,
    },
    {
      field: "name",
      header: "Nome",
      columnKey: "name",
      sortable: true,
      filter: true,
    },
    {
      field: "equipmentId",
      header: "ID Equipamento",
      columnKey: "equipmentId",
      sortable: true,
      filter: true,
    },
    {
      field: "equipmentType.name",
      header: "Nome Equipamento",
      columnKey: "equipmentType.name",
      sortable: true,
      filter: true,
    },
    {
      field: "eventRecord.createdAt",
      header: "Data de Cadastro",
      columnKey: "eventRecord.createdAt",
      sortable: true,
      filter: true,
    },
  ],

  lookupEquipmentType: {
    visible: false,
    modal: true,
    header: "Tipo Equipamento",
  },

};

const reducer = new Reducer(initialState, types);

export function CRUDTowerEquipmentReducer(state, action) {
  state = reducer.execute(state, action);
  return execute(state, action);
}

function execute(state, action) {
  let result = state;
  switch (action.type) {
    case types.SET_LOOKUP_EQUIPMENTTYPE_VISIBLE:
      return setLookupEquipmentTypeVisible(state, action);

    default:
      return result;
  }
}


function setLookupEquipmentTypeVisible(state, action) {
  let lookupEquipmentType = state.lookupEquipmentType;
  
  lookupEquipmentType = Object.assign({}, lookupEquipmentType, { visible: action.visible }); 
  return Object.assign({}, state, { lookupEquipmentType });
}

