export function validate(record) {
    const errors = [];

    if (!record.description || record.description.trim() === "") {
        errors.push({ field: 'description', message: 'A descrição do segmento é obrigatória!' });
    }
        
    const result = {
        ok: errors.length === 0,
        errors,
        record
    }

    return new Promise((resolve, reject) => {
        const action = result.ok ? resolve : reject;
        return action(result);
    })
}