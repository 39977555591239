

export function validate(record) {
    const errors = [];
    if (!record.latlong || record.latlong.trim() === "") {
        errors.push({ field: 'latlong', message: 'A latitude e longitude é obrigatório!' });
    }
    if (!record.currentBlockType) {
        errors.push({ field: 'currentBlockType', message: 'O tipo de bloqueio é obrigatório!' });
    }
    if (!record.blockPartial && !record.blockTotal) {
        errors.push({ field: 'situation', message: 'Defina se o bloqueio é parcial ou total!' });
    }
    const result = {
        ok: errors.length === 0,
        errors,
        record
    }

    return new Promise((resolve, reject) => {
        const action = result.ok ? resolve : reject;
        return action(result);
    })
}