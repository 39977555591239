import { types } from "./cfop.actions";
import { Reducer } from "../../services/reducer.factory";

const initialState = {
  columns: [
    {
      field: "oid",
      header: "Código",
      columnKey: "oid",
      sortable: true,
      filter: true
    },
    {
      field: "codeCFOP",
      header: "Código CFOP",
      columnKey: "codeCFOP",
      sortable: true,
      filter: true
    },{
      field: "description",
      header: "Descrição",
      columnKey: "description",
      sortable: true,
      filter: true
    },   
  ],
  currentRecord: {
    oid: null,
    codeCFOP: "",
    description: "",
  },

  records: []
};

const reducer = new Reducer(initialState, types);

export function CRUDCfopReducer(state, action) {
  state = reducer.execute(state, action);
  return execute(state, action);
}

function execute(state, action) {
  let result = state;
  switch (action.type) {
    case types.SET_ONCHANGE_CFOP:
      return onChangeCFOP(state, action);
    default:
      return result;
  }

}

function onChangeCFOP(state,action){
  let currentRecord = state.currentRecord;
  currentRecord.codeCFOP = action.codeCFOP;
  currentRecord.description = action.description;
  return Object.assign({}, state, { currentRecord });
}





