import React from "react";
import { Card } from "primereact/card"; 
import { CadTagsPanelPage } from "./list/CadTagsPanelListPage";

export const CadTagsPanel = (props) => {
  return (
    <Card style={{padding:"0px"}}> 
      <CadTagsPanelPage />
    </Card>
  );
};
// .p-card-body {
//   padding: 1em
// em
// ;
// }