import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Column } from "primereact/column";
import { actions } from "../../tower_travel.actions";
import "./origin.scss";
import { OriginList } from "./OriginList";

const mapStateToProps = (state) => {
  const origins = state.crudTowerTravelState.currentRecord.origin || [];
  return {
    columns: state.crudTowerTravelState.originColumns,
    records: origins,
    first: state.crudTowerTravelState.index,
    rows: state.crudTowerTravelState.max,
    totalRecords: origins.length,
    sortField: state.crudTowerTravelState.sortField,
    order: state.crudTowerTravelState.sortOrder,
    selectedRecords: state.crudTowerTravelState.selectedRecordsOrigin,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    paginate: (paginationParams) => dispatch(actions.paginate(paginationParams)),
    sortOrder: (sortParams) => dispatch(actions.sortOrder(sortParams)),
    applyFilter: (filterParams) => {
      dispatch(actions.setType(null));
      dispatch(actions.applyFilter(filterParams));
    },

    setSelectedRecords: (selectedRecords) => {
      dispatch(actions.setSelectedRowsOrigin(selectedRecords));
    },
  };
};

class OriginListPageContainer extends React.Component {
  componentDidMount() {
    this.props.setSelectedRecords([]);
  }

  onSelectedEventsChangeHandler = (e) => {
    this.props.setSelectedRecords(e.value);
  };

  onPage = (e) => {
    this.props.paginate({
      max: e.rows,
      index: e.first,
    });
  };
  onSort = (e) => {
    this.props.sortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField,
    });
  };

  onRemoveEventsSelected = (e) => {
    this.props.onRemoveEventsSelected();
  };

  render() {
    const latLngTemplate = (rowData, column) => {
      try {
        return (
          <div className={"center-itens-row"}>
            {rowData.lat}/{rowData.lng}
          </div>
        );
      } catch (error) {
        return <div></div>;
      }
    };
    let columns = [<Column key="selectorColumn" selectionMode="multiple" style={{ width: "3em" }} />];
    columns = [
      ...columns,
      ...this.props.columns.map((col) => {
        if (col.field == "lat_lng") {
          return <Column {...col} key={col.field} body={latLngTemplate} style={{ width: "13em" }} />;
        }

        return <Column {...col} key={col.field} />;
      }),
    ];

    return <OriginList {...this.props} onSelectedEventsChange={this.onSelectedEventsChangeHandler} onPage={this.onPage} onSort={this.onSort} columns={columns} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OriginListPageContainer);
