export function validate(record) {
    const errors = [];

    if (!record.plate || record.plate.trim() === "") {
        errors.push({ field: 'plate', message: 'A placa do veículo é obrigatória!' });
    }

    if (!record.driver || record.driver.name === "") {
        errors.push({ field: 'driver.name', message: 'O motorista é obrigatório!' });
    }
    if (!record.vehicleCapacity || record.vehicleCapacity === "") {
        errors.push({ field: 'vehicleCapacity', message: 'A capacidade do veículo é obrigatória!' });
    }

    if (!record.vehicleType || record.vehicleType.name === "") {
        errors.push({ field: 'vehicleType.name', message: 'O Tipo do veículo é obrigatório!' });
    }

    const result = {
        ok: errors.length === 0,
        errors,
        record
    }

    return new Promise((resolve, reject) => {
        const action = result.ok ? resolve : reject;
        return action(result);
    })
}