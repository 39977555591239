import { Dialog } from "primereact/dialog";
import React from "react";
import { LookupField } from "../../../components/lookup/LookupField";
import "./dialog_obs.scss";
export class ImputTravelObsDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const footer = (
      <div>
        <button title="Confirmar" className="lognex-btn-highlighted" onClick={this.props.onConfirm}>
          <i className="pi pi-check"></i>
        </button>
        <button title="Cancelar" className="lognex-btn-danger" onClick={this.props.onCancel}>
          <i className="pi pi-times"></i>
        </button>
      </div>
    );

    return (
      <Dialog
        className="lxImputTravelObsDialog content"
        header={"Adcionar observação na viagem"}
        footer={footer}
        visible={this.props.visible}
        style={this.props.style || { width: "70vw" }}
        modal={false}
        onHide={this.props.onHide}
      >
        <div className="p-col-3 form-field-container">
          <label>Tipo de observação</label>
          <LookupField
            value={this.props.observationType ? this.props.observationType : null}
            placeholder="Tipo de observação"
            name="observationType"
            field="name"
            records={this.props.lookupObservationType.records}
            columns={this.props.lookupObservationType.columns}
            totalRecords={this.props.lookupObservationType.totalRecords}
            sortOrder={this.props.lookupObservationType.order}
            sortField={this.props.lookupObservationType.sortField}
            first={this.props.lookupObservationType.first}
            rows={this.props.lookupObservationType.rows}
            visible={this.props.lookupObservationType.visible}
            modal={this.props.lookupObservationType.modal}
            header={this.props.lookupObservationType.header}
            editDisabled={this.props.lookupObservationType.editDisabled}
            onEditField={this.props.lookupObservationType.lookupObservationTypeOnEdit}
            onClick={this.props.lookupObservationType.lookupObservationTypeOnClick}
            onHide={this.props.lookupObservationType.lookupObservationTypeOnHide}
            onConfirm={this.props.lookupObservationType.lookupObservationTypeOnConfirm}
            onCancel={this.props.lookupObservationType.lookupObservationTypeOnCancel}
            onComplete={this.props.lookupObservationType.lookupObservationTypeOnComplete}
            onInputFilter={this.props.lookupObservationType.lookupObservationTypeOnInputFilter}
            onPage={this.props.lookupObservationType.lookupObservationTypeOnPage}
            onFilter={this.props.lookupObservationType.lookupObservationTypeOnFilter}
            onSort={this.props.lookupObservationType.lookupObservationTypeOnOnSort}
          />
        </div>
        <textarea
          className="lxInputTextArea"
          value={this.props.inputObs}
          onChange={this.props.onChange}
          rows={10}
        ></textarea>
      </Dialog>
    );
  }
}
