export function validate(record) {
    const errors = [];
    const length = parseFloat(record.packing2.length)
    if (!record.packing1.name) {
        errors.push({ field: 'packing1.name', message: 'A embalagem 1 é obrigatória!' });
    }

    if (!record.packing2.name) {
        errors.push({ field: 'packing2.name', message: 'A embalagem 2 é obrigatória!' });
    }

    if (length <= 0) {
        errors.push({ field: 'packing2.length', message: 'Informe um valor válido para a quantidade!' });
    }

    if (!record.productCode) {
        errors.push({ field: 'productCode', message: 'O código do Produto deve ser informado!' });
    }

    const result = {
        ok: errors.length === 0,
        errors,
        record
    }

    return new Promise((resolve, reject) => {
        const action = result.ok ? resolve : reject;
        return action(result);
    })
}