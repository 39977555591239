import { validate } from "./validations";
import { build } from "../../services/crudFactory";
import { Action } from "../../services/actions.factory";
import api from "../../services/api.service";

const BASE_PATH = "/lognex/towerequipmenttype";
const prefix = "crudTowerEquipmentType";
const crud = build(validate, BASE_PATH);

const newTypes = {
  SET_NEW_EVENT_NAME: `${prefix}setNewEventName`,
  SET_LOOKUP_EVENT_LOGNEX_VISIBLE: `${prefix}setLookupEventLognexVisible`,
  VINCULA_EVENT_EQUIPMENT_TYPE_LOGNEX_EVENT: `${prefix}vinculaEventEquipmentTypeEventLognex`,
  REMOVE_EVENT_SELECTED: `${prefix}onRemoveEventsSelected`,
  LOAD_ALL: `${prefix}loadAll`,
  CUSTOM_LOAD_BY_ID: `${prefix}customLoadById`,
};

const actions = new Action(prefix, crud);

const types = Object.assign({}, actions.types, newTypes);
export { types, actions };

actions.loadAll = () => {
  return (dispatch, getState) => {
    api.post("/api/v1/lognex/towerequipmenttype/loadAll").then(({ data }) => {
      console.log("data=",data)
      dispatch({
        type: types.LOAD_ALL,
        records: data,
      });
    });
  };
};


actions.customLoadById = (oid) => {
  return (dispatch, getState) => {
    api.get(`/api/v1/lognex/towerequipmenttype/loadById/${oid}`).then(({ data }) => {
      console.log("data=",data)
      dispatch({
        type: types.CUSTOM_LOAD_BY_ID,
         data,
      });
    });
  };
};


actions.setNewEventName = (eventName) => {
  return {
    type: types.SET_NEW_EVENT_NAME,
    eventName,
  };
};

actions.setLookupEventLognexVisible = (visible) => {
  return {
    type: types.SET_LOOKUP_EVENT_LOGNEX_VISIBLE,
    visible,
  };
};

actions.vinculaEventEquipmentTypeEventLognex = () => {
  return {
    type: types.VINCULA_EVENT_EQUIPMENT_TYPE_LOGNEX_EVENT,
  };
};

actions.onRemoveEventsSelected = () => {
  return {
    type: types.REMOVE_EVENT_SELECTED,
  };
};
