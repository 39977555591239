import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Column } from "primereact/column";
import { actions } from "../../camapanha-anymarket.actions";
import "./product.scss";
import { ProductCampAnyList } from "./ProductCampAnyList";
import { InputText } from "primereact/inputtext";
import { ButtonDialog } from "../../../../components/button-dialog/ButtonDialog";

const mapStateToProps = (state) => {
  const products = state.crudCampanhaAnymarketState.currentRecord.product || [];
  const maketplaceName = state.crudCampanhaAnymarketState.currentRecord.maketplaceName
  const percentDesconto = state.crudCampanhaAnymarketState.currentRecord.actionType.percentDesconto
  return {
    columns: state.crudCampanhaAnymarketState.productColumns,
    records: products,
    selectedRecords: state.crudCampanhaAnymarketState.selectedRecordsProduct,
    inputEditSku: state.crudCampanhaAnymarketState.inputEditSku,
    maketplaceName,
    percentDesconto
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    paginate: (paginationParams) => dispatch(actions.paginate(paginationParams)),
    sortOrder: (sortParams) => dispatch(actions.sortOrder(sortParams)),
    applyFilter: (filterParams) => {
      dispatch(actions.setType(null));
      dispatch(actions.applyFilter(filterParams));
    },
    setField: (field, value) => dispatch(actions.setField(field, value)),
    changeHandler: event => {
      const target = event.target;
      const field = target.name;
      let value = target.value;
      if (field == "actionType.percentDesconto") {
        try {
          value = parseFloat(value)
          if (value < 0)
            value = 0
          if (value > 100)
            value = 100
        } catch (error) {

        }
      }

      dispatch(actions.removeErrors([{ field }]));
      dispatch(actions.setField(field, value));
    },

    onRemoveProduct: (sku) => {
      dispatch(actions.removeProduct(sku));
    },
    setInputSku: (sku) => {
      dispatch(actions.setInputSku(sku));
    },

    addNewProduct: () => dispatch(actions.addNewProduct())

  };
};

class ProductCampAnyListPageContainer extends React.Component {
  componentDidMount() {
  }

  onchangeInputSku = (e) => {
    this.props.setInputSku(e.target.value)
  }


  render() {


    const removeTemplate = (rowData, column) => {
      return (
        <div>
          <ButtonDialog
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              this.props.onRemoveProduct(rowData.sku);
            }}
            button={{
              title: "Remover",
              className: "lognex-btn-danger-inverse",
            }}
            dialog={{
              header: "Confirmação",
              modal: true,
              message: "Deseja realmente remover esse produto",
            }}
          ></ButtonDialog>
        </div>
      );

    };
    let columns = this.props.columns.map((col) => {

      if (col.field == "remove") {
        return <Column {...col} key={col.field} body={removeTemplate} style={{ width: "60px" }} />;
      }
      return <Column {...col} key={col.field} />;
    })

    let disableBtnAdd = true
    if (this.props.inputEditSku && this.props.inputEditSku != "") {
      disableBtnAdd = false
    }

    return <div className="p-grid">


      <div className="p-col-8 form-field-container">
        <label className="lxRequiredField">Nome Marketplace</label>
        <InputText type="text" value={this.props.maketplaceName} name="maketplaceName" placeholder="Nome Marketplace" onChange={this.props.changeHandler} />
      </div>
      <div className="p-col-4 form-field-container">
        <label>Tipo Ação - %off</label>
        <InputText type="number" value={this.props.percentDesconto} name="actionType.percentDesconto" min={0} max={100} placeholder="" onChange={this.props.changeHandler} />
      </div>

      <div className="p-col-3 form-field-container">
        <label>SKU</label>
        <InputText type="text" value={this.props.inputEditSku} placeholder="sku" onChange={this.onchangeInputSku} />
      </div>

      <div className="p-col-6 form-field-container" />

      <div className="p-col-3 form-field-container align-bottom-btn">
        <button
          className={"lognex-btn-highlighted bottons"}
          onClick={this.props.addNewProduct}
          title="Novo"
          disabled={disableBtnAdd}
        >
          <i className="pi pi-plus"></i>
        </button>
      </div>
      <div className="p-col-12">
        <ProductCampAnyList {...this.props} columns={columns} />
      </div>
    </div >;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductCampAnyListPageContainer);
