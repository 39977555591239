
    import React from 'react';
    import ComponentRangeTypeFormPageContainer from './ComponentRangeTypeFormPageContainer';
    
    export class ComponentRangeTypeFormPage extends React.Component{
        render(){
            return (
                <ComponentRangeTypeFormPageContainer {...this.props}/>
            )
        }
    }
    