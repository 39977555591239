
import { validate } from './validations';
import { build } from '../../services/crudFactory';
import { Action } from '../../services/actions.factory';
const BASE_PATH = '/lognex/vehicles';
const prefix = 'crudVehicle';
const crud = build(validate, BASE_PATH);


const actions = new Action(prefix, crud);

const types = {
    SET_LOOKUP_DRIVER_VISIBLE: `${prefix}setLookupDriverVisible`,
    SET_LOOKUP_VEHICLE_TYPE_VISIBLE: `${prefix}setLookupVehicleTypeVisible`,
    ...actions.types
}


actions.setLookupDriverVisible = (visible) => {
    return {
        type: types.SET_LOOKUP_DRIVER_VISIBLE,
        visible
    }
}
actions.setLookupVehicleTypeVisible = (visible) => {
    return {
        type: types.SET_LOOKUP_VEHICLE_TYPE_VISIBLE,
        visible
    }
}

export { types, actions }