import React from "react";
import { DataTable } from "primereact/datatable";

export const RouteRowSteps = (props) => (
  <DataTable  
   onRowClick={props.onRowClick}
   value={props.records}
   emptyMessage="Não foram encontrados registros" 
   >
    {props.columns}
  </DataTable>
);
