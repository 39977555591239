import React from "react";
import { connect } from "react-redux";
import { Page } from "../../../components/page/Page";
import Toolbar from "../../../components/toolbar/Toolbar";
import { actions as unidadeActions } from "../../unidade/unidade.actions";
import { actions } from "../transferRoute.actions";
import { routes } from '../../../constants';
import { Column } from 'primereact/column';
import { TransferRouteForm } from "./TransferRouteForm";

const mapStateToProps = (state) => {
    const record = state.crudTransferRouteState.currentRecord;
    
    // lookup de Origem
    const lookupOriginBase = {
        records: state.crudUnidadeState.records,
        columns: state.crudUnidadeState.columns
            .filter(col => col.field === 'oid' || col.field === 'sigla' )
            .map(col => <Column key={col.field} {...col} />),
        first: state.crudUnidadeState.index,
        rows: state.crudUnidadeState.max,
        totalRecords: state.crudUnidadeState.count,
        sortField: state.crudUnidadeState.sortField,
        order: state.crudUnidadeState.sortOrder,
    }
    let lookupOrigin = { ...state.crudTransferRouteState.lookupOrigin, ...lookupOriginBase}

    // lookup de Destino
    const lookupDestinationBase = {
        records: state.crudUnidadeState.records,
        columns: state.crudUnidadeState.columns
            .filter(col => col.field === 'oid' || col.field === 'sigla')
            .map(col => <Column key={col.field} {...col} />),
        first: state.crudUnidadeState.index,
        rows: state.crudUnidadeState.max,
        totalRecords: state.crudUnidadeState.count,
        sortField: state.crudUnidadeState.sortField,
        order: state.crudUnidadeState.sortOrder,
    }
    let lookupDestination = { ...state.crudTransferRouteState.lookupDestination, ...lookupDestinationBase}
    
    return {
        record,
        lookupOrigin,
        lookupDestination,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        saveHandler: event => {
            dispatch(actions.save());
        },
        removeHandler: event => {
            dispatch(actions.remove());
        },
        newHandler: event => {
            dispatch(actions.makeNew());
        },
        loadById: oid => dispatch(actions.loadById(oid)),

        // lookup de Origem
        setOriginSelected: origin => dispatch(actions.setOriginSelected(origin)),
        originLoad: () => dispatch(unidadeActions.load()),
        setOriginVisible: visible => dispatch(actions.setOriginVisible(visible)),
        lookupOriginApplyFilter: (filterParams) => {
            dispatch(unidadeActions.setType(null));
            dispatch(unidadeActions.applyFilter(filterParams));
        },
        lookupOriginPaginate: paginationParams => dispatch(unidadeActions.paginate(paginationParams)),
        lookupOriginSort: sortParams => dispatch(unidadeActions.sortOrder(sortParams)),

        // lookup de Destino
        setDestinationSelected: origin => dispatch(actions.setDestinationSelected(origin)),
        setDestinationVisible: visible => dispatch(actions.setDestinationVisible(visible)),
        lookupDestinationApplyFilter: (filterParams) => {
            dispatch(unidadeActions.setType(null));
            dispatch(unidadeActions.applyFilter(filterParams));
        },
        destinationLoad: () => dispatch(unidadeActions.load()),
        lookupDestinationPaginate: paginationParams => dispatch(unidadeActions.paginate(paginationParams)),
        lookupDestinationOnSort: sortParams => dispatch(unidadeActions.sortOrder(sortParams)),
        setField: (field, value) => dispatch(actions.setField(field, value)),
    }
}

class TransferRouteFormPageContainer extends React.Component {


    constructor(props) {
        super(props);
        actions.afterSave = ({ oid }) => {
            this.props.history.replace(`${routes.PATH_TRANSFER_ROUTE}/${oid}`);
        };
        actions.afterNew = () => {
            this.props.history.replace(`${routes.PATH_TRANSFER_ROUTE}/novo`);
        };
        actions.afterRemove = () => {
            this.props.history.replace(`${routes.PATH_TRANSFER_ROUTE}`);
        };
    }

    componentDidMount() {
        const oid = this.props.match.params.id;
        if (oid && parseInt(oid)) {
            this.props.loadById(oid);
        } else {
            this.props.newHandler();
        }
    }

    searchHandler = () => {
        this.props.history.push(`${routes.PATH_TRANSFER_ROUTE}`)
    }

    onChangeHandlerDistance = (e) => {
        const target = e.target;
        const field = target.name;
        let value = Number(target.value);        
        this.props.setField(field, value);
    }   

    // LOOKUP ORIGIN
    lookupOriginOnComplete = (e) => {};
    lookupOriginOnInputFilter = (e) => {
        const value = e.value;
        if (typeof value === "string") {
          const filters = [
            {
              campo: "sigla",
              valor: e.value,
              tipoFiltro: "CONTEM",
            },
          ];
          this.props.lookupOriginApplyFilter(filters);          
          this.props.setOriginSelected({ sigla: e.value });
          
        } else {
            // const data = {
            //     documentNumber: value.cnpj,
            //     label: value.sigla,
            //     name: value.sigla
            // }
          this.props.setOriginSelected(value);
        }
    };
    lookupOriginOnEdit = (e) => {}
    lookupOriginOnClick = (e) => {
        this.props.lookupOriginApplyFilter([]);
        this.props.originLoad();
        this.props.setOriginVisible(true);
    };
    lookupOriginOnConfirm = (e) => {
        this.props.lookupOriginApplyFilter([]);
        const data = {
            documentNumber: e.selection.cnpj,
            sigla: e.selection.sigla,
            name:  e.selection.sigla
        }
        this.props.setOriginSelected(data);
        this.props.setOriginVisible(false);
    };
    lookupOriginOnCancel = (e) => {
        this.props.lookupOriginApplyFilter([]);
        this.props.setOriginVisible(false);
    };
    lookupOriginOnPage = (e) => {
        this.props.lookupOriginPaginate({ 
          max: e.rows,
          index: e.first,
        });
    };
    lookupOriginOnSort = (e) => {
        this.props.lookupOriginSort({ 
            sortOrder: e.sortOrder,
            sortField: e.sortField,
        });
    };
    lookupOriginOnFilter = (e) => {
        const filters = [];
        const keys = Object.keys(e.filters) || [];
        keys.forEach((key) => {
            filters.push({
            campo: key,
            valor: e.filters[key].value,
            tipoFiltro: "CONTEM",
            });
        });
        this.props.lookupOriginApplyFilter(filters);
    };
    lookupOriginOnHide = (e) => {
        this.props.lookupOriginApplyFilter([]);
        this.props.setOriginVisible(false);
    };


    // LOOKUP DESTINATION
    lookupDestinationOnComplete = (e) => {};
    lookupDestinationOnInputFilter= (e) => {
        const value = e.value;
        if (typeof value === "string") {
          const filters = [
            {
              campo: "sigla",
              valor: e.value,
              tipoFiltro: "CONTEM",
            },
          ];
          this.props.lookupDestinationApplyFilter(filters);
          this.props.setDestinationSelected({ sigla: e.value });
          
        } else {
          this.props.setDestinationSelected(value);
        }
    };
    lookupDestinationOnEdit = (e) => {}
    lookupDestinationOnClick= (e) => {
        this.props.lookupDestinationApplyFilter([]);
        this.props.destinationLoad();
        this.props.setDestinationVisible(true);
    };
    lookupDestinationOnHide = (e) => {
        this.props.lookupDestinationApplyFilter([]);
        this.props.setDestinationVisible(false);
    };
    lookupDestinationOnConfirm= (e) => {
        this.props.lookupDestinationApplyFilter([]);
        const data = {
            documentNumber: e.selection.cnpj,
            sigla: e.selection.sigla,
            name:  e.selection.sigla
        }
        this.props.setDestinationSelected(data);
        this.props.setDestinationVisible(false);
    };
    lookupDestinationOnCancel = (e) => {
        this.props.lookupDestinationApplyFilter([]);
        this.props.setDestinationVisible(false);
    };
    lookupDestinationOnPage = (e) => {
        this.props.lookupDestinationPaginate({  
          max: e.rows,
          index: e.first,
        });
    };
    lookupDestinationOnFilter = (e) => {
        const filters = [];
        const keys = Object.keys(e.filters) || [];
        keys.forEach((key) => {
            filters.push({
            campo: key,
            valor: e.filters[key].value,
            tipoFiltro: "CONTEM",
            });
        });
        this.props.lookupDestinationApplyFilter(filters);
    };
    lookupDestinationOnSort= (e) => {
        this.props.lookupDestinationOnSort({
            sortOrder: e.sortOrder,
            sortField: e.sortField,
        });
    };




    render(){
        const title = `Rota de Transferência ${this.props.record.oid || 'nova'}`
        const header = (
            <div>
                <h1>{title}</h1>
                <hr className="lxSplitter" ></hr>
                <div>
                    <Toolbar
                        onSave={this.props.saveHandler} saveButtonStyle='highlighted'
                        onRemove={this.props.removeHandler} removeButtonStyle='danger'
                        onNew={this.props.newHandler}
                        onSearch={this.searchHandler}>
                    </Toolbar>
                </div>
            </div>
        );
        const content = (
            <TransferRouteForm
               record={this.props.record} 
               changeHandler={this.onChangeHandlerDistance}
               
               
               lookupOrigin={this.props.lookupOrigin}
               lookupOriginOnComplete={this.lookupOriginOnComplete}
               lookupOriginOnInputFilter={this.lookupOriginOnInputFilter}
               lookupOriginOnEdit={this.lookupOriginOnEdit}
               lookupOriginOnClick={this.lookupOriginOnClick}
               lookupOriginOnConfirm={this.lookupOriginOnConfirm}
               lookupOriginOnCancel={this.lookupOriginOnCancel}
               lookupOriginOnHide={this.lookupOriginOnHide}
               lookupOriginOnPage={this.lookupOriginOnPage}
               lookupOriginOnFilter={this.lookupOriginOnFilter}
               lookupOriginOnSort={this.lookupOriginOnSort}
               
               
               lookupDestination={this.props.lookupDestination}
               lookupDestinationOnComplete={this.lookupDestinationOnComplete}
               lookupDestinationOnInputFilter={this.lookupDestinationOnInputFilter}
               lookupDestinationOnEdit={this.lookupDestinationOnEdit}
               lookupDestinationOnClick={this.lookupDestinationOnClick}
               lookupDestinationOnHide={this.lookupDestinationOnHide}
               lookupDestinationOnConfirm={this.lookupDestinationOnConfirm}
               lookupDestinationOnCancel={this.lookupDestinationOnCancel}
               lookupDestinationOnPage={this.lookupDestinationOnPage}
               lookupDestinationOnFilter={this.lookupDestinationOnFilter}
               lookupDestinationOnSort={this.lookupDestinationOnSort}

            />
        )
        
        return <Page header={header} content={content}></Page>
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TransferRouteFormPageContainer)