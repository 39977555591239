import React from 'react';
import './Toolbar.scss'
import { ButtonDialog } from '../button-dialog/ButtonDialog'
import FileUploaderButton from '../file-uploader-button/FileUploaderButton';
import csvIcon from '../../assets/images/icons/file_csv_solid.svg';
import locationIcon from "../../assets/images/icons/location.png"; 

function styleClass(styleType) {
    if (!styleType || typeof styleType !== 'string') {
        return '';
    }
    switch (styleType.toUpperCase()) {
        case 'DANGER':
            return 'lognex-btn-danger'
        case 'HIGHLIGHTED':
            return 'lognex-btn-highlighted';
        default:
            return '';
    }
}
const Toolbar = props => {
    const { saveButtonStyle, removeButtonStyle, newButtonStyle, searchButtonStyle, exportButtonStyle, importButtonStyle, duplicateButtonStyle, downloadButtonStyle, downloadXLSXTemplateStyle } = props;
    const saveButtonClass = styleClass(saveButtonStyle);
    const removeButtonClass = styleClass(removeButtonStyle);
    const newButtonClass = styleClass(newButtonStyle);
    const searchButtonClass = styleClass(searchButtonStyle);
    const exportButtonClass = styleClass(exportButtonStyle);
    const importButtonClass = styleClass(importButtonStyle);
    const duplicateButtonClass = styleClass(duplicateButtonStyle);
    const downloadButtonClass = styleClass(downloadButtonStyle);
    const downloadXLSXTemplateButtonClass = styleClass(downloadXLSXTemplateStyle)
    if (props.children) {
        return (
            <div className='lognex-toolbar'>
                {props.children}
            </div>
        )
    }
    const messageDelete = props && props.messageDelete ? props.messageDelete : "Deseja realmente remover o registro?"
    const counter = props && props.counter ? props.counter : false
    return (
        <div className='lognex-toolbar'>
            <div className="p-toolbar-group-left">
                {props.onSave &&
                    <ButtonDialog
                        onClick={props.onSave}
                        alwaysExecute={true}
                        button={{
                            title: 'Salvar',
                            className: saveButtonClass,
                            iconClassName: "pi pi-save",
                            disabled: props.btnSaveIsDisabled || false
                        }}
                        dialog={props.saveDialog}
                    ></ButtonDialog>
                }
                {props.onRemove &&

                    <ButtonDialog
                        onClick={props.onRemove}
                        button={{
                            title: 'Remover',
                            className: removeButtonClass,
                            disabled: props.btnRemoveIsDisabled || false
                        }}
                        dialog={{
                            header: 'Confirmação',
                            modal: false,
                            message: messageDelete,
                        }}
                        counter={counter}
                    ></ButtonDialog>
                }
                {props.onNew &&
                    <button
                        className={newButtonClass}
                        onClick={props.onNew}
                        title="Novo"
                        style={{ marginRight: ".25em" }}
                    >
                        <i className="pi pi-plus"></i>
                    </button>
                }
                {props.onSearch &&
                    <button
                        className={searchButtonClass}
                        onClick={props.onSearch}
                        title="Pesquisar"
                        style={{ marginRight: ".25em" }}
                    >
                        <i className="pi pi-search"></i>
                    </button>
                }
                {props.onExportJSON &&
                    <button
                        className={exportButtonClass}
                        onClick={props.onExportJSON}
                        title="Exportar"
                        style={{ marginRight: ".25em" }}
                    >
                        <i className="pi pi-download"></i>
                    </button>
                }
                {props.onImportJSON &&
                    <FileUploaderButton buttonClass={importButtonClass} accept=".json" onUpload={props.onImportJSON}></FileUploaderButton>
                }
                {props.onImportXLSX &&
                    <FileUploaderButton buttonClass={importButtonClass} accept=".xlsx,.xls" onUpload={props.onImportXLSX}></FileUploaderButton>
                }

                {props.onDuplicate &&
                    <button
                        className={duplicateButtonClass}
                        onClick={props.onDuplicate}
                        title="Duplicar"
                        style={{ marginRight: ".25em" }}
                    >
                        <i className="pi pi-clone"></i>
                    </button>
                }

                {props.onDownload &&
                    <button
                        className={downloadButtonClass}
                        onClick={props.onDownload}
                        title="Download"
                        style={{ marginRight: ".30em", marginBottom: "5px" }}
                    >
                        <i>
                            <img src={csvIcon} style={{ width: "33px", height: "33px" }}></img>
                        </i>
                    </button>
                }



            </div>

            <div className="p-toolbar-group-right">
                {props.onImportXML &&
                    <FileUploaderButton buttonClass={importButtonClass} accept=".xml,.kml" onUpload={props.onImportXML}></FileUploaderButton>
                }
                {props.onExportXlsxVtex &&

                    <button
                        title="Exportar Tabela no template XLSX VTEX"
                        className={"lognex-btn-highlighted-inverse"}
                        style={{
                            width: "84px",
                            cursor: "pointer",
                            margin: "auto",
                            marginRight: "5px"
                        }}
                        onClick={props.onExportXlsxVtex}
                    >
                        <div style={{ border: "none" }}>
                            <i style={{ border: "none" }} className="pi pi-download">VTEX</i>
                        </div>
                    </button>
                }
                {props.XLSXTemplatePath &&
                    <a className="downloadXLSXTemplateButton" href={props.XLSXTemplatePath}
                        title={props.titleBtnDownload ? props.titleBtnDownload : "Download Template"}>
                        <i className="pi pi-download"></i>
                    </a>
                }

                {props.onOpenCargoShipOnMapa &&
                    <button
                        key="btnViewOnMap"
                        title="Ver rota no mapa"
                        onClick={props.onOpenCargoShipOnMapa}
                        style={{ width: "33px", marginRight: ".25em",cursor:"pointer" }}
                        className="lognex-btn"
                    >
                        <i>
                            <img src={locationIcon} style={{ height: "26px",cursor:"pointer" }}></img>
                        </i>
                    </button>
                }

                {props.onShowFreightQuote &&
                    <button
                        key="freightQuote"
                        title="Calcular cotações de frete"
                        onClick={props.onShowFreightQuote}
                        style={{ width: "33px", marginRight: ".25em" }}
                        className="lognex-btn-highlighted"
                    >
                        <i className="pi pi-dollar"></i>
                    </button>
                }

                {props.onShowHistoryLog &&
                    <button
                        className={'lognex-btn-highlighted'}
                        onClick={props.onShowHistoryLog}
                        title="Histórico"
                        style={{ marginRight: ".25em" }}>
                        <i className="pi pi-comments"></i>
                    </button>
                }

                {props.onExportXLSX &&
                    <button
                        className={'lognex-btn-highlighted-inverse'}
                        onClick={props.onExportXLSX}
                        title="Exportar Prazos de Entrega"
                        style={{ width: "80px", marginLeft: "5px", marginRight: ".25em" }}>
                        Exportar
                    </button>
                }

            </div>
        </div>



    )
};


export default Toolbar;