import { types } from "./client.actions";
import { Reducer } from "../../services/reducer.factory";

const initialState = {
  columns: [
    {
      field: "oid",
      header: "Código",
      columnKey: "oid",
      sortable: true,
      filter: true
    },
    {
      field: "razaoSocial",
      header: "Razão Social",
      columnKey: "razaoSocial",
      sortable: true,
      filter: true
    },{
      field: "cnpj",
      header: "CNPJ",
      columnKey: "cnpj",
      sortable: true,
      filter: true
    },
    {
      field: "nomeFantasia",
      header: "Nome Fantasia",
      columnKey: "nomeFantasia",
      sortable: true,
      filter: true
    },
    {
      field: "email",
      header: "Email",
      columnKey: "email",
      sortable: true,
      filter: true
    },
    {
      field: "phone",
      header: "Telefone",
      columnKey: "phone",
      sortable: true,
      filter: true
    }
  ],
  logImportResult: null,
  currentRecord: {
    oid: "",
    razaoSocial: "",
    nomeFantasia: "",
    cnpj: "",
    address: { },
    email: "",
    phone: "",
  },
  lookupCities: {
    visible: false,
    modal: true,
    header: "Cidade"
  },
  lookupStates: {
    visible: false,
    modal: true,
    header: "Estado"
  },
  // records: [
  //   {
  //     oid: "1",
  //     razaoSocial: "Cliente Teste",
  //     nomeFantasia: "Cliente Fantasia",
  //     cnpj: "02.759.150/0001-09",
  //     address: {
  //       street: "Rua da empresa",
  //       number: "45",
  //       district: "Salto",
  //       cep: "",
  //       complement: "Empresa",
  //       city: "Blumenau",
  //       estate: "SC"
  //     },
  //     email: "ClienteTeste@gmail.com",
  //     phone: "3334-3562"
  //   }
  // ]
};

export function CRUDClientReducer(state, action) {
  state = reducer.execute(state, action);
  return execute(state, action);
}

function execute(state, action) {
  let result = state;
  switch (action.type) {
    case types.SET_ADDRESS:
      return setAddress(state, action);
    case types.SET_LOOKUP_CITIES_VISIBLE:
      return setlookupCitiesVisible(state, action);
    case types.SET_LOOKUP_STATES_VISIBLE:
      return setlookupStatesVisible(state, action);
    case types.SET_IMPORT_XLSX_RESULT:
      return setImportXLSXResult(state, action);
    default:
      return result;
  }
}

function setImportXLSXResult(state, action){
  return {...state, logImportResult:action.logImportResult}
}
function setlookupCitiesVisible(state, action) {
  let lookupCities = state.lookupCities;
  lookupCities = Object.assign({}, lookupCities, { visible: action.visible });
  return Object.assign({}, state, { lookupCities });
}

function setlookupStatesVisible(state, action) {
  let lookupStates = state.lookupStates;
  lookupStates = Object.assign({}, lookupStates, { visible: action.visible });
  return Object.assign({}, state, { lookupStates });
}

function setAddress(state,action){
  const address = Object.assign({},state.currentRecord.address);
  address[action.field] = action.value;
  const currentRecord = Object.assign({},state.currentRecord,{address});
  return Object.assign({},state,{currentRecord});
}


const reducer = new Reducer(initialState, types);
