import { validate } from './validations';
import { build } from '../../services/crudFactory';
import { Action } from '../../services/actions.factory'; 
import { makeMessages, messageActions } from '../../store/message';
import api from "../../services/api.service";

const BASE_PATH = '/lognex/pendencedoc';
const prefix = 'crudPendenceDoc';
const crud = build(validate, BASE_PATH);

const newTypes = {  
  //
  SET_INITIAL_DATE_FILTER: `${prefix}setInitialDateFilter`,
  SET_FINAL_DATE_FILTER: `${prefix}setFinalDateFilter`,
  SET_EXPANDED_ROW: `${prefix}setExpandedRows`,
  START_REPROCESS_DOC_PENDENT: `${prefix}reprocessPendenceDoc`,  
  SET_FILTERS: `${prefix}saveFilter`,  
}

const actions = new Action(prefix, crud);

const types = Object.assign({}, actions.types, newTypes); 
export { types, actions }

 
actions.setInitialDateFilter = (initialDateFilter) => {
  return {
    type: types.SET_INITIAL_DATE_FILTER,
    initialDateFilter,
  };
};

actions.setFinalDateFilter = (finalDateFilter) => {
  return {
    type: types.SET_FINAL_DATE_FILTER,
    finalDateFilter,
  };
};
actions.setExpandedRows = (expandedRows) => {
  return {
    type: types.SET_EXPANDED_ROW,
    expandedRows,
  };
};

//
actions.reprocessPendenceDoc = (documentReprocess) => {
  return (dispatch, getState) => {  
    const state = getState().crudPendenceDocState
    const filters = state.filters;

    dispatch(actions.showLoader());

    api.post("/api/v1/lognex/pendencedoc/reprocess", documentReprocess)
      .then((result) => {
        dispatch(actions.hideLoader());
        dispatch(actions.applyFilter(filters));
        dispatch(messageActions.messageShowMessages(makeMessages(["Registro realizado com sucesso"], "success")));
      })
      .catch((err) => {
        dispatch(actions.hideLoader());
        dispatch(messageActions.messageShowMessages(makeMessages(["Não foi possível atualizar o registro"], "error"))); 
      }); 
  };
};
actions.saveFilter = (filters) => {
  return {
    type: types.SET_FILTERS,
    filters,
  };
};
