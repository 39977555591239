const TOKEN_KEY = 'TOKEN'
const COMPANY_KEY = 'COMPANY'
const USER_KEY = 'USER'
const RESPONSE_ID = 'RESPONSE_ID'
const internalStorage = window.localStorage; 

const setResponseID = (responseID)=>{
    if(responseID){
        internalStorage.setItem(RESPONSE_ID,responseID);
    }else{
        internalStorage.removeItem(RESPONSE_ID);
    }
}

const getResponseID = ()=>{
    return internalStorage.getItem(RESPONSE_ID);
}

const setToken = (token)=>{
    internalStorage.setItem(TOKEN_KEY,token);
}

const clear = ()=>{
    internalStorage.clear();
}

const getToken = ()=>{
    return internalStorage.getItem(TOKEN_KEY);
}

const setCompany = (empresa)=>{
    if(empresa){
        internalStorage.setItem(COMPANY_KEY,JSON.stringify(empresa));
    }else{
        internalStorage.removeItem(COMPANY_KEY);
    }
}

const getCompany = ()=>{
     const company = internalStorage.getItem(COMPANY_KEY);
     if(company){
         return JSON.parse(company);
     }
     return null;
}


const setUser = (user)=>{
    if(user){
        internalStorage.setItem(USER_KEY,JSON.stringify(user));
    }
}

const getUser = ()=>{
    const value = internalStorage.getItem(USER_KEY);
    return value ? JSON.parse(value): null;
}

export default {
    clear,
    setToken,
    getToken,
    setCompany,
    getCompany,
    setUser,
    getUser,
    setResponseID,
    getResponseID
}