import { build } from "../../services/crudFactory";
import { Action } from "../../services/actions.factory";
import api from "../../services/api.service";
const BASE_PATH = "/lognex/audit-nfe-by-shipment";
const prefix = "auditoriaTracking";

const crud = build(false, BASE_PATH);

const newTypes = {
  SET_LOOKUP_CARRIER_VISIBLE: `${prefix}setLookupCarrierVisible`,
  SET_INITIAL_DATE_FILTER: `${prefix}setInitialDateFilter`,
  SET_FINAL_DATE_FILTER: `${prefix}setFinalDateFilter`,
  SET_NFE_NUMBER_FILTER: `${prefix}setNfeNumberFilter`,
  SET_LOOKUP_CARRIER_VISIBLE: `${prefix}setLookupCarrierVisible`,
  SET_EXPANDED_ORDERS_ROWS: `${prefix}setExpandedShipmentRows`,
  APPROVE_ORDER: `${prefix}approveOrder`,
  SET_DIALOG_APPROVE_COMPONENT_VISIBLE: `${prefix}setDialogApproveComponentVisible`,
  SET_CURRENT_APPROVE_COMPONENT_DESCRIPTION: `${prefix}setCurrentApproveComponentDescription`,
  SET_DIALOG_APPROVE_COMPONENT_TYPE: `${prefix}setDialogApproveComponentType`,
  SET_ORDERS_TO_APPROVE: `${prefix}setOrdersToApprove`,
};

const actions = new Action(prefix, crud);

const types = Object.assign({}, actions.types, newTypes);

actions.setLookupCarrierVisible = (visible) => {
  return {
      type: types.SET_LOOKUP_CARRIER_VISIBLE,
      visible
  }
}
actions.setInitialDateFilter = (initialDateFilter) => {
  return {
    type: types.SET_INITIAL_DATE_FILTER,
    initialDateFilter
  }
}
actions.setFinalDateFilter = (finalDateFilter) => {
  return {
    type: types.SET_FINAL_DATE_FILTER,
    finalDateFilter
  }
}
actions.setNfeNumberFilter = (nfeNumberFilter) => {
  return {
    type: types.SET_NFE_NUMBER_FILTER,
    nfeNumberFilter
  }
}
actions.setLookupCarrierVisible = (visible) => {
  return {
    type: types.SET_LOOKUP_CARRIER_VISIBLE,
    visible,
  };
};
actions.setExpandedOrdersRows = (expandedOrders) => {
  return {
    type: types.SET_EXPANDED_ORDERS_ROWS,
    expandedOrders,
  };
}
actions.setOrdersToApprove = (approveOrder) => {
  return {
    type: types.SET_ORDERS_TO_APPROVE,
    approveOrder,
  };
}
actions.approveOrder = () => {
  return (dispatch, getState) => {
    const order = getState().auditoriaTrackingState.approveOrder;
    const description = getState().auditoriaTrackingState.currentApproveComponent.description;
    const idEmbarque = getState().auditoriaTrackingState.idEmbarque;
    const valueFreight = getState().auditoriaTrackingState.valueFreight;
    const cnpjCarrier = getState().auditoriaTrackingState.cnpjCarrier;
    const numberNFE = getState().auditoriaTrackingState.numberNFE;

    return api.post(`/api/v1${BASE_PATH}/approve`, { order, description, idEmbarque, valueFreight, cnpjCarrier,numberNFE })
      .then((response) => {
      })
  }
}
actions.downloadCSV = (filters) => {
  return (dispatch) => {
    api.post(`/api/v1${BASE_PATH}/report`)
      .then((response) => {
        dispatch(actions.load());
        const data = response.data;
        const anchor = document.createElement("a");
        anchor.href = data.presigned_url;
        anchor.target = "_blank";
        document.body.append(anchor);
        anchor.click();
        anchor.remove();
      })
      .catch((err) => {
        console.log(err);
        dispatch(actions.hideLoader());
      });
  }
}

// Dialog Approve Component
actions.setDialogApproveComponentVisible = (visible) => {
  return {
    type: types.SET_DIALOG_APPROVE_COMPONENT_VISIBLE,
    visible,
  };
}
actions.setCurrentApproveComponentDescription = (description) => {
  return {
    type: types.SET_CURRENT_APPROVE_COMPONENT_DESCRIPTION,
    description
  }
}
actions.setDialogApproveComponentType = (approvalType) => {
  return {
    type: types.SET_DIALOG_APPROVE_COMPONENT_TYPE,
    approvalType
  }
}
export { types, actions };
