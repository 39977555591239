import React from 'react';
import { connect } from 'react-redux';
import { Column } from 'primereact/column';
import Toolbar from "../../../components/toolbar/Toolbar";
import { actions } from '../permissao.actions';
import { actions as menuActions } from '../../menu/menu.actions';
import { actions as perfilActions } from '../../perfil/perfil.actions';
import { routes } from '../../../constants';
import { Page } from "../../../components/page/Page";
import { PermissaoForm } from './PermissaoForm';


const mapStateToProps = state => {
    const record = state.crudPermissaoState.currentRecord;
    const lookupMenuBase = {
        records: state.crudMenuState.records,
        columns: state.crudMenuState.columns
            .filter(col => col.field === 'oid' || col.field === 'nome' || col.field === 'nome')
            .map(col => <Column key={col.field} {...col} />),
        first: state.crudMenuState.index,
        rows: state.crudMenuState.max,
        totalRecords: state.crudMenuState.count,
        sortField: state.crudMenuState.sortField,
        order: state.crudMenuState.sortOrder,
        editDisabled: !record.oidMenu
    }
    const lookupPerfilBase = {
        records: state.crudPerfilState.records,
        columns: state.crudPerfilState.columns
            .filter(col => col.field === 'oid' || col.field === 'nome' || col.field === 'nome')
            .map(col => <Column key={col.field} {...col} />),
        first: state.crudPerfilState.index,
        rows: state.crudPerfilState.max,
        totalRecords: state.crudPerfilState.count,
        sortField: state.crudPerfilState.sortField,
        order: state.crudPerfilState.sortOrder,
        editDisabled: !record.oidPerfil
    }
    let lookupMenu = Object.assign({}, state.crudPermissaoState.lookupMenu, lookupMenuBase);
    let lookupPerfil = Object.assign({}, state.crudPermissaoState.lookupPerfil, lookupPerfilBase);

    return {
        record,
        lookupMenu,
        lookupPerfil
    }
}

const mapDispatchToProps = dispatch => {
    return {
        lookupMenuSetVisible: visible => dispatch(actions.setLookupMenuVisible(visible)),
        lookupMenuPaginate: paginationParams => dispatch(menuActions.paginate(paginationParams)),
        lookupMenuSortOrder: sortParams => dispatch(menuActions.sortOrder(sortParams)),
        lookupMenuApplyFilter: filterParams => {
            dispatch(menuActions.setType(null));
            dispatch(menuActions.applyFilter(filterParams))
        },
        lookupMenuLoad: () => {
            const baseFilters = [{
                tipoFiltro : 'IGUAL_A',
                campo : 'active',
                valor : true
            }]
            dispatch(menuActions.applyBaseFilters(baseFilters))
            dispatch(menuActions.load())
        },
        setMenu: (menu) => {
            dispatch(actions.setField('oidMenu', menu != null ? menu.oid : null));
            dispatch(actions.setField('nomeMenu', menu != null ? menu.nome : ''));
        },
        setField: (field, value) => dispatch(actions.setField(field, value)),
        lookupPerfilSetVisible: visible => dispatch(actions.setLookupPerfilVisible(visible)),
        lookupPerfilPaginate: paginationParams => dispatch(perfilActions.paginate(paginationParams)),
        lookupPerfilSortOrder: sortParams => dispatch(perfilActions.sortOrder(sortParams)),
        lookupPerfilApplyFilter: filterParams => {
            dispatch(perfilActions.setType(null));
            dispatch(perfilActions.applyFilter(filterParams))
        },
        lookupPerfilLoad: () => dispatch(perfilActions.load()),
        setPerfil: (perfil) => {
            dispatch(actions.setField('oidPerfil', perfil != null ? perfil.oid : null));
            dispatch(actions.setField('nomePerfil', perfil != null ? perfil.nome : ''));
        },
        changeHandler: event => {
            const target = event.target;
            const field = target.name;
            let value = target.value;
            dispatch(actions.removeErrors([{ field }]));

            dispatch(actions.setField(field, value));
        },
        saveHandler: event => {
            dispatch(actions.save());
        },
        removeHandler: event => {
            dispatch(actions.remove());
        },
        newHandler: event => {
            dispatch(actions.makeNew());
        },
        loadById: oid => dispatch(actions.loadById(oid))
    }
}

class PermissaoFormPageContainer extends React.Component {

    constructor(props) {
        super(props);
        actions.afterSave = ({ oid }) => {
            this.props.history.replace(`${routes.PATH_PERMISSAO}/${oid}`);
        };
        actions.afterNew = () => {
            this.props.history.replace(`${routes.PATH_PERMISSAO}/novo`);
        };
        actions.afterRemove = () => {
            this.props.history.replace(`${routes.PATH_PERMISSAO}`);
        };
    }

    componentDidMount() {
        const oid = this.props.match.params.id;

        if (oid && parseInt(oid)) {
            this.props.loadById(oid);
        } else {
            this.props.newHandler();
        }
    }
    searchHandler = () => {
        this.props.history.push(`${routes.PATH_PERMISSAO}`)
    }


    lookupMenuOnPage = (e) => {
        this.props.lookupMenuPaginate({
            max: e.rows,
            index: e.first
        })
    }
    lookupMenuOnSort = (e) => {
        this.props.lookupMenuSortOrder({
            sortOrder: e.sortOrder,
            sortField: e.sortField
        })
    }
    lookupMenuOnFilter = (e) => {
        const filters = [];
        const keys = Object.keys(e.filters) || [];
        keys.forEach(key => {
            filters.push({
                campo: key,
                valor: e.filters[key].value,
                tipoFiltro: 'CONTEM'

            })
        })
        this.props.lookupMenuApplyFilter(filters)

    }
    lookupMenuOnClick = (e) => {
        this.props.lookupMenuLoad();
        this.props.lookupMenuSetVisible(true);
    }
    lookupMenuOnHide = (e) => {
        this.props.lookupMenuSetVisible(false);
    }
    lookupMenuOnConfirm = (e) => {
        this.props.setMenu(e.selection);
        this.props.lookupMenuSetVisible(false);

    }
    lookupMenuOnCancel = (e) => {
        this.props.setMenu(null);
        this.props.lookupMenuSetVisible(false);
    }

    lookupMenuOnComplete = (e) => {

    }


    lookupMenuOnInputFilter = (e) => {
        const value = e.value;
        if (typeof value === 'string') {
            const filters = [{
                campo: "nome",
                valor: e.value,
                tipoFiltro: 'CONTEM'

            }];

            this.props.setField("nomeMenu", e.value);
            this.props.setField("oidMenu", null);
            this.props.lookupMenuApplyFilter(filters)
        } else {
            this.props.setMenu(value);
        }

    }

    lookupMenuOnEdit = () => {
        if (this.props.record.oidMenu) {
            this.props.history.push(`${routes.PATH_MENU}/${this.props.record.oidMenu}`);
        }
    }



    lookupPerfilOnPage = (e) => {
        this.props.lookupPerfilPaginate({
            max: e.rows,
            index: e.first
        })
    }
    lookupPerfilOnSort = (e) => {
        this.props.lookupPerfilSortOrder({
            sortOrder: e.sortOrder,
            sortField: e.sortField
        })


    }
    lookupPerfilOnFilter = (e) => {
        const filters = [];
        const keys = Object.keys(e.filters) || [];
        keys.forEach(key => {
            filters.push({
                campo: key,
                valor: e.filters[key].value,
                tipoFiltro: 'CONTEM'

            })
        })
        this.props.lookupPerfilApplyFilter(filters)

    }
    lookupPerfilOnClick = (e) => {
        this.props.lookupPerfilLoad();
        this.props.lookupPerfilSetVisible(true);
    }
    lookupPerfilOnHide = (e) => {
        this.props.lookupPerfilSetVisible(false);
    }
    lookupPerfilOnConfirm = (e) => {
        this.props.setPerfil(e.selection);
        this.props.lookupPerfilSetVisible(false);

    }
    lookupPerfilOnCancel = (e) => {
        this.props.setPerfil(null);
        this.props.lookupPerfilSetVisible(false);
    }

    lookupPerfilOnInputFilter = (e) => {
        const value = e.value;
        if (typeof value === 'string') {
            const filters = [{
                campo: "nome",
                valor: e.value,
                tipoFiltro: 'CONTEM'

            }];
            this.props.setField("nomePerfil", e.value);
            this.props.setField("oidPerfil", null);
            this.props.lookupPerfilApplyFilter(filters)
        } else {
            this.props.setPerfil(value);
        }
    }

    lookupPerfilOnEdit = () => {
        if (this.props.record.oidPerfil) {
            this.props.history.push(`${routes.PATH_PERFIL}/${this.props.record.oidPerfil}`);
        }
    }

    lookupPerfilOnComplete = (e) => {

    }


    render() {
        const content = (
            <PermissaoForm
                changeHandler={this.props.changeHandler}
                record={this.props.record}
                lookupMenu={this.props.lookupMenu}
                lookupMenuOnHide={this.lookupMenuOnHide}
                lookupMenuOnConfirm={this.lookupMenuOnConfirm}
                lookupMenuOnCancel={this.lookupMenuOnCancel}
                lookupMenuOnPage={this.lookupMenuOnPage}
                lookupMenuOnSort={this.lookupMenuOnSort}
                lookupMenuOnFilter={this.lookupMenuOnFilter}
                lookupMenuOnClick={this.lookupMenuOnClick}
                lookupMenuOnEdit={this.lookupMenuOnEdit}
                lookupMenuOnComplete={this.lookupMenuOnComplete}
                lookupMenuOnInputFilter={this.lookupMenuOnInputFilter}

                lookupPerfil={this.props.lookupPerfil}
                lookupPerfilOnHide={this.lookupPerfilOnHide}
                lookupPerfilOnConfirm={this.lookupPerfilOnConfirm}
                lookupPerfilOnCancel={this.lookupPerfilOnCancel}
                lookupPerfilOnPage={this.lookupPerfilOnPage}
                lookupPerfilOnSort={this.lookupPerfilOnSort}
                lookupPerfilOnFilter={this.lookupPerfilOnFilter}
                lookupPerfilOnClick={this.lookupPerfilOnClick}
                lookupPerfilOnEdit={this.lookupPerfilOnEdit}
                lookupPerfilOnComplete={this.lookupPerfilOnComplete}
                lookupPerfilOnInputFilter={this.lookupPerfilOnInputFilter}

            />
        );
        const title = `Permissão ${this.props.record.oid || 'nova'}`
        const header = (
            <div>
                <h1>{title}</h1>
                <hr className="lxSplitter" ></hr>
                <div>
                    <Toolbar
                        onSave={this.props.saveHandler} saveButtonStyle='highlighted'
                        onRemove={this.props.removeHandler} removeButtonStyle='danger'
                        onNew={this.props.newHandler}
                        onSearch={this.searchHandler}>
                    </Toolbar>
                </div>
            </div>
        );
        return (<Page header={header} content={content} />);
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PermissaoFormPageContainer);