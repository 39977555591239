import React,{useState, useEffect} from "react";
import { DataTable } from 'primereact/datatable';
import { Dropdown } from "primereact/dropdown";
import ModalInsertComponent from "./ModalInsertComponent";
import './CalculatedComponent.scss';
import { ButtonDialog } from "../../../../components/button-dialog/ButtonDialog";


const CalculatedValue = (props) => {
   
    const [selectedRows, setSelectedRows] = useState([])
    const [showModal,setShowModal] = useState(false)
    const [description,setDescription] = useState("")

    const onChangeSelectedRows = (event) =>{
        event.originalEvent.stopPropagation();
        event.originalEvent.preventDefault();
       setSelectedRows([...event.value])
    }

    const onAddCompositionHandler = (e)=>{
        setShowModal(true);
    }

    const onRemoveCompositionHandler = (e)=>{
        props.actions.onRemoveCompositionHandler && props.actions.onRemoveCompositionHandler(e,selectedRows);
        setSelectedRows([]);
    }

    const disableBtn = selectedRows && selectedRows.length > 0
    return (
        <div className="p-col-12">
            {/* criar a operação */}
            <div className="p-grid">
                <div className="p-col-4 form-field-container">
                    <label>Operação</label>
                    <Dropdown
                        style={{ width: "auto" }}
                        className="lxCalculatedValue"
                        value={props.component.operation}
                        placeholder="Selecione a operação"
                        options={props.listOperations}
                        onChange={(e) =>{props.actions.onChangeOperationHandler(e)}}
                    ></Dropdown>
                </div>

                <div className="p-col-12 calculated-component">

                    <div className="p-grid">
                        <div className="p-col-3">
                            <label>Composição</label>
                        </div>
                        <div className="p-col-9 btn-actions">
                            <button
                                name="addCompositionByComponent"
                                style={{ marginRight: ".25em", marginBottom: ".25em" }}
                                className="lognex-btn-highlighted"
                                disabled={!props.component.operation}
                                onClick={onAddCompositionHandler}
                            >
                                <i className="pi pi-plus"></i>
                            </button>
                            <ButtonDialog
                                onClick={onRemoveCompositionHandler}
                                button={{
                                    disabled: !disableBtn,
                                    title: "Remover",
                                    className: "lognex-btn-danger"
                                }}
                                dialog={{
                                    header: 'Confirmação',
                                    modal: false,
                                    message: `Deseja realmente remover o composição?`,
                                }}
                            ></ButtonDialog>
                        </div>
                    </div>
                </div>
                {/* criar a lista de composição */}
                <div className="p-col-12 form-field-container">
                    <DataTable
                        selectionMode="multiple"
                        className="lxCalculatedValue"
                        emptyMessage="Não foram encontrados registros"
                        dataKey="label"
                        value={props.component.composition}
                        selection={selectedRows}
                        metaKeySelection={false}
                        onSelectionChange={onChangeSelectedRows}
                    >
                        {props.calculatedComponent.columnsComposition}
                    </DataTable>

                </div>


            </div>

            {/* criar a modal */}
            {showModal && (
                <ModalInsertComponent
                    title="Inserir Componente"
                    visible={showModal}
                    description={props.calculatedComponent.descriptionComponent}
                    onClick={() => {
                        props.actions.onAddComposition(description)
                        setShowModal(false)
                    }}
                    onChange={(e) => {setDescription(e.target.value)}}
                    onHide={() => setShowModal(false)}
                ></ModalInsertComponent>
            )}

        </div>
    )
}

export default CalculatedValue;