import { validate } from './validations';
import { build } from '../../services/crudFactory';
import { Action } from '../../services/actions.factory';
import api from '../../services/api.service';

const BASE_PATH = '/lognex/importFiles';
const crud = build(validate, BASE_PATH);
const prefix = 'crudImportFiles'

const newTypes = {
    //LOAD_IMPORTS: `${prefix}load`,
    SET_DIALOG_LOG_RECORD_VISIBLE: `${prefix}setDialogLogRecordVisible`,
    SET_CURRENT_LOG_RECORD: `${prefix}setCurrentLogRecord`,
    SET_FIELD_LOG_FILTER: `${prefix}setFieldLogFilter`,
}


const actions = new Action(prefix, crud);

const types = Object.assign({}, actions.types, newTypes);

actions.loadRecords = (params, dispatch) => {
    api.post('/api/v1/lognex/logs/resources/all', params)
        .then(({ data }) => {
            dispatch(actions.fetchRecordsSuccess(data))
        })
        .catch(err => {
            dispatch(actions.fetchRecordsError(err));
        })

}

actions.load = () => {
    return (dispatch, getState) => {
        const state = getState()[`${prefix}State`];
        dispatch(actions.fetchRecords());
        let sortFields = [];
        if (state.sortField) {
            sortFields = [
                {
                    fieldName: state.sortField,
                    order: state.sortOrder > -1 ? `ASC` : `DESC`
                }
            ];
        }

        
        const params = {
            index: state.index,
            max: state.max,
            filters: state.filters,
            sortFields, 
            
        };
        actions.loadRecords(params, dispatch);
    };
}

actions.setDialogLogRecordVisible = (visible) => {
    return {
        type: types.SET_DIALOG_LOG_RECORD_VISIBLE,
        visible
    }
}

actions.setCurrentLogRecord = logRecord => {
    return {
        type: types.SET_CURRENT_LOG_RECORD,
        logRecord
    }
}

actions.setFieldLogFilter = (fieldName, fieldValue) => {
    return {
        type: types.SET_FIELD_LOG_FILTER,
        fieldName,
        fieldValue,
    }
}

export { types, actions };