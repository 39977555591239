import React from 'react';
import { InputText } from 'primereact/inputtext';
import { InputCep } from '../../../components/input-cep/InputCep';


export const ZipCodeRangeForm = (props) => {
    const ranges = props.record.zipCodeRanges && props.record.zipCodeRanges.length > 0 ? props.record.zipCodeRanges[0] : {start:"", end:""}
    let {start="", end=""} = ranges

    return (
        <div className="zipCodeRange-form">
            <div className="p-grid">
                <div className="p-col-6">
                    <div className="p-grid">

                        <div className="p-col-12 form-field-container">
                            <label>Código</label>
                            <InputText value={props.record.oid} onChange={props.changeHandler} name="oid" disabled={true} />
                        </div>
                        <div className="p-col-12 form-field-container">
                            <label className="lxRequiredField">Nome</label>
                            <InputText value={props.record.name} onChange={props.changeHandler} name="name" disabled={false} />
                        </div>
                        <div className="p-col-6 form-field-container">
                            <label className="lxRequiredField">CEP Inicial</label>
                            <InputCep value={start.toString()} onChange={props.changeHandler} name="start" disabled={false} />
                        </div>
                        <div className="p-col-6 form-field-container">
                            <label className="lxRequiredField">CEP Final</label>
                            <InputCep value={end.toString()} onChange={props.changeHandler} name="end" disabled={false} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};