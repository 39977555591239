import React from 'react';
import ClientListPageContainer from './ClientListPageContainer';

export class ClientListPage extends React.Component{
    
    render(){
      return (
        <ClientListPageContainer {...this.props}/>
      )
    }
}