
import { types } from "./route.actions";
import { Reducer } from "../../services/reducer.factory";

const initialState = {
  columns: [{
    field: "oid",
    header: "Código",
    columnKey: "oid",
    sortable: true,
    filter: true,
    style: { width: '20%' }
  }, {
    field: "name",
    header: "Nome",
    columnKey: "name",
    sortable: true,
    filter: true
  }, {
    field: "carrierName",
    header: "Transportadora",
    columnKey: "carrierName",
    sortable: true,
    filter: true
  }, {
    field: "originName",
    header: "Origem",
    columnKey: "originName",
    sortable: true,
    filter: true
  }, {
    field: "destinationName",
    header: "Destino",
    columnKey: "destinationName",
    sortable: true,
    filter: true
  }],
  currentRecord: { 
    oid: null, 
    name: "", 
    oidCarrier: null, 
    carrierName: "", 
    oidOrigin: null, 
    originName: "", 
    oidDestination: null, 
    destinationName: "" ,
    onlyRedispatch:false
  },
  lookupCarrier: {
    visible: false,
    modal: true,
    header: "Transportadora"
  }, lookupOrigin: {
    visible: false,
    modal: true,
    header: "Origem"
  }, lookupDestination: {
    visible: false,
    modal: true,
    header: "Destino"
  }
};

const reducer = new Reducer(initialState, types);

export function CRUDRouteReducer(state, action) {
  state = reducer.execute(state, action);
  return execute(state, action);
}

function execute(state, action) {
  let result = state;
  switch (action.type) {
    case types.SET_LOOKUP_CARRIER_VISIBLE:
      return setLookupCarrierVisible(state, action);
    case types.SET_LOOKUP_ORIGIN_VISIBLE:
      return setLookupOriginVisible(state, action);
    case types.SET_LOOKUP_DESTINATION_VISIBLE:
      return setLookupDestinationVisible(state, action);
    default:
      return result;
  }
}


function setLookupCarrierVisible(state, action) {
  let lookupCarrier = state.lookupCarrier;
  lookupCarrier = Object.assign({}, lookupCarrier, { visible: action.visible });
  return Object.assign({}, state, { lookupCarrier })
}
function setLookupOriginVisible(state, action) {
  let lookupOrigin = state.lookupOrigin;
  lookupOrigin = Object.assign({}, lookupOrigin, { visible: action.visible });
  return Object.assign({}, state, { lookupOrigin })
}
function setLookupDestinationVisible(state, action) {
  let lookupDestination = state.lookupDestination;
  lookupDestination = Object.assign({}, lookupDestination, { visible: action.visible });
  return Object.assign({}, state, { lookupDestination })
}

