import React from "react";
import { Dialog } from 'primereact/dialog';

export const FileUploudScreen = (props) => {
    return (
        <React.Fragment>
            <Dialog
                className="lxLookupDialog"
                header={props.header ? props.header : "Enviar comprovante de entrega"}
                visible={props.visible} 
                modal={false}
                onHide={props.onHide}
              
            >

                {props.children}

            </Dialog>
        </React.Fragment>
    )
}