import React from "react";
import { DataTable } from "primereact/datatable";

export const OrderCargoVolumeList = (props) => (
  
  <DataTable
    selectionMode="single"
    // onFilter={props.onFilter} 
    // onSort={props.onSort}
    // onPage={props.onPage}
    value={props.records}  
    // totalRecords={props.totalRecords}
    // sortOrder={props.order}
    // sortField={props.sortField}
    paginator={false}
    // first={props.first}
    // rows={props.rows}
    scrollHeight={"300px"}
    scrollable={true}
    emptyMessage="Não foram encontrados registros" 
    // rowsPerPageOptions={[5, 10, 20, 50]}
  >
    {props.columns}
  </DataTable>
);
