import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Toolbar from "../../../components/toolbar/Toolbar";
import { Column } from "primereact/column";
import { routes } from "../../../constants";
import { Page } from "../../../components/page/Page"; 
import { actions } from "../tower_equipment_type.actions";
import { AppService, COMPANY_CHANGE_EVENT_NAME, BUSINESS_UNIT_CHANGE_EVENT_NAME } from "../../../services/app.service";
import moment from "moment";
import { TowerEquipmentTypeList } from "./TowerEquipmentTypeList";

const mapStateToProps = (state) => {
  const records= state.crudTowerEquipmentTypeState.records
  return {
    columns: state.crudTowerEquipmentTypeState.columns,
    records: records,
    first: state.crudTowerEquipmentTypeState.index,
    rows: state.crudTowerEquipmentTypeState.max,
    totalRecords: state.crudTowerEquipmentTypeState.count,
    sortField: state.crudTowerEquipmentTypeState.sortField,
    order: state.crudTowerEquipmentTypeState.sortOrder,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    paginate: (paginationParams) => dispatch(actions.paginate(paginationParams)),
    sortOrder: (sortParams) => dispatch(actions.sortOrder(sortParams)),
    applyFilter: (filterParams) => {
      dispatch(actions.setType(null));
      dispatch(actions.applyFilter(filterParams));
    },
    load: () => {
      dispatch(actions.loadAll());
    },
    loadAll: () => {
      dispatch(actions.loadAll());
    },
    setSelectedRecords: (selectedRecords) => {
      dispatch(actions.setSelectedRecords(selectedRecords));
    },
  };
};

class TowerEquipmentTypeListPageContainer extends React.Component {
  newHandler = () => {
    this.props.history.push(`${routes.PATH_TOWER_EQUIPMENT_TYPE}/novo`);
  };

  componentDidMount() {
    this.props.load();
    AppService.on(COMPANY_CHANGE_EVENT_NAME, this);
    AppService.on(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
  }
  componentWillUnmount() {
    AppService.off(COMPANY_CHANGE_EVENT_NAME, this);
    AppService.off(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
  }

  onReceiveAppNotifications = (eventName, params) => {
    if (eventName === COMPANY_CHANGE_EVENT_NAME || eventName === BUSINESS_UNIT_CHANGE_EVENT_NAME) {
      this.props.load();
    }
  };
  onPage = (e) => {
    this.props.paginate({
      max: e.rows,
      index: e.first,
    });
  };
  onSort = (e) => {
    this.props.sortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField,
    });
  };

  onFilter = (e) => {
    const filters = [];
    const keys = Object.keys(e.filters) || [];
    keys.forEach((key) => {
      filters.push({
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: "CONTEM",
      });
    });
    this.props.applyFilter(filters);
  };
 

  render() {
    const header = (
      <div>
        <h1>Tipo Equipamento</h1>
        <hr className="lxSplitter"></hr>
        <div>
          <Toolbar onNew={this.newHandler} newButtonStyle="highlighted"></Toolbar>
        </div>
      </div>
    );

    const booleanTemplate = (rowData, column) => {
      let value = rowData[column.columnKey];
      if (typeof rowData[column.columnKey] === "string") {
        value = rowData[column.columnKey].toUpperCase() === "TRUE";
      }

      value = value ? "SIM" : "NÃO";
      return <div style={{ textAlign: "center" }}>{value}</div>;
    };


    const dateTemplate = (rowData, column) => {
      const date = rowData[column.field];
      const formattedDate = moment(date).format('DD/MM/YYYY hh:mm')
      return <div style={{ textAlign: 'center', color: 'black' }}>{formattedDate}</div>
    };

    const columns = this.props.columns.map((col) => {
      

      if (col.field === "eventRecord.createdAt") {
        return <Column {...col} key={col.field} body={dateTemplate} />;
      }
      

      return <Column {...col} key={col.field} />;
    });

    const content = (
      <TowerEquipmentTypeList
        {...this.props} 
        onFilter={this.onFilter}
        onPage={this.onPage}
        onSort={this.onSort}
        columns={columns}
      />
    );
    return <Page header={header} content={content} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TowerEquipmentTypeListPageContainer);
