    import { types } from "./modal.actions";
    import { Reducer } from "../../services/reducer.factory";
    
    const initialState = {
      columns: [{
        field: "oid",
        header: "ID",
        columnKey: "oid",
        sortable: "false",
        filter: "false"
      },{
        field: "code",
        header: "Código",
        columnKey: "code",
        sortable: "false",
        filter: "false"
      },{
        field: "name",
        header: "Nome",
        columnKey: "name",
        sortable: "false",
        filter: "false"
      },{
        field: "transportType",
        header: "Tipo transporte",
        columnKey: "transportType",
        sortable: "false",
        filter: "false"
      },{
        field: "freightTableType",
        header: "Tipo tabela de frete",
        columnKey: "freightTableType",
        sortable: "false",
        filter: "false"
      },{
        field: "pickupType",
        header: "Tipo coleta",
        columnKey: "pickupType",
        sortable: "false",
        filter: "false"
      },{
        field: "deliveryType",
        header: "Tipo entrega",
        columnKey: "deliveryType",
        sortable: "false",
        filter: "false"
      }],
      currentRecord: {oid:"",code:"",name:"",transportType:null,freightTableType:null,pickupType:null,deliveryType:null,serviceId:"",serviceCode:"",serviceDescription:""},
      
    };
    
    const reducer = new Reducer(initialState, types);
    
    export function CRUDModalReducer(state, action) {
      state = reducer.execute(state, action);
      return execute(state,action);
    }
    
    function execute(state, action){
        let result = state;
        switch(action.type){
          
          
        }
        return result;
    }
   
    
    
    