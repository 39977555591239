import React from 'react'
import { Dialog } from 'primereact/dialog';
import './Wizard.scss';


export const Wizard = (props) => {
    return (
        <div className="p-col-12">
            <Dialog
                className="lxStepDialog"
                selectionMode={props.selectionMode}
                header={props.header}
                visible={props.visible}
                modal={props.modal}
                onHide={props.onHide}
            >
                {props.children[props.currentIndex]}
            </Dialog>
        </div>
    );
}