import { validate } from './validations';
import { build } from '../../services/crudFactory';
import { Action } from '../../services/actions.factory';
const BASE_PATH = '/lognex/holiday';
const prefix = 'crudHoliday';
const crud = build(validate, BASE_PATH);



const newTypes = {    
    SET_ONCHANGE_HOLIDAY: `${prefix}onChangeHOLIDAY`
}


const actions = new Action(prefix, crud);

const types = Object.assign({}, actions.types, newTypes);


actions.onChangeHOLIDAY = (holiday) => {
    return {
        type: types.SET_ONCHANGE_HOLIDAY,
        holiday
    }
}


export { types, actions }

