
    import React from 'react';
    import LocationListPageContainer from './LocationListPageContainer';
    
    export class LocationListPage extends React.Component{
        
        render(){
          return (
            <LocationListPageContainer {...this.props}/>
          )
        }
    }