import React from 'react';
import { connect } from 'react-redux';
import Toolbar from "../../../components/toolbar/Toolbar";
import { Column } from 'primereact/column';
import { routes } from '../../../constants';
import { Page } from "../../../components/page/Page";
import { actions } from '../../occurrenceType/occurrenceType.actions';
import { OccurrenceTypeForm } from './OccurrenceTypeForm'

function getGroupType(groupType){
    let value = "";
    switch(groupType){
      case 'COMPANY':
        value = "EMPRESA";
        break;
        case 'SYSTEM':
        value = "SISTEMA";
        break;
    }
   return value;
  }
  

const mapStateToProps = state => {
    const record = state.crudOccurrenceTypeState.currentRecord;
    const currentCompany = state.appState.currentCompany;
     console.log("mapStateToProps = ",record)

    let groupType = getGroupType(record.groupType);

    return {
        groupType,
        record,
        currentCompany
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setField: (field, value) => dispatch(actions.setField(field, value)),
        newHandler: event => {
            dispatch(actions.makeNew());
        },

        setField: (field, value) => dispatch(actions.setField(field, value)),
        changeHandler: event => {
            const target = event.target;
            const field = target.name;
            let value = target.value;
            dispatch(actions.removeErrors([{ field }]));
            dispatch(actions.setField(field, value));
        },


        saveHandler: event => {
            dispatch(actions.save());
        },
        removeHandler: event => {
            dispatch(actions.remove());
        },
        newHandler: event => {
            dispatch(actions.makeNew());

        },
        loadById: oid => dispatch(actions.loadById(oid))
    }
}

class OccurrenceTypeFormPageContainer extends React.Component {

    constructor(props) {
        super(props);
        actions.afterSave = ({ oid }) => {
            this.props.history.replace(`${routes.PATH_OCCURRENCETYPE}/${oid}`);
        };
        actions.afterNew = () => {
            this.props.history.replace(`${routes.PATH_OCCURRENCETYPE}/novo`);
        };
        actions.afterRemove = () => {
            this.props.history.replace(`${routes.PATH_OCCURRENCETYPE}`);
        };
    }

    componentDidMount() {
        const oid = this.props.match.params.id;

        if (oid && parseInt(oid)) {
            this.props.loadById(oid);
        } else {
            this.props.newHandler();
        }
    }
    searchHandler = () => {
        this.props.history.push(`${routes.PATH_OCCURRENCETYPE}`)
    }


    render() {
        const content = (
            <OccurrenceTypeForm
                changeHandler={this.props.changeHandler}
                record={this.props.record}
                types={this.props.types}
                groupType={this.props.groupType}
            />
        );
        const title = `Tipo de Ocorrência ${this.props.record.oid || 'nova'}`
        const header = (
            <div>
                <h1>{title}</h1>
                <hr className="lxSplitter" ></hr>
                <div>
                    <Toolbar
                        onSave={this.props.saveHandler} saveButtonStyle='highlighted'
                        onRemove={this.props.removeHandler} removeButtonStyle='danger'
                        onNew={this.props.newHandler}
                        onSearch={this.searchHandler}>
                    </Toolbar>
                </div>
            </div>
        );
        return (<Page header={header} content={content} />);
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OccurrenceTypeFormPageContainer);