import React from "react";
import { Link } from 'react-router-dom';
import { connect } from "react-redux";

import Toolbar from "../../../components/toolbar/Toolbar";
import apiService from "../../../services/api.service";
import { Page } from "../../../components/page/Page";
import { Column } from 'primereact/column';
import { routes } from '../../../constants';
import { HolidayList } from "./Holiday";
import { AppService, COMPANY_CHANGE_EVENT_NAME, BUSINESS_UNIT_CHANGE_EVENT_NAME } from "../../../services/app.service";
import './HolidayListPageContainer.scss';
import { actions } from '../holiday.actions'
import moment from "moment";

const mapStateToProps = state => {

    return {
        columns: state.crudHolidayState.columns,
        records: state.crudHolidayState.records,
        first: state.crudHolidayState.index,
        rows: state.crudHolidayState.max,    
        totalRecords: state.crudHolidayState.count,
        sortField: state.crudHolidayState.sortField,
        order: state.crudHolidayState.sortOrder,
    }
}

const mapDispatchToProps = dispatch => {

    return {
        paginate: paginationParams => {            
            dispatch(actions.paginate(paginationParams))
        },

        sortOrder: sortParams => dispatch(actions.sortOrder(sortParams)),
        
        applyFilter: filterParams => {
            dispatch(actions.setType(null));
            dispatch(actions.applyFilter(filterParams))
        },
        
        load: () => dispatch(actions.load()),
    }
}

class HolidayListPageContainer extends React.Component {

    newHandler = () => {
        this.props.history.push(`${routes.PATH_HOLIDAY}/novo`)
    }

    componentWillReceiveProps(newProps) {
        if ((!this.props.menuItems || this.props.menuItems.length === 0) && newProps.menuItems && newProps.menuItems.length > 0) {
            const menuPath = this.props.history.location.pathname;
        }
    }

    componentDidMount() {
        this.props.load();
        AppService.on(COMPANY_CHANGE_EVENT_NAME, this);
        AppService.on(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
    }
    
    componentWillUnmount() {
        AppService.off(COMPANY_CHANGE_EVENT_NAME, this);
        AppService.off(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
    }

    onReceiveAppNotifications = (eventName, params) => {
        if (eventName === COMPANY_CHANGE_EVENT_NAME || eventName === BUSINESS_UNIT_CHANGE_EVENT_NAME) {
            this.props.load();
        }
    }

    onPage = (e) => {
        this.props.paginate({
            max: e.rows,
            index: e.first
        })
    }
    onSort = (e) => {        
        this.props.sortOrder({
            sortOrder: e.sortOrder,
            sortField: e.sortField
        })
    }
    
    onFilter = (e) => {                
        const filters = [];
        const keys = Object.keys(e.filters) || [];
        keys.forEach(key => {
            if (key == "oid" || key == "deadline.value") {
                filters.push({
                    campo: key,
                    valor: parseInt(e.filters[key].value),
                    tipoFiltro: 'IGUAL_A'
                })
            } else {
                let field = key; 
                filters.push({
                    campo: field,
                    valor: e.filters[key].value,
                    tipoFiltro: 'CONTEM'
                })
            }
        })
        this.props.applyFilter(filters)
    }

    onRowClick =(e) => {
        const oid = e && e.data && e.data.oid
        if(oid){
          this.props.history.push(`${routes.PATH_HOLIDAY}/${oid}`)
        }
      }

    render() {
        const header = (
            <div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <h1>Feriados</h1>
                </div>
                <hr className="lxSplitter" ></hr>
                <div>
                    <Toolbar onNew={this.newHandler} newButtonStyle='highlighted'></Toolbar >
                </div>
            </div>
        );

        const oidTemplate = (rowData, column) => {
            return (
                <div style={{ textAlign: "center" }} className="id-link"><Link to={`${routes.PATH_HOLIDAY}/${rowData.oid}`}>{rowData.oid}</Link></div>
            )
        }
        const dateTemplate = (rowData, column) => {
            const date = rowData[column.field];
            const formattedDate = moment(date).format('DD/MM/YYYY')
            return <div style={{ textAlign: 'center', color: 'black' }}>{formattedDate}</div>
          };

        const columns = this.props.columns.map(col => {
            if (col.field === 'oid') {
                return (<Column {...col} key={col.field} body={oidTemplate} style={{ width: '10%' }} />);
            }
            if (col.field === 'date') {
                return (<Column {...col} key={col.field} body={dateTemplate} style={{ width: '10%' }} />);
            }
            return (<Column {...col} key={col.field} />)
        })

        const content = (
            <div>
                <HolidayList  {...this.props} onFilter={this.onFilter} onPage={this.onPage} onSort={this.onSort} columns={columns} onRowClick={this.onRowClick}/>
            </div>
        );
        return <Page header={header} content={content} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HolidayListPageContainer);
