import React from 'react';
import {InputText} from 'primereact/inputtext';
import { LookupField } from '../../../components/lookup/LookupField';
import { InputSwitch } from 'primereact/inputswitch';

export const PermissaoForm = (props)=>{
    const record = props.record;
    const changeHandler = props.changeHandler;
return(
    <div className="pessoa-form">
        <div className="p-grid">
            <div className="p-col-6">
                <div className="p-grid">
                    <div className="p-col-3">
                        <div className="form-field-container">
                            <label>Código</label>
                            <InputText value={record.oid}  onChange={changeHandler} disabled={true} />
                        </div>
                        
                    </div>
                    <div className="p-col-12 form-field-container">
                        <label className="lxRequiredField">Perfil</label>
                            <LookupField
                                value={record.nomePerfil}
                                placeholder="Perfil"
                                name="nomePerfil"
                                field="nome"
                                onComplete={props.lookupPerfilOnComplete}
                                onInputFilter={props.lookupPerfilOnInputFilter}
                                editDisabled={props.lookupPerfil.editDisabled}
                                onEditField={props.lookupPerfilOnEdit}
                                onClick= {props.lookupPerfilOnClick}

                                visible={props.lookupPerfil.visible}
                                modal={props.lookupPerfil.modal}
                                header={props.lookupPerfil.header}
                                onHide={props.lookupPerfilOnHide}
                                onConfirm={props.lookupPerfilOnConfirm}
                                onCancel={props.lookupPerfilOnCancel}
                                records={props.lookupPerfil.records}
                                columns = {props.lookupPerfil.columns}
                                totalRecords = {props.lookupPerfil.totalRecords}
                                sortOrder= {props.lookupPerfil.order}
                                sortField = {props.lookupPerfil.sortField}
                                first= {props.lookupPerfil.first}
                                rows={props.lookupPerfil.rows}
                                onPage={props.lookupPerfilOnPage}
                                onFilter={props.lookupPerfilOnFilter}
                                onSort={props.lookupPerfilOnOnSort}

                            />
                            
                    </div>
                    <div className="p-col-12 form-field-container">
                        <label className="lxRequiredField">Menu</label>
                            <LookupField
                                value={record.nomeMenu}
                                placeholder="Menu"
                                name="nomeMenu"
                                field="nome"
                                onComplete={props.lookupMenuOnComplete}
                                onInputFilter={props.lookupMenuOnInputFilter}
                                editDisabled={props.lookupMenu.editDisabled}
                                onEditField={props.lookupMenuOnEdit}
                                onClick= {props.lookupMenuOnClick}

                                visible={props.lookupMenu.visible}
                                modal={props.lookupMenu.modal}
                                header={props.lookupMenu.header}
                                onHide={props.lookupMenuOnHide}
                                onConfirm={props.lookupMenuOnConfirm}
                                onCancel={props.lookupMenuOnCancel}
                                records={props.lookupMenu.records}
                                columns = {props.lookupMenu.columns}
                                totalRecords = {props.lookupMenu.totalRecords}
                                sortOrder= {props.lookupMenu.order}
                                sortField = {props.lookupMenu.sortField}
                                first= {props.lookupMenu.first}
                                rows={props.lookupMenu.rows}
                                onPage={props.lookupMenuOnPage}
                                onFilter={props.lookupMenuOnFilter}
                                onSort={props.lookupMenuOnOnSort}

                            />
                            
                    </div>
                    
                    {/* <div className="p-col-12 form-field-container"> 
                        <label>Listar</label>
                        <InputSwitch checked={record.listar}   onChange={changeHandler}  name="listar" />
                    </div>
                    <div className="p-col-12 form-field-container"> 
                        <label>Inserir</label>
                        <InputSwitch checked={record.inserir}   onChange={changeHandler}  name="inserir" />
                    </div>
                    <div className="p-col-12 form-field-container"> 
                        <label>Alterar</label>
                        <InputSwitch checked={record.alterar}   onChange={changeHandler}  name="alterar" />
                    </div>
                    <div className="p-col-12 form-field-container"> 
                        <label>Remover</label>
                        <InputSwitch checked={record.excluir}   onChange={changeHandler}  name="excluir" />
                    </div> */}

                    
                    </div>
                </div>
            </div>
        </div>

 
)
};