import React from "react";
import { InputTextarea } from "primereact/inputtextarea";
import { InputText } from "primereact/inputtext";
import { InputSwitch } from 'primereact/inputswitch';
import './FreightComponentForm.scss'

export const FreightComponentForm = props => {
  const items = [];
  if (props.record && props.record.presetList) {
    const keys = Object.keys(props.record.presetList);
    keys.forEach(el => {
      items.push({
        key: el,
        value: props.record.presetList[el]
      });
    });

  }
  const hasWeight = props.record.rangeTypes.find(rtype => rtype === 'Weight') != null
  const hasExcessWeight = props.record.rangeTypes.find(rtype => rtype === 'ExcessWeight') != null
  const hasProductValue = props.record.rangeTypes.find(rtype => rtype === 'ProductValue') != null
  const hasVolumeQuantity = props.record.rangeTypes.find(rtype => rtype === 'VolumeQuantity') != null
  const hasExcessVolumeQuantity = props.record.rangeTypes.find(rtype => rtype === 'ExcessVolumeQuantity') != null
  const hasQuantityItems = props.record.rangeTypes.find(rtype => rtype === 'QuantityItems') != null
  const hasCostFreight = props.record.rangeTypes.find(rtype => rtype === 'CostFreight') != null
  const hasKilometer = props.record.rangeTypes.find(rtype => rtype === 'Kilometer') != null
  const hasExcessKilometer = props.record.rangeTypes.find(rtype => rtype === 'ExcessKilometer') != null
  const hasKilometerDriven = props.record.rangeTypes.find(rtype => rtype === 'KilometerDriven') != null
  const collectionDelivery = props.record.rangeTypes.find(rtype => rtype === 'CollectionDelivery') != null

  return (
    <div className="freightComponent-form">
      <div className="p-grid">
        <div className="p-col-6">
          <div className="p-grid">
            <div className="p-col-4 form-field-container">
              <label>Código</label>
              <InputText
                value={props.record.oid}
                onChange={props.changeHandler}
                name="oid"
                disabled={true}
              />

            </div>
            <div className="p-col-4 form-field-container">
              <label>Tipo Componente</label>
              <p className="lxGroupTypeLabel">{props.groupType}</p>

            </div>
            <div className="p-col-12 form-field-container">
              <label className="lxRequiredField">Sigla do Componente</label>
              <InputText
                value={props.record.code}
                onChange={props.changeHandler}
                name="code"
                disabled={false}
              />
            </div>
            <div className="p-col-12 form-field-container">
              <label className="lxRequiredField">Nome</label>
              <InputText
                value={props.record.name}
                onChange={props.changeHandler}
                name="name"
                disabled={false}
              />
            </div>
            <div className="p-col-12 form-field-container">
              <label>Descrição</label>
              <InputTextarea
                value={props.record.description}
                onChange={props.changeHandler}
                name="description"
                disabled={false}
              />
            </div>
            <div className="p-col-12 form-field-container">
              <p className="field-label lxRequiredField">Tipos de valor</p>
              <div className="p-grid">
                <div className="p-col-12">
                  <div className="p-grid">
                  </div>
                </div>
                <div className="p-col-12">
                  <div className="p-grid">
                    <div className="p-col-3">Valor lógico</div>
                    <div className="p-col-2 p-justify-center">
                      <InputSwitch
                        checked={props.record.useBooleanValue}
                        onChange={props.changeHandler}
                        name="useBooleanValue"
                        disabled={props.disableBoolean} />
                    </div>
                  </div>
                  <div className="p-grid">
                    <div className="p-col-3">Valor mínimo</div>
                    <div className="p-col-2 p-justify-center">
                      <InputSwitch
                        checked={props.record.useMinValue}
                        onChange={props.changeHandler}
                        name="useMinValue"
                        disabled={props.disableValue} />
                    </div>
                  </div>
                  <div className="p-grid">
                    <div className="p-col-3">Valor máximo</div>
                    <div className="p-col-2 p-justify-center">
                      <InputSwitch
                        checked={props.record.useMaxValue}
                        onChange={props.changeHandler}
                        name="useMaxValue"
                        disabled={props.disableValue} />
                    </div>
                  </div>
                  <div className="p-grid">
                    <div className="p-col-3">Valor fíxo</div>
                    <div className="p-col-2 p-justify-center">
                      <InputSwitch
                        checked={props.record.useFixedValue}
                        onChange={props.changeHandler}
                        name="useFixedValue"
                        disabled={props.disableValue}
                      />
                    </div>
                  </div>
                  <div className="p-grid">
                    <div className="p-col-3">Valor percentual</div>
                    <div className="p-col-2 p-justify-center">
                      <InputSwitch
                        checked={props.record.usePercentValue}
                        onChange={props.changeHandler}
                        name="usePercentValue"
                        disabled={props.disableValue}
                      />
                    </div>
                  </div>

                  <div className="p-grid">
                    <div className="p-col-3">Valor por Quilômetro</div>
                    <div className="p-col-2 p-justify-center">
                      <InputSwitch
                        checked={props.record.useKilometerValue}
                        onChange={props.changeHandler}
                        name="useKilometerValue"
                        disabled={false}
                      />
                    </div>
                  </div>
                  <div className="p-grid">
                    <div className="p-col-3">Valor por Quilo</div>
                    <div className="p-col-2 p-justify-center">
                      <InputSwitch
                        checked={props.record.useKiloValue}
                        onChange={props.changeHandler}
                        name="useKiloValue"
                        disabled={false}
                      />
                    </div>
                  </div>
                  <div className="p-grid">
                    <div className="p-col-3">Valor textual</div>
                    <div className="p-col-2 p-justify-center">
                      <InputSwitch
                        checked={props.record.useTextValue}
                        onChange={props.changeHandler}
                        name="useTextValue"
                        disabled={props.disableCalculateValue}
                      />
                    </div>
                  </div>
                  <div className="p-grid">
                    <div className="p-col-3">Valor Unitário</div>
                    <div className="p-col-2 p-justify-center">
                      <InputSwitch
                        checked={props.record.useUnitValue}
                        onChange={props.changeHandler}
                        name="useUnitValue"
                        disabled={false}
                      />
                    </div>
                  </div>
                  <div className="p-grid">
                    <div className="p-col-3">Valor Desconto</div>
                    <div className="p-col-2 p-justify-center">
                      <InputSwitch
                        checked={props.record.useDiscountValue}
                        onChange={props.changeHandler}
                        name="useDiscountValue"
                        disabled={false}
                      />
                    </div>
                  </div>
                  <div className="p-grid">
                    <div className="p-col-3">Valor Calculado</div>
                    <div className="p-col-2 p-justify-center">
                      <InputSwitch
                        checked={props.record.useCalculatedValue}
                        onChange={props.changeHandler}
                        name="useCalculatedValue"
                        disabled={false}
                      />
                    </div>
                  </div>
                  <div className="p-grid">
                    <div className="p-col-3">Valor M3</div>
                    <div className="p-col-2 p-justify-center">
                      <InputSwitch
                        checked={props.record.useCubageValue}
                        onChange={props.changeHandler}
                        name="useCubageValue"
                        disabled={false}
                      />
                    </div>
                  </div>
                  <div className="p-grid">
                    <div className="p-col-3">Lista de multiplos valores</div>
                    <div className="p-col-2 p-justify-center">
                      <InputSwitch
                        checked={props.record.useMultEntries}
                        onChange={props.changeHandler}
                        name="useMultEntries"
                        disabled={false}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-col-12 form-field-container">
              <p className="field-label lxRequiredField">Tipos de faixa</p>
              <div className="p-col-12">
                <div className="p-grid">
                  <div className="p-col-3">Peso</div>
                  <div className="p-col-2 p-justify-center">
                    <InputSwitch
                      checked={hasWeight}
                      onChange={props.changeHandler}
                      name="rangeTypes"
                      id="Weight"
                      disabled={false}
                    />
                  </div>
                </div>
                <div className="p-grid">
                  <div className="p-col-3">Peso excendente</div>
                  <div className="p-col-2 p-justify-center">
                    <InputSwitch
                      checked={hasExcessWeight}
                      onChange={props.changeHandler}
                      name="rangeTypes"
                      id="ExcessWeight"
                      disabled={false}
                    />
                  </div>
                </div>
                <div className="p-grid">
                  <div className="p-col-3">Valor mercadoria</div>
                  <div className="p-col-2 p-justify-center">
                    <InputSwitch
                      checked={hasProductValue}
                      onChange={props.changeHandler}
                      name="rangeTypes"
                      id="ProductValue"
                      disabled={false}
                    />
                  </div>
                </div>
                <div className="p-grid">
                  <div className="p-col-3">Volume (M3)</div>
                  <div className="p-col-2 p-justify-center">
                    <InputSwitch
                      checked={hasVolumeQuantity}
                      onChange={props.changeHandler}
                      name="rangeTypes"
                      id="VolumeQuantity"
                      disabled={false}
                    />
                  </div>
                </div>
                <div className="p-grid">
                  <div className="p-col-3">Volume (M3) excendente</div>
                  <div className="p-col-2 p-justify-center">
                    <InputSwitch
                      checked={hasExcessVolumeQuantity}
                      onChange={props.changeHandler}
                      name="rangeTypes"
                      id="ExcessVolumeQuantity"
                      disabled={false}
                    />
                  </div>
                </div>
                <div className="p-grid">
                  <div className="p-col-3">Quantidade itens</div>
                  <div className="p-col-2 p-justify-center">
                    <InputSwitch
                      checked={hasQuantityItems}
                      onChange={props.changeHandler}
                      name="rangeTypes"
                      id="QuantityItems"
                      disabled={false}
                    />
                  </div>
                </div>
                <div className="p-grid">
                  <div className="p-col-3">Valor frete</div>
                  <div className="p-col-2 p-justify-center">
                    <InputSwitch
                      checked={hasCostFreight}
                      onChange={props.changeHandler}
                      name="rangeTypes"
                      id="CostFreight"
                      disabled={false}
                    />
                  </div>
                </div>

                <div className="p-grid">
                  <div className="p-col-3">Quilômetro</div>
                  <div className="p-col-2 p-justify-center">
                    <InputSwitch
                      checked={hasKilometer}
                      onChange={props.changeHandler}
                      name="rangeTypes"
                      id="Kilometer"
                      disabled={false}
                    />
                  </div>
                </div>
                <div className="p-grid">
                  <div className="p-col-3">Quilômetro Excendente</div>
                  <div className="p-col-2 p-justify-center">
                    <InputSwitch
                      checked={hasExcessKilometer}
                      onChange={props.changeHandler}
                      name="rangeTypes"
                      id="ExcessKilometer"
                      disabled={false}
                    />
                  </div>
                </div>
                <div className="p-grid">
                  <div className="p-col-3">Coleta / Entrega</div>
                  <div className="p-col-2 p-justify-center">
                    <InputSwitch
                      checked={collectionDelivery}
                      onChange={props.changeHandler}
                      name="rangeTypes"
                      id="CollectionDelivery"
                      disabled={false}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="p-col-12 form-field-container">
              <p className="field-label lxRequiredField">Restrições</p>
              <div className="p-grid">
                <div className="p-col-12">
                  <div className="p-grid">
                  </div>
                </div>
                <div className="p-col-12">
                  <div className="p-grid">
                    <div className="p-col-3">Cliente</div>
                    <div className="p-col-2 p-justify-center">
                      <InputSwitch
                        checked={props.record.restrictedByClient}
                        onChange={props.changeHandler}
                        name="restrictedByClient"
                        id="Client"
                      />
                    </div>
                  </div>
                  <div className="p-grid">
                    <div className="p-col-3">Localização</div>
                    <div className="p-col-2 p-justify-center">
                      <InputSwitch
                        checked={props.record.restrictedByLocalization}
                        onChange={props.changeHandler}
                        name="restrictedByLocalization"
                        id="Localization"
                      />
                    </div>
                  </div>
                  <div className="p-grid">
                    <div className="p-col-3">Por Unidade</div>
                    <div className="p-col-2 p-justify-center">
                      <InputSwitch
                        checked={props.record.restrictedByBusinessUnit}
                        onChange={props.changeHandler}
                        name="restrictedByBusinessUnit"
                        id="Unit"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-col-12 form-field-container">
              <p className="field-label lxRequiredField">Tipo de Restrição</p>
              <div className="p-grid">
                <div className="p-col-12">
                  <div className="p-grid">
                  </div>
                </div>
                <div className="p-col-12">
                  <div className="p-grid">
                    <div className="p-col-3">Permissão</div>
                    <div className="p-col-2 p-justify-center">
                      <InputSwitch
                        checked={props.record.restrictedByTypeAllowed}
                        onChange={props.changeHandler}
                        name="restrictedByTypeAllowed"
                        id="Allowed"
                      />
                    </div>
                  </div>
                  <div className="p-grid">
                    <div className="p-col-3">Negação</div>
                    <div className="p-col-2 p-justify-center">
                      <InputSwitch
                        checked={props.record.restrictedByTypeDenied}
                        onChange={props.changeHandler}
                        name="restrictedByTypeDenied"
                        id="Denied"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-col-12 form-field-container">
              <p className="field-label lxRequiredField">Outros</p>
              <div className="p-grid">
                <div className="p-col-12">
                  <div className="p-grid">
                  </div>
                </div>
                <div className="p-col-12">
                  <div className="p-grid">
                    <div className="p-col-3">Ignorar valor do ICMS</div>
                    <div className="p-col-2 p-justify-center">
                      <InputSwitch
                        checked={props.record.ignoreICMSamount ?? false}
                        onChange={props.changeHandler}
                        name="ignoreICMSamount"
                        disabled={false} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

