
    import React from 'react';
    import CargoShipmentFormPageContainer from './CargoShipmentFormPageContainer';
    
    export class CargoShipmentFormPage extends React.Component{
        render(){
            return (
                <CargoShipmentFormPageContainer {...this.props}/>
            )
        }
    }
    