import React from 'react'; 
import CollectionOrdersOpenPageContainer from './CollectionOrdersOpenPageContainer';

export class CollectionOrdersOpenListPage extends React.Component{
    
    render(){
      return (
        <CollectionOrdersOpenPageContainer {...this.props}/>
      )
    }
}

