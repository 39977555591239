import React from "react";
import { ConfigurationPanel } from "./ConfigurationPanel";

export const IndicatorsSection = (props) => {
  return (
    <ConfigurationPanel
    id={"indicators"}
    title={"Indicadores"}
    configurations={props.indicatorConfigurations}
/>
  );
};
