import React from 'react';
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { Dropdown } from "primereact/dropdown";
import { AutoComplete } from 'primereact/autocomplete';
import { routes } from '../constants';
import { menuActions } from '../store/menu';
import { actions as appActions } from '../store/app';
import PrivateRoute from "../components/PrivateRoute";
import './admin.layout.scss';
import { SideBar } from "../components/sidebar/SideBar";
import { SideMenu } from "../components/sidemenu/SideMenu";
import { Loader } from "../components/loader/Loader";
import storage from '../services/stoage.service';

import { PessoaListPage, PessoaFormPage } from '../pages/pessoa';
import { EmpresaListPage, EmpresaFormPage } from '../pages/empresa';
import { UsuarioListPage, UsuarioFormPage } from '../pages/usuario';
import { PerfilListPage, PerfilFormPage } from '../pages/perfil';
import { ModuloListPage, ModuloFormPage } from '../pages/modulo';
import { AcaoListPage, AcaoFormPage } from '../pages/acao';
import { PermissaoListPage, PermissaoFormPage } from '../pages/permissao';
import { UnidadeListPage, UnidadeFormPage } from '../pages/unidade';
import authenticationService from "../services/authentication.service";
import { AppService, COMPANY_CHANGE_EVENT_NAME, BUSINESS_UNIT_CHANGE_EVENT_NAME } from '../services/app.service';
import { MenuListPage, MenuFormPage } from '../pages/menu';
import { ZipCodeRangeListPage, ZipCodeRangeFormPage } from '../pages/zipCodeRange';
import { RegionListPage, RegionFormPage } from '../pages/region';
import { CarrierListPage, CarrierFormPage } from '../pages/carrier';
import { RouteListPage, RouteFormPage } from '../pages/route';
import { SegmentListPage, SegmentFormPage } from '../pages/segment';
import { FreightComponentListPage, FreightComponentFormPage } from '../pages/freightComponent';
//import { PalletStandardListPage, PalletStandardFormPage } from '../pages/palletStandard';
//import { PalletListPage, PalletFormPage } from '../pages/pallet';
import { PackingListPage, PackingFormPage } from '../pages/packing';
//import { ProductListPage, ProductFormPage } from '../pages/product';
import { ModalListPage, ModalFormPage } from '../pages/modal';
//import { VehicleListPage, VehicleFormPage } from '../pages/vehicle';
import { FreightTableListPage, FreightTableFormPage } from '../pages/freightTable';
//import { VehicleGroupListPage, VehicleGroupFormPage } from '../pages/vehicleGroup';
import { DashboardPage } from '../pages/dashboard';
import { ImportFilesFormPage } from "../pages/importfiles";
import { AuditListPage } from "../pages/audit"
import { FreightCalculationFormPage } from "../pages/freightCalculation"
import { TrackingListPage } from "../pages/tracking"
import { ClientListPage, ClientFormPage } from "../pages/client"
import { OccurrenceTypeListPage, OccurrenceTypeFormPage } from "../pages/occurrenceType"
import { DeliveryListPage, DeliveryFormPage } from "../pages/deliveryDeadline/"
import { AccountConfigurationFormPage } from "../pages/accountConfiguration"
import { OrderLogListPage } from "../pages/order-log/"
import { OrderManagementListPage, OrderManagementFormPage } from "../pages/order-management"
import { UserDocumentTypeListPage, UserDocumentTypeFormPage } from "../pages/userDocumentType"
import { PackingConverterListPage, PackingConverterFormPage } from "../pages/packingConverter";
import { CargoShipmentFormPage } from "../pages/order-management/cargo-shipment"

import { Dialog } from 'primereact/dialog';
import { VersionNotes } from '../notes';

import '../index.scss'
import { DriverListPage } from '../pages/driver/list/DriverListPage';
import { DriverFormPage } from '../pages/driver/form/DriverFormPage';
import { VehicleFormPage, VehicleListPage } from '../pages/vehicle';
import { VehicleTypeFormPage, VehicleTypeListPage } from '../pages/vehicleType';
import { HistoryLogListPage } from '../pages/history-log-component';
import { OrderDeliveryFormPage, OrderDeliveryListPage } from '../pages/detailDeliveryOrder';

import { LogNFeListPage } from '../pages/log-nfe/list/LogNFeListPage';
import { TowerEventControlListPage, TowerEventControlFormPage } from '../pages/towerEventControl';
import { TowerTravelFormPage, TowerTravelListPage } from '../pages/towerTravel';
import { TowerEquipmentFormPage, TowerEquipmentListPage } from '../pages/towerEquipment';
import { TowerEquipmentTypeFormPage, TowerEquipmentTypeListPage } from '../pages/towerEquipmentType';
import { TowerTravelVisualizeMapListPage } from '../pages/towerTravelVisualizeMap';
import { TowerDashboardListPage } from '../pages/towerDashboard'
import { TowerObservationTypeFormPage, TowerObservationTypeListPage } from '../pages/towerObservationType'
import { AuditRecordsPage } from '../pages/auditRecords'
import { DockmanagerFormPage } from '../pages/patioManager'
import { PatioManagerDocaFormPage, PatioManagerDocaListPage } from '../pages/patioManagerDoca'
import { ScriptwriterListPage } from '../pages/scriptwriterMap'
import { TransferRouteListPage, TransferRouteFormPage } from '../pages/transferRoute'
import { PendenceDocListPage } from '../pages/pendenceDoc'
import { PolicesListPage, PolicesFormPage } from "../pages/polices";
import { PolicesGroupListPage, PolicesGroupFormPage } from "../pages/policeGroup";
import { PolicesResourcesListPage, PolicesResourcesFormPage } from "../pages/policeResources";
import { CfopFormPage, CfopListPage } from '../pages/cfop';
import { CargoShipmentReportListPage } from '../pages/cargoShipmentReport'
import { CargoShipmentReportKmListPage } from '../pages/cargoShipmentReportKm'
import { CampanhaAnymarketFormPage, CampanhaAnymarketListPage } from '../pages/campanhaAnymarket'
import { HolidayFormPage, HolidayListPage } from '../pages/holiday'
import { EnchentesRSFormPage, EnchentesRSListPage } from '../pages/cadEnchentesRS';
import {
  PortalCarrierFormPage, CollectionOrdersOpenListPage, CollectionOrdersWithPendencesListPage
  , CargoShipmentInStatusColetaListPage, CargoShipmentInStatusTrasitoListPage,
  CargoShipmentDeliveredListPage,
  CollectionOrdersRejectedListPage
} from '../pages/portalCarrier'
import { CollectionOrderPortalFormPage } from '../pages/collectionOrderPortalCarrier'
import { CadastroVeiculosListPage } from '../pages/portalCarrier/components/cadastroVeiculos';
import { DashboardNfeListPage } from '../pages/dashboardConsultaNfe';

import { AuditoriaTrackingListPage } from '../pages/auditoriaTracking';

import { actions as accountConfigurationActions } from "../pages/accountConfiguration/accountConfiguration.actions";
import { makeMessages, messageActions } from '../store/message';
import history from '../history';



function filterActionMenus(menus) {
  let actionMenus = []
  menus.forEach(menu => {
    if (menu.command) {
      actionMenus.push(menu)
    } else {
      actionMenus = actionMenus.concat(filterActionMenus(menu.items))
    }
  });
  return actionMenus
}
function checkShowConfiguration(menus) {

  let showConfiguration = false;
  for (const menu of menus) {
    if (menu.command) {
      if (menu.path == "/account_configuration") {
        showConfiguration = true;
        break
      }
    } else {
      for (const subm of menu.items) {
        if (subm.command) {
          if (subm.path == "/account_configuration") {
            showConfiguration = true;
            break
          }
        }
      }
    }
    if (showConfiguration) {
      break
    }
  }
  return showConfiguration
}
const mapStateToProps = state => {
  const menus = state.menuState.items;
  const menuRecords = filterActionMenus(menus)
  const showConfiguration = checkShowConfiguration(menus);
  const units = state.appState.units && state.appState.units.length > 0 ? [{ oid: "", cnpj: "0", label: "Todas" }, ...state.appState.units] : [];
  let currentUnit = state.appState.currentUnit
  if (!currentUnit && units.length > 0) {
    currentUnit = units[0]
  }
  const { connectSettingsBI } = state.crudAccountConfigurationState.currentRecord;

  const currentUserCarrier = state.appState.currentUserCarrier
  const messageDialog = state.appState.messageDialog;
  const dialogVisible = state.appState.dialogVisible;

  return {
    items: state.menuState.items,
    companies: state.appState.companies,
    units,
    currentUnit,
    currentCompany: state.appState.currentCompany,
    currentUser: state.appState.currentUser,
    menuRecords,
    currentUserOid: state.appState.currentUserOid,
    showConfiguration,
    currentUserCarrier,
    connectSettingsBI,
    dialogVisible,
    messageDialog
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    load: () => {
      dispatch(menuActions.menuFetch());
      dispatch(appActions.loadCompanies());
    },
    loadUserConfigurations: () => {
      dispatch(appActions.loadUserConfigurations());
    },
    setCompany: (company) => {
      let units = []
      let currentUnit = null;
      if (company && company.businessUnits) {
        units = company.businessUnits || [];

        currentUnit = units.find(unit => unit.matriz);
      }
      dispatch(appActions.setCurrentCompany(company))
      dispatch(appActions.setUnits(units));
      dispatch(appActions.setCurrentUnit(currentUnit));
      AppService.dispatch(COMPANY_CHANGE_EVENT_NAME, company)
    },

    setCurrentUser: (company, oid, carrier) => dispatch(appActions.setCurrentUser(company, oid, carrier)),
    setCurrentUnit: (unit) => {
      dispatch(appActions.setCurrentUnit(unit));
      AppService.dispatch(BUSINESS_UNIT_CHANGE_EVENT_NAME, unit)
    },
    accountConfigurationLoad: () => dispatch(accountConfigurationActions.loadAccountConfigurationsByCompany()),
    showMessage: (message, messageType) => dispatch(messageActions.messageShowMessages(
      makeMessages([message], messageType)
    )),
    showMessageDialog: (message, visible) => dispatch(appActions.showMessageDialog(message, visible))

  }
}

class AdminLayout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showInfo: false,
      prevCompany: null,
      prevUnit: null,
      suggestedMenus: [],
      selectedMenu: '',
    }
  }

  componentDidMount() {
    this.props.load();

    if (!this.props.currentCompany) {
      this.props.setCompany(authenticationService.getDefaultCompany());
    }
    if (!this.props.currentUser) {
      this.props.setCurrentUser(authenticationService.getUserName(), authenticationService.getUserOid(), authenticationService.getCarrier());

      const user = storage.getUser();
      if (user) {
        if (user.carrier || this.props.currentUserCarrier) {
          this.props.history.push(`${routes.PATH_CARRIER_PORTAL}`);
        }
      }
    }

    setTimeout(() => {
      this.props.loadUserConfigurations()
    }, 1000)
    setTimeout(() => {
      this.props.accountConfigurationLoad()
    }, 2000)
  }
  onChangeCompany = (event) => {
    const company = event.value;
    this.props.setCompany(company);
  }

  onChangeUnit = (event) => {
    let unit = event.value;
    if (unit && unit.oid === -1) {
      unit = null;
    }
    this.props.setCurrentUnit(unit);
  }

  onSingOut = (event) => {
    authenticationService.logout();
    this.props.setCurrentUser(null, null, null);
    this.props.setCurrentUnit(null);
    this.props.setCompany(null);
    this.props.history.push(`/login`)
  }

  onInfo = (event) => {
    this.setState({ showInfo: true })
  }

  onAccountConfiguration = (event) => {
    this.props.history.push(`/account_configuration`)
  }

  onCompleteMenus = (event) => {
    const selectedMenu = event.query
    const suggestedMenus = this.props.menuRecords.filter(menu => {
      return menu.label.toUpperCase().startsWith(selectedMenu.toUpperCase())
    })
    this.setState((prev) => {
      return {
        selectedMenu,
        suggestedMenus
      }
    })
  }

  onChangeMenu = event => {
    if (event &&
      event.value &&
      event.value.command) {
      event.value.command()
      this.setState((prev) => {
        return {
          selectedMenu: '',
          suggestedMenus: []
        }
      })
    }
  }

  render() {
    const user = storage.getUser();
    if (!user) {
      history.push('/login');
    }
    let userIsCarrier = false;
    if (user.carrier || this.props.currentUserCarrier) {
      userIsCarrier = true;
    }

    const menu = (<SideMenu currentCompany={this.props.currentCompany} model={this.props.items} />)
    const companies = this.props.companies;
    const getButtonConfiguration = () => {
      if (this.props.showConfiguration) {
        return (
          <button title="Configurações" onClick={this.onAccountConfiguration}>
            <i className="pi pi-cog"></i>
          </button>
        )
      } else {
        return <></>
      }
    }

    const getButtonConnectSettingsUseDashBi = () => {
      return (
        <button title="Dashboard BI" className='btnDashboardBi' onClick={() => {
          if (this.props.connectSettingsBI && this.props.connectSettingsBI.useDashboardBi) {
            window.open(this.props.connectSettingsBI.link, '_blank');
          } else {
            this.props.showMessageDialog(<div>Para contratar nosso BI, entre em contato com <a target='_blank' href="mailto:lognex@lognex.com.br">lognex@lognex.com.br</a></div>, true);
          }
        }}>
          <i className="pi pi-chart-bar" ></i>
        </button>
      )
    }

    return (
      <div className="admin-layout">
        {!userIsCarrier &&
          <SideBar content={menu} model={this.props.items} />
        }
        <div className="admin-layout__main">
          <div className="admin-layout__toolbar" >
            {!userIsCarrier &&

              <Dropdown
                className="lxSelectEmpresaUnidade"
                placeholder="Selecione uma empresa"
                value={this.props.currentCompany}
                optionLabel="tradeName"
                dataKey={"documentNumber"}
                options={companies}
                onChange={this.onChangeCompany} />
            }
            {!userIsCarrier &&
              <Dropdown
                className="lxSelectEmpresaUnidade"
                placeholder="Selecione uma unidade"
                value={this.props.currentUnit}
                dataKey={"oid"}
                optionLabel="label"
                options={this.props.units}
                onChange={this.onChangeUnit} />
            }
            <AutoComplete
              className="lxSearchMenus"
              completeMethod={this.onCompleteMenus}
              onChange={this.onChangeMenu}
              suggestions={this.state.suggestedMenus}
              placeholder={"Buscar menu"}
              value={this.state.selectedMenu}
              name={"selectedMenu"}
              field={"label"}
            />
            <div className="admin-layout__userinf">
              <h3 style={{ color: 'white' }}>{this.props.currentUser}</h3>
              {getButtonConnectSettingsUseDashBi()}
              {getButtonConfiguration()}

              {/* <button
                style={{position:"relative"}}
                title="Notificações"                
                onClick={()=>{}}                
              >
                <i className="pi pi-bell"></i>
                <div className="btnNotification">13</div>
              </button> */}


              <button
                title="Informações"
                // className='lognex-btn-highlighted'
                // style={{backgroundColor: 'white'}}
                onClick={this.onInfo}
              >
                <i className="pi pi-question-circle"  ></i>
              </button>
              <button
                title="Sair do sistema"
                className='lognex-btn-danger'
                onClick={this.onSingOut}
              >
                <i className="pi pi-sign-out"></i>
              </button>



            </div>
          </div>

          <Dialog
            header={(<h3>Sobre</h3>)}
            visible={this.state.showInfo}
            style={{ width: '50vw' }}
            modal={false}
            baseZIndex={1}
            onHide={() => { this.setState({ showInfo: false }) }}
            footer={
              <div>

                <button
                  title="fechar"
                  className="lognex-btn-highlighted"
                  onClick={() => { this.setState({ showInfo: false }) }}
                >
                  <i className="pi pi-check"></i>
                </button>

              </div>}>

            <div className="p-col-12 form-field-container">
              <VersionNotes />
            </div>
          </Dialog>

          <Dialog
            header="Atenção"
            visible={this.props.dialogVisible}
            style={{ width: '400px' }} modal={true}
            onHide={() => this.props.showMessageDialog("", false)}>
            <div>
              <h2>{this.props.messageDialog}</h2>
            </div>
          </Dialog>

          <div className="admin-layout__content">
            <Loader />
            <Switch>
              <PrivateRoute exact path={`${routes.PATH_PESSOA}`} component={PessoaListPage} />
              <PrivateRoute exact path={`${routes.PATH_PESSOA}/:id`} component={PessoaFormPage} />
              <PrivateRoute exact path={`${routes.PATH_EMPRESA}`} component={EmpresaListPage} />
              <PrivateRoute exact path={`${routes.PATH_EMPRESA}/:id`} component={EmpresaFormPage} />
              <PrivateRoute exact path={`${routes.PATH_USUARIO}`} component={UsuarioListPage} />
              <PrivateRoute exact path={`${routes.PATH_USUARIO}/:id`} component={UsuarioFormPage} />
              <PrivateRoute exact path={`${routes.PATH_PERFIL}`} component={PerfilListPage} />
              <PrivateRoute exact path={`${routes.PATH_PERFIL}/:id`} component={PerfilFormPage} />
              <PrivateRoute exact path={`${routes.PATH_MODULO}`} component={ModuloListPage} />
              <PrivateRoute exact path={`${routes.PATH_MODULO}/:id`} component={ModuloFormPage} />
              <PrivateRoute exact path={`${routes.PATH_ACAO}`} component={AcaoListPage} />
              <PrivateRoute exact path={`${routes.PATH_ACAO}/:id`} component={AcaoFormPage} />
              <PrivateRoute exact path={`${routes.PATH_PERMISSAO}`} component={PermissaoListPage} />
              <PrivateRoute exact path={`${routes.PATH_PERMISSAO}/:id`} component={PermissaoFormPage} />
              <PrivateRoute exact path={`${routes.PATH_UNIDADE}`} component={UnidadeListPage} />
              <PrivateRoute exact path={`${routes.PATH_UNIDADE}/:id`} component={UnidadeFormPage} />
              <PrivateRoute exact path={`${routes.PATH_MENU}`} component={MenuListPage} />
              <PrivateRoute exact path={`${routes.PATH_MENU}/:id`} component={MenuFormPage} />
              <PrivateRoute exact path={`${routes.PATH_ZIPCODERANGE}`} component={ZipCodeRangeListPage} />
              <PrivateRoute exact path={`${routes.PATH_ZIPCODERANGE}/:id`} component={ZipCodeRangeFormPage} />
              <PrivateRoute exact path={`${routes.PATH_CARRIER}`} component={CarrierListPage} />
              <PrivateRoute exact path={`${routes.PATH_CARRIER}/:id`} component={CarrierFormPage} />
              <PrivateRoute exact path={`${routes.PATH_REGION}`} component={RegionListPage} />
              <PrivateRoute exact path={`${routes.PATH_REGION}/:id`} component={RegionFormPage} />
              <PrivateRoute exact path={`${routes.PATH_ROUTE}`} component={RouteListPage} />
              <PrivateRoute exact path={`${routes.PATH_ROUTE}/:id`} component={RouteFormPage} />
              <PrivateRoute exact path={`${routes.PATH_SEGMENT}`} component={SegmentListPage} />
              <PrivateRoute exact path={`${routes.PATH_SEGMENT}/:id`} component={SegmentFormPage} />
              <PrivateRoute exact path={`${routes.PATH_FREIGHT_COMPONENT}`} component={FreightComponentListPage} />
              <PrivateRoute exact path={`${routes.PATH_FREIGHT_COMPONENT}/:id`} component={FreightComponentFormPage} />
              <PrivateRoute exact path={`${routes.PATH_MODAL}`} component={ModalListPage} />
              <PrivateRoute exact path={`${routes.PATH_MODAL}/:id`} component={ModalFormPage} />
              <PrivateRoute exact path={`${routes.PATH_FREIGHT_TABLE}`} component={FreightTableListPage} />
              <PrivateRoute exact path={`${routes.PATH_FREIGHT_TABLE}/:id`} component={FreightTableFormPage} />
              <PrivateRoute exact path={`${routes.PATH_IMPORT_FILES}`} component={ImportFilesFormPage} />
              <PrivateRoute exact path={`${routes.PATH_AUDIT}`} component={AuditListPage} />
              <PrivateRoute exact path={`${routes.PATH_FREIGHT_CALCULATION}`} component={FreightCalculationFormPage} />
              <PrivateRoute exact path={`${routes.PATH_TRACKING}`} component={TrackingListPage} />
              <PrivateRoute exact path={`${routes.PATH_AUDIT_TRACKING}`} component={AuditoriaTrackingListPage} />
              <PrivateRoute exact path={`${routes.PATH_OCCURRENCETYPE}`} component={OccurrenceTypeListPage} />
              <PrivateRoute exact path={`${routes.PATH_OCCURRENCETYPE}/:id`} component={OccurrenceTypeFormPage} />
              <PrivateRoute exact path={`${routes.PATH_CLIENT}`} component={ClientListPage} />
              <PrivateRoute exact path={`${routes.PATH_CLIENT}/:id`} component={ClientFormPage} />
              <PrivateRoute exact path={`${routes.PATH_DELIVERY_DEADLINE}`} component={DeliveryListPage} />
              <PrivateRoute exact path={`${routes.PATH_DELIVERY_DEADLINE}/:id`} component={DeliveryFormPage} />
              <PrivateRoute exact path={`${routes.PATH_ACCOUNT_CONFIGURATION}`} component={AccountConfigurationFormPage} />
              <PrivateRoute exact path={`${routes.PATH_ORDER_LOG}`} component={OrderLogListPage} />
              <PrivateRoute exact path={`${routes.PATH_ORDER_MANAGEMENT}`} component={OrderManagementListPage} />
              <PrivateRoute exact path={`${routes.PATH_ORDER_MANAGEMENT}/:id`} component={OrderManagementFormPage} />
              <PrivateRoute exact path={`${routes.PATH_USER_DOCUMENT_TYPE}`} component={UserDocumentTypeListPage} />
              <PrivateRoute exact path={`${routes.PATH_USER_DOCUMENT_TYPE}/:id`} component={UserDocumentTypeFormPage} />
              <PrivateRoute exact path={`${routes.PATH_PACKING}`} component={PackingListPage} />
              <PrivateRoute exact path={`${routes.PATH_PACKING}/:id`} component={PackingFormPage} />
              <PrivateRoute exact path={`${routes.PATH_PACKING_CONVERTER}`} component={PackingConverterListPage} />
              <PrivateRoute exact path={`${routes.PATH_PACKING_CONVERTER}/:id`} component={PackingConverterFormPage} />
              <PrivateRoute exact path={`${routes.PATH_CARGO_SHIPMENT}/:id`} component={CargoShipmentFormPage} />
              <PrivateRoute exact path={`${routes.PATH_DRIVER}`} component={DriverListPage} />
              <PrivateRoute exact path={`${routes.PATH_DRIVER}/:id`} component={DriverFormPage} />
              <PrivateRoute exact path={`${routes.PATH_VEHICLE}`} component={VehicleListPage} />
              <PrivateRoute exact path={`${routes.PATH_VEHICLE}/:id`} component={VehicleFormPage} />
              <PrivateRoute exact path={`${routes.PATH_VEHICLE_TYPE}`} component={VehicleTypeListPage} />
              <PrivateRoute exact path={`${routes.PATH_VEHICLE_TYPE}/:id`} component={VehicleTypeFormPage} />
              <PrivateRoute exact path={`${routes.PATH_LOG_NFE}`} component={LogNFeListPage} />
              <PrivateRoute exact path={`${routes.PATH_HISTORY_LOG}/:id`} component={HistoryLogListPage} />
              <PrivateRoute exact path={`${routes.PATH_ORDER_DELIVERY}/:id`} component={OrderDeliveryFormPage} />
              <PrivateRoute exact path={`${routes.PATH_ORDER_DELIVERY}`} component={OrderDeliveryListPage} />
              <PrivateRoute exact path={`${routes.PATH_CFOP}`} component={CfopListPage} />
              <PrivateRoute exact path={`${routes.PATH_CFOP}/:id`} component={CfopFormPage} />
              {/* Tower Control */}
              <PrivateRoute exact path={`${routes.PATH_TOWER_EVENT}`} component={TowerEventControlListPage} />
              <PrivateRoute exact path={`${routes.PATH_TOWER_EVENT}/:id`} component={TowerEventControlFormPage} />
              <PrivateRoute exact path={`${routes.PATH_TOWER_TRAVEL}`} component={TowerTravelListPage} />
              <PrivateRoute exact path={`${routes.PATH_TOWER_TRAVEL}/:id`} component={TowerTravelFormPage} />
              <PrivateRoute exact path={`${routes.PATH_TOWER_EQUIPMENT_TYPE}`} component={TowerEquipmentTypeListPage} />
              <PrivateRoute exact path={`${routes.PATH_TOWER_EQUIPMENT_TYPE}/:id`} component={TowerEquipmentTypeFormPage} />
              <PrivateRoute exact path={`${routes.PATH_TOWER_EQUIPMENT}`} component={TowerEquipmentListPage} />
              <PrivateRoute exact path={`${routes.PATH_TOWER_EQUIPMENT}/:id`} component={TowerEquipmentFormPage} />
              <PrivateRoute exact path={`${routes.PATH_TOWER_TRAVEL_VISUALIZE_MAP}`} component={TowerTravelVisualizeMapListPage} />
              <PrivateRoute exact path={`${routes.PATH_TOWER_TRAVEL_VISUALIZE_MAP}/:id`} component={TowerTravelVisualizeMapListPage} />
              <PrivateRoute exact path={`${routes.PATH_TOWER_DASHBOARD}`} component={TowerDashboardListPage} />
              <PrivateRoute exact path={`${routes.PATH_TOWER_OBSERVATION_TYPE}`} component={TowerObservationTypeListPage} />
              <PrivateRoute exact path={`${routes.PATH_TOWER_OBSERVATION_TYPE}/:id`} component={TowerObservationTypeFormPage} />
              <PrivateRoute exact path={`${routes.PATH_AUDIT_RECORDS}`} component={AuditRecordsPage} />
              <PrivateRoute exact path={`${routes.PATH_DOCK_MANAGER}`} component={DockmanagerFormPage} />
              <PrivateRoute exact path={`${routes.PATH_PATIO_MANAGER}`} component={DockmanagerFormPage} />
              <PrivateRoute exact path={`${routes.PATH_PATIO_MANAGER_DOCK}`} component={PatioManagerDocaListPage} />
              <PrivateRoute exact path={`${routes.PATH_PATIO_MANAGER_DOCK}/:id`} component={PatioManagerDocaFormPage} />
              <PrivateRoute exact path={`${routes.PATH_HOLIDAY}`} component={HolidayListPage} />
              <PrivateRoute exact path={`${routes.PATH_HOLIDAY}/:id`} component={HolidayFormPage} />
              <PrivateRoute exact path={`${routes.PATH_CADENCHENTESRS}/:id`} component={EnchentesRSFormPage} />
              <PrivateRoute exact path={`${routes.PATH_CADENCHENTESRS}`} component={EnchentesRSListPage} />

              <PrivateRoute exact path={`${routes.PATH_TRANSFER_ROUTE}`} component={TransferRouteListPage} />
              <PrivateRoute exact path={`${routes.PATH_TRANSFER_ROUTE}/:id`} component={TransferRouteFormPage} />
              <PrivateRoute exact path={`${routes.PATH_SCRIPTWRITER}`} component={ScriptwriterListPage} />
              <PrivateRoute exact path={`${routes.PATH_POLICES}`} component={PolicesListPage} />
              <PrivateRoute exact path={`${routes.PATH_POLICES}/:id`} component={PolicesFormPage} />
              <PrivateRoute exact path={`${routes.PATH_PENDENCE_DOC}`} component={PendenceDocListPage} />
              <PrivateRoute exact path={`${routes.PATH_POLICES_GROUP}/:id`} component={PolicesGroupFormPage} />
              <PrivateRoute exact path={`${routes.PATH_POLICES_GROUP}`} component={PolicesGroupListPage} />
              <PrivateRoute exact path={`${routes.PATH_POLICES_RESOURCES}/:id`} component={PolicesResourcesFormPage} />
              <PrivateRoute exact path={`${routes.PATH_POLICES_RESOURCES}`} component={PolicesResourcesListPage} />
              <PrivateRoute exact path={`${routes.PATH_CARGOSHIPMENT_REPORT}`} component={CargoShipmentReportListPage} />
              <PrivateRoute exact path={`${routes.PATH_CAMPANHA_ANYMARKET}`} component={CampanhaAnymarketListPage} />
              <PrivateRoute exact path={`${routes.PATH_CAMPANHA_ANYMARKET}/:id`} component={CampanhaAnymarketFormPage} />
              <PrivateRoute exact path={`${routes.PATH_CARRIER_PORTAL}`} component={PortalCarrierFormPage} />
              <PrivateRoute exact path={`${routes.PATH_CARRIER_PORTAL}/open-collection-orders`} component={CollectionOrdersOpenListPage} />
              <PrivateRoute exact path={`${routes.PATH_CARRIER_PORTAL_COLLECTION_ORDER}`} component={CollectionOrderPortalFormPage} />
              <PrivateRoute exact path={`${routes.PATH_CARRIER_PORTAL}/open-collection-orders-pendences`} component={CollectionOrdersWithPendencesListPage} />
              <PrivateRoute exact path={`${routes.PATH_CARRIER_PORTAL}/open-collection-orders-rejected`} component={CollectionOrdersRejectedListPage} />
              <PrivateRoute exact path={`${routes.PATH_CARRIER_PORTAL}/shipment-in-coleta`} component={CargoShipmentInStatusColetaListPage} />
              <PrivateRoute exact path={`${routes.PATH_CARRIER_PORTAL}/shipment-in-transito`} component={CargoShipmentInStatusTrasitoListPage} />
              <PrivateRoute exact path={`${routes.PATH_CARRIER_PORTAL}/shipment-delivered`} component={CargoShipmentDeliveredListPage} />
              <PrivateRoute exact path={`${routes.PATH_CARRIER_PORTAL}/cad-veiculos`} component={CadastroVeiculosListPage} />
              <PrivateRoute exact path={`${routes.PATH_CARGOSHIPMENT_REPORT_KM}`} component={CargoShipmentReportKmListPage} />
              <PrivateRoute exact path={`${routes.PATH_DASHBOARD_NFE}`} component={DashboardNfeListPage} />


              {/* <Route exact path="/teste" component={TestePage}></Route> */}


              {/* Must be the last Route*/}
              <PrivateRoute exact path={`${routes.PATH_DASHBOARD}`} component={DashboardPage} />

            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminLayout);
