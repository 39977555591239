
import { Calendar } from "primereact/calendar";
import { InputTextarea } from "primereact/inputtextarea";
import React from "react";
import { connect } from "react-redux";
import { FileUploudScreen } from "../../../components/file-upload-screen/FileUploadScreen";
import FileUploaderButton from "../../../components/file-uploader-button/FileUploaderButton";
import { actions } from "../collectionOrderPortalCarrier.actions";
import "./componetes.scss"
const mapStateToProps = store => {
  const fileImg = store.crudCollectionOrderPortalState.fileImg;
  const link = window.location.href;
  return {
    fileImg,
    link,
    currentOrderEdit: store.crudCollectionOrderPortalState.currentOrderEdit,
    observation: store.crudCollectionOrderPortalState.observation,
    deliveryDate: store.crudCollectionOrderPortalState.deliveryDate,
    showModalUploadImg: store.crudCollectionOrderPortalState.showModalUploadImg,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    rejectedCollectionOrderHandler: (link) => dispatch(actions.rejectedCollectionOrder(link)),
    setCodeOrder: order => dispatch(actions.setCodeOrder(order)),
    setShowModalUploadImg: visible => dispatch(actions.setShowModalUploadImg(visible)),
    changeHandlerImage: (fileImg) => dispatch(actions.changeHandlerImage(fileImg)),
    onChangeDeliveryDate: (deliveryDate) => dispatch(actions.onChangeDeliveryDate(deliveryDate)),
    onChangeObs: (obs) => dispatch(actions.onChangeObs(obs)),
    sendReceipt: data => dispatch(actions.sendReceipt(data)),
  }
}


class UpdateDataEntregaPortal extends React.Component {
  constructor(props) {
    super(props);
  }
  onSave = (e) => {

    let file = this.props.fileImg;
    if (file) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      let fileBase64 = "";
      reader.onloadend = () => {
        fileBase64 = reader.result
          .replace("data:", "")
          .replace(/^.+,/, "");

        const data = {
          "link": this.props.link,
          "code": this.props.currentOrderEdit.code,
          "deliveryDate": this.props.currentOrderEdit.deliveryDate,
          "observation": this.props.currentOrderEdit.observation,
          "file": fileBase64,
          "type": file.type
        }
        this.props.sendReceipt(data);
      }
    }
    else {
      const data = {
        "link": this.props.link,
        "code": this.props.currentOrderEdit.code,
        "deliveryDate": this.props.currentOrderEdit.deliveryDate,
        "observation": this.props.currentOrderEdit.observation,
      }
      this.props.sendReceipt(data);
    }
  }
  onUploadImage = (e) => {
    if (e.target && e.target.files.length > 0) {
      this.props.changeHandlerImage(e.target.files[0])
    }
  }

  showModalUploadImg = (visible) => {
    this.props.setShowModalUploadImg(visible);
    if (!visible) {
      this.props.setCodeOrder(null);
    }
  }

  onChangeDeliveryDate = (event) => {
    this.props.onChangeDeliveryDate(event.target.value)
  }
  onChangeObs = (event) => {
    this.props.onChangeObs(event.target.value)
  }
 
  render() {

    const deliveryDate = this.props.deliveryDate// ? this.props.currentOrderEdit.deliveryDate : "";
    const observation = this.props.observation// ? this.props.currentOrderEdit.observation : "";


    return (

      <FileUploudScreen
        visible={this.props.showModalUploadImg}
        onHide={(e) => this.showModalUploadImg(false)}>


        <div className="p-grid">
          <div className="p-col-3">
            <div className="content-upload">
              <FileUploaderButton
                title="Enviar Comprovante de Entrega"
                onUpload={(e) => this.onUploadImage(e)}
              />
              <label style={{ paddingTop: "5px", marginLeft: "5px", fontWeight: "bold" }}>
                Comprovante
              </label>
            </div>

          </div>

          <div className="p-col-9 form-field-container">

            <div className="p-col-12 form-field-container">
              <label>Data da entrega</label>
              <Calendar
                showIcon={true}
                name="deliveryDate"
                value={deliveryDate}
                hourFormat="24"
                showTime={true}
                onChange={this.onChangeDeliveryDate}
                disabled={false}
                dateFormat="dd/mm/yy"
              />
            </div>

            <div className="p-col-12 form-field-container">
              <label>Observação</label>
              <InputTextarea
                rows={10}
                cols={30}
                name="observation"
                value={observation}
                onChange={this.onChangeObs}
              />
            </div>
          </div>

          <div className="p-col-12 lxActionButtons">
            <div>
            <button
              title="Enviar Comprovante"
              onClick={this.onSave}
              style={{ marginRight: ".25em" }}
              className="lognex-btn-highlighted">
              <i className="pi pi-save"></i>
            </button>
            Salvar
            </div>
          </div>
        </div>

      </FileUploudScreen>
    )

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateDataEntregaPortal);
