
import React from "react";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import Toolbar from "../../../components/toolbar/Toolbar";
import { Column } from 'primereact/column';
import { routes } from '../../../constants';
import { Page } from "../../../components/page/Page";
import { CarrierList } from "./CarrierList";
import { actions } from '../carrier.actions'
import { AppService, COMPANY_CHANGE_EVENT_NAME, BUSINESS_UNIT_CHANGE_EVENT_NAME } from "../../../services/app.service";
import { actions as appActions } from '../../../store/app';
import * as menuActions from '../../../store/menu/menu.actions';
import * as utils from '../../../utils'
import { importFromJSONFile } from '../../../file-handler'

const mapStateToProps = state => {
  const currentMenu = state.menuState.currentRecord;
  const userConfigurations = state.appState.userConfigurations;
  let isFavorite = utils.isFavorite(currentMenu, userConfigurations);
  return {
    columns: state.crudCarrierState.columns,
    records: state.crudCarrierState.records,
    first: state.crudCarrierState.index,
    rows: state.crudCarrierState.max,
    totalRecords: state.crudCarrierState.count,
    sortField: state.crudCarrierState.sortField,
    order: state.crudCarrierState.sortOrder,
    menuItems: state.menuState.items,
    currentMenu,
    isFavorite,
    selectedRecords: state.crudCarrierState.selectedRecords,
  }
}
const mapDispatchToProps = dispatch => {
  
  return {
    paginate: paginationParams => dispatch(actions.paginate(paginationParams)),
    sortOrder: sortParams => dispatch(actions.sortOrder(sortParams)),
    applyFilter: (filterParams,fields = []) => {
      dispatch(actions.setType(null));
      dispatch(actions.applyFilter(filterParams,{fields}))
    },
    load: (fields=[]) => dispatch(actions.load({fields})),
    userLastAccess: (oidMenu) => dispatch(appActions.userLastAccess(oidMenu)),
    setCurrentMenu: (menuPath) => dispatch(menuActions.setCurrentRecord(menuPath)),
    toggleFavorite: (isFavorite, oidMenu) => {
      dispatch(appActions.toggleFavorite(isFavorite, oidMenu))
    },
    setSelectedRecords: (selectedRecords) => {
      dispatch(actions.setSelectedRecords(selectedRecords))
    },
    exportCarrier: () => {
      dispatch(actions.export('transportadoras'))
    },
    importCarrier: (data) => {
      const errorMessageHandler = (results) => {
        const messages = [];
        results.data.forEach(result => {
          const record = result.data;
          if (!result.ok) {
            if (!record.person) {
              messages.push({ message: `Pessoa inválida ou em branco`, type: 'error' })
            } else {
              messages.push({ message: `Falha ao importar a transportadora ${record.name}, CNPJ: ${record.documentNumber}`, type: 'error' })
            }
          } else {
            messages.push({ message: `Transportadora ${record.name}, CNPJ: ${record.documentNumber} importada com sucesso`, type: 'success' })
          }
        });
        return messages;
      }
      dispatch(actions.import(data, errorMessageHandler))
    },

  }
}


class CarrierListPageContainer extends React.Component {

  newHandler = () => {
    this.props.history.push(`${routes.PATH_CARRIER}/novo`)
  }

  componentWillReceiveProps(newProps) {
    if ((!this.props.menuItems || this.props.menuItems.length === 0) && newProps.menuItems && newProps.menuItems.length > 0) {
      const menuPath = this.props.history.location.pathname;
      this.props.setCurrentMenu(menuPath)
    }
    if ((!this.props.currentMenu && newProps.currentMenu) ||
      (this.props.currentMenu !== newProps.currentMenu)) {
      const oidMenu = newProps.currentMenu.oid;
      this.props.userLastAccess(oidMenu)
    }
  }

  componentDidMount() {
    const fields = this.props.columns.map(col => col.field)
    this.props.applyFilter([],fields)
    AppService.on(COMPANY_CHANGE_EVENT_NAME, this);
    AppService.on(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
    const menuPath = this.props.history.location.pathname;
    this.props.setCurrentMenu(menuPath)
  }

  componentWillUnmount() {
    AppService.off(COMPANY_CHANGE_EVENT_NAME, this);
    AppService.off(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
  }

  onReceiveAppNotifications = (eventName, params) => {
    if (eventName === COMPANY_CHANGE_EVENT_NAME || eventName === BUSINESS_UNIT_CHANGE_EVENT_NAME) {
      const fields = this.props.columns.map(col => col.field)
      this.props.applyFilter([],fields)
    }
  }

  onPage = (e) => {
    this.props.paginate({
      max: e.rows,
      index: e.first
    });
  }

  onSort = (e) => {
    this.props.sortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField
    });
  }

  onFilter = (e) => {
    const fields = this.props.columns.map(col => col.field)
      
    const filters = [];
    const keys = Object.keys(e.filters) || [];
    keys.forEach(key => {
      if(key === "oid"){
        filters.push({
          campo: 'oid',
          valor: parseInt(e.filters[key].value),
          tipoFiltro: 'IGUAL_A'
        });
      }else {
        filters.push({
          campo: key,
          valor: e.filters[key].value,
          tipoFiltro: 'CONTEM'
        });
      }
    });
    this.props.applyFilter(filters,fields);
  }

  onRowClick = (e) => {
    const oid = e && e.data && e.data.oid;
    if (oid) {
      this.props.history.push(`${routes.PATH_CARRIER}/${oid}`);
    }
  }

  onFavorite = (e) => {
    if (this.props.currentMenu) {
      const oidMenu = this.props.currentMenu.oid
      const isFavorite = this.props.isFavorite;
      this.props.toggleFavorite(isFavorite, oidMenu);
    }
  }

  carrierExportJSON = () => {
    const { person, branches } = this.props.records
    return {
      person,
      branches
    }
  }

  onExportCarrier = () => {
    this.props.exportCarrier();
  }

  onImportCarrier = ({ target }) => {
    importFromJSONFile(target.files[0], this.props.importCarrier);
  }

  onSelectedRecordsChangeHandler = (e) => {
    this.props.setSelectedRecords(e.value);
  }

  render() {
    const isFavorite = this.props.isFavorite;
    const header = (
      <div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h1>Transportadoras</h1>

          <i className={`pi pi-star lxFavoriteStar ${isFavorite ? 'lxFavorite' : ''}`}
            title={isFavorite ? 'Remover dos favoritos' : 'Adicionar aos favoritos'} onClick={this.onFavorite}></i>
        </div>
        <hr className="lxSplitter" ></hr>
        <div>
          <Toolbar onNew={this.newHandler}
            onExportJSON={this.onExportCarrier}
            onImportJSON={this.onImportCarrier}
            newButtonStyle='highlighted'>
          </Toolbar >
        </div>
      </div>
    );


    const personNameTemplate = (rowData, column) => {
      const value =rowData.name ? rowData.name : '';
      return (
        <div>{value}</div>
      )
    }

    const standardModalNameTemplate = (rowData, column) => {
      let value = rowData.standardModal;
      switch (value) {
        case 'AIR':
          value = 'Aéreo';
          break;
        case 'ROAD':
          value = 'Rodoviário';
          break;
        case 'SHIP':
          value = 'Marítimo'
          break;
        default:
          value = ''
          break;
      }
      return (
        <div style={{ textAlign: 'center' }}>{value}</div>
      )
    }
    const taxModalNameTemplate = (rowData, column) => {
      const value = rowData.taxModal && rowData.taxModal.name ? rowData.taxModal.name : '';
      return (
        <div>{value}</div>
      )
    }

    const booleanTemplate = (rowData, column) => {
      let value = rowData[column.columnKey];
      if (typeof rowData[column.columnKey] === 'string') {
        value = rowData[column.columnKey].toUpperCase() === 'TRUE';
      }

      value = value ? 'SIM' : 'NÃO';
      return (
        <div style={{ textAlign: 'center' }}>{value}</div>
      )
    }

    let columns = [<Column key="selectorColumn" selectionMode="multiple" style={{ width: '3em' }} />]
    columns = [...columns,
    ...this.props.columns.map(col => {
      if (col.field === 'name') {
        return (<Column {...col} key={col.field} body={personNameTemplate} />);
      }

      if (col.field === 'standardModal') {
        return (<Column {...col} key={col.field} body={standardModalNameTemplate} />);
      }
      if (col.field === 'taxModalName') {
        return (<Column {...col} key={col.field} body={taxModalNameTemplate} />);
      }


      if (col.field === 'prorateICMSPerMove' ||
        col.field === 'issueServiceInvoiceInMunicipalFreight' ||
        col.field === 'postOfficeCarrier') {
        return (<Column {...col} key={col.field} body={booleanTemplate} />);
      }


      return (<Column {...col} key={col.field} />)
    })]



    const content = <CarrierList
      {...this.props}
      onSelectedRecordsChange={this.onSelectedRecordsChangeHandler}
      onRowClick={this.onRowClick} onFilter={this.onFilter}
      onPage={this.onPage} onSort={this.onSort}
      columns={columns} />;
    return <Page header={header} content={content} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CarrierListPageContainer);
