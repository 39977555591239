import React from 'react';
import { connect } from 'react-redux';
import { Column } from 'primereact/column';
import Toolbar from "../../../components/toolbar/Toolbar";
import { actions } from '../carrier.actions';
import { routes } from '../../../constants';
import { Page } from "../../../components/page/Page";
import { CarrierForm } from './CarrierForm';
import { AppService, COMPANY_CHANGE_EVENT_NAME, BUSINESS_UNIT_CHANGE_EVENT_NAME } from '../../../services/app.service';

const mapStateToProps = state => {
  const record = state.crudCarrierState.currentRecord;

  

  return {
    record,
    currentBranch : state.crudCarrierState.currentBranch,
    branchesColumns: state.crudCarrierState.branchesColumns,
    branchesRecords: state.crudCarrierState.currentRecord.branches,
    selectedBranches: state.crudCarrierState.selectedBranches,
    branchesFirst: 1,
    branchesRows: state.crudCarrierState.currentRecord.branches.length,
    branchesTotalRecords: state.crudCarrierState.currentRecord.branches.length,
    branchesSortField: '',
    branchesOrder: 1,
    dialogAddBranches: state.crudCarrierState.dialogAddBranches,

    
  }
}

const mapDispatchToProps = dispatch => {
  return {
   
    setField: (field, value) => dispatch(actions.setField(field, value)),
    changeHandler: event => {
      const target = event.target;
      const field = target.name;
      let value = target.value;
      dispatch(actions.removeErrors([{ field }]));
      dispatch(actions.setField(field, value));
    },
    currentBranchChangeHandler: event =>{
      const target = event.target;
      const field = target.name;
      let value = target.value;
      dispatch(actions.setCurrentBranchField(field, value));
    },
    saveHandler: () => {
      dispatch(actions.save());
    },
    removeHandler: event => {
      dispatch(actions.remove());
    },
    newHandler: event => {
      dispatch(actions.makeNew());
    },
    loadById: oid => dispatch(actions.loadById(oid)),
   
    paginate: paginationParams => dispatch(actions.paginate(paginationParams)),
    sortOrder: sortParams => dispatch(actions.sortOrder(sortParams)),
    applyFilter: filterParams => {
      dispatch(actions.setType(null));
      dispatch(actions.applyFilter(filterParams))
    },
    setSelectedBranches: (branches) => {
      dispatch(actions.setSelectedBranches(branches != null ? branches : []));
    },
    removeSelectedBranches: () => {
      dispatch(actions.removeSelectedBranches());
    },
    setDialogAddBranchesVisible: (visible) => {
      dispatch(actions.setDialogAddBranchesVisible(visible))
    },
    addBranch: () => {
      dispatch(actions.addBranch())
    },
    
    setRecord : record => {
      dispatch(actions.setRecord(record))
    },
    setEmptyCurrentBranch:()=>{
      dispatch(actions.setEmptyCurrentBranch());
    },
    setCurrentBranch:(branch)=>{
      dispatch(actions.setCurrentBranch(branch));
    }
  }
}

class CarrierFormPageContainer extends React.Component {

  constructor(props) {
    super(props);
    actions.afterSave = ({ oid }) => {
      this.props.history.replace(`${routes.PATH_CARRIER}/${this.props.record.oid}`);
    };
    actions.afterNew = () => {
      this.props.history.replace(`${routes.PATH_CARRIER}/novo`);
    };
    actions.afterRemove = () => {
      this.props.history.replace(`${routes.PATH_CARRIER}`);
    };
  }

  componentDidMount() {
    const oid = this.props.match.params.id;
    if (oid && parseInt(oid)) {
      this.props.loadById(oid);
    } else {
      this.props.newHandler();
    }
   
    AppService.on(COMPANY_CHANGE_EVENT_NAME, this);
    AppService.on(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
  }

  componentWillUnmount() {
    AppService.off(COMPANY_CHANGE_EVENT_NAME, this);
    AppService.off(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
  }

  onReceiveAppNotifications = (eventName, params) => {
    if (eventName === COMPANY_CHANGE_EVENT_NAME || eventName === BUSINESS_UNIT_CHANGE_EVENT_NAME) {
      // this.props.load();
    }
  }
  searchHandler = () => {
    this.props.history.push(`${routes.PATH_CARRIER}`);
  }

  
  branchesOnSelectionChange = (e) => {
    this.props.setSelectedBranches(e.value);
  }

  onRemoveSelectedBranches = (e) => {
    this.props.removeSelectedBranches();
  }

  dialogAddBranchesOnOpen = (e) => {
    this.props.setEmptyCurrentBranch();
    this.props.setDialogAddBranchesVisible(true);
  }
  dialogEditBranchesOnOpen = (e) => {
    if(this.props.selectedBranches && this.props.selectedBranches.length ===1){
      const selectedBranch = this.props.selectedBranches[0]
      this.props.setCurrentBranch({...selectedBranch});
      this.props.setDialogAddBranchesVisible(true);

    }
  }

  dialogAddBranchesOnClose = (e) => {
    this.props.setDialogAddBranchesVisible(false);
  }

  dialogAddBranchesOnConfirm = (e) => {
    this.props.setDialogAddBranchesVisible(false);
    this.props.addBranch();
    this.props.setEmptyCurrentBranch();
    this.props.setSelectedBranches([]);
  }
  dialogAddBranchesOnAdd = (e) => {
    this.props.addBranch();
    this.props.setEmptyCurrentBranch();
  }

  

  onPageBranches = (e) => {
    this.props.paginate({
      max: e.rows,
      index: e.first
    });
  }

  onSortBranches = (e) => {
    this.props.sortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField
    });
  }

  onFilterBranches = (e) => {
    const filters = [];
    const keys = Object.keys(e.filters) || [];
    keys.forEach(key => {
      filters.push({
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: 'CONTEM'
      });
    });
    this.props.applyFilter(filters);
  }

  
  
  render() {
    

    

    
    let branchesColumns = [<Column key="selectorColumn" selectionMode="multiple" style={{ width: '3em' }} />];
    branchesColumns = [...branchesColumns, ...this.props.branchesColumns.map(col => {
      return (<Column {...col} key={col.field} />)
    })]

    

    

    const content = (
      <CarrierForm
        changeHandler={this.props.changeHandler}
        currentBranchChangeHandler={this.props.currentBranchChangeHandler}
        record={this.props.record}
        currentBranch = {this.props.currentBranch}
        

       

        branchesColumns={branchesColumns}
        selectedBranches={this.props.selectedBranches}
        branchesRecords={this.props.branchesRecords}
        branchesFirst={this.props.branchesFirst}
        branchesRows={this.props.branchesRows}
        branchesTotalRecords={this.props.branchesTotalRecords}
        branchesSortField={this.props.branchesSortField}
        branchesOrder={this.props.branchesOrder}
        onFilterBranches={this.onFilterBranches}
        onPageBranches={this.onPageBranches}
        onSortBranches={this.onSortBranches}
        branchesOnSelectionChange={this.branchesOnSelectionChange}
        onRemoveSelectedBranches={this.onRemoveSelectedBranches}

        dialogAddBranches={this.props.dialogAddBranches}
        dialogAddBranchesOnOpen={this.dialogAddBranchesOnOpen}
        dialogAddBranchesOnClose={this.dialogAddBranchesOnClose}
        dialogAddBranchesOnConfirm={this.dialogAddBranchesOnConfirm}
        dialogAddBranchesOnAdd={this.dialogAddBranchesOnAdd}
        dialogEditBranchesOnOpen = {this.dialogEditBranchesOnOpen}
      />
    );
    const title = `Transportadora ${this.props.record.oid || 'nova'}`
    const header = (
      <div>
        <h1>{title}</h1>
        <hr className="lxSplitter" ></hr>
        <div>
          <Toolbar
            onSave={this.props.saveHandler} saveButtonStyle='highlighted'
            onRemove={this.props.removeHandler} removeButtonStyle='danger'
            onNew={this.props.newHandler}
            onSearch={this.searchHandler}
            onExportJSON={this.onExportCarrier}
            onImportJSON={this.onImportCarrier}>

          </Toolbar>
        </div>
      </div>
    );
    return (<Page header={header} content={content} />);
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CarrierFormPageContainer);