import { types } from "./pessoa.actions";
import { Reducer } from "../../services/reducer.factory";

const initialState = {
  columns: [
    {
      field: "oid",
      header: "Código",
      columnKey: "oid",
      sortable: true,
      filter: true
    },
    {
      field: "numeroDocumento",
      header: "CPF/CNPJ",
      columnKey: "numeroDocumento",
      sortable: true,
      filter: true
    },
    {
      field: "nome",
      header: "Nome",
      columnKey: "nome",
      sortable: true,
      filter: true
    },
    {
      field: "nomeFantasia",
      header: "Nome Fantasia",
      columnKey: "nomeFantasia",
      sortable: true,
      filter: true
    }
    
  ],
  currentRecord: {
    oid: "",
    nome: "",
    nomeFantasia: "",
    numeroDocumento: "",
    numeroDocumentoRaiz: "",
    inscricaoEstadual: "",
    email: "",
    site: "",
    telefone: "",
    logradouro: "",
    numeroLogradouro: "",
    bairro: "",
    cep: "",
    beneficiarioINSS: true,
    numeroPis: "",
    cidade : "",
    complemento : "",
    contato : "",
    ativo: true,
    empresa: null
  }
};

const reducer = new Reducer(initialState, types);

export function CRUDPessoaReducer(state, action) {
  return reducer.execute(state, action);
}

