import React from 'react';
import { connect } from 'react-redux';
import { Page } from "../../../components/page/Page";
import { routes } from '../../../constants';
import Toolbar from "../../../components/toolbar/Toolbar";
import { OrderManagementForm } from './OrderManagementForm'
import { HistoryLogListPage } from '../../history-log-component';

const mapStateToProps = state => {
    const record = state.orderManagementState.order
    return {
        record
    }
}

const mapDispatchToProps = dispatch => {
    return {}
}

class OrderManagementFormPageContainer extends React.Component {

    constructor(props) {
        super(props)
        this.state={
           isVisibleHistorylog:false
        }
    }

    searchHandler = () => {
        this.props.history.push(`${routes.PATH_ORDER_MANAGEMENT}`)
    }

    onClickOpenHistoryLog =()=>{
        this.setState({ 
            isVisibleHistorylog:true
        })
    }
    onHideHistorylog=()=>{
        this.setState({ 
            isVisibleHistorylog:false
        })
    }

    render() {
        const content = (
            <React.Fragment>
                <OrderManagementForm record={this.props.record} onClickOpenHistoryLog={this.onClickOpenHistoryLog}/>
                {this.state.isVisibleHistorylog &&
                    <HistoryLogListPage  docID={this.props.record.oid} resourceType={"shipment_order"} onHide={this.onHideHistorylog}/>
                }
            </React.Fragment>
        );
        const title = `Pedido ${this.props.record.oid || 'nova'}`
        const header = (
            <div>
                <h1>{title}</h1>
                <div>
                    <Toolbar onSearch={this.searchHandler} onShowHistoryLog={this.props.record.oid ? this.onClickOpenHistoryLog : null} />
                </div>
            </div>
        );

        return (<Page header={header} content={content} />);
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderManagementFormPageContainer);