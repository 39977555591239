import { types } from "./region.actions";
import { Reducer } from "../../services/reducer.factory";

const initialState = {
  columns: [
    {
      field: "oid",
      header: "Código",
      columnKey: "oid",
      sortable: true,
      filter: true
    },
    {
      field: "name",
      header: "Nome",
      columnKey: "name",
      sortable: true,
      filter: true
    },
    {
      field: "carrier.name",
      header: "Transportadora",
      columnKey: "carrier.name",
      sortable: true,
      filter: true
    },
  ],
  locationsColumns: [
    {
      field: "description",
      header: "Localizações",
      columnKey: "description",
      sortable: true,
      filter: true
    }
  ],
  currentRecord: {
    oid: "",
    name: "",
    carrier: {oid : null, CNPJ: "", name :""},
    locations : [],
    restrictions : [],
    
    //TODO the fields below most be removed from the current record  
    type: null,
    parent: null,
    //TODO MUST BE REMOVED
    currentLocation: null,
    currentRestriction: null,
    selectedLocations: [],
    selectedRestrictions: [],
  },
  types: ["COUNTRY", "STATE", "CITY", "ZIP_CODE_RANGE", "REGION"],
  lookupCarrier: {
    visible: false,
    modal: true,
    header: "Transportadoras"
  },
  lookupParent: {
    disabled: true,
    visible: false,
    modal: false,
    header: "Pertence a"
  }, 
  lookupLocation: {
    visible: false,
    modal: false,
    header: "Localizações",
    records: [],
    selectedRecords: [],
    searchText: "",
    tableColumns: [
      {
        field: "name",
        header: "Nome",
        columnKey: "name",
        sortable: true,
        filter: true
      }
    ]
  }, 
  lookupRestriction: {
    visible: false,
    modal: false,
    header: "Restrições",
    records: [],
    selectedRecords: [],
    searchText: "",
    tableColumns: [
      {
        field: "name",
        header: "Nome",
        columnKey: "name",
        sortable: true,
        filter: true
      }
    ]
  },
  dialogAddLocations: {
    visible: false,
    modal: true,
    header: "Adicionar Localizações"
  },
  dialogAddRestrictions: {
    visible: false,
    modal: true,
    header: "Adicionar Restrições"
  }
};

const reducer = new Reducer(initialState, types);

export function CRUDRegionReducer(state, action) {
  state = reducer.execute(state, action);
  return execute(state, action);
}

function execute(state, action) {
  let result = state;
  switch (action.type) {
    case types.SET_LOOKUP_CARRIER_VISIBLE:
      return setLookupCarrierVisible(state, action);
    case types.SET_LOOKUP_LOCATION_VISIBLE:
      return setLookupLocationVisible(state, action);
    case types.SET_LOOKUP_RESTRICTION_VISIBLE:
      return setLookupRestrictionVisible(state, action);
    case types.REMOVE_SELECTED_LOCATIONS:
      return removeSelectedLocations(state, action);
    case types.SET_DIALOG_ADD_LOCATIONS_VISIBLE:
      return setDialogAddLocationsVisible(state, action);
    case types.ADD_LOCATION:
      return addLocation(state, action);
    case types.ADD_LOCATIONS_CURRENT_RECORD:
      return addLocationsCurrentRecord(state, action);
    case types.REMOVE_SELECTED_RESTRICTIONS:
      return removeSelectedRestrictions(state, action);
    case types.SET_DIALOG_ADD_RESTRICTIONS_VISIBLE:
      return setDialogAddRestrictionsVisible(state, action);
    case types.ADD_RESTRICTION:
      return addRestriction(state, action);
    case types.ADD_RESTRICTIONS_CURRENT_RECORD:
      return addRestrictionsCurrentRecord(state, action)
    case types.SET_LOOKUP_PARENT_VISIBLE:
      return setLookupParentVisible(state, action);
    case types.SET_LOOKUP_PARENT_DISABLED:
      return setLookupParentDisabled(state, action);
    case types.SET_LOOKUP_LOCATION_RECORDS:
      return setLookupLocationRecords(state, action);
    case types.ADD_LOOKUP_LOCATION_RECORDS:
      return addLookupLocationRecords(state, action);
    case types.FILTER_SELECTED_RECORDS_LOOKUP_LOCATION_RECORDS:
      return filterSelectedRecordsLookupLocationRecords(state, action);
    case types.SET_LOOKUP_LOCATION_SELECTED_RECORDS:
      return setLookupLocationSelectedRecords(state, action);
    case types.SET_LOOKUP_LOCATION_SEARCH_TEXT:
      return setLookupLocationSearchText(state, action);
    case types.SET_LOOKUP_RESTRICTION_RECORDS:
      return setLookupRestrictionRecords(state, action);
    case types.SET_LOOKUP_RESTRICTION_SELECTED_RECORDS:
      return setLookupRestrictionSelectedRecords(state, action);
    case types.SET_LOOKUP_RESTRICTION_SEARCH_TEXT:
      return setLookupRestrictionSearchText(state, action);
    case types.ADD_LOOKUP_RESTRICTION_RECORDS:
      return addLookupRestrictionRecords(state, action);
    case types.FILTER_SELECTED_RECORDS_LOOKUP_RESTRICTION_RECORDS:
      return filterSelectedRecordsLookupRestrictionRecords(state, action);
    default:
      return result;
  }
}


function setLookupCarrierVisible(state, action) {
  let lookupCarrier = state.lookupCarrier;
  lookupCarrier = Object.assign({}, lookupCarrier, { visible: action.visible });
  return Object.assign({}, state, { lookupCarrier })
}
function setLookupLocationVisible(state, action) {
  let lookupLocation = state.lookupLocation;
  lookupLocation = Object.assign({}, lookupLocation, { visible: action.visible });
  return Object.assign({}, state, { lookupLocation })
}
function setLookupRestrictionVisible(state, action) {
  let lookupRestriction = state.lookupRestriction;
  lookupRestriction = Object.assign({}, lookupRestriction, { visible: action.visible });
  return Object.assign({}, state, { lookupRestriction })
}

function setLookupRestrictionRecords(state, action) {
  let lookupRestriction = state.lookupRestriction;
  lookupRestriction = Object.assign({}, lookupRestriction, { records: action.records });
  return Object.assign({}, state, { lookupRestriction })
}

function addLookupRestrictionRecords(state, action) {
  let lookupRestriction = state.lookupRestriction;
  let newRecords = action.records;
  const oldRecords = lookupRestriction.records;


  newRecords = newRecords.filter(newRecord =>
    oldRecords.filter(oldRecord => oldRecord.oid === newRecord.oid) <= 0)

  const records = [...oldRecords, ...newRecords]
  lookupRestriction = Object.assign({}, lookupRestriction, { records });
  return Object.assign({}, state, { lookupRestriction })
}

function filterSelectedRecordsLookupRestrictionRecords(state, action) {
  let lookupRestriction = state.lookupRestriction;
  let records = lookupRestriction.records;
  const selectedRecords = lookupRestriction.selectedRecords;
  records = records.filter(record =>
    selectedRecords.filter(selectedRecord => selectedRecord.oid === record.oid) <= 0)
    lookupRestriction = Object.assign({}, lookupRestriction, { records });
  return Object.assign({}, state, { lookupRestriction })
}

function setLookupRestrictionSelectedRecords(state, action) {
  let lookupRestriction = state.lookupRestriction;
  lookupRestriction = Object.assign({}, lookupRestriction, { selectedRecords: action.selectedRecords });
  return Object.assign({}, state, { lookupRestriction })
}

function setLookupRestrictionSearchText(state, action) {
  let lookupRestriction = state.lookupRestriction;
  lookupRestriction = Object.assign({}, lookupRestriction, { searchText: action.searchText });
  return Object.assign({}, state, { lookupRestriction })
}

//TODO adaptar para a nova estrutura do backend GO - já alterado
function removeSelectedLocations(state, action) {
  let locations = state.currentRecord.locations;
  let selectedLocations = state.currentRecord.selectedLocations;
  locations = locations.filter(location => {
    const existe = selectedLocations.filter(selectedLocation => {
      return selectedLocation.oid === location.oid
    });
    return existe.length > 0 ? false : true;
  })

  let currentRecord = Object.assign({}, state.currentRecord, { locations });
  return Object.assign({}, state, { currentRecord });
}

function setDialogAddLocationsVisible(state, action) {
  let dialogAddLocations = state.dialogAddLocations;
  dialogAddLocations = Object.assign({}, dialogAddLocations, { visible: action.visible });
  return Object.assign({}, state, { dialogAddLocations })
}

function setLookupLocationRecords(state, action) {
  let lookupLocation = state.lookupLocation;
  lookupLocation = Object.assign({}, lookupLocation, { records: action.records });
  return Object.assign({}, state, { lookupLocation })
}

function addLookupLocationRecords(state, action) {
  let lookupLocation = state.lookupLocation;
  let newRecords = action.records;
  const oldRecords = lookupLocation.records;


  newRecords = newRecords.filter(newRecord =>
    oldRecords.filter(oldRecord => oldRecord.oid === newRecord.oid) <= 0)

  const records = [...oldRecords, ...newRecords]
  lookupLocation = Object.assign({}, lookupLocation, { records });
  return Object.assign({}, state, { lookupLocation })
}

function filterSelectedRecordsLookupLocationRecords(state, action) {
  let lookupLocation = state.lookupLocation;
  let records = lookupLocation.records;
  const selectedRecords = lookupLocation.selectedRecords;
  records = records.filter(record =>
    selectedRecords.filter(selectedRecord => selectedRecord.oid === record.oid) <= 0)
  lookupLocation = Object.assign({}, lookupLocation, { records });
  return Object.assign({}, state, { lookupLocation })
}

function setLookupLocationSelectedRecords(state, action) {
  let lookupLocation = state.lookupLocation;
  lookupLocation = Object.assign({}, lookupLocation, { selectedRecords: action.selectedRecords });
  return Object.assign({}, state, { lookupLocation })
}

function setLookupLocationSearchText(state, action) {
  let lookupLocation = state.lookupLocation;
  lookupLocation = Object.assign({}, lookupLocation, { searchText: action.searchText });
  return Object.assign({}, state, { lookupLocation })
}

//TODO MUST BE REMOVED
function addLocation(state, action) {
  const alreadyAdded = state.currentRecord.children.filter(location => {
    return location.oid === state.currentRecord.currentLocation.oid;
  }).length > 0;
  if (!alreadyAdded) {
    let children = state.currentRecord.children;

    children = [...children, state.currentRecord.currentLocation];

    let currentRecord = Object.assign({}, state.currentRecord, { children });
    return Object.assign({}, state, { currentRecord });
  }
  return state;
}

//TODO adaptar para a nova estrutura do backend GO * Filtrar locations and restrictions - ja alterado
function addLocationsCurrentRecord(state, action) {
  //filtering new records to avoid duplicity

  let newRecords = state.lookupLocation.records;
  const oldLocations = state.currentRecord.locations;
  const oldRestrictions = state.currentRecord.restrictions;
  newRecords = newRecords.filter(newRecord =>
    oldLocations.filter(oldLocation => oldLocation.oid === newRecord.oid).length <= 0);

  newRecords = newRecords.filter(newRecord =>
    oldRestrictions.filter(oldRestriction => oldRestriction.oid === newRecord.oid).length <= 0);
   
  //Add to the current record
  let locations = state.currentRecord.locations;
  locations = [...locations, ...newRecords];
  let currentRecord = Object.assign({}, state.currentRecord, { locations });
  return Object.assign({}, state, { currentRecord });
}

//TODO adaptar para a nova estrutura do backend GO - já alterado
function removeSelectedRestrictions(state, action) {
  let restrictions = state.currentRecord.restrictions;
  let selectedRestrictions = state.currentRecord.selectedRestrictions;
  restrictions = restrictions.filter(restriction => {
    const existe = selectedRestrictions.filter(selectedRestriction => {
      return selectedRestriction.oid === restriction.oid
    });
    return existe.length > 0 ? false : true;
  })

  let currentRecord = Object.assign({}, state.currentRecord, { restrictions });
  return Object.assign({}, state, { currentRecord });
}

function setDialogAddRestrictionsVisible(state, action) {
  let dialogAddRestrictions = state.dialogAddRestrictions;
  dialogAddRestrictions = Object.assign({}, dialogAddRestrictions, { visible: action.visible });
  return Object.assign({}, state, { dialogAddRestrictions })
}

//TODO MUST BE REMOVED
function addRestriction(state, action) {
  const alreadyAdded = state.currentRecord.children.filter(location => {
    return location.oid === state.currentRecord.currentRestriction.oid;
  }).length > 0;
  if (!alreadyAdded) {
    let children = state.currentRecord.children;

    children = [...children, state.currentRecord.currentRestriction];

    let currentRecord = Object.assign({}, state.currentRecord, { children });
    return Object.assign({}, state, { currentRecord });
  }
  return state;
}

// TODO adaptar para a nova estrutura do backend GO * Filtrar locations and restrictions - já alterado
function addRestrictionsCurrentRecord(state, action) {
  //filtering new records to avoid duplicity
  let newRecords = state.lookupRestriction.records;  
  const oldLocations = state.currentRecord.locations;
  const oldRestrictions = state.currentRecord.restrictions;
  newRecords = newRecords.filter(newRecord =>
    oldLocations.filter(oldLocation => oldLocation.oid === newRecord.oid).length <= 0);

  newRecords = newRecords.filter(newRecord =>
    oldRestrictions.filter(oldRestriction => oldRestriction.oid === newRecord.oid).length <= 0);
  
 
  //Add to the current record
  let restrictions = state.currentRecord.restrictions;
  restrictions = [...restrictions, ...newRecords];
  let currentRecord = Object.assign({}, state.currentRecord, { restrictions });
  return Object.assign({}, state, { currentRecord });
}

function setLookupParentVisible(state, action) {
  let lookupParent = state.lookupParent;
  lookupParent = Object.assign({}, lookupParent, { visible: action.visible });
  return Object.assign({}, state, { lookupParent })
}


function setLookupParentDisabled(state, action) {
  let lookupParent = state.lookupParent;
  lookupParent = Object.assign({}, lookupParent, { disabled: action.disabled });
  return Object.assign({}, state, { lookupParent })
}


