import React from 'react';
import { DataTable } from 'primereact/datatable';

export const PerfilList = (props) => (
  <DataTable
    selectionMode="single"
    onFilter={props.onFilter}
    onSort={props.onSort}
    onPage={props.onPage}
    value={props.records}
    lazy={true}
    totalRecords={props.totalRecords}
    sortOrder={props.order}
    sortField={props.sortField}
    paginator={true}
    first={props.first}
    rows={props.rows}
    emptyMessage="Não foram encontrados registros"
    rowsPerPageOptions={[5, 10, 20, 50]}
    onRowClick={props.onRowClick}
    scrollable={true}
    scrollHeight="600px"
  >
    {props.columns}

  </DataTable>
)
