
    import React from 'react';
    import PalletStandardFormPageContainer from './PalletStandardFormPageContainer';
    
    export class PalletStandardFormPage extends React.Component{
        render(){
            return (
                <PalletStandardFormPageContainer {...this.props}/>
            )
        }
    }
    