import React from 'react';
import PermissaoListPageContainer from './PermissaoListPageContainer';

export class PermissaoListPage extends React.Component{

    render(){
        return (
            <PermissaoListPageContainer {...this.props}/>
        )
    }
}
