import React from 'react';
import { Card } from 'primereact/card';
import './CardChart.scss';

class CardChart extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            showGhost: false,
            expanded: false,            
            title: "",
            header: "",
            info: "",
            content: {}
        }
    }
    optionsFooterHandler = (e) => {
        this.setState((prev) => {
            return {
                showGhost: !prev.showGhost

            }
        })
    }

    expandChartHandler = (e) => {
        this.setState((prev) => {
            return {
                expanded: !prev.expanded,
                showGhost: false
            }
        })
    }

    leftButtonHandler = (e) => {
        this.props.leftButtonOnClick && this.props.leftButtonOnClick(e)
        
    }
    rightButtonHandler = (e) => {
        this.props.rightButtonOnClick && this.props.rightButtonOnClick(e)
    }
    render() {
        const ghostClasses = ['lxCardChart__footer-ghost'];
        const containerClasses = ['lxCardContainer']
        const mainPanelClasses = ['lxCardChart']
        const footerClasses = ['lxCardChart__footer']
        const footerActions = ['footer__footer-actions']
        const headerActions = ['header__actions']        
        let headerActionsButton = 'pi pi-window-maximize'
        if (this.state.showGhost && !this.state.expanded) {
            ghostClasses.push('show')
        }
        if (this.state.expanded) {
            containerClasses.push('expanded');
            mainPanelClasses.push('expanded');
            footerClasses.push('hide')
            headerActionsButton = 'pi pi-window-minimize'
        }
        if (this.props.footer) {
            footerActions.push('show')
        }
        if (this.props.expandChart) {
            headerActions.push('show')
        }        
        return (
            <div className={containerClasses.join(' ')} >
                <Card style={{ padding: "0" }}>
                    <div className={mainPanelClasses.join(' ')}>
                        <div className="lxCardChart__header">
                            <div className="header__title">
                                <p>{this.props.title && this.props.title}</p>
                            </div>
                            {/* <div className="header__actions"> */}
                            <div className={headerActions.join(' ')}>
                                <button onClick={this.expandChartHandler}>
                                    <i className={headerActionsButton}></i>
                                </button>
                            </div>
                        </div>
                        <div className="lxCardChart__body-header">
                            <p>{this.props.header && this.props.header}</p>
                        </div>
                        <div className="lxCardChart__body-info">
                            <p>{this.props.info && this.props.info}</p>
                        </div>
                        <div className="lxCardChart__body">
                            <div className="body__action-left">
                                <button
                                    // onClick={this.leftButtonHandler}
                                    onClick={this.props.leftButtonClick}
                                ><i className="pi pi-arrow-circle-left"></i></button>
                            </div>
                            <div className="body__content" >
                                {this.props.content && this.props.content}
                            </div>
                            <div className="body__action-right">                            
                                <button
                                    onClick={this.props.rightButtonOnClick}
                                    disabled={!this.props.showRightButton}
                                ><i className="pi pi-arrow-circle-right"></i></button>
                            </div>
                        </div>
                        <div className={footerClasses.join(' ')}>                            
                            <div className={footerActions.join(' ')}>
                                <button onClick={this.optionsFooterHandler}>
                                    <i className="pi pi-ellipsis-v"></i>
                                </button>
                            </div>
                        </div>
                        <div className={ghostClasses.join(' ')}>
                            <div className="footer-ghost__content">
                                {this.props.footer}
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
        )
    }


}

export default CardChart