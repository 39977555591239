import React from 'react';
import { Card } from 'primereact/card';
import { CheckboxConfigurationComponent } from './CheckboxConfigurationComponent';
import { InputConfiguration } from './InputConfiguration';
import { TimeConfigurationsComponent } from './TimeConfigurationsComponent'
import { TextConfigurationsComponent } from './TextConfigurationsComponent'
import { PasswordConfigurationsComponent } from './PasswordConfigurationsComponent';


const TitleConfigurations = (props) => {
    return (
        <div className="p-grid">
            <div className="p-col-6" style={{ margin: '10px 0 10px 0', fontWeight: 'bold' }}>{props.data.title}</div>
        </div>
    )
}


export const ConfigurationPanel = (props) => {

    const configurations = props.configurations.map((config,index) => {
        let result = null;
        switch (config.componentType) {
            case 'INPUT':
                result = (<InputConfiguration key={config.id+"-"+index} data={config.data} />);
                break;
            case 'TEXT':
                result = (<TextConfigurationsComponent key={config.id+"-"+index} data={config.data} />);
                break;
            case 'PASSWORD':
                result = (<PasswordConfigurationsComponent key={config.id+"-"+index} data={config.data} />);
                break;
            case 'TIME':
                result = (<TimeConfigurationsComponent key={config.id+"-"+index} title={config.title} data={config.data} />);
                break;
            case 'CHECKBOX':
                result = (<CheckboxConfigurationComponent key={config.id+"-"+index} data={config.data} />);
                break;
            case 'TITLE':
                result = (<TitleConfigurations key={config.id+"-"+index} data={config.data} />);
                break;
        }
        return result;
    })

    return (
        <Card>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h2 id={props.id}> {props.title} </h2>
            </div>
            <hr className="lxSplitter" ></hr>
            {configurations}
        </Card>

    )
}