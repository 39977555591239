import truck_icon from '../assets/images/menu/truck.png';
import arrow_icon from '../assets/images/menu/arrow.png';
import finances_icon from '../assets/images/menu/finances.png';
import freightEngine_icon from '../assets/images/menu/freightEngineNew.png'
import system_icon from '../assets/images/menu/system.png'
import planning_icon from '../assets/images/menu/planning.png'
import cenci_icon from '../assets/images/logo-clients/cenci.jpeg'
import instagram_icon from '../assets/images/icons/instagram-logo_32x32.png'
import facebook_icon from '../assets/images/icons/facebook-logo_32x32.png'
import linkedin_icon from '../assets/images/icons/linkedin-logo_32x32.png'
import valduga_icon from '../assets/images/logo-clients/valduga.jpeg'
import arke_icon from '../assets/images/logo-clients/arke.jpeg'
import icms_icon from '../assets/images/icons/icms.png'
import calendar_icon from '../assets/images/icons/calendar.png'
import mark_icon from '../assets/images/icons/mark.png'
import percentual_note_icon from '../assets/images/icons/percentual_note.png'
import box_dollar_sign_icon from '../assets/images/icons/box_dollar_sign.png'
import clock_person_icon from '../assets/images/icons/clock_person.png'
import clock_truck_icon from '../assets/images/icons/clock_truck.png'
import dollar_sign_icon from '../assets/images/icons/dollar_sign.png'
import truck_person_icon from '../assets/images/icons/truck_person.png'
import zeze_icon from '../assets/images/logo-clients/zeze.jpeg'
import truck_success from '../assets/images/icons/truck_success.png'
import truck_failure from '../assets/images/icons/truck_failure.png'
import truck_blue_border from '../assets/images/icons/truck_blue_border.png'
import truck_canceled from '../assets/images/icons/truck_canceled.png'
import truck_warning from '../assets/images/icons/truck_warning.png'
import truck_success_with_pendings from '../assets/images/icons/truck_success_with_pendings.png'
import truck_normal from '../assets/images/icons/truck_normal.png'
import objeto_brasil_icon from '../assets/images/logo-clients/objeto-brasil.jpeg'
import roni_icon from'../assets/images/logo-clients/roni.jpeg'
import pgs_icon from'../assets/images/logo-clients/pgs.jpeg'
import xalingo_icon from'../assets/images/logo-clients/xalingo.jpeg'
import pomerode_alimentos_icon from'../assets/images/logo-clients/pomerode-alimentos.jpeg'
import vinum_day from '../assets/images/logo-clients/vinum_day.jpeg'
import martinazo from '../assets/images/logo-clients/martinazo.jpeg'
import queroQuero from '../assets/images/logo-clients/quero-quero.jpeg'
import laticiniosSaoVicente from '../assets/images/logo-clients/laticinios_sao_vicente.jpeg'


const icons = {
    "truck": truck_icon,
    "arrow": arrow_icon,
    "finances": finances_icon,
    "freightEngine": freightEngine_icon,
    "system": system_icon,
    "planning": planning_icon,
    "instagram": instagram_icon,
    "facebook": facebook_icon,
    "linkedin": linkedin_icon,
    "cenci": cenci_icon,
    "valduga": valduga_icon,
    "arke": arke_icon,
    "zeze" : zeze_icon,
    "icms": icms_icon,
    "calendar": calendar_icon,
    "mark": mark_icon,
    "percentual_note": percentual_note_icon,
    "box_dollar_sign": box_dollar_sign_icon,
    "clock_person": clock_person_icon,
    "clock_truck": clock_truck_icon,
    "dollar_sign" : dollar_sign_icon,
    "truck_person" : truck_person_icon,
    "truck_success" : truck_success,
    "truck_failure" : truck_failure,
    "truck_canceled" : truck_canceled,
    "truck_warning" : truck_warning,
    "truck_success_pending" : truck_success_with_pendings,
    "truck_normal" : truck_normal,
    "objeto_brasil" : objeto_brasil_icon,
    "roni" : roni_icon,
    "pgs" : pgs_icon,
    "xalingo" : xalingo_icon,
    "pomerode-alimentos" : pomerode_alimentos_icon,
    "vinum_day" : vinum_day,
    "martinazo" : martinazo,
    "quero_quero" : queroQuero,
    "laticinios_sao_vicente": laticiniosSaoVicente,
    "truck_blue_border" : truck_blue_border,
    
}

export const getIcon = (iconName, showDefaultIcon = true) => {
    if (showDefaultIcon) {
        return icons[iconName] || truck_icon; //truck icon is the default icon
    }
    return icons[iconName]
}