import React from 'react'
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import { InputTextarea } from "primereact/inputtextarea";
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Card } from 'primereact/card';
import { LookupField } from '../../../../components/lookup/LookupField';
import { LookupTable } from '../../../../components/lookup/LookupTable';
import { LxButtonActions } from '../../../../components/lx/button-actions/LxButtonActions';

export const LxFreightTableMainForm = (props) => {
    const addBusinessUnitsDisabled = props.record.currentBusinessUnit === null;

    let carrier = null;

    if (props.record && props.record.carrier) {
        carrier = props.record.carrier
        if (carrier.person && !carrier.name) {
            carrier.name = carrier.person.name;
            carrier.documentNumber = carrier.person.documentNumber;
            carrier.tradeName = carrier.person.tradeName;
        }
    }
    return (
        <div className="lxFreightTableMainForm" style={{ "padding": "7px" }}>
            <Card>
                <h3>Cadastro</h3>

                <div className="p-grid">
                    <div className="p-col-6">
                        <div className="p-grid p-fluid">

                            <div className="p-col-4 form-field-container">
                                <label>Código</label>
                                <InputText value={props.record.oid} onChange={props.changeHandler} name="oid" disabled={true} />
                            </div>
                            <div className="p-col-8 form-field-container"> </div>
                            <div className="p-col-2 form-field-container">
                                <label>Ativo</label>
                                <InputSwitch checked={props.record.active} onChange={props.changeHandler} name="active" />
                            </div>
                            <div className="p-col-5 form-field-container">
                                <label className="lxRequiredField">Início da vigência</label>
                                <Calendar value={props.record.initialValidity} showTime={false} onChange={props.changeHandler} name="initialValidity" disabled={false} dateFormat="dd/mm/yy" />
                            </div>
                            <div className="p-col-5 form-field-container">
                                <label className="lxRequiredField">Final da vigência</label>
                                <Calendar value={props.record.finalValidity} showTime={false} onChange={props.changeHandler} name="finalValidity" disabled={false} dateFormat="dd/mm/yy" />
                            </div>
                            <div className="p-col-12 form-field-container">
                                <label className="lxRequiredField">Transportadora</label>
                                <LookupField
                                    value={carrier}
                                    placeholder="Transportadora"
                                    name="record.carrier"
                                    field="name"
                                    onComplete={props.lookupCarrierOnComplete}
                                    onInputFilter={props.lookupCarrierOnInputFilter}
                                    editDisabled={props.lookupCarrier.editDisabled}
                                    onEditField={props.lookupCarrierOnEdit}
                                    onClick={props.lookupCarrierOnClick}

                                    visible={props.lookupCarrier.visible}
                                    modal={props.lookupCarrier.modal}
                                    header={props.lookupCarrier.header}
                                    onHide={props.lookupCarrierOnHide}
                                    onConfirm={props.lookupCarrierOnConfirm}
                                    onCancel={props.lookupCarrierOnCancel}
                                    records={props.lookupCarrier.records}
                                    columns={props.lookupCarrier.columns}
                                    totalRecords={props.lookupCarrier.totalRecords}
                                    sortOrder={props.lookupCarrier.order}
                                    sortField={props.lookupCarrier.sortField}
                                    first={props.lookupCarrier.first}
                                    rows={props.lookupCarrier.rows}
                                    onPage={props.lookupCarrierOnPage}
                                    onFilter={props.lookupCarrierOnFilter}
                                    onSort={props.lookupCarrierOnOnSort}

                                />
                            </div>

                            <div className="p-col-12 form-field-container">
                                <label className="lxRequiredField">Nome</label>
                                <InputText value={props.record.name} onChange={props.changeHandler} name="name" disabled={false} />
                            </div>
                            <div className="p-col-4 form-field-container">
                                <label>Fator de Cubagem</label>
                                <InputText value={props.record.cubingFactor || ""} keyfilter="pnum" onChange={props.changeHandler} name="cubingFactor" />
                            </div>
                            <div className="p-col-8 form-field-container"> </div>
                            <div className="p-col-4 form-field-container">
                                <label>Label ICMS Customizado</label>
                                <InputText value={props.record.labelIcms || ""} onChange={props.changeHandler} name="labelIcms" />
                            </div>
                            <div className="p-col-8 form-field-container"> </div>

                            <div className="p-col-6" style={{display:'flex'}}>
                                <div className=" form-field-container"  style={{width:"180px", marginRight: "10px"}} title={"Valor da taxa por entrega/coleta"}>
                                    <label>Taxa Coleta/Entrega</label>
                                    <InputText value={props.record.taxaColetaEntrega.valorTaxa || ""} onChange={props.changeHandler} name="taxaColetaEntrega.valorTaxa" />
                                </div>
                                <div className=" form-field-container" title={"Aprtir de quantas entregas ou coletas deve aplicar a taxa"}>
                                    <label>Apartir De</label>
                                    <InputText value={props.record.taxaColetaEntrega.apartirDeQutde || ""} onChange={props.changeHandler} name="taxaColetaEntrega.apartirDeQutde" />
                                </div>
                            </div>
                            <div className="p-col-8 form-field-container"> </div>
                            <div className="p-col-12 form-field-container">
                                <label>Aplicar ICMS</label>
                                <InputSwitch checked={!props.record.ignoreICMS} onChange={props.changeHandler} name="ignoreICMS" />
                            </div>
                            <div className="p-col-12 form-field-container">
                                <label>ICMS Destacado</label>
                                <InputSwitch checked={props.record.icmsHighlighted} onChange={props.changeHandler} name="icmsHighlighted" />
                            </div>
                            <div className="p-col-12 form-field-container">
                                <label>Tabela Exclusiva para Matriz</label>
                                <InputSwitch checked={props.record.isOnlyMatriz} onChange={props.changeHandler} name="isOnlyMatriz" />
                            </div>
                            <div className="p-col-12 form-field-container">
                                <label>Aplicar para Matriz</label>
                                <InputSwitch checked={props.record.applyToMatriz} onChange={props.changeHandler} name="applyToMatriz" />
                            </div>
                            <div className="p-col-12 form-field-container">
                                <label>Transferência</label>
                                <InputSwitch checked={props.transfer} onChange={props.transferChangeHandler} name="isTransfer" />
                            </div>
                            <div className="p-col-12 form-field-container">
                                <label>Atende produto refrigerado</label>
                                <InputSwitch checked={props.chilledProduct} onChange={props.chilledProductChangeHandler} name="chilledProduct" />
                            </div>
                            <div className="p-col-12">
                                <label>Unidades</label>
                            </div>
                            <div className="p-col-12">
                                <LxButtonActions
                                    hint={{ add: 'Adicionar Unidades', remove: 'Excluir Unidades Selecionadas' }}
                                    onAdd={props.dialogAddBusinessUnitsOnOpen}
                                    onRemove={props.onRemoveSelectedBusinessUnits}
                                    disabled={props.disabledButtonBusinessUnits}
                                    header={""}
                                />


                                <Dialog
                                    header={props.dialogAddBusinessUnits.header}
                                    visible={props.dialogAddBusinessUnits.visible}
                                    style={{ width: '50vw' }}
                                    modal={props.dialogAddBusinessUnits.modal}
                                    baseZIndex={1}
                                    onHide={props.dialogAddBusinessUnitsOnClose}
                                    footer={
                                        <div>
                                            <button
                                                title="Confirmar"
                                                className={addBusinessUnitsDisabled !== true ? "lognex-btn-highlighted" : ""}
                                                onClick={props.dialogAddBusinessUnitsOnConfirm}
                                                disabled={addBusinessUnitsDisabled} >
                                                <i className="pi pi-check"></i>
                                            </button>
                                            <button
                                                title="Incluir"
                                                className={addBusinessUnitsDisabled !== true ? "lognex-btn-highlighted" : ""}
                                                onClick={props.dialogAddBusinessUnitsOnAdd}
                                                disabled={addBusinessUnitsDisabled} >
                                                <i className="pi pi-plus"></i>
                                            </button>
                                            <button
                                                title="Cancelar"
                                                className="lognex-btn-danger"
                                                onClick={props.dialogAddBusinessUnitsOnClose} >
                                                <i className="pi pi-times"></i>
                                            </button>
                                        </div>}>

                                    <div className="p-col-12">
                                        <label className="lxRequiredField">Unidades</label>
                                        <LookupField
                                            value={props.lookupBusinessUnit.currentBusinessUnit}
                                            placeholder="Unidades"
                                            name="currentBusinessUnit.tradeName"
                                            field="tradeName"
                                            onComplete={props.lookupBusinessUnitOnComplete}
                                            onInputFilter={props.lookupBusinessUnitOnInputFilter}
                                            editDisabled={props.lookupBusinessUnit.editDisabled}
                                            onEditField={props.lookupBusinessUnitOnEdit}
                                            onClick={props.lookupBusinessUnitOnClick}

                                            visible={props.lookupBusinessUnit.visible}
                                            modal={props.lookupBusinessUnit.modal}
                                            header={props.lookupBusinessUnit.header}
                                            onHide={props.lookupBusinessUnitOnHide}
                                            onConfirm={props.lookupBusinessUnitOnConfirm}
                                            onCancel={props.lookupBusinessUnitOnCancel}
                                            records={props.lookupBusinessUnit.records}
                                            columns={props.lookupBusinessUnit.columns}
                                            totalRecords={props.lookupBusinessUnit.totalRecords}
                                            sortOrder={props.lookupBusinessUnit.order}
                                            sortField={props.lookupBusinessUnit.sortField}
                                            first={props.lookupBusinessUnit.first}
                                            rows={props.lookupBusinessUnit.rows}
                                            onPage={props.lookupBusinessUnitOnPage}
                                            onFilter={props.lookupBusinessUnitOnFilter}
                                            onSort={props.lookupBusinessUnitOnOnSort}
                                        />
                                    </div>
                                </Dialog>

                                <DataTable
                                    selection={props.selectedBusinessUnits}
                                    onSelectionChange={props.businessUnitsOnSelectionChange}
                                    onFilter={props.onFilterBusinessUnits}
                                    onSort={props.onSortBusinessUnits}
                                    onPage={props.onPageBusinessUnits}
                                    value={props.businessUnitsRecords}
                                    lazy={true}
                                    totalRecords={props.businessUnitsTotalRecords}
                                    sortOrder={props.businessUnitsOrder}
                                    sortField={props.businessUnitsSortField}
                                    paginator={true}
                                    first={props.businessUnitsFirst}
                                    rows={props.businessUnitsRows}
                                    emptyMessage="Não foram encontrados registros"
                                    rowsPerPageOptions={[5, 10, 20, 50]}
                                >

                                    {props.businessUnitsColumns}
                                </DataTable>
                            </div>
                            <div className="p-col-12 form-field-container">
                                <label>Observações</label>
                                <InputTextarea value={props.record.note} onChange={props.changeHandler} name="note" disabled={false} />
                            </div>
                        </div>
                    </div>
                </div>

            </Card>
        </div>
    )
}