
    import { validate } from './validations';
    import { build } from '../../services/crudFactory';
    import { Action } from '../../services/actions.factory';
    const BASE_PATH = '/lognex/pallets';
    const prefix = 'crudPallet';
    const crud = build(validate, BASE_PATH);

    const newTypes = {
        
      SET_LOOKUP_PALLETSTANDARD_VISIBLE: `${prefix}setLookupPalletStandardVisible`
    }

    const actions = new Action(prefix, crud);

    const types = Object.assign({}, actions.types, newTypes);
    
    
        actions.setLookupPalletStandardVisible = (visible) => {
        return {
            type: types.SET_LOOKUP_PALLETSTANDARD_VISIBLE,
            visible
        }
        }

    export { types, actions }