import { actions as clientActions } from '../../client/client.actions';
import { actions as locationActions } from '../../location/location.actions';

export const businessUnitDispatchToProps = ({ dispatch, actions, unidadeActions }) => {
  return {
    lookupBusinessUnitSetVisible: visible => dispatch(actions.setLookupBusinessUnitVisible(visible)),
    lookupBusinessUnitPaginate: paginationParams => dispatch(unidadeActions.paginate(paginationParams)),
    lookupBusinessUnitSortOrder: sortParams => dispatch(unidadeActions.sortOrder(sortParams)),
    lookupBusinessUnitApplyFilter: (filterParams) => {
      dispatch(unidadeActions.setType(null));
      dispatch(unidadeActions.applyFilter(filterParams))
    },
    lookupBusinessUnitLoad: () => dispatch(unidadeActions.load()),
    setCurrentBusinessUnit: (businessUnit) => {
      if (businessUnit) { 
        const { oid, label, codigo, tradeName, oidPessoa, cnpj,documentNumber } = businessUnit;
        const currentBusinessUnit = {
          oid,
          label,
          codigo,
          tradeName,
          oidPessoa,
          cnpj,
          documentNumber
        }
        dispatch(actions.setCurrentBusinessUnit(currentBusinessUnit));
      } else {
        dispatch(actions.setCurrentBusinessUnit(null));
      }
    },
    setSelectedBusinessUnits: (businessUnits) => {
      dispatch(actions.setSelectedBusinessUnits(businessUnits != null ? businessUnits : []));
    },
    removeSelectedBusinessUnits: () => {
      dispatch(actions.removeSelectedBusinessUnits());
    },
    setDialogAddBusinessUnitsVisible: (visible) => {
      dispatch(actions.setDialogAddBusinessUnitsVisible(visible))
    },
    addBusinessUnit: () => {
      dispatch(actions.addBusinessUnit())
    },

  }
};

export const carrierDispatchToProps = ({ dispatch, actions, carrierActions }) => {
  return {
    lookupCarrierSetVisible: visible => dispatch(actions.setLookupCarrierVisible(visible)),
    lookupCarrierPaginate: paginationParams => dispatch(carrierActions.paginate(paginationParams)),
    lookupCarrierSortOrder: sortParams => dispatch(carrierActions.sortOrder(sortParams)),
    lookupCarrierApplyFilter: filterParams => {
      dispatch(carrierActions.setType(null));
      dispatch(carrierActions.applyFilter(filterParams))
    },
    lookupCarrierLoad: () => dispatch(carrierActions.load()),
    setCarrier: (carrier) => {
      if (carrier) {
        const { oid, person = {}, branches, name, tradeName, documentNumber } = carrier;

        if (!person.name) {
          person.name = name;
          person.tradeName = tradeName;
          person.documentNumber = documentNumber;
        }
        const currentCarrier = {
          oid,
          name,
          tradeName,
          documentNumber,
          person,
          branches
        }
        dispatch(actions.setField('carrier', currentCarrier));
      } else {
        dispatch(actions.setField('carrier', null));
      }
    },
  }
};

export const routeDispatchToProps = ({ dispatch, actions, routeActions }) => {
  return {
    addRoute: (route) => {
      if (route) {
        dispatch(actions.addRoute(route))
      }
    },
    setCurrentRoute: (route) => { dispatch(actions.setCurrentRoute(route)) },
    setDialogRouteVisible: (visible) => { dispatch(actions.setDialogRouteVisible(visible)) },
    setDialogDuplicateRouteVisible: (visible) => { dispatch(actions.setDialogDuplicateRouteVisible(visible)) },
    removeSelectedRoutes: () => { dispatch(actions.removeSelectedRoutes()) },
    lookupRouteLoad: () => { dispatch(routeActions.load()) },
    lookupRouteApplyFilter: (filter) => { dispatch(routeActions.applyFilter(filter)) },
    lookupRouteSetVisible: (visible) => { dispatch(actions.setLookupRouteVisible(visible)) },
    lookupRoutePaginate: (paginationParams) => dispatch(routeActions.paginate(paginationParams)),
    lookupRouteSortOrder: (sortParams) => dispatch(routeActions.sortOrder(sortParams)),
    lookupRouteClearFilter: () => {
      const reload = false;
      dispatch(routeActions.paginate({ index: 0 }, reload))
      dispatch(routeActions.sortOrder({
        sortOrder: "",
        sortField: "",
      }, reload))
      dispatch(routeActions.applyFilter([], { reload }))

    },
    onSelectionChangeRoutes: (event) => {
      const routes = event.value;
      if (routes && routes.length === 1) {
        dispatch(actions.setSelectedRoute(routes[0]))
      } else {
        dispatch(actions.setSelectedRoute(null))
      }
      dispatch(actions.setSelectedRoutes(routes))
    },
    setDialogRangeVisible: (visible) => { dispatch(actions.setDialogRouteRangeVisible(visible)) },
    addRange: (rangeData) => { dispatch(actions.addRange(rangeData)) },
    setSelectedRange: (index) => { dispatch(actions.setSelectedRange(index)) },
    removeSelectedRange: () => { dispatch(actions.removeSelectedRange()) },
    setSelectedSerie: (serie) => { dispatch(actions.setSelectedSerie(serie)) },
    addComponentSelectedSerie: (component) => {
      if (component) {
        dispatch(actions.addComponentSelectedSerie(component))
      }
    },
    removeComponentFromSelectedSerie: (component) => {
      dispatch(actions.removeComponentFromSelectedSerie(component))
      //  dispatch(actions.removeComponentFromGeneralComponents(component))
      if (component.restrictedByClient || component.restrictedByLocalization || component.restrictedByBusinessUnit) {
        dispatch(actions.removeRestrictionOnRestrictionTable(component))
        dispatch(actions.deleteAddedClients(component))
        dispatch(actions.deleteAddedLocations(component))
        dispatch(actions.deleteAddedBusinessUnits(component))
      }
    },
    changeComponentFromSelectedSerie: (component, field, value, index) => { dispatch(actions.changeComponentFromSelectedSerie(component, field, value, index)) },
    setVisibleDialogAddComponentIntoSelectedSerie: (visible) => { dispatch(actions.setVisibleDialogAddComponentIntoSelectedSerie(visible)) },
    removeComponentFromGeneralComponents: (component) => {
      dispatch(actions.removeComponentFromGeneralComponents(component))
      dispatch(actions.removeComponent(component))
      if (component.restrictedByClient || component.restrictedByLocalization || component.restrictedByBusinessUnit) {
        dispatch(actions.removeRestrictionOnRestrictionTable(component))
        dispatch(actions.deleteAddedClients(component))
        dispatch(actions.deleteAddedLocations(component))
        dispatch(actions.deleteAddedBusinessUnits(component))
      }
    },
    changeComponentFromGeneralComponents: (component, field, value) => { dispatch(actions.changeComponentFromGeneralComponents(component, field, value)) },
    addComponentGeneralComponents: (component) => {
      if (component) {
        dispatch(actions.addComponentGeneralComponents(component))
        if (component.restrictedByClient || component.restrictedByLocalization || component.restrictedByBusinessUnit) {
          dispatch(actions.addRestrictionOnRestrictionTable(component))
        }
      }
    },
    setVisibleDialogAddComponentIntoGeneralComponents: (visible) => { dispatch(actions.setVisibleDialogAddComponentIntoGeneralComponents(visible)) },
    setDuplicateRoute: (route) => { dispatch(actions.setDuplicateRoute(route)) },
    setDuplicateRouteField: (field, value) => { dispatch(actions.setDuplicateRouteField(field, value)) },
    setLookupOriginVisible: (visible) => { dispatch(actions.setLookupOriginVisible(visible)) },
    setLookupDestinationVisible: (visible) => { dispatch(actions.setLookupDestinationVisible(visible)) },
    loadLocations: () => { dispatch(locationActions.load()) },
    setDialogEditSerieVisible: (visible) => { dispatch(actions.setDialogEditSerieVisible(visible)) },
    setSerie: (serie) => { dispatch(actions.setSerie(serie)) },
    removeSelectedSerie: () => { dispatch(actions.removeSelectedSerie()) },
    setSelectedRoute: (route) => dispatch(actions.setSelectedRoute(route)),
    setSelectedRoutes: (routes) => dispatch(actions.setSelectedRoutes(routes)),

  }
};

export const restrictionDispatchToProps = ({ dispatch, actions, restrictionActions }) => {
  return {
    //criar uma função que chama a action de atualização da lista de clientes
    importFilesXLSX: (xls, type) => {
      if (type === 'CLIENT') {
        dispatch(actions.importFileByClientXLSX(xls))
      } else {
        dispatch(actions.importFileByLocationsXLSX(xls))
      }
    },
    lookupClientLoad: () => {
      dispatch(clientActions.load())
      const visible = true

      dispatch(actions.lookupClientSetVisible(visible))
    },
    lookupClientOnCancel: () => {
      const visible = false
      dispatch(actions.lookupClientSetVisible(visible))
    },
    lookupClientOnSelectClient: (event) => {
      const selectedRecords = event.value
      dispatch(actions.setlookupClientSelectedRecords(selectedRecords))
    },
    lookupClientOnConfirm: (e) => {
      dispatch(actions.lookupClientAddRecords(e.selection))
      dispatch(actions.lookupClientSetVisible(false))
    },
    lookupLocationOnCancel: () => {
      dispatch(actions.lookupLocationSetVisible(false))
    },

    lookupLocationLoad: () => {
      dispatch(locationActions.load())
      const visible = true
      dispatch(actions.lookupLocationSetVisible(visible))
    },
    lookupLocationOnSelectLocation: (event) => {
      const selectedRecords = event.value
      dispatch(actions.setLookupLocationSelectedRecords(selectedRecords))
    },
    lookupLocationOnConfirm: (e) => {
      dispatch(actions.lookupLocationAddRecords(e.selection))
      dispatch(actions.lookupLocationSetVisible(false))
    },
    lookupLocationPaginate: paginationParams => dispatch(locationActions.paginate(paginationParams)),
    onSelectionChangeRestrictionsComponents: (event) => {
      const component = event.value
      dispatch(actions.setCurrentSelectedComponent(component))
      dispatch(actions.setdialogComponentName(component))
      dispatch(actions.setDialogComponentRestrictionLookupClientVisible(component))
      dispatch(actions.setDialogComponentRestrictionLookupLocationVisible(component))
      dispatch(actions.setDialogComponentRestrictionLookupUnitVisible(component))
      dispatch(actions.setAddRestrictionButtonState())
      dispatch(actions.setEditRestrictionButtonState())
    },
    dialogAddRestrictionsOnOpen: (event) => {
      const visible = true
      dispatch(actions.setDialogAddRestrictionsVisible(visible))
      dispatch(actions.setRestrictionsToDuplicate())
    },
    dialogEditRestrictionsOpen: () => {
      dispatch(actions.setDialogAddRestrictionsVisible(true))
      dispatch(actions.setLookupTableClients())
      dispatch(actions.setLookupTableLocations())
    },

    dialogAddRestrictionsOnConfirm: (event) => {
      const selectedRecords = event.value
      // Estes metodos foram adicionados aqui para somente remover os itens quando confirmar as ações no dialog
      dispatch(actions.removeSelectedClientsFromRestrictionComponent(selectedRecords))
      dispatch(actions.removeSelectedLocationsFromRestrictionComponent(selectedRecords))
      dispatch(actions.removeSelectedUnitsFromRestrictionComponent(selectedRecords))
      dispatch(actions.setDialogAddRestrictionsVisible(false))
      dispatch(actions.setClientsOnCurrentRestrictionComponent())
      dispatch(actions.setLocationsOnCurrentRestrictionComponent())
      dispatch(actions.setBusinessUnitsOnCurrentRestrictionComponent())
      dispatch(actions.setCurrentSelectedComponentOnDropdown(null))
      dispatch(actions.lookupClientAddRecords(null))
      dispatch(actions.lookupLocationAddRecords(null))
      dispatch(actions.setEditRestrictionButtonState())

    },
    dialogAddRestrictionsOnClose: () => {
      const visible = false
      dispatch(actions.setDialogAddRestrictionsVisible(visible))
      dispatch(actions.setCurrentSelectedComponentOnDropdown(null))
      dispatch(actions.lookupClientAddRecords(null))
      dispatch(actions.lookupLocationAddRecords(null))
    },
    componentsRestrictionOnChange: (e) => {
      dispatch(actions.duplicateClientsRestrictions(e.value))
      dispatch(actions.duplicateLocationsRestrictions(e.value))
      dispatch(actions.setCurrentSelectedComponentOnDropdown(e.value))
    },
    lookupClientOnRemoveSelected: () => {
      dispatch(actions.removeSelectedClients())
    },
    lookupLocationOnRemoveSelected: () => {
      dispatch(actions.removeSelectedLocations())
    },
    lookupClientApplyFilter: (filters) => {
      dispatch(clientActions.applyFilter(filters))
    },
    lookupClientSetSearchText: (SearchText) => {
      dispatch(actions.lookupClientSetSearchText(SearchText))
    },
    setCurrentClientName: (name) => {
      dispatch(actions.setCurrentClientName(name))
    },
    setCurrentClient: (client) => {
      dispatch(actions.setCurrentClient(client))
    },
    lookupLocationApplyFilter: (filters) => {
      dispatch(locationActions.applyFilter(filters))
    },
    setLookupRestrictedUnitVisible: (visible) => {
      dispatch(actions.setLookupRestrictedUnitVisible(visible));
    },
    setVisibleLookupRestrictedUnit: (visible) => {
      dispatch(actions.setVisibleLookupRestrictedUnit(visible));
    },
    setSelectedRecordsLookupUnitRestricted: (records) => {
      dispatch(actions.setSelectedRecordsLookupUnitRestricted(records));
    },
    setBusinessUnitRestrictedOnConfirm: (businessUnit) => {
      dispatch(actions.setTableRecordsBusinessUnitRestricted(businessUnit));
      dispatch(actions.setLookupRestrictedUnitVisible(false));
    },
    lookupUnitRestrictedApplyFilter: (filters) => dispatch(actions.applyFilter(filters)),
    sendInforTabelaFreteXLSX: (xls) => {
      dispatch(actions.sendInforTabelaFreteXLSX(xls))
    },
  }
};

export const componentDispatchToProps = ({ dispatch, actions, componentActions }) => {
  return {
    setCurrentComponent: (component) => {
      dispatch(actions.setCurrentComponent(component))

    },
    addRestrictionOnRestrictionTable: (currentComponent) => {
      if (currentComponent && (currentComponent.restrictedByClient || currentComponent.restrictedByLocalization || currentComponent.restrictedByBusinessUnit)) {
        dispatch(actions.addRestrictionOnRestrictionTable(currentComponent))
      }
    },
    lookupComponentLoad: () => { dispatch(componentActions.load()) },
    lookupComponentApplyFilter: (filter) => { dispatch(componentActions.applyFilter(filter)) },
    lookupComponentSetVisible: (visible) => { dispatch(actions.setLookupComponentVisible(visible)) },
    lookupComponentPaginate: (paginationParams) => dispatch(componentActions.paginate(paginationParams)),
    lookupComponentSortOrder: (sortParams) => dispatch(componentActions.sortOrder(sortParams)),
    lookupComponentClearFilter: () => {
      const reload = false;
      dispatch(componentActions.paginate({ index: 0 }, reload))
      dispatch(componentActions.sortOrder({
        sortOrder: "",
        sortField: "",
      }, reload))
      dispatch(componentActions.applyFilter([], { reload }))
    }
  }
};

export const defaultActionsDispatchToProps = ({ dispatch, actions, messageActions, makeMessages }) => {
  return {
    setField: (field, value) => dispatch(actions.setField(field, value)),
    changeHandler: event => {
      const target = event.target;
      const field = target.name;
      let value = target.value;
      if (field === 'ignoreICMS') {
        value = !value;
      }
      dispatch(actions.removeErrors([{ field }]));
      dispatch(actions.setField(field, value));
    },
    transferChangeHandler: (event) => {
      const target = event.target;
      let value = target.value;
      if (value) {
        dispatch(actions.addTag("TRANSFERÊNCIA"));
      } else {
        dispatch(actions.removeTag("TRANSFERÊNCIA"));
      }
    },
    chilledProductChangeHandler: (event) => {
      const target = event.target;
      let value = target.value;
      if (value) {
        dispatch(actions.addTag("REFRIGERADO"));
      } else {
        dispatch(actions.removeTag("REFRIGERADO"));
      }
    },
    saveHandler: event => {
      dispatch(actions.save());
    },
    removeHandler: event => {
      dispatch(actions.remove());
    },
    newHandler: event => {
      dispatch(actions.makeNew());
      dispatch(actions.setCurrentRoute(null));
      dispatch(actions.setSelectedRoute(null));
      dispatch(actions.setSelectedRoutes([]));
      dispatch(actions.setSelectedSerie(null));
      dispatch(actions.setCurrentComponent(null));
    },
    loadById: oid => {
      dispatch(actions.loadTableById(oid));
    },
    showMessage: (message, type) => {
      dispatch(messageActions.messageClearMessages());
      dispatch(
        messageActions.messageShowMessages(
          makeMessages([message], type)
        )
      );
    },
    setVisibleDialogStatusImportXlsRoutes: (visible) => {
      dispatch(actions.setVisibleDialogStatusImportXlsRoutes(visible));
    },
    clearCurrentRecord: () => dispatch(actions.clearCurrentRecord()),
    saveMultiEntries: (component, entries) => dispatch(actions.saveMultiEntries(component, entries)),
    removeMultiEntry: (component, entries) => dispatch(actions.removeMultiEntry(component, entries)),
    addIgnoreComponentList: (value) => dispatch(actions.addIgnoreComponentList(value)),
    removeIgnoreComponentList: (value) => dispatch(actions.removeIgnoreComponentList(value)),
  }
}