import React from 'react';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { Lookup } from '../../../components/lookup/Lookup';
import { PickList } from 'primereact/picklist';


class AcaoForm extends React.Component {

    
    handleTamplatePerfil = objeto => {
        let oid = objeto.oidPerfil ? objeto.oidPerfil : objeto.oid;
        let nome = objeto.nomePerfil ? objeto.nomePerfil : objeto.nome;        

        return `${oid} - ${nome}`;
    }

    handleMoveToTarget = (e) => {
        this.props.pickListPerfisMoveToTarget(e.value, this.props.record.oid);
    }
    handleMoveToSource = (e) => {
        this.props.pickListPerfisMoveToSource(e.value);
    } 
    handleMoveAllToSource = (e) => {
        this.props.pickListPerfisMoveAllToSource();
    }

    handleMoveAllToTarget = (e) =>{
        this.props.pickListPerfisMoveAllToTarget(this.props.recordPerfis, this.props.record.oid);     
    }
    render() {
        const { props } = this;
        const record = props.record;
        const recordPerfis = props.recordPerfis;

        return (
            <div className="pessoa-form">
                <div className="p-grid">
                    <div className="p-col-6">
                        <div className="p-grid">
                            <div className="p-col-3">
                                <div className="form-field-container">
                                    <label>Código</label>
                                    <InputText value={record.oid} onChange={props.changeHandler} disabled={true} />
                                </div>
                            </div>

                            <div className="p-col-12 form-field-container">
                                <label>Nome</label>
                                <InputText value={record.nome} onChange={props.changeHandler} name="nome" />
                            </div>

                            <div className="p-col-6 form-field-container">
                                <label>Módulo</label>
                                <div className="p-inputgroup" style={{ width: '100%', display: 'flex' }}>
                                    <InputText style={{ flexGrow: '1' }} placeholder="Módulo" value={record.nomeModulo} name="modulo" readOnly />
                                    <Button label="Search" onClick={props.lookupModuloOnClick} />
                                </div>

                                <Lookup
                                    visible={props.lookupModulo.visible}
                                    modal={props.lookupModulo.modal}
                                    header={props.lookupModulo.header}
                                    onHide={props.lookupModuloOnHide}
                                    onConfirm={props.lookupModuloOnConfirm}
                                    onCancel={props.lookupModuloOnCancel}
                                    records={props.lookupModulo.records}
                                    columns={props.lookupModulo.columns}
                                    totalRecords={props.lookupModulo.totalRecords}
                                    sortOrder={props.lookupModulo.order}
                                    sortField={props.lookupModulo.sortField}
                                    first={props.lookupModulo.first}
                                    rows={props.lookupModulo.rows}
                                    onPage={props.lookupModuloOnPage}
                                    onFilter={props.lookupModuloOnFilter}
                                    onSort={props.lookupModuloOnOnSort}
                                />
                            </div>

                            <div className="p-col-12 form-field-container">
                                <label>Desvincular</label>
                                <Checkbox checked={props.record.desvincular} onChange={props.changeHandler} name="desvincular" />
                            </div>
                            
                            <div>
                                <PickList className="p-col-12" source={[...recordPerfis]} itemTemplate={this.handleTamplatePerfil} target={props.record.perfisAcaoSelecionados}
                                    onMoveToTarget={this.handleMoveToTarget} onMoveToSource={this.handleMoveToSource}
                                    onMoveAllToSource={this.handleMoveAllToSource} onMoveAllToTarget={this.handleMoveAllToTarget}
                                    sourceHeader="Disponíveis" targetHeader="Selecionados" showSourceControls={false} showTargetControls={false}>
                                </PickList>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        )
    }
};

export default AcaoForm;