import { types } from "./vehicle.actions";
import { Reducer } from "../../services/reducer.factory";


const initialState = {
  columns: [{
    field: "oid",
    header: "Código",
    columnKey: "oid",
    sortable: true,
    filter: true
  },
   {
    field: "plate",
    header: "Placa",
    columnKey: "plate",
    sortable: true,
    filter: true
  },
  {
    field: "vehicleType.name",
    header: "Tipo do veículo",
    columnKey: "vehicleType.name",
    sortable: false,
    filter: false
  },
  {
    field: "vehicleCapacity",
    header: "Capac. veículo",
    columnKey: "vehicleCapacity",
    sortable: false,
    filter: false
  },
  {
    field: "driver.name",
    header: "Motorista",
    columnKey: "driver",
    sortable: false,
    filter: false
  }],
  currentRecord: { oid: "", plate: "", numberRenavam:"", vehicleCapacity:"", driver: null, vehicleType: null },
  lookupDriver: {
    visible: false,
    modal: true,
    header: "Motoristas"
  },
  lookupVehicleType: {
    visible: false,
    modal: true,
    header: "Tipo de veículo"
  }

};

const reducer = new Reducer(initialState, types);

export function CRUDVehicleReducer(state, action) {
  state = reducer.execute(state, action);
  return execute(state, action);
}

function execute(state, action) {
  let result = state;
  switch (action.type) {
    case types.SET_LOOKUP_DRIVER_VISIBLE:
      return setLookupDriverVisible(state, action);
    case types.SET_LOOKUP_VEHICLE_TYPE_VISIBLE:
      return setLookupVehicleTypeVisible(state, action);
    default:
      return result;
  }
}


function setLookupDriverVisible(state, action) {
  let lookupDriver = {...state.lookupDriver,  visible: action.visible};
  return {...state, lookupDriver }
}
function setLookupVehicleTypeVisible(state, action) {
  let lookupVehicleType = {...state.lookupVehicleType,  visible: action.visible};
  return {...state, lookupVehicleType }
}

