import React from "react";
import { connect } from "react-redux";
import Toolbar from "../../../components/toolbar/Toolbar";
import { routes } from '../../../constants';
import { Page } from "../../../components/page/Page";
import { PolicesGroupForm } from './PolicesGroupForm';
import { actions } from '../policesGroup.actions';
import { Column } from "primereact/column";
import { actions as actionsPolices } from '../../polices/polices.actions';
import { actions as actionsPerfil } from '../../perfil/perfil.actions';
import { ButtonDialog } from "../../../components/button-dialog/ButtonDialog";

const mapStateToProps = state => {

  const lookupPolicesBase = {
    records: state.crudPolicesState.records,
    columns: state.crudPolicesState.columns.filter((col) => col.field === "oid" || col.field === "name" || col.field === "description").map((col) => <Column key={col.field} {...col} />),
    first: state.crudPolicesState.index,
    rows: state.crudPolicesState.max,
    totalRecords: state.crudPolicesState.count,
    sortField: state.crudPolicesState.sortField,
    order: state.crudPolicesState.sortOrder,
    rowsPerPageOptions: [20, 50, 100]
  };
  let lookupPolices = Object.assign({}, state.crudPolicesGroupState.lookupPolices, lookupPolicesBase);

  const lookupGroupBase = {
    records: state.crudPerfilState.records,
    columns: state.crudPerfilState.columns.filter((col) => col.field === "oid" || col.field === "name" || col.field === "description").map((col) => <Column key={col.field} {...col} />),
    first: state.crudPerfilState.index,
    rows: state.crudPerfilState.max,
    totalRecords: state.crudPerfilState.count,
    sortField: state.crudPerfilState.sortField,
    order: state.crudPerfilState.sortOrder,
    rowsPerPageOptions: [20, 50, 100]
  };
  let lookupGroup = Object.assign({}, state.crudPolicesGroupState.lookupGroup, lookupGroupBase);


  return {
    record: state.crudPolicesGroupState.currentRecord,
    lookupPolices,
    lookupGroup,
    policesGroup: state.crudPolicesGroupState.policesGroup,
    columnsPolices: state.crudPolicesGroupState.columnsPolices,
    selectedRecord: state.crudPolicesGroupState.selectedRecord,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    changeHandler: event => {
      const target = event.target;
      const field = target.name;
      let value = target.value;

      dispatch(actions.removeErrors([{ field }]));
      dispatch(actions.setField(field, value));
    },
    saveHandler: event => {
      dispatch(actions.save());
    },
    removeHandler: event => {
      dispatch(actions.remove());
    },
    newHandler: event => {
      dispatch(actions.makeNew());
      dispatch(actions.setRecordsLoadByGroupId([]));
    },
    loadById: oid => dispatch(actions.loadById(oid)),

    lookupPolicesSetVisible: (visible) => dispatch(actions.setLookupPolicesVisible(visible)),
    lookupPolicesPaginate: (paginationParams) => dispatch(actionsPolices.paginate(paginationParams)),
    lookupPolicesSortOrder: (sortParams) => dispatch(actionsPolices.sortOrder(sortParams)),
    lookupPolicesApplyFilter: (filterParams) => {
      dispatch(actionsPolices.setType(null));
      dispatch(actionsPolices.applyFilter(filterParams));
    },

    setLookupPolices: (polices) => {
      const { oid, name } = polices || {};
      dispatch(actions.setField("polices", polices ? { oid, name } : null));
      dispatch(actions.setField("policeId", oid));
    },

    //group
    lookupGroupSetVisible: (visible) => dispatch(actions.setLookupGroupVisible(visible)),
    lookupGroupPaginate: (paginationParams) => dispatch(actionsPerfil.paginate(paginationParams)),
    lookupGroupSortOrder: (sortParams) => dispatch(actionsPerfil.sortOrder(sortParams)),
    lookupGroupApplyFilter: (filterParams) => {
      dispatch(actionsPerfil.setType(null));
      dispatch(actionsPerfil.applyFilter(filterParams));
    },

    setLookupGroup: (group) => {
      console.log("group=", group)
      const { oid, name } = group || {};
      dispatch(actions.setField("group", group ? { oid, name } : null));
      dispatch(actions.setField("groupId", oid));
    },

    //carregar todas politicas vinculado à esse grupo/perfil
    loadAllPolicesByGroupID: oid => dispatch(actions.loadAllPolicesByGroupID(oid)),
    removePolicesGroupBy: oid => dispatch(actions.removePolicesGroupBy(oid)),

  };
};

class PolicesGroupFormPageContainer extends React.Component {

  constructor(props) {
    super(props);
    actions.afterSave = ({ oid }) => {
      // this.props.history.replace(`${routes.PATH_POLICES_GROUP}/${oid}`);
      // this.props.loadAllPolicesByGroupID(oid);
      this.searchHandler();

    };
    actions.afterNew = () => {
      this.props.history.replace(`${routes.PATH_POLICES_GROUP}/novo`);
    };
    actions.afterRemove = () => {
      this.props.history.replace(`${routes.PATH_POLICES_GROUP}`);
    };
  }

  componentDidMount() {
    const oid = this.props.match.params.id;

    if (oid && parseInt(oid)) {
      this.props.loadById(oid);
      this.props.loadAllPolicesByGroupID(oid);
    } else {
      this.props.newHandler();
    }
  }
  searchHandler = () => {
    this.props.history.push(`${routes.PATH_POLICES_GROUP}`)
  }

  reloadAfterRemove = (removeID) => {
    const oid = this.props.match.params.id;

    if (oid && parseInt(oid) && removeID != oid) {
      this.props.loadById(oid);
      this.props.loadAllPolicesByGroupID(oid);
    } else {
      this.props.searchHandler();
    }
  }

  //Polices
  lookupPolicesOnPage = (e) => {
    this.props.lookupPolicesPaginate({
      max: e.rows,
      index: e.first,
    });
  };
  lookupPolicesOnSort = (e) => {
    this.props.lookupPolicesSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField,
    });
  };
  lookupPolicesOnFilter = (e) => {
    const filters = [];
    const keys = Object.keys(e.filters) || [];
    keys.forEach((key) => {
      filters.push({
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: "CONTEM",
      });
    });

    this.props.lookupPolicesApplyFilter(filters);
  };
  lookupPolicesOnClick = (e) => {
    const filters = [];
    this.props.lookupPolicesApplyFilter(filters);
    this.props.lookupPolicesSetVisible(true);
  };
  lookupPolicesOnHide = (e) => {
    this.props.lookupPolicesSetVisible(false);
  };
  lookupPolicesOnConfirm = (e) => {
    this.props.setLookupPolices(e.selection);
    this.props.lookupPolicesSetVisible(false);
  };
  lookupPolicesOnCancel = (e) => {
    this.props.setLookupPolices(null);
    this.props.lookupPolicesSetVisible(false);
  };

  lookupPolicesOnEdit = () => {
    if (this.props.record.polices && this.props.record.polices.oid) {
      this.props.history.push(`${routes.PATH_POLICES}/${this.props.record.polices.oid}`);
    }
  };

  lookupPolicesOnComplete = (e) => { };

  lookupPolicesOnInputFilter = (e) => {
    const value = e.value;
    if (typeof value === "string") {
      const filters = [
        {
          campo: "name",
          valor: e.value,
          tipoFiltro: "CONTEM",
        },
      ];

      this.props.setField("polices.name", e.value);
      this.props.lookupPolicesApplyFilter(filters);
    } else {
      this.props.setLookupPolices(value);
    }
  };

  //Group
  lookupGroupOnPage = (e) => {
    this.props.lookupGroupPaginate({
      max: e.rows,
      index: e.first,
    });
  };
  lookupGroupOnSort = (e) => {
    this.props.lookupGroupSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField,
    });
  };
  lookupGroupOnFilter = (e) => {
    const filters = [];
    const keys = Object.keys(e.filters) || [];
    keys.forEach((key) => {
      filters.push({
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: "CONTEM",
      });
    });

    this.props.lookupGroupApplyFilter(filters);
  };
  lookupGroupOnClick = (e) => {
    const filters = [];
    this.props.lookupGroupApplyFilter(filters);
    this.props.lookupGroupSetVisible(true);
  };
  lookupGroupOnHide = (e) => {
    this.props.lookupGroupSetVisible(false);
  };
  lookupGroupOnConfirm = (e) => {
    this.props.setLookupGroup(e.selection);
    this.props.lookupGroupSetVisible(false);
  };
  lookupGroupOnCancel = (e) => {
    this.props.setLookupGroup(null);
    this.props.lookupGroupSetVisible(false);
  };

  lookupGroupOnEdit = () => {
    if (this.props.record.group && this.props.record.group.oid) {
      this.props.history.push(`${routes.PATH_PERFIL}/${this.props.record.group.oid}`);
    }
  };

  lookupGroupOnComplete = (e) => { };

  lookupGroupOnInputFilter = (e) => {
    const value = e.value;
    if (typeof value === "string") {
      const filters = [
        {
          campo: "name",
          valor: e.value,
          tipoFiltro: "CONTEM",
        },
      ];

      this.props.setField("group.name", e.value);
      this.props.lookupGroupApplyFilter(filters);
    } else {
      this.props.setLookupGroup(value);
    }
  };



  render() {
    let recordId = this.props.record ? this.props.record.oid : null
    const title = `Politicas-Perfil ${recordId || 'nova'}`
    const header = (
      <div>
        <h1>{title}</h1>
        <hr className="lxSplitter" ></hr>
        <div>
          <Toolbar
            onSave={this.props.saveHandler} saveButtonStyle='highlighted'
            // onRemove={this.props.removeHandler} removeButtonStyle='danger'
            onNew={this.props.newHandler}
            onSearch={this.searchHandler}>
          </Toolbar>
        </div>
      </div>
    );

    const templateRemoverPoliceGroup = (rowData, e) => {

      return (
        <div>
          <ButtonDialog
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              const id = rowData.oid
              this.props.removePolicesGroupBy(id);
              setTimeout(() => { this.reloadAfterRemove(id); }, 500);
            }}
            button={{
              title: "Remover",
              disabled: false,
              className: "lognex-btn-danger",
            }}
            dialog={{
              header: "Confirmação",
              modal: true,
              message: "Gostaria de remover essa Police x Group?",
            }}
          ></ButtonDialog>
        </div>
      );
    };

    const columnsPolices = this.props.columnsPolices.map((col) => {
      if (col.field == "remove") {
        return <Column {...col} key={col.field} style={{ width: "60px" }} body={templateRemoverPoliceGroup} />;
      }
      return <Column {...col} key={col.field} />;
    });
    const content = (<PolicesGroupForm
      record={this.props.record}
      changeHandler={this.props.changeHandler}
      policesGroup={this.props.policesGroup}
      selectedRecord={this.props.selectedRecord}
      columnsPolices={columnsPolices}
      // onSelectedEventsChange={this.setSelectePoliceGroup}

      //Grupos/Perfil
      lookupGroup={this.props.lookupGroup}
      lookupGroupOnHide={this.lookupGroupOnHide}
      lookupGroupOnConfirm={this.lookupGroupOnConfirm}
      lookupGroupOnCancel={this.lookupGroupOnCancel}
      lookupGroupOnPage={this.lookupGroupOnPage}
      lookupGroupOnSort={this.lookupGroupOnSort}
      lookupGroupOnFilter={this.lookupGroupOnFilter}
      lookupGroupOnClick={this.lookupGroupOnClick}
      lookupGroupOnEdit={this.lookupGroupOnEdit}
      lookupGroupOnComplete={this.lookupGroupOnComplete}
      lookupGroupOnInputFilter={this.lookupGroupOnInputFilter}

      //Politicas
      lookupPolices={this.props.lookupPolices}
      lookupPolicesOnHide={this.lookupPolicesOnHide}
      lookupPolicesOnConfirm={this.lookupPolicesOnConfirm}
      lookupPolicesOnCancel={this.lookupPolicesOnCancel}
      lookupPolicesOnPage={this.lookupPolicesOnPage}
      lookupPolicesOnSort={this.lookupPolicesOnSort}
      lookupPolicesOnFilter={this.lookupPolicesOnFilter}
      lookupPolicesOnClick={this.lookupPolicesOnClick}
      lookupPolicesOnEdit={this.lookupPolicesOnEdit}
      lookupPolicesOnComplete={this.lookupPolicesOnComplete}
      lookupPolicesOnInputFilter={this.lookupPolicesOnInputFilter}


    />);
    return (<Page header={header} content={content} />);
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(PolicesGroupFormPageContainer);
