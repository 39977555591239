import React from 'react';
import EmpresaFormPageContainer from './EmpresaFormPageContainer';

export class EmpresaFormPage extends React.Component{
    render(){
        return (
            <EmpresaFormPageContainer {...this.props}/>
        )
    }
}

