import React from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";

export default function ModalInsertComponent(props) {

  const body = (
    <div className="p-grid">
      <div className="p-col-12 form-field-container">
        <label>Label Componente</label>
        <InputText
          name="description"
          value={props.description}
          onChange={props.onChange}
        />
      </div>
      <div className="p-col-3 form-field-container">
        <button
          className="lognex-btn-highlighted"
          style={{ width: "100%" }}
          onClick={props.onClick}
          title={"Inserir"}
        >
          <i className="pi pi-check"></i>
        </button>
      </div>
      <div className="p-col-3 form-field-container">  
        <button
          className="lognex-btn-danger"
          style={{ width: "100%" }}
          onClick={props.onHide}
          title={"Cancelar"}
        >
          <i className="pi pi-times"></i>
        </button>
      </div>
    </div>
  )

 return (
    <div>
      <div>
        <Dialog
          className="lxLookupDialog"
          header={props.title}
          visible={props.visible}
          style={{ width: "auto" }}
          modal={true}
          onHide={props.onHide}
        >
         {body}
        </Dialog>
      </div>
    </div>
  );
}
