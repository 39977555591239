import { types } from "./patiomanagerdoca.actions";
import { Reducer } from "../../services/reducer.factory";

const initialState = {
  columns: [
    {
      field: "oid",
      header: "Código",
      columnKey: "oid",
      sortable: false,
      filter: false,
    },
     
    {
      field: "eventRecord.createdAt",
      header: "Data Cadastro",
      columnKey: "neventRecord.createdAt",
      sortable: false,
      filter: false,
    },
    {
      field: "name",
      header: "Nome",
      columnKey: "name",
      sortable: false,
      filter: false,
    },
    {
      field: "activeAceite",
      header: "Visivel Aceite Ordem",
      columnKey: "activeAceite",
      sortable: false,
      filter: false,
    },

    // {
    //   field: "operatingTime",
    //   header: "Tempo por Operação",
    //   columnKey: "operatingTime",
    //   sortable: false,
    //   filter: false,
    // },
    
  ],
  columnsRowTreeviewTemplateDocas:[
    {
      field: "packagingType",
      header: "Embalagens",
      columnKey: "packagingType",
      sortable: false,
      filter: false,
    },
    {
      field: "operationType",
      header: "Tipo de Operação",
      columnKey: "operationType",
      sortable: false,
      filter: false,
    },
    {
      field: "vehicleType",
      header: "Tipo de Veículos",
      columnKey: "vehicleType",
      sortable: false,
      filter: false,
    }, 
    {
      field: "operationTime",
      header: "Tempo de Operação",
      columnKey: "operationTime",
      sortable: false,
      filter: false,
    } 
  ],
  currentRecord: {
    oid: 0, 
    name:"",
    sigla:""
  },

  operationTypes: [
    { label: "Inbound", type: 1 },
    { label: "Outbound", type: 2},
  ],
  isVisibleModalDockConfig:false,
  docaVehicleOperationBackup:null,
  expandedRows:null,
};
 
const reducer = new Reducer(initialState, types);

export function CRUDPatioManagerDocaReducer(state, action) {
  state = reducer.execute(state, action);
  return execute(state, action);
}

function execute(state, action) {
  let result = state;
  switch (action.type) {
    case types.SET_SELECTED_OPERATION_TYPE:
      return setSelectedOperationType(state, action);
    case types.SET_SELECTED_PACKGING_TYPE:
      return setSelectedPackagingType(state, action);
    case types.SET_SELECTED_VEHICLE_TYPE:
      return setSelectedVehicleType(state, action);
    case types.SET_VISIBLE_MODAL_DOCK_CONFIG:
      return setVisibleModalDockConfig(state, action);
    case types.SET_DOCA_VEHICLE_OPE_BACKUP:
      return setDocaVehicleOperationBackup(state, action);
    case types.RESTAURA_DOCA_OPERATION_BACKUP:
      return setRestauraDocaVehicleOperation(state, action);
    case types.SET_EXPANDED_ROW:
      return setExpandedRows(state, action);
  }
  return result;
}
 

function setExpandedRows(state, action) {
  state = Object.assign({}, state, { expandedRows: action.expandedRows }); 
  return state;
}

function setRestauraDocaVehicleOperation(state, action) {
  let currentRecord = state.currentRecord;
  currentRecord.docaVehicleOperation = action.docaVehicleOperation;
  return Object.assign({}, state, { currentRecord });
}

function setDocaVehicleOperationBackup(state, action) {
  let docaVehicleOperationBackup = [...action.docaVehicleOperationBackup];
  return Object.assign({}, state, {docaVehicleOperationBackup});
}

function setVisibleModalDockConfig(state, action) {
  let isVisibleModalDockConfig = state.isVisibleModalDockConfig;
  isVisibleModalDockConfig = action.visible;
  return Object.assign({}, state, { isVisibleModalDockConfig });
}

function setSelectedOperationType(state, action) {
  let currentRecord = state.currentRecord;
  currentRecord.operationType = action.operationTypeSelected;
  return Object.assign({}, state, { currentRecord });
}

function setSelectedPackagingType(state, action) {
  let currentRecord = state.currentRecord;
  currentRecord.packagingType = action.packagingTypeSelected;
  return Object.assign({}, state, { currentRecord });
}

function setSelectedVehicleType(state, action) {
  let currentRecord = state.currentRecord;
  currentRecord.vehicleType = action.vehicleTypeSelected;
  return Object.assign({}, state, { currentRecord });
}
