import React from 'react';
import { connect } from 'react-redux';
import { Column } from 'primereact/column';
import Toolbar from "../../../components/toolbar/Toolbar";
import { actions } from '../acao.actions';
import { actions as moduloActions } from '../../modulo/modulo.actions';
import { actions as perfilActions} from '../../perfil/perfil.actions';

import { routes } from '../../../constants';
import { Page } from "../../../components/page/Page";
import AcaoForm  from './AcaoForm';
import { AppService, COMPANY_CHANGE_EVENT_NAME, BUSINESS_UNIT_CHANGE_EVENT_NAME } from '../../../services/app.service';


const mapStateToProps = state => {
  const lookupModuloBase = {
    records: state.crudModuloState.records,
    columns: state.crudModuloState.columns
      .filter(col => col.field === 'oid' || col.field === 'nome')
      .map(col => <Column key={col.field} {...col} />),
    first: state.crudModuloState.index,
    rows: state.crudModuloState.max,
    totalRecords: state.crudModuloState.count,
    sortField: state.crudModuloState.sortField,
    order: state.crudModuloState.sortOrder
  }
  let lookupModulo = Object.assign({}, state.crudAcaoState.lookupModulo, lookupModuloBase);

  return {
    record: state.crudAcaoState.currentRecord,
    lookupModulo,
    recordPerfis : state.crudPerfilState.records.filter(perfil => {
      let listIdPerfis = state.crudAcaoState.currentRecord.perfisAcaoSelecionados.map(perfilAcao => perfilAcao.oidPerfil);
      return !(listIdPerfis.includes(perfil.oid));
    })
  }
}

const mapDispatchToProps = dispatch => {
  return {
    //lookupModulo
    lookupModuloSetVisible: visible => dispatch(actions.setLookupModuloVisible(visible)),
    lookupModuloPaginate: paginationParams => dispatch(moduloActions.paginate(paginationParams)),
    lookupModuloSortOrder: sortParams => dispatch(moduloActions.sortOrder(sortParams)),
    lookupModuloApplyFilter: filterParams => {
      dispatch(moduloActions.setType(null));
      dispatch(moduloActions.applyFilter(filterParams))
    },
    lookupModuloLoad: () => dispatch(moduloActions.load()),
    setModulo: (modulo) => {
    dispatch(actions.setField('oidModulo', modulo ? modulo.oidModulo : null));
      dispatch(actions.setField('nomeModulo', modulo ? modulo.nomeModulo : null));
    },
    //pickListPerfis 
    pickListPerfisLoad: () => dispatch(perfilActions.load()),
    pickListPerfisMoveToSource : (perfisAcaoRemoved) => dispatch(actions.pickListPerfisMoveToSource(perfisAcaoRemoved)),
    pickListPerfisMoveAllToSource : () => dispatch(actions.pickListPerfisMoveAllToSource()),
    pickListPerfisMoveToTarget : (perfisAdd, oidAcao) => dispatch(actions.pickListPerfisMoveToTarget(perfisAdd, oidAcao)),
    pickListPerfisMoveAllToTarget : (allPerfis, oidAcao) => dispatch(actions.pickListPerfisMoveAllToTarget(allPerfis, oidAcao)),
    //Ação
    changeHandler: event => {
      const target = event.target;
      const field = target.name;
      let value = target.value;
      if(field === 'desvincular'){
        value = target.checked;
      }
      dispatch(actions.removeErrors([{ field }]));
      dispatch(actions.setField(field, value));
    },
    saveHandler: event => {
      dispatch(actions.save());
    },
    removeHandler: event => {
      dispatch(actions.remove());
    },
    newHandler: event => {
      dispatch(actions.makeNew());
    },
    loadById: oid => dispatch(actions.loadById(oid)),
  }
}

class AcaoFormPageContainer extends React.Component {

  constructor(props) {
    super(props);
    actions.afterSave = ({ oid }) => {
      this.props.history.replace(`${routes.PATH_ACAO}/${oid}`);
    };
    actions.afterNew = () => {
      this.props.history.replace(`${routes.PATH_ACAO}/novo`);
    };
    actions.afterRemove = () => {
      this.props.history.replace(`${routes.PATH_ACAO}`);
    };
  }
  onReceiveAppNotifications = (eventName, params)=>{
    if(eventName === COMPANY_CHANGE_EVENT_NAME || eventName === BUSINESS_UNIT_CHANGE_EVENT_NAME){
     // this.props.load();
    }
  }

  componentDidMount() {
    AppService.on(COMPANY_CHANGE_EVENT_NAME,this);
    const oid = this.props.match.params.id;
        
    if (oid && parseInt(oid)) {
      this.props.loadById(oid);
    }else{
        this.props.newHandler();
    }
    this.props.pickListPerfisLoad();
  }

  componentWillUnmount(){
    AppService.off(COMPANY_CHANGE_EVENT_NAME,this);
  }

  searchHandler = () => {
    this.props.history.push(`${routes.PATH_ACAO}`)
  }


  lookupModuloOnPage = (e) => {
    this.props.lookupModuloPaginate({
      max: e.rows,
      index: e.first
    })
  }
  lookupModuloOnSort = (e) => {
    this.props.lookupModuloSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField
    })
  }

  lookupModuloOnFilter = (e) => {
    const filters = [];
    const keys = Object.keys(e.filters) || [];
    keys.forEach(key => {
      filters.push({
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: 'CONTEM'

      })
    })
    this.props.lookupModuloApplyFilter(filters)

  }
  lookupModuloOnClick = (e) => {
    this.props.lookupModuloLoad();
    this.props.lookupModuloSetVisible(true);
  }
  lookupModuloOnHide = (e) => {
    this.props.lookupModuloSetVisible(false);
  }
  lookupModuloOnConfirm = (e) => {
    this.props.setModulo(e.selection.oid, e.selection.nome);
    this.props.lookupModuloSetVisible(false);

  }
  lookupModuloOnCancel = (e) => {
    this.props.setModulo(null, null);
    this.props.lookupModuloSetVisible(false);
  }
  
  pickListPerfisMoveToSource = (perfisAcaoRemoved) => {
    this.props.pickListPerfisMoveToSource(perfisAcaoRemoved);
  }

  pickListPerfisMoveAllToSource = () => {
    this.props.pickListPerfisMoveAllToSource();
  }

  pickListPerfisMoveToTarget = (perfisAdd, oidAcao) => {
    this.props.pickListPerfisMoveToTarget(perfisAdd, oidAcao);
  }

  pickListPerfisMoveAllToTarget = (allPerfis, oidAcao) => {
    this.props.pickListPerfisMoveAllToTarget(allPerfis, oidAcao);
  }

  render() {
    const content = (
      <AcaoForm
        changeHandler={this.props.changeHandler}
        record={this.props.record}
        recordPerfis={this.props.recordPerfis}
        pickListPerfisMoveToSource={this.pickListPerfisMoveToSource}
        pickListPerfisMoveAllToSource={this.pickListPerfisMoveAllToSource}
        pickListPerfisMoveToTarget={this.pickListPerfisMoveToTarget}
        pickListPerfisMoveAllToTarget={this.pickListPerfisMoveAllToTarget}
        lookupModulo={this.props.lookupModulo}
        lookupModuloOnHide={this.lookupModuloOnHide}
        lookupModuloOnConfirm={this.lookupModuloOnConfirm}
        lookupModuloOnCancel={this.lookupModuloOnCancel}
        lookupModuloOnPage={this.lookupModuloOnPage}
        lookupModuloOnSort={this.lookupModuloOnSort}
        lookupModuloOnFilter={this.lookupModuloOnFilter}
        lookupModuloOnClick={this.lookupModuloOnClick}
      />
    );
    const title = `Ação ${this.props.record.oid || 'nova'}`
    const header = (
      <div>
        <h1>{title}</h1>
        <div>
          <Toolbar
            onSave={this.props.saveHandler} saveButtonStyle='highlighted'
            onRemove={this.props.removeHandler} removeButtonStyle='danger'
            onNew={this.props.newHandler}
            onSearch={this.searchHandler}>
          </Toolbar>
        </div>
      </div>
    );
    return (<Page header={header} content={content} />);
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AcaoFormPageContainer);