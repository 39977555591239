export function validate(record) {
    const errors = [];

    if (!record.name || record.name.trim() === "") {
        errors.push({ field: 'name', message: 'O nome do menu é obrigatório!' });
    }

    try {
        if (!record.position  || parseInt(record.position) < 1) {
            errors.push({ field: 'position', message: 'A ordem do menu é obrigatória!' });
        }
    } catch (error) {
        errors.push({ field: 'position', message: 'A ordem do menu é obrigatória!' });
    }
    if (!record.key || record.key.trim() === "") {
        errors.push({ field: 'key', message: 'A chave do menu é obrigatória!' });
    }
   
    

    const result = {
        ok: errors.length === 0,
        errors,
        record
    }

    return new Promise((resolve, reject) => {
        const action = result.ok ? resolve : reject;
        return action(result);
    })
}