export function validate(record) {
    const errors = [];

    if (!record.name || record.name.trim() === "") {
        errors.push({ field: 'name', message: 'O nome da rota é obrigatório!' });
    }
    
    if (!record.oidCarrier || record.oidCarrier.toString().trim() === "" || parseInt(record.oidCarrier) <= 0) {
        errors.push({ field: 'carrier', message: 'A transportadora da rota é obrigatória!' });
    }
    
    if (!record.oidOrigin || record.oidOrigin === "" || parseInt(record.oidOrigin) <= 0) {
        errors.push({ field: 'origin', message: 'A origem da rota é obrigatória!' });
    }

    if (!record.oidDestination || record.oidDestination === "" || parseInt(record.oidDestination) <= 0) {
        errors.push({ field: 'destination', message: 'O destino da rota é obrigatório!' });
    }
    
    
    const result = {
        ok: errors.length === 0,
        errors,
        record
    }

    return new Promise((resolve, reject) => {
        const action = result.ok ? resolve : reject;
        return action(result);
    })
}