import React from 'react';
import PessoaListPageContainer from './PessoaListPageContainer';

export class PessoaListPage extends React.Component{
    
    render(){
      return (
        <PessoaListPageContainer {...this.props}/>
      )
    }
}

