
export function validate(record) {
    const errors = [];

    if (!record.oidMenu || record.oidMenu.toString().trim() === "" || parseInt(record.oidMenu) <= 0) {
        errors.push({ field: 'oidMenu', message: 'O menu da permissão é obrigatório!' });
    }

    if (!record.oidPerfil || record.oidPerfil.toString().trim() === "" || parseInt(record.oidPerfil) <= 0) {
        errors.push({ field: 'oidPerfil', message: 'O perfil da permissão é obrigatório!' });
    }

    const result = {
        ok: errors.length === 0,
        errors,
        record
    }

    return new Promise((resolve, reject) => {
        const action = result.ok ? resolve : reject;
        return action(result);
    })
}