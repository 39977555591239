
import { validate } from './validations';
import { build } from '../../services/crudFactory';
import { Action } from '../../services/actions.factory';

import authenticationService from "../../services/authentication.service";
import api from '../../services/api.service';

const BASE_PATH = '/lognex/userconfigurations'; 
const prefix = 'dashboard';
const crud = build(validate, BASE_PATH);

const newTypes = {
    //SET_USER_CONFIGURATIONS: `${prefix}setLookupStandardModalVisible`,

}

const actions = new Action(prefix, crud);

const types = Object.assign({}, actions.types, newTypes);

function fetchUserConfigurations(dispatch) {
    const userOid = authenticationService.getUserOid()
    if (userOid) {
        dispatch(actions.fetchRecord());
        api.get(`/api/v1/lognex/userConfigurations/${userOid}`)
            .then(({ data }) => {
                dispatch(actions.setField("userConfigurationRecord", data))
                dispatch(actions.hideLoader());
            })
            .catch(()=>{
                dispatch(actions.hideLoader());
            })
    }
}

actions.loadUserConfigurations = () => {
    return (dispatch, getState) => {
        fetchUserConfigurations(dispatch);
    };
}



export { types, actions }