
    import React from 'react';
    import PalletStandardListPageContainer from './PalletStandardListPageContainer';
    
    export class PalletStandardListPage extends React.Component{
        
        render(){
          console.log('PalletStandardListPageContainer');
          return (
            <PalletStandardListPageContainer {...this.props}/>
          )
        }
    }