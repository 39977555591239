import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { AutoComplete } from 'primereact/autocomplete';
import { Lookup } from './Lookup'
import { ButtonDialog } from '../button-dialog/ButtonDialog'


import './LookupField.scss';

export class LookupTable extends React.Component {
    render() {
        const props = this.props;
        let tbColumns = [{ selectionMode: "multiple", field: "selection", style: { width: '3em', field: "selection" } }]
        if (props.tableColumns) {
            tbColumns = tbColumns.concat(...props.tableColumns)
        }
        tbColumns = tbColumns.map((col, index) => (<Column {...col} key={`${index}-${col.field}`} />))
        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div
                        className="p-inputgroup lookup-field-complete"
                        style={{ width: "100%", display: "flex" }}
                    >
                        <AutoComplete
                            style={{ flexGrow: "1" }}
                            completeMethod={props.onComplete}
                            onChange={props.onInputFilter}
                            suggestions={props.records}
                            placeholder={props.placeholder}
                            value={props.value}
                            name={props.name}
                            field={props.field}
                            disabled={props.disabled}
                        />
                        <button
                            className="lognex-btn-highlighted"
                            disabled={props.disabled}
                            onClick={props.onClick}>
                            <i className="pi pi-search"></i>
                        </button>
                        <ButtonDialog
                            onClick={props.onRemoveSelection}
                            button={{
                                title: "Remover selecionado(s)",
                                className: "lognex-btn-danger"
                            }}
                            dialog={{
                                header: 'Confirmação',
                                modal: false,
                                message: props.removeSelectionMessage ? props.removeSelectionMessage : "Deseja realmente remover o registro(s)?",
                            }}
                        ></ButtonDialog>
                    </div>
                    <Lookup
                        visible={props.visible}
                        modal={props.modal}
                        header={props.header}
                        onHide={props.onHide}
                        onConfirm={props.onConfirm}
                        onCancel={props.onCancel}
                        records={props.records}
                        columns={props.columns}
                        totalRecords={props.totalRecords}
                        sortOrder={props.order}
                        sortField={props.sortField}
                        first={props.first}
                        rows={props.rows}
                        onPage={props.onPage}
                        onFilter={props.onFilter}
                        onSort={props.onSort}
                        isMultiple={true}
                        rowsPerPageOptions={this.props.rowsPerPageOptions || []}
                        paginator={this.props.paginator || false}
                    />
                </div>
                <div className="p-col-12">
                    <DataTable
                        selectionMode="multiple"
                        value={this.props.tableRecords}
                        metaKeySelection={false}
                        selection={this.props.tableSelection}
                        onSelectionChange={this.props.onTableSelectionChange}
                        lazy={true}
                        scrollable={this.props.scrollable || true}
                        scrollHeight={this.props.scrollHeight || "170px"}
                        emptyMessage="Não foram encontrados registros"
                       
                    >
                        {tbColumns}
                    </DataTable>
                </div>
            </div>
        );
    }
}
