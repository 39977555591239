import React from 'react'
import './login-form.component.scss';
import { Loader } from '../../components/loader/Loader';
import background from '../../assets/images/logo/logo_form_login.png';

const LoginForm = (props) => (
    <div className="login-form">
        <Loader />
        <span className="login-form__logo-container"><img src={background} alt="background" /></span>
        <input value={props.email} name="login" onChange={props.changeValue} placeholder="Seu Email" />
        <input 
            value={props.password} 
            name="password" 
            type="password" 
            onKeyPress={
                (e)=>{
                   if (e.key === 'Enter'){
                       props.authenticate();
                   }
                }
            } 
            onChange={props.changeValue}
            placeholder="Sua Senha" />
        <div className="login-form__lembrar">
            <span className="login-form__lembrar__span-lembrar">
                <input type="checkbox" />
                <span>Lembrar</span>
            </span>
            <span className="login-form__lembrar__span-esqueceuSenha">esqueceu a senha?</span>
        </div>
        <button className="btn-destacado"  onClick={props.authenticate}>Entrar</button>
        <button onClick={props.authenticate}>Cadastrar</button>
        <p>{props.errorMessage}</p>

    </div>
)

export default LoginForm;