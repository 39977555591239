import { validate } from './validations';
import { build } from '../../services/crudFactory';
import { Action } from '../../services/actions.factory';
import api from '../../services/api.service';

const BASE_PATH = '/lognex/towerequipment';
const prefix = 'crudTowerEquipment';
const crud = build(validate, BASE_PATH);

const newTypes = {  
  //
  SET_LOOKUP_EQUIPMENTTYPE_VISIBLE: `${prefix}setLookupEquipmentTypeVisible`,
}

const actions = new Action(prefix, crud);

const types = Object.assign({}, actions.types, newTypes); 
export { types, actions }

 
actions.setLookupEquipmentTypeVisible = (visible) => {
  return {
    type: types.SET_LOOKUP_EQUIPMENTTYPE_VISIBLE,
    visible,
  };
};