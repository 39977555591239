import React from 'react';
import './FileUploaderButtonPreview.scss'

export class FileUploaderButtonPreview extends React.Component {
    removeFiles() {
        const importInput = this.refs.importInput;
        importInput.files = null
    }

    render() {
        const { props } = this;
        let imgData = ""
        const tipImg = typeof props.fileImg;
        if (!props.fileImg || tipImg === "string") {
            imgData =  props.fileImg
        } else {
            imgData = URL.createObjectURL(props.fileImg);
        }

        return (
            <label className="content-label-upload" htmlFor="btnUploadPreview">
                <input
                    ref='importInput'
                    id="btnUploadPreview"
                    disabled={props.disabled}
                    title={props.title || "Importar"}
                    type='file'
                    accept={props.accept}
                    className={"lxUploadFileButton"}
                    onChange={(e) => {
                        props.onUpload(e);
                        this.removeFiles();
                        e.target.value = null;
                    }}
                />
                {props.fileImg && (
                    <img className='img-preview' src={imgData} alt="Preview" />
                )}
                <div className='content-center'>
                    <i className="pi pi-upload icon-upload"></i>
                    <label style={{ paddingTop: "5px", marginLeft: "5px", fontWeight: "bold" }}>
                        {props.label}
                    </label>
                </div>
            </label>
        )
    }
};

export default FileUploaderButtonPreview;
