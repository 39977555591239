
import React from 'react';
import CampanhaAnymarketFormPageContainer from './CampanhaAnymarketFormPageContainer';

export class CampanhaAnymarketFormPage extends React.Component {
    render() {
        return (
            <CampanhaAnymarketFormPageContainer {...this.props} />
        )
    }
}
