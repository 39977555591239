import { types } from "./acao.actions";
import { Reducer } from "../../services/reducer.factory";

const initialState = {
  columns: [
    {
      field: "oid",
      header: "Código",
      columnKey: "oid",
      sortable: true,
      filter: true
    },
    {
      field: "nome",
      header: "Nome",
      columnKey: "nome",
      sortable: true,
      filter: true
    },
    {
      field: "nomeModulo",
      header: "Módulo",
      columnKey: "nomeModulo",
      sortable: true,
      filter: true
    },

  ],
  currentRecord: {
    oid: "",
    nome: "",
    oidModulo: "",
    nomeModulo: "",
    desvincular: false,
    perfisAcaoSelecionados: [],
    ativo: true
  },
  lookupModulo: {
    visible: false,
    modal: true,
    header: 'Módulos'
  }
};

const reducer = new Reducer(initialState, types);

export function CRUDAcaoReducer(state, action) {
  state = reducer.execute(state, action);
  return execute(state, action);
}

function execute(state, action) {
  let result = state;
  switch (action.type) {
    case types.SET_LOOKUP_MODULO_VISIBLE:
      return setLookupModuloVisible(state, action);
    case types.PICKLIST_PERFIS_MOVE_TO_SOURCE:
      return pickListPerfisMoveToSource(state, action);
    case types.PICKLIST_PERFIS_MOVE_ALL_TO_SOURCE:
      return pickListPerfisMoveAllToSource(state, action);
    case types.PICKLIST_PERFIS_MOVE_TO_TARGET:
      return pickListPerfisMoveToTarget(state, action);
    case types.PICKLIST_PERFIS_MOVE_ALL_TO_TARGET:
      return pickListPerfisMoveAllToTarget(state, action);
    default:
      return result;
  }
}


function setLookupModuloVisible(state, action) {
  let lookupModulo = state.lookupModulo;
  lookupModulo = Object.assign({}, lookupModulo, { visible: action.visible });
  return Object.assign({}, state, { lookupModulo })
}

function buildPerfilAcao(perfis = [], oidAcao) {
  let perfisAcao = perfis.map(perfil => {
    return {
      oidAcao: oidAcao,
      oidPerfil: perfil.oid,
      nomePerfil: perfil.nome,
      oid: null
    }
  });
  return perfisAcao;
}

function pickListPerfisMoveToSource(state, action) {
  let perfisAcaoDeselecionados = [];

  action.perfisAcaoRemoved.forEach(perfilAcaoRemoved => {
    perfisAcaoDeselecionados = state.currentRecord.perfisAcaoSelecionados.filter(perfilAcao => perfilAcao.oid !== perfilAcaoRemoved.oid);
  });

  let currentRecord = Object.assign({}, state.currentRecord, { perfisAcaoSelecionados: perfisAcaoDeselecionados });
  return Object.assign({}, state, { currentRecord });
}

function pickListPerfisMoveAllToSource(state, action) {
  let currentRecord = Object.assign({}, state.currentRecord, { perfisAcaoSelecionados: [] });
  return Object.assign({}, state, { currentRecord });
}

function filterOldPerfisAcao(perfisAdd, perfisAcaoSelecionados) {
  return perfisAdd.filter(perfil => {
    let existe = perfisAcaoSelecionados.filter(perfilAcao => perfilAcao.oidPerfil === perfil.oid);
    return existe.length > 0 ? false : true;
  });
}

function pickListPerfisMoveToTarget(state, action) {
  let perfisAcaoSelecionados = [...state.currentRecord.perfisAcaoSelecionados];
  let perfisAdd = filterOldPerfisAcao(action.perfisAdd, perfisAcaoSelecionados);
  let perfisAcao = buildPerfilAcao(perfisAdd, action.oidAcao);

  perfisAcaoSelecionados = [...perfisAcaoSelecionados, ...perfisAcao];

  let currentRecord = Object.assign({}, state.currentRecord, { perfisAcaoSelecionados });
  return Object.assign({}, state, { currentRecord });
}

function pickListPerfisMoveAllToTarget(state, action) {
  return pickListPerfisMoveToTarget(state)
}


