import React from "react";
import { connect } from "react-redux";
import { Column } from "primereact/column";
import { actions } from "../../../../accountConfiguration.actions";
import { ButtonDialog } from "../../../../../../components/button-dialog/ButtonDialog";
import "./index.scss";
import { CadTagsPanelList } from "./CadTagsPanelList";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";

const initialState = {
  columns: [
    {
      field: "name",
      header: "Tag",
      columnKey: "name",
      sortable: false,
      filter: false,
    },
  ],
};
const mapStateToProps = (state) => {
  const { integrationConfigurations = null } = state.crudAccountConfigurationState.currentRecord;
  //normalizar dados packing
  let tags = [];

  if (integrationConfigurations && integrationConfigurations.integrationAnymarket && integrationConfigurations.integrationAnymarket.tags) {
    tags = integrationConfigurations.integrationAnymarket.tags.map((tag) => {
      return { name: tag };
    });
  }

  return {
    columns: initialState.columns,
    records: tags,
    selectedTagRecord:state.crudAccountConfigurationState.selectedTagRecord
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addNewFreightTag: (tag) => {
      dispatch(actions.addNewFreightTag(tag));
    },
    setSelectedTagRedord: (e) => {
      dispatch(actions.setSelectedTagRedord(e.value));
    },
    onRemoveSelectedTagRecord:()=>{
      dispatch(actions.onRemoveSelectedTagRecord());
    }
  };
};

class CadTagsPanelListPageContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDialogCadTags: false,
      tag: "",
    };
  }

  addBtnAddConfigTag = () => {
    this.setState({ showDialogCadTags: true });
  };

  onConfirmAddTag = () => {
    if (this.state.tag.trim().length > 0) {
      this.props.addNewFreightTag(this.state.tag.trim().toUpperCase());
      this.setState({ showDialogCadTags: false,tag:"" });
    }
  };

  onCancelAddTag = () => {
    this.setState({ showDialogCadTags: false, tag: "" });
  };

  changeHandlerTag = (e) => {
    this.setState({ tag: e.target.value });
  };
  
  render() {
    let tagsColumns = [<Column key="selectorColumn" selectionMode="single" style={{ width: "3em" }} />];
    tagsColumns = [
      ...tagsColumns,
      ...this.props.columns.map((col) => {
        return <Column {...col} key={col.field} />;
      }),
    ];

    const footerDialog = (
      <div>
        <button title="Confirmar" className="lognex-btn-highlighted" onClick={() => this.onConfirmAddTag()}>
          <i className="pi pi-check"></i>
        </button>
        <button title="Cancelar" className="lognex-btn-danger" onClick={this.onCancelAddTag}>
          <i className="pi pi-times"></i>
        </button>
      </div>
    );

    return (
      <div className="client-form">
        <div className="p-grid">
          <div className="p-col-3">
            <h3>Configuração de Tags</h3>
          </div>

          <div className="p-col-3  align-bottom-right  ">
            <button className={"lognex-btn-highlighted"} onClick={this.addBtnAddConfigTag} title="Novo" style={{ marginRight: ".25em" }}>
              <i className="pi pi-plus"></i>
            </button>

            <ButtonDialog
              onClick={this.props.onRemoveSelectedTagRecord}
              button={{
                title: "Remover",
                className: "lognex-btn-danger",
                disabled: !this.props.selectedTagRecord,
              }}
              dialog={{
                header: "Confirmação",
                modal: false,
                message: "Deseja realmente remover o registro?",
              }}
            ></ButtonDialog>
          </div>
        </div>
        <div className="p-grid">
          <div className="p-col-6">
            <CadTagsPanelList
              records={this.props.records}
              onSelectedTagsRecordsChange={this.props.setSelectedTagRedord}
              selectedTagRecord={this.props.selectedTagRecord}
              columns={tagsColumns}
            />
          </div>
        </div>

        {this.state.showDialogCadTags && (
          <Dialog
            className="content"
            header={"Cadastro de tags"}
            footer={footerDialog}
            visible={this.state.showDialogCadTags}
            style={this.props.style || { width: "320px" }}
            modal={true}
            onHide={this.onCancelAddTag}
          >
            <div className="p-grid">
              <div className="p-col-12 form-field-container">
                <label>Tag</label>
                <InputText value={this.state.tag} placeholder="Nome da tag" onChange={this.changeHandlerTag} name="tag" />
              </div>
            </div>
          </Dialog>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CadTagsPanelListPageContainer);
