import moment from 'moment';
import * as utils from '../utils';
import { types } from './expectedandrealized.action'
const initialState = {
   
    filter: {
        initialDateFilter: moment().startOf('month').toDate(),
        finalDateFilter: moment().toDate(),
    },
    // datasets: []
    datasets: {
        descriptionPoints: [],
        lineValues: []    
    }    
}

const execute = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_EXPECTED_AND_REALIZED:
            return setDataset(state, action.data)
        case types.SET_INITIAL_DATE:
            return setInitialDate(state, action);
        case types.SET_FINAL_DATE:
            return setFinalDate(state, action);
    }
    return state;
}

function setDataset(state, data) {
    let descriptionPoints = state.datasets.descriptionPoints
    let lineValues = state.datasets.lineValues
    data = data || [];
    let records = data.data || [];
    records.sort((a,b)=>{
        if (a.date == b.date){
            return 0;
        }else if (a.date > b.date){
            return 1;
        }
        return -1;
    })
    const descriptions = records.map(record => record.date);
    const dataset = {
        descriptions:[],
        line1:[],
        line2:[]
    }
    records.forEach(({date,totalQuoted,totalRealized}) => {
        // dataset.descriptions.push(moment(date,);
        if (date !== ""){
            dataset.descriptions.push(moment(date,"YYYY-MM-DD").toDate());
        }        
        dataset.line1.push(totalQuoted.toFixed(2));
        dataset.line2.push(totalRealized.toFixed(2))
    });

    const result = {
        currencyX:true,
        descriptionPoints:dataset.descriptions,
        lineValues:[
            {
                values:dataset.line1,
                title:"Orçado",
                color:"blue"
            },
            {
                values:dataset.line2,
                title:"Realizado",
                color:"red"
            }
        ]
    }
    

    //transformar os dados
    return Object.assign({}, state, { datasets: result })
}


function setInitialDate(state, action) {
    let filter = state.filter
    let initialDateFilter = action.date

    filter = Object.assign({}, filter, { initialDateFilter: initialDateFilter });
    return Object.assign({}, state, { filter })
}

function setFinalDate(state, action) {
    let filter = state.filter
    let finalDateFilter = action.date

    filter = Object.assign({}, filter, {finalDateFilter: finalDateFilter});
    return Object.assign({}, state, {filter})
}


export function reportAuditExpectedAndRealizedReducer(state, action) {
    return execute(state, action);
}


