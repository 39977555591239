
import React from 'react';
import { InputText } from 'primereact/inputtext';

export const ComponentRangeTypeForm = (props) => {

    return (
        <div className="componentRangeType-form">
            <div className="p-grid">
                <div className="p-col-6">
                    <div className="p-grid">

                        <div className="p-col-12 form-field-container">
                            <label>ID</label>
                            <InputText value={props.record.oid} onChange={props.changeHandler} name="oid" disabled={true} />
                        </div>
                        <div className="p-col-12 form-field-container">
                            <label>Código</label>
                            <InputText value={props.record.code} onChange={props.changeHandler} name="code" disabled={true} />
                        </div>
                        <div className="p-col-12 form-field-container">
                            <label>Nome</label>
                            <InputText value={props.record.name} onChange={props.changeHandler} name="name" disabled={true} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};