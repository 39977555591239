
import { types } from "./freightComponent.actions";
import { Reducer } from "../../services/reducer.factory";

const initialState = {
  columns: [
    {
      field: "oid",
      header: "Código",
      columnKey: "oid",
      sortable: true,
      filter: true
    }, {
      field: "code",
      header: "Sigla",
      columnKey: "code",
      sortable: true,
      filter: true
    }, {
      field: "name",
      header: "Nome",
      columnKey: "name",
      sortable: true,
      filter: true
    },{
      field: "groupType",
      header: "Tipo",
      columnKey: "groupType",
      sortable: true,
      filter: true
    }
  ],
  currentRecord: {
    oid: "",
    code: "",
    name: "",
    description: "",
    calculationMode: "",
    motionCalculationCode: "",
    order: "",
    isSum: false,
    generalities: false,
    byRoute: false,
    restrictByTransportDocumentType: false,
    restrictByPerson: false,
    useMinValue: false,
    suggestionUseMinValue: false,
    useMaxValue: false,
    suggestionUseMaxValue: false,
    useFixedValue: false,
    suggestionUseFixedValue: false,
    usePercentValue: false,
    suggestionUsePercentValue: false,
    useKilometerValue: false,
    suggestionUseKilometerValue: false,
    useKiloValue: false,
    useTextValue:false,
    useUnitValue: false,
    useDiscountValue: false,
    useCalculatedValue: false,
    suggestionUsekiloValue: false,
    useBooleanValue: false,
    useMultEntries:false,
    restrictedByClient: false,
    restrictedByBusinessUnit: false,
    restrictedByLocalization: false,
    restrictedByTypeAllowed: true,
    restrictedByTypeDenied: false,
    restrictedByType: 'allow',
    standardComponent: null,
    isBooleanChecked: false,
    isValueChecked: false,
    rangeTypes: [],
    ignoreICMSamount:false,
  }, 
  groupTypeFilter: {
    groupType: ''
  },
  rangeTypes: [],
};

const reducer = new Reducer(initialState, types);

export function CRUDFreightComponentReducer(state, action) {
  state = reducer.execute(state, action);
  return execute(state, action);
}

function execute(state, action) {
  let result = state;
  switch (action.type) {
    case types.SET_RECORD:
      return setRecord(state, action)
    case types.SET_GROUP_TYPE:
      return setGroupType(state, action)
    case types.SET_RESTRICTED_BY_TYPE:
      return setRestrictedByType(state, action)  
    default:
      return result;
  }
}

function setRecord(state,action){
  if(action.record){
    return {...state, currentRecord:action.record};
  }else {
    return {...state, currentRecord:initialState.currentRecord};
  }
}

function setRestrictedByType(state, action){
  let record = state.currentRecord
  record.restrictedByType = action.value
  return { ...state, record }
}

function setAllRangeTypes(state, action) {
  const rangeTypes = action.rangeTypes;
  return Object.assign({}, state, { rangeTypes });
}

function setGroupType(state, action) {
  let groupTypeFilter = Object.assign({}, state.groupTypeFilter);
  groupTypeFilter[action.field] = action.value;
  return Object.assign({}, state, { groupTypeFilter });
}


