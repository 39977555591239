
export function validate(record) {
    const errors = [];

    if (!record.name || record.name.trim() === "") {
        errors.push({ field: 'nome', message: 'O nome/razão social da empresa é obrigatório!' });
    }
    if (!record.tradeName || record.tradeName.trim() === "") {
        errors.push({ field: 'nome', message: 'O nome fantasia da empresa é obrigatório!' });
    }

    if (!record.code || record.code.trim() === "") {
        errors.push({ field: 'sigla', message: 'A sigla da empresa é obrigatório!' });
    }


    const result = {
        ok: errors.length === 0,
        errors,
        record
    }

    return new Promise((resolve, reject) => {
        const action = result.ok ? resolve : reject;
        return action(result);
    })
}