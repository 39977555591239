
import React from 'react';
import { connect } from 'react-redux';
import { Column } from 'primereact/column';
import { actions } from '../freightCalculation.actions';
import { routes } from '../../../constants';
import { Page } from "../../../components/page/Page";
import { FreightCalculationForm } from './FreightCalculationForm';
import { actions as locationActions } from '../../location/location.actions';
import {actions as clientActions} from '../../client/client.actions'
import {actions as vehicleTypeActions} from '../../vehicleType/vehicleType.actions'
import { actions as appActions } from '../../../store/app';
import * as menuActions from '../../../store/menu/menu.actions';
import * as utils from '../../../utils'
import * as IconFactory from '../../../services/icons.factory'
import './FreightCalculationFormPageContainer.scss';
import ModalCalculateCubage from '../componets/ModalCalculateCubage';

const mapStateToProps = state => {
  const freightCalculationParams = state.freightCalculationState.freightCalculationParams;
  const lookupClientBase = {
    records: state.crudClientState.records,
    columns: state.freightCalculationState.lookupClient.visible ? state.crudClientState.columns
    .filter(col => col.field == 'razaoSocial' || col.field == 'cnpj')
    .map(col => <Column key={col.field} {...col} />) : [<Column key={0} />],
    first: state.crudClientState.index,
    rows: state.crudClientState.max,
    totalRecords: state.crudClientState.count,
    sortField: state.crudClientState.sortField,
    order: state.crudClientState.sortOrder,    
  }
  let lookupClient = Object.assign({}, state.freightCalculationState.lookupClient, lookupClientBase);
  
  const lookupVehicleTypeBase = {
    records: state.crudVehicleTypeState.records,
    columns: state.freightCalculationState.lookupVehicleType.visible ? state.crudVehicleTypeState.columns
    .filter(col => col.field == 'name' )
    .map(col => <Column key={col.field} {...col} />) : [<Column key={0} />],
    first: state.crudVehicleTypeState.index,
    rows: state.crudVehicleTypeState.max,
    totalRecords: state.crudVehicleTypeState.count,
    sortField: state.crudVehicleTypeState.sortField,
    order: state.crudVehicleTypeState.sortOrder,    
  }
  let lookupVehicleType = Object.assign({}, state.freightCalculationState.lookupVehicleType, lookupVehicleTypeBase);
 
  const lookupOriginBase = {
    records: state.crudLocationState.records,
    columns: state.freightCalculationState.lookupOrigin.visible ?  state.crudLocationState.columns
      .filter(col => col.field === 'description')
  .map(col => <Column key={col.field} {...col} />) : [<Column key={0} />],
    first: state.crudLocationState.index,
    rows: state.crudLocationState.max,
    totalRecords: state.crudLocationState.count,
    sortField: state.crudLocationState.sortField,
    order: state.crudLocationState.sortOrder,
    editDisabled: !freightCalculationParams.locationOrigin
  }
  let lookupOrigin = Object.assign({}, state.freightCalculationState.lookupOrigin, lookupOriginBase);
  const lookupDestinationBase = {
    records: state.crudLocationState.records,
    columns: state.freightCalculationState.lookupDestination.visible ? state.crudLocationState.columns
      .filter(col => col.field === 'description')
      .map(col => <Column key={col.field} {...col} />) : [<Column key={0} />],
    first: state.crudLocationState.index,
    rows: state.crudLocationState.max,
    totalRecords: state.crudLocationState.count,
    sortField: state.crudLocationState.sortField,
    order: state.crudLocationState.sortOrder,
    editDisabled: !freightCalculationParams.locationDestination
  }
  let lookupDestination = Object.assign({}, state.freightCalculationState.lookupDestination, lookupDestinationBase);
  const currentMenu = state.menuState.currentRecord;
  const userConfigurations = state.appState.userConfigurations;
  let isFavorite = utils.isFavorite(currentMenu, userConfigurations);

  return {
    freightCalculationParams,
    lookupOrigin,
    lookupDestination,
    lookupClient,
    lookupVehicleType,
    tableQuotes: state.freightCalculationState.tableQuotes,
    menuItems: state.menuState.items,
    currentMenu,
    isFavorite,
    locationTypeFilterOptions: state.freightCalculationState.locationTypeFilterOptions,
    currentLocationTypeFilter: state.freightCalculationState.currentLocationTypeFilter,
    visibleModalCalculateCubage: state.freightCalculationState.visibleModalCalculateCubage,
    listComponentsCalculateCubage: state.freightCalculationState.listComponentsCalculateCubage,
    typeCalculateOptions: state.freightCalculationState.typeCalculateOptions,
    currentTypeCalculateFilter: state.freightCalculationState.currentTypeCalculateFilter,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    lookupOriginSetVisible: visible => dispatch(actions.setLookupOriginVisible(visible)),
    lookupOriginPaginate: paginationParams => dispatch(locationActions.paginate(paginationParams)),
    lookupOriginSortOrder: sortParams => dispatch(locationActions.sortOrder(sortParams)),
    lookupOriginApplyFilter: filters => {
      dispatch(locationActions.setType(null));
      dispatch(locationActions.applyFilter(filters))
    },
    lookupOriginLoad: () => {
      const filters = [{
        campo: "type",
        valor: 'CITY',
        tipoFiltro: 'IGUAL_A'

      }];
      dispatch(locationActions.applyFilter(filters))
    },
    lookupDestinationSetVisible: visible => dispatch(actions.setLookupDestinationVisible(visible)),
    lookupDestinationPaginate: paginationParams => dispatch(locationActions.paginate(paginationParams)),
    lookupDestinationSortOrder: sortParams => dispatch(locationActions.sortOrder(sortParams)),    
    lookupDestinationApplyFilter: filters => {
      dispatch(locationActions.setType(null));
      dispatch(locationActions.applyFilter(filters))
    },
    lookupDestinationLoad: () => {
      const filters = [{
        campo: "type",
        valor: 'CITY',
        tipoFiltro: 'IGUAL_A'

      }];
      dispatch(locationActions.applyFilter(filters))
    },

    setField: (field, value) => dispatch(actions.setField(field, value)),
    changeHandler: event => {
      const target = event.target;
      const field = target.name;
      let value = target.value;
      dispatch(actions.removeErrors([{ field }]));
      dispatch(actions.setField(field, value));
    },
    calculateFreight: () => { dispatch(actions.calculateFreight()) },
    setLocationOrigin: (location) => {
      dispatch(actions.setLocationOrigin(location))
    },
    setLocationDestination: (location) => {
      dispatch(actions.setLocationDestination(location))
    },
    setValue: (value) => {
      dispatch(actions.setValue(value))
    },
    setWeight: (weight) => {
      dispatch(actions.setWeight(weight))
    },
    setVolumes: (volumes) => {
      dispatch(actions.setVolumes(volumes))
    },
    setQuantityOfItems: (quantityOfItems) => {
      dispatch(actions.setQuantityOfItems(quantityOfItems))
    },
    setZipCodeOrigin: (zipCodeOrigin) => {
      dispatch(actions.setZipCodeOrigin(zipCodeOrigin))
    },
    setZipCodeDestination: (zipCodeDestination) => {
      dispatch(actions.setZipCodeDestination(zipCodeDestination))
    },
    setCubage: (cubage) => {
      dispatch(actions.setCubage(cubage))
    },
    setDistance: (distance) => {
      dispatch(actions.setDistance(distance))
    },
    userLastAccess: (oidMenu) => dispatch(appActions.userLastAccess(oidMenu)),
    setCurrentMenu: (menuPath) => dispatch(menuActions.setCurrentRecord(menuPath)),
    toggleFavorite: (isFavorite, oidMenu) => {
      dispatch(appActions.toggleFavorite(isFavorite, oidMenu))
    },
    locationTypeFilterChangeHandler: (e) => {
      dispatch(actions.setCurrentLocationTypeFilter(e.value))
    },

    lookupClientLoad: () => {
      dispatch(clientActions.load())
    },
    lookupClientSetVisible: (visible) => {
      dispatch(actions.setLookupClientVisible(visible))
    },
    setClient: (client) => {
      dispatch(actions.setClient(client))
    },
    lookupClientPaginate: paginationParams => dispatch(clientActions.paginate(paginationParams)),
    lookupClientSortOrder: sortParams => dispatch(clientActions.sortOrder(sortParams)),
    lookupClientApplyFilter: filters => {
      dispatch(clientActions.setType(null));
      dispatch(clientActions.applyFilter(filters))
    },

    lookupVehicleTypeLoad: () => {
      dispatch(vehicleTypeActions.load())
    },
    lookupVehicleTypeSetVisible: (visible) => {
      dispatch(actions.setLookupVehicleTypeVisible(visible))
    },
    setVehicleType: (vehicleType) => {
      dispatch(actions.setVehicleType(vehicleType))
    },
    lookupVehicleTypePaginate: paginationParams => dispatch(vehicleTypeActions.paginate(paginationParams)),
    lookupVehicleTypeSortOrder: sortParams => dispatch(vehicleTypeActions.sortOrder(sortParams)),
    lookupVehicleTypeApplyFilter: filters => {
      dispatch(vehicleTypeActions.setType(null));
      dispatch(vehicleTypeActions.applyFilter(filters))
    },
    clearQuotes: () => dispatch(actions.clearQuotes()),
    setVisibleModalCalculateCubage: (visible) => dispatch(actions.setVisibleModalCalculateCubage(visible)),
    addComponentListCalculateCubage: () => dispatch(actions.addComponentListCalculateCubage()),
    removeComponentListCalculateCubage: (component) => dispatch(actions.removeComponentListCalculateCubage(component)),    
    setValuesFieldCalculateFreitgh: (values) => dispatch(actions.setValuesFieldCalculateFreitgh(values)),
    clearFieldCalculateFreitgh: () => dispatch(actions.clearFieldCalculateFreitgh()),
    setTypeCalculate: (value) => dispatch(actions.setTypeCalculate(value)),
  }
}

class FreightCalculationFormPageContainer extends React.Component {

  constructor(props) {
    super(props);
    actions.afterSave = ({ oid }) => {
      this.props.history.replace(`${routes.PATH_FREIGHTCALCULATION}/${oid}`);
    };
    actions.afterNew = () => {
      this.props.history.push(`${routes.PATH_FREIGHTCALCULATION}/novo`);
    };
    actions.afterRemove = () => {
      this.props.history.replace(`${routes.PATH_FREIGHTCALCULATION}`);
    };
  }

  componentWillReceiveProps(newProps) {
    if ((!this.props.menuItems || this.props.menuItems.length === 0) && newProps.menuItems && newProps.menuItems.length > 0) {
      const menuPath = this.props.history.location.pathname;
      this.props.setCurrentMenu(menuPath)
    }

    if ((!this.props.currentMenu && newProps.currentMenu) ||
      (this.props.currentMenu !== newProps.currentMenu)) {
      const oidMenu = newProps.currentMenu.oid;
      this.props.userLastAccess(oidMenu)
    }
  }

  componentDidMount() {
    const oid = this.props.match.params.id;
    if (oid && parseInt(oid)) this.props.loadById(oid);
    const menuPath = this.props.history.location.pathname;
    this.props.setCurrentMenu(menuPath)
  }

  componentWillUnmount() {
    this.props.clearQuotes();
  }

  searchHandler = () => {
    this.props.history.push(`${routes.PATH_FREIGHTCALCULATION}`)
  }

  lookupOriginOnPage = (e) => {
    this.props.lookupOriginPaginate({
      max: e.rows,
      index: e.first
    })
  }
  lookupOriginOnSort = (e) => {
    this.props.lookupOriginSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField
    })


  }
  lookupOriginOnFilter = (e) => {
    const filters = [];
    const keys = Object.keys(e.filters) || [];
    keys.forEach(key => {
      filters.push({
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: 'CONTEM'

      })
    })
    filters.push({
      campo: "type",
      valor: 'CITY',
      tipoFiltro: 'IGUAL_A'

    });
    this.props.lookupOriginApplyFilter(filters)

  }
  lookupOriginOnClick = (e) => {
    this.props.lookupOriginApplyFilter([]);
    this.props.lookupOriginLoad();
    this.props.lookupOriginSetVisible(true);
  }
  lookupOriginOnHide = (e) => {
    this.props.lookupOriginApplyFilter([]);
    this.props.lookupOriginSetVisible(false);
  }
  lookupOriginOnConfirm = (e) => {
    this.props.lookupOriginApplyFilter([]);
    this.props.setLocationOrigin(e.selection);
    this.props.lookupOriginSetVisible(false);

  }
  lookupOriginOnCancel = (e) => {
    this.props.lookupOriginApplyFilter([]);
    this.props.setLocationOrigin(null);
    this.props.lookupOriginSetVisible(false);
  }
  lookupOriginOnEdit = () => {
    if (this.props.record.oidOrigin) {
      this.props.history.push(`${routes.PATH_ORIGIN}/${this.props.record.oidOrigin}`);
    }
  }

  lookupOriginOnComplete = e => {
  };

  lookupOriginOnInputFilter = (e) => {
    const value = e.value;
    if (typeof value === 'string') {
      const filters = [{
        campo: "description",
        valor: value,
        tipoFiltro: 'CONTEM'

      }];
      filters.push({
        campo: "type",
        valor: 'CITY',
        tipoFiltro: 'IGUAL_A'

      });
      this.props.setLocationOrigin({ description: value });
      this.props.lookupOriginApplyFilter(filters)
    } else {
      this.props.setLocationOrigin(value);
      this.props.lookupOriginApplyFilter([])
    }
  }
  lookupDestinationOnPage = (e) => {
    this.props.lookupDestinationPaginate({
      max: e.rows,
      index: e.first
    })
  }
  lookupDestinationOnSort = (e) => {
    this.props.lookupDestinationSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField
    })


  }
  lookupDestinationOnFilter = (e) => {
    const filters = [];
    const keys = Object.keys(e.filters) || [];
    keys.forEach(key => {
      filters.push({
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: 'CONTEM'

      })
    })
    filters.push({
      campo: "type",
      valor: 'CITY',
      tipoFiltro: 'IGUAL_A'

    });
    this.props.lookupDestinationApplyFilter(filters)
  }
  lookupDestinationOnClick = (e) => {
    this.props.lookupDestinationApplyFilter(null);
    this.props.lookupDestinationLoad();
    this.props.lookupDestinationSetVisible(true);
  }
  lookupDestinationOnHide = (e) => {
    this.props.lookupDestinationSetVisible(false);
  }
  lookupDestinationOnConfirm = (e) => {
    this.props.setLocationDestination(e.selection);
    this.props.lookupDestinationSetVisible(false);

  }
  lookupDestinationOnCancel = (e) => {
    this.props.setLocationDestination(null);
    this.props.lookupDestinationSetVisible(false);
  }
  lookupDestinationOnEdit = () => {
    if (this.props.record.oidDestination) {
      this.props.history.push(`${routes.PATH_DESTINATION}/${this.props.record.oidDestination}`);
    }
  }

  lookupDestinationOnComplete = e => {
  };

  lookupDestinationOnInputFilter = (e) => { //TODO
    const value = e.value;
    if (typeof value === 'string') {
      let filters = [{
        campo: "description",
        valor: value,
        tipoFiltro: 'CONTEM'

      }];
      this.props.setLocationDestination({ description: value });
      filters.push({
        campo: "type",
        valor: 'CITY',
        tipoFiltro: 'IGUAL_A'

      });
      this.props.lookupDestinationApplyFilter(filters)
    } else {
      this.props.setLocationDestination(value);
      this.props.lookupOriginApplyFilter([])
    }
  }

  lookupClientOnHide = (e) => {
    this.props.lookupClientSetVisible(false)
  }

  lookupClientOnConfirm = (e) => {
    this.props.lookupClientApplyFilter([]);
    this.props.setClient(e.selection);
    this.props.lookupClientSetVisible(false);

  }

  lookupClientOnCancel = (e) => {
    this.props.lookupClientSetVisible(false)
  }

  lookupClientOnPage = (e) => {
    this.props.lookupClientPaginate({
      max: e.rows,
      index: e.first
    })

  }

  lookupClientOnSort = (e) => {
    this.props.lookupClientSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField
    })    
  }

  lookupClientOnFilter = (e) => {
    const filters = [];
    const keys = Object.keys(e.filters) || [];
    keys.forEach(key => {
      filters.push({
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: 'CONTEM'

      })
    })
    this.props.lookupClientApplyFilter(filters)
  }

  lookupClientOnClick = (e) => {

    this.props.lookupClientApplyFilter(null);
    this.props.lookupClientLoad()
    this.props.lookupClientSetVisible(true);

  }

  lookupClientOnEdit = (e) => {
    if (this.props.record.oidClient) {
      this.props.history.push(`${routes.PATH_CLIENT}/${this.props.record.oidClient}`);
    }
    
  }

  lookupClientOnComplete = (e) => {
    //TODO
  }

  lookupClientOnInputFilter = (e) => {    
    const value = e.value;
    if (typeof value === 'string') {
      const filters = [{
        campo: "razaoSocial",
        valor: value,
        tipoFiltro: 'CONTEM'

      }];
      this.props.setClient({ razaoSocial: value });
      this.props.lookupClientApplyFilter(filters)
    } else {
      this.props.setClient(value);
      this.props.lookupClientApplyFilter([])
    }
  }

    
  lookupVehicleTypeOnHide = (e) => {
    this.props.lookupVehicleTypeSetVisible(false)
  }

  lookupVehicleTypeOnConfirm = (e) => {
    this.props.lookupVehicleTypeApplyFilter([]);
    this.props.setVehicleType(e.selection);
    this.props.lookupVehicleTypeSetVisible(false);

  }

  lookupVehicleTypeOnCancel = (e) => {
    this.props.lookupVehicleTypeSetVisible(false)
  }

  lookupVehicleTypeOnPage = (e) => {
    this.props.lookupVehicleTypePaginate({
      max: e.rows,
      index: e.first
    })

  }

  lookupVehicleTypeOnSort = (e) => {
    this.props.lookupVehicleTypeSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField
    })    
  }

  lookupVehicleTypeOnFilter = (e) => {
    const filters = [];
    const keys = Object.keys(e.filters) || [];
    keys.forEach(key => {
      filters.push({
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: 'CONTEM'

      })
    })
    this.props.lookupVehicleTypeApplyFilter(filters)
  }

  lookupVehicleTypeOnClick = (e) => {

    this.props.lookupVehicleTypeApplyFilter(null);
    this.props.lookupVehicleTypeLoad()
    this.props.lookupVehicleTypeSetVisible(true);

  }

  lookupVehicleTypeOnEdit = (e) => {
    if (this.props.record.oid) {
      this.props.history.push(`${routes.PATH_VEHICLE_TYPE}/${this.props.record.oid}`);
    }
    
  }

  lookupVehicleTypeOnComplete = (e) => {
    //TODO
  }

  lookupVehicleTypeOnInputFilter = (e) => {    
    const value = e.value;
    if (typeof value === 'string') {
      const filters = [{
        campo: "name",
        valor: value,
        tipoFiltro: 'CONTEM'

      }];
      this.props.setVehicleType({ name: value });
      this.props.lookupVehicleTypeApplyFilter(filters)
    } else {
      this.props.setVehicleType(value);
      this.props.lookupVehicleTypeApplyFilter([])
    }
  }
  
  locationOriginChangeHandler = (e) => {
    this.props.setLocationOrigin(e.target.value)
  }

  locationDestinationChangeHandler = (e) => {
    this.props.setLocationDestination(e.target.value)
  }


  valueChangeHandler = (e) => {
    this.props.setValue(e.target.value)
  }

  weightChangeHandler = (e) => {
    this.props.setWeight(e.target.value)
  }

  volumesChangeHandler = (e) => {
    this.props.setVolumes(e.target.value)
  }

  quantityOfItemsChangeHandler = (e) => {
    this.props.setQuantityOfItems(e.target.value)
  }

  zipCodeOriginChangeHandler = (e) => {
    this.props.setZipCodeOrigin(e.target.value)
  }
  zipCodeDestinationChangeHandler = (e) => {
    this.props.setZipCodeDestination(e.target.value)
  }
  cubageChangeHandler = (e) => {
    if( this.props.listComponentsCalculateCubage.length >= 0 ){
      this.props.clearFieldCalculateFreitgh();    
    }
    this.props.setCubage(e.target.value);  
  }
  distanceChangeHandler = (e) => {
    this.props.setDistance(e.target.value)
  }
  onFavorite = (e) => {
    if (this.props.currentMenu) {
      const oidMenu = this.props.currentMenu.oid
      const isFavorite = this.props.isFavorite;
      this.props.toggleFavorite(isFavorite, oidMenu)
    }
  }

  onSetVisibleModalCalculateCubage = (visible) => {
    this.props.setVisibleModalCalculateCubage(visible);
  }

  onCalculateCubageChangeHandler = () => {
    let cubage = 0; 
    this.props.listComponentsCalculateCubage.map( component => {
      
      let comprimento = component.comprimento;
      let largura = component.largura;
      let altura = component.altura;

      if (this.props.currentTypeCalculateFilter === "Centimetros"){
        comprimento = comprimento / 100;
        largura = largura / 100;
        altura = altura / 100;
      } 
      cubage += ((comprimento * largura * altura ) * component.quantidade);
      
    });
    
    if (cubage > 0 ){
      this.props.setCubage( cubage.toFixed(3));
      this.onSetVisibleModalCalculateCubage(false)
    }
  }

  onChangeHandler = (values) => {
    this.props.setValuesFieldCalculateFreitgh(values);
  }

  onTypeCalculateChangeHandler = (e) => {
    this.props.setTypeCalculate(e.value);
  }



  render() {
    const isFavorite = this.props.isFavorite;
    const currencyTemplate = (rowData, column) => {
      return (
        <div style={{ textAlign: 'center' }}>{utils.numberToCurrency(rowData[column.field], 'REAL')}</div>
      )
    }

    const valueTemplate = (rowData, column) => {
      return (
        <div style={{ textAlign: 'center' }}> {utils.formatNumber(rowData[column.field])}</div>
      )
    }

    const deadlineTemplate = (rowData, column) => {
      return (
        <div style={{ textAlign: 'center' }}> {utils.formatNumber(rowData[column.field])}<span title="dias">{' d'}</span></div>
      )
    }

    const tagsTemplate = (rowData, column) => {
      const tags = rowData[column.field] && rowData[column.field].length > 0 ? rowData[column.field].join(', ') : ""
      return (
        <div style={{ textAlign: 'center' }}> {tags}</div>
      )
    }
    
    const workingDaysTemplate = (rowData, column) => {
      const result = rowData[column.field];
      return <div style={{ textAlign: 'center' }}>{result === true ? 'Úteis' : result === false ? 'Todos' : ''}</div>;
    };

    const applyColumnsCustomBodies = (columns) => {
      return columns.map(col => {
        if (col.columnKey === 'freightValue' || col.columnKey === 'icmsValue' || col.columnKey === 'totalFreightValue') {
          col.body = currencyTemplate
        } else if (col.columnKey === 'carrierDeliveryDeadline' || col.columnKey === 'clientDeliveryDeadline') {
          col.body = deadlineTemplate
        } else if (col.columnKey === 'nfePercent') {
          col.body = valueTemplate
        } else if (col.columnKey === 'tags') {
          col.body = tagsTemplate
        } else if (col.columnKey === 'onlyWorkingDays') {
          col.body = workingDaysTemplate
        }
        return col
      })
    }
    const headerTemplate = (iconName, col, label = false) => {
      return (
        <div className="iconTemplate">
          <span>
            <img src={IconFactory.getIcon(iconName)}
              title={col.header} alt={col.header} />
          </span>
          <span>
            {label ? col.header : ""}
          </span>
        </div>)
    }


    const applyColumnsCustomHeaderBodies = (columns) => {
      return columns.map(col => {
        if (col.columnKey === 'carrierDeliveryDeadline') {
          col.headerBody = headerTemplate("clock_truck", col)
        } else if (col.columnKey === 'clientDeliveryDeadline') {
          col.headerBody = headerTemplate("clock_person", col)
        } else if (col.columnKey === 'tags') {
          col.headerBody = headerTemplate("mark", col)
        } else if (col.columnKey === 'onlyWorkingDays') {
          col.headerBody = headerTemplate("calendar", col)
        }
        return col
      })
    }

    let columns = applyColumnsCustomBodies(this.props.tableQuotes.columns)
    columns = applyColumnsCustomHeaderBodies(this.props.tableQuotes.columns)

    let tableQuotes = {
      records: this.props.tableQuotes.records.map(record => {
        const data = {...record}
        data.carrierName = record && record.carrier ? record.carrier.name :"";
        return data
      }),
      columns,
    }

    const content = (
      <div>

        <FreightCalculationForm
          changeHandler={this.props.changeHandler}
          freightCalculationParams={this.props.freightCalculationParams}
          clientParams={this.props.clientParams}
          tableQuotes={tableQuotes}
          onChangeLocationOrigin={this.locationOriginChangeHandler}
          onChangeLocationDestination={this.locationDestinationChangeHandler}
          onChangeValue={this.valueChangeHandler}
          onChangeWeight={this.weightChangeHandler}
          onChangeQuantityOfItems={this.quantityOfItemsChangeHandler}
          onChangeVolumes={this.volumesChangeHandler}
          onChangeZipCodeDestination={this.zipCodeDestinationChangeHandler}
          onChangeZipCodeOrigin={this.zipCodeOriginChangeHandler}
          onChangeCubage={this.cubageChangeHandler}
          onChangeDistance={this.distanceChangeHandler}
          onCalculateFreight={this.props.calculateFreight}
          lookupOrigin={this.props.lookupOrigin}
          lookupOriginOnHide={this.lookupOriginOnHide}
          lookupOriginOnConfirm={this.lookupOriginOnConfirm}
          lookupOriginOnCancel={this.lookupOriginOnCancel}
          lookupOriginOnPage={this.lookupOriginOnPage}
          lookupOriginOnSort={this.lookupOriginOnSort}
          lookupOriginOnFilter={this.lookupOriginOnFilter}
          lookupOriginOnClick={this.lookupOriginOnClick}
          lookupOriginOnEdit={this.lookupOriginOnEdit}
          lookupOriginOnComplete={this.lookupOriginOnComplete}
          lookupOriginOnInputFilter={this.lookupOriginOnInputFilter}
  
          lookupClient={this.props.lookupClient}
          lookupClientOnHide={this.lookupClientOnHide}
          lookupClientOnConfirm={this.lookupClientOnConfirm}
          lookupClientOnCancel={this.lookupClientOnCancel}
          lookupClientOnPage={this.lookupClientOnPage}
          lookupClientOnSort={this.lookupClientOnSort}
          lookupClientOnFilter={this.lookupClientOnFilter}
          lookupClientOnClick={this.lookupClientOnClick}
          lookupClientOnEdit={this.lookupClientOnEdit}
          lookupClientOnComplete={this.lookupClientOnComplete}
          lookupClientOnInputFilter={this.lookupClientOnInputFilter}
  
  
          lookupVehicleType={this.props.lookupVehicleType}
          lookupVehicleTypeOnHide={this.lookupVehicleTypeOnHide}
          lookupVehicleTypeOnConfirm={this.lookupVehicleTypeOnConfirm}
          lookupVehicleTypeOnCancel={this.lookupVehicleTypeOnCancel}
          lookupVehicleTypeOnPage={this.lookupVehicleTypeOnPage}
          lookupVehicleTypeOnSort={this.lookupVehicleTypeOnSort}
          lookupVehicleTypeOnFilter={this.lookupVehicleTypeOnFilter}
          lookupVehicleTypeOnClick={this.lookupVehicleTypeOnClick}
          lookupVehicleTypeOnEdit={this.lookupVehicleTypeOnEdit}
          lookupVehicleTypeOnComplete={this.lookupVehicleTypeOnComplete}
          lookupVehicleTypeOnInputFilter={this.lookupVehicleTypeOnInputFilter}
  
          lookupDestination={this.props.lookupDestination}
          lookupDestinationOnHide={this.lookupDestinationOnHide}
          lookupDestinationOnConfirm={this.lookupDestinationOnConfirm}
          lookupDestinationOnCancel={this.lookupDestinationOnCancel}
          lookupDestinationOnPage={this.lookupDestinationOnPage}
          lookupDestinationOnSort={this.lookupDestinationOnSort}
          lookupDestinationOnFilter={this.lookupDestinationOnFilter}
          lookupDestinationOnClick={this.lookupDestinationOnClick}
          lookupDestinationOnEdit={this.lookupDestinationOnEdit}
          lookupDestinationOnComplete={this.lookupDestinationOnComplete}
          lookupDestinationOnInputFilter={this.lookupDestinationOnInputFilter}
          locationTypeFilterOptions={this.props.locationTypeFilterOptions}
          currentLocationTypeFilter={this.props.currentLocationTypeFilter}
          locationTypeFilterChangeHandler={this.props.locationTypeFilterChangeHandler}
  
          onSetVisibleModalCalculateCubage={(e) => this.onSetVisibleModalCalculateCubage(true)}
  
        />
  
  
         {/* /* modal de aprovação de componentes  */}
         {this.props.visibleModalCalculateCubage && (
          <ModalCalculateCubage
            visible={this.props.visibleModalCalculateCubage}
            onHide={(e) => this.onSetVisibleModalCalculateCubage(false)}
            calculateCubageChangeHandler={this.onCalculateCubageChangeHandler}
            listComponents={this.props.listComponentsCalculateCubage}
            addComponentChangeHandler={this.props.addComponentListCalculateCubage}
            removeComponentChangeHandler={this.props.removeComponentListCalculateCubage}
            onChangeHandler={this.onChangeHandler}
            currentTypeCalculateFilter={this.props.currentTypeCalculateFilter}
            typeCalculateOptions={this.props.typeCalculateOptions}
            typeCalculateChangeHandler={(e) => this.onTypeCalculateChangeHandler(e)}
          ></ModalCalculateCubage>
        )}
      </div>

    );
    const header = (
      <div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h1>Calculadora de frete</h1>

          <i className={`pi pi-star lxFavoriteStar ${isFavorite ? 'lxFavorite' : ''}`}
            title={isFavorite ? 'Remover dos favoritos' : 'Adicionar aos favoritos'} onClick={this.onFavorite}></i>
        </div>
        <hr className="lxSplitter" ></hr>
      </div>
    );
    return (<Page header={header} content={content} />);
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FreightCalculationFormPageContainer);