import React from 'react';
import { GridBox } from "../../../components/grid-box/GridBox";
import { TextSelector } from "../../../components/text-selector/TextSelector";
import  calendar from '../../../assets/images/icons/calendar_blue.png';
import  truck from '../../../assets/images/icons/truck_blue.png';
import { Lookup } from '../../../components/lookup/Lookup';

export const IndicatorWithLocationAndPeriod = (props) => {
    
    const footerIndicator = (
        <div>
            <TextSelector
                icon={truck}
                text={props.location} 
                onActivate={props.onLocationClick}
            />
            <TextSelector 
                icon={calendar} 
                text={props.period} 
                onActivate={props.onPeriodClick}
            />
        </div>
    )

    const lookup = props.lookup;
    if(!lookup){
        return null
    }

    return (
        <div>
            <GridBox 
                title={props.title}
                content={props.value}
                footer={footerIndicator}
            />
            <Lookup
                style={{width:'400px'}}
                visible={lookup.location.show}
                modal={false}
                header={"Selecionar Estado"}
                onHide={lookup.location.onHide}
                onConfirm={lookup.location.onConfirm}
                onCancel={lookup.location.onCancel}
                records={lookup.location.records}
                columns={lookup.location.columns}
                totalRecords={lookup.location.totalRecords}
                first={lookup.location.first}
                rows={lookup.location.rows}
                rowsPerPageOptions={lookup.location.rowsPerPageOptions}
                onPage={lookup.location.onPage}
                onFilter={lookup.location.onFilter}
            />
            <Lookup
                style={{width:'400px'}}
                visible={lookup.period.show}
                modal={false}
                header={"Selecionar período"}
                onHide={lookup.period.onHide}
                onConfirm={lookup.period.onConfirm}
                onCancel={lookup.period.onCancel}
                records={lookup.period.records}
                columns={lookup.period.columns}
                totalRecords={lookup.period.totalRecords}
                first={lookup.period.first}
                rows={lookup.period.rows}
                rowsPerPageOptions={lookup.period.rowsPerPageOptions}
                onPage={lookup.period.onPage}
                onFilter={lookup.period.onFilter}
            />

        </div>
    )

}