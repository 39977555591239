import React from "react";
import { connect } from "react-redux";
import { Column } from "primereact/column";
import { Page } from "../../../components/page/Page";
import { actions } from "../pendence_doc.actions";
import { AppService, COMPANY_CHANGE_EVENT_NAME, BUSINESS_UNIT_CHANGE_EVENT_NAME } from "../../../services/app.service";
import { PendenceDocList } from "./PendenceDocList";
import moment from "moment";
import { FiltroPendenceDoc } from "../components/filtro/filtro";
import { ButtonDialog } from "../../../components/button-dialog/ButtonDialog";
import { PendenceRowTreeview } from "../components/PendenceRowTreeview";
import "./pendence.scss";  
import { makeMessages, messageActions } from "../../../store/message";

const mapStateToProps = (state) => {
  return {
    columns: state.crudPendenceDocState.columns,
    records: state.crudPendenceDocState.records,
    first: state.crudPendenceDocState.index,
    rows: state.crudPendenceDocState.max,
    totalRecords: state.crudPendenceDocState.count,
    sortField: state.crudPendenceDocState.sortField,
    order: state.crudPendenceDocState.sortOrder,
    initialDate: state.crudPendenceDocState.initialDate,
    finalDate: state.crudPendenceDocState.finalDate,
    columnsCarrierTemplate:state.crudPendenceDocState.columnsCarrierTemplate,
    expandedRows: state.crudPendenceDocState.expandedRows,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    paginate: (paginationParams) => dispatch(actions.paginate(paginationParams)),
    sortOrder: (sortParams) => dispatch(actions.sortOrder(sortParams)),
    applyFilter: (filterParams) => {
      dispatch(actions.setType(null));
      dispatch(actions.applyFilter(filterParams));
      dispatch(actions.saveFilter(filterParams));
    },
    load: () => {
      dispatch(actions.load());
    },

    initialDateChangeHandler: (e) => dispatch(actions.setInitialDateFilter(e.value)),
    finalDateChangeHandler: (e) => dispatch(actions.setFinalDateFilter(e.value)),
    setExpandedRows: (expandedRows) => {
      dispatch(actions.setExpandedRows(expandedRows));
    },
    reprocessPendenceDoc: (documentReprocess) => {
      dispatch(actions.reprocessPendenceDoc(documentReprocess));
    },
    copyClipboardMessage: (message) => dispatch(messageActions.messageShowMessages(makeMessages(message, "success"))),
  };
};

class PendenceDocListPageContainer extends React.Component {
  componentDidMount() {
    this.onApplyFilter();
    AppService.on(COMPANY_CHANGE_EVENT_NAME, this);
    AppService.on(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
  }
  componentWillUnmount() {
    AppService.off(COMPANY_CHANGE_EVENT_NAME, this);
    AppService.off(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
  }

  onReceiveAppNotifications = (eventName, params) => {
    if (eventName === COMPANY_CHANGE_EVENT_NAME || eventName === BUSINESS_UNIT_CHANGE_EVENT_NAME) {
      this.onApplyFilter();
    }
  };
  onPage = (e) => {
    this.props.paginate({
      max: e.rows,
      index: e.first,
    });
  };
  onSort = (e) => {
    this.props.sortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField,
    });
  };

  onFilter = (e) => {
    const filters = [];
    const keys = Object.keys(e.filters) || [];
    keys.forEach((key) => {
      filters.push({
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: "CONTEM",
      });
    });
    this.props.applyFilter(filters);
  };

  normalizeDate = (date) => {
    return date && typeof date === "object" ? date : null;
  };

  getFilters = () => {
    let filters = [];
    const initialDateFilter = this.normalizeDate(this.props.initialDate);
    const finalDateFilter = this.normalizeDate(this.props.finalDate);

    if (initialDateFilter && finalDateFilter) {
      filters.push({
        campo: "eventRecord.createdAt",
        valor: moment(initialDateFilter).startOf("day").toDate(),
        valorComplementar: moment(finalDateFilter).endOf("day").toDate(),
        tipoFiltro: "ENTRE",
      });
    }
    return filters;
  };

  onApplyFilter = () => {
    const filters = this.getFilters();
    this.props.applyFilter(filters); 
  };

  onReprocessPendenceDoc = (rowData) => { 
    if(rowData.status==="PENDING"){ 
      this.props.reprocessPendenceDoc({
        documentId:rowData.documentId,
        status:rowData.status,
        type:rowData.type,
        oid:rowData.oid
      });
    }
  };

  setExpandedRowsByRowClick = (e) => { 
    this.props.setExpandedRows(e.data); 
  };
  
  setExpandedRowsClick = (e) => {
    // console.log("setExpandedRowsClick e.data=",e.data);
    // this.props.setExpandedRows([e.data]);    
  };
  copyToClipBoard = (str) => { 
    navigator.clipboard.writeText(str)
        .then(() => {        
          this.props.copyClipboardMessage(["Copiado com sucesso!"]);
        })
  }

  render() {
    const header = (
      <div>
        <h1>Documentos pendentes</h1>
        <hr className="lxSplitter"></hr>
      </div>
    );
    const datetimeTemplate = (rowData, column) => {
      try {
        const date = rowData.eventRecord.createdAt; //data da ultima entrega realizada
        let formattedDate = "";
        if (date) {
          formattedDate = moment(date).format("DD/MM/YYYY HH:mm:ss");
        }
        return (
          <div>
            <p style={{ textAlign: "center", color: "black" }}>{formattedDate}</p>
          </div>
        );
      } catch (error) {
        return <div></div>;
      }
    };
    const statusTemplate = (rowData, column) => {
      try {
        let status = "indefinido"; //data da ultima entrega realizada

        switch (rowData.status) {
          case "PENDING":
            status = "PENDENTE";
            break;
          case "REPROCESSING":
            status = "REPROCESSANDO";
            break
          case "RESOLVED":
            status = "RESOLVIDO";
            break;
          default:
            break;
        }
        return (
          <div>
            <p style={{ textAlign: "center", color: "black" }}>{status}</p>
          </div>
        );
      } catch (error) {
        return <div></div>;
      }
    };
    const typeTemplate = (rowData, column) => {
      try {
        let tipo = "indefinido";
        switch (rowData.type) {
          case "EDIDOCCOB":
            tipo = "FATURA";
            break;
          default:
            break;
        }
        return (
          <div>
            <p style={{ textAlign: "center", color: "black" }}>{tipo}</p>
          </div>
        );
      } catch (error) {
        return <div></div>;
      }
    };
    const oidTemplate = (rowData, column) => {
      try {
        return (
          <div>
            <p style={{ textAlign: "center", color: "black" }}>{rowData.oid}</p>
          </div>
        );
      } catch (error) {
        return <div></div>;
      }
    };
    const btnProcessarTemplate = (rowData, column) => {
      try {
        return (
          <div style={{ textAlign: "center" }}>
            <ButtonDialog
              onClick={(e)=>{
                e.preventDefault();
                e.stopPropagation();
                this.onReprocessPendenceDoc(rowData);
              }}
              button={{
                title: "Reprocessar CTe",
                className: "lognex-btn-highlighted",
                iconClassName: "pi pi-refresh",
                disabled: rowData.status !== "PENDING",
              }}
              dialog={{
                header: "Reprocessar",
                modal: false,
                message: "Deseja reprocessar o documento?",
              }}
            ></ButtonDialog>
          </div>
        );
      } catch (error) {
        return <div></div>;
      }
    };

    let columns = this.props.columns.map((col) => {
      if (col.field === "oid") {
        return <Column {...col} key={col.field} body={oidTemplate} style={{ fontSize: "11px", width: "100px" }} />;
      }
      if (col.field === "eventRecord.createdAt") {
        return <Column {...col} key={col.field} body={datetimeTemplate} style={{ fontSize: "11px", width: "180px" }} />;
      }
      if (col.field === "status") {
        return <Column {...col} key={col.field} body={statusTemplate} style={{ fontSize: "11px", width: "180px" }} />;
      }
      if (col.field === "type") {
        return <Column {...col} key={col.field} body={typeTemplate} style={{ fontSize: "11px" }} />;
      }
      if (col.field === "btnProcessar") {
        return <Column {...col} key={col.field} body={btnProcessarTemplate} style={{ fontSize: "11px", width: "100px" }} />;
      }

      return <Column {...col} key={col.field} style={{ fontSize: "11px" }} />;
    });
    const carrierTemplate = (rowData, column) => {
      try {
        return (
          <div className="carrier-pendence">
            <span style={{ textAlign: "center", color: "red" }}>{rowData[column.field]}</span>
           <button className={"btn-copy-pendence"} onClick={(e)=>{
            e.preventDefault();
            e.stopPropagation();
            this.copyToClipBoard(rowData[column.field]);
           }}> <i class="pi pi-copy"></i></button>
          </div>
        );
      } catch (error) {
        return <div></div>;
      }
    };
    const templateExpandedRow = (rowData, e) => {
      const recordsCarrier = [];
      try { 
        if(rowData.document.carrier){
          rowData.document.carrier.forEach((car) => {          
            recordsCarrier.push(car);
          });
        }
      } catch (error) {
        console.log("Erro recordsCarrier=", error);
      } 

      let columnsTreeviewCarrier = this.props.columnsCarrierTemplate.map((col) => {
        return <Column {...col} key={col.field} style={{ fontSize: "11px"}} body={carrierTemplate}/>;
      });
 
      return (
        <>
          <div>
            <h3>Transportadoras com pendência de cadastro</h3>
            <PendenceRowTreeview records={recordsCarrier} columns={columnsTreeviewCarrier} />
          </div> 
        </>
      );
    };

    const content = (
      <div>
        <FiltroPendenceDoc
          {...this.props}
          initialDateChangeHandler={this.props.initialDateChangeHandler}
          finalDateChangeHandler={this.props.finalDateChangeHandler}
          onClickAplicarFiltro={this.onApplyFilter}
        />
        <PendenceDocList
          {...this.props}
          onFilter={this.onFilter}
          onPage={this.onPage}
          onSort={this.onSort}
          columns={columns} 
          rowExpandButtonOnClick={this.setExpandedRowsByRowClick}
          expandedRows={this.props.expandedRows}
          rowToggleHandler={this.setExpandedRowsClick}
          templateExpandedRow={templateExpandedRow}
        />
      </div>
    );

    return <Page header={header} content={content} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PendenceDocListPageContainer);
