import moment from 'moment';
import { build } from '../../services/crudFactory';
import { Action } from '../../services/actions.factory';
import { messageActions, makeMessages } from '../../store/message';
import api from '../../services/api.service';
const BASE_PATH = '/lognex/audit';
const prefix = 'audit';

//TODO ADD VALIDATE
const crud = build(false, BASE_PATH);

const newTypes = {
    SET_LOOKUP_CARRIER_VISIBLE: `${prefix}setLookupCarrierVisible`,
    SET_TYPE_FILTER: `${prefix}setCurrentDocTypeFilter`,
    SET_STATUS_FILTER: `${prefix}setCurrentDocStatusFilter`,
    SET_PENDINGS_TYPE_FILTER: `${prefix}setCurrentPendingsTypeFilter`,
    SET_INITIAL_DATE_FILTER: `${prefix}setInitialDateFilter`,
    SET_FINAL_DATE_FILTER: `${prefix}setFinalDateFilter`,
    SET_CARRIER_FILTER: `${prefix}setCarrierFilter`,
    SET_INVOICE_RECORDS: `${prefix}setInvoiceRecords`,
    SET_CURRENT_INVOICE_RECORD: `${prefix}setCurrentInvoiceRecord`,
    SET_FILTER_INVOICE_TABLE: `${prefix}setFilterInvoiceTable`,
    SET_CTE_RECORDS: `${prefix}setCTeRecords`,
    SET_CURRENT_CTE_RECORD: `${prefix}setCurrentCTeRecord`,
    SET_FILTER_CTE_TABLE: `${prefix}setFilterCTeTable`,
    LOAD_INVOICE_RECORDS: `${prefix}loadInvoiceRecords`,
    LOAD_CTE_RECORDS: `${prefix}loadCTeRecords`,
    SET_DIALOG_NFES_VISIBLE: `${prefix}setDialogNFesVisible`,
    SET_DIALOG_QUOTE_VISIBLE: `${prefix}setDialogQuoteVisible`,
    SET_CURRENT_NFE_RECORDS: `${prefix}setCurrentNFeRecords`,
    SET_CURRENT_QUOTE_RECORD: `${prefix}setCurrentQuoteRecord`,
    LOAD_NFES_RECORDS: `${prefix}loadNFeRecords`,
    LOAD_QUOTE_RECORD: `${prefix}loadQuoteRecord`,
    SET_DIALOG_HISTORY_VISIBLE: `${prefix}setDialogHistoryVisible`,
    LOAD_HISTORY_RECORDS: `${prefix}loadHistoryRecords`,
    APPROVE_COMPONENT: `${prefix}approveComponent`,
    SET_DIALOG_APPROVE_COMPONENT_VISIBLE: `${prefix}setDialogApproveComponentVisible`,
    SET_DIALOG_APPROVE_COMPONENT_TYPE: `${prefix}setDialogApproveComponentType`,
    SET_DIALOG_APPROVE_COMPONENT_ALERT_MESSAGE: `${prefix}setDialogApproveComponentAlertMessage`,
    SET_CURRENT_APPROVE_COMPONENT_DESCRIPTION: `${prefix}setCurrentApproveComponentDescription`,
    SET_CURRENT_APPROVE_COMPONENT_ID_CTE: `${prefix}setCurrentApproveComponentIDCTe`,
    SET_CURRENT_APPROVE_COMPONENT_LABEL_CTE: `${prefix}setCurrentApproveComponentLabelCTe`,
    SET_DIALOG_APPROVE_INVOICE_ALERT_MESSAGE: `${prefix}setDialogApproveInvoiceAlertMessage`,
    SET_DIALOG_APPROVE_INVOICE_VISIBLE: `${prefix}setDialogApproveInvoiceVisible`,
    SET_DIALOG_APPROVE_INVOICE_DESCRIPTION: `${prefix}setDialogApproveInvoiceDescription`,
    SET_CURRENT_INVOICE: `${prefix}setCurrentInvoice`,
    SET_EXPANDED_CTE_ROWS: `${prefix}setExpandedCTeRows`,
    SET_CTE_TOTALIZER: `${prefix}setCTeTotalize`,
    APPROVE_ALL_COMPONENTS: `${prefix}approveAllComponents`,
    APPROVE_INVOICE: `${prefix}approveInvoice`,
    SET_CURRENT_CTE: `${prefix}setCurrentCTe`,
    SET_DIALOG_LOGS_VISIBLE: `${prefix}setDialogLogsVisible`,
    LOAD_LOG_RECORDS: `${prefix}loadDialogRecord`,
    SET_DATE_FILTER: `${prefix}setDateFilter`,
    APPLY_INVOICE_FILTER: `${prefix}applyInvoiceFilter`,
    SET_MANUAL_RELEASE: `${prefix}setReleaseManualInvoice`,
    SET_SELECTED_CTES_APROVE_ALL: `${prefix}setSelectecCteAproveAll`,
    APROVE_ALL_SELECTED_CTE: `${prefix}approveAllSelectedCTEs`,

}

const actions = new Action(prefix, crud);

const types = Object.assign({}, actions.types, newTypes);

const validateFilters = (filters) => {
    const errors = [];

    if (!filters.initialDateFilter || filters.initialDateFilter === "") {
        errors.push('A data inicial do filtro é obrigatória!');
    } else if (typeof filters.initialDateFilter === 'string') {
        errors.push('O formato da data inicial do filtro está incorreto!');
    }

    if (!filters.finalDateFilter || filters.finalDateFilter === "") {
        errors.push('A data final do filtro é obrigatória!');
    } else if (typeof filters.finalDateFilter === 'string') {
        errors.push('O formato da data final do filtro está incorreto!');
    }

    if (!filters.currentDocStatusFilter || filters.currentDocStatusFilter.length === 0) {
        errors.push('Ao menos uma situação deve ser informada no filtro!');
    }

    if (!filters.currentPendingsTypeFilter || filters.currentPendingsTypeFilter.length === 0) {
        errors.push('Informar o tipo no filtro!');
    }

    return errors;
}

actions.setSelectecCteAproveAll = (listCtesAllAprove) => {
    return {
        type: types.SET_SELECTED_CTES_APROVE_ALL,
        listCtesAllAprove
    }
}

actions.setReleaseManualInvoice = (param) => {
    return (dispatch) => {
        dispatch(actions.showLoader());
        api.post('/api/v1/lognex/invoices/setReleaseManualInvoice', param)
            .then((response) => {
                dispatch(actions.loadInvoiceRecords());
            })
            .catch((err) => {
                console.log(err);
                dispatch(actions.hideLoader());
                dispatch(actions.messageActions.messageShowMessages(actions.makeMessages(["Não foi possivel alterar o tipo de sincronização da Fatura"], "error")))
            });
    }
}

actions.download = (filters) => {
    return (dispatch) => {
        api.post('/api/v1/lognex/cargorecord/export/cargorecord', { filters })
            .then((response) => {
                dispatch(actions.load());
                const data = response.data;
                const anchor = document.createElement("a");
                anchor.href = data.presigned_url;
                anchor.target = "_blank";
                document.body.append(anchor);
                anchor.click();
                anchor.remove();
            })
            .catch((err) => {
                console.log(err);
                dispatch(actions.hideLoader());
            });
    }
}

actions.exportReportInvoice = (id) => {
    return (dispatch) => {
        api.post('/api/v1/lognex/invoices/report/invoiceCtes', { id })
            .then((response) => {
                dispatch(actions.load());
                const data = response.data;
                const anchor = document.createElement("a");
                anchor.href = data.presigned_url;
                anchor.target = "_blank";
                document.body.append(anchor);
                anchor.click();
                anchor.remove();
            })
            .catch((err) => {
                console.log(err);
                dispatch(actions.hideLoader());
            });
    }
}

function setFieldFilter(filters, field, valor, valorComplementar = undefined, filterType = "IGUAL_A") {
    if (filters.filter(filter => filter.campo === field).length > 0) {
        filters = filters.map(filter => {
            if (filter.campo === field) {
                filter.valor = valor
                filter.valorComplementar = valorComplementar
            }
            return filter
        })
    } else {
        filters.push({
            campo: field,
            valor: valor,
            valorComplementar: valorComplementar,
            tipoFiltro: filterType
        })
    }

    return filters
}

function removeFieldFilter(filters, field) {
    if (filters && filters.length > 0) {
        for (let index = 0; index < filters.length; index++) {
            const filter = filters[index];
            if (filter.campo == field) {
                filters.splice(index, 1);
            }

        }
    }

    return filters
}
function getInvoices(dispatch, auditFilter, filterInvoiceTable) {
    const { currentDocStatusFilter, currentPendingsTypeFilter, initialDateFilter, finalDateFilter, carrierFilter } = auditFilter;
    let filters = filterInvoiceTable.filters ? [...filterInvoiceTable.filters] : [];
    const dateFilter = filters.find(filter => filter.campo === 'date');
    let date = null
    if (dateFilter) {
        date = moment(dateFilter.valor)
        filters = removeFieldFilter(filters, 'date')
    }

    if (initialDateFilter && finalDateFilter) {
        if (date) {
            const initialDate = moment.utc(date.toDate().getTime()).startOf('day').toDate()
            const finalDate = moment.utc(date.toDate().getTime()).endOf('day').toDate()
            filters = setFieldFilter(filters, 'createdAt', initialDate, finalDate, "ENTRE")
        } else {
            const initialDate = moment.utc(initialDateFilter.getTime()).startOf('day').toDate()
            const finalDate = moment.utc(finalDateFilter.getTime()).endOf('day').toDate()
            filters = setFieldFilter(filters, 'createdAt', initialDate, finalDate, "ENTRE")
        }
    } else {
        filters = removeFieldFilter(filters, 'createdAt')
    }
    if (carrierFilter && carrierFilter.oid) {
        if (carrierFilter && carrierFilter.documentNumber) {
            const cnpjs = [carrierFilter.documentNumber];
            const branches = carrierFilter.branches || [];
            branches.forEach(branch => {
                cnpjs.push(branch.documentNumber)
            })
            filters = setFieldFilter(filters, 'carrier.cnpj', cnpjs, null, 'ESTA_CONTIDO_EM')
        }
    } else {
        filters = removeFieldFilter(filters, 'carrier.cnpj')
    }

    if (currentDocStatusFilter) {
        let copyCurrentDocStatusFilter = [...currentDocStatusFilter]
        filters = setFieldFilter(filters, "status", copyCurrentDocStatusFilter, false, "ESTA_CONTIDO_EM")
        filters.map(filter => {
            if (filter.campo === "status") {
                if (filter.valor.filter(docStatus => docStatus === 'CLOSED').length > 0) {
                    filter.valor.push('CLOSED_MANUALLY')
                }

            }
        })
    } else {
        filters = removeFieldFilter(filters, 'status')
    }

    filters = removeFieldFilter(filters, 'pendings.status');
    if (currentPendingsTypeFilter) {
        if (currentPendingsTypeFilter == "OPEN") {
            filters = setFieldFilter(filters, "pendings.status", currentPendingsTypeFilter);
        } else if (currentPendingsTypeFilter != "ALL") {
            filters = setFieldFilter(filters, "pendings.status", "OPEN", undefined, "DIFERENTE");
        }
    }

    dispatch({
        type: types.SET_CTE_TOTALIZER,
        cteTotalizer: { totalQuoted: 0.0, totalRealized: 0.0 }
    })
    const params = {
        filters,
        index: filterInvoiceTable.index,
        max: filterInvoiceTable.max
    }
    dispatch(actions.showLoader());
    api.post('/api/v1/lognex/invoices/all', params)
        .then(({ data }) => {
            if (actions && actions.messageActions && (!data || data.length === 0)) {
                dispatch(actions.messageActions.messageShowMessages(actions.makeMessages([mountMessageEmptyRecords(currentDocStatusFilter)], "error")))
            }
            dispatch({
                type: types.LOAD_INVOICE_RECORDS,
                data,
            }
            )
            dispatch(actions.hideLoader())
        })
        .catch(() => {
            if (actions && actions.messageActions) {
                dispatch(actions.messageActions.messageShowMessages(actions.makeMessages([mountMessageEmptyRecords(currentDocStatusFilter)], "error")))
            }
            dispatch({
                type: types.LOAD_INVOICE_RECORDS,
                data: { records: [], size: 20, count: 0, index: 0 },
            })
            dispatch(actions.hideLoader())
        });
}

function mountMessageEmptyRecords(currentDocStatusFilter) {
    const docStatusFilters = currentDocStatusFilter.map(currentDocStatus => {
        if (currentDocStatus === "WITHPENDINGS") {
            return "divergentes"
        }
        if (currentDocStatus === "CLOSED") {
            return "resolvidos"
        }
        return ""
    }).join("/")
    return `Não foram encontrados registros ${docStatusFilters}`
}
function getCTes(dispatch, auditFilter, filterCTeTable, currentInvoiceRecord) {
    const { currentDocStatusFilter, currentDocTypeFilter, currentPendingsTypeFilter, initialDateFilter, finalDateFilter, carrierFilter } = auditFilter;


    let filters = filterCTeTable.filters ? [...filterCTeTable.filters] : [];

    if (initialDateFilter && finalDateFilter) {
        const initialDate = moment.utc(initialDateFilter.getTime()).startOf('day').toDate()
        const finalDate = moment.utc(finalDateFilter.getTime()).endOf('day').toDate()
        filters = setFieldFilter(filters, 'createdAt', initialDate, finalDate, "ENTRE")
    } else {
        filters = removeFieldFilter(filters, 'createdAt')
    }
    if (carrierFilter && carrierFilter.oid) {
        if (carrierFilter.documentNumber) {
            const cnpjs = [carrierFilter.documentNumber];
            const branches = carrierFilter.branches || [];
            branches.forEach(branch => {
                cnpjs.push(branch.documentNumber)
            })
            filters = setFieldFilter(filters, 'carrier.cnpj', cnpjs, null, 'ESTA_CONTIDO_EM')
        }
    } else {
        filters = removeFieldFilter(filters, 'carrier.cnpj')
    }

    if (currentDocStatusFilter) {
        let copyCurrentDocStatusFilter = [...currentDocStatusFilter]
        filters = setFieldFilter(filters, "status", copyCurrentDocStatusFilter, false, "ESTA_CONTIDO_EM")
        filters.map(filter => {
            if (filter.campo === "status") {
                if (filter.valor.filter(docStatus => docStatus === 'CLOSED').length > 0) {
                    filter.valor.push('CLOSED_MANUALLY')
                }

            }
        })
    } else {
        filters = removeFieldFilter(filters, 'status')
    }


    filters = removeFieldFilter(filters, 'pendings.status');
    if (currentPendingsTypeFilter) {
        if (currentPendingsTypeFilter == "OPEN") {
            filters = setFieldFilter(filters, "pendings.status", currentPendingsTypeFilter);
        } else if (currentPendingsTypeFilter != "ALL") {
            filters = setFieldFilter(filters, "pendings.status", "OPEN", undefined, "DIFERENTE");
        }
    }


    if (currentInvoiceRecord && currentInvoiceRecord.oid) {
        filters = setFieldFilter(filters, 'invoice.oid', currentInvoiceRecord.oid)
    } else {
        filters = removeFieldFilter(filters, 'invoice.oid');
        filters = removeFieldFilter(filters, 'documents.content.number')
    }
    if (currentDocTypeFilter == "INVOICE" && currentInvoiceRecord && currentInvoiceRecord.number) {
        filters = setFieldFilter(filters, 'documents.content.number', currentInvoiceRecord.number)
    } else {
        filters = removeFieldFilter(filters, 'documents.content.number')
    }
    const params = {
        index: filterCTeTable.index,
        max: filterCTeTable.max,
        filters,
        sorts: []
    };


    dispatch(actions.setSelectecCteAproveAll([]));

    dispatch(actions.showLoader());
    return api.post('/api/v1/lognex/cargorecord/ctes', params)
        .then(({ data }) => {
            if (actions && actions.messageActions && (!data || !data.records || data.records.length === 0)) {
                dispatch(actions.messageActions.messageShowMessages(actions.makeMessages([mountMessageEmptyRecords(currentDocStatusFilter)], "error")))
            }
            dispatch({
                type: types.LOAD_CTE_RECORDS,
                cteRecords: data ? data.records : [],
            })

            const totalize = data ? { ...data.totalizer, totalRecords: data.count } : { totalQuoted: 0.0, totalRealized: 0.0 }
            dispatch({
                type: types.SET_CTE_TOTALIZER,
                cteTotalizer: totalize,
            })
            const filterCTeTable = {
                filters,
                sortField: "",
                sortOrder: 0,
                index: data ? data.index : 0,
                max: data ? data.size : 20,
                count: data ? data.count : 0,
            }
            dispatch(actions.setFilterCTeTable(filterCTeTable));
            dispatch(actions.hideLoader())
        })
        .catch(err => {
            dispatch({
                type: types.LOAD_CTE_RECORDS,
                cteRecords: [],
            })
            dispatch({
                type: types.SET_CTE_TOTALIZER,
                cteTotalizer: { totalQuoted: 0.0, totalRealized: 0.0 }
            })
            if (actions && actions.messageActions) {
                dispatch(actions.messageActions.messageShowMessages(actions.makeMessages([mountMessageEmptyRecords(currentDocStatusFilter)], "error")))
            }
            dispatch(actions.hideLoader())
        });
}


actions.clearInvoiceRecords = () => {
    return {
        type: types.LOAD_INVOICE_RECORDS,
        data: { records: [], count: 0, size: 20, index: 0 }
    }
}

actions.loadInvoiceRecords = () => {
    return (dispatch, getState) => {
        const auditState = getState().auditState;
        const errors = validateFilters(auditState.auditFilter)
        if (errors.length > 0) {
            dispatch(
                messageActions.messageShowMessages(
                    makeMessages(errors, 'error')
                )
            );
        } else {
            getInvoices(dispatch, auditState.auditFilter, auditState.filterInvoiceTable);
        }
    };
}

actions.loadCTeRecords = () => {
    return (dispatch, getState) => {
        const auditState = getState().auditState;
        const errors = validateFilters(auditState.auditFilter)
        if (errors.length > 0) {
            dispatch(
                messageActions.messageShowMessages(
                    makeMessages(errors, 'error')
                )
            );
        } else {
            getCTes(dispatch, auditState.auditFilter, auditState.filterCTeTable, auditState.currentInvoiceRecord);
        }
    };
}

actions.loadNFeRecords = (idCTe) => {
    return (dispatch, getState) => {
        const auditState = getState().auditState;
        dispatch(actions.fetchRecord());
        getNFes(dispatch, idCTe);
        dispatch(actions.hideLoader());
    };
}

actions.getQuote = (dispatch, idCTe) => {
    api.post('/api/v1/lognex/ctes/quote', { "cteId": idCTe })
        .then(({ data }) => {
            dispatch({
                type: types.LOAD_QUOTE_RECORD,
                quoteRecords: data
            }
            )
        });
}


actions.loadQuoteRecord = () => {
    return (dispatch, getState) => {
        const auditState = getState().auditState;
        const idCTe = auditState.currentCTe ? auditState.currentCTe.oid : null
        dispatch(actions.fetchRecord());
        actions.getQuote(dispatch, idCTe);
        dispatch(actions.hideLoader());
    };
}

actions.loadHistoryRecords = (idCTe) => {
    return (dispatch, getState) => {
        const auditState = getState().auditState;
        dispatch(actions.fetchRecord());
        actions.getHistory(dispatch, idCTe)
        dispatch(actions.hideLoader());

    }
}

actions.getHistory = (dispatch, idCTe) => {
    api.post('/api/v1/lognex/cargorecord/history', { "cteId": idCTe })
        .then(({ data }) => {
            dispatch({
                type: types.LOAD_HISTORY_RECORDS,
                historyRecords: data
            }
            )
        });
}

actions.setCurrentDocTypeFilter = (typeFilter) => {
    return {
        type: types.SET_TYPE_FILTER,
        typeFilter
    }
}

actions.setCurrentDocStatusFilter = (statusFilter) => {
    return {
        type: types.SET_STATUS_FILTER,
        statusFilter
    }
}

actions.setCurrentPendingsTypeFilter = (pendingsType) => {
    return {
        type: types.SET_PENDINGS_TYPE_FILTER,
        pendingsType
    }
}

actions.setInitialDateFilter = (initialDateFilter) => {
    return {
        type: types.SET_INITIAL_DATE_FILTER,
        initialDateFilter
    }
}

actions.setFinalDateFilter = (finalDateFilter) => {
    return {
        type: types.SET_FINAL_DATE_FILTER,
        finalDateFilter
    }
}

actions.setCarrierFilter = (carrierFilter) => {
    return {
        type: types.SET_CARRIER_FILTER,
        carrierFilter
    }
}

actions.setLookupCarrierVisible = (visible) => {
    return {
        type: types.SET_LOOKUP_CARRIER_VISIBLE,
        visible
    }
}

actions.setInvoiceRecords = (invoiceRecords) => {
    return {
        type: types.SET_INVOICE_RECORDS,
        invoiceRecords
    }
}

actions.setCurrentInvoiceRecord = (currentInvoiceRecord) => {
    return {
        type: types.SET_CURRENT_INVOICE_RECORD,
        currentInvoiceRecord
    }
}

actions.setFilterInvoiceTable = (filterInvoiceTable) => {
    return {
        type: types.SET_FILTER_INVOICE_TABLE,
        filterInvoiceTable
    }
}

actions.setCTeRecords = (cteRecords) => {
    return {
        type: types.SET_CTE_RECORDS,
        cteRecords
    }
}

actions.setCurrentCTeRecord = (currentCTeRecord) => {
    return {
        type: types.SET_CURRENT_CTE_RECORD,
        currentCTeRecord
    }
}

actions.setFilterCTeTable = (filterCTeTable) => {
    return {
        type: types.SET_FILTER_CTE_TABLE,
        filterCTeTable
    }
}

actions.setDialogNFesVisible = (visible) => {
    return {
        type: types.SET_DIALOG_NFES_VISIBLE,
        visible
    }
}

actions.setCurrentNFeRecords = (currentNFeRecords) => {
    return {
        type: types.SET_CURRENT_NFE_RECORDS,
        currentNFeRecords
    }
}


actions.setDialogQuoteVisible = (visible) => {
    return {
        type: types.SET_DIALOG_QUOTE_VISIBLE,
        visible
    }
}

actions.setDialogHistoryVisible = (visible) => {
    return {
        type: types.SET_DIALOG_HISTORY_VISIBLE,
        visible
    }
}

actions.setCurrentQuoteRecord = (currentQuoteRecord) => {
    return {
        type: types.SET_CURRENT_QUOTE_RECORD,
        currentQuoteRecord
    }
}


function getNFes(dispatch, idCTe) {
    dispatch(actions.fetchRecords())
    api.post('/api/v1/lognex/cargorecord/nfesbycte', { "cteId": idCTe })
        .then(({ data }) => {
            dispatch({
                type: types.LOAD_NFES_RECORDS,
                nfeRecords: data,
                async: false
            }
            )
        })
        .catch(err => {
            dispatch({
                type: types.LOAD_NFES_RECORDS,
                nfeRecords: [],
                async: false
            }
            )
            if (actions && actions.messageActions) {
                actions.messageActions.messageShowMessages(actions.makeMessages(["Não foi possivel carregar as NFEs"], "error"))
            }
        });
}

function reopenCTeDetail(dispatch, cteRecords, currentCTe) {
    for (let index = 0; index < cteRecords.length; index++) {
        if (cteRecords[index].oid === currentCTe.oid) {
            dispatch(actions.setExpandedCTeRows([cteRecords[index]]));
            break;
        }
    }
}

function reopenCTe(dispatch, cteRecords, oldExpandedCTes, currentCTe) {
    for (let index = 0; index < cteRecords.length; index++) {
        if (cteRecords[index].oid === currentCTe.oid) {
            for (let index = 0; index < oldExpandedCTes.length; index++) {
                const oldCExpandedCTe = oldExpandedCTes[index];
                if (oldCExpandedCTe.oid === currentCTe.oid) {
                    dispatch(actions.setExpandedCTeRows([cteRecords[index]]));
                }
                break;
            }
            break;
        }
    }
}

function postApproveComponent(currentCTe, currentApproveComponent) {
    const body = {
        cteID: currentCTe ? currentCTe.oid : null,
        description: currentApproveComponent ? currentApproveComponent.description : null,
        componentLabelCTe: currentApproveComponent ? currentApproveComponent.labelCTe : null,
    };

    return api.post('/api/v1/lognex/cargorecord/approveauditcomponent', body)
}

function postApproveAllComponents(currentCTe, currentApproveComponent) {
    const body = {
        cteID: currentCTe ? currentCTe.oid : null,
        description: currentApproveComponent ? currentApproveComponent.description : null,
    };

    return api.post('/api/v1/lognex/cargorecord/approveallauditcomponents', body)
}

function postReprocessCTe(currentCTe) {
    const body = {
        cteID: currentCTe ? currentCTe.oid : null,
    };
    return api.post('/api/v1/lognex/cargorecord/reprocess', body)
}
function postRemoveCTe(currentCTe) {
    const body = {
        cteID: currentCTe ? currentCTe.oid : null,
    };
    return api.post('/api/v1/lognex/cargorecord/removecte', body)
}


actions.approveComponent = () => {
    return (dispatch, getState) => {
        const auditState = getState().auditState;
        dispatch(actions.fetchRecord());

        postApproveComponent(auditState ? auditState.currentCTe : {}, auditState ? auditState.currentApproveComponent : {}).then((data) => {
            getCTes(dispatch, auditState.auditFilter, auditState.filterCTeTable, auditState.currentInvoiceRecord, auditState.currentApproveComponent)
                .then(() => {
                    reopenCTeDetail(dispatch, getState().auditState.cteRecords, auditState.currentCTe);
                    dispatch(actions.hideLoader());
                })
                .catch(() => {
                    dispatch(actions.hideLoader());
                })
        })
            .catch(() => {
                dispatch(actions.hideLoader());
            })
    };
}



actions.approveAllComponents = () => {
    return (dispatch, getState) => {
        const auditState = getState().auditState;
        const oldExpandedCTes = [...auditState.expandedCTeRows]
        dispatch(actions.fetchRecord());

        postApproveAllComponents(auditState ? auditState.currentCTe : {}, auditState ? auditState.currentApproveComponent : {}).then((data) => {
            getCTes(dispatch, auditState.auditFilter, auditState.filterCTeTable, auditState.currentInvoiceRecord, auditState.currentApproveComponent)
                .then(() => {
                    reopenCTe(dispatch, getState().auditState.cteRecords, oldExpandedCTes, auditState.currentCTe);
                    dispatch(actions.hideLoader());
                })
                .catch(() => {
                    dispatch(actions.hideLoader());
                })
        })
            .catch(() => {
                dispatch(actions.hideLoader());
            })
    };
}

actions.reprocessCTe = () => {
    return (dispatch, getState) => {
        const auditState = getState().auditState;
        const oldExpandedCTes = [...auditState.expandedCTeRows]
        dispatch(actions.fetchRecord());

        postReprocessCTe(auditState ? auditState.currentCTe : {}).then((data) => {
            getCTes(dispatch, auditState.auditFilter, auditState.filterCTeTable, auditState.currentInvoiceRecord, auditState.currentApproveComponent)
                .then(() => {
                    reopenCTe(dispatch, getState().auditState.cteRecords, oldExpandedCTes, auditState.currentCTe);
                    dispatch(actions.hideLoader());
                })
                .catch(() => {
                    dispatch(actions.hideLoader());
                })
        })
            .catch(() => {
                dispatch(actions.hideLoader());
            })

    };
}
actions.removeCTe = () => {
    return (dispatch, getState) => {
        const auditState = getState().auditState;
        const oldExpandedCTes = [...auditState.expandedCTeRows]
        dispatch(actions.fetchRecord());

        postRemoveCTe(auditState ? auditState.currentCTe : {}).then((data) => {
            getCTes(dispatch, auditState.auditFilter, auditState.filterCTeTable, auditState.currentInvoiceRecord, auditState.currentApproveComponent)
                .then(() => {
                    reopenCTe(dispatch, getState().auditState.cteRecords, oldExpandedCTes, auditState.currentCTe);
                    dispatch(actions.hideLoader());
                })
                .catch(() => {
                    dispatch(actions.hideLoader());
                })
        })
            .catch(() => {
                dispatch(actions.hideLoader());
            })

    };
}


actions.reprocessAllCTEs = () => {

    return (dispatch, getState) => {
        const auditState = getState().auditState;

        const listCtesAllAprove = auditState.listCtesAllAprove;

        const cteIDs = listCtesAllAprove.map(item => item.oid)
        if (cteIDs.length == 0)
            return

        dispatch(actions.showLoader());
        api.post("/api/v1/lognex/cargorecord/reprocessall", { cteIDs })
            .then(({ data }) => {
                dispatch(actions.hideLoader());
                dispatch(actions.setSelectecCteAproveAll([]));
                dispatch(actions.loadCTeRecords());
                dispatch(
                    messageActions.messageShowMessages(
                        makeMessages(["CTEs Reprocessados com sucesso."], "success")
                    )
                );
            })
            .catch((err) => {
                console.log(err);
                dispatch(actions.hideLoader());
                dispatch(
                    messageActions.messageShowMessages(
                        makeMessages(["Ops! Falha ao reprocessar todos os CTEs"], "error")
                    )
                );
            })
    }
}


actions.setDialogApproveComponentVisible = (visible) => {
    return {
        type: types.SET_DIALOG_APPROVE_COMPONENT_VISIBLE,
        visible
    }
}


actions.setCurrentApproveComponentIDCTe = (idCTe) => {
    return {
        type: types.SET_CURRENT_APPROVE_COMPONENT_ID_CTE,
        idCTe
    }
}

actions.setCurrentApproveComponentLabelCTe = (labelCTe) => {
    return {
        type: types.SET_CURRENT_APPROVE_COMPONENT_LABEL_CTE,
        labelCTe
    }
}

actions.setCurrentApproveComponentDescription = (description) => {
    return {
        type: types.SET_CURRENT_APPROVE_COMPONENT_DESCRIPTION,
        description
    }
}

actions.setDialogApproveComponentType = (approvalType) => {
    return {
        type: types.SET_DIALOG_APPROVE_COMPONENT_TYPE,
        approvalType
    }
}

actions.setDialogApproveComponentAlertMessage = (alertMessage) => {
    return {
        type: types.SET_DIALOG_APPROVE_COMPONENT_ALERT_MESSAGE,
        alertMessage
    }
}

actions.clearCTeRecords = () => {
    return {
        type: types.LOAD_CTE_RECORDS,
        cteRecords: [],
    }
}

actions.setExpandedCTeRows = (expandedCTeRows) => {
    return {
        type: types.SET_EXPANDED_CTE_ROWS,
        expandedCTeRows
    }
}

actions.setAuditComponentApproved = () => {
    return {
        type: types.SET_AUDIT_COMPONENT_APPROVED
    }
}

actions.setCTeTotalizer = (cteTotalizer) => {
    return {
        type: types.SET_CTE_TOTALIZER,
        cteTotalizer
    }
}

actions.setCurrentCTe = (cte) => {
    return {
        type: types.SET_CURRENT_CTE,
        cte
    }
}

actions.setCurrentInvoice = (invoice) => {
    return {
        type: types.SET_CURRENT_INVOICE,
        invoice
    }
}

actions.setDialogLogsVisible = (visible) => {
    return {
        type: types.SET_DIALOG_LOGS_VISIBLE,
        visible
    }
}

function getLogs(dispatch, idCTe) {
    dispatch(actions.fetchRecords())
    api.post('/api/v1/lognex/cargorecord/logsbycte', { "cteId": idCTe })
        .then(({ data }) => {
            dispatch({
                type: types.LOAD_LOG_RECORDS,
                logRecords: data,
                async: false
            }
            )
        })
        .catch(err => {
            dispatch({
                type: types.LOAD_LOG_RECORDS,
                nfeRecords: [],
                async: false
            }
            )
            if (actions && actions.messageActions) {
                actions.messageActions.messageShowMessages(actions.makeMessages(["Não foi possivel carregar os logs"], "error"))
            }
        });
}

actions.loadLogRecords = (idCTe) => {
    return (dispatch) => {
        dispatch(actions.fetchRecord());
        getLogs(dispatch, idCTe);
        dispatch(actions.hideLoader());
    };
}

actions.setDateFilter = (field, value) => {
    return {
        type: types.SET_DATE_FILTER,
        field,
        value
    }
}

actions.applyInvoiceFilter = (filters) => {
    return (dispatch) => {
        dispatch({
            type: types.APPLY_INVOICE_FILTER,
            filters
        })
        dispatch(actions.loadInvoiceRecords())
    }
}


actions.approveInvoice = () => {

    return (dispatch, getState) => {
        const auditState = getState().auditState;
        const invoiceID = auditState.currentInvoice && auditState.currentInvoice.oid ? auditState.currentInvoice.oid : -1;
        const approveInvoiceDescription = auditState.dialogApproveInvoice.description;

        return api.post(`/api/v1/lognex/cargorecord/approveinvoice/${invoiceID}`, { message: approveInvoiceDescription })
            .then((data) => {
                dispatch(actions.loadInvoiceRecords());
            })
            .catch((err) => {
                console.log(err);
                dispatch(actions.hideLoader());
            })
    };
}

actions.setDialogApproveInvoiceVisible = (visible) => {
    return {
        type: types.SET_DIALOG_APPROVE_INVOICE_VISIBLE,
        visible
    }
}


actions.setDialogApproveInvoiceAlertMessage = (alertMessage) => {
    return {
        type: types.SET_DIALOG_APPROVE_INVOICE_ALERT_MESSAGE,
        alertMessage
    }
}

actions.setDialogApproveInvoiceDescription = (description) => {
    return {
        type: types.SET_DIALOG_APPROVE_INVOICE_DESCRIPTION,
        description
    }
}

actions.approveAllSelectedCTEs = () => {

    return (dispatch, getState) => {
        const auditState = getState().auditState;
        const listCtesAllAprove = auditState.listCtesAllAprove;
        const observation = "Aprovação manual em massa";

        const cteIDs = listCtesAllAprove.map(item => item.oid)
        const body = {
            observation,
            cteIDs
        }
        dispatch(actions.showLoader());
        api.post("/api/v1/lognex/audit-records/approved/ctes", body)
            .then(({ data }) => {
                dispatch(actions.hideLoader());
                dispatch(actions.loadCTeRecords());
                dispatch(
                    messageActions.messageShowMessages(
                        makeMessages(["CTEs Aprovados com sucesso."], "success")
                    )
                );

            })
            .catch((err) => {
                console.log(err);
                dispatch(actions.hideLoader());
                dispatch(
                    messageActions.messageShowMessages(
                        makeMessages(["Ops! Falha ao aprovar todos os CTEs"], "error")
                    )
                );
            })
    }
}

export { types, actions }