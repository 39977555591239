import React from "react";

import { Dialog } from "primereact/dialog";
import FieldsCalculateCubage from "./FieldsCalculateCubage";
import "./ModalCalculateCubage.scss";
import { SelectButton } from "primereact/selectbutton";

export default function ModalCalculateCubage(props) {
  const footer = (
    <div className="p-col-12 modal-calculate-cubage-btn-form-footer">
      <div className="btn-footer">
        <button
          className="lognex-btn-highlighted"
          style={{ width: "100%" }}
          onClick={props.calculateCubageChangeHandler}
          title={"Adicionar Cubagem"}
        >
          Adicionar
        </button>
      </div>
      <div className="btn-footer">
        <button
          className="lognex-btn-danger"
          style={{ width: "100%" }}
          onClick={props.onHide}
          title={"Cancelar"}
        >
          Cancelar
        </button>
      </div>
    </div>
  );

  const listComp = props.listComponents.map((item, index) => {
    return (
      <FieldsCalculateCubage
        {...item}
        key={index}
        onChangeHaldler={props.onChangeHandler}
        onAddComponentChageHandler={props.addComponentChangeHandler}
        removeComponentChangeHandler={props.removeComponentChangeHandler}
      ></FieldsCalculateCubage>
    );
  });

  const content = (
    <div>
      <div className="p-col-4 form-field-container">
        <label>Calcular por</label>
        <SelectButton
          value={props.currentTypeCalculateFilter}
          options={props.typeCalculateOptions}
          onChange={props.typeCalculateChangeHandler}
        ></SelectButton>
      </div>
      <div className="p-col-12"></div>
      
      {listComp}

    </div>
  );

  return (
    <Dialog
      header="Cálculo de cubagem"
      visible={props.visible}
      style={{ width: "45%" }}
      modal={true}
      baseZIndex={1}
      onHide={props.onHide}
      footer={footer}
    >
      {content}
    </Dialog>
  );
}
