
import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';

export const CTeTable = (props) => 
    (
        <div className="cteTable-form">


            <DataTable
                selectionMode="multiple"
                onFilter={props.cteTbFilterHandler}
                onSort={props.cteTbSortHandler}
                onPage={props.cteTbPageHandler}
                value={props.cteTbRecords}
                lazy={true}
                totalRecords={props.cteTbTotalRecords}
                sortOrder={props.cteTbOrder}
                sortField={props.cteTbSortField}
                paginator={true}
                first={props.cteTbFirst}
                rows={props.cteTbRows}
                emptyMessage={props.cteTbEmptyMessage}
                rowsPerPageOptions={[5, 10, 20,50]}
                onRowToggle={props.cteTbRowExpandButtonOnClick}
                onRowClick={props.cteTbRowToggleHandler}
                rowExpansionTemplate={props.cteTbRowExpasionTemplateHandler}
                expandedRows={props.cteTBExpandedRows}
                onSelectionChange={props.onSelectedEventsChange}
                selection={props.selectedRows}
            /*dataKey={props.cteTbDataKey}*/
            >
                {[<Column key="cteTableAprove" selectionMode="multiple" style={{ width: "3em" }}/>,<Column expander={true} key={"cteTable"} style={{ width: '4em' }} />, ...props.cteTbColumns]}
                
            </DataTable>
            <Dialog
                header={props.dialogApproveComponent.header}
                visible={props.dialogApproveComponent.visible}
                style={{ width: '50vw' }}
                modal={props.dialogApproveComponent.modal}
                baseZIndex={1}
                onHide={props.dialogApproveComponent.actions.onCancel}
                footer={<div>
                    <button
                        title="Aprovar"
                        onClick={props.dialogApproveComponent.actions.onApprove}
                        style={{ marginRight: ".25em" }}
                        className="lognex-btn-highlighted"
                        disabled={!props.dialogApproveComponent.description}>
                        <i className="pi pi-check"></i>
                    </button>

                    <button
                        title="Cancelar"
                        onClick={props.dialogApproveComponent.actions.onCancel}
                        style={{ marginRight: ".25em", marginBottom: ".25em" }}
                        className="lognex-btn-danger">
                        <i className="pi pi-times"></i>
                    </button>
                </div>}>
                <h4 className="lxAlertMessage">{props.dialogApproveComponent.alertMessage}</h4>
                <InputTextarea
                    className='lxInputTextArea'
                    rows={5}
                    cols={30}
                    value={props.dialogApproveComponent.description}
                    onChange={props.dialogApproveComponent.actions.onChangeDescription} />

            </Dialog>
        </div>
    )
