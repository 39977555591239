
    import { validate } from './validations';
    import { build } from '../../services/crudFactory';
    import { Action } from '../../services/actions.factory';
    const BASE_PATH = '/lognex/locations';
    const prefix = 'crudLocation';
    const crud = build(validate, BASE_PATH);

    const newTypes = {
        SET_LOCATION_FILTER_TYPE: `${prefix}SetLocationFilterType`,
    }

    const actions = new Action(prefix, crud);

    const types = Object.assign({}, actions.types, newTypes);
    
    actions.setLookupLocationVisible = (visible, type) => {
        return {
            type: type,
            visible
        }
    }

    actions.setLocationFilterType = (locationFilterType) => {
        return {
            type: types.SET_LOCATION_FILTER_TYPE,
            locationFilterType
        }
    }

    export { types, actions }