
    import React from 'react';
    import VehicleFormPageContainer from './VehicleFormPageContainer';
    
    export class VehicleFormPage extends React.Component{
        render(){
            return (
                <VehicleFormPageContainer {...this.props}/>
            )
        }
    }
    