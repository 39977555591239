import React from 'react';
import { connect } from "react-redux";
import loader from '../../assets/images/loader.svg';
import './Loader.scss';

const mapStateToProps = state =>{
    return {
        show: state.loaderState.show
    }
}

const ConnectedLoader = ({show})=>{
     if(show){
        return (
            <div className="loader">
                <img src={loader} alt="loader"></img>
            </div>
            )
     }else {
         return null;
     }
}

export const Loader = connect(mapStateToProps)(ConnectedLoader);