import React from "react";
import { connect } from "react-redux";
import Toolbar from "../../../components/toolbar/Toolbar";
import { routes } from "../../../constants";
import { Page } from "../../../components/page/Page";
import { TowerTravelForm } from "./TowerTravelForm";
import { actions } from "../tower_travel.actions";
import { actions as actionEquipment } from "../../towerEquipment/tower_equipment.actions";
import { actions as carrierActions } from "../../carrier/carrier.actions";
import { actions as locationActions } from "../../location/location.actions";
import { actions as unidadeActions } from "../../unidade/unidade.actions";
import { actions as vehicleActions } from "../../vehicle/vehicle.actions";
import { actions as driverActions } from "../../driver/driver.actions";

import { Column } from "primereact/column";
import { makeMessages, messageActions } from "../../../store/message";

const makeCol = (col) => {
  if (col.field === "flagActive") {
    return <Column {...col} key={col.field} body={booleanTemplate} />;
  }
  return <Column key={col.field} {...col} />;
};

const booleanTemplate = (rowData, column) => {
  let value = rowData[column.columnKey];
  if (typeof rowData[column.columnKey] === "string") {
    value = rowData[column.columnKey].toUpperCase() === "TRUE";
  }

  value = value ? "SIM" : "NÃO";
  return <div style={{ textAlign: "center" }}>{value}</div>;
};

const mapStateToProps = (state) => {
  const record = state.crudTowerTravelState.currentRecord;

  const lookupEquipmentBase = {
    records: state.crudTowerEquipmentState.records,
    columns: state.crudTowerEquipmentState.columns.filter((col) => col.field === "name" || col.field === "equipmentType.name").map((col) => makeCol(col)),
    first: state.crudTowerEquipmentState.index,
    rows: state.crudTowerEquipmentState.max,
    totalRecords: state.crudTowerEquipmentState.count,
    sortField: state.crudTowerEquipmentState.sortField,
    order: state.crudTowerEquipmentState.sortOrder,
  };

  let lookupEquipment = Object.assign({}, state.crudTowerTravelState.lookupEquipment, lookupEquipmentBase);

  const lookupOriginBase = {
    records: state.crudLocationState.records,
    columns: state.crudLocationState.columns.filter((col) => col.field === "oid" || col.field === "description").map((col) => <Column key={col.field} {...col} />),
    first: state.crudLocationState.index,
    rows: state.crudLocationState.max,
    totalRecords: state.crudLocationState.count,
    sortField: state.crudLocationState.sortField,
    order: state.crudLocationState.sortOrder,
    editDisabled: !record.oidLocation,
  };
  let lookupOrigin = Object.assign({}, state.crudTowerTravelState.lookupOrigin, lookupOriginBase);

  const lookupCarrierBase = {
    records: state.crudCarrierState.records,
    columns: state.crudCarrierState.columns.filter((col) => col.field === "oid" || col.field === "name").map((col) => <Column key={col.field} {...col} />),
    first: state.crudCarrierState.index,
    rows: state.crudCarrierState.max,
    totalRecords: state.crudCarrierState.count,
    sortField: state.crudCarrierState.sortField,
    order: state.crudCarrierState.sortOrder,
    editDisabled: !record.oidCarrier,
    rowsPerPageOptions:[20,50,100],
  };
  let lookupCarrier = Object.assign({}, state.crudTowerTravelState.lookupCarrier, lookupCarrierBase);

  const lookupDestinationBase = {
    records: state.crudLocationState.records,
    columns: state.crudLocationState.columns.filter((col) => col.field === "oid" || col.field === "description").map((col) => <Column key={col.field} {...col} />),
    first: state.crudLocationState.index,
    rows: state.crudLocationState.max,
    totalRecords: state.crudLocationState.count,
    sortField: state.crudLocationState.sortField,
    order: state.crudLocationState.sortOrder,
    editDisabled: !record.oidLocation,
  };
  let lookupDestination = Object.assign({}, state.crudTowerTravelState.lookupDestination, lookupDestinationBase);

  const lookupVehicleBase = {
    records: state.crudVehicleState.records,
    columns: state.crudVehicleState.columns
      .map((col) => {
        col.sortable = false;
        col.filter = false;
        return col;
      })
      .filter((col) => col.field !== "oid")
      .map((col) => <Column key={col.field} {...col} />),
    first: state.crudVehicleState.index,
    rows: state.crudVehicleState.max,
    totalRecords: state.crudVehicleState.count,
    sortField: state.crudVehicleState.sortField,
    order: state.crudVehicleState.sortOrder,
    editDisabled: !record.oidUnidade,
  };
  let lookupVehicle = Object.assign({}, state.crudTowerTravelState.lookupVehicle, lookupVehicleBase);

  const lookupDriverBase = {
    records: state.crudDriverState.records,
    columns: state.crudDriverState.columns
      .filter((col) => col.field === "oid" || col.field === "name")
      .map((col) => {
        col = { ...col, sortable: false };
        return <Column key={col.field} {...col} />;
      }),
    first: state.crudDriverState.index,
    rows: state.crudDriverState.max,
    totalRecords: state.crudDriverState.count,
    sortField: state.crudDriverState.sortField,
    order: state.crudDriverState.sortOrder,
    editDisabled: !record.driver,
  };
  let lookupDriver = Object.assign({}, state.crudTowerTravelState.lookupDriver, lookupDriverBase);

  return {
    record,
    lookupEquipment,
    lookupOrigin,
    lookupCarrier,
    lookupDestination,
    showModalOrigin: state.crudTowerTravelState.showModalOrigin,
    inputLatitudeOrigin: state.crudTowerTravelState.inputLatitudeOrigin,
    inputLongitudeOrigin: state.crudTowerTravelState.inputLongitudeOrigin,
    originTemp: state.crudTowerTravelState.originTemp,
    selectedRecordsOrigin: state.crudTowerTravelState.selectedRecordsOrigin,
    selectedRecordsDest: state.crudTowerTravelState.selectedRecordsDest,
    showModalDestination: state.crudTowerTravelState.showModalDestination,
    destinationTemp: state.crudTowerTravelState.destinationTemp,

    inputLatitudeDestination: state.crudTowerTravelState.inputLatitudeDestination,
    inputLongitudeDestination: state.crudTowerTravelState.inputLongitudeDestination,

    lookupVehicle,
    lookupDriver,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeHandler: (event) => {
      const target = event.target;
      const field = target.name;
      let value = target.value;

      dispatch(actions.removeErrors([{ field }]));
      dispatch(actions.setField(field, value));
    },

    setField: (field, value) => dispatch(actions.setField(field, value)),
    saveHandler: (event) => {
      dispatch(actions.save());
    },
    removeHandler: (event) => {
      dispatch(actions.remove());
    },
    newHandler: (event) => {
      dispatch(actions.makeNew());
    },

    loadById: (oid) => dispatch(actions.loadById(oid)),
    //
    //equipment
    lookupEquipmentSetVisible: (visible) => dispatch(actions.setLookupEquipmentVisible(visible)),
    lookupEquipmentPaginate: (paginationParams) => dispatch(actionEquipment.paginate(paginationParams)),
    lookupEquipmentSortOrder: (sortParams) => dispatch(actionEquipment.sortOrder(sortParams)),
    lookupEquipmentApplyFilter: (filterParams) => {
      dispatch(actionEquipment.setType(null));
      dispatch(actionEquipment.applyFilter(filterParams));
    },
    lookupEquipmentLoad: () => dispatch(actions.load()),

    setEquipment: (equip) => {
      const { oid, name, equipmentId, equipmentType } = equip || {};
      dispatch(actions.setField("equipment", equip ? { oid, name, equipmentId, equipmentType } : null));
    },

    //origin lookup
    lookupOriginSetVisible: (visible) => dispatch(actions.setLookupOriginVisible(visible)),
    lookupOriginPaginate: (paginationParams) => dispatch(locationActions.paginate(paginationParams)),
    lookupOriginSortOrder: (sortParams) => dispatch(locationActions.sortOrder(sortParams)),
    lookupOriginApplyFilter: (filterParams) => {
      dispatch(locationActions.setType(null));
      dispatch(locationActions.applyFilter(filterParams));
    },
    lookupOriginLoad: () => dispatch(locationActions.load()),
    setOrigin: (origin) => {
      dispatch(actions.setOriginTemp(origin));
    },
    //origin lookup

    //carrier lookup
    lookupCarrierSetVisible: (visible) => dispatch(actions.setLookupCarrierVisible(visible)),
    lookupCarrierPaginate: (paginationParams) => dispatch(carrierActions.paginate(paginationParams)),
    lookupCarrierSortOrder: (sortParams) => dispatch(carrierActions.sortOrder(sortParams)),
    lookupCarrierApplyFilter: (filterParams) => {
      dispatch(carrierActions.setType(null));
      dispatch(carrierActions.applyFilter(filterParams));
    },
    lookupCarrierLoad: () => dispatch(carrierActions.load()),

    setCarrier: (carrier) => {
      dispatch(actions.setField("carrier.oid", carrier ? carrier.oid : null));
      dispatch(actions.setField("carrier.name", carrier  ? carrier.name : ""));
    },
    //carrier lookup

    //destination lookup
    lookupDestinationSetVisible: (visible) => dispatch(actions.setLookupDestinationVisible(visible)),
    lookupDestinationPaginate: (paginationParams) => dispatch(locationActions.paginate(paginationParams)),
    lookupDestinationSortOrder: (sortParams) => dispatch(locationActions.sortOrder(sortParams)),
    lookupDestinationApplyFilter: (filterParams) => {
      dispatch(locationActions.setType(null));
      dispatch(locationActions.applyFilter(filterParams));
    },
    lookupDestinationLoad: () => dispatch(locationActions.load()),
    setDestination: (destination) => {
      dispatch(actions.setDestinationTemp(destination));
    },
    //destination lookup

    setModalOriginVisible: (visible) => {
      dispatch(actions.setModalOriginVisible(visible));
    },

    changeHandlerInputLatitudeOrigin: (event) => {
      const target = event.target;
      const field = target.name;
      let value = target.value;
      dispatch(actions.setInputLatitudeOrigin(value));
    },
    setInputLatitudeOrigin: (value) => {
      dispatch(actions.setInputLatitudeOrigin(value));
    },

    changeHandlerInputLongitudeOrigin: (event) => {
      const target = event.target;
      const field = target.name;
      let value = target.value;

      dispatch(actions.removeErrors([{ field }]));
      dispatch(actions.setInputLongitudeOrigin(value));
    },

    setInputLongitudeOrigin: (value) => {
      dispatch(actions.setInputLongitudeOrigin(value));
    },

    showMessage: (msg, success) => {
      dispatch(messageActions.messageShowMessages(makeMessages([msg], success ? "success" : "error")));
    },

    addNewOrigin: () => {
      dispatch(actions.addNewOrigin());
    },

    onRemoveItemOrigin: () => {
      dispatch(actions.onRemoveItemOrigin());
    },

    //destination
    setModalDestinationVisible: (visible) => {
      dispatch(actions.setModalDestinationVisible(visible));
    },
    onRemoveItemDestination: () => {
      dispatch(actions.onRemoveItemDestination());
    },

    changeHandlerInputLatitudeDestination: (event) => {
      const target = event.target;
      const field = target.name;
      let value = target.value;
      dispatch(actions.setInputLatitudeDestination(value));
    },
    setInputLatitudeDestination: (value) => {
      dispatch(actions.setInputLatitudeDestination(value));
    },
    changeHandlerInputLongitudeDestination: (event) => {
      const target = event.target;
      const field = target.name;
      let value = target.value;

      dispatch(actions.removeErrors([{ field }]));
      dispatch(actions.setInputLongitudeDestination(value));
    },
    setInputLongitudeDestination: (value) => {
      dispatch(actions.setInputLongitudeDestination(value));
    },

    addNewDestination: () => {
      dispatch(actions.addNewDestination());
    },

    //lookup vehicle
    lookupVehicleSetVisible: (visible) => dispatch(actions.setlookupVehicleVisible(visible)),
    lookupVehiclePaginate: (paginationParams) => dispatch(vehicleActions.paginate(paginationParams)),
    lookupVehicleSortOrder: (sortParams) => dispatch(vehicleActions.sortOrder(sortParams)),
    lookupVehicleApplyFilter: (filterParams) => {
      dispatch(vehicleActions.setType(null));
      dispatch(vehicleActions.applyFilter(filterParams));
    },
    lookupVehicleLoad: () => dispatch(vehicleActions.load()),
    setVehicle: (vehicle) => {
      if (vehicle) {
        const { oid, plate, vehicleType, vehicleCapacity } = vehicle;
        const vehicleAux = {
          oid,
          plate,
          vehicleType,
          vehicleCapacity,
        };
        dispatch(actions.setField("vehicle", vehicleAux));
      } else {
        dispatch(actions.setField("vehicle", null));
      }
    },

    //lookup driver
    lookupDriverSetVisible: (visible) => dispatch(actions.setlookupDriverVisible(visible)),
    lookupDriverPaginate: (paginationParams) => dispatch(driverActions.paginate(paginationParams)),
    lookupDriverSortOrder: (sortParams) => dispatch(driverActions.sortOrder(sortParams)),
    lookupDriverApplyFilter: (filterParams) => {
      dispatch(driverActions.setType(null));
      dispatch(driverActions.applyFilter(filterParams));
    },
    lookupDriverLoad: () => dispatch(driverActions.load()),
    setDriver: (driver) => {
      if (driver) {
        const { oid, name, documentNumber } = driver;
        const driverAux = {
          oid,
          name,
          documentNumber,
        };
        dispatch(actions.setField("driver", driverAux));
      } else {
        dispatch(actions.setField("driver", null));
      }
    },
  };
};

class TowerTravelFormPageContainer extends React.Component {
  constructor(props) {
    super(props);
    actions.afterSave = ({ oid }) => {
      this.props.history.replace(`${routes.PATH_TOWER_TRAVEL}/${oid}`);
    };
    actions.afterNew = () => {
      this.props.history.replace(`${routes.PATH_TOWER_TRAVEL}/novo`);
    };
    actions.afterRemove = () => {
      this.props.history.replace(`${routes.PATH_TOWER_TRAVEL}`);
    };
  }

  componentDidMount() {
    const oid = this.props.match.params.id;

    if (oid && parseInt(oid)) {
      this.props.loadById(oid);
    } else {
      this.props.newHandler();
    }
    this.clearFieldsLookups();
  }

  clearFieldsLookups = () => {
    this.props.setCarrier(null);
    this.props.setEquipment(null);
    this.props.setOrigin(null);
    this.props.setDestination(null);
    this.props.setInputLongitudeOrigin("");
    this.props.setInputLongitudeOrigin("");
    this.props.setInputLongitudeDestination("");
    this.props.setInputLongitudeDestination("");
    this.props.setVehicle(null);
  };

  searchHandler = () => {
    this.props.history.push(`${routes.PATH_TOWER_TRAVEL}`);
  };

  //EquipamentType
  lookupEquipmentOnPage = (e) => {
    this.props.lookupEquipmentPaginate({
      max: e.rows,
      index: e.first,
    });
  };
  lookupEquipmentOnSort = (e) => {
    this.props.lookupEquipmentSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField,
    });
  };
  lookupEquipmentOnFilter = (e) => {
    const filters = [];
    const keys = Object.keys(e.filters) || [];
    keys.forEach((key) => {
      filters.push({
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: "CONTEM",
      });
    });

    this.props.lookupEquipmentApplyFilter(filters);
  };
  lookupEquipmentOnClick = (e) => {
    const filters = [];
    this.props.lookupEquipmentApplyFilter(filters);
    this.props.lookupEquipmentSetVisible(true);
  };
  lookupEquipmentOnHide = (e) => {
    this.props.lookupEquipmentSetVisible(false);
  };
  lookupEquipmentOnConfirm = (e) => {
    this.props.setEquipment(e.selection);
    this.props.lookupEquipmentSetVisible(false);
  };
  lookupEquipmentOnCancel = (e) => {
    this.props.setEquipment(null);
    this.props.lookupEquipmentSetVisible(false);
  };

  lookupEquipmentOnEdit = () => {
    if (this.props.record.equipment && this.props.record.equipment.oid) {
      this.props.history.push(`${routes.PATH_TOWER_EQUIPMENT}/${this.props.record.equipment.oid}`);
    }
  };

  lookupEquipmentOnComplete = (e) => {};

  lookupEquipmentOnInputFilter = (e) => {
    const value = e.value;
    if (typeof value === "string") {
      const filters = [
        {
          campo: "name",
          valor: e.value,
          tipoFiltro: "CONTEM",
        },
      ];

      this.props.setField("equipment.name", e.value);
      this.props.lookupEquipmentApplyFilter(filters);
    } else {
      this.props.setEquipment(value);
    }
  };

  //Lookup origem
  lookupOriginOnPage = (e) => {
    this.props.lookupOriginPaginate({
      max: e.rows,
      index: e.first,
    });
  };

  lookupOriginOnSort = (e) => {
    this.props.lookupOriginSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField,
    });
  };

  lookupOriginOnFilter = (e) => {
    const filters = [];
    const keys = Object.keys(e.filters) || [];
    keys.forEach((key) => {
      filters.push({
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: "CONTEM",
      });
    });
    this.props.lookupOriginApplyFilter(filters);
  };

  lookupOriginOnClick = (e) => {
    this.props.lookupOriginLoad();
    this.props.lookupOriginSetVisible(true);
  };

  lookupOriginOnHide = (e) => {
    this.props.lookupOriginSetVisible(false);
  };

  lookupOriginOnConfirm = (e) => {
    this.props.setOrigin(e.selection);
    this.props.lookupOriginSetVisible(false);
  };

  lookupOriginOnCancel = (e) => {
    this.props.setOrigin(null);
    this.props.lookupOriginSetVisible(false);
  };

  lookupOriginOnEdit = () => {
    if (this.props.record.oidPessoa) {
      this.props.history.push(`${routes.PATH_ORIGIN}/${this.props.record.oidOrigin}`);
    }
  };

  lookupOriginOnComplete = (e) => {
    console.log(e);
  };

  lookupOriginOnInputFilter = (e) => {
    const value = e.value;
    const filters = [];
    if (typeof value === "string") {
      filters.push({
        campo: "name",
        valor: value,
        tipoFiltro: "CONTEM",
      });
      const originTemp = { description: value };
      this.props.setOrigin(originTemp);
    } else {
      this.props.setOrigin(value);
    }
    this.props.lookupOriginApplyFilter(filters);
  };
  //lookup origem

  //lookup carrier

  lookupCarrierOnPage = (e) => {
    this.props.lookupCarrierPaginate({
      max: e.rows,
      index: e.first,
    });
  };

  lookupCarrierOnSort = (e) => {
    this.props.lookupCarrierSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField,
    });
  };

  lookupCarrierOnFilter = (e) => {
    const filters = [];
    const keys = Object.keys(e.filters) || [];
    keys.forEach((key) => {
      filters.push({
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: "CONTEM",
      });
    });
    this.props.lookupCarrierApplyFilter(filters);
  };

  lookupCarrierOnClick = (e) => {
    this.props.lookupCarrierLoad();
    this.props.lookupCarrierSetVisible(true);
  };

  lookupCarrierOnHide = (e) => {
    this.props.lookupCarrierSetVisible(false);
  };

  lookupCarrierOnConfirm = (e) => {
    this.props.setCarrier(e.selection);
    this.props.lookupCarrierSetVisible(false);
  };

  lookupCarrierOnCancel = (e) => {
    this.props.setCarrier(null);
    this.props.lookupCarrierSetVisible(false);
  };

  lookupCarrierOnEdit = () => {
    if (this.props.record.oidPessoa) {
      this.props.history.push(`${routes.PATH_CARRIER}/${this.props.record.oidCarrier}`);
    }
  };

  lookupCarrierOnComplete = (e) => {};

  lookupCarrierOnInputFilter = (e) => {
    const value = e.value;
    const filters = [];
    if (typeof value === "string") {
      filters.push({
        campo: "name",
        valor: value,
        tipoFiltro: "CONTEM",
      });
      this.props.setField("carrier.name", value);
      this.props.setField("carrier.oid", null);
    } else {
      this.props.setCarrier(value);
    }
    this.props.lookupCarrierApplyFilter(filters);
  };
  //lookup carrier

  //lookup destination
  lookupDestinationOnPage = (e) => {
    this.props.lookupDestinationPaginate({
      max: e.rows,
      index: e.first,
    });
  };

  lookupDestinationOnSort = (e) => {
    this.props.lookupDestinationSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField,
    });
  };

  lookupDestinationOnFilter = (e) => {
    const filters = [];
    const keys = Object.keys(e.filters) || [];
    keys.forEach((key) => {
      filters.push({
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: "CONTEM",
      });
    });
    this.props.lookupDestinationApplyFilter(filters);
  };

  lookupDestinationOnClick = (e) => {
    this.props.lookupDestinationLoad();
    this.props.lookupDestinationSetVisible(true);
  };

  lookupDestinationOnHide = (e) => {
    this.props.lookupDestinationSetVisible(false);
  };

  lookupDestinationOnConfirm = (e) => {
    this.props.setDestination(e.selection);
    this.props.lookupDestinationSetVisible(false);
  };

  lookupDestinationOnCancel = (e) => {
    this.props.setDestination(null);
    this.props.lookupDestinationSetVisible(false);
  };

  lookupDestinationOnEdit = () => {
    if (this.props.record.oidPessoa) {
      this.props.history.push(`${routes.PATH_DESTINATION}/${this.props.record.oidDestination}`);
    }
  };

  lookupDestinationOnComplete = (e) => {
    console.log(e);
  };

  lookupDestinationOnInputFilter = (e) => {
    const value = e.value;
    const filters = [];
    if (typeof value === "string") {
      filters.push({
        campo: "name",
        valor: value,
        tipoFiltro: "CONTEM",
      });
      const destinationTemp = { name: value };
      this.props.setDestination(destinationTemp);
    } else {
      this.props.setDestination(value);
    }
    this.props.lookupDestinationApplyFilter(filters);
  };
  //lookup destination

  //add origem
  showAddNewOrigin = () => {
    this.props.setOrigin(null);
    this.props.setInputLatitudeOrigin("");
    this.props.setInputLongitudeOrigin("");
    this.props.lookupOriginApplyFilter([]);

    this.props.setModalOriginVisible(true);
  };
  onHideModalOrigin = () => {
    this.props.setModalOriginVisible(false);
  };

  latLngValidation = (value) => {
    const regExp = /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/;
    return regExp.test(value);
  };

  onConfirmeAddOrigin = () => {
    if (!this.latLngValidation(this.props.inputLatitudeOrigin)) {
      this.props.showMessage("Os dados da latitude está incorreto", false);
    } else if (!this.latLngValidation(this.props.inputLongitudeOrigin)) {
      this.props.showMessage("Os dados da longitude está incorreto", false);
    } else {
      this.props.addNewOrigin();
      this.onHideModalOrigin();
    }
  };

  showAddNewDestination = () => {
    this.props.setInputLatitudeDestination("");
    this.props.setInputLongitudeDestination("");
    this.props.setDestination(null);
    this.props.lookupDestinationApplyFilter([]);
    this.props.setModalDestinationVisible(true);
  };
  onHideModalDestination = () => {
    this.props.setModalDestinationVisible(false);
  };

  onConfirmeAddDestination = () => {
    if (!this.latLngValidation(this.props.inputLatitudeDestination)) {
      this.props.showMessage("Os dados da latitude está incorreto", false);
    } else if (!this.latLngValidation(this.props.inputLongitudeDestination)) {
      this.props.showMessage("Os dados da longitude está incorreto", false);
    } else {
      this.props.addNewDestination();
      this.onHideModalDestination();
    }
  };

  //lookup vehicle
  lookupVehicleOnPage = (e) => {
    this.props.lookupVehiclePaginate({
      max: e.rows,
      index: e.first,
    });
  };

  lookupVehicleOnSort = (e) => {
    this.props.lookupVehicleSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField,
    });
  };

  lookupVehicleOnFilter = (e) => {
    const filters = [];
    const keys = Object.keys(e.filters) || [];
    keys.forEach((key) => {
      filters.push({
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: "CONTEM",
      });
    });
    this.props.lookupVehicleApplyFilter(filters);
  };

  lookupVehicleOnClick = (e) => {
    this.props.lookupVehicleLoad();
    this.props.lookupVehicleSetVisible(true);
  };

  lookupVehicleOnHide = (e) => {
    this.props.lookupVehicleSetVisible(false);
  };

  lookupVehicleOnConfirm = (e) => {
    this.props.setVehicle(e.selection);
    this.props.lookupVehicleSetVisible(false);
  };

  lookupVehicleOnCancel = (e) => {
    this.props.setVehicle(null);
    this.props.lookupVehicleSetVisible(false);
  };

  lookupVehicleOnEdit = () => {};

  lookupVehicleOnComplete = (e) => {};

  lookupVehicleOnInputFilter = (e) => {
    const value = e.value;
    if (typeof value === "string") {
      const filters = [
        {
          campo: "plate",
          valor: value,
          tipoFiltro: "CONTEM",
        },
      ];
      this.props.setVehicle({ plate: value });
      this.props.lookupVehicleApplyFilter(filters);
    } else {
      this.props.setVehicle(value);
    }
  };
  //lookup vehicle
  // vehicleOnInputPlate = (e) => {
  //   const value = e.target.value;
  //   console.log("veiculo=",value)
  //   this.props.setVehicle({
  //     plate: value, 
  //     vehicleCapacity: 0,
  //     vehicleType: {
  //       name: "carreta",
  //       label: "carreta",
  //     },
  //   });
  // };

  //lookup driver
  lookupDriverOnPage = (e) => {
    this.props.lookupDriverPaginate({
      max: e.rows,
      index: e.first,
    });
  };

  lookupDriverOnSort = (e) => {
    this.props.lookupDriverSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField,
    });
  };

  lookupDriverOnFilter = (e) => {
    const filters = [];
    const keys = Object.keys(e.filters) || [];
    keys.forEach((key) => {
      filters.push({
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: "CONTEM",
      });
    });
    this.props.lookupDriverApplyFilter(filters);
  };

  lookupDriverOnClick = (e) => {
    this.props.lookupDriverLoad();
    this.props.lookupDriverSetVisible(true);
  };

  lookupDriverOnHide = (e) => {
    this.props.lookupDriverSetVisible(false);
  };

  lookupDriverOnConfirm = (e) => {
    this.props.setDriver(e.selection);
    this.props.lookupDriverSetVisible(false);
  };

  lookupDriverOnCancel = (e) => {
    this.props.setDriver(null);
    this.props.lookupDriverSetVisible(false);
  };

  lookupDriverOnEdit = () => {};

  lookupDriverOnComplete = (e) => {};

  lookupDriverOnInputFilter = (e) => {
    const value = e.value;
    if (typeof value === "string") {
      const filters = [
        {
          campo: "name",
          valor: value,
          tipoFiltro: "CONTEM",
        },
      ];
      this.props.setDriver({ name: value });
      this.props.lookupDriverApplyFilter(filters);
    } else {
      this.props.setDriver(value);
    }
  };
  //lookup driver

  render() {
    const title = `Viagem ${this.props.record.oid || "nova"}`;
    const header = (
      <div>
        <h1>{title}</h1>
        <hr className="lxSplitter"></hr>
        <div>
          <Toolbar
            onSave={this.props.saveHandler}
            saveButtonStyle="highlighted"
            onRemove={this.props.removeHandler}
            removeButtonStyle="danger"
            onNew={this.props.newHandler}
            onSearch={this.searchHandler}
          ></Toolbar>
        </div>
      </div>
    );

    const content = (
      <div>
        <TowerTravelForm
          record={this.props.record}
          changeHandler={this.props.changeHandler}
          lookupEquipment={this.props.lookupEquipment}
          lookupEquipmentOnHide={this.lookupEquipmentOnHide}
          lookupEquipmentOnConfirm={this.lookupEquipmentOnConfirm}
          lookupEquipmentOnCancel={this.lookupEquipmentOnCancel}
          lookupEquipmentOnPage={this.lookupEquipmentOnPage}
          lookupEquipmentOnSort={this.lookupEquipmentOnSort}
          lookupEquipmentOnFilter={this.lookupEquipmentOnFilter}
          lookupEquipmentOnClick={this.lookupEquipmentOnClick}
          lookupEquipmentOnEdit={this.lookupEquipmentOnEdit}
          lookupEquipmentOnComplete={this.lookupEquipmentOnComplete}
          lookupEquipmentOnInputFilter={this.lookupEquipmentOnInputFilter}
          //
          lookupOrigin={this.props.lookupOrigin}
          lookupOriginOnHide={this.lookupOriginOnHide}
          lookupOriginOnConfirm={this.lookupOriginOnConfirm}
          lookupOriginOnCancel={this.lookupOriginOnCancel}
          lookupOriginOnPage={this.lookupOriginOnPage}
          lookupOriginOnSort={this.lookupOriginOnSort}
          lookupOriginOnFilter={this.lookupOriginOnFilter}
          lookupOriginOnClick={this.lookupOriginOnClick}
          lookupOriginOnEdit={this.lookupOriginOnEdit}
          lookupOriginOnComplete={this.lookupOriginOnComplete}
          lookupOriginOnInputFilter={this.lookupOriginOnInputFilter}
          //

          lookupCarrier={this.props.lookupCarrier}
          lookupCarrierOnHide={this.lookupCarrierOnHide}
          lookupCarrierOnConfirm={this.lookupCarrierOnConfirm}
          lookupCarrierOnCancel={this.lookupCarrierOnCancel}
          lookupCarrierOnPage={this.lookupCarrierOnPage}
          lookupCarrierOnSort={this.lookupCarrierOnSort}
          lookupCarrierOnFilter={this.lookupCarrierOnFilter}
          lookupCarrierOnClick={this.lookupCarrierOnClick}
          lookupCarrierOnEdit={this.lookupCarrierOnEdit}
          lookupCarrierOnComplete={this.lookupCarrierOnComplete}
          lookupCarrierOnInputFilter={this.lookupCarrierOnInputFilter}
          //
          lookupDestination={this.props.lookupDestination}
          lookupDestinationOnHide={this.lookupDestinationOnHide}
          lookupDestinationOnConfirm={this.lookupDestinationOnConfirm}
          lookupDestinationOnCancel={this.lookupDestinationOnCancel}
          lookupDestinationOnPage={this.lookupDestinationOnPage}
          lookupDestinationOnSort={this.lookupDestinationOnSort}
          lookupDestinationOnFilter={this.lookupDestinationOnFilter}
          lookupDestinationOnClick={this.lookupDestinationOnClick}
          lookupDestinationOnEdit={this.lookupDestinationOnEdit}
          lookupDestinationOnComplete={this.lookupDestinationOnComplete}
          lookupDestinationOnInputFilter={this.lookupDestinationOnInputFilter}
          //

          //modal origin
          originTemp={this.props.originTemp}
          showAddNewOrigin={this.showAddNewOrigin}
          showModalOrigin={this.props.showModalOrigin}
          onHideModalOrigin={this.onHideModalOrigin}
          inputLatitudeOrigin={this.props.inputLatitudeOrigin}
          inputLongitudeOrigin={this.props.inputLongitudeOrigin}
          changeHandlerInputLatitudeOrigin={this.props.changeHandlerInputLatitudeOrigin}
          changeHandlerInputLongitudeOrigin={this.props.changeHandlerInputLongitudeOrigin}
          onConfirmeAddOrigin={this.onConfirmeAddOrigin}
          selectedRecordsOrigin={this.props.selectedRecordsOrigin}
          onRemoveItemOrigin={this.props.onRemoveItemOrigin}
          selectedRecordsDest={this.props.selectedRecordsDest}
          //modal destination
          onRemoveItemDestination={this.props.onRemoveItemDestination}
          showModalDestination={this.props.showModalDestination}
          showAddNewDestination={this.showAddNewDestination}
          onHideModalDestination={this.onHideModalDestination}
          destinationTemp={this.props.destinationTemp}
          inputLatitudeDestination={this.props.inputLatitudeDestination}
          inputLongitudeDestination={this.props.inputLongitudeDestination}
          changeHandlerInputLatitudeDestination={this.props.changeHandlerInputLatitudeDestination}
          changeHandlerInputLongitudeDestination={this.props.changeHandlerInputLongitudeDestination}
          onConfirmeAddDestination={this.onConfirmeAddDestination}
          //lookupVehicle
          lookupVehicle={this.props.lookupVehicle}
          lookupVehicleOnHide={this.lookupVehicleOnHide}
          lookupVehicleOnConfirm={this.lookupVehicleOnConfirm}
          lookupVehicleOnCancel={this.lookupVehicleOnCancel}
          lookupVehicleOnPage={this.lookupVehicleOnPage}
          lookupVehicleOnSort={this.lookupVehicleOnSort}
          lookupVehicleOnFilter={this.lookupVehicleOnFilter}
          lookupVehicleOnClick={this.lookupVehicleOnClick}
          lookupVehicleOnEdit={this.lookupVehicleOnEdit}
          lookupVehicleOnComplete={this.lookupVehicleOnComplete}
          lookupVehicleOnInputFilter={this.lookupVehicleOnInputFilter}
          lookupDriver={this.props.lookupDriver}
          lookupDriverOnHide={this.lookupDriverOnHide}
          lookupDriverOnConfirm={this.lookupDriverOnConfirm}
          lookupDriverOnCancel={this.lookupDriverOnCancel}
          lookupDriverOnPage={this.lookupDriverOnPage}
          lookupDriverOnSort={this.lookupDriverOnSort}
          lookupDriverOnFilter={this.lookupDriverOnFilter}
          lookupDriverOnClick={this.lookupDriverOnClick}
          lookupDriverOnEdit={this.lookupDriverOnEdit}
          lookupDriverOnComplete={this.lookupDriverOnComplete}
          lookupDriverOnInputFilter={this.lookupDriverOnInputFilter}
          // vehicleOnInputPlate={this.vehicleOnInputPlate}
        />
      </div>
    );
    return <Page header={header} content={content} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TowerTravelFormPageContainer);
