import React from "react";
import { connect } from "react-redux";
import { Column } from "primereact/column";
import Toolbar from "../../../components/toolbar/Toolbar";
import { actions } from "../empresa.actions";
import { actions as pessoaActions } from "../../pessoa/pessoa.actions";
import { routes } from "../../../constants";
import { Page } from "../../../components/page/Page";
import { EmpresaForm } from "./EmpresaForm";

const mapStateToProps = state => {
  const record = state.crudEmpresaState.currentRecord;
  const lookupPessoaBase = {
    records: state.crudPessoaState.records,
    columns: state.crudPessoaState.columns
      .filter(
        col =>
          col.field === "oid" ||
          col.field === "nome" ||
          col.field === "numeroDocumento"
      )
      .map(col => <Column key={col.field} {...col} />),
    first: state.crudPessoaState.index,
    rows: state.crudPessoaState.max,
    totalRecords: state.crudPessoaState.count,
    sortField: state.crudPessoaState.sortField,
    order: state.crudPessoaState.sortOrder,
    editDisabled: !record.oidPessoa
  };
  let lookupPessoa = Object.assign(
    {},
    state.crudEmpresaState.lookupPessoa,
    lookupPessoaBase
  );

  return {
    record,
    lookupPessoa
  };
};

const mapDispatchToProps = dispatch => {
  return {
    lookupPessoaSetVisible: visible =>
      dispatch(actions.setLookupEmpresaVisible(visible)),
    lookupPessoaPaginate: paginationParams =>
      dispatch(pessoaActions.paginate(paginationParams)),
    lookupPessoaSortOrder: sortParams =>
      dispatch(pessoaActions.sortOrder(sortParams)),
    lookupPessoaApplyFilter: filterParams => {
      dispatch(pessoaActions.setType(null));
      dispatch(pessoaActions.applyFilter(filterParams));
    },
    lookupPessoaLoad: () => dispatch(pessoaActions.load()),
    setPessoa: (person) => {
      dispatch(actions.setField("oidPessoa", person ? person.oid : null))
      dispatch(actions.setField("nomePessoa", person ? person.nome : null))
    },
    setField: (field, value) => dispatch(actions.setField(field, value)),

    changeHandler: event => {
      const target = event.target;
      const field = target.name;
      let value = target.value;
      dispatch(actions.removeErrors([{ field }]));
      dispatch(actions.setField(field, value));
    },
    saveHandler: event => {
      dispatch(actions.save());
    },
    removeHandler: event => {
      dispatch(actions.remove());
    },
    newHandler: event => {
      dispatch(actions.makeNew());
    },
    loadById: oid => dispatch(actions.loadById(oid))
  };
};

class EmpresaFormPageContainer extends React.Component {
  constructor(props) {
    super(props);
    actions.afterSave = ({ oid }) => {
      this.props.history.replace(`${routes.PATH_EMPRESA}/${oid}`);
    };
    actions.afterNew = () => {
      this.props.history.replace(`${routes.PATH_EMPRESA}/novo`);
    };
    actions.afterRemove = () => {
      this.props.history.replace(`${routes.PATH_EMPRESA}`);
    };
  }

  componentDidMount() {
    const oid = this.props.match.params.id;

    if (oid && parseInt(oid)) {
      this.props.loadById(oid);
    } else {
      this.props.newHandler();
    }
  }
  searchHandler = () => {
    this.props.history.push(`${routes.PATH_EMPRESA}`);
  };

  lookupPessoaOnPage = e => {
    this.props.lookupPessoaPaginate({
      max: e.rows,
      index: e.first
    });
  };
  lookupPessoaOnSort = e => {
    this.props.lookupPessoaSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField
    });
  };
  lookupPessoaOnFilter = e => {
    const filters = [];
    const keys = Object.keys(e.filters) || [];
    keys.forEach(key => {
      filters.push({
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: "CONTEM"
      });
    });
    this.props.lookupPessoaApplyFilter(filters);
  };
  lookupPessoaOnClick = e => {
    this.props.lookupPessoaLoad();
    this.props.lookupPessoaSetVisible(true);
  };
  lookupPessoaOnHide = e => {
    this.props.lookupPessoaSetVisible(false);
  };
  lookupPessoaOnConfirm = e => {
    this.props.setPessoa(e.selection);
    this.props.lookupPessoaSetVisible(false);
  };
  lookupPessoaOnCancel = e => {
    this.props.setPessoa(null);
    this.props.lookupPessoaSetVisible(false);
  };

  lookupPessoaOnEdit = () => {
    if (this.props.record.pessoa && this.props.record.pessoa.oid) {
      this.props.history.push(
        `${routes.PATH_PESSOA}/${this.props.record.pessoa.oid}`
      );
    }
  };

  lookupPessoaOnComplete = e => {
  };

  lookupPessoaOnInputFilter = e => {
    const value = e.value;
    if (typeof value === "string") {
      const filters = [
        {
          campo: "nome",
          valor: value,
          tipoFiltro: "CONTEM"
        }
      ];

      this.props.setField("nomePessoa", value);
      this.props.setField("pessoa.oid", null);
      this.props.lookupPessoaApplyFilter(filters);
    } else {
      const { oid, nome } = value;
      this.props.setPessoa({ oid, nome });
    }
  };

  render() {
    const content = (
      <EmpresaForm
        changeHandler={this.props.changeHandler}
        record={this.props.record}
        lookupPessoa={this.props.lookupPessoa}
        lookupPessoaOnHide={this.lookupPessoaOnHide}
        lookupPessoaOnConfirm={this.lookupPessoaOnConfirm}
        lookupPessoaOnCancel={this.lookupPessoaOnCancel}
        lookupPessoaOnPage={this.lookupPessoaOnPage}
        lookupPessoaOnSort={this.lookupPessoaOnSort}
        lookupPessoaOnFilter={this.lookupPessoaOnFilter}
        lookupPessoaOnClick={this.lookupPessoaOnClick}
        lookupPessoaOnEdit={this.lookupPessoaOnEdit}
        lookupPessoaOnComplete={this.lookupPessoaOnComplete}
        lookupPessoaOnInputFilter={this.lookupPessoaOnInputFilter}
      />
    );
    const title = `Empresa ${this.props.record.oid || "nova"}`;
    const header = (
      <div>
        <h1>{title}</h1>
        <hr className="lxSplitter" ></hr>
        <div>
          <Toolbar
            onSave={this.props.saveHandler} saveButtonStyle='highlighted'
            onRemove={this.props.removeHandler} removeButtonStyle='danger'
            onNew={this.props.newHandler}
            onSearch={this.searchHandler}>
          </Toolbar>
        </div>
      </div>
    );
    return <Page header={header} content={content} />;
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmpresaFormPageContainer);
