import React from "react";
import { InputText } from "primereact/inputtext";

import "./TowerEquipmentActionForm.scss";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "@material-ui/core";
import { InputSwitch } from "primereact/inputswitch";
import { LookupField } from "../../../components/lookup/LookupField";
// "name":"",
//     "events":[],
//     "external_id":""
export const TowerEquipmentForm = (props) => {
  //({ record, changeHandler }) => {

  const equipmentType = props.record && props.record.equipmentType && props.record.equipmentType.name ? props.record.equipmentType : "";


  console.log("equipmentType=",equipmentType)
  return (
    <div className="tower-equip-form">
      <div className="p-grid">
        <div className="p-col-6">
          <div className="p-grid">
            <div className="p-col-3">
              <div className="form-field-container">
                <label>Código</label>
                <InputText value={props.record.oid} onChange={props.changeHandler} disabled={true} />
              </div>
            </div>

            <div className="p-col-12 form-field-container">
              <label className="lxRequiredField">ID Equipamento</label>
              <InputText value={props.record.equipmentId} onChange={props.changeHandler} name="equipmentId" />
            </div>

            <div className="p-col-12 form-field-container">
              <label className="lxRequiredField">Nome</label>
              <InputText value={props.record.name} onChange={props.changeHandler} name="name" />
            </div>

            <div className="p-col-12 form-field-container">
              <label>Tipo Equipamento</label>
              <LookupField
                value={equipmentType}
                placeholder="Tipo equipamento"
                name="name"
                field="name"
                onComplete={props.lookupEquipmentTypeOnComplete}
                onInputFilter={props.lookupEquipmentTypeOnInputFilter}
                editDisabled={props.lookupEquipmentType.editDisabled}
                onEditField={props.lookupEquipmentTypeOnEdit}
                onClick={props.lookupEquipmentTypeOnClick}
                visible={props.lookupEquipmentType.visible}
                modal={props.lookupEquipmentType.modal}
                header={props.lookupEquipmentType.header}
                onHide={props.lookupEquipmentTypeOnHide}
                onConfirm={props.lookupEquipmentTypeOnConfirm}
                onCancel={props.lookupEquipmentTypeOnCancel}
                records={props.lookupEquipmentType.records}
                columns={props.lookupEquipmentType.columns}
                totalRecords={props.lookupEquipmentType.totalRecords}
                sortOrder={props.lookupEquipmentType.order}
                sortField={props.lookupEquipmentType.sortField}
                first={props.lookupEquipmentType.first}
                rows={props.lookupEquipmentType.rows}
                onPage={props.lookupEquipmentTypeOnPage}
                onFilter={props.lookupEquipmentTypeOnFilter}
                onSort={props.lookupEquipmentTypeOnOnSort}
                paginator={false}
              />
            </div>

            
          </div>
        </div>
      </div>
    </div>
  );
};
