import React from 'react';
import './simple-table.scss'

const SimpleTable = props => {
    const numberOfColumns = props.columns.length;
    const tableCells = (record) => {
        return props.columns.map((col, index) => {
            if (col.body) {
                return <td key={index}>{col.body(record, col)}</td>
            }
            return <td  key={0} >{record[col.field]}</td>
        })
    }
    const tableRows = props.records && props.records.length > 0 ? props.records.map((record, index) => {
        return (
            <tr key={index}>
                {tableCells(record)}
            </tr>)
    }) : <tr key={0}><td colSpan={numberOfColumns}>{props.notFoundMessage ? props.notFoundMessage : 'Not found'}</td></tr>

    const tableColumns = props.columns.map((column,index) => {
        return <th key={index}>{column.headerBody ? column.headerBody : column.header}</th>
    })
    return (
        <div className='lognex-simple-table' >
            <table>
                <thead><tr>{tableColumns}</tr></thead>
                <tbody>{tableRows}</tbody>
            </table>
        </div>
    )
};


export default SimpleTable;