import React from "react";
import { LxFreightComponentPanel } from './LxFreightComponentPanel';

export class LxFreightComponentsList extends React.Component {
    render() {
        const { props } = this;
        let components = props.componentsParams ? props.componentsParams : []
        components = components.map((componentParam,index) => {
            const onRemove = ()=>{
                if(componentParam.actions && componentParam.actions.onRemove){
                    componentParam.actions.onRemove(componentParam.component)
                }else{
                    console.log("Must implement method componentParam.actions.onRemove");
                }

            };
            const onChange = (event)=>{
                if(componentParam.actions && componentParam.actions.onChange){
                    componentParam.actions.onChange(componentParam.component, event.target.name, event.target.value, index)
                }  else{
                    console.log("Must implement method componentParam.actions.onChange");
                } 
            };
            const onChangeOperationHandler = (event)=>{
                console.log("onChangeOperation =>",event)
                if(componentParam.actions && componentParam.actions.onChangeOperationHandler){
                    componentParam.actions.onChangeOperationHandler(componentParam.component, event.target.name, event.value, index)
                }  else{
                    console.log("Must implement method componentParam.actions.onChangeOperationHandler");
                } 
            };
            const onRemoveCompositionHandler = (event, composition)=>{
                if(componentParam.actions && componentParam.actions.onRemoveItem){
                    componentParam.actions.onRemoveItem(componentParam.component, composition, index)
                }  else{
                    console.log("Must implement method componentParam.actions.onRemoveItem");
                } 
            };
            const onAddComposition = (description)=>{
                if(componentParam.actions && componentParam.actions.onAddComposition){
                    componentParam.actions.onAddComposition(componentParam.component, description, index)
                }  else{
                    console.log("Must implement method componentParam.actions.onAddComposition");
                } 
            };
            const onAddEntries = (entries)=>{
                if(componentParam.actions && componentParam.actions.onAddEntries){
                    componentParam.actions.onAddEntries(componentParam.component, entries, index)
                }  else{
                    console.log("Must implement method componentParam.actions.onAddComposition");
                } 
            };
            const onRemoveEntries = (entries)=>{
                if(componentParam.actions && componentParam.actions.onRemoveEntries){
                    componentParam.actions.onRemoveEntries(componentParam.component, entries, index)
                }  else{
                    console.log("Must implement method componentParam.actions.onAddComposition");
                } 
            };
            const onUpdateEntry = (entry, indexEntry)=>{
                if(componentParam.actions && componentParam.actions.onUpdateEntry){
                    componentParam.actions.onUpdateEntry(componentParam.component, entry, index, indexEntry)
                }  else{
                    console.log("Must implement method componentParam.actions.onAddComposition");
                } 
            };

            const onLoadMultiEntry = (filters) => {
                if(componentParam.actions && componentParam.actions.onLoadMultiEntry){
                    componentParam.actions.onLoadMultiEntry(filters);
                }
            }

            const sendInportTaxaComponetXLSX = (xls)=>{
                //inserir o resultado no componentParam.component.entries
                componentParam.actions.sendInportTaxaComponetXLSX(componentParam.component,index,xls)
                //componentParam.actions.onAddEntries()
            };


            return (
                <div style={{ margin: "10px" }}  key={index}>
                    <LxFreightComponentPanel
                       component={componentParam.component}
                       indexComponent={index}
                       auxiliarMultiEntry={componentParam.auxiliarMultiEntry}
                        actions={{
                            onRemove: onRemove,
                            onChange:onChange,
                            onChangeOperationHandler: onChangeOperationHandler,
                            onRemoveCompositionHandler: onRemoveCompositionHandler,
                            onAddComposition: onAddComposition,
                            onAddEntries: onAddEntries,
                            onRemoveEntries: onRemoveEntries,
                            onUpdateEntry: onUpdateEntry,
                            onLoadMultiEntry: onLoadMultiEntry,
                            sendInportTaxaComponetXLSX:sendInportTaxaComponetXLSX,
                        }}
                        listOperations={componentParam.listOperations}
                        calculatedComponent={componentParam.calculatedComponent}
                    >
                    </LxFreightComponentPanel>
                </div>
            )
        });

        return (
            <div className="p-grid " >
                <div className="p-col-12">
                    {components}
                </div>
            </div>
        );
    }
}
