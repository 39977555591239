import moment from "moment";
import { types } from "./history-log.actions";
import { Reducer } from "../../services/reducer.factory";

const initialState = {
   
  max: 10,
  columns: [
    {
      field: "oid",
      header: "ID",
      columnKey: "oid",
      sortable: true,
      filter: true
    },
    {
      field: "field",
      header: "Campo",
      columnKey: "field",
      sortable: false,
      filter: true
    },
    {
      field: "user",
      header: "Usuário",
      columnKey: "user",
      sortable: false,
      filter: true
    }, 
    {
      field: "operationType",
      header: "Ação",
      columnKey: "operationType",
      sortable: false,
      filter: true
    },
    {
      field: "createdAt",
      header: "Data Realizada",
      columnKey: "createdAt",
      sortable: false,
      filter: true
    },
    {
      field: "selectedRow",
      header: "",
      columnKey: "selectedRow",
      sortable: false,
      filter: false
    }
    
  ],
  createdAtFilter:null
};


export function CRUDHistoryLogReducer(state, action) {
  state = reducer.execute(state, action);
  return execute(state, action);
}

function execute(state, action) {
  let result = state;
  switch (action.type) { 
    case types.SET_CREATE_DATE_FILTER:
      return setCreateDateFilter(state, action)    
    default:
      return result;
  }
} 


function setCreateDateFilter(state, action) {
  let createdAtFilter = action.createdAtFilter; 
  state = Object.assign({}, state, { createdAtFilter })
  return state;
}
const reducer = new Reducer(initialState, types);