
import React from 'react';
import { InputText } from 'primereact/inputtext';
import { LookupField } from '../../../components/lookup/LookupField';

export const PalletForm = (props) => {
    console.log('PalletForm');
    const record = props.record;

    return (
        <div className="pallet-form">
            <div className="p-grid">
                <div className="p-col-6">
                    <div className="p-grid">

                        <div className="p-col-12 form-field-container">
                            <label>Código</label>
                            <InputText value={props.record.oid} onChange={props.changeHandler} name="oid" disabled={true} />
                        </div>
                        <div className="p-col-12 form-field-container">
                            <label>Identificador*</label>
                            <InputText value={props.record.identifier} onChange={props.changeHandler} name="identifier" disabled={false} />
                        </div>
                        <div className="p-col-12 form-field-container">
                            <label>Padrão de Pallet*</label>
                            <LookupField
                                value={record.palletStandardName}
                                placeholder="Padrão de Pallet"
                                name="palletStandardName"
                                field="name"
                                onComplete={props.lookupPalletStandardOnComplete}
                                onInputFilter={props.lookupPalletStandardOnInputFilter}
                                editDisabled={props.lookupPalletStandard.editDisabled}
                                onEditField={props.lookupPalletStandardOnEdit}
                                onClick={props.lookupPalletStandardOnClick}
                                visible={props.lookupPalletStandard.visible}
                                modal={props.lookupPalletStandard.modal}
                                header={props.lookupPalletStandard.header}
                                onHide={props.lookupPalletStandardOnHide}
                                onConfirm={props.lookupPalletStandardOnConfirm}
                                onCancel={props.lookupPalletStandardOnCancel}
                                records={props.lookupPalletStandard.records}
                                columns={props.lookupPalletStandard.columns}
                                totalRecords={props.lookupPalletStandard.totalRecords}
                                sortOrder={props.lookupPalletStandard.order}
                                sortField={props.lookupPalletStandard.sortField}
                                first={props.lookupPalletStandard.first}
                                rows={props.lookupPalletStandard.rows}
                                onPage={props.lookupPalletStandardOnPage}
                                onFilter={props.lookupPalletStandardOnFilter}
                                onSort={props.lookupPalletStandardOnOnSort}

                            />
                        </div>
                        <div className="p-col-12 form-field-container">
                            <label>Altura</label>
                            <InputText value={props.record.height} onChange={props.changeHandler} name="height" disabled={true} />
                        </div>
                        <div className="p-col-12 form-field-container">
                            <label>Largura</label>
                            <InputText value={props.record.width} onChange={props.changeHandler} name="width" disabled={true} />
                        </div>
                        <div className="p-col-12 form-field-container">
                            <label>Profundidade</label>
                            <InputText value={props.record.depth} onChange={props.changeHandler} name="depth" disabled={true} />
                        </div>
                        <div className="p-col-12 form-field-container">
                            <label>Peso Máximo</label>
                            <InputText value={props.record.maxWeight} onChange={props.changeHandler} name="maxWeight" disabled={true} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};