import React from "react";
import { Dialog } from "primereact/dialog";
import { Checkbox } from "primereact/checkbox";

import "./LxDialogStatusImportXlsRoutes.scss";
export class LxDialogStatusImportTaxaMutCompXls extends React.Component {
  render() {
    const { props } = this;

   console.log("LxDialogStatusImportTaxaMutCompXls=",props.dialog)
    const getTotalRegistrosErros = () => {
      let total = 0;

      if (props.dialog && props.dialog.error) {
        console.log("Entrou no if")
        total = props.dialog.error.messages.length;
        return <h2 className="label-total-error">Total erros:&nbsp; {total} </h2>;
      }
      return <></>;
    };
 
    const getMessagesRowError = () => {
      if (props.dialog && props.dialog.error.messages) {
        return props.dialog.error.messages.map((m, index) => {
          return (
            <p key={index} className="label-error">
              {m}
            </p>
          );
        });
      } else return <></>;
    };

    return (
      <Dialog
        header={"Importação dos dados do XLS"}
        visible={true}
        style={{ width: "600px" }}
        onHide={() => props.onCloseDialog()}
        modal={true}
        baseZIndex={1}
        footer={
          <div className="footer-container">
            <button title="ok" className="lognex-btn-highlighted" onClick={() => props.onCloseDialog()} disabled={!props.dialog}>
              <span>OK</span>
            </button>  
          </div>
        }
      >
        <div className="content-dialog">
          <div className="p-grid">
          
            <div className="contador">

              <div className="p-col-12 title">
                <h2>Resultado do processamento</h2>
              </div>

              <div className="resultado">
                <div className="p-col-6 cardTextInportMulti" style={{ lineHeight: 1.2 }}>
                  <p>Registros inseridos </p> <span className="totalInportMulti">{props.dialog.totalInserido}</span>
                </div>                
                <div className="p-col-6 cardTextInportMulti" style={{ lineHeight: 1.2 }}>
                  <p>Registros atualizados</p> <span  className="totalInportMulti">{props.dialog.totalAtualizado}</span>
                </div>            
              </div>
            </div>         

            <div className="p-col-12" >
              {getTotalRegistrosErros()}
            </div> 
            <br></br> <br></br>


            <div className="p-col-12" style={{ lineHeight: 1.2 }}>
              Mensagens de erro:{getMessagesRowError()}
            </div>
            {/* <div className="p-col-12">
            <Checkbox inputId="addComponents" checked={props.dialog.statusCode == 3}></Checkbox>
            <label htmlFor="addComponents" className="p-checkbox-label">
              Adicionou componentes ?
            </label>
          </div> */}
          </div>
        </div>
      </Dialog>
    );
  }
}
