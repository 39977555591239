
import React from "react";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import Toolbar from "../../../components/toolbar/Toolbar";
import { Column } from 'primereact/column';
import { routes } from '../../../constants';
import { Page } from "../../../components/page/Page";
import { VehicleGroupList } from "./VehicleGroupList";
import { actions } from '../vehicleGroup.actions'
import { AppService, COMPANY_CHANGE_EVENT_NAME, BUSINESS_UNIT_CHANGE_EVENT_NAME } from "../../../services/app.service";


const mapStateToProps = state => {
  return {
    columns: state.crudVehicleGroupState.columns,
    records: state.crudVehicleGroupState.records,
    first: state.crudVehicleGroupState.index,
    rows: state.crudVehicleGroupState.max,
    totalRecords: state.crudVehicleGroupState.count,
    sortField: state.crudVehicleGroupState.sortField,
    order: state.crudVehicleGroupState.sortOrder
  }
}
const mapDispatchToProps = dispatch => {
  return {
    paginate: paginationParams => dispatch(actions.paginate(paginationParams)),
    sortOrder: sortParams => dispatch(actions.sortOrder(sortParams)),
    applyFilter: filterParams => {
      dispatch(actions.setType(null));
      dispatch(actions.applyFilter(filterParams))
    },
    load: () => dispatch(actions.load())
  }
}


class VehicleGroupListPageContainer extends React.Component {

  newHandler = () => {
    this.props.history.push(`${routes.PATH_VEHICLE_GROUP}/novo`)
  }
  
   
  componentDidMount(){
    this.props.load();
    AppService.on(COMPANY_CHANGE_EVENT_NAME,this);
    AppService.on(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
  }
  componentWillUnmount(){
    AppService.off(COMPANY_CHANGE_EVENT_NAME,this);
    AppService.off(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
  }
  
  onReceiveAppNotifications = (eventName, params)=>{
    if(eventName === COMPANY_CHANGE_EVENT_NAME || eventName === BUSINESS_UNIT_CHANGE_EVENT_NAME){
      this.props.load();
    }
  }


  onPage = (e) => {
    this.props.paginate({
      max: e.rows,
      index: e.first
    })
  }
  onSort = (e) => {
    console.log('sorting', e);
    this.props.sortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField
    })


  }
  onFilter = (e) => {
    console.log(e);
    const filters = [];
    const keys = Object.keys(e.filters) || [];
    keys.forEach(key => {
      filters.push({
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: 'CONTEM'

      })
    })
    console.log(filters);
    this.props.applyFilter(filters)
  }

  render() {
    const header = (
      <div>
        <h1>Grupo de Veículos</h1>
        <div>
          <Toolbar onNew={this.newHandler} newButtonStyle='highlighted'>
          </Toolbar >
        </div>
      </div>
    );
    const oidTemplate = (rowData, column) => {
      return (
        <div className="id-link"><Link to={`${routes.PATH_VEHICLE_GROUP}/${rowData.oid}`}>{rowData.oid}</Link></div>
      )
    }

    const vehicleTypeTemplate = (rowData, column) => {
      const data = {
        CAR: "Carro", TRUCK: "Caminhão"
      }
      const value = data[rowData.vehicleType] || '';
      return (
        <div style={{ textAlign: 'center' }}>{value}</div>
      )
    }
    const columns = this.props.columns.map(col => {
      if (col.field === 'oid') {
        return (<Column {...col} key={col.field} body={oidTemplate} />);
      }


      if (col.field === 'vehicleType') {
        return (<Column {...col} key={col.field} body={vehicleTypeTemplate} />);
      }
      return (<Column {...col} key={col.field} />)
    })

    const content = <VehicleGroupList  {...this.props} onFilter={this.onFilter} onPage={this.onPage} onSort={this.onSort} columns={columns} />;
    return <Page header={header} content={content} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VehicleGroupListPageContainer);
