
export function validate(record) {
    const errors = [];

    if (!record.login || record.login.trim() === "") {
        errors.push({ field: 'login', message: 'O login do usuário é obrigatório!' });
    }

    if (!record.password || record.password.trim() === "") {
        errors.push({ field: 'password', message: 'A senha do usuário é obrigatória!' });
    }

    if (!record.groups || record.groups.length == 0) {
        errors.push({ field: 'groups', message: 'É necessário cadastrar ao menos 1 perfil!' });
    }
    if (!record.email || record.email.trim() === "") {
        errors.push({ field: 'email', message: 'O e-mail é obrigatório!' });
    }


    const result = {
        ok: errors.length === 0,
        errors,
        record
    }

    return new Promise((resolve, reject) => {
        const action = result.ok ? resolve : reject;
        return action(result);
    })
}