import React from 'react';
import AuditListPageContainer from './AuditListPageContainer';

export class AuditListPage extends React.Component{
    
    render(){
      return (
        <AuditListPageContainer {...this.props}/>
      )
    }
}