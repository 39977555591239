
    import React from 'react';
    import CarrierListPageContainer from './CarrierListPageContainer';
    
    export class CarrierListPage extends React.Component{
        
        render(){
          return (
            <CarrierListPageContainer {...this.props}/>
          )
        }
    }