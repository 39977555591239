import api from './api.service';
const emptyValidator = (record) => {
    const result = {
        ok: true,
        errors: [],
        record
    }
    return Promise.resolve(result)
}

class Crud {
    constructor(validate, basePath) {
        this.validate = validate;
        this.basePath = '/api/v1' + basePath;
    }

    
    insert(record) {
        return new Promise((resolve, reject) => {
            const path = this.basePath;
            record = Object.assign({}, record);
            record.oid = null; // se é novo garante que não terá um id
            this.validate = this.validate || emptyValidator();
            this.validate(record)
                .then(({ record }) => {
                    api.post(path, record)
                        .then(resolve)
                        .catch(reject);
                })
                .catch(reject);
        })
    }
    update(record) {
        return new Promise((resolve, reject) => {
            if (!record.oid) {
                return reject({ message: "O registro não possui identificador" })
            }
            this.validate = this.validate || emptyValidator();
            this.validate(record)
                .then(({ record }) => {
                    api.put(`${this.basePath}/${record.oid}`, record)
                        .then(resolve)
                        .catch(err => {
                            console.log(err);
                            reject(err);
                        });
                }).catch(err => {
                    console.log(err);
                    reject(err);
                });
        })
    }
    getByOid(oid) {
        return api.get(`${this.basePath}/${oid}`);
    }
    getAll(params) {
        return api.post(`${this.basePath}/all`, params);
    }
    remove(oid) {
        return api.remove(`${this.basePath}/${oid}`);
    }
    export(params) {
        return api.post(`${this.basePath}/export`, params);
    }
    import(records) {
        return new Promise((resolve, reject) => {
            const path = this.basePath + '/import';
            api.post(path, records)
                .then(resolve)
                .catch(reject);

        })
    }
}
export function build(validator, basePath) {
    return new Crud(validator, basePath);
}