import exportFromJSON from 'export-from-json'

export const exportToJSONFile = (data, fileName) => {
  exportFromJSON({ data, fileName, exportType: 'json' })
}

export const importFromJSONFile = (file, cb) => {
  if (file) {
    var fr = new FileReader();
    fr.readAsText(file);
    fr.onload = ({ target }) => {
      const data = JSON.parse(target.result);
      cb(data)
    }
  }
}