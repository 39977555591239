import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Column } from "primereact/column";
import { actions } from "../../camapanha-anymarket.actions";
import "./zipcoderange.scss";
import {  ZipcodeRangeCampAnyList } from "./ZipcodeRangeCampAnyList";
import { InputText } from "primereact/inputtext";
import { ButtonDialog } from "../../../../components/button-dialog/ButtonDialog";

const mapStateToProps = (state) => {
  const zipCodeRange = state.crudCampanhaAnymarketState.currentRecord.zipCodeRange || [];  
  return {
    columns: state.crudCampanhaAnymarketState.zipCodeRangeColumns,
    records: zipCodeRange, 
    inputZipCodeStart: state.crudCampanhaAnymarketState.inputZipCodeStart,
    inputZipCodeEnd: state.crudCampanhaAnymarketState.inputZipCodeEnd, 
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    paginate: (paginationParams) => dispatch(actions.paginate(paginationParams)),
    sortOrder: (sortParams) => dispatch(actions.sortOrder(sortParams)),
    applyFilter: (filterParams) => {
      dispatch(actions.setType(null));
      dispatch(actions.applyFilter(filterParams));
    },
    setField: (field, value) => dispatch(actions.setField(field, value)),
    changeHandler: event => {
      const target = event.target;
      const field = target.name;
      let value = target.value; 
      dispatch(actions.removeErrors([{ field }]));
      dispatch(actions.setField(field, value));
    },

    onRemoveZipCodeRange: (zipcodeRange) => {
      dispatch(actions.onRemoveZipCodeRange(zipcodeRange));
    },
    setInputZipCodeStart: (zipcode) => {
      dispatch(actions.setInputZipCodeStart(zipcode));
    },
    setInputZipCodeEnd: (zipcode) => {
      dispatch(actions.setInputZipCodeEnd(zipcode));
    },
    addNewZipCodeRange: () => dispatch(actions.addNewZipCodeRange())

  };
};

class ZipcodeRangeCampAnyListPageContainer extends React.Component {
  componentDidMount() {
  }

  onchangeInputZipCodeStart = (e) => {
    this.props.setInputZipCodeStart(e.target.value)
  }

  onchangeInputZipCodeEnd = (e) => {
    this.props.setInputZipCodeEnd(e.target.value)
  }


  render() {


    const removeTemplate = (rowData, column) => {
      return (
        <div>
          <ButtonDialog
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              this.props.onRemoveZipCodeRange(rowData);
            }}
            button={{
              title: "Remover",
              className: "lognex-btn-danger-inverse",
            }}
            dialog={{
              header: "Confirmação",
              modal: true,
              message: "Deseja realmente remover esse produto",
            }}
          ></ButtonDialog>
        </div>
      );

    };
    let columns = this.props.columns.map((col) => {

      if (col.field == "remove") {
        return <Column {...col} key={col.field} body={removeTemplate} style={{ width: "60px" }} />;
      }
      return <Column {...col} key={col.field} />;
    })

    let disableBtnAdd = true
    if (this.props.inputZipCodeStart && this.props.inputZipCodeStart != "" && this.props.inputZipCodeEnd && this.props.inputZipCodeEnd != "") {
      disableBtnAdd = false
    }

    return <div className="p-grid">
 

      <div className="p-col-4 form-field-container">
        <label>CEP Inicial</label>
        <InputText type="text" value={this.props.inputZipCodeStart} placeholder="CEP Inicio" onChange={this.onchangeInputZipCodeStart} />
      </div>
      <div className="p-col-4 form-field-container">
        <label>CEP Final</label>
        <InputText type="text" value={this.props.inputZipCodeEnd} placeholder="CEP Inicio" onChange={this.onchangeInputZipCodeEnd} />
      </div>
 



      <div className="p-col-4 form-field-container align-bottom-btn">
        <button
          className={"lognex-btn-highlighted bottons"}
          onClick={this.props.addNewZipCodeRange}
          title="Novo"
          disabled={disableBtnAdd}
        >
          <i className="pi pi-plus"></i>
        </button>
      </div>
      <div className="p-col-12">
        <ZipcodeRangeCampAnyList {...this.props} columns={columns} />
      </div>
    </div >;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ZipcodeRangeCampAnyListPageContainer);
