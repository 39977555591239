import React from "react";
import { DataTable } from "primereact/datatable";

export const CadTagsPanelList = (props) => (
  <DataTable
    selectionMode="single"
    // onFilter={props.onFilter}
    // onSort={props.onSort}
    onPage={props.onPage}
    value={props.records}
    lazy={true}
    //totalRecords={props.totalRecords}
    //sortOrder={props.order}
    //sortField={props.sortField}
    paginator={false}
    // first={props.first}
    //rows={props.rows}
    emptyMessage="Não foram encontrados registros" 
    metaKeySelection={false}
     selection={props.selectedTagRecord}
     onSelectionChange={props.onSelectedTagsRecordsChange}
    // rowsPerPageOptions={[5, 10, 20, 50]}
  >
    {props.columns}
  </DataTable>
);
