import React from "react";
import { DataTable } from "primereact/datatable";

export const RouteEditTable = (props) => (
  <DataTable
    value={props.records}
    emptyMessage="Não foram encontrados registros"
    onRowClick={props.rowClickHandler}
    scrollable={true}
    scrollHeight={"300px"}
    onSelectionChange={props.onSelectedEventsChange}
    selection={props.selectedRouterIncludDelivery}
  >
    {props.columns}
  </DataTable>
);
