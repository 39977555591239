import {types} from './login.actions';
import storage from '../../services/stoage.service';


const initialState = {
    credential: {
        login:'',
        password:''
    },
    token: storage.getToken(),
    login:'',
    errorMessage: '',
    twoFA: null,
    code2FA: ''
}

export function loginReducer( state = initialState, action){
    if(action.type === types.LOGIN_SET_CREDENTIAL){
        const credential =  Object.assign({},state.credential, action.credential);
        return Object.assign({}, state , { credential });
    }
    if(action.type === types.LOGIN_CLEAR_CREDENTIAL){
        const errorMessage = "";
        return Object.assign({}, state , { errorMessage });
    }

    if(action.type === types.LOGIN_SET_ERROR_MESSAGE){
        const {errorMessage} = action;
        return Object.assign({}, state , { errorMessage });
    }
    if(action.type === types.LOGIN_CLEAR_ERROR_MESSAGE){
        const errorMessage = "";
        return Object.assign({}, state , { errorMessage });
    }
    
    if(action.type === types.LOGIN_SET_TOKEN){
        const {token} = action;
        return Object.assign({}, state , { token });
    }
    
    if(action.type === types.LOGIN_CLEAR_TOKEN){
        const token = "";
        return Object.assign({}, state , { token });
    }
    
    if(action.type === types.LOGIN_SET_LOGIN){
        const {login} = action;
        return Object.assign({}, state , { login });
    }
    
    if(action.type === types.LOGIN_CLEAR_LOGIN){
        const login = "";
        return Object.assign({}, state , { login });
    }

    if(action.type === types.LOGIN_CLEAR_ALL){
        const data = {
            credential: {
                email:'',
                password:''
            },
            token: '',
            login:'',
            errorMessage: ''
        }
        return Object.assign({}, state , data);
    }

    if(action.type === types.LOGIN_FETCH_AUTHENTICATION_2FA){
        const {twoFA} = action;
        return Object.assign({}, state , { twoFA });
    } 
    if(action.type === types.LOGIN_AUTHENTICATION_2FA_ON_CHANGE){
        const {code2FA} = action;
        return Object.assign({}, state , { code2FA });
    } 
 
    return state;
}