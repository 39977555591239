import React from "react";
import { ConfigurationPanel } from "./ConfigurationPanel";

export const AuditSection = (props) => {
  return (
    <ConfigurationPanel
      id={"auditoria"}
      title={"Auditoria"}
      configurations={props.auditConfigurations}
    />
  );
};
