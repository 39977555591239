import { validate } from './validations';
import {build} from '../../services/crudFactory';
import {Action} from '../../services/actions.factory';
import api from '../../services/api.service';
const BASE_PATH = '/lognex/userdocumenttype';
const crud = build(validate,BASE_PATH);
const prefix = 'crudUserDocumentType';

const actions = new Action(prefix,crud);

const  newTypes = {
    SET_LOGNEX_ALL_DOCUMENT_TYPES: `${prefix}setLognexAllDocumentTypes`,
    SET_LOGNEX_DOCUMENT_TYPES:`${prefix}setLognexDocumentTypes`,
    SET_LOGNEX_DOCUMENT_TYPE:`${prefix}setLognexDocumentType`
}

const types = {...actions.types, ...newTypes}

actions.setLognexDocumentTypes = (lognexDocumentTypes)=>{
    return{
        type:types.SET_LOGNEX_DOCUMENT_TYPES,
        lognexDocumentTypes
    }
}
actions.setLognexDocumentType = (value)=>{
    return{
        type:types.SET_LOGNEX_DOCUMENT_TYPE,
        value
    }
}

actions.setLognexAllDocumentTypes = () => {
    return (dispatch) => {
        const params = {}
        dispatch(actions.showLoader())
        api.post('/api/v1/lognex/lognexdocumenttype/all', params)
        .then(({data}) => {
            dispatch(actions.setLognexDocumentTypes(data.records))
            dispatch(actions.hideLoader());
        })
        .catch((err) => {
            console.log(err)
            dispatch(actions.hideLoader());
        })
    }
}

export {types, actions};