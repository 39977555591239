import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import React from 'react';

export function MultiEntryComponentModalUpdate(props) {

    const footer = (
        <div>
            <button
                title="Confirmar"
                className={ "lognex-btn-highlighted" }
                onClick={props.onConfirm}
                disabled={false} >
                <i className="pi pi-check"></i>
            </button>

            <button
                title="Cancelar"
                className="lognex-btn-danger"
                onClick={props.onHide} >
                <i className="pi pi-times"></i>
            </button>
        </div>
    )

    return (

        <div>
            <Dialog
                className="lxLookupDialog"
                visible={props.visible}
                style={{ width: "auto" }}
                modal={true}
                onHide={props.onHide}
                header={props.title}
                footer={footer}
            >
                <div className="p-grid">
                    <div className="p-col-4 form-field-container">
                        <label className="lxRequiredField">Código</label>
                        <InputText value={props.record ? props.record.key : ""} onChange={props.onChangeHandler} name="key" />
                    </div>
                    <div className="p-col-4 form-field-container">
                        <label className="lxRequiredField">Nome</label>
                        <InputText value={props.record ? props.record.name : ""} onChange={props.onChangeHandler} name="name" />
                    </div>
                    <div className="p-col-4 form-field-container">
                        <label className="lxRequiredField">Valor</label>
                        <InputText value={props.record ? props.record.value : ""} onChange={props.onChangeHandler} name="value" />
                    </div>
                   
                </div>
            </Dialog>
        </div>

    )
}