import React from "react";
import { InputText } from "primereact/inputtext";
import "./filtro.scss";
import { LookupField } from "../../../../components/lookup/LookupField";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";

export const TravelFiltro = (props) => {
  const totalItens = props.records ? props.records.length : 0;
  return (
    <div className="filtro">
      <div className="p-grid" style={{ display: "flex" }}>
        <div className="p-md-2  form-field-container">
          <label className="lxRequiredField">De</label>
          <Calendar showIcon={true} value={props.initialDate} showTime={false} onChange={props.initialDateChangeHandler} disabled={false} name="initialDate" dateFormat="dd/mm/yy" />
        </div>
        <div className="p-md-2  form-field-container">
          <label className="lxRequiredField">Até</label>
          <Calendar showIcon={true} value={props.finalDate} showTime={false} onChange={props.finalDateChangeHandler} disabled={false} name="finallDate" dateFormat="dd/mm/yy" />
        </div>
        <div className="p-md-2  form-field-container">
          <label>Documento</label>
          <InputText value={props.deliveryCode ? props.deliveryCode : ""} onChange={props.deliveryCodeChangeHandler} name="deliveryCode" />
        </div>
        <div className="p-col-2  form-field-container">
          <label>Embarque</label>
          <InputText value={props.nroEmbarque || ""} onChange={props.embarqueIdChangeHandler} name="nroEmbarque" />
        </div>
        <div className="p-col-2  form-field-container">
          <br />
          <button className={"lognex-btn-highlighted"} title="Ver mapa" style={{ width: "100%", cursor: "pointer" }} onClick={props.onClickShowAllMap}>
            Ver todas no Mapa
          </button>
        </div>

        <div className="p-md-2" style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ width: "48%" }}>
            <br />
            <button className={"lognex-btn-highlighted"} title="Aplicar filtro" style={{ width: "100%", cursor: "pointer" }} onClick={props.onClickAplicarFiltro}>
              Filtrar
            </button>
          </div>

          <div style={{ width: "48%" }}>
            <br />
            <button
              className="lognex-btn-highlighted-inverse"
              style={{ width: "100%", cursor: "pointer" }}
              title="Download"
              onClick={props.onExportTravelDownloadXLSX}
              disabled={!props.enableExportCSV}
            >
              Exportar
            </button>
          </div>
        </div>
      </div>

      <div className="p-grid">
        <div className="p-col-12  form-field-container row-align-right-bottom">
          <label></label>
          <h3>Itens: {totalItens}</h3>
        </div>
      </div>
    </div>
  );
};
