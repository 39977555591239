import React from 'react'
import { InputText } from 'primereact/inputtext';
import * as utils from '../../utils'
import './InputNumber.scss'

const applyMaskValue = (value, decimalPlaces, target ) => {
        
    value = value || '';

    value = `${value}`; 

    if(value.length == 0){
        value = '0,000';
    } else if (value.length == 1) {
        value = `0,0${value}`
    } else if (value.length == 2) {
        value = `0,${value}`
    }
    if (target){
        target.selectionStart = value.length;
        target.selectionEnd = value.length;
    }
    let partial = value.replace('.', '').replace(',', '')
    let length = partial.length;
    partial = partial.substring(0, length - decimalPlaces) + "." + partial.substring(length - decimalPlaces)

    const number = parseFloat(partial)
    const mask = utils.applyThousandsPoints(`${number.toFixed(decimalPlaces).replace(".", ",")}`)

    return {mask, number};
}


export class InputNumber extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            numberValue: 0,
            decimalPlaces: props.decimalPlaces || 2
        }
    }

    static getDerivedStateFromProps(props, state) {
        if(props.value !== state.numberValue){
            //Change in props
            const {mask} = applyMaskValue(props.value, state.decimalPlaces);
            return{
                numberValue: mask
            };
        }
        return null; // No change to state
    }

    isCharacterValid = (character) => {
        const characterValids = '0123456789,';
        return characterValids.includes(character);
    }

    consistnumberValue = (value) => {
        value = utils.removeInvalidCaracteres(value, this.isCharacterValid);
        value = utils.applyThousandsPoints(value);
        return value;
    }

    consistValue = (numberValue) => {
        numberValue = utils.replaceAll(numberValue, '.', '')
        numberValue = utils.replaceAll(numberValue, ',', '.')
        return parseFloat(numberValue)
    }

   

    onChangeNumberValue = (e) => {
        const target = e.target;
        let value = e.target.value;
        // if(value.length == 0){
        //     value = '0,000';
        // } else if (value.length == 1) {
        //     value = `0,0${value}`
        // } else if (value.length == 2) {
        //     value = `0,${value}`
        // }
        // target.selectionStart = value.length;
        // target.selectionEnd = value.length;
        // let partial = value.replace('.', '').replace(',', '')
        // let length = partial.length;
        // partial = partial.substring(0, length - this.state.decimalPlaces) + "." + partial.substring(length - this.state.decimalPlaces)

        // const number = parseFloat(partial)
        
        // const mask = utils.applyThousandsPoints(`${number.toFixed(this.state.decimalPlaces).replace(".", ",")}`)

        // const mask = applyMaskValue(value, target);
        const {mask, number} = applyMaskValue(value, this.state.decimalPlaces, target);
        this.setState({
            numberValue: mask
        })

        e.target.value = `${number}`
        this.props.onChange(e)
    }

    render() {
        const { props, state } = this;        
        return (
            <div className="lxInputNumber">
                <div className="p-inputgroup">                   
                    <InputText value={state.numberValue} onBlur={this.onBlurNumberValue} onChange={this.onChangeNumberValue} name={props.name} disabled={props.disabled} />
                </div>
            </div>
        );
    }


}