import React from 'react';

import PessoaFormPageContainer from './PessoaFormPageContainer';

export class PessoaFormPage extends React.Component{

    
    render(){
        return (
           <PessoaFormPageContainer {...this.props} />
        )
    }
}
