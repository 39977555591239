
import React from 'react';
import { DataTable } from 'primereact/datatable';

export class RouteList extends React.Component {
        onRowClick(e) {
                if (e.originalEvent && e.originalEvent.target && e.originalEvent.target.cellIndex) {
                        this.props.onRowClick(e)
                }
        }
        render() {
                const { props } = this;
                return (
                        <DataTable
                                onFilter={props.onFilter}
                                onSort={props.onSort}
                                onPage={props.onPage}
                                value={props.records}
                                lazy={true}
                                totalRecords={props.totalRecords}
                                sortOrder={props.order}
                                sortField={props.sortField}
                                paginator={true}
                                first={props.first}
                                rows={props.rows}
                                emptyMessage="Não foram encontrados registros"
                                rowsPerPageOptions={[5, 10, 20, 50]}
                                onRowClick={(e) => { this.onRowClick(e) }}
                                selectionMode="multiple"
                                metaKeySelection={false}
                                selection={props.selectedRecords}
                                onSelectionChange={props.onSelectedRecordsChange}
                        >
                                {props.columns}

                        </DataTable>
                )
        }
}