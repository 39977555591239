
import { validate } from './validations';
import { build } from '../../services/crudFactory';
import { Action } from '../../services/actions.factory';

const BASE_PATH = '/lognex/drivers';
const prefix = 'crudDriver';

const crud = build(validate, BASE_PATH);

const newTypes = {
   // LOOKUP_CARRIER_SET_VISIBLE: `${prefix}setLookupCarrierVisible`,
}

const actions = new Action(prefix, crud);

const types = Object.assign({}, actions.types, newTypes);

// actions.lookupCarrierSetVisible = (visible)=>{
//     return {
//         type:types.LOOKUP_CARRIER_SET_VISIBLE,
//         visible
//     }
// }


export { types, actions }