import React from 'react';

import PolicesFormPageContainer from './PolicesFormPageContainer';

export class PolicesFormPage extends React.Component {

    render() {
        return (
            <PolicesFormPageContainer {...this.props} />
        )
    }
}
