import { validate } from './validations';
import { build } from '../../services/crudFactory';
import { Action } from '../../services/actions.factory';
import { messageActions, makeMessages } from '../../store/message';
import api from '../../services/api.service';
const BASE_PATH = '/lognex/deliverydeadline';
const prefix = 'crudDeliveryDeadline';
const crud = build(validate, BASE_PATH);



const newTypes = {    
    SET_LOOKUP_CARRIER_VISIBLE: `${prefix}setLookupCarrierVisible`,    
    SET_LOOKUP_ORIGIN_VISIBLE: `${prefix}setLookupOriginVisible`,
    SET_LOOKUP_DESTINATION_VISIBLE: `${prefix}setLookupDestinationVisible`,
    ADD_TAG_TO_TAGS_LIST: `${prefix}addTagToTagsList`,
    REMOVE_TAG_FROM_TAGS_LIST: `${prefix}removeTagFromTagsList`,
    SET_FILES_VISIBLE: `${prefix}setFilesVisible`,
    SET_ERRORS_DIALOG_VISIBLE: `${prefix}setErrorsDialogVisible`,
    SET_IMPORT_LOG_DATA: `${prefix}setImportLogData`,
    SET_MODAL_EXPORT_PRAZO_VISIBLE: `${prefix}setModalExportPrazoVisible`,
    SET_LOOKUP_CARRIER_EXPORT_VISIBLE: `${prefix}setLookupCarrierExportVisible`,
    SET_CARRIER_EXPORT: `${prefix}setCarrierExport`,
}


const actions = new Action(prefix, crud);

const types = Object.assign({}, actions.types, newTypes);

actions.setCarrierExport = (carrierExport) => {
    return {
        type: types.SET_CARRIER_EXPORT,
        carrierExport
    }
}


actions.setLookupCarrierExportVisible = (visible) => {
    return {
         type: types.SET_LOOKUP_CARRIER_EXPORT_VISIBLE,
        visible
    }
}

actions.setModalExportPrazoVisible = (visible) => {
    return {
         type: types.SET_MODAL_EXPORT_PRAZO_VISIBLE,
        visible
    }
}

actions.setLookupCarrierVisible = (visible) => {
    return {        
        type: types.SET_LOOKUP_CARRIER_VISIBLE,
        visible
    }
}

actions.setLookupOriginVisible = (visible) => {
    return {
        type: types.SET_LOOKUP_ORIGIN_VISIBLE,
        visible
    }
}

actions.setLookupDestinationVisible = (visible) => {
    return {
        type: types.SET_LOOKUP_DESTINATION_VISIBLE,
        visible
    }
}

actions.addTagToTagsList = () => {
    return {
        type: types.ADD_TAG_TO_TAGS_LIST
    }
}

actions.removeTagFromTagsList = (tag) => {
    return {
        type: types.REMOVE_TAG_FROM_TAGS_LIST,
        tag
    }
}

actions.setFilesVisible = (visible) => {
    return {
        type: types.SET_FILES_VISIBLE,
        visible
    }
}

actions.setErrorsDialogVisible = (visible) => {
    return {
        type: types.SET_ERRORS_DIALOG_VISIBLE,
        visible
    }
}

actions.setImportLogData = (data) => {
    return {
        type: types.SET_IMPORT_LOG_DATA,
        data
    }
}


actions.exportPrazo = () => {
    return (dispatch, getState) => { 
        dispatch(actions.showLoader());
        const {oid, documentNumber, name} = getState().crudDeliveryDeadlineState.carrierExport;
        const URL = `/api/v1/lognex/deliverydeadline/export-prazo`;             
        const body = {
            oid,
            name,
            documentNumber,
        }
        api.post(URL,body).then(response =>{  
            dispatch(actions.hideLoader());
            console.log("response => ",response)
            const {data } = response 
            const anchor = document.createElement("a");
            anchor.href = data.presigned_url;
            anchor.target = "_blank";
            document.body.append(anchor);
            anchor.click();
            anchor.remove();
            dispatch(
                messageActions.messageShowMessages(
                    makeMessages(["Relatório de prazo de entrega exportado com sucesso."], "success")
                )
            );

       })
       .catch((err)=>{
           dispatch(actions.hideLoader());
           // atualizar o log de erro no retorno do servidor
           console.log(err);
           dispatch(
            messageActions.messageShowMessages(
                makeMessages(["Não foi possível exportar o relatório de prazo de entrega."], "error")
            )
        );
       }) 
    }
}



export { types, actions }

