import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ContextMenu } from "primereact/contextmenu";

import "./InvoiceTable.scss";

import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";

export class InvoiceTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedRowData: null,
    };
    this.menuModel = [{ label: "Exportar relatório", icon: "pi pi-fw pi-print", command: () => this.exportReportInvoice(this.state.selectedRowData) }];
  }
  
  exportReportInvoice = (rowData) => { 
    this.props.exportReportInvoice(rowData.oid)
  };

  render() {
    return (
      <div>
        <ContextMenu model={this.menuModel} ref={(el) => (this.cm = el)} onHide={() => this.setState({ selectedRowData: null })} />
        <div className="invoiceTable-form">
          <div className="p-grid">
            <div className="p-col-12">
              <div className="p-col-12">
                <DataTable
                  contextMenuSelection={this.state.selectedRowData}
                  onContextMenuSelectionChange={(e) => this.setState({ selectedRowData: e.value })}
                  onContextMenu={(e) => this.cm.show(e.originalEvent)}
                  selectionMode="single"
                  onFilter={this.props.invoiceTbFilterHandler}
                  onSort={this.props.invoiceTbSortHandler}
                  onPage={this.props.invoiceTbPageHandler}
                  value={this.props.invoiceTbRecords}
                  lazy={true}
                  totalRecords={this.props.invoiceTbTotalRecords}
                  sortOrder={this.props.invoiceTbOrder}
                  sortField={this.props.invoiceTbSortField}
                  paginator={true}
                  first={this.props.invoiceTbFirst}
                  rows={this.props.invoiceTbRows}
                  emptyMessage={this.props.invoiceTbEmptyMessage}
                  rowsPerPageOptions={[5, 10, 20]}
                  onRowClick={this.props.invoiceTbRowClickHandler}
                >
                  {this.props.invoiceTbColumns}
                </DataTable>
                <Dialog
                  header={this.props.dialogApproveInvoice.header}
                  visible={this.props.dialogApproveInvoice.visible}
                  style={{ width: "50vw" }}
                  modal={this.props.dialogApproveInvoice.modal}
                  baseZIndex={1}
                  onHide={this.props.dialogApproveInvoice.actions.onCancel}
                  footer={
                    <div>
                      <button
                        title="Aprovar"
                        onClick={this.props.dialogApproveInvoice.actions.onApprove}
                        style={{ marginRight: ".25em" }}
                        className="lognex-btn-highlighted"
                        disabled={!this.props.dialogApproveInvoice.description}
                      >
                        <i className="pi pi-check"></i>
                      </button>

                      <button title="Cancelar" onClick={this.props.dialogApproveInvoice.actions.onCancel} style={{ marginRight: ".25em", marginBottom: ".25em" }} className="lognex-btn-danger">
                        <i className="pi pi-times"></i>
                      </button>
                    </div>
                  }
                >
                  <h4 className="lxAlertMessage">{this.props.dialogApproveInvoice.alertMessage}</h4>
                  <InputTextarea
                    className="lxInputTextArea"
                    rows={5}
                    cols={30}
                    value={this.props.dialogApproveInvoice.description}
                    onChange={this.props.dialogApproveInvoice.actions.onChangeDescription}
                  />
                </Dialog>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
