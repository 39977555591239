import React from "react";
import { connect } from "react-redux";
import { actions as appActions } from "../../../store/app";
import { actions } from "../transferRoute.actions";
import {
  AppService,
  COMPANY_CHANGE_EVENT_NAME,
  BUSINESS_UNIT_CHANGE_EVENT_NAME,
} from "../../../services/app.service";
import * as menuActions from "../../../store/menu/menu.actions";
import { Page } from "../../../components/page/Page";
import { TransferRouteList } from "./TransferRouteList";
import { Column } from "primereact/column";
import { routes } from '../../../constants';
import Toolbar from "../../../components/toolbar/Toolbar";

const mapStateToProps = (state) => {
  return {
    columns: state.crudTransferRouteState.columns,
    records: state.crudTransferRouteState.records,
    first: state.crudUnidadeState.index,
    rows: state.crudUnidadeState.max,
    totalRecords: state.crudUnidadeState.count,
    sortField: state.crudUnidadeState.sortField,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    paginate: (paginationParams) =>
      dispatch(actions.paginate(paginationParams)),
    sortOrder: (sortParams) => dispatch(actions.sortOrder(sortParams)),
    load: () => dispatch(actions.load()),
    setCurrentMenu: (menuPath) =>
      dispatch(menuActions.setCurrentRecord(menuPath)),
  };
};

class TransferRouteListPageContainer extends React.Component {
  componentDidMount() {
    this.props.load();
    AppService.on(COMPANY_CHANGE_EVENT_NAME, this);
    AppService.on(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
    const menuPath = this.props.history.location.pathname;
    this.props.setCurrentMenu(menuPath);
  }
  componentWillUnmount() {
    AppService.off(COMPANY_CHANGE_EVENT_NAME, this);
    AppService.off(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
  }

  onReceiveAppNotifications = (eventName, params) => {
    if (
      eventName === COMPANY_CHANGE_EVENT_NAME ||
      eventName === BUSINESS_UNIT_CHANGE_EVENT_NAME
    ) {
      this.props.load();
    }
  };
  onPage = (e) => {
    this.props.paginate({
      max: e.rows,
      index: e.first,
    });
  };
  onSort = (e) => {
    this.props.sortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField,
    });
  };

  onFilter = (e) => {
    const filters = [];
    const keys = Object.keys(e.filters) || [];
    keys.forEach((key) => {
      filters.push({
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: "CONTEM",
      });
    });
    this.props.applyFilter(filters);
  };

  onRowClick = (e) => {
    const oid = e && e.data && e.data.oid;
    if (oid) {
      this.props.history.push(`${routes.PATH_TRANSFER_ROUTE}/${oid}`)
    }
  }

  newHandler = () => {
    this.props.history.push(`${routes.PATH_TRANSFER_ROUTE}/novo`);
  };

  render() {
    const header = (
      <div>
        <div>
          <h1>Rotas de Transferência</h1>
        </div>
        <hr className="lxSplitter" ></hr>
        <div>
          <Toolbar onNew={this.newHandler} newButtonStyle='highlighted'>
          </Toolbar >
        </div>
      </div>
    );

    const originTemplate = (rowData, column) => {
      return  <div style={{ textAlign: "center" }}>{rowData.businessUnitOrigin.sigla}</div>
    }
    const destinationTemplate = (rowData, column) => {
      return  <div style={{ textAlign: "center" }}>{rowData.businessUnitDestination.sigla}</div>
    }
    const distanceTemplate = (rowData, column) => {
      return  <div style={{ textAlign: "center" }}>{rowData.distance}</div>
    }

    const columns = this.props.columns.map((col) => {

      if (col.columnKey === "businessUnitOrigin.sigla") {
        return <Column {...col}   key={col.field} body={originTemplate}/>;
      } else if (col.columnKey === "businessUnitDestination.sigla") {
        return <Column {...col}   key={col.field} body={destinationTemplate}/>;
      } else if (col.columnKey === "distance") {
        return <Column {...col}   key={col.field} body={distanceTemplate}/>;
      }
      
      return <Column {...col}   key={col.field} />;
    });
    const content = (
      <TransferRouteList
        {...this.props}
        onRowClick={this.onRowClick}
        onFilter={this.onFilter}
        onPage={this.onPage}
        onSort={this.onSort}
        columns={columns}
      />
    );
    return <Page header={header} content={content}></Page>;
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransferRouteListPageContainer);
