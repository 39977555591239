import React from "react";
import { Dialog } from 'primereact/dialog';
import { Calendar } from "primereact/calendar";
import { LookupField } from '../../components/lookup/LookupField';
import { InputText } from 'primereact/inputtext';
import Moment from 'moment';

import "./ModalDate.scss";
import { InputSwitch } from "primereact/inputswitch";

export class ModalDate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            date: props.initialDate,
            visible: props.visible ? props.visible : false,
            lookupOccurrenceType: props.lookupOccurrenceType,
            baixaManual: props.baixaManual,
        }
    }

    onHide = (e) => {
        if (this.props.onHide) {
            this.props.onHide(e);
        }
    }

    onConfirm = () => {
        if (this.props.onConfirm) {
            this.props.setBaixaManual(this.state.baixaManual);
            this.props.onConfirm(this.state.date);
        }
    }
    componentWillUpdate() { }


    componentWillReceiveProps(props) {
        if (props.visible != this.state.visible) {
            this.setState((prevState) => { return { visible: props.visible } })
        }
    }

    onChangeBaixaManual = (e) => {
        this.setState({ baixaManual: e.value });
    }


    render() {
        const footer = (
            <div>
                <button
                    title="Confirmar"
                    className={"lognex-btn-highlighted"}
                    onClick={this.onConfirm}
                >
                    <i className="pi pi-check"></i>
                </button>
                <button
                    title="Cancelar"
                    className="lognex-btn-danger"
                    onClick={this.onHide}
                >
                    <i className="pi pi-times"></i>
                </button>
            </div>
        )
        
        let baixaManualDisabled = false
        try{
            let occurrenceType=this.props.occurrenceTypeFilter ? parseInt(this.props.occurrenceTypeFilter.code)  : -1;
            if(occurrenceType==1){
                baixaManualDisabled=true;
            }
        }catch(e){
            console.log("baixaManualDisabled:",e);
        }
        

        return (
            <div className="modal-date">
                <Dialog
                    header={this.props.header}
                    onHide={this.onHide}
                    visible={this.state.visible}
                    footer={footer}>

                    <div>
                        <p className="data">{Moment(this.state.date).format("DD/MM/YYYY") || " "}</p>
                        <Calendar
                            inline={true}
                            dateFormat="dd/mm/yy"
                            readOnlyInput={true}
                            disabledDays={[0]}
                            value={this.state.date}
                            onChange={(e) => this.setState({ date: e.value })}
                        />
                    </div>
                    <div className="content-occurrences-type">
                        <label>Ocorrência</label>
                        <LookupField
                            value={this.props.occurrenceTypeFilter ? this.props.occurrenceTypeFilter : null}
                            placeholder="Ocorrência"
                            name="description"
                            field="description"
                            onComplete={this.props.lookupOccurrenceTypeOnComplete}
                            onInputFilter={this.props.lookupOccurrenceTypeOnInputFilter}
                            editDisabled={this.props.lookupOccurrenceType.editDisabled}
                            onEditField={this.props.lookupOccurrenceTypeOnEdit}
                            onClick={this.props.lookupOccurrenceTypeOnClick}
                            visible={this.props.lookupOccurrenceType.visible}
                            modal={this.props.lookupOccurrenceType.modal}
                            header={this.props.lookupOccurrenceType.header}
                            onHide={this.props.lookupOccurrenceTypeOnHide}
                            onConfirm={this.props.lookupOccurrenceTypeOnConfirm}
                            onCancel={this.props.lookupOccurrenceTypeOnCancel}
                            records={this.props.lookupOccurrenceType.records}
                            columns={this.props.lookupOccurrenceType.columns}
                            totalRecords={this.props.lookupOccurrenceType.totalRecords}
                            sortOrder={this.props.lookupOccurrenceType.order}
                            sortField={this.props.lookupOccurrenceType.sortField}
                            first={this.props.lookupOccurrenceType.first}
                            rows={this.props.lookupOccurrenceType.rows}
                            onPage={this.props.lookupOccurrenceTypeOnPage}
                            onFilter={this.props.lookupOccurrenceTypeOnFilter}
                            onSort={this.props.lookupOccurrenceTypeOnOnSort}
                        />
                        <label>Obs:</label>
                        <InputText value={this.props.additionalDescription} onChange={this.props.additionalDescriptionChangeHandler} name="additionalDescription" />

                        <div className="switch-align-vertical-center">
                            <InputSwitch disabled={baixaManualDisabled} checked={this.state.baixaManual} onChange={this.onChangeBaixaManual} />
                            <label style={{ marginLeft: "10px" }}>Baixar manual</label>
                        </div>

                    </div>



                </Dialog>
            </div>
        );
    }
} 