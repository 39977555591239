import { validate } from "./validations";
import { build } from "../../services/crudFactory";
import { Action } from "../../services/actions.factory";
import api from "../../services/api.service";

const BASE_PATH = "/lognex/towertravel";
const prefix = "crudTowerTravel";
const crud = build(validate, BASE_PATH);

const newTypes = {
  SET_LOOKUP_EQUIPMENT_VISIBLE: `${prefix}setLookupEquipmentVisible`,
  SET_LOOKUP_CARRIER_VISIBLE: `${prefix}setLookupCarrierVisible`,
  SET_LOOKUP_ORIGIN_VISIBLE: `${prefix}setLookupOriginVisible`,
  SET_LOOKUP_DESTINATION_VISIBLE: `${prefix}setLookupDestinationVisible`,
  SET_VISIBLE_MODAL_ORIGIN: `${prefix}setModalOriginVisible`,
  SET_VALUE_LATITUDE_ORIGIN: `${prefix}setInputLatitudeOrigin`,
  SET_VALUE_LONGITUDE_ORIGIN: `${prefix}setInputLongitudeOrigin`,
  ADD_NEW_ORIGIN: `${prefix}addNewOrigin`,
  SET_ORIGIN_LOOKUP: `${prefix}setOriginTemp`,
  SET_SELECTED_ROWS_ORIGIN: `${prefix}setSelectedRowsOrigin`,
  REMOVE_SELECTED_ROWS_ORIGIN: `${prefix}onRemoveItemOrigin`,
  SET_SELECTED_ROWS_DESTINATION: `${prefix}setSelectedRowsDestination`,
  REMOVE_SELECTED_ROWS_DESTINATION: `${prefix}onRemoveItemDestination`,
  SET_VISIBLE_MODAL_DESTINATION: `${prefix}setModalDestinationVisible`,
  SET_DESTINATION_FROM_LOOKUP: `${prefix}setDestinationTemp`,
  SET_VALUE_LATITUDE_DESTINATION: `${prefix}setInputLatitudeDestination`,
  SET_VALUE_LONGITUDE_DESTINATION: `${prefix}setInputLongitudeDestination`,
  ADD_NEW_DESTINATION: `${prefix}addNewDestination`, 
  SET_LOOKUP_VEHICLE_VISIBLE: `${prefix}setlookupVehicleVisible`,
  SET_LOOKUP_DRIVER_VISIBLE: `${prefix}setlookupDriverVisible`,
};

const actions = new Action(prefix, crud);
const types = Object.assign({}, actions.types, newTypes);

export { types, actions };


actions.setlookupVehicleVisible = (visible) => {
  return {
      type: types.SET_LOOKUP_VEHICLE_VISIBLE,
      visible
  }
}
actions.setlookupDriverVisible = (visible) => {
  return {
      type: types.SET_LOOKUP_DRIVER_VISIBLE,
      visible
  }
}
 

actions.addNewDestination = () => {
  return {
    type: types.ADD_NEW_DESTINATION,
  };
};

actions.setInputLatitudeDestination = (inputLatitudeDestination) => {
  return {
    type: types.SET_VALUE_LATITUDE_DESTINATION,
    inputLatitudeDestination,
  };
};

actions.setInputLongitudeDestination = (inputLongitudeDestination) => {
  return {
    type: types.SET_VALUE_LONGITUDE_DESTINATION,
    inputLongitudeDestination,
  };
};

actions.setDestinationTemp = (destination) => {
  return {
    type: types.SET_DESTINATION_FROM_LOOKUP,
    destination,
  };
};

actions.setModalDestinationVisible = (visible) => {
  return {
    type: types.SET_VISIBLE_MODAL_DESTINATION,
    visible,
  };
};

actions.onRemoveItemOrigin = () => {
  return {
    type: types.REMOVE_SELECTED_ROWS_ORIGIN,
  };
};

actions.onRemoveItemDestination = () => {
  return {
    type: types.REMOVE_SELECTED_ROWS_DESTINATION,
  };
};

actions.setSelectedRowsDestination = (selectedRows) => {
  return {
    type: types.SET_SELECTED_ROWS_DESTINATION,
    selectedRows,
  };
};

actions.setSelectedRowsOrigin = (selectedRows) => {
  return {
    type: types.SET_SELECTED_ROWS_ORIGIN,
    selectedRows,
  };
};

actions.setOriginTemp = (origin) => {
  return {
    type: types.SET_ORIGIN_LOOKUP,
    origin,
  };
};

actions.addNewOrigin = () => {
  return {
    type: types.ADD_NEW_ORIGIN,
  };
};

actions.setInputLatitudeOrigin = (inputLatitudeOrigin) => {
  return {
    type: types.SET_VALUE_LATITUDE_ORIGIN,
    inputLatitudeOrigin,
  };
};

actions.setInputLongitudeOrigin = (inputLongitudeOrigin) => {
  return {
    type: types.SET_VALUE_LONGITUDE_ORIGIN,
    inputLongitudeOrigin,
  };
};

actions.setLookupEquipmentVisible = (visible) => {
  return {
    type: types.SET_LOOKUP_EQUIPMENT_VISIBLE,
    visible,
  };
};

actions.setModalOriginVisible = (visible) => {
  return {
    type: types.SET_VISIBLE_MODAL_ORIGIN,
    visible,
  };
};

actions.setLookupCarrierVisible = (visible) => {
  return {
    type: types.SET_LOOKUP_CARRIER_VISIBLE,
    visible,
  };
};
actions.setLookupOriginVisible = (visible) => {
  return {
    type: types.SET_LOOKUP_ORIGIN_VISIBLE,
    visible,
  };
};
actions.setLookupDestinationVisible = (visible) => {
  return {
    type: types.SET_LOOKUP_DESTINATION_VISIBLE,
    visible,
  };
};
