import React from 'react';
import './Totalizer.scss';
import * as utils from '../../utils'

function isFloat(n){
    return Number(n) === n && n % 1 !== 0;
}
export class Totalizer extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { props } = this;
        const totalizerComponents = !props.totalizers ? '' : props.totalizers.map((totalizer, index) => {
            let classValue = "" 
            if (totalizer.value !== 0.0 && totalizer.colours) {
                classValue = "lxNegativeValue"
            } 
            const separator = index > 0 ? <div className="vl"></div> : "" 
            
            return (
                <React.Fragment key={index}>
                    {separator}
                    <label className={classValue}>{ isFloat(totalizer.value) ? utils.numberToCurrency(totalizer.value, 'REAL') : totalizer.value}</label>
                    <label>{totalizer.label}</label>
                    
                </React.Fragment>)
        })
        return (
            <div className="p-grid">
                <div className={`p-col-12 lxTotalizer ${props.classNameT}`}>
                    {totalizerComponents}
                </div>
            </div>
        )
    }
}
