import React from "react";
import { DataTable } from "primereact/datatable";

export const OrderCargoProductsList = (props) => (
  <DataTable
    selectionMode="single"
    // onFilter={props.onFilter}
    // onSort={props.onSort}
    // onPage={props.onPage}
    value={props.records}
    // totalRecords={props.totalRecords}
    // sortOrder={props.order}
    // sortField={props.sortField}
    paginator={false}
    // first={props.first}
    // rows={props.rows}
    scrollHeight={"500px"}
    scrollable={true}
    emptyMessage="Não foram encontrados registros"
    // rowsPerPageOptions={[5, 10, 20, 50]}
    rowExpansionTemplate={props.rowExpasionTemplateHandler}
    expandedRows={props.productRowSelected}
    onRowCollapse={props.onRowCollapse}
    onRowToggle={props.onRowToggle}
  >
    {props.columns}
  </DataTable>
);
