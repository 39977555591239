import React from "react";
import { ConfigurationPanel } from "./ConfigurationPanel";

export const DeliveryDeadLineSection = (props) => {
    return (
        <ConfigurationPanel
            id={"deliveryDeadline"}
            title={"Prazo de Entrega"}
            configurations={props.deliveryDeadLineConfigurations}
        />
    )
}