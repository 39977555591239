import React from "react";
import { connect } from "react-redux";
import {Link} from 'react-router-dom';
import Toolbar from "../../../components/toolbar/Toolbar";
import {Column} from 'primereact/column';
import {routes} from '../../../constants';
import { Page } from "../../../components/page/Page";
import { MenuList } from "./MenuList";
import {actions} from '../menu.actions'


const mapStateToProps = state => {
  return {
      columns: state.crudMenuState.columns,
      records: state.crudMenuState.records,
      first: state.crudMenuState.index,
      rows: state.crudMenuState.max,
      totalRecords:state.crudMenuState.count,
      sortField:state.crudMenuState.sortField,
      order:state.crudMenuState.sortOrder
  }
}
const mapDispatchToProps = dispatch =>{
  return {
      paginate: paginationParams => dispatch(actions.paginate(paginationParams)),
      sortOrder: sortParams => dispatch(actions.sortOrder(sortParams)),
      applyFilter: filterParams => {
          dispatch(actions.setType(null));
          dispatch(actions.applyFilter(filterParams))
      }, 
      load: ()=>dispatch(actions.load())
  }
}


class MenuListPageContainer extends React.Component {

  newHandler = ()=>{
    this.props.history.push(`${routes.PATH_MENU}/novo`)
  }
  componentDidMount(){
    this.props.load();
  }


  onPage = (e)=>{
    this.props.paginate({
        max:e.rows,
        index: e.first
    })
}
onSort = (e)=>{
    this.props.sortOrder({
        sortOrder: e.sortOrder,
        sortField: e.sortField
    })
    
   
}
onFilter = (e)=>{
    const filters = [];
    const keys = Object.keys(e.filters) || [];
    keys.forEach(key =>{
        filters.push({
            campo:key,
            valor: e.filters[key].value,
            tipoFiltro: 'CONTEM'

        })
    })
   this.props.applyFilter(filters)
    
}

  render() {
    const header = (
      <div>
        <h1>Menus</h1>
        <div>
          <Toolbar onNew={this.newHandler} newButtonStyle='highlighted'>
          </Toolbar >
        </div>
      </div>
    );
    const oidTemplate = (rowData,column) =>{
      return (
        <div className="id-link"><Link to={`${routes.PATH_MENU}/${rowData.oid}`}>{rowData.oid}</Link></div>
      )
  }

    const columns = this.props.columns.map(col => {
      if(col.field === 'oid'){
        return (<Column {...col} key={col.field} body={oidTemplate}/> );
      }
      if(col.field === 'path'){
        return (<Column {...col} key={col.field} style={{width:'25%'}}/> );
      }
    return (<Column {...col} key={col.field}/>)})

    const content = <MenuList  {...this.props}  onFilter={this.onFilter} onPage={this.onPage} onSort={this.onSort}  columns={columns} />;
    return <Page header={header} content={content} />;
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(MenuListPageContainer);
