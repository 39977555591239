import React from "react";
import L, { Icon, map, marker, point } from "leaflet";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import "leaflet.markercluster";
import leafletPip from "@mapbox/leaflet-pip";
import polyUtil from "@mapbox/polyline";
import ReactDOMServer from "react-dom/server";
import { connect } from "react-redux";
import { actions } from "../tower_travel_visualize_map.actions";

import vehicleIconFail from "../../../assets/images/icons/truck_failure.png";
import vehicleIconSuccess from "../../../assets/images/icons/truck_success.png";
import vehicleIconWarning from "../../../assets/images/icons/truck_warning.png";
import vehicleIconNormal from "../../../assets/images/icons/truck_normal.png";

import vehicleIconCanceled from "../../../assets/images/icons/truck_failure.png";
import vehicleIconLagTrend from "../../../assets/images/icons/truck_success_with_pendings.png";

import pin_green from "../../../assets/images/icons/pin_green.svg";
import pin_red from "../../../assets/images/icons/pin_red.svg";
import pin_blue from "../../../assets/images/icons/pin_blue.svg";
import pin_home_origin from "../../../assets/images/icons/icon-home.png";

import "./travel-map.scss";

import { CANCELED, CREATED, END_TRAVEL, TRAVELING, TRAVEL_LAG_TREND, TRAVEL_LATE, TRAVEL_ON_TIME } from "../../towerDashboard/constants";
import moment from "moment";
import { GraficoTempHora } from "../components/dialogGraficoTempHora";

const mapStateToProps = (state) => {
  // const crudTowerTravelBase = {
  //   travels: state.crudTowerTravelState.records,
  // };

  // const records = Object.assign({}, state.crudTowerTravelVisualizeMapState, crudTowerTravelBase);
  const showLoader = state.loaderState.show;
  const travels = state.crudTowerTravelVisualizeMapState.travels || [];
  return {
    travels,
    defaultPosition: state.crudTowerTravelVisualizeMapState.defaultPosition,
    routeColors: state.crudTowerTravelVisualizeMapState.routeColors,
    lineStyle: state.crudTowerTravelVisualizeMapState.lineStyle,
    unidadeRecords: state.crudTowerTravelVisualizeMapState.unidadeRecords,
    showLoader,
    graficoTempHoraVisible: state.crudTowerTravelVisualizeMapState.graficoTempHoraVisible,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setTavelDeliveryDate: (travelData) => {
      dispatch(actions.setTavelDeliveryDate(travelData));
    },
    changeDialogDeliveryDateVisible: (val) => {
      dispatch(actions.changeDialogDeliveryDateVisible(val));
    },
    showGraficoTempHora:(visible)=>dispatch(actions.showGraficoTempHora(visible)),
  };
};
const htmlDecode = (input) => {
  var e = document.createElement("div");
  e.innerHTML = input;
  return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
};
const BoxPopup = (param) => {
  return (
    <div className="content-delivery-box">
      <div dangerouslySetInnerHTML={{ __html: htmlDecode(ReactDOMServer.renderToString(param.detail)) }}></div>

      <div>
        <h3>Já foi entregue?</h3>
      </div>
      <div>
        <button id="popup-button-id" data={JSON.stringify({ oid: param.travel.oid, index: param.index, destinOid: param.destinOid })} className="btn-click-delivery" type="button">
          Incluir data de entrega
        </button>
      </div>
    </div>
  );
};

class Map extends React.Component {
  constructor(props) {
    super(props);
    this.data = { bounds: L.latLngBounds(), markers: [], layersCds: [] };
    this.deliveryPoint = [];
    this.markersGroupCds = L.markerClusterGroup({
      spiderfyOnMaxZoom: true,
      showCoverageOnHover: false,
      zoomToBoundsOnClick: true,
    });
    this.state = {
      travelsRender: [],
    };
    this.loaderComplete = false;
    this.showDeliveryPoints = this.showDeliveryPoints.bind(this);
  }
  componentDidMount() {
    this.map = L.map("map", {
      center: this.props.defaultPosition,
      zoom: 4,
      zoomControl: true,
      layers: [
        L.tileLayer("https://{s}.tile.osm.org/{z}/{x}/{y}.png", {
          attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        }),
      ],
    });
    this.clearCds();
    this.clearMap();
  }
  componentDidUpdate() {
    this.addMarkerRouter();
    this.renderCdsOnMap();
  }

  clearCds = () => {
    this.markersGroupCds.clearLayers();
  };

  removeCD = (latLng) => {
    let newListAux = [];
    for (var i = 0; i < this.data.layersCds.length; i++) {
      if (this.data.layersCds[i].lat == latLng.lat && this.data.layersCds[i].lng == latLng.lng) {
        // this.map.removeLayer(this.data.layersCds[i].layer);
        this.markersGroupCds.removeLayer(this.data.layersCds[i].layer);
      } else {
        newListAux.push(this.data.layersCds[i]);
      }
    }
    this.data.layersCds = newListAux;
  };

  setLayerCD = (marker) => {
    this.data.layersCds.push(marker);
  };

  getCoordenadasCDS = (cd) => {
    try {
      if (cd.address.georeference && cd.address.georeference.latitude) {
        return { lat: cd.address.georeference.latitude, lng: cd.address.georeference.longitude };
      }
    } catch (error) { }
    return null;
  };
  renderCdsOnMap = () => {
    this.clearCds();

    if (this.props.unidadeRecords && this.props.unidadeRecords.length > 0) {
      this.markersGroupCds.addTo(this.map);

      this.props.unidadeRecords.forEach((c) => {
        const coordenadasCds = this.getCoordenadasCDS(c);

        if (coordenadasCds != null) {
          const markerCD = L.marker([coordenadasCds.lat, coordenadasCds.lng]);
          markerCD.bindPopup(`Centro de Distribuição: <b>${c.label}</b> <br /> `);
          markerCD.setIcon(
            new Icon({
              iconUrl: pin_home_origin,
              iconRetinaUrl: pin_home_origin,
              iconSize: point(25, 25),
              iconAnchor: [15, 15],
            })
          );
          this.markersGroupCds.addLayer(markerCD);
          this.setLayerCD({ layer: markerCD, lat: coordenadasCds.lat, lng: coordenadasCds.lng });
        }
      });

      // this.markersGroupCds.bindPopup

      // markers.addTo(this.map)
    }
  };
  setNewLayer = (marker) => {
    this.data.markers.push(marker);
  };

  setNewBounds = (coords) => {
    this.data.bounds.extend([coords[0], coords[coords.length - 1]]);
  };

  fitView = () => {
    if (this.data.bounds) {
      try {
        this.map.fitBounds(
          this.data.bounds,
          {
            paddingBottomRight: [10, 0],
            paddingTopLeft: [10, 0],
          },
          { duration: 3 }
        );
      } catch (e) {
        console.log("Erro fitView ", e);
      }
    }
  };

  clearMap = () => {
    for (var i = 0; i < this.data.markers.length; i++) {
      this.map.removeLayer(this.data.markers[i]);
    }
    this.data.markers = [];
  };

  clearMarherPath = () => {
    for (let index = 0; index < this.listMarkerPath.length; index++) {
      this.map.removeLayer(this.listMarkerPath[index]);
    }
    this.listMarkerPath = [];
  };

  getStatusPopupTravel = (travel) => {
    let status = "";
    /* if (travel.travel_status === CREATED) {
      status = "Ainda nao iniciada";
    } else */ if (travel.status_time === TRAVEL_ON_TIME) {
      status = "Dentro do prazo";
    } else if (travel.status_time === TRAVEL_LAG_TREND) {
      status = "Tendência de atraso";
    } else if (travel.status_time === TRAVEL_LATE) {
      status = "Atrasada";
    }
    return status;
  };

  //renderiza os pontos do caminho percorrido
  renderPathTaken = (travel) => {
    try {
      if (!this.map) return;

      // const latlngs = travel.pathTraveled.map((p)=>{return [p.lat,p.lng]})
      if (this.map && travel.vehicle_route_path && travel.vehicle_route_path.length > 0) {
        //
        travel.vehicle_route_path.forEach((p) => {
          if (p.shape) {
            var latlngs = polyUtil.decode(p.shape);//6
            var path = new L.Polyline(latlngs, { className: "vehicle_polyline" },).addTo(this.map);
            this.setNewLayer(path);
          }
        });
      }
    } catch (error) {
      console.error(error)
    }
    return null;
  };

  getVelocidade = (travel) => {
    try {
      if (travel.pathTraveled && travel.pathTraveled.length > 0) {
        const lastPostion = travel.pathTraveled[travel.pathTraveled.length - 1];
        if (lastPostion.velocidade) return lastPostion.velocidade;
      }
    } catch (error) {
      console.error(error)
    }
    return 0;
  };
  addMarkerRouter = () => {
    //
    try {
      this.clearMap();
      this.props.travels.map((travel, index) => {
        if (travel.origin && travel.origin.length > 0 && travel.destinations && travel.destinations.length) {
          //veiculo
          //mostrar o veiculo no mapa somente se não tiver finalizado
          if (travel.travel_status !== END_TRAVEL) {
            const latLng = this.getPostionCarrier(travel);
            if (latLng && latLng.length > 0) {
              const markerVehicle = L.marker(latLng).addTo(this.map);
              markerVehicle.bindPopup(
                `Origem: <b>${travel.origin[0].description}</b> <br />
              Destino: <b> ${travel.destinations[travel.destinations.length - 1].description}</b> <br />
              Previsão de entrega:<b> ${moment(travel.deliveryForecastDate).format("DD/MM/YYYY HH:mm")} </b> <br />
              Placa:<b> ${travel.vehicle.plate} </b> <br />
              Velocidade:<b> ${this.getVelocidade(travel)} Km/h </b> <br />
             
              Status: <b> ${this.getStatusPopupTravel(travel)} </b> <br />`
              );
              markerVehicle.setIcon(this.getTravelIcon(travel));
              this.setNewLayer(markerVehicle);
            }
          }
          //ponto de origem
          const circuloIconOrigem = L.circle([travel.origin[0].lat, travel.origin[0].lng], 2500, {
            fillOpacity: 0.1,
            color: "#E26D97",
          });
          circuloIconOrigem.addTo(this.map);
          this.setNewLayer(circuloIconOrigem);

          //pontos de entregas
          if (travel.initialRoute && travel.initialRoute.geometry) {
            var latlngs = polyUtil.decode(travel.initialRoute.geometry);
            // var latlngs = polyUtil.decode(shape);

            this.setNewBounds(latlngs);

            var routeColor = this.props.routeColors[index % this.props.routeColors.length];

            var path = new L.Polyline(latlngs, {
              opacity: this.props.lineStyle.opacity,
              weight: this.props.lineStyle.weight,
              color: routeColor,
            }).addTo(this.map);

            path.on("mouseover", function () {
              this.setStyle({
                opacity: 1,
                weight: 12,
              });
            });
            path.bindPopup(
              `Origem: <b>${travel.origin[0].description}</b> <br /> 
              Placa:<b> ${travel.vehicle.plate} </b> <br />  
              Status: <b> ${this.getStatusPopupTravel(travel)} </b> <br />`
            );

            const opacity = this.props.lineStyle.opacity;
            const weight = this.props.lineStyle.weight;
            path.on("mouseout", function () {
              this.setStyle({
                opacity: opacity,
                weight: weight,
              });
            });

            //mostrar todos os pontos de entrega da viagem
            this.setNewLayer(path);
            this.showDeliveryPoints(travel);
            this.renderPathTaken(travel);


          }
        }
      });

      if (this.data.markers.length > 0) {
        this.fitView();
      }
      this.loaderComplete = true;
    } catch (error) {
      console.error(error)
    }
  };

  checkDelivery = () => {
    const buttonEl = document.getElementById("popup-button-id");
    if (buttonEl) {
      let data = buttonEl.getAttribute("data");
      const travelData = JSON.parse(data);
      if (travelData) {
        this.props.setTavelDeliveryDate(travelData);
        this.props.changeDialogDeliveryDateVisible(true);
      }
    }
  };

  popupopenDelivery = () => {
    const buttonEl = document.getElementById("popup-button-id");
    if (buttonEl) {
      buttonEl.addEventListener("click", this.checkDelivery);
    }
  };

  popupcloseDelivery = () => {
    const buttonEl = document.getElementById("popup-button-id");
    if (buttonEl) {
      buttonEl.removeEventListener("click", this.checkDelivery);
    }
  };

  showDeliveryPoints = (travel) => {
    try {
      travel.destinations.forEach((destino, index) => {
        const deliveryPoint = L.marker([destino.lat, destino.lng]).addEventListener("popupopen", this.popupopenDelivery).addEventListener("popupclose", this.popupcloseDelivery).addTo(this.map);

        //L.circle([50.5, 30.5], 200).addTo(map);
        //criar um circulo envolta do ponto de entrega, com 1000 metros
        const circulo = L.circle([destino.lat, destino.lng], 1000, {
          fillOpacity: 0.1,
          color: "#FF9F8A",
        });
        circulo.addTo(this.map);

        //remover o ponto do centro de distribuição, caso seja o mesmo ponto do ponto de entrega
        this.removeCD({ lat: destino.lat, lng: destino.lng });

        let deliveryStr = `Cidade:<b> ` + destino.description + `</b> <br />`;
        deliveryStr += `Previsão de entrega:<b> ` + moment(destino.deliveryForecastDate).format("DD/MM/YYYY HH:mm") + ` </b> <br /> `;

        if (destino.deliveryDate) {
          //ponto ja entregue
          if (travel.status_time === TRAVEL_LATE) {
            deliveryStr += `Status:<b> Entregue atrasado</b> <br /> `;
          }
          deliveryStr += `Data da entrega:<b> ${moment(destino.deliveryDate).format("DD/MM/YYYY HH:mm")}</b> <br /> `;
          deliveryPoint.setIcon(this.getIconPinEntregueDestino(travel));
        } else {
          //não entregue
          deliveryStr += `Status:<b> Não entregue </b> <br /> `;

          deliveryPoint.setIcon(this.getIconPinDestino(travel));
        }

        if (destino.distanceTotalKm) {
          deliveryStr += `Distancia:<b>${destino.distanceTotalKm}</b> <br /> `;
        }
        if (destino.percentDistancia) {
          deliveryStr += `Percentual:<b>${destino.percentDistancia}%</b> <br /> `;
        }
        deliveryStr += `Ordem:<b>${destino.code}</b> <br />`;
        if (destino.address) deliveryStr += `address:<b>${destino.address.Street}</b> <br /> `;
        // deliveryStr += `Origem LatLng:<b>${travel.origin[0].lat},${travel.origin[0].lng}</b> <br /> `;
        // deliveryStr += `Destin LatLng:<b>${destino.lat},${destino.lng}</b> <br /> `;

        if (destino.deliveryDate) {
          deliveryPoint.bindPopup(deliveryStr);
        } else {
          if (this.props.permitEditarMap) {
            deliveryPoint.bindPopup(ReactDOMServer.renderToString(<BoxPopup travel={travel} detail={deliveryStr} index={index} destinOid={destino.uid} />));
          }
        }
        this.setNewLayer(deliveryPoint);
        this.setNewLayer(circulo);
      });
    } catch (error) {
      console.error(error)
    }
  };

  getIconPinDestino = (travel) => {
    try {
      //entregue com sucesso
      let icon = new Icon({
        iconUrl: pin_blue,
        iconRetinaUrl: pin_blue,
        iconSize: point(20, 20),
        iconAnchor: [15, 15],
      });

      //entregue cem atraso
      if (travel.status_time === TRAVEL_LATE) {
        icon = new Icon({
          iconUrl: pin_red,
          iconRetinaUrl: pin_red,
          iconSize: point(20, 20),
          iconAnchor: [15, 15],
        });
      }

      return icon;
    } catch (error) {
      console.error(error)
    }
    return null
  };
  getIconPinEntregueDestino = (travel) => {
    //entregue com sucesso
    let icon = new Icon({
      iconUrl: pin_green,
      iconRetinaUrl: pin_green,
      iconSize: point(20, 20),
    });

    return icon;
  };

  //mostrar icons conforme o status da viagem
  getTravelIcon = (travel) => {
    let icon = new Icon({
      iconUrl: vehicleIconNormal,
      iconRetinaUrl: vehicleIconNormal,
      iconSize: point(35, 35),
    });

    if (travel.travel_status === CREATED) {
      icon = new Icon({
        iconUrl: vehicleIconNormal,
        iconRetinaUrl: vehicleIconNormal,
        iconSize: point(35, 35),
      });
    } else if (travel.travel_status === CANCELED) {
      icon = new Icon({
        iconUrl: vehicleIconCanceled,
        iconRetinaUrl: vehicleIconCanceled,
        iconSize: point(35, 35),
      });
    } else if (travel.status_time === TRAVEL_ON_TIME) {
      icon = new Icon({
        iconUrl: vehicleIconSuccess,
        iconRetinaUrl: vehicleIconSuccess,
        iconSize: point(35, 35),
      });
    } else if (travel.status_time === TRAVEL_LAG_TREND) {
      icon = new Icon({
        iconUrl: vehicleIconLagTrend,
        iconRetinaUrl: vehicleIconLagTrend,
        iconSize: point(35, 35),
      });
    } else if (travel.status_time === TRAVEL_LATE) {
      icon = new Icon({
        iconUrl: vehicleIconWarning,
        iconRetinaUrl: vehicleIconWarning,
        iconSize: point(35, 35),
      });
    }

    return icon;
  };

  getPostionCarrier = (travel) => {
    try {
      if (travel.origin.length > 0) {
        if (travel.pathTraveled && travel.pathTraveled.length > 0) {
          //pega a posição
          if (travel.pathTraveled[travel.pathTraveled.length - 1].lat) {
            return [travel.pathTraveled[travel.pathTraveled.length - 1].lat, travel.pathTraveled[travel.pathTraveled.length - 1].lng];
          }
        } else {
          return [travel.origin[0].lat, travel.origin[0].lng];
        }
      }
    } catch (error) {
      console.error(error)
    }

    return [];
  };

  

  render() {
    // this.addMarkerRouter();
    const travel = this.props.travels[0]
    return (
      <div style={{ height: "75vh", width: "100%", padding: 0, zIndex: 0, position: "relative" }}>
        {this.props.showLoader && this.loaderComplete && <div className="content-loader">Carregando viagens no Mapa...</div>}
        <div id="map" style={{ height: "100%", width: "100%", padding: 0, zIndex: 0 }} />
       
        <button className="btn-grafico"
          onClick={(e) => {
            this.props.showGraficoTempHora(true);
            e.preventDefault();
            e.stopPropagation();
          }}
          title="Gráfico de temperatura hora a hora"
        >
          <i className="pi pi-chart-line"> </i>
        </button>

        <div>{this.props.graficoTempHoraVisible }</div>
        {this.props.graficoTempHoraVisible &&
          <GraficoTempHora
            onHide={() => {
              this.props.showGraficoTempHora(false);
            }}
            onConfirm={() => {
              this.props.showGraficoTempHora(false);
            }}
            visible={true}
            travel={travel}
          />
        }
      
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Map);
