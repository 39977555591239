import React from 'react';  
import CadastroVeiculosPageContainer from './cadastroVeiculosPageContainer';
 
export class CadastroVeiculosListPage extends React.Component{
    
    render(){
      return (
        <CadastroVeiculosPageContainer {...this.props}/>
      )
    }
}

