
import { validate } from './validations';
import { build } from '../../services/crudFactory';
import { Action } from '../../services/actions.factory';
const BASE_PATH = '/lognex/routes';
const prefix = 'crudRoute';
const crud = build(validate, BASE_PATH);

const newTypes = {
    
    SET_LOOKUP_CARRIER_VISIBLE: `${prefix}setLookupCarrierVisible`,
    SET_LOOKUP_ORIGIN_VISIBLE: `${prefix}setLookupOriginVisible`,
    SET_LOOKUP_DESTINATION_VISIBLE: `${prefix}setLookupDestinationVisible`
}

const actions = new Action(prefix, crud);

const types = Object.assign({}, actions.types, newTypes);

actions.setLookupCarrierVisible = (visible) => {
    return {
        type: types.SET_LOOKUP_CARRIER_VISIBLE,
        visible
    }
}
actions.setLookupOriginVisible = (visible) => {
    return {
        type: types.SET_LOOKUP_ORIGIN_VISIBLE,
        visible
    }
}
actions.setLookupDestinationVisible = (visible) => {
    return {
        type: types.SET_LOOKUP_DESTINATION_VISIBLE,
        visible
    }
}

export { types, actions }