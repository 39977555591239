import { InputText } from 'primereact/inputtext';
import React from 'react';
import { InputCurrency } from "../../../../components/input-currency/InputCurrency";


export const InputConfiguration = (props) => {
    if(props.data.currencyCode){
        return (
            <div className="p-col-3 form-field-container">
                <label>{props.data.title}</label>
                <InputCurrency
                    currencyCode={props.data.currencyCode}
                    value={props.data.value}
                    onChange={props.data.onChange}
                    name={props.data.name}
                    disabled={props.data.disabled}
                ></InputCurrency>
          </div>
        )
    }
    return (
        <div className="p-col-3 form-field-container">
            <label>{props.data.title}</label>
            <InputText
                value={props.data.value}
                onChange={props.data.onChange}
                name={props.data.name}
                disabled={props.data.disabled}
            ></InputText>
      </div>
    )
}