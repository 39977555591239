import React from 'react';

import UnidadeFormPageContainer from './UnidadeFormPageContainer';

export class UnidadeFormPage extends React.Component{

    
    render(){
        return (
           <UnidadeFormPageContainer {...this.props} />
        )
    }
}