import { types } from "./tower_equipment_type.actions";
import { Reducer } from "../../services/reducer.factory";

const initialState = {
  columns: [
    {
      field: "oid",
      header: "Código",
      columnKey: "oid",
      sortable: true,
      filter: true,
    },
    {
      field: "name",
      header: "Nome",
      columnKey: "name",
      sortable: true,
      filter: true,
    },
    {
      field: "eventRecord.createdAt",
      header: "Data Cadastro",
      columnKey: "eventRecord.createdAt",
      sortable: true,
      filter: true,
    },
  ],

  eventName: null,
  lookupEventLognex: {
    visible: false,
    modal: true,
    header: "Evento Lognex",
  },
  eventControl: {
    eventName: "",
  },
  events: {
    columns: [
      {
        field: "event",
        header: "Evento Dispositivo",
        columnKey: "event",
        sortable: true,
        filter: true,
      },
      {
        field: "eventLognex",
        header: "Evento Lognex",
        columnKey: "eventLognex",
        sortable: true,
        filter: true,
      },
    ],
  },

  apiEndpointsColumns: [
    {
      field: "name",
      header: "Nome",
      columnKey: "name",
      sortable: true,
      filter: true,
    },
    {
      field: "url",
      header: "URL",
      columnKey: "url",
      sortable: true,
      filter: true,
    },
    {
      field: "interval_min",
      header: "Intervalo Minutos", //roda a cada 5 minutos
      columnKey: "interval_min",
      sortable: true,
      filter: true,
    },
  ],
  records: [],
};

const reducer = new Reducer(initialState, types);

export function CRUDTowerEquipmentTypeReducer(state, action) {
  state = reducer.execute(state, action);

  return execute(state, action);
}

function execute(state, action) {
  let result = state;
  switch (action.type) {
    case types.SET_NEW_EVENT_NAME:
      return setNewEventName(state, action);
    case types.SET_LOOKUP_EVENT_LOGNEX_VISIBLE:
      return setLookupEventLognexVisible(state, action);
    case types.VINCULA_EVENT_EQUIPMENT_TYPE_LOGNEX_EVENT:
      return vinculaEventEquipmentTypeEventLognex(state, action);
    case types.REMOVE_EVENT_SELECTED:
      return onRemoveEventsSelected(state, action);
    case types.LOAD_ALL:
      return setEquipmentTypes(state, action);
    case types.CUSTOM_LOAD_BY_ID:
      return customLoadById(state, action);
    default:
      return result;
  }
}

function customLoadById(state, action) {
  let currentRecord = state.currentRecord;
  currentRecord = Object.assign({}, currentRecord, action.data);
  return Object.assign({}, state, { currentRecord });
}
 
function setEquipmentTypes(state, action) {
  let records = action.records;
  console.log("records=", records);
  return Object.assign({}, state, { records });
}

function setNewEventName(state, action) {
  return Object.assign({}, state, {
    eventName: action.eventName,
  });
}

function setLookupEventLognexVisible(state, action) {
  let lookupEventLognex = state.lookupEventLognex;
  lookupEventLognex = Object.assign({}, lookupEventLognex, { visible: action.visible });
  return Object.assign({}, state, { lookupEventLognex });
}

function vinculaEventEquipmentTypeEventLognex(state, action) {
  let events = state.currentRecord.events ? state.currentRecord.events : [];
  let newEvent = {
    event: state.currentRecord.eventName,
    eventLognex: state.currentRecord.eventControl.eventName,
  };
  events.push(newEvent);

  const currentRecord = Object.assign({}, state.currentRecord, { events });
  return Object.assign({}, state, { currentRecord });
}
function onRemoveEventsSelected(state, action) {
  let events = state.currentRecord.events ? state.currentRecord.events : [];
  let selectedRecords = state.selectedRecords ? state.selectedRecords : [];

  //remover os registros selecionados
  events = events.filter(
    (ar) => !selectedRecords.find((rm) => rm.event === ar.event && ar.eventLognex === rm.eventLognex)
  );

  const currentRecord = Object.assign({}, state.currentRecord, { events });
  return Object.assign({}, state, { currentRecord, selectedRecords: [] });
}
