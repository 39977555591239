import React from "react";
import "./ApprovedCTEs.scss";

const ApprovedCTEs = (props) => {
  return (
    <div className="approved-reprocess-ctes">
      <div className="btn-ctes">
        <button
          className="lognex-btn-highlighted"
          style={{ width: "100%" }}
          title={props.titleAproved}
          onClick={props.onApprove}
          disabled={!props.visible}
        >
          <i className="pi pi-thumbs-up"></i>
        </button>
      </div>
      <div className="btn-ctes">
        <button
          className="lognex-btn-highlighted"
          style={{ width: "100%" }}
          title={props.titleCancel}
          onClick={props.onReprocess}
          disabled={!props.visible}
        >
          <i className="pi pi-refresh"></i>
        </button>
      </div>
    </div>
  );
};

export default ApprovedCTEs;
