import React from "react";
import { BoxGrid } from "./BoxGrid";

import "./CustomToolbar.scss";

const CustomToolbar = (props) => {

  const footerIndicator = <div></div>;

  function onClickHandler(name) {
    props.onClick(name)
  }
    
  return (
    <div className={"p-grid auditrecord-dashboard"}>
      <div className="p-col-12 p-md-2 row-center">
        <div className="customToolbar-totalizadores">
          <BoxGrid
            id="todos"
            title={"Total"}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              onClickHandler("todos")
            }}
            isSelected={props.viewFilter === "todos"}
            content={props.totalizer.total || 0}
            footer={footerIndicator}
          />
        </div>
      </div>

      <div className="p-col-12  p-md-2 row-center">
        <div className="customToolbar-totalizadores">
          <BoxGrid
            id="pendencies"
            name="pendencies"
            title={"Pendentes"}
            // onClick={props.onClickPendencies}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              onClickHandler("pendencies")
            }}
            isSelected={props.viewFilter === "pendencies"}
            content={props.totalizer.pendencies || 0}
            footer={footerIndicator}
          />
        </div>
      </div>

      <div className="p-col-12  p-md-2 row-center">
        <div className="customToolbar-totalizadores">
          <BoxGrid
            id="divergencies"
            name="divergencies"
            title={"Divergentes"}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              onClickHandler("divergencies")
            }}
            isSelected={props.viewFilter === "divergencies"}
            content={props.totalizer.divergence || 0}
            footer={footerIndicator}
          />
        </div>
      </div>

      <div className="p-col-12  p-md-3 row-center">
        <div className="customToolbar-totalizadores">
          <BoxGrid
            id="withoutReceipt"
            title={"Sem recibo Entrega"}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              onClickHandler("withoutReceipt")
            }}
            isSelected={props.viewFilter === "withoutReceipt"}
            content={props.totalizer.withoutRecept || 0}
            footer={footerIndicator}
          />
        </div>
      </div>

      <div className="p-col-12  p-md-2 row-center">
        <div className="customToolbar-totalizadores">
          <BoxGrid
            id="finalized"
            title={"Finalizados"}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              onClickHandler("finalized")
            }}
            isSelected={props.viewFilter === "finalized"}
            content={props.totalizer.finished}
            footer={footerIndicator}
          />
        </div>
      </div>
    </div>
  );

};

export default CustomToolbar;
