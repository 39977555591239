
import { build } from '../../services/crudFactory';
import { Action } from '../../services/actions.factory';

const prefix = 'crudHistorylog';
const BASE_PATH = '/lognex/logs/historylog'; 
const crud = build(false, BASE_PATH);

const newTypes = {
  SET_CREATE_DATE_FILTER: `${prefix}setCreateDateFilter`,
};

const actions = new Action(prefix, crud);

const types = Object.assign({}, actions.types, newTypes);

actions.setCreateDateFilter = (createdAtFilter) => {
  return {
      type: types.SET_CREATE_DATE_FILTER,
      createdAtFilter
  }
}

export { types, actions }