import { validate } from './validations';
import {build} from '../../services/crudFactory';
import {Action} from '../../services/actions.factory';
import api from '../../services/api.service';
const BASE_PATH = '/lognex/clients';
const crud = build(validate,BASE_PATH);
const prefix = 'crudClient';

const actions = new Action(prefix,crud);

const newTypes = {
    SET_ADDRESS : `${prefix}findByZipCodeRange`,
    SET_LOOKUP_CITIES_VISIBLE: `${prefix}setlookupCitiesVisible`,
    SET_LOOKUP_STATES_VISIBLE: `${prefix}setlookupStatesVisible`,
    SEND_XLSX: `${prefix}sendXlsx`,
    SET_IMPORT_XLSX_RESULT: `${prefix}setImportXLSXResult`
}

const types = Object.assign({}, actions.types, newTypes);

actions.findByZipCodeRange = (dispatch) => {
    api.get('/api/v1/lognex/clients/')
    .then(({ data }) => {
        dispatch({
            type: types.SET_ADDRESS,
            address : data
        }
        )
    });
}

actions.setlookupCitiesVisible = (visible) => {
    return {
        type: types.SET_LOOKUP_CITIES_VISIBLE,
        visible
    }
}

actions.setlookupStatesVisible = (visible) => {
    return {
        type: types.SET_LOOKUP_STATES_VISIBLE,
        visible
    }
}

actions.setAddress = (field,value)=>{
    return {
        type: types.SET_ADDRESS,
        field,
        value
    }
}

actions.sendXLS = () => {
    return {
        type: types.SEND_XLSX,
        async: true
    }
}

actions.setLogImportXLSXResult = (logImportResult)=>{
    return {
        type: types.SET_IMPORT_XLSX_RESULT,
        logImportResult
    }
}


actions.importXLSX = (xlsx)=>{
    return (dispatch)=>{
        const URL = '/api/v1/lognex/clients/import/xls';
         //enviar os dados do arquivo xlsx para o servidor
         dispatch(actions.sendXLS());
        api.uploadFiles(URL,xlsx)
        .then(response =>{
            // atualizar o log de sucesso ou erro no retorno do servidor
            dispatch(actions.setLogImportXLSXResult(response.data))
            dispatch(actions.hideLoader());
            dispatch(actions.load())
        })
        .catch((err)=>{
            // atualizar o log de erro no retorno do servidor
            console.log(err);
            const messages = [
                "Não foi possível processar a planilha."
              ];
              dispatch(
                actions.messageActions.messageShowMessages(actions.makeMessages(messages, "error"))
              );
            dispatch(actions.hideLoader());
        })
       
        
    }
}

export {types, actions};