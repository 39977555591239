import React from "react";
import { Card } from "primereact/card"; 
import { CarrierEquipmentTypePage } from "./list/CarrierEquipmentTypeListPage";

export const CarrierEquipmentTypePanel = (props) => {
  return (
    <Card>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <p id={props.id}> {props.title} </p>
      </div>
      <hr></hr>
      <CarrierEquipmentTypePage />
    </Card>
  );
};
