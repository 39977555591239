
    import React from 'react';
    import PackingListPageContainer from './PackingListPageContainer';
    
    export class PackingListPage extends React.Component{
        
        render(){
          return (
            <PackingListPageContainer {...this.props}/>
          )
        }
    }