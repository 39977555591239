import { validate } from "./validations";
import { build } from "../../services/crudFactory";
import { Action } from "../../services/actions.factory";
import api from "../../services/api.service";
import moment from "moment"; 

const BASE_PATH = "/lognex/dockmanager";
const prefix = "crudDockmanager";
const crud = build(validate, BASE_PATH);

const newTypes = {
  SET_CURRENT_BUSINESSUNIT: `${prefix}setCurrentBusinessUnit`,
  SET_SELECTED_CARRIERS: `${prefix}setSelectedCarriers`,
  SET_SELECTED_DATE: `${prefix}setSelectedDate`,
  FIND_SCHEDULE_BY_FILTERS: `${prefix}findDoksByOwnerFilters`,
  SET_SCHEDULING_RECORDS: `${prefix}setSchedulingRecords`,
  SET_SELECTED_ECHEDULE_EDIT: `${prefix}setEditSelectedSchedule`,
  SET_VISIBLE_MODAL_EDIT_SCHEDULE: `${prefix}setVisibleEditSchedule`,
  SET_SELECTED_HORA_EDIT: `${prefix}setEditHoraSelected`,
  SET_SELECTED_DOCK_EDIT: `${prefix}setEditDockSelected`,
  ON_CONFIRM_SCHEDULE_DOCK: `${prefix}onConfirmScheduleDock`,
  GET_HOURS_SCHEDULE_DOCK: `${prefix}getHorasDisponiveisDoca`,
  ENABLE_LOAD_HOURS_SCHEDULE_DOCK: `${prefix}setLoaderHoursScheduleDoca`,
  SET_SCHEDULE_LIMITE: `${prefix}setScheduleLimites`,
  ON_REMOVE_SCHEDULE_BY_ID: `${prefix}removeScheduleById`,  
};

const actions = new Action(prefix, crud);

const types = Object.assign({}, actions.types, newTypes);

actions.setEditDockSelected = (dockSelected) => {
  return {
    type: types.SET_SELECTED_DOCK_EDIT,
    dockSelected,
  };
};

actions.setEditHoraSelected = (horaSelected) => {
  return {
    type: types.SET_SELECTED_HORA_EDIT,
    horaSelected,
  };
};

actions.setEditSelectedSchedule = (scheduleEdit) => {
  return {
    type: types.SET_SELECTED_ECHEDULE_EDIT,
    scheduleEdit,
  };
};

actions.setVisibleEditSchedule = (visible) => {
  return {
    type: types.SET_VISIBLE_MODAL_EDIT_SCHEDULE,
    visible,
  };
};
actions.setCurrentBusinessUnit = (currentBusinessUnit) => {
  return {
    type: types.SET_CURRENT_BUSINESSUNIT,
    currentBusinessUnit,
  };
};

actions.setSelectedCarriers = (selectedCarriers) => {
  return {
    type: types.SET_SELECTED_CARRIERS,
    selectedCarriers,
  };
};

actions.setSelectedDate = (dateSelected) => {
  return {
    type: types.SET_SELECTED_DATE,
    dateSelected,
  };
};
actions.setSchedulingRecords = (schedulingRecords) => {
  return {
    type: types.SET_SCHEDULING_RECORDS,
    schedulingRecords,
  };
};

actions.setScheduleLimites = (scheduleLimite) => {
  return {
    type: types.SET_SCHEDULE_LIMITE,
    scheduleLimite,
  };
};

actions.findScheduleByOwnerFilters = () => {
  return (dispatch, getState) => {
    const { businessUnit,sigla } = getState().crudDockmanagerState.currentBusinessUnit;
    const scheduleDate = getState().crudDockmanagerState.dateSelected;
    const company = getState().appState.currentCompany.CNPJ;
    const filters = [];

    filters.push({
      campo: "businessUnit.documentNumber",
      valor: businessUnit,
      tipoFiltro: "IGUAL_A",
    });

    // filters.push({
    //   campo: "businessUnit.company",
    //   valor: company,
    //   tipoFiltro: "IGUAL_A",
    // });

    filters.push({
      campo: "scheduleDate",
      valor: scheduleDate,
      tipoFiltro: "IGUAL_A",
    });
    filters.push({
      campo: "businessUnit.sigla",
      valor: sigla,
      tipoFiltro: "IGUAL_A",
    });

    dispatch(actions.showLoader());

    api
      .post("/api/v1/lognex/dockschedule/find-by-filters", { filters })
      .then(({ data }) => {
        dispatch(actions.setSchedulingRecords(data));
        dispatch(actions.hideLoader());
      })
      .catch((err) => {
        console.log(err);
        dispatch(actions.hideLoader());
        dispatch(actions.setSchedulingRecords([]));
      });
  };
};

actions.onConfirmScheduleDock = (dockSchedule) => {
  return (dispatch, getState) => {
    dispatch(actions.showLoader());
    api
      .post("/api/v1/lognex/dockschedule/save", { ...dockSchedule })
      .then((resultData) => {
        if(resultData.data && resultData.data.erro){ 
            const messages = [resultData.data.erro];
            dispatch(actions.messageActions.messageShowMessages(actions.makeMessages(messages, "error"))); 
            dispatch(actions.hideLoader());
        }else{
          dispatch(actions.setSchedulingRecords([]));
          
          dispatch(actions.setEditSelectedSchedule(null));
          dispatch(actions.setVisibleEditSchedule(false));
          dispatch(actions.findScheduleByOwnerFilters());
          dispatch(actions.messageActions.messageShowMessages(actions.makeMessages(["Agendamento realizado com sucesso"], "success")));  
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(actions.hideLoader());
        dispatch(actions.messageActions.messageShowMessages(actions.makeMessages(["Não foi possível criar o registro de agenda, verifique o horário selecionado"], "error")));   
      });
  };
};

actions.getHorasDisponiveisDoca = (dockHourSchedule) => {
  return (dispatch, getState) => {
    const { businessUnit ,sigla} = getState().crudDockmanagerState.currentBusinessUnit;
    dispatch(actions.setLoaderHoursScheduleDoca(true));
    api
      .post("/api/v1/lognex/dockschedule/getAvailableHours", { ...dockHourSchedule, businessUnit,sigla })
      .then(({ data }) => {
        dispatch(actions.setLoaderHoursScheduleDoca(false));
        console.log("data=",data)
        if (data && data.mensagem) {
          const { mensagem = null } = data;

          dispatch(actions.setVisibleEditSchedule(false)); 
          const messages = [mensagem];
          dispatch(actions.messageActions.messageShowMessages(actions.makeMessages(messages, "error")));  
          dispatch(actions.hideLoader());
           
        } else {
          if (data.dateStart) {
            dispatch(actions.setScheduleLimites(data.minuteLeft));
            dispatch(actions.setEditHoraSelected(data.dateStart));
          }
          dispatch(actions.setVisibleEditSchedule(true));
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(actions.setLoaderHoursScheduleDoca(false));
        dispatch(actions.messageActions.messageShowMessages(actions.makeMessages(["Não foi possível criar o registro de agenda"], "error")));  
      });
  };
};

actions.setLoaderHoursScheduleDoca = (isLoadingShedulesHours) => {
  return {
    type: types.ENABLE_LOAD_HOURS_SCHEDULE_DOCK,
    isLoadingShedulesHours,
  };
};

actions.removeScheduleById = (id) => {
  return (dispatch, getState) => { 
    dispatch(actions.showLoader()); 
    api.remove("/api/v1/lognex/dockschedule/"+id)
      .then((response) => {
        dispatch(actions.setVisibleEditSchedule(false));
        dispatch(actions.findScheduleByOwnerFilters()); 
        dispatch(actions.messageActions.messageShowMessages(actions.makeMessages(["Registro excluido com sucesso!"], "success"))); 
      })
      .catch((err) => {
        console.log(err); 
        dispatch(actions.hideLoader()); 
        dispatch(actions.messageActions.messageShowMessages(actions.makeMessages(["Não foi possível excluir o registro!"], "error")));  
      });
  };
}; 

export { types, actions };
