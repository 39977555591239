import React from 'react';
import DashboardPageContainer from './DashboardPageContainer';

export class DashboardPage extends React.Component{
    render(){
        return (
            <DashboardPageContainer {...this.props}/>
        )
    }
}

