import React from "react";
import "./TextSelector.scss";

export class TextSelector extends React.Component {
    
    constructor (props) {
        super(props);
    }

    render(){
        return(
            <div className="text-selector">
                <img className="text-selector__img" src={this.props.icon} onClick={this.props.onActivate}></img>
                <p className="text-selector__text" onClick={this.props.onActivate} > {this.props.text}  </p>                    
            </div>
        );
    }

}
