import React from 'react';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import './LogNFeFilter.scss'

export const LogNFeFilter = (props) => {
    return (
        <div className="log-form">
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="p-grid">
                        <div className="p-col-2 form-field-container">
                        <label>Número do Pedido</label>
                            <InputText value={props.orderFilter} onChange={props.orderFilterChangeHandler} name="orderFilter" />
                        </div>
                        <div className="p-col-3 form-field-container">
                            <label className="lxRequiredField">De</label>
                            <Calendar showIcon={true} value={props.initialDateFilter} showTime={false} onChange={props.onInitialDateChangeHandler} disabled={false} name="initialDateFilter" dateFormat="dd/mm/yy" />
                        </div>
                        <div className="p-col-3 form-field-container">
                            <label className="lxRequiredField">Até</label>
                            <Calendar showIcon={true} value={props.finalDateFilter} showTime={false} onChange={props.onFinalDateChangeHandler} disabled={false} name="finalDateFilter" dateFormat="dd/mm/yy" />
                        </div>
                        
                        <div className="p-col-2 form-field-container">
                            <br />
                            <button
                                className="lognex-btn-highlighted"
                                style={{ width: "100%" }}
                                title="Filtrar"
                                onClick={props.applyLogNFeFilter}>
                                Filtrar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
