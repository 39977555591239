import React from "react";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import Toolbar from "../../../components/toolbar/Toolbar";
import { Column } from 'primereact/column';
import { routes } from '../../../constants';
import { Page } from "../../../components/page/Page";
import { PessoaList } from "./PessoaList";
import { actions } from '../pessoa.actions'
import { AppService, COMPANY_CHANGE_EVENT_NAME, BUSINESS_UNIT_CHANGE_EVENT_NAME } from "../../../services/app.service";
import { actions as appActions } from '../../../store/app';
import * as menuActions from '../../../store/menu/menu.actions';
import * as utils from '../../../utils'

const mapStateToProps = state => {
  const currentMenu = state.menuState.currentRecord;
  const userConfigurations = state.appState.userConfigurations;
  let isFavorite = utils.isFavorite(currentMenu, userConfigurations);
  return {
    columns: state.crudPessoaState.columns,
    records: state.crudPessoaState.records,
    first: state.crudPessoaState.index,
    rows: state.crudPessoaState.max,
    totalRecords: state.crudPessoaState.count,
    sortField: state.crudPessoaState.sortField,
    order: state.crudPessoaState.sortOrder,
    menuItems: state.menuState.items,
    currentMenu,
    isFavorite,
  }
}
const mapDispatchToProps = dispatch => {
  return {
    paginate: paginationParams => dispatch(actions.paginate(paginationParams)),
    sortOrder: sortParams => dispatch(actions.sortOrder(sortParams)),
    applyFilter: filterParams => {
      dispatch(actions.setType(null));
      dispatch(actions.applyFilter(filterParams))
    },
    load: () => dispatch(actions.load()),
    userLastAccess: (oidMenu) => dispatch(appActions.userLastAccess(oidMenu)),
    setCurrentMenu: (menuPath) => dispatch(menuActions.setCurrentRecord(menuPath)),
    toggleFavorite: (isFavorite, oidMenu) => {
      dispatch(appActions.toggleFavorite(isFavorite, oidMenu))
    }
  }
}



class PessoaListPageContainer extends React.Component {

  newHandler = () => {
    this.props.history.push(`${routes.PATH_PESSOA}/novo`)
  }


  componentWillReceiveProps(newProps) {
    if ((!this.props.menuItems || this.props.menuItems.length === 0) && newProps.menuItems && newProps.menuItems.length > 0) {
      const menuPath = this.props.history.location.pathname;
      this.props.setCurrentMenu(menuPath)
    }

    if ((!this.props.currentMenu && newProps.currentMenu) ||
      (this.props.currentMenu !== newProps.currentMenu)) {
      const oidMenu = newProps.currentMenu.oid;
      this.props.userLastAccess(oidMenu)
    }
  }


  componentDidMount() {
    this.props.applyFilter([])
    AppService.on(COMPANY_CHANGE_EVENT_NAME, this);
    AppService.on(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
    const menuPath = this.props.history.location.pathname;
    this.props.setCurrentMenu(menuPath)
  }
  componentWillUnmount() {
    AppService.off(COMPANY_CHANGE_EVENT_NAME, this);
    AppService.off(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
  }

  onReceiveAppNotifications = (eventName, params) => {
    if (eventName === COMPANY_CHANGE_EVENT_NAME || eventName === BUSINESS_UNIT_CHANGE_EVENT_NAME) {
      this.props.applyFilter([])
    }
  }


  onPage = (e) => {
    this.props.paginate({
      max: e.rows,
      index: e.first
    })
  }
  onSort = (e) => {
    this.props.sortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField
    })
  }

  onFilter = (e) => {
    const filters = [];
    const keys = Object.keys(e.filters) || [];
    keys.forEach(key => {
      filters.push({
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: 'CONTEM'

      })
    })
    this.props.applyFilter(filters)

  }

  onRowClick = (e) => {
    const oid = e && e.data && e.data.oid;
    if (oid) {
      this.props.history.push(`${routes.PATH_PESSOA}/${oid}`)
    }
  }

  onFavorite = (e) => {
    if (this.props.currentMenu) {
      const oidMenu = this.props.currentMenu.oid
      const isFavorite = this.props.isFavorite;
      this.props.toggleFavorite(isFavorite, oidMenu)
    }
  }

  render() {

    const formatCnpjOrCpf = (value) => {

      if (!value || value.lenth < 11) {
        return value;
      }
      
      if(!value || value.length == 11){
        const mask = "###.###.###-##"
        let index = 0;
        const data = mask.split('').map(it => {
          if (it === '#') {
            return value[index++]
          } else {
            return it
          }
        });
        let formated = data.join('');
        return formated
      }

      if(!value || value.length == 14){
        const mask = "##.###.###/####-##"
        let index = 0;
        const data = mask.split('').map(it => {
          if (it === '#') {
            return value[index++]
          } else {
            return it
          }
        });
        let formated = data.join('');
        return formated
      }      
    }
    
    
    const cnpjOrCpfTemplate = (rowData, column) => {
      return (
        <span style={{ display: "inline-block", width: "100%", textAlign: "center" }}>{formatCnpjOrCpf(rowData.numeroDocumento)}</span>
      )
    }

    const isFavorite = this.props.isFavorite;
    const header = (
      <div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h1>Pessoas</h1>

          <i className={`pi pi-star lxFavoriteStar ${isFavorite ? 'lxFavorite' : ''}`}
            title={isFavorite ? 'Remover dos favoritos' : 'Adicionar aos favoritos'} onClick={this.onFavorite}></i>
        </div>
        <hr className="lxSplitter" ></hr>
        <div>
          <Toolbar onNew={this.newHandler} newButtonStyle='highlighted'>
          </Toolbar >
        </div>
      </div>
    );
    

    const columns = this.props.columns.map(col => {
      if(col.field === 'numeroDocumento'){
        return (<Column {...col} key={col.field} body={cnpjOrCpfTemplate}/>)
      }
      return (<Column {...col} key={col.field} />)
    })

    const content = <PessoaList  {...this.props} onRowClick={this.onRowClick} onFilter={this.onFilter} onPage={this.onPage} onSort={this.onSort} columns={columns} />;
    return <Page header={header} content={content} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PessoaListPageContainer);
