import api from './api.service';
import storage from './stoage.service';

const isAuthenticated = () => {
    return storage.getToken() != null;
}

const login = (credential) => {
    return api.post('/api/v1/lognex/login', credential)
        .then((response) => {
            const { data } = response;
            if (!data.twoFA) {
                storage.setToken(data.token);
                storage.setUser(data.user);
                storage.setCompany(data.company);
            }
            return Promise.resolve(response);
        })
}

const generateNewCode = (credential) => {
    return api.post('/api/v1/lognex/login/newCodeTwoFa', credential)
        .then((response) => {
            const { data } = response;
            console.log("login generateNewCode=", data)
            return Promise.resolve(response);
        })
}
const validateCode2FA = (credential) => {
    console.log("login validateCodeTwoFa=", credential)
    return api.post('/api/v1/lognex/login/validateCodeTwoFa', credential)
        .then((response) => {
            const { data } = response; 
            storage.setToken(data.token);
            storage.setUser(data.user);
            storage.setCompany(data.company);
            return Promise.resolve(response);
        }).catch((error) => { 
            return Promise.reject(error);
        })
}

const logout = () => {
    return api.get('/api/v1/lognex/logout')
        .then(() => {
            storage.clear()
        })
}

const isLognexUser = () => {
    const user = storage.getUser();
    return user && user.userType === 'LOGNEX';
}

const isCarrierUser = () => {
    //aparentemente não esta sendo utilizado
    const user = storage.getUser();
    return user && user.userType === 'CARRIER';
}
const isShipperUser = () => {
    const user = storage.getUser();
    return user && user.userType === 'SHIPPER';
}

const isCompanyUser = () => {
    const user = storage.getUser();
    return user && user.userType === 'COMPANY';
}

const isUserType = (type) => {
    const user = storage.getUser();
    return user && user.userType === type;
}

const getDefaultCompany = () => {
    return storage.getCompany();
}

const getUserName = () => {

    const user = storage.getUser();
    return user ? user.login : '';
}

const getUserOid = () => {
    const user = storage.getUser();
    return user ? user.oid : null;
}

const getCarrier = () => {
    const user = storage.getUser();
    if (user && user.carrier) {
        return user.carrier;
    }
    return null
}


export default {
    isLognexUser,
    isShipperUser,
    isCompanyUser,
    isCarrierUser,
    isUserType,
    isAuthenticated,
    login,
    logout,
    getDefaultCompany,
    getUserName,
    getUserOid,
    getCarrier,
    generateNewCode,
    validateCode2FA
}

