import { types } from "./portalCarrier.actions";
import { Reducer } from "../../services/reducer.factory";
import { ORIGEM_PORTAL_TRANSPORTADOR } from "../towerDashboard/constants";
import moment from "moment";
import { CARGO_IN_TRANSITO, CARGO_SHIP_DELIVERED } from "./constantes";

const initialState = {
  collectionOrdersPendentAceite: 0,
  totalPendencias: 0,
  totalEntregas: 0,
  totalMensagens: 0,
  totalAgendamentos: 0,
  totalConfirmadas: 0, 
  totalVeiculosCadastrados: 0,
  totalVeiculosViagens: 0,
  totalInCollectionOrder: 0,
  totalInTravel: 0,
  totalRejected: 0,

  filters: [],
  collectionOrdersPendeceAceite: [],
  columnsCollectionOrdersPendeceAceite: [
    {
      field: "oid",
      header: "ID",
      columnKey: "oid",
      sortable: false,
      filter: false
    },
    {
      field: "collectionOrder.origin.name",
      header: "Origem",
      columnKey: "collectionOrder.origin.name",
      sortable: false,
      filter: false
    },
    {
      field: "collectionOrder.destination.name",
      header: "Destino",
      columnKey: "collectionOrder.destination.name",
      sortable: false,
      filter: false
    },
    {
      field: "collectionOrder.pickupDate",
      header: "Data Coleta",
      columnKey: "collectionOrder.pickupDate",
      sortable: false,
      filter: false
    },
    {
      field: "collectionOrder.vehicleType",
      header: "Veículo",
      columnKey: "collectionOrder.vehicleType",
      sortable: false,
      filter: false
    },
    {
      field: "collectionOrder.cargo.weight",
      header: "Peso",
      columnKey: "collectionOrder.cargo.weight",
      sortable: false,
      filter: false
    },
    {
      field: "totalValueProduct",
      header: "Valor Produtos",
      columnKey: "totalValueProduct",
      sortable: false,
      filter: false
    },

    {
      field: "qtdeTotalSacos",
      header: "Sacos",
      columnKey: "qtdeTotalSacos",
      sortable: false,
      filter: false
    },
    {
      field: "qtdeTotalPallets",
      header: "Pallets",
      columnKey: "qtdeTotalPallets",
      sortable: false,
      filter: false
    },
    {
      field: "freightValue",
      header: "Custo do frete",
      columnKey: "freightValue",
      sortable: false,
      filter: false
    },
    {
      field: "distance",
      header: "Distância",
      columnKey: "distance",
      sortable: false,
      filter: false
    },
    {
      field: "darAceite",
      header: "Aceite",
      columnKey: "darAceite",
      sortable: false,
      filter: false
    },
    {
      field: "btnViewOnMap",
      header: "Mapa",
      columnKey: "btnViewOnMap",
      sortable: false,
      filter: false
    }
  ],
  columnsCargoShipmentDelivered: [
    {
      field: "oid",
      header: "ID",
      columnKey: "oid",
      sortable: false,
      filter: false
    },
    {
      field: "collectionOrder.origin.name",
      header: "Origem",
      columnKey: "collectionOrder.origin.name",
      sortable: false,
      filter: false
    },
    {
      field: "collectionOrder.destination.name",
      header: "Destino",
      columnKey: "collectionOrder.destination.name",
      sortable: false,
      filter: false
    },
    {
      field: "collectionOrder.pickupDate",
      header: "Data Coleta",
      columnKey: "collectionOrder.pickupDate",
      sortable: false,
      filter: false
    },
    {
      field: "collectionOrder.vehicleType",
      header: "Veículo",
      columnKey: "collectionOrder.vehicleType",
      sortable: false,
      filter: false
    },
    {
      field: "collectionOrder.cargo.weight",
      header: "Peso",
      columnKey: "collectionOrder.cargo.weight",
      sortable: false,
      filter: false
    },
    {
      field: "collectionOrder.createUser",
      header: "Criado por Usuario",
      columnKey: "collectionOrder.createUser",
      sortable: false,
      filter: false
    },
    {
      field: "collectionOrder.acceptUser",
      header: "Aceito por Usuario",
      columnKey: "collectionOrder.acceptUser",
      sortable: false,
      filter: false
    },
    {
      field: "collectionOrder.acceptAt",
      header: "Data aceite",
      columnKey: "collectionOrder.acceptAt",
      sortable: false,
      filter: false
    },
    {
      field: "qtdeTotalPallets",
      header: "Pallets",
      columnKey: "qtdeTotalPallets",
      sortable: false,
      filter: false
    },
    {
      field: "freightValue",
      header: "Custo do frete",
      columnKey: "freightValue",
      sortable: false,
      filter: false
    },
    {
      field: "btnViewOnMap",
      header: "Mapa",
      columnKey: "btnViewOnMap",
      sortable: false,
      filter: false
    }
  ],

  expandedRows: [],
  columnsRowTreeviewTemplate: [
    {
      field: "type",
      header: "Tipo",
      columnKey: "type",
      sortable: false,
      filter: false
    },
    {
      field: "cd",
      header: "CD",
      columnKey: "cd",
      sortable: false,
      filter: false
    },
    {
      field: "tomadorFrete",
      header: "Tomador Frete",
      columnKey: "tomadorFrete",
      sortable: false,
      filter: false
    },
    {
      field: "fullAddress",
      header: "Destino",
      columnKey: "fullAddress",
      sortable: false,
      filter: false
    },
    {
      field: "code",
      header: "Pedido",
      columnKey: "code",
      sortable: false,
      filter: false
    }, {
      field: "situation",
      header: "Situação",
      columnKey: "situation",
      sortable: false,
      filter: false
    },
    {
      field: "previsionDate",
      header: "Previsão de Entrega",
      columnKey: "previsionDate",
      sortable: false,
      filter: false
    },
    {
      field: "qtdeSacos",
      header: "Sacos",
      columnKey: "qtdeSacos",
      sortable: false,
      filter: false
    },
    {
      field: "qtdePallets",
      header: "Pallets",
      columnKey: "qtdePallets",
      sortable: false,
      filter: false
    },
    {
      field: "apportionmentValue",
      header: "Valor Frete",
      columnKey: "apportionmentValue",
      sortable: false,
      filter: false
    },
  ],

  columnsRowTreeviewTransfTemplate: [
    {
      field: "type",
      header: "Tipo",
      columnKey: "type",
      sortable: false,
      filter: false
    },
    {
      field: "orderCodeTransfer",
      header: "Pedido",
      columnKey: "orderCodeTransfer",
      sortable: false,
      filter: false
    },
    {
      field: "code",
      header: "Pedido Venda",
      columnKey: "code",
      sortable: false,
      filter: false
    },
    {
      field: "fullAddress",
      header: "Destino",
      columnKey: "fullAddress",
      sortable: false,
      filter: false
    },
    {
      field: "apportionmentValue",
      header: "Valor do Frete",
      columnKey: "apportionmentValue",
      sortable: false,
      filter: false
    },

  ],

  openDialogIframeVisible: false,
  link: null,

  lookupCarrier: {
    visible: false,
    modal: true,
    header: "Transportadora",
  },
  carrier: {
    tradeName: "",
    name: "",
    documentNumber: "",
  },
  // origin:"",
  collectionOrderWithPendences: [],
  currentScreem: "",
  columnsCollectionOrdersWithPendeces: [
    {
      field: "oid",
      header: "ID",
      columnKey: "oid",
      sortable: false,
      filter: false
    },
    {
      field: "status",
      header: "Status",
      columnKey: "status",
      sortable: false,
      filter: false
    },
    {
      field: "collectionOrder.origin.name",
      header: "Origem",
      columnKey: "collectionOrder.origin.name",
      sortable: false,
      filter: false
    },
    {
      field: "collectionOrder.destination.name",
      header: "Destino",
      columnKey: "collectionOrder.destination.name",
      sortable: false,
      filter: false
    },
    {
      field: "collectionOrder.vehicleType",
      header: "Veículo",
      columnKey: "collectionOrder.vehicleType",
      sortable: false,
      filter: false
    },
    {
      field: "freightValue",
      header: "Custo do frete",
      columnKey: "freightValue",
      sortable: false,
      filter: false
    },
    {
      field: "collectionOrder.plate",
      header: "Placa",
      columnKey: "collectionOrder.plate",
      sortable: false,
      filter: false
    },
    {
      field: "collectionOrder.driver.name",
      header: "Nome Motorista",
      columnKey: "collectionOrder.driver.name",
      sortable: false,
      filter: false
    },
    {
      field: "collectionOrder.driver.documentNumber",
      header: "Documento Motorista",
      columnKey: "collectionOrder.driver.documentNumber",
      sortable: false,
      filter: false
    },
    {
      field: "darAceite",
      header: "Atualizar",
      columnKey: "darAceite",
      sortable: false,
      filter: false
    },
    {
      field: "btnViewOnMap",
      header: "Mapa",
      columnKey: "btnViewOnMap",
      sortable: false,
      filter: false
    }
  ],
  cargoShipStatusInOrderColeta: [],

  columnsShipStatusInOrderColeta: [
    {
      field: "oid",
      header: "ID",
      columnKey: "oid",
      sortable: false,
      filter: false
    },
    {
      field: "pickupDate",
      header: "Data Coleta",
      columnKey: "pickupDate",
      sortable: false,
      filter: false
    },
    {
      field: "deliveryForeCastDate",
      header: "Previsão entrega",
      columnKey: "deliveryForeCastDate",
      sortable: false,
      filter: false
    },
    {
      field: "status",
      header: "Status",
      columnKey: "status",
      sortable: false,
      filter: false
    },
    {
      field: "originName",
      header: "Origem",
      columnKey: "originName",
      sortable: false,
      filter: false
    },
    {
      field: "destinationName",
      header: "Destino",
      columnKey: "destinationName",
      sortable: false,
      filter: false
    },
    {
      field: "vehicleType",
      header: "Veículo",
      columnKey: "vehicleType",
      sortable: false,
      filter: false
    },
    {
      field: "freightValue",
      header: "Custo do frete",
      columnKey: "freightValue",
      sortable: false,
      filter: false
    },
    {
      field: "plate",
      header: "Placa",
      columnKey: "plate",
      sortable: false,
      filter: false
    },
    {
      field: "isAg",
      header: "Situação",
      columnKey: "isAg",
      sortable: false,
      filter: false
    },
    {
      field: "driverName",
      header: "Nome Motorista",
      columnKey: "driverName",
      sortable: false,
      filter: false
    },
    {
      field: "driverDocumentNumber",
      header: "Documento Motorista",
      columnKey: "driverDocumentNumber",
      sortable: false,
      filter: false
    },
    {
      field: "darAceite",
      header: "Alterar veículo",
      columnKey: "darAceite",
      sortable: false,
      filter: false
    },
    {
      field: "btnToTransito",
      header: "Mudar para Transito",
      columnKey: "btnToTransito",
      sortable: false,
      filter: false
    },
    {
      field: "btnViewOnMap",
      header: "Mapa",
      columnKey: "btnViewOnMap",
      sortable: false,
      filter: false
    }
  ],

  //IN TRANSITO
  columnsShipStatusInTransito: [
    {
      field: "oid",
      header: "ID",
      columnKey: "oid",
      sortable: false,
      filter: false
    },
    {
      field: "pickupDate",
      header: "Data Coleta",
      columnKey: "pickupDate",
      sortable: false,
      filter: false
    },
    {
      field: "deliveryForeCastDate",
      header: "Previsão entrega",
      columnKey: "deliveryForeCastDate",
      sortable: false,
      filter: false
    },
    {
      field: "status",
      header: "Status",
      columnKey: "status",
      sortable: false,
      filter: false
    },
    {
      field: "originName",
      header: "Origem",
      columnKey: "originName",
      sortable: false,
      filter: false
    },
    {
      field: "destinationName",
      header: "Destino",
      columnKey: "destinationName",
      sortable: false,
      filter: false
    },
    {
      field: "vehicleType",
      header: "Veículo",
      columnKey: "vehicleType",
      sortable: false,
      filter: false
    },
    {
      field: "freightValue",
      header: "Custo do frete",
      columnKey: "freightValue",
      sortable: false,
      filter: false
    },
    {
      field: "plate",
      header: "Placa",
      columnKey: "plate",
      sortable: false,
      filter: false
    },
    {
      field: "driverName",
      header: "Nome Motorista",
      columnKey: "driverName",
      sortable: false,
      filter: false
    },
    {
      field: "driverDocumentNumber",
      header: "Documento Motorista",
      columnKey: "driverDocumentNumber",
      sortable: false,
      filter: false
    },
    {
      field: "darAceite",
      header: "Alterar veículo",
      columnKey: "darAceite",
      sortable: false,
      filter: false
    },
    {
      field: "btnViewOnMap",
      header: "Mapa",
      columnKey: "btnViewOnMap",
      sortable: false,
      filter: false
    }
  ],
  cargoShipStatusInTransito: [],

  columnsRowTreeviewInTransitoTemplate: [

    {
      field: "code",
      header: "Pedido",
      columnKey: "code",
      sortable: false,
      filter: false
    },
    {
      field: "nroNfe",
      header: "Nro NFe",
      columnKey: "nroNfe",
      sortable: false,
      filter: false
    },
    {
      field: "shipmentCode",
      header: "Cód Shipment",
      columnKey: "shipmentCode",
      sortable: false,
      filter: false
    },
    {
      field: "tomadorFrete",
      header: "Tomador Frete",
      columnKey: "tomadorFrete",
      sortable: false,
      filter: false
    },
    {
      field: "originName",
      header: "Origem",
      columnKey: "originName",
      sortable: false,
      filter: false
    },
    {
      field: "destinationName",
      header: "Destino",
      columnKey: "destinationName",
      sortable: false,
      filter: false
    },
    {
      field: "occurrence.dateOccurrence",
      header: "Data Entrega",
      columnKey: "occurrence.dateOccurrence",
      sortable: false,
      filter: false
    },
    {
      field: "occurrence.pickupDate",
      header: "Data da Coleta",
      columnKey: "occurrence.pickupDate",
      sortable: false,
      filter: false
    },
    {
      field: "occurrence.description",
      header: "Observação",
      columnKey: "occurrence.description",
      sortable: false,
      filter: false
    },
    {
      field: "occurrence.image",
      header: "Imagem canhoto",
      columnKey: "occurrence.image",
      sortable: false,
      filter: false
    },
    {
      field: "btnCanhoto",
      header: "Alterar",
      columnKey: "btnCanhoto",
      sortable: false,
      filter: false
    },
  ],

  //canhoto
  fileImg: null,
  currentOrderEdit: {
    observation: "",
    deliveryDate: null,
    pickupDate: null,
    deliverySuccess: null,
    dv: false,
    clientNaoPossuiMaterial: false,
    product: [],
  },
  observation: "",
  deliveryDate: null,
  pickupDate: null,
  showModalUploadImg: false,
  linkCanhoto: "",
  //canhoto

  //DELIVERED
  cargoShipStatusDelivereds: [],

  //campos filtros
  delivery: "",
  nroNfe: "",
  codeShipment: "",
  nroEmbarque: "",

  //cadastro veiculos
  cadVeiculoPlate: "",
  cadVeiculoDescription: "",
  cadastroVeiculos: [],

  columnsCadastroVeiculos: [
    {
      field: "oid",
      header: "ID",
      columnKey: "oid",
      sortable: false,
      filter: false
    },
    {
      field: "eventRecord.createdAt",
      header: "Data Cadastro",
      columnKey: "eventRecord.createdAt",
      sortable: false,
      filter: false
    },
    {
      field: "plate",
      header: "Placa",
      columnKey: "plate",
      sortable: false,
      filter: false
    },
    {
      field: "description",
      header: "Descrição",
      columnKey: "description",
      sortable: false,
      filter: false
    },
  ],
  //cadastro veiculos

  columnsProducts: [
    {
      field: "name",
      header: "Material",
      columnKey: "name",
      sortable: false,
      filter: false
    },
    {
      field: "batchNumber",
      header: "Lote",
      columnKey: "batchNumber",
      sortable: false,
      filter: false
    },
    {
      field: "packingName",
      header: "Embalagem",
      columnKey: "packingName",
      sortable: false,
      filter: false
    },
    {
      field: "quantity",
      header: "Qtde",
      columnKey: "quantity",
      sortable: false,
      filter: false
    },
    {
      field: "quantityCollected",
      header: "Quantidade Coletada",
      columnKey: "quantityCollected",
      sortable: false,
      filter: false
    },
    {
      field: "btnRemove",
      header: "Remover",
      columnKey: "btnRemove",
      sortable: false,
      filter: false
    },
  ],

  dialogMapViewRouteVisible:false,
  idEmbarqueShowMap:null,

  collectionOrderRejected: [],
  columnsCollectionOrdersRejected: [
    {
      field: "oid",
      header: "ID",
      columnKey: "oid",
      sortable: false,
      filter: false
    },
    {
      field: "status",
      header: "Status",
      columnKey: "status",
      sortable: false,
      filter: false
    },
    {
      field: "freightValue",
      header: "Custo do frete",
      columnKey: "freightValue",
      sortable: false,
      filter: false
    },{
      field: "pickupDate",
      header: "Data Coleta",
      columnKey: "pickupDate",
      sortable: false,
      filter: false
    },{
      field: "originName",
      header: "Origem",
      columnKey: "pickupDate",
      sortable: false,
      filter: false
    },{
      field: "destinationName",
      header: "Destino",
      columnKey: "destinationName",
      sortable: false,
      filter: false
    }, 
    {
      field: "isAg",
      header: "AG",
      columnKey: "isAg",
      sortable: false,
      filter: false
    },
    {
      field: "isDv",
      header: "Devolucao",
      columnKey: "isDv",
      sortable: false,
      filter: false
    },
    {
      field: "btnViewOnMap",
      header: "Mapa",
      columnKey: "btnViewOnMap",
      sortable: false,
      filter: false
    }
  ],
  columnsRowTreeviewRejectedTemplate:[
    {
      field: "status",
      header: "Status",
      columnKey: "status",
      sortable: false,
      filter: false
    }, 
    {
      field: "vehicleType",
      header: "Veículo",
      columnKey: "vehicleType",
      sortable: false,
      filter: false
    },
    {
      field: "pickupDate",
      header: "Data Coleta",
      columnKey: "pickupDate",
      sortable: false,
      filter: false
    }, {
      field: "cargo.Weight",
      header: "Peso",
      columnKey: "cargo.Weight",
      sortable: false,
      filter: false
    }, {
      field: "createAt",
      header: "Data registro",
      columnKey: "createAt",
      sortable: false,
      filter: false
    },{
      field: "rejectedAt",
      header: "Data Recusa",
      columnKey: "rejectedAt",
      sortable: false,
      filter: false
    },{
      field: "createUser",
      header: "Usuario Criou",
      columnKey: "createUser",
      sortable: false,
      filter: false
    },{
      field: "rejetedUser",
      header: "Usuario Recusou",
      columnKey: "rejetedUser",
      sortable: false,
      filter: false
    }     
  ]
 ,
  initialDate: moment().startOf("year").toDate(),
  finalDate: moment().toDate(),
};
const reducer = new Reducer(initialState, types);

export function CRUDPortalCarrierReducer(state, action) {
  state = reducer.execute(state, action);
  return execute(state, action);
}

function execute(state, action) {
  let result = state;
  switch (action.type) {

    case types.SET_COUNT_COLLECTION_ORDERS:
      return setCountCollectionOrders(state, action);
    case types.SET_FILTERS:
      return setFilters(state, action);
    case types.SET_RECORDS_COLLECTION_ORDERS:
      return setRecordsCollectionOrders(state, action);
    case types.SET_EXPANDED_ROW:
      return setExpandedRows(state, action);
    case types.OPEN_DIOLOG_IFRAME_COLLECTION_ORDER:
      return openDialogIframeCollectionOrder(state, action);
    case types.SET_COUNT_COLLECTION_ORDERS_CONFIRMED:
      return setCountCollectionOrdersConfirmadas(state, action);
    case types.SET_LOOKUP_CARRIER_VISIBLE:
      return setLookupCarrierVisible(state, action);
    case types.SET_CARRIER_FILTER:
      return setCarrierFilter(state, action);
    case types.SET_ORIGEM:
      return setOrigem(state, action);
    case types.SET_COUNT_COLLECTION_ORDERS_WITH_PENDENCES:
      return setCountCollectionOrderWithPendences(state, action);
    case types.SET_COLLECTION_ORDERS_WITH_PENDENCES:
      return setCollectionOrderWithPendences(state, action);
    case types.SET_CURRENT_SCREEN:
      return setCurrentScreen(state, action);
    case types.SET_COUNT_IN_COLLECTION_ORDERS:
      return setCountInCollectionOrder(state, action);
    case types.SET_COUNT_IN_TRAVEL:
      return setCountInTravel(state, action);
    case types.SET_SHIP_STATUS_IN_COLLECTION_ORDERS:
      return setAllInCollectionOrder(state, action);
    case types.SET_SHIP_STATUS_IN_TRANSITO:
      return setRecordsCargoShiptmentInTrasito(state, action);
    case types.SET_SHOW_MODAL_UPLOAD_IMG:
      return setShowModalUploadImg(state, action);
    case types.SET_OBSERVATION:
      return onChangeObs(state, action);
    case types.ON_CHANGE_HANDLE_IMAGE:
      return changeHandlerImage(state, action);
    case types.SET_DELIVERY_DATE:
      return onChangeDeliveryDate(state, action);
    case types.SET_PICKUP_DATE:
      return onChangePickupDate(state, action);
    case types.SET_CODE_ORDER:
      return setCodeOrder(state, action);
    case types.SET_LINK_CANHOTO:
      return setLinkAddCanhoto(state, action);
    case types.SET_UPDATE_INFO_PEDIDO:
      return updatePedidoInfo(state, action);
    case types.SET_SHIP_STATUS_DELIVERED:
      return setRecordsCargoShipmentDelivered(state, action);
    case types.SET_COUNT_SHIP_STATUS_DELIVERED:
      return setCountCargoShipmentDelivered(state, action);
    case types.SET_DELIVERY_FILTER:
      return setDeliveryFilter(state, action);
    case types.SET_NRO_NFE_FILTER:
      return setNroNfeFilter(state, action);
    case types.SET_CODE_SHIPMENT_FILTER:
      return setCodeShipmentFilter(state, action);
    case types.SET_NRO_EMBARQUE_FILTER:
      return setNroEmbarqueFilter(state, action);
    case types.SET_CAD_VEICULO_PLATE:
      return setCadVeiculoPlate(state, action);
    case types.SET_CAD_VEICULO_DESCRICAO:
      return setCadVeiculoDescricao(state, action);
    case types.SET_RECORD_CAD_VEICULOS:
      return setRecordsVehicles(state, action);
    case types.ON_CHANGE_CLIENT_N_POSSUI_MATERIAL:
      return onChangeClientNaoPossuiMaterial(state, action);
    case types.SET_VISIBLE_MAP_VIEW_ROUTE:
      return setVisibleDialogMapViewRoute(state, action);
    case types.SET_ID_EMBARQ_MAP_VIEW_ROUTE:
      return setIdEmbarToViewRouteOnMap(state, action);
    case types.SET_COLLECTION_ORDERS_REJECTED:
      return setCollectionOrderRejected(state, action);
    case types.SET_COUNT_COLLECTION_ORDERS_REJECTED:
      return setCountCollectionOrderRejected(state, action);

    case types.SET_INITIAL_DATE_FILTER:
      return setInitialDateFilter(state, action);
    case types.SET_FINAL_DATE_FILTER:
      return setFinalDateFilter(state, action);
    default:
      return result;
  }
} 
function setInitialDateFilter(state, action) {
  let initialDate = state.initialDate;
  initialDate = action.initialDateFilter;
  return { ...state, initialDate };
}

function setFinalDateFilter(state, action) {
  let finalDate = state.finalDate;
  finalDate = action.finalDateFilter;
  return { ...state, finalDate };
}
function setCountCollectionOrderRejected(state, action) {
  state = Object.assign({}, state, { totalRejected: action.total });
  return state;
}
function setIdEmbarToViewRouteOnMap(state, action) {
  state = Object.assign({}, state, { idEmbarqueShowMap: action.idEmbarqueShowMap });
  return state;
}
 
function onChangeClientNaoPossuiMaterial(state, action) {

  let currentOrderEdit = state.currentOrderEdit
  if (currentOrderEdit) {
    currentOrderEdit.clientNaoPossuiMaterial = action.clientNaoPossuiMaterial;   
    state = { ...state, currentOrderEdit,observation:currentOrderEdit.observation };
  }
  return state;
}

function setRecordsVehicles(state, action) {
  state = Object.assign({}, state, { cadastroVeiculos: action.cadastroVeiculos });
  return state;
}

function setVisibleDialogMapViewRoute(state, action) {
  state = Object.assign({}, state, { dialogMapViewRouteVisible: action.visible });
  return state;
}

function setCadVeiculoDescricao(state, action) {
  state = Object.assign({}, state, { cadVeiculoDescription: action.description });
  return state;
}
function setCadVeiculoPlate(state, action) {
  state = Object.assign({}, state, { cadVeiculoPlate: action.plate });
  return state;
}
function setNroEmbarqueFilter(state, action) {
  state = Object.assign({}, state, { nroEmbarque: action.nroEmbarque });
  return state;
}
function setCodeShipmentFilter(state, action) {
  state = Object.assign({}, state, { codeShipment: action.codeShipment });
  return state;
}
function setNroNfeFilter(state, action) {
  state = Object.assign({}, state, { nroNfe: action.nroNfe });
  return state;
}
function setDeliveryFilter(state, action) {
  state = Object.assign({}, state, { delivery: action.delivery });
  return state;
}
function setCountCargoShipmentDelivered(state, action) {
  state = Object.assign({}, state, { totalEntregas: action.total });
  return state;
}

function setRecordsCargoShipmentDelivered(state, action) {
  state = Object.assign({}, state, { cargoShipStatusDelivereds: action.records });
  return state;
}

function updatePedidoInfo(state, action) {
  if (state.expandedRows && Array(state.expandedRows).length > 0) {
    let rowSeletect = state.expandedRows[0];
    const { link, code, deliveryDate, observation, file, deliverySuccess, pickupDate, product, clientNaoPossuiMaterial } = action.dados;

    //verificar se esta na tela de entregas realizadas ou em transito
    if (state.currentScreen == CARGO_IN_TRANSITO) {
      let cargoShipStatusInTransito = state.cargoShipStatusInTransito;
      let indice = -1;
      for (let index = 0; index < cargoShipStatusInTransito.length; index++) {
        if (cargoShipStatusInTransito[index].oid == rowSeletect.oid) {
          indice = index;
          break
        }
      }

      if (indice > -1) {

        for (let index = 0; index < cargoShipStatusInTransito[indice].orders.length; index++) {
          if (cargoShipStatusInTransito[indice].orders[index].code == code) {
            //occurrence.description
            cargoShipStatusInTransito[indice].orders[index].occurrence = {
              "code": 9999,
              "description": observation,
              "orderCode": code,
              "dateOccurrence": deliveryDate,
              "pickupDate": pickupDate,
              "image": file,
              "deliverySuccess": deliverySuccess,
              "product": product,
              "clientNaoPossuiMaterial": clientNaoPossuiMaterial
            }
          }
        }
      }

      state = Object.assign({}, state, { cargoShipStatusInTransito });
    } else if (state.currentScreen == CARGO_SHIP_DELIVERED) {

      let cargoShipStatusDelivereds = state.cargoShipStatusDelivereds;
      let indice = -1;
      for (let index = 0; index < cargoShipStatusDelivereds.length; index++) {
        if (cargoShipStatusDelivereds[index].oid == rowSeletect.oid) {
          indice = index;
          break
        }
      }

      if (indice > -1) {

        for (let index = 0; index < cargoShipStatusDelivereds[indice].orders.length; index++) {
          if (cargoShipStatusDelivereds[indice].orders[index].code == code) {
            //occurrence.description
            cargoShipStatusDelivereds[indice].orders[index].occurrence = {
              "code": 9999,
              "description": observation,
              "orderCode": code,
              "dateOccurrence": deliveryDate,
              "pickupDate": pickupDate,
              "image": file,
              "deliverySuccess": deliverySuccess,
              "product": product,
              "clientNaoPossuiMaterial": clientNaoPossuiMaterial
            }
          }
        }
      }
      state = Object.assign({}, state, { cargoShipStatusDelivereds });
    }
  }
  return state;
}
function setLinkAddCanhoto(state, action) {
  state = Object.assign({}, state, { linkCanhoto: action.linkCanhoto });
  return state;
}
function setCodeOrder(state, action) {
  let currentOrderEdit = action.order;
  const deliveryDate = currentOrderEdit && currentOrderEdit.occurrence && currentOrderEdit.occurrence.dateOccurrence ? moment(currentOrderEdit.occurrence.dateOccurrence).toDate() : "";
  const pickupDate = currentOrderEdit && currentOrderEdit.occurrence && currentOrderEdit.occurrence.pickupDate ? moment(currentOrderEdit.occurrence.pickupDate).toDate() : "";
  const observation = currentOrderEdit && currentOrderEdit.occurrence && currentOrderEdit.occurrence.description ? currentOrderEdit.occurrence.description : "";
  const fileImg = currentOrderEdit && currentOrderEdit.occurrence && currentOrderEdit.occurrence.image ? currentOrderEdit.occurrence.image : "";
  const deliverySuccess = currentOrderEdit && currentOrderEdit.occurrence ? currentOrderEdit.occurrence.deliverySuccess : null;
  const clientNaoPossuiMaterial = currentOrderEdit && currentOrderEdit.occurrence ? currentOrderEdit.occurrence.clientNaoPossuiMaterial : false;
  const isDv = currentOrderEdit && currentOrderEdit.dv ? true : false;

  let product = [];
  //pega da order
  if (currentOrderEdit && currentOrderEdit.product) {
    product = currentOrderEdit.product
  }
  //se ja tiver ocorrencia, pegar da ocorrencia
  if (currentOrderEdit && currentOrderEdit.occurrence && currentOrderEdit.occurrence.product && currentOrderEdit.occurrence.product.length > 0) {
    product = currentOrderEdit.occurrence.product
  }

  if (currentOrderEdit) {
    currentOrderEdit.observation = observation;
    currentOrderEdit.deliveryDate = deliveryDate;
    currentOrderEdit.pickupDate = pickupDate;
    currentOrderEdit.dv = isDv;//devolucao
    currentOrderEdit.clientNaoPossuiMaterial = clientNaoPossuiMaterial;//devolucao
    //criar um ID para cada produto
    product = product.map((p, index) => {
      return { ...p, id: index }
    })
    currentOrderEdit.product = product;
    if (deliveryDate) {
      if (deliverySuccess == null) {
        currentOrderEdit.deliverySuccess = true;
      } else {
        currentOrderEdit.deliverySuccess = deliverySuccess;
      }
    }
  }
  return { ...state, currentOrderEdit, observation, deliveryDate, pickupDate, fileImg };
}

function setShowModalUploadImg(state, action) {
  state = Object.assign({}, state, { showModalUploadImg: action.visible });
  return state;
}

function onChangeObs(state, action) {
  let currentOrderEdit = state.currentOrderEdit;
  currentOrderEdit.observation = action.obs;
  state = Object.assign({}, state, { currentOrderEdit, observation: action.obs });
  return state;
}
function changeHandlerImage(state, action) {
  state = Object.assign({}, state, { fileImg: action.fileImg });
  return state;
}

function onChangeDeliveryDate(state, action) {
  let currentOrderEdit = state.currentOrderEdit;
  currentOrderEdit.deliveryDate = action.deliveryDate;
  state = Object.assign({}, state, { currentOrderEdit, deliveryDate: action.deliveryDate });
  return state;
}
function onChangePickupDate(state, action) {
  let currentOrderEdit = state.currentOrderEdit;
  currentOrderEdit.pickupDate = action.pickupDate;
  state = Object.assign({}, state, { currentOrderEdit, pickupDate: action.pickupDate });
  return state;
}


function setRecordsCargoShiptmentInTrasito(state, action) {
  state = Object.assign({}, state, { cargoShipStatusInTransito: action.records });
  return state;
}
function setAllInCollectionOrder(state, action) {
  state = Object.assign({}, state, { cargoShipStatusInOrderColeta: action.records });
  return state;
}

function setCountInTravel(state, action) {
  state = Object.assign({}, state, { totalInTravel: action.total });
  return state;
}

function setCountInCollectionOrder(state, action) {
  state = Object.assign({}, state, { totalInCollectionOrder: action.total });
  return state;
}


function setCurrentScreen(state, action) {
  state = Object.assign({}, state, { currentScreen: action.currentScreen });
  return state;
}

function setCollectionOrderWithPendences(state, action) {
  state = Object.assign({}, state, { collectionOrderWithPendences: action.records });
  return state;
}
function setCollectionOrderRejected(state, action) {
  state = Object.assign({}, state, { collectionOrderRejected: action.records });
  return state;
}

function setCountCollectionOrderWithPendences(state, action) {
  state = Object.assign({}, state, { totalPendencias: action.total });
  return state;
}

function setOrigem(state, action) {
  state = Object.assign({}, state, { origem: action.origem });
  return state;
}
function setCarrierFilter(state, action) {
  let carrier = state.carrier;
  carrier = action.carrier;
  return { ...state, carrier };
}
function setLookupCarrierVisible(state, action) {
  let lookupCarrier = state.lookupCarrier;
  lookupCarrier.visible = action.visible;
  return { ...state, lookupCarrier };
}

function setCountCollectionOrdersConfirmadas(state, action) {
  state = Object.assign({}, state, { totalConfirmadas: action.total });
  return state;
}

function openDialogIframeCollectionOrder(state, action) {
  const openDialogIframe = action.openDialogIframe;
  const openDialogIframeVisible = openDialogIframe.visible;
  const link = openDialogIframe.link;
  return Object.assign({}, state, { openDialogIframeVisible, link })
}

function setExpandedRows(state, action) {
  state = Object.assign({}, state, { expandedRows: action.expandedRows });
  return state;
}
function setRecordsCollectionOrders(state, action) {
  const collectionOrdersPendeceAceite = action.records || [];
  return Object.assign({}, state, { collectionOrdersPendeceAceite })
}
function setFilters(state, action) {
  const filters = action.filters || [];
  return Object.assign({}, state, { filters })
}

function setCountCollectionOrders(state, action) {
  const collectionOrdersPendentAceite = action.total || 0;
  return Object.assign({}, state, { collectionOrdersPendentAceite })
}

