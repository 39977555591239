
export function validate(record) {
    const errors = [];

    if (!record.name  || record.name.trim().length == 0) {
        errors.push({ field: 'name', message: 'O nome da Transportadora é obrigatório' });
    }
    if (!record.documentNumber || record.documentNumber.trim().length === 0) {
        errors.push({ field: 'documentNumber', message: 'O CNPJ/CPF do Transportador é obrigatório' });
    }
    if(record.label == '0000000000'){
        record.label = "";
    }
    
    const result = {
        ok: errors.length === 0,
        errors,
        record
    }

    return new Promise((resolve, reject) => {
        const action = result.ok ? resolve : reject;
        return action(result);
    })
}