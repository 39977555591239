import { validate } from './validations';
import {build} from '../../services/crudFactory';
import {Action} from '../../services/actions.factory';
const BASE_PATH = '/lognex/permissions';
const prefix = 'crudPermissao';
const crud = build(validate,BASE_PATH);

const newTypes = {
    SET_LOOKUP_PERFIL_VISIBLE: `${prefix}setLoockupPerfilVisible`,
    SET_LOOKUP_MENU_VISIBLE: `${prefix}setLoockupMenuVisible`,
    SET_BOOLEAN_FILTER: `${prefix}setBooleanFilter`,
}

const actions = new Action(prefix,crud);

const types = Object.assign({},actions.types,newTypes);

actions.setLookupMenuVisible = (visible)=>{
 return{
     type:types.SET_LOOKUP_MENU_VISIBLE,
     visible
 }
}

actions.setLookupPerfilVisible = (visible)=>{
    return{
        type:types.SET_LOOKUP_PERFIL_VISIBLE,
        visible
    }
   }

   actions.setBooleanFilter = (field, value)=>{
    return {
        type:types.SET_BOOLEAN_FILTER,
        field,
        value
    }
}

export {types, actions};