export function validate(record) {
    const errors = [];


    if (!record.date || record.date === '') {
        errors.push({ field: 'date', message: 'Data é obrigatória!' });
    }
    if (!record.description || record.description === '') {
        errors.push({ field: 'description', message: 'Descrição é obrigatória!' });
    }

    const result = {
        ok: errors.length === 0,
        errors,
        record
    }

    return new Promise((resolve, reject) => {
        const action = result.ok ? resolve : reject;
        return action(result);
    })
}