
import React from 'react';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';

export const VehicleGroupForm = (props) => {
    console.log('VehicleGroupForm');

    return (
        <div className="vehicleGroup-form">
            <div className="p-grid">
                <div className="p-col-6">
                    <div className="p-grid">

                        <div className="p-col-6 form-field-container">
                            <label>Código</label>
                            <InputText value={props.record.oid} onChange={props.changeHandler} name="oid" disabled={true} />
                        </div>
                        <div className="p-col-12 form-field-container">
                            <label>Descrição</label>
                            <InputText value={props.record.description} onChange={props.changeHandler} name="description" disabled={false} />
                        </div>
                        <div className="p-col-3 form-field-container">
                            <label>Altura(CM)</label>
                            <InputText value={props.record.height} onChange={props.onChangeHandlerCalculateCubage} name="height" disabled={false} />
                        </div>
                        <div className="p-col-3 form-field-container">
                            <label>Largura(CM)</label>
                            <InputText value={props.record.width} onChange={props.onChangeHandlerCalculateCubage} name="width" disabled={false} />
                        </div>
                        <div className="p-col-3 form-field-container">
                            <label>Comprimento(CM)</label>
                            <InputText value={props.record.length} onChange={props.onChangeHandlerCalculateCubage} name="length" disabled={false} />
                        </div>
                        <div className="p-col-3 form-field-container">
                            <label>Cubagem(m²)</label>
                            <InputText value={props.record.cubage} onChange={props.changeHandler} name="cubage" disabled={true} />
                        </div>
                        <div className="p-col-3 form-field-container">
                            <label>Peso tara(KG)</label>
                            <InputText value={props.record.tareWeight} onChange={props.onChangeHandlerCalculateNetWeight} name="tareWeight" disabled={false} />
                        </div>
                        <div className="p-col-3 form-field-container">
                            <label>Peso máximo</label>
                            <InputText value={props.record.maxWeight} onChange={props.onChangeHandlerCalculateNetWeight} name="maxWeight" disabled={false} />
                        </div>
                        <div className="p-col-3 form-field-container">
                            <label>Peso líquido</label>
                            <InputText value={props.record.netWeight} onChange={props.changeHandler} name="netWeight" disabled={true} />
                        </div>
                        <div className="p-col-4 form-field-container">
                            <label>Fator de Velocidade</label>
                            <InputText value={props.record.speedFactor} onChange={props.changeHandler} name="speedFactor" disabled={false} />
                        </div>
                        <div className="p-col-4 form-field-container">
                            <label>Tipo de veículo</label>
                            <Dropdown value={props.record.vehicleType} onChange={props.changeHandler} name="vehicleType" options={props.vehicleTypes} ></Dropdown>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};