import React from "react";
import { numberToCurrency } from "../../../../utils";
import "./Totalizador.scss";

const Totalizador = (props) => {
  return (
    <p>
      Total orçado: {numberToCurrency(props.totalOrcado, "REAL") || 0} | Total
      auditado: {numberToCurrency(props.totalAuditado, "REAL") || 0} |
      <span className="totalizator-warning">
        Total divergente: {numberToCurrency(props.totalDivergency, "REAL") || 0}{" "}
      </span>
    </p>
  );
};

export default Totalizador;
