
import React from 'react';
import { InputText } from 'primereact/inputtext';

export const SegmentForm = (props) => {

    return (
        <div className="segment-form">
            <div className="p-grid">
                <div className="p-col-6">
                    <div className="p-grid">

                        <div className="p-col-12 form-field-container">
                            <label>Código</label>
                            <InputText value={props.record.oid} onChange={props.changeHandler} name="oid" disabled={true} />
                        </div>
                        <div className="p-col-12 form-field-container">
                            <label>Descrição*</label>
                            <InputText value={props.record.description} onChange={props.changeHandler} name="description" disabled={false} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};