import React from "react";
import { Calendar } from "primereact/calendar";
import { LookupField } from "../../../../components/lookup/LookupField";

export const AuditRecordsFilter = (props) => {
  return (
    <div className="cargo-shipment-form">
      <div className="p-grid">
        <div className="p-col-12">
          <div className="p-grid">
            <div className="p-col-2 form-field-container">
              <label className="lxRequiredField">De</label>
              <Calendar
                showIcon={true}
                value={props.initialDateFilter}
                showTime={false}
                onChange={props.onInitialDateChangeHandler}
                disabled={false}
                name="initialDateFilter"
                dateFormat="dd/mm/yy"
              />
            </div>
            <div className="p-col-2 form-field-container">
              <label className="lxRequiredField">Até</label>
              <Calendar
                showIcon={true}
                value={props.finalDateFilter}
                showTime={false}
                onChange={props.onFinalDateChangeHandler}
                disabled={false}
                name="finallDateFilter"
                dateFormat="dd/mm/yy"
              />
            </div>
            <div className="p-col-4 form-field-container">
              <label>Transportadora</label>
              <LookupField
                value={props.carrierFilter ? props.carrierFilter : ""}
                placeholder="Transportadora"
                name="tradeName"
                field="tradeName"
                onComplete={props.lookupCarrierOnComplete}
                onInputFilter={props.lookupCarrierOnInputFilter}
                editDisabled={props.lookupCarrier.editDisabled}
                onEditField={props.lookupCarrierOnEdit}
                onClick={props.lookupCarrierOnClick}
                visible={props.lookupCarrier.visible}
                modal={props.lookupCarrier.modal}
                header={props.lookupCarrier.header}
                onHide={props.lookupCarrierOnHide}
                onConfirm={props.lookupCarrierOnConfirm}
                onCancel={props.lookupCarrierOnCancel}
                records={props.lookupCarrier.records}
                columns={props.lookupCarrier.columns}
                totalRecords={props.lookupCarrier.totalRecords}
                sortOrder={props.lookupCarrier.order}
                sortField={props.lookupCarrier.sortField}
                first={props.lookupCarrier.first}
                rows={props.lookupCarrier.rows}
                onPage={props.lookupCarrierOnPage}
                onFilter={props.lookupCarrierOnFilter}
                onSort={props.lookupCarrierOnOnSort}
                paginator={true}
                owsPerPageOptions={[5, 10, 20,50]}
              />
            </div>

            <div className="p-col-2 form-field-container">
              <br />
              <button
                className="lognex-btn-highlighted"
                style={{ width: "100%" }}
                title="Filtrar"
                onClick={props.applyFilter}
              >
                Filtrar
              </button>
            </div>
            <div className="p-col-2 form-field-container">
              <br />
              <button
                className="lognex-btn-highlighted"
                style={{ width: "100%" }}
                title="Download"
                onClick={props.onDownloadCSV}
                disabled={!props.enabledExportCSV}
              >
                Exportar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
