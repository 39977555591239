import React from 'react';
import {Tag} from './Tag';
import  './TagList.scss';

const classes = {
    main:"tag-list",
}
export const TagList = (props)=>{
    const tags = props.tags ? props.tags.map((current,index) => (<Tag name={current} onRemove={props.onRemove} key={index}/>)) : null;
    return (
        <div className={classes.main}>
           {tags}
        </div>
    )
}