
import React from 'react';
import { connect } from 'react-redux';
import { Column } from 'primereact/column';
import Toolbar from "../../../components/toolbar/Toolbar";
import { actions } from '../pallet.actions';
import { routes } from '../../../constants';
import { Page } from "../../../components/page/Page";
import { PalletForm } from './PalletForm';
import { actions as palletStandardActions } from '../../palletStandard/palletStandard.actions';


const mapStateToProps = state => {
  const record = state.crudPalletState.currentRecord;

  const lookupPalletStandardBase = {
    records: state.crudPalletStandardState.records,
    columns: state.crudPalletStandardState.columns
      .filter(col => col.field === 'oid' || col.field === 'name')
      .map(col => <Column key={col.field} {...col} />),
    first: state.crudPalletStandardState.index,
    rows: state.crudPalletStandardState.max,
    totalRecords: state.crudPalletStandardState.count,
    sortField: state.crudPalletStandardState.sortField,
    order: state.crudPalletStandardState.sortOrder,
    editDisabled: !record.oidPalletStandard
  }
  let lookupPalletStandard = Object.assign({}, state.crudPalletState.lookupPalletStandard, lookupPalletStandardBase);

  return {
    record
    , lookupPalletStandard

  }
}

const mapDispatchToProps = dispatch => {
  return {

    lookupPalletStandardSetVisible: visible => dispatch(actions.setLookupPalletStandardVisible(visible)),
    lookupPalletStandardPaginate: paginationParams => dispatch(palletStandardActions.paginate(paginationParams)),
    lookupPalletStandardSortOrder: sortParams => dispatch(palletStandardActions.sortOrder(sortParams)),
    lookupPalletStandardApplyFilter: filterParams => {
      dispatch(palletStandardActions.setType(null));
      dispatch(palletStandardActions.applyFilter(filterParams))
    },
    lookupPalletStandardLoad: () => dispatch(palletStandardActions.load()),
    setPalletStandard: (palletStandard) => {
      dispatch(actions.setField('oidPalletStandard', palletStandard != null ? palletStandard.oid : null));
      dispatch(actions.setField('palletStandardName', palletStandard != null ? palletStandard.name : ''));
      dispatch(actions.setField('depth', palletStandard != null ? palletStandard.depth : 0.00));
      dispatch(actions.setField('height', palletStandard != null ? palletStandard.height : 0.00));
      dispatch(actions.setField('width', palletStandard != null ? palletStandard.width : 0.00));
      dispatch(actions.setField('maxWeight', palletStandard != null ? palletStandard.maxWeight : 0.00));

    },

    setField: (field, value) => dispatch(actions.setField(field, value)),
    changeHandler: event => {
      const target = event.target;
      const field = target.name;
      let value = target.value;
      dispatch(actions.removeErrors([{ field }]));
      dispatch(actions.setField(field, value));
    },
    saveHandler: event => {
      dispatch(actions.save());
    },
    removeHandler: event => {
      dispatch(actions.remove());
    },
    newHandler: event => {
      dispatch(actions.makeNew());
    },
    loadById: oid => dispatch(actions.loadById(oid))
  }
}

class PalletFormPageContainer extends React.Component {

  constructor(props) {
    super(props);
    actions.afterSave = ({ oid }) => {
      this.props.history.replace(`${routes.PATH_PALLET}/${oid}`);
    };
    actions.afterNew = () => {
      this.props.history.replace(`${routes.PATH_PALLET}/novo`);
    };
    actions.afterRemove = () => {
      this.props.history.replace(`${routes.PATH_PALLET}`);
    };
  }

  componentDidMount() {
    const oid = this.props.match.params.id;
        
    if (oid && parseInt(oid)) {
      this.props.loadById(oid);
    }else{
        this.props.newHandler();
    }
  }
  searchHandler = () => {
    this.props.history.push(`${routes.PATH_PALLET}`)
  }

  lookupPalletStandardOnPage = (e) => {
    this.props.lookupPalletStandardPaginate({
      max: e.rows,
      index: e.first
    })
  }
  lookupPalletStandardOnSort = (e) => {
    console.log('sorting', e);
    this.props.lookupPalletStandardSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField
    })


  }
  lookupPalletStandardOnFilter = (e) => {
    const filters = [];
    const keys = Object.keys(e.filters) || [];
    keys.forEach(key => {
      filters.push({
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: 'CONTEM'

      })
    })
    console.log(filters);
    this.props.lookupPalletStandardApplyFilter(filters)

  }
  lookupPalletStandardOnClick = (e) => {
    this.props.lookupPalletStandardLoad();
    this.props.lookupPalletStandardSetVisible(true);
  }
  lookupPalletStandardOnHide = (e) => {
    this.props.lookupPalletStandardSetVisible(false);
  }
  lookupPalletStandardOnConfirm = (e) => {
    console.log(e);
    this.props.setPalletStandard(e.selection);
    this.props.lookupPalletStandardSetVisible(false);

  }
  lookupPalletStandardOnCancel = (e) => {
    this.props.setPalletStandard(null);
    this.props.lookupPalletStandardSetVisible(false);
  }
  lookupPalletStandardOnEdit = () => {
    if (this.props.record.oidPessoa) {
      this.props.history.push(`${routes.PATH_PALLETSTANDARD}/${this.props.record.oidPalletStandard}`);
    }
  }

  lookupPalletStandardOnComplete = e => {
    console.log(e);
  };

  lookupPalletStandardOnInputFilter = (e) => {
    const value = e.value;
    if (typeof value === 'string') {
      const filters = [{
        campo: "name",
        valor: e.value,
        tipoFiltro: 'CONTEM'

      }];
      this.props.setField("palletStandardName", e.value);
      this.props.setField("oidPalletStandard", null);
      this.props.lookupPalletStandardApplyFilter(filters)
    } else {
      this.props.setPalletStandard(value);
    }
  }

  render() {
    const content = (
      <PalletForm
        changeHandler={this.props.changeHandler}
        record={this.props.record}

        lookupPalletStandard={this.props.lookupPalletStandard}
        lookupPalletStandardOnHide={this.lookupPalletStandardOnHide}
        lookupPalletStandardOnConfirm={this.lookupPalletStandardOnConfirm}
        lookupPalletStandardOnCancel={this.lookupPalletStandardOnCancel}
        lookupPalletStandardOnPage={this.lookupPalletStandardOnPage}
        lookupPalletStandardOnSort={this.lookupPalletStandardOnSort}
        lookupPalletStandardOnFilter={this.lookupPalletStandardOnFilter}
        lookupPalletStandardOnClick={this.lookupPalletStandardOnClick}
        lookupPalletStandardOnEdit={this.lookupPalletStandardOnEdit}
        lookupPalletStandardOnComplete={this.lookupPalletStandardOnComplete}
        lookupPalletStandardOnInputFilter={this.lookupPalletStandardOnInputFilter}

      />
    );
    const title = `Pallet ${this.props.record.oid || 'novo'}`
    const header = (
      <div>
        <h1>{title}</h1>
        <div>
          <Toolbar
            onSave={this.props.saveHandler} saveButtonStyle='highlighted'
            onRemove={this.props.removeHandler} removeButtonStyle='danger'
            onNew={this.props.newHandler}
            onSearch={this.searchHandler}>
          </Toolbar>
        </div>
      </div>
    );
    return (<Page header={header} content={content} />);
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PalletFormPageContainer);