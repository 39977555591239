import React from 'react'
import { DataTable } from 'primereact/datatable'

export const LxPanelUnitByComponentRestriction = (props) => {
    const datatable = {
        // selection: props.dataTable && props.dataTable.selectedComponents ? props.dataTable.selectedComponents : [],
        value: props.dataTable && props.dataTable.records ? props.dataTable.records : [],
        sortOrder: props.dataTable && props.dataTable.Order ? props.dataTable.Order : 0,
        sortField: props.dataTable && props.restrictionsSortField ? props.restrictionsSortField : "",
        paginator: true,        
        rows: props.dataTable && props.dataTable.rows ? props.dataTable.rows : 0,
        onSelectionChange: props.dataTable && props.dataTable.actions && props.dataTable.actions.onSelectionChangeUnit ? props.dataTable.actions.onSelectionChangeUnit : props.dataTable.actions.onSelectionChangeUnit,
        // onFilter: props.dataTable && props.dataTable.onFilterUnit && props.dataTable.actions.onFilterUnit ? props.dataTable.actions.onFilterUnit : "",
        // onSort: props.dataTable && props.dataTable.onSortUnit && props.dataTable.actions.onSortUnit ? props.dataTable.actions.onSortUnit : "",
        emptyMessage: "Não foram encontrados registros",
        rowsPerPageOptions: [5, 10, 20, 50],
        selectionMode: "single",        
    }

    return (
        <div className="p-grid lxPanelRestrictionsByComponent"> 
            <div className="p-col-12">
                <DataTable {...datatable}>
                    {props.dataTable.unitsByComponentRestrictioncolumns}
                </DataTable>
            </div>
        </div>
    )
}