import React from 'react';
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import './DriverForm.scss';


export const DriverForm = (props) => {
    const record = props.record;
    return (
        <div className="driver-form">
            <div className="p-grid">
                <div className="p-col-6">
                    <div className="p-grid">
                        <div className="p-col-3">
                            <div className="form-field-container">
                                <label>Código</label>
                                <InputText value={record.oid} onChange={props.changeHandler} disabled={true} />
                            </div>
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-6 form-field-container">
                            <label className="lxRequiredField">Nome</label>
                            <InputText  value={record.name} onChange={props.changeHandler} name="name" />
                        </div>
                    </div>
                    <div className="p-grid">


                        <div className="p-col-6 form-field-container">
                            <label className="lxRequiredField">CPF</label>
                            <InputMask mask="999.999.999-99" value={record.documentNumber} onChange={props.changeHandler} name="documentNumber" />
                        </div>

                    </div>
                </div>
            </div>

        </div>
    );
}
