import React from "react";
import "./DockmanagerLeftComponet.scss";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import moment from "moment";

export const DockmanagerLeftComponet = (props) => {
 /* let carriers = props.currentBusinessUnit ? props.currentBusinessUnit.carrierConfigurations : [];
  carriers = carriers.map((c) => {
    return { carrier: c.carrier, name: c.name || `CNPJ:${c.carrier}` };
  });

  if(carriers.length>0)
    carriers = [{ carrier: '0', name:`SEM TRANSPORTADORA` },...carriers] 
    */
  const wednesday = [];
  
  if (!props.docaWorkDayTime.docaWorkDays.sunday) wednesday.push(0);
  if (!props.docaWorkDayTime.docaWorkDays.monday) wednesday.push(1);
  if (!props.docaWorkDayTime.docaWorkDays.tuesday) wednesday.push(2);
  if (!props.docaWorkDayTime.docaWorkDays.wednesday) wednesday.push(3);
  if (!props.docaWorkDayTime.docaWorkDays.thursday) wednesday.push(4);
  if (!props.docaWorkDayTime.docaWorkDays.friday) wednesday.push(5);
  if (!props.docaWorkDayTime.docaWorkDays.saturday) wednesday.push(6);
  
  let holidays=[];
  if(props.docaWorkDayTime.holidays && props.docaWorkDayTime.holidays.length>0){
    holidays = props.docaWorkDayTime.holidays .map((c) => {
      return moment(c.holiday).toDate();
    });
  }
 

  const columns = [<Column key="selectorColumn" selectionMode="multiple" style={{ width: "3em" }} />];
  columns.push(<Column key="carrier" field="name" header="Nome Transportadora" />);
  const keyDropdown = `"dropUnity_${moment().format("YYYYMMDDHHmmss")}"`;
  const keyTable = `"tablTransp_${moment().format("YYYYMMDDHHmmss")}"`;
  return (
    <div className="let-component">
      <div className="form-field-container">
        <Calendar disabledDays={wednesday} disabledDates={holidays} inline={true} onChange={props.onSelectionDateChange} disabled={!props.currentBusinessUnit} value={props.dateSelected} />
      </div>
      <br></br>

      {/* Uniades */}
      <div className="form-field-container">
        <label>Unidade</label>
        <Dropdown key={keyDropdown} placeholder="Selecione" value={props.currentBusinessUnit} dataKey={"label"} optionLabel="label" options={props.businessUnits} onChange={props.onDropdownBusinessUnitChange} />
      </div>

      <br></br>
      <div className="form-field-container">
        {/* <label>Transportadoras</label> */}
        <DataTable  key={keyTable}  value={props.carriers} onSelectionChange={props.onSelectionCarrierChange} selectionMode="mltiple" metaKeySelection={false} selection={props.selectedCarriers}>
          {columns}
        </DataTable>
      </div>
    </div>
  );
};
