
import React from 'react';
import { InputText } from 'primereact/inputtext';
import { LookupField } from '../../../components/lookup/LookupField';
import { InputSwitch } from 'primereact/inputswitch';

export const RouteForm = (props) => {
    const record = props.record;

    return (
        <div className="route-form">
            <div className="p-grid">
                <div className="p-col-6">
                    <div className="p-grid">

                        <div className="p-col-12 form-field-container">
                            <label>Código</label>
                            <InputText value={props.record.oid} onChange={props.changeHandler} name="oid" disabled={true} />
                        </div>
                        <div className="p-col-12 form-field-container">
                            <label className="lxRequiredField">Nome</label>
                            <InputText value={props.record.name} onChange={props.changeHandler} name="name" disabled={false} />
                        </div>
                        <div className="p-col-12 form-field-container">
                            <label className="lxRequiredField">Transportadora</label>
                            <LookupField
                                value={record.carrierName}
                                placeholder="Transportadora"
                                name="carrierName"
                                field="name"
                                onComplete={props.lookupCarrierOnComplete}
                                onInputFilter={props.lookupCarrierOnInputFilter}
                                editDisabled={props.lookupCarrier.editDisabled}
                                onEditField={props.lookupCarrierOnEdit}
                                onClick={props.lookupCarrierOnClick}

                                visible={props.lookupCarrier.visible}
                                modal={props.lookupCarrier.modal}
                                header={props.lookupCarrier.header}
                                onHide={props.lookupCarrierOnHide}
                                onConfirm={props.lookupCarrierOnConfirm}
                                onCancel={props.lookupCarrierOnCancel}
                                records={props.lookupCarrier.records}
                                columns={props.lookupCarrier.columns}
                                totalRecords={props.lookupCarrier.totalRecords}
                                sortOrder={props.lookupCarrier.order}
                                sortField={props.lookupCarrier.sortField}
                                first={props.lookupCarrier.first}
                                rows={props.lookupCarrier.rows}
                                onPage={props.lookupCarrierOnPage}
                                onFilter={props.lookupCarrierOnFilter}
                                onSort={props.lookupCarrierOnOnSort}
                            />
                        </div>
                        <div className="p-col-12 form-field-container">
                            <label className="lxRequiredField">Origem</label>
                            <LookupField
                                value={record.originName}
                                placeholder="Origem"
                                name="originName"
                                field="name"
                                onComplete={props.lookupOriginOnComplete}
                                onInputFilter={props.lookupOriginOnInputFilter}
                                editDisabled={props.lookupOrigin.editDisabled}
                                onEditField={props.lookupOriginOnEdit}
                                onClick={props.lookupOriginOnClick}

                                visible={props.lookupOrigin.visible}
                                modal={props.lookupOrigin.modal}
                                header={props.lookupOrigin.header}
                                onHide={props.lookupOriginOnHide}
                                onConfirm={props.lookupOriginOnConfirm}
                                onCancel={props.lookupOriginOnCancel}
                                records={props.lookupOrigin.records}
                                columns={props.lookupOrigin.columns}
                                totalRecords={props.lookupOrigin.totalRecords}
                                sortOrder={props.lookupOrigin.order}
                                sortField={props.lookupOrigin.sortField}
                                first={props.lookupOrigin.first}
                                rows={props.lookupOrigin.rows}
                                onPage={props.lookupOriginOnPage}
                                onFilter={props.lookupOriginOnFilter}
                                onSort={props.lookupOriginOnOnSort}
                            />
                        </div>
                        <div className="p-col-12 form-field-container">
                            <label className="lxRequiredField">Destino</label>
                            <LookupField
                                value={record.destinationName}
                                placeholder="Destino"
                                name="destinationName"
                                field="name"
                                onComplete={props.lookupDestinationOnComplete}
                                onInputFilter={props.lookupDestinationOnInputFilter}
                                editDisabled={props.lookupDestination.editDisabled}
                                onEditField={props.lookupDestinationOnEdit}
                                onClick={props.lookupDestinationOnClick}

                                visible={props.lookupDestination.visible}
                                modal={props.lookupDestination.modal}
                                header={props.lookupDestination.header}
                                onHide={props.lookupDestinationOnHide}
                                onConfirm={props.lookupDestinationOnConfirm}
                                onCancel={props.lookupDestinationOnCancel}
                                records={props.lookupDestination.records}
                                columns={props.lookupDestination.columns}
                                totalRecords={props.lookupDestination.totalRecords}
                                sortOrder={props.lookupDestination.order}
                                sortField={props.lookupDestination.sortField}
                                first={props.lookupDestination.first}
                                rows={props.lookupDestination.rows}
                                onPage={props.lookupDestinationOnPage}
                                onFilter={props.lookupDestinationOnFilter}
                                onSort={props.lookupDestinationOnOnSort}
                            />
                        </div>
                        <div className="p-col-12 form-field-container">
                            <label>Apenas para Redespacho</label>
                            <InputSwitch
                                checked={props.record.onlyRedispatch}
                                onChange={props.changeHandler}
                                name="onlyRedispatch"
                                 />
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
};