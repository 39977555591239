
    import React from 'react';
    import EdiFormPageContainer from './EdiFormPageContainer';
    
    export class EdiFormPage extends React.Component{
        render(){
            return (
                <EdiFormPageContainer {...this.props}/>
            )
        }
    }
    