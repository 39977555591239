
    import React from 'react';
    import VehicleListPageContainer from './VehicleListPageContainer';
    
    export class VehicleListPage extends React.Component{
        
        render(){
          return (
            <VehicleListPageContainer {...this.props}/>
          )
        }
    }