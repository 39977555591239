
    import React from 'react';
    import VehicleGroupFormPageContainer from './VehicleGroupFormPageContainer';
    
    export class VehicleGroupFormPage extends React.Component{
        render(){
            return (
                <VehicleGroupFormPageContainer {...this.props}/>
            )
        }
    }
    