
    import React from 'react';
    import PackingFormPageContainer from './PackingFormPageContainer';
    
    export class PackingFormPage extends React.Component{
        render(){
            return (
                <PackingFormPageContainer {...this.props}/>
            )
        }
    }
    