import React from "react";
import { Dialog } from "primereact/dialog";
import { Checkbox } from "primereact/checkbox";

import "./LxDialogStatusImportXlsRoutes.scss";
export class LxDialogStatusImportXlsRoutes extends React.Component {
  render() {
    const { props } = this;

    const getTotalRegistros = () => {
      let total = 0;
      if (props.dialog.resultImportTableXls) {
        total = props.dialog.resultImportTableXls.total;
        return <h2>Total de Rotas:&nbsp; {total}</h2>;
      }
      return <></>;
    };
    const getTotalRegistrosErros = () => {
      let total = 0;
      if (props.dialog.resultImportTableXls && props.dialog.resultImportTableXls.erros) {
        total = props.dialog.resultImportTableXls.erros.length;
        return <h2 className="label-total-error">Total erros:&nbsp; {total} </h2>;
      }
      return <></>;
    };

    const getMessagesRowError = () => {
      if (props.dialog.resultImportTableXls && props.dialog.resultImportTableXls.erros) {
        return props.dialog.resultImportTableXls.erros.map((m, index) => {
          return (
            <p key={index} className="label-error">
              {m}
            </p>
          );
        });
      } else return <></>;
    };
    const getMessagesRowSuccess = () => {
      if (props.dialog.resultImportTableXls && props.dialog.resultImportTableXls.messages) {
        return props.dialog.resultImportTableXls.messages.map((m, index) => {
          return (
            <p key={index} className="label-success">
              {m}
            </p>
          );
        });
      } else return <></>;
    };

    return (
      <Dialog
        header={"Importação dos dados do XLS"}
        visible={props.dialog.visible || false}
        style={{ width: "800px" }}
        onHide={() => props.onCloseDialog()}
        modal={true}
        baseZIndex={1}
        footer={
          <div className="footer-container">
            <div>
              {!props.dialog.resultImportTableXls && (
                <div className="footer-readxls">
                  <i className="pi pi-spin pi-spinner" style={{ fontSize: "2em" }}></i>
                  <h3>Aguarde...</h3>
                </div>
              )}
            </div>
            {/* <button title="ok" className="lognex-btn-highlighted" onClick={() => props.onCloseDialog()} disabled={!props.dialog.resultImportTableXls}>
              <span>OK</span>
            </button> */}
          </div>
        }
      >
        <div className="content-dialog">
          <div className="p-grid">
            <div className="p-col-12">
              <Checkbox inputId="readXls" checked={props.dialog.statusCode >= 1}></Checkbox>
              <label htmlFor="readXls" className="p-checkbox-label">
                Importou arquivo XLS ?
              </label>
            </div>
            <div className="p-col-12">
              <Checkbox inputId="createRoutes" checked={props.dialog.statusCode >= 2}></Checkbox>
              <label htmlFor="createRoutes" className="p-checkbox-label">
                Criou as rotas ?
              </label>
            </div>
            <div className="p-col-12" >
              {getTotalRegistros()}
            </div>
            <div className="p-col-12" >
              {getTotalRegistrosErros()}
            </div>
            <br></br> <br></br>
            <div className="p-col-12" style={{ lineHeight:1.2 }}>
              {getMessagesRowSuccess()}
            </div>
            <div className="p-col-12" style={{ lineHeight: 1.2 }}>
              {getMessagesRowError()}
            </div>
            {/* <div className="p-col-12">
            <Checkbox inputId="addComponents" checked={props.dialog.statusCode == 3}></Checkbox>
            <label htmlFor="addComponents" className="p-checkbox-label">
              Adicionou componentes ?
            </label>
          </div> */}
          </div>
        </div>
      </Dialog>
    );
  }
}
