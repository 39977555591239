import React from 'react';
import TowerTravelVisualizeMapPortalListPageContainer from './TowerTravelVisualizeMapPortalListPageContainer';


export class TowerTravelVisualizeMapPortalListPage extends React.Component{
    
    render(){
      return (
        <TowerTravelVisualizeMapPortalListPageContainer {...this.props}/>
      )
    }
}

