import React from "react";
import { DataTable } from "primereact/datatable";

export const RouteManualTable = (props) => (
  <DataTable
    value={props.records}
    emptyMessage="Não foram encontrados registros" 
    scrollHeight={"400px"} 
    scrollable={true}

  >
    {props.columns}
  </DataTable>
);
