
import { types } from "./audit.actions";
import { Reducer } from "../../services/reducer.factory";
import moment from 'moment';

const initialState = {
    auditFilter: {
        currentDocTypeFilter: "CTE",
        currentDocStatusFilter: ["WITHPENDINGS"],
        currentPendingsTypeFilter: "ALL",
        initialDateFilter: moment().startOf('month').toDate(),
        finalDateFilter: moment().toDate(),
        carrierFilter: {
            oid: null,
            documentNumber: "",
            name: "",
            tradeName: "",
        },
        docTypeFilterOptions: [
            { label: "CTe", value: "CTE" },
            { label: "Fatura", value: "INVOICE" }
        ],
        docStatusFilterOptions: [
            //{ label: 'Todas', value: "ALL" },
            { label: 'Divergentes', value: 'WITHPENDINGS' },
            { label: 'Resolvidas', value: 'CLOSED' },
            //{ label: 'Resolvidas Manualmente', value: 'CLOSED_MANUALLY' }
        ],
        docPendingsTypeFilterOptions: [
            { label: 'Todos', value: "ALL" },
            { label: 'Com Pendências', value: 'OPEN' },
            { label: 'Sem Pendências', value: 'SOLVED_AUTO' },
        ],
        lookupCarrier: {
            visible: false,
            modal: false,
            header: "Transportadora"
        }
    },
    invoiceRecords: [
    ],
    currentInvoiceRecord: {
        oid: null,
        number: "",
        date: null,
        carrier: null,
        status: null,
        total: 0
    },
    filterInvoiceTable: {
        filters: [],
        sortField: "",
        sortOrder: 0,
        index: 0,
        max: 20,
        count: 2,
        errors: {}
    },
    invoiceTableColumns: [
        {
            field: "number",
            header: "Número",
            columnKey: "number",
            sortable: true,
            filter: true
        },
        {
            field: "dueDate",
            header: "Vencimento",
            columnKey: "dueDate",
            sortable: false,
            filter: true
        },
        {
            field: "date",
            header: "Data",
            columnKey: "date",
            sortable: false,
            filter: true
        },

        {
            field: "carrier",
            header: "Transportadora",
            columnKey: "carrier",
            sortable: false,
            filter: false
        },
        {
            field: "qtdeCTes",// 15/30 15 importadas e 30 total na ivoice
            header: "CTE Importado/Total",
            columnKey: "qtdeCTes",
            sortable: false,
            filter: false
        },
        {
            field: "total",
            header: "Total",
            columnKey: "total",
            sortable: false,
            filter: false
        },
        {
            field: "actions",
            header: "Ações",
            columnKey: "actions",
            sortable: false,
            filter: false
        }
    ],
    dateFilter: {
        date: null,
        dueDate: null
    },
    cteRecords: [],
    cteTotalizer: { totalQuoted: 0.0, totalRealized: 0.0 },
    currentCTeRecord: {
        oid: null,
        number: "",
        date: null,
        carrier: null,
        status: null,
        total: 0
    },
    expandedCTeRows: [],
    filterCTeTable: {
        filters: [],
        sortField: "",
        sortOrder: 0,
        index: 0,
        max: 20,
        count: 6,
        errors: {}
    },
    cteTableColumns: [
        {
            field: "number",
            header: "Número",
            columnKey: "number",
            sortable: true,
            filter: true
        },
        {
            field: "dtEmissao",
            header: "Emissão",
            columnKey: "dtEmissao",
            sortable: false,
            filter: false
        },

        {
            field: "carrier.name",
            header: "Transportadora",
            columnKey: "carrier.name",
            sortable: false,
            filter: false
        },
        {
            field: "quote.freightTableName",
            header: "Tabela de Frete",
            columnKey: "quote.freightTableName",
            sortable: false,
            filter: false
        },
        {
            field: "totalQuotedValue",
            header: "Orçado",
            columnKey: "totalQuotedValue",
            sortable: false,
            filter: false
        },
        {
            field: "cteFreightValue",
            header: "Realizado",
            columnKey: "cteFreightValue",
            sortable: false,
            filter: false
        },
        {
            field: "totalValueNFe",
            header: "Valor Total NFe",
            columnKey: "totalValueNFe",
            sortable: false,
            filter: false
        },
        {
            field: "porcentageFreightNFe",
            header: "%Frete / NFe",
            columnKey: "porcentageFreightNFe",
            sortable: false,
            filter: false
        },
        {
            field: "actions",
            header: "Ações",
            columnKey: "actions",
            sortable: false,
            filter: false
        }
    ],
    auditTbColumns: [
        {
            field: "name",
            header: "Componente",
            columnKey: "name"
        },
        {
            field: "quotedValue",
            header: "Orçado",
            columnKey: "quotedValue",
        },
        {
            field: "realizedValue",
            header: "Realizado",
            columnKey: "realizedValue"
        },
        {
            header: "Ações",
            columnKey: "actions"
        }
    ],
    dialogNFes: {
        visible: false,
        modal: true,
        header: "Notas Fiscais"
    },
    nfeRecords: [],
    nfesTbColumns: [
        {
            field: "number",
            header: "Número",
            columnKey: "number"
        },
        {
            field: "serie",
            header: "Série",
            columnKey: "serie"
        },
        {
            field: "key",
            header: "Chave",
            columnKey: "key"
        },],
    dialogQuote: {
        visible: false,
        modal: true,
        header: "Orçamento"
    },
    quoteRecord: {}, //This struct must be defined
    dialogHistory: {
        visible: false,
        modal: true,
        header: "Histórico"
    },
    historyRecords: [],
    historyTbColumns: [
        {
            field: "date",
            header: "Data",
            columnKey: "date"
        },
        {
            field: "user",
            header: "Usuário",
            columnKey: "user"
        },
        {
            field: "note",
            header: "Descrição",
            columnKey: "note"
        }
    ],
    dialogApproveComponent: {
        visible: false,
        modal: true,
        header: "Motivo da aprovação",
        approvalType: "ALL", /*ALL or COMPONENT*/
        alertMessage: "",
    },
    dialogApproveInvoice: {
        visible: false,
        modal: true,
        header: "Motivo da aprovação",
        alertMessage: "",
        description: ""
    },
    currentCTe: {
        oid: null
    },
    currentInvoice: {
        oid: null
    },
    currentApproveComponent: {
        description: "",
        labelCTe: ""
    },
    currentApproveInvoice: {
        description: ""
    },
    dialogLogs: {
        visible: false,
        modal: true,
        header: "Logs"
    },
    logRecords: [],
    logTbColumns: [
        {
            field: "errorCode",
            header: "Código",
            columnKey: "errorCode"
        },
        {
            field: "description",
            header: "Descrição",
            columnKey: "description"
        }],
    listCtesAllAprove: []
};

const reducer = new Reducer(initialState, types);

export function AuditReducer(state, action) {
    state = reducer.execute(state, action);
    return execute(state, action);
}

function execute(state, action) {
    let result = state;
    switch (action.type) {
        case types.SET_LOOKUP_CARRIER_VISIBLE:
            return setLookupCarrierVisible(state, action);
        case types.SET_TYPE_FILTER:
            return setCurrentDocTypeFilter(state, action);
        case types.SET_STATUS_FILTER:
            return setCurrentDocStatusFilter(state, action);
        case types.SET_PENDINGS_TYPE_FILTER:
            return setCurrentPendingsTypeFilter(state, action);
        case types.SET_INITIAL_DATE_FILTER:
            return setInitialDateFilter(state, action);
        case types.SET_FINAL_DATE_FILTER:
            return setFinalDateFilter(state, action);
        case types.SET_CARRIER_FILTER:
            return setCarrierFilter(state, action);
        case types.SET_INVOICE_RECORDS:
            return setInvoiceRecords(state, action);
        case types.SET_CURRENT_INVOICE_RECORD:
            return setCurrentInvoiceRecord(state, action);
        case types.SET_FILTER_INVOICE_TABLE:
            return setFilterInvoiceTable(state, action);
        case types.SET_CTE_RECORDS:
            return setCteRecords(state, action);
        case types.SET_CURRENT_CTE_RECORD:
            return setCurrentCteRecord(state, action);
        case types.SET_FILTER_CTE_TABLE:
            return setFilterCteTable(state, action);
        case types.LOAD_INVOICE_RECORDS:
            return loadInvoiceRecords(state, action);
        case types.LOAD_CTE_RECORDS:
            return loadCTeRecords(state, action);
        case types.SET_DIALOG_NFES_VISIBLE:
            return setDialogNFesVisible(state, action);
        case types.LOAD_NFES_RECORDS:
            return loadNFeRecords(state, action);
        case types.SET_DIALOG_QUOTE_VISIBLE:
            return setDialogQuoteVisible(state, action);
        case types.LOAD_QUOTE_RECORD:
            return loadQuoteRecord(state, action);
        case types.SET_DIALOG_HISTORY_VISIBLE:
            return setDialogHistoryVisible(state, action);
        case types.LOAD_HISTORY_RECORDS:
            return loadHistoryRecords(state, action)
        case types.SET_DIALOG_APPROVE_COMPONENT_VISIBLE:
            return setDialogApproveComponentVisible(state, action);
        case types.SET_CURRENT_APPROVE_COMPONENT_DESCRIPTION:
            return setCurrentApproveComponentDescription(state, action);
        case types.SET_CURRENT_APPROVE_COMPONENT_ID_CTE:
            return setCurrentApproveComponentIDCTe(state, action);
        case types.SET_DIALOG_APPROVE_COMPONENT_TYPE:
            return setDialogApproveComponentType(state, action);
        case types.SET_DIALOG_APPROVE_COMPONENT_ALERT_MESSAGE:
            return setDialogApproveComponentAlertMessage(state, action);
        case types.SET_CURRENT_APPROVE_COMPONENT_LABEL_CTE:
            return setCurrentApproveComponentLabelCTe(state, action);
        case types.SET_EXPANDED_CTE_ROWS:
            return setExpandedCTeRows(state, action)
        case types.SET_CTE_TOTALIZER:
            return setCTeTotalizer(state, action)
        case types.SET_CURRENT_CTE:
            return setCurrentCTe(state, action)
        case types.SET_DIALOG_LOGS_VISIBLE:
            return setDialogLogsVisible(state, action)
        case types.LOAD_LOG_RECORDS:
            return loadLogRecords(state, action)
        case types.SET_DATE_FILTER:
            return setDateFilter(state, action)
        case types.APPLY_INVOICE_FILTER:
            return applyInvoiceFilter(state, action)
        case types.SET_DIALOG_APPROVE_INVOICE_ALERT_MESSAGE:
            return setDialogApproveInvoiceAlertMessage(state, action)
        case types.SET_DIALOG_APPROVE_INVOICE_VISIBLE:
            return setDialogApproveInvoiceVisible(state, action)
        case types.SET_DIALOG_APPROVE_INVOICE_DESCRIPTION:
            return setDialogApproveInvoiceDescription(state, action)
        case types.SET_CURRENT_INVOICE:
            return setCurrentInvoice(state, action)
        case types.SET_SELECTED_CTES_APROVE_ALL:
            return setSelectecCteAproveAll(state, action)
        // case types.SET_AUDIT_COMPONENT_APPROVED:
        //     return setAuditComponentApproved(state,action)
        default:
            return result;
    }
}

function setSelectecCteAproveAll(state, action) {
    let listCtesAllAprove = [];
    if (action.listCtesAllAprove && Array(action.listCtesAllAprove).length > 0) {
        listCtesAllAprove = action.listCtesAllAprove.filter(cte => cte.status != "CLOSED_MANUALLY" && cte.status != "CLOSED")
    }

    state = Object.assign({}, state, { listCtesAllAprove })
    return state;
}
function setLookupCarrierVisible(state, action) {
    let auditFilter = state.auditFilter;
    let lookupCarrier = auditFilter.lookupCarrier;
    lookupCarrier = Object.assign({}, lookupCarrier, { visible: action.visible });
    auditFilter = Object.assign({}, auditFilter, { lookupCarrier });
    state = Object.assign({}, state, { auditFilter })
    return state;
}

function setCurrentDocTypeFilter(state, action) {
    if (action.typeFilter) {
        let auditFilter = state.auditFilter;
        auditFilter = Object.assign({}, auditFilter, { currentDocTypeFilter: action.typeFilter });
        state = Object.assign({}, state, { auditFilter })
        if (action.typeFilter === 'CTE') {
            state = setCurrentInvoiceRecord(
                state,
                {
                    currentInvoiceRecord: {
                        oid: null,
                        number: "",
                        date: null,
                        carrier: null,
                        status: null,
                        total: 0
                    }
                })
        }
        state = setInvoiceRecords(state, { invoiceRecords: [] })
        state = setCteRecords(state, { cteRecords: [] })
    }
    return state;
}

function setCurrentDocStatusFilter(state, action) {
    let auditFilter = state.auditFilter;
    auditFilter = Object.assign({}, auditFilter, { currentDocStatusFilter: action.statusFilter });
    state = Object.assign({}, state, { auditFilter })
    return state;
}

function setCurrentPendingsTypeFilter(state, action) {
    let auditFilter = state.auditFilter;
    auditFilter = Object.assign({}, auditFilter, { currentPendingsTypeFilter: action.pendingsType });
    state = Object.assign({}, state, { auditFilter });
    return state;
}

function setInitialDateFilter(state, action) {
    let auditFilter = state.auditFilter;
    auditFilter = Object.assign({}, auditFilter, { initialDateFilter: action.initialDateFilter });
    state = Object.assign({}, state, { auditFilter })
    return state;
}

function setFinalDateFilter(state, action) {
    let auditFilter = state.auditFilter;
    auditFilter = Object.assign({}, auditFilter, { finalDateFilter: action.finalDateFilter });
    state = Object.assign({}, state, { auditFilter })
    return state;
}

function setCarrierFilter(state, action) {
    let auditFilter = state.auditFilter;
    auditFilter = Object.assign({}, auditFilter, { carrierFilter: action.carrierFilter });
    state = Object.assign({}, state, { auditFilter })
    return state;
}

function setInvoiceRecords(state, action) {
    state = Object.assign({}, state, { invoiceRecords: action.invoiceRecords });
    return state;
}

function setCurrentInvoiceRecord(state, action) {
    state = Object.assign({}, state, { currentInvoiceRecord: action.currentInvoiceRecord });
    return state;
}

function setFilterInvoiceTable(state, action) {
    let filterInvoiceTable = state.filterInvoiceTable;
    filterInvoiceTable = Object.assign({}, filterInvoiceTable, action.filterInvoiceTable)
    state = Object.assign({}, state, { filterInvoiceTable });
    return state;
}

function setCteRecords(state, action) {
    state = Object.assign({}, state, { cteRecords: action.cteRecords });
    return state;
}

function setCurrentCteRecord(state, action) {
    state = Object.assign({}, state, { currentCteRecord: action.currentCteRecord });
    return state;
}

function setFilterCteTable(state, action) {
    let filterCTeTable = { ...state.filterCTeTable, ...action.filterCTeTable }
    state = Object.assign({}, state, { filterCTeTable });
    return state;
}

function loadInvoiceRecords(state, action) {
    const { records, count, size, index } = action.data;
    const filterInvoiceTable = { ...state.filterInvoiceTable, count, max: size, index }

    state = Object.assign({}, state, { filterInvoiceTable, invoiceRecords: records });

    return state;
}

function loadCTeRecords(state, action) {
    state = Object.assign({}, state, { cteRecords: action.cteRecords });
    return state;
}

// NFe
function setDialogNFesVisible(state, action) {
    let dialogNFes = state.dialogNFes;
    dialogNFes = Object.assign({}, dialogNFes, { visible: action.visible });
    return Object.assign({}, state, { dialogNFes })
}

function loadNFeRecords(state, action) {
    state = Object.assign({}, state, { nfeRecords: action.nfeRecords });
    return state;
}

// Quote
function setDialogQuoteVisible(state, action) {
    let dialogQuote = state.dialogQuote;
    dialogQuote = Object.assign({}, dialogQuote, { visible: action.visible });
    return Object.assign({}, state, { dialogQuote })
}

function loadQuoteRecord(state, action) {
    state = Object.assign({}, state, { quoteRecord: action.quoteRecords });
    return state;
}

function setDialogHistoryVisible(state, action) {
    let dialogHistory = state.dialogHistory;
    dialogHistory = Object.assign({}, dialogHistory, { visible: action.visible });
    return Object.assign({}, state, { dialogHistory })
}

function loadHistoryRecords(state, action) {
    state = Object.assign({}, state, { historyRecords: action.historyRecords })
    return state
}
function setDialogApproveComponentVisible(state, action) {
    let dialogApproveComponent = state.dialogApproveComponent;
    dialogApproveComponent = Object.assign({}, dialogApproveComponent, { visible: action.visible });
    return Object.assign({}, state, { dialogApproveComponent })
}

function setDialogApproveComponentType(state, action) {
    let dialogApproveComponent = state.dialogApproveComponent;
    dialogApproveComponent = Object.assign({}, dialogApproveComponent, { approvalType: action.approvalType });
    return Object.assign({}, state, { dialogApproveComponent })
}

function setDialogApproveComponentAlertMessage(state, action) {
    let dialogApproveComponent = state.dialogApproveComponent;
    dialogApproveComponent = Object.assign({}, dialogApproveComponent, { alertMessage: action.alertMessage });
    return Object.assign({}, state, { dialogApproveComponent })
}

function setCurrentApproveComponentDescription(state, action) {
    let currentApproveComponent = state.currentApproveComponent;
    currentApproveComponent = Object.assign({}, currentApproveComponent, { description: action.description });
    state = Object.assign({}, state, { currentApproveComponent })
    return state;
}

function setCurrentApproveComponentLabelCTe(state, action) {
    let currentApproveComponent = state.currentApproveComponent;
    currentApproveComponent = Object.assign({}, currentApproveComponent, { labelCTe: action.labelCTe });
    state = Object.assign({}, state, { currentApproveComponent })
    return state;
}

function setCurrentApproveComponentIDCTe(state, action) {
    let currentApproveComponent = state.currentApproveComponent;
    currentApproveComponent = Object.assign({}, currentApproveComponent, { idCTe: action.idCTe });
    state = Object.assign({}, state, { currentApproveComponent })
    return state;
}


function setExpandedCTeRows(state, action) {
    state = Object.assign({}, state, { expandedCTeRows: action.expandedCTeRows });
    return state;
}

function setCTeTotalizer(state, action) {
    state = Object.assign({}, state, { cteTotalizer: action.cteTotalizer });
    return state;
}

function setCurrentCTe(state, action) {
    state = Object.assign({}, state, { currentCTe: action.cte });
    return state;
}
// function setAuditComponentApproved(state,action){
//     const currentApproveComponent = state.currentApproveComponent; 
//     const cteRecords = state.cteRecords.map(cteRecord =>{
//         if (cteRecord.oid === currentApproveComponent.idCTe){
//             cteRecord.audit = cteRecord.audit.map(audit =>{
//                 if (audit.labelCTe === currentApproveComponent.labelCTe){
//                     audit.status = 'SOLVED_MANUALLY'
//                 }
//             }) 
//         }
//         return cteRecord
//     })

//     state = Object.assign({}, state, { cteRecords });
//     return state;
// }

function setDialogLogsVisible(state, action) {
    let dialogLogs = state.dialogLogs;
    dialogLogs = Object.assign({}, dialogLogs, { visible: action.visible });
    return Object.assign({}, state, { dialogLogs })
}

function loadLogRecords(state, action) {
    state = Object.assign({}, state, { logRecords: action.logRecords })
    return state
}


function setDateFilter(state, action) {
    let dateFilter = state.dateFilter;
    dateFilter[action.field] = action.value;
    return Object.assign({}, state, { dateFilter })
}

function setCurrentInvoice(state, action) {
    state = Object.assign({}, state, { currentInvoice: action.invoice });
    return state;
}

function applyInvoiceFilter(state, action) {
    let filters = action.filters;
    const filterInvoiceTable = { ...state.filterInvoiceTable, filters }
    return Object.assign({}, state, { filterInvoiceTable })
}

function setDialogApproveInvoiceVisible(state, action) {
    let dialogApproveInvoice = state.dialogApproveInvoice;
    dialogApproveInvoice = Object.assign({}, dialogApproveInvoice, { visible: action.visible });
    return Object.assign({}, state, { dialogApproveInvoice })
}

function setDialogApproveInvoiceAlertMessage(state, action) {
    let dialogApproveInvoice = state.dialogApproveInvoice;
    dialogApproveInvoice = Object.assign({}, dialogApproveInvoice, { alertMessage: action.alertMessage });
    return Object.assign({}, state, { dialogApproveInvoice })
}

function setDialogApproveInvoiceDescription(state, action) {
    let dialogApproveInvoice = state.dialogApproveInvoice;
    dialogApproveInvoice = Object.assign({}, dialogApproveInvoice, { description: action.description });
    return Object.assign({}, state, { dialogApproveInvoice })
}


