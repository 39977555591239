
import { validate } from './validations';
import { build } from '../../services/crudFactory';
import { Action } from '../../services/actions.factory';
import api from "../../services/api.service";
import { messageActions, makeMessages } from '../../store/message';
const BASE_PATH = '/lognex/packingconverter';
const prefix = 'crudPackingConverter';

const crud = build(validate, BASE_PATH);

const actions = new Action(prefix, crud);

const newTypes = {
    PACKING1_SET_VISIBLE: `${prefix}packing1SetVisible`,
    SET_PACKING1: `${prefix}setPacking1`,
    PACKING2_SET_VISIBLE: `${prefix}packing2SetVisible`,
    SET_PACKING2: `${prefix}setPacking2`,
}

const types = Object.assign({}, actions.types, newTypes);

actions.packing1SetVisible = (visible) => {
    return {
        type: types.PACKING1_SET_VISIBLE,
        visible
    }
}

actions.setPacking1 = (packing1) => {
    return {
        type: types.SET_PACKING1,
        packing1
    }
}

actions.packing2SetVisible = (visible) => {
    return {
        type: types.PACKING2_SET_VISIBLE,
        visible
    }
}

actions.setPacking2 = (packing2) => {
    return {
        type: types.SET_PACKING2,
        packing2
    }
}

actions.importXLSX = (files) => {
    return (dispatch, getState) => {
        dispatch(actions.fetchRecord());
        const path = "/api/v1/lognex/packingconverter/import";
        api.uploadFiles(path, files)
            .then((response) => {
                const created = response.data.created.total;
                const ignored = response.data.ignored.total;
                const updated = response.data.updated.total;
                const error = response.data.error.total;
                dispatch(actions.hideLoader());
                if (created > 0){
                    dispatch(
                        messageActions.messageShowMessages(
                            makeMessages([ created + " registro(s) inseridos."], "success")
                        )
                    );
                }
                if (ignored > 0){
                    dispatch(
                        messageActions.messageShowMessages(
                            makeMessages([ ignored + " registro(s) ignorados."], "success")
                        )
                    );
                }
                if (updated > 0){
                    dispatch(
                        messageActions.messageShowMessages(
                            makeMessages([ updated +" registro(s) atualizados."], "success")
                        )
                    );
                }
                if (error > 0){
                    dispatch(
                        messageActions.messageShowMessages(
                            makeMessages([ error+ " registro(s) com erros."], "success")
                        )
                    );
                }
                dispatch(actions.load());
            })
            .catch((error) => {
                dispatch(actions.hideLoader());
                console.log(error)
                dispatch(
                    messageActions.messageShowMessages(
                        makeMessages(["Ops! Falha na importação do arquivo"], "error")
                    )
                );
            })

    }
}

export { types, actions }