import AuthenticationService from '../../../services/authentication.service';

export function validate(record) {
    const errors = [];

    if (!record.code || record.code.trim() === "") {
        errors.push({ field: 'code', message: 'O código da unidade é obrigatório!' });
    }

    if ((!record.oidCompany || record.oidCompany.toString().trim() === "" || parseInt(record.oidCompany) <= 0)) {
        errors.push({ field: 'oidCompany', message: 'A empresa da unidade é obrigatória!' });
    }

    const result = {
        ok: errors.length === 0,
        errors,
        record
    }

    return new Promise((resolve, reject) => {
        const action = result.ok ? resolve : reject;
        return action(result);
    })
}