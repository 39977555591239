import React from 'react';

import TowerEventControlFormPageContainer from './TowerEventControlFormPageContainer';

export class TowerEventControlFormPage extends React.Component{

    
    render(){
        return (
           <TowerEventControlFormPageContainer {...this.props} />
        )
    }
}
