import { validate } from './validations';
import { build } from '../../services/crudFactory';
import { Action } from '../../services/actions.factory';
import api from '../../services/api.service';

const BASE_PATH = '/lognex/patiomanagerdock';
const prefix = 'crudPatioManagerDoca';
const crud = build(validate, BASE_PATH);

const newTypes = { 
  //  SET_SELECTED_RECORDS : `${prefix}setSelectedRecords`
  SET_SELECTED_OPERATION_TYPE : `${prefix}setSelectedOperationType`,
  SET_SELECTED_PACKGING_TYPE : `${prefix}setSelectedPackagingType`,
  SET_SELECTED_VEHICLE_TYPE : `${prefix}setSelectedVehicleType`,
  FIND_DOCKS_BY_OWNER : `${prefix}findDoksByOwner`,
  SET_VISIBLE_MODAL_DOCK_CONFIG: `${prefix}setVisibleModalDockConfig`,
  SET_DOCA_VEHICLE_OPE_BACKUP: `${prefix}setDocaVehicleOperationBackup`,
  RESTAURA_DOCA_OPERATION_BACKUP: `${prefix}setRestauraDocaVehicleOperation`,
  SET_EXPANDED_ROW: `${prefix}setExpandedRows`,
}

const actions = new Action(prefix, crud);

const types = Object.assign({}, actions.types, newTypes); 
    


actions.setExpandedRows = (expandedRows) => {
  return {
    type: types.SET_EXPANDED_ROW,
    expandedRows,
  };
};

actions.setRestauraDocaVehicleOperation = (docaVehicleOperation) => {
  return {
    type: types.RESTAURA_DOCA_OPERATION_BACKUP,
    docaVehicleOperation,
  };
};

actions.setDocaVehicleOperationBackup = (docaVehicleOperationBackup) => {
  return {
    type: types.SET_DOCA_VEHICLE_OPE_BACKUP,
    docaVehicleOperationBackup,
  };
};


actions.setSelectedOperationType = (operationTypeSelected) => {
  return {
    type: types.SET_SELECTED_OPERATION_TYPE,
    operationTypeSelected,
  };
};

actions.setSelectedPackagingType = (packagingTypeSelected) => {
  return {
    type: types.SET_SELECTED_PACKGING_TYPE,
    packagingTypeSelected,
  };
};
 
actions.setSelectedVehicleType = (vehicleTypeSelected) => {
  return {
    type: types.SET_SELECTED_VEHICLE_TYPE,
    vehicleTypeSelected,
  };
};
 

actions.findDoksByOwner = (businessUnit,sigla) => {
  return (dispatch, getState) => {  
    api.post("/api/v1/lognex/patiomanagerdock/find-by-owner",{ businessUnit,sigla }).then(({ data }) => {     
      const {records=[]}=data
      dispatch(actions.setRecords(records));      
    }).catch((err)=>{
      console.log(err)
      dispatch(actions.setRecords([])); 
    });     
  };
};

actions.setVisibleModalDockConfig = (visible) => {
  return {
    type: types.SET_VISIBLE_MODAL_DOCK_CONFIG,
    visible,
  };
};

export { types, actions }
 