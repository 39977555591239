
import React from 'react';
import { connect } from 'react-redux';
import { Column } from 'primereact/column';
import Toolbar from "../../../components/toolbar/Toolbar";
import { actions } from '../camapanha-anymarket.actions';
import { routes } from '../../../constants';
import { Page } from "../../../components/page/Page";
import { CampanhaAnymarketForm } from './CampanhaAnymarketForm';


const mapStateToProps = state => {
  const record = state.crudCampanhaAnymarketState.currentRecord;
  return {
    record,
    inputEditProductValueMin: state.crudCampanhaAnymarketState.inputEditProductValueMin,
    inputEditProductValueMax: state.crudCampanhaAnymarketState.inputEditProductValueMax,
  }
}

const mapDispatchToProps = dispatch => {
  return {

    setField: (field, value) => dispatch(actions.setField(field, value)),
    changeHandler: event => {
      const target = event.target;
      const field = target.name;
      let value = target.value
      if (field == "valMin" || field == "valMax") {
        try {
          value = parseFloat(value)
          console.log("value=", value)
        } catch (error) {
          console.log("error=", error)
        }
      }

      dispatch(actions.removeErrors([{ field }]));
      dispatch(actions.setField(field, value));
    }, 
    clearFields: () => {
      dispatch(actions.clearFields());
    },
    
    saveHandler: event => {
      dispatch(actions.save());
    },
    removeHandler: event => {
      dispatch(actions.remove());
      dispatch(actions.clearFields());
    },
    newHandler: event => {
      dispatch(actions.makeNew());
      dispatch(actions.clearFields());
    },
    loadById: oid => {
      dispatch(actions.clearFields());
      dispatch(actions.loadById(oid));
    },
    onchangeInputValueMin: (value) => {
      dispatch(actions.onchangeInputValueMin(value));
    },
    onchangeInputValueMax: (value) => {
      dispatch(actions.onchangeInputValueMax(value));
    },
  }
}

class CampanhaAnymarketFormPageContainer extends React.Component {
  // IMPORTANT!!
  constructor(props) {
    super(props);
    actions.afterSave = ({ oid }) => {
      this.props.history.replace(`${routes.PATH_CAMPANHA_ANYMARKET}/${oid}`);
    };
    actions.afterNew = () => {
      this.props.clearFields();
      this.props.history.replace(`${routes.PATH_CAMPANHA_ANYMARKET}/novo`); 
      this.props.clearFields();
    };
    actions.afterRemove = () => {
      this.props.history.replace(`${routes.PATH_CAMPANHA_ANYMARKET}`);
      this.props.clearFields();
    };
  }

  componentDidMount() {
    const oid = this.props.match.params.id;

    if (oid && parseInt(oid)) {
      this.props.loadById(oid);
    } else {
      this.props.newHandler();
    }
  }
  searchHandler = () => {
    this.props.clearFields();
    this.props.history.push(`${routes.PATH_CAMPANHA_ANYMARKET}`)
  }


  render() {
    const content = (
      <CampanhaAnymarketForm
        changeHandler={this.props.changeHandler}
        record={this.props.record}
        onchangeInputValueMin={this.props.onchangeInputValueMin}
        onchangeInputValueMax={this.props.onchangeInputValueMax}
      />
    );
    const title = `Campanha ${this.props.record.oid || 'novo'}`
    const header = (
      <div>
        <h1>{title}</h1>
        <div>
          <Toolbar
            onSave={this.props.saveHandler} saveButtonStyle='highlighted'
            onRemove={this.props.removeHandler} removeButtonStyle='danger'
            onNew={this.props.newHandler}
            onSearch={this.searchHandler}>
          </Toolbar>
        </div>
      </div>
    );
    return (<Page header={header} content={content} />);
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CampanhaAnymarketFormPageContainer);