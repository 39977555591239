    import { types } from "./componentRangeType.actions";
    import { Reducer } from "../../services/reducer.factory";
    
    const initialState = {
      columns: [{
        field: "oid",
        header: "ID",
        columnKey: "oid",
        sortable: "false",
        filter: "false"
      },{
        field: "name",
        header: "Nome",
        columnKey: "name",
        sortable: "false",
        filter: "false"
      },{
        field: "code",
        header: "Código",
        columnKey: "code",
        sortable: "false",
        filter: "false"
      }],
      currentRecord: {
        oid:null,
        name:"", 
        code:""},
      
    };
    
    const reducer = new Reducer(initialState, types);
    
    export function CRUDComponentRangeTypeReducer(state, action) {
      state = reducer.execute(state, action);
      return execute(state,action);
    }
    
    function execute(state, action){
        let result = state;
        switch(action.type){
          
          
        }
        return result;
    }
   
    
    
    