import React from 'react';
import OccurrenceTypeListPageContainer from './OccurrenceTypeListPageContainer';

export class OccurrenceTypeListPage extends React.Component{
    
    render(){
      return (
        <OccurrenceTypeListPageContainer {...this.props}/>
      )
    }
}