
    import React from 'react';
    import EdiListPageContainer from './EdiListPageContainer';
    
    export class EdiListPage extends React.Component{
        
        render(){
          return (
            <EdiListPageContainer {...this.props}/>
          )
        }
    }