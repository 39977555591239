import React, { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputCurrency } from '../../../../components/input-currency/InputCurrency';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ButtonDialog } from '../../../../components/button-dialog/ButtonDialog';

const EMPTY_ENTRY = { key: "", name: "", value: "" };

export function MultiEntryComponentModalInsert(props) {

    const [records, setRecords] = useState([]);
    const [filteredRecords, setFilteredRecords] = useState([]);
    const [selectedRecords, setSelectedRecords] = useState([]);
    const [currentEntry, setCurrentEntry] = useState(EMPTY_ENTRY);

    const onConfirm = () => {
        //console.log("onConfirm =>")
        props.onConfirm && props.onConfirm(records);
        props.onHide && props.onHide();
    }

    const currentEntryChangeHandler = (event) => {
        const target = event.target;
        const key = target.name;
        let value = target.value || "";
        if (key === 'value' && value.trim().length > 0) {
            value = parseFloat(value);
        }
        setCurrentEntry({ ...currentEntry, [key]: value });
    };

    const onAddEntry = () => {
        //console.log("onAddEntry =>")
        setRecords([...records, currentEntry]);
        setCurrentEntry(EMPTY_ENTRY);
    };

    const onSelectionChangeRecords = (event)=>{
        const {value = []} = event;
        setSelectedRecords(value);
    };
    const onFilterRecords = ({filters})=>{
        console.log(`==> Filters => ${filters}`);
        let filtered = [];
        if(filters && filters.key){
            const {value} =filters.key;
            if(value.trim().length > 0 && records){
                filtered =  records.filter(record => record.key.toLowerCase().startsWith(value.toLowerCase())) || [];
            }
        }
        setFilteredRecords(filtered);
    };
    const onSortRecords = (sortField, sortOrder, multiSortMeta)=>{
        console.log(`==> sort => ${sortField} | ${sortOrder} | ${multiSortMeta}`);
    };

    const onRemove = ()=>{
        //console.log("onRemove =>")
        let currentRecords = records || [];
        let selection = selectedRecords || [];
        currentRecords = currentRecords.filter(current =>{
            const record = selection.find(selected => selected.key === current.key);
            return record == null; 
        })
        setRecords(currentRecords);
        setSelectedRecords([]);
    };

    const recordsColumns = [
        {
            field: "key",
            header: "Código",
            columnKey: "key",
            sortable: false,
            filter: true,
          },
          {
            field: "name",
            header: "Nome",
            columnKey: "name",
            sortable: false,
            filter: false,
          },
          {
            field: "value",
            header: "Valor",
            columnKey: "value",
            sortable: false,
            filter: false,
          }
    ].map( record => <Column key={record.field} {...record}/>);
    recordsColumns.unshift((<Column key="selectorColumn" selectionMode="multiple" style={{ width: '3em' }} />))
    const footer = (
        <div>
            <button
                title="Confirmar"
                className={records.length > 0 ? "lognex-btn-highlighted" : ""}
                onClick={onConfirm}
                disabled={records.length === 0} >
                <i className="pi pi-check"></i>
            </button>

            <button
                title="Cancelar"
                className="lognex-btn-danger"
                onClick={props.onHide} >
                <i className="pi pi-times"></i>
            </button>
        </div>
    )

    return (

        <div>
            <Dialog
                className="lxLookupDialog"
                visible={props.visible}
                style={{ width: "auto" }}
                modal={true}
                onHide={props.onHide}
                header="Adicionar valores"
                footer={footer}
            >
                <div className="p-grid">
                    <div className="p-col-3 form-field-container">
                        <label className="lxRequiredField">Código</label>
                        <InputText value={currentEntry.key} onChange={currentEntryChangeHandler} name="key" />
                    </div>
                    <div className="p-col-3 form-field-container">
                        <label className="lxRequiredField">Nome</label>
                        <InputText value={currentEntry.name} onChange={currentEntryChangeHandler} name="name" />
                    </div>
                    <div className="p-col-3 form-field-container">
                        <label className="lxRequiredField">Valor</label>
                        <InputCurrency currencyCode="R$" value={currentEntry.value} onChange={currentEntryChangeHandler} name="value" disabled={false}> </InputCurrency>
                    </div>
                    <div className="p-col-3 form-field-container" style={{ display: "flex", flexDirection:"row", alignItems: "flex-end", justifyContent: "flex-end" }}>
                        <button
                            title="Incluir"
                            className="lognex-btn-highlighted"
                            onClick={onAddEntry}
                            disabled={false} >
                            <i className="pi pi-plus"></i>
                        </button>
                        <ButtonDialog
                            onClick={onRemove}
                            button={{
                                title: "Remover selecionado(s)",
                                className: "lognex-btn-danger",
                                disabled: selectedRecords == null || selectedRecords.length === 0,
                                style: { marginLeft: ".50em" }
                            }}
                            dialog={{
                                header: 'Confirmação',
                                modal: false,
                                message:  "Deseja realmente remover o(s) registro(s)?",
                            }}
                        ></ButtonDialog>
                    </div>
                    <div className="p-col-12 form-field-container">
                        <DataTable
                            selection={selectedRecords}
                            onSelectionChange={onSelectionChangeRecords}
                            onFilter={onFilterRecords}
                            onSort={onSortRecords}
                            totalRecords={records.length}
                            value={ filteredRecords.length > 0 ? filteredRecords : records}
                        >
                            {recordsColumns}
                        </DataTable>
                    </div>

                </div>
            </Dialog>
        </div>

    )
}