
import React from 'react';
import { connect } from 'react-redux';
import { Column } from 'primereact/column';
import Toolbar from "../../../components/toolbar/Toolbar";
import { actions } from '../product.actions';
import { routes } from '../../../constants';
import { Page } from "../../../components/page/Page";
import { ProductForm } from './ProductForm';
import { actions as productActions } from '../../product/product.actions';


const mapStateToProps = state => {
  const record = state.crudProductState.currentRecord;

  const lookupProductParentBase = {
    records: state.crudProductState.records,
    columns: state.crudProductState.columns
      .filter(col => col.field === 'oid' || col.field === 'name')
      .map(col => <Column key={col.field} {...col} />),
    first: state.crudProductState.index,
    rows: state.crudProductState.max,
    totalRecords: state.crudProductState.count,
    sortField: state.crudProductState.sortField,
    order: state.crudProductState.sortOrder,
    editDisabled: !record.oidProduct
  }
  let lookupProductParent = Object.assign({}, state.crudProductState.lookupProductParent, lookupProductParentBase);

  return {
    record
    , lookupProductParent

  }
}

const mapDispatchToProps = dispatch => {
  return {

    lookupProductParentSetVisible: visible => dispatch(actions.setLookupProductParentVisible(visible)),
    lookupProductParentPaginate: paginationParams => dispatch(productActions.paginate(paginationParams)),
    lookupProductParentSortOrder: sortParams => dispatch(productActions.sortOrder(sortParams)),
    lookupProductParentApplyFilter: filterParams => {
      dispatch(productActions.setType(null));
      dispatch(productActions.applyFilter(filterParams))
    },
    lookupProductParentLoad: () => dispatch(productActions.load()),
    setProductParent: (productParent) => {
      dispatch(actions.setField('oidProductParent', productParent != null ? productParent.oid : null));
      dispatch(actions.setField('productParentName', productParent != null ? productParent.name : ''));
    },

    setField: (field, value) => dispatch(actions.setField(field, value)),
    changeHandler: event => {
      const target = event.target;
      const field = target.name;
      let value = target.value;
      dispatch(actions.removeErrors([{ field }]));
      dispatch(actions.setField(field, value));
    },
    saveHandler: event => {
      dispatch(actions.save());
    },
    removeHandler: event => {
      dispatch(actions.remove());
    },
    newHandler: event => {
      dispatch(actions.makeNew());
    },
    loadById: oid => dispatch(actions.loadById(oid))
  }
}

class ProductFormPageContainer extends React.Component {

  constructor(props) {
    super(props);
    actions.afterSave = ({ oid }) => {
      this.props.history.replace(`${routes.PATH_PRODUCT}/${oid}`);
    };
    actions.afterNew = () => {
      this.props.history.replace(`${routes.PATH_PRODUCT}/novo`);
    };
    actions.afterRemove = () => {
      this.props.history.replace(`${routes.PATH_PRODUCT}`);
    };
  }

  componentDidMount() {
    const oid = this.props.match.params.id;

    if (oid && parseInt(oid)) {
      this.props.loadById(oid);
    } else {
      this.props.newHandler();
    }
  }
  searchHandler = () => {
    this.props.history.push(`${routes.PATH_PRODUCT}`)
  }

  lookupProductParentOnPage = (e) => {
    this.props.lookupProductParentPaginate({
      max: e.rows,
      index: e.first
    })
  }
  lookupProductParentOnSort = (e) => {
    console.log('sorting', e);
    this.props.lookupProductParentSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField
    })


  }
  lookupProductParentOnFilter = (e) => {
    const filters = [];
    const keys = Object.keys(e.filters) || [];
    keys.forEach(key => {
      filters.push({
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: 'CONTEM'

      })
    })
    console.log(filters);
    this.props.lookupProductParentApplyFilter(filters)

  }
  lookupProductParentOnClick = (e) => {
    this.props.lookupProductParentLoad();
    this.props.lookupProductParentSetVisible(true);
  }
  lookupProductParentOnHide = (e) => {
    this.props.lookupProductParentSetVisible(false);
  }
  lookupProductParentOnConfirm = (e) => {
    console.log(e);
    this.props.setProductParent(e.selection);
    this.props.lookupProductParentSetVisible(false);

  }
  lookupProductParentOnCancel = (e) => {
    this.props.setProductParent(null);
    this.props.lookupProductParentSetVisible(false);
  }
  lookupProductParentOnEdit = () => {
    if (this.props.record.oidPessoa) {
      this.props.history.push(`${routes.PATH_PRODUCTPARENT}/${this.props.record.oidProductParent}`);
    }
  }

  lookupProductParentOnComplete = e => {
    console.log(e);
  };

  lookupProductParentOnInputFilter = (e) => {
    const value = e.value;
    if (typeof value === 'string') {
      const filters = [{
        campo: "name",
        valor: e.value,
        tipoFiltro: 'CONTEM'

      }];
      this.props.setField("productParentName", e.value);
      this.props.setField("oidProductParent", null);
      this.props.lookupProductParentApplyFilter(filters)
    } else {
      this.props.setProductParent(value);
    }
  }

  render() {
    const content = (
      <ProductForm
        changeHandler={this.props.changeHandler}
        record={this.props.record}

        lookupProductParent={this.props.lookupProductParent}
        lookupProductParentOnHide={this.lookupProductParentOnHide}
        lookupProductParentOnConfirm={this.lookupProductParentOnConfirm}
        lookupProductParentOnCancel={this.lookupProductParentOnCancel}
        lookupProductParentOnPage={this.lookupProductParentOnPage}
        lookupProductParentOnSort={this.lookupProductParentOnSort}
        lookupProductParentOnFilter={this.lookupProductParentOnFilter}
        lookupProductParentOnClick={this.lookupProductParentOnClick}
        lookupProductParentOnEdit={this.lookupProductParentOnEdit}
        lookupProductParentOnComplete={this.lookupProductParentOnComplete}
        lookupProductParentOnInputFilter={this.lookupProductParentOnInputFilter}

      />
    );
    const title = `Product ${this.props.record.oid || 'novo'}`
    const header = (
      <div>
        <h1>{title}</h1>
        <div>
          <Toolbar
            onSave={this.props.saveHandler} saveButtonStyle='highlighted'
            onRemove={this.props.removeHandler} removeButtonStyle='danger'
            onNew={this.props.newHandler}
            onSearch={this.searchHandler}>
          </Toolbar>
        </div>
      </div>
    );
    return (<Page header={header} content={content} />);
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductFormPageContainer);