import React from 'react'

import UserDocumentTypeFormPageContainer from './UserDocumentTypeFormPageContainer'

export class UserDocumentTypeFormPage extends React.Component{
    render(){
        return (
            <UserDocumentTypeFormPageContainer {...this.props} />
        )
    }
}