import React from 'react';
import DeliveryListPageContainer from './DeliveryListPageContainer';

export class DeliveryListPage extends React.Component{
    
    render(){
      return (
        <DeliveryListPageContainer {...this.props}/>
      )
    }
}