import React from 'react';
import TowerTravelListPageContainer from './TowerTravelListPageContainer';

export class TowerTravelListPage extends React.Component{
    
    render(){
      return (
        <TowerTravelListPageContainer {...this.props}/>
      )
    }
}

