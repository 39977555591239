
import React from 'react';
import { InputText } from 'primereact/inputtext';

export const PalletStandardForm = (props) => {
    console.log('PalletStandardForm');

    return (
        <div className="palletStandard-form">
            <div className="p-grid">
                <div className="p-col-6">
                    <div className="p-grid">

                        <div className="p-col-12 form-field-container">
                            <label>Código</label>
                            <InputText value={props.record.oid} onChange={props.changeHandler} name="oid" disabled={true} />
                        </div>
                        <div className="p-col-12 form-field-container">
                            <label>Nome*</label>
                            <InputText value={props.record.name} onChange={props.changeHandler} name="name" disabled={false} />
                        </div>
                        <div className="p-col-12 form-field-container">
                            <label>Altura*</label>
                            <InputText type="number" value={props.record.height} onChange={props.changeHandler} name="height" disabled={false} />
                        </div>
                        <div className="p-col-12 form-field-container">
                            <label>Largura*</label>
                            <InputText type="number" value={props.record.width} onChange={props.changeHandler} name="width" disabled={false} />
                        </div>
                        <div className="p-col-12 form-field-container">
                            <label>Profundidade*</label>
                            <InputText type="number" value={props.record.depth} onChange={props.changeHandler} name="depth" disabled={false} />
                        </div>
                        <div className="p-col-12 form-field-container">
                            <label>Peso máximo*</label>
                            <InputText type="number" value={props.record.maxWeight} onChange={props.changeHandler} name="maxWeight" disabled={false} />
                        </div>
                        <div className="p-col-12 form-field-container">
                            <label>Altura máxima*</label>
                            <InputText type="number" value={props.record.maxHeight} onChange={props.changeHandler} name="maxHeight" disabled={false} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};