import { types } from "./holiday.actions";
import { Reducer } from "../../services/reducer.factory";


const initialState = {
  columns: [
    {
      field: "oid",
      header: "Código",
      columnKey: "oid",
      sortable: false,
      filter: true
    },
    {
      field: "date",
      header: "Data",
      columnKey: "date",
      sortable: false,
      filter: true
    },
    {
      field: "description",
      header: "Descrição",
      columnKey: "description",
      sortable: false,
      filter: true
    },
  ],

  currentRecord: {
    oid: null,
    date: "",
    description: "",
  },
  records: []
}

const reducer = new Reducer(initialState, types);

export function CRUDHolidayReducer(state, action) {
  state = reducer.execute(state, action)
  return execute(state, action)
}

function execute(state, action) {
  let result = state;
  switch (action.type) {
    case types.SET_ONCHANGE_HOLIDAY:
      return onChangeHoliday(state, action);
    default:
      return result;
  }
}

function onChangeHoliday(state,action){
  let currentRecord = state.currentRecord;
  currentRecord.date = action.date;
  currentRecord.description = action.description;
  return Object.assign({}, state, { currentRecord });
}