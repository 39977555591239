import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Toolbar from "../../../components/toolbar/Toolbar";
import { Dropdown } from "primereact/dropdown";
import { Column } from "primereact/column";
import { routes } from "../../../constants";
import { Page } from "../../../components/page/Page";
import { PerfilList } from "./PerfilList";
import { actions } from "../perfil.actions";
import { AppService, COMPANY_CHANGE_EVENT_NAME, BUSINESS_UNIT_CHANGE_EVENT_NAME } from "../../../services/app.service";
import { actions as appActions } from '../../../store/app';
import * as menuActions from '../../../store/menu/menu.actions';
import * as utils from '../../../utils'

const mapStateToProps = state => {
  const currentMenu = state.menuState.currentRecord;
  const userConfigurations = state.appState.userConfigurations;
  let isFavorite = utils.isFavorite(currentMenu, userConfigurations);
  return {
    columns: state.crudPerfilState.columns,
    records: state.crudPerfilState.records,
    first: state.crudPerfilState.index,
    rows: state.crudPerfilState.max,
    totalRecords: state.crudPerfilState.count,
    sortField: state.crudPerfilState.sortField,
    order: state.crudPerfilState.sortOrder,
    menuItems: state.menuState.items,
    currentMenu,
    isFavorite,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    paginate: paginationParams => dispatch(actions.paginate(paginationParams)),
    sortOrder: sortParams => dispatch(actions.sortOrder(sortParams)),
    applyFilter: filterParams => {
      dispatch(actions.applyFilter(filterParams));
    },
    changeBooleanFilter: (e) => {
      const target = e.target;

      dispatch(actions.setBooleanFilter(target.name, target.value));
      let filters = [];
      if (target.value || target.value === false) {
        filters = [{
          campo: `${target.name}`,
          valor: target.value,
          tipoFiltro: 'IGUAL_A'
        }];
      }
      dispatch(actions.applyFilter(filters));

    },
    load: () => dispatch(actions.load()),
    userLastAccess: (oidMenu) => dispatch(appActions.userLastAccess(oidMenu)),
    setCurrentMenu: (menuPath) => dispatch(menuActions.setCurrentRecord(menuPath)),
    toggleFavorite: (isFavorite, oidMenu) => {
      dispatch(appActions.toggleFavorite(isFavorite, oidMenu))
    }
  };
};

class PerfilListPageContainer extends React.Component {
  newHandler = () => {
    this.props.history.push(`${routes.PATH_PERFIL}/novo`);
  };

  componentWillReceiveProps(newProps) {
    if ((!this.props.menuItems || this.props.menuItems.length === 0) && newProps.menuItems && newProps.menuItems.length > 0) {
      const menuPath = this.props.history.location.pathname;
      this.props.setCurrentMenu(menuPath)
    }

    if ((!this.props.currentMenu && newProps.currentMenu) ||
      (this.props.currentMenu !== newProps.currentMenu)) {
      const oidMenu = newProps.currentMenu.oid;
      this.props.userLastAccess(oidMenu)
    }
  }

  componentDidMount() {
    this.props.load();
    AppService.on(COMPANY_CHANGE_EVENT_NAME, this);
    AppService.on(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
    const menuPath = this.props.history.location.pathname;
    this.props.setCurrentMenu(menuPath)
  }
  componentWillUnmount() {
    AppService.off(COMPANY_CHANGE_EVENT_NAME, this);
    AppService.off(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
  }

  onReceiveAppNotifications = (eventName, params) => {
    if (eventName === COMPANY_CHANGE_EVENT_NAME || eventName === BUSINESS_UNIT_CHANGE_EVENT_NAME) {
      this.props.load();
    }
  }

  onPage = e => {
    this.props.paginate({
      max: e.rows,
      index: e.first
    });
  };
  onSort = e => {
    this.props.sortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField
    });
  };
  onFilter = e => {
    const filters = [];
    const keys = Object.keys(e.filters) || [];
    keys.forEach(key => {
      filters.push({
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: "CONTEM"
      });
    });
    this.props.applyFilter(filters);
  };
  onRowClick = (e) => {
    const oid = e && e.data && e.data.oid;
    if (oid) {
      this.props.history.push(`${routes.PATH_PERFIL}/${oid}`)
    }
  }

  onFavorite = (e) => {
    if (this.props.currentMenu) {
      const oidMenu = this.props.currentMenu.oid
      const isFavorite = this.props.isFavorite;
      this.props.toggleFavorite(isFavorite, oidMenu)
    }
  }

  render() {
    const isFavorite = this.props.isFavorite;
    const header = (
      <div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h1>Perfis</h1>

          <i className={`pi pi-star lxFavoriteStar ${isFavorite ? 'lxFavorite' : ''}`}
            title={isFavorite ? 'Remover dos favoritos' : 'Adicionar aos favoritos'} onClick={this.onFavorite}></i>
        </div>
        <hr className="lxSplitter" ></hr>
        <div>
          <Toolbar onNew={this.newHandler} newButtonStyle='highlighted'>
          </Toolbar >
        </div>
      </div>
    );





    const columns = this.props.columns.map(col => {
      return <Column {...col} key={col.field} />;
    });

    const content = (
      <div>
        <PerfilList
          {...this.props}
          onRowClick={this.onRowClick}
          onFilter={this.onFilter}
          onPage={this.onPage}
          onSort={this.onSort}
          columns={columns}
        />
      </div>
    );
    return <Page header={header} content={content} />;
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PerfilListPageContainer);
