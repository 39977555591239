import React from "react";
import { connect } from "react-redux";
import Toolbar from "../../../components/toolbar/Toolbar";
import { Column } from 'primereact/column';
import { routes } from '../../../constants';
import { Page } from "../../../components/page/Page";
import { AppService, COMPANY_CHANGE_EVENT_NAME, BUSINESS_UNIT_CHANGE_EVENT_NAME } from "../../../services/app.service";
import { actions } from '../userdocumenttype.actions'
import { actions as appActions } from '../../../store/app';
import * as menuActions from '../../../store/menu/menu.actions';
import * as utils from '../../../utils'
import { UserDocumentTypeList} from './UserDocumentTypeList'

const mapStateToProps = state => {
    const currentMenu = state.menuState.currentRecord;
    const userConfigurations = state.appState.userConfigurations;
    let isFavorite = utils.isFavorite(currentMenu, userConfigurations);
    return {
        columns: state.crudUserDocumentTypeState.columns.filter((col) => col.field != "lognexDocumentType.name"),
        records: state.crudUserDocumentTypeState.records,
        first: state.crudUserDocumentTypeState.index,
        rows: state.crudUserDocumentTypeState.max,
        totalRecords: state.crudUserDocumentTypeState.count,
        sortField: state.crudUserDocumentTypeState.sortField,
        order: state.crudUserDocumentTypeState.sortOrder,
        menuItems: state.menuState.items,
        currentMenu,
        isFavorite
    }
}

const mapDispatchToProps = dispatch => {
    return {
        paginate: paginationParams => dispatch(actions.paginate(paginationParams)),
        sortOrder: sortParams => dispatch(actions.sortOrder(sortParams)),
        applyFilter: filterParams => {
            dispatch(actions.setType(null));
            dispatch(actions.applyFilter(filterParams))
        },
        load: () => dispatch(actions.load()),
        userLastAccess: (oidMenu) => dispatch(appActions.userLastAccess(oidMenu)),
        setCurrentMenu: (menuPath) => dispatch(menuActions.setCurrentRecord(menuPath)),
        toggleFavorite: (isFavorite, oidMenu) => {
            dispatch(appActions.toggleFavorite(isFavorite, oidMenu))
        }
    }
}

class UserDocumentTypeFormPageContainer extends React.Component{

    newHandler = () => {
        this.props.history.push(`${routes.PATH_USER_DOCUMENT_TYPE}/novo`)
      }

    componentWillReceiveProps(newProps) {
        if ((!this.props.menuItems || this.props.menuItems.length === 0) && newProps.menuItems && newProps.menuItems.length > 0) {
          const menuPath = this.props.history.location.pathname;
          this.props.setCurrentMenu(menuPath)
        }
    
        if ((!this.props.currentMenu && newProps.currentMenu) ||
          (this.props.currentMenu !== newProps.currentMenu)) {
          const oidMenu = newProps.currentMenu.oid;
          this.props.userLastAccess(oidMenu)
        }
      }

    componentDidMount() {
      this.props.load();
      this.props.applyFilter([])
      AppService.on(COMPANY_CHANGE_EVENT_NAME, this);
      AppService.on(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
      const menuPath = this.props.history.location.pathname;
      this.props.setCurrentMenu(menuPath)
    }
    componentWillUnmount() {
      AppService.off(COMPANY_CHANGE_EVENT_NAME, this);
      AppService.off(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
    }
  
    onReceiveAppNotifications = (eventName, params) => {
      if (eventName === COMPANY_CHANGE_EVENT_NAME || eventName === BUSINESS_UNIT_CHANGE_EVENT_NAME) {
        this.props.applyFilter([])
        this.props.load();
      }
    }

    onPage = (e) => {
        this.props.paginate({
          max: e.rows,
          index: e.first
        })
    }
    onSort = (e) => {
      this.props.sortOrder({
        sortOrder: e.sortOrder,
        sortField: e.sortField
      })
    }
  
    onFilter = (e) => {
      const filters = [];
      const keys = Object.keys(e.filters) || [];
      keys.forEach(key => {
        filters.push({
          campo: key,
          valor: e.filters[key].value,
          tipoFiltro: 'CONTEM'
  
        })
      })
      this.props.applyFilter(filters)
  
    }

    onRowClick = (e) => {
        const oid = e && e.data && e.data.oid;
        if (oid) {
          this.props.history.push(`${routes.PATH_USER_DOCUMENT_TYPE}/${oid}`)
        }
    }
    
    onFavorite = (e) => {
        if (this.props.currentMenu) {
          const oidMenu = this.props.currentMenu.oid
          const isFavorite = this.props.isFavorite;
          this.props.toggleFavorite(isFavorite, oidMenu)
        }
    }

    render(){
        const isFavorite = this.props.isFavorite;
        const header = (
            <div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h1>Tipo de documentos</h1>
      
                <i className={`pi pi-star lxFavoriteStar ${isFavorite ? 'lxFavorite' : ''}`}
                  title={isFavorite ? 'Remover dos favoritos' : 'Adicionar aos favoritos'} onClick={this.onFavorite}></i>
              </div>
              <hr className="lxSplitter" ></hr>
              <div>
                <Toolbar 
                    onNew={this.newHandler} 
                    newButtonStyle='highlighted'
                >
                </Toolbar >
              </div>
            </div>
        );

        const columns = this.props.columns.map(col => {
            return (<Column {...col} key={col.field} />)
        })

        const content = <UserDocumentTypeList 
                            {...this.props} 
                            onRowClick={this.onRowClick} 
                            onFilter={this.onFilter} 
                            onPage={this.onPage} 
                            onSort={this.onSort} 
                            columns={columns}/>
        return <Page header={header} content={content} />;

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserDocumentTypeFormPageContainer)