import { types } from "./packing-converter.actions"
import { Reducer } from "../../services/reducer.factory"

const initialState = {
    columns: [
        {
            field: "oid",
            header: "Código",
            columnKey: "oid",
            sortable: false,
            filter: false
        },
        {
            field: "packing1.name",
            header: "Nome da Embalagem 1",
            columnKey: "packing1.name",
            sortable: false,
            filter: false
        },
        {
            field: "packing1.length",
            header: "Tamanho Embalagem 1",
            columnKey: "packing1.length",
            sortable: false,
            filter: false
        },
        {
            field: "packing2.name",
            header: "Nome da Embalagem 2",
            columnKey: "packing2.name",
            sortable: false,
            filter: false
        },
        {
            field: "packing2.length",
            header: "Tamanho Embalagem 2",
            columnKey: "packing2.length",
            sortable: false,
            filter: false
        },
        {
            field: "productCode",
            header: "Código Produto",
            columnKey: "productCode",
            sortable: false,
            filter: false
        }
    ],
    currentRecord: {
        oid: "",
        packing1: { name: "", length: 1 },
        packing2: { name: "", length: 0 },
        productCode:"",
    },
    lookupPackingFilters: {
        lookupPacking1: {
            visible: false,
            modal: false,
            header: "Embalagem 1"
        },
        lookupPacking2: {
            visible: false,
            modal: false,
            header: "Embalagem 2"
        }
    }
}

const reducer = new Reducer(initialState, types);

export function CRUDPackingConverterReducer(state, action) {
    state = reducer.execute(state, action)
    return execute(state, action)
}


function execute(state, action) {
    let result = state
    switch (action.type) {
        case types.PACKING1_SET_VISIBLE:
            return packing1SetVisible(state, action)
        case types.SET_PACKING1:
            return setPacking1(state, action)
        case types.PACKING2_SET_VISIBLE:
            return packing2SetVisible(state, action)
        case types.SET_PACKING2:
            return setPacking2(state, action)
        default:
            return result
    }
}

function packing1SetVisible(state, action) {
    let lookupPacking1 = state.lookupPackingFilters.lookupPacking1
    lookupPacking1.visible = action.visible
    return { ...state, lookupPacking1 }

}

function setPacking1(state, action) {
    let currentRecord = state.currentRecord
    let packing1 = currentRecord.packing1
    packing1 = { ...packing1, ...action.packing1 }
    currentRecord = { ...currentRecord, packing1 }
    return { ...state, currentRecord }
}

function packing2SetVisible(state, action) {
    let lookupPacking2 = state.lookupPackingFilters.lookupPacking2
    lookupPacking2.visible = action.visible
    return { ...state, lookupPacking2 }

}

function setPacking2(state, action) {
    let currentRecord = state.currentRecord;
    let packing2 = currentRecord.packing2;
    packing2 = {...packing2, ...action.packing2};
    currentRecord = {...currentRecord, packing2};
    return { ...state, currentRecord }
}