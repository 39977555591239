import React from "react";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import "./index.scss";
import { ButtonDialog } from "../../../../components/button-dialog/ButtonDialog";
import { TableVehicles } from "./TableVehicles";
import { numberToCurrency } from "../../../../utils";
import { getCDsFromRouter } from "../../utils";

export default class ModalChangeVehicle extends React.Component {
  constructor(props) {
    super(props);
  }
  onSelecteVehicleType = (e) => {
    this.props.setVehicleToChange(e.value);
  };
  rowClickHandler = (e) => {
    this.props.setVehicleToChange(e.data);
  };
  render() {
    const footer = (
      <div>
        <ButtonDialog
          onClick={() => this.props.onConfirm()}
          button={{
            title: "Confirmar",
            disabled: !this.props.vehicleSelected,
            className: "lognex-btn-highlighted",
            iconClassName: "pi pi-check",
          }}
          dialog={{
            header: "Confirmação",
            modal: false,
            message: "Deseja realmente alterar o tipo de veículo na rota?",
          }}
        ></ButtonDialog>

        <button title="Cancelar" className="lognex-btn-danger" onClick={this.props.onHide}>
          <i className="pi pi-times"></i>
        </button>
      </div>
    );

    const templateCustoKm = (rowData, e) => {
      return <span>{numberToCurrency(parseFloat(rowData.custoKm), "R$")}</span>;
    };

    let columns = [<Column key="selectorColumnVehicleType" selectionMode="single" style={{ width: "3em" }} />];
    columns = [
      ...columns,
      ...this.props.columns.map((col) => {
        if (col.field == "custoKm") {
          return <Column {...col} key={col.field} body={templateCustoKm} style={{ width: "120px" }} />;
        }
        if (col.field == "cd") {
          return <Column {...col} key={col.field} style={{ width: "70px" }} />;
        }
        if (col.field == "weight") {
          return <Column {...col} key={col.field} style={{ width: "80px" }} />;
        }
        if (col.field == "pallets") {
          return <Column {...col} key={col.field} style={{ width: "80px" }} />;
        }

        return <Column {...col} key={col.field} />;
      }),
    ];

    //mostrar somente veiculos que são dos CDs das Ordens
    let vehicles = this.props.vehicles;
    const listCds = getCDsFromRouter(this.props.routeEditVehicle); 

    //filtrar veiculos por CD
    vehicles = vehicles.filter((v) => listCds.indexOf(String(v.cd).trim()) > -1);

    let isDevolucao = false;
    for (let index = 0; index < this.props.routeEditVehicle.shipments.length; index++) {
      if (this.props.routeEditVehicle.shipments[index].dv && this.props.routeEditVehicle.shipments[index].dv == "dv") {
        isDevolucao = true
        break
      }
    }
    if (isDevolucao) {
      let vehiclesAux = []//deixar somente u
      for (let index = 0; index < vehicles.length; index++) { 
        if (vehiclesAux.filter(va => va.cd === vehicles[index].cd && va.code === vehicles[index].code).length == 0) {
          const vehicle ={...vehicles[index],label:"Devolução"}
          vehiclesAux.push(vehicle);
        }
      }
      vehicles = vehiclesAux
    }
    vehicles.sort((a, b) => {
      return a.pallets - b.pallets;
    });

    return (
      <Dialog
        className="content"
        header={"Alterar o tipo de veículo da rota"}
        footer={footer}
        visible={this.props.visible}
        modal={true}
        onHide={this.props.onHide}
        style={this.props.style || { width: "60vw" }}
      >
        <div style={{ minHeight: "300px" }}>
          <h3>Selecione um veículo para o CD e depósito!</h3>
          <TableVehicles
            records={vehicles}
            columns={columns}
            selectedRow={this.props.vehicleSelected}
            rowClickHandler={this.rowClickHandler}
            onSelectedEventsChange={this.onSelecteVehicleType}
          />
        </div>
      </Dialog>
    );
  }
}
