import React from 'react'; 
import DashboardNfeListPageContainer from './DashboardNfeListPageContainer';

export class DashboardNfeListPage extends React.Component{
    
    render(){
      return (
        <DashboardNfeListPageContainer {...this.props}/>
      )
    }
}