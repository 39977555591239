import { types } from "./camapanha-anymarket.actions";
import { Reducer } from "../../services/reducer.factory";

const initialState = {
  columns: [
    {
      field: "oid",
      header: "Código",
      columnKey: "oid",
      sortable: false,
      filter: false
    }, {
      field: "dataInicio",
      header: "Data Inicio",
      columnKey: "dataInicio",
      sortable: false,
      filter: false
    }, {
      field: "dataFim",
      header: "Data Fim",
      columnKey: "dataFim",
      sortable: false,
      filter: false
    },
    {
      field: "active",
      header: "Ativo",
      columnKey: "active",
      sortable: false,
      filter: false
    }, 
    {
      field: "maketplaceName",
      header: "Marketplace",
      columnKey: "maketplaceName",
      sortable: false,
      filter: false
    },
    {
      field: "descricao",
      header: "Descrição",
      columnKey: "descricao",
      sortable: false,
      filter: false
    },    
    {
      field: "percentDesconto",
      header: "% off",
      columnKey: "percentDesconto",
      sortable: false,
      filter: false
    },
  ],

  productColumns: [
    {
      field: "sku",
      header: "SKU",
      columnKey: "sku",
      sortable: false,
      filter: false
    }, 
    {
      field: "remove",
      header: "",
      columnKey: "remove",
      sortable: false,
      filter: false
    }
  ],
  zipCodeRangeColumns: [
    {
      field: "start",
      header: "Inicio",
      columnKey: "start",
      sortable: false,
      filter: false
    },
    {
      field: "end",
      header: "Fim",
      columnKey: "end",
      sortable: false,
      filter: false
    },
    {
      field: "remove",
      header: "",
      columnKey: "remove",
      sortable: false,
      filter: false
    }
  ],
  currentRecord: {
    oid: null,
    descricao:"",
    zipCodeRange: [],
    active: true,
    product: [],
    maketplaceName: null,
    dataInicio: null,
    dataFim: null,
    actionType: {
      percentDesconto: 0,
    },
    valMin:null,
    valMax:null
  },
  currentRecordDefault: {
    oid: null,
    descricao:"",
    zipCodeRange: [],
    active: true,
    product: [],
    maketplaceName: null,
    dataInicio: null,
    dataFim: null,
    actionType: {
      percentDesconto: 0,
    },
    valMin:null,
    valMax:null
  },
  
  records: [],
  inputEditSku: "", 

  inputZipCodeStart: "",
  inputZipCodeEnd: ""

};

const reducer = new Reducer(initialState, types);

export function CRUDCampanhaAnymarketReducer(state, action) {
  state = reducer.execute(state, action);
  return execute(state, action);
}

function execute(state, action) {
  let result = state;
  switch (action.type) {
    case types.SET_INPUT_SKU:
      return setInputSku(state, action);
    case types.SET_INPUT_VALUE_MIN_PRODUCT:
    case types.ADD_NEW_PRODUCT:
      return addNewProduct(state, action);
    case types.ADD_REMOVE_PRODUCT:
      return removeProduct(state, action);
    case types.SET_INPUT_ZIP_CODE_START:
      return setInputZipCodeStart(state, action);
    case types.SET_INPUT_ZIP_CODE_END:
      return setInputZipCodeEnd(state, action);
    case types.ADD_NEW_ZIP_CODE_RANGE:
      return addNewZipCodeRange(state, action);
    case types.REMOVE_ZIP_CODE_RANGE:
      return onRemoveZipCodeRange(state, action);
      case types.ADD_CLEAR_FIELDS:
        return clearFields(state, action);
    default:
      return result;
  }

}


function clearFields(state, action) {  
     return Object.assign({}, state, { currentRecord:{...state.currentRecordDefault}, inputZipCodeEnd: "", inputZipCodeStart: "" });
  } 

function onRemoveZipCodeRange(state, action) {
  let zipcodeRange = action.zipcodeRange;
  if (zipcodeRange.start > 0 && zipcodeRange.end > 0) {
    let currentRecord = state.currentRecord;

    const zipCodeRanges = currentRecord.zipCodeRange.filter(p => p.start != zipcodeRange.start || p.end != zipcodeRange.end)
    currentRecord.zipCodeRange = zipCodeRanges
    return Object.assign({}, state, { currentRecord, inputZipCodeEnd: "", inputZipCodeStart: "" });
  }
}
function addNewZipCodeRange(state, action) {
  let inputZipCodeStart = Number(state.inputZipCodeStart);
  let inputZipCodeEnd = Number(state.inputZipCodeEnd);

  if (inputZipCodeStart > 0 && inputZipCodeEnd > 0) {
    let currentRecord = state.currentRecord;

    if (!currentRecord.zipCodeRange) {
      currentRecord.zipCodeRange = []
    }
    if (currentRecord.zipCodeRange.filter(p => p.start == inputZipCodeStart && p.end == inputZipCodeEnd).length == 0) {
      currentRecord.zipCodeRange.push({ start: inputZipCodeStart, end: inputZipCodeEnd })
    }

    return Object.assign({}, state, { currentRecord, inputZipCodeEnd: "", inputZipCodeStart: "" });
  }
}

function setInputZipCodeStart(state, action) {
  let inputZipCodeStart = String(action.zipcode).trim();
  inputZipCodeStart = inputZipCodeStart.replace(/[^0-9]/g, "");
  if (inputZipCodeStart) {
    inputZipCodeStart = Number(inputZipCodeStart)
  }
  return Object.assign({}, state, { inputZipCodeStart });
}
function setInputZipCodeEnd(state, action) {
  let inputZipCodeEnd = String(action.zipcode).trim();
  inputZipCodeEnd = inputZipCodeEnd.replace(/[^0-9]/g, "");
  if (inputZipCodeEnd) {
    inputZipCodeEnd = Number(inputZipCodeEnd)
  }
  return Object.assign({}, state, { inputZipCodeEnd });
}


function addNewProduct(state, action) {
  let inputEditSku = String(state.inputEditSku).trim(); 
  if (inputEditSku.length > 0 ) {
    let currentRecord = state.currentRecord;

    if (!currentRecord.product) {
      currentRecord.product = []
    }
    if (currentRecord.product.filter(p => p.sku == inputEditSku).length == 0) { 
      currentRecord.product.push({ sku: inputEditSku })
    }

    return Object.assign({}, state, { currentRecord, inputEditSku: "" });
  }
}


function removeProduct(state, action) {
  let sku = String(action.sku).trim();
  if (sku.length > 0) {
    let currentRecord = state.currentRecord;

    const listProd = currentRecord.product.filter(p => p.sku != sku)
    currentRecord.product = listProd
    return Object.assign({}, state, { currentRecord, inputEditSku: "" });
  }
}

function setInputSku(state, action) {
  let inputEditSku = state.inputEditSku;
  inputEditSku = String(action.sku).trim();
  return Object.assign({}, state, { inputEditSku });
}
  


