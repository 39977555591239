import React from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'

export const PackingConverterList = (props) => (
    <DataTable
        className="lxPackingConverterTable"
        selectionMode="single"
        onFilter={props.onFilter}
        onSort={props.onSort}
        onPage={props.onPage}
        value={props.records}
        lazy={true}
        totalRecords={props.totalRecords}
        sortOrder={props.order}
        sortField={props.sortField}
        paginator={true}
        first={props.first}
        rows={props.rows}
        responsive={true}
        emptyMessage="Não foram encontrados registros"
        rowsPerPageOptions={[5, 10, 20, 50]}
        onRowClick={props.onRowClick}
    >
        
    {[<Column key={"expander"} expander={true} style={{width: '4em'}} />,...props.columns]}

    </DataTable>
)