import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Column } from "primereact/column";
import { EventList } from "./EventList";
import { actions } from "../tower_equipment_type.actions";
import { ButtonDialog } from "../../../components/button-dialog/ButtonDialog";
import "./event_list.scss";

const mapStateToProps = (state) => {
  return {
    columns: state.crudTowerEquipmentTypeState.events.columns,
    records: state.crudTowerEquipmentTypeState.currentRecord.events || [],
    first: state.crudTowerEquipmentTypeState.index,
    rows: state.crudTowerEquipmentTypeState.max,
    totalRecords: state.crudTowerEquipmentTypeState.count,
    sortField: state.crudTowerEquipmentTypeState.sortField,
    order: state.crudTowerEquipmentTypeState.sortOrder,
    selectedRecords: state.crudTowerEquipmentTypeState.selectedRecords,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    paginate: (paginationParams) => dispatch(actions.paginate(paginationParams)),
    sortOrder: (sortParams) => dispatch(actions.sortOrder(sortParams)),
    applyFilter: (filterParams) => {
      dispatch(actions.setType(null));
      dispatch(actions.applyFilter(filterParams));
    },
    load: () => {
      dispatch(actions.load());
    },
    setSelectedRecords: (selectedRecords) => {
      dispatch(actions.setSelectedRecords(selectedRecords));
    },
    onRemoveEventsSelected: () => {
      dispatch(actions.onRemoveEventsSelected());
    },
  };
};

class EventListPageContainer extends React.Component {
  onSelectedEventsChangeHandler = (e) => {
    this.props.setSelectedRecords(e.value);
  };

  onPage = (e) => {
    this.props.paginate({
      max: e.rows,
      index: e.first,
    });
  };
  onSort = (e) => {
    this.props.sortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField,
    });
  };

  onRemoveEventsSelected = (e) => {
    this.props.onRemoveEventsSelected();
  };

  render() {
    const showBtnRemover = this.props.selectedRecords.length > 0;

    let columns = [<Column key="selectorColumn" selectionMode="multiple" style={{ width: "3em" }} />];
    columns = [
      ...columns,
      ...this.props.columns.map((col) => {
        return <Column {...col} key={col.field} />;
      }),
    ];

    return (
      <Fragment>
        <div className={"content-top"}>
          
            <ButtonDialog
              onClick={this.onRemoveEventsSelected}
              
              button={{
                title: "Remover",
                className: "lognex-btn-danger",
                disabled:!showBtnRemover
              }}
              dialog={{
                header: "Confirmação",
                modal: false,
                message: "Deseja realmente remover o registro?",
              }}
            />
          
        </div>
        <EventList
          {...this.props}
          onSelectedEventsChange={this.onSelectedEventsChangeHandler}
          onPage={this.onPage}
          onSort={this.onSort}
          columns={columns}
        />
      </Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EventListPageContainer);
