
import React from "react";
import { connect } from "react-redux";
import Toolbar from "../../../components/toolbar/Toolbar";
import { Column } from 'primereact/column';
import { routes } from '../../../constants';
import { Page } from "../../../components/page/Page";
import { CampanhaAnymarketList } from "./CampanhaAnymarketList";
import { actions } from '../camapanha-anymarket.actions'
import { AppService, COMPANY_CHANGE_EVENT_NAME, BUSINESS_UNIT_CHANGE_EVENT_NAME } from "../../../services/app.service";
import moment from "moment";


const mapStateToProps = state => {
  return {
    columns: state.crudCampanhaAnymarketState.columns,
    records: state.crudCampanhaAnymarketState.records,
    first: state.crudCampanhaAnymarketState.index,
    rows: state.crudCampanhaAnymarketState.max,
    totalRecords: state.crudCampanhaAnymarketState.count,
    sortField: state.crudCampanhaAnymarketState.sortField,
    order: state.crudCampanhaAnymarketState.sortOrder
  }
}
const mapDispatchToProps = dispatch => {
  return {
    paginate: paginationParams => dispatch(actions.paginate(paginationParams)),
    sortOrder: sortParams => dispatch(actions.sortOrder(sortParams)),
    applyFilter: filterParams => {
      dispatch(actions.setType(null));
      dispatch(actions.applyFilter(filterParams))
    },
    load: () => dispatch(actions.load())
  }
}


class CampanhaAnymarketListPageContainer extends React.Component {

  newHandler = () => {
    this.props.history.push(`${routes.PATH_CAMPANHA_ANYMARKET}/novo`)
  }


  componentDidMount() {
   
    AppService.on(COMPANY_CHANGE_EVENT_NAME, this);
    AppService.on(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
    this.props.load();
  }
  componentWillUnmount() {
    AppService.off(COMPANY_CHANGE_EVENT_NAME, this);
    AppService.off(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
  }

  onReceiveAppNotifications = (eventName, params) => {
    if (eventName === COMPANY_CHANGE_EVENT_NAME || eventName === BUSINESS_UNIT_CHANGE_EVENT_NAME) {
      this.props.load();
    }
  }
  onRowClick = (e) => {
    const oid = e && e.data && e.data.oid
    if (oid) {
      this.props.history.push(`${routes.PATH_CAMPANHA_ANYMARKET}/${oid}`)
    }
  }


  onPage = (e) => {
    this.props.paginate({
      max: e.rows,
      index: e.first
    })
  }
  onSort = (e) => {
    console.log('sorting', e);
    this.props.sortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField
    })


  }
  onFilter = (e) => {
    console.log(e);
    const filters = [];
    const keys = Object.keys(e.filters) || [];
    keys.forEach(key => {
      if (key == "oid") {
        filters.push({
          campo: key,
          valor: parseInt(e.filters[key].value),
          tipoFiltro: 'IGUAL_A'
        })
      } else {
        filters.push({
          campo: key,
          valor: e.filters[key].value,
          tipoFiltro: 'CONTEM'

        })
      }
    })
    console.log(filters);
    this.props.applyFilter(filters)
  }

  render() {
    const header = (
      <div>
        <h1>Campanha</h1>
        <div>
          <Toolbar onNew={this.newHandler} newButtonStyle='highlighted'>
          </Toolbar >
        </div>
      </div>
    );
    const dateTemplate = (rowData, column) => {
      const date = rowData[column.field];
      const formattedDate = moment(date).format('DD/MM/YYYY')
      return <div style={{ textAlign: 'center', color: 'black' }}>{formattedDate}</div>
    };


    const booleanFieldsTemplate = (rowData, column) => {
      const result = rowData[column.field];
      if (result) {
        return <div style={{ color: 'green', display: 'flex', justifyContent: 'center' }}><span className="pi pi-check-circle" style={{ 'fontSize': '1.8rem' }} /> </div>;
      } else {
        return <div style={{ color: 'red', display: 'flex', justifyContent: 'center' }}><span className="pi pi-times-circle" style={{ 'fontSize': '1.8rem' }} /></div>;
      }
    };

    const columns = this.props.columns.map(col => {
      if (col.field === 'oid') {
        return (<Column {...col} key={col.field} style={{ textAlign: "center",width:"65px" }} />);
      }
      if (col.field === 'dataInicio') {
        return (<Column {...col} key={col.field} body={dateTemplate} style={{ textAlign: "center" ,width:"105px"}} />);
      }
      if (col.field === 'dataFim') {
        return (<Column {...col} key={col.field} body={dateTemplate} style={{ textAlign: "center" ,width:"105px"}} />);
      }
      if (col.field === 'active') {
        return (<Column {...col} key={col.field}  body={booleanFieldsTemplate}  style={{ textAlign: "center",width:"60px" }} />);
      }

      if (col.field === 'percentDesconto') {
        return (<Column {...col} key={col.field} style={{ textAlign: "center",width:"70px" }} />);
      }

      if (col.field === 'sku') {
        return (<Column {...col} key={col.field} style={{ textAlign: "center",width:"180px" }} />);
      }
      
      if (col.field === 'maketplaceName') {
        return (<Column {...col} key={col.field} style={{ textAlign: "center",width:"190px" }} />);
      }

      if (col.field === 'valMin') {
        return (<Column {...col} key={col.field} style={{ textAlign: "center",width:"85px" }} />);
      }
      
      if (col.field === 'valMax') {
        return (<Column {...col} key={col.field} style={{ textAlign: "center",width:"85px" }} />);
      }

      return (<Column {...col} key={col.field} />)
    })

    const content = <CampanhaAnymarketList  {...this.props} onFilter={this.onFilter} onPage={this.onPage} onSort={this.onSort} columns={columns} onRowClick={this.onRowClick} />;
    return <Page header={header} content={content} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CampanhaAnymarketListPageContainer);
