import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Toolbar from "../../../components/toolbar/Toolbar";
import { Column } from "primereact/column";
import { routes } from "../../../constants";
import { Page } from "../../../components/page/Page";
import { TowerTravelList } from "./TowerTravelList";
import { actions } from "../tower_travel.actions";
import {
  AppService,
  COMPANY_CHANGE_EVENT_NAME,
  BUSINESS_UNIT_CHANGE_EVENT_NAME,
} from "../../../services/app.service";
import moment from "moment";
import { Dialog } from "primereact/dialog";

const mapStateToProps = (state) => {
  return {
    columns: state.crudTowerTravelState.columns
      .filter(
        (col) =>
          col.field === "oid" ||
          col.field === "current_status.status" ||
          col.field === "datetime_begin" ||
          col.field === "datetime_finished" ||
          col.field === "equipment.name" ||
          col.field === "equipment.equipmentType.name" ||
          col.field === "eventRecord.createdAt"
      )
      .map((col) => col),

    records: state.crudTowerTravelState.records,
    first: state.crudTowerTravelState.index,
    rows: state.crudTowerTravelState.max,
    totalRecords: state.crudTowerTravelState.count,
    sortField: state.crudTowerTravelState.sortField,
    order: state.crudTowerTravelState.sortOrder,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    paginate: (paginationParams) =>
      dispatch(actions.paginate(paginationParams)),
    sortOrder: (sortParams) => dispatch(actions.sortOrder(sortParams)),
    applyFilter: (filterParams) => {
      dispatch(actions.setType(null));
      dispatch(actions.applyFilter(filterParams));
    },
    load: () => {
      dispatch(actions.load());
    },
    setSelectedRecords: (selectedRecords) => {
      dispatch(actions.setSelectedRecords(selectedRecords));
    },
  };
};

class TowerTravelListPageContainer extends React.Component {
  newHandler = () => {
    this.props.history.push(`${routes.PATH_TOWER_TRAVEL}/novo`);
  };

  componentDidMount() {
    this.props.applyFilter([]);
    AppService.on(COMPANY_CHANGE_EVENT_NAME, this);
    AppService.on(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
  }
  componentWillUnmount() {
    AppService.off(COMPANY_CHANGE_EVENT_NAME, this);
    AppService.off(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
  }

  onReceiveAppNotifications = (eventName, params) => {
    if (
      eventName === COMPANY_CHANGE_EVENT_NAME ||
      eventName === BUSINESS_UNIT_CHANGE_EVENT_NAME
    ) {
      this.props.applyFilter([]);
    }
  };
  onPage = (e) => {
    this.props.paginate({
      max: e.rows,
      index: e.first,
    });
  };
  onSort = (e) => {
    this.props.sortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField,
    });
  };

  onFilter = (e) => {
    const filters = [];
    const keys = Object.keys(e.filters) || [];
    keys.forEach((key) => {
      filters.push({
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: "CONTEM",
      });
    });
    this.props.applyFilter(filters);
  };

  onRowClick = (e) => {
    const oid = e && e.data && e.data.oid;
    if (oid) {
      this.props.history.push(`${routes.PATH_TOWER_TRAVEL}/${oid}`);
    }
  };

  render() {
    const header = (
      <div>
        <h1>Viagem</h1>
        <hr className="lxSplitter"></hr>
        <div>
          <Toolbar
            onNew={this.newHandler}
            newButtonStyle="highlighted"
          ></Toolbar>
        </div>
      </div>
    );
    const dateTemplate = (rowData, column) => {
      const date = rowData["" + column.field + ""];

      let formattedDate = "";
      if (date) {
        formattedDate = moment(date).format("DD/MM/YYYY HH:mm:ss");
      }
      return (
        <div style={{ textAlign: "center", color: "black" }}>
          {formattedDate}
        </div>
      );
    };

    const eventRecordCreatedAt = (rowData, column) => {
      try {
        const date = rowData.eventRecord.createdAt;
        let formattedDate = "";
        if (date) {
          formattedDate = moment(date).format("DD/MM/YYYY HH:mm:ss");
        }
        return (
          <div style={{ textAlign: "center", color: "black" }}>
            {formattedDate}
          </div>
        );
      } catch (error) {
        return <div></div>;
      }
    };

    const columns = this.props.columns.map((col) => {
      if (col.field === "datetime_begin") {
        return <Column {...col} key={col.field} body={dateTemplate} />;
      }
      if (col.field === "datetime_finished") {
        return <Column {...col} key={col.field} body={dateTemplate} />;
      }
      if (col.field === "eventRecord.createdAt") {
        return <Column {...col} key={col.field} body={eventRecordCreatedAt} />;
      }
      return <Column {...col} key={col.field} />;
    });

    const content = ( 
        <TowerTravelList
          {...this.props}
          onRowClick={this.onRowClick}
          onFilter={this.onFilter}
          onPage={this.onPage}
          onSort={this.onSort}
          columns={columns}
        /> 
    );
    return <Page header={header} content={content} />;
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TowerTravelListPageContainer);
