import React from 'react';
import {DataTable} from 'primereact/datatable';
import { Column } from 'primereact/column';

export const PendenceDocList = (props)=>(
    <DataTable   
    selectionMode="single"
    onFilter = {props.onFilter}
    onSort = {props.onSort}
    onPage = {props.onPage}
    value={props.records}
    lazy = {true}
    totalRecords = {props.totalRecords}
    sortOrder= {props.order}
    sortField = {props.sortField}
    paginator={true}
    first= {props.first}
    rows={props.rows}
    emptyMessage = "Não foram encontrados registros"
    rowsPerPageOptions={[5, 10, 20,50]}
    onRowToggle={props.rowExpandButtonOnClick}
    onRowClick={props.rowToggleHandler}
    rowExpansionTemplate={props.templateExpandedRow}
    expandedRows={props.expandedRows}
  >
    {[<Column expander={true} key={"travelTablePendeceDoc"} style={{ width: "4em" }} />, ...props.columns]}
</DataTable>
)
