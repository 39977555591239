import React from 'react';
import TransferRouteListPageContainer from './TransferRouteListPageContainer';

export class TransferRouteListPage extends React.Component{

    render(){
        return (
            <TransferRouteListPageContainer {...this.props}/>
        )
    }
}
