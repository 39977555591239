import {types} from './report.actions';

const initialState = {    
    carrierEfficiency : {
        title: "EFICIÊNCIA DE ENTREGA",
        value: "0.0%",
        filters: {
            carrier: {
                documentNumber: null,
                name: "TODOS",
            },
            period: {
                description:"Últimos 7 dias",
                period: "LAST_SEVEN_DAYS"
            },
        },
        lookup:{
            showCarrier: false,
            showPeriod: false,
            columnsCarrier:[
                {
                    field: "razaoSocial",
                    header: "Razão social",
                    columnKey: "razaoSocial",
                    sortable: false,
                    filter: true
                   
                  }
            ],
            columnsPeriod:[
                {
                    field: "description",
                    header: "Período",
                    columnKey: "description",
                    sortable: false,
                    filter: true
                   
                }
            ]
        }
    }
}

export function reducerCarrierEfficiency(state = initialState, action){
   let result = state;
   switch(action.type){
    case types.SET_VALUE:
        return setValue(state, action);
    case types.SET_CNPJ:
        return setCNPJ(state, action);
    case types.SET_NAME:
        return setName(state, action);
    case types.SET_PERIOD:
        return setPeriod(state, action);
    case types.SET_SHOW_CARRIER:
        return setShowCarrier(state, action);
    case types.SET_SHOW_PERIOD:
        return setShowPeriod(state, action);   
    case types.LOADER_SHOW:
        return loaderShow(state, action);
    case types.LOADER_HIDDEN:
        return loaderHide(state, action);                   
    default:
        return result; 
   }
}


function setValue(state, action){
    const carrierEfficiency = {...state.carrierEfficiency, value:action.value}
    return Object.assign({}, state, {carrierEfficiency});
}

function setCNPJ(state, action){
    const filters = {...state.carrierEfficiency.filters,carrier:action.carrier}
    const carrierEfficiency = {...state.carrierEfficiency, filters}
    return Object.assign({}, state, {carrierEfficiency}); 
}

function setName(state, action){
    return Object.assign({}, state, { value: action.name }); 
}

function setPeriod(state, action){
    const filters = {...state.carrierEfficiency.filters, period:action.period}
    const carrierEfficiency = {...state.carrierEfficiency, filters}
    return Object.assign({}, state, {carrierEfficiency});  
}

function setShowCarrier(state, action){
    const lookup = {...state.carrierEfficiency.lookup, showCarrier:action.showCarrier}
    const carrierEfficiency = {...state.carrierEfficiency, lookup}
    return Object.assign({}, state, {carrierEfficiency}); 
}

function setShowPeriod(state, action){
    const lookup = {...state.carrierEfficiency.lookup, showPeriod:action.showPeriod}
    const carrierEfficiency = {...state.carrierEfficiency, lookup}
    return Object.assign({}, state, {carrierEfficiency}); 
}

function loaderShow(state, action){
    return Object.assign({}, state, { value: action.show }); 
}

function loaderHide(state, action){
    return Object.assign({}, state, { value: action.show }); 
}
