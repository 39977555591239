import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { Column } from "primereact/column";
import { routes } from "../../../constants";
import { Page } from "../../../components/page/Page";
import { actions } from "../tower_dashboard.actions";
import { actions as carrierActions } from "../../carrier/carrier.actions";
import { actions as locationsActions } from "../../location/location.actions";
import { actions as observationTypeActions } from "../../towerObservationType/tower_towerobservationtype.actions";
import { actions as appActions } from "../../../store/app";

import { AppService, COMPANY_CHANGE_EVENT_NAME, BUSINESS_UNIT_CHANGE_EVENT_NAME } from "../../../services/app.service";
import moment from "moment";
import { TowerDashboardList } from "./TowerDashboardList";
import "./tower_dashboard.scss";
import locationIcon from "../../../assets/images/icons/location.png";
import vehicleIconFail from "../../../assets/images/icons/truck_failure.png";
import vehicleIconSuccess from "../../../assets/images/icons/truck_success.png";
import vehicleIconWarning from "../../../assets/images/icons/truck_warning.png";
import vehicleIconNormal from "../../../assets/images/icons/truck_normal.png";
import vehicleIconCanceled from "../../../assets/images/icons/truck_failure.png";
import vehicleIconLagTrend from "../../../assets/images/icons/truck_success_with_pendings.png";

import chatIcon from "../../../assets/images/icons/chat.png";
import { Button } from "@material-ui/core";
import { CANCELED, CREATED, END_TRAVEL, TRAVELING, TRAVEL_LAG_TREND, TRAVEL_LATE, TRAVEL_ON_TIME } from "../constants";
import { ImputTravelObsDialog } from "../components/travelImputObs";
import { TravelFiltro } from "../components/filtro/filtro";
import { IndicatorTravel } from "../components/indicator/totalizadores";
import { formatNumber, segParaHora } from "../../../utils";
import { TowerRowTreeview } from "../components/TowerRowTreeview";
import { TowerRowTreeviewPlate } from "../components/TowerRowTreeviewPlate";

const mapStateToProps = (state) => {
  let normalizedRecords = state.crudTowerDashboardState.records.map((item) => {
    //ultimo ponto de entrega,
    //esses
    let lastDestinatoion = item.destinations && item.destinations.length > 0 ? item.destinations[item.destinations.length - 1] : null;
    let firstOrigin = item.origin && item.origin.length > 0 ? item.origin[0] : null;

    let lastDelivery = null;
    let nextDelivery = null;

    if (item.destinations && item.destinations.length > 0) {
      for (const dest of item.destinations) {
        if (dest.deliveryDate) {
          //pega o destinatario que tem data de entregue
          lastDelivery = dest;
        } else {
          //pega o proximo destinatario que ainda nao foi entregue
          nextDelivery = dest;
          break;
        }
      }
    }

    //ordernar conforme a criticidade, o numero maior é mais critico, deve vir primeiro
    let sortNumber = 0;
    if (item.travel_status == CANCELED) sortNumber = 0;
    if (item.travel_status == END_TRAVEL) sortNumber = 1;
    if (item.travel_status == CREATED) sortNumber = 2;
    if (item.travel_status == TRAVELING) sortNumber = 3;

    if (item.status_time == TRAVEL_ON_TIME) sortNumber += 1;
    if (item.status_time == TRAVEL_LAG_TREND) sortNumber += 2;
    if (item.status_time == TRAVEL_LATE) sortNumber += 3;

    let timeLastPostion;
    if (item.lastPosition && item.lastPosition.datePosition) timeLastPostion = item.lastPosition.datePosition;

    return {
      ...item,
      destination: lastDestinatoion,
      firstOrigin: firstOrigin,
      lastDelivery,
      nextDelivery,
      sortNumber,
      timeLastPostion,
    };
  });

  //sort by status
  normalizedRecords.sort((a, b) => b.sortNumber - a.sortNumber);

  const lookupCarrierBase = {
    records: state.crudCarrierState.records,
    columns: state.crudTowerDashboardState.lookupCarrier.visible
      ? state.crudCarrierState.columns.filter((col) => col.field === "oid" || col.field === "name").map((col) => <Column key={col.field} {...col} />)
      : [<Column key={"columnKeyCarrier"} />],
    first: state.crudCarrierState.index,
    rows: state.crudCarrierState.max,
    totalRecords: state.crudCarrierState.count,
    sortField: state.crudCarrierState.sortField,
    order: state.crudCarrierState.sortOrder,
    editDisabled: true,
    rowsPerPageOptions: [20, 50, 100],
  };
  const lookupCarrier = { ...state.crudTowerDashboardState.lookupCarrier, ...lookupCarrierBase };

  const lookupDestinationBase = {
    records: state.crudLocationState.records,
    columns: state.crudTowerDashboardState.lookupDestination.visible
      ? state.crudLocationState.columns.filter((col) => col.field === "oid" || col.field === "description").map((col) => <Column key={col.field} {...col} />)
      : [<Column key={"columnKeyDestination"} />],
    first: state.crudLocationState.index,
    rows: state.crudLocationState.max,
    totalRecords: state.crudLocationState.count,
    sortField: state.crudLocationState.sortField,
    order: state.crudLocationState.sortOrder,
    editDisabled: true,
    rowsPerPageOptions: [20, 50, 100],
  };
  const lookupDestination = { ...state.crudTowerDashboardState.lookupDestination, ...lookupDestinationBase };

  const lookupOriginBase = {
    records: state.crudLocationState.records,
    columns: state.crudTowerDashboardState.lookupOrigin.visible
      ? state.crudLocationState.columns.filter((col) => col.field === "oid" || col.field === "description").map((col) => <Column key={col.field} {...col} />)
      : [<Column key={"columnKeyOrigin"} />],
    first: state.crudLocationState.index,
    rows: state.crudLocationState.max,
    totalRecords: state.crudLocationState.count,
    sortField: state.crudLocationState.sortField,
    order: state.crudLocationState.sortOrder,
    editDisabled: true,
    rowsPerPageOptions: [20, 50, 100],
  };
  const lookupOrigin = { ...state.crudTowerDashboardState.lookupOrigin, ...lookupOriginBase };

  const lookupObservationTypeBase = {
    records: state.crudTowerObservationTypeState.records,
    columns: state.crudTowerDashboardState.lookupObservationType.visible
      ? state.crudTowerObservationTypeState.columns.filter((col) => col.field === "oid" || col.field === "name").map((col) => <Column key={col.field} {...col} />)
      : [<Column key={"columnKeyObservationType"} />],
    first: state.crudTowerObservationTypeState.index,
    rows: state.crudTowerObservationTypeState.max,
    totalRecords: state.crudTowerObservationTypeState.count,
    sortField: state.crudTowerObservationTypeState.sortField,
    order: state.crudTowerObservationTypeState.sortOrder,
    editDisabled: false,
    rowsPerPageOptions: [20, 50, 100],
  };
  const lookupObservationType = {
    ...state.crudTowerDashboardState.lookupObservationType,
    ...lookupObservationTypeBase,
  };

  const enableExportCSV = normalizedRecords && normalizedRecords.length > 0;
  const userConfigurations = state.appState.userConfigurations;
  let towerShowTotalizers = userConfigurations.towerShowTotalizers || {
    total: true,
    andamento: true,
    atraso: true,
    tendencia_atraso: true,
    on_time: true,
    notmirrored: true,
    finalizados: false,
    canceladas: false,
  };

  return {
    columns: state.crudTowerDashboardState.columns
      .filter(
        (col) =>
          col.field === "vehicle.plate" ||
          col.field === "current_status.status" ||
          col.field === "firstOrigin.address.City" ||
          col.field === "destination.description" || //destino do ultimo ponto de entrega
          col.field === "lastDelivery.description" || //a ultima entrega realizada
          col.field === "nextDelivery.description" || //proximo ponto de entrega
          col.field === "nextDelivery.currentDistanceLeft" ||
          col.field === "travel_status" ||
          col.field === "tempoTotalKmtotal" ||
          col.field === "timeLastPostion" ||
          col.field === "eventRecord.createdAt" ||
          col.field === "currentPercent" ||
          col.field === "acoes" ||
          col.field === "oid"
      )
      .map((col) => col),

    records: normalizedRecords,
    first: state.crudTowerDashboardState.index,
    rows: state.crudTowerDashboardState.max,
    totalRecords: state.crudTowerDashboardState.count,
    sortField: state.crudTowerDashboardState.sortField,
    order: state.crudTowerDashboardState.sortOrder,

    totalizers: state.crudTowerDashboardState.totalizers,
    inputObs: state.crudTowerDashboardState.inputObs,
    visibleImputTravelObsDialog: state.crudTowerDashboardState.visibleImputTravelObsDialog,
    lookupCarrier,
    carrier: state.crudTowerDashboardState.carrier,
    lookupDestination,
    destination: state.crudTowerDashboardState.destination,
    origin: state.crudTowerDashboardState.origin,
    lookupOrigin,
    statusSelectedFilter: state.crudTowerDashboardState.statusSelectedFilter,
    statusTimeSelectedFilter: state.crudTowerDashboardState.statusTimeSelectedFilter,
    statusTravelOptions: state.crudTowerDashboardState.statusTravelOptions,
    statusTimeOptions: state.crudTowerDashboardState.statusTimeOptions,
    initialDate: state.crudTowerDashboardState.initialDate,
    finalDate: state.crudTowerDashboardState.finalDate,
    deliveryCode: state.crudTowerDashboardState.deliveryCode,
    observationType: state.crudTowerDashboardState.observationType,
    lookupObservationType,
    nroEmbarque: state.crudTowerDashboardState.nroEmbarque,
    expandedRows: state.crudTowerDashboardState.expandedRows,
    columnsRowTreeviewTemplate: state.crudTowerDashboardState.columnsRowTreeviewTemplate,
    selectedTypeFilter: state.crudTowerDashboardState.selectedTypeFilter,
    enableExportCSV,
    filterAux: state.crudTowerDashboardState.filterAux,
    columnsRowTreeviewGridPlatesTemplate: state.crudTowerDashboardState.columnsRowTreeviewGridPlatesTemplate,
    towerShowTotalizers: towerShowTotalizers,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    paginate: (paginationParams) => dispatch(actions.paginate(paginationParams)),
    sortOrder: (sortParams) => dispatch(actions.sortOrder(sortParams)),
    applyFilter: (filterParams) => {
      dispatch(actions.setType(null));
      dispatch(actions.applyFilter(filterParams));
    },
    setFiltersMapParams: (filterParams) => {
      dispatch(actions.setFiltersMapParams(filterParams));
    },

    load: () => {
      dispatch(actions.load());
    },
    loadTotalizers: (filtersParams) => {
      dispatch(actions.loadTotalizers(filtersParams));
      dispatch(actions.setTotalizersFiltersParams(filtersParams));
    },

    setImputObs: (obs) => {
      dispatch(actions.setImputObs(obs));
    },

    setSelectedTravel: (selectedTravel) => {
      dispatch(actions.setSelectedTravel(selectedTravel));
    },

    saveObsTravel: () => {
      dispatch(actions.saveObsTravel());
    },
    setVisibleObs: (visible) => {
      dispatch(actions.setVisibleObs(visible));
    },

    //lookup carrier
    lookupCarrierSetVisible: (visible) => dispatch(actions.setLookupCarrierVisible(visible)),
    lookupCarrierPaginate: (paginationParams) => dispatch(carrierActions.paginate(paginationParams)),
    lookupCarrierSortOrder: (sortParams) => dispatch(carrierActions.sortOrder(sortParams)),
    lookupCarrierLoad: () => dispatch(carrierActions.load()),
    setCarrierFilter: (carrier) => dispatch(actions.setCarrierFilter(carrier)),
    lookupCarrierApplyFilter: (filterParams) => {
      dispatch(carrierActions.setType(null));
      dispatch(carrierActions.applyFilter(filterParams));
    },
    //lookup carrier

    //destination
    lookupDestinationLoad: () => dispatch(locationsActions.load()),
    lookupDestinationSetVisible: (visible) => dispatch(actions.setLookupDestinationVisible(visible)),
    setDestinationFilter: (destination) => dispatch(actions.setDestinationFilter(destination)),
    lookupDestinationApplyFilter: (filterParams) => {
      dispatch(locationsActions.setType(null));
      dispatch(locationsActions.applyFilter(filterParams));
    },
    lookupDestinationPaginate: (paginationParams) => dispatch(locationsActions.paginate(paginationParams)),
    lookupDestinationSortOrder: (sortParams) => dispatch(locationsActions.sortOrder(sortParams)),

    //destination

    //origin
    lookupOriginLoad: () => dispatch(locationsActions.load()),
    lookupOriginSetVisible: (visible) => dispatch(actions.setLookupOriginVisible(visible)),
    setOriginFilter: (origin) => dispatch(actions.setOriginFilter(origin)),
    lookupOriginApplyFilter: (filterParams) => {
      dispatch(locationsActions.setType(null));
      dispatch(locationsActions.applyFilter(filterParams));
    },
    lookupOriginPaginate: (paginationParams) => dispatch(locationsActions.paginate(paginationParams)),
    lookupOriginSortOrder: (sortParams) => dispatch(locationsActions.sortOrder(sortParams)),

    //origin

    setStatusSelectedFilter: (value) => {
      dispatch(actions.setStatusSelectedFilter(value));
    },
    setStatusTimeSelectedFilter: (value) => {
      dispatch(actions.setStatusTimeSelectedFilter(value));
    },

    initialDateChangeHandler: (e) => dispatch(actions.setInitialDateFilter(e.value)),
    finalDateChangeHandler: (e) => dispatch(actions.setFinalDateFilter(e.value)),
    setDeliveryCode: (value) => dispatch(actions.setDeliveryCode(value)),
    setEmbarqueIdFilter: (value) => dispatch(actions.setEmbarqueIdFilter(value)),

    //destination
    lookupObservationTypeLoad: () => dispatch(observationTypeActions.load()),
    lookupObservationTypeSetVisible: (visible) => dispatch(actions.setLookupObservationTypeVisible(visible)),
    setObservationType: (observationType) => dispatch(actions.setObservationType(observationType)),
    lookupObservationTypeApplyFilter: (filterParams) => {
      dispatch(observationTypeActions.setType(null));
      dispatch(observationTypeActions.applyFilter(filterParams));
    },
    lookupObservationTypePaginate: (paginationParams) => dispatch(observationTypeActions.paginate(paginationParams)),
    lookupObservationTypeSortOrder: (sortParams) => dispatch(observationTypeActions.sortOrder(sortParams)),
    setExpandedRows: (expandedRows) => {
      dispatch(actions.setExpandedRows(expandedRows));
    },
    setFiltersParams: (filtersParams) => {
      dispatch(actions.setFiltersParams(filtersParams));
    },
    setSelectedTypeFilter: (selectedTypeFilter) => {
      dispatch(actions.setSelectedTypeFilter(selectedTypeFilter));
    },

    onExportTravelDownloadXLSX: (filtersParams) => {
      dispatch(actions.onExportTravelDownloadXLSX(filtersParams));
    },

    setFilterAux: (filterAux) => {
      dispatch(actions.setFilterAux(filterAux));
    },

    userSaveTowerTotalizers: (towerTotalizers) => dispatch(appActions.appSaveTowerTotalizers(towerTotalizers)),
    setTowerShowTotalizers: (towerShowTotalizers) => {
      dispatch(appActions.setTowerShowTotalizers(towerShowTotalizers));
    },
    loaderUser: () => {
      dispatch(appActions.loadUserConfigurations());
    },
  };
};

class TowerDashboardListPageContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      statusSelectedFilter: null,
      statusTimeSelectedFilter: null,
    };
  }

  newHandler = () => {
    this.props.history.push(`${routes.PATH_TOWER_TRAVEL}/novo`);
  };

  componentDidMount() {
    this.props.loaderUser();
    setTimeout(() => this.onFilterStartPage(), 1000);
    AppService.on(COMPANY_CHANGE_EVENT_NAME, this);
    AppService.on(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
  }
  componentWillUnmount() {
    AppService.off(COMPANY_CHANGE_EVENT_NAME, this);
    AppService.off(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
  }

  onReceiveAppNotifications = (eventName, params) => {
    if (eventName === COMPANY_CHANGE_EVENT_NAME || eventName === BUSINESS_UNIT_CHANGE_EVENT_NAME) {
      this.props.loaderUser();
      setTimeout(() => this.onFilterStartPage(), 1000);
    }
  };
  onPage = (e) => {
    this.props.paginate({
      max: e.rows,
      index: e.first,
    });
  };
  onSort = (e) => {
    this.props.sortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField,
    });
  };

  onFilter = (e) => {
    const filters = [];
    const keys = Object.keys(e.filters) || [];
    keys.forEach((key) => {
      filters.push({
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: "CONTEM",
      });
    });
    this.props.applyFilter(filters);
  };

  setExpandedRowsByRowClick = (e) => {
    if (e.data && e.data.length > 1) {
      this.props.setExpandedRows([e.data[1]]);
    } else if (e.data && e.data.length == 1) {
      this.props.setExpandedRows([e.data[0]]);
    } else {
      this.props.setExpandedRows([e.data]);
    }
  };
  setExpandedRowsClick = (e) => {
    if (e.data && e.data.length > 1) {
      this.props.setExpandedRows([e.data[1]]);
    } else {
      this.props.setExpandedRows([e.data]);
    }
  };

  onClickShowMap = (data) => {
    if (data.oid) {
      this.props.history.push(`${routes.PATH_TOWER_TRAVEL_VISUALIZE_MAP}/${data.oid}`);
    }
  };
  onClickShowAllMap = () => {
    const filtersMapParams = this.getMapFilters();
    this.props.setFiltersMapParams(filtersMapParams);
    this.props.history.push(`${routes.PATH_TOWER_TRAVEL_VISUALIZE_MAP}`);
  };

  onClickImputObs = (selectedTravel) => {
    this.props.setImputObs("");
    this.props.setSelectedTravel(selectedTravel);
    this.props.setVisibleObs(true);
  };

  onHideImputObs = () => {
    this.props.setImputObs("");
    this.props.setVisibleObs(false);
  };

  onConfirmImputObs = () => {
    this.props.saveObsTravel();
  };

  onCancelImputObs = () => {
    this.props.setImputObs("");
    this.props.setVisibleObs(false);
  };
  onChangeImputObs = (e) => {
    this.props.setImputObs(e.target.value);
  };

  //lookup carrier

  lookupCarrierOnPage = (e) => {
    this.props.lookupCarrierPaginate({
      max: e.rows,
      index: e.first,
    });
  };
  lookupCarrierOnSort = (e) => {
    this.props.lookupCarrierSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField,
    });
  };
  lookupCarrierOnFilter = (e) => {
    const filters = [];
    const keys = Object.keys(e.filters) || [];
    keys.forEach((key) => {
      filters.push({
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: "CONTEM",
      });
    });
    this.props.lookupCarrierApplyFilter(filters);
  };
  lookupCarrierOnClick = (e) => {
    this.props.lookupCarrierLoad();
    this.props.lookupCarrierSetVisible(true);
  };
  lookupCarrierOnHide = (e) => {
    this.props.lookupCarrierSetVisible(false);
  };
  lookupCarrierOnConfirm = (e) => {
    this.props.lookupCarrierApplyFilter([]);
    this.props.setCarrierFilter(e.selection);
    this.props.lookupCarrierSetVisible(false);
  };
  lookupCarrierOnCancel = (e) => {
    this.props.setCarrierFilter(null);
    this.props.lookupCarrierSetVisible(false);
  };

  lookupCarrierOnEdit = () => { };

  lookupCarrierOnComplete = (e) => { };

  lookupCarrierOnInputFilter = (e) => {
    const value = e.value;
    const filters = [];
    if (typeof value === "string") {
      filters.push({
        campo: "tradeName",
        valor: value,
        tipoFiltro: "CONTEM",
      });
      this.props.setCarrierFilter({ tradeName: value });
    } else {
      this.props.setCarrierFilter(value);
    }
    this.props.lookupCarrierApplyFilter(filters);
  };

  getCarrierFilter = () => {
    return this.props.carrier ? this.props.carrier.documentNumber : null;
  };
  //lookup carrier

  // LOOKUP DESTINATION
  lookupDestinationOnPage = (e) => {
    this.props.lookupDestinationPaginate({
      max: e.rows,
      index: e.first,
    });
  };
  lookupDestinationOnSort = (e) => {
    this.props.lookupDestinationSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField,
    });
  };
  lookupDestinationOnFilter = (e) => {
    const filters = [];
    const keys = Object.keys(e.filters) || [];
    keys.forEach((key) => {
      filters.push({
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: "CONTEM",
      });
    });
    this.props.lookupDestinationApplyFilter(filters);
  };
  lookupDestinationOnInputFilter = (e) => {
    const value = e.value;
    const filters = [];
    if (typeof value === "string") {
      filters.push({
        campo: "description",
        valor: value,
        tipoFiltro: "CONTEM",
      });
      this.props.setDestinationFilter({ description: value });
    } else {
      this.props.setDestinationFilter(value);
    }
    this.props.lookupDestinationApplyFilter(filters);
  };
  lookupDestinationOnClick = (e) => {
    this.props.lookupDestinationLoad();
    this.props.lookupDestinationSetVisible(true);
  };
  lookupDestinationOnHide = (e) => {
    this.props.lookupDestinationSetVisible(false);
  };
  lookupDestinationOnConfirm = (e) => {
    this.props.lookupDestinationApplyFilter([]);
    this.props.setDestinationFilter(e.selection);
    this.props.lookupDestinationSetVisible(false);
  };
  lookupDestinationOnCancel = (e) => {
    this.props.setDestinationFilter(null);
    this.props.lookupDestinationSetVisible(false);
  };
  lookupDestinationOnEdit = () => { };
  lookupDestinationOnComplete = (e) => { };
  getDestinationFilter = () => {
    const { oid } = this.props.destination || {};
    return oid;
  };

  // LOOKUP ORIGIN
  lookupOriginOnPage = (e) => {
    this.props.lookupOriginPaginate({
      max: e.rows,
      index: e.first,
    });
  };
  lookupOriginOnSort = (e) => {
    this.props.lookupOriginSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField,
    });
  };
  lookupOriginOnFilter = (e) => {
    const filters = [];
    const keys = Object.keys(e.filters) || [];
    keys.forEach((key) => {
      filters.push({
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: "CONTEM",
      });
    });
    this.props.lookupOriginApplyFilter(filters);
  };
  lookupOriginOnClick = (e) => {
    this.props.lookupOriginLoad();
    this.props.lookupOriginSetVisible(true);
  };
  lookupOriginOnHide = (e) => {
    this.props.lookupOriginSetVisible(false);
  };
  lookupOriginOnConfirm = (e) => {
    this.props.lookupOriginApplyFilter([]);
    this.props.setOriginFilter(e.selection);
    this.props.lookupOriginSetVisible(false);
  };
  lookupOriginOnCancel = (e) => {
    this.props.setOriginFilter(null);
    this.props.lookupOriginSetVisible(false);
  };
  lookupOriginOnEdit = () => { };
  lookupOriginOnComplete = (e) => { };
  lookupOriginOnInputFilter = (e) => {
    const value = e.value;
    const filters = [];
    if (typeof value === "string") {
      filters.push({
        campo: "description",
        valor: value,
        tipoFiltro: "CONTEM",
      });
      this.props.setOriginFilter({ description: value });
    } else {
      this.props.setOriginFilter(value);
    }
    this.props.lookupOriginApplyFilter(filters);
  };
  getOriginFilter = () => {
    const { oid } = this.props.origin || {};
    return oid;
  };
  //origin

  deliveryCodeChangeHandler = (e) => {
    const value = e.target.value;
    this.props.setDeliveryCode(value);
  };

  embarqueIdChangeHandler = (e) => {
    const value = e.target.value;
    this.props.setEmbarqueIdFilter(value);
  };

  setStatusSelectedFilter = (e) => {
    let statusSelected = [e.value];
    if (e.value.status == "Andamento") {
      statusSelected = [
        {
          travel_status: TRAVELING,
          status: "Andamento",
        },
        {
          travel_status: CREATED,
          status: "Criada",
        },
      ];
    }
    this.props.setStatusSelectedFilter(statusSelected);
  };

  setStatusTimeSelectedFilter = (e) => {
    this.props.setStatusTimeSelectedFilter(e.value);
  };

  normalizeDate = (date) => {
    return date && typeof date === "object" ? date : null;
  };
  getFiltersTotalizers = () => {
    let filters = [];
    const initialDateFilter = this.normalizeDate(this.props.initialDate);
    const finalDateFilter = this.normalizeDate(this.props.finalDate);
    if (initialDateFilter && finalDateFilter) {
      filters.push({
        field: "eventRecord.createdAt",
        startValue: moment(initialDateFilter).startOf("day").toDate(),
        endValue: moment(finalDateFilter).endOf("day").toDate(),
        type: "BETWEEN",
      });
    }
    return filters;
  };

  getMapFilters = () => {
    let filters = [];
    const initialDateFilter = this.normalizeDate(this.props.initialDate);
    const finalDateFilter = this.normalizeDate(this.props.finalDate);
    const deliveryCode = this.props.deliveryCode;
    const nroEmbarque = this.props.nroEmbarque;

    if (initialDateFilter && finalDateFilter) {
      filters.push({
        field: "eventRecord.createdAt",
        startValue: moment(initialDateFilter).startOf("day").toDate(),
        endValue: moment(finalDateFilter).endOf("day").toDate(),
        type: "BETWEEN",
      });
    }
    if (deliveryCode) {
      filters.push({
        campo: "destinations.code",
        valor: deliveryCode,
        tipoFiltro: "IGUAL_A",
      });
    }
    if (nroEmbarque) {
      filters.push({
        campo: "shipment_code",
        valor: parseInt(nroEmbarque),
        tipoFiltro: "IGUAL_A",
      });
    }
    //
    return filters;
  };

  getFilters = () => {
    let filters = [];

    // const carrierOptions = this.getCarrierFilter();
    // const destinationOptions = this.getDestinationFilter() || "";
    // const originOptions = this.getOriginFilter() || "";
    const initialDateFilter = this.normalizeDate(this.props.initialDate);
    const finalDateFilter = this.normalizeDate(this.props.finalDate);
    const deliveryCode = this.props.deliveryCode;
    const nroEmbarque = this.props.nroEmbarque;

    if (initialDateFilter && finalDateFilter) {
      filters.push({
        field: "eventRecord.createdAt",
        startValue: moment(initialDateFilter).startOf("day").toDate(),
        endValue: moment(finalDateFilter).endOf("day").toDate(),
        type: "BETWEEN",
      });
    }
    if (deliveryCode) {
      filters.push({
        campo: "destinations.code",
        valor: deliveryCode,
        tipoFiltro: "IGUAL_A",
      });
    }
    if (nroEmbarque) {
      filters.push({
        campo: "shipment_code",
        valor: parseInt(nroEmbarque),
        tipoFiltro: "IGUAL_A",
      });
    }

    if (this.props.statusSelectedFilter && this.props.statusSelectedFilter.length > 0) {
      const listStatus = [];
      this.props.statusSelectedFilter.forEach((element) => {
        if (element.travel_status) listStatus.push(element.travel_status);
      });
      if (listStatus.length > 0)
        filters.push({
          field: "travel_status",
          value: listStatus,
          type: "IN",
        });
    }
    if (this.props.statusTimeSelectedFilter && this.props.statusTimeSelectedFilter.status_time) {
      filters.push({
        campo: "status_time",
        valor: this.props.statusTimeSelectedFilter.status_time,
        tipoFiltro: "IGUAL_A",
      });
    }

    this.props.filterAux.forEach((filter) => {
      filters.push(filter);
    });

    return filters;
  };

  onAplyFilterTotalizadores = () => {
    const filtersParams = this.getFilters();
    console.log("onAplyFilterTotalizadores filtersParams=", filtersParams);
    this.props.applyFilter(filtersParams);

    const filtersMapParams = this.getMapFilters();
    this.props.setFiltersMapParams(filtersMapParams);
  };

  onClickFinalizadas = () => {
    //atualiza o status no combobox PRAZO para todos
    // const status = this.props.statusTimeOptions.filter((i) => !i.status_time)[0];
    // this.props.setStatusTimeSelectedFilter(status);
    this.props.setFilterAux([]);
    const statusViagem = this.props.statusTravelOptions.filter((i) => i.travel_status == END_TRAVEL);
    console.log("onClickFinalizadas statusViagem=", statusViagem);
    this.props.setStatusSelectedFilter(statusViagem);
    setTimeout(() => this.onAplyFilterTotalizadores(), 100);
    this.props.setSelectedTypeFilter("finalizados");
  };

  onClickOnTime = () => {
    const statusTravel = [
      {
        travel_status: TRAVELING,
      },
    ];
    this.props.setStatusSelectedFilter(statusTravel);

    this.props.setFilterAux([
      {
        campo: "pathTraveledCount",
        valor: 2,
        tipoFiltro: "MAIOR",
      },
    ]);

    const status = this.props.statusTimeOptions.filter((i) => i.status_time == TRAVEL_ON_TIME)[0];
    this.props.setStatusTimeSelectedFilter(status);
    setTimeout(() => this.onAplyFilterTotalizadores(), 100);
    this.props.setSelectedTypeFilter("on_time");
  };

  onClickTendenciaAtraso = () => {
    const statusTravel = [
      {
        travel_status: TRAVELING,
      },
    ];
    this.props.setStatusSelectedFilter(statusTravel);

    this.props.setFilterAux([
      {
        campo: "pathTraveledCount",
        valor: 2,
        tipoFiltro: "MAIOR",
      },
    ]);

    const status = this.props.statusTimeOptions.filter((i) => i.status_time == TRAVEL_LAG_TREND)[0];
    this.props.setStatusTimeSelectedFilter(status);
    this.props.setSelectedTypeFilter("tendencia_atraso");
    setTimeout(() => this.onAplyFilterTotalizadores(), 100);
  };

  onClickAtraso = () => {
    const statusTravel = [
      {
        travel_status: TRAVELING,
      },
    ];
    this.props.setStatusSelectedFilter(statusTravel);

    this.props.setFilterAux([
      {
        campo: "pathTraveledCount",
        valor: 2,
        tipoFiltro: "MAIOR",
      },
    ]);

    const status = this.props.statusTimeOptions.filter((i) => i.status_time == TRAVEL_LATE)[0];

    this.props.setStatusTimeSelectedFilter(status);
    setTimeout(() => this.onAplyFilterTotalizadores(), 100);

    this.props.setSelectedTypeFilter("atraso");
  };

  onClickAndamento = () => {
    const statusTime = this.props.statusTimeOptions.filter((i) => !i.status_time)[0];
    this.props.setStatusTimeSelectedFilter(statusTime);
    const statusTravel = [
      {
        travel_status: TRAVELING,
      },
    ];
    this.props.setStatusSelectedFilter(statusTravel);

    this.props.setFilterAux([
      {
        campo: "pathTraveledCount",
        valor: 2,
        tipoFiltro: "MAIOR",
      },
    ]);

    setTimeout(() => this.onAplyFilterTotalizadores(), 100);
    this.props.setSelectedTypeFilter("andamento");
  };

  onClickNotMirrored = () => {
    const statusTime = this.props.statusTimeOptions.filter((i) => !i.status_time)[0];
    this.props.setStatusTimeSelectedFilter(statusTime);
    const statusTravel = [
      {
        travel_status: TRAVELING,
      },
      {
        travel_status: CREATED,
      },
    ];
    this.props.setStatusSelectedFilter(statusTravel);

    this.props.setFilterAux([
      {
        campo: "pathTraveledCount",
        valor: 2,
        tipoFiltro: "MENOR_OU_IGUAL",
      },
    ]);

    setTimeout(() => this.onAplyFilterTotalizadores(), 100);
    this.props.setSelectedTypeFilter("notmirrored");
  };

  onClickCanceladas = () => {
    const statusTime = this.props.statusTimeOptions.filter((i) => !i.status_time)[0];
    this.props.setStatusTimeSelectedFilter(statusTime);
    const statusTravel = [
      {
        travel_status: CANCELED,
      },
    ];
    this.props.setStatusSelectedFilter(statusTravel);

    this.props.setFilterAux([]);

    setTimeout(() => this.onAplyFilterTotalizadores(), 100);
    this.props.setSelectedTypeFilter("canceladas");
  };

  onClickTotal = () => {
    const status = [
      {
        travel_status: null,
        status: "Todos",
      },
    ];

    this.props.setFilterAux([]);

    this.props.setStatusSelectedFilter(status);

    const statusTime = this.props.statusTimeOptions.filter((i) => !i.status_time)[0];
    this.props.setStatusTimeSelectedFilter(statusTime);

    setTimeout(() => this.onAplyFilterTotalizadores(), 100);
    this.props.setSelectedTypeFilter("total");
  };

  onFilterStartPage = () => {
    this.props.setStatusSelectedFilter(this.state.statusSelectedFilter);
    this.props.setStatusTimeSelectedFilter(this.state.statusTimeSelectedFilter);

    // let filtersParams = this.getFilters();
    // this.props.loadTotalizers(filtersParams);
    const filtersParamsTotalizers = this.getFiltersTotalizers();
    this.props.loadTotalizers(filtersParamsTotalizers);

    console.log("onFilterStartPage - não deve passar aqi")
    if (this.props.towerShowTotalizers.andamento) {
      this.onClickAndamento();
    } else if (this.props.towerShowTotalizers.atraso) {
      this.onClickAtraso();
    } else if (this.props.towerShowTotalizers.tendencia_atraso) {
      this.onClickTendenciaAtraso();
    } else if (this.props.towerShowTotalizers.on_time) {
      this.onClickOnTime();
    } else if (this.props.towerShowTotalizers.notmirrored) {
      this.onClickNotMirrored();
    } else if (this.props.towerShowTotalizers.finalizados) {
      this.onClickFinalizadas();
    } else if (this.props.towerShowTotalizers.total) {
      this.onClickTotal();
    }
    //
  };

  onApplyFilter = () => {
    const filtersParams = this.getFilters();
    // window.localStorage.setItem("tower_filters",JSON.stringify(filtersParams))
    this.props.applyFilter(filtersParams);

    const filtersParamsTotalizers = this.getFiltersTotalizers();
    this.props.loadTotalizers(filtersParamsTotalizers);


    this.setState({
      statusSelectedFilter: this.props.statusSelectedFilter,
      statusTimeSelectedFilter: this.props.statusTimeSelectedFilter,
    });
  };

  //Lookup observation type
  lookupObservationTypeOnPage = (e) => {
    this.props.lookupObservationTypePaginate({
      max: e.rows,
      index: e.first,
    });
  };
  lookupObservationTypeOnSort = (e) => {
    this.props.lookupObservationTypeSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField,
    });
  };
  lookupObservationTypeOnFilter = (e) => {
    const filters = [];
    const keys = Object.keys(e.filters) || [];
    keys.forEach((key) => {
      filters.push({
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: "CONTEM",
      });
    });
    this.props.lookupObservationTypeApplyFilter(filters);
  };
  lookupObservationTypeOnInputFilter = (e) => {
    const value = e.value;
    const filters = [];
    if (typeof value === "string") {
      filters.push({
        campo: "name",
        valor: value,
        tipoFiltro: "CONTEM",
      });
      this.props.setObservationType({ name: value });
    } else {
      this.props.setObservationType(value);
    }
    this.props.lookupObservationTypeApplyFilter(filters);
  };

  lookupObservationTypeOnClick = (e) => {
    this.props.lookupObservationTypeLoad();
    this.props.lookupObservationTypeSetVisible(true);
  };
  lookupObservationTypeOnHide = (e) => {
    this.props.lookupObservationTypeSetVisible(false);
  };
  lookupObservationTypeOnConfirm = (e) => {
    this.props.lookupObservationTypeApplyFilter([]);
    this.props.setObservationType(e.selection);
    this.props.lookupObservationTypeSetVisible(false);
  };
  lookupObservationTypeOnCancel = (e) => {
    this.props.setObservationType(null);
    this.props.lookupObservationTypeSetVisible(false);
  };
  lookupObservationTypeOnEdit = () => {
    this.props.history.push(`${routes.PATH_TOWER_OBSERVATION_TYPE}/novo`);
  };

  lookupObservationTypeOnComplete = (e) => { };
  getObservationTypeFilter = () => {
    return this.props.observationType;
  };
  //Lookup observation type

  onExportTravelDownloadXLSX = () => {
    const filtersParams = this.getFilters();
    this.props.onExportTravelDownloadXLSX(filtersParams);
  };

  render() {
    const destinationCityTemplate = (rowData, column) => {
      try {
        return (
          <div>
            <span style={{ textAlign: "center", color: "black" }}>
              {rowData.destination.address.City}-{rowData.destination.address.State}
            </span>
          </div>
        );
      } catch (error) {
        return <div></div>;
      }
    };

    const deliveryDateTemplate = (rowData, column) => {
      try {
        let lastDeliveryName = "";
        let formattedDate = "";
        let state = "";
        if (rowData.lastDelivery) {
          const date = rowData.lastDelivery.deliveryDate; //data da ultima entrega realizada

          if (date) {
            formattedDate = moment(date).format("DD/MM/YYYY HH:mm:ss");
          }

          state = "-" + rowData.lastDelivery.address.State;
          lastDeliveryName = rowData.lastDelivery.address.City; //a ultima entrega realizada
        }
        return (
          <div>
            <p style={{ textAlign: "center", color: "black" }}>
              {lastDeliveryName}
              {state}
            </p>
            <p style={{ textAlign: "center", color: "black" }}>{formattedDate}</p>
          </div>
        );
      } catch (error) {
        return <div></div>;
      }
    };

    const deliveryForecastDateTemplate = (rowData, column) => {
      try {
        let lastDeliveryName = "";
        let formattedDate = "";
        let state = "";
        if (rowData.nextDelivery) {
          const date = rowData.nextDelivery.deliveryForecastDate; //data da ultima entrega realizada

          if (date) {
            formattedDate = moment(date).format("DD/MM/YYYY HH:mm:ss");
          }

          state = "-" + rowData.nextDelivery.address.State;
          lastDeliveryName = rowData.nextDelivery.address.City; //a ultima entrega realizada
        }
        return (
          <div>
            <p style={{ textAlign: "center", color: "black" }}>
              {lastDeliveryName}
              {state}
            </p>
            <p style={{ textAlign: "center", color: "black" }}>{formattedDate}</p>
          </div>
        );
      } catch (error) {
        return <div></div>;
      }
    };

    const acoesTemplate = (rowData, column) => {
      try {
        return (
          <div style={{ textAlign: "left", display: "flex" }}>
            <button
              onClick={() => {
                this.onClickShowMap(rowData);
              }}
              className={"btnAcoes"}
              title="Ver no Mapa"
            >
              <i>
                <img src={locationIcon} style={{ height: "26px" }}></img>
              </i>
            </button>
            <button
              onClick={() => {
                this.onClickImputObs(rowData);
              }}
              className={"btnAcoes"}
              title="Ver no Mapa"
            >
              <i>
                <img src={chatIcon} style={{ height: "26px" }}></img>
              </i>
            </button>
          </div>
        );
      } catch (error) {
        return <div></div>;
      }
    };

    const infoTranspTemplate = (rowData, column) => {
      let elementPlate = <span>{`${rowData.vehicle.plate}`}</span>
      if (rowData.travelsSamePlate && rowData.travelsSamePlate.length > 0) {
        elementPlate = <span style={{ color: "red" }}>{`${rowData.vehicle.plate}`}</span>
      }
      try {
        return (
          <div style={{ textAlign: "center" }} data-tip data-for={rowData.vehicle.plate}>
            {elementPlate}

            <ReactTooltip id={rowData.vehicle.plate} type="info">
              <div style={{ color: "#FFF", textAlign: "left" }}>
                <span>
                  Transpotadora:<b>{rowData.carrier.name}</b>
                </span>
                <br />
                <span>
                  Veículo <b>{rowData.vehicle.vehicleType.label}</b>
                </span>
                <br />
                <span>
                  Motorista:<b>{rowData.driver.name}</b>
                </span>
              </div>
            </ReactTooltip>
          </div>
        );
      } catch (error) {
        return <div></div>;
      }
    };
    const getIconStatusTravel = (rowData) => {
      try {
        let iconStatus = <img src={vehicleIconNormal} style={{ height: "26px" }} />;
        //se a viagem ainda não iniciou
        if (rowData.travel_status === CANCELED) {
          iconStatus = <img src={vehicleIconCanceled} style={{ height: "26px" }} title="Viagem cancelada" />;
        } else if (rowData.status_time === TRAVEL_ON_TIME) {
          iconStatus = <img src={vehicleIconSuccess} style={{ height: "26px" }} title="Dentro do prazo previsto" />;
        } else if (rowData.status_time === TRAVEL_LAG_TREND) {
          iconStatus = <img src={vehicleIconLagTrend} style={{ height: "26px" }} title="Tendência de atraso" />;
        } else if (rowData.status_time === TRAVEL_LATE) {
          iconStatus = <img src={vehicleIconWarning} style={{ height: "26px" }} title="Esta atrasada" />;
        }
        return <i>{iconStatus}</i>;
      } catch (error) {
        return <div></div>;
      }
    };

    const statusViagemTemplate = (rowData, column) => {
      try {
        let statusTravel = "CRIADA";
        if (rowData.travel_status === CREATED) {
          statusTravel = "CRIADA";
        } else if (rowData.travel_status === CANCELED) {
          statusTravel = "CANCELADA";
        } else if (rowData.travel_status === TRAVELING) {
          statusTravel = "EM VIAGEM";
        } else if (rowData.travel_status === END_TRAVEL) {
          statusTravel = "FINALIZADA";
        }
        const iconeStatus = getIconStatusTravel(rowData);
        return (
          <div className={"center-itens-row"}>
            {iconeStatus} {statusTravel}
          </div>
        );
      } catch (error) {
        return <div></div>;
      }
    };

    const tempoTotalKmtotalTemplate = (rowData, column) => {
      try {
        return (
          <div style={{ textAlign: "center", color: "black" }}>
            <span>{formatNumber(rowData.distanceTotalKm / 1000, 0)} km</span>
            {/* <br /> <span>{segParaHora(rowData.durationSeg)}</span> */}
          </div>
        );
      } catch (error) {
        return <div></div>;
      }
    };

    const distanceKmTemplate = (rowData, column) => {
      try {
        let kmTravel = `${rowData[column.field]} Km`;
        return <div style={{ textAlign: "center", color: "black" }}>{kmTravel}</div>;
      } catch (error) {
        return <div></div>;
      }
    };

    const proximEntreaEmTemplate = (rowData, column) => {
      try {
        return (
          <div style={{ textAlign: "center", color: "black" }}>
            <span>{formatNumber(rowData.nextDelivery.currentDistanceLeft / 1000, 0)} km</span>
            <br />
            <span>{segParaHora(rowData.nextDelivery.currentTimeLeft)}</span>
          </div>
        );
      } catch (error) {
        return <div></div>;
      }
    };

    const oidTemplate = (rowData, column) => {
      try {
        return (
          <div className="id-link">
            <Link to={`${routes.PATH_TOWER_TRAVEL}/${rowData.oid}`}>{rowData.oid}</Link>
          </div>
        );
      } catch (error) {
        return <div></div>;
      }
    };

    const timeLastPostionTemplate = (rowData, column) => {
      try {
        let labelStatus = "";
        if (!rowData.timeLastPostion) {
          labelStatus = "Não Sinc...";
        }
        const difSegunds = rowData.timeLastPostion ? moment().diff(moment(rowData.timeLastPostion), "seconds") : 0;
        let classStatus = "statusSincTravelOK";

        if (difSegunds > 2 * 3600) {
          //2horas
          classStatus = "statusSincTravelFault";
          labelStatus = segParaHora(difSegunds);
        } else if (difSegunds > 0) {
          labelStatus = "OK";
        }
        return (
          <div style={{ textAlign: "center", color: "black" }}>
            <span className={classStatus}>{labelStatus}</span>
          </div>
        );
      } catch (error) {
        return <div>{"---"}</div>;
      }
    };
    const percentTemplate = (rowData, column) => {
      try {
        let percent = `${rowData[column.field]} %`;
        return <div style={{ textAlign: "center", color: "black" }}>{percent}</div>;
      } catch (error) {
        return <div></div>;
      }
    };

    const datetimeTemplate = (rowData, column) => {
      try {
        const date = rowData.eventRecord.createdAt; //data da ultima entrega realizada
        let formattedDate = "";
        if (date) {
          formattedDate = moment(date).format("DD/MM/YYYY HH:mm:ss");
        }
        return (
          <div>
            <p style={{ textAlign: "center", color: "black" }}>{formattedDate}</p>
          </div>
        );
      } catch (error) {
        return <div></div>;
      }
    };
    let columns = this.props.columns.map((col) => {
      if (col.field === "destination.description") {
        return <Column {...col} key={col.field} body={destinationCityTemplate} style={{ fontSize: "11px" }} />;
      }
      if (col.field === "lastDelivery.description") {
        return <Column {...col} key={col.field} body={deliveryDateTemplate} style={{ fontSize: "11px" }} />;
      }
      if (col.field === "nextDelivery.description") {
        return <Column {...col} key={col.field} body={deliveryForecastDateTemplate} style={{ fontSize: "11px" }} />;
      }

      if (col.field === "acoes") {
        return <Column {...col} key={col.field} style={{ width: "8em", fontSize: "11px" }} body={acoesTemplate} />;
      }
      if (col.field === "vehicle.plate") {
        return <Column {...col} key={col.field} style={{ width: "6em", fontSize: "11px" }} body={infoTranspTemplate} />;
      }

      if (col.field === "currentPercent") {
        return <Column {...col} key={col.field} style={{ width: "7em", fontSize: "11px" }} body={percentTemplate} />;
      }
      if (col.field === "travel_status") {
        return <Column {...col} key={col.field} body={statusViagemTemplate} style={{ width: "10em", fontSize: "11px" }} />;
      }
      if (col.field === "tempoTotalKmtotal") {
        return <Column {...col} key={col.field} body={tempoTotalKmtotalTemplate} style={{ width: "10em", fontSize: "11px" }} />;
      }
      // if (col.field === "distanceTotalKm") {
      //   return <Column {...col} key={col.field} body={distanceKmTemplate} style={{ width: "7em",fontSize: "11px" }} />;
      // }
      if (col.field === "eventRecord.createdAt") {
        return <Column {...col} key={col.field} body={datetimeTemplate} style={{ width: "7em", fontSize: "11px" }} />;
      }
      if (col.field === "nextDelivery.currentDistanceLeft") {
        return <Column {...col} key={col.field} body={proximEntreaEmTemplate} style={{ width: "12em", fontSize: "11px" }} />;
      }
      if (col.field === "timeLastPostion") {
        return <Column {...col} key={col.field} body={timeLastPostionTemplate} style={{ width: "7em", fontSize: "11px" }} />;
      }
      if (col.field === "oid") {
        return <Column {...col} key={col.field} body={oidTemplate} style={{ width: "5em", fontSize: "11px" }} />;
      }

      return <Column {...col} key={col.field} style={{ fontSize: "12px" }} />;
    });

    const lookupCarrier = {
      ...this.props.lookupCarrier,
      lookupCarrierOnComplete: this.lookupCarrierOnComplete,
      lookupCarrierOnInputFilter: this.lookupCarrierOnInputFilter,
      lookupCarrierOnEdit: this.lookupCarrierOnEdit,
      lookupCarrierOnClick: this.lookupCarrierOnClick,
      lookupCarrierOnConfirm: this.lookupCarrierOnConfirm,
      lookupCarrierOnCancel: this.lookupCarrierOnCancel,
      lookupCarrierOnPage: this.lookupCarrierOnPage,
      lookupCarrierOnFilter: this.lookupCarrierOnFilter,
      lookupCarrierOnSort: this.lookupCarrierOnSort,
      lookupCarrierOnHide: this.lookupCarrierOnHide,
    };

    const lookupOrigin = {
      ...this.props.lookupOrigin,
      lookupOriginOnComplete: this.lookupOriginOnComplete,
      lookupOriginOnInputFilter: this.lookupOriginOnInputFilter,
      lookupOriginOnEdit: this.lookupOriginOnEdit,
      lookupOriginOnClick: this.lookupOriginOnClick,
      lookupOriginOnConfirm: this.lookupOriginOnConfirm,
      lookupOriginOnCancel: this.lookupOriginOnCancel,
      lookupOriginOnPage: this.lookupOriginOnPage,
      lookupOriginOnFilter: this.lookupOriginOnFilter,
      lookupOriginOnSort: this.lookupOriginOnSort,
      lookupOriginOnHide: this.lookupOriginOnHide,
    };

    const lookupDestination = {
      ...this.props.lookupDestination,
      lookupDestinationOnComplete: this.lookupDestinationOnComplete,
      lookupDestinationOnInputFilter: this.lookupDestinationOnInputFilter,
      lookupDestinationOnEdit: this.lookupDestinationOnEdit,
      lookupDestinationOnClick: this.lookupDestinationOnClick,
      lookupDestinationOnConfirm: this.lookupDestinationOnConfirm,
      lookupDestinationOnCancel: this.lookupDestinationOnCancel,
      lookupDestinationOnPage: this.lookupDestinationOnPage,
      lookupDestinationOnFilter: this.lookupDestinationOnFilter,
      lookupDestinationOnSort: this.lookupDestinationOnSort,
      lookupDestinationOnHide: this.lookupDestinationOnHide,
    };

    const lookupObservationType = {
      ...this.props.lookupObservationType,
      lookupDestinationOnComplete: this.lookupObservationTypeOnComplete,
      lookupObservationTypeOnInputFilter: this.lookupObservationTypeOnInputFilter,
      lookupObservationTypeOnEdit: this.lookupObservationTypeOnEdit,
      lookupObservationTypeOnClick: this.lookupObservationTypeOnClick,
      lookupObservationTypeOnConfirm: this.lookupObservationTypeOnConfirm,
      lookupObservationTypeOnCancel: this.lookupObservationTypeOnCancel,
      lookupObservationTypeOnPage: this.lookupObservationTypeOnPage,
      lookupObservationTypeOnFilter: this.lookupObservationTypeOnFilter,
      lookupObservationTypeOnSort: this.lookupObservationTypeOnSort,
      lookupObservationTypeOnHide: this.lookupObservationTypeOnHide,
    };

    const treeviewIndiceTemplate = (rowData, column) => {
      try {
        return <div style={{ textAlign: "center", color: "black" }}>{rowData.index}</div>;
      } catch (error) {
        return <div></div>;
      }
    };

    const treeviewCodeTemplate = (rowData, column) => {
      try {
        return <div style={{ textAlign: "center", color: "black" }}>{rowData.code}</div>;
      } catch (error) {
        return <div></div>;
      }
    };

    const treeviewEmbarqueTemplate = (rowData, column) => {
      try {
        return <div style={{ textAlign: "center", color: "black" }}>{rowData.embarque}</div>;
      } catch (error) {
        return <div></div>;
      }
    };

    const originalExpectedForecastDateTreeviewTemplate = (rowData, column) => {
      try {
        const date = rowData.originalExpectedDeliveryDate; //data da ultima entrega realizada
        let formattedDate = "";
        if (date) {
          formattedDate = moment(date).format("DD/MM/YYYY HH:mm:ss");
        }
        return (
          <div>
            <p style={{ textAlign: "center", color: "black" }}>{formattedDate}</p>
          </div>
        );
      } catch (error) {
        return <div></div>;
      }
    };
    const deliveryForecastDateTreeviewTemplate = (rowData, column) => {
      try {
        const date = rowData.deliveryForecastDate; //data da ultima entrega realizada
        let formattedDate = "";
        if (date) {
          formattedDate = moment(date).format("DD/MM/YYYY HH:mm:ss");
        }
        return (
          <div>
            <p style={{ textAlign: "center", color: "black" }}>{formattedDate}</p>
          </div>
        );
      } catch (error) {
        return <div></div>;
      }
    };
    const deliveryDateTreeviewTemplate = (rowData, column) => {
      try {
        const date = rowData.deliveryDate; //data da ultima entrega realizada
        let formattedDate = "";
        if (date) {
          formattedDate = moment(date).format("DD/MM/YYYY HH:mm:ss");
        }
        return (
          <div>
            <p style={{ textAlign: "center", color: "black" }}>{formattedDate}</p>
          </div>
        );
      } catch (error) {
        return <div></div>;
      }
    };
    const createdAtTreeviewTemplate = (rowData, column) => {
      try {
        const date = rowData.createdAt; //data da ultima entrega realizada
        let formattedDate = "";
        if (date) {
          formattedDate = moment(date).format("DD/MM/YYYY HH:mm:ss");
        }
        return (
          <div>
            <p style={{ textAlign: "center", color: "black" }}>{formattedDate}</p>
          </div>
        );
      } catch (error) {
        return <div></div>;
      }
    };
    const tempoTotalKmtotalTemplatePlate = (rowData, column) => {
      try {
        return (
          <div style={{ textAlign: "center", color: "black" }}>
            <span>{formatNumber(rowData.distanceTotalKm / 1000, 0)} km</span>
          </div>
        );
      } catch (error) {
        return <div></div>;
      }
    };
    const isOcurrenceTemplate = (rowData, column) => {
      try {
        return (
          <div style={{ textAlign: "center", color: "black" }}>
            <span>{rowData.isOcurrence ? "SIM" : "NÂO"}</span>
          </div>
        );
      } catch (error) {
        return <div></div>;
      }
    };

    const templateTowerRowTreeviewPlate = (rowData, e) => {
      const records = rowData.travelsSamePlate;
      let columnsTreeview = this.props.columnsRowTreeviewGridPlatesTemplate.map((col) => {
        if (col.field === "createdAt") {
          return <Column {...col} key={col.field} style={{ fontSize: "11px", padding: "3px" }} body={createdAtTreeviewTemplate} />;
        }
        if (col.field === "deliveryForecastDate") {
          return <Column {...col} key={col.field} style={{ fontSize: "11px", padding: "3px" }} body={deliveryForecastDateTreeviewTemplate} />;
        }
        if (col.field === "travel_status") {
          return <Column {...col} key={col.field} style={{ fontSize: "11px", padding: "3px" }} body={statusViagemTemplate} />;
        }
        if (col.field === "distanceTotalKm") {
          return <Column {...col} key={col.field} style={{ fontSize: "11px", padding: "3px" }} body={tempoTotalKmtotalTemplatePlate} />;
        }
        if (col.field === "oid") {
          return <Column {...col} key={col.field} body={oidTemplate} style={{ width: "6em", fontSize: "11px" }} />;
        }
        return <Column {...col} key={col.field} style={{ fontSize: "11px", padding: "3px" }} />;
      });

      return <TowerRowTreeviewPlate records={records} columns={columnsTreeview} />;
    };
    const templateExpandedRow = (rowData, e) => {
      const records = [];
      try {
        let sequencia = 0;
        rowData.destinations.forEach((dest) => {
          sequencia++;
          records.push({
            index: sequencia,
            code: dest.code,
            embarque: rowData.shipment_code,
            originalExpectedDeliveryDate: dest.originalExpectedDeliveryDate ? dest.originalExpectedDeliveryDate : "",
            deliveryForecastDate: dest.deliveryForecastDate,
            deliveryDate: dest.deliveryDate,
            isOcurrence: dest.isOcurrence || false,
          });
        });
      } catch (error) {
        console.log("Erro templateExpandedRow=", error);
      }

      let columnsTreeview = this.props.columnsRowTreeviewTemplate.map((col) => {
        if (col.field === "index") {
          return <Column {...col} key={col.field} style={{ width: "8em", fontSize: "11px", padding: "3px" }} body={treeviewIndiceTemplate} />;
        }
        if (col.field === "code") {
          return <Column {...col} key={col.field} style={{ width: "8em", fontSize: "11px", padding: "3px" }} body={treeviewCodeTemplate} />;
        }
        if (col.field === "embarque") {
          return <Column {...col} key={col.field} style={{ width: "8em", fontSize: "11px", padding: "3px" }} body={treeviewEmbarqueTemplate} />;
        }
        if (col.field === "originalExpectedDeliveryDate") {
          return <Column {...col} key={col.field} style={{ fontSize: "11px", padding: "3px" }} body={originalExpectedForecastDateTreeviewTemplate} />;
        }
        if (col.field === "deliveryForecastDate") {
          return <Column {...col} key={col.field} style={{ fontSize: "11px", padding: "3px" }} body={deliveryForecastDateTreeviewTemplate} />;
        }
        if (col.field === "deliveryDate") {
          return <Column {...col} key={col.field} style={{ fontSize: "11px", padding: "3px" }} body={deliveryDateTreeviewTemplate} />;
        }
        if (col.field === "isOcurrence") {
          return <Column {...col} key={col.field} style={{ fontSize: "11px", padding: "3px" }} body={isOcurrenceTemplate} />;
        }

      });

      let travelsSamePlate;
      if (rowData.travelsSamePlate && rowData.travelsSamePlate.length > 0) {
        travelsSamePlate = (
          <div style={{ marginTop: "12px" }}>
            <p style={{ color: "red" }}>Atenção: Viagens com a mesma placa</p>
            {templateTowerRowTreeviewPlate(rowData, e)}
          </div>
        );
      }

      return (
        <>
          <div>
            <h3>Entregas</h3>
            <TowerRowTreeview records={records} columns={columnsTreeview} />
          </div>
          {travelsSamePlate}
        </>
      );
    };

    const content = (
      <div className="tower_dashboard">
        <ReactTooltip />
        <IndicatorTravel
          {...this.props.totalizers}
          onClickFinalizadas={this.onClickFinalizadas}
          onClickOnTime={this.onClickOnTime}
          onClickAndamento={this.onClickAndamento}
          onClickAtraso={this.onClickAtraso}
          onClickTotal={this.onClickTotal}
          onClickTendenciaAtraso={this.onClickTendenciaAtraso}
          onClickNotMirrored={this.onClickNotMirrored}
          onClickCanceladas={this.onClickCanceladas}
          selectedTypeFilter={this.props.selectedTypeFilter}
          towerShowTotalizers={this.props.towerShowTotalizers}
          userSaveTowerTotalizers={this.props.userSaveTowerTotalizers}
          setTowerShowTotalizers={this.props.setTowerShowTotalizers}
          loaderUser={this.props.loaderUser}
        />
        <TravelFiltro
          onClickShowAllMap={this.onClickShowAllMap}
          onClickAplicarFiltro={this.onApplyFilter}
          {...this.props}
          lookupCarrier={lookupCarrier}
          lookupOrigin={lookupOrigin}
          lookupDestination={lookupDestination}
          onDropdownTravelStatusChange={this.setStatusSelectedFilter}
          onDropdownStatusTimeStatusChange={this.setStatusTimeSelectedFilter}
          initialDateChangeHandler={this.props.initialDateChangeHandler}
          finalDateChangeHandler={this.props.finalDateChangeHandler}
          deliveryCodeChangeHandler={this.deliveryCodeChangeHandler}
          embarqueIdChangeHandler={this.embarqueIdChangeHandler}
          enableExportCSV={this.props.enableExportCSV}
          onExportTravelDownloadXLSX={this.onExportTravelDownloadXLSX}
        />

        <TowerDashboardList
          {...this.props}
          rowToggleHandler={this.setExpandedRowsClick}
          rowExpandButtonOnClick={this.setExpandedRowsByRowClick}
          expandedRows={this.props.expandedRows}
          templateExpandedRow={templateExpandedRow}
          onFilter={this.onFilter}
          onPage={this.onPage}
          onSort={this.onSort}
          columns={columns}
        />

        <ImputTravelObsDialog
          onHide={this.onHideImputObs}
          onConfirm={this.onConfirmImputObs}
          onCancel={this.onCancelImputObs}
          inputObs={this.props.inputObs}
          visible={this.props.visibleImputTravelObsDialog}
          onChange={this.onChangeImputObs}
          lookupObservationType={lookupObservationType}
          observationType={this.props.observationType}
        />
      </div>
    );
    return <Page content={content} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TowerDashboardListPageContainer);
