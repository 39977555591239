import React from "react";
import { Dialog } from "primereact/dialog";

export default function ImageModal(props) {
  const buttonRotation = (
    <div>
      <button
        className="lognex-btn-highlighted"
        onClick={props.changeTransiction}
        title={"Girar imagem"}
      >
        <i className="pi pi-undo"></i>
      </button>
    </div>
  );

  return (
    <div>
      <div>
        <Dialog
          className="lxLookupDialog"
          header={props.title}
          visible={props.visible}
          style={{ width: "auto" }}
          modal={true}
          onHide={props.onHide}
        >
          {buttonRotation}

          {props.image}
        </Dialog>
      </div>
    </div>
  );
}
