import React from 'react';
import './TimelineCard.scss'
export const TimelineCard = (props) => {

    const buttonImg = () => {
        if (showComprovante()) {
            return (
                <button
                    className="btn_image" 
                    title={"Ver comprovante de entrega"}
                >
                    <i class="pi pi-images"></i>
                </button>
            )
        }
    }
    const showComprovante = () => {
        return props.resources && props.resources.length > 0;
    }
    return (
        <div className="timeline__card" onClick={(e)=>{
            if(showComprovante()){
                props.showVisibleModalImage(props.resources)
            }
        }}>
            <h4>{props.title}</h4>
            {props.subTitle && <h5>{props.subTitle}</h5>}
            <div>{props.text}</div> 
            {showComprovante() && <h4> Comprovante de entrega  {buttonImg()} </h4>}
        </div>
    );
}
