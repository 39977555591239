import React from "react";
import { connect } from "react-redux";
import Toolbar from "../../../components/toolbar/Toolbar";
import { routes } from '../../../constants';
import { Page } from "../../../components/page/Page";
import { PerfilForm } from './PerfilForm';
import { actions } from '../perfil.actions';
import { Column } from "primereact/column";
import { actions as actionsPolices } from '../../polices/polices.actions';
import { actions as actionsPolicesGroup } from '../../policeGroup/policesGroup.actions';
import { ButtonDialog } from "../../../components/button-dialog/ButtonDialog";


const mapStateToProps = state => {
  const lookupPolicesBase = {
    records: state.crudPolicesState.records,
    columns: state.crudPolicesState.columns.filter((col) => col.field === "oid" || col.field === "name" || col.field === "description").map((col) => <Column key={col.field} {...col} />),
    first: state.crudPolicesState.index,
    rows: state.crudPolicesState.max,
    totalRecords: state.crudPolicesState.count,
    sortField: state.crudPolicesState.sortField,
    order: state.crudPolicesState.sortOrder,
    rowsPerPageOptions: [20, 50, 100]
  };
  let lookupPolices = Object.assign({}, state.crudPerfilState.lookupPolices, lookupPolicesBase);

  return {
    record: state.crudPerfilState.currentRecord,
    polices: state.crudPerfilState.polices,
    lookupPolices,
    columnsPolices: state.crudPolicesGroupState.columnsPolices,
    selectedRecord: state.crudPolicesGroupState.selectedRecord,
    policesGroup: state.crudPolicesGroupState.policesGroup,
    groups:state.crudPerfilState.groups,
    columnsGroups:state.crudPerfilState.columnsGroups,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    changeHandler: event => {
      const target = event.target;
      const field = target.name;
      let value = target.value;

      dispatch(actions.removeErrors([{ field }]));
      dispatch(actions.setField(field, value));
    },
    saveHandler: event => {
      dispatch(actions.save());
    },
    removeHandler: event => {
      dispatch(actions.remove());
    },
    newHandler: event => {
      dispatch(actions.makeNew());
    },
    clearForm: () => dispatch(actions.makeNew()),
    loadById: oid => dispatch(actions.loadById(oid)),

    lookupPolicesSetVisible: (visible) => dispatch(actions.setLookupPolicesVisible(visible)),
    lookupPolicesPaginate: (paginationParams) => dispatch(actionsPolices.paginate(paginationParams)),
    lookupPolicesSortOrder: (sortParams) => dispatch(actionsPolices.sortOrder(sortParams)),
    lookupPolicesApplyFilter: (filterParams) => {
      dispatch(actionsPolices.setType(null));
      dispatch(actionsPolices.applyFilter(filterParams));
    },

    setLookupPolices: (polices) => {
      dispatch(actions.setPolice(polices));
    },

    //carregar todas politicas vinculado à esse grupo/perfil
    loadAllByGroupId: oid => dispatch(actionsPolicesGroup.loadAllByGroupId(oid)),
    removePolicesGroupBy: oid => dispatch(actionsPolicesGroup.removePolicesGroupBy(oid)),
    setRecordsLoadByGroupId: data => dispatch(actionsPolicesGroup.setRecordsLoadByGroupId(data)),

    addPoliceGroup: () => dispatch(actions.addPoliceGroup()), 

    loadAllGroups: () => dispatch(actions.loadAllGroups()),
    setGroups: (data) => dispatch(actions.setGroups(data)),
    addGroupFilho:(group)=>  dispatch(actions.addGroupFilho(group)),
    removeGroupBy:(id) =>  dispatch(actions.removeGroupBy(id)),
  };
};

class PerfilFormPageContainer extends React.Component {

  constructor(props) {
    super(props);
    actions.afterSave = ({ oid }) => {
      this.props.history.replace(`${routes.PATH_PERFIL}/${oid}`);
    };
    actions.afterNew = () => {
      this.props.history.replace(`${routes.PATH_PERFIL}/novo`);
      this.props.setRecordsLoadByGroupId([]);
    };
    actions.afterRemove = () => {
      this.props.history.replace(`${routes.PATH_PERFIL}`);
      this.props.setRecordsLoadByGroupId([]);
    };
  }

  componentDidMount() {
    const oid = this.props.match.params.id;

    if (oid && parseInt(oid)) {
      this.props.loadById(oid);
      this.props.loadAllByGroupId(oid);
      this.props.loadAllGroups();
    } else {
      this.newHandler();
      this.props.setRecordsLoadByGroupId([]);
      this.props.setGroups([]);
    }
  }
  searchHandler = () => {
    this.props.history.push(`${routes.PATH_PERFIL}`)
    this.props.setRecordsLoadByGroupId([]);
    this.props.setGroups([]);
  }
  newHandler = () => {
    this.props.setLookupPolices(null);
    this.props.newHandler();
  }
  refresh = () => {
    const oid = this.props.match.params.id;

    if (oid && parseInt(oid)) {
      this.props.loadById(oid);
      this.props.loadAllByGroupId(oid);
      this.props.loadAllGroups();
    }else{
      this.props.setGroups([]);
    }
  }
  //Polices
  lookupPolicesOnPage = (e) => {
    this.props.lookupPolicesPaginate({
      max: e.rows,
      index: e.first,
    });
  };
  lookupPolicesOnSort = (e) => {
    this.props.lookupPolicesSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField,
    });
  };
  lookupPolicesOnFilter = (e) => {
    const filters = [];
    const keys = Object.keys(e.filters) || [];
    keys.forEach((key) => {
      filters.push({
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: "CONTEM",
      });
    });

    this.props.lookupPolicesApplyFilter(filters);
  };
  lookupPolicesOnClick = (e) => {
    const filters = [];
    this.props.lookupPolicesSetVisible(true);
    this.props.lookupPolicesApplyFilter(filters);

  };
  lookupPolicesOnHide = (e) => {
    this.props.lookupPolicesSetVisible(false);
  };
  lookupPolicesOnConfirm = (e) => {
    this.props.setLookupPolices(e.selection);
    this.props.lookupPolicesSetVisible(false);
  };
  lookupPolicesOnCancel = (e) => {
    this.props.setLookupPolices(null);
    this.props.lookupPolicesSetVisible(false);
  };

  lookupPolicesOnEdit = () => {
    if (this.props.polices && this.props.polices.oid) {
      this.props.history.push(`${routes.PATH_POLICES}/${this.props.polices.oid}`);
    }
  };

  lookupPolicesOnComplete = (e) => { };

  lookupPolicesOnInputFilter = (e) => {
    const value = e.value;
    if (typeof value === "string") {
      const filters = [
        {
          campo: "name",
          valor: e.value,
          tipoFiltro: "CONTEM",
        },
      ];

      this.props.setField({ name: e.value });
      this.props.lookupPolicesApplyFilter(filters);
    } else {
      this.props.setLookupPolices(value);
    }
  };

  addPoliceGroup = (e) => {
    this.props.addPoliceGroup();
    setTimeout(() => { this.refresh() }, 200);
  }


  render() {
 
    const title = `Perfil ${this.props.record.oid || 'novo'}`
    const header = (
      <div>
        <h1>{title}</h1>
        <hr className="lxSplitter" ></hr>
        <div>
          <Toolbar
            onSave={this.props.saveHandler} saveButtonStyle='highlighted'
            onRemove={this.props.removeHandler} removeButtonStyle='danger'
            onNew={this.newHandler}
            onSearch={this.searchHandler}>
          </Toolbar>
        </div>
      </div>
    );

    const templateRemoverPoliceGroup = (rowData, e) => {

      return (
        <div>
          <ButtonDialog
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              const id = rowData.oid
              this.props.removePolicesGroupBy(id);
              setTimeout(() => { this.refresh() }, 200);
            }}
            button={{
              title: "Remover",
              disabled: false,
              className: "lognex-btn-danger",
            }}
            dialog={{
              header: "Confirmação",
              modal: true,
              message: "Gostaria de remover essa Police x Group?",
            }}
          ></ButtonDialog>
        </div>
      );
    };

    const templateRemoverGroup = (rowData, e) => {

      return (
        <div>
          <ButtonDialog
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              const id = rowData.oid
              this.props.removeGroupBy(id); 
            }}
            button={{
              title: "Remover",
              disabled: false,
              className: "lognex-btn-danger",
            }}
            dialog={{
              header: "Confirmação",
              modal: true,
              message: "Gostaria de remover essa Police x Group?",
            }}
          ></ButtonDialog>
        </div>
      );
    };
    const columnsPolices = this.props.columnsPolices.map((col) => {
      if (col.field == "remove") {
        return <Column {...col} key={col.field} style={{ width: "60px" }} body={templateRemoverPoliceGroup} />;
      }
      return <Column {...col} key={col.field} />;
    });

    const columnsGroups = this.props.columnsGroups.map((col) => {
      if (col.field == "remove") {
        return <Column {...col} key={col.field} style={{ width: "60px" }} body={templateRemoverGroup} />;
      }
      return <Column {...col} key={col.field} />;
    });


    const content = (<PerfilForm
      record={this.props.record}
      changeHandler={this.props.changeHandler}
      policesGroup={this.props.policesGroup}
      selectedRecord={this.props.selectedRecord}
      columnsPolices={columnsPolices}
      polices={this.props.polices}

      //Politicas
      lookupPolices={this.props.lookupPolices}
      lookupPolicesOnHide={this.lookupPolicesOnHide}
      lookupPolicesOnConfirm={this.lookupPolicesOnConfirm}
      lookupPolicesOnCancel={this.lookupPolicesOnCancel}
      lookupPolicesOnPage={this.lookupPolicesOnPage}
      lookupPolicesOnSort={this.lookupPolicesOnSort}
      lookupPolicesOnFilter={this.lookupPolicesOnFilter}
      lookupPolicesOnClick={this.lookupPolicesOnClick}
      lookupPolicesOnEdit={this.lookupPolicesOnEdit}
      lookupPolicesOnComplete={this.lookupPolicesOnComplete}
      lookupPolicesOnInputFilter={this.lookupPolicesOnInputFilter}

      addPoliceGroup={this.addPoliceGroup}
      groups={this.props.groups}
      columnsGroups={columnsGroups}
      addGroupFilho={this.props.addGroupFilho}
    />);
    return (<Page header={header} content={content} />);
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(PerfilFormPageContainer);
