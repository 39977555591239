import React from 'react';
import { InputText } from 'primereact/inputtext';

import './PolicesForm.scss';

export const PolicesForm = ({ record, changeHandler }) => {

    return (
        <div className="Polices-form">
            <div className="p-grid">
                <div className="p-col-6">
                    <div className="p-grid">
                        <div className="p-col-3">
                            <div className="form-field-container">
                                <label>Código</label>
                                <InputText value={record.oid} onChange={changeHandler} disabled={true} />
                            </div>

                        </div>
                        <div className="p-col-12 form-field-container">
                            <label className="lxRequiredField">Nome</label>
                            <InputText value={record.name} onChange={changeHandler} name="name" />
                        </div>
                        <div className="p-col-12 form-field-container">
                            <label>Descrição</label>
                            <InputText value={record.description} onChange={changeHandler} name="description" />
                        </div>
                        <div className="p-col-6 form-field-container">
                            <label>Categoria</label>
                            <InputText value={record.category} onChange={changeHandler} name="category" />
                        </div>
                        <div className="p-col-6 form-field-container">
                            <label>Módulo</label>
                            <InputText value={record.module} onChange={changeHandler} name="module" />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}
