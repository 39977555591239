import React from "react";
import moment from 'moment';
import { connect } from "react-redux";
import { Column } from "primereact/column";
import { Loader } from '../../../components/loader/Loader';
import { Page } from "../../../components/page/Page";
import { actions } from "../rastrearDelivery.actions";
import "./RastrearDeliveryForm.scss";
import { RastrearDeliveryForm } from "./RastrearDeliveryForm";

const mapStateToProps = store => {
  const { rastrearDeliveryState } = store;
  const link = window.location.href;
  const record = rastrearDeliveryState.currentRecord;

  return {
    record,
    cdRastreio: rastrearDeliveryState.cdRastreio
  }
}

const mapDispatchToProps = dispatch => {
  return {
    findDeliveryByTraceCode: () => dispatch(actions.findDeliveryByTraceCode()),
    onChangeCodigoRastreio: (codigoRastreio) => dispatch(actions.onChangeCodigoRastreio(codigoRastreio)),
  }
}

function Check(props) {
  if (!props.show) {
    return (<div style={{ width: "17px" }}></div>)
  }
  return (
    <React.Fragment>
      <div >
        <i className="pi pi-check"></i>
      </div>

    </React.Fragment>

  )
}

class RastrearDeliveryFormPageContainer extends React.Component {
  constructor(props) {
    super(props);

  }

  componentDidMount(props) { 
    const traceCode = this.props.match.params.traceCode; 
    if (traceCode && traceCode != "") {
      this.props.onChangeCodigoRastreio(traceCode);
      this.props.findDeliveryByTraceCode();
    }
  }


  onSetCodeOrder = (code) => {
    this.props.setCodeOrder(code);
  }


  changeHandler = (e) => {
    this.props.changeHandler(e);
  }

  showModalUploadImg = (visible) => {
    this.props.setShowModalUploadImg(visible);
  }


  render() {
    const header = (
      <div>
        <div className="header-collectionOrder">
          <div className="header-collectionOrder-logo"></div>
        </div>
        <div className="header-title">
          <h1>Rastrear Delivery</h1>
          <Loader />
          <hr className="lxSplitter"></hr>
        </div>
      </div>
    )

    const content = () => {

      return (
        <div>
          <RastrearDeliveryForm
            record={this.props.record}
            cdRastreio={this.props.cdRastreio}
            onChangeCodigoRastreio={this.props.onChangeCodigoRastreio}
            findDeliveryByTraceCode={this.props.findDeliveryByTraceCode}

          />
        </div>
      )

    }

    return (

      <div>
        {header}
        <Page content={content()} />
      </div>
    )

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RastrearDeliveryFormPageContainer);
