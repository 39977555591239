
    import { validate } from './validations';
    import { build } from '../../services/crudFactory';
    import { Action } from '../../services/actions.factory';
    const BASE_PATH = '/lognex/products';
    const prefix = 'crudProduct';
    const crud = build(validate, BASE_PATH);

    const newTypes = {
        
      SET_LOOKUP_PRODUCTPARENT_VISIBLE: `${prefix}setLookupProductParentVisible`
    }

    const actions = new Action(prefix, crud);

    const types = Object.assign({}, actions.types, newTypes);
    
    
        actions.setLookupProductParentVisible = (visible) => {
        return {
            type: types.SET_LOOKUP_PRODUCTPARENT_VISIBLE,
            visible
        }
        }

    export { types, actions }