import React from "react";
import { Card } from "primereact/card";
import { DockConfigPage } from "./list/DockConfigListPage";

export const DockConfigPanel = (props) => {
  return (
    <Card>
      <DockConfigPage />
    </Card>
  );
};
