import React from "react";
import { DataTable } from "primereact/datatable";

export const ZipcodeRangeCampAnyList = (props) => (
  <DataTable
    selectionMode="single"
    metaKeySelection={false} 
    value={props.records}
    lazy={true} 
    paginator={false}  
    emptyMessage="Não foram encontrados registros"  
  >
    {props.columns}
  </DataTable>
);
