
import React from 'react';
import { connect } from 'react-redux';
import Toolbar from "../../../components/toolbar/Toolbar";
import { actions } from '../freightComponent.actions';
import { routes } from '../../../constants';
import { Page } from "../../../components/page/Page";
import { FreightComponentForm } from './FreightComponentForm';

function getGroupType(groupType) {
  let value = "";
  switch (groupType) {
    case 'COMPANY':
      value = "EMPRESA";
      break;
    case 'SYSTEM':
      value = "SISTEMA";
      break;
  }
  return value;
}


const mapStateToProps = state => {
  const record = state.crudFreightComponentState.currentRecord;
  const sharingCargoTypes = state.crudFreightComponentState.sharingCargoTypes;
  const standardComponents = state.crudFreightComponentState.standardComponents;

  let disableBoolean = record.useMinValue ||
    record.suggestionUseMinValue ||
    record.useMaxValue ||
    record.suggestionUseMaxValue ||
    record.useFixedValue ||
    record.suggestionUseFixedValue ||
    record.usePercentValue ||
    record.suggestionUsePercentValue ||
    record.useKilometerValue ||
    record.suggestionUseKilometerValue ||
    record.useKiloValue ||
    record.suggestionUseKiloValue ||
    record.useMinValue ||
    record.unitValue ||
    record.discontValue ||
    record.useCalculatedValue ||
    record.useList;

  let disableValue = record.useBooleanValue || record.useList;

  let disableDefinedList = (record.useMinValue ||
    record.suggestionUseMinValue ||
    record.useMaxValue ||
    record.suggestionUseMaxValue ||
    record.useFixedValue ||
    record.suggestionUseFixedValue ||
    record.usePercentValue ||
    record.suggestionUsePercentValue ||
    record.useKilometerValue ||
    record.suggestionUseKilometerValue ||
    record.usekiloValue ||
    record.suggestionUsekiloValue ||
    record.useMinValue ||
    record.unitValue ||
    record.discontValue ||
    record.useCalculatedValue ||
    record.useBooleanValue);

  let disableCalculateValue = record.useFixedValue ||
    record.suggestionUseFixedValue ||
    record.useKilometerValue ||
    record.suggestionUseKilometerValue ||
    record.usekiloValue ||
    record.suggestionUsekiloValue ||
    record.useMinValue ||
    record.unitValue ||
    record.discontValue ||
    record.useCalculatedValue;

    if(disableBoolean){
      disableValue = false;
      record.useBooleanValue = false;
    }
    



  const items = state.crudFreightComponentState.rangeTypes.map((item) => {
    const checked = record.rangeTypes.filter(recordItem => { return recordItem.oid === item.oid }).length > 0;
    return {
      record: item,
      id: item.oid,
      label: item.name,
      checked
    }
  })

  let groupType = getGroupType(record.groupType);

  return {
    groupType,
    record,
    sharingCargoTypes,
    standardComponents,
    rangeTypes: items,
    disableBoolean,
    disableValue,
    disableDefinedList,
    disableCalculateValue,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setField: (field, value) => dispatch(actions.setField(field, value)),
    changeHandler: (field, value) => {
      dispatch(actions.removeErrors([{ field }]));
      dispatch(actions.setField(field, value));
    },
    saveHandler: event => {
      dispatch(actions.save());
    },
    removeHandler: event => {
      dispatch(actions.remove());
    },
    newHandler: event => {
      dispatch(actions.makeNew());
    },
    loadById: oid => dispatch(actions.loadById(oid)),
    addItemPresetList: e => dispatch(actions.addItemPresetList(e)),
    removeItemPresetList: e => dispatch(actions.removeItemPresetList(e)),
    removeAllPresetList: () => dispatch(actions.removeAllPresetList()),
    changeRangeTypes: e => dispatch(actions.setField('rangeTypes', e)),
    setField: (field, value) => dispatch(actions.setField(field, value)),
    setRestrictedByType: (value) => dispatch(actions.setRestrictedByType(value)),
  }
}

class FreightComponentFormPageContainer extends React.Component {

  constructor(props) {
    super(props);
    actions.afterSave = () => {
      const oid = this.props.record.oid;
      this.props.history.replace(`${routes.PATH_FREIGHT_COMPONENT}/${oid}`);
      if (oid && parseInt(oid)) {
        this.props.loadById(oid);
      } else {
        this.props.newHandler();
      }
    };
    actions.afterNew = () => {
      this.props.history.replace(`${routes.PATH_FREIGHT_COMPONENT}/novo`);
    };
    actions.afterRemove = () => {
      this.props.history.replace(`${routes.PATH_FREIGHT_COMPONENT}`);
    };
  }

  componentDidMount() {
    const oid = this.props.match.params.id;

    if (oid && parseInt(oid)) {
      this.props.loadById(oid);
    } else {
      this.props.newHandler();
    }
  }
  changeHandler = (event) => {
    const target = event.target;
    const field = target.name;
    let value = target.value;
    if (field === "rangeTypes") {
      let ranges = [...this.props.record.rangeTypes]
      if (ranges.find((rangeType) => rangeType === target.id)) {
        ranges = ranges.filter(rangeType => rangeType !== target.id)
      } else {
        if (target.id === "Weight" && target.value === true) {
          if (ranges.find((rangeType) => rangeType === "ExcessWeight")) {
            ranges = ranges.filter(rangeType => rangeType !== "ExcessWeight")
          }
        }
        if (target.id === "ExcessWeight" && target.value === true) {
          if (ranges.find((rangeType) => rangeType === "Weight")) {
            ranges = ranges.filter(rangeType => rangeType !== "Weight")
          }
        }

        if (target.id === "Kilometer" && target.value === true) {
          if (ranges.find((rangeType) => rangeType === "ExcessKilometer")) {
            ranges = ranges.filter(rangeType => rangeType !== "ExcessKilometer")
          }
        }
        if (target.id === "ExcessKilometer" && target.value === true) {
          if (ranges.find((rangeType) => rangeType === "Kilometer")) {
            ranges = ranges.filter(rangeType => rangeType !== "Kilometer")
          }
        }
        ranges.push(target.id)
      }
      value = ranges;
    }
    
    if(target.name === "useDiscontValue"){
      this.props.setField("useMinValue", target.value);
    } 

    // if(target.name === "useCalculatedValue"){
      //  deixar ativo apenas os botoes de 
      // valor mínimo
      // valor máximo
      // valor percentual

        
    // } 

    if (target.id === "Client" && target.value === true) {
      this.props.setField("restrictedByLocalization", false);
      this.props.setField("restrictedByBusinessUnit", false);
    }

    if (target.id === "Localization" && target.value === true) {
      this.props.setField("restrictedByClient", false);
      this.props.setField("restrictedByBusinessUnit", false);
    }

    if(target.id === "Unit" && target.value === true) {
      this.props.setField("restrictedByLocalization", false);
      this.props.setField("restrictedByClient", false);
    }

    if(target.id == "Allowed" && target.value === true){
      this.props.setField("restrictedByTypeDenied", false)
      this.props.setRestrictedByType('allow')
    }

    if(target.id == "Denied" && target.value === true){
      this.props.setField("restrictedByTypeAllowed", false)
      this.props.setRestrictedByType('denied')
    }
    this.props.changeHandler(field, value)
  }
  searchHandler = () => {
    this.props.history.push(`${routes.PATH_FREIGHT_COMPONENT}`)
  }

  onRangeTypesSelectionChange = (e) => {
    this.props.changeRangeTypes(e);
  }

  addItemPresetList = (e) => {
    this.props.addItemPresetList(e);
  }

  removeItemPresetList = (e) => {
    this.props.removeItemPresetList(e);
  }

  onChangeUseList = (e) => {
    if (!e.target.value) {
      this.props.removeAllPresetList();
    }
    this.props.changeHandler(e);
  }
  render() {
    const content = (
      <FreightComponentForm
        changeHandler={this.changeHandler}
        sharingCargoTypes={this.props.sharingCargoTypes}
        standardComponents={this.props.standardComponents}
        record={this.props.record}
        groupType={this.props.groupType}
        rangeTypes={this.props.rangeTypes}
        onSelectionChange={this.onRangeTypesSelectionChange}
        disableBoolean={this.props.disableBoolean}
        disableValue={this.props.disableValue}
        disableCalculateValue={this.props.disableCalculateValue}
        //disableLocation={this.props.disableLocation}
        disableDefinedList={this.props.disableDefinedList}
        addItemPresetList={this.addItemPresetList}
        removeItemPresetList={this.removeItemPresetList}
        onChangeUseList={this.onChangeUseList}
      />
    );
    const title = `Componente de Frete ${this.props.record.oid || 'novo'}`
    const header = (
      <div>
        <h1>{title}</h1>
        <hr className="lxSplitter" ></hr>
        <div>
          <Toolbar
            onSave={this.props.saveHandler} saveButtonStyle='highlighted'
            onRemove={this.props.removeHandler} removeButtonStyle='danger'
            onNew={this.props.newHandler}
            onSearch={this.searchHandler}>
          </Toolbar>
        </div>
      </div>
    );
    return (<Page header={header} content={content} />);
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FreightComponentFormPageContainer);