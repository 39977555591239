import { Action } from '../../services/actions.factory';
import api from "../../services/api.service";
import { build } from '../../services/crudFactory';
import { validate } from './validations';
const BASE_PATH = '/portal/collectionorder';
const prefix = 'collectionOrder';
const crud = build(validate, BASE_PATH);

const newTypes = {
    HIDE_LOADER: `${prefix}hideLoader`,
    SHOW_LOADER: `${prefix}showLoader`,
    SET_COLLECTION_ORDER: `${prefix}setCollectionOrder`,
    SET_REJECTED: `${prefix}setReject`,
    SET_CONFIRMED: `${prefix}setConfirmed`,
    SET_REQUEST_STATUS: `${prefix}setRequestStatus`,
    SET_DISABLED_BTN_CONFIRM: `${prefix}setDisabledBtnConfirm`,
    SET_DISABLED_BTN_REJECT: `${prefix}setDisabledBtnReject`,
    SET_CODE_ORDER: `${prefix}setCodeOrder`,
    SET_ORDERS: `${prefix}setOrders`,
    SET_SHOW_MODAL_UPLOAD_IMG: `${prefix}setShowModalUploadImg`,
    LOAD_EQUIPMENT_TYPE: `${prefix}loadEquipmentType`,
    SET_EQUIPMENT_TYPE: `${prefix}setEquipmentType`,
    LOAD_HOURS_DOCKS: `${prefix}loadHoursDocks`,
    SET_HOURS_DOCKS_RECODRS: `${prefix}setHoursDocks`,
    SET_ERROR_MESSAGE: `${prefix}setErrorMessage`,
    ON_SHOW_MESSAGE_ERROR: `${prefix}setShowMessageErro`,
    SET_SELECTED_DOCK_SCHEDULING: `${prefix}setDockScheduling`,
    ON_CHANGE_HANDLE_IMAGE: `${prefix}changeHandlerImage`,
    SET_DELIVERY_DATE: `${prefix}onChangeDeliveryDate`,
    SET_OBSERVATION: `${prefix}onChangeObs`,
}

const actions = new Action(prefix, crud);

const types = Object.assign({}, actions.types, newTypes);

actions.onChangeObs = (obs) => {
    return {
        type: types.SET_OBSERVATION,
        obs
    };
}

actions.onChangeDeliveryDate = (deliveryDate) => {
    return {
        type: types.SET_DELIVERY_DATE,
        deliveryDate
    };
}

actions.setDockScheduling = (dockSelected) => {
    return {
        type: types.SET_SELECTED_DOCK_SCHEDULING,
        dockSelected
    };
}
actions.changeHandlerImage = (fileImg) => {
    return {
        type: types.ON_CHANGE_HANDLE_IMAGE,
        fileImg
    };
}
actions.setErrorMessage = (message) => {
    return {
        type: types.SET_ERROR_MESSAGE,
        message
    };
}

actions.setShowMessageErro = (visible) => {
    return {
        type: types.ON_SHOW_MESSAGE_ERROR,
        visible
    };
}

actions.setHoursDocks = (docasHoursRangeTime) => {
    return {
        type: types.SET_HOURS_DOCKS_RECODRS,
        docasHoursRangeTime
    };
}
actions.setEquipmentType = (equipmentTypes) => {
    return {
        type: types.SET_EQUIPMENT_TYPE,
        equipmentTypes
    };
}

actions.setShowModalUploadImg = (visible) => {
    return {
        type: types.SET_SHOW_MODAL_UPLOAD_IMG,
        visible
    };
}

actions.hideLoader = () => {
    return {
        type: types.HIDE_LOADER,
        async: false
    };
}

actions.showLoader = () => {
    return {
        type: types.SHOW_LOADER,
        async: true
    };
}

actions.setOrders = (orders) => {
    return {
        type: types.SET_ORDERS,
        orders
    }
}
actions.setCodeOrder = (order) => {
    return {
        type: types.SET_CODE_ORDER,
        order
    }
}

actions.setCollectionOrder = (collectionOrder) => {
    return {
        type: types.SET_COLLECTION_ORDER,
        collectionOrder
    }
}

actions.setStatus = (status) => {
    return {
        type: types.SET_REQUEST_STATUS,
        status
    }
}

actions.setDisabledBtnConfirm = (disabled) => {
    return {
        type: types.SET_DISABLED_BTN_CONFIRM,
        disabled
    }
}

actions.setDisabledBtnReject = (disabled) => {
    return {
        type: types.SET_DISABLED_BTN_REJECT,
        disabled
    }
}

actions.loadCollectionOrder = (link) => {
    return (dispatch) => {
        api.post(`${BASE_PATH}/load`, { link })
            .then((result) => {
                dispatch(actions.setStatus("SUCCESS_LOAD"));
                dispatch(actions.setCollectionOrder(result.data));
                dispatch(actions.loadEquipmentType(link));
                dispatch(actions.loadHoursDocks(link));
                dispatch(actions.hideLoader());
            })
            .catch((error) => {
                dispatch(actions.setStatus("FAIL_LOAD"));
                dispatch(actions.hideLoader());
                console.log(error)
            });
    }
}



actions.confirmCollectionOrder = (data, statusCollectionOrder) => {
    return (dispatch) => {
        let costumHeaders = {
            origem: "transportadora"
        }

        dispatch(actions.setDisabledBtnConfirm(true))
        dispatch(actions.setDisabledBtnReject(true))
        if (statusCollectionOrder === "NEW") {
            dispatch(actions.showLoader());
            console.log("confirmCollectionOrder data=", data)

            let msgErro = [];
            const newList = [];
            if (data.dockScheduling) {
                data.dockScheduling.forEach(d => {
                    if (d.useDocas && !d.rangeTime) {
                        msgErro.push("É obrigatorio o agendamento para DOCA: " + d.sigla, " CNPJ: " + d.documentNumber)
                    } else {
                        newList.push(d.rangeTime);
                    }
                });
            }
            data.dockScheduling = newList;
            if (msgErro.length > 0) {
                dispatch(actions.setStatus("FAIL_CONFIRM"))

                dispatch(actions.setErrorMessage(msgErro.join(",")))
                dispatch(actions.setShowMessageErro(true));
                dispatch(actions.hideLoader());
                return
            }

            api.post(`${BASE_PATH}/confirm`, data, costumHeaders)
                .then((resultData) => {
                    console.log("resultData=", resultData)
                    if (resultData.data && resultData.data.erro) {
                        dispatch(actions.setStatus("FAIL_CONFIRM"))

                        dispatch(actions.setErrorMessage(resultData.data.erro))
                        dispatch(actions.setShowMessageErro(true));

                        dispatch(actions.setDisabledBtnConfirm(false))
                        dispatch(actions.setDisabledBtnReject(false))
                        dispatch(actions.hideLoader());
                        //load
                        dispatch(actions.loadHoursDocks(window.location.href));
                    } else {
                        dispatch(actions.setStatus("SUCCESS_CONFIRM"))
                        dispatch(actions.setDisabledBtnConfirm(false))
                        dispatch(actions.setDisabledBtnReject(false));
                        dispatch(actions.hideLoader());
                    }
                })
                .catch((error) => {
                    dispatch(actions.setStatus("FAIL_CONFIRM"))
                    // const messages = ["Não foi possivel confirmar a ordem de coleta"];
                    dispatch(actions.setErrorMessage("Não foi possivel confirmar a ordem de coleta"))
                    dispatch(actions.setShowMessageErro(true));
                    /*dispatch(
                        actions.messageActions.messageShowMessages(
                            actions.makeMessages(messages, "error")
                        )
                    );*/
                    dispatch(actions.setDisabledBtnConfirm(false))
                    dispatch(actions.setDisabledBtnReject(false))
                    dispatch(actions.hideLoader());

                    //load
                    dispatch(actions.loadHoursDocks(window.location.href));
                    console.log(error)
                });
        } else {
            dispatch(actions.showLoader());
            api.post(`${BASE_PATH}/update`, data, costumHeaders)
                .then((data) => {
                    dispatch(actions.setStatus("SUCCESS_CONFIRM"))
                    dispatch(actions.setDisabledBtnConfirm(false))
                    dispatch(actions.setDisabledBtnReject(false))
                    dispatch(actions.hideLoader());
                })
                .catch((error) => {
                    console.log("error=", error)
                    dispatch(actions.setStatus("FAIL_CONFIRM"))
                    const messages = ["Não foi possivel confirmar a ordem de coleta"];
                    dispatch(
                        actions.messageActions.messageShowMessages(
                            actions.makeMessages(messages, "error")
                        )
                    );
                    dispatch(actions.setDisabledBtnConfirm(false))
                    dispatch(actions.setDisabledBtnReject(false))
                    dispatch(actions.hideLoader());
                    console.log(error)
                });
        }

    }

}

actions.rejectedCollectionOrder = (data) => {
    return (dispatch) => {
        let costumHeaders = {
            origem: "transportadora"
        }
        dispatch(actions.setDisabledBtnReject(true))
        dispatch(actions.setDisabledBtnConfirm(true))
        dispatch(actions.showLoader());
        api.post(`${BASE_PATH}/rejected`, data, costumHeaders)
            .then((data) => {
                dispatch(actions.setStatus("SUCCESS_REJECT"))
                dispatch(actions.setDisabledBtnReject(false))
                dispatch(actions.setDisabledBtnConfirm(false))
            })
            .catch((error) => {
                dispatch(actions.setStatus("FAIL_REJECT"))
                const messages = ["Não foi possivel rejeitar a ordem de coleta"];
                dispatch(
                    actions.messageActions.messageShowMessages(
                        actions.makeMessages(messages, "error")
                    )
                );
                dispatch(actions.setDisabledBtnReject(false))
                dispatch(actions.setDisabledBtnConfirm(false))
                console.log(error)
            })
    }

}

actions.sendReceipt = (data) => {
    return (dispatch) => {
        let costumHeaders = {
            origem: "transportadora"
        }
        dispatch(actions.showLoader());
        if (data && data.deliveryDate && data.deliveryDate != "") {
            const url = `${BASE_PATH}/add/receipt`
            api.post(url, data, costumHeaders)
                .then((response) => {
                    dispatch(actions.hideLoader());
                    dispatch(actions.setShowModalUploadImg(false));
                    const messages = ["Comprovante enviado com sucesso."];
                    dispatch(
                        actions.messageActions.messageShowMessages(
                            actions.makeMessages(messages, "success")
                        )
                    );
                    dispatch(actions.loadOrdersInCargoShipment({ link: data.link }));
                    dispatch(actions.setCodeOrder(null));
                })
                .catch((err) => {
                    console.log("err = ", err);
                    dispatch(actions.setShowModalUploadImg(false));
                    dispatch(actions.hideLoader());
                    const messages = ["Não foi possível enviar o comprovante de entrega da ordem selecionada"];
                    dispatch(
                        actions.messageActions.messageShowMessages(
                            actions.makeMessages(messages, "error")
                        )
                    );
                })
        }
        else {
            dispatch(actions.hideLoader());
            const messages = ["A data de entrega deve ser informada"];
            dispatch(
                actions.messageActions.messageShowMessages(
                    actions.makeMessages(messages, "error")
                )
            )
        }

    }
}

actions.loadOrdersInCargoShipment = (data) => {
    return (dispatch) => {
        dispatch(actions.showLoader());
        if (data) {
            const url = `${BASE_PATH}/findByOrders/cargoShipment`
            api.post(url, data)
                .then((result) => {
                    dispatch(actions.setOrders(result.data));
                    console.log("result.data:", result.data)
                    dispatch(actions.hideLoader());
                })
                .catch((err) => {
                    console.log("err = ", err);
                    dispatch(actions.hideLoader());
                    const messages = ["Não foi carregar as ordens do embarque"];
                    dispatch(
                        actions.messageActions.messageShowMessages(
                            actions.makeMessages(messages, "error")
                        )
                    );
                })
        }

    }
}

actions.loadEquipmentType = (link) => {
    return (dispatch, getState) => {
        const { carrierDocumentNumber } = getState().collectionOrderState.currentRecord;
        api.post(`${BASE_PATH}/findEquipmentType`, { link, carrierDocumentNumber })
            .then((result) => {
                dispatch(actions.setEquipmentType(result.data));
            })
            .catch((error) => {
                console.log(error)
            });
    }
}

actions.loadHoursDocks = (link) => {
    return (dispatch, getState) => {

        api.post(`${BASE_PATH}/loadHoursDocks`, { link })
            .then((result) => {
                dispatch(actions.setHoursDocks(result.data));
            })
            .catch((error) => {
                console.log(error)
            });
    }
}

export { actions, types };
