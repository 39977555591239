
    import React from 'react';
    import CarrierFormPageContainer from './CarrierFormPageContainer';
    
    export class CarrierFormPage extends React.Component{
        render(){
            return (
                <CarrierFormPageContainer {...this.props}/>
            )
        }
    }
    