import React from 'react';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { PickList } from 'primereact/picklist';
import { InputCep } from '../../../components/input-cep/InputCep';
import { InputCnpjCpf } from '../../../components/input-cnpj-cpf/InputCnpjCpf';
import { InputPhone } from '../../../components/input-phone/InputPhone';
import { LookupField } from '../../../components/lookup/LookupField';
export class UsuarioForm extends React.Component {
    render() {
        const { props } = this;
        const record = props.record;
        const changeHandler = props.changeHandler; 

        const recordGroups = record.groups || [] 
        return (
            <div className="pessoa-form">
                <div className="p-grid">
                    <div className="p-col-6">
                        <div className="p-grid">
                            <div className="p-col-3">
                                <div className="form-field-container">
                                    <label>Código</label>
                                    <InputText value={record && record.oid ? record.oid : ""} onChange={changeHandler} disabled={true} />
                                </div>

                            </div>
                            <div className="p-col-12 form-field-container">
                                <label className="lxRequiredField">Nome</label>
                                <InputText value={record && record.name ? record.name : ""} onChange={changeHandler} name="name" />
                            </div> 

                            <div className="p-col-6 form-field-container">
                                <label className="lxRequiredField">CPF/CNPJ</label>
                                <InputCnpjCpf value={record && record.documentNumber ? record.documentNumber : ""} onChange={changeHandler} name="documentNumber" />
                            </div>
                            
                            <div className="p-col-6">
                                <div className="form-field-container">
                                    <label className='lxRequiredField'>Email</label>
                                    <InputText type="email" value={record && record.email ? record.email : ""} onChange={changeHandler} name="email" autoComplete="off" />
                                </div>
                            </div>
                            <div className="p-col-6">
                                <div className="form-field-container">
                                    <label>Telefone</label>
                                    <InputPhone value={record && record.phone ? record.phone : ""} onChange={changeHandler} name="phone" />
                                </div>
                            </div>
                            <div className="p-col-6">
                                <div className="form-field-container">
                                    <label>Cargo</label>
                                    <InputText value={record && record.role ? record.role : ""} onChange={changeHandler} name="role" />
                                </div>
                            </div>
                            <div className="p-col-6">
                                <div className="form-field-container">
                                    <label className="lxRequiredField">Login</label>
                                    <input type="text" value={record && record.login ? record.login : ""} onChange={changeHandler} name="login" autoComplete="off" style={{ height: "32px", }} />
                                </div>
                            </div>
                            <div className="p-col-6">
                                <div className="form-field-container">
                                    <label className="lxRequiredField">Senha</label>
                                    <Password type="text" value={record && record.password ? record.password : ""} onChange={changeHandler} name="password" autoComplete="off" />
                                </div>
                            </div>

                            {props.permitCadastroUsuarioTransportadora &&

                                <div className="p-col-6 form-field-container">
                                    <label>Transportadora</label>
                                    <LookupField
                                        value={record.carrier ? record.carrier.name : ""}
                                        placeholder="Transportadora"
                                        name="carrier.name"
                                        field="name"
                                        onComplete={props.lookupCarrierOnComplete}
                                        onInputFilter={props.lookupCarrierOnInputFilter}
                                        editDisabled={props.lookupCarrier.editDisabled}
                                        onEditField={props.lookupCarrierOnEdit}
                                        onClick={props.lookupCarrierOnClick}

                                        visible={props.lookupCarrier.visible}
                                        modal={props.lookupCarrier.modal}
                                        header={props.lookupCarrier.header}
                                        onHide={props.lookupCarrierOnHide}
                                        onConfirm={props.lookupCarrierOnConfirm}
                                        onCancel={props.lookupCarrierOnCancel}
                                        records={props.lookupCarrier.records}
                                        columns={props.lookupCarrier.columns}
                                        totalRecords={props.lookupCarrier.totalRecords}
                                        sortOrder={props.lookupCarrier.order}
                                        sortField={props.lookupCarrier.sortField}
                                        first={props.lookupCarrier.first}
                                        rows={props.lookupCarrier.rows}
                                        onPage={props.lookupCarrierOnPage}
                                        onFilter={props.lookupCarrierOnFilter}
                                        onSort={props.lookupCarrierOnOnSort}
                                        paginator={true}
                                        rowsPerPageOptions={[5, 10, 20,50]}
                                    />
                                </div>
                            }


                            <div className="p-col-12 form-field-container">
                                <label className="lxRequiredField">Perfís</label>
                                <PickList
                                    source={props.groups || []}
                                    target={recordGroups}
                                    onMoveToSource={props.pickListPerfisMoveToSource}
                                    onMoveAllToSource={props.pickListPerfisMoveAllToSource}
                                    onMoveToTarget={props.pickListPerfisMoveToTarget}
                                    onMoveAllToTarget={props.pickListPerfisMoveAllToTarget}
                                    itemTemplate={(group) => (<label>{group.name}</label>)}
                                    showSourceControls={false}
                                    showTargetControls={false}
                                />

                            </div>

                            <div className="p-col-12 form-field-container">
                                <label>Endereço</label>
                                <InputText value={record.address && record.address.street ? record.address.street : ""} onChange={changeHandler} name="address.street" />
                            </div>
                            <div className="p-col-12 form-field-container">
                                <label>Complemento</label>
                                <InputText value={record.address && record.address.complement ? record.address.complement : ""} onChange={changeHandler} name="address.complement" />
                            </div>
                            <div className="p-col-4 form-field-container">
                                <label>Número</label>
                                <InputText value={record.address && record.address.number ? record.address.number : ""} onChange={changeHandler} name="address.number" />
                            </div>
                            <div className="p-col-4 form-field-container">
                                <label>Bairro</label>
                                <InputText value={record.address && record.address.district ? record.address.district : ""} onChange={changeHandler} name="address.district" />
                            </div>
                            <div className="p-col-4 form-field-container">
                                <label>CEP</label>
                                <InputCep value={record.address && record.address.zipCode ? record.address.zipCode : ""} onChange={changeHandler} name="address.zipCode" disabled={false} />
                            </div>
                            <div className="p-col-12 form-field-container">
                                <label>Cidade</label>
                                <InputText value={record.address && record.address.city ? record.address.city : ""} onChange={changeHandler} name="address.city" />
                            </div>

                        </div>
                    </div>
                </div>
            </div>


        )
    }
};