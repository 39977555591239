import { types } from './app.actions';

const initialState = {
    currentUser: null,
    currentUserOid: null,
    currentUserCarrier: null,
    currentCompany: null,
    currentUnit: null,
    companies: [],
    units: [],
    userConfigurations: {
        indicators: {
            efficiency: false,
            divergence: false,
            freightValueLocation: false,
            percentFreightOnBill: false,
            relationWeightByFreight: false,
        },
        towerShowTotalizers: {
            total: true,
            andamento: true,
            atraso: true,
            tendencia_atraso: true,
            on_time: true,
            notmirrored: true,
            finalizados: false,
            canceladas: false,
        }
    },
    messageDialog: "",
    dialogVisible: false
}

export function appReducer(state = initialState, action) {
    let result = state;
    switch (action.type) {
        case types.APP_SET_COMPANIES:
            return setCompanies(state, action);
        case types.APP_SET_UNITS:
            return setUnits(state, action);
        case types.APP_SET_CURRENT_COMPANY:
            return setCurrentCompany(state, action);
        case types.APP_SET_CURRENT_UNIT:
            return setCurrentUnit(state, action);
        case types.APP_SET_CURRENT_USER:
            return setCurrentUser(state, action);
        case types.APP_SET_USER_CONFIGURATIONS:
            return setUserConfigurations(state, action);
        case types.APP_SET_INDICATOR:
            return setIndicator(state, action);
        case types.APP_SET_INDICATORS:
            return setIndicators(state, action);
        case types.APP_SET_FAVORITES:
            return setFavorites(state, action);
        case types.SET_TOWER_SHOW_TOTALIZERS:
            return setTowerShowTotalizers(state, action);
        case types.SHOW_MESSAGE_DIALOG:
            return showMessageDialog(state, action);
        default:
            return result;
    }
}

function showMessageDialog(state, action) {
    let messageDialog = action.message;
    let dialogVisible = action.visible;
    return { ...state, messageDialog, dialogVisible };
}

function setTowerShowTotalizers(state, action) {
    let userConfigurations = state.userConfigurations || {};
    const towerShowTotalizers = action.towerShowTotalizers;
    userConfigurations = { ...userConfigurations, towerShowTotalizers }
    return { ...state, userConfigurations };
}

function setIndicator(state, action) {
    let userConfigurations = state.userConfigurations || {};
    let indicators = userConfigurations.indicators || {};
    let { field, value } = action;
    indicators = { ...indicators, [field]: value };
    userConfigurations = { ...userConfigurations, indicators }
    return { ...state, userConfigurations };
}
function setFavorites(state, action) {
    let userConfigurations = state.userConfigurations || {};
    const favorites = action.favorites || [];
    userConfigurations = { ...userConfigurations, favorites }
    return { ...state, userConfigurations };
}

function setIndicators(state, action) {
    let userConfigurations = state.userConfigurations || {};
    const indicators = { ...action.indicators };
    userConfigurations = { ...userConfigurations, indicators }
    return { ...state, userConfigurations };
}

function setCompanies(state, action) {
    let { companies = [] } = action;
    return Object.assign({}, state, { companies });
}

function setUnits(state, action) {
    let { units = [] } = action;
    // console.log("setUnits units=",units)
    return Object.assign({}, state, { units });
}

function setCurrentCompany(state, action) {
    let { company } = action;
    // console.log("setCurrentCompany company=",company)
    return Object.assign({}, state, { currentCompany: company });
}

function setCurrentUnit(state, action) {
    let { unit } = action;
    return Object.assign({}, state, { currentUnit: unit });
}

function setCurrentUser(state, action) {
    let { user, oid, carrier } = action;
    return Object.assign({}, state, { currentUser: user, currentUserOid: oid, currentUserCarrier: carrier });
}

function setUserConfigurations(state, action) {
    let { userConfigurations } = action;
    return Object.assign({}, state, { userConfigurations });
}