
    import React from 'react';
    import ComponentRangeTypeListPageContainer from './ComponentRangeTypeListPageContainer';
    
    export class ComponentRangeTypeListPage extends React.Component{
        
        render(){
          return (
            <ComponentRangeTypeListPageContainer {...this.props}/>
          )
        }
    }