import React from 'react';
import * as IconFactory from '../../services/icons.factory'
import './SocialMediaBar.scss'
export const SocialMediaBar = (props) => {
    const instagramIcon = IconFactory.getIcon('instagram', false)
    const instagramImg = instagramIcon === '' ? '' :
        <a href="https://www.instagram.com/lognex.tech/" target="_blank">
            <img src={instagramIcon} className='lxSocialMediaBar__instagram' alt="Instagram" title="Visitar o Instagram da Lognex" />
        </a>

    const facebookIcon = IconFactory.getIcon('facebook', false)
    const facebookImg = instagramIcon === '' ? '' :
        <a href="https://www.facebook.com/Lognex.tech/" target="_blank">
            <img src={facebookIcon} className='lxSocialMediaBar__facebook' alt="Facebook" title="Visitar o Facebook da Lognex" />
        </a>

    const linkedinIcon = IconFactory.getIcon('linkedin', false)
    const linkedinImg = instagramIcon === '' ? '' :
        <a href="https://www.linkedin.com/company/lognextech/" target="_blank">
            <img src={linkedinIcon} className='lxSocialMediaBar__linkedin' alt="Linkedin" title="Visitar o Linkedin da Lognex" />
        </a>
    return (
        <div className="lxSocialMediaBar">
            {instagramImg}
            {facebookImg}
            {linkedinImg}
        </div>)

}