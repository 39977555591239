import React from "react";
import { connect } from "react-redux";
import { Page } from "../../../components/page/Page";
import { ImportFilesForm } from './ImportFilesForm';
import { actions } from '../importfiles.actions';
import storage from '../../../services/stoage.service'
import { Column } from 'primereact/column';
import { messageActions, makeMessages } from '../../../store/message/';
import { AppService, COMPANY_CHANGE_EVENT_NAME, BUSINESS_UNIT_CHANGE_EVENT_NAME } from "../../../services/app.service";
import { actions as appActions } from '../../../store/app';
import * as menuActions from '../../../store/menu/menu.actions';
import * as utils from '../../../utils'

import moment from 'moment';

const mapStateToProps = state => {
  let fileTypes = state.crudImportFilesState.currentRecord.fileTypes;
  fileTypes = [{ label: 'Selecione uma opção', value: null }, ...fileTypes.map(type => {
    switch (type) {
      case "NFE":
        return { value: "NFE", label: "NFe" };
      case "CTE":
        return { value: "CTE", label: "CTe" };
      case "EventoCTE":
        return { value: "EventoCTE", label: "Eventos CTE" };
      case "EDIDocCob":
        return { value: "EDIDocCob", label: "EDI DOCCOB" };
      case "EDIOcoRen":
        return { value: "EDIOcoRen", label: "EDI OCOREN" };
      default:
        return type;
    }
  })];
  const currentMenu = state.menuState.currentRecord;
  const userConfigurations = state.appState.userConfigurations;
  let isFavorite = utils.isFavorite(currentMenu, userConfigurations);
  return {
    fileTypes,
    files: state.crudImportFilesState.currentRecord.files,
    currentFileType: state.crudImportFilesState.currentRecord.currentFileType,
    columns: state.crudImportFilesState.columns,
    records: state.crudImportFilesState.records,
    dialogLogRecord: state.crudImportFilesState.dialogLogRecord,
    currentLogRecord: state.crudImportFilesState.currentLogRecord,
    first: state.crudImportFilesState.index,
    rows: state.crudImportFilesState.max,
    totalRecords: state.crudImportFilesState.count,
    sortField: state.crudImportFilesState.sortField,
    order: state.crudImportFilesState.sortOrder,
    filters: state.crudImportFilesState.filters,
    importFilesVisible: state.crudImportFilesState.currentRecord.importFilesVisible,
    menuItems: state.menuState.items,
    currentMenu,
    isFavorite,
    logFilter: state.crudImportFilesState.logFilter,
    detailColumns: state.crudImportFilesState.detailColumns,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    changeHandler: event => {
      const target = event.target;
      const field = target.name;
      let value = target.value;

      dispatch(actions.removeErrors([{ field }]));
      dispatch(actions.setField(field, value))
    },
    paginate: paginationParams => dispatch(actions.paginate(paginationParams)),
    sortOrder: sortParams => dispatch(actions.sortOrder(sortParams)),
    applyFilter: filterParams => {
      dispatch(actions.setType(null));
      dispatch(actions.applyFilter(filterParams))
    },
    applyBaseFilters: filters => {
      dispatch(actions.applyBaseFilters(filters))
    },
    load: () => dispatch(actions.load()),
    setDialogLogRecordVisible: visible => dispatch(actions.setDialogLogRecordVisible(visible)),
    setCurrentLogRecord: logRecord => dispatch(actions.setCurrentLogRecord(logRecord)),
    setField: (field, value) => dispatch(actions.setField(field, value)),
    showMessage: (message, type) => {
      dispatch(messageActions.messageClearMessages());
      dispatch(
        messageActions.messageShowMessages(
          makeMessages([message], type)
        )
      );
    },
    userLastAccess: (oidMenu) => dispatch(appActions.userLastAccess(oidMenu)),
    setCurrentMenu: (menuPath) => dispatch(menuActions.setCurrentRecord(menuPath)),
    toggleFavorite: (isFavorite, oidMenu) => {
      dispatch(appActions.toggleFavorite(isFavorite, oidMenu))
    },
    initialDateFilterChangeHandler: (e) => { dispatch(actions.setFieldLogFilter("initialDateFilter", e.value)) },
    finalDateFilterChangeHandler: (e) => { dispatch(actions.setFieldLogFilter("finalDateFilter", e.value)) },
    applyFilterLog: ({ fileTypeFilter, initialDateFilter, finalDateFilter }) => {
      let fileType = ""
      if (fileTypeFilter) {
        switch (fileTypeFilter.toUpperCase()) {
          case "CTE":
            fileType = "FILE_CTE"
            break;
          case "NFE":
            fileType = "FILE_NFE"
            break;
          case "EVENTOCTE":
            fileType = "FILE_EVENTOCTE"
            break;
          case "EDIDOCCOB":
            fileType = "FILE_EDIDOCCOB"
            break;
          case "EDIOCOREN":
            fileType = "FILE_EDIOCOREN"
            break;
          default:
            break;
        }
      }
      const filters = []
      if (fileType) {
        filters.push({
          campo: "type",
          valor: fileType,
          tipoFiltro: "EQUALS"
        })
      }

      if (initialDateFilter && finalDateFilter) {
        filters.push({
          campo: "createdAt",
          campoComplementar: "createdAt",
          valorInicial: initialDateFilter,
          valorFinal: finalDateFilter,
          tipoFiltro: "ENTRE"
        })
      }

      dispatch(actions.applyBaseFilters(filters, false))
      dispatch(actions.load())
    }
  };
};

class ImportFilesFormPageContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      currentCompany: null,
      currentBusinessUnit: null
    }
  }

  componentWillReceiveProps(newProps) {
    if ((!this.props.menuItems || this.props.menuItems.length === 0) && newProps.menuItems && newProps.menuItems.length > 0) {
      const menuPath = this.props.history.location.pathname;
      this.props.setCurrentMenu(menuPath)
    }

    if ((!this.props.currentMenu && newProps.currentMenu) ||
      (this.props.currentMenu !== newProps.currentMenu)) {
      const oidMenu = newProps.currentMenu.oid;
      this.props.userLastAccess(oidMenu)
    }
  }

  componentDidMount() {
    AppService.on(COMPANY_CHANGE_EVENT_NAME, this);
    AppService.on(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
    const menuPath = this.props.history.location.pathname;
    this.props.setCurrentMenu(menuPath)
  }
  componentWillUnmount() {
    AppService.off(COMPANY_CHANGE_EVENT_NAME, this);
    AppService.off(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
  }

  onReceiveAppNotifications = (eventName, params) => {
    if (eventName === COMPANY_CHANGE_EVENT_NAME || eventName === BUSINESS_UNIT_CHANGE_EVENT_NAME) {
      if (eventName === COMPANY_CHANGE_EVENT_NAME) {
        this.setState({ currentCompany: params && params.length > 0 ? params[0] : null });
      } else if (eventName === BUSINESS_UNIT_CHANGE_EVENT_NAME) {
        this.setState({ currentBusinessUnit: params && params.length > 0 ? params[0] : null });
      }
      this.applyFilterLog()
    }
  }

  load = () => {
    this.props.load();
  }
  onPage = (e) => {
    this.props.paginate({
      max: e.rows,
      index: e.first
    })
  }

  onSort = (e) => {
    this.props.sortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField
    })
  }

  onFilter = (e) => {
    if (this.props.currentFileType) {
      const filters = [];
      const keys = Object.keys(e.filters) || [];
      keys.forEach(key => {
        filters.push({
          campo: key,
          valor: e.filters[key].value,
          tipoFiltro: 'CONTEM'

        })
      })
      this.props.applyFilter(filters)
    }
  }
  //This kind of function must be created using the action and reducer pattern,  
  //but this is a exception due to the fact we are using third part components
  importFiles = (event) => {
    let token = storage.getToken()
    let company = this.state.currentCompany || storage.getCompany();
    let businessUnit = this.state.currentBusinessUnit;
    let user = storage.getUser()
    let appid = storage.getResponseID() || "-1"

    event.xhr.setRequestHeader('Authorization', `Bearer ${token}`);
    event.xhr.setRequestHeader('companyid', company.CNPJ);

    //TODO create methods get business unit and logistic operator
    event.xhr.setRequestHeader('unitid', businessUnit ? businessUnit.CNPJ : "-1");
    event.xhr.setRequestHeader('logistic_operator', -1);
    event.xhr.setRequestHeader('user', user.login);
    const currentFileType = this.props.currentFileType
    event.xhr.setRequestHeader('currentFileType', currentFileType)
    event.xhr.setRequestHeader('appid', appid)

  }

  onUploadSucess = (event) => {
    this.props.showMessage("Arquivos recebidos. Verificar situaçao da importação.", "success")
    this.props.load();
  }

  onUploadError = (event) => {
    this.props.showMessage("Não foi possível enviar os arquivos para processamento. Contate o administrador do sistema.", "error")
    this.props.load();
  }
  onClickLogRow = (e) => {
    this.props.setCurrentLogRecord(e.data);
    this.props.setDialogLogRecordVisible(true);
  }

  onHideDialogLogRecord = (e) => {
    this.props.setDialogLogRecordVisible(false);
  }

  toggleImportFiles = () => {
    const props = this.props;
    const visible = props.importFilesVisible ? false : true
    this.props.setField('importFilesVisible', visible)
  }

  onFavorite = (e) => {
    if (this.props.currentMenu) {
      const oidMenu = this.props.currentMenu.oid
      const isFavorite = this.props.isFavorite;
      this.props.toggleFavorite(isFavorite, oidMenu)
    }
  }

  applyFilterLog = () => {
    this.props.applyFilterLog({
      fileTypeFilter: this.props.logFilter.currentFileType,
      initialDateFilter: this.props.logFilter.initialDateFilter,
      finalDateFilter: this.props.logFilter.finalDateFilter,
    })
  }

  onBlurInitialDateFilter = (e) => {
    if (typeof this.props.logFilter.initialDateFilter === 'object') {
      this.props.applyFilterLog({
        fileTypeFilter: this.props.logFilter.currentFileType,
        initialDateFilter: this.props.logFilter.initialDateFilter,
        finalDateFilter: this.props.logFilter.finalDateFilter,
      })
    }
  }

  onBlurFinalDateFilter = (e) => {
    if (typeof this.props.logFilter.finalDateFilter === 'object') {
      this.props.applyFilterLog({
        fileTypeFilter: this.props.logFilter.currentFileType,
        initialDateFilter: this.props.logFilter.initialDateFilter,
        finalDateFilter: this.props.logFilter.finalDateFilter,
      })
    }
  }

  onChangeFileType = (e) => {
    this.props.changeHandler(e)
    this.props.applyFilterLog({
      fileTypeFilter: e.target.value,
      initialDateFilter: this.props.logFilter.initialDateFilter,
      finalDateFilter: this.props.logFilter.finalDateFilter,
    })
  }
  render() {
    const isFavorite = this.props.isFavorite;
    const title = `Importação de arquivos`
    const header = (
      <div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h1>{title}</h1>
          <i className={`pi pi-star lxFavoriteStar ${isFavorite ? 'lxFavorite' : ''}`}
            title={isFavorite ? 'Remover dos favoritos' : 'Adicionar aos favoritos'} onClick={this.onFavorite}></i>

        </div>
        <hr className="lxSplitter" ></hr>
      </div>
    );

    const centeredTemplate = (rowData, column) => {
      return <div style={{ textAlign: 'center', color: "#333333" }}>{rowData[column.field]}</div>
    }
    const dateTemplate = (rowData, column) => {
      const date = rowData[column.field];
      const formattedDate = moment(date).format('DD/MM/YYYY hh:mm')
      return <div style={{ textAlign: 'center', color: "#333333" }}>{formattedDate}</div>
    }

    const columns = this.props.columns.map(col => {
      if (col.columnKey === 'createdAt') {
        return (<Column {...col} key={col.field} style={{ width: '15%' }} body={dateTemplate} />)
      } else if (col.columnKey === 'type') {
        return (<Column {...col} key={col.field} style={{ width: '15%' }} body={centeredTemplate} />)
      } else if (col.columnKey === 'fileName') {
        return (<Column {...col} key={col.field} />)

      } else {
        return (<Column {...col} key={col.field} />)
      }
    })

    const dateFilterFunction = (value, filter) =>{
      const formattedDate = moment(value).format('DD/MM/YYYY hh:mm')
      return formattedDate.includes(filter)
      
    }
    const detailColumns = this.props.detailColumns.map(col => {
      if (col.columnKey === 'createdAt') {
        return (<Column filterMatchMode="custom" filterFunction={dateFilterFunction} {...col} key={col.field} style={{ width: '25%' }} body={dateTemplate} />)
      } else {
        return (<Column {...col} key={col.field} />)
      }
    })

    const content = (<ImportFilesForm
      {...this.props}
      onBeforeSend={this.importFiles}
      onUploadSucess={this.onUploadSucess}
      onUploadError={this.onUploadError}
      onPage={this.onPage}
      onSort={this.onSort}
      onFilter={this.onFilter}
      columns={columns}
      detailColumns={detailColumns}
      onClickLogRow={this.onClickLogRow}
      toggleImportFiles={this.toggleImportFiles}
      onHideDialogLogRecord={this.onHideDialogLogRecord}
      onChangeFileType={this.onChangeFileType}
      onBlurInitialDateFilter={this.onBlurInitialDateFilter}
      onBlurFinalDateFilter={this.onBlurFinalDateFilter}
    />);
    return (<Page header={header} content={content} />);
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(ImportFilesFormPageContainer);
