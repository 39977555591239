import React from "react";
import { DataTable } from "primereact/datatable";

export const OrdensNotScriptedList = (props) => (
  <DataTable
    selectionMode="multiple"
    onRowClick={props.rowClick}
    value={props.records}
    // scrollHeight="300px"
    onSelectionChange={props.onSelectedEventsChange}
    selection={props.selectedRows}
    // scrollable={true}
  >
    {props.columns}
  </DataTable>
);
