import { Reducer } from "../../services/reducer.factory";
import { types } from "./enchentesRS.actions";

const initialState = {
  columns: [
    {
      field: "oid",
      header: "ID",
      columnKey: "oid",
      sortable: true,
      filter: true
    },
    {
      field: "highwayName",
      header: "Nome da Rodovia",
      columnKey: "highwayName",
      sortable: true,
      filter: false
    },
    {
      field: "latlong",
      header: "Coordenadas",
      columnKey: "latlong",
      sortable: true,
      filter: true
    },
    {
      field: "description",
      header: "Informações",
      columnKey: "description",
      sortable: true,
      filter: true
    },
    {
      field: "situation",
      header: "Situação",
      columnKey: "situation",
      sortable: true,
      filter: true
    },
    {
      field: "currentBlockType",
      header: "Tipo de bloqueio",
      columnKey: "currentBlockType",
      sortable: true,
      filter: true
    },
    // {
    //   field: "currentVehicleTypeReleased",
    //   header: "Veículos permitidos",
    //   columnKey: "currentVehicleTypeReleased",
    //   sortable: true,
    //   filter: true
    // },
    {
      field: "dateBlock",
      header: "Data",
      columnKey: "dateBlock",
      sortable: true,
      filter: true
    },
  ],
  currentRecord: {
    oid: null,
    highwayName: "",
    dateBlock: null,
    timeReleaase: null,
    latlong: "",
    description: "",
    weightVehicle: "",
    blockPartial: false,
    blockTotal: false,
    truckNotSelect: true,
    vehicleTypeReleased: [
      { label: 'Veículo leve', value: "vehicleTypeLight" },
      { label: 'Veículo oficial', value: 'vehicleTypeOfficial' },
      { label: 'Veículo de emergência', value: 'vehicleTypeEmergency' },
      { label: 'Van', value: 'van' },
      { label: 'Caminhões', value: 'vehicleTypeTruck' },
    ],
    currentVehicleTypeReleased: "",
    disableCurrentVehicleTypeReleasedList: true,
    disasterType: [
      { label: 'Deslizamento', value: "Deslizamento" },
      { label: 'Ponte destruída', value: 'Ponte destruída' },
      { label: 'Alagamento', value: 'Alagamento' },
      { label: 'Rachadura na pista', value: 'Rachadura na pista' },
      { label: 'Fenômeno da natureza indefinido', value: 'Fenômeno da natureza indefinido' },
      { label: 'Interditado', value: 'Interditado' },
      { label: 'Atolamento', value: 'Atolamento' },
      { label: 'Desvio de pista', value: 'Desvio de pista' },
      { label: 'Não informado', value: 'Não informado' },
    ],
    currentBlockType: "",

  },

  // records: []
};

const reducer = new Reducer(initialState, types);

export function CRUDEnchentesRS(state, action) {
  state = reducer.execute(state, action);
  return execute(state, action);
}

function execute(state, action) {
  let result = state;
  switch (action.type) {
    case types.ADD_VEHICLE_TYPE_RELEASE:
      return addVehicleTypeReleased(state, action)
    case types.CHANGE_BLOCK_PARTIAL_HANDLE:
      return changeBlockPartial(state, action)
    case types.CHANGE_BLOCK_TOTAL_HANDLE:
      return changeBlockTotalHandle(state, action)
    case types.CHANGE_BLOCK_TYPE:
      return changeBlockType(state, action)
    default:
      return result;
  }
}

function addVehicleTypeReleased(state, action) {
  let currentRecord = state.currentRecord
  currentRecord.currentVehicleTypeReleased = action.vehicleType;
  if (currentRecord.currentVehicleTypeReleased != null) {
    for (let i = 0; i < currentRecord.currentVehicleTypeReleased.length; i++) {
      if (currentRecord.currentVehicleTypeReleased[i] === "vehicleTypeTruck") {
        currentRecord.truckNotSelect = false
      } 
    }
  }
  state = { ...state, currentRecord };
  return state;
}
function changeBlockPartial(state, action) {
  let currentRecord = state.currentRecord
  currentRecord.blockPartial = action.blockPartial
  if (action.blockPartial) {
    // currentRecord.disableCurrentVehicleTypeReleasedList = false;
    currentRecord.blockTotal = false;
  } 
  state = { ...state, currentRecord }
  return state
}
function changeBlockTotalHandle(state, action) {
  let currentRecord = state.currentRecord
  currentRecord.blockTotal = action.blockTotal
  if (action.blockTotal) {
    currentRecord.currentVehicleTypeReleased = ""
    currentRecord.blockPartial = false
  }
  state = { ...state, currentRecord }
  return state
}
function changeBlockType(state, action) {
  let currentRecord = state.currentRecord
  currentRecord.currentBlockType = action.blockType
  state = { ...state, currentRecord }
  return state
}





