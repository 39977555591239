import React from 'react';
import { InputText } from 'primereact/inputtext';
import { LookupField } from '../../../components/lookup/LookupField';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputSwitch } from 'primereact/inputswitch';
import { TagList } from '../../../components/tag/TagList';
import { InputWithSelect } from '../../../components/input-with-select/InputWithSelect';

export const DeliveryForm = (props) => {
    return (
        <div className="DeliveryDeadline-form">
            <div className="p-grid">
                <div className="p-col-6">
                    <div className="p-grid">
                        <div className="p-col-4 form-field-container">
                            <label>Código</label>
                            <InputText value={props.record.oid} onChange={props.changeHandler} name="oid" disabled={true} />
                        </div>
                        <div className="p-col-12 form-field-container">
                            <label className="lxRequiredField">Transportadora</label>
                            <LookupField
                                value={props.lookupCarrier && props.lookupCarrier.record ? props.lookupCarrier.record.carrier : null}
                                placeholder="Transportadora"
                                name="record.carrier"
                                field="name"
                                onComplete={props.lookupCarrierOnComplete}
                                onInputFilter={props.lookupCarrierOnInputFilter}
                                editDisabled={props.lookupCarrier.editDisabled}
                                onEditField={props.lookupCarrierOnEdit}
                                onClick={props.lookupCarrierOnClick}

                                visible={props.lookupCarrier.visible}
                                modal={props.lookupCarrier.modal}
                                header={props.lookupCarrier.header}
                                onHide={props.lookupCarrierOnHide}
                                onConfirm={props.lookupCarrierOnConfirm}
                                onCancel={props.lookupCarrierOnCancel}
                                records={props.lookupCarrier.records}
                                columns={props.lookupCarrier.columns}
                                totalRecords={props.lookupCarrier.totalRecords}
                                sortOrder={props.lookupCarrier.order}
                                sortField={props.lookupCarrier.sortField}
                                first={props.lookupCarrier.first}
                                rows={props.lookupCarrier.rows}
                                onPage={props.lookupCarrierOnPage}
                                onFilter={props.lookupCarrierOnFilter}
                                onSort={props.lookupCarrierOnOnSort}
                            />
                        </div>
                        <div className="p-col-12 form-field-container">
                            <label className="lxRequiredField">Nome</label>
                            <InputText value={props.record.name} onChange={props.changeHandler} name="name" disabled={false} />
                        </div>
                        <div className="p-col-12 form-field-container">
                            <label>Descrição</label>
                            <InputTextarea rows={5} cols={30} value={props.record.description} onChange={props.changeHandler} name="description" />
                        </div>
                        <div className="p-col-12 form-field-container">
                            <label>Rota</label>
                            <InputText value={props.record.route} onChange={props.changeHandler} name="route" />
                        </div>
                        <div className="p-col-6 form-field-container">
                            <label className="lxRequiredField">Origem</label>
                            <LookupField
                                value={props.lookupOrigin && props.lookupOrigin.record ? props.lookupOrigin.record.origin : null}
                                placeholder="Origem"
                                name="originName"
                                field="description"
                                onComplete={props.lookupOriginOnComplete}
                                onInputFilter={props.lookupOriginOnInputFilter}
                                editDisabled={props.lookupOrigin.editDisabled}
                                onEditField={props.lookupOriginOnEdit}
                                onClick={props.lookupOriginOnClick}

                                visible={props.lookupOrigin.visible}
                                modal={props.lookupOrigin.modal}
                                header={props.lookupOrigin.header}
                                onHide={props.lookupOriginOnHide}
                                onConfirm={props.lookupOriginOnConfirm}
                                onCancel={props.lookupOriginOnCancel}
                                records={props.lookupOrigin.records}
                                columns={props.lookupOrigin.columns}
                                totalRecords={props.lookupOrigin.totalRecords}
                                sortOrder={props.lookupOrigin.order}
                                sortField={props.lookupOrigin.sortField}
                                first={props.lookupOrigin.first}
                                rows={props.lookupOrigin.rows}
                                onPage={props.lookupOriginOnPage}
                                onFilter={props.lookupOriginOnFilter}
                                onSort={props.lookupOriginOnOnSort}
                            />
                        </div>
                        <div className="p-col-6 form-field-container">
                            <label className="lxRequiredField">Destino</label>
                            <LookupField
                                value={props.lookupDestination && props.lookupDestination.record ? props.lookupDestination.record.destination : null}
                                placeholder="Destino"
                                name="destinationName"
                                field="description"
                                onComplete={props.lookupDestinationOnComplete}
                                onInputFilter={props.lookupDestinationOnInputFilter}
                                editDisabled={props.lookupDestination.editDisabled}
                                onEditField={props.lookupDestinationOnEdit}
                                onClick={props.lookupDestinationOnClick}

                                visible={props.lookupDestination.visible}
                                modal={props.lookupDestination.modal}
                                header={props.lookupDestination.header}
                                onHide={props.lookupDestinationOnHide}
                                onConfirm={props.lookupDestinationOnConfirm}
                                onCancel={props.lookupDestinationOnCancel}
                                records={props.lookupDestination.records}
                                columns={props.lookupDestination.columns}
                                totalRecords={props.lookupDestination.totalRecords}
                                sortOrder={props.lookupDestination.order}
                                sortField={props.lookupDestination.sortField}
                                first={props.lookupDestination.first}
                                rows={props.lookupDestination.rows}
                                onPage={props.lookupDestinationOnPage}
                                onFilter={props.lookupDestinationOnFilter}
                                onSort={props.lookupDestinationOnOnSort}
                            />
                        </div>
                        <div className="p-col-6 form-field-container">
                            <label className="lxRequiredField">Prazo de entrega</label>
                            <InputWithSelect
                                field="deadline"
                                input={
                                    {
                                        name: "deadline.value",
                                        value: props.record.deadline.value,
                                        onChange: props.changeHandler,
                                        type: "number"
                                    }
                                }
                                select={{
                                    options: props.options,
                                    name: "deadline.type",
                                    value: props.record.deadline.type,
                                    onChange: props.changeHandler
                                }} />
                        </div>
                        <div className="p-col-6 form-field-container">
                            <label className="lxRequiredField">Prazo de despacho após coleta</label>
                            <InputWithSelect
                                field="delayAfterCollect"
                                input={
                                    {
                                        name: "delayAfterCollect.value",
                                        value: props.record.delayAfterCollect.value,
                                        onChange: props.changeHandler,
                                        type: "number"
                                    }
                                }
                                select={
                                    {
                                        options: props.options,
                                        name: "delayAfterCollect.type",
                                        value: props.record.delayAfterCollect.type,
                                        onChange: props.changeHandler,
                                    }
                                } />
                        </div>
                        {/* <div className="p-col-12 form-field-container">
                            <label>Dias úteis</label>
                            <InputSwitch checked={props.record.onlyWorkingDays} name="onlyWorkingDays" onChange={props.changeHandler} />
                        </div> */}
                        <div className="p-col-6 form-field-container">
                            <label>Tags</label>
                            <InputText value={props.record.tag} onChange={props.changeHandler} onKeyPress={props.onKeyPress} name="tag" disabled={false} />
                        </div>
                        <div className="p-col-6 form-field-container">
                            <br />
                            <button className="lognex-btn-highlighted" onClick={props.buttonAddTagOnClick}>
                                <i className="pi pi-check"></i>
                            </button>
                        </div>
                        <div className="p-col-12 form-field-container">
                            <TagList tags={props.record.tags} onRemove={props.removeTagOnClick}></TagList>

                        </div>
                        <div className="p-col-6 form-field-container">
                            <label>Dias de Coleta</label>
                            <hr />
                            <div className="p-grid">
                                <div className="p-col-12">
                                    <div className="p-grid">
                                    </div>
                                </div>
                                <div className="p-col-12">
                                    <div className="p-grid">
                                        <div className="p-col-6">Segunda-feira</div>
                                        <div className="p-col-2 p-justify-center">
                                            <InputSwitch
                                                checked={props.record.weekDays.monday}
                                                onChange={props.changeHandler}
                                                name="weekDays.monday"
                                                id="monday"
                                            />
                                        </div>
                                    </div>
                                    <div className="p-grid">
                                        <div className="p-col-6">Terça-feira</div>
                                        <div className="p-col-2 p-justify-center">
                                            <InputSwitch
                                                checked={props.record.weekDays.tuesday}
                                                onChange={props.changeHandler}
                                                name="weekDays.tuesday"
                                                id="tuesday"
                                            />
                                        </div>
                                    </div>
                                    <div className="p-grid">
                                        <div className="p-col-6">Quarta-feira</div>
                                        <div className="p-col-2 p-justify-center">
                                            <InputSwitch
                                                checked={props.record.weekDays.wednesday}
                                                onChange={props.changeHandler}
                                                name="weekDays.wednesday"
                                                id="wednesday"
                                            />
                                        </div>
                                    </div>
                                    <div className="p-grid">
                                        <div className="p-col-6">Quinta-feira</div>
                                        <div className="p-col-2 p-justify-center">
                                            <InputSwitch
                                                checked={props.record.weekDays.thursday}
                                                onChange={props.changeHandler}
                                                name="weekDays.thursday"
                                                id="thursday"
                                            />
                                        </div>
                                    </div>
                                    <div className="p-grid">
                                        <div className="p-col-6">Sexta-feira</div>
                                        <div className="p-col-2 p-justify-center">
                                            <InputSwitch
                                                checked={props.record.weekDays.friday}
                                                onChange={props.changeHandler}
                                                name="weekDays.friday"
                                                id="friday"
                                            />
                                        </div>
                                    </div>
                                    <div className="p-grid">
                                        <div className="p-col-6">Sábado</div>
                                        <div className="p-col-2 p-justify-center">
                                            <InputSwitch
                                                checked={props.record.weekDays.saturday}
                                                onChange={props.changeHandler}
                                                name="weekDays.saturday"
                                                id="saturday"
                                            />
                                        </div>
                                    </div>
                                    <div className="p-grid">
                                        <div className="p-col-6">Domingo</div>
                                        <div className="p-col-2 p-justify-center">
                                            <InputSwitch
                                                checked={props.record.weekDays.sunday}
                                                onChange={props.changeHandler}
                                                name="weekDays.sunday"
                                                id="sunday"
                                            />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="p-col-6 form-field-container">
                            <label>Dias de Entrega</label>
                            <hr />
                            <div className="p-grid">
                                <div className="p-col-12">
                                    <div className="p-grid">
                                    </div>
                                </div>
                                <div className="p-col-12">
                                    <div className="p-grid">
                                        <div className="p-col-6">Segunda-feira</div>
                                        <div className="p-col-2 p-justify-center">
                                            <InputSwitch
                                                checked={props.record.deliveryDays.monday}
                                                onChange={props.changeHandler}
                                                name="deliveryDays.monday"
                                                id="monday"
                                            />
                                        </div>
                                    </div>
                                    <div className="p-grid">
                                        <div className="p-col-6">Terça-feira</div>
                                        <div className="p-col-2 p-justify-center">
                                            <InputSwitch
                                                checked={props.record.deliveryDays.tuesday}
                                                onChange={props.changeHandler}
                                                name="deliveryDays.tuesday"
                                                id="tuesday"
                                            />
                                        </div>
                                    </div>
                                    <div className="p-grid">
                                        <div className="p-col-6">Quarta-feira</div>
                                        <div className="p-col-2 p-justify-center">
                                            <InputSwitch
                                                checked={props.record.deliveryDays.wednesday}
                                                onChange={props.changeHandler}
                                                name="deliveryDays.wednesday"
                                                id="wednesday"
                                            />
                                        </div>
                                    </div>
                                    <div className="p-grid">
                                        <div className="p-col-6">Quinta-feira</div>
                                        <div className="p-col-2 p-justify-center">
                                            <InputSwitch
                                                checked={props.record.deliveryDays.thursday}
                                                onChange={props.changeHandler}
                                                name="deliveryDays.thursday"
                                                id="thursday"
                                            />
                                        </div>
                                    </div>
                                    <div className="p-grid">
                                        <div className="p-col-6">Sexta-feira</div>
                                        <div className="p-col-2 p-justify-center">
                                            <InputSwitch
                                                checked={props.record.deliveryDays.friday}
                                                onChange={props.changeHandler}
                                                name="deliveryDays.friday"
                                                id="friday"
                                            />
                                        </div>
                                    </div>
                                    <div className="p-grid">
                                        <div className="p-col-6">Sábado</div>
                                        <div className="p-col-2 p-justify-center">
                                            <InputSwitch
                                                checked={props.record.deliveryDays.saturday}
                                                onChange={props.changeHandler}
                                                name="deliveryDays.saturday"
                                                id="saturday"
                                            />
                                        </div>
                                    </div>
                                    <div className="p-grid">
                                        <div className="p-col-6">Domingo</div>
                                        <div className="p-col-2 p-justify-center">
                                            <InputSwitch
                                                checked={props.record.deliveryDays.sunday}
                                                onChange={props.changeHandler}
                                                name="deliveryDays.sunday"
                                                id="sunday"
                                            />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}