export function validate(record) {
  const errors = [];

  if (!record.equipment || !record.equipment.oid) {
    errors.push({
      field: "equipment",
      message: "O equipmaneto usado na viagem é obrigatório!",
    });
  }

  if (!record.carrier || !record.carrier.name) {
    errors.push({
      field: "carrier",
      message: "A tranportadora da viagem é obrigatório!",
    });
  } 
  const {vehicle}=record  
  if (!vehicle || !vehicle.plate) {
    errors.push({
      field: "vehicle",
      message: "O veículo da viagem é obrigatório!",
    });
  } 
  if (!record.origin || record.origin.length == 0) {
    errors.push({
      field: "origin",
      message: "A origem da viagem é obrigatório!",
    });
  }
  if (!record.destinations || record.destinations.length == 0) {
    errors.push({
      field: "destinations",
      message: "O destino da viagem é obrigatório!",
    });
  }
  if (!record.datetime_begin && ( !record.datetime_prev_begin || record.datetime_prev_begin.length == 0)) {
    errors.push({
      field: "datetime_prev_begin",
      message: "A data de previsão de inicio da viagem é obrigatório!",
    });
  }

  const result = {
    ok: errors.length === 0,
    errors,
    record,
  };

  return new Promise((resolve, reject) => {
    const action = result.ok ? resolve : reject;
    return action(result);
  });
}
