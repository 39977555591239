
    import React from 'react';
    import FreightComponentListPageContainer from './FreightComponentListPageContainer';
    
    export class FreightComponentListPage extends React.Component{
        
        render(){
          return (
            <FreightComponentListPageContainer {...this.props}/>
          )
        }
    }