import { Button } from "@material-ui/core";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import React from "react";
import "./dialog-custom-fields.scss";
export class DialogCustomFieldsEquipType extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      field: "",
      value: "",
      equipTypeSelected:this.props.equipTypeSelected
    };
  }
  changeHandler = (e) => {
    this.setState({ field: e.target.value });
  };
  changeValueHandler = (e) => {
    this.setState({ value: e.target.value });
  };

  addNewCustomField = () => {
    if (String(this.state.field).trim().length > 0 && String(this.state.value).trim().length > 0) {
    }
  };
  enableBtnAdd = () => {
    return String(this.state.field).trim().length > 0 && String(this.state.value).trim().length > 0;
  };

  onRemoveItemCustomFields = (field) => {
    let customFields = {};
    for (var [key, value] of Object.entries(this.state.equipTypeSelected.customFields)) {
      if (field != key) customFields[key] = value;
    }
    let equipTypeSelected = this.state.equipTypeSelected;
    equipTypeSelected.customFields = customFields;
    // this.props.onChangeEquipTypeCustomFields(equipTypeSelected);
    this.setState({ equipTypeSelected:equipTypeSelected });
  };

  getCustomFields = () => {
    const listCustomFields = [];
    for (var [key, value] of Object.entries(this.state.equipTypeSelected.customFields)) {
      listCustomFields.push({ field: key, value: value });
    }
    return (
      <table className="table-custom-fields">
        <thead>
          <tr>
            <th className="table-custom-fields-th">Nome Campo</th>
            <th className="table-custom-fields-th">Valor Campo</th>
            <th className="table-custom-fields-th">Remover</th>
          </tr>
        </thead>

        <tbody>
          {listCustomFields.map((item) => {
            return (
              <tr key={item.field}>
                <td className="table-custom-fields-td">{item.field}</td>
                <td className="table-custom-fields-td">{item.value}</td>
                <td className="table-custom-fields-td">
                  <button className="btn-del lognex-btn-danger" onClick={() => this.onRemoveItemCustomFields(item.field)}>
                    <i className="pi pi-trash"></i>
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  onClickAddNewCustomFields = () => {
    if (String(this.state.field).trim().length > 0 && String(this.state.value).trim().length > 0) {
      let field = String(this.state.field).trim();

      let exists = false;
      for (var [key, value] of Object.entries(this.state.equipTypeSelected.customFields)) {
        if (field == key) {
          exists = true;
        }
      }

      if (!exists) {
        let equipTypeSelected = this.state.equipTypeSelected;
        equipTypeSelected.customFields[field] = String(this.state.value).trim();
        // this.props.onChangeEquipTypeCustomFields(equipTypeSelected);
        this.setState({ field: "", value: "",equipTypeSelected:equipTypeSelected });
      }
    }
  };
  
  onConfirm=()=>{
    this.props.onChangeEquipTypeCustomFields( this.state.equipTypeSelected);
    this.props.onConfirm()
  }
  render() {
    const footer = (
      <div>
        <button title="Confirmar" className="lognex-btn-highlighted" onClick={()=>this.onConfirm()}>
          <i className="pi pi-check"></i>
        </button>
        <button title="Cancelar" className="lognex-btn-danger" onClick={this.props.onCancel}>
          <i className="pi pi-times"></i>
        </button>
      </div>
    );

    return (
      <Dialog
        className="content"
        header={"Equipamento de rastreamento"}
        footer={footer}
        visible={this.props.visible}
        style={this.props.style || { width: "520px" }}
        modal={true}
        onHide={this.props.onHide}
      >
        <div className="p-grid">
          <div className="p-col-12">
            <h3>Equipamento:{this.state.equipTypeSelected.name}</h3>
          </div>
          <div className="p-col-9">
            <div className="p-grid">
              <div className="p-col-6 form-field-container">
                <label>Nome Campo</label>
                <InputText value={this.state.field} placeholder="Nome do campo" onChange={this.changeHandler} name="field" />
              </div>
              <div className="p-col-6 form-field-container">
                <label>Valor Campo</label>
                <InputText value={this.state.value} placeholder="valor do campo" onChange={this.changeValueHandler} name="value" />
              </div>
            </div>
          </div>
          <div className="p-col-3 ">
            <br></br>
            <button className=" btn-add lognex-btn-highlighted" onClick={() => this.onClickAddNewCustomFields()} disabled={!this.enableBtnAdd()}>
              <i className="pi pi-plus"></i>
            </button>
          </div>
        </div>
        {this.getCustomFields()}
      </Dialog>
    );
  }
}
