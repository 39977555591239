import React from 'react';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputCep } from '../../../components/input-cep/InputCep';
import { InputCnpjCpf } from '../../../components/input-cnpj-cpf/InputCnpjCpf';

import './PessoaForm.scss';

export const PessoaForm = ({record, changeHandler})=>{
    const contato = record.contato || '';
    let cep = record && record.cep ? record.cep : "";
    return(
    <div className="pessoa-form">
        <div className="p-grid">
            <div className="p-col-6">
                <div className="p-grid">
                    <div className="p-col-3">
                        <div className="form-field-container">
                            <label>Código</label>
                            <InputText value={record.oid} onChange={changeHandler} disabled={true} />
                        </div>

                    </div>
                    <div className="p-col-12 form-field-container">
                        <label className="lxRequiredField">Nome/Razão Social</label>
                        <InputText value={record.nome} onChange={changeHandler} name="nome" />
                    </div>
                    <div className="p-col-12 form-field-container">
                        <label>Nome Fantasia</label>
                        <InputText value={record.nomeFantasia} onChange={changeHandler} name="nomeFantasia" />
                    </div>

                    <div className="p-col-6 form-field-container">
                        <label className="lxRequiredField">CPF/CNPJ</label>
                        <InputCnpjCpf value={record.numeroDocumento} onChange={changeHandler} name="numeroDocumento" />
                    </div>

                    <div className="p-col-4 form-field-container">
                        <label>Inscrição Estadual</label>
                        <InputText value={record.inscricaoEstadual} onChange={changeHandler} name="inscricaoEstadual" />
                    </div>

                    <div className="p-col-12 form-field-container">
                        <label>Endereço</label>
                        <InputText value={record.logradouro} onChange={changeHandler} name="logradouro" />
                    </div>
                    <div className="p-col-12 form-field-container">
                        <label>Complemento</label>
                        <InputText value={record.complemento} onChange={changeHandler} name="complemento" />
                    </div>
                    <div className="p-col-4 form-field-container">
                        <label>Número</label>
                        <InputText value={record.numeroLogradouro} onChange={changeHandler} name="numeroLogradouro" />
                    </div>
                    <div className="p-col-4 form-field-container">
                        <label>Bairro</label>
                        <InputText value={record.bairro} onChange={changeHandler} name="bairro" />
                    </div>
                    <div className="p-col-4 form-field-container">
                        <label>CEP</label>
                        <InputCep value={cep} onChange={changeHandler} name="cep" disabled={false} />
                    </div>
                    <div className="p-col-12 form-field-container">
                        <label>Cidade</label>
                        <InputText value={record.cidade} onChange={changeHandler} name="cidade" />
                    </div>

                    <div className="p-col-12 form-field-container">
                        <label>Email</label>
                        <InputText value={record.email} onChange={changeHandler} name="email" />
                    </div>
                    <div className="p-col-12 form-field-container">
                        <label>Site</label>
                        <InputText value={record.site} onChange={changeHandler} name="site" />
                    </div>
                    <div className="p-col-12 form-field-container">
                        <label>Contato</label>
                        <InputTextarea value={contato}  onChange={changeHandler} name="contato" />
                    </div>

                </div>
            </div>
        </div>

    </div>
);
}
