import React from 'react';   
import CargoShipmentDeliveredPageContainer from './CargoShipmentDeliveredPageContainer';

export class CargoShipmentDeliveredListPage extends React.Component{
    
    render(){
      return (
        <CargoShipmentDeliveredPageContainer  {...this.props}/>
      )
    }
}

