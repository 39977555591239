import { InputText } from 'primereact/inputtext';
import React from 'react';
import { LookupField } from '../../../components/lookup/LookupField';

export const TransferRouteForm = (props) => {

    return (
        <div className="transferroute-form">
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="p-grid">
                        <div className="p-col-4">
                            <div className="form-field-container">
                                <label>Código</label>
                                <InputText value={props.record.oid} disabled={true} />
                            </div>
                        </div>  
                    </div>
                </div>
                <div className="p-col-12">
                    <div className="p-grid">                             
                        <div className="p-col-4 form-field-container">
                            <label className="lxRequiredField">Origem</label>
                            <LookupField
                                value={props.record.businessUnitOrigin}
                                placeholder="Origem"
                                name="origin"
                                field="sigla"
                                
                                editDisabled={props.lookupOrigin.editDisabled}
                                visible={props.lookupOrigin.visible}
                                modal={props.lookupOrigin.modal}
                                header={props.lookupOrigin.header}

                                onComplete={props.lookupOriginOnComplete}
                                onInputFilter={props.lookupOriginOnInputFilter}
                                onEditField={props.lookupOriginOnEdit}
                                onClick={props.lookupOriginOnClick}
                                onHide={props.lookupOriginOnHide}
                                onConfirm={props.lookupOriginOnConfirm}
                                onCancel={props.lookupOriginOnCancel}
                                onPage={props.lookupOriginOnPage}
                                onFilter={props.lookupOriginOnFilter}
                                onSort={props.lookupOriginOnOnSort}
                                records={props.lookupOrigin.records}
                                columns={props.lookupOrigin.columns}
                                totalRecords={props.lookupOrigin.totalRecords}
                                sortOrder={props.lookupOrigin.order}
                                sortField={props.lookupOrigin.sortField}
                                first={props.lookupOrigin.first}
                                rows={props.lookupOrigin.rows}

                            />

                        </div>
                        <div className="p-col-4 form-field-container">
                            <label className="lxRequiredField">Destino</label>
                            <LookupField
                                value={props.record.businessUnitDestination}
                                placeholder="Destino"
                                name="destination"
                                field="sigla"
                                onComplete={props.lookupDestinationOnComplete}
                                onInputFilter={props.lookupDestinationOnInputFilter}
                                onEditField={props.lookupDestinationOnEdit}
                                onClick={props.lookupDestinationOnClick}
                                onHide={props.lookupDestinationOnHide}
                                onConfirm={props.lookupDestinationOnConfirm}
                                onCancel={props.lookupDestinationOnCancel}
                                onPage={props.lookupDestinationOnPage}
                                onFilter={props.lookupDestinationOnFilter}
                                onSort={props.lookupDestinationOnSort}
                                
                                editDisabled={props.lookupDestination.editDisabled}
                                visible={props.lookupDestination.visible}
                                modal={props.lookupDestination.modal}
                                header={props.lookupDestination.header}
                                records={props.lookupDestination.records}
                                columns={props.lookupDestination.columns}
                                totalRecords={props.lookupDestination.totalRecords}
                                sortOrder={props.lookupDestination.order}
                                sortField={props.lookupDestination.sortField}
                                first={props.lookupDestination.first}
                                rows={props.lookupDestination.rows}

                            />

                        </div>                      

                        <div className="p-col-2 form-field-container">
                            <label>Distância</label>
                            <InputText type='number' value={props.record.distance || 0} onChange={props.changeHandler} name="distance" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}