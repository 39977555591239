function createCNPJ(cnpj) {
    cnpj = cnpj.replace(/[^\d]+/g, '');

    if (cnpj === '') {
        return false;
    }

    if (cnpj.length !== 14) {
        return false;
    }

    // Elimina CNPJs invalidos conhecidos
    if (cnpj === '00000000000000' ||
        cnpj === '11111111111111' ||
        cnpj === '22222222222222' ||
        cnpj === '33333333333333' ||
        cnpj === '44444444444444' ||
        cnpj === '55555555555555' ||
        cnpj === '66666666666666' ||
        cnpj === '77777777777777' ||
        cnpj === '88888888888888' ||
        cnpj === '99999999999999') {
        return false;
    }

    // Valida DVs
    let tamanho = cnpj.length - 2
    let numeros = cnpj.substring(0, tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) {
            pos = 9;
        }

    }
    const resultados = [0, 0];
    resultados[0] = soma % 11 < 2 ? 0 : 11 - soma % 11;

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) {
            pos = 9;
        }
    }
    resultados[1] = soma % 11 < 2 ? 0 : 11 - soma % 11;
    return resultados;
}

function validateCNPJ(cnpj) {
    cnpj = cnpj.replace(/[^\d]+/g, '');
    let tamanho = cnpj.length - 2
    const digitos = cnpj.substring(tamanho);
    const resultados = createCNPJ(cnpj);
    if (resultados[0] !== parseInt(digitos.charAt(0), 10)) {
        return false;
    }

    if (resultados[1] !== parseInt(digitos.charAt(1), 10)) {
        return false;
    }
    return true;
}

// * OLD VALIDATE CPF
// function validateCPF(strCpf) { 

//     var soma;
//     var resto;
//     soma = 0;
//     if (strCpf === "00000000000") {
//         return false;
//     }

//     for (let i = 1; i <= 9; i++) {
//         soma = soma + parseInt(strCpf.substring(i - 1, i)) * (11 - i);
//     }

//     resto = soma % 11;

//     if (resto === 10 || resto === 11 || resto < 2) {
//         resto = 0;
//     } else {
//         resto = 11 - resto;
//     }

//     if (resto !== parseInt(strCpf.substring(9, 10))) {
//         return false;
//     }

//     soma = 0;

//     for (let i = 1; i <= 10; i++) {
//         soma = soma + parseInt(strCpf.substring(i - 1, i)) * (12 - i);
//     }
//     resto = soma % 11;

//     if (resto === 10 || resto === 11 || resto < 2) {
//         resto = 0;
//     } else {
//         resto = 11 - resto;
//     }

//     if (resto !== parseInt(strCpf.substring(10, 11))) {
//         return false;
//     }

//     return true;
// }

function validateCPF(strCPF) {
    var Soma;
    var Resto;
    Soma = 0;
    if (strCPF == "00000000000") return false;
    for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;
    if ((Resto == 10) || (Resto == 11)) Resto = 0;
    if (Resto != parseInt(strCPF.substring(9, 10))) return false;
    Soma = 0;
    for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;
    if ((Resto == 10) || (Resto == 11)) Resto = 0;
    if (Resto != parseInt(strCPF.substring(10, 11))) return false;
    return true;
}

export function validate(record) {
    const errors = [];
    record.numeroDocumento = record.numeroDocumento ? record.numeroDocumento.replace(/[^\d]+/g, '') : "";
    if (!record.nome || record.nome.trim() === "") {
        errors.push({ field: 'nome', message: 'O nome/razão social da pessoa é obrigatório!' });
    }

    if (!record.numeroDocumento || record.numeroDocumento.trim() === "") {
        errors.push({ field: 'numeroDocumento', message: 'O CPF/CNPJ da pessoa é obrigatório!' });
    }

    if (record.numeroDocumento && record.numeroDocumento.trim() !== "" &&
        (record.numeroDocumento.length !== 11 && record.numeroDocumento.length !== 14)) {
        errors.push({ field: 'numeroDocumento', message: 'O CPF/CNPJ informado é inválido!' });
    }

    if (record.numeroDocumento && record.numeroDocumento.trim() !== "" &&
        ((record.numeroDocumento.length === 11 && !validateCPF(record.numeroDocumento)) ||
            (record.numeroDocumento.length === 14 && !validateCNPJ(record.numeroDocumento)))) {
        errors.push({ field: 'numeroDocumento', message: 'O CPF/CNPJ informado é inválido!' });
    }

    if (record.cep && record.cep.toString().trim() !== "" && record.cep.toString().length !== 8) {
        errors.push({ field: 'cep', message: 'O CEP informado não contem 8 digitos!' });
    }

    if (!record.cep || record.cep.toString().trim() === "") {
        record.cep = null;
    }

    if (!record.numeroPis || record.numeroPis.toString().trim() === "") {
        record.numeroPis = null;
    }
    const result = {
        ok: errors.length === 0,
        errors,
        record
    }

    return new Promise((resolve, reject) => {
        const action = result.ok ? resolve : reject;
        return action(result);
    })
}