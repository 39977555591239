import { validate } from "./validations";
import { build } from "../../services/crudFactory";
import { Action } from "../../services/actions.factory";
import api from "../../services/api.service";
const BASE_PATH = "/lognex/regions";
const prefix = "crudRegion";
const crud = build(validate, BASE_PATH);

const newTypes = {
  SET_LOOKUP_CARRIER_VISIBLE: `${prefix}setLookupCarrierVisible`,
  SET_LOOKUP_LOCATION_VISIBLE: `${prefix}setLookupLocationVisible`,
  SET_LOOKUP_RESTRICTION_VISIBLE: `${prefix}setLookupRestrictionVisible`,
  REMOVE_SELECTED_LOCATIONS: `${prefix}removeSelectedLocations`,
  SET_DIALOG_ADD_LOCATIONS_VISIBLE: `${prefix}setDialogAddLocationsVisible`,
  ADD_LOCATION: `${prefix}addLocation`,
  ADD_LOCATIONS_CURRENT_RECORD: `${prefix}addLocationsCurrentRecord`,
  REMOVE_SELECTED_RESTRICTIONS: `${prefix}removeSelectedRestrictions`,
  SET_DIALOG_ADD_RESTRICTIONS_VISIBLE: `${prefix}setDialogAddRestrictionsVisible`,
  ADD_RESTRICTION: `${prefix}addRestriction`,
  ADD_RESTRICTIONS_CURRENT_RECORD: `${prefix}addRestrictionsCurrentRecord`,
  SET_LOOKUP_PARENT_VISIBLE: `${prefix}setLookupParentVisible`,
  SET_LOOKUP_PARENT_DISABLED: `${prefix}setLookupParentDisabled`,
  SET_LOOKUP_LOCATION_RECORDS: `${prefix}setLookupLocationRecords`,
  SET_LOOKUP_LOCATION_SELECTED_RECORDS: `${prefix}setLookupLocationSelectedRecords`,
  SET_LOOKUP_LOCATION_SEARCH_TEXT: `${prefix}setLookupLocationSearchText`,
  ADD_LOOKUP_LOCATION_RECORDS: `${prefix}addLookupLocationRecords`,
  FILTER_SELECTED_RECORDS_LOOKUP_LOCATION_RECORDS: `${prefix}filterSelectedRecordsLookupLocationRecords`,
  SET_LOOKUP_RESTRICTION_RECORDS: `${prefix}setLookupRestrictionRecords`,
  SET_LOOKUP_RESTRICTION_SELECTED_RECORDS: `${prefix}setLookupRestrictionSelectedRecords`,
  SET_LOOKUP_RESTRICTION_SEARCH_TEXT: `${prefix}setLookupRestrictionSearchText`,
  ADD_LOOKUP_RESTRICTION_RECORDS: `${prefix}addLookupRestrictionRecords`,
  FILTER_SELECTED_RECORDS_LOOKUP_RESTRICTION_RECORDS: `${prefix}filterSelectedRecordsLookupRestrictionRecords`,
  SEND_XLSX: `${prefix}sendXLSX`,
  IMPORT_XLSX_LOCALIZATIONS: `${prefix}importXLSXLocalizations`,
};

const actions = new Action(prefix, crud);

const types = Object.assign({}, actions.types, newTypes);

actions.removeSelectedLocations = () => {
  return {
    type: types.REMOVE_SELECTED_LOCATIONS,
  };
};
//TODO MUST BE REMOVE
actions.addLocation = () => {
  return {
    type: types.ADD_LOCATION,
  };
};

actions.addLocationsCurrentRecord = () => {
  return {
    type: types.ADD_LOCATIONS_CURRENT_RECORD,
  };
};

actions.setDialogAddLocationsVisible = (visible) => {
  return {
    type: types.SET_DIALOG_ADD_LOCATIONS_VISIBLE,
    visible,
  };
};

actions.removeSelectedRestrictions = () => {
  return {
    type: types.REMOVE_SELECTED_RESTRICTIONS,
  };
};
//TODO MUST BE REMOVE
actions.addRestriction = () => {
  return {
    type: types.ADD_RESTRICTION,
  };
};

actions.addRestrictionsCurrentRecord = () => {
  return {
    type: types.ADD_RESTRICTIONS_CURRENT_RECORD,
  };
};
actions.setDialogAddRestrictionsVisible = (visible) => {
  return {
    type: types.SET_DIALOG_ADD_RESTRICTIONS_VISIBLE,
    visible,
  };
};

actions.setLookupCarrierVisible = (visible) => {
  return {
    type: types.SET_LOOKUP_CARRIER_VISIBLE,
    visible,
  };
};
actions.setLookupLocationVisible = (visible) => {
  return {
    type: types.SET_LOOKUP_LOCATION_VISIBLE,
    visible,
  };
};
actions.setLookupRestrictionVisible = (visible) => {
  return {
    type: types.SET_LOOKUP_RESTRICTION_VISIBLE,
    visible,
  };
};

actions.setLookupParentVisible = (visible) => {
  return {
    type: types.SET_LOOKUP_PARENT_VISIBLE,
    visible,
  };
};

actions.setLookupParentDisabled = (disabled) => {
  return {
    type: types.SET_LOOKUP_PARENT_DISABLED,
    disabled,
  };
};

actions.setLookupLocationRecords = (records) => {
  return {
    type: types.SET_LOOKUP_LOCATION_RECORDS,
    records,
  };
};

actions.addLookupLocationRecords = (records) => {
  return {
    type: types.ADD_LOOKUP_LOCATION_RECORDS,
    records,
  };
};

actions.filterSelectedRecordsLookupLocationRecords = () => {
  return {
    type: types.FILTER_SELECTED_RECORDS_LOOKUP_LOCATION_RECORDS,
  };
};
actions.setLookupLocationSelectedRecords = (selectedRecords) => {
  return {
    type: types.SET_LOOKUP_LOCATION_SELECTED_RECORDS,
    selectedRecords,
  };
};

actions.setLookupLocationSearchText = (searchText) => {
  return {
    type: types.SET_LOOKUP_LOCATION_SEARCH_TEXT,
    searchText,
  };
};

actions.setLookupRestrictionRecords = (records) => {
  return {
    type: types.SET_LOOKUP_RESTRICTION_RECORDS,
    records,
  };
};

actions.addLookupRestrictionRecords = (records) => {
  return {
    type: types.ADD_LOOKUP_RESTRICTION_RECORDS,
    records,
  };
};

actions.filterSelectedRecordsLookupRestrictionRecords = () => {
  return {
    type: types.FILTER_SELECTED_RECORDS_LOOKUP_RESTRICTION_RECORDS,
  };
};

actions.setLookupRestrictionSelectedRecords = (selectedRecords) => {
  return {
    type: types.SET_LOOKUP_RESTRICTION_SELECTED_RECORDS,
    selectedRecords,
  };
};

actions.setLookupRestrictionSearchText = (searchText) => {
  return {
    type: types.SET_LOOKUP_RESTRICTION_SEARCH_TEXT,
    searchText,
  };
};

actions.sendXLSX = () => {
  return {
    type: types.SEND_XLSX,
    async: true,
  };
};

actions.importXLSXLocalizations = (xlsx, type = "localizations") => {
  return (dispatch) => {
    const URL = `/api/v1${BASE_PATH}/import/localizations/xlsx`;
    dispatch(actions.sendXLSX());
    api
      .uploadFiles(URL, xlsx)
      .then((response) => {
        const { data } = response.data;
        console.log("ImportXLSXLocalizations data => ", data);

        dispatch(actions.hideLoader());
        if (data.length > 0) {
          if (type === "localizations") {
            // setar a listagem de localizações no lookup de localizações
            dispatch(actions.addLookupLocationRecords(data));
            // em seguida adicionar os registro no currentRecord
            dispatch(actions.addLocationsCurrentRecord());
            const messages = ["Localizações importadas com sucesso"];
            dispatch(
              actions.messageActions.messageShowMessages(
                actions.makeMessages(messages, "info")
              )
            );
          } else if (type === "restricions") {
             // setar a listagem de localizações no lookup de localizações
             dispatch(actions.addLookupRestrictionRecords(data));
             // em seguida adicionar os registro no currentRecord
             dispatch(actions.addRestrictionsCurrentRecord());
             const messages = ["Restrições importadas com sucesso"];
             dispatch(
               actions.messageActions.messageShowMessages(
                 actions.makeMessages(messages, "info")
               )
             );
          }
        } else {
          const messages = ["Erro ao importar planilha de localizações."];
          dispatch(
            actions.messageActions.messageShowMessages(
              actions.makeMessages(messages, "error")
            )
          );
        }
      })
      .catch((err) => {
        console.log(err);
        const messages = ["Não foi possível processar a planilha."];
        dispatch(
          actions.messageActions.messageShowMessages(
            actions.makeMessages(messages, "error")
          )
        );
        dispatch(actions.hideLoader());
      });
  };
};

export { types, actions };
