import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Column } from "primereact/column";
import { EndpointList, EventList } from "./EndpointList";
import { actions } from "../tower_equipment_type.actions";
import { ButtonDialog } from "../../../components/button-dialog/ButtonDialog";
import "./endpoint_list.scss";

const mapStateToProps = (state) => {
  return {
    columns: state.crudTowerEquipmentTypeState.apiEndpointsColumns,
    records: state.crudTowerEquipmentTypeState.currentRecord.apiEndpoints || [],
    first: state.crudTowerEquipmentTypeState.index,
    rows: state.crudTowerEquipmentTypeState.max,
    totalRecords: state.crudTowerEquipmentTypeState.count,
    sortField: state.crudTowerEquipmentTypeState.sortField,
    order: state.crudTowerEquipmentTypeState.sortOrder,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    paginate: (paginationParams) => dispatch(actions.paginate(paginationParams)),
    sortOrder: (sortParams) => dispatch(actions.sortOrder(sortParams)),
    applyFilter: (filterParams) => {
      dispatch(actions.setType(null));
      dispatch(actions.applyFilter(filterParams));
    },
  };
};

class EndpointListPageContainer extends React.Component {
  onPage = (e) => {
    this.props.paginate({
      max: e.rows,
      index: e.first,
    });
  };
  onSort = (e) => {
    this.props.sortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField,
    });
  };

  render() {
    let columns = this.props.columns.map((col) => {
      return <Column {...col} key={col.field} />;
    });

    return (
      <Fragment>
        <EndpointList {...this.props} onPage={this.onPage} onSort={this.onSort} columns={columns} />
      </Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EndpointListPageContainer);
