import React from "react";
import { connect } from "react-redux";
import { Column } from "primereact/column";
import { OrderCargoVolumeList } from "./OrderCargoVolumeList";
import { formatNumber, numberToCurrency } from "../../../../utils";

const mapStateToProps = (state) => {
  const record = state.crudOrderDeliveryState.currentRecord;
  let barcodes = [];
  if (record && record.cargo && record.cargo.summary && record.cargo.summary.volumes) {
    barcodes = record.cargo.summary.volumes;
  }

  return {
    columns: state.crudOrderDeliveryState.columnsCargoVolume,
    records: barcodes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

class OrderCargoVolumeListPageContainer extends React.Component {
  render() {
    const numberTemplate = (rowData, column) => {
      const valor = rowData[column.field];
      return <div style={{ textAlign: "center", color: "black" }}>{formatNumber(valor)}</div>;
    };

    const valorTemplate = (rowData, column) => {
      const valor = rowData[column.field];
      return <div style={{ textAlign: "center", color: "black" }}>{numberToCurrency(valor, "REAL")}</div>;
    };

    const columns = this.props.columns.map((col) => {
      if (col.field === "value") {
        return <Column {...col} key={col.field} body={valorTemplate} />;
      }
      if (col.field === "cubage") {
        return <Column {...col} key={col.field} body={numberTemplate} />;
      }

      return <Column {...col} key={col.field} />;
    });

    return <OrderCargoVolumeList {...this.props} columns={columns} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderCargoVolumeListPageContainer);
