
import React from 'react';
import { InputText } from 'primereact/inputtext';
import { LookupField } from '../../../components/lookup/LookupField';
import { InputAutoCompleteText } from '../../../components/input-autocomplete-text/InputAutoCompleteText';
import { TabView, TabPanel } from 'primereact/tabview';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { InputMask } from 'primereact/inputmask';
import { InputCnpjCpf } from '../../../components/input-cnpj-cpf/InputCnpjCpf';

export class CarrierForm extends React.Component {

    isValidBranch =(branch)=>{
        let result = true;
        if(!branch){
            return false;
        }
        if(!branch.name || branch.name.trim().length === 0){
            result  = false;
        }
        if(!branch.documentNumber || branch.documentNumber.trim().length === 0){
            result  = false;
        }
        return result;
    }



    render() {
        const { props } = this;
        const record = props.record;
        const { currentBranch = {
            name: "",
            tradeName: "",
            documentNumber: "",
            email: "",
            phone: "",
            contact: "",
            address: { street: "", number: "", complement: "", district: "", city: "", zipCode: "" }
        } } = props;
        const addBranchDisabled = !this.isValidBranch(currentBranch);

        return (
            <div className="carrier-form">
                <div className="p-grid">
                    <div className="p-col-6">
                        <div className="p-grid">
                            <div className="p-col-6 form-field-container">
                                <label>Código</label>
                                <InputText value={record.oid} onChange={props.changeHandler} name="oid" disabled={true} />
                            </div>
                            <div className="p-col-6 form-field-container"></div>
                            <div className="p-col-12 form-field-container">
                                <label>Nome/Razão social</label>
                                <InputText maxlength="300" value={record.name} onChange={props.changeHandler} name="name" />
                            </div>
                            <div className="p-col-12 form-field-container">
                                <label>Nome Fantasia</label>
                                <InputText maxlength="300" value={record.tradeName} onChange={props.changeHandler} name="tradeName" />
                            </div>
                            <div className="p-col-6 form-field-container">
                                <label>Sigla</label>
                                <InputAutoCompleteText
                                    name="label"
                                    value={record.label || ""}
                                    onChange={props.changeHandler}
                                    placeholder="0000000000"
                                    maxLength="10"
                                />
                            </div>
                            <div className="p-col-6 form-field-container"></div>


                            <div className="p-col-6 form-field-container">
                                <label className="lxRequiredField">CNPJ/CPF</label>
                                <InputCnpjCpf value={record.documentNumber} onChange={props.changeHandler} name="documentNumber" />
                            </div>

                            <div className="p-col-6 form-field-container">
                                <label>Telefone </label>
                                <InputMask mask="(99)99999-9999" value={record.phone} onChange={props.changeHandler} name="phone" />
                            </div>

                            <div className="p-col-12 form-field-container">
                                <label>Endereço</label>
                                <InputText maxlength="300" value={record.address.street} onChange={props.changeHandler} name="address.street" />
                            </div>

                            <div className="p-col-12 form-field-container">
                                <label>Complemento</label>
                                <InputText maxlength="300" value={record.address.complement} onChange={props.changeHandler} name="address.complement" />
                            </div>

                            <div className="p-col-4 form-field-container">
                                <label>Número</label>
                                <InputText maxlength="10" value={record.address.number} onChange={props.changeHandler} name="address.number" />
                            </div>

                            <div className="p-col-4 form-field-container">
                                <label>Bairro</label>
                                <InputText maxlength="300" value={record.address.district} onChange={props.changeHandler} name="address.district" />
                            </div>

                            <div className="p-col-4 form-field-container">
                                <label>CEP</label>
                                <InputMask mask="99999-999" value={record.address.zipCode} onChange={props.changeHandler} onComplete={props.onCompleteCep} name="address.zipCode" />
                            </div>

                        </div>
                    </div>
                </div>
                <div className="p-grid">
                    <div className="p-col-12">
                        <button
                            title="Adicionar Filiais"
                            onClick={props.dialogAddBranchesOnOpen}
                            style={{ marginRight: ".25em" }}
                            className="lognex-btn-highlighted">
                            <i className="pi pi-plus"></i>
                        </button>
                        <button
                            title="Editar Filial"
                            disabled={props.selectedBranches.length != 1}
                            onClick={props.dialogEditBranchesOnOpen}
                            style={{ marginRight: ".25em" }}
                            className={props.selectedBranches.length === 1 ? "lognex-btn-highlighted" : ""}>
                            <i className="pi pi-pencil"></i>
                        </button>
                        <button
                            title="Excluir Filiais"
                            onClick={props.onRemoveSelectedBranches}
                            style={{ marginRight: ".25em", marginBottom: ".25em" }}
                            className="lognex-btn-danger">
                            <i className="pi pi-times"></i>
                        </button>
                        <h3>Filiais</h3>

                        <Dialog
                            header={props.dialogAddBranches.header}
                            visible={props.dialogAddBranches.visible}
                            style={{ width: '50vw' }}
                            modal={props.dialogAddBranches.modal}
                            baseZIndex={1}
                            onHide={props.dialogAddBranchesOnClose}
                            footer={
                                <div>
                                    <button
                                        title="Confirmar"
                                        className={addBranchDisabled !== true ? "lognex-btn-highlighted" : ""}
                                        onClick={props.dialogAddBranchesOnConfirm}
                                        disabled={addBranchDisabled} >
                                        <i className="pi pi-check"></i>
                                    </button>
                                    <button
                                        title="Incluir"
                                        className={addBranchDisabled !== true ? "lognex-btn-highlighted" : ""}
                                        onClick={props.dialogAddBranchesOnAdd}
                                        disabled={addBranchDisabled} >
                                        <i className="pi pi-plus"></i>
                                    </button>
                                    <button
                                        title="Cancelar"
                                        className="lognex-btn-danger"
                                        onClick={props.dialogAddBranchesOnClose} >
                                        <i className="pi pi-times"></i>
                                    </button>
                                </div>}>
                            <div className="p-grid">
                                <div className="p-col-12">
                                    <div className="p-grid">

                                        <div className="p-col-12 form-field-container">
                                            <label>Nome/Razão social</label>
                                            <InputText maxlength="300" value={currentBranch.name} onChange={props.currentBranchChangeHandler} name="name" />
                                        </div>
                                        <div className="p-col-12 form-field-container">
                                            <label>Nome Fantasia</label>
                                            <InputText maxlength="300" value={currentBranch.tradeName} onChange={props.currentBranchChangeHandler} name="tradeName" />
                                        </div>



                                        <div className="p-col-6 form-field-container">
                                            <label className="lxRequiredField">CNPJ/CPF</label>
                                            <InputCnpjCpf value={currentBranch.documentNumber} onChange={props.currentBranchChangeHandler} name="documentNumber" />
                                        </div>

                                        <div className="p-col-6 form-field-container">
                                            <label>Telefone </label>
                                            <InputMask mask="(99)99999-9999" value={currentBranch.phone} onChange={props.currentBranchChangeHandler} name="phone" />
                                        </div>

                                        <div className="p-col-12 form-field-container">
                                            <label>Endereço</label>
                                            <InputText maxlength="300" value={currentBranch.address.street} onChange={props.currentBranchChangeHandler} name="address.street" />
                                        </div>

                                        <div className="p-col-12 form-field-container">
                                            <label>Complemento</label>
                                            <InputText maxlength="300" value={currentBranch.address.complement} onChange={props.currentBranchChangeHandler} name="address.complement" />
                                        </div>

                                        <div className="p-col-6 form-field-container">
                                            <label>Número</label>
                                            <InputText maxlength="10" value={currentBranch.address.number} onChange={props.currentBranchChangeHandler} name="address.number" />
                                        </div>

                                        <div className="p-col-6 form-field-container">
                                            <label>CEP</label>
                                            <InputMask mask="99999-999" value={currentBranch.address.zipCode} onChange={props.currentBranchChangeHandler} onComplete={props.currentBranchOnCompleteZipCode} name="address.zipCode" />
                                        </div>
                                        <div className="p-col-6 form-field-container">
                                            <label>Bairro</label>
                                            <InputText maxlength="300" value={currentBranch.address.district} onChange={props.currentBranchChangeHandler} name="address.district" />
                                        </div>

                                        <div className="p-col-6 form-field-container">
                                            <label>Cidade</label>
                                            <InputText maxlength="300" value={currentBranch.address.city} onChange={props.currentBranchChangeHandler} name="address.city" />
                                        </div>

                                    </div>
                                </div>
                            </div>


                        </Dialog>
                    </div>
                    <div className="p-col-12">
                        <DataTable
                            selection={props.selectedBranches}
                            onSelectionChange={props.branchesOnSelectionChange}
                            onFilter={props.onFilterBranches}
                            onSort={props.onSortBranches}
                            //onPage={props.onPageBranches}
                            value={props.branchesRecords}
                            //lazy={true}
                            totalRecords={props.branchesTotalRecords}
                            sortOrder={props.branchesOrder}
                            sortField={props.branchesSortField}
                            paginator={true}
                            //first={props.branchesFirst}
                            rows={props.branchesRows ? props.branchesRows : 10}
                            emptyMessage="Não foram encontrados registros"
                            rowsPerPageOptions={[5, 10, 20, 50]}
                        >

                            {props.branchesColumns}
                        </DataTable>
                    </div>
                </div>
            </div>
        )
    }
};