
import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';

export const ShipmentTable = (props) => 
    (
        <div className="cteTable-form">


            <DataTable
                selectionMode="multiple"
                onFilter={props.onFilter}
                onSort={props.onSort}
                onPage={props.onPage}
                value={props.records}
                lazy={true}
                totalRecords={props.totalRecords}
                sortOrder={props.order}
                sortField={props.sortField}
                paginator={true}
                first={props.first}
                rows={props.rows}
                emptyMessage="Não foram encontrados registros"
                rowsPerPageOptions={[5, 10, 20,50]}
                onRowToggle={props.onRowToggle}
                onRowClick={props.setExpandedOrdersRowsByRowClick}
                rowExpansionTemplate={props.rowExpansionTemplate}
                expandedRows={props.expandendOrdersRowns}
                onSelectionChange={props.onSelectedEventsChange}
                selection={props.selectedRows}
            >
                {[<Column key="shipmentTableAprove" selectionMode="multiple" style={{ width: "3em" }}/>,<Column expander={true} key={"shipmentTable"} style={{ width: '4em' }} />, ...props.columns]}
                
            </DataTable>
            { <Dialog
                header={props.dialogApproveComponent.header}
                visible={props.dialogApproveComponent.visible}
                style={{ width: '50vw' }}
                modal={props.dialogApproveComponent.modal}
                baseZIndex={1}
                onHide={props.dialogApproveComponent.actions.onCancel}
                footer={<div>
                    <button
                        title="Aprovar"
                        onClick={props.dialogApproveComponent.actions.onApprove}
                        style={{ marginRight: ".25em" }}
                        className="lognex-btn-highlighted"
                        disabled={!props.dialogApproveComponent.description}>
                        <i className="pi pi-check"></i>
                    </button>

                    <button
                        title="Cancelar"
                        onClick={props.dialogApproveComponent.actions.onCancel}
                        style={{ marginRight: ".25em", marginBottom: ".25em" }}
                        className="lognex-btn-danger">
                        <i className="pi pi-times"></i>
                    </button>
                </div>}>
                {/* <h4 className="lxAlertMessage">{props.dialogApproveComponent.alertMessage}</h4> */}
                <InputTextarea
                    className='lxInputTextArea'
                    rows={5}
                    cols={30}
                    value={props.dialogApproveComponent.description}
                    onChange={props.dialogApproveComponent.actions.onChangeDescription} />

            </Dialog> }
        </div>
    )
