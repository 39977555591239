import { types } from "./tower_event_control.actions";
import { Reducer } from "../../services/reducer.factory";

const initialState = {
  columns: [
    {
      field: "oid",
      header: "Código",
      columnKey: "oid",
      sortable: true,
      filter: true
    },
    {
      field: "eventName",
      header: "Nome Evento",
      columnKey: "eventName",
      sortable: true,
      filter: true
    },
    {
      field: "actionType",
      header: "Tipo de Ação",
      columnKey: "actionType",
      sortable: true,
      filter: true
    }
    
  ],
  currentRecord: {
    oid: "",
    eventName:"",
    actionType:""
  }
};

const reducer = new Reducer(initialState, types);

export function CRUDTowerEventControlReducer(state, action) {
  return reducer.execute(state, action);
}

