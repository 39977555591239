import { validate } from './validations';
import { build } from '../../services/crudFactory';
import { Action } from '../../services/actions.factory';
import api from "../../services/api.service";
const BASE_PATH = '/portal/rastreardelivery';
const prefix = 'rastrearDelivery';
const crud = build(validate, BASE_PATH);

const newTypes = {
    ON_CHANGE_CODIGO_RASTREIO: `${prefix}onChangeCodigoRastreio`,
    GET_INFO_RASTREIO_DELIVERY: `${prefix}findDeliveryByTraceCode`,
    SET_RECORD: `${prefix}setRecord`,
}

const actions = new Action(prefix, crud);

const types = Object.assign({}, actions.types, newTypes);


actions.onChangeCodigoRastreio = (cdRastreio) => {
    return {
        type: types.ON_CHANGE_CODIGO_RASTREIO,
        cdRastreio
    };
}
 
actions.setRecord = (record) => {
    return {
        type: types.SET_RECORD,
        record
    };
}
actions.findDeliveryByTraceCode = () => {
    return (dispatch,getState) => {
        const tracecode = getState().rastrearDeliveryState.cdRastreio; 
        api.post(`${BASE_PATH}/tracecode`, { tracecode })
            .then((result) => { 
                  dispatch(actions.setRecord(result.data));
                // dispatch(actions.setCollectionOrder(result.data));
                // dispatch(actions.loadEquipmentType(link));
                // dispatch(actions.loadHoursDocks(link));
                dispatch(actions.hideLoader());
            })
            .catch((error) => {
                dispatch(actions.setRecord(null));
                dispatch(actions.hideLoader());
                console.log(error)
            });
    }
}


export { types, actions }