import React from 'react';

import PolicesGroupFormPageContainer from './PolicesGroupFormPageContainer';

export class PolicesGroupFormPage extends React.Component {

    render() {
        return (
            <PolicesGroupFormPageContainer {...this.props} />
        )
    }
}
