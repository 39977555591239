import history from '../../history';
import api from '../../services/api.service';

export const types = {
  MENU_SET_ITEMS: "menu.setItems",
  MENU_CLEAR_ITEMS: "menu.setItems",
  MENU_FETCH_ITEMS: "menu.fetchItems",
  MENU_FETCH_ITEMS_SUCCESS: "menu.fetchItemsSuccess",
  MENU_FETCH_ITEMS_ERROR: "menu.fetchItemsError",
  SET_CURRENT_RECORD: "menu.setCurrentRecord"
};


export function menuSetItems(items) {
  return {
    type: types.MENU_SET_ITEMS,
    items
  };
}

export function menuClearItems() {
  return {
    type: types.MENU_CLEAR_ITEMS
  };
}


/* Notifica a início de um processo assincrono */
export function menuFetchItems() {
  return {
    type: types.MENU_FETCH_ITEMS,
    async: true
  };
}

export function setCurrentRecord(menuPath) {
  return {
    type: types.SET_CURRENT_RECORD,
    async: true,
    menuPath,
  }
}
/* Notifica a fim de um processo assincrono  com sucesso*/
function fetchSuccess() {
  return {
    type: types.MENU_FETCH_ITEMS_SUCCESS,
    async: false
  };

}
export function menuFetchItemsSucces(items) {
  return dispatch => {
    dispatch(menuSetItems(items));
    dispatch(fetchSuccess());
  };
}

/* Notifica a fim de um processo assincrono com erro */
export function menuFetchItemsError(errorMessage) {
  return {
    type: types.MENU_FETCH_ITEMS_ERROR,
    async: false
  };
}

/* Notifica a início de um processo de carregamento dos dados do menu */
export function menuFetch() {
  return (dispatch, getState) => {
    dispatch(menuFetchItems());
    const data = {
      "provider": "br.com.lognex.core.controller.MenuController",
      "action": "getMenusUsuarioAssembler"
    }
    api.post('/api/v1/lognex/menus/get', data)
      .then(response => {
        let menuItems = response.data || [];

        const newMenuItens = [];

        for (let index = 0; index < menuItems.length; index++) {
          try {
            const element = mapMenu(menuItems[index]);
            newMenuItens.push(element)
          } catch (error) {
            console.log("Erro = ", error, " index=", index)
          }
        }

        dispatch(menuFetchItemsSucces(newMenuItens));
      })
      .catch(e => {
        dispatch(menuFetchItemsError(e))
      })


  };
}

function mapMenu(menu) {

  const label = menu.name;
  const iconName = menu.icon;
  const oid = menu.oid;
  const path = menu.path;

  let items = [];
  const command = (event) => {
    if (menu.path && menu.path.trim().length > 0) {
      history.push(menu.path);
    }
  }
  if (menu.children && menu.children.length > 0) {
    for (let index = 0; index < menu.children.length; index++) {
      const children = menu.children[index];
      if (children) {
        const men = mapMenu(children)
        items.push(men)
      } else {
        // console.log("children nulo index", index, " menu=", menu)
      }
    }
    // items = menu.children.map(mapMenu);
    return {
      oid,
      label,
      iconName,
      items,
    }
  }
  return {
    oid,
    label,
    iconName,
    command,
    path,
  }


}