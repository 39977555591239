import { types } from "./order-delivery.actions";
import { Reducer } from "../../services/reducer.factory";
import moment from "moment";

const initialState = {
  columns: [
    {
      field: "code",
      header: "Código",
      columnKey: "code",
      sortable: "true",
      filter: "true",
    },
    {
      field: "scriptStatus",
      header: "Status",
      columnKey: "scriptStatus",
      sortable: "true",
      filter: "true",
    },
        
    {
      field: "createdAt",
      header: "Data Cadastro",
      columnKey: "code",
      sortable: "false",
      filter: "false",
    },
    {
      field: "sender.name",
      header: "Emitente",
      columnKey: "sender.name",
      sortable: "true",
      filter: "true",
    },

    {
      field: "receiver.name",
      header: "Destinatário",
      columnKey: "receiver.name",
      sortable: "false",
      filter: "true",
    },
    {
      field: "deliveryForecast",
      header: "Previsão Entrega",
      columnKey: "deliveryForecast",
      sortable: "true",
      filter: "false",
    },
  ],

  columnsRouteOrder: [
    {
      field: "carrier.documentNumber",
      header: "Código Transp",
      columnKey: "carrier.documentNumber",
      sortable: "false",
      filter: "false",
    },
    {
      field: "carrier.name",
      header: "Nome Transp",
      columnKey: "carrier.name",
      sortable: "false",
      filter: "false",
    },
    {
      field: "payer",
      header: "Payer",
      columnKey: "payer",
      sortable: "false",
      filter: "false",
    },
    {
      field: "distance",
      header: "Distância(Km)",
      columnKey: "distance",
      sortable: "false",
      filter: "false",
    },
    {
      field: "origin.zipCode",
      header: "CEP Origem",
      columnKey: "origin.zipCode",
      sortable: "false",
      filter: "false",
    },
    {
      field: "origin.ibgeCode",
      header: "Código IBGE Origem",
      columnKey: "origin.ibgeCode",
      sortable: "false",
      filter: "false",
    },
    {
      field: "destination.zipCode",
      header: "CEP Destino",
      columnKey: "destination.zipCode",
      sortable: "false",
      filter: "false",
    },
    {
      field: "destination.ibgeCode",
      header: "Código IBGE Destino",
      columnKey: "destination.ibgeCode",
      sortable: "false",
      filter: "false",
    },
  ],
  columnsCargoBarcodes: [
    {
      field: "barcodes",
      header: "Código de Barras",
      columnKey: "barcodes",
      sortable: "false",
      filter: "false",
    },
  ],
  columnsCargoVolume: [
    {
      field: "code",
      header: "Código",
      columnKey: "code",
      sortable: "false",
      filter: "false",
    },
    {
      field: "unit",
      header: "Unidade",
      columnKey: "unit",
      sortable: "false",
      filter: "false",
    },
    {
      field: "name",
      header: "Nome",
      columnKey: "name",
      sortable: "false",
      filter: "false",
    },
    {
      field: "compartmentName",
      header: "Compartimento",
      columnKey: "compartmentName",
      sortable: "false",
      filter: "false",
    },
    {
      field: "value",
      header: "Valor",
      columnKey: "value",
      sortable: "false",
      filter: "false",
    },
    {
      field: "cubage",
      header: "Cubagem",
      columnKey: "cubage",
      sortable: "false",
      filter: "false",
    },
  ],
  columnsCargoProducts: [
    {
      field: "code",
      header: "Código",
      columnKey: "code",
      sortable: "false",
      filter: "false",
    },
    {
      field: "name",
      header: "Nome",
      columnKey: "name",
      sortable: "false",
      filter: "false",
    },
    {
      field: "barcode",
      header: "Código de Barras",
      columnKey: "barcode",
      sortable: "false",
      filter: "false",
    },
    {
      field: "cubage",
      header: "Cubagem",
      columnKey: "cubage",
      sortable: "false",
      filter: "false",
    },

    {
      field: "weight",
      header: "Peso",
      columnKey: "weight",
      sortable: "false",
      filter: "false",
    },
    {
      field: "value",
      header: "Valor",
      columnKey: "value",
      sortable: "false",
      filter: "false",
    },
    {
      field: "quantity",
      header: "Quantidade",
      columnKey: "quantity",
      sortable: "false",
      filter: "false",
    },
  ],
  columnsExpandedProdPacking: [
    {
      field: "code",
      header: "Código",
      columnKey: "code",
      sortable: "false",
      filter: "false",
    },
    {
      field: "name",
      header: "Nome",
      columnKey: "name",
      sortable: "false",
      filter: "false",
    },
    {
      field: "unit",
      header: "Unidade",
      columnKey: "unit",
      sortable: "false",
      filter: "false",
    },

    {
      field: "compartmentName",
      header: "Compartimento",
      columnKey: "compartmentName",
      sortable: "false",
      filter: "false",
    },

    {
      field: "value",
      header: "Valor",
      columnKey: "value",
      sortable: "false",
      filter: "false",
    },

    {
      field: "grossWeight",
      header: "Peso Bruto",
      columnKey: "grossWeight",
      sortable: "false",
      filter: "false",
    },

    {
      field: "cubage",
      header: "Cubagem",
      columnKey: "cubage",
      sortable: "false",
      filter: "false",
    },
  ],
  columnsExpandedProd: [
    {
      field: "key",
      header: "Conteúdo",
      columnKey: "key",
      sortable: "false",
      filter: "false",
    },
  ],
  columnsOrderAttemdanceWindow: [
    {
      field: "start",
      header: "Inicio",
      columnKey: "start",
      sortable: "false",
      filter: "false",
    },
    {
      field: "end",
      header: "Final",
      columnKey: "end",
      sortable: "false",
      filter: "false",
    },
  ],

  columnsPendencies:[
    {
      field: "type",
      header: "Tipo",
      columnKey: "type",
      sortable: "false",
      filter: "false",
    },
    {
      field: "createdAt",
      header: "Data Cadastro",
      columnKey: "createdAt",
      sortable: "false",
      filter: "false",
    },
    {
      field: "status",
      header: "Status",
      columnKey: "status",
      sortable: "false",
      filter: "false",
    },
    {
      field: "finishedAt",
      header: "Terminou em",
      columnKey: "finishedAt",
      sortable: "false",
      filter: "false",
    },
    {
      field: "observation",
      header: "Observação",
      columnKey: "observation",
      sortable: "false",
      filter: "false",
    },
  ],

  filterFields: {
    number: null,
    initialDate: moment().startOf("month").toDate(),
    finalDate: moment().toDate(),
    carrier: {
      tradeName: "",
      documentNumber: "",
    },
    lookupCarrier: {
      visible: false,
      modal: false,
      header: "Transportadora",
    },
    lookupOrigin: {
      visible: false,
      modal: false,
      header: "Origem",
    },
    lookupDestination: {
      visible: false,
      modal: false,
      header: "Destino",
    },
    selectedBusinessUnitGroup: null,
    selectedGrouperList: [],
    selectedBusinessUnits: [],
    selectedMainBusinessUnit: null,
    selectedBusinessUnitGroupLabel: "",
    modalGrouperVisible: false,
    modalSearchBusinessUnitVisible: false,
    businessUnitGroup: null,
    currentBusinessUnit: null,
  },
  productRowSelected: null,
};

const reducer = new Reducer(initialState, types);

export function CRUDOrderDeliveryReducer(state, action) {
  state = reducer.execute(state, action);
  return execute(state, action);
}

function execute(state, action) {
  let result = state;
  switch (action.type) {
    case types.SET_BUSINESS_UNIT_FILTER:
      return setBusinessUnitFilter(state, action);
    case types.SET_BUSINESS_UNIT_GROUP_FILTER:
      return setBusinessUnitGroupFilter(state, action);
    case types.SET_VISIBLE_MODAL_SEARCH_FILTER:
      return setVisibleModalSearchBusinessUnitFilter(state, action);
    case types.SET_VISIBLE_MODAL_WIZARD_FILTER:
      return setVisibleModalGrouperFilter(state, action);
    case types.SET_SELECTED_BUSINESS_UNIT_RECORD:
      return setSelectedBusinessUnit(state, action);
    case types.SET_SELECTED_BUSINESS_UNITS:
      return setSelectedBusinessUnits(state, action);
    case types.SET_SELECTED_MAIN_BUSINESS_UNIT:
      return setSelectedMainBusinessUnit(state, action);
    case types.SET_SELECTED_BUSINESS_UNIT_GROUP_LABEL:
      return setSelectedBusinessUnitGroupLabel(state, action);
    case types.SET_LOOKUP_CARRIER_VISIBLE:
      return setLookupCarrierVisible(state, action);
    case types.SET_CARRIER_FILTER:
      return setCarrierFilter(state, action);
    case types.SET_LOOKUP_DESTINATION_VISIBLE:
      return setLookupDestinationVisible(state, action);
    case types.SET_DESTINATION_FILTER:
      return setDestinationFilter(state, action);
    case types.SET_LOOKUP_ORIGIN_VISIBLE:
      return setLookupOriginVisible(state, action);
    case types.SET_ORIGIN_FILTER:
      return setOriginFilter(state, action);
    case types.SET_INITIAL_DATE_FILTER:
      return setInitialDateFilter(state, action);
    case types.SET_FINAL_DATE_FILTER:
      return setFinalDateFilter(state, action);
    case types.SET_NUMBER_FILTER:
      return setNumberFilter(state, action);
    case types.SET_EXPANDED_PRODUCT_ROW:
      return setExpandedProductRows(state, action);

    default:
      return result;
  }
}

function setExpandedProductRows(state, action) {
  let productRowSelected = state.productRowSelected;
  productRowSelected = action.productRowSelected;
  return { ...state, productRowSelected };
}
function setNumberFilter(state, action) {
  let filterFields = state.filterFields;
  filterFields[action.field] = action.value;
  return { ...state, filterFields };
}

function setInitialDateFilter(state, action) {
  let filterFields = state.filterFields;
  filterFields.initialDate = action.initialDateFilter;
  return { ...state, filterFields };
}

function setFinalDateFilter(state, action) {
  let filterFields = state.filterFields;
  filterFields.finalDate = action.finalDateFilter;
  return { ...state, filterFields };
}

function setOriginFilter(state, action) {
  let filterFields = state.filterFields;
  filterFields.origin = action.origin;
  return { ...state, filterFields };
}

function setLookupOriginVisible(state, action) {
  let lookupOrigin = state.filterFields.lookupOrigin;
  lookupOrigin.visible = action.visible;
  return { ...state, lookupOrigin };
}

function setDestinationFilter(state, action) {
  let filterFields = state.filterFields;
  filterFields.destination = action.destination;
  return { ...state, filterFields };
}

function setLookupDestinationVisible(state, action) {
  let filterFields = { ...state.filterFields };
  let lookupDestination = filterFields.lookupDestination;
  lookupDestination.visible = action.visible;
  filterFields.lookupDestination = lookupDestination;
  return { ...state, filterFields };
}

function setCarrierFilter(state, action) {
  let filterFields = state.filterFields;
  filterFields.carrier = action.carrier;
  return { ...state, filterFields };
}
function setLookupCarrierVisible(state, action) {
  let lookupCarrier = state.filterFields.lookupCarrier;
  lookupCarrier.visible = action.visible;
  return { ...state, lookupCarrier };
}

function setSelectedBusinessUnitGroupLabel(state, action) {
  let filterFields = { ...state.filterFields };
  filterFields.selectedBusinessUnitGroupLabel = action.value;
  return { ...state, filterFields };
}

function setSelectedMainBusinessUnit(state, action) {
  let filterFields = { ...state.filterFields };
  filterFields.selectedMainBusinessUnit = action.record;
  return { ...state, filterFields };
}

function setBusinessUnitFilter(state, action) {
  let filterFields = state.filterFields;
  let currentBusinessUnit = action.currentBusinessUnit;
  filterFields = { ...filterFields, currentBusinessUnit };
  return { ...state, filterFields };
}

function setBusinessUnitGroupFilter(state, action) {
  let filterFields = state.filterFields;
  let businessUnitGroup = action.businessUnitGroup;
  filterFields = { ...filterFields, businessUnitGroup };
  return { ...state, filterFields };
}

function setVisibleModalSearchBusinessUnitFilter(state, action) {
  let filterFields = state.filterFields;
  filterFields.modalSearchBusinessUnitVisible = action.visible;
  return { ...state, filterFields };
}

function setVisibleModalGrouperFilter(state, action) {
  let filterFields = state.filterFields;
  filterFields.modalGrouperVisible = action.visible;
  return { ...state, filterFields };
}

function setSelectedBusinessUnit(state, action) {
  let filterFields = state.filterFields;
  filterFields.selectedBusinessUnitGroup = action.record;
  return { ...state, filterFields };
}

function setSelectedBusinessUnits(state, action) {
  let filterFields = { ...state.filterFields };
  filterFields.selectedBusinessUnits = action.records;
  return { ...state, filterFields };
}
