import api from '../../../services/api.service';
import * as utils from '../../../utils';

export const types = {
    LOADER_SHOW: 'loaderShow',
    LOADER_HIDDEN: 'loaderHide',
    SET_SHOW_LOCATION: 'FreightValueByLocationSetShowLocation',
    SET_SHOW_PERIOD: 'FreightValueByLocationsetShowPeriod',
    SET_LOCATION: 'FreightValueByLocationSetLocation',
    SET_VALUE: 'FreightValueByLocationSetValue',
    SET_PERIOD: 'FreightValueByLocationSetPeriod'
}

export function setValue(value){
    return {
        type: types.SET_VALUE,
        value,
    }
}

export function setLocation(location){
    return {
        type: types.SET_LOCATION,
        location,
    }
}

export function loaderShow(){
    return {
        type: types.LOADER_SHOW,
        async: true,
    }
}

export function loaderHide(){
    return {
        type: types.LOADER_HIDDEN,
        async: false,
    }
}

export function setShowLocation(showLocation){
    return {
        type: types.SET_SHOW_LOCATION,
        showLocation,
    }
}

export function setShowPeriod(showPeriod){
    return {
        type: types.SET_SHOW_PERIOD,
        showPeriod,
    }
}

export function setPeriod(period){
    return {
        type: types.SET_PERIOD,
        period,
    }
}


export function load(){
    return (dispatch, getState) => {
       const state = getState().freightValueByLocationState.freightValue;
       const filter = {
           location: {
               initials:state.filters.location.initials
           },
           period: state.filters.period.period || "CURRENT_MONTH",
       }
       const URL = `/api/v1/lognex/reports/indicators/calculatorFreightValue`;
       dispatch(loaderShow())
       api.post(URL, filter)
       .then(({data}) => {
            const freightValue = data.data.freightValue || 0
            dispatch(setValue(`R$ ${utils.formatNumber(freightValue)}`))
            dispatch(loaderHide())
       })
       .catch((error) => {
            console.log(error)
            dispatch(loaderHide())
       });
    }
}