import {types} from './message.actions.js';

const initialState = {
    messages:[]
}
export function messageReducer(state = initialState, action){
    if(action.type === types.MESSAGE_SHOW_MESSAGES  ){
        return Object.assign({},state,{messages:action.messages})
    }

    if(action.type === types.MESSAGE_CLEAR_MESSAGES  ){
        return Object.assign({},state,{messages:[]})
    }
    return state;
}