import React from 'react'
import './login-form.component.scss';
import { Loader } from '../../components/loader/Loader';
import background from '../../assets/images/logo/logo_form_login.png';
import { useEffect } from 'react';

const CheckCode2FAForm = (props) => {

    const [leftTime, setLeftTime] = React.useState(120);
    useEffect(() => {
        const interval = setInterval(() => {
            let leftTime = getLeftTime();
            if (leftTime <= 0) {
                clearInterval(interval);
                setLeftTime(0);
            }else{
                setLeftTime(leftTime);
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [props.twoFA.expireDate]);

    function getLeftTime() {
        const expireDate = new Date(props.twoFA.expireDate);
        const now = new Date();
        const diff = expireDate - now;
        const seconds = Math.floor(diff / 1000);
        return seconds;
    }
    return (
        <div className="login-form">
            <Loader />
            <span className="login-form__logo-container"><img src={background} alt="background" /></span>
            <h2>Informe o código que foi enviado no teu e-mail</h2>
            <input value={props.code2FA} name="code2FA" onChange={props.changeValue} placeholder="Informe o código que foi enviado no teu e-mail" />

            <button className="btn-destacado" onClick={props.validateCode2FA}>Verificar código</button>

            {leftTime>0 && <button onClick={props.generateNewCode} disabled={leftTime > 0}>Gerar novo código em {leftTime} seg</button>}
            {leftTime<=0 && <button onClick={props.generateNewCode} disabled={leftTime > 0}>Gerar novo código</button>}
            
            <p style={{color:"red"}}>{props.errorMessage}</p>

        </div>
    );
}

export default CheckCode2FAForm;